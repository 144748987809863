import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import {
    UserService, User,
    CommentService, Comment, CommentModel
} from 'src/app/@tji/_dbShare';

export interface DialogData {
    title: string;
    message: string;
    type: string;
    resource: any;
    comment?: any;
}

@Component({
    selector: 'comment-dialog',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentDialogComponent implements OnInit {

    authUser: User;
    commentsForm: UntypedFormGroup;
    resource: any;
    comment: Comment = null;
    type: string = 'new';
    description: '';
    isForm: boolean = false;

    constructor(public dialogRef: MatDialogRef<CommentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public userService: UserService,
        private _formBuilder: UntypedFormBuilder) {
        if (data && data.comment && data.comment) {
            this.resource = data.comment;
            this.resource['className'] = data.comment.resource_type;
            this.resource['id'] = data.comment.resource_id;
            this.resource['clientSiteId'] = data.resource.clientSiteId;
        } else {
            this.resource = data.resource;
        }
        if (data.comment && data.comment._id) {
            this.type = 'reply';
            this.comment = data.comment;
        }
    }

    ngOnInit() {
        this.userService.user.subscribe(data => {
            if (!this.isForm) {
                this.authUser = data;
                this.commentsForm = this.createForm();
            }
        });
    }


    onNoClick(): void {
        this.dialogRef.close({ data: false });
    }

    onOkClick(): void {
        this.dialogRef.close({ data: true, formValue: this.commentsForm.value });
    }

    getTitle() {
        if (this.data && this.data.title) {
            return this.data.title;
        }
        switch (this.type) {
            case "new":
                return "Add New Comment";
                break;
            case "reply":
                return "Reply Comment";
                break;
            default:
                return "Add New Comment";
                break;
        }
    }

    /** Create form */
    createForm(): UntypedFormGroup {
        if (!this.isForm) {
            var user: any = JSON.parse(localStorage.getItem('tji_user'));
            this.isForm = true;
            return this._formBuilder.group({
                client_site_id: [(this.resource && this.resource.client_site_id) ? this.resource.client_site_id : this.resource.clientSiteId, [Validators.required]],
                comment_id: [(this.comment && this.comment._id) ? this.comment._id : null],
                ticket_id: [(this.resource) ? this.resource.id : null, [Validators.required]],
                user_id: [(user && user.id) ? user.id : null],
                description: [this.description, [Validators.required, Validators.minLength(3)]],
                title: ["Commented.", [Validators.required, Validators.minLength(3)]],

            });
        }
    }

}
