import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, ImageService,
	PeopleService, People, PeopleModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'people-item-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
	authUser: User;
	people: People;
	baseImage: string = 'assets/img/no-user.jpg';

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => this.people = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	storeImage(event) {
	}

}
