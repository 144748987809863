import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Itrsbot } from './itrsbot.interface';
import { ItrsbotModel } from './itrsbot_model.model';

import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ItrsbotService implements Resolve<any> {
	url: string = 'itrsbot';
	routeParams: any;
	defaultParams: any = {
		"limit": 200,
		"current": 1
	};

	sortIdentity: any = {
		'name': 'name'
	};

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<Itrsbot[]>([]);
	allItems = this.allItemsSource.asObservable();

	public itemSource = new BehaviorSubject<Itrsbot>(new ItrsbotModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Itrsbot[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ItrsbotService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on ItrsbotService');
	}

	concatlibrary(allItems: Itrsbot[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Itrsbot) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Itrsbot) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: Itrsbot[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Itrsbot[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: Itrsbot) {
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Itrsbot[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Itrsbot) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0 && item && item.id) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			"limit": 200,
			"current": 1
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('Itrsbot', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var itrsBotData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];

					if (itrsBotData && itrsBotData.length > 0) {
						itrsBotData.forEach(itrsBot => {
							var dataFormats = {
								"id": itrsBot.id,
								"parent_id": itrsBot.parent_id,
								"code": itrsBot.code,
								"name": itrsBot.name,
								"resource_type": itrsBot.resource_type,
								"resource_id": parseInt(itrsBot.resource_id),
								"client_id": itrsBot.client_id,
								"is_active": itrsBot.is_active ? true : false,
								"created_by": itrsBot.created_by,
								"updated_by": itrsBot.updated_by,
								"created_at": itrsBot.created_at,
								"updated_at": itrsBot.updated_at,
								"deleted_at": itrsBot.deleted_at,
							}
							if (additionalData && additionalData.clientSiteInfo) {
								additionalData.clientSiteInfo.forEach(clientSiteInfo => {
									if (itrsBot.resource_id == clientSiteInfo.id) {
										if (clientSiteInfo.details) {
											dataFormats["siteName"] = clientSiteInfo.details.name;
										}
										dataFormats["accountName"] = clientSiteInfo.account_name;
										dataFormats["clientSiteName"] = clientSiteInfo.account_name;
										dataFormats["clientSiteColor"] = clientSiteInfo.color;
										dataFormats["clientSiteTextColor"] = clientSiteInfo.textcolor;
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
					}
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.success) {
					var itrsBotData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];

					if (itrsBotData && itrsBotData.length > 0) {
						itrsBotData.forEach(itrsBot => {
							var dataFormats = {
								"id": itrsBot.id,
								"parent_id": itrsBot.parent_id,
								"code": itrsBot.code,
								"name": itrsBot.name,
								"resource_type": itrsBot.resource_type,
								"resource_id": parseInt(itrsBot.resource_id),
								"client_id": itrsBot.client_id,
								"is_active": itrsBot.is_active ? true : false,
								"created_by": itrsBot.created_by,
								"updated_by": itrsBot.updated_by,
								"created_at": itrsBot.created_at,
								"updated_at": itrsBot.updated_at,
								"deleted_at": itrsBot.deleted_at,
							}
							if (additionalData && additionalData.clientSiteInfo) {
								additionalData.clientSiteInfo.forEach(clientSiteInfo => {
									if (itrsBot.resource_id == clientSiteInfo.id) {
										if (clientSiteInfo.details) {
											dataFormats["siteName"] = clientSiteInfo.details.name;
										}
										dataFormats["accountName"] = clientSiteInfo.account_name;
										dataFormats["clientSiteName"] = clientSiteInfo.account_name;
										dataFormats["clientSiteColor"] = clientSiteInfo.color;
										dataFormats["clientSiteTextColor"] = clientSiteInfo.textcolor;
									}
								});
							}
							dataFormat.push(dataFormats);
						});
					}
				}

				if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
					params.current = data.pagnitation.pageCount;
					this.changeParams(params);
				} 
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					if (dataFormat && dataFormat.length > 0) {
						lists = result.concat(dataFormat);
						this.changeAllItems(lists);
					}
				});
				
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: Itrsbot) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			var viewMore = {
				id: this.routeParams.id
			}
			this.commonService.storeItem('Itrsbot/viewMore', viewMore, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success) {
						var additionalData = data.data;
						if (additionalData.clientSiteInfo) {
							if (additionalData.clientSiteInfo.details) {
								data.data["siteName"] = additionalData.clientSiteInfo.details.name;
							}
							data.data["accountName"] = additionalData.clientSiteInfo.account_name;
							data.data["clientSiteName"] = additionalData.clientSiteInfo.account_name;
							data.data["clientSiteColor"] = additionalData.clientSiteInfo.color;
							data.data["clientSiteTextColor"] = additionalData.clientSiteInfo.textcolor;
						}
						this.changeAllItemsByItem(data.data);
						this.changeItem(data.data);
					} else {
						this.alertService.webErrorShow(data);
					}
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ItrsbotModel({}));
		}
	}

	getItemWithGroups(params) {
		if (params && params.id > 0) {
			this.commonService.getItem(this.url, params.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ItrsbotModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(url, formData) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(formData.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	itrsBotUpdate(url, formData) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					// this.concatItem(data.data);
					// this.changeItem(data.data);
					this.alert('Success', 'Created Successfully !!!');
					this.getAllItems();
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}
	
	itrsBotDataUpdate(url, formData, itrsbot) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					itrsbot.name = formData.name;
					itrsbot.client_site_id = formData.resource_id;
					// this.concatItem(itrsbot);
					this.changeItem(itrsbot);
					this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}
}