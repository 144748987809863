import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, UserProfileService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'profile-profile-view',
	templateUrl: './profile-view.component.html',
	styleUrls: ['./profile-view.component.scss'],
	providers: [DatePipe]
})

export class ProfileViewComponent implements OnInit, OnDestroy {
	@Output() onEditProfile = new EventEmitter();
	user: any;
	avatar = 'assets/img/no-user.jpg';
	profileImageForm: UntypedFormGroup;
	mandiatoryResponse: boolean;
	profileImage = '';

	constructor(public userService: UserService,
		private userProfileService: UserProfileService,
		public matDialog: MatDialog,
		private _formBuilder: UntypedFormBuilder,
		private datePipe: DatePipe) {
		this.userService.user.subscribe(data => {
		this.user = data
		if (this.user && this.user.person && this.user.person.person_details && this.user.person.person_details.image_api) {
				this.profileImage = this.user.person.person_details.image_api;
			}else{
				this.profileImage = 'assets/img/no-user'
			}
		});
		this.userService.checkMandiatoryResponse.subscribe(res => this.mandiatoryResponse = res);
	}

	ngOnInit() {
		this.userService.checkMandatoryInputs();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.userProfileService.unSubscribe();
	}

	onFileSelected(event, user: User) {
		this.userProfileService.changeItem(user);
		let reader = new FileReader();
		let fileData = null;
		this.profileImageForm = this._formBuilder.group({
			file_data: null,
			file_name: null,
			file_type: null,
			user_id: user.id,
			person_id: user.person_id,
			person_type: user.person_type,
		});
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				this.profileImageForm.patchValue({
					file_data: reader.result,
					file_name: file.name,
					file_type: file.type,
					user_id: user.id,
					person_id: user.person_id,
					person_type: user.person_type,
				});
				this.sendFile();
			};
		}
	}

	getProfilePercentage(user: User) {
		if (user && user.person && user.person.detail
			&& user.person.detail.profileCompletePercentage && user.person.detail.profileCompletePercentage > 0) {
			return user.person.detail.profileCompletePercentage;
		}
		return 0;
	}

	sendFile() {
		this.userProfileService.storeProfileImage(this.profileImageForm.value);
		this.profileImageForm.reset();
	}

	/** Verification Mail Send Confirmation */
	mailSendConfirmation(user: User): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to send verification mail to ' + user.email,
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.userProfileService.sendVerifyMail();
			}
		});
	}


}
