import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let $: any;
import { environment } from 'src/environments/environment';
import { PremiumDashboardService, PremiumDashboard, PremiumAlert, EventService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'premium-dashboard-tickets-count',
    templateUrl: './ticketsCount.component.html',
    styleUrls: ['./ticketsCount.component.scss'],
    animations: tjiAnimation,
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class TicketsCountComponent {

    countWidgets: Array<any> = [];
    dashboardParam: any;
    animState = false;
    channel: any;
    eventData: any;

    ticketCarouselOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        autoplayHoverPause: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        animateOut: 'animate__animated animate__slideOutDown',
        animateIn: 'animate__animated animate__slideInDown',
        autoplayTimeout: 2500,
        autoplaySpeed: 800,
        navSpeed: 700,
        navText: [],
        responsive: {
            0: { items: 1 },
            360: { items: 1 },
            450: { items: 1 },
            740: { items: 1 },
            940: { items: 1 },
            1200: { items: 1 }
        },
        nav: false
    }

    constructor(private router: Router,
        private eventService: EventService,
        public premiumDashboardService: PremiumDashboardService) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
        this.premiumDashboardService.countWidgets.pipe(untilDestroyed(this)).subscribe(data => this.countWidgets = data);
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit() { }

    animStateDone() { this.animState = !this.animState; }

    getTotalCountBySlug(input = 'total-tickets'): number {
        let totalTickets: number = 0;
        if (this.countWidgets && this.countWidgets.length > 0) {
            this.countWidgets.forEach((item) => {
                totalTickets = totalTickets + item.counts.filter((x) => {
                    return (x.slug === input) ? true : false;
                })[0].count;
            });
        }
        return totalTickets;
    }

    getIcon(countWidget: any): string {
        if (countWidget && countWidget.site_name) {
            return countWidget.site_name.toLowerCase();
        }
        return 'support';
    }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'newfeed': case "aNewfeed":
                    this.premiumDashboardService.changeCountWidget(data, "newfeed");
                    break;
                case 'removefeed':
                    this.premiumDashboardService.changeCountWidget(data, "removefeed");
                    break;
                case 'queueticket': case "aQueueticket":
                    this.premiumDashboardService.changeCountWidget(data, "queueticket");
                    break;
                case 'aticketassigned':
                    this.premiumDashboardService.changeCountWidget(data, "aticketassigned");
                    break;
                case 'ticketclosed':
                    this.premiumDashboardService.changeCountWidget(data, "ticketclosed");
                    break;
                case 'aclosedticketremoved':
                    this.premiumDashboardService.changeCountWidget(data, "ticketclosed");
                    break;
                case 'aticketreopened':
                        this.premiumDashboardService.changeCountWidget(data, "aticketreopened");
                    break;


                // case 'clientSitevsTicket':
                //     if (eventData && eventData.userId && data) {
                //         this.premiumDashboardService.changeCountWidget(data, "clientSitevsTicket");
                //     }
                //     break;
                default:
                    break;
            }
        }
    }

}
