import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Aicollection, AicollectionService, ClientService, EventService, PaymentsService, UserService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { GeneratePaymentComponent } from '../generate-payment/generate-payment.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
@UntilDestroy()
@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss'],
  providers: [OrderByPipe]
})
export class PaymentFailedComponent implements OnInit {

  paymentList: Aicollection[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  param: any;
  dataSource = new MatTableDataSource(this.paymentList);
  displayedColumns = ['date', 'name', 'payment id', 'amount', 'payment link', 'details', 'action'];


  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  eventData: any;
  statusData: any;

  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  filterType: string = 'failed';
  filterLoader: boolean = true;

  constructor(public aicollectionService: AicollectionService, public eventService: EventService,
    private orderByPipe: OrderByPipe, public matDialog: MatDialog,public userService: UserService,
    public clientService: ClientService, public paymentsService: PaymentsService) {

    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });
    this.clientService.getAllUsers();
    this.aicollectionService.resetParams();
    this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
      this.param['type'] = 'failed';
    });

    this.aicollectionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentList = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.paymentList);
      this.dataSource.sort = this.sort;
    });

    this.aicollectionService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.aicollectionService.isStatusPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['payment_status']) {
        if (data['transaction_response']) {
          data['transaction_response'] = JSON.parse(data['transaction_response']);
        }
        this.statusData = data;
      } else {
        delete this.statusData;
      }
    });

    this.paymentsService.filterLoader.subscribe(data => this.filterLoader = data);
    this.aicollectionService.filterLoader.subscribe(data => this.filterLoader = data);
  }

  ngOnInit(): void {
    this.aicollectionService.getCurrentItems();
    this.dataInit();
  }
  ngOnDestroy(): void {
    this.aicollectionService.isLoader.next(true);
    this.aicollectionService.allItemsSource.next(null);
    this.aicollectionService.resetParams();
    this.aicollectionService.unSubscribe();
    this.aicollectionService.unSubscribeFilter();
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }
  dataInit() {
    this.aicollectionService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'paymentCreate':
          if (this.eventData.data && this.eventData.data.payment_status == 'failed') {
            this.aicollectionService.concatItem(this.eventData.data);
          }
          break;
        case 'paymentUpdate':
          if (this.eventData.data && this.eventData.data.payment_status == 'failed') {
            this.aicollectionService.changeAllItemsByItem(this.eventData.data);
          }
          break;
        default:
          break;
      }
    }
  }

  toggleRightSidebar() {
    this.rightSideBar.toggle();
  }


  viewStatus(payment: any = null) {
    if (payment && payment._id) {
      var data = {
        "trackId": payment._id
      }
      this.aicollectionService.getStatusPayment(data);
    }
  }

  closeStatus() {
    this.aicollectionService.isStatusPaymentData.next(null);
  }

  /** create New Payment */
  createNewPayment() {
    this.aicollectionService.checkAuth();
    var clientSettings = JSON.parse(localStorage.getItem('client_settings'));
    let dialogRef = this.matDialog.open(GeneratePaymentComponent, {
      width: "600px",
      data: {
        action: 'new',
        item: 'payment',
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        switch (actionType) {
          case 'new':
            this.aicollectionService.isPaymentLoader.next(true);
            formData.value.phoneNumber = parseInt(formData.value.phoneNumber);
            this.aicollectionService.generatePaymentUrl(formData.value);
            break;
        }
      });
  }

}
