export class ChatUserModel {
    id: number;
    username: string;
    status: string;
    avatar: string;
    displayName?: string;
    participantType?: string;
    chatUnreadCount?: number;
    isOnline?: boolean;
    person_id?: number;
    person_type?: string;

    /**
     * chatUser
     *
     * @param chatUser
     */
    constructor(chatUser) {
        this.id = chatUser.id || '';
        this.username = chatUser.username || '';
        this.status = chatUser.status || '';
        this.avatar = chatUser.avatar || '';
        this.displayName = chatUser.displayName || '';
        this.participantType = chatUser.participantType || '';
        this.chatUnreadCount = chatUser.chatUnreadCount || '';
        this.person_id = chatUser.person_id || '';
        this.person_type = chatUser.person_type || '';
    }
}
