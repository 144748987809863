import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, CustomvalidationService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'change-password',
	templateUrl: './changePassword.component.html',
	styleUrls: ['./changePassword.component.scss']
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Change Password';
	authUser: User;
	action: string;
	changePasswordForm: UntypedFormGroup;

	constructor(public userService: UserService,
		private customValidator: CustomvalidationService,
		public matDialogRef: MatDialogRef<ChangePasswordComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.changePasswordForm = this.createForm();
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		this.action = data.action;
		this.authUser = data.item;
		this.dialogTitle = "Change Password";
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			// password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(30), Validators.compose([this.customValidator.patternValidator()])]],
			password: [null, [Validators.required, Validators.min(1), Validators.max(100), Validators.compose([this.customValidator.patternValidator()])]],
			confirm_password: [null, [Validators.required, Validators.maxLength(30), Validators.minLength(8), Validators.compose([this.customValidator.patternValidator()])]],
		},
			{
				validators: this.customValidator.MustMatch('password', 'confirm_password')
			}
		);
	}
}
