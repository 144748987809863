export class AgentPerformanceModel {
	account_name: string;
	site_name: string;
	totalTickets: number;
	totalMessages: number;
	sendMessages: number;
	receivedMessages: number;

	/**
	 * Constructor
	 * @param agentPerformance
	 */
	constructor(agentPerformance) {
		this.account_name = agentPerformance.account_name || null;
		this.site_name = agentPerformance.site_name || null;
		this.totalTickets = agentPerformance.totalTickets || null;
		this.totalMessages = agentPerformance.totalMessages || null;
		this.sendMessages = agentPerformance.sendMessages || null;
		this.receivedMessages = agentPerformance.receivedMessages || null;
	}
}
