export class TemplateMessageModel {
    id:number;
    stencil_id: number;
    client_id: number;
    client_site_id: number;
    code: string;
    name: string;
    namespace: string;
    label: string;
    description: string;
    language:string;
    required_values:string;
    status:boolean;
    json:string;
    token:string;
    created_by:number;
    updated_by:number;
    created_at:any;
    updated_at:any;

    requiredAllValues?:Array<any>;
    jsonData?:Array<any>;
    templateUsages?:Array<any>;
    stencil?:any;
    stencilName?:string;
    templateData?:any;
    postData?: any;
    clientSiteName?: string;
    buttons?:any;

    /**
     * Constructor
     *
     * @param templateMessage
     */
    constructor(templateMessage) {
            this.id = templateMessage.id || '';
            this.stencil_id = templateMessage.stencil_id || '';
            this.code = templateMessage.code || '';
            this.name = templateMessage.name || '';
            this.namespace = templateMessage.namespace || '';
            this.label = templateMessage.label || '';
            this.description = templateMessage.description || '';
            this.language = templateMessage.language || '';
            this.required_values = templateMessage.required_values || '';
            this.client_id = templateMessage.client_id || '';
            this.client_site_id = templateMessage.client_site_id || '';
            this.status = templateMessage.status || false;
            this.json = templateMessage.json || '';
            this.token = templateMessage.token || '';
            this.required_values = templateMessage.required_values || '';
            this.created_by = templateMessage.created_by || '';
            this.updated_by = templateMessage.updated_by || '';
            this.buttons = templateMessage.buttons || '';
    }
}
