import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { UserService } from 'src/app/@tji/_dbShare/user/user.service';
import { Count } from 'src/app/@tji/_dbShare/dashboard/count/count.interface';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
    providedIn: 'root',
})
@UntilDestroy()

export class CountService implements Resolve<any> {
    url: string = 'count';
    routeParams: any;
    defaultParams: any = {
        'user_id': null,
    };

    @Output() onChangeItem = new EventEmitter();
    @Output() onChangeAllItems = new EventEmitter();

    private librariesSource = new BehaviorSubject<Array<any>>([]);
    libraries = this.librariesSource.asObservable();

    private jsonDataSource = new BehaviorSubject<Array<any>>([]);
    jsonData = this.jsonDataSource.asObservable();

    private itemSource = new BehaviorSubject<any>(null);
    item = this.itemSource.asObservable();

    private paramsSource = new BehaviorSubject<any>(this.defaultParams);
    params = this.paramsSource.asObservable();

    private _unsubscribeAll: Subject<any>;
    clientId: number = null;

    constructor(private globalService: GlobalService,
        private commonService: CommonService,
        private userService: UserService) {
        this._unsubscribeAll = new Subject();
        this.clientId = this.userService.getAuthClientId();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.resetParams(),
                this.getItem()
            ])
                .then(() => {
                    resolve(null);
                }, reject
                );
        });
    }

    unSubscribe() {
        // console.log('UnSubscribed CountService');
    }

    unSubscribeFilter() {
        // console.log('UnSubscribed Filters on CountService');
    }

    clearSubscribe() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    changeLibraries(libraries: Array<any>) {
        this.librariesSource.next(libraries);
    }

    changeItem(item: Count) {
        this.itemSource.next(item);
        this.onChangeItem.emit(item);
        if(item && item.id && item.jsonData) {
            this.changeJsonData(item.jsonData);
        }
    }

    changeJsonData(jsonData: Array<any>) {
        this.jsonDataSource.next(jsonData);
    }

    changeJsonDataByType(data: any) {
    	if(data && data.type && data.type === 'increment') {
            this.doIncrement(data);
    	}
    	if(data && data.type && data.type === 'decrement') {
            this.doDecrement(data);
    	}
    }

    doIncrement(data: any) {
        if (data && data.key && data.value && data.value > 0) {
            let oldJson: Array<any> = [];
            this.jsonData.pipe(untilDestroyed(this))
                .subscribe(result => {
                    oldJson = result;
                    if (oldJson && oldJson[data.key]) {
                        oldJson[data.key]++;
                    } else {
                        oldJson[data.key] = 1;
                    }
                });
        }
    }

    doDecrement(data: any) {
        if (data && data.key && data.value && data.value > 0) {
            let oldJson: Array<any> = [];
            this.jsonData.pipe(untilDestroyed(this))
                .subscribe(result => {
                    oldJson = result;
                    if (oldJson && oldJson[data.key] && oldJson[data.key] > 1) {
                        oldJson[data.key]--;
                    } else {
                        oldJson[data.key] = 0;
                    }
                });
        }
    }

    changeParams(parms: any) {
        this.paramsSource.next(parms);
    }

    resetParams() {
        let authUser = this.userService.storedUser;
        const defaultParams: any = {
            'user_id': (authUser && authUser.id) ? authUser.id : null,
        };
        this.changeParams(this.defaultParams);
    }

    getItem(params: any = null) {
        let userId = (params && params.user_id) ? params.user_id : this.defaultParams.user_id;
        userId = (userId && userId > 0) ? userId : this.userService.authUserId();
        if (userId && userId > 0) {
            this.commonService.hitUrl(this.url + '-byuserid/' + userId)
                .pipe(untilDestroyed(this, 'unSubscribe'))
                .subscribe(data => {
                    if (data && data.data && data.data.jsonData) {
                        this.changeItem(data.data.jsonData);
                    }
                },
                    error => console.log('Error ::' + error)
                );
        }
    }

    getItemCount(slug: string, site: string = null, clientSiteId: number = null) {
        slug = (slugify(slug, { lower: true }));
        site = (site) ? (slugify(site, { lower: true })) : null;
        let itemKey: string = '';
        if(this.clientId) { itemKey = itemKey + String(this.clientId); }
        if (clientSiteId) { itemKey = itemKey + '_' + String(clientSiteId); }
        if (site) { itemKey = itemKey + '_' + site; }
        if (slug) { itemKey = itemKey + '_' + slug; }
        if(slug && itemKey) {
            var jsonData: any = null;
            this.jsonData.subscribe(data => { jsonData = data; });
            if(jsonData && jsonData[itemKey]) {
                return Number(jsonData[itemKey]);
            }
        }
        return 0;
    }


}
