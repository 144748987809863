export class AicollectionModel {
    _id: number;
    id: number;
    business_id: number;
    name: string;
    catalog_id: number;
    client_id: number;
    clientSiteInfo?: Array<any>;
    mongo?: Array<any>;
    PaymentId: any;
    tags: any;

    /**
     * Constructor
     *
     * @param Aicollection
     */
    constructor(Aicollection) {
        this._id = Aicollection._id || '';
        this.id = Aicollection.id || '';
        this.business_id = Aicollection.business_id || '';
        this.name = Aicollection.name || '';
        this.catalog_id = Aicollection.catalog_id || '';
        this.client_id = Aicollection.client_id || '';
        this.clientSiteInfo = Aicollection.clientSiteInfo || {};
        this.mongo = Aicollection.mongo || {};
        this.PaymentId = Aicollection || '';
        this.tags = Aicollection.tags || '';
    }
}