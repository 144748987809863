import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	RTeam, RTeamService,
	Agent, AgentService, GlobalService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'team-assign-team',
	templateUrl: './assignTeam.component.html',
	styleUrls: ['./assignTeam.component.scss']
})

export class AssignTeamComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	action: string;
	assignTeamForm: UntypedFormGroup;

	agentParam: any;
	agents: Agent[] = [];
	team: RTeam;
	isChecked: boolean = true;

	constructor(public userService: UserService,
		public agentService: AgentService,
		private globalService: GlobalService,
		public matDialogRef: MatDialogRef<AssignTeamComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
		this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (this.agents.length > 0 && data.length > 0) {
				// this.agents = this.globalService.arrayMergeById(this.agents, length);
				if (this.agents && this.agents.length > 0) {
					for (var i = 0; i < data.length; ++i) {
						var item = data[i];
						for (var j = 0; j < this.agents.length; ++j) {
							var oldItem = this.agents[j];
							if (oldItem.id == item.id) {
								this.agents[j] = item;
								break;
							}
						}
					}
					this.agents = this.agents.concat(data.filter(x => this.agents.every(y => y.id !== x.id)));
				} else {
					this.agents = data;
				}
				this.reconstract();
				// return this.agents;
			} else {
				this.agents = data
				this.reconstract();
			}
		});
		this.getInit(_data);
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { 
	this.agentService.unSubscribeFilter();
	}

	reconstract() {
		if (this.agents) {
			this.agents.forEach(element => {
				element['checked'] = false;
			});
			if (this.team) {
				this.team['agnets_ids'].forEach(selectAgent => {
					this.agents.forEach(agent => {
						if (agent.id == selectAgent) {
							agent['checked'] = true;
						}
					});
				});
			}
		}
	}

	getAgents() {
		this.agentParam.limit = 500;
		this.agentService.getAllItems();
	}

	getInit(data) {
		if (data.item) {
			this.reconstract();
		}
		// Set the defaults
		this.team = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Agent to Assign Team';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Agent to Change Team';
				break;
			default:
				break;
		}
		this.assignTeamForm = this.createForm();

	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			user_id: [null, [Validators.required]],
			team_id: [(this.team && this.team.id) ? this.team.id : null, [Validators.required]],
			client_id: [(this.team && this.team.client_id) ? this.team.client_id : null, [Validators.required]],
			checked: [this.isChecked, [Validators.required]],
			agentId: [null],
		});
	}

	changeAgent(userId : any) {
		// var agentDetails = this.agents.filter(agent => agent.userId === userId);
		var agentDetails = this.agents.filter(agent => agent.userInfo.id === userId);
		if (agentDetails && agentDetails[0]['checked']) {
			this.isChecked = false;
		} else {
			this.isChecked = true;
		}
		this.assignTeamForm.controls.agentId.setValue(agentDetails[0].id);
		this.assignTeamForm.controls.checked.setValue(this.isChecked);
		this.assignTeamForm.controls.user_id.setValue(agentDetails[0].userInfo.id);
	}

}
