import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { PeopleService } from 'src/app/@tji/_dbShare/person/people/people.service';
import { FlagService } from 'src/app/@tji/_dbShare/remark';

import { WhatsappService } from 'src/app/@tji/_dbShare/whatsapp/whatsapp/whatsapp.service';
import { WhatsappTicketService } from 'src/app/@tji/_dbShare/whatsapp/whatsapp_ticket/whatsapp_ticket.service';
import { WebchatService } from 'src/app/@tji/_dbShare/webchat/webchat/webchat.service';
import { WebchatTicketService } from 'src/app/@tji/_dbShare/webchat/webchat_ticket/webchat_ticket.service';
import { YoutubeService } from 'src/app/@tji/_dbShare/youtube/youtube/youtube.service';
import { YoutubeTicketService } from 'src/app/@tji/_dbShare/youtube/youtube_ticket/youtube_ticket.service';
import { InstagramService } from 'src/app/@tji/_dbShare/instagram/instagram/instagram.service';
import { InstagramTicketService } from 'src/app/@tji/_dbShare/instagram/instagram_ticket/instagram_ticket.service';
import { InstadmService } from 'src/app/@tji/_dbShare/instadm/instadm/instadm.service';
import { InstadmTicketService } from 'src/app/@tji/_dbShare/instadm/instadm_ticket/instadm_ticket.service';
import { MessengerService } from 'src/app/@tji/_dbShare/messenger/messenger/messenger.service';
import { MessengerTicketService } from 'src/app/@tji/_dbShare/messenger/messenger_ticket/messenger_ticket.service';
import { TweetService } from 'src/app/@tji/_dbShare/twitter/tweet/tweet.service';
import { TweetTicketService } from 'src/app/@tji/_dbShare/twitter/tweet_ticket/tweet_ticket.service';
import { TweetdmService } from 'src/app/@tji/_dbShare/twitterdm/tweetdm/tweetdm.service';
import { TweetdmTicketService } from 'src/app/@tji/_dbShare/twitterdm/tweetdm_ticket/tweetdm_ticket.service';
import { ClosedTicketService } from 'src/app/@tji/_dbShare/chat/closedTicket.service';
import { CommentService } from 'src/app/@tji/_dbShare/remark/comment/comment.service';

declare var require: any;
var slugify = require('slugify')

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class PeopleChatService {
	authUser: User;
	defaultParams: any = {
		'paginate': 25,
		'page': 1,
		'order': 'created_at|desc',
		'include_parent': 'true',
		'all_history': 'true',
	};

	private siteNameSource = new BehaviorSubject<string>(null);
	siteName = this.siteNameSource.asObservable();

	private selectedPeopleSource = new BehaviorSubject<any>(null);
	selectedPeople = this.selectedPeopleSource.asObservable();

	private chatMessagesSource = new BehaviorSubject<Array<any>>([]);
	chatMessages = this.chatMessagesSource.asObservable();

	private viewScreenSource = new BehaviorSubject<any>('');
	viewScreen = this.viewScreenSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	changePeople: EventEmitter<boolean> = new EventEmitter();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private tweetService: TweetService,
		private tweetTicketService: TweetTicketService,
		private tweetdmService: TweetdmService,
		private tweetdmTicketService: TweetdmTicketService,
		private messengerService: MessengerService,
		private messengerTicketService: MessengerTicketService,
		private whatsappService: WhatsappService,
		private whatsappTicketService: WhatsappTicketService,
		private webchatService: WebchatService,
		private webchatTicketService: WebchatTicketService,
		private youtubeService: YoutubeService,
		private youtubeTicketService: YoutubeTicketService,
		private instagramService: InstagramService,
		private instagramTicketService: InstagramTicketService,
		private instadmService: InstadmService,
		private instadmTicketService: InstadmTicketService,
		private commentService: CommentService,
		private flagService: FlagService,
		private closedTicketService: ClosedTicketService,
		private userService: UserService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([
				// this.getConcurrentLimit(),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ChatService');
	}

	changeSiteName(siteName: string) {
		this.siteNameSource.next(siteName);
	}

	changeSelectedPeople(ticket: any) {
		this.changeChatMessages([]);
		this.resetParams();
		this.selectedPeopleSource.next(ticket);
		this.changePeople.emit(true);
		if (ticket && ticket.className) {
			this.changeSiteName(ticket.className);
			this.getChatMessages();
		} else {
			this.changeChatMessages([]);
			this.commentService.changeAllItems([]);
		}
		return;
	}

	changeChatMessages(messages: Array<any>) {
		this.chatMessagesSource.next(messages);
	}

	changeViewScreen(viewScreen: any) {
		this.viewScreenSource.next(viewScreen);
	}

	getChatMessages() {
		let params = null;
		this.params.subscribe(data => params = data);
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		let selectedPeople = null;
		this.selectedPeople.subscribe(data => selectedPeople = data);
		params.people_id = selectedPeople.id;
		params.client_id = selectedPeople.client_id;
		params.client_site_id = selectedPeople.client_site_id;
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					params.only = 'id,tweet_id,reply_id,text,status,media,asset_url,client_id,client_site_id,tweet_at,user_image,message_type,is_itrs,created_by,updated_by,created_at,updated_at';
					this.tweetService.changeParams(params);
					this.tweetService.getAllItems();
					this.tweetService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
						this.changeChatMessages(data);
					});
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					params.only = 'id,tweetdm_id,reply_id,text,status,media,asset_url,client_id,client_site_id,tweet_at,user_image,message_type,is_itrs,created_by,updated_by,created_at,updated_at';
					this.tweetdmService.changeParams(params);
					this.tweetdmService.getAllItems();
					this.tweetdmService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.tweetdmService.unSubscribe();
					break;
				case "messenger":
				case "Messenger":
					params.only = 'id,messenger_id,reply_id,text,status,media,asset_url,client_id,client_site_id,from,to,user_image,is_itrs,created_by,updated_by,created_at,updated_at';
					this.messengerService.changeParams(params);
					this.messengerService.getAllItems();
					this.messengerService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.messengerService.unSubscribe();
					break;
				case "whatsapp":
				case "Whatsapp":
					params.only = 'id,whatsapp_id,reply_id,text,status,sms_message_sid,media,asset_url,client_id,client_site_id,from,to,user_image,is_itrs,created_by,updated_by,created_at,updated_at,json';
					this.whatsappService.changeParams(params);
					this.whatsappService.getAllItems();
					this.whatsappService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.whatsappService.unSubscribe();
					break;
				case "webchat":
				case "Webchat":
					params.only = 'id,webchat_id,reply_id,uuid,text,status,media,asset_url,client_id,client_site_id,from,to,user_image,is_itrs,created_by,updated_by,created_at,updated_at,json';
					this.webchatService.changeParams(params);
					this.webchatService.getAllItems();
					this.webchatService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.webchatService.unSubscribe();
					break;
				case "youtube":
				case "Youtube":
					params.only = 'id,youtube_id,reply_id,text,status,client_id,client_site_id,from,to,user_image,created_by,updated_by,created_at,updated_at';
					this.youtubeService.changeParams(params);
					this.youtubeService.getAllItems();
					this.youtubeService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.youtubeService.unSubscribe();
					break;
				case "instagram":
				case "Instagram":
					params.instagram_id = selectedPeople.instagram_id;
					params.is_closed = selectedPeople.is_closed;
					params.only = 'id,instagram_id,reply_id,text,status,mediaId,commentId,parent_commentId,media_type,media_url,userId,uName,client_id,client_site_id,from,to,user_image,is_itrs,created_by,updated_by,created_at,updated_at';
					this.instagramService.changeParams(params);
					this.instagramService.getAllItems();
					this.instagramService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.instagramService.unSubscribe();
					break;
				case "instadm":
				case "instaDm":
				case "Instadm":
				case "InstaDm":
					params.only = 'id,instadm_id,reply_id,text,status,message_mid,media,asset_url,client_id,client_site_id,from,to,user_image,is_itrs,created_by,updated_by,created_at,updated_at';
					this.instadmService.changeParams(params);
					this.instadmService.getAllItems();
					this.instadmService.allItems.subscribe(data => {
						this.changeChatMessages(data);
					});
					this.instadmService.unSubscribe();
					break;
				default:
					break;
			}
		}
		return;
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
		let siteName = null;
		let params = null;
		this.siteName.subscribe(data => siteName = data);
		this.params.subscribe(data => params = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.changeParams(params);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmService.changeParams(params);
					break;
				case "messenger":
				case "Messenger":
					this.messengerService.changeParams(params);
					break;
				case "whatsapp":
				case "Whatsapp":
					this.whatsappService.changeParams(params);
					break;
				case "webchat":
				case "Webchat":
					this.webchatService.changeParams(params);
					break;
				case "youtube":
				case "Youtube":
					this.youtubeService.changeParams(params);
					break;
				case "instagram":
				case "Instagram":
					this.instagramService.changeParams(params);
					break;
				case "instadm":
				case "instaDm":
				case "Instadm":
				case "InstaDm":
					this.instadmService.changeParams(params);
					break;
				default:
					break;
			}
		}
	}

	resetParams() {
		const defaultParams: any = {
			'paginate': 25,
			'page': 1,
			'order': 'created_at|desc',
			'include_parent': 'true',
			'all_history': 'true',
		};
		this.changeParams(defaultParams);
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.unSubscribe();
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmService.unSubscribe();
					break;
				case "messenger":
				case "Messenger":
					this.messengerService.unSubscribe();
					break;
				case "whatsapp":
				case "Whatsapp":
					this.whatsappService.unSubscribe();
					break;
				case "webchat":
				case "Webchat":
					this.webchatService.unSubscribe();
					break;
				case "youtube":
				case "Youtube":
					this.youtubeService.unSubscribe();
					break;
				case "instagram":
				case "Instagram":
					this.instagramService.unSubscribe();
					break;
				case "instadm":
				case "instaDm":
				case "Instadm":
				case "InstaDm":
					this.instadmService.unSubscribe();
					break;
				default:
					break;
			}
		}
	}

	/** Scroll Event */
	onScroll() {
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.onScroll();
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmService.onScroll();
					break;
				case "messenger":
				case "Messenger":
					this.messengerService.onScroll();
					break;
				case "whatsapp":
				case "Whatsapp":
					this.whatsappService.onScroll();
					break;
				case "webchat":
				case "Webchat":
					this.webchatService.onScroll();
					break;
				case "youtube":
				case "Youtube":
					this.youtubeService.onScroll();
					break;
				case "instagram":
				case "Instagram":
					this.instagramService.onScroll();
					break;
				case "instadm":
				case "instaDm":
				case "Instadm":
				case "InstaDm":
					this.instadmService.onScroll();
					break;
				default:
					break;
			}
		}
	}

	post(data: any) {
		let url: string = null;
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					url = 'tweet-send'
					this.tweetService.post(data, url);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					url = 'tweetdm-send'
					this.tweetdmService.post(data, url);
					break;
				case "messenger":
				case "Messenger":
					url = 'messenger-send';
					this.messengerService.post(data, url);
					break;
				case "whatsapp":
				case "Whatsapp":
					url = 'whatsapp-send';
					this.whatsappService.post(data, url);
					break;
				case "webchat":
				case "Webchat":
					url = 'webchat-send';
					this.webchatService.post(data, url);
					break;
				case "youtube":
				case "Youtube":
					url = 'youtube-send';
					this.youtubeService.post(data, url);
					break;
				case "instagram":
				case "Instagram":
					url = 'instagram-send';
					this.instagramService.post(data, url);
					break;
				case "instadm":
				case "instaDm":
				case "Instadm":
				case "InstaDm":
					url = 'instadm-send';
					this.instadmService.post(data, url);
					break;
				default:
					break;
			}
		}
	}

	getLiveMessages() {
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		let viewScreen = null;
		this.viewScreen.subscribe(data => viewScreen = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					if (this.userService.permissionMatch(['manage twitter'])) {
						this.tweetService.getAllLiveItems();
					}
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					if (this.userService.permissionMatch(['manage twitterdm'])) {
						this.tweetdmService.getAllLiveItems();
					}
					break;
				case "messenger":
				case "Messenger":
					if (this.userService.permissionMatch(['manage messenger'])) {
							this.messengerService.getAllLiveItems();
					}
					break;
				case "whatsapp":
				case "Whatsapp":
					if (this.userService.permissionMatch(['manage whatsapp'])) {
							this.whatsappService.getAllLiveItems();
					}
					break;
				case "webchat":
				case "Webchat":
					if (this.userService.permissionMatch(['manage webchat'])) {
							this.webchatService.getAllLiveItems();
					}
					break;
				case "youtube":
				case "Youtube":
					if (this.userService.permissionMatch(['manage youtube'])) {
							this.youtubeService.getAllLiveItems();
					}
					break;
				case "instagram":
				case "Instagram":
					if (this.userService.permissionMatch(['manage instagram'])) {
							this.instagramService.getAllLiveItems();
					}
					break;
				case "instadm":
				case "instaDm":
				case "Instadm":
				case "InstaDm":
					if (this.userService.permissionMatch(['manage instadm'])) {
							this.instadmService.getAllLiveItems();
					}
					break;
				default:
					break;
			}
		}
	}

}
