import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ListComponent } from './../list/list.component';
import { MatSidenav } from '@angular/material/sidenav';

import {
	User, UserService,
	TemplateMessageService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-template-main',
	templateUrl: './mainTemplate.component.html',
	styleUrls: ['./mainTemplate.component.scss']
})

export class MainTemplateComponent implements OnInit, OnDestroy {

	@ViewChild('rightSideBar') rightSideBar: MatSidenav;
	@ViewChild(ListComponent) listComponent;

	url: string;
	user: User;
	params: any;
	selectedTemplate: any = null;
	clientId: number = Number(this.route.snapshot.paramMap.get('clientId'));
	clientSiteId: number = Number(this.route.snapshot.paramMap.get('clientSiteId'));

	constructor(public userService: UserService,
		public templateMessageService: TemplateMessageService,
		private router: Router,
		public location: Location,
		private route: ActivatedRoute,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.params = data);
		this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => this.selectedTemplate = data);
	}

	ngOnInit() {
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	toggleRightSidebar() {
		this.rightSideBar.toggle();
	}

	/** Add / Update Template Message */
	newTemplate(): void {
		this.router.navigateByUrl('/app/template/add');
	}

	dataInit() {
		this.params.client_id = this.clientId;
		if(this.clientSiteId) {
			this.params.client_site_id = this.clientSiteId;
		} else {
			this.params.client_site_id = null;
		}
		this.templateMessageService.getAllItems();
	}
}
