import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { UserService, User } from 'src/app/@tji/_dbShare/user';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class ProductCartService {
  private _unsubscribeAll: Subject<any>;
  
  constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		public userService: UserService,
		private snackBar: MatSnackBar) { 
    this._unsubscribeAll = new Subject();
    }
  // cartPanel: boolean = false;
  cartClientPanel: boolean = false;
  cartSummaryData: boolean = false;

  public cartDataSource = new BehaviorSubject<boolean>(null);
  cartPanel = this.cartDataSource.asObservable();

  public summaryData = new BehaviorSubject<any>('');
  summaryCartDetails = this.summaryData.asObservable();

  public cartData = new BehaviorSubject<any[]>([]);
  cartDetails = this.cartData.asObservable();

  unSubscribe() {
    // console.log('UnSubscribed ProductService');
  }
}
