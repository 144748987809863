import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from 'src/app/app-share.module';

import { TemplateGroupRoutingModule } from './template-group-route.module';

import { MainComponent } from './main/main.component';
import { TemplateUsageComponent } from './template-usage/template-usage.component';
import { TemplateSuccessComponent } from './template-success/template-success.component';
import { TemplateFailedComponent } from './template-failed/template-failed.component';
import { TemplateExportExcelComponent } from './template-export-excel/template-export-excel.component'
import { TemplateViewComponent } from './template-view/template-view.component';
import { FaliedReasonComponent } from './falied-reason/falied-reason.component';
import { ExportTemplateComponent } from './export-template/export-template.component';
import { TempUsageSidebarComponent } from './template-usage-sidebar/template-usage-sidebar.component';


@NgModule({
    declarations: [
        MainComponent,
        TemplateUsageComponent,
        TemplateSuccessComponent,
        TemplateFailedComponent,
        TemplateExportExcelComponent,
        TemplateViewComponent,
        FaliedReasonComponent,
        ExportTemplateComponent,
        TempUsageSidebarComponent
    ],
    imports: [
        CommonModule,
        AppShareModule,
        TemplateGroupRoutingModule,
    ]
})
export class TemplateGroupModule {  

}
