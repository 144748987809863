import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	MessengerTicketService, MessengerTicket
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'messenger-ticket-detail',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss']
})

export class DetailComponent implements OnInit, OnDestroy {
	user: User;
	ticket: MessengerTicket;

	constructor(public userService: UserService,
		public messengerTicketService: MessengerTicketService,
		private router: Router,
		public location: Location,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.messengerTicketService.item.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

}
