export class RegionModel {
    _id: string;
    id: number;
    name_en: string;
    name_ar: string;
    country_id: number;
    fips_code: number;
    iso2: string;
    latitude: string;
    longitude: string;

    /**
     * Constructor
     *
     * @param region
     */
    constructor(region) {
        this._id = region._id || '';
        this.id = region.id || '';
        this.name_en = region.name_en || '';
        this.name_ar = region.name_ar || '';
        this.country_id = region.country_id || '';
        this.fips_code = region.fips_code || '';
        this.iso2 = region.iso2 || '';
        this.latitude = region.latitude || '';
        this.longitude = region.longitude || '';
    }
}