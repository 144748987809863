import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let $: any;
import { environment } from 'src/environments/environment';
import {
  PremiumDashboardService, PremiumDashboard, PremiumAlert,
  ClientAgent, EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-label-count',
  templateUrl: './label-count.component.html',
  styleUrls: ['./label-count.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})

export class LabelCountComponent {

  labelCount: any;
  dashboardParam: any;
  base_image: string = 'assets/img/no-user.jpg';
  channel: any;
  eventData: any;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplaySpeed: 800,
    navSpeed: 700,
    navText: [],
    responsive: {
      0: { items: 1 },
      360: { items: 1 },
      450: { items: 1 },
      740: { items: 2 },
      940: { items: 3 },
      1200: { items: 3 }
    },
    nav: false
  }

  constructor(private router: Router,
    private eventService: EventService,
    public premiumDashboardService: PremiumDashboardService) {
    this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
    this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
    this.premiumDashboardService.labelCount.pipe(untilDestroyed(this)).subscribe(data => this.labelCount = data);
    // this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
    //   this.eventData = data;
    //   this.checkEventData();
    // });
  }

  ngOnInit() { }

  // checkEventData() {
  //   let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
  //   if (eventName) {
  //     let eventData = this.eventData;
  //     let data = this.eventData.data;
  //     switch (eventName) {
  //       case 'logout': case 'Logout':
  //         this.premiumDashboardService.changeAiAgentActive(data, "logout");
  //         break;
  //       case 'login': case 'Login':
  //         this.premiumDashboardService.changeAiAgentActive(data, "login");
  //         break;
  //       case 'paymentCreate':
  //         this.premiumDashboardService.changeAcicollectionCount(data, 'paymentCreate');
  //         break;
  //       case 'paymentUpdate':
  //         this.premiumDashboardService.changeAcicollectionCount(data, 'paymentUpdate');
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }
}
