export class ProductCatalogModel {
    _id: number;
    client_site_id: number;
    catalog_id: number;
    type: any;
    name: string;
    product_content_id?: Array<any>;
    status: any;
    client_id: number;
    created_by: number;
    created_at: number;

    /**
 * Constructor
 *
 * @param productCatalog
 */
    constructor(productCatalog) {
        this._id = productCatalog._id || '';
        this.client_site_id = productCatalog.client_site_id || '';
        this.catalog_id = productCatalog.catalog_id || '';
        this.type = productCatalog.type || '';
        this.name = productCatalog.name || '';
        this.product_content_id = productCatalog.product_content_id || new productContentIdObjectModel({});
        this.status = productCatalog.status || '';
        this.client_id = productCatalog.client_id || '';
        this.created_by = productCatalog.created_by || '';
        this.created_at = productCatalog.created_at || '';
    }

}


export class productContentIdObjectModel {
    title: string;
    product_items: any;

    /**
     * Constructor
     *
     * @param productContentId
     */
    constructor(productContentId) {
        this.title = productContentId.title || '';
        this.product_items = productContentId.product_items || new productItemsModel({});
    }
}

export class productItemsModel {
    product_retailer_id: any;

    /**
     * Constructor
     *
     * @param productItems
     */
    constructor(productItems) {
        this.product_retailer_id = productItems.product_retailer_id || '';
    }
}