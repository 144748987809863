import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { ConsumerGroup } from './consumerGroup.interface';
import { ConsumerGroupModel } from './consumerGroup_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';


declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ConsumerGroupService implements Resolve<any> {
	url: string = 'consumer-group';
	routeParams: any;
	defaultParams: any = {
		'limit': 200,
		'current': 1,
		'sort': 'created_at|desc'
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<ConsumerGroup[]>([]);
	allItems = this.allItemsSource.asObservable();

	private allParentItemsSource = new BehaviorSubject<ConsumerGroup[]>([]);
	allParentItems = this.allParentItemsSource.asObservable();

	private itemSource = new BehaviorSubject<ConsumerGroup>(new ConsumerGroupModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<ConsumerGroup[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ConsumerGroupService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on ConsumerGroupService');
	}

	concatlibrary(allItems: ConsumerGroup[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: ConsumerGroup) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: ConsumerGroup) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: ConsumerGroup[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: ConsumerGroup[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeAllParentItems(allParentItems: ConsumerGroup[]) {
		this.concatlibrary(allParentItems);
		this.allParentItemsSource.next(allParentItems);
	}

	changeItem(item) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: ConsumerGroup[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);

		let allParentItems = [];
		this.allParentItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allParentItems = data);
		if (allParentItems && allParentItems.length > 0) {
			for (var i = 0; i < allParentItems.length; ++i) {
				if (allParentItems[i].id === item.id) { allParentItems.splice(i, 1, item); }
			}
		}
		this.changeAllParentItems(allParentItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 200,
			'current': 1,
			'sort': 'created_at|desc'
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('consumer', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var dataFormat = [];
					var consumerData = data.data;
					var additionalData = data.additional;
					consumerData.forEach(consumer => {
						var dataFormats = {
							"id": consumer.id,
							"parent_id": consumer.parent_id,
							"client_id": consumer.client_id,
							"name": consumer.name,
							"description": consumer.description,
							"created_by": consumer.created_by,
							"updated_by": consumer.updated_by,
							"created_at": consumer.created_at,
							"updated_at": consumer.updated_at,
							"deleted_at": consumer.deleted_at,
						}
						additionalData.parnentInfo.forEach(parnentInfo => {
							if (consumer.parent_id === parnentInfo.id) {
								dataFormats["parentName"] = parnentInfo.name;
							}
						});
						if (consumer && consumer.consumerInfo && consumer.consumerInfo.totalContacts) {
							dataFormats["totalContacts"] = consumer.consumerInfo.totalContacts;
						}
						dataFormat.push(dataFormats);
					});

					this.changeAllItems(dataFormat);
					this.changeTotalItem(data.pagnitation.totalResult);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllParentItems(params: any = null) {
		params = this.paramsInit(params);
		params.null = 'parent_id|true';
		params.all = 1;
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (params.all && params.all === 1) {
					this.changeAllParentItems(data.data);
				} else {
					this.changeAllParentItems(data.data.data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: ConsumerGroup) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id == id) { allItems.splice(i, 1); }
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) { //Itrsbot/viewMore
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			var formData = {
				id: this.routeParams.id,
			}
			this.commonService.storeItem('consumer/view', formData, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success) {
						var dataFormat = {};
						var dataFormats = {};
						dataFormats["childrens"] = [];
						var consumerData = data.data;
						consumerData.forEach(consumer => {
							if (this.routeParams.id == consumer.id) {
								dataFormats["id"] = consumer.id;
								dataFormats["parent_id"] = consumer.parent_id ? consumer.parent_id : null;
								dataFormats["client_id"] = consumer.client_id;
								dataFormats["name"] = consumer.name;
								dataFormats["description"] = consumer.description;
								dataFormats["updated_by"] = consumer.updated_by;
								dataFormats["created_at"] = consumer.created_by;
								dataFormats["updated_at"] = consumer.updated_at;
								dataFormats["deleted_at"] = consumer.deleted_at ? consumer.deleted_at : null;
								dataFormats["createdByName"] = consumer.createdByName;
								dataFormats["updatedByName"] = consumer.updatedByName;
								dataFormats["parentName"] = consumer.parentName ? consumer.parentName : null;
								dataFormats["totalContacts"] = consumer.consumerInfo ? consumer.consumerInfo.totalContacts : 0;
								if (consumer.consumerInfo) {
									dataFormats['consumer'] = {
										"id": consumer.id,
										"consumer_group_id": consumer.consumerInfo.consumer_group_id,
										"client_id": consumer.consumerInfo.client_id,
										"json": JSON.stringify(consumer.consumerInfo.json),
										"created_by": consumer.consumerInfo.created_by,
										"updated_by": consumer.consumerInfo.updated_by,
										"created_at": consumer.consumerInfo.created_at,
										"updated_at": consumer.consumerInfo.updated_at,
										"deleted_at": consumer.consumerInfo ? consumer.consumerInfo.deleted_at : null,
										"createdByName": consumer.consumerInfo.createdByName,
										"updatedByName": consumer.consumerInfo.updatedByName,
										"jsonArray": consumer.consumerInfo.json
									}
								}
								dataFormat = dataFormats;
							}
							
						});
						consumerData.forEach(consumer => {
							if (this.routeParams.id != consumer.id) {
								var childrens = {};
								childrens["id"] = consumer.id;
								childrens["parent_id"] = consumer.parent_id ? consumer.parent_id : null;
								childrens["client_id"] = consumer.client_id;
								childrens["name"] = consumer.name;
								childrens["description"] = consumer.description;
								childrens["updated_by"] = consumer.updated_by;
								childrens["created_at"] = consumer.created_by;
								childrens["updated_at"] = consumer.updated_at;
								childrens["deleted_at"] = consumer.deleted_at ? consumer.deleted_at : null;
								childrens["createdByName"] = consumer.createdByName;
								childrens["updatedByName"] = consumer.updatedByName;
								childrens["parentName"] = consumer.parentName ? consumer.parentName : null;
								childrens["totalContacts"] = consumer.consumerInfo ? consumer.consumerInfo.totalContacts : 0;
								if (consumer.consumerInfo) {
									childrens['consumer'] = {
										"id": consumer.id,
										"consumer_group_id": consumer.consumerInfo.consumer_group_id,
										"client_id": consumer.consumerInfo.client_id,
										"json": JSON.stringify(consumer.consumerInfo.json),
										"created_by": consumer.consumerInfo.created_by,
										"updated_by": consumer.consumerInfo.updated_by,
										"created_at": consumer.consumerInfo.created_at,
										"updated_at": consumer.consumerInfo.updated_at,
										"deleted_at": consumer.consumerInfo ? consumer.consumerInfo.deleted_at : null,
										"createdByName": consumer.createdByName,
										"updatedByName": consumer.updatedByName,
										"jsonArray": consumer.consumerInfo.json
									}
								}
								dataFormats["childrens"].push(childrens);
							}
						});
						this.changeAllItemsByItem(dataFormat);
						this.changeItem(dataFormat);
					} else {
						this.alertService.webErrorShow(data);
					}
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ConsumerGroupModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(url, formData, item = null) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (item && item.parent_id) {
						delete item.parent_id;
						delete item.parentName;
						this.changeAllItemsByItem(item);
						this.changeItem(item)
					} else {
						this.spliceItem(formData.id);
					}
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	consumer(url, formData: any) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.concatItem(data.data);
					this.changeItem(data.data);
					this.alert('Success', data.message);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	consumerUpdate(url, formData: any, item: any) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					item.name = formData.name;
					item.description = formData.description;
					this.changeAllItemsByItem(item);
					this.changeItem(item);
					this.alert('Success', data.message);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}
	consumerGroupUpdate(url, formData: any, item: any) {
		this.commonService.storeItem(url, formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				var lists = []
				if (data.success) {
					this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
						var datas = result.filter(item => item.id == formData.parent_id);
						item.parent_id = datas[0]['id'];
						item.parentName = datas[0]['name'];
						lists = result.concat(data.data);
					});

					this.changeAllItemsByItem(item);
					this.changeItem(item);
					this.alert('Success', data.message);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}
}