export class CommentModel {
    _id: number;
    id: number;
    resource_id: number;
    resource_type: string
    comment_id: number;
    client_id: number;
    client_site_id: number;
    title: string;
    description: string;
    rating: number;
    person_id: number;
    person_type: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: any;

    tableName: string;
    className: string;
    createdByName?: string;
    updatedByName?: string;
    createdByAvatar?: string;
    resource?: any;
    siteName?: string;
    ticketCode?: string;
    isClosed?: boolean;
    peopleName?: string;
    peopleAvatar?: string;
    peopleContactRef?: string;
    childrenCount?: number;
    ReplyInfo: any;
    additional: any[];
    Resource_info: any;
    agnet_info: any;
    client_info: any;
    name: any;
    resourceInfo: any;
    deleted_at: any;
    user_id: number;


    /**
     * Constructor
     *
     * @param comment
     */
    constructor(comment) {
            this._id = comment.id || '';
            this.id = comment.id || '';
            this.resource_id = comment.resource_id || '';
            this.resource_type = comment.resource_type || '';
            this.comment_id = comment.comment_id || '';
            this.client_id = comment.client_id || '';
            this.client_site_id = comment.client_site_id || '';
            this.title = comment.title || '';
            this.description = comment.description || '';
            this.rating = comment.rating || '';
            this.person_id = comment.person_id || '';
            this.person_type = comment.person_type || '';
            this.created_by = comment.created_by || '';
            this.updated_by = comment.updated_by || '';
            this.created_at = comment.created_at || '';
            this.updated_at = comment.updated_at || '';

            this.tableName = comment.tableName || '';
            this.ReplyInfo = comment.ReplyInfo || '';
            this.additional = comment.additional || '';
            this.agnet_info = comment.agnet_info || '';
            this.client_info = comment.client_info || '';
            this.name = comment.name || '';
            this.resourceInfo = comment.resourceInfo || '';
            this.deleted_at = comment.deleted_at || '';
            this.user_id = comment.user_id || '';
    }
}
