import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	PersonSettingService, PersonSetting,
	RClientService,
	ClientService,
} from 'src/app/@tji/_dbShare';

import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'filter-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent {
	@Output() onClose = new EventEmitter();
	isAuthReseller: boolean = (this.userService.isAuthUserIsReseller()) ? true : false;
	param: any;
	clientSiteList: any;
	selectedClientSite: any;
	isActiveData: boolean = false;
	isDeletedData: boolean = false;

	myControl = new FormControl<string | any>('');
	filteredOptions: Observable<any[]>;


	constructor(private _formBuilder: UntypedFormBuilder,
		public personSettingService: PersonSettingService,
		public userService: UserService,
		public rClientService: RClientService,
		public clientService: ClientService,
		private router: Router,
		public matDialog: MatDialog) {
		this.rClientService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
		this.clientSiteList = data
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.clientSiteList.slice();
			}),
		);
		});
	}

	ngOnInit() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.clientSiteList.slice();
			}),
		);
	}
	
	displayFn(user: any): string {
		return user && user.name ? user.name : '';
	}

	_filter(name: string): any[] {
		const filterValue = name.toLowerCase();

		return this.clientSiteList.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	/** On destroy */
	ngOnDestroy(): void {
	}

	checkHidden(list): boolean {
		if (this.isAuthReseller) {
			return list.resellerAccess;
		} else {
			return list.clientAccess;
		}
	}

	selectClient(data: any) {
		if (data == null) {
			delete this.param.client_id;
			this.rClientService.getAllItems();
		} else {
			this.param["client_id"] = data.option.value.id;
			this.rClientService.getAllItems();
		}
	}

	isActive(data) {
		if (data.checked == true) {
			this.param["is_active"] = 1;
			this.rClientService.getAllItems();
		} else {
			delete this.param.is_active;
			this.rClientService.getAllItems();
		}
	}

	isDeleted(data) {
		if (data.checked == true) {
			this.param["deleted_at"] = 'yes';
			this.rClientService.getAllItems();
		} else {
			delete this.param.deleted_at;
			this.rClientService.getAllItems();
		}
	}

	reset() {
		delete this.param.deleted_at;
		delete this.param.is_active;
		delete this.param.client_id;
		this.rClientService.getAllItems();
		this.isActiveData = false;
		this.isDeletedData = false;
		delete this.selectedClientSite;
		this.myControl.setValue('');
	}
}
