export class TagModel {
    id: number;
    name: string;
    slug: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName: string;

    /**
     * Constructor
     *
     * @param tag
     */
    constructor(tag) {
            this.id = tag.id || '';
            this.name = tag.name || '';
            this.slug = tag.slug || '';
            this.created_by = tag.created_by || '';
            this.updated_by = tag.updated_by || '';
            this.created_at = tag.created_at || '';
            this.updated_at = tag.updated_at || '';

            this.tableName = tag.tableName || '';
    }
}
