export class IntractiveCommandModel {
	id: number;
	intractive_id: number;
	client_id: number;
	client_site_id: number;
	resource_id: number;
	resource_type: string;
	command: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	client?: any;
	clientSite?: any;
	intractive?: any;
	resource?: any;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param intractive_command
	 */
	constructor(intractive_command) {
		this.id = intractive_command.id || '';
		this.intractive_id = intractive_command.intractive_id || '';
		this.client_id = intractive_command.client_id || '';
		this.client_site_id = intractive_command.client_site_id || '';
		this.resource_id = intractive_command.resource_id || '';
		this.resource_type = intractive_command.resource_type || '';
		this.command = intractive_command.command || '';
		this.created_by = intractive_command.created_by || '';
		this.updated_by = intractive_command.updated_by || '';
		this.created_at = intractive_command.created_at || '';
		this.updated_at = intractive_command.updated_at || '';

		this.tableName = intractive_command.tableName || '';
	}
}
