import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import * as color from 'd3-color';
import {
	History, HistoryService,ticketHistory,
	User, UserService,DashboardService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'dashboard-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit, OnDestroy {
	authUser: User;
	histories: ticketHistory[] = [];

	constructor(public userService: UserService,
		public historyService: HistoryService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		// this.historyService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.histories = data);
		this.historyService.ticketHistoryList.pipe(untilDestroyed(this)).subscribe(response=>{this.histories = response['data'];
		})
	}

	ngOnInit() {
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.historyService.unSubscribe();
	}

	getHistories() {
		// this.historyService.getAllItems();
		// this.historyService.loadTicketHistory()
	}

	getTitle(history): string {
		if (history && history.model_type) {
			switch (history.model_type) {
				case "tweet_ticket":
					return "On Twitter";
					break;
				case "tweetdm_ticket":
					return "On Twitter DM";
					break;
				case "messenger_ticket":
					return "On Messenger";
					break;
				case "whatsapp_ticket":
					return "On Whatsapp";
					break;
				case "webchat_ticket":
					return "On Webchat";
					break;
				case "youtube_ticket":
					return "On Youtube";
					break;
				case "instagram_ticket":
					return "On Instagram";
					break;
				case "instadm_ticket":
					return "On Instadm";
					break;
				case "tweet":
					return "On Twitter";
					break;
				case "tweet":
					return "On Twitter DM";
					break;
				case "messenger":
					return "On Messenger";
					break;
				case "whatsapp":
					return "On Whatsapp";
					break;
				case "webchat":
					return "On Webchat";
					break;
				case "youtube":
					return "On Youtube";
					break;
				case "instagram":
					return "On Instagram";
					break;
				case "instadm":
					return "On Instadm";
					break;
				default:
					return "By Admin";
					break;
			}
		}
	}
}
