import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { StoreAsCombinedFormComponent } from './../storeAsCombinedForm/storeAsCombinedForm.component';
import { StoreAsIntractiveFormComponent } from './../storeAsIntractiveForm/storeAsIntractiveForm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChooseIntractiveFormComponent } from './../chooseIntractiveForm/chooseIntractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { TicketableIntractiveFormComponent } from './../ticketableIntractiveForm/ticketableIntractiveForm.component';
import { ShortCodeFormComponent } from './../shortCodeForm/shortCodeForm.component';
import { LinkTemplateFormComponent } from './../linkTemplateForm/linkTemplateForm.component';
import { LinkIntractiveMessageFormComponent } from './../linkIntractiveMessageForm/linkIntractiveMessageForm.component';
import { MapTeamFormComponent } from './../mapTeamForm/mapTeamForm.component';
import { MapAgentFormComponent } from './../mapAgentForm/mapAgentForm.component';

import {
  User, UserService,
  ItrsbotService, Itrsbot,
  IntractiveService, Intractive, IntractiveModel,
  IntractiveGroupService, IntractiveGroup, IntractiveGroupModel,
  ModalIntractiveGroupService, ModalIntractiveGroup,
  IntractiveCommandService,
  ConsumerGroupService
} from 'src/app/@tji/_dbShare';
import { ChooseConsumerGroupFormComponent } from '../chooseConsumerGroupForm/chooseConsumerGroupForm.component';

@UntilDestroy()
@Component({
  selector: 'itrsbot-template-message',
  templateUrl: './templateMessage.component.html',
  styleUrls: ['./templateMessage.component.scss']
})

export class TemplateMessageComponent implements OnInit, OnDestroy {
  @Input() intractive: Intractive;
  authUser: User;
  itrsbot: Itrsbot;
	consumerGroupParam: any;

  constructor(public userService: UserService,
    public itrsbotService: ItrsbotService,
    public intractiveService: IntractiveService,
    public intractiveGroupService: IntractiveGroupService,
    public intractiveCommandService: IntractiveCommandService,
    public modalIntractiveGroupService: ModalIntractiveGroupService,
		public consumerGroupService: ConsumerGroupService,
    private router: Router,
    public matDialog: MatDialog) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.consumerGroupService.params.pipe(untilDestroyed(this)).subscribe(data => this.consumerGroupParam = data);

  }

  ngOnInit() { }

  /** On destroy */
  ngOnDestroy(): void { 
    this.intractiveService.changeItem(null);
  }

  /** Add / Update Tag */
  newIntractiveGroup(intractiveGroup = null): void {
    this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(SimpleFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
        isChoiceMsg: this.isItrsTemplateMessage(this.intractive),
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
      });
  }

  /** Delete Intractive */
  deleteIntractiveGroup(intractiveGroup): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete this message?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var formData = {
					id : intractiveGroup.id,
				}
				this.intractiveGroupService.destroy('interactiveGroup/delete', formData, intractiveGroup);
      }
    });
  }

  /** Add / Update Intractive */
  newIntractive(intractive = null): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(IntractiveFormComponent, {
      width: "600px",
      data: {
        action: (intractive) ? 'edit' : 'new',
        item: intractive,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
        switch (actionType) {
          case 'new':
            this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
            break;
          case 'update':
            formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
            break;
        }
      });
  }

  /** Delete Intractive */
  deleteIntractive(intractive): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete this template?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var formData ={
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete',formData);
        this.intractiveService.removelibraryById(intractive.id);
        setTimeout(() => {
          this.intractiveService.changeItem(null);
        }, 2000);
      }
    });
  }

  isItrsTemplateMessage(intractive: Intractive): boolean {
    let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
    return (slug && slug === 'template-message') ? true : false;
  }

  getOptions(group: IntractiveGroup) {
    return (group && group.json) ? JSON.parse(group.json) : null;
  }

  /** Add / Update Change Intractive */
  linkIntractiveGroup(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(ChooseIntractiveFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formDatas: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
					case 'new':
						var formData = {
							id: id,
							key: "next_intractive_id",
							value: formDatas.value.next_intractive_id,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
					case 'update':
						var formData = {
							id: id,
							key: "next_intractive_id",
							value: formDatas.value.next_intractive_id,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
				}
      });
  }

  /** Add / Change IntractiveGroup Images */
  imageIntractiveGroup(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(ChangeIntractiveGroupImageComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
      });
  }

  /** Add / Update Change Intractive */
  markIntractiveGroup(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(TicketableIntractiveFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formDatas: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
					case 'new':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
					case 'update':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
				}
      });
  }

	/** setPrimaryIntractive Intractive */
	setPrimaryIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to set this Ticket Survey as Primary Survey ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.intractiveService.removelibraryById(intractive.id);
				var formData = {
					"id": intractive.id,
					"key": "is_begin",
					"value": 1,
					"itrsbot_id": intractive.itrsbot_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
			}
		});
	}

	/** removePrimaryIntractive Intractive */
	removePrimaryIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to remove this Ticket Survey as Primary Survey ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.intractiveService.removelibraryById(intractive.id);
				var formData = {
					"id": intractive.id,
					"key": "is_begin",
					"value": 0,
					"itrsbot_id": intractive.itrsbot_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
			}
		});
	}

  /** Add / Update Intractive */
  newShortCode(intractive): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(ShortCodeFormComponent, {
      width: "600px",
      data: {
        action: (intractive) ? 'edit' : 'new',
        item: intractive,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        this.intractiveCommandService.addShortCode('Intractive/addshortcode', formData.value);
        this.intractive.shortCode = formData.value['command'];
      });
  }

  /** Add / Update Intractive */
  newTemplate(intractive): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(LinkTemplateFormComponent, {
      width: "600px",
      data: {
        action: (intractive) ? 'edit' : 'new',
        item: intractive,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        this.intractiveService.update(intractive.id, formData.value);
        this.intractive.template_id = formData.value['template_id'];
      });
  }

  getStoreAs(intractiveGroup): string {
    let json = '';
    if (intractiveGroup && intractiveGroup.json) {
      let jsonData = JSON.parse(intractiveGroup.json);
      if (jsonData && jsonData.store_as) {
        json = jsonData.store_as;
      }
    }
    return json;
  }

  /** Add / Update IntractiveGroup Json */
  updateIntractiveJson(intractive = null): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(StoreAsIntractiveFormComponent, {
      width: "600px",
      data: {
        action: 'edit',
        item: intractive
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        formData.value['intractive_id'] = id;
				this.intractiveService.updateStoreAs(formData.value);
        setTimeout(() => {
          this.intractiveService.getIntractiveItem({ id: intractive.id });
        }, 1500);
      });
  }

  /** Add / Update IntractiveGroup Json */
  updateIntractiveGroupJson(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(StoreAsCombinedFormComponent, {
      width: "600px",
      data: {
        action: 'edit',
        item: intractiveGroup
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        formData.value["intractive_group_id"] = id;
				this.intractiveGroupService.updateSaveAsGroup(formData.value);
      });
  }

  getStoreValue(intractiveGroup): string {
    let json = '';
    if (intractiveGroup && intractiveGroup.json) {
      let jsonData = JSON.parse(intractiveGroup.json);
      if (jsonData && jsonData.store_value) {
        json = jsonData.store_value;
      }
    }
    return json;
  }

  hasStoreAsValue(intractiveGroup): boolean {
    if (intractiveGroup && intractiveGroup.json) {
      let jsonData = JSON.parse(intractiveGroup.json);
      if (jsonData && jsonData.store_as) {
        return true;
      }
    }
    return false;
  }

  /** Add / Update Intractive With Intractive Message Id */
  updateIntractiveWithIntractiveMessageId(intractive = null): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(LinkIntractiveMessageFormComponent, {
      width: "600px",
      data: {
        action: (intractive && intractive.intractive_message_id) ? 'edit' : 'new',
        item: intractive
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formDatas: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        const intractiveId: number = (intractive && intractive.id) ? intractive.id : id;
        var formData = {
					"id": intractiveId,
					"key": "intractive_message_id",
					"value": response[1].value.intractive_message_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
        // setTimeout(() => {
        //   this.intractiveService.getIntractiveItem({ id: intractiveId });
        // }, 1500);
      });
  }

  removeIntractiveMessageId(intractive: Intractive) {
    var formData = {
			"id": intractive.id,
			"key": "intractive_message_id",
			"value": null
		}
		this.intractiveService.updateData('Intractive/options', formData);
    // this.intractiveService.update(intractive.id, { 'intractive_message_id': null });
    // setTimeout(() => {
    //   this.intractiveService.getIntractiveItem({ id: intractive.id });
    // }, 1500);
  }

  checkGroupAgents(intractiveGroup: IntractiveGroup): boolean {
    return (this.getGroupAgents(intractiveGroup) && this.getGroupAgents(intractiveGroup).length > 0) ? true : false;
  }

  getGroupAgents(intractiveGroup: IntractiveGroup): Array<any> {
    let agentGroups = [];
    if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups
      && this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
      agentGroups = this.intractive.intractive_groups_through_modal_intractive_groups.filter(x => {
        return (x.resource_type === 'agent' && x.intractive_group_id === intractiveGroup.id) ? true : false;
      });
    }
    return agentGroups;
  }

  mapAgent(intractiveGroup: IntractiveGroup) {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups &&
      this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
      this.modalIntractiveGroupService.changeAllItems(this.intractive.intractive_groups_through_modal_intractive_groups);
    }

    let dialogRef = this.matDialog.open(MapAgentFormComponent, {
      width: "600px",
      data: {
        action: 'new',
        item: intractiveGroup
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        this.modalIntractiveGroupService.newAgent('interactiveGroup/modal-intractive-group',formData.value, intractiveGroup);
      });
  }

  removeGroupAgent(intractiveGroup: IntractiveGroup, groupAgent: ModalIntractiveGroup) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to Remove this Agent?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        // this.modalIntractiveGroupService.destroy(groupAgent.id);
        // setTimeout(() => {
        //   this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
        // }, 1500);
      }
    });
  }


  checkGroupTeams(intractiveGroup: IntractiveGroup): boolean {
    return (this.getGroupTeams(intractiveGroup) && this.getGroupTeams(intractiveGroup).length > 0) ? true : false;
  }

  getGroupTeams(intractiveGroup: IntractiveGroup): Array<any> {
    let teamGroups = [];
    if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups
      && this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
      teamGroups = this.intractive.intractive_groups_through_modal_intractive_groups.filter(x => {
        return (x.resource_type === 'team' && x.intractive_group_id === intractiveGroup.id) ? true : false;
      });
    }
    return teamGroups;
  }

  mapTeam(intractiveGroup: IntractiveGroup) {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups &&
      this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
      this.modalIntractiveGroupService.changeAllItems(this.intractive.intractive_groups_through_modal_intractive_groups);
    }

    let dialogRef = this.matDialog.open(MapTeamFormComponent, {
      width: "600px",
      data: {
        action: 'new',
        item: intractiveGroup
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				this.modalIntractiveGroupService.newTems('interactiveGroup/modal-intractive-group',formData.value,intractiveGroup);
        // setTimeout(() => {
        //   this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
        // }, 1500);
      });
  }

  removeGroupTeam(intractiveGroup: IntractiveGroup, groupTeam: ModalIntractiveGroup) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to Remove this Team?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var datas = {
					intractive_group_id: intractiveGroup.id,
					resource_id: groupTeam.resource_id
				}
				this.modalIntractiveGroupService.destroy('interactiveGroup/removeTeam',datas);
        setTimeout(() => {
          this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
        }, 1500);
      }
    });
  }

  removeNextIntractiveGroup(intractiveGroup: IntractiveGroup) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to Remove this ITRS?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var data = {
          next_intractive_id: null,
			    is_active: 1,
        }
        this.intractiveGroupService.update(intractiveGroup.id, data);
      }
    });
  }


/** Add / Update Change Consumer Group */
	linkConsumerGroup(intractiveGroup = null): void {
		this.consumerGroupParam.limit = 200;
		this.consumerGroupParam.current = 1;
		this.consumerGroupService.getAllItems();
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChooseConsumerGroupFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				var formData = {
					id: id,
					json_consumer_action: formDatas.value.json_consumer_action,
					key: 'consumer_group_id',
					value: formDatas.value.consumer_group_id,
					is_active: formDatas.value.is_active,
				}
				this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
			});
	}

	checkSubscribeBtn(intractive, index): boolean {
		let result: boolean = false;
		result = (index != intractive.intractive_groups.length - 1 &&
			index > 0 &&
			this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug != 'live-seperate-message') ? true : false;
		return result;
	}
  
  checkLinkIntractive(intractive): boolean {
		let result: boolean = false;
		result = (this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug != 'live-seperate-message') ? true : false;
		return result;
	}
  
}
