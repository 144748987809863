import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel,
	ConsumerService, Consumer, ConsumerModel, ClientSiteService,
	FbTemplateMessageService, FbTemplateMessage, ClientSite, AlertService,
	MediaService,
	EventService
} from 'src/app/@tji/_dbShare';

import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, TAB, SPACE } from '@angular/cdk/keycodes';
import { FbItrsbotComponent } from '../fbItrsbot/fbitrsbot.component';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { TemplateGalleryComponent } from '../../gallery/template-gallery/template-gallery.component';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

import * as moment from 'moment-timezone';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@UntilDestroy()
@Component({
	selector: 'fb-template-templateform',
	templateUrl: './templateForm.component.html',
	styleUrls: ['./templateForm.component.scss']
})

export class FbTemplateFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Choose Template & Send Bulk';
	authUser: User;
	template: FbTemplateMessage;
	action: string;
	templateForm: FormGroup;
	data: Array<any> = [];
	clientSites: ClientSite[] = [];
	isTemplateForm: boolean = false;

	contacts: Array<string> = [];
	selectable = true;
	removable = true;
	addOnBlur = true;
	cardLengthData: any = [];
	loader: boolean = true;
	isCarousel: boolean = false;
	readonly separatorKeysCodes = [TAB, COMMA] as const;
	mediaParam: any;
	eventData: any;

	constructor(public userService: UserService, private eventService: EventService,
		public fbTemplateMessageService: FbTemplateMessageService,
		public matDialogRef: MatDialogRef<FbTemplateFormComponent>,
		public matDialog: MatDialog,
		public mediaService: MediaService,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private alertService: AlertService,
		private _formBuilder: FormBuilder, public clientSiteService: ClientSiteService,) {
		this.getInit(_data);
		this.mediaService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.mediaParam = data;
		});
		this.fbTemplateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
			if (data.required_values && data.required_values.data) {
				if (data.required_values && data.required_values.data) {

					data.required_values.data.forEach(template => {
						if (template.type == "header" && template.parameters && template.parameters.length > 0) {
							if (template.parameters[0].type == 'image') {
								template.parameters[0].image.link = '';
							}
							if (template.parameters[0].type == 'video') {
								template.parameters[0].video.link = '';
							}
							if (template.parameters[0].type == 'document') {
								template.parameters[0].document.link = '';
								template.parameters[0].document.filename = '';
							}
						}

						if (template.type == 'body' && template.parameters && template.parameters.length > 0) {
							template.parameters.forEach((body, bodyIdx) => {
								body.text = '';
							});
						}

						if (template.type == "button" && template.sub_type == "url" && template.parameters && template.parameters.length > 0) {
							template.parameters.forEach((cardButton, cardButtonIdx) => {
								cardButton.text = '';
							});
						}

						if (template.type == "button" && template.sub_type == "quick_reply" && template.parameters && template.parameters.length > 0) {
							template.parameters.forEach((cardButton, cardButtonIdx) => {
								cardButton.payload = '';
							});
						}

						if (template.type == "button" && template.sub_type == "copy_code" && template.parameters && template.parameters.length > 0) {
							template.parameters.forEach((cardButton, cardButtonIdx) => {
								cardButton.coupon_code = '';
							});
						}

						if (template.type == 'CAROUSEL' && template.cards && template.cards.length > 0) {
							template.cards.forEach((cards, cardsIdx) => {
								cards.components.forEach((components, componentsIdx) => {
									if (components.type == "header" && components.parameters && components.parameters.length > 0) {
										if (components.parameters[0].type == 'image') {
											components.parameters[0].image.id = '';
										}
										if (components.parameters[0].type == 'video') {
											components.parameters[0].video.id = '';
										}
									}
									if (components.type == "body" && components.parameters && components.parameters.length > 0) {
										components.parameters.forEach((cardBody, cardBodyIdx) => {
											cardBody.text = '';
										});
									}
									if (components.type == "button" && components.sub_type == "url" && components.parameters && components.parameters.length > 0) {
										components.parameters.forEach((cardButton, cardButtonIdx) => {
											cardButton.text = '';
										});
									}
									if (components.type == "button" && components.sub_type == "quick_reply" && components.parameters && components.parameters.length > 0) {
										components.parameters.forEach((cardButton, cardButtonIdx) => {
											cardButton.payload = '';
										});
									}
								});

							});
						}
					});

				}

				this.data = data.required_values.data;
			}
			if (this.template && this.template.components && this.template.components.length > 0) {
				this.template.components.forEach(component => {
					if (component.type.toLowerCase() == 'carousel') {
						this.isCarousel = true;
					}
				});
			}
			this.template["templateComponent"] = {};
			if (this.template && this.template.components && this.template.components.length > 0) {
				this.template.components.forEach(component => {
					if (component.type.toLowerCase() == 'header') {
						this.template["templateComponent"]["header"] = component;
						this.template["templateComponent"]["header"]["format"] = component.format.toLowerCase();
					}
					if (component.type.toLowerCase() == 'body') {
						this.template["templateComponent"]["body"] = component;
						this.template["templateComponent"]["body"]["type"] = component.type.toLowerCase();
					}
					if (component.type.toLowerCase() == 'footer') {
						this.template["templateComponent"]["footer"] = component;
						this.template["templateComponent"]["footer"]["type"] = component.type.toLowerCase();
					}
					if (component.type.toLowerCase() == 'buttons') {
						this.template["templateComponent"]["buttons"] = component;
						this.template["templateComponent"]["buttons"]["type"] = component.type.toLowerCase();
					}
					if (component.type.toLowerCase() == 'carousel') {
						this.template["templateComponent"]["carousel"] = component;
						this.template["templateComponent"]["carousel"]["type"] = component.type.toLowerCase();
						if (component.cards && component.cards.length > 0) {
							var cardId = 0;
							component.cards.forEach(cards => {
								cardId = cardId + 1;
								var data = {
									item: cardId
								}
								this.cardLengthData.push(data);
								if (cards.components && cards.components.length > 0) {
									cards.components.forEach(carousel => {
										carousel["type"] = carousel.type.toLowerCase();
										if (carousel.format) {
											carousel["format"] = carousel.format.toLowerCase();
										}
									});
								}
							});
						}
					}
				});
			}
			this.templateForm = this.createForm();
		});
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data;
			this.clientSites = this.clientSites.filter(client => (client.site_id == 2 && client.service_provider == "option-7") || client.site_id == 2 && client.service_provider == "option-3")
			this.templateForm = this.createForm();
		});
		this.fbTemplateMessageService.getMediaIdData.pipe(untilDestroyed(this)).subscribe(data => {
			if (data['success']) {
				this.onChangeCaroselValue(data['data'].mediaId.id, data['data'].place, data['data'].identify, data['data'].idx);
			}
		});
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.template = null;
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Template & Send Bulk';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Template & Send Bulk';
				break;
			default:
				break;
		}
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 1000);
	}

	onChangeValue(value, place, idx, fileName: any = null) {
		this.data.forEach((required, indexed) => {
			if (required.type && required.type == place.type) {
				required.parameters.forEach((parameters, index) => {
					if (required.type == 'header' && parameters.type == 'document' && fileName != 'filename') {
						// var index = value.lastIndexOf("/") + 1;
						// var filename = value.substr(index);
						parameters.document.link = value;
					}
					if (required.type == 'header' && parameters.type == 'document' && fileName == 'filename') {
						if (fileName == 'filename') {
							parameters.document.filename = value;
						}
					}
					if (required.type == 'header' && parameters.type == 'image') {
						parameters.image.link = value;
					}
					if (required.type == 'header' && parameters.type == 'video') {
						parameters.video.link = value;
					}
					if (required.type == 'header' && parameters.type == 'text') {
						parameters.text = value;
					}
					if (required.type == 'button' && place.index == required.index && index == idx && parameters.type == 'payload') {
						parameters.payload = "temp_itrs_" + value;
					}
					if (required.type == 'button' && place.index == required.index && index == idx && parameters.type == 'coupon_code') {
						parameters.coupon_code = value;
					}
					if (required.type != 'header' && index == idx && parameters.type != 'payload' && parameters.type != 'coupon_code') {
						parameters.text = value;
					}
				});
			}
		});
	}

	/** Create form */
	createForm(): FormGroup {
		return this._formBuilder.group({
			template_id: [this.template.id || null, [Validators.required]],
			client_site_id: [null, [Validators.required]],
		});
	}

	sendTemplate() {
		if (this.contacts.length < 1) {
			this.alertService.webShow('Danger', 'Enter To Number');
			return;
		}
		var keepHeader = true;
		var keepBody = true;
		var keepButton = true;
		var keepCardHeader = true;
		var keepCardBody = true;
		var keepCardButton = true;

		if (this.data) {
			this.data.forEach(template => {

				if (template.type != 'CAROUSEL' && template.type == "header" && template.parameters && template.parameters.length > 0) {
					if (keepHeader) {
						if (template.parameters[0].type == 'image' && !template.parameters[0].image.link) {
							var msg = 'Enter header image url ';
							this.alertService.webShow('Danger', msg);
							keepHeader = false;
							return;
						}
						if (template.parameters[0].type == 'video' && !template.parameters[0].video.link) {
							var msg = 'Enter header video url ';
							this.alertService.webShow('Danger', msg);
							keepHeader = false;
							return;
						}
						if (template.parameters[0].type == 'document' && !template.parameters[0].document.link) {
							var msg = 'Enter header document url ';
							this.alertService.webShow('Danger', msg);
							keepHeader = false;
							return;
						}
						if (template.parameters[0].type == 'document' && !template.parameters[0].document.filename) {
							var msg = 'Enter header Document Name ';
							this.alertService.webShow('Danger', msg);
							keepHeader = false;
							return;
						}
					}
				}

				if (template.type == 'body' && template.parameters && template.parameters.length > 0) {
					template.parameters.forEach((body, bodyIdx) => {
						if (keepBody) {
							if (!body.text) {
								var count = bodyIdx + 1;
								var msg = 'Enter Body Parameter on ' + count + ' Text Value ';
								this.alertService.webShow('Danger', msg);
								keepBody = false;
								return;
							}
						}
					});
				}

				if (template.type == "button" && template.sub_type == "url" && template.parameters && template.parameters.length > 0) {
					template.parameters.forEach((cardButton, cardButtonIdx) => {
						if (keepButton) {
							if (!cardButton.text) {
								var msg = 'Enter Button Url';
								this.alertService.webShow('Danger', msg);
								keepButton = false;
								return;
							}
						}
					});
				}

				if (template.type == 'CAROUSEL' && template.cards && template.cards.length > 0) {
					template.cards.forEach((cards, cardsIdx) => {
						cards.components.forEach((components, componentsIdx) => {
							if (components.type == "header" && components.parameters && components.parameters.length > 0) {
								if (keepCardHeader) {
									if (components.parameters[0].type == 'image' && !components.parameters[0].image.id) {
										var count = cardsIdx + 1;
										var msg = 'Enter Card no ' + count + '  header image url';
										this.alertService.webShow('Danger', msg);
										keepCardHeader = false;
										return;
									}
									if (components.parameters[0].type == 'video' && !components.parameters[0].video.id) {
										var count = cardsIdx + 1;
										var msg = 'Enter Card no ' + count + ' header video url';
										this.alertService.webShow('Danger', msg);
										keepCardHeader = false;
										return;
									}
								}
							}
							if (components.type == "body" && components.parameters && components.parameters.length > 0) {
								components.parameters.forEach((cardBody, cardBodyIdx) => {
									if (keepCardBody) {
										if (!cardBody.text) {
											var count = cardsIdx + 1;
											var msg = 'Enter Card no ' + count + ' Body Text Value ';
											this.alertService.webShow('Danger', msg);
											keepCardBody = false;
											return;
										}
									}
								});
							}
							if (components.type == "button" && components.sub_type == "url" && components.parameters && components.parameters.length > 0) {
								components.parameters.forEach((cardButton, cardButtonIdx) => {
									if (keepCardButton) {
										if (!cardButton.text) {
											var count = cardsIdx + 1;
											var msg = 'Enter Card no ' + count + ' Button Url Value ';
											this.alertService.webShow('Danger', msg);
											keepCardButton = false;
											return;
										}
									}
								});
							}
						});

					});
				}
			});
		}

		if (!keepHeader || !keepBody || !keepButton || !keepCardHeader || !keepCardButton || !keepCardBody) {
			return;
		}

		var sendingData = [];
		var newVariable = Object.assign({ 'to': this.contacts.toString() }, { 'data': this.data })
		sendingData.push(newVariable);
		var datas = {
			"template_id": this.template?.id.toString(),
			"variables": sendingData,
			"client_site_id": this.templateForm.value.client_site_id
		}
		if (!this.templateForm.value.client_site_id) {
			datas["client_site_id"] = this.template.client_site_id
		}
		if (this.isSendLater) {
			var next = moment(this.next_shot).format('YYYY-MM-DD');
			var nextTime = new Date(next + ' ' + this.time_shot);
			var nextTimes = new Date(moment(nextTime).format('YYYY-MM-DD h:mm A'));
			var untile = moment(this.shot_until).format('YYYY-MM-DD');
			var untileTime = new Date(untile + ' ' + this.time_shot);
			var untileTimes = new Date(moment(untileTime).format('YYYY-MM-DD h:mm A'));
			datas["next_shot"] =nextTimes.toISOString();
			datas["type"] = this.type;
			datas["shot_until"] = untileTimes.toISOString();
		}
		this.fbTemplateMessageService.sendSingleTemplate(datas);
		this.matDialogRef.close();

	}

	add(event: MatChipInputEvent): void {
		const value = (event.value).trim();
		const arrayValue = value.split(/\r\n|\n|\r|,|\t|\s| /).filter(word => word.length > 0);

		if (arrayValue && arrayValue.length > 0) {
			for (let i = 0; i <= arrayValue.length; i++) {
				if (arrayValue[i] === null || arrayValue[i] === '' || arrayValue[i] === ' ' || arrayValue[i] === undefined) {
					continue;
				}
				else { this.contacts.push(arrayValue[i]); }
			}
		}

		this.contacts = this.contacts.filter((element): element is any => {
			return (element !== null || element !== 'null' || element !== undefined) ? true : false;
		});

		// Clear the input value
		event.chipInput!.clear();
	}

	remove(contact: string): void {
		const index = this.contacts.indexOf(contact);

		if (index >= 0) {
			this.contacts.splice(index, 1);
		}
	}

	onChangeBodyValue(value, place, idx, fileName: any = null) {
		this.data.forEach(required => {
			if (required.type && required.type == place.type) {
				required.parameters.forEach((parameters, index) => {
					if (required.type == 'header' && parameters.type == 'document') {
						var index = value.lastIndexOf("/") + 1;
						var filename = value.substr(index);
						parameters.document.link = value
						if (fileName == 'filename') {
							parameters.document.filename = value;
						}
					}
					if (required.type == 'header' && parameters.type == 'image') {
						parameters.image.link = value
					}
					if (required.type == 'header' && parameters.type == 'video') {
						parameters.video.link = value
					}
					if (required.type != 'header' && index == idx) {
						parameters.text = value
					}
					if (required.type == 'button' && index == idx && parameters.type == 'payload') {
						parameters.payload = "temp_itrs_" + value;
					}
				});
			}
		});
	}

	onChangeCaroselValue(value, place, identify, idx,) {
		this.data.forEach(required => {
			if (required.type && required.type.toLowerCase() == 'carousel') {
				required.cards.forEach((cards, index) => {
					if (cards.card_index == place.card_index) {

						cards.components.forEach(components => {
							components.parameters.forEach((parameters, index) => {
								if (components.type == 'header' && parameters.type == 'document' && parameters.type == identify.type) {
									var index = value.lastIndexOf("/") + 1;
									var filename = value.substr(index);
									parameters.document.id = value
									parameters.document.filename = filename ? filename : ''
								}
								if (components.type == 'header' && parameters.type == 'image' && parameters.type == identify.type) {
									parameters.image.id = value
								}
								if (components.type == 'header' && parameters.type == 'video' && parameters.type == identify.type) {
									parameters.video.id = value
								}
								if (components.type == 'header' && parameters.type == 'text' && parameters.type == identify.type) {
									parameters.video.id = value
								}
								if (components.type == 'body' && parameters.type == identify.type && index == idx) {
									parameters.text = value
								}
								if (components.type == 'button' && parameters.type == identify.type && index == idx && parameters.type == 'payload') {
									parameters.payload = "temp_itrs_" + value;
								}
								if (components.type == 'button' && parameters.type != 'payload' && parameters.type == identify.type && index == idx) {
									parameters.text = value
								}
							});
						});

					}
				});
			}
		});
	}

	getHandle(value, place, identify, idx) {
		if (value && value.length > 1) {
			var data = {
				url: value,
				place: place,
				idx: idx,
				identify: identify,
				"client_site_id": this.templateForm.value.client_site_id
			}
			if (!this.templateForm.value.client_site_id) {
				data["client_site_id"] = this.template.client_site_id
			}
			this.fbTemplateMessageService.getMediaId('fbTemplate/getMediaId', data);
		}

	}

	/** Add / Update Itrsbot */
	newItrs(requireValue: any, idx: any, parameter: any = null): void {
		var itrsbotData: any;
		if (!this.templateForm.value.client_site_id) {
			this.alertService.webShow('Danger', 'Please Select Account');
			return
		}
		if (this.templateForm.value.client_site_id) {
			itrsbotData = {
				client_site_id: this.templateForm.value.client_site_id,
				requireValue: requireValue,
				idx: idx,
			}
		}
		this.fbTemplateMessageService.changelinkItrs(itrsbotData);
		let dialogRef = this.matDialog.open(FbItrsbotComponent, {
			width: "600px",
			data: {
				action: "new",
				item: itrsbotData,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				if (response[1].value && parameter != null) {
					this.onChangeCaroselItrs(response[1].value, requireValue, parameter, idx);
				} else {
					this.onChangeValue(response[1].value, requireValue, idx);
				}
			});
	}


	onChangeCaroselItrs(value, place, identify, idx) {
		this.data.forEach(required => {
			if (required.type && required.type.toLowerCase() == 'carousel') {
				required.cards.forEach((cards, index) => {
					if (cards.card_index == place.card_index) {

						cards.components.forEach((components, compIndex) => {
							if (compIndex == idx) {
								components.parameters.forEach((parameters, index) => {
									if (components.type == 'header' && parameters.type == 'document' && parameters.type == identify.type) {
										var index = value.lastIndexOf("/") + 1;
										var filename = value.substr(index);
										parameters.document.id = value
										parameters.document.filename = filename ? filename : ''
									}
									if (components.type == 'header' && parameters.type == 'image' && parameters.type == identify.type) {
										parameters.image.id = value
									}
									if (components.type == 'header' && parameters.type == 'video' && parameters.type == identify.type) {
										parameters.video.id = value
									}
									if (components.type == 'header' && parameters.type == 'text' && parameters.type == identify.type) {
										parameters.video.id = value
									}
									if (components.type == 'body' && parameters.type == identify.type && index == idx) {
										parameters.text = value
									}
									if (components.type == 'button' && parameters.type == identify.type && parameters.type == 'payload') {
										parameters.payload = "temp_itrs_" + value;
									}
									if (components.type == 'button' && parameters.type != 'payload' && parameters.type == identify.type && index == idx) {
										parameters.text = value
									}
								});
							}

						});

					}
				});
			}
		});
	}


	deleteItrs(place, idx, identify: any = null, componetIdx = null) {
		if (identify) {
			this.data.forEach(required => {
				if (required.type && required.type.toLowerCase() == 'carousel') {
					required.cards.forEach((cards, index) => {
						if (cards.card_index == place.card_index) {
							cards.components.forEach((components, compIndex) => {
								if (compIndex == componetIdx) {
									components.parameters.forEach((parameters, index) => {
										if (components.type == 'button' && parameters.type == identify.type && index == idx && components.sub_type == 'quick_reply') {
											parameters.payload = "";
										}
									});
								}
							});

						}
					});
				}
			});
		} else {
			this.data.forEach(required => {
				if (required.type && required.type == place.type && required.index == place.index) {
					required.parameters.forEach((parameters, index) => {
						if (required.type == 'button' && index == idx && parameters.type == 'payload') {
							parameters.payload = "";
						}
					});
				}
			});
		}

		this.alertService.webShow('Danger', 'Intractive Removed success');
	}


	/** addAssets From Gallery */
	addAssetsTemp(requireValue: any = null, idx: any = null, type: any = null): void {
		this.mediaService.resetParams();
		this.mediaParam.type = type;
		this.mediaService.getAllItems();
		let dialogRef = this.matDialog.open(TemplateGalleryComponent, {
			width: "600px",
			data: {
				action: 'new',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response.type;
				const formData = response.url;
				switch (actionType) {
					case 'new':
						this.onChangeValue(formData, requireValue, idx)
						break;
				}
			});
	}

	/** addAssets From Gallery */
	addAssetsCurTemp(card: any = null, parameter: any = null, idx: any = null, type: any = null): void {
		this.mediaService.resetParams();
		this.mediaParam.type = type;
		this.mediaService.getAllItems();
		let dialogRef = this.matDialog.open(TemplateGalleryComponent, {
			width: "600px",
			data: {
				action: 'new',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response.type;
				const formData = response.url;
				switch (actionType) {
					case 'new':
						this.getHandle(formData, card, parameter, idx);
						break;
				}
			});
	}


	isSendLater: any = false;
	typeData = [
		{
			id: 1,
			name: 'Once'
		},
		{
			id: 2,
			name: 'Daily'
		},
		{
			id: 3,
			name: 'Weekly'
		},
		{
			id: 4,
			name: 'Monthly'
		},
		{
			id: 5,
			name: 'Yearly'
		},
	]
	next_shot: any = null;
	shot_until: any = null;
	type: any = null;
	time_shot: any = null;
	currentDate: any = new Date();
	minTime: any = null;
	maxTime: any = null;

	onChangeSendLater($event: any = null) {
		var currentDate  =  moment(new Date()).add(15, 'minutes').format('hh:mm A'); // moment(new Date()).format('h:mm A');
		this.minTime = currentDate;
		this.maxTime = '11:59 PM';
		this.isSendLater = $event.checked;
	}

	onDateChange(event: MatDatepickerInputEvent<Date>): void {
		var date = new Date(event.value);
		var selectedDate = moment(new Date(event.value)).format('YYYY-MM-DD');
		var current = moment(new Date()).format('YYYY-MM-DD'); // moment(new Date()).format('h:mm A');
		if (selectedDate != current) {
			this.minTime = '12:00 AM';
			this.maxTime = '11:59 PM';
			
		}else{
			var currentDate  =  moment(new Date()).add(15, 'minutes').format('hh:mm A'); // moment(new Date()).format('h:mm A');
			this.minTime = currentDate;
			this.maxTime = '11:59 PM';
		}
		let year = date.getFullYear();
		date.setFullYear(year + 1);
		this.shot_until = date;
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'Login': case 'login':
					let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				case 'logout': case 'Logout':
					let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				default:
					break;
			}
		}
	}


}
