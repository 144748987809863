import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';
import { ReassignFormComponent } from './../reassignForm/reassignForm.component';

import {
	User, UserService, CommonService,
	YoutubeService, Youtube,
	YoutubeTicketService, YoutubeTicket,
	ChatService,
	CommentService, Comment
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'youtube-ticket-abandon',
	templateUrl: './abandon.component.html',
	styleUrls: ['./abandon.component.scss']
})

export class AbandonComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	baseImage: string = this.commonService.logo_image;
	authUser: User;
	tickets: YoutubeTicket[] = [];
	abandonTickets: YoutubeTicket[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.abandonTickets);
	displayedColumns = ['date', 'avatar', 'content', 'time_elapsed', 'agent', 'priority', 'status', 'action'];
	dataLength: number = 0;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public youtubeService: YoutubeService,
		public youtubeTicketService: YoutubeTicketService,
		public commentService: CommentService,
		public commonService: CommonService,
		public chatService: ChatService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.youtubeTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.tickets = data;
			this.abandonTickets = data.filter(x => {
				return x.statusName === 'Abandon' &&
					x.abandonElapsed !== null &&
					x.is_closed === false;
			});
			this.dataSource = new MatTableDataSource(this.abandonTickets);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.youtubeTicketService.unSubscribeFilter();
		this.youtubeTicketService.changeAllItems([]);
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.youtubeTicketService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.youtubeTicketService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Tag */
	reassignTicket(ticket = null): void {
		this.youtubeTicketService.changeItem((ticket) ? ticket : null);
		let dialogRef = this.matDialog.open(ReassignFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: ticket,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				let updateData: any = formData.value;
				let newAgentId: any = updateData.agent_id;
				if(newAgentId && newAgentId === ticket.agent_id) {
					updateData.status_mode = null;
				}
				switch (actionType) {
					case 'update':
						this.youtubeTicketService.update(id, updateData);
						break;
				}
			});
	}

	/** Close Tickets */
	closeTicket(ticket): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'close',
				title: 'Close Ticket',
				resource: ticket,
				clientSiteId: ticket.clientSiteId,
				message: 'Are you sure you want to Close this Ticket?',
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				result.formValue.is_closed = true;
				result.formValue.youtube_ticket_id = result.formValue.id;
				result.formValue.status_mode = 'Closed';
				this.youtubeTicketService.close(ticket.id, result.formValue, 'youtube-ticket-close');
				this.youtubeTicketService.removelibraryById(ticket.id);
			}
		});
	}

	/** Close Tickets */
	commentTicket(ticket): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: ticket,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.store(result.formValue);
			}
		});
	}

	statusChange(ticket: YoutubeTicket, status: string) {
		switch (status) {
			case "Re-Assigned":
				this.reassignTicket(ticket);
				break;
			case "Closed":
				this.closeTicket(ticket);
				break;
			case "Important":
				let updateData: any = { status_mode: 'Important' };
				this.youtubeTicketService.update(ticket.id, updateData);
				break;
			default:
				break;
		}
	}

}
