import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { Router } from '@angular/router';
import {
	User, UserService,
	TemplateUsageReportsService, TemplateUsageReports
} from 'src/app/@tji/_dbShare';

import { Location } from '@angular/common';
import { TemplateExportExcelComponent } from '../template-export-excel/template-export-excel.component';
import * as moment from 'moment-timezone';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
	selector: 'template-usage-list',
	templateUrl: './template-usage.component.html',
	styleUrls: ['./template-usage.component.scss'],
	providers: [OrderByPipe]
})

export class TemplateUsageComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	downloadLoader: boolean = false;
	subLoader: boolean = false;
	authUser: User;
	templateUsages: TemplateUsageReports[] = [];
	templateUsage: TemplateUsageReports;
	param: any;
	dataSource = new MatTableDataSource(this.templateUsages);
	displayedColumns = ['date', 'jobcode', 'sentby', 'totalCount', 'success', 'failed', 'rejected', 'completed'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	@ViewChild('rightSideBar') rightSideBar: MatSidenav;

	constructor(public userService: UserService,
		public templateUsageReportsService: TemplateUsageReportsService,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe, public location: Location,
		public router: Router) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.templateUsageReportsService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.templateUsageReportsService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.templateUsageReportsService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsage = data);
		this.templateUsageReportsService.loader.pipe(untilDestroyed(this)).subscribe(data => this.loader = data);
		this.templateUsageReportsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.templateUsages) { this.subLoader = false; }
			this.templateUsages = data;
			this.dataSource = new MatTableDataSource(this.templateUsages);
			this.dataSource.sort = this.sort;
		});
		this.templateUsageReportsService.downloadLoader.pipe(untilDestroyed(this)).subscribe(data => this.downloadLoader = data);
	}

	ngOnInit() {
		// this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.templateUsageReportsService.unSubscribe();
		// this.templateUsageReportsService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	getSuccessCount(data: TemplateUsageReports) {
		this.templateUsage = data;
		// this.viewPanel = 'success';
		this.router.navigate(['app/template-usage/' + data.id + '/success']);
	}

	getFailedCount(data: TemplateUsageReports) {
		this.templateUsage = data;
		// this.viewPanel = 'success';
		this.router.navigate(['app/template-usage/' + data.id + '/failed']);
	}

	viewTemplate(data: TemplateUsageReports) {
		this.templateUsage = data;
		// this.viewPanel = 'success';
		this.templateUsageReportsService.changeItem(this.templateUsage);
		this.router.navigate(['app/template-usage/' + data.id + '/view']);
	}

	exportExcel() {
		// let confirmDialogRef = this.matDialog.open(TemplateExportExcelComponent, {
		// 	data: {
		// 		type: 'info',
		// 	},
		// });
		// confirmDialogRef.afterClosed().subscribe(result => {
		// 	if (result) {
		// 		let dateFormat: any = {
		// 			"from_date": moment(result.value).format('DD-MM-YYYY'), // moment(result).format("DD-MM-YYY"),
		// 			"limit": 2000,
    	// 			"current": 1,
		// 		}
				// this.templateUsageReportsService.exportReport(dateFormat);
		// 	}
		// });
		let dateFormat: any = {
			"from_date": this.param.from_date,
			"to_date": this.param.to_date,
			"limit": 2000,
			"current": 1,
		}
		this.templateUsageReportsService.exportReport(dateFormat);
	}

	toggleRightSidebar() {
		this.rightSideBar.toggle();
	}
}
