import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardRouteModule } from './dashboard-route.module';

import { MainComponent } from './main/main.component';
import { Chart1Component } from './chart1/chart1.component';
import { Chart2Component } from './chart2/chart2.component';
import { Chart3Component } from './chart3/chart3.component';
import { Chart4Component } from './chart4/chart4.component';
import { HistoryComponent } from './history/history.component';
import { SiteCountComponent } from './siteCount/siteCount.component';

import { DashboardService, HistoryService } from 'src/app/@tji/_dbShare';
import { DashboardPaymentsCountComponent } from './dashboard-payments-count/dashboard-payments-count.component';
import { DashboardOrderCountComponent } from './dashboard-order-count/dashboard-order-count.component';

@NgModule({
    declarations: [
        MainComponent,
        Chart1Component,
        Chart2Component,
        Chart3Component,
        Chart4Component,
        HistoryComponent,
        SiteCountComponent,
        DashboardPaymentsCountComponent,
        DashboardOrderCountComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        DashboardRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        Chart1Component,
        Chart2Component,
        Chart3Component,
        Chart4Component,
        HistoryComponent,
        SiteCountComponent,
        DashboardPaymentsCountComponent,
        DashboardOrderCountComponent
    ],
    providers: [
        DashboardService,
        HistoryService
    ]
})

export class DashboardModule { }
