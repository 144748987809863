import { Component, ElementRef, OnDestroy, OnInit, AfterContentInit, ViewChild, ViewContainerRef, EventEmitter, Output, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StringBetweenPipe } from 'src/app/pipes/string/stringBetween.pipe';
import { CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem, CdkDragExit, CdkDragEnter } from '@angular/cdk/drag-drop';
import {
	User, UserService,
	ClientSiteService, ClientSite,
	StencilService, Stencil, StencilModel,
	TemplateMessageService, TemplateMessage, TemplateMessageModel, ComponentObjectModel,
	TemplateMessageObject, TemplateMessageObjectModel, Header, HeaderModel, Body, BodyModel,
	Footer, FooterModel, Action, ActionModel, Text, TextModel, Tool, ToolModel,
	Imaget, ImagetModel, Video, VideoModel, Document, DocumentModel,
	Buttons, ButtonsModel, ButtonItem, ButtonItemModel, Reply, ReplyModel,
	TemplateButton, TemplateButtonModel, TemplateButtonItemModel,
	Sections, SectionsModel, Lists, ListsModel, ListItem, ListItemModel,
	TemplateComponent, TemplateComponentModel, AlertService, TemplateDynamicButtonModel
} from 'src/app/@tji/_dbShare';
import { ItrsbotComponent } from 'src/app/appAccount/template/itrsbot/itrsbot.component';
import { filter } from 'rxjs';
@UntilDestroy()
@Component({
	selector: 'template-message-form',
	templateUrl: './templateMessage-form.component.html',
	styleUrls: ['./templateMessage-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class TemplateMessageFormComponent implements OnInit, OnDestroy, AfterContentInit {
	mode: string = null;
	dialogTitle: string = 'Create';
	authUser: User;
	clientSites: ClientSite[] = [];
	clientSiteId: number = null;
	templateMessageName: string = null;
	namespace: string = null;
	templateLabel: string = null;
	templateDescription: string = null;
	selectList = true;
	loader: boolean = false;
	stencils: Stencil[] = [];
	stencilId: number = null;
	templateId: number = null;
	selectedLanguage: any = null;
	languageCode: string = null;
	templateMessage: TemplateMessage;

	templateNamePattern = "^[a-z0-9_-]{8,15}$";

	dropStatus = true;
	selectedType = '';
	imageURL = '';
	videoURL = '';
	listCount: number = 0;

	storeData: Array<string>;
	listArray: number[] = [];

	headerAcceptTypes = ["text", "image", "document", "video"];
	bodyAcceptTypes = ["text"];
	footerAcceptTypes = ["text"];
	actionAcceptTypes = ["button", "dynamic button"];

	tools: Tool[] = [
		{ 'type': 'text', 'icon': 'text_snippet', 'value': '', 'category': 'input-fields', 'index': null, 'color': 'toolColor', 'intractiveId': null },
		{ 'type': 'image', 'icon': 'add_photo_alternate', 'value': '', 'category': 'media-fields', 'index': null, 'color': 'toolColor', 'intractiveId': null },
		{ 'type': 'video', 'icon': 'ondemand_video', 'value': '', 'category': 'media-fields', 'index': null, 'color': 'toolColor', 'intractiveId': null },
		{ 'type': 'document', 'icon': 'description', 'value': '', 'category': 'media-fields', 'index': null, 'color': 'toolColor', 'intractiveId': null },
		{ 'type': 'button', 'icon': 'gamepad', 'value': '', 'category': 'intraction-fields', 'index': null, 'color': 'toolColor', 'intractiveId': null },
		{ 'type': 'dynamic button', 'icon': 'gamepad', 'value': '', 'category': 'intraction-fields', 'index': null, 'color': 'toolColor', 'intractiveId': null },
	];

	languages = [
		{ 'name': 'English', 'code': 'en', 'policy': 'deterministic' },
		{ 'name': 'English(US)', 'code': 'en_US', 'policy': 'deterministic' },
		{ 'name': 'English(UK)', 'code': 'en_UK', 'policy': 'deterministic' },
		{ 'name': 'English(GB)', 'code': 'en_GB', 'policy': 'deterministic' },
		{ 'name': 'Arabic', 'code': 'ar', 'policy': 'deterministic' },
		{ 'name': 'Turkish', 'code': 'tr', 'policy': 'deterministic' },
	];

	headerList: Tool[] = [];

	bodyList: Tool[] = [];

	footerList: Tool[] = [];

	actionList: Tool[] = [];

	responseObject = new TemplateMessageObjectModel({});

	showPlaceholder: boolean[] = [];
	templateComponent: TemplateComponent = new TemplateComponentModel({});
	currecntDate = new Date();
	currecntTime = '';
	itrsDataList = { templateId: '', itrsId: '', action: 'button', button: [] };
	constructor(public userService: UserService,
		public location: Location,
		public clientSiteService: ClientSiteService,
		public templateMessageService: TemplateMessageService,
		public stencilService: StencilService,
		private stringBetweenPipe: StringBetweenPipe,
		private _formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
		private router: Router,
		private alertService: AlertService,
		public matDialog: MatDialog) {
		this.mode = this.route.snapshot.paramMap.get('mode');
		this.templateId = Number(this.route.snapshot.paramMap.get('id'));
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = [];
			if (data.length > 0) {
				data.forEach(clientSite => {
					if (clientSite.site_id == 2) {
						this.clientSites.push(clientSite);
					}
				});
			}
		});
		this.stencilService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.stencils = data;
		});
		this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.templateMessage = data;
			if (this.templateMessage && this.templateMessage.id) {
				this.responsiveObjectInit();
			}
		});
		this.currecntTime = formatDate(this.currecntDate, 'hh:mm a', 'en-US', '+0530');
	}

	ngOnInit() {
		this.getItem();
		// this.clientSiteService.getAllItems();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.clientSiteService.unSubscribe();
		this.clientSiteService.unSubscribeFilter();
		// this.templateMessageService.unSubscribe();
		// this.templateMessageService.unSubscribeFilter();
	}

	/** On destroy */
	ngAfterContentInit(): void {
		// this.cd.detectChanges();
	}

	doRefresh() {
		// this.cd.detectChanges();
	}

	getPageTitle(): string {
		if (this.mode && this.mode === 'edit') {
			return 'Update Template Message';
		}
		return 'Create Template Message';
	}
	getItem() {
		this.clientSiteService.getAllItems();
		this.stencilService.getAllItems();
		if (this.mode && this.mode === 'edit' && this.templateId) {
			this.templateMessageService.getItem({ 'id': this.templateId });
		}
		setTimeout(() => {
			this.cd.detectChanges();
		}, 500);
	}

	addMedia() {

	}

	responsiveObjectInit() {
		if (this.mode && this.mode === 'edit' && this.templateMessage && this.templateMessage.jsonData) {
			let jsonData: any = this.templateMessage.jsonData;
			let templateJsonData: any = (jsonData && jsonData.template) ? jsonData.template : null;
			let templateData: any = this.templateMessage.templateData;
			let templateType: any = (templateData && templateData.type) ? templateData.type : 'template';
			this.clientSiteId = this.templateMessage ? this.templateMessage.client_site_id : null;
			this.stencilId = this.templateMessage ? this.templateMessage.stencil_id : null;
			this.templateLabel = this.templateMessage.label;
			this.templateDescription = this.templateMessage.description;
			this.templateMessageName = this.templateMessage.name;
			this.onChangeName(this.templateMessageName);
			this.namespace = this.templateMessage.namespace;
			this.onChangeNameSpace(this.namespace);
			this.languageCode = this.templateMessage ? this.templateMessage.language : null;

			if (this.languageCode) {
				this.selectedLanguage = this.languages.filter(x => { return (x.code === this.languageCode) ? true : false; })[0];
			} else {
				this.selectedLanguage = this.languages.filter(x => { return (x.code === 'en_US') ? true : false; })[0];
			}

			if (this.clientSites.length > 0 && this.mode && this.mode === 'edit') {
				let clientId = this.clientSites.some(client => client.id == this.clientSiteId);
				if (!clientId) {
					this.clientSiteId = null;
				}
			}

			if (this.stencils.length > 0 && this.mode && this.mode === 'edit') {
				let stenId = this.stencils.some(sten => sten.id == this.stencilId);
				if (!stenId) {
					this.stencilId = null;
				}
			}
			this.onChangeLanguage();

			// HeaderList Recovery
			let headerDataType: any = (templateData && templateData.headerType) ? templateData.headerType : 'text';
			if (headerDataType && headerDataType !== null) {
				let selectedTool = this.tools.filter(x => { return (x.type === headerDataType) ? true : false; })[0];
				this.headerList[0] = new ToolModel(selectedTool);
				switch (headerDataType) {
					case 'image':
						if (templateData.headerType = 'image') {
							this.headerList[0].value = (templateData && templateData.image) ? templateData.image : null;
							this.imageURL = (templateData && templateData.image) ? templateData.image : null;
						} else {
							this.clearHeader();
						}
						break;
					case 'video':
						if (templateData.headerType = 'video') {
							this.headerList[0].value = (templateData && templateData.video) ? templateData.video : null;
							this.videoURL = (templateData && templateData.video) ? templateData.video : null;
						} else {
							this.clearHeader();
						}
						break;
					case 'document':
						if (templateData.headerType = 'document') {
							this.headerList[0].value = (templateData && templateData.document) ? templateData.document : null;
						} else {
							this.clearHeader();
						}
						break;
					default:
						if (templateData.headerType = 'text' && templateData.headerText != null) {
							this.headerList[0].value = templateData.headerText;
							this.headerListValues = templateData.headerText;
						} else {
							this.clearHeader();
						}
						break;
				}
			}
			// BodyList Recovery
			let bodyDataType: any = (templateData && templateData.bodyType) ? templateData.bodyType : 'text';
			if (bodyDataType && bodyDataType !== null && templateData.bodyText) {
				let selectedTool = this.tools.filter(x => { return (x.type === bodyDataType) ? true : false; })[0];
				this.bodyList[0] = new ToolModel(selectedTool);
				this.bodyList[0].value = templateData.bodyText;
				this.bodyListValues = templateData.bodyText;
				this.bodyTextMessageView();

			} else {
				this.clearBody();
			}
			// FooterList Recovery
			let footerDataType: any = (templateData && templateData.footerType) ? templateData.footerType : 'text';
			if (footerDataType && footerDataType !== null && templateData.footerText) {
				let selectedTool = this.tools.filter(x => { return (x.type === footerDataType) ? true : false; })[0];
				this.footerList[0] = new ToolModel(selectedTool);
				this.footerList[0].value = templateData.footerText;
				this.FooterListValues = templateData.footerText;
			} else {
				this.clearFooter();
			}
			// ActionsList Recovery activity
			// let actionDatas: any = (templateJsonData && templateJsonData.components) ? templateJsonData.components : [];
			let actionDatas: any = (jsonData && jsonData.templateComponent["buttons"]) ? jsonData.templateComponent["buttons"] : [];
			var actionData = [];
			if (actionDatas.length > 0) {
				actionDatas.forEach(element => {
					if (element.type === "button" || element.type === "dynamic button") {
						actionData.push(element);
					}
				});
				if (actionData.length > 0) {
					if (actionData && actionData.length > 0) {
						let selectedTool = this.tools.filter(x => { return (x.type === 'button' || x.type === 'dynamic button') ? true : false; })[0];
						for (let i = 0; i < actionData.length; i++) {
							this.actionList[i] = new ToolModel(selectedTool);
							this.actionList[i].value = actionData[i].value;
							this.actionList[i].index = actionData[i].index;
							this.actionList[i].intractiveId = actionData[i].intractiveId;
						}
						this.responseObject.templateComponent["buttons"] = this.actionList;
					} else {
						this.clearAction();
					}

					if (this.templateMessage && this.templateMessage['activity']) {
						const itrsData = JSON.parse(this.templateMessage['activity']);
						this.itrsDataList.button = itrsData;
						this.itrsDataList.itrsId = this.templateMessage['itrsbot_id']
						this.actionList.forEach(action => {
							itrsData.forEach(itrs => {
								if (action.index == itrs.button_id) {
									action.intractiveId = itrs.intractiveId
								}
							});
						});
					}
					this.responseObject.templateComponent["buttons"] = this.actionList;
				}
			}

			this.processHeaderList();
			this.processBodyList();
			this.processFooterList();
			this.processActionList();
			// this.responseObject = jsonData;
		}

	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			let selectedTool = this.tools[event.previousIndex];
			this.selectedType = selectedTool.type;
			let newData: Tool = new ToolModel(selectedTool);
			if (event.container.id == 'cdk-drop-list-1') {
				if (this.headerAcceptTypes.includes(selectedTool.type)) {
					// newData.index = (this.headerList.length > 0) ? this.headerList.length - 1 : 0;
					// this.headerList.push(newData);
					delete this.headerListValues;
					this.headerList = [newData];
					this.processHeaderList();
				}
			} else if (event.container.id == 'cdk-drop-list-2') {
				if (this.bodyAcceptTypes.includes(selectedTool.type)) {
					// newData.index = (this.bodyList.length > 0) ? this.bodyList.length - 1 : 0;
					// this.bodyList.push(newData);
					this.bodyList = [newData];
					this.processBodyList();
				}
			} else if (event.container.id == 'cdk-drop-list-3') {
				if (this.footerAcceptTypes.includes(selectedTool.type)) {
					// newData.index = (this.footerList.length > 0) ? this.footerList.length - 1 : 0;
					// this.footerList.push(newData);
					this.footerList = [newData];
					this.processFooterList();
				}
			} else if (event.container.id == 'cdk-drop-list-4') {
				if (this.actionAcceptTypes.includes(selectedTool.type)) {
					newData.index = this.actionList.length // (this.actionList.length > 0) ? this.actionList.length - 1 : 0;
					if (this.actionList.length <= 2) {
						this.actionList.push(newData);
						this.processActionList();
					}
				}
			}
		}
	}

	getParam(toolType, value: string = null, i = 0, intractiveId = null) {
		let newParam = null;
		switch (toolType) {
			case 'text':
				newParam = new TextModel({});
				newParam.text = value;
				break;
			case 'image':
				newParam = new ImagetModel({});
				newParam.image.link = '<<' + value + '>>';
				break;
			case 'video':
				newParam = new VideoModel({});
				newParam.video.link = '<<' + value + '>>';
				break;
			case 'document':
				newParam = new DocumentModel({});
				newParam.document.link = '<<' + value + '>>';
				break;
			case 'button':
				newParam = new TemplateButtonModel({});
				newParam.index = i;
				newParam.intractiveId = intractiveId;
				newParam.value = value;
				if (newParam && newParam.parameters && newParam.parameters.length > 0) {
					for (let param of newParam.parameters) {
						param.text = '<<' + value + '>>';
					}
				} else {
					let buttonItem = new TemplateButtonItemModel({});
					buttonItem.text = '<<' + value + '>>';
					newParam.parameters[0] = buttonItem;
				}
				break;
			case 'dynamic button':
				newParam = new TemplateDynamicButtonModel({});
				newParam.index = i;
				newParam.intractiveId = intractiveId;
				newParam.value = value;
				if (newParam && newParam.parameters && newParam.parameters.length > 0) {
					for (let param of newParam.parameters) {
						param.text = '<<' + value + '>>';
					}
				} else {
					let buttonItem = new TemplateButtonItemModel({});
					buttonItem.text = '<<' + value + '>>';
					newParam.parameters[0] = buttonItem;
				}
				break;
			default:
				break;
		}
		return newParam;
	}

	processHeaderList() {
		if (this.headerList && this.headerList.length > 0) {
			let newHeaderData = null;
			let selectedHeaderType: string = 'text';
			let i = 0;
			for (const header of this.headerList) {
				header.index = i;
				let headerValues = this.stringBetweenPipe.transform(header.value, '<<', '>>');
				if (headerValues && headerValues.length > 0) {
					newHeaderData = new ComponentObjectModel({ 'type': 'header', 'parameters': [] });
					selectedHeaderType = header.type;
					let index = 0;
					for (const headerValue of headerValues) {
						newHeaderData.parameters.push(this.getParam(header.type, '<<' + headerValue + '>>'));
					}
				}
				this.responseObject.templateComponent.headerType = header.type;
				this.responseObject.templateComponent.headerText = null;
				this.responseObject.templateComponent.image = null;
				this.responseObject.templateComponent.video = null;
				this.responseObject.templateComponent.document = null;
				// this.responseObject.templateComponent.document_name = null;
				// this.responseObject.templateComponent.video_name = null;
				// this.responseObject.templateComponent.image_name = null;
				const mapObj = { '"': "'", '“': "'", '″': "'" };
				selectedHeaderType = header.type ? header.type : 'text';
				newHeaderData = new ComponentObjectModel({ 'type': 'header', 'parameters': [] });
				switch (selectedHeaderType) {
					case 'text':
						this.responseObject.templateComponent.headerText = header.value.replace(/\b(?:"|“|″)\b/gi, matched => mapObj[matched]);
						let headerParams = this.stringBetweenPipe.transform(header.value, '<<', '>>');
						if (headerParams && headerParams.length > 0) {
							var parameters = [{
								type: "text",
								text: '<<' + headerParams + '>>'
							}];
							newHeaderData.parameters = parameters;
						}
						break;
					case 'image':
						if (header.value) {
							this.responseObject.templateComponent.image = header.value;
							newHeaderData.parameters.push(new ImagetModel({}));
							newHeaderData.parameters[0].image.link = header.value;
							// newHeaderData.parameters[0].image['filename'] = '<<image_name>>';
						} else {
							this.responseObject.templateComponent.image = header.value;
							// newHeaderData.parameters[0].image['filename'] = '<<image_name>>';
							newHeaderData.parameters.push(new ImagetModel({}));
						}
						break;
					case 'video':
						if (header.value) {
							this.responseObject.templateComponent.video = header.value;
							newHeaderData.parameters.push(new VideoModel({}));
							newHeaderData.parameters[0].video.link = header.value;
							// newHeaderData.parameters[0].video['filename'] = '<<video_name>>';
						} else {
							this.responseObject.templateComponent.video = header.value;
							newHeaderData.parameters.push(new VideoModel({}));
							// newHeaderData.parameters[0].video['filename'] = '<<video_name>>';
						}
						break;
					case 'document':
						if (header.value) {
							this.responseObject.templateComponent.document = header.value;
							newHeaderData.parameters.push(new DocumentModel({}));
							newHeaderData.parameters[0].document.link = header.value;
							newHeaderData.parameters[0].document['filename'] = '<<document_name>>';
						} else {
							this.responseObject.templateComponent.document = header.value;
							newHeaderData.parameters.push(new DocumentModel({}));
							newHeaderData.parameters[0].document['filename'] = '<<document_name>>';
						}
						break;
					default:
						this.responseObject.templateComponent.headerText = header.value;
						break;
				}
				i++;
			}
			this.responseObject.template.components[0] = newHeaderData;
		} else {
			this.responseObject.template.components[0] = null;
			this.responseObject.templateComponent.headerType = 'text';
			this.responseObject.templateComponent.headerText = null;
			this.responseObject.templateComponent.image = null;
			this.responseObject.templateComponent.video = null;
			this.responseObject.templateComponent.document = null;
		}
	}

	processBodyList() {
		if (this.bodyList && this.bodyList.length > 0) {
			let newBodyData = null;
			let i = 0;
			for (let body of this.bodyList) {
				body.index = i;
				let bodyValues = this.stringBetweenPipe.transform(body.value, '<<', '>>');
				if (bodyValues && bodyValues.length > 0) {
					newBodyData = new ComponentObjectModel({ 'type': 'body', 'parameters': [] });
					let selectedBodyType: string = body.type;
					let index = 0;
					for (const bodyValue of bodyValues) {
						newBodyData.parameters.push(this.getParam(body.type, '<<' + bodyValue + '>>'));
					}
				}
				var bodyValue = body.value;
				this.responseObject.templateComponent.bodyType = body.type;
				this.responseObject.templateComponent.bodyText = bodyValue.replace(/['"]+/g, "");
			}
			this.responseObject.template.components[1] = newBodyData;
		} else {
			this.responseObject.template.components[1] = null;
			this.responseObject.templateComponent.bodyType = 'text';
			this.responseObject.templateComponent.bodyText = null;
		}
	}

	processFooterList() {
		if (this.footerList && this.footerList.length > 0) {
			let newFooterData = null;
			let i = 0;
			for (let footer of this.footerList) {
				footer.index = i;
				let footerValues = this.stringBetweenPipe.transform(footer.value, '<<', '>>');
				if (footerValues && footerValues.length > 0) {
					newFooterData = new ComponentObjectModel({ 'type': 'footer', 'parameters': [] });
					let selectedFooterType: string = footer.type;
					let index = 0;
					for (const footerValue of footerValues) {
						newFooterData.parameters.push(this.getParam(footer.type, '<<' + footerValue + '>>'));
					}
				}
				this.responseObject.templateComponent.footerType = footer.type;
				this.responseObject.templateComponent.footerText = footer.value;
				this.FooterListValues = footer.value;
			}
			this.responseObject.template.components[2] = newFooterData;
		} else {
			this.responseObject.template.components[2] = null;
			this.responseObject.templateComponent.footerType = 'text';
			this.responseObject.templateComponent.footerText = null;
			delete this.FooterListValues;
		}
	}

	processActionList() {
		if (this.actionList && this.actionList.length > 0) {
			let i = 0;
			for (let action of this.actionList) {
				let selectedActionType: string = action.type;
				this.responseObject.template.components[3 + i] = this.getParam(action.type, action.value, i, action.intractiveId);
				i++;
			}
			this.actionList.forEach(action => {
				if (this.itrsDataList && this.itrsDataList.button) {
					this.itrsDataList.button.forEach(button => {
						if (action.index === button.button_id) {
							button.button_text = action.value;
						}
					});
				}
			});
			this.responseObject.templateComponent["buttons"] = this.actionList;
			// this.responseObject.templateComponent["buttons"]
		} else {
			let j = 0;
			for (let component of this.responseObject.template.components) {
				if (j > 2) {
					this.responseObject.template.components[j] = null;
				}
				j++;
			}
			this.responseObject.templateComponent["buttons"] = null;
		}
	}

	resetAll() {
		this.resetError();
		this.clearHeader();
		this.clearBody();
		this.clearFooter();
		this.clearAction();
	}

	clearHeader() {
		this.headerList = [];
		delete this.headerListValues;
		this.processHeaderList();
	}

	clearBody() {
		this.bodyList = [];
		delete this.bodyListValues;
		this.bodyTextMessageView();
		this.processBodyList();
	}

	clearFooter() {
		this.footerList = [];
		delete this.FooterListValues;
		this.processFooterList();
	}

	clearAction() {
		this.actionList = [];
		this.processActionList();
	}

	onHeaderChange(data, i) {
		this.headerList[i].value = data;
		this.headerListValues = data;
		this.processHeaderList();
	}

	onBodyChange(data, i) {
		this.bodyList[i].value = data;
		this.bodyListValues = data;
		this.bodyTextMessageView();
		this.processBodyList();
		this.onTemplateValidation();
	}

	onFooterChange(data, i) {
		this.footerList[i].value = data;
		this.FooterListValues = data;
		this.processFooterList();
	}

	onActionChange(data, i) {
		this.actionList[i].value = data;
		this.processActionList();
	}

	onChangeName(event) {
		this.responseObject.template.name = event;
	}

	onChangeNameSpace(event) {
		this.responseObject.template.namespace = event;
	}

	onChangeLanguage() {
		this.responseObject.template.language.code = (this.languageCode) ? this.languageCode : 'en';
		this.responseObject.template.language.policy = 'deterministic';
		this.languageCode = (this.languageCode) ? this.languageCode : null;
	}
	serviceProvider: string = '';
	checkComponents() {
		let components = [];
		if (this.responseObject.template.components) {
			this.responseObject.template.components.forEach((item, index) => {
				components.push(item)
			});
			this.responseObject.template.components = components.filter(x => x != null).filter(x => x != undefined).filter(x => x != 0);
		} else {
			this.responseObject.template.components = [];
		}

		this.clientSiteId
		if (this.clientSites.length > 0) {
			this.clientSites.forEach(clientSite => {
				if (clientSite.id == this.clientSiteId) {
					this.serviceProvider = clientSite.service_provider;
				}
			});

			if (this.serviceProvider == 'option-3' || this.serviceProvider == 'option-7') {
				this.responseObject.template.components.forEach(component => {
					if (component.parameters && component.parameters.length > 0) {
						component.parameters.forEach(provider => {
							if (provider.image) {
								delete provider['image']['provider']
							}
							if (provider.video) {
								delete provider['video']['provider']
							}
							if (provider.document) {
								delete provider['document']['provider']
							}
						});
						// delete component.parameters;
					}
				});
			}
			if (this.serviceProvider == 'option-3' || this.serviceProvider == 'option-7') {
				this.responseObject['recipient_type'] = "individual"
				this.responseObject['messaging_product'] = "whatsapp";
			}
		}
	}
	templateMessageStatus: any;

	storeTemplateMessage() {
		if (this.validation()) {
			this.deleteNull(this.responseObject);
			this.checkComponents();
			let storeData: TemplateMessage = new TemplateMessageModel({});
			var template = this.responseObject.template.components;
			var removeButton = template.filter((arr) => {
				if (arr.type == "button") {
					let buttonParams = this.stringBetweenPipe.transform(arr["value"], '<<', '>>');
					if (buttonParams && buttonParams.length > 0) {
						delete arr.parameters;
						// arr["value"] = '<<' + buttonParams + '>>'
						var parameter = [];
						// arr["value"] = '<<' + buttonParams + '>>'
						parameter.push({ "type": "text", "text": '<<' + buttonParams + '>>' });
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						arr.parameters = parameter;
						arr["sub_type"] = "url";
						delete arr["intractiveId"];
						delete arr["value"];
						return arr;
					} else if (arr["intractiveId"]) {
						delete arr.parameters;
						var parameter = [];
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						delete arr["intractiveId"]
						delete arr["value"]
						arr.parameters = parameter;
						return arr;
					}
				} else if (arr.type == "dynamic button") {
					let buttonParams = this.stringBetweenPipe.transform(arr["value"], '<<', '>>');
					if (buttonParams && buttonParams.length > 0) {
						delete arr.parameters;
						var parameters = [];
						// arr["value"] = '<<' + buttonParams + '>>'
						// var parameters = [{
						// 	"type": "url",
						// 	"url": '<<' + buttonParams + '>>'
						// }]
						parameter.push({ "type": "text", "url": '<<' + buttonParams + '>>' });
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						arr.parameters = parameters;
						arr["sub_type"] = "url";
						arr.type = "button";
						delete arr["intractiveId"];
						delete arr["value"];
						return arr;
					} else if (arr["intractiveId"]) {
						delete arr.parameters;
						var parameter = [];
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						delete arr["intractiveId"]
						delete arr["value"]
						arr.parameters = parameter;
						return arr;
					}
				} else {
					return arr;
				}
			});
			this.responseObject.template.components = removeButton;
			storeData.json = JSON.stringify(this.responseObject)
				.replace('<<<', '<<').replace('>>>', '>>')
				.replace('<<<', '<<').replace('>>>', '>>')
				.replace('<<<', '<<').replace('>>>', '>>')
				.replace('<<<', '<<').replace('>>>', '>>');
			// storeData.jsonData = this.responseObject;`
			storeData.name = this.templateMessageName.trim();
			storeData.label = this.templateLabel.trim();
			storeData.description = this.templateDescription.trim();
			storeData.namespace = this.namespace.trim();
			storeData.language = this.languageCode;
			storeData.client_site_id = this.clientSiteId;
			storeData.stencil_id = this.stencilId;

			// this.deleteEmptyKey(storeData)
			this.templateMessageService.store(storeData);
			this.templateMessageService.changeItrsDataScurce(this.itrsDataList);
			this.TemplateFormStatus();

		} else {
			this.isNotValid = true;
		}
	}

	updateTemplateMessage() {
		delete this.templateMessageStatus;
		if (this.validation()) {
			this.deleteNull(this.responseObject);
			this.checkComponents();
			let updateData: TemplateMessage = new TemplateMessageModel({});
			var template = this.responseObject.template.components;
			var removeButton = template.filter((arr) => {
				if (arr.type == "button") {
					let buttonParams = this.stringBetweenPipe.transform(arr["value"], '<<', '>>');
					if (buttonParams && buttonParams.length > 0) {
						delete arr.parameters;
						var parameter = [];
						// arr["value"] = '<<' + buttonParams + '>>'
						parameter.push({ "type": "text", "text": '<<' + buttonParams + '>>' });
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						arr.parameters = parameter;
						arr["sub_type"] = "url";
						delete arr["intractiveId"];
						delete arr["value"];
						return arr;
					} else if (arr["intractiveId"]) {
						delete arr.parameters;
						var parameter = [];
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						delete arr["intractiveId"]
						delete arr["value"]
						arr.parameters = parameter;
						return arr;
					}
				} else if (arr.type == "dynamic button") {
					let buttonParams = this.stringBetweenPipe.transform(arr["value"], '<<', '>>');
					if (buttonParams && buttonParams.length > 0) {
						delete arr.parameters;
						var parameters = [];
						// arr["value"] = '<<' + buttonParams + '>>'
						// var parameters = [{
						// 	"type": "url",
						// 	"url": '<<' + buttonParams + '>>'
						// }]
						parameter.push({ "type": "text", "url": '<<' + buttonParams + '>>' });
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						arr.parameters = parameters;
						arr["sub_type"] = "url";
						arr.type = "button";
						delete arr["intractiveId"];
						delete arr["value"];
						return arr;
					} else if (arr["intractiveId"]) {
						delete arr.parameters;
						var parameter = [];
						if (arr["intractiveId"]) {
							parameter.push({ "type": "payload", "payload": "itrs_" + arr["intractiveId"] });
						}
						delete arr["intractiveId"]
						delete arr["value"]
						arr.parameters = parameter;
						return arr;
					}
				} else {
					return arr;
				}
			});

			this.responseObject.template.components = removeButton;
			updateData.json = JSON.stringify(this.responseObject)
				.replace('<<<', '<<').replace('>>>', '>>')
				.replace('<<<', '<<').replace('>>>', '>>')
				.replace('<<<', '<<').replace('>>>', '>>')
				.replace('<<<', '<<').replace('>>>', '>>');
			updateData.name = this.templateMessageName.trim();
			updateData.label = this.templateLabel.trim();
			updateData.description = this.templateDescription.trim();
			updateData.namespace = this.namespace.trim();
			updateData.language = this.languageCode;
			updateData.client_site_id = this.clientSiteId;
			updateData.stencil_id = this.stencilId;

			this.deleteEmptyKey(updateData);
			updateData.id = this.templateMessage.id;
			this.templateMessageService.updateTemplate(updateData);
			this.templateMessageService.changeItrsDataScurce(this.itrsDataList);
			this.loader = !this.loader;
			this.TemplateFormStatus();
		} else {
			this.isNotValid = true;
		}
	}
	bodyListValues: string = "";
	FooterListValues: string = "";
	headerListValues: string = "";
	isNotValid: boolean = false;
	clientSitesError: string = "";
	stencilIdError: string = "";
	languageCodeError: string = "";
	bodyTextNameError: string = "";
	labelNameError: string = "";
	templateDescriptionError: string = "";
	templateMessageNameError: string = "";
	templateNamespaceError: string = "";

	//On Validation
	onTemplateValidation() {
		if (this.isNotValid) {
			this.validation();
		}
	}
	validation() {
		this.resetError();
		this.bodyTextNameError = (this.bodyListValues == "") ? "Input Required" : "";
		this.clientSitesError = (this.clientSiteId == null) ? "Input Required" : "";
		this.stencilIdError = (this.stencilId == null) ? "Input Required" : "";
		this.languageCodeError = (this.languageCode == null) ? "Input Required" : "";
		this.labelNameError = (this.templateLabel == null) ? "Input Required" : "";
		this.templateDescriptionError = (this.templateDescription == null) ? "Input Required" : "";
		this.templateMessageNameError = (this.templateMessageName == null) ? "Input Required" : "";
		this.templateNamespaceError = (this.namespace == null) ? "Input Required" : "";
		return this.clientSiteId != null && this.stencilId != null && this.languageCode != null && this.bodyListValues != "" && this.templateLabel != "" && this.templateDescription != "" && this.templateMessageName != "" && this.namespace != "";
	}
	resetError() {
		this.clientSitesError = "";
		this.stencilIdError = "";
		this.languageCodeError = "";
		this.bodyTextNameError = "";
		this.labelNameError = "";
		this.templateDescriptionError = "";
		this.templateMessageNameError = "";
		this.templateNamespaceError = "";
	}

	TemplateFormStatus() {
		setTimeout(() => {
			if (this.templateMessageService.templateStatus == true) {
				this.loader = !this.loader;
				this.location.back();
			} else if (this.templateMessageService.templateStatus == false) {
				this.loader = !this.loader;
			}
		}, 3000)
	}

	//Clean null Value for template
	deleteNull(obj) {
		for (var prop in obj) {
			if (typeof obj[prop] === 'object' && obj[prop] !== null) {
				obj[prop] = this.deleteNull(obj[prop]);
				if (Object.keys(obj[prop]).length === 0) {
					delete obj[prop];
				}
			}
			if (obj[prop] === null || obj[prop] === undefined || obj[prop] === 'null') {
				delete obj[prop];
			}
		}
		return obj;
	}

	deleteEmptyKey(updateData) {
		Object.keys(updateData).forEach(key => {
			if (updateData[key] === null || updateData[key] == '') {
				delete updateData[key];
			}
		});
	}

	bodyTextMessageView() {
		var el: any;
		el = document.getElementById("bodyText");
		if (el) {
			el.style.height = "auto";
			el.style.height = (5 + el.scrollHeight) + "px";
		}
	}


	/** Add / Update Itrsbot */
	newItrs(itrsbot = null, text, statsu: any = null): void {
		var itrsbotData: any;
		if (this.clientSiteId) {
			itrsbotData = {
				client_site_id: this.clientSiteId,
				btnId: itrsbot,
				btnName: text,
			}
		} else {
			this.alertService.webShow('Danger', 'Please Select Account');
		}
		this.templateMessageService.changelinkItrs(itrsbotData);
		let dialogRef = this.matDialog.open(ItrsbotComponent, {
			width: "600px",
			data: {
				action: (statsu) ? 'edit' : 'new',
				item: itrsbotData,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				let actionButonId = response[1].btnId;
				const itrsId = response[1].itrsId;
				const itrsbot_id = response[1].itrsbotId;
				const buttonName = response[1].buttonName
				var itrs: any;
				if (this.actionList && this.actionList.length > 0) {
					this.actionList.forEach(action => {

						if (action.index == actionButonId) {
							action.intractiveId = itrsId;
							itrs = {
								"button_id": actionButonId,
								"intractiveId": itrsId,
								"button_text": buttonName
							}
						}
					});
				}
				this.alertService.webShow('success', 'Intractive Added Success');
				this.itrsDataList['button'].push(itrs);
				this.itrsDataList['itrsId'] = itrsbot_id;
				this.processActionList();
				this.cd.detectChanges();
			});
	}

	deleteItrs(i) {
		if (this.actionList && this.actionList.length > 0) {
			this.actionList.forEach(action => {
				if (action.index == i) {
					action.intractiveId = null;
				}
			});
		}
		if (this.itrsDataList && this.itrsDataList.button) {
			this.itrsDataList.button.forEach((data, index) => {
				if (data.button_id == i) {
					this.itrsDataList.button.splice(index, 1);
				}
			});
		}
		this.alertService.webShow('Danger', 'Intractive Removed success');
		this.processActionList();
	}

	isTemplateCreate = true;
	selectClientSite(clientSite: any) {
		this.isTemplateCreate = true;
		var clientSiteData = this.clientSites.filter(client => client.id == clientSite);
		var clientSiteFilter = clientSiteData[0]
		if (clientSiteFilter['service_provider'] != 'option-2') {
			delete this.clientSiteId;
			this.isTemplateCreate = false;
			this.alertService.webShow('Danger', 'This client site not allowed to create template');
		}
	}
}
