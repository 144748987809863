import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	TeamService, Team,
	IntractiveGroupService, IntractiveGroup,
	ModalIntractiveGroupService, ModalIntractiveGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-mapteamform',
	templateUrl: './mapTeamForm.component.html',
	styleUrls: ['./mapTeamForm.component.scss']
})

export class MapTeamFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Map Team';
	authUser: User;
	teams: Team[] = [];
	modalIntractiveGroups: ModalIntractiveGroup[] = [];
	intractiveGroup: IntractiveGroup;
	action: string;
	mapTeamForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public teamService: TeamService,
		public intractiveGroupService: IntractiveGroupService,
		public modalIntractiveGroupService: ModalIntractiveGroupService,
		public matDialogRef: MatDialogRef<MapTeamFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.teamService.getAllItems();
		this.teamService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.teams = data);
		this.modalIntractiveGroupService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.modalIntractiveGroups = data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.mapTeamForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			resource_ids: [null, [Validators.required]],
			resource_type: ['team', [Validators.required]],
			intractive_group_id: [this.intractiveGroup.id || null, [Validators.required]],
			client_id: [this.intractiveGroup.client_id || this.userService.getAuthClientId() || null, [Validators.required]],
		});
	}

	checkTeamSelected(team: Team): boolean {
		if(this.modalIntractiveGroups && this.modalIntractiveGroups.length > 0) {
			return (this.modalIntractiveGroups.filter(x => {
				return (x && x.resource_id === team.id && x.resource_type === 'team' && 
					x.intractive_group_id === this.intractiveGroup.id && x.client_id === this.intractiveGroup.client_id) ? true : false;
			}).length > 0) ? true : false;
		}
		return false;
	}


}
