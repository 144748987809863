import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Notify } from './notify.interface';
import { NotifyModel } from './notify_model.model';
import { UnreadCount } from './unreadCount.interface';
import { UnreadCountModel } from './unreadCount_model.model';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
    providedIn: 'root',
})
@UntilDestroy()

export class NotifyService implements Resolve<any> {
    url: string = 'notify';
    routeParams: any;
    defaultParams: any = {
        'paginate': 25,
        'page': 1,
        'search': '',
    };

    sortIdentity: any = {
        'name': 'name'
    };

    @Output() onChangeItem = new EventEmitter();
    @Output() onChangeAllItems = new EventEmitter();

    private allItemsSource = new BehaviorSubject<Notify[]>([]);
    allItems = this.allItemsSource.asObservable();

    private itemSource = new BehaviorSubject<Notify>(new NotifyModel({}));
    item = this.itemSource.asObservable();

    private unreadCountsSource = new BehaviorSubject<UnreadCount[]>([]);
    unreadCounts = this.unreadCountsSource.asObservable();

    private totalItemSource = new BehaviorSubject<number>(0);
    totalItem = this.totalItemSource.asObservable();

    private displayItemsSource = new BehaviorSubject<Notify[]>([]);
    displayItems = this.displayItemsSource.asObservable();

    private paramsSource = new BehaviorSubject<any>(this.defaultParams);
    params = this.paramsSource.asObservable();

    private _unsubscribeAll: Subject<any>;

    constructor(private globalService: GlobalService,
        private commonService: CommonService) {
        this._unsubscribeAll = new Subject();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.resetParams(),
                this.getAllItems(),
                this.getItem(),
                // this.getUnreadCounts(),
            ])
                .then(() => {
                    resolve(null);
                }, reject
                );
        });
    }

    unSubscribe() {
        // console.log('UnSubscribed NotifyService');
    }

    unSubscribeFilter() {
        // console.log('UnSubscribed Filters on NotifyService');
    }

    changeAllItems(allItems: Notify[]) {
        this.allItemsSource.next(allItems);
        this.onChangeAllItems.emit(allItems);
    }

    changeItem(item: Notify) {
        this.itemSource.next(item);
        this.onChangeItem.emit(item);
    }

    changeUnreadCounts(allCounts: UnreadCount[]) {
        this.unreadCountsSource.next(allCounts);
    }

    changeTotalItem(total: number) {
        this.totalItemSource.next(total);
    }

    changeDisplayItems(displayItems: Notify[]) {
        this.displayItemsSource.next(displayItems);
    }

    changeParams(parms: any) {
        this.paramsSource.next(parms);
    }

    changeAllItemsByItem(item: Notify) {
        let allItems = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
        if (allItems && allItems.length > 0) {
            for (var i = 0; i < allItems.length; ++i) {
                if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
            }
        }
        this.changeAllItems(allItems);
    }

    paramsInit(params: any) {
        let newParams: any;
        let key: any;
        if (params !== null) {
            newParams = params;
        } else {
            this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
        }

        for (key in newParams) {
            if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
                delete newParams[key];
            }
        }
        return newParams;
    }

    resetParams() {
        const defaultParams: any = {
            'paginate': 25,
            'page': 1,
            'search': '',
        };
        this.changeParams(this.paramsInit(defaultParams));
    }

    getAllItems(params: any = null) {
        params = this.paramsInit(params);
        this.commonService.getAll(this.url, params)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                if (params.all && params.all === 1) {
                    this.changeAllItems(data.data);
                    this.changeTotalItem(data.data.length);
                } else {
                    this.changeAllItems(data.data.data);
                    this.changeTotalItem(data.data.total);
                }
            },
                error => console.log('Error ::' + error)
            );
    }

    concatAllItems(params: any = null) {
        params = this.paramsInit(params);
        this.commonService.getAll(this.url, params)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                let lists = [];
                this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
                    lists = result.concat(data.data.data);
                    if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
                        params.page = data.pagnitation.last;
                        this.changeParams(params);
                    }
                });
                this.changeAllItems(lists);
            },
                error => console.log('Error ::' + error)
            );
    }

    concatItem(item: Notify) {
        let lists = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
            lists = result.concat([item]);
        });
        this.changeAllItems(lists);
    }

    spliceItem(id: number) {
        let allItems = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
        if (allItems && allItems.length > 0) {
            for (var i = 0; i < allItems.length; ++i) {
                if (allItems[i].id === id) { allItems.splice(i, 1); }
            }
        }
        this.changeAllItems(allItems);
    }

    getItem(params: any = null) {
        this.routeParams = (params) ? params : this.routeParams;
        if (this.routeParams && this.routeParams.id > 0) {
            this.commonService.getItem(this.url, this.routeParams.id)
                .pipe(untilDestroyed(this, 'unSubscribe'))
                .subscribe(data => {
                    this.changeAllItemsByItem(data.data);
                    this.changeItem(data.data);
                },
                    error => console.log('Error ::' + error)
                );
        }
        else {
            this.changeItem(new NotifyModel({}));
        }
    }

    store(data: any) {
        this.commonService.storeItem(this.url, data)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.concatItem(data.data);
                this.changeItem(data.data);
            },
                error => console.log('Error ::' + error)
            );
    }

    update(id: number, data: any) {
        this.commonService.updateItem(this.url, id, data)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.changeAllItemsByItem(data.data);
                this.changeItem(data.data);
            },
                error => console.log('Error ::' + error)
            );
    }

    destroy(id: number) {
        this.commonService.deleteItem(this.url, id)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.spliceItem(id);
            },
                error => console.log('Error ::' + error)
            );
    }

    getUnreadCounts(params: any = null) {
        let url = 'dashboard/unread-counts';
        this.commonService.getAll(url, params)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.changeUnreadCounts(data.data);
            },
                error => console.log('Error ::' + error)
            );
    }

    getByUrl(params: any = null, url = null) {
        params = this.paramsInit(params);
        let newUrl: string = (url) ? url : this.url;
        this.commonService.getAll(newUrl, params)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.changeAllItems(data.data);
                // this.changeTotalItem(data.data.length);
            },
                error => console.log('Error ::' + error)
            );
    }

    markAsRead(id, url = null) {
        let newUrl: string = (url) ? url : 'mark-read';
        this.commonService.getAll(newUrl + '/' + id)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.spliceItem(id);
            },
                error => console.log('Error ::' + error)
            );
    }

    markAsAllRead(url = null) {
        let newUrl: string = (url) ? url : 'mark-all-read';
        this.commonService.getAll(newUrl)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.changeAllItems([]);
            },
                error => console.log('Error ::' + error)
            );
    }



    /** Scroll Event */
    onScroll() {
        let newParams: any;
        this.params
            .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page += 1;
                this.changeParams(newParams);
                this.concatAllItems();
            });
    }

    /** Search Event */
    onSearch(input: string) {
        let newParams: any;
        this.params
            .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page = 1;
                newParams.search = input;
                this.changeParams(newParams);
                this.getAllItems();
            });
    }

    pageEvent(event) {
        let newParams: any;
        this.params
            .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page = event.pageIndex + 1;
                newParams.paginate = event.pageSize;
                this.changeParams(newParams);
                this.getAllItems();
            });
    }

    getSortName(input: string) {
        let sortName = 'name';
        sortName = (input) ? this.sortIdentity[input] : sortName;
        return sortName;
    }

    sortData(event) {
        let newParams: any;
        this.params
            .pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page = 1;
                newParams.order = this.getSortName(event.active) + '|' + event.direction;
                this.changeParams(newParams);
                this.getAllItems();
            });
    }


    increment(site:string, name: string) {
        // let unreadCounts = [];
        // this.unreadCounts.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => unreadCounts = data);
        // if (unreadCounts && unreadCounts.length > 0) {
        //     for (var i = 0; i < unreadCounts.length; ++i) {
        //         if (unreadCounts[i].site.toLowerCase() === site.toLowerCase() &&
        //             unreadCounts[i].name.toLowerCase() === name.toLowerCase()) {
        //             unreadCounts[i].count++;
        //         }
        //     }
        // }
        // this.changeAllItems(unreadCounts);
        return;
    }

    decrement(site:string, name: string) {
        // let unreadCounts = [];
        // this.unreadCounts.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => unreadCounts = data);
        // if (unreadCounts && unreadCounts.length > 0) {
        //     for (var i = 0; i < unreadCounts.length; ++i) {
        //         if (unreadCounts[i].site.toLowerCase() === site.toLowerCase() &&
        //             unreadCounts[i].name.toLowerCase() === name.toLowerCase()) {
        //             unreadCounts[i].count--;
        //             if(unreadCounts[i].count < 0) { unreadCounts[i].count = 0; }
        //         }
        //     }
        // }
        // this.changeAllItems(unreadCounts);
        return;
    }

}
