import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveService, Intractive,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-itrs-shortCodeForm',
	templateUrl: './shortCodeForm.component.html',
	styleUrls: ['./shortCodeForm.component.scss']
})

export class ShortCodeFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Add / Update Short Code';
	authUser: User;
	intractive: Intractive;
	action: string;
	shortCodeForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public intractiveService: IntractiveService,
		public matDialogRef: MatDialogRef<ShortCodeFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractive = data;
			this.getInit(data);
			this.shortCodeForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = (data && data.id) ? 'edit' : 'new';
		switch (this.action) {
			case 'new':
				this.dialogTitle = 'Add / Update Short Code';
				break;
			case 'edit':
				this.dialogTitle = 'Add / Update Short Code';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			command: [this.intractive.shortCode || null, [Validators.required, Validators.minLength(1)]],
			client_id: [this.intractive.client_id || null, [Validators.required]],
			client_site_id: [this.intractive.resource_id || null, [Validators.required]],
			resource_id: [this.intractive.resource_id || null, [Validators.required]],
			resource_type: [this.intractive.resource_type || null, [Validators.required]],
			intractive_id: [this.intractive.id || null, [Validators.required]],
			itrsbot_id: [this.intractive.itrsbot_id || null, [Validators.required]],
		});
	}
}
