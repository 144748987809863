import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import { TemplateUsageService, TemplateUsage, TemplateSuccessService, TemplateFailedService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'template-template-history-view',
	templateUrl: './template-history-view.component.html',
	styleUrls: ['./template-history-view.component.scss']
})

export class TemplateHistoryViewComponent implements OnInit, OnDestroy {
	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
	@Output() onClose = new EventEmitter();
	@Input() status: string;
	loader: boolean = false;

	templateUsage: TemplateUsage;
	templateUsageList: any;
	templateSuccessDataLength: number = 0;
	templateFailedDataLength: number = 0;
	templateSuccessLoader: boolean = false;
	templateFailedLoader: boolean = false;
	templateFailedSubLoader: boolean = false;
	templateSuccessSubLoader: boolean = false;
	tempSuccessParam: any;
	templateFailedParam: any;
	isSearchSucess: boolean = false;
	isSearchFailed: boolean = false;

	constructor(public templateUsageService: TemplateUsageService, public templateSuccessService: TemplateSuccessService, public templateFailedService: TemplateFailedService,
		private router: Router,
		public matDialog: MatDialog) {
		this.templateUsageService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsage = data);
		this.templateSuccessService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.templateUsageList = data);
		this.templateFailedService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.templateUsageList = data);
		this.templateFailedService.loader.pipe(untilDestroyed(this)).subscribe(data => this.templateFailedLoader = data);
		this.templateSuccessService.loader.pipe(untilDestroyed(this)).subscribe(data => this.templateSuccessLoader = data);
		this.templateFailedService.subLoader.pipe(untilDestroyed(this)).subscribe(data => this.templateFailedSubLoader = data);
		this.templateSuccessService.subLoader.pipe(untilDestroyed(this)).subscribe(data => this.templateSuccessSubLoader = data);
		this.templateSuccessService.params.pipe(untilDestroyed(this)).subscribe(data => this.tempSuccessParam = data);
		this.templateFailedService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateFailedParam = data);
		this.templateSuccessService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.templateSuccessDataLength = data);
		this.templateFailedService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.templateFailedDataLength = data);
		
	}

	ngOnInit() {
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.templateUsageService.changeItem(null);
		this.templateSuccessService.allItemsSource.next(null)
		this.templateFailedService.allItemsSource.next(null)
		this.templateSuccessService.resetParams();
		this.templateFailedService.resetParams();
		this.templateUsageService.unSubscribe();
	}

	searchSuccessKeyPress(event) {
		this.tempSuccessParam.search = event.target.value;
		this.tempSuccessParam.limit = 50;
		this.tempSuccessParam.current = 1;
		this.templateSuccessService.getSearchAllItems();
	}

	searchFailedKeyPress(event) {
		this.templateFailedParam.search = event.target.value;
		this.templateFailedParam.limit = 50;
		this.templateFailedParam.current = 1;
		this.templateFailedService.getAllItems();
	}

}
