import { Component, OnInit, Inject, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Media } from 'src/app/@tji/_dbShare';
import { Subscription } from 'rxjs';

import { PanZoomConfig, PanZoomAPI, PanZoomModel, PanZoomConfigOptions, Rect, PanZoomComponent } from 'ngx-panzoom';
import * as utils from './utils';

@Component({
  selector: 'app-media-view',
  templateUrl: './mediaView.component.html',
  styleUrls: ['./mediaView.component.scss'],
})

export class MediaViewComponent implements OnInit {

  subLoader: boolean = false;
  mediaData: any;
  mediaUrl: string = null;
  mediaType: string = null;
  panZoomConfig: PanZoomConfig = new PanZoomConfig({
    freeMouseWheel: false,
    //     zoomLevels: 5,
    //     scalePerZoomLevel: 1.4,
    // initialZoomLevel: 0,
    invertMouseWheel: true,
    // dragMouseButton: 'left',

    zoomLevels: 10,
    scalePerZoomLevel: 2.0,
    zoomStepDuration: 0.2,
    freeMouseWheelFactor: 0.01,
    zoomToFitZoomLevelFactor: 0.9,
    dragMouseButton: 'left',
  });
  contentItems: any;

  constructor(public dialogRef: MatDialogRef<MediaViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private el: ElementRef,
    private changeDetector: ChangeDetectorRef,) {
    if (data && data.item && data.item.type === 'image') {
      this.contentItems = data.item.url;
      this.mediaData = data.item;
      this.mediaUrl = data.item.url;
      this.mediaType = data.item.type;
      this.subLoader = false;
    } else if (data && data.item.type === 'video' || data.item.type === 'audio') {
      this.mediaData = data.item;
      this.mediaUrl = data.item.url;
      this.mediaType = data.item.type;
      this.subLoader = false;
    } else if (data && data.item) {
      this.mediaData = data.item;
      this.mediaUrl = data.item.url;
      this.mediaType = data.item.type;
      this.subLoader = false;
    }
    // this.onCenterContentClicked();
  }

  ngOnInit() {
    this.setSubLoader();
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 1000);
  }

  private panZoomConfigOptions: PanZoomConfigOptions = {
    zoomLevels: 10,
    scalePerZoomLevel: 2.0,
    zoomStepDuration: 0.2,
    freeMouseWheelFactor: 0.01,
    zoomToFitZoomLevelFactor: 0.9,
    dragMouseButton: 'left'
  };
  panzoomConfig: PanZoomConfig;
  private panZoomAPI: PanZoomAPI;
  private apiSubscription: Subscription;
  panzoomModel: PanZoomModel;
  private modelChangedSubscription: Subscription;
  canvasWidth = 2400;
  initialZoomHeight: number; // set in resetZoomToFit()
  initialZoomWidth = this.canvasWidth;
  scale: number;
  initialised = false

  ngAfterViewInit(): void {
    this.panzoomConfig = this.initPanzoomConfig();
    this.initialZoomHeight = this.panzoomConfig.initialZoomToFit?.height;
    this.scale = this.getCssScale(this.panzoomConfig.initialZoomLevel)
    this.changeDetector.detectChanges();

    this.apiSubscription = this.panzoomConfig.api.subscribe(
      (api: PanZoomAPI) => this.panZoomAPI = api
    );
    this.modelChangedSubscription = this.panzoomConfig.modelChanged.subscribe(
      (model: PanZoomModel) => this.onModelChanged(model)
    );
    this.initialised = !!this.panzoomConfig // && initialZoomHeight
    this.changeDetector.detectChanges()
  }



  ngOnDestroy(): void {
    this.modelChangedSubscription.unsubscribe();
    this.apiSubscription.unsubscribe();
  }



  private initPanzoomConfig(): PanZoomConfig {
    return {
      ...new PanZoomConfig(this.panZoomConfigOptions),
      // initialZoomToFit: this.getInitialZoomToFit()
    };
  }



  onModelChanged(model: PanZoomModel): void {
    this.panzoomModel = utils.deepCopy(model);
    this.scale = this.getCssScale(this.panzoomModel.zoomLevel);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }



  private getCssScale(zoomLevel: any): number {
    return Math.pow(this.panzoomConfig.scalePerZoomLevel, zoomLevel - this.panzoomConfig.neutralZoomLevel);
  }



  getInitialZoomToFit(): Rect {
    const width = this.el.nativeElement.clientWidth;
    const height = this.canvasWidth * this.el.nativeElement.clientHeight / width;
    return {
      x: 0,
      y: 0,
      width: this.canvasWidth,
      height
    };
  }



  onZoomInClicked(): void {
    this.panZoomAPI.zoomIn('viewCenter');
  }



  onZoomOutClicked(): void {
    this.panZoomAPI.zoomOut('viewCenter');
  }



  onResetViewClicked(): void {
    this.panZoomAPI.resetView();
  }



  onPanLeft100Clicked(): void {
    this.panZoomAPI.panDelta({ x: -100, y: 0 });
  }



  onPanRight100Clicked(): void {
    this.panZoomAPI.panDelta({ x: 100, y: 0 });
  }



  onPanUp100Clicked(): void {
    this.panZoomAPI.panDelta({ x: 0, y: -100 });
  }



  onPanDown100Clicked(): void {
    this.panZoomAPI.panDelta({ x: 0, y: 100 });
  }



  onPanLeftPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: -20, y: 0 });
  }



  onPanRightPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: 20, y: 0 });
  }



  onPanUpPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: 0, y: -20 });
  }



  onPanDownPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: 0, y: 20 });
  }



  onPanToPointClicked(): void {
    this.panZoomAPI.panToPoint({ x: 2400, y: 4270 });
  }


  onCenterContentClicked(): void {
    this.panZoomAPI.centerContent();
  }



  onCenterXClicked(): void {
    this.panZoomAPI.centerX();
  }



  onCenterYClicked(): void {
    this.panZoomAPI.centerY();
  }



  onCenterTopLeftClicked(): void {
    this.panZoomAPI.centerTopLeft();
  }



  onCenterBottomLeftClicked(): void {
    this.panZoomAPI.centerBottomLeft();
  }



  onCenterTopRightClicked(): void {
    this.panZoomAPI.centerTopRight();
  }



  onCenterBottomRightClicked(): void {
    this.panZoomAPI.centerBottomRight();
  }

}
