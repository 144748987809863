import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TemplateMessageService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-itrsbot',
  templateUrl: './itrsbot.component.html',
  styleUrls: ['./itrsbot.component.scss']
})
export class ItrsbotComponent implements OnInit {
  dialogTitle: string = 'Create';
  action: any;
  linkItrs: any;
  ItrsDataList: any;
  itrsId: any;
  itrsData: any;
  noItrsDataList: boolean = false;
  constructor(
    public templateMessageService: TemplateMessageService,
    public matDialogRef: MatDialogRef<ItrsbotComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,

  ) {
    this.templateMessageService.currentItrs.subscribe(data => this.linkItrs = data);
    this.templateMessageService.currentAllItrs.subscribe(data => { this.ItrsDataList = data });
    var data = {
      "client_site_id": this.linkItrs?.client_site_id,
      "limit": 500,
      "current": 1
    }
    this.getInit(_data);
    this.templateMessageService.getIntractiItem(data, 'optionOne');
    // this.getInfo();
  }

  ngOnInit(): void {
  }
  getInit(data) {
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Link To on ITRS';
        break;
      case 'edit':
        this.dialogTitle = 'Link To on ITRS';
        break;
      default:
        break;
    }
  }
  getInfo() {
    if (this.ItrsDataList.itractiveinfo.length > 1) {
      this.noItrsDataList = false;
    } else {
      this.noItrsDataList = true;
    }
  }
  selectItrs(itrsId: any, itrsbot_id: any) {
    this.itrsData = {
      btnId: (this.linkItrs.btnId - 1),
      itrsId: itrsId,
      itrsbotId: itrsbot_id,
      buttonName: this.linkItrs.btnName,
    }
  }
}
