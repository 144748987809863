import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientDeliverySettingService, TeamService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'delivery-settings',
  templateUrl: './delivery-settings.component.html',
  styleUrls: ['./delivery-settings.component.scss']
})
export class DeliveryClientSettingsComponent {
  brandInfo: any;
  regionList: any;
  loader: boolean = true;
  subLoader: boolean = false;
  teams: any;
  updatePrice: any;
  teamsData = {
          "id": 0,
          "name": "Select Team",
          "person_type": "agent",
          "is_default": "0",

        }

  constructor(public clientDeliverySettingService: ClientDeliverySettingService,
    public location: Location,
    public teamService: TeamService,) {
    this.teamService.getAllItems();
    this.clientDeliverySettingService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data !== this.regionList) { this.subLoader = false; }
      this.regionList = data;
    });
    this.clientDeliverySettingService.brandItem.pipe(untilDestroyed(this)).subscribe(data => {
      this.brandInfo = data;
    });
    
    this.teamService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.teams = data
      // if (this.teams && this.teams.length > 0) {
      //   var teamsData = {
      //     "id": 0,
      //     "name": "Select Team",
      //     "person_type": "agent",
      //     "is_default": "0",

      //   }
      //   this.teams.unshift(teamsData);
      // }
    });
  }

  ngOnInit() {
    this.doLoader();
  }

  /** On destroy */
  ngOnDestroy(): void { }


  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  deliveryTeams(data, event) {
    if (event) {
      var settings = {
        "client_site_id": data.client_site_id,
        "team_id": event,
        "regions": [
          {
            "region_id": data.id,
            "is_delivarable": data.is_delivarable ? 'yes' : 'no',
            "price": data.price ? data.price : 0
          }
        ]
      }
      this.clientDeliverySettingService.deliverySettings(settings);
    } else {
      var settings = {
        "client_site_id": data.client_site_id,
        "team_id": null,
        "regions": [
          {
            "region_id": data.id,
            "is_delivarable": data.is_delivarable ? 'yes' : 'no',
            "price": data.price ? data.price : 0
          }
        ]
      }
      this.clientDeliverySettingService.deliverySettings(settings);
    }
  }

  priceUpdate(action, data = null) {
    if (action == 'edit') {
      this.regionList.forEach(price => {
        if (price._id == data._id) {
          price.isEditable = true;
        } else {
          price.isEditable = false;
        }
      });
    }
    if (action == 'remove') {
      this.regionList.forEach(price => {
        price.isEditable = false;
      });
    }
    if (action == 'update') {
      var settings = {
        "client_site_id": data.client_site_id,
        "team_id": data.team_id,
        "regions": [
          {
            "region_id": data.id,
            "is_delivarable": data.is_delivarable ? "yes" : "no",
            "price": this.updatePrice
          }
        ]
      }
      this.clientDeliverySettingService.deliverySettings(settings);
    }
  }

  saveSetting(data, price) {
    this.updatePrice = price;
  }

  deliveryStatus(data, event) {
    if (event.checked) {
      var settings = {
        "client_site_id": data.client_site_id,
        "team_id": data.team_id,
        "regions": [
          {
            "region_id": data.id,
            "is_delivarable": "yes",
            "price": data.price ? data.price : 0
          }
        ]
      }
      this.clientDeliverySettingService.deliverySettings(settings);
    } else {
      var settings = {
        "client_site_id": data.client_site_id,
        "team_id": data.team_id,
        "regions": [
          {
            "region_id": data.id,
            "is_delivarable": "no",
            "price": data.price ? data.price : 0
          }
        ]
      }
      this.clientDeliverySettingService.deliverySettings(settings);
    }
  }

}
