export class WebchatReceiveDataModel {
    client_site_id: number;
    client_id: number;
    consumer_key: string;
    uuid: string;
    text: string;
    from: string;
    to: string;
    media: string;
    people_id: number;

    /**
     * Constructor
     *
     * @param webchatReceiveDataModel
     */
    constructor(webchatReceiveDataModel) {
            this.client_site_id = webchatReceiveDataModel.client_site_id || '';
            this.client_id = webchatReceiveDataModel.client_id || '';
            this.consumer_key = webchatReceiveDataModel.consumer_key || '';
            this.uuid = webchatReceiveDataModel.uuid || '';
            this.text = webchatReceiveDataModel.text || '';
            this.from = webchatReceiveDataModel.from || '';
            this.to = webchatReceiveDataModel.to || '';
            this.media = webchatReceiveDataModel.media || '';
            this.people_id = webchatReceiveDataModel.people_id || '';
    }
}