import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, Role,
	Client, ClientService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'role-assign-agent',
	templateUrl: './assignAgent.component.html',
	styleUrls: ['./assignAgent.component.scss']
})

export class AssignAgentComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	action: string;
	assignAgentForm: UntypedFormGroup;

	clientParam: any;
	client: Client[] = [];
	role: Role;
	isChecked: boolean = true;

	constructor(public userService: UserService,
		public clientService: ClientService,
		public matDialogRef: MatDialogRef<AssignAgentComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientService.params.pipe(untilDestroyed(this)).subscribe(data => this.clientParam = data);
		this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (this.client.length > 0 && data.length > 0) {
				// this.client = this.globalService.arrayMergeById(this.client, length);
				if (this.client && this.client.length > 0) {
					for (var i = 0; i < data.length; ++i) {
						var item = data[i];
						for (var j = 0; j < this.client.length; ++j) {
							var oldItem = this.client[j];
							if (oldItem.id == item.id) {
								this.client[j] = item;
								break;
							}
						}
					}
					this.client = this.client.concat(data.filter(x => this.client.every(y => y.id !== x.id)));
				} else {
					this.client = data;
				}
				this.reconstract();
				// return this.client;
			} else {
				this.client = data
				this.reconstract();
			}
		});

		});
	}

	ngOnInit() {
		this.clientParam.limit = 200;
		this.getClient();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getClient() {
		this.clientService.getAllIData();
	}

	reconstract() {
		if (this.client) {
			this.client.forEach(element => {
				element['checked'] = false;
			});
			if (this.role) {
				this.role['users'].forEach(selectAgent => {
					this.client.forEach(agent => {
						if (agent.userInfo.id == selectAgent.model_id) {
							agent['checked'] = true;
						}
					});
				});
			}
		}
	}

	getInit(data) {
		// Set the defaults
		this.role = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Agent to Apply Role';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Agent to Change Role';
				break;
			default:
				break;
		}
		this.assignAgentForm = this.createForm();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			user_id: [null, [Validators.required]],
			id: [(this.role && this.role.id) ? this.role.id : null, [Validators.required]],
			checked: [this.isChecked, [Validators.required]],
		});
	}

	changeAgent(agentId) {
		
		let agentDetails: any = this.client.filter(x => x.userInfo.id === agentId)[0];
		if (agentDetails && agentDetails['checked']) {
			this.isChecked = false;
		} else {
			this.isChecked = true;
		}
		this.assignAgentForm.controls.user_id.setValue(agentDetails.userInfo.id);
		this.assignAgentForm.controls.checked.setValue(this.isChecked);
	}

}
