import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ImageService,
	IntractiveGroupService, IntractiveGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-itrs-video_change_form',
	templateUrl: './changeIntractiveGroupVideo.component.html',
	styleUrls: ['./changeIntractiveGroupVideo.component.scss']
})

export class ChangeIntractiveGroupVideoComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Add / Change Video Asset';
	authUser: User;
	intractiveGroup: IntractiveGroup;
	action: string;
	videoForm: UntypedFormGroup;
	videos: Array<any> = [];

	constructor(public userService: UserService,
		public intractiveGroupService: IntractiveGroupService,
		public imageService: ImageService,
		public matDialogRef: MatDialogRef<ChangeIntractiveGroupVideoComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder,
		private cd: ChangeDetectorRef) {
		// this.intractiveGroup = this._data.item;
		// if(this.intractiveGroup && this.intractiveGroup.videoAssets) {
		// 	this.videos = this.intractiveGroup.videoAssets;
		// }
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			if(this.intractiveGroup && this.intractiveGroup.videoAssets) {
				this.videos = this.intractiveGroup.videoAssets;
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			next_intractive_id: [this.intractiveGroup.next_intractive_id || null, [Validators.required]],
			is_active: [1],
		});
	}

	storeVideo(event, intractiveGroupId = null) {
		intractiveGroupId = (intractiveGroupId) ? intractiveGroupId : this.intractiveGroup.id;
		let resourceData = {
			'resource_type': 'intractive_group',
			'resource_id': intractiveGroupId,
			'storeMode': 'add-itrs-video'
		};
		let reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = (e: any) => {
				let input = {
					'video': e.target.result,
					'video_name': file.name,
					'video_type': file.type,
					'video_file_path': event.target.value,
					'image': e.target.result,
					'image_name': file.name,
					'image_type': file.type,
					'image_file_path': event.target.value,
				};
				this.storeProcess(Object.assign(resourceData, input));
			}
		}
	}

	storeProcess(input) {
		this.intractiveGroupService.post('add-video-asset', input);
	}

	videoHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				// this.intractiveGroup.video_asset = e.target.result;
				let newVideo = [];
				newVideo['url'] = e.target.result;
				newVideo['type'] = 'video';
				this.videos[this.videos.length] = newVideo;
				this.cd.markForCheck();
			};
		}
	}

	removeVideo(intractiveGroupId, videoUrl) {
		intractiveGroupId = (intractiveGroupId) ? intractiveGroupId : this.intractiveGroup.id;
		let input = {
			'intractive_group_id': intractiveGroupId,
			'url': videoUrl,
		};
		this.intractiveGroupService.post('remove-video-asset', input);
	}

	getNamefromUrl($url) {
		let decodeUrl = decodeURIComponent($url);
	}

}
