import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import {
    User, UserService, catalogProduct, CatalogProductService,
    AgentService
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AssignCatalogWabaComponent } from '../assign-catalog-waba/assign-catalog-waba.component';
import { SelectionModel } from '@angular/cdk/collections';
@UntilDestroy({ checkProperties: true })

@Component({
    selector: 'catlog-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    providers: [OrderByPipe]
})
export class ProductListComponent implements OnInit {
    @Output() isEditable = new EventEmitter();
    loader: boolean = true;
    subLoader: boolean = false;
    authUser: User;
    productLists: catalogProduct[] = [];
    param: any;
    usageParam: any;
    dataSource = new MatTableDataSource(this.productLists);
    displayedColumns = ['select', 'image', 'name', 'catalog name', 'price', 'content id', 'status', 'action'];
    @Input() isEditProduct: boolean = false;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    // MatPaginator Output
    pageEvent: PageEvent;
    dataLength: number = 0;
    constructor(public userService: UserService,
        private snackBar: MatSnackBar,
        private router: Router,
        public catalogProductService: CatalogProductService,
        public agentService: AgentService,
        public matDialog: MatDialog,
        private orderByPipe: OrderByPipe) {
        this.userService.user.subscribe(data => this.authUser = data);
        this.catalogProductService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
            if (data) { this.subLoader = false; }
            this.productLists = data;
            // this.productLists = this.orderByPipe.transform(data, '-_id');
            this.dataSource = new MatTableDataSource(this.productLists);
            this.dataSource.sort = this.sort;
        });

        this.catalogProductService.isLoader.pipe(untilDestroyed(this)).subscribe(data => {
            if (data) { this.subLoader = true; } else {
                this.subLoader = false;
            }
        });

        this.catalogProductService.isSelectProduct.pipe(untilDestroyed(this)).subscribe(data => {
            if (data == true) {
                let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
                    disableClose: false,
                    width: '600',
                    data: {
                        type: 'delete',
                        message: 'Are you sure you want to delete?',
                    }
                });
                confirmDialogRef.afterClosed().subscribe(result => {
                    if (result && result.data) {
                        this.deleteProductAll();
                    }
                });
            }
        });

    }


    ngOnInit(): void {
        this.doLoader();
        this.dataInit();
    }
    /** On destroy */
    ngOnDestroy(): void {
        this.catalogProductService.unSubscribeFilter();
        this.catalogProductService.unSubscribe();
        this.catalogProductService.changeAllItems([]);
    }

    doLoader() {
        setTimeout(() => {
            this.loader = !this.loader;
        }, 5000);
    }
    setSubLoader() {
        this.subLoader = true;
        setTimeout(() => {
            this.subLoader = false;
        }, 5000);
    }

    dataInit() {
        this.catalogProductService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
        this.catalogProductService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
    }

    editProduct(catalog: any) {
        this.isEditable.emit(true);
        this.catalogProductService.itemSource.next(catalog);
        this.catalogProductService.getBrand({catalog_id: catalog.catalog_id})
    }

    /** Delete Social Site */
    deleteProduct(item: catalogProduct): void {
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'delete',
                message: 'Are you sure you want to delete?',
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                var id = {
                    product_id: item.id.toString()
                }
                this.catalogProductService.destroy('fbProduct/delete', id);
            }
        });
    }

    linkBussiness(item: catalogProduct) {
        let confirmDialogRef = this.matDialog.open(AssignCatalogWabaComponent, {
            data: { type: 'info' }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result > 0) {
                var mark = {
                    "catalog_id": item.id.toString(),
                    "waba_id": result.toString()
                }
                this.catalogProductService.bussinessActivity("fbCatalog/linkCatalogWaba", mark, item);
            }
        });
    }

    removeBussiness(item: any) {
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'info',
                message: 'Are you sure you want to Unlink the Catalog Waba?',
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                var mark = {
                    "catalog_id": item.id.toString()
                }
                this.catalogProductService.bussinessActivity("fbCatalog/deleteCatalogWaba", mark, item);
            }
        });
    }

    statusChange(id: any, event: any, product: any) {
        var is_active;
        if (event == false) {
            is_active = "0";
        } else {
            is_active = "1";
        }
        var update = {
            "catalog_id": product.catalog_id.toString(),
            "gtin": product.gtin,
            "availability": is_active,
            "id": product.id
        }
        this.catalogProductService.store('fbProduct/manage', [update]);

        // this.agentService.statusUpdate('agent/status', { "id": id, 'is_active': is_active }, agent);
    }

    swipeProduct(item: catalogProduct): void {
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'info',
                message: 'This product will be pushed to the TOP in whatsapp',
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                var payload = {
                    "id": item.id,
                    "ordering_index": 2
                }
                this.catalogProductService.store('fbProduct/update', payload);
            }
        });
    }

    selection = new SelectionModel<any>(true, []);

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        // this.agentService.isSelectAgent = true;
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        if (numSelected > 0) {
            this.agentService.isSelectAgent = true;
        } else {
            this.agentService.isSelectAgent = false;
        }
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.agentService.isSelectAgent = true;
        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        // this.agentService.isSelectAgent = true;
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    deleteProductAll() {
        var selectedAgent = { product_id: [] };
        if (this.selection && this.selection['_selected'] && this.selection['_selected'].length > 0) {
            this.selection['_selected'].forEach(element => {
                if (this.authUser && this.authUser.person_id != element.id) {
                    selectedAgent.product_id.push(element.id);
                }
            });
            // console.log('selectedAgent', selectedAgent);
            this.catalogProductService.destroyAll('fbProduct/delete', selectedAgent);

            setTimeout(() => {
                this.selection.clear();
                this.catalogProductService.isSelectProductSource.next(false);
            }, 1000);
        } else {
            // this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
    }

}