export class AutoTemplateModel {
    _id: number;
    name: string;
    catalog_id: number;
    client_id: number;
    clientSiteInfo?: Array<any>;
    products?: Array<any>;
    total_products: number;
    total_allowed: number;
    catalogName: string;

    /**
     * Constructor
     *
     * @param category
     */
    constructor(category) {
        this._id = category._id || '';
        this.name = category.name || '';
        this.catalog_id = category.catalog_id || '';
        this.client_id = category.client_id || '';
        this.clientSiteInfo = category.clientSiteInfo || {};
        this.products = category.products || {};
        this.total_products = category.total_products || '';
        this.total_allowed = category.total_allowed || '';
        this.catalogName = category.catalogName || '';
    }
}