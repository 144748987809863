import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { ItemComponent } from './item/item.component';

import { ClientService } from 'src/app/@tji/_dbShare';

// import {ScheduleService, CategoryService, SubCategoryService,
//         PlanService, ShiftService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: ClientService },
	},
	{
		path: ':id',
		component: ItemComponent,
		resolve: { data: ClientService },
	},
	{ path: '**', redirectTo: '/reseller/client' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class RClientRouteModule { }
