import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';


import {
  User, UserService,
  PeopleService, People, ClientSiteService, DateModel, ClientSite
} from 'src/app/@tji/_dbShare';


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },

};
@UntilDestroy()

@Component({
  selector: 'app-leadsexportexcel',
  templateUrl: './leadsexportexcel.component.html',
  styleUrls: ['./leadsexportexcel.component.scss'],
  providers: [OrderByPipe]
})

export class LeadsexportexcelComponent implements OnInit, OnDestroy {


  user: User;
  people: People[] = [];
  dialogTitle: string = 'Export Xlsx';
  campaignOne: UntypedFormGroup = new UntypedFormGroup({
    start: new UntypedFormControl(''),
    end: new UntypedFormControl(''),
    client_site_id : new UntypedFormControl(''),
  });
  fromDate: Date;
  toDate: Date;
  today: Date = new Date();
  minDate: Date = new Date(2021, 0, 1);
  maxDate: Date = new Date();
  clientSites: ClientSite[] = [];
  selectedClientId: number;
  sites: ClientSite;

 
  constructor(private formBuilder: UntypedFormBuilder, public userService: UserService, private orderByPipe: OrderByPipe, public peopleService: PeopleService, public clientSiteService: ClientSiteService, public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<LeadsexportexcelComponent>,
  ) {
    this.userService.user.subscribe(data => this.user = data);
    this.getDates();
    this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.clientSites = this.orderByPipe.transform(data, '-id');
    
      // if(this.clientSites.length == 1){
      //   this.campaignOne.get('client_site_id').setValue(this.clientSites.account_name);
      // }
    });

  }

  ngOnInit() {
  }

  /** On destroy */
  ngOnDestroy(): void { }


  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  @ViewChild('select') select: MatSelect;

  getDates() {
    let date = new Date();
    this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    this.toDate = this.today;
    this.campaignOne = new UntypedFormGroup({
      start: new UntypedFormControl(this.fromDate),
      end: new UntypedFormControl(this.toDate),
      client_site_id: new UntypedFormControl(this.selectedClientId)
    });
   }

  onChangeFromDate(event) {
    this.fromDate = event.target.value;
    let maxToDate = this.today;
    maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
    this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
    this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;

  }

  onChangeToDate(event) {
    this.toDate = event.target.value;
    let maxToDate = this.today;
    maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
    this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
    this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;


  }

  onSelectClientSite(selectedClientId: number) {
    this.selectedClientId = selectedClientId;
  }
  
}
