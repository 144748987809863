import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSidenav } from '@angular/material/sidenav';
import { FormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import { User, UserService, 
		 Site, SiteService, 
		 ClientSiteService, ClientSiteModel } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-socialsite-medialist',
	templateUrl: './socialMediaList.component.html',
	styleUrls: ['./socialMediaList.component.scss']
})

export class SocialMediaListComponent implements OnInit, OnDestroy {
	user: User;
	isAdmin: any;
	@ViewChild('rightSideBar') rightSideBar: MatSidenav;

	param: any;
	site: Site;
	siteId: number = Number(this.route.snapshot.paramMap.get('mediaId'));

	constructor(public userService: UserService,
		public siteService: SiteService,
		public location: Location ,
		private router: Router,
		private route: ActivatedRoute,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.isAdmin = this.userService.isAuthUserIsAdmin();
		this.siteService.item.pipe(untilDestroyed(this)).subscribe(data => this.site = data);
	}

	ngOnInit() {
	}

	/** On destroy */
	ngOnDestroy(): void { }



	getTitle(): string {
		if(this.site && this.site.name) {
			return "All " + this.site.name + " Accounts";
		}
		return "Social Media Accounts";
	}

	toggleRightSidebar() {
		this.rightSideBar.toggle();
	}
	
}
