export class IntractiveModel {
	id: number;
	intractive_category_id: number;
	client_id: number;
	itrsbot_id: number;
	resource_id: number;
	resource_type: string;
	name: string;
	code: string;
	is_multiple: boolean;
	is_active: boolean;
	is_ticketable: boolean;
	is_seperate_message: boolean;
	is_begin: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	template_id: number;
	intractive_message_id: number;
	jump_intractive_id?: number;

	intractiveCategoryName?: string;
	siteName?: string;
	intractive_groups?: Array<any>;
	intractive_category?: any;
	resource?: any;

	tableName: string;
	shortCode?: string;
	templateCode?: any;
	intractiveMessageName?: string;
	intractive_groups_through_modal_intractive_groups?: Array<any>;
	jumpIntractiveName?: string;
	clientSiteName?: string;
	clientSiteColor?: string;
	clientSiteTextColor?: string;
	language: string;
	track_it: boolean;

	/**
	 * Constructor
	 *
	 * @param intractive
	 */
	constructor(intractive) {
		this.id = intractive.id || '';
		this.intractive_category_id = intractive.intractive_category_id || '';
		this.client_id = intractive.client_id || '';
		this.resource_id = intractive.resource_id || '';
		this.resource_type = intractive.resource_type || '';
		this.name = intractive.name || '';
		this.code = intractive.code || '';
		this.is_multiple = intractive.is_multiple || '';
		this.is_active = intractive.is_active || '';
		this.is_ticketable = intractive.is_ticketable || '';
		this.is_seperate_message = intractive.is_seperate_message || null;
		this.is_begin = intractive.is_begin || '';
		this.created_by = intractive.created_by || '';
		this.updated_by = intractive.updated_by || '';
		this.created_at = intractive.created_at || '';
		this.updated_at = intractive.updated_at || '';
		this.template_id = intractive.template_id || '';
		this.intractive_message_id = intractive.intractive_message_id || '';
		this.jump_intractive_id = intractive.jump_intractive_id || null;

		this.tableName = intractive.tableName || '';
		this.language = intractive.language || null;
		this.track_it = intractive.track_it || false;
	}
}
