import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { LogRouteModule } from './log-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';

import { LogService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        LogRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ListComponent,
    ],
    providers: [
        LogService
    ]
})
export class LogModule { }
