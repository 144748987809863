import { Permission } from 'src/app/@tji/_dbShare/roles/permission';

export class RRoleModel {
    id: number;
    name: string;
    guard_name: string;
    created_at: Date;
    updated_at: Date;

    permissions?: Permission[];
    permission?: Permission[];
    activeUsersCount?: number; 
    permissionIdArray?: Array<any>; 

    tableName?: string;

    /**
     * Constructor
     *
     * @param role
     */
    constructor(role) {
            this.id = role.id || '';
            this.name = role.name || '';
            this.guard_name = role.guard_name || '';
            this.created_at = role.created_at || '';
            this.updated_at = role.updated_at || '';
    }
}
