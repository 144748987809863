import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { TemplateUsage } from './templateUsage.interface';

import { TemplateUsageModel } from './templateUsage_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class TemplateUsageService implements Resolve<any> {
	url: string = 'template/usage';
	routeParams: any;
	defaultParams: any = {
		'limit': 100,
		'current': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<TemplateUsage[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<TemplateUsage>(new TemplateUsageModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private totalCountSource = new BehaviorSubject<number>(0);
	totalCount = this.totalCountSource.asObservable();

	private successCountSource = new BehaviorSubject<number>(0);
	successCount = this.successCountSource.asObservable();

	private failedCountSource = new BehaviorSubject<number>(0);
	failedCount = this.failedCountSource.asObservable();

	private displayItemsSource = new BehaviorSubject<TemplateUsage[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private completedCountsSource = new BehaviorSubject<number>(0);
	completedCounts = this.completedCountsSource.asObservable();

	private rejectedCountSource = new BehaviorSubject<number>(0);
	rejectedCount = this.rejectedCountSource.asObservable();
	

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				// this.getAllItems(),
				this.getAllListItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TemplateUsageService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TemplateUsageService');
	}

	changeAllItems(allItems: TemplateUsage[]) {
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: TemplateUsage) {
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeTotalCount(totalCount: number) {
		this.totalCountSource.next(totalCount);
	}

	changeSuccessCount(successCount: number) {
		this.successCountSource.next(successCount);
	}

	changeFailedCount(failedCount: number) {
		this.failedCountSource.next(failedCount);
	}

	changeCompletedCounts(total: number) {
		this.completedCountsSource.next(total);
	}

	changeRejectedCount(count: number) {
		this.rejectedCountSource.next(count);
	}

	changeDisplayItems(displayItems: TemplateUsage[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: TemplateUsage) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 100,
			'current': 1,
			'search': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		if (this.routeParams && this.routeParams.templateId) {
			params.template_id = this.routeParams.templateId;
		}
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.client_info
						var usersInfo = data.additional.users_info
						this.changeTotalCount(data.additional.totalCount);
						this.changeSuccessCount(data.additional.successCount);
						this.changeFailedCount(data.additional.failedCount);
						this.changeRejectedCount(data.additional.rejectedCount);
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"id": usage.id,
								"jobcode": usage.jobcode,
								"template_id": usage.template_id,
								"client_id": usage.client_id,
								"client_site_id": usage.client_site_id,
								"is_sendlater": usage.is_sendlater,
								"total_count": usage.total_count,
								"success_count": usage.success_count,
								"failed_count": usage.failed_count,
								"is_completed": usage.is_completed,
								"total_retries": usage.total_retries,
								"created_by": usage.created_by,
								"updated_by": usage.updated_by,
								"deleted_at": usage.deleted_at,
								"progress_status": usage.progress_status,
								"stage": usage.stage,
								"clientName": clientInfo.name,
								"clientAvatar": clientInfo.details.image_api,
								"created_at": usage.created_at, //moment(usage.created_at + '.000+0300').local(),
								"updated_at": usage.created_at //moment(usage.updated_at + '.000+0300').local(),
							}
							if (usersInfo) {
								if (usage.template_info) {
									dataFormats["templateCode"] = usage.template_info.code;
									dataFormats["templateName"] = usage.template_info.name;
								}
							}
							usersInfo.forEach(user => {
								if (usage.created_by == user.id) {
									dataFormats["senderName"] = user.username
									dataFormats["senderEmail"] = user.email
								} else if (usage.created_by == 1) {
									dataFormats["senderName"] = "API"
								}
							});
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllListItems(params: any = null) {
		params = this.paramsInit(params);
		if (this.routeParams && this.routeParams.templateId) {
			params.template_id = this.routeParams.templateId;
		}
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.client_info
						var usersInfo = data.additional.users_info
						this.changeTotalCount(data.additional.templateCount.totalCount);
						this.changeSuccessCount(data.additional.templateCount.successCount);
						this.changeFailedCount(data.additional.templateCount.failedCount);
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"id": usage.id,
								"jobcode": usage.jobcode,
								"template_id": usage.template_id,
								"client_id": usage.client_id,
								"client_site_id": usage.client_site_id,
								"is_sendlater": usage.is_sendlater,
								"total_count": usage.total_count,
								"rejected_count": usage.rejected_count,
								"success_count": usage.success_count,
								"failed_count": usage.failed_count,
								"is_completed": usage.is_completed,
								"total_retries": usage.total_retries,
								"created_by": usage.created_by,
								"updated_by": usage.updated_by,
								"deleted_at": usage.deleted_at,
								"progress_status": usage.progress_status,
								"stage": usage.stage,
								"clientName": clientInfo.name,
								"clientAvatar": clientInfo.details.image_api,
								"created_at": usage.created_at, //moment(usage.created_at + '.000+0300').local(),
								"updated_at": usage.created_at //moment(usage.updated_at + '.000+0300').local(),
							}
							if (usage.template_info) {
								dataFormats["templateCode"] = usage.template_info.code;
								dataFormats["templateName"] = usage.template_info.name;
							}
							if (usersInfo) {
								usersInfo.forEach(user => {
									if (usage.created_by == user.id) {
										dataFormats["senderName"] = user.username
										dataFormats["senderEmail"] = user.email
									} else if (usage.created_by == 1) {
										dataFormats["senderName"] = "API"
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new TemplateUsageModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
			});
	}

	/** Search Event */
	onSearch(input: string, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllListItems();
			});
	}

	pageEvent(event, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllListItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllListItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(type: string, url: string = null) {
		let domain = this.commonService.backendUrl;
		let ch_url = '';

		if (type == 'excel') {
			ch_url = (url) ? domain + url : domain + 'template/people-export/';
		} else if (type == 'pdf') {
			ch_url = (url) ? domain + url : domain + 'person/people-exportpdf/';
		}

		return ch_url;
	}

}
