import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  User, UserService,
  RTemplateUsageReportsService, RTemplateUsageReports, ClientSiteService, ClientSite, AlertService
} from 'src/app/@tji/_dbShare';


@UntilDestroy()
@Component({
  selector: 'app-template-delivery-report',
  templateUrl: './template-delivery-report.component.html',
  styleUrls: ['./template-delivery-report.component.scss']
})
export class TemplateDeliveryReportComponent implements OnInit, OnDestroy {
  dialogTitle: string = 'Delivery Report';
  action: string;
  deliveryReport: any;
  template: any;
  downloadLoader: boolean = false;

  constructor(public userService: UserService,
    public rtemplateUsageReportsService: RTemplateUsageReportsService, public clientSiteService: ClientSiteService,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<TemplateDeliveryReportComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,) {
    this.getInit(_data);
    this.rtemplateUsageReportsService.deliveryTemplate.pipe(untilDestroyed(this)).subscribe(data => {
      this.deliveryReport = data;
    });
    this.rtemplateUsageReportsService.downloadLoader.pipe(untilDestroyed(this)).subscribe(data => this.downloadLoader = data);
  }

  ngOnInit() {

  }

  /** On destroy */
  ngOnDestroy(): void {
    this.rtemplateUsageReportsService.unSubscribeFilter();
    this.deliveryReport = [];
  }

  getInit(data) {
    this.action = data.action;
    this.template = data?.resource;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Delivery Report';
        break;
      default:
        break;
    }
  }

  exportData() {

    if (this.deliveryReport && this.deliveryReport.deliveryInfo && this.deliveryReport.deliveryInfo.length > 0) {
      this.rtemplateUsageReportsService.isDownloadLoader.next(true);
      var usage = this.deliveryReport.deliveryInfo;
      var dataFormat = [];
      usage.forEach(usage => {
        var dataFormats = {
          "Phone Number": usage.phonenumber ? usage.phonenumber : '--',
          "Sent Status": usage.sentStatus ? usage.sentStatus : '--',
          "Sent Date": usage.SentDate ? usage.SentDate : '--',
          "Delivered Status": usage.deliveredStatus ? usage.deliveredStatus : '--',
          "Delivered Date": usage.deliveredDate ? usage.deliveredDate : '--',
          "Failed Status": usage.failedStatus ? usage.failedStatus : '--',
          "Failed Date": usage.failedDate ? usage.failedDate : '--',
          "Read Status": usage.readStatus ? usage.readStatus : '--',
          "Failed Reason": usage.response ? usage.response : '--',
        }
        dataFormat.push(dataFormats);
      });

      let arrHeader = ["Phone Number", "Sent Status", "Sent Date", "Delivered Status", "Delivered Date", "Failed Status", "Failed Date", "Read Status", "Failed Reason"];
      let csvData = this.ConvertToCSV(dataFormat, arrHeader);
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      // this.downLoadLoader = false;
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      var currentTime = new Date().toJSON();
      dwldLink.setAttribute("download", 'Template_usage_reports_' + currentTime + '.csv');
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      this.rtemplateUsageReportsService.isDownloadLoader.next(false);
      // this.matDialogRef.close();
    }

  }


  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    let newHeaders = ["Phone Number", "Sent Status", "Sent Date", "Delivered Status", "Delivered Date", "Failed Status", "Failed Date", "Read Status", "Failed Reason"];

    for (let index in newHeaders) {
      row += newHeaders[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + this.strRep(array[i][head]);
      }
      str += line + '\r\n';
    }
    return str;
  }

  strRep(data) {
    if (typeof data == "string") {
      if (data == '\"') {
        data = ','
      }
      let newData = data.replace(/,/g, " ");
      // newData.replace(|\|, "colour");
      return newData.toString();
    }
    else if (typeof data == "undefined") {
      return "-";
    }
    else if (typeof data == "number") {
      return data.toString();
    }
    else {
      return data;
    }
  }

  exportSuccessTemplateReport(count: number, temp: any) {
    if (count > 0) {
      this.rtemplateUsageReportsService.exportTemSuccessReport({ "template_usage_id": temp.id, "client_id": temp.client_id, limit: count, current: 1});
    }
  }

   exportTempFailedReport(count: number, temp: any) {
    if (count > 0) {
      this.rtemplateUsageReportsService.exportTempFailedReport({ "template_usage_id": temp.id, "client_id": temp.client_id, limit: count, current: 1});
    }

  }

  exportRejectedTemplateReport(count: number, temp: any){
    if (count > 0) {
      this.rtemplateUsageReportsService.exportTempRejectReport({ "template_usage_id": temp.id});
    }
  }

}
