import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ProfileValidateGuard } from 'src/app/auth/profileValidate.guard';

import { MainComponent } from './main/main.component';

const routes: Routes = [
	{
		path: '',
		component: MainComponent,
		children: [
			{
				path: 'dashboard',
				pathMatch: 'full',
				loadChildren: () => import('src/app/reseller/dashboard/dashboard.module').then(a => a.DashboardModule),
				canActivate: [ProfileValidateGuard],
				data: { dashboard: 'Reseller Dashboard' }
			},
			{
				path: 'socialsite',
				loadChildren: () => import('src/app/reseller/socialSite/rSocialSite.module').then(a => a.RSocialSiteModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'analytics',
				loadChildren: () => import('src/app/reseller/analytics/analytics.module').then(a => a.AnalyticsModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'client',
				loadChildren: () => import('src/app/reseller/client/rclient.module').then(a => a.RClientModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'role-permission',
				loadChildren: () => import('src/app/reseller/roles/rroles.module').then(a => a.RRolesModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'template-usage',
				loadChildren: () => import('src/app/reseller/template-group/rtemplate-group.module').then(a => a.RTemplateGroupModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'report',
				loadChildren: () => import('src/app/reseller/report/report.module').then(a => a.ResellerClientTemplateReportModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'activity',
				loadChildren: () => import('src/app/reseller/log/log.module').then(a => a.RLogModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'template',
				loadChildren: () => import('src/app/reseller/template/template.module').then(a => a.RTemplateImportModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'team',
				loadChildren: () => import('src/app/reseller/team/team.module').then(a => a.RTeamModule),
				canActivate: [ProfileValidateGuard],
			}
		]
	},
	{ path: '**', redirectTo: '/reseller/dashboard' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	],
	exports: [
		RouterModule
	]
})

export class ResellerRouteModule { }
