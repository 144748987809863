import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { FormComponent } from './../form/form.component';
import { FormLinkGroupComponent } from './../formLinkGroup/formLinkGroup.component';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { TemplateFormComponent } from './../templateForm/templateForm.component';

import {
	User, UserService,
	ConsumerService, Consumer, ConsumerModel,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel,
	TemplateMessageService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'consumer-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	consumerGroupsAll: ConsumerGroup[] = [];
	consumerGroups: ConsumerGroup[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.consumerGroups);
	displayedColumns = ['date', 'name', 'totalContacts', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	templateParam: any;

	constructor(public userService: UserService,
		public consumerService: ConsumerService,
		public consumerGroupService: ConsumerGroupService,
		public templateMessageService: TemplateMessageService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.consumerGroupService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.consumerGroupsAll) { this.subLoader = false; }
			this.consumerGroupsAll = data;
			this.consumerGroups = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.consumerGroups);
			this.dataSource.sort = this.sort;
		});
		// this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateParam = data);
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.consumerService.unSubscribe();
		this.consumerService.unSubscribeFilter();
		this.templateMessageService.unSubscribe();
		this.templateMessageService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.consumerService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.consumerGroupService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {this.dataLength = data
		});
		// this.templateParam.limit = 200;
		// this.templateParam.current = 1;
		// // this.templateParam.stencilType = 'intractive-template';
		// this.templateMessageService.getAllItems();
	}

	/** Add / Update Agent */
	newConsumerGroup(consumerGroup = null): void {
		this.consumerGroupService.changeItem((consumerGroup) ? consumerGroup : new ConsumerGroupModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (consumerGroup) ? 'edit' : 'new',
				item: consumerGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.consumerGroupService.consumer('consumer/add', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.consumerGroupService.consumerUpdate('consumer/update', formData.value, consumerGroup);
						break;
					case 'delete':
						// this.deleteConsumerGroup(consumerGroup);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteConsumer(consumerGroup: ConsumerGroup): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					'id': consumerGroup.id
				}
				this.consumerGroupService.destroy('consumer/delete', formData);
			}
		});
	}


	/** Delete Social Site */
	deleteConsumerGroup(consumerGroup: ConsumerGroup): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					'id': consumerGroup.id,
					'parent_id': consumerGroup.parent_id
				}
				this.consumerGroupService.destroy('consumer/removeparent', formData, consumerGroup);
			}
		});
	}

	/** Add To Parent */
	addToConsumerGroup(consumerGroup = null): void {
		this.consumerGroupService.getAllParentItems();
		this.consumerGroupService.changeItem((consumerGroup) ? consumerGroup : new ConsumerGroupModel({}));
		let dialogRef = this.matDialog.open(FormLinkGroupComponent, {
			width: "600px",
			data: {
				action: (consumerGroup) ? 'edit' : 'new',
				item: consumerGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				formData.value['id'] = id;
				this.consumerGroupService.consumerGroupUpdate('consumer/addparent', formData.value, consumerGroup);
				// this.consumerGroupService.update(id, formData.value);
			});
	}

	/** Send Template Message to Lead */
	sendTemplateMessage(consumerGroup: ConsumerGroup): void {
		this.consumerGroupService.changeItem(consumerGroup);
		let templateDialogRef = this.matDialog.open(TemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: consumerGroup
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.consumer_group_id && result.message_data) {
				this.templateMessageService.postUrl(result, 'send-bulk-template-by-consumer-group');
			}
		});
	}

}
