import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
import { AssignTeamComponent } from './../assignTeam/assignTeam.component';

import {
	User, UserService,
	RTeamService, RTeam, RTeamModel,
	PersonTeamService, PersonTeam, PersonTeamModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'team-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	teamsAll: RTeam[] = [];
	teams: RTeam[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.teams);
	displayedColumns = ['date','clientName','avatar', 'name', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public rteamService: RTeamService,
		public personTeamService: PersonTeamService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.rteamService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.teamsAll) { this.subLoader = false; }
			this.teamsAll = data;
			this.teams = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.teams);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.rteamService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.rteamService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rteamService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Team */
	newTeam(team = null): void {
		this.rteamService.changeItem((team) ? team : new RTeamModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (team) ? 'edit' : 'new',
				item: team,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.rteamService.store(formData.value, 'team/add');
						break;
					case 'update':
						var data = {
							id: id,
							name: formData.value.name,
						}
						this.rteamService.update(data, 'team/update', team);
						break;
					case 'delete':
						this.deleteTeam(team);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteTeam(team: RTeam): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var data = {
					id: team.id,
				}
				this.rteamService.destroy(data, 'team/delete');
			}
		});
	}

	assignTeam(team: RTeam) {
		let dialogRef = this.matDialog.open(AssignTeamComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: team,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const formData: UntypedFormGroup = response[1];
				if (formData.value.checked) {
					var add = {
						'team_id': formData.value.team_id,
						'user_id': formData.value.user_id
					}
					this.personTeamService.assignToteam(add, 'team/assignToteam', team, formData.value.agentId);
				} else {
					var remove = {
						'team_id': formData.value.team_id,
						'user_id': formData.value.user_id
					}
					this.personTeamService.destroy('team/removeAgent', remove, team, formData.value.agentId);
				}
			});
	}

	/** markAsDefault team */
	markAsDefault(team: RTeam): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to set this Team as Default Team ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.rteamService.removelibraryById(team.id);
				this.rteamService.markAsDefault({ 'is_default': 1, 'id': team.id }, 'team/setDefault');
			}
		});
	}
}
