import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { DatePipe } from '@angular/common';

import {
	User, UserService,
	ResellerDashboardService, ResellerDashboard, PusherService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-dashboard-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	providers: [DatePipe]
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;
	channel: any;
	dashboard: ResellerDashboard;
	dashboardParam: any;
	date: any;
	loop: boolean = true;

	constructor(public userService: UserService,
		public resellerDashboardService: ResellerDashboardService,
		private router: Router,
		public matDialog: MatDialog,
		private datePipe: DatePipe) {
		// this.resellerDashboardService.getAllItems();
		this.resellerDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
		this.resellerDashboardService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.dashboard = data);
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() {
		this.loop = true;
		this.getLiveDashboard();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.resellerDashboardService.resetParams();
		this.resellerDashboardService.unSubscribe();
		this.loop = false;
	}
	
	getLiveDashboard() {
		setTimeout(() => {
			// this.resellerDashboardService.getAllItems();
			if (this.loop) {
				this.getLiveDashboard();
			}
		}, 120000);
	}

}
