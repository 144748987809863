export class TeamChatModel {
    id: number;
    user_id: number;
    title: string;
    text: string;
    type: string;
    media_type: string;
    media_url: string;
    is_read: boolean;
    json: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    fromId?: number;
    toId?: number;
    message?: string;
    createdByName?: string;
    updatedByName?: string;
    user?: any;
    
    tableName: string;
    className: string;
    chatUnreadCount?: number;
    isOnline?: boolean;

    file?: any;
    file_name?: any;
    file_data?: any;
    file_type?: any;

    /**
     * Constructor
     *
     * @param teamChat
     */
    constructor(teamChat) {
            this.id = teamChat.id || '';
            this.user_id = teamChat.user_id || '';
            this.title = teamChat.title || '';
            this.text = teamChat.text || '';
            this.type = teamChat.type || '';
            this.media_type = teamChat.media_type || '';
            this.media_url = teamChat.media_url || '';
            this.is_read = teamChat.is_read || false;
            this.json = teamChat.json || '';
            this.message = teamChat.message || '';
            this.fromId = teamChat.fromId || '';
            this.toId = teamChat.toId || '';
            this.created_by = teamChat.created_by || '';
            this.updated_by = teamChat.updated_by || '';
            this.created_at = teamChat.created_at || '';
            this.updated_at = teamChat.updated_at || '';
            this.isOnline = teamChat.isOnline || false;
            
            this.file = teamChat.file || null;
            this.file_name = teamChat.file_name || null;
            this.file_data = teamChat.file_data || null;
            this.file_type = teamChat.file_type || null;
    }
}
