export class YoutubeTicketModel {
    id: number;
    code: string;
    youtube_id: number;
    client_id: number;
    agent_id: number;
    priority: string;
    is_active: boolean;
    is_closed: boolean;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    youtube?: any;
    people?: any;
    people_id?: number;
    peopleName?: string;
    peopleAvatar?: string;
    clientSiteId?: number;
    pusherSync?: string;
    abandonAt: Date;
    abandonElapsed: number;
    last_message_on?: Date;

    unReadCount?: number;
    tableName: string;
    className: string;
    class?: string;
    statusName?: string;
    commentId?: string;
    statusChanges?: Array<string>;
    peopleLabelId?: number;
    peopleLabelName?: string;
    peopleLabelColor?: string;
    peopleLabelColorText?: string;
    siteColor?: string;
    uName?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    siteName?: string;

    /**
     * Constructor
     *
     * @param youtube_ticket
     */
    constructor(youtube_ticket) {
            this.id = youtube_ticket.id || '';
            this.code = youtube_ticket.code || '';
            this.youtube_id = youtube_ticket.youtube_id || '';
            this.client_id = youtube_ticket.client_id || '';
            this.agent_id = youtube_ticket.agent_id || '';
            this.priority = youtube_ticket.priority || '';
            this.is_active = youtube_ticket.is_active || '';
            this.is_closed = youtube_ticket.is_closed || '';
            this.created_by = youtube_ticket.created_by || '';
            this.updated_by = youtube_ticket.updated_by || '';
            this.created_at = youtube_ticket.created_at || '';
            this.updated_at = youtube_ticket.updated_at || '';

            this.tableName = youtube_ticket.tableName || '';
            this.siteName = youtube_ticket.siteName || '';
    }
}
