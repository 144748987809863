export class ClientSiteModel {
	id: number;
	client_id: number;
	site_id: number;
	account_name: string;
	consumer_key: string;
	consumer_secret: string;
	token: string;
	token_secret: string;
	is_active: boolean;
	is_authorized: boolean;
	intraction_type: number;
	apikey: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	service_provider: string;
	whatsapp_number: string;
	username: string;
	uname: string;
	password: string;
	endpoint: string;
	namespace: string;
	page_id: string;
	json?: string;
	server?: string;
	do_reauthendicate?: boolean;
	color?: string;
	textcolor?: string;

	loginUrl?: string;
	intraction_type_name: string;
	intraction_obj: any;
	whatsappEmail?: string;
	whatsappAddress?: string;
	whatsappDescription?: string;
	whatsappWebhookUrl?: string;
	businessVertical?: string;
	whatsappWebsites?: string;
	whatsappAbout?: string;
	avatar_url?: string;
	activeItrsbotId?: number;
	time?:string;
	zone ?:string;
	details: any;
	siteName?: string;
	siteType?: string;
	webhook?: any;

	tableName: string;
	mongo?: any;

	/**
	 * Constructor
	 *
	 * @param client_site
	 */
	constructor(client_site) {
		this.id = client_site.id || '';
		this.client_id = client_site.client_id || '';
		this.site_id = client_site.site_id || '';
		this.account_name = client_site.account_name || '';
		this.consumer_key = client_site.consumer_key || '';
		this.consumer_secret = client_site.consumer_secret || '';
		this.token = client_site.token || '';
		this.token_secret = client_site.token_secret || '';
		this.is_active = client_site.is_active || '';
		this.is_authorized = client_site.is_authorized || '';
		this.intraction_type = client_site.intraction_type || '';
		this.apikey = client_site.apikey || '';
		this.created_by = client_site.created_by || '';
		this.updated_by = client_site.updated_by || '';
		this.created_at = client_site.created_at || '';
		this.updated_at = client_site.updated_at || '';
		this.service_provider = client_site.service_provider || '';
		this.whatsapp_number = client_site.whatsapp_number || '';
		this.uname = client_site.uname || '';
		this.username = client_site.username || '';
		this.password = client_site.password || '';
		this.endpoint = client_site.endpoint || '';
		this.namespace = client_site.namespace || '';
		this.page_id = client_site.page_id || '';
		this.do_reauthendicate = client_site.do_reauthendicate || false;
		this.color = client_site.color || null;

		this.tableName = client_site.tableName || '';
		this.whatsappEmail = client_site.whatsappEmail || '';
		this.whatsappAddress = client_site.whatsappAddress || '';
		this.whatsappDescription = client_site.whatsappDescription || '';
		this.whatsappWebhookUrl = client_site.whatsappWebhookUrl || '';
		this.businessVertical = client_site.businessVertical || '';
		this.whatsappWebsites = client_site.whatsappWebsites || '';
		this.time = client_site.time || '';
		this.zone = client_site.zone || '';
		this.whatsappAbout = client_site.whatsappAbout || '';
		this.avatar_url = client_site.avatar_url || '';
		this.intraction_obj = client_site.intraction_obj ||  new intractionObjectModel({});
		this.mongo = client_site.mongo || '';
		
	}
	
}
export class intractionObjectModel {
    id: number;
    name_ar: string;
    name_en: string;

    /**
     * Constructor
     *
     * @param intraction
     */
    constructor(intraction) {
        this.id = intraction.id || '';
        this.name_ar = intraction.name_ar || '';
        this.name_en = intraction.name_en || '';
    }
}