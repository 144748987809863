import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { ChangeIntractiveGroupVideoComponent } from './../changeIntractiveGroupVideo/changeIntractiveGroupVideo.component';
import { ShortCodeFormComponent } from './../shortCodeForm/shortCodeForm.component';
import { LinkIntractiveMessageFormComponent } from './../linkIntractiveMessageForm/linkIntractiveMessageForm.component';
import { MapTeamFormComponent } from './../mapTeamForm/mapTeamForm.component';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive, IntractiveModel,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel,
	IntractiveCommandService, ModalIntractiveGroupService, ModalIntractiveGroup, ConsumerGroupService
} from 'src/app/@tji/_dbShare';
import { TicketableIntractiveFormComponent } from '../ticketableIntractiveForm/ticketableIntractiveForm.component';
import { ChooseConsumerGroupFormComponent } from '../chooseConsumerGroupForm/chooseConsumerGroupForm.component';
import { AiCollectionComponent } from '../ai-collection/ai-collection.component';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-single',
	templateUrl: './single.component.html',
	styleUrls: ['./single.component.scss']
})

export class SingleComponent implements OnInit, OnDestroy {
	@Input() intractive: Intractive;
	authUser: User;
	itrsbot: Itrsbot;
	consumerGroupParam: any;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public intractiveCommandService: IntractiveCommandService,
		public modalIntractiveGroupService: ModalIntractiveGroupService,
		public consumerGroupService: ConsumerGroupService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => { this.itrsbot = data });
		this.consumerGroupService.params.pipe(untilDestroyed(this)).subscribe(data => this.consumerGroupParam = data);
	}

	ngOnInit() {	
	}


	/** On destroy */
	ngOnDestroy(): void {
		this.intractiveService.changeItem(null);
	}

	/** Add / Update Tag */
	newIntractiveGroup(intractiveGroup = null): void {
		this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(SimpleFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
				isChoiceMsg: this.isItrsCombined(this.intractive),
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
			});
	}

/** Add / Update Tag */
	// newIntractiveGroupAicollection(intractiveGroup = null): void {
	// 	this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
	// 	this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
	// 	let dialogRef = this.matDialog.open(AiCollectionComponent, {
	// 		width: "600px",
	// 		data: {
	// 			action: (intractiveGroup) ? 'edit' : 'new',
	// 			item: intractiveGroup,
	// 			isChoiceMsg: this.isItrsCombined(this.intractive),
	// 		}
	// 	});

	// 	dialogRef.afterClosed()
	// 		.subscribe(response => {
	// 			if (!response) { return; }
	// 			const actionType: string = response[0];
	// 			const formData: UntypedFormGroup = response[1];
	// 			const id: number = (response[2]) ? response[2] : null;
	// 			switch (actionType) {
	// 				case 'new':
	// 					this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
	// 					break;
	// 				case 'update':
	// 					formData.value['id'] = id;
	// 					this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
	// 					break;
	// 			}
	// 		});
	// }

/** Add / Update Change Ai Collection */
	newIntractiveGroupAicollection(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(AiCollectionComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				const tag = (response[3]) ? response[3] : null;
				var ai = {
					type : formDatas.value.aiCollectionType,
					value : formDatas.value.aiCollectionValue,
					tags: tag.toString()
				}
				var formData = {
					id: id,
					json_aicollection_action: ai,
					key: 'aicollection',
					is_active: formDatas.value.is_active,
				}
				this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
			});
	}

	/** Add / Update Intractive */
	newIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(IntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
				switch (actionType) {
					case 'new':
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
				}
			});
	}

	/** Delete Intractive */
	deleteIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete this template?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete', formData);
				this.intractiveService.removelibraryById(intractive.id);

				setTimeout(() => {
					this.intractiveService.changeItem(null);
				}, 2000);
			}
		});
	}

	/** Add / Change IntractiveGroup Images */
	imageIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChangeIntractiveGroupImageComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				this.itrsbotService.getAllItems()
				if (this.intractive) {
					this.intractiveService.getIntractiveItem({ id: this.intractive.id });
				}
				setTimeout(() => {
					this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
				}, 2000);
				if (!response) { return; }
			});
	}

	/** Add / Change IntractiveGroup Videos */
	videoIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChangeIntractiveGroupVideoComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
			});
	}

	isItrsCombined(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'feedback' || slug === 'survey' || slug === 'one-time-survey')) ? true : false;
	}

	checkImageAssets(intractive: Intractive): boolean {
		if (intractive && intractive.intractive_groups &&
			intractive.intractive_groups[0].image_asset && intractive.intractive_groups[0].image_asset.length > 0) {
			return true;
		} else {
			return false
		}
	}

	checkVideoAssets(intractive: Intractive): boolean {
		if (intractive && intractive.intractive_groups &&
			intractive.intractive_groups[0].videoAssets && intractive.intractive_groups[0].videoAssets.length > 0) {
			return true;
		} else {
			return false
		}
	}

	checkAicollection(intractive: Intractive): boolean {
		if (intractive && intractive.intractive_groups &&
			intractive.intractive_groups[0].json && intractive.intractive_groups[0].json.type) {
			return true;
		} else {
			return false
		}
	}

	/** Add / Update Intractive */
	newShortCode(intractive): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(ShortCodeFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.intractiveCommandService.addShortCode('Intractive/addshortcode', formData.value);
				this.intractive.shortCode = formData.value['command'];
			});
	}

	/** Add / Update Intractive With Intractive Message Id */
	updateIntractiveWithIntractiveMessageId(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(LinkIntractiveMessageFormComponent, {
			width: "600px",
			data: {
				action: (intractive && intractive.intractive_message_id) ? 'edit' : 'new',
				item: intractive
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				const intractiveId: number = (intractive && intractive.id) ? intractive.id : id;
				var formData = {
					"id": intractiveId,
					"key": "intractive_message_id",
					"value": response[1].value.intractive_message_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
				// setTimeout(() => {
				// 	this.intractiveService.getIntractiveItem({ id: intractiveId });
				// }, 1500);
			});
	}

	removeIntractiveMessageId(intractive: Intractive) {
		var formData = {
			"id": intractive.id,
			"key": "intractive_message_id",
			"value": null
		}
		this.intractiveService.updateData('Intractive/options', formData);
		// this.intractiveService.update(intractive.id, { 'intractive_message_id': null });
		// setTimeout(() => {
		// 	this.intractiveService.getIntractiveItem({ id: intractive.id });
		// }, 1500);
	}
	checkGroupTeams(intractiveGroup: IntractiveGroup): boolean {
		return (this.getGroupTeams(intractiveGroup)) ? true : false;
	}
	getGroupTeams(intractiveGroup: IntractiveGroup): Array<any> {
		let teamGroups = [];
		if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups
			&& this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
			teamGroups = this.intractive.intractive_groups_through_modal_intractive_groups.filter(x => {
				return (x.resource_type === 'team' && x.intractive_group_id == intractiveGroup.id) ? true : false;
			});
		}
		return teamGroups;
	}
	checkMapTeamBtn(intractive, index): boolean {
		let result: boolean = false;
		result = (index != intractive.intractive_groups.length &&
			index == 0 &&
			this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug != 'live-seperate-message') ? true : false;
		return result;
	}

	mapTeam(intractiveGroup: IntractiveGroup) {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		if (this.intractive && this.intractive.intractive_groups_through_modal_intractive_groups &&
			this.intractive.intractive_groups_through_modal_intractive_groups.length > 0) {
			this.modalIntractiveGroupService.changeAllItems(this.intractive.intractive_groups_through_modal_intractive_groups);
		}

		let dialogRef = this.matDialog.open(MapTeamFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: intractiveGroup
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				this.modalIntractiveGroupService.newTems('interactiveGroup/modal-intractive-group', formData.value, intractiveGroup);
				// setTimeout(() => {
				// 	this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
				// }, 1500);
			});
	}

	removeGroupTeam(intractiveGroup: IntractiveGroup, groupTeam: ModalIntractiveGroup) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to Remove this Team?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var datas = {
					intractive_group_id: intractiveGroup.id,
					resource_id: groupTeam.resource_id
				}
				this.modalIntractiveGroupService.destroy('interactiveGroup/removeTeam',datas);
				setTimeout(() => {
					this.intractiveService.getIntractiveItem({ id: intractiveGroup.intractive_id });
				}, 1500);
			}
		});
	}


	/** Add / Update Change Intractive */
	markIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(TicketableIntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
					case 'update':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
				}
			});
	}

	checkMarkIntractiveGroupBtn(intractive): boolean {
		let result: boolean = false;
		result = (intractive.intractive_groups.length == 1 &&
			this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug == 'live-message') ? true : false;
		return result;
	}


	/** Add / Update Change Consumer Group */
	linkConsumerGroup(intractiveGroup = null): void {
		this.consumerGroupParam.limit = 200;
		this.consumerGroupParam.current = 1;
		this.consumerGroupService.getAllItems();
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChooseConsumerGroupFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				var formData = {
					id: id,
					json_consumer_action: formDatas.value.json_consumer_action,
					key: 'consumer_group_id',
					value: formDatas.value.consumer_group_id,
					is_active: formDatas.value.is_active,
				}
				this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
			});
	}

	checkSubscribeBtn(intractive, index): boolean {
		let result: boolean = false;
		result = (index != intractive.intractive_groups.length - 1 &&
			index > 0 &&
			this.userService.permissionMatch(['Manage ITRS']) &&
			intractive.intractive_category.slug != 'live-seperate-message') ? true : false;
		return result;
	}
}
