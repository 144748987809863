import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { CarouselService } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
	User, UserService,
	Poster, PosterService, PosterModel,
	PosterAsset, PosterAssetModel,
	ClientSite, ClientSiteService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
	selector: 'smm-poster-preview',
	templateUrl: './posterPreview.component.html',
	styleUrls: ['./posterPreview.component.scss']
})

export class PosterPreviewComponent implements OnInit, OnDestroy {
	user: User;
	poster: Poster;
	today: Date = new Date();
	allAssets: PosterAsset[] = [new PosterAssetModel({})];
	clientSites: ClientSite[] = [];
	posterPreviewOptions: OwlOptions = {
        loop: false,
        mouseDrag: true,
        autoplayHoverPause: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: false,
        navSpeed: 700,
        navText : [
        	"<i class='fa fa-chevron-left leftIcon'></i>",
        	"<i class='fa fa-chevron-right rightIcon'></i>"
        ],
        items: 1,
        nav: true
    }
    @ViewChild('carousel', { static: true }) carousel: CarouselComponent;

	constructor(public userService: UserService,
		public posterService: PosterService,
		public clientSiteService: ClientSiteService,
		private dialog: MatDialog,
		private router: Router,
		public location: Location,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data.filter(x => { return (x.siteType === 'smm') ? true : false ; });
		});
		this.posterService.item.subscribe(data => {
			this.poster = data;
		});
	}

	ngOnInit() {
		this.allAssets = (this.poster.allAssets && this.poster.allAssets.length > 0) ? this.poster.allAssets : [new PosterAssetModel({})];
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.posterService.unSubscribe();
	}

	refreshCarousel() {
		const anyService = this.carousel as any;
   		const carouselService = anyService.carouselService as CarouselService;
   		carouselService.refresh();
    	carouselService.update();
	}

	getAvatarUrl() {
		if(this.poster && this.poster.client && this.poster.client.id && this.poster.client.avatar_url) {
			return this.poster.client.avatar_url;
		} else if(this.user && this.user.id && this.user.avatar_url) {
			return this.user.avatar_url;
		} else {
			return 'assets/img/no-user.jpg';
		}
	}

	getAuthorName() {
		if(this.poster && this.poster.client && this.poster.client.id && this.poster.client.name) {
			return this.poster.client.code + ' | ' + this.poster.client.name;
		} else if(this.user && this.user.person && this.user.person.name) {
			return this.user.person.code + ' | ' + this.user.person.name;
		} else {
			return 'Authorized Account User';
		}
	}

	getImage(asset: PosterAsset) {
		if(this.allAssets && this.allAssets.length === 0) {
			return 'assets/images/smm-bg.jpg';
		} else if(asset && asset.url) {
			return asset.url;
		} else if(asset.file_data) {
			return asset.file_data;
		} else {
			return 'assets/images/smm-bg.jpg';
		}
	}

	getType(asset: PosterAsset) {
		if(this.allAssets && this.allAssets.length < 1) {
			return 'image';
		} else if(asset && asset.type) {
			return asset.type;
		} else if(asset.file_type) {
			let type: string = 'image';
			if(asset.file_type.includes('image/')) {
				type = 'image';
			} else if (asset.file_type.includes('video/')) {
				type = 'video';
			} else if (asset.file_type.includes('audio/')) {
				type = 'audio';
			} else {
				type = 'document';
			}
			return type;
		} else {
			return 'image';
		}
	}

	onSelectClientSite(clientSite: ClientSite) {
		let doMerge: boolean = true;
		let clientSiteIds: Array<any> = this.poster.clientSiteIds;
		let filteredItems = this.poster.clientSiteIds.filter(x => {
			return (x && x === clientSite.id) ? true : false;
		});
		if(this.poster && this.poster.clientSiteIds && this.poster.clientSiteIds.length > 0 && 
			filteredItems && filteredItems.length > 0) {
			doMerge = false;
		}
		if(doMerge) {
			clientSiteIds.push(clientSite.id);
			this.poster.clientSiteIds = clientSiteIds;
		}
	}

	onRemoveClientSite(clientSite: ClientSite) {
		let clientSiteIds: Array<any> = this.poster.clientSiteIds;
		if (clientSiteIds && clientSiteIds.length > 0) {
			for (var i = 0; i < clientSiteIds.length; ++i) {
				if (clientSiteIds[i] === clientSite.id) { clientSiteIds.splice(i, 1); }
			}
		}
		this.poster.clientSiteIds = clientSiteIds;
	}

	checkIsSelected(clientSite: ClientSite): boolean {
		let isSelected: boolean = false;
		let filteredItems = this.poster.clientSiteIds.filter(x => {
			return (x && x === clientSite.id) ? true : false;
		});
		if(this.poster && this.poster.clientSiteIds && this.poster.clientSiteIds.length > 0 && 
			filteredItems && filteredItems.length > 0) {
			isSelected = true;
		}
		return isSelected;
	}

}
