import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { ModalHasLabel } from './modalHasLabel.interface';
import { ModalHasLabelModel } from './modalHasLabel_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ModalHasLabelService implements Resolve<any> {
	url: string = 'label/modelhaslabel';
	routeParams: any;
	defaultParams: any = {
		'all': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<ModalHasLabel[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<ModalHasLabel>(new ModalHasLabelModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<ModalHasLabel[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ModalHasLabelService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on ModalHasLabelService');
	}

	concatlibrary(allItems: ModalHasLabel[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: ModalHasLabel) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: ModalHasLabel) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: ModalHasLabel[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: ModalHasLabel[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: ModalHasLabel) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: ModalHasLabel[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: ModalHasLabel) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'all': 1,
			'search': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (params.all && params.all === 1) {
					this.changeAllItems(data.data);
					this.changeTotalItem(data.length);
				} else {
					this.changeAllItems(data.data.data);
					this.changeTotalItem(data.data.total);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: ModalHasLabel) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ModalHasLabelModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
			},
				error => console.log('Error ::' + error)
			);
	}

	getByUrl(params: any = null, url = null) {
		params = this.paramsInit(params);
		let newUrl: string = (url) ? url : this.url;
		this.commonService.getAll(newUrl, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItems(data.data);
				// this.changeTotalItem(data.data.length);

			},
				error => console.log('Error ::' + error)
			);
	}

	markAsRead(id, url = null) {
		let newUrl: string = (url) ? url : 'mark-read';
		this.commonService.getAll(newUrl + '/' + id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
			},
				error => console.log('Error ::' + error)
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}
	modelHasLabelUpdate(formData: any) {
		this.commonService.storeItem("label/modelhaslabel", formData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.concatItem(data.data);
					this.changeItem(data.data);
					this.alertService.webShow('Success', 'updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				
			},
				error => console.log('Error ::' + error)
			);
	}
}