import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from "../../app-share.module";

import { ProductSettingsRoutingModule } from './product-settings-routing.module';
import { ProductSettingsComponent } from './product-settings/product-settings.component';
import { MainComponent } from './main/main.component';

@NgModule({
  declarations: [
    MainComponent,
    ProductSettingsComponent
  ],
  imports: [
    CommonModule,
    ProductSettingsRoutingModule,
    AppShareModule
  ]
})
export class ProductSettingsModule { }
