import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { FirebaseJobService } from 'src/app/@tji/_dbShare/alert/firebaseJob/firebaseJob.service';
import { TemplateMessage } from './templateMessage.interface';
import { TemplateMessageModel } from './templateMessage_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import * as moment from 'moment-timezone';
declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class TemplateMessageService implements Resolve<any> {
	url: string = 'template';
	urlItrs: string = 'Intractive/list'
	urlTemplateItrs: string = 'template/update'
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
	};

	sortIdentity: any = {
		'name': 'name'
	};
	templateStatus: any;

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<TemplateMessage[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<TemplateMessage>(new TemplateMessageModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<TemplateMessage[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private completedCountsSource = new BehaviorSubject<number>(0);
	completedCounts = this.completedCountsSource.asObservable();

	private linkItrs = new BehaviorSubject<any>([]);
	currentItrs = this.linkItrs.asObservable()

	private itrsListData = new BehaviorSubject<any>([]);
	currentAllItrs = this.itrsListData.asObservable()

	private itrsDataScurce = new BehaviorSubject<any>([]);
	currentItrsDataScurce = this.itrsDataScurce.asObservable()

	public bulkTemSend = new BehaviorSubject<Array<any>>([]);
	temSend = this.bulkTemSend.asObservable();

	public templateViewsidebar = new BehaviorSubject<boolean>(false);
	templateView = this.templateViewsidebar.asObservable();

	// public isTemplateViewLoader = new BehaviorSubject<boolean>(false);
	// templateViewLoader = this.isTemplateViewLoader.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private firebaseJobService: FirebaseJobService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TemplateMessageService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TemplateMessageService');
	}
	concatlibrary(allItems: TemplateMessage[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: TemplateMessage) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: TemplateMessage) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: TemplateMessage[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: TemplateMessage[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: TemplateMessage) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeCompletedCounts(total: number) {
		this.completedCountsSource.next(total);
	}

	changeDisplayItems(displayItems: TemplateMessage[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changelinkItrs(itrs: any) {
		this.linkItrs.next(itrs)
	}

	changeItrsData(itrs: any) {
		this.itrsListData.next(itrs);
	}

	changeItrsDataScurce(itrs: any) {
		this.itrsDataScurce.next(itrs);
	}

	changeAllItemsByItem(item: TemplateMessage) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var templateData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (templateData && templateData.length > 0) {
						templateData.forEach(tepm => {
							var templateView = {}
							var dataFormats = {
								"id": tepm.id,
								"code": tepm.code,
								"name": tepm.name,
								"namespace": tepm.namespace,
								"stencil_id": tepm.stencil_id,
								"language": tepm.language,
								"required_values": tepm.required_values,
								"client_id": tepm.client_id,
								"status": tepm.status ? true : false,
								"created_by": tepm.created_by,
								"updated_by": tepm.updated_by,
								"created_at": moment(tepm.created_at + '.000+0300').local(),
								"updated_at": moment(tepm.updated_at + '.000+0300').local(),
								"deleted_at": tepm.deleted_at,
								"client_site_id": tepm.client_site_id,
								"label": tepm.label,
								"description": tepm.description,
								"token": tepm.token,
								"itrsbot_id": tepm.itrsbot_id,
								"activity": tepm.activity,
								"requiredAllValues": JSON.parse(tepm.required_values),
							}

							if (tepm.clientSiteInfo) {
								dataFormats["siteName"] = tepm.clientSiteInfo.details.name;
								dataFormats["clientSiteName"] = tepm.clientSiteInfo.account_name;
								dataFormats["clientSiteColor"] = tepm.clientSiteInfo.color;
								dataFormats["clientSiteTextColor"] = tepm.clientSiteInfo.textcolor;
							}

							if (tepm.stencilInfo) {
								dataFormats["stencil"] = tepm.stencilInfo;
								dataFormats["stencilName"] = tepm.stencilInfo.slug;
							}

							var type = typeof tepm.jsonInfo

							if (type == "string") {
								var jsonInfo = JSON.parse(tepm.jsonInfo);
								dataFormats["jsonData"] = jsonInfo;
								templateView['to'] = jsonInfo?.to;
								templateView["type"] = jsonInfo?.type;
								templateView["headerType"] = jsonInfo?.templateComponent?.headerType;
								templateView["headerText"] = jsonInfo?.templateComponent?.headerText;
								templateView["image"] = jsonInfo?.templateComponent?.image;
								templateView["video"] = jsonInfo?.templateComponent?.video;
								templateView["document"] = jsonInfo?.templateComponent?.document;
								templateView["bodyType"] = jsonInfo?.templateComponent?.bodyType;
								templateView["bodyText"] = jsonInfo?.templateComponent?.bodyText;
								templateView["footerType"] = jsonInfo?.templateComponent?.footerType;
								templateView["footerText"] = jsonInfo?.templateComponent?.footerText;
								templateView["buttons"] = jsonInfo?.templateComponent?.buttons;
							} else {
								var jsonInfo = tepm.jsonInfo
								dataFormats["jsonData"] = tepm.jsonInfo;
								templateView['to'] = jsonInfo?.to;
								templateView["type"] = jsonInfo?.type;
								templateView["headerType"] = jsonInfo?.templateComponent?.headerType;
								templateView["headerText"] = jsonInfo?.templateComponent?.headerText;
								templateView["image"] = jsonInfo?.templateComponent?.image;
								templateView["video"] = jsonInfo?.templateComponent?.video;
								templateView["document"] = jsonInfo?.templateComponent?.document;
								templateView["bodyType"] = jsonInfo?.templateComponent?.bodyType;
								templateView["bodyText"] = jsonInfo?.templateComponent?.bodyText;
								templateView["footerType"] = jsonInfo?.templateComponent?.footerType;
								templateView["footerText"] = jsonInfo?.templateComponent?.footerText;
								templateView["buttons"] = jsonInfo?.templateComponent?.buttons;
							}
							dataFormats["templateData"] = templateView;
							if (tepm.token) {
								var required = JSON.parse(tepm.required_values);
								var dataPost = {
									client_id: tepm.client_id,
									// template_id: tepm.id,
									token: tepm.token,
								}

								for (var i = 0; i < required.length; i++) {
									dataPost[required[i]] = "";
								}

								dataFormats["postData"] = dataPost;
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: TemplateMessage) {
		this.replacelibrary(item);
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		this.removelibraryById(id);
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params?.id : this.routeParams;

		if (this.routeParams && this.routeParams > 0) {
			// this.isTemplateViewLoader.next(true);
			this.commonService.storeItem('template/view', { id: this.routeParams }, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success && data.data.id) {
						
						var template = data.data;
						var templateView = {}
						// this.isTemplateViewLoader.next(false);

						var templateData = {
							"id": template.id,
							"code": template.code,
							"name": template.name,
							"namespace": template.namespace,
							"stencil_id": template.stencil_id,
							"language": template.language,
							"required_values": template.required_values,
							"json": template.jsonInfo,
							"client_id": template.client_id,
							"status": template.status ? true : false,
							"created_by": template.created_by,
							"updated_by": template.updated_by,
							"created_at": moment(template.created_at + '.000+0300').local(),
							"updated_at": moment(template.updated_at + '.000+0300').local(),
							"deleted_at": template.deleted_at,
							"client_site_id": template.client_site_id,
							"label": template.label,
							"description": template.description,
							"token": template.token,
							"itrsbot_id": template.itrsbot_id,
							"activity": template.activity,
							"requiredAllValues": JSON.parse(template.required_values),
							"stencilName": "greeting-template",
						}


						if (template.clientSiteInfo) {
							templateData["siteName"] = template.clientSiteInfo.details.name;
							templateData["clientSiteName"] = template.clientSiteInfo.account_name;
							templateData["clientSiteColor"] = template.clientSiteInfo.color;
							templateData["clientSiteTextColor"] = template.clientSiteInfo.textcolor;
						}

						if (template.stencilInfo) {
							templateData["stencil"] = template.stencilInfo;
							templateData["stencilName"] = template.stencilInfo.slug;
						}

						var type = typeof template.jsonInfo

						if (type == "string") {
							var jsonInfo = JSON.parse(template.jsonInfo);
							templateData["jsonData"] = jsonInfo;
							templateView['to'] = jsonInfo?.to;
							templateView["type"] = jsonInfo?.type;
							templateView["headerType"] = jsonInfo?.templateComponent?.headerType;
							templateView["headerText"] = jsonInfo?.templateComponent?.headerText;
							templateView["image"] = jsonInfo?.templateComponent?.image;
							templateView["video"] = jsonInfo?.templateComponent?.video;
							templateView["document"] = jsonInfo?.templateComponent?.document;
							templateView["bodyType"] = jsonInfo?.templateComponent?.bodyType;
							templateView["bodyText"] = jsonInfo?.templateComponent?.bodyText;
							templateView["footerType"] = jsonInfo?.templateComponent?.footerType;
							templateView["footerText"] = jsonInfo?.templateComponent?.footerText;
							templateView["buttons"] = jsonInfo?.templateComponent?.buttons;
						} else {
							var jsonInfo = template.jsonInfo
							templateData["jsonData"] = template.jsonInfo;
							templateView['to'] = jsonInfo?.to;
							templateView["type"] = jsonInfo?.type;
							templateView["headerType"] = jsonInfo?.templateComponent?.headerType;
							templateView["headerText"] = jsonInfo?.templateComponent?.headerText;
							templateView["image"] = jsonInfo?.templateComponent?.image;
							templateView["video"] = jsonInfo?.templateComponent?.video;
							templateView["document"] = jsonInfo?.templateComponent?.document;
							templateView["bodyType"] = jsonInfo?.templateComponent?.bodyType;
							templateView["bodyText"] = jsonInfo?.templateComponent?.bodyText;
							templateView["footerType"] = jsonInfo?.templateComponent?.footerType;
							templateView["footerText"] = jsonInfo?.templateComponent?.footerText;
							templateView["buttons"] = jsonInfo?.templateComponent?.buttons;
						}
						templateData["templateData"] = templateView;

						if (template.token) {
							var required = JSON.parse(template.required_values);
							var dataPost = {
								client_id: template.client_id,
								// template_id: template.id,
								token: template.token,
							}

							for (var i = 0; i < required.length; i++) {
								dataPost[required[i]] = "";
							}

							templateData["postData"] = dataPost;
						}

						// this.changeAllItemsByItem(templateData);
						this.changeItem(templateData);
					} else {
						// this.isTemplateViewLoader.next(false);
					}

				},
					error => { console.log('Error ::' + error); }
				);
		}
		else {
			this.changeItem(new TemplateMessageModel({}));
		}
	}

	store(data: any) {
		var template: any = {};
		this.currentItrsDataScurce.subscribe(data => template = data);
		this.commonService.storeItem("template/create", data, true, "optionOne")
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				template.templateId = data.data.id;
				if (template.templateId && template.itrsId) {
					this.createIntractiItem(template, 'optionOne');
				}
				this.concatItem(data.data);
				this.changeItem(data.data);
				this.templateStatus = true;
				this.alert('Info', 'Created Successfully !!!');
			},
				error => {
					this.templateStatus = false;
					console.log('Error ::' + error)
					this.alert('Danger', error);
				}
			);
	}

	updateTemplate(data: any) {
		var template: any = {};
		this.currentItrsDataScurce.subscribe(data => template = data)
		this.commonService.storeItem("template/update", data, true, "optionOne")
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				template.templateId = data.data.id;
				if (template.templateId && template.itrsId) {
					this.createIntractiItem(template, 'optionOne');
				}
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.templateStatus = true;
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.templateStatus = false;
					console.log('Error ::' + error)
					this.alert('Danger', error);
				}
			);
	}

	update(id: number, data: any) {
		var template: any = {};
		this.currentItrsDataScurce.subscribe(data => template = data)
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				template.templateId = data.data.id;
				if (template.templateId && template.itrsId) {
					this.createIntractiItem(template, 'optionOne');
				}
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.templateStatus = true;
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.templateStatus = false;
					console.log('Error ::' + error)
					this.alert('Danger', error);
				}
			);
	}

	destroy(url, temp) {
		this.commonService.storeItem(url, temp, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(temp.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getByUrl(params: any = null, url = null) {
		params = this.paramsInit(params);
		let newUrl: string = (url) ? url : this.url;
		this.commonService.getAll(newUrl, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItems(data.data);
				// this.changeTotalItem(data.data.length);

			},
				error => console.log('Error ::' + error)
			);
	}

	postUrl(data: any, url = null) {
		url = (url) ? url : this.url;
		let showAlert: boolean = (data && data.showAlert) ? data.showAlert : false;
		this.commonService.storeItem(url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data && data.data) {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				}
				if (data.jobcode) {
					this.firebaseJobService.changeJobcode(data.jobcode);
				}
				if (showAlert) {
					this.alert('Info', 'Message Sent Successfully');
				}
				// this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	markAsRead(id, url = null) {
		let newUrl: string = (url) ? url : 'mark-read';
		this.commonService.getAll(newUrl + '/' + id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
			},
				error => console.log('Error ::' + error)
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getCompletedCounts(params: any = null) {
		let url = 'check-queue-status';
		this.commonService.getAll(url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				console.log('api return ' + data.data.remaining_count);
				this.changeCompletedCounts(data.data.remaining_count);
			},
				error => console.log('Error ::' + error)
			);
	}

	getIntractiItem(data: any = null, urlOption: any = null) {
		this.commonService.getItrsData(this.urlItrs, data, urlOption)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				var intractive = data.data;
				var additionalData = data.additional;
				if (intractive && intractive.length > 0) {
					intractive.forEach(intractive => {
						additionalData.forEach(category => {
							if (intractive.intractive_category_id == category.id) {
								intractive.resource_id = parseInt(intractive.resource_id);
								intractive['intractive_category'] = category;
							}
						});
					});
					this.changeItrsData(intractive);
				} else {
					this.changeItrsData([]);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', error);
				}
			);
	}
	createIntractiItem(data: any = null, urlOption: any = null) {
		this.commonService.getItrsData(this.urlTemplateItrs, data, urlOption)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.alert('Info', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', error);
				}
			);
	}

	sendTemplate(data): void {
		this.commonService.storeItem('template/send', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					// this.concatItem(res.data);
					// this.changeItem(res.data);
					var data = [{
						jobcode: res.data.jobcode,
						tempSent: true,
					}]
					this.bulkTemSend.next(data);
					// this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	sendSingleTemplate(data : any) {
		this.commonService.getData('template/send', data, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.alert('Success', 'Template Send Successfully !!!');
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

}
