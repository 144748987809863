import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { WebchatComponent } from './webchat/webchat.component';
import { MessengerComponent } from './messenger/messenger.component';
import { TweetComponent } from './twitter/twitter.component';
import { TweetdmComponent } from './twitterdm/twitterdm.component';
import { InstagramComponent } from './instagram/instagram.component';
import { InstadmComponent } from './instadm/instadm.component';
import { YoutubeComponent } from './youtube/youtube.component';

import {
	WhatsappListService, MessengerListService,
	InstagramListService, InstadmListService,
	TweetListService, TweetdmListService,
	YoutubeListService, WebchatListService
} from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		children: [
			{
				path: 'whatsapp',
				component: WhatsappComponent,
				resolve: { data: WhatsappListService },
			},
			{
				path: 'webchat',
				component: WebchatComponent,
				resolve: { data: WebchatListService },
			},
			{
				path: 'messenger',
				component: MessengerComponent,
				resolve: { data: MessengerListService },
			},
			{
				path: 'twitter',
				component: TweetComponent,
				resolve: { data: TweetListService },
			},
			{
				path: 'twitterdm',
				component: TweetdmComponent,
				resolve: { data: TweetdmListService },
			},
			{
				path: 'instagram',
				component: InstagramComponent,
				resolve: { data: InstagramListService },
			},
			{
				path: 'instadm',
				component: InstadmComponent,
				resolve: { data: InstadmListService },
			},
			{
				path: 'youtube',
				component: YoutubeComponent,
				resolve: { data: YoutubeListService },
			},
			{ path: '**', redirectTo: '/app/closed-conversation/whatsapp' }
		]
	},
	{ path: '**', redirectTo: '/app/closed-conversation/whatsapp' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class ClosedConversationRouteModule { }
