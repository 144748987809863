import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Dashboard, DashboardList } from './dashboard.interface';
import { DashboardModel, DashBoardCountModel } from './dashboard_model.model';

import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { HistoryService } from '../../history/history.service';
declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class DashboardService implements Resolve<any> {
	url: string = 'dashboard';
	routeParams: any;
	defaultParams: any = {};
	typeofData: any = "Live";
	firstCount: any;
	secondCount: any;
	sortIdentity: any = {
		'name': 'name'
	};

	currentDate = new Date();

	currentDates = this.currentDate.getMonth() + 1 + "-" + this.currentDate.getFullYear()

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<Dashboard>(new DashboardModel({}));
	allItems = this.allItemsSource.asObservable();

	private chartFourDataSource = new BehaviorSubject<any>([]);
	chartFourData = this.chartFourDataSource.asObservable();

	private itemSource = new BehaviorSubject<Dashboard>(new DashboardModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Dashboard>(new DashboardModel({}));
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private dashBoardDetails = new BehaviorSubject<DashboardList[]>([]);
	showDashBoard = this.dashBoardDetails.asObservable();

	private agentDashBoardDetails = new BehaviorSubject<any>([]);
	agentDashboard = this.agentDashBoardDetails.asObservable();

	private ordersCountSource = new BehaviorSubject<Array<any>>([]);
	orderCounts = this.ordersCountSource.asObservable();

	private paymentsCountSource = new BehaviorSubject<Array<any>>([]);
	paymentsCounts = this.paymentsCountSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar, private dashboardService: HistoryService) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		if (state.url != "/app/agent-dashboard") {
			return new Promise((resolve, reject) => {
				Promise.all([
					this.resetParams(),
					// this.getAllItems(),
					// this.getItem(),

					this.getChartFourData({ monthYear: this.currentDates }),
					this.dashBoardCounts(),
					this.dashboardService.loadTicketHistory()
				])
					.then(() => {
						resolve(null);
					}, reject
					);
			});
		} else {
			this.agentDashbOard();
		}

	}

	unSubscribe() {
		// console.log('UnSubscribed DashboardService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on DashboardService');
	}

	changeAllItems(allItems: Dashboard) {
		this.allItemsSource.next(allItems);
	}

	changeChartFourData(allItems: any) {
		this.chartFourDataSource.next(allItems);
	}
	changeItem(item: Dashboard) {
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Dashboard) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	//order CountSource
	changeOrdersCount(ordersCount: Array<any>) {
		this.ordersCountSource.next(ordersCount);
	}

	changePaymentsCount(ordersCount: Array<any>) {
		this.paymentsCountSource.next(ordersCount);
	}

	changeAllItemsByItem(item: Dashboard) {
		// let allItems = [];
		// this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		// if (allItems && allItems.length > 0) {
		//     for (var i = 0; i < allItems.length; ++i) {
		//         if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
		//     }
		// }
		// this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItems(data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	getChartFourData(params: any = null) {
		params = this.paramsInit(params);
		let url = 'dashboard/chartFourData';
		this.commonService.storeItem(url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var dataFormat = [];
					if (data.data && data.data.length > 0) {
						data.data.forEach(chart => {
							if (chart.CountTicket && chart.CountTicket.length > 0) {
								var dataFormats = {
									name: chart.account_name,
									series: []
								}
								chart.CountTicket.forEach(count => {
									dataFormats.series.push({
										"name": count.event + " " + chart.account_name,
										"value": count.count,
										"extra": {
											"code": count.event
										}
									})
								});
							}
							dataFormat.push(dataFormats);
						});
					}
					this.changeChartFourData(dataFormat);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new DashboardModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				// this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	//DashBoard Count
	dashBoardCounts(value: any = null) {
		let type = value == null ? 'live' : this.typeToShow(value);
		this.ordersOverallCount(type);
		this.paymentOverallCount(type);
		let promiseValues = [
			this.dashboardOpenticket(type),
			this.dashboardTodayTicket(type),
			this.dashboardLeadsCount(type),
			this.dashboardAgentCount(type),
		];
		Promise.allSettled(promiseValues).then((res: any) => {
			let getData = res;
			let manipulated = [];
			getData.forEach((element, index) => {
				if (element.value['key'] == "queueAndOpenTicketCount") {
					if (element.status == "fulfilled") {
						this.firstCount = element.value['data']['data']['OpenTicketsCount']
						manipulated.push( {
							"name": `${this.typeofData} Queue Tickets`,
							"value": element.value['data']['data']['QTicketsCount'],
							"icon": "fa fa-clock-o fa-1x green-400-fg",
							"color": "green-400-fg"
							//"sub_head": `0 Today Active Tickets`
						},{
							"name": `${this.typeofData} Open Tickets`,
							"value": element.value['data']['data']['OpenTicketsCount'],
							"icon": "fa fa-weixin fa-1x cyan-400-fg",
							"color": "cyan-400-fg"

							//"sub_head": `0 Open Conversation`
						})
					} else {
						this.firstCount = 0
						manipulated.push({
							"name": `${this.typeofData} Queue Tickets`,
							"value": 0,
							"icon": "fa fa-clock-o fa-1x green-400-fg",
							"color": "green-400-fg"
							//"sub_head": `0 Today Active Tickets`
						},{
							"name": `${this.typeofData} Open Tickets`,
							"value": 0,
							"icon": "fa fa-weixin fa-1x cyan-400-fg",
							"color": "cyan-400-fg"
							//"sub_head": `0 Open Conversation`
						})
					}
				}
				if (element.value['key'] == "ticketCount") {
					if (element.status == "fulfilled") {
						this.secondCount = element.value['data']['data']['ActiveTotalticket']
						manipulated.push({
							"name": `${this.typeofData} Total Tickets`,
							"value": element.value['data']['data']['ActiveTotalticket'],
							"icon": "fa fa-ticket fa-1x red-300-fg",
							"color": "red-300-fg"
						}
							// , {
							// 	"name": `Closed Tickets",
							// 	"value": element.value['data']['data']['ClosedTotalticket'],
							// 	"icon": "fa fa-ticket fa-1x red-300-fg"
							// }
						)
					} else {
						this.secondCount = 0;
						manipulated.push({
							"name": `${this.typeofData} Total Tickets`,
							"value": element.value['data']['data']['ActiveTotalticket'],
							"icon": "fa fa-ticket fa-1x red-300-fg",
							"color": "red-300-fg"
						}
							// , {
							// 	"name": `Closed Tickets",
							// 	"value": element.value['data']['data']['ClosedTotalticket'],
							// 	"icon": "fa fa-ticket fa-1x red-300-fg"
							// }
						)
					}
				}
				if (element.value['key'] == "leadsCount") {
					let totalCount = parseInt(this.firstCount) + parseInt(this.secondCount);
					let countData = this.typeofData == 'Live' ? totalCount : element.value['data']['data']['Leadcount'];
					if (element.status == "fulfilled") {
						manipulated.push({
							"name": `${this.typeofData} Leads`,
							"value": countData,
							"icon": "fa fa-users fa-1x yellow-300-fg",
							"color": "yellow-300-fg"
						})
					} else {
						manipulated.push({
							"name": `${this.typeofData} Leads`,
							"value": 0,
							"icon": "fa fa-users fa-1x yellow-300-fg",
							"color": "yellow-300-fg"
						})
					}
				}
				if (element.value['key'] == "allcount") {
					if (element.status == "fulfilled") {
						manipulated.push({
							"name": `${this.typeofData} Active Agents`,
							"value": element.value['data']['data']['ActiveAgentCount'],
							"icon": "fa fa-black-tie fa-1x cyan-400-fg",
							"color": "cyan-400-fg"
						}, {
							"name": `${this.typeofData} Activated Accounts`,
							"value": element.value['data']['data']['clientsiteCount'],
							"icon": "fa fa-sitemap fa-1x indigo-300-fg",
							"color": "indigo-300-fg"
						})
					} else {
						manipulated.push({
							"name": `${this.typeofData} Active Agents`,
							"value": 0,
							"icon": "fa fa-black-tie fa-1x cyan-400-fg",
							"color": "cyan-400-fg"
						}, {
							"name": `${this.typeofData} Activated Accounts`,
							"value": 0,
							"icon": "fa fa-sitemap fa-1x indigo-300-fg",
							"color": "indigo-300-fg"
						})
					}
				}
			});
			this.dashBoardDetails.next(manipulated);
		})
	}

	dashboardOpenticket(str: any) {
		let requestPayload = { "type": str }
		return new Promise((resolve, reject) => {
			this.commonService.getData(this.url + '/queueAndOpenTicketCount', requestPayload, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe')).subscribe((data: any) => {
					if (data.success) {
						resolve({ "key": "queueAndOpenTicketCount", data });
					}
				},
					(error) => { reject({ "key": "queueAndOpenTicketCount", error }) }
				)
		});
	}
	dashboardTodayTicket(str: any) {
		let requestPayload = { "type": str }
		return new Promise((resolve, reject) => {
			this.commonService.getData(this.url + '/ticketCount', requestPayload, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
					if (data.success) {
						resolve({ "key": "ticketCount", data });
					}
				}, error => reject({ "key": "ticketCount", error })
				);
		})
	}

	dashboardLeadsCount(str: any) {
		let requestPayload = { "type": str };
		return new Promise((resolve, reject) => {
			this.commonService.getData(this.url + '/leadsCount', requestPayload, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
					if (data.success) {
						resolve({ "key": "leadsCount", data });
					}
				},
					error => reject({ "key": "leadsCount", error })
				);
		})

	}
	dashboardAgentCount(str: any) {
		let requestPayload = { "type": str }
		return new Promise((resolve, reject) => {
			this.commonService.storeItem(this.url + '/allcount', requestPayload, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
					if (data.success) {
						resolve({ "key": "allcount", data });
					}
				}, error => reject(
					{ "key": "allcount", error }
				)
				);
		})
	}

	typeToShow(intract: any) {
		switch (intract) {
			case "Live":
				this.typeofData = "Live"
				return 'live'
				break;
			case "Today":
				this.typeofData = "Today"
				return 'today'
				break;
			case "This Week":
				this.typeofData = "This Week"
				return 'week'
				break;
			case "This Month":
				this.typeofData = "This Month"
				return 'month'
				break;
			case "This Year":
				this.typeofData = "This Year"
				return 'year'
				break;
			default:
				this.typeofData = "Today"
				return 'live';
				break;
		}
	}

	//Agent Dashboard

	agentDashbOard(): void {
		this.commonService.storeItem(this.url + '/agentticketsCount', {}, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					res.additional.forEach(closed => {
						res.data.forEach(element => {
							if (element.client_site_id == closed.client_site_id) {
								element['ClosedticketCount'] = closed.ClosedticketCount
							}
						});
					});
					this.agentDashBoardDetails.next(res.data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	ordersOverallCount(newParam): void {
		let requestPayload = { "type": newParam }
		this.commonService.getData('ecomDashboard', requestPayload, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.ordersCountSource.next(res.data);
				} else {
					this.ordersCountSource.next([]);
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	paymentOverallCount(newParam): void {
		let requestPayload = { "type": newParam }
		this.commonService.getData('ecomDashboard/paymentCount', requestPayload, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.paymentsCountSource.next(res.data);
				} else {
					this.paymentsCountSource.next([]);
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}


	//change Order Count update

	changeOrderCount(order: any, evenName: string) {
		var oldOrderCount = [];
		this.orderCounts.subscribe(data => oldOrderCount = data);
		if (oldOrderCount && oldOrderCount.length > 0) {
			var currentStatus = null;
			var updatedStatus = null;
			if (order.OrderStatusInfo && order.OrderStatusInfo.length > 1) {
				currentStatus = order.OrderStatusInfo[0];
				updatedStatus = order.OrderStatusInfo[1];
			} else {
				console.log('order socket OrderStatusInfo data failed');
			}

			if (currentStatus && currentStatus.status && updatedStatus && updatedStatus.status) {
				oldOrderCount.forEach(oldWidgets => {
					if (evenName == 'orderUpdate' && oldWidgets.status.trim() == updatedStatus.status) {
						oldWidgets.count = oldWidgets.count + 1;
					}
					if (evenName == 'orderUpdate' && oldWidgets.status.trim() == currentStatus.status) {
						if (oldWidgets.count > 0) {
							oldWidgets.count = oldWidgets.count - 1;
						} else {
							oldWidgets.count = 0;
						}
					}
					this.ordersCountSource.next(oldOrderCount);
				});
			}


		}
	}

}