export class TweetModel {
    id: number;
    tweet_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    twitter_code: string;
    in_reply_to_tweet_code: string;
    text: string;
    sender_id: string;
    recipient_id: string;
    user_name: string;
    user_screen_name: string;
    recipient_screen_name: string;
    tweet_at: Date;
    comment_count: number;
    user_image: string;
    media: string;
    asset_url: string;
    json: string;
    status: string;
    message_type: string;
    is_active: boolean;
    is_read: boolean;
    is_assigned: boolean;
    is_closed: boolean;
    is_itrs: boolean;
    intractive_id: number;
    intractive_group_id: number;
    people_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    statusType?: string;
    isSent?: boolean;
    pusherSync?: string;
    agentName?: string;
    createdByName?: string;
    createdByAvatar?: string;
    location_url?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    reply?: any;

    tableName: string;
    className: string;
    class?: string;
    replayPersonType: string;

    /**
     * Constructor
     *
     * @param tweet
     */
    constructor(tweet) {
            this.id = tweet.id || '';
            this.tweet_id = tweet.tweet_id || '';
            this.reply_id = tweet.reply_id || '';
            this.client_id = tweet.client_id || '';
            this.client_site_id = tweet.client_site_id || '';
            this.twitter_code = tweet.twitter_code || '';
            this.in_reply_to_tweet_code = tweet.in_reply_to_tweet_code || '';
            this.text = tweet.text || '';
            this.sender_id = tweet.sender_id || '';
            this.recipient_id = tweet.recipient_id || '';
            this.user_name = tweet.user_name || '';
            this.user_screen_name = tweet.user_screen_name || '';
            this.recipient_screen_name = tweet.recipient_screen_name || '';
            this.tweet_at = tweet.tweet_at || '';
            this.comment_count = tweet.comment_count || '';
            this.user_image = tweet.user_image || '';
            this.media = tweet.media || '';
            this.asset_url = tweet.asset_url || '';
            this.json = tweet.json || '';
            this.status = tweet.status || '';
            this.message_type = tweet.message_type || '';
            this.is_active = tweet.is_active || '';
            this.is_read = tweet.is_read || '';
            this.is_assigned = tweet.is_assigned || '';
            this.is_closed = tweet.is_closed || '';
            this.is_itrs = tweet.is_itrs || '';
            this.intractive_id = tweet.intractive_id || '';
            this.intractive_group_id = tweet.intractive_group_id || '';
            this.people_id = tweet.people_id || '';
            this.created_by = tweet.created_by || '';
            this.updated_by = tweet.updated_by || '';
            this.created_at = tweet.created_at || '';
            this.updated_at = tweet.updated_at || '';

            this.tableName = tweet.tableName || '';
            this.replayPersonType = tweet.replayPersonType || '';
    }
}
