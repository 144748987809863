export class DeliverySettingsModel {
    client_id: number;
    governorate_id: number;
    created_by: any;
    created_at: any;
    updated_at: any;
    __v: any;
    name_er: string;
    deliveryId: number;

    country_id: number;
    fips_code: number;
    id: number;
    _id: number;
    latitude: number;
    longitude: number
    name_ar: string;
    name_en: string;
    is_delivarable: any;
    price: any;
    currency: string;

    /**
     * Constructor
     *
     * @param DeliverySettings
     */
    constructor(DeliverySettings) {
        this.client_id = DeliverySettings.client_id || '';
        this.governorate_id = DeliverySettings.governorate_id || '';
        this.created_by = DeliverySettings.created_by || '';
        this.updated_at = DeliverySettings.updated_at || '';
        this.__v = DeliverySettings.__v || '';
        this.name_er = DeliverySettings.name_er || '';
        this.deliveryId = DeliverySettings.deliveryId || '';


        this.country_id = DeliverySettings.deliveryId || '';
        this.fips_code = DeliverySettings.deliveryId || '';
        this.id = DeliverySettings.deliveryId || '';
        this._id = DeliverySettings.deliveryId || '';
        this.latitude = DeliverySettings.deliveryId || '';
        this.longitude = DeliverySettings.deliveryId || '';
        this.name_ar = DeliverySettings.deliveryId || '';
        this.name_en = DeliverySettings.deliveryId || '';
        this.is_delivarable = DeliverySettings.deliveryId || '';
        this.price = DeliverySettings.price || '';
        this.currency = DeliverySettings.currency || '';
    }
}