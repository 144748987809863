import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PolicyComponent } from './policy/policy.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

const routes = [
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'privacy-policy',
                component: PolicyComponent,
            },
            {
                path: 'privacy-policy.html',
                component: PolicyComponent,
            },
        ]
    },
    {   
        path     : 'app',
        loadChildren: () => import('./../appAccount/appAccount.module').then(a => a.AppAccountModule),
        canActivate: [AuthGuard]
    },
    { path     : 'login', component: LoginComponent },
    { path     : 'register', component: RegisterComponent },
    { path     : '**', redirectTo: '/' }
];

@NgModule({
    imports     : [
        RouterModule.forChild(routes),
    ]
})
export class PageRouteModule {}
