import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ListComponent } from './../list/list.component';

import { User, UserService, AgentService, Agent } from 'src/app/@tji/_dbShare';
import { OrderByPipe } from 'ngx-pipes';

@UntilDestroy()
@Component({
	selector: 'agent-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	providers: [OrderByPipe]
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;
	agentsAll: Agent[] = [];
	@ViewChild(ListComponent) listComponent;

	constructor(public userService: UserService,
		public agentService: AgentService,
		private router: Router,
		private orderByPipe: OrderByPipe,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.agentsAll)
				if (data && data.length > 0) {
					data.forEach(agent => {
						if (agent && agent.CountInfo && agent.CountInfo.length > 0) {
							var counts = 0
							agent.CountInfo.forEach(count => {
								counts = count.count + counts;
							});
							agent.CountInfo = counts;
						}
					});
				}
			this.agentsAll = this.orderByPipe.transform(data, '-id');
		});
	}
	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }
	logoutAgentUser() {
		this.agentService.isAcitveLogoutAgent.next(true);
	}


	exportExcel() {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure want to Export Agent',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var title = 'Export Agent';
				setTimeout(() => {
					this.downloadFile(this.agentsAll, title);
				}, 2000);
			}
		});
	}

		// downloadFile
	downloadFile(agentsAll, title = null) {
		var excelData = [];
		if (agentsAll && agentsAll.length > 0) {
			agentsAll.forEach((agent, index )=> {
				if (agent && agent.id > 0) {
					var data = {
						"Agent Name": agent['name'],
						"Email ID": agent['userInfo']['email'],
						"Designation": agent['person_details']['designation'],
						"Code": agent['code'],
						"Contact Number": agent['person_details']["mobile"]?agent['person_details']['mobile']: '-',
						"Ticket Limit": agent['CountAllowed'],
					}
					if (agent['is_active'] == 1) {
						data["Status"] = "Yes"
					}else{
						data["Status"] = "No"
					}
					if (agent['userInfo']['MoreInfo']['is_supervisor'] == 1) {
						data["Supervisor"] = "Yes"
					}else{
						data["Supervisor"] = "No"
					}
					excelData.push(data);
				}
			});
		}

		let arrHeader = ["Agent Name", "Email ID", "Designation", "Code", "Contact Number", "Ticket Limit", "Supervisor", "Status"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'agent_reports_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}
	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Agent Name", "Email ID", "Designation", "Code", "Contact Number", "Ticket Limit", "Supervisor", "Status"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}
}
