import { Component, OnInit, Inject, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Media } from 'src/app/@tji/_dbShare';
import { Subscription } from 'rxjs';

import { PanZoomConfig, PanZoomAPI, PanZoomModel, PanZoomConfigOptions, Rect, PanZoomComponent } from 'ngx-panzoom';
import { MediaViewComponent } from '../dialog/mediaView/mediaView.component';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent {

  subLoader: boolean = false;
  imageList: any;


  constructor(public dialogRef: MatDialogRef<ImageCarouselComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private el: ElementRef,
		public matDialog: MatDialog,
    private changeDetector: ChangeDetectorRef,) {
    if (data && data.item && data.item.media === 'image') {
      this.imageList = data.item.asset_url;
      this.subLoader = false;
    }else if ((data && !data.item) || (data && data.item && !data.item.media)) {
      this.subLoader = false;
    }
    // this.onCenterContentClicked();
  }

  ngOnInit() {
    this.setSubLoader();
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 1000);
  }

  ngAfterViewInit(): void {

  }

previewMedia(chat: any) {
		let mediaData = {
				'url': chat,
				'type': 'image',
				'tableName': 'chatData',
			};
		if (mediaData && mediaData.url) {
			let dialogRef = this.matDialog.open(MediaViewComponent, {
				data: {
					item: mediaData,
				}
			});
		}
	}
  

}