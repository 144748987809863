import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
// import { BrowserModule } from '@angular/platform-browser';
import { LibraryModule } from 'src/app/library/library.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppAccountModule } from 'src/app/appAccount/appAccount.module';
import { ResellerModule } from 'src/app/reseller/reseller.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { NewPageRoutingModule } from './new-page-routing.module';
import { NewPageComponent } from './new-page.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { FooterStyleThreeComponent } from './components/common/footer-style-three/footer-style-three.component';
import { FreeTrialStyleOneComponent } from './components/common/free-trial-style-one/free-trial-style-one.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { ScreenshotsStyleOneComponent } from './components/common/screenshots-style-one/screenshots-style-one.component';
import { DownloadAppStyleOneComponent } from './components/common/download-app-style-one/download-app-style-one.component';
import { ProgressStyleOneComponent } from './components/common/progress-style-one/progress-style-one.component';
import { FeaturesStyleOneComponent } from './components/common/features-style-one/features-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { ProgressStyleTwoComponent } from './components/common/progress-style-two/progress-style-two.component';
import { IntroVideoComponent } from './components/common/intro-video/intro-video.component';
import { ScreenshotsStyleTwoComponent } from './components/common/screenshots-style-two/screenshots-style-two.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FreeTrialStyleTwoComponent } from './components/common/free-trial-style-two/free-trial-style-two.component';
import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { FeaturesStyleTwoComponent } from './components/common/features-style-two/features-style-two.component';
import { ScreenshotsStyleThreeComponent } from './components/common/screenshots-style-three/screenshots-style-three.component';
import { SoftwareIntegrationsComponent } from './components/common/software-integrations/software-integrations.component';
import { DownloadAppStyleTwoComponent } from './components/common/download-app-style-two/download-app-style-two.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeaturesStyleThreeComponent } from './components/common/features-style-three/features-style-three.component';
import { DownloadAppStyleThreeComponent } from './components/common/download-app-style-three/download-app-style-three.component';
import { FeedbackStyleThreeComponent } from './components/common/feedback-style-three/feedback-style-three.component';
import { ScreenshotsStyleFourComponent } from './components/common/screenshots-style-four/screenshots-style-four.component';
import { ProgressStyleThreeComponent } from './components/common/progress-style-three/progress-style-three.component';
import { FreeTrialStyleThreeComponent } from './components/common/free-trial-style-three/free-trial-style-three.component';
import { FeedbackStyleFourComponent } from './components/common/feedback-style-four/feedback-style-four.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { ScreenshotsStyleFiveComponent } from './components/common/screenshots-style-five/screenshots-style-five.component';
import { FeaturesStyleFourComponent } from './components/common/features-style-four/features-style-four.component';
import { FeedbackStyleFiveComponent } from './components/common/feedback-style-five/feedback-style-five.component';
import { ScreenshotsStyleSixComponent } from './components/common/screenshots-style-six/screenshots-style-six.component';
import { FeaturesStyleFiveComponent } from './components/common/features-style-five/features-style-five.component';
import { FunfactsStyleOneComponent } from './components/common/funfacts-style-one/funfacts-style-one.component';
import { FunfactsStyleTwoComponent } from './components/common/funfacts-style-two/funfacts-style-two.component';
import { NavbarStyleFourComponent } from './components/common/navbar-style-four/navbar-style-four.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { ContactInfoComponent } from './components/pages/contact-page/contact-info/contact-info.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { BlogSidebarComponent } from './components/common/blog-sidebar/blog-sidebar.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AppDownloadPageComponent } from './components/pages/app-download-page/app-download-page.component';
import { TeamStyleOneComponent } from './components/common/team-style-one/team-style-one.component';
import { UserStatsComponent } from './components/common/user-stats/user-stats.component';
import { TeamStyleTwoComponent } from './components/common/team-style-two/team-style-two.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeBannerComponent } from './components/pages/home/home-banner/home-banner.component';
import { HomeSofterIntegrationComponent } from './components/pages/home/home-softer-integration/home-softer-integration.component';
import { HomeFeaturesAreaComponent } from './components/pages/home/home-features-area/home-features-area.component';
import { HomeFunfactsAreaComponent } from './components/pages/home/home-funfacts-area/home-funfacts-area.component';
import { HomeAboutOctopusComponent } from './components/pages/home/home-about-octopus/home-about-octopus.component';
import { HomeAppIntegrationComponent } from './components/pages/home/home-app-integration/home-app-integration.component';
import { FooterOctopusComponent } from './components/common/footer-octopus/footer-octopus.component';
import { OctopusBenefitsComponent } from './components/pages/octopus-benefits/octopus-benefits.component';

import { BenefitsOneComponent } from './components/pages/octopus-benefits/benefits-one/benefits-one.component';
import { BenefitsTwoComponent } from './components/pages/octopus-benefits/benefits-two/benefits-two.component';
import { BenefitsThreeComponent } from './components/pages/octopus-benefits/benefits-three/benefits-three.component';
import { BenefitsFourComponent } from './components/pages/octopus-benefits/benefits-four/benefits-four.component';
import { BenefitsFiveComponent } from './components/pages/octopus-benefits/benefits-five/benefits-five.component';

import { OctopusFeaturesComponent } from './components/pages/octopus-features/octopus-features.component';
import { FeaturesOneComponent } from './components/pages/octopus-features/features-one/features-one.component';
import { FeaturesTwoComponent } from './components/pages/octopus-features/features-two/features-two.component';
import { FeaturesThreeComponent } from './components/pages/octopus-features/features-three/features-three.component';
import { FeaturesFourComponent } from './components/pages/octopus-features/features-four/features-four.component';
import { FeaturesFiveComponent } from './components/pages/octopus-features/features-five/features-five.component';
import { FeaturesSixComponent } from './components/pages/octopus-features/features-six/features-six.component';
import { OctopusPricingComponent } from './components/pages/octopus-pricing/octopus-pricing.component';
import { PricingOneComponent } from './components/pages/octopus-pricing/pricing-one/pricing-one.component';
import { OctopusClientsComponent } from './components/pages/octopus-clients/octopus-clients.component';
import { OctopusContactSalesComponent } from './components/pages/octopus-contact-sales/octopus-contact-sales.component';
import { ShotClientsComponent } from './components/pages/home/shot-clients/shot-clients.component';
import { WhatsappIconComponent } from './components/common/whatsapp-icon/whatsapp-icon.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { PremiumDashboardComponent } from './components/premiumDashboard/premiumDashboard.component';
import { AlertComponent } from './components/premiumDashboard/alert/alert.component';
import { TicketsCountComponent } from './components/premiumDashboard/ticketsCount/ticketsCount.component';
import { AgentsCountComponent } from './components/premiumDashboard/agentsCount/agentsCount.component';
import { AgentTicketCountComponent } from './components/premiumDashboard/agentTicketCount/agentTicketCount.component';
import { LiveTicketsComponent } from './components/premiumDashboard/liveTickets/liveTickets.component';
import { LiveMessagesComponent } from './components/premiumDashboard/liveMessages/liveMessages.component';
import { LeadsBoardComponent } from './components/premiumDashboard/leadsBoard/leadsBoard.component';
import { PerformanceComponent } from './components/premiumDashboard/performance/performance.component';
import { PremiumDashboardService, WebchatGuestService } from 'src/app/@tji/_dbShare';
import { VerifySuccessComponent } from './verifySuccess/verifySuccess.component';
import { OrderCountComponent } from './components/premiumDashboard/order-count/order-count.component';
import { PaymentsCountComponent } from './components/premiumDashboard/payments-count/payments-count.component';
import { BestSellingProductComponent } from './components/premiumDashboard/best-selling-product/best-selling-product.component';
import { AgentAicollectionCountComponent } from './components/premiumDashboard/agent-aicollection-count/agent-aicollection-count.component';
import { FlagsCountComponent } from './components/premiumDashboard/flags-count/flags-count.component';
import { LabelCountComponent } from './components/premiumDashboard/label-count/label-count.component';

@NgModule({
    declarations: [
        NewPageComponent,
        FooterStyleOneComponent,
        FooterStyleTwoComponent,
        FooterStyleThreeComponent,
        FreeTrialStyleOneComponent,
        FeedbackStyleOneComponent,
        ScreenshotsStyleOneComponent,
        DownloadAppStyleOneComponent,
        ProgressStyleOneComponent,
        FeaturesStyleOneComponent,
        NavbarStyleOneComponent,
        NavbarStyleTwoComponent,
        ProgressStyleTwoComponent,
        IntroVideoComponent,
        ScreenshotsStyleTwoComponent,
        PartnerComponent,
        FreeTrialStyleTwoComponent,
        FeedbackStyleTwoComponent,
        FeaturesStyleTwoComponent,
        ScreenshotsStyleThreeComponent,
        SoftwareIntegrationsComponent,
        DownloadAppStyleTwoComponent,
        BlogComponent,
        FeaturesStyleThreeComponent,
        DownloadAppStyleThreeComponent,
        FeedbackStyleThreeComponent,
        ScreenshotsStyleFourComponent,
        ProgressStyleThreeComponent,
        FreeTrialStyleThreeComponent,
        FeedbackStyleFourComponent,
        NavbarStyleThreeComponent,
        ScreenshotsStyleFiveComponent,
        FeaturesStyleFourComponent,
        FeedbackStyleFiveComponent,
        ScreenshotsStyleSixComponent,
        FeaturesStyleFiveComponent,
        FunfactsStyleOneComponent,
        FunfactsStyleTwoComponent,
        NavbarStyleFourComponent,
        ContactPageComponent,
        ContactInfoComponent,
        FaqPageComponent,
        HowItWorksPageComponent,
        BlogSidebarComponent,
        ComingSoonPageComponent,
        NotFoundComponent,
        AppDownloadPageComponent,
        TeamStyleOneComponent,
        UserStatsComponent,
        TeamStyleTwoComponent,
        HomeComponent,
        HomeBannerComponent,
        HomeSofterIntegrationComponent,
        HomeFeaturesAreaComponent,
        HomeFunfactsAreaComponent,
        HomeAboutOctopusComponent,
        HomeAppIntegrationComponent,
        FooterOctopusComponent,
        OctopusBenefitsComponent,
        BenefitsOneComponent,
        BenefitsTwoComponent,
        BenefitsThreeComponent,
        BenefitsFourComponent,
        BenefitsFiveComponent,
        OctopusFeaturesComponent,
        FeaturesOneComponent,
        FeaturesTwoComponent,
        FeaturesThreeComponent,
        FeaturesFourComponent,
        FeaturesFiveComponent,
        FeaturesSixComponent,
        OctopusPricingComponent,
        PricingOneComponent,
        OctopusClientsComponent,
        OctopusContactSalesComponent,
        ShotClientsComponent,
        WhatsappIconComponent,
        LoginComponent,
        RegisterComponent,
        PremiumDashboardComponent,
        AlertComponent,
        TicketsCountComponent,
        AgentsCountComponent,
        AgentTicketCountComponent,
        LiveTicketsComponent,
        LiveMessagesComponent,
        PerformanceComponent,
        LeadsBoardComponent,
        VerifySuccessComponent,
        OrderCountComponent,
        PaymentsCountComponent,
        BestSellingProductComponent,
        AgentAicollectionCountComponent,
        FlagsCountComponent,
        LabelCountComponent,
    ],
    imports: [
        AppShareModule,
        LibraryModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NewPageRoutingModule,
        AppAccountModule,
        ResellerModule,
        PipesModule,
        TranslateModule,
    ],
    exports: [
        LibraryModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AppAccountModule,
        ResellerModule,
        PipesModule,
    ],
    providers: [],
    bootstrap: [NewPageComponent]
})
export class NewPageModule { }
