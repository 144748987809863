import { Component, ElementRef, Input, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, AfterViewChecked, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ChatUserService, ChatUser,
	TeamChatService, TeamChat, TeamChatModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'appaccount-chat-type',
	templateUrl: './chatType.component.html',
	styleUrls: ['./chatType.component.scss']
})

export class ChatTypeComponent implements OnInit {

	@Input() authUser: User;
	@Input() chat: TeamChat;
	@Input() activeUser: ChatUser;

	constructor() { }

	ngOnInit() { }

}
