export class ModalIntractiveGroupModel {
	id: number;
	resource_id: number;
	resource_type: string;
	intractive_group_id: number;
	client_id: number;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param modal_intractive_group
	 */
	constructor(modal_intractive_group) {
		this.id = modal_intractive_group.id || '';
		this.resource_id = modal_intractive_group.resource_id || '';
		this.resource_type = modal_intractive_group.resource_type || '';
		this.intractive_group_id = modal_intractive_group.intractive_group_id || '';
		this.client_id = modal_intractive_group.client_id || '';
		this.created_by = modal_intractive_group.created_by || '';
		this.updated_by = modal_intractive_group.updated_by || '';
		this.created_at = modal_intractive_group.created_at || '';
		this.updated_at = modal_intractive_group.updated_at || '';

		this.tableName = modal_intractive_group.tableName || '';
	}
}
