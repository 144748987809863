import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';

const routes = [
    {
        path: '',
        component: MainComponent,
        // resolve: { data: LogService },
    },
    { path: '**', redirectTo: '/app/log' }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ]
})
export class DiagnosticToolRouteModule { }
