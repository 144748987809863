import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TruncatePipe } from 'src/app/pipes/string/truncate.pipe';
declare let $: any;
import { environment } from 'src/environments/environment';
import {
    PremiumDashboardService, PremiumDashboard, PremiumAlert,
    PremiumPerformance, EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'premium-dashboard-performance',
    templateUrl: './performance.component.html',
    styleUrls: ['./performance.component.scss'],
    animations: tjiAnimation,
    providers: [
        TruncatePipe,
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class PerformanceComponent {

    duration: number = 5000;
    animOutState: boolean = true;
    animInState: boolean = true;
    dashboardParam: any;
    performances: PremiumPerformance[] = [];
    base_image: string = 'assets/img/no-user.jpg';
    channel: any;
    eventData: any;

    constructor(private router: Router,
        private eventService: EventService,
        public premiumDashboardService: PremiumDashboardService) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
        this.premiumDashboardService.performances.pipe(untilDestroyed(this)).subscribe(data => this.performances = data);
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit() {
        this.checkDuration();
    }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'aticketreopened':
                    this.premiumDashboardService.appendPerformance(data, "Re-open");
                    break;
                case 'aticketreassigned':
                    this.premiumDashboardService.appendPerformance(data, "Assigned");
                    break;
                case 'ticketclosed':
                    this.premiumDashboardService.appendPerformance(data, "Closed");
                    break;
                default:
                    break;
            }
        }
    }

    checkDuration() {
        if (this.performances.length > 20) { this.duration = 500; }
        else if (this.performances.length > 10) { this.duration = 700; }
        else if (this.performances.length > 5) { this.duration = 800; }
        else { this.duration = 1500; }
        setTimeout(() => {
            if (this.performances.length > 4) {
                this.performances.shift();
            }
            this.checkDuration();
        }, this.duration);
    }

}
