import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { AgentDashboardRouteModule } from './agentDashboard-route.module';
import { GalleryExportModule } from 'src/app/appAccount/gallery/gallery-export.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ChatInfoComponent } from './chatInfo/chatInfo.component';
import { GalleryViewComponent } from './galleryView/galleryView.component';
import { QuickMessageComponent } from './quickMessage/quickMessage.component';
import { QuickMessageFormComponent } from './quickMessageForm/quickMessageForm.component';
import { QuickMessageMediaFormComponent } from './quickMessageMediaForm/quickMessageMediaForm.component';
import { InitiateMessageComponent } from './initiateMessage/initiateMessage.component';
import { InitiateMessageFormComponent } from './initiateMessageForm/initiateMessageForm.component';
import { ReassignFormComponent } from './reassignForm/reassignForm.component';

import { AgentAllOpenTicketComponent } from './ticket/allOpenTicket/allOpenTicket.component';
import { AgentClosedTicketComponent } from './ticket/closedTicket/closedTicket.component';
import { AgentDashboardTicketsComponent } from './ticket/tickets/tickets.component';
import { ChatComponent } from './ticket/chat/chat.component';
import { ChatBoardComponent } from './ticket/chatBoard/chatBoard.component';

import { Chart1Component } from './chart1/chart1.component';

import { CommentSideNavComponent } from './itemView/commentSidenav/commentSidenav.component';
import { FeedbackSideNavComponent } from './itemView/feedbackSidenav/feedbackSidenav.component';
import { FlagSideNavComponent } from './itemView/flagSidenav/flagSidenav.component';
import { PersonSideNavComponent } from './itemView/personSidenav/personSidenav.component';
import { LeadsHistorySideNavComponent } from './itemView/leadsHistorySidenav/leadsHistorySidenav.component';

import { CustomerListComponent } from './customers/list/list.component';
// import { CustomerMessagesComponent } from './customers/messages/messages.component';

import { ChatService } from 'src/app/@tji/_dbShare';
import { ProductCatalogModule } from "../product-catalog/product-catalog.module";
import { TrackTicketComponent } from './itemView/track-ticket/track-ticket.component';
import { AiCollectionModule } from '../aiCollection/aiCollection.module';
import { CatalogModule } from '../catalog/catalog.module';
import { FbTemplateImportModule } from "../fbTemplate/fb-template-import.module";
import { OnlineStatusModule } from 'ngx-online-status';
@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ChatInfoComponent,
        GalleryViewComponent,
        QuickMessageComponent,
        QuickMessageFormComponent,
        QuickMessageMediaFormComponent,
        InitiateMessageComponent,
        InitiateMessageFormComponent,
        ReassignFormComponent,
        AgentDashboardTicketsComponent,
        ChatComponent,
        ChatBoardComponent,
        Chart1Component,
        CommentSideNavComponent,
        FeedbackSideNavComponent,
        FlagSideNavComponent,
        PersonSideNavComponent,
        LeadsHistorySideNavComponent,
        CustomerListComponent,
        // CustomerMessagesComponent,
        AgentAllOpenTicketComponent,
        AgentClosedTicketComponent,
        TrackTicketComponent,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
        ListComponent,
        ChatInfoComponent,
        GalleryViewComponent,
        QuickMessageComponent,
        QuickMessageFormComponent,
        QuickMessageMediaFormComponent,
        InitiateMessageComponent,
        InitiateMessageFormComponent,
        ReassignFormComponent,
        AgentDashboardTicketsComponent,
        ChatComponent,
        ChatBoardComponent,
        Chart1Component,
        CommentSideNavComponent,
        FeedbackSideNavComponent,
        FlagSideNavComponent,
        PersonSideNavComponent,
        LeadsHistorySideNavComponent,
        CustomerListComponent,
        // CustomerMessagesComponent,
        AgentAllOpenTicketComponent,
        AgentClosedTicketComponent,
        TrackTicketComponent,
    ],
    providers: [
        ChatService
    ],
    imports: [
        AppShareModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        GalleryExportModule,
        AgentDashboardRouteModule,
        ProductCatalogModule,
        AiCollectionModule,
        CatalogModule,
        FbTemplateImportModule,
        OnlineStatusModule
    ]
})

export class AgentDashboardModule { }
