import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
import { FormModuleComponent } from './../formModule/formModule.component';

import {
	User, UserService,
	PermissionService, Permission, PermissionModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'managePermission-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	permissions: Permission[] = [];
	groupedPermissions: any[] = [];
	param: any;
	permissionResponse: any;
	dataSource = new MatTableDataSource(this.permissions);
	displayedColumns = ['avatar', 'name', 'applied', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public permissionService: PermissionService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.permissionService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.permissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.permissions = data;
		});
		this.permissionService.groupedItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.groupedPermissions = data;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.permissionService.params.subscribe(data => this.param = data);
		// this.permissionService.totalItem.subscribe(data => this.dataLength = data);
	}

	/** Add / Update Permission */
	newPermisson(permission = null): void {
		this.permissionService.changeItem((permission) ? permission : new PermissionModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (permission) ? 'edit' : 'new',
				item: permission,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.permissionService.store(formData.value);
						break;
					case 'edit':
						this.permissionService.update(id, formData.value);
						break;
					case 'delete':
						this.deletePermission(permission);
						break;
				}
			});
	}

	/** Delete Social Site */
	deletePermission(permission: Permission): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.permissionService.destroy(permission.id);
			}
		});
	}

	/** Add / Update Module */
	newModule(permission = null): void {
		this.permissionService.changeItem((permission) ? permission : new PermissionModel({}));
		let dialogRef = this.matDialog.open(FormModuleComponent, {
			width: "600px",
			data: {
				action: (permission) ? 'edit' : 'new',
				item: permission,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.permissionService.store(formData.value);
						break;
					case 'edit':
						this.permissionService.update(id, formData.value);
						break;
					case 'delete':
						this.deletePermission(permission);
						break;
				}
			});
	}



}
