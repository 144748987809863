export class PaymentModel {
    _id: number;
    people_id: number;
    client_site_id: number;
    type: string;
    payment_type: number;
    amount: number;
    currency: string;
    expire_time: number;
    expire_at: number;
    payment_interval: number;
    direct_name: string;
    direct_phone: any;
    direct_email: string;
    payment_status: string;
    payment_link: string;
    trackId: number;
    typeId: number;
    person_id: number;
    person_type: string;
    client_id: number;
    created_by: any;
    updated_by: any;
    created_at: any;
    updated_at: any;
    PaymentId: any;

    /**
     * Constructor
     *
     * @param payment
     */
    constructor(payment) {
        this._id = payment._id || '';
        this.people_id = payment.people_id || '';
        this.client_site_id = payment.client_site_id || '';
        this.type = payment.type || '';
        this.payment_type = payment.payment_type || '';
        this.amount = payment.amount || '';
        this.currency = payment.currency || '';
        this.expire_time = payment.expire_time || '';
        this.expire_at = payment.expire_at || '';
        this.payment_interval = payment.payment_interval || '';
        this.direct_name = payment.direct_name || '';
        this.direct_phone = payment.direct_phone || '';
        this.direct_email = payment.direct_email || '';
        this.payment_status = payment.payment_status || '';
        this.payment_link = payment.payment_link || '';
        this.trackId = payment.trackId || '';
        this.typeId = payment.typeId || '';
        this.person_id = payment.person_id || '';
        this.person_type = payment.person_type || '';
        this.client_id = payment.client_id || '';
        this.created_by = payment.created_by || '';
        this.updated_by = payment.updated_by || '';
        this.created_at = payment.created_at || '';
        this.updated_at = payment.updated_at || '';
        this.PaymentId = payment.paymentId || '';
    }
}