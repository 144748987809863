import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from "../../app-share.module";

import { CatalogRoutingModule } from './catalog-routing.module';
import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { SidebarCatalogComponent } from './sidebar-catalog/sidebar-catalog.component';
import { AssignCatalogWabaComponent } from './assign-catalog-waba/assign-catalog-waba.component';
import { MainProductComponent } from './main-product/main-product.component';
import { ProductListComponent } from './product-list/product-list.component';
import { SidebarProductComponent } from './sidebar-product/sidebar-product.component';
import { ProductsComponent } from './products/products.component';
import { DeliverySettingsComponent } from './delivery-settings/delivery-settings.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { OrderListComponent } from './order-list/order-list.component';
import { CartOrderListComponent } from './cart-order-list/cart-order-list.component';
import { OrderStatusUpdateComponent } from './order-status-update/order-status-update.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrderConfirmedComponent } from './order-confirmed/order-confirmed.component';
import { AddressListComponent } from './address-list/address-list.component';
import { OrderPrintPageComponent } from './order-print-page/order-print-page.component';
import { OrderGroupListComponent } from './order-group-list/order-group-list.component';
import { OrderReassignFormComponent } from './order-reassign-form/order-reassign-form.component';
import { BulkUploadProductComponent } from './bulk-upload-product/bulk-upload-product.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CatalogCategoryComponent } from './catalog-category-list/catalog-category.component';
import { MainCategoryComponent } from './main-category/main-category.component';
import { SidebarCategoryComponent } from './sidebar-category/sidebar-category.component';
import { ManageCategoryComponent } from './manage-category/manage-category.component';
import { ItrsbotImportModule } from "../itrsbot/itrsbot-import.module";
import { CategoryProductListComponent } from './category-product-list/category-product-list.component';

@NgModule({
  declarations: [
    MainComponent,
    ListComponent,
    SidebarCatalogComponent,
    AssignCatalogWabaComponent,
    MainProductComponent,
    ProductListComponent,
    SidebarProductComponent,
    ProductsComponent,
    DeliverySettingsComponent,
    PaymentListComponent,
    OrderListComponent,
    CartOrderListComponent,
    OrderStatusUpdateComponent,
    OrderViewComponent,
    OrderConfirmedComponent,
    AddressListComponent,
    OrderPrintPageComponent,
    OrderGroupListComponent,
    OrderReassignFormComponent,
    BulkUploadProductComponent,
    SidebarComponent,
    CatalogCategoryComponent,
    MainCategoryComponent,
    SidebarCategoryComponent,
    ManageCategoryComponent,
    CategoryProductListComponent,
  ],
  imports: [
    AppShareModule,
    CommonModule,
    CatalogRoutingModule,
    ItrsbotImportModule
],
  exports: [
    CartOrderListComponent,
    OrderPrintPageComponent,
    OrderGroupListComponent,
  ]
})
export class CatalogModule { }
