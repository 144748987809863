export class ContactModel {
    name: string;
    email: string;
    message: string;
    phone?: string;
    subject: string;


    /**
     * user
     *
     * @param user
     */
    constructor(user) {

    }
}