export class ResellerClientTemplateReportModel {
	client_id: number;
	clientName: string;
	clientAvatar: string;
	total_jobs?: number;
	total_count?: number;
	success_count?: number;
	failed_count?: number;
	rejectedCount?: number;

	/**
	 * Constructor
	 * @param resellerTemplateReport
	 */
	constructor(resellerTemplateReport) {
		this.client_id = resellerTemplateReport.client_id || null;
		this.clientName = resellerTemplateReport.clientName || null;
		this.clientAvatar = resellerTemplateReport.clientAvatar || null;
		this.total_jobs = resellerTemplateReport.total_jobs || 0;
		this.total_count = resellerTemplateReport.total_count || 0;
		this.success_count = resellerTemplateReport.success_count || 0;
		this.failed_count = resellerTemplateReport.failed_count || 0;
		this.rejectedCount = resellerTemplateReport.rejectedCount || 0;
	}
}
