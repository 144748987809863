import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  CatalogService, CatalogModel, Catalog, UserService, Business, BusinessService,
  AlertService,
  CategoryService,
  Category
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'sidebar-category',
  templateUrl: './sidebar-category.component.html',
  styleUrls: ['./sidebar-category.component.scss']
})
export class SidebarCategoryComponent {

  @Output() onClose = new EventEmitter();
  categoryForm: UntypedFormGroup;
  categoryTitle: string = 'Create';
  category: Category;
  catalogList: Catalog[] = [];

  constructor(public catalogService: CatalogService,
    public categoryService: CategoryService,
    public matDialog: MatDialog,
    public userService: UserService,
    public businessService: BusinessService,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder) {
    this.categoryService.item.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.category = data;
        this.categoryForm = this.createForm();
        if (data && data._id) {
          this.categoryTitle = 'Edit Category';
          this.categoryForm.get('catalog_id').disable();
        } else {
          this.categoryTitle = 'New Category';
          this.resetForm()
          this.categoryForm.get('catalog_id').enable();
        }
      }else{
        this.categoryTitle = 'New Category';
        this.resetForm()
        this.categoryForm.get('catalog_id').enable();
      }
    });

    this.categoryService.addItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.resetForm()
      }
    });

    this.catalogService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.catalogList = data;
      }
    });

  }

  ngOnInit(): void {
    // this.categoryForm.reset();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.categoryService.itemSource.next(null);
  }

  createForm(): UntypedFormGroup {
    return this._formBuilder.group({
      catalog_id: [this.category.catalog_id || null, [Validators.required]],
      name: [this.category.name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
    });
  }

  submit() {
    if (this.categoryForm.invalid) {
      return
    }

    if (this.category._id) {
      var update = {
        "catalog_id": this.category.catalog_id.toString(),
        "name": this.categoryForm.value.name,
        "id": this.category._id.toString(),
      }
      this.categoryService.update('catCategory/update', update, this.category);
      this.onClose.emit();
      this.categoryService.changeItem(null)
    } else {
      var data = {
        "catalog_id": this.categoryForm.value.catalog_id.toString(),
        "name": this.categoryForm.value.name,
      }
      this.categoryService.store('catCategory/add', data);
      this.onClose.emit();
      this.categoryService.changeItem(null)
    }
    
  }

  resetForm() {
    this.categoryForm.reset();
    // this.categoryForm.get('catalog_id').enable();
  }

  onClientSiteChange(value) {
    // if (value) {
    //   this.catalogList.forEach(element => {
    //     if (element.catalog_id == value && element['service_provider'] == 'option-7') {
    //       this.alertService.webShow('Danger', 'The catalog should be added while configuring the number on the account.');
    //       this.resetForm();
    //     }
    //   });
    // }
  }
}
