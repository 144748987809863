import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-text-loader',
  templateUrl: './textLoader.component.html',
  styleUrls: ['./textLoader.component.scss']
})
export class TextLoaderComponent implements OnInit {

  @Input() enable: boolean = false;

  constructor() {}

  ngOnInit() { }

}
