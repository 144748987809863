import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import * as moment from 'moment-timezone';
import { User, UserService, PeopleService, LabelService } from 'src/app/@tji/_dbShare';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { LeadsexportexcelComponent } from '../leadsexportexcel/leadsexportexcel.component';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
	selector: 'people-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;
	filterType = 'lead';
	@ViewChild('rightSideBar') rightSideBar: MatSidenav;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		private router: Router,
		private labelService: LabelService,
		@Inject(DOCUMENT) private document: Document,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.labelService.getAllLabelItems();
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Export Excel */
	// exportExcel() {
	// 	let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
	// 		disableClose: false,
	// 		width: '600',
	// 		data: {
	// 			type: 'info',
	// 			message: 'Are you sure you want to Export all Leads',
	// 		}
	// 	});
	// 	confirmDialogRef.afterClosed().subscribe(result => {
	// 		if (result && result.data) {
	// 			let clientId = this.userService.getAuthClientId();
	// 			// let url: string = this.peopleService.getExportUrl('excel');
	// 			let url: string = "https://pin.ai-octopus.com/leads/export/";
	// 			window.open(url + clientId, '_blank');
	// 		}
	// 	});
	// }



	/** Export PDF */
	exportPdf() {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to PDF all Leads',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let clientId = this.userService.getAuthClientId();
				let url: string = this.peopleService.getExportUrl('pdf');
				window.open(url + clientId, '_blank');
			}
		});
	}



	/** Export Excel */


	exportExcel() {

		let confirmDialogRef = this.matDialog.open(LeadsexportexcelComponent, {
			data: {
				type: 'info',
			},
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.value) {
			
				let dateFormat: any = {
					"from_date": moment(result.value.start).format("YYYY-MM-DD"),
					"to_date": moment(result.value.end).format("YYYY-MM-DD"),
				}
				
				if (result.value.client_site_id > 0) {
					dateFormat.client_site_id = result.value.client_site_id
				}
				this.peopleService.export(dateFormat);
			}
		});
	}

	toggleRightSidebar() {
		this.rightSideBar.toggle();
	}
}
