import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, CommonService, ClientToggleService,
	Site,  SiteModel, PusherService,
	 ClientSite, ClientSiteModel,
	RSiteService
} from 'src/app/@tji/_dbShare';
import { exit } from 'process';

@UntilDestroy()
@Component({
	selector: 'reseller-socialsite-media',
	templateUrl: './socialMedia.component.html',
	styleUrls: ['./socialMedia.component.scss'],
	providers: [OrderByPipe]
})

export class SocialMediaComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	isAdmin: any;
	sites: any[];
	param: any;

	dataLength: number;

	constructor(public userService: UserService,
		public commonService: CommonService,
		public rSiteService: RSiteService,
		public clientToggleService: ClientToggleService,
		public pusherService: PusherService,
		private router: Router,
		public matDialog: MatDialog,
		private _formBuilder: UntypedFormBuilder,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.rSiteService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.sites = this.orderByPipe.transform(data, '-id');
		});
		this.isAdmin = this.userService.isAuthUserIsAdmin();
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.rSiteService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	selectSite(site = null) {
		this.rSiteService.changeItem((site));
		this.clientToggleService.changeItem(null);
		this.router.navigateByUrl('/reseller/socialsite/'+site.id+'/socialsites');
	}

}
