import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  User, UserService,
  TeamService, Team, TeamModel
} from 'src/app/@tji/_dbShare';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent {
  dialogTitle: string = 'Edit Template Schedule';
  authUser: User;
  schedule: any;
  action: string;

  isSendLater: any = false;
	typeData = [
		{
			id: 1,
			name: 'Once'
		},
		{
			id: 2,
			name: 'Daily'
		},
		{
			id: 3,
			name: 'Weekly'
		},
		{
			id: 4,
			name: 'Monthly'
		},
		{
			id: 5,
			name: 'Yearly'
		},
	]
	shot_until: any = null;
	type: any = null;
	time_shot: any = null;
	currentDate: any = new Date();
	minTime: any = null;
	maxTime: any = null;
  scheduleData: any = {
    next_shot: new Date(),
    shot_until: new Date(),
    type: 1,
    time_shot: "12:00 AM",
  };

  constructor(public userService: UserService,
    public matDialogRef: MatDialogRef<RescheduleComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder) {

    this.getInit(_data);
  }

  ngOnInit() { }

  /** On destroy */
  ngOnDestroy(): void { }

  getInit(data) {
    // Set the defaults
    this.schedule = data.item;
    this.scheduleData["next_shot"] = new Date(moment(this.schedule.next_short).local().format('YYYY-MM-DD HH:mm:ss'));
    this.scheduleData["shot_until"] =  new Date(moment(this.schedule.until).local().format('YYYY-MM-DD HH:mm:ss'));
    this.scheduleData["type"] = this.schedule.schedule_type;
    this.scheduleData["time_shot"] = moment(this.schedule.next_short).local().format('hh:mm A');;
    this.action = data.action;
    switch (data.action) {
      case 'edit':
        this.dialogTitle = 'Edit Template Schedule';
        break;
      default:
        break;
    }
  }


  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }


  
	onDateChange(event: MatDatepickerInputEvent<Date>): void {
		var date = new Date(event.value);
		var selectedDate = moment(new Date(event.value)).format('YYYY-MM-DD');
		var current = moment(new Date()).format('YYYY-MM-DD'); // moment(new Date()).format('h:mm A');
		if (selectedDate != current) {
			this.minTime = '12:00 AM';
			this.maxTime = '11:59 PM';
			
		}else{
			var currentDate  =  moment(new Date()).add(15, 'minutes').format('hh:mm A'); // moment(new Date()).format('h:mm A');
			this.minTime = currentDate;
			this.maxTime = '11:59 PM';
		}
		// let year = date.getFullYear();
		// date.setFullYear(year + 1);
		// this.scheduleData["shot_until"] = date;
	}
  
}
