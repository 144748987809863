export class TemplateUsageModel {
    id:number;
    jobcode: string;
    template_id: number;
    client_id: number;
    total_count: number;
    success_count: number;
    failed_count: number;
	is_completed: boolean;
	json: string;
    created_by:number;
    updated_by:number;
    created_at:Date;
    updated_at:Date;

    template?:any;
    templateCode?:string;
    allUsers?:Array<any>;
    failedUsers?:Array<any>;
    templateName: string;
    rejected_count: number;

    /**
     * Constructor
     *
     * @param templateUsage
     */
    constructor(templateUsage) {
            this.id = templateUsage.id || '';
            this.jobcode = templateUsage.jobcode || '';
            this.template_id = templateUsage.template_id || '';
            this.client_id = templateUsage.client_id || '';
            this.total_count = templateUsage.total_count || '';
            this.success_count = templateUsage.success_count || '';
            this.failed_count = templateUsage.failed_count || '';
            this.is_completed = templateUsage.is_completed || '';
            this.json = templateUsage.json || '';
            this.rejected_count = templateUsage.rejected_count || '';
    }
}
