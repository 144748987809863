import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppDownloadPageComponent } from './components/pages/app-download-page/app-download-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';

//Ai-octopus menu
import { HomeComponent } from './components/pages/home/home.component';
import { OctopusBenefitsComponent } from './components/pages/octopus-benefits/octopus-benefits.component';
import { OctopusFeaturesComponent } from './components/pages/octopus-features/octopus-features.component';
import { OctopusPricingComponent } from './components/pages/octopus-pricing/octopus-pricing.component';
import { OctopusClientsComponent } from './components/pages/octopus-clients/octopus-clients.component';

import { OctopusContactSalesComponent } from './components/pages/octopus-contact-sales/octopus-contact-sales.component';

import { VerifySuccessComponent } from './verifySuccess/verifySuccess.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PremiumDashboardComponent } from './components/premiumDashboard/premiumDashboard.component';

import { PremiumDashboardService, WebchatGuestService, FirebaseService } from 'src/app/@tji/_dbShare';

import { AuthGuard } from 'src/app/auth/auth.guard';

const routes: Routes = [
    // { path: '', component: HomeComponent },
    { path: '', component: LoginComponent },
    { path: 'home', component: HomeComponent },
    { path: 'benefits', component: OctopusBenefitsComponent },
    { path: 'features', component: OctopusFeaturesComponent },
    // { path: 'pricing', component: OctopusPricingComponent },
    { path: 'clients', component: OctopusClientsComponent },
    { path: 'contact-sales', component: OctopusContactSalesComponent },

    { path: 'faq', component: FaqPageComponent },
    { path: 'how-it-works', component: HowItWorksPageComponent },
    { path: 'coming-soon', component: ComingSoonPageComponent },
    { path: 'app-download', component: AppDownloadPageComponent },
    { path: 'contact', component: ContactPageComponent },
    // Here add new pages component

    {
        path: 'premium-dashboard',
        component: PremiumDashboardComponent,
        resolve: { 
            data: PremiumDashboardService,
            // data1: FirebaseService
        },
    },
    {
        path: 'app-chat',
        loadChildren: () => import('./../appChat/appChat.module').then(a => a.AppChatModule),
        resolve: { 
            data: WebchatGuestService,
            data1: FirebaseService 
        }
    },
    {
        path: 'reseller',
        loadChildren: () => import('./../reseller/reseller.module').then(a => a.ResellerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'app',
        loadChildren: () => import('./../appAccount/appAccount.module').then(a => a.AppAccountModule),
        canActivate: [AuthGuard],
        resolve: { data: FirebaseService },
    },
    { path: 'email-verify-success', component: VerifySuccessComponent },
    // { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },

    { path: '**', redirectTo: '/app/dashboard' } // This line will remain down from the whole pages component list
    // { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
})
export class NewPageRoutingModule { }
