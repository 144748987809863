export class BrandModel {
	_id: string;
	name: string;
	email: string;
	logo: string;
	established_at: string;
	country?: number;
	website: string;
	about: string;
	category: string;
	client_id: number;
	created_by: number;
	updated_by: number;
	created_at: number;
	updated_at: number;
	countryInfo: any;
	orderSettings: any;
	clientSite: any;
	clientSiteInfo: any;
	clientCount: number;
	clientSiteName: any;

	/**
	 * Constructor
	 *
	 * @param brand
	 */
	constructor(brand) {
		this._id = brand._id || '';
		this.name = brand.name || '';
		this.email = brand.email || '';
		this.logo = brand.logo || '';
		this.established_at = brand.established_at || '';
		this.country = brand.country || '';
		this.website = brand.website || '';
		this.about = brand.about || '';
		this.category = brand.category || '';
		this.client_id = brand.client_id || '';
		this.created_by = brand.created_by || '';
		this.updated_by = brand.updated_by || '';
		this.created_at = brand.created_at || '';
		this.updated_at = brand.updated_at || '';
		this.countryInfo = brand.updated_at || '';
		this.orderSettings = brand.orderSettings || '';
		this.clientSiteInfo = brand.clientSiteInfo || '';
		this.clientCount = brand.clientCount || 0;
		this.clientSiteName = brand.clientSiteName || '';
	}
}
