import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Address, AddressService, AlertService, CommonService, Eorders, EordersService, EventService, SettingsService } from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {

  addressList: Address[] = [];
  loader: boolean = true;
  deliveryChargesPriceId: any;
  dialogTitle : string = "Address List"

  constructor(
    private addressService: AddressService,
    private commonService: CommonService,
    private alertService: AlertService,
    private settingsService: SettingsService,
    public matDialogRef: MatDialogRef<AddressListComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.getInit(_data);
    this.addressService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.addressList = data;
      } else {
        this.addressList = null;
      }
    });
  }

  ngOnInit(): void {
    this.doLoader();
  }

  getInit(data) {
    // Set the defaults
    data.type;
    if (data &&data.type && data.type.address && data.type.address) {
      this.deliveryChargesPriceId = data.type.address._id
    }
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 1000);
  }

  getAddrestData(address) {
    if (address.is_delivarable && address._id) {
      this.deliveryChargesPriceId = address._id;
      this.matDialogRef.close(['update', this.deliveryChargesPriceId])
    } else {
      this.alertService.webShow('Danger', 'This address governarate is disabled !!!');
    }
  }

}
