import { AccountUsageReport, TemplateUsageReport } from './clientUsage.interface';

export class ClientUsageModel {
	accountUsageReport: Array<AccountUsageReport>;
	templateUsageReport: Array<TemplateUsageReport>;

	/**
	 * Constructor
	 * @param clientUsage
	 */
	constructor(clientUsage) {
		this.accountUsageReport = clientUsage.accountUsageReport || [];
		this.templateUsageReport = clientUsage.templateUsageReport || [];
	}
}

export class AccountUsageReportModel {
	account_name: string;
	site_id: number;
	site_name: string;
	client_id: number;
	client_site_id: number;
	totalTickets: number;
	totalMessages: number;
	sendMessages: number;
	receivedMessages: number;
	
	agentReports: Array<any>;
	flagReports?: Array<any>;
	itrsCounts: Array<any>;
	abandonCount?: number;
	assignedCount?: number;
	reAssignedCount?: number;
	closedCount?: number;
	openTickets?: number;
	closedTickets?: number;
	rejectedCount?: number;

	/**
	 * Constructor
	 * @param accountUsage
	 */
	constructor(accountUsage) {
		this.account_name = accountUsage.account_name || null;
		this.site_name = accountUsage.site_name || null;
		this.client_id = accountUsage.client_id || null;
		this.client_site_id = accountUsage.client_site_id || null;
		this.site_id = accountUsage.site_id || null;
		this.totalTickets = accountUsage.totalTickets || null;
		this.totalMessages = accountUsage.totalMessages || null;
		this.sendMessages = accountUsage.sendMessages || null;
		this.receivedMessages = accountUsage.receivedMessages || null;

		this.receivedMessages = accountUsage.agentReports || [];
		this.flagReports = accountUsage.flagReports || [];
		this.receivedMessages = accountUsage.itrsCounts || [];
		this.abandonCount = accountUsage.abandonCount || 0;
		this.assignedCount = accountUsage.assignedCount || 0;
		this.reAssignedCount = accountUsage.reAssignedCount || 0;
		this.closedCount = accountUsage.closedCount || 0;
		this.openTickets = accountUsage.openTickets || 0;
		this.closedTickets = accountUsage.closedTickets || 0;
		this.rejectedCount = accountUsage.rejectedCount || 0;
	}
}

export class TemplateUsageReportModel {
	template_name: string;
	total_jobs: number;
	total_count: string;
	success_count: number;
	failed_count: number;
	rejectedCount: number;

	/**
	 * Constructor
	 * @param templateUsage
	 */
	constructor(templateUsage) {
		this.template_name = templateUsage.template_name || null;
		this.total_jobs = templateUsage.total_jobs || 0;
		this.total_count = templateUsage.total_count || 0;
		this.success_count = templateUsage.success_count || 0;
		this.failed_count = templateUsage.failed_count || 0;
		this.rejectedCount = templateUsage.rejectedCount || 0;
	}
}
