import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
  User, UserService,
  AgentActivityService, AgentActivity, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
  selector: 'utils-agent-abandonchart',
  templateUrl: './agent-abandonchart.component.html',
  styleUrls: ['./agent-abandonchart.component.scss']
})
export class AgentAbandonchartComponent implements OnInit, AfterViewInit {

  @ViewChild('abandonChart', { static: true }) abandonChart: ElementRef;

  view: any[] = [];

  authUser: User;
  agentActivityParam: any;
  agentActivities: AgentActivity = null;
  abandonChartData: any = [];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  tooltipDisabled: boolean = false;
  legendPosition: string = 'below';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Duration(Seconds)';

  reportData: Subject<any> = new Subject();

  colorScheme = {
    // domain: ["aqua", "aquamarine", "darkcyan", "cadetblue", "greenyellow"]
    domain: ["#1d68fb", "#33c0fc", "#4afffe"]
  };

  private _unsubscribeAll: Subject<any>;

  constructor(public userService: UserService,
    private router: Router,
    public agentActivityService: AgentActivityService,
    private _formBuilder: UntypedFormBuilder,
    public matDialog: MatDialog) {
    this.agentActivityService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentActivityParam = data);
    this.agentActivityService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.agentActivities = data
      if (data && data.abandon) {
        let abandonData = data.abandon;
        this.abandonChartData = [];
        if (abandonData && abandonData.length > 0) {
          for (let i = 0; abandonData.length > i; i++) {
            let newData = abandonData[i];
            newData.name = abandonData[i].date;
            newData.value = abandonData[i].average_duration;
            this.abandonChartData.push(newData);
          }
        }
      }
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.view = [this.abandonChart.nativeElement.offsetWidth, this.abandonChart.nativeElement.offsetHeight];
  }

  ngOnDestroy() {
    this.agentActivityService.unSubscribe();
  }

  ngAfterViewInit() { }

  getData(date) {
    return this.abandonChartData.filter(x => {
      return (x.date === date) ? true : false;
    })[0];
  }
}