import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryRouteModule } from './gallery-route.module';
import { GalleryExportModule } from './gallery-export.module';
import { DatePipe } from '@angular/common';

import { MediaService } from 'src/app/@tji/_dbShare';
import { TemplateGalleryComponent } from './template-gallery/template-gallery.component';

@NgModule({
    declarations: [],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        GalleryRouteModule,
    ],
    exports: [],
    providers: [
        DatePipe,
        MediaService
    ]
})

export class GalleryModule { }
