import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';

import {
  CatalogProductService,
  User, UserService,
} from 'src/app/@tji/_dbShare';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {


  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  outputs: ['uploaded']
  user: User;
  isEditProduct: boolean = false;
  constructor(public userService: UserService,
    public catalogProductService: CatalogProductService,
    public location: Location,
    private router: Router) {
    this.userService.user.subscribe(data => this.user = data);
    this.catalogProductService.getAllItems();
  }

  ngOnInit(): void {
  }

  toggleRightSidebar() {
    this.rightSideBar.toggle();
    this.catalogProductService.itemSource.next(null);
  }

  isEditableData(data) {
    this.rightSideBar.toggle();
  }
  sync() {
    this.catalogProductService.sync('fbProduct/syncProducts');
    this.catalogProductService.itemSource.next(null);
  }

  deleteProduct() {
		this.catalogProductService.isSelectProductSource.next(true);
	}

}
