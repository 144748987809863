export class InstadmModel {
    id: number;
    instadm_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    sms_message_sid: string;
    account_sid: string;
    text: string;
    from: string;
    to: Date;
    permalink: string;
    user_image: string;
    media_url: string;
    media: string;
    json: string;
    asset_url: string;
    status: string;
    is_active: boolean;
    is_assigned: boolean;
    is_read: boolean;
    is_closed: boolean;
    is_itrs: boolean;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    isSent?: boolean;
    pusherSync?: string;
    agentName?: string;
    createdByName?: string;
    createdByAvatar?: string;
    location_url?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    reply?: any;

    tableName: string;
    className: string;
    ChatData: any;
    people_id: number;
    clientSite: any;
    ReplyData: any;
    replayPersonType: any;

    /**
     * Constructor
     *
     * @param instadm
     */
    constructor(instadm) {
            this.id = instadm.id || '';
            this.instadm_id = instadm.instadm_id || '';
            this.reply_id = instadm.reply_id || '';
            this.client_id = instadm.client_id || '';
            this.client_site_id = instadm.client_site_id || '';
            this.sms_message_sid = instadm.sms_message_sid || '';
            this.account_sid = instadm.account_sid || '';
            this.text = instadm.text || '';
            this.from = instadm.from || '';
            this.to = instadm.to || '';
            this.user_image = instadm.user_image || '';
            this.media = instadm.media || '';
            this.asset_url = instadm.asset_url || '';
            this.json = instadm.json || '';
            this.status = instadm.status || '';
            this.is_active = instadm.is_active || '';
            this.is_assigned = instadm.is_assigned || '';
            this.is_read = instadm.is_read || '';
            this.is_closed = instadm.is_closed || '';
            this.is_itrs = instadm.is_itrs || '';
            this.created_by = instadm.created_by || '';
            this.updated_by = instadm.updated_by || '';
            this.created_at = instadm.created_at || '';
            this.updated_at = instadm.updated_at || '';

            this.tableName = instadm.tableName || '';
            this.ChatData = instadm.ChatData || '';
            this.people_id = instadm.people_id || '';
            this.clientSite = instadm.clientSite || '';
            this.ReplyData = instadm.ReplyData || '';
            this.replayPersonType = instadm.replayPersonType || '';
    }
}
