export class ModalHasLabelModel {
    id: number;
    resource_id: number;
    resource_type: string;
    label_id: number;
    client_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    tableName?: string;

    /**
     * Constructor
     *
     * @param modalHasLabel
     */
    constructor(modalHasLabel) {
            this.id = modalHasLabel.id || '';
            this.resource_id = modalHasLabel.resource_id || '';
            this.resource_type = modalHasLabel.resource_type || '';
            this.label_id = modalHasLabel.label_id || '';
            this.client_id = modalHasLabel.client_id || '';
            this.created_by = modalHasLabel.created_by || '';
            this.updated_by = modalHasLabel.updated_by || '';
            this.created_at = modalHasLabel.created_at || '';
            this.updated_at = modalHasLabel.updated_at || '';
            this.deleted_at = modalHasLabel.deleted_at || '';

            this.tableName = modalHasLabel.tableName || '';
    }
}
