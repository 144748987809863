import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { Router } from '@angular/router';
import {
  User, UserService,
  TemplateUsageReportsService, TemplateUsageReports
} from 'src/app/@tji/_dbShare';

import { Location } from '@angular/common';
import * as moment from 'moment-timezone';

@UntilDestroy()
@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit {

  loader: boolean = true;
  authUser: User;
  templateUsage: TemplateUsageReports;
  templateUsageList: TemplateUsageReports;
  param: any;
  tempNumberSearch: string = "";
  isCloudTemplate: boolean = false;



  constructor(public userService: UserService,
    public templateUsageReportsService: TemplateUsageReportsService,
    public matDialog: MatDialog,
    private orderByPipe: OrderByPipe, public location: Location,
    public router: Router) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.templateUsageReportsService.getItemData()
    this.templateUsageReportsService.item.pipe(untilDestroyed(this)).subscribe(data => {
      var template_id = data.template_id
      if (data.template_id && Number(template_id) < 10000) {
        this.isCloudTemplate = false;
      }else {
        this.isCloudTemplate = true;
      }
      this.templateUsage = data; 
    });
    this.templateUsageReportsService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsageList = data);
    this.templateUsageReportsService.itemTemplate.pipe(untilDestroyed(this)).subscribe(data => this.templateUsageList = data);
  }

  ngOnInit(): void {
    this.doLoader();
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 1000);
  }

  searchClear() {
    this.tempNumberSearch = "";
    this.templateUsage = this.templateUsageList;
  }

  // searchTempNumberData(data: any) {
  //   console.log(data.target.value);

  //   var filterData = [];
	// 	var interactiveDataSearch: any = [];
	// 	var filterInteractiveKeys = ['name']
	// 	interactiveDataSearch = this.intractives;
	// 	if (this.tempNumberSearch === '') {
	// 		filterData = null;
	// 		interactiveDataSearch = this.intractives;
	// 		this.intractivesData = interactiveDataSearch;
	// 	} else {
	// 		if (interactiveDataSearch.length > 0) {
	// 			for (let i = 0; i < interactiveDataSearch.length; i++) {
	// 				if (filterInteractiveKeys.length > 0) {
	// 					filterInteractiveKeys.forEach((key) => {
	// 						if (typeof interactiveDataSearch[i][key] === 'string' && typeof this.tempNumberSearch === 'string') {
	// 							if (interactiveDataSearch[i][key].toLowerCase().indexOf(this.tempNumberSearch.toLowerCase()) > -1) {
	// 								const found = filterData.some(el => el.id === interactiveDataSearch[i].id);
	// 								if (!found) {
	// 									filterData.push(interactiveDataSearch[i]);
	// 								}
	// 							}
	// 						}
	// 					});
	// 				} else {
	// 					if (interactiveDataSearch[i].name.toLowerCase().indexOf(this.tempNumberSearch.toLowerCase()) > -1) {
	// 						filterData.push(interactiveDataSearch[i]);
	// 					}
	// 				}
	// 			}
	// 		}
	// 		this.intractivesData = filterData;
	// 	}

  // }

}
