import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { WabaService } from 'src/app/@tji/_dbShare/waba/waba.service';

import { Address } from './address.interface';
import { AddressModel } from './address-model.model';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');
@Injectable({
  providedIn: 'root'
})
export class AddressService {
  url: string = 'deliverySettings/addressList';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'search': '',
  };

  private librariesSource = new BehaviorSubject<Array<any>>([]);
  libraries = this.librariesSource.asObservable();

  private allItemsSource = new BehaviorSubject<Address[]>([]);
  allItems = this.allItemsSource.asObservable();

  public itemSource = new BehaviorSubject<Address>(new AddressModel({}));
  item = this.itemSource.asObservable();

  public itemIdSource = new BehaviorSubject<Address>(new AddressModel({}));
  itemId = this.itemIdSource.asObservable();

  private displayItemsSource = new BehaviorSubject<Address[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  public paymentGenerateItems = new BehaviorSubject<boolean>(false);
  paymentItems = this.paymentGenerateItems.asObservable();

  public isLoader = new BehaviorSubject<boolean>(true);
  loader = this.isLoader.asObservable();

  public isOrderLoader = new BehaviorSubject<boolean>(false);
  orderLoader = this.isOrderLoader.asObservable();

  public statusAddressInfoData = new BehaviorSubject<boolean>(false);
  statusAddressInfo = this.statusAddressInfoData.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private wabaService: WabaService) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.defaultParams.search = '',
        this.resetParams(),
        this.getAllItems(),
        this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed AddressService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on AddressService');
  }

  concatlibrary(allItems: Address[]) {
    var oldLists: Array<any> = [];
    this.libraries.subscribe(data => {
      oldLists = data;
    });
    if (oldLists && oldLists.length > 0 && allItems && allItems.length > 0) {
      oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
    } else {
      oldLists = allItems;
    }
    this.changeLibraries(oldLists);
  }

  removelibrary(item: Address) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      oldLists = oldLists.filter(x => {
        return x._id !== item._id;
      });
    }
    this.changeLibraries(oldLists);
  }

  removelibraryById(id: number) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && id) {
      oldLists = oldLists.filter(x => {
        return x.id !== id;
      });
    }
    this.changeLibraries(oldLists);
  }

  replacelibrary(item: Address) {
    let oldLists = [];
    let isReplaced: boolean = false;
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      for (var i = 0; i < oldLists.length; ++i) {
        if (oldLists[i]._id === item._id) {
          oldLists.splice(i, 1, item);
          isReplaced = true;
          break;
        }
      }
    }
    if (!isReplaced) { oldLists.concat([item]); }
    this.changeLibraries(oldLists);
  }

  changeLibraries(libraries: Address[]) {
    this.librariesSource.next(libraries);
  }

  changeAllItems(allItems: Address[]) {
    this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }

  changeItem(item: Address) {
    this.itemSource.next(item);
  }

  changeItemId(item: Address) {
    this.itemIdSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: Address[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: Address) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
      }
    }
    this.changeAllItems(allItems);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'search': '',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.isLoader.next(false);
          if (data.data && data.data.length > 0) {
            var address = data.data;
            if (data.additional && data.additional.GovernaratesInfo) {
              var governaratesInfo = data.additional.GovernaratesInfo;
            }
            if (data.additional && data.additional.AvailableGovernarates) {
              var governarates = data.additional.AvailableGovernarates;
            }
            if (data.additional && data.additional.brandInfo) {
              var brandInfo = data.additional.brandInfo;
            }
            if (data.additional && data.additional.regions) {
              var regionInfo = data.additional.regions;
            }


            if (governaratesInfo && governaratesInfo.length > 0 && governarates && governarates.length > 0) {

              governarates.forEach(governarates => {
                governaratesInfo.forEach(governaratesInfo => {
                  if (governaratesInfo.governorate_id == governarates._id) {
                    governaratesInfo.governarateName = governarates.name_en;
                  }
                });
              });

              address.forEach(element => {
                governaratesInfo.forEach(governarates => {
                  if (element.governarate_id == governarates.governorate_id) {
                    element["governarateAmount"] = governarates.price;
                    element["governarateName"] = governarates.governarateName;
                    element["currency"] = governarates.currency;
                    element["is_delivarable"] = governarates.is_delivarable;
                  }
                });
              });

            }


            if (regionInfo && regionInfo.length > 0) {
              address.forEach(element => {
                regionInfo.forEach(regions => {
                  if (element.region_id && element.region_id == regions.id) {
                    element['regionsAmount'] = regions.price;
                    element['regionsName'] = regions.name_en;
                    element['currency'] = regions.currency;
                    element['is_delivarable'] = regions.is_delivarable;
                  }
                });

                if (brandInfo && brandInfo.length > 0) {
                  brandInfo.forEach(brand => {
                    brand.clientSite.forEach(brandClient => {
                      if (brandClient.client_site_id == element.client_site_id) {
                        element['brand'] = brand?.name;
                        element['tax'] = brand?.countryInfo?.taxinfo;
                      }
                    });
                  });
                }

              });
            }



            this.changeAllItems(address);
            this.changeTotalItem(data.pagnitation.totalResult);
          } else {
            this.changeAllItems(null);
            this.changeTotalItem(null);
          }
        } else {
          this.isLoader.next(false);
        }
      },
        error => { console.log('Error ::' + error); this.isLoader.next(false); }
      );
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        if (data.data.last_page <= data.data.current_page) {
          params.page = data.data.last_page;
          this.changeParams(params);
        }
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data.data);
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: Address) {
    let lists = [];
    let isChanged: boolean = false;
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result
      if (!lists) {
        lists = [];
      }
      if (lists && lists.length > 0) {
        var foundIndex = lists.findIndex(x => x?._id == item._id);
        if (foundIndex >= 0) {
          lists[foundIndex] = item;
          isChanged = true;
        } else {
          lists = lists.concat([item]);
          isChanged = true;
        };
      } else {
        lists[0] = item;
        isChanged = true;
      }
    });
    if (isChanged) {
      this.changeAllItems(lists);
      isChanged = false;
    }

  }


  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }


  getItem(params: any = null) {
    this.routeParams = (params) ? params : this.routeParams;
    var itemId;
    this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
    var id = {
      "id": itemId
    }
    if (itemId > 0) {

      this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          this.changeAllItemsByItem(data.data);
          this.changeItem(data.data);
        },
          error => console.log('Error ::' + error)
        );
    }
    else {
      this.changeItem(new AddressModel({}));
    }
  }

  store(url, data: any) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          if (res.data && res.data._id) {
            var address = res.data;
            // var governaratesInfo = res.additional.GovernaratesInfo;
            // var governarates = res.additional.AvailableGovernarates;
            // if (governaratesInfo && governaratesInfo.length > 0 && governarates && governarates.length > 0) {

            //   governarates.forEach(governarates => {
            //     governaratesInfo.forEach(governaratesInfo => {
            //       if (governaratesInfo.governorate_id == governarates._id) {
            //         governaratesInfo.governarateName = governarates.name_en;
            //       }
            //     });
            //   });

            //   governaratesInfo.forEach(governarates => {
            //     if (address.governarate_id == governarates.governorate_id) {
            //       address["governarateAmount"] = governarates.price;
            //       address["governarateName"] = governarates.governarateName;
            //       address["currency"] = governarates.currency;
            //       address["is_delivarable"] = governarates.is_delivarable;
            //     }
            //   });
            // }
            if (address && address.regions) {
              address["regionsAmount"] = address.regions.price;
              address["regionsName"] = address.regions.name_en;
              address["currency"] = address.regions.currency;
              address["is_delivarable"] = address.regions.is_delivarable;
            }
            if (address && address.brandInfo && address.brandInfo.countryInfo && address.brandInfo.countryInfo.tax) {
              address["tax"] = address.brandInfo.countryInfo.tax;
            }

            this.concatItem(address);
            this.changeItem(address);
            this.alert('Success', 'Created Successfully !!!');
            this.statusAddressInfoData.next(true);
          }
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  update(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          if (res.data && res.data._id) {
            var updateData = res.data;
            // var governaratesInfo = res.additional.GovernaratesInfo;
            // var governarates = res.additional.AvailableGovernarates;
            // if (governaratesInfo && governaratesInfo.length > 0 && governarates && governarates.length > 0) {

            //   governarates.forEach(governarates => {
            //     governaratesInfo.forEach(governaratesInfo => {
            //       if (governaratesInfo.governorate_id == governarates._id) {
            //         governaratesInfo.governarateName = governarates.name_en;
            //       }
            //     });
            //   });

            //   governaratesInfo.forEach(governarates => {
            //     if (updateData.governarate_id == governarates.governorate_id) {
            //       updateData["governarateAmount"] = governarates.price;
            //       updateData["governarateName"] = governarates.governarateName;
            //       updateData["currency"] = governarates.currency;
            //       updateData["is_delivarable"] = governarates.is_delivarable;
            //     }
            //   });

            // }

            if (updateData && updateData.regions) {
              updateData["regionsAmount"] = updateData.regions.price;
              updateData["regionsName"] = updateData.regions.name_en;
              updateData["currency"] = updateData.regions.currency;
              updateData["is_delivarable"] = updateData.regions.is_delivarable;
            }
            if (updateData && updateData.brandInfo && updateData.brandInfo.countryInfo && updateData.brandInfo.countryInfo.tax) {
              updateData["tax"] = updateData.brandInfo.countryInfo.tax;
            }

            this.changeAllItemsByItem(updateData);
            this.alert('Success', 'Created Successfully !!!');
            this.statusAddressInfoData.next(true);
          }
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(url, id) {
    this.commonService.storeItem(url, id, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.spliceItem(id.id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }


  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.page += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }
  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  // updateCatalog(catalog: any) {
  // 	let allItems = [];
  // 	var isChanged = false;
  // 	this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
  // 	if (allItems.length > 0) {
  // 		allItems.forEach(element => {
  // 			if (element.id == catalog.id) {
  // 				element = catalog;
  // 				isChanged = true;
  // 			}
  // 		});
  // 		if (!isChanged) {
  // 			allItems.push(catalog);
  // 			this.changeAllItems(allItems);
  // 		} else {
  // 			this.changeAllItems(allItems);
  // 		}
  // 	}
  // }

}
