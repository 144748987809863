import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ClientService, Client, ClientModel, CustomvalidationService
} from 'src/app/@tji/_dbShare';
import { kMaxLength } from 'buffer';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'client-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	client: Client;
	action: string;
	clientForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public clientService: ClientService,
		private customValidator: CustomvalidationService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.client = data;
			this.clientForm = this.createForm();
		});

	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Client';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Client';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.client.name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
			email: [this.client.userInfo.email || null, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
			password: [null, [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
			mobile: [(this.client.details) ? this.client.details.mobile : null || null, [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
			address: [(this.client.details) ? this.client.details.address : null || null, [Validators.minLength(1)]],
		});
	}

}
