import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface Button {
    title: string;
    icon?: string;
    color?: string;
}

@Component({
  selector: 'app-norecord',
  templateUrl: './norecord.component.html',
  styleUrls: ['./norecord.component.scss']
})

export class NoRecordComponent implements OnInit {

	@Input() icon: string = 'error_outline';
	@Input() title: string = 'No Records';
  @Input() subTitle: string = 'No Records Founded, Please try after sometimes...';

  @Input() addButton: Button = null;
  @Output() addNew = new EventEmitter();

  @Input() backButton: Button = null;
	@Output() back = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
