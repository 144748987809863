import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ClientService, Client, ClientModel, CustomvalidationService
} from 'src/app/@tji/_dbShare';
import { kMaxLength } from 'buffer';

export interface InputPattern { title: string; visible: boolean; required: boolean; }
import { DatePipe } from '@angular/common';


@UntilDestroy()
@Component({
	selector: 'client-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	client: Client;
	action: string;
	clientForm: UntypedFormGroup;
	minDate: any;
	maxDate: any;

	constructor(public userService: UserService,
		public clientService: ClientService,
		private customValidator: CustomvalidationService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private datePipe: DatePipe,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.client = data;
			this.clientForm = this.createForm();
		});
		this.minDate = new Date();
		var today = new Date();
		this.maxDate = new Date();
		this.maxDate.setDate(today.getDate()+365);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Client';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Client';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		var date = new Date();
		return this._formBuilder.group({
			name: [this.client.name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
			email: [this.client.userInfo.email || null, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
			password: [null, [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
			mobile: [(this.client.details) ? this.client.details.mobile : null || null, [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
			// expire_at: new FormControl(moment(this.client.expire_at?this.client.expire_at:date).format('DD/MM/YYYY'),[<any>Validators.required]) //[new Date(this.client.expire_at), [Validators.required]] //[this.client.expire_at ? this.client.expire_at : new Date() || new Date(), [Validators.required]],
			expire_at: new FormControl(new Date(this.client.expire_at?this.client.expire_at:date),[<any>Validators.required]),
			address: [(this.client.details) ? this.client.details.address : null || null, [Validators.minLength(1)]],
		});
	}

}
