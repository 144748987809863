import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ResellerService, Reseller, ResellerModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'reseller-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	reseller: Reseller;
	action: string;
	resellerForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public resellerService: ResellerService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.resellerService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.reseller = data;
			this.resellerForm = this.createForm();
		});

	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Reseller';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Reseller';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.reseller.name || null, [Validators.required, Validators.minLength(3)]],
			email: [this.reseller.userEmail || null, [Validators.required, Validators.minLength(1)]],
			password: [null, [Validators.required, Validators.minLength(1)]],
			mobile: [(this.reseller.detail) ? this.reseller.detail.mobile : null || null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
			address: [(this.reseller.detail) ? this.reseller.detail.address : null || null, [Validators.minLength(1)]],
		});
	}

}
