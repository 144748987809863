import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { WabaService } from 'src/app/@tji/_dbShare/waba/waba.service';

import { DeliverySettings } from './deliverySettings.interface';
import { DeliverySettingsModel } from './deliverySettings-model.model';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');
@Injectable({
  providedIn: 'root'
})
export class DeliverySettingsService {
  url: string = 'master/allsublist';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'search': '',
  };

  private librariesSource = new BehaviorSubject<Array<any>>([]);
  libraries = this.librariesSource.asObservable();

  private allItemsSource = new BehaviorSubject<DeliverySettings[]>([]);
  allItems = this.allItemsSource.asObservable();

  public itemSource = new BehaviorSubject<DeliverySettings>(new DeliverySettingsModel({}));
  item = this.itemSource.asObservable();

  public itemIdSource = new BehaviorSubject<DeliverySettings>(new DeliverySettingsModel({}));
  itemId = this.itemIdSource.asObservable();

  private displayItemsSource = new BehaviorSubject<DeliverySettings[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  public isAddItems = new BehaviorSubject<boolean>(false);
  addItems = this.isAddItems.asObservable();

  public isLoader = new BehaviorSubject<boolean>(true);
  loader = this.isLoader.asObservable();

  private governorateSource = new BehaviorSubject<any>([]);
  governorate = this.governorateSource.asObservable();

  private allPaymentMethodsSource = new BehaviorSubject<any>([]);
  allPaymentMethods = this.allPaymentMethodsSource.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private wabaService: WabaService) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.defaultParams.search = '',
        this.resetParams(),
        this.getAllItems(),
        this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed DeliverySettingsService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on DeliverySettingsService');
  }

  concatlibrary(allItems: DeliverySettings[]) {
    var oldLists: Array<any> = [];
    this.libraries.subscribe(data => {
      oldLists = data;
    });
    if (oldLists && oldLists.length > 0) {
      oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
    } else {
      oldLists = allItems;
    }
    this.changeLibraries(oldLists);
  }

  removelibrary(item: DeliverySettings) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      oldLists = oldLists.filter(x => {
        return x._id !== item._id;
      });
    }
    this.changeLibraries(oldLists);
  }

  removelibraryById(id: number) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && id) {
      oldLists = oldLists.filter(x => {
        return x.id !== id;
      });
    }
    this.changeLibraries(oldLists);
  }

  replacelibrary(item: DeliverySettings) {
    let oldLists = [];
    let isReplaced: boolean = false;
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      for (var i = 0; i < oldLists.length; ++i) {
        if (oldLists[i]._id === item._id) {
          oldLists.splice(i, 1, item);
          isReplaced = true;
          break;
        }
      }
    }
    if (!isReplaced) { oldLists.concat([item]); }
    this.changeLibraries(oldLists);
  }

  changeLibraries(libraries: DeliverySettings[]) {
    this.librariesSource.next(libraries);
  }

  changeAllItems(allItems: DeliverySettings[]) {
    this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }

  changeItem(item: DeliverySettings) {
    this.itemSource.next(item);
  }

  changeItemId(item: DeliverySettings) {
    this.itemIdSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: DeliverySettings[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: DeliverySettings) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
      }
    }
    this.changeAllItems(allItems);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'search': '',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.isLoader.next(false);
          // var brandInfo = data.additional.brandInfo;
          // var regions = data.additional.regions;

          // if (regions && regions.length > 0) {
          //   data.data.forEach(element => {
          //     regions.forEach(region => {
          //       if (element.region_id == region.id) {
          //         element.regionName = region.name_en;
          //         element.is_delivarable = region.is_delivarable;
          //         if (brandInfo && brandInfo.length > 0) {
          //           brandInfo.forEach(brand => {
          //             if (region.country_id == brand.country) {
          //               element.country = brand.countryInfo.name_en;
          //               element.brand = brand.name;
          //             }
          //           });
          //         }
          //       }
          //     });
          //   });
          // }

          // var deliverySetting = data.additional.AllGovernarates;

          // if (deliverySetting && deliverySetting.length > 0) {
          //   data.data.deliveryInfo.forEach(element => {
          //     deliverySetting.forEach(delivery => {
          //       if (element.governorate_id == delivery._id) {
          //         element.deliveryId = delivery._id;
          //         element.name_en = delivery.name_en;
          //         element.name_er = delivery.name_er;
          //       }
          //     });
          //   });
          // }
          if (data.additional && data.additional.AllPaymentMethods) {
            var allPaymentMethods = data.additional.AllPaymentMethods;
            var paymentMethodsList = [];

            if (allPaymentMethods && allPaymentMethods.length > 0) {
              allPaymentMethods.forEach(allPaymentMethods => {
                if (allPaymentMethods.id == '1') {
                  allPaymentMethods['isPaymentMethod'] = true;
                }else{
                  allPaymentMethods['isPaymentMethod'] = true;
                }
              });
            }
            this.allPaymentMethodsSource.next(allPaymentMethods);
          }

          this.changeAllItems(data.data);
          // this.changeTotalItem(data.pagnitation.totalResult);
        } else {
          this.isLoader.next(false);
        }
      },
        error => { console.log('Error ::' + error); this.isLoader.next(false); }
      );
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        if (data.data.last_page <= data.data.current_page) {
          params.page = data.data.last_page;
          this.changeParams(params);
        }
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data.data);
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: DeliverySettings) {
    let lists = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result.concat([item]);
    });
    this.changeAllItems(lists);
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i].id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  getItem(params: any = null) {
    this.routeParams = (params) ? params : this.routeParams;
    var itemId;
    this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
    var id = {
      "id": itemId
    }
    if (itemId > 0) {

      this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          this.changeAllItemsByItem(data.data);
          this.changeItem(data.data);
        },
          error => console.log('Error ::' + error)
        );
    }
    else {
      this.changeItem(new DeliverySettingsModel({}));
    }
  }

  store(url, data: any) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.concatItem(res.data);
          this.changeItem(res.data);
          this.alert('Success', 'Created Successfully !!!');
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }


  update(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.updateDelivery(res.data);
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(url, id) {
    this.commonService.storeItem(url, id, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.spliceItem(id.DeliverySettings_id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.page += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }
  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  updateDelivery(delivery: any) {
    let allItems = [];
    var isChanged = false;
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems.length > 0) {
      allItems.forEach(element => {
        if (element._id == delivery.governorate_id) {
          element.client_id = delivery.client_id;
          element.created_by = delivery.created_by;
          element.currency = delivery.currency;
          element.governorate_id = delivery.governorate_id;
          element.is_delivarable = delivery.is_delivarable;
          element.price = delivery.price;
          element.updated_at = delivery.updated_at;
          element.deliveryId = delivery._id;
        }
      });
      this.changeAllItems(allItems);
    }
  }

}
