export class WebhookModel {
    id: number;
    site_url: string;
    client_site_id: number;
    client_id: number
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName: string;
    appUrl?: string;

    /**
     * Constructor
     *
     * @param webhook
     */
    constructor(webhook) {
            this.id = webhook.id || '';
            this.site_url = webhook.site_url || '';
            this.client_site_id = webhook.client_site_id || '';
            this.client_id = webhook.client_id || '';
            this.created_by = webhook.created_by || '';
            this.updated_by = webhook.updated_by || '';
            this.created_at = webhook.created_at || '';
            this.updated_at = webhook.updated_at || '';

            this.tableName = webhook.tableName || '';
    }
}
