export class LeadsHistoryModel {
	people: any;
	tickets: Array<any>;

	/**
	 * Constructor
	 * @param leadsHistory
	 */
	constructor(leadsHistory) {
		this.people = leadsHistory.people || null;
		this.tickets = leadsHistory.tickets || [];
	}
}
