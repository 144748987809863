import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { ItemComponent } from './item/item.component';
import { LeadsViewComponent } from './leadsView/leads-view.component';
import { ReserveLeadComponent } from './reserve-lead/reserve-lead.component';

import { PeopleService, LeadsHistoryService, ReservePeopleService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: PeopleService },
	},
	{
		path: 'reserve-lead',
		component: ReserveLeadComponent,
		resolve: { data: ReservePeopleService },
	},
	{
		path: 'leads-view/:clientId/:clientSiteId/:peopleId',
		component: LeadsViewComponent,
		resolve: { data: LeadsHistoryService },
	},
	{
		path: ':id',
		component: ItemComponent,
		resolve: { data: PeopleService },
	},
	{ path: '**', redirectTo: '/app/people' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class PeopleRouteModule { }
