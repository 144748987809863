import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
	User, UserService,
    PosterSchedule, PosterScheduleService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
	selector: 'smm-poster-item',
	templateUrl: './posterItem.component.html',
	styleUrls: ['./posterItem.component.scss']
})

export class PosterItemComponent implements OnInit, OnDestroy {
	@Input() posterSchedule: PosterSchedule;
    subLoader: boolean = false;

    user: User;
    posterItemCarouselOptions: OwlOptions = {
        loop: false,
        autoWidth: true,
        mouseDrag: true,
        autoplayHoverPause: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        navSpeed: 500,
        items: 1,
        nav: false
    }

	constructor(public userService: UserService,
        private posterScheduleService: PosterScheduleService,
		private dialog: MatDialog,
		private router: Router,
		public location: Location,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

    setLoader() {
        setTimeout(() => {
            this.subLoader = false;
        }, 3000);
    }

    getAsset(posterSchedule: PosterSchedule): string {
        let output: string = '';
    	if(posterSchedule && posterSchedule.poster && posterSchedule.poster.allAssets && posterSchedule.poster.allAssets.length > 0) {
            for (let i = 0; i <= posterSchedule.poster.allAssets.length; i++) {
                let item = posterSchedule.poster.allAssets[i];
            	if(item && item.type === 'image') {
                    output = item.url;
            	}
            }
    	}
        return output;
    }

    newPoster(): void {
        this.router.navigateByUrl('/app/smm/new-poster');
    }

    publishNow(posterSchedule) {
        this.posterScheduleService.publishNow('posterschedule/publish-now', { 'id': posterSchedule.id });
        this.setLoader();
    }
}
