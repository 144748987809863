export class WhatsappListModel {
    id: number;
    whatsapp_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    sms_message_sid: string;
    account_sid: string;
    text: string;
    from: string;
    to: Date;
    user_image: string;
    media: string;
    asset_url: string;
    json: string;
    status: string;
    is_active: boolean;
    is_assigned: boolean;
    is_read: boolean;
    is_closed: boolean;
    is_itrs: boolean;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    isSent?: boolean;
    pusherSync?: string;
    agentName?: string;
    createdByName?: string;
    createdByAvatar?: string;
    location_url?: string;
    interactiveData?: any;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    reply?: any;

    tableName: string;
    className: string;
    templateData?: any;
    replayPersonType: any;

    /**
     * Constructor
     *
     * @param whatsapp
     */
    constructor(whatsapp) {
            this.id = whatsapp.id || '';
            this.whatsapp_id = whatsapp.whatsapp_id || '';
            this.reply_id = whatsapp.reply_id || '';
            this.client_id = whatsapp.client_id || '';
            this.client_site_id = whatsapp.client_site_id || '';
            this.sms_message_sid = whatsapp.sms_message_sid || '';
            this.account_sid = whatsapp.account_sid || '';
            this.text = whatsapp.text || '';
            this.from = whatsapp.from || '';
            this.to = whatsapp.to || '';
            this.user_image = whatsapp.user_image || '';
            this.media = whatsapp.media || '';
            this.json = whatsapp.json || '';
            this.status = whatsapp.status || '';
            this.is_active = whatsapp.is_active || '';
            this.is_assigned = whatsapp.is_assigned || '';
            this.is_read = whatsapp.is_read || '';
            this.is_closed = whatsapp.is_closed || '';
            this.is_itrs = whatsapp.is_itrs || '';
            this.created_by = whatsapp.created_by || '';
            this.updated_by = whatsapp.updated_by || '';
            this.created_at = whatsapp.created_at || '';
            this.updated_at = whatsapp.updated_at || '';

            this.tableName = whatsapp.tableName || '';
            this.replayPersonType = whatsapp.replayPersonType || '';
    }
}
