import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from 'src/app/@tji/_dbShare';

@Component({
	selector: 'register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

	showregForm: boolean = true;
	showResponse: boolean = false;
	registerForm: UntypedFormGroup;

    apiError: any;

  	constructor(private _formBuilder: UntypedFormBuilder,
        public userService: UserService,
        private router: Router) {
  	}

	ngOnInit() {
		this.registerFormInit();
	}

	registerFormInit() {
		this.registerForm = this._formBuilder.group({
			first_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
			last_name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
			email: ['', [Validators.email, Validators.required]],
			mobile: ['', [Validators.required, Validators.pattern('^[6-9][0-9]{9}$'), Validators.minLength(10), Validators.maxLength(10)]],
			line_1: ['', [Validators.required]],
			line_2: ['', [Validators.required]],
			is_primary: ['1', [Validators.required]]
		});
	}

    get first_name() { return this.registerForm.get('first_name'); }
    get last_name() { return this.registerForm.get('last_name'); }
    get email() { return this.registerForm.get('email'); }
    get mobile() { return this.registerForm.get('mobile'); }
    get line_1() { return this.registerForm.get('line_1'); }
    get line_2() { return this.registerForm.get('line_2'); }


    register(registerData) {
    	this.showregForm = false;
        this.userService.register('register', registerData).subscribe(data => {
            this.showResponse = true;
        }, error => {
        	this.showregForm = false;
            this.apiError = error.error.error;
        });
    }

}
