import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Tweetdm } from './tweetdm.interface';
import { TweetdmModel } from './tweetdm_model.model';
import { LoaderService } from 'src/app/@tji/_dbShare/general/loader.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class TweetdmService implements Resolve<any> {
	url: string = 'tweetdm';
	routeParams: any;
	defaultParams: any = {
		"limit": 25,
		"current": 1,
		"sort": "created_at|DESC",
		"is_history": 1
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Tweetdm[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<Tweetdm>(new TweetdmModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Tweetdm[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private loaderService: LoaderService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TweetdmService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TweetdmService');
	}

	concatlibrary(allItems: Tweetdm[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Tweetdm) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Tweetdm) {
		if (item != null && item != undefined) {
			let oldLists = [];
			let isReplaced: boolean = false;
			this.libraries.subscribe(data => oldLists = data);
			if (oldLists && oldLists.length > 0 && item && item.id) {
				for (var i = 0; i < oldLists.length; ++i) {
					if (oldLists[i].id === item.id) {
						oldLists.splice(i, 1, item);
						isReplaced = true;
						break;
					}
				}
			}
			if (!isReplaced) { oldLists.push(item); }
			this.changeLibraries(oldLists);
		}
	}

	changeLibraries(libraries: Tweetdm[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Tweetdm[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: Tweetdm) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Tweetdm[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Tweetdm) {
		let allItems = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) {
					allItems.splice(i, 1, item);
					isChanged = true;
				}
			}
		}
		this.changeAllItems(allItems);
		if (!isChanged) { this.concatItem(item); }
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			"limit": 25,
			"current": 1,
			"sort": "created_at|DESC",
			"is_history": 1
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('tickets/chatView', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (ticketData && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var jsonData = JSON.parse(ticketList.json);
							var dataFormats = {
								id: ticketList.id,
								reply_id: ticketList.reply_id,
								status: ticketList.status,
								sms_message_sid: ticketList.sms_message_sid,
								from: ticketList.from,
								to: ticketList.to,
								media: ticketList.media,
								asset_url: ticketList.asset_url ? ticketList.asset_url : null,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								updated_at: ticketList.updated_at,
								people_id: ticketList.people_id,
								parent_id: jsonData.parent_id ? ticketList.id : 'null',
								replyMessageId: ticketList.reply_id,
								createdByName: ticketList?.CreatedByInfo?.MoreInfo?.name,
								replayPersonType: ticketList?.CreatedByInfo?.person_type,
								is_itrs: ticketList.is_itrs ? true : false,
								location_url: ticketList.locationurl
							}
							if (ticketList.asset_url && ticketList.asset_url.length > 0) {
								var baseUrl = ticketList.asset_url;
								// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
								// var decodeName = baseUrl.split("/").pop()
								// let encoded = decodeURIComponent(decodeName);
								dataFormats["asset_url"] = baseUrl;
							}
							if (ticketList && ticketList.reply_id) {
								dataFormats['text'] = ticketList.text;
								additionalData.ReplyInfo.forEach(replyInfo => {
									if (replyInfo.id === ticketList.reply_id) {
										var jsonData = JSON.parse(replyInfo.json);

										if (jsonData && jsonData.interactive) {
											var interactiveData = {
												"to": "",
												"type": "interactive",
												"headerType": null,
												"headerText": null,
												"image": null,
												"video": null,
												"document": null,
												"bodyType": null,
												"footerType": null,
												"footerText": null,
												"buttons": [],
												"lists": []
											};
											var intreactiveStr = [];
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
												jsonData.interactive.action.buttons.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
													if (interactive.reply.id == dataFormats['text']) {
														dataFormats['text'] = interactive.reply.title;
													}
												});
												interactiveData.buttons = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
												jsonData.interactive.action.lists.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
												});
												interactiveData.lists = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
												jsonData.interactive.action.sections[0].rows.forEach(interactive => {
													var data = {
														text: interactive.title,
														index: interactive.id
													}
													intreactiveStr.push(data);
													if (interactive.id == dataFormats['text']) {
														dataFormats['text'] = interactive.title;
													}
												});
												interactiveData.lists = intreactiveStr
											}
											interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
											dataFormats['reply']['interactiveData'] = interactiveData;
										} else if (replyInfo.media == 'text' || ticketList.media == 'text') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'video' || ticketList.media == 'video') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'image' || ticketList.media == 'image') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'audio' || ticketList.media == 'audio') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'document' || ticketList.media == 'document') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'voice' || ticketList.media == 'voice') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (replyInfo.media == 'location') {
											dataFormats['reply'] = replyInfo;
											dataFormats['reply']['location_url'] = replyInfo.locationurl;

											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (jsonData && jsonData.order) {
											dataFormats['reply']['orderData'] = jsonData.order;
										}


									}
								});
							} else if (ticketList && !ticketList.reply_id) {
								if (ticketList.media == 'contacts') {
									dataFormats['text'] = JSON.parse(ticketList.text);
								} else {
									dataFormats['text'] = ticketList.text;
								}

								if (jsonData && jsonData.interactive) {
									var interactiveData = {
										"to": "",
										"type": "interactive",
										"headerType": null,
										"headerText": null,
										"image": null,
										"video": null,
										"document": null,
										"bodyType": null,
										"footerType": null,
										"footerText": null,
										"buttons": [],
										"lists": []
									};
									var intreactiveStr = [];
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
										jsonData.interactive.action.buttons.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.buttons = intreactiveStr
									}
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
										jsonData.interactive.action.lists.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.lists = intreactiveStr
									}
									
									if (jsonData && jsonData.order) {
										dataFormats['orderData'] = jsonData.order;
									}
									interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
									dataFormats['interactiveData'] = interactiveData;
								}

								if (jsonData && jsonData.template && jsonData.template.json) {
									var templateData: any;
									var type = typeof jsonData.template.json
									if (type == "string") {
										templateData = JSON.parse(jsonData.template.json)
									} else {
										templateData = jsonData.template.json;
									}

									var chatDatas = {};
									var buttonList = [];
									if (jsonData.template.selected) {
										chatDatas['selected'] = jsonData.template.selected
										templateData.templateComponent['templateName'] = templateData.template.name;
										chatDatas['templateComponent'] = templateData.templateComponent;
										if (templateData.templateComponent.buttons && templateData.templateComponent.buttons.length > 0) {
											templateData.templateComponent.buttons.forEach(temp => {

												if (temp.value == jsonData.template.selected) {
													temp['isActive'] = true;
												}
												if (temp.type == 'button') {
													buttonList.push(temp)
												}
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										} else {
											dataFormats['text'] = ticketList.text;
										}
									}
									if (!jsonData.template.selected) {
										var isCarousel = false;
										chatDatas['selected'] = jsonData.human.button_text;
										if (!templateData.templateComponent) {
											templateData["templateComponent"] = jsonData.template.json;
										}
										jsonData.template.json.forEach(carousel => {
											if (carousel.type == 'CAROUSEL') {
												isCarousel = true;
											}
										});
										if (isCarousel) {
											templateData.templateComponent['templateName'] = jsonData.template.name + ' - carousel Template';
											templateData.templateComponent['isCarousel'] = true;
										} else {
											templateData.templateComponent['templateName'] = jsonData.template.name;
										}
										chatDatas['templateComponent'] = templateData;
										const buttonData = templateData.filter((template) => template.type == 'BUTTONS');
										if (buttonData && buttonData.length > 0 && !isCarousel) {
											buttonData[0].buttons.forEach(temp => {
												var dataButton = {
													value: temp.text,
													type: temp.type,
												}
												if (temp.text == jsonData.human.button_text) {
													dataButton['isActive'] = true;
												}
												buttonList.push(dataButton)
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										}
										if(isCarousel) {
											var components = {
												// value: '',
												// type: 'button',
											}
											buttonList.push(components);
											chatDatas['components'] = buttonList;
											chatDatas['text'] = ticketList.text;
											dataFormats['text'] = chatDatas;
										}
									}

								} else {
									dataFormats['text'] = ticketList.text;
								}

							}
							if (additionalData.TicketInfo && additionalData.TicketInfo.length > 0 && ticketList && ticketList.people_id) {
								additionalData.TicketInfo.forEach(ticketId => {
									if (ticketId.people_id == ticketList.people_id) {
										dataFormats['ticketId'] = ticketId.id;
									}
								});
							}
							if (ticketList && ticketList.tweetdm_id) {
								dataFormats['tweetdm_id'] = ticketList.tweetdm_id;
							} else {
								dataFormats['tweetdm_id'] = ticketList.id;
							}
							if (additionalData && additionalData.clientSiteInfo) {
								dataFormats['siteName'] = additionalData.clientSiteInfo.details?.name;
								dataFormats['clientSiteTextColor'] = additionalData.clientSiteInfo?.color;
								dataFormats['clientSiteColor'] = additionalData.clientSiteInfo.details?.color;
							}
							if (additionalData && additionalData.peopleData.length > 0) {
								dataFormats['peopleName'] = additionalData.peopleData[0]?.name;
								dataFormats['peopleAvatar'] = additionalData.peopleData[0]?.peopleAvatar;
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllLiveItems(params: any = null) {
		params = this.paramsInit(params);
		params.page = 1;
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data.filter(x => result.every(y => y.id !== x.id)));
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('tickets/chatView', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
						params.current = data.pagnitation.pageCount;
						this.changeParams(params);
					}
					if (ticketData && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var jsonData = JSON.parse(ticketList.json);
							var dataFormats = {
								id: ticketList.id,
								reply_id: ticketList.reply_id,
								status: ticketList.status,
								sms_message_sid: ticketList.sms_message_sid,
								from: ticketList.from,
								to: ticketList.to,
								media: ticketList.media,
								asset_url: ticketList.asset_url ? ticketList.asset_url : null,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								updated_at: ticketList.updated_at,
								people_id: ticketList.people_id,
								parent_id: jsonData.parent_id ? ticketList.id : 'null',
								replyMessageId: ticketList.reply_id,
								createdByName: ticketList?.CreatedByInfo?.MoreInfo?.name,
								replayPersonType: ticketList?.CreatedByInfo?.person_type,
								is_itrs: ticketList.is_itrs ? true : false,
								location_url: ticketList.locationurl
							}
							if (ticketList.asset_url && ticketList.asset_url.length > 0) {
								var baseUrl = ticketList.asset_url;
								// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
								// var decodeName = baseUrl.split("/").pop()
								// let encoded = decodeURIComponent(decodeName);
								dataFormats["asset_url"] = baseUrl;
							}
							if (ticketList && ticketList.reply_id) {
								dataFormats['text'] = ticketList.text;
								additionalData.ReplyInfo.forEach(replyInfo => {
									if (replyInfo.id === ticketList.reply_id) {
										var jsonData = JSON.parse(replyInfo.json);

										if (jsonData && jsonData.interactive) {
											var interactiveData = {
												"to": "",
												"type": "interactive",
												"headerType": null,
												"headerText": null,
												"image": null,
												"video": null,
												"document": null,
												"bodyType": null,
												"footerType": null,
												"footerText": null,
												"buttons": [],
												"lists": []
											};
											var intreactiveStr = [];
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
												jsonData.interactive.action.buttons.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
													if (interactive.reply.id == dataFormats['text']) {
														dataFormats['text'] = interactive.reply.title;
													}
												});
												interactiveData.buttons = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
												jsonData.interactive.action.lists.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
												});
												interactiveData.lists = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
												jsonData.interactive.action.sections[0].rows.forEach(interactive => {
													var data = {
														text: interactive.title,
														index: interactive.id
													}
													intreactiveStr.push(data);
													if (interactive.id == dataFormats['text']) {
														dataFormats['text'] = interactive.title;
													}
												});
												interactiveData.lists = intreactiveStr
											}
											interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
											dataFormats['reply']['interactiveData'] = interactiveData;
										} else if (replyInfo.media == 'text' || ticketList.media == 'text') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'video' || ticketList.media == 'video') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'image' || ticketList.media == 'image') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'audio' || ticketList.media == 'audio') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'document' || ticketList.media == 'document') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'voice' || ticketList.media == 'voice') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (replyInfo.media == 'location') {
											dataFormats['reply'] = replyInfo;
											dataFormats['reply']['location_url'] = replyInfo.locationurl;

											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (jsonData && jsonData.order) {
											dataFormats['reply']['orderData'] = jsonData.order;
										}
									}
								});
							} else if (ticketList && !ticketList.reply_id) {
								if (ticketList.media == 'contacts') {
									dataFormats['text'] = JSON.parse(ticketList.text);
								} else {
									dataFormats['text'] = ticketList.text;
								}

								if (jsonData && jsonData.interactive) {
									var interactiveData = {
										"to": "",
										"type": "interactive",
										"headerType": null,
										"headerText": null,
										"image": null,
										"video": null,
										"document": null,
										"bodyType": null,
										"footerType": null,
										"footerText": null,
										"buttons": [],
										"lists": []
									};
									var intreactiveStr = [];
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
										jsonData.interactive.action.buttons.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.buttons = intreactiveStr
									}
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
										jsonData.interactive.action.lists.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.lists = intreactiveStr
									} 

									if (jsonData && jsonData.order) {
										dataFormats['orderData'] = jsonData.order;
									}
									interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
									dataFormats['interactiveData'] = interactiveData;
								}

								if (jsonData && jsonData.template && jsonData.template.json) {
									var templateData: any;
									var type = typeof jsonData.template.json
									if (type == "string") {
										templateData = JSON.parse(jsonData.template.json)
									} else {
										templateData = jsonData.template.json;
									}

									var chatDatas = {};
									var buttonList = [];
									if (jsonData.template.selected) {
										chatDatas['selected'] = jsonData.template.selected
										templateData.templateComponent['templateName'] = templateData.template.name;
										chatDatas['templateComponent'] = templateData.templateComponent;
										if (templateData.templateComponent.buttons && templateData.templateComponent.buttons.length > 0) {
											templateData.templateComponent.buttons.forEach(temp => {

												if (temp.value == jsonData.template.selected) {
													temp['isActive'] = true;
												}
												if (temp.type == 'button') {
													buttonList.push(temp)
												}
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										} else {
											dataFormats['text'] = ticketList.text;
										}
									}
									if (!jsonData.template.selected) {
										var isCarousel = false;
										chatDatas['selected'] = jsonData.human.button_text;
										if (!templateData.templateComponent) {
											templateData["templateComponent"] = jsonData.template.json;
										}
										jsonData.template.json.forEach(carousel => {
											if (carousel.type == 'CAROUSEL') {
												isCarousel = true;
											}
										});
										if (isCarousel) {
											templateData.templateComponent['templateName'] = jsonData.template.name + ' - carousel Template';
											templateData.templateComponent['isCarousel'] = true;
										} else {
											templateData.templateComponent['templateName'] = jsonData.template.name;
										}
										chatDatas['templateComponent'] = templateData;
										const buttonData = templateData.filter((template) => template.type == 'BUTTONS');
										if (buttonData && buttonData.length > 0 && !isCarousel) {
											buttonData[0].buttons.forEach(temp => {
												var dataButton = {
													value: temp.text,
													type: temp.type,
												}
												if (temp.text == jsonData.human.button_text) {
													dataButton['isActive'] = true;
												}
												buttonList.push(dataButton)
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										}
										if(isCarousel) {
											var components = {
												// value: '',
												// type: 'button',
											}
											buttonList.push(components);
											chatDatas['components'] = buttonList;
											chatDatas['text'] = ticketList.text;
											dataFormats['text'] = chatDatas;
										}
									}

								} else {
									dataFormats['text'] = ticketList.text;
								}
								
							}
							if (additionalData.TicketInfo && additionalData.TicketInfo.length > 0 && ticketList && ticketList.people_id) {
								additionalData.TicketInfo.forEach(ticketId => {
									if (ticketId.people_id == ticketList.people_id) {
										dataFormats['ticketId'] = ticketId.id;
									}
								});
							}
							if (ticketList && ticketList.tweetdm_id) {
								dataFormats['tweetdm_id'] = ticketList.tweetdm_id;
							} else {
								dataFormats['tweetdm_id'] = ticketList.id;
							}
							if (additionalData && additionalData.clientSiteInfo) {
								dataFormats['siteName'] = additionalData.clientSiteInfo.details?.name;
								dataFormats['clientSiteTextColor'] = additionalData.clientSiteInfo?.color;
								dataFormats['clientSiteColor'] = additionalData.clientSiteInfo.details?.color;
							}
							if (additionalData && additionalData.peopleData.length > 0) {
								dataFormats['peopleName'] = additionalData.peopleData[0]?.name;
								dataFormats['peopleAvatar'] = additionalData.peopleData[0]?.peopleAvatar;
							}
							dataFormat.push(dataFormats);
						});
						if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
							params.current = data.pagnitation.pageCount;
							this.changeParams(params);
						}
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: Tweetdm) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new TweetdmModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				// this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	post(data: any, url: string = null) {
		let postUrl = (url) ? url : this.url;
		let showAlert: boolean = (data && data.showAlert) ? data.showAlert : false;
		this.commonService.replayChat(postUrl, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.loaderService.changeAdSendMsgLoader(false);
				if (data.datas && data.datas.length > 0) {
					for (let i = 0; i <= data.datas.length; i++) {
						if (data.datas[i]["asset_url"] && data.datas[i]["asset_url"].length > 0) {
							var baseUrl = data.datas[i]["asset_url"];
							// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
							// var decodeName = baseUrl.split("/").pop()
							// let encoded = decodeURIComponent(decodeName);
							data.datas[i].asset_url = baseUrl;
						}

						this.concatItem(data.datas[i]);
						this.changeItem(data.datas[i]);
					}
					if (showAlert) { this.alert('Success', 'Message Sent Successfully'); }
				} else if (data && data.data) {

					if (data.data.asset_url && data.data.asset_url.length > 0) {
						var baseUrl = data.data.asset_url;
						// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
						// var decodeName = baseUrl.split("/").pop()
						// let encoded = decodeURIComponent(decodeName);
						data.data.asset_url = baseUrl;
					}
					this.concatItem(data.data);
					this.changeItem(data.data);
					if (showAlert) { this.alert('Success', 'Message Sent Successfully'); }
				}
				if (data.message) {
					this.alert('Info', data.message);
				}
				setTimeout(() => {
					this.getAllLiveItems();
				}, 200);
				// this.alert('Success', 'Message Sent Successfully');
			},
				error => {
					console.log('Error ::' + error);
					this.loaderService.changeAdSendMsgLoader(false);
					this.alert('Danger', 'Server Busy. Try after Sometimes !!!');
				}
			);
	}

	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				// this.alert('Info', 'Updated Successfully !!!')
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
				this.spliceItem(id);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

}
