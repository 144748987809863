import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from "../../app-share.module";

import { BrandRoutingModule } from './brand-routing.module';
import { MainComponent } from './main/main.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { SidebarBrandComponent } from './sidebar-brand/sidebar-brand.component';
import { AssignClientComponent } from './assign-client/assign-client.component';
import { BranchListComponent } from './branch-list/branch-list.component';
import { MainBranchComponent } from './main-branch/main-branch.component';
import { SidebarBranchComponent } from './sidebar-branch/sidebar-branch.component';
// import { AgmCoreModule } from '@agm/core';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { GoogleMapsModule } from '@angular/google-maps'

@NgModule({
  declarations: [
    MainComponent,
    BrandListComponent,
    SidebarBrandComponent,
    AssignClientComponent,
    BranchListComponent,
    MainBranchComponent,
    SidebarBranchComponent,
    MapMarkerComponent
  ],
  imports: [
    AppShareModule,
    CommonModule,
    BrandRoutingModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   apiKey: 'AIzaSyA3oMGeQj2KG61epdlIigtRnUBjjg0YzCU'
    // })
    GoogleMapsModule,
  ],
  exports: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class BrandModule { }
