import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';

import {
    User, UserService,
    ResellerService, Reseller, ResellerModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'reseller-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
    loader: boolean = true;
    subLoader: boolean = false;
    authUser: User;
    resellersAll: Reseller[] = [];
    resellers: Reseller[] = [];
    param: any;
    resellerResponse: any;
    dataSource = new MatTableDataSource(this.resellers);
    displayedColumns = ['code', 'avatar', 'name', 'contact', 'status', 'action'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    // MatPaginator Output
    pageEvent: PageEvent;
    dataLength: number = 0;

    constructor(public userService: UserService,
        public resellerService: ResellerService,
        private snackBar: MatSnackBar,
        private router: Router,
        public matDialog: MatDialog,
        private orderByPipe: OrderByPipe) {
        this.userService.user.subscribe(data => this.authUser = data);
        this.resellerService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
            if(data !== this.resellersAll) { this.subLoader = false; }
            this.resellersAll = data;
            this.resellers = this.orderByPipe.transform(data, '-id');
            this.dataSource = new MatTableDataSource(this.resellers);
            this.dataSource.sort = this.sort;
        });
    }

    ngOnInit() {
        this.doLoader();
        this.dataInit();
    }

    /** On destroy */
    ngOnDestroy(): void {
        this.resellerService.unSubscribeFilter();
    }

    doLoader() {
        setTimeout(() => {
            this.loader = !this.loader;
        }, 5000);
    }

    setSubLoader() {
        this.subLoader = true;
        setTimeout(() => {
            this.subLoader = false;
        }, 5000);
    }

    dataInit() {
        this.resellerService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
        this.resellerService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
    }

    /** Add / Update Reseller */
    newReseller(reseller = null): void {
        this.resellerService.changeItem((reseller) ? reseller : new ResellerModel({}));
        let dialogRef = this.matDialog.open(FormComponent, {
            width: "600px",
            data: {
                action: (reseller) ? 'edit' : 'new',
                item: reseller,
            }
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) { return; }
                const actionType: string = response[0];
                const formData: UntypedFormGroup = response[1];
                const id: number = (response[2]) ? response[2] : null;
                switch (actionType) {
                    case 'new':
                        this.resellerService.store(formData.value);
                        break;
                    case 'update':
                        this.resellerService.update(id, formData.value);
                        break;
                    case 'delete':
                        this.deleteReseller(reseller);
                        break;
                }
            });
    }

    /** Delete Social Site */
    deleteReseller(reseller: Reseller): void {
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'delete',
                message: 'Are you sure you want to delete?',
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                this.resellerService.destroy(reseller.id);
            }
        });
    }

    statusChange(id, event) {
        this.resellerService.update(id, { 'is_active': event });
        // setTimeout(() => {
        // this.snackBar.openFromComponent(AlertComponent, {
        //     duration: 500000,
        //     panelClass: ['snackBarSuccess']
        // });
        // this.snackBar.open('Update Successfully', 'Close', {
        //     panelClass: ['snackBarSuccess']
        // });
        // }, 300);
    }

}
