import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RLogRouteModule } from './log-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';

import { LogService } from 'src/app/@tji/_dbShare';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        SidebarComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        RLogRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ListComponent,
    ],
    providers: [
        LogService
    ]
})
export class RLogModule { }
