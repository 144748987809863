import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  User, UserService, CustomvalidationService,
  AgentService, Agent, AgentModel,
  RClientService,
  ClientService
} from 'src/app/@tji/_dbShare';

import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
  selector: 'app-swipe-client-site',
  templateUrl: './swipe-client-site.component.html',
  styleUrls: ['./swipe-client-site.component.scss']
})
export class SwipeClientSiteComponent {
  dialogTitle: string = 'Swipe Client Site Account';
  authUser: User;
  action: string;
  clientSiteList: any;
  selectedClient: any;
  defualtClientID: any;

  myControl = new FormControl<string | any>('');
  filteredOptions: Observable<any[]>;

  constructor(public userService: UserService,
    public rClientService: RClientService,
    public clientService: ClientService,
    private customValidator: CustomvalidationService,
    public matDialogRef: MatDialogRef<SwipeClientSiteComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder) {
    this.getInit(_data);
    this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.clientSiteList = data
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.clientSiteList.slice();
        }),
      );
    });
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.clientSiteList.slice();
      }),
    );
  }


  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.clientSiteList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  /** On destroy */
  ngOnDestroy(): void {
    delete this.defualtClientID;
    delete this.selectedClient;
  }

  getInit(data) {
    // Set the defaults
    this.action = data.action;
    this.defualtClientID = data.item.client_id;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Swipe Client Site Account';
        break;
      default:
        break;
    }
  }

  selectClient(data: any) {
    if (data == null) {
      delete this.selectedClient;
    } else {
      this.selectedClient = data.option.value.id;;
    }
  }

}
