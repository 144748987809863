import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	QuickMessageService, QuickMessage, QuickMessageModel, AlertService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'quick-message-media-form',
	templateUrl: './quickMessageMediaForm.component.html',
	styleUrls: ['./quickMessageMediaForm.component.scss']
})

export class QuickMessageMediaFormComponent implements OnInit, OnDestroy {
	subLoader: boolean = false;
	dialogTitle: string = 'Add / Change Image Asset';
	authUser: User;
	quickMessage: QuickMessage;
	action: string;
	type: string;
	allAssets: Array<any> = [];
	images: Array<any> = [];
	videos: Array<any> = [];
	documents: Array<any> = [];
	error: string = null;
	typeData: string = 'image';

	constructor(public userService: UserService,
		public quickMessageService: QuickMessageService,
		public matDialogRef: MatDialogRef<QuickMessageMediaFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder,
		private cd: ChangeDetectorRef, 
		private alertService: AlertService,) {
		this.getInit(_data);
		this.quickMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.quickMessage) { this.subLoader = false; }
			this.quickMessage = data;

			if (this.quickMessage && this.quickMessage.imageAssets) {
				this.images = this.quickMessage.imageAssets;
			}else {
				this.images = [];
			}

			if (this.quickMessage && this.quickMessage.videoAssets) {
				this.videos = this.quickMessage.videoAssets;
			}else {
				this.videos = [];
			}

			if (this.quickMessage && this.quickMessage.documentAssets) {
				this.documents = this.quickMessage.documentAssets;
			}else {
				this.documents = [];
			}

			if (this.quickMessage && this.quickMessage.allAssets) {
				this.allAssets = this.quickMessage.allAssets;
			}else {
				this.allAssets = [];
			}

		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 2000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.type = data.type;
		switch (data.type) {
			case 'image':
				this.dialogTitle = 'Add / Change Image Asset';
				break;
			case 'video':
				this.dialogTitle = 'Add / Change Video Asset';
				break;
			case 'document':
				this.dialogTitle = 'Add / Change Document Asset';
				break;
			default:
				this.dialogTitle = 'Add / Change Image Asset';
				break;
		}
	}

	getAcceptType(): string {
		let type = (this.type) ? this.type : 'image';
		let output: string = 'image/*';
		switch (type) {
			case 'image':
				output = 'image/*';
				break;
			case 'video':
				output = 'video/*';
				break;
			case 'document':
				output = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf';
				break;
			default:
				output = 'image/*';
				break;
		}
		return output;
	}

	storeMedia(event) {
		let type = (this.type) ? this.type : 'image';
		switch (type) {
			case 'image':
				this.storeImage(event);
				break;
			case 'video':
				this.storeVideo(event);
				break;
			case 'document':
				this.storeImage(event);
				break;
			default:
				this.storeImage(event);
				break;
		}
	}

	storeImageProcess(input) {
		if (this.typeData != null) {
			this.setSubLoader();
			this.quickMessageService.post('quickMessage/addAsset', input);
			// setTimeout(() => { this.quickMessageService.getItem({ 'id': this.quickMessage.id }); }, 300);
		}else {
			this.alertService.webShow('Danger', 'This Format Not Supported Contact admin');
		}
	}

	storeVideoProcess(input) {
		if (this.typeData != null) {
			this.setSubLoader();
			this.quickMessageService.post('quickMessage/addAsset', input);
			// setTimeout(() => { this.quickMessageService.getItem({ 'id': this.quickMessage.id }); }, 300);
		}else {
			this.alertService.webShow('Danger', 'This Format Not Supported Contact admin');
		}
	}

	storeDocumentProcess(input) {
		this.setSubLoader();
		this.quickMessageService.post('add-quickmessage-document-asset', input);
		setTimeout(() => { this.quickMessageService.getItem({ 'id': this.quickMessage.id }); }, 300);
	}

	imageHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			if (file.size && file.size < 6000000) {
				this.error = null;
				reader.onload = (e: any) => {
					let newImage = [];
					newImage['url'] = e.target.result;
					newImage['type'] = 'image';
					this.images[this.images.length] = newImage;
					this.cd.markForCheck();
				};
			} else {
				this.error = "Maximum FileSize 5MB";
			}
		}
	}

	videoHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			if (file.size && file.size < 6000000) {
				this.error = null;
				reader.onload = (e: any) => {
					let newVideo = [];
					newVideo['url'] = e.target.result;
					newVideo['type'] = 'video';
					this.videos[this.videos.length] = newVideo;
					this.cd.markForCheck();
				};
			} else {
				this.error = "Maximum FileSize 5MB";
			}
		}
	}

	documentHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			if (file.size && file.size < 6000000) {
				this.error = null;
				reader.onload = (e: any) => {
					let newDocument = [];
					newDocument['url'] = e.target.result;
					newDocument['type'] = 'document';
					this.documents[this.documents.length] = newDocument;
					this.cd.markForCheck();
				};
			} else {
				this.error = "Maximum FileSize 5MB";
			}
		}
	}

	storeImage(event, quickMessageId = null) {
		quickMessageId = (quickMessageId) ? quickMessageId : this.quickMessage.id;
		let resourceData = {
			'resource_type': 'quick_message',
			'resource_id': quickMessageId,
			'storeMode': 'add-quickmessage-image-asset'
		};
		let reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = (e: any) => {
				var types = file.type.split("/");
				var typeDatas = types[1];
				this.typeData = null;
				switch (typeDatas) {
					case "JPEG": case "JPG":
					case "PNG": case "GIF":
					case "TIFF": case "RAW":
					case "jpeg": case "jpg":
					case "png": case "gif":
					case "tiff": case "raw":
					case "WebP": case "webp": case "WEBP":
						this.typeData = 'image'
						break;
					case "DOC": case "DOCX":
					case "ODT": case "PDF":
					case "PPT": case "PPTX":
					case "TXT": case "XLS":
					case "XLSX": case "ZIP":
					case "CVS": case "vnd.ms-excel":
					case "doc": case "csv":
					case "docx": case "odt":
					case "pdf": case "pptx":
					case "text": case "xls":
					case "xlsx": case "zip": case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
					case "cvs": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
					case "vnd.openxmlformats-officedocument.wordprocessingml.document":
						this.typeData = 'document'
						break;
					case "MP3": case "WAV":
					case "AAC": case "FLAC":
					case "Ogg": case "Ogg Vorbis":
					case "PCM": case "MPEG":
					case "mp3": case "wav":
					case "aac": case "flac":
					case "ogg": case "mpeg":
					case "ogg vorbis": case "pcm":
                    case "vnd.dlna.adts":
                    case "m4a": case "M4A":
                    case "x-m4a": case "x-M4A":
						this.typeData = 'voice'
						break;
					case "WEBM": case "MPG":
					case "MP2":
					case "MPE": case "MPV":
					case "OGG": case "MP4":
					case "AVI": case "WMV":
					case "MOV": case "QT":
					case "FLV": case "SWF":
					case "AVCHD":
					case "webm": case "mpg":
					case "mp2":
					case "mpe": case "mpv":
					case "ogg": case "mp4":
					case "avi": case "wmv":
					case "mov": case "qt":
					case "flv": case "swf":
					case "avchd": case "3gpp":
                            case "3GPP":
						this.typeData = 'video'
					default:
						break;
				}
				let input = {
					'asset': reader.result,
					'asset_name': file.name.replace(/ /g, "_"),
					'asset_type': this.typeData,
					'resource_id': resourceData.resource_id,
				};
				this.storeImageProcess(input);
			}
		}
	}

	storeVideo(event, quickMessageId = null) {
		quickMessageId = (quickMessageId) ? quickMessageId : this.quickMessage.id;
		let resourceData = {
			'resource_type': 'quick_message',
			'resource_id': quickMessageId,
			'storeMode': 'add-quickmessage-video-asset'
		};
		let reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			if (file.size && file.size < 5000000) {
				reader.onload = (e: any) => {

					var types = file.type.split("/");
					var typeDatas = types[1];
					this.typeData = null;
					switch (typeDatas) {
						case "JPEG": case "JPG":
						case "PNG": case "GIF":
						case "TIFF": case "RAW":
						case "jpeg": case "jpg":
						case "png": case "gif":
						case "tiff": case "raw":
						case "WebP": case "webp": case "WEBP":
							this.typeData = 'image'
							break;
						case "DOC": case "DOCX":
						case "ODT": case "PDF":
						case "PPT": case "PPTX":
						case "TXT": case "XLS":
						case "XLSX": case "ZIP":
						case "CVS": case "vnd.ms-excel":
						case "doc": case "csv":
						case "docx": case "odt":
						case "pdf": case "pptx":
						case "text": case "xls":
						case "xlsx": case "zip": case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
						case "cvs": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
						case "vnd.openxmlformats-officedocument.wordprocessingml.document":
							this.typeData = 'document'
							break;
						case "MP3": case "WAV":
						case "AAC": case "FLAC":
						case "Ogg": case "Ogg Vorbis":
						case "PCM": case "MPEG":
						case "mp3": case "wav":
						case "aac": case "flac":
						case "ogg": case "mpeg":
						case "ogg vorbis": case "pcm":
						case "vnd.dlna.adts":
						case "m4a": case "M4A":
						case "x-m4a": case "x-M4A":
							this.typeData = 'voice'
							break;
						case "WEBM": case "MPG":
						case "MP2":
						case "MPE": case "MPV":
						case "OGG": case "MP4":
						case "AVI": case "WMV":
						case "MOV": case "QT":
						case "FLV": case "SWF":
						case "AVCHD":
						case "webm": case "mpg":
						case "mp2":
						case "mpe": case "mpv":
						case "ogg": case "mp4":
						case "avi": case "wmv":
						case "mov": case "qt":
						case "flv": case "swf":
						case "avchd": case "3gpp":
                            case "3GPP":
						case "m4a": case "M4A":
							this.typeData = 'video'
						default:
							break;
					}

					this.error = null;
					let input = {
						'asset': reader.result,
						'asset_name': file.name.replace(/ /g, "_"),
						'asset_type': this.typeData,
						'resource_id': resourceData.resource_id,
					};
					this.storeVideoProcess(input);
				}
			} else {
				// this.error = "Maximum FileSize 5MB";
				this.alertService.webShow('Danger', 'Maximum FileSize 5MB');
			}
		}
	}

	storeDocument(event, quickMessageId = null) {
		quickMessageId = (quickMessageId) ? quickMessageId : this.quickMessage.id;
		let resourceData = {
			'resource_type': 'quick_message',
			'resource_id': quickMessageId,
			'storeMode': 'add-quickmessage-document-asset'
		};
		let reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = (e: any) => {
				let input = {
					'document': e.target.result,
					'document_name': file.name,
					'document_type': file.type,
					'document_file_path': event.target.value,
					'image': e.target.result,
					'image_name': file.name,
					'image_type': file.type,
					'image_file_path': event.target.value,
				};
				this.storeDocumentProcess(Object.assign(resourceData, input));
			}
		}
	}

	removeImage(quickMessageId, imageUrl) {
		quickMessageId = (quickMessageId) ? quickMessageId : this.quickMessage;
		let input = {
			'resource_id': quickMessageId,
			'url': imageUrl,
		};
		this.quickMessageService.post('quickMessage/removeAsset', input);
		// setTimeout(() => { this.quickMessageService.getItem({ 'id': this.quickMessage.id }); }, 300);
	}

	removeVideo(quickMessageId, videoUrl) {
		quickMessageId = (quickMessageId) ? quickMessageId : this.quickMessage;
		let input = {
			'resource_id': quickMessageId,
			'url': videoUrl,
		};
		this.quickMessageService.post('quickMessage/removeAsset', input);
		// setTimeout(() => { this.quickMessageService.getItem({ 'id': this.quickMessage.id }); }, 300);
	}

	removeDocument(quickMessageId, documentUrl) {
		quickMessageId = (quickMessageId) ? quickMessageId : this.quickMessage;
		let input = {
			'resource_id': quickMessageId,
			'url': documentUrl,
		};
		this.quickMessageService.post('quickMessage/removeAsset', input);
		// setTimeout(() => { this.quickMessageService.getItem({ 'id': this.quickMessage.id }); }, 300);
	}

}
