import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FacebookFormComponent } from '../facebookForm/facebookForm.component';
import { MessengerFormComponent } from '../messengerForm/messengerForm.component';
import { WhatsappFormComponent } from '../whatsappForm/whatsappForm.component';
import { TwitterFormComponent } from '../twitterForm/twitterForm.component';
import { YoutubeFormComponent } from '../youtubeForm/youtubeForm.component';
import { GooglechatFormComponent } from '../googlechatForm/googlechatForm.component';
import { InstagramFormComponent } from '../instagramForm/instagramForm.component';
import { InstadmFormComponent } from '../instadmForm/instadmForm.component';
import { WebchatFormComponent } from '../webchatForm/webchatForm.component';

import {
	User, UserService,
	ClientSiteService, ClientSite, ClientSiteModel,
	PersonSettingService, PersonSetting,
	SiteService, Site
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'socialsite-sidebar-sites',
	templateUrl: './sidebarSites.component.html',
	styleUrls: ['./sidebarSites.component.scss']
})

export class SidebarSitesComponent {
	@Output() onClose = new EventEmitter();
	sites: Site[] = [];
	siteParam: any;

	twitterRedirectUrl: string = null;
	loader: boolean = true;
	loginUrl: any;

	constructor(private _formBuilder: UntypedFormBuilder,
		public siteService: SiteService,
		public personSettingService: PersonSettingService,
		public clientSiteService: ClientSiteService,
		public userService: UserService,
		public matDialog: MatDialog) {
		this.siteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.sites = data;
		});
		this.clientSiteService.twitterRedirectUrl.pipe(untilDestroyed(this)).subscribe(data => this.twitterRedirectUrl = data);
		this.siteService.params.pipe(untilDestroyed(this)).subscribe(data => this.siteParam = data);
		this.clientSiteService.socialSiteUrlRedirectUrl.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.data) {
				this.loginUrl = data.data ? data.data.RedirectUrl : '';
			}
		});
	}

	ngOnInit() {
		this.siteService.getAllItems();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.siteService.resetParams();
	}


	addAccount(site: Site) {
		var data = {
			"site_id": site.id,
		}
		if (site.id != 2) {
			this.clientSiteService.facebookClientSiteUrl(null, data)
		}
		setTimeout(() => {
			if (site.permittedCount == 0  && site.installedCount > 0 && site.permittedCount > 0  && site.installedCount >= site.permittedCount) {
				let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
					disableClose: false,
					width: '400px',
					data: {
						title: 'Access Limited',
						type: 'info',
						message: "You have Only " + site.permittedCount + " permitted accounts to Configure. Please Contact Ai-Octopus Team to Get More...",
					}
				});
			} else if (site.permittedCount == 0  || site.installedCount == site.permittedCount || site.installedCount > site.permittedCount) {
				let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
					disableClose: false,
					width: '400px',
					data: {
						title: 'Access Limited',
						type: 'info',
						message: "You have Only " + site.permittedCount + " permitted accounts to Configure. Please Contact Ai-Octopus Team to Get More...",
					}
				});
			} else {
				this.siteService.changeItem(site);
				this.clientSiteService.changeItem(new ClientSiteModel({}));
				let dialogRef: any = null;
				switch (site.slug) {
					case "Twitter": case "twitter":
					case "Tweet": case "tweet":
					case "Twitterdm": case "twitterdm":
					case "Tweetdm": case "tweetdm":
						// let twitterUrl = this.loginUrl ? this.loginUrl : null;
						// if (twitterUrl) {
						// 	// this.clientSiteService.getTwitterRedirectUrl(twitterUrl + '/new');
						// 	setTimeout(() => {
						// 		this.loader = !this.loader;
						// 		if (this.twitterRedirectUrl) {
						// 			window.open(this.twitterRedirectUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						// 		}
						// 	}, 3000);
						// } else {
						// 	dialogRef = this.matDialog.open(TwitterFormComponent, {
						// 		width: "600px",
						// 		data: {
						// 			action: 'new',
						// 			item: site,
						// 		}
						// 	});
						// }
						break;
					case "messenger":
						let messengerUrl = this.loginUrl ? this.loginUrl : null;
						if (messengerUrl) {
							window.open(messengerUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						} else {
							dialogRef = this.matDialog.open(MessengerFormComponent, {
								width: "600px",
								data: {
									action: 'new',
									item: site,
								}
							});
						}
						break;
					case "facebook":
						let facebookUrl = this.loginUrl ? this.loginUrl : null;
                        if (facebookUrl) {
                            window.open(facebookUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						} else {
							dialogRef = this.matDialog.open(FacebookFormComponent, {
								width: "600px",
								data: {
									action: 'new',
									item: site,
								}
							});
						}
						break;
					case "whatsapp":
						dialogRef = this.matDialog.open(WhatsappFormComponent, {
							width: "400px",
							data: {
								action: 'new',
								item: site,
							},
							panelClass: ['animate__animated', 'animate__slideInRight']
						});
						break;
					case "instagram":
						let instagramUrl = this.loginUrl ? this.loginUrl : null;
						if (instagramUrl) {
							window.open(instagramUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						} else {
							dialogRef = this.matDialog.open(InstagramFormComponent, {
								width: "600px",
								data: {
									action: 'new',
									item: site,
								}
							});
						}
						break;
					case "instadm":
						let instadmUrl = this.loginUrl ? this.loginUrl : null;
						if (instadmUrl) {
							window.open(instadmUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						} else {
							dialogRef = this.matDialog.open(InstadmFormComponent, {
								width: "600px",
								data: {
									action: 'new',
									item: site,
								}
							});
						}
						break;
					case "webchat":
						dialogRef = this.matDialog.open(WebchatFormComponent, {
							width: "600px",
							data: {
								action: 'new',
								item: site,
							}
						});
						break;
					case "youtube":
						let youtubeUrl = this.loginUrl ? this.loginUrl : null;
						if (youtubeUrl) {
							window.open(youtubeUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						} else {
							dialogRef = this.matDialog.open(YoutubeFormComponent, {
								width: "600px",
								data: {
									action: 'new',
									item: site,
								}
							});
						}
						break;
					case "googlechat":
						let googlechatUrl = this.loginUrl ? this.loginUrl : null;
						if (googlechatUrl) {
							window.open(googlechatUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
						} else {
							dialogRef = this.matDialog.open(GooglechatFormComponent, {
								width: "600px",
								data: {
									action: 'new',
									item: site,
								}
							});
						}
						break;
					default:
						break;
				}

				if (dialogRef != null) {
					dialogRef.afterClosed().subscribe(response => {
						if (!response) { return; }
						const actionType: string = response[0];
						const formData: UntypedFormGroup = response[1];
						const id: number = (response[2]) ? response[2] : null;
						switch (actionType) {
							case 'new':
								this.clientSiteService.store(formData.value);
								break;
							case 'update':
								this.clientSiteService.store(formData.value);
								break;
						}
					});
				}
			}
		}, 1000);
	}


	// getSettingFields() {
	// 	if(this.userService.isAuthUserIsClient()) {
	// 		this.settingFieldParam.is_client_editable = '1';
	// 		this.settingFieldParam.is_agent_editable = '0';
	// 	}
	// 	this.settingFieldService.getAllItems();
	// }

	// selectSettingField(personSetting: PersonSetting = null, field: SettingField = null) {
	// 	this.settingFieldService.changeItem(field);
	// 	let dialogRef = this.matDialog.open(FormComponent, {
	// 		width: "600px",
	// 		data: {
	// 			action: (personSetting) ? 'edit' : 'new',
	// 			item: personSetting,
	// 			field: field,
	// 		}
	// 	});

	// 	dialogRef.afterClosed()
	// 		.subscribe(response => {
	// 			if (!response) { return; }
	// 			const actionType: string = response[0];
	// 			const formData: FormGroup = response[1];
	// 			const id: number = (response[2]) ? response[2] : null;
	// 			switch (actionType) {
	// 				case 'new':
	// 					this.personSettingService.store(formData.value);
	// 					break;
	// 				case 'update':
	// 					this.personSettingService.update(id, formData.value);
	// 					break;
	// 			}
	// 		});
	// }

}
