import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import {
  User,
  UserService,
  AlertService,
  ImageService,
  IntractiveService,
  IntractiveGroupService,
  IntractiveGroup,
  CatalogProductService,
  catalogProduct,
  ListProductSectionModel,
  EventService,
} from "src/app/@tji/_dbShare";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@UntilDestroy()
@Component({
  selector: 'app-manage-category',
  templateUrl: './manage-category.component.html',
  styleUrls: ['./manage-category.component.scss']
})
export class ManageCategoryComponent {
  subLoader: boolean = false;
  dialogTitle: string = "Add / Change Product";
  authUser: User;
  eventData: any;
  categoryData: any;

  selectedProductLists: catalogProduct[] = [];
  loader: boolean = false;

  intractiveGroupProduct: IntractiveGroup;
  productList: catalogProduct[] = [];
  catalogName: string = "";

  constructor(
    public userService: UserService, private eventService: EventService,
    public catalogProductService: CatalogProductService,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<ManageCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.catalogProductService.allIntractiveProductItems
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data && data.length > 0) {
          this.productList = data ? data : [];
          this.productResture();
        }
      });
    this.catalogProductService.item.pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data && data._id) {
          this.categoryData = data ? data : [];
          this.catalogName = this.categoryData.catalogName?this.categoryData.catalogName:'';
          this.productResture();
        }
      });
    this.userService.user.subscribe(data => this.authUser = data);
    this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });
  }

  ngOnInit(): void {
  }


  // Transfer Items Between Lists
  onDrop(event: any, index: any) {
    if (this.selectedProductLists.length < 30) {
      this.catalogProductService.drop(event);
      this.selectedProductLists =
        event.container.data.filter(
          (product, index, array) =>
            index ===
            array.findIndex((findTest) => findTest._id === product._id)
        );
    } else {
      this.alertService.webShow("danger", "maximum 30 products only allowed");

    }
  }


  drop(event: CdkDragDrop<any[]>) {
    if (this.selectedProductLists.length < 30) {
      moveItemInArray(this.selectedProductLists, event.previousIndex, event.currentIndex);
      this.catalogProductService.drop(event);
      this.selectedProductLists =
        event.container.data.filter(
          (product, index, array) =>
            index ===
            array.findIndex((findTest) => findTest._id === product._id)
        );
    } else {
      this.alertService.webShow("danger", "maximum 30 products only allowed");

    }

  }


  reserProduct() {
    this.productList.forEach(element => {
      element['isDragDrop'] = false;
    });
  }

  productData: any;

  onSubmit(): void {
    delete this.productData;
    var productData = [];
    if (!this.selectedProductLists || this.selectedProductLists.length == 0) {
      this.alertService.webShow("danger", "Please add the product");
      return;
    }
    this.selectedProductLists.forEach(element => {
      productData.push(element.id);
    });
    this.matDialogRef.close(["update", productData]);
  }


  removeProduct(data, list: any = null, idx: any = null) {
    this.productList.forEach(element => {
      if (element._id == list._id) {
        element.isDragDrop = false;
        // this.catalogProductService.getAllIntractiveProduct(element)
      }
    });
    if (data == "muliple") {
      this.selectedProductLists.forEach((prod, index) => {
        if (prod._id == list._id) {
          this.selectedProductLists.splice(index, 1);
        }
      });
    }
  }

  productResture() {
    if (this.productList && this.productList.length > 0 && this.categoryData && this.categoryData._id && this.categoryData.products && this.categoryData.products.length > 0) {
      this.categoryData.products.forEach(category => {
        this.productList.forEach(product => {
          if (category == product.id) {
            product['isDragDrop'] = true;
            this.selectedProductLists.push(product);
          }
        });
      });
    }
  }

  checkEventData() {
    let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
    if (eventName) {
      let eventData = this.eventData;
      let data = this.eventData.data;
      switch (eventName) {
        case 'Login': case 'login':
          let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
          if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
            this.matDialogRef.close();
          }
          break;
        case 'logout': case 'Logout':
          let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
          if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
            this.matDialogRef.close();
          }
          break;
        default:
          break;
      }
    }
  }


}
