import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { Router } from '@angular/router';
import {
  User, UserService,
  RTemplateUsageReportsQueueService, RTemplateUsageReportsQueue
} from 'src/app/@tji/_dbShare';

import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { TemplateDeliveryReportComponent } from '../template-delivery-report/template-delivery-report.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
  selector: 'app-template-queue',
  templateUrl: './template-queue.component.html',
  styleUrls: ['./template-queue.component.scss'],
  providers: [OrderByPipe]
})
export class TemplateQueueComponent {
  loader: boolean = true;
  downloadLoader: boolean = false;
  subLoader: boolean = false;
  authUser: User;
  templatequeue: RTemplateUsageReportsQueue[] = [];
  templateUsage: RTemplateUsageReportsQueue;
  param: any;
  dataSource = new MatTableDataSource(this.templatequeue);
  displayedColumns = ['date', 'jobcode', 'sentby', 'totalCount', 'success', 'failed', 'completed', 'action'];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;

  
  constructor(public userService: UserService,
    public rtemplateUsageReportsQueueService: RTemplateUsageReportsQueueService,
    public matDialog: MatDialog,
    private orderByPipe: OrderByPipe, public location: Location,
    public router: Router) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.rtemplateUsageReportsQueueService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
    this.rtemplateUsageReportsQueueService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
    this.rtemplateUsageReportsQueueService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsage = data);
    this.rtemplateUsageReportsQueueService.loader.pipe(untilDestroyed(this)).subscribe(data => this.loader = data);
    this.rtemplateUsageReportsQueueService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data !== this.templatequeue) { this.subLoader = false; }
      this.templatequeue = data;
      this.dataSource = new MatTableDataSource(this.templatequeue);
      this.dataSource.sort = this.sort;
    });
    this.rtemplateUsageReportsQueueService.downloadLoader.pipe(untilDestroyed(this)).subscribe(data => this.downloadLoader = data);
  }

  ngOnInit() {
    // this.doLoader();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.rtemplateUsageReportsQueueService.unSubscribe();
    this.rtemplateUsageReportsQueueService.unSubscribeFilter();
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  syncTempCount(temp) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'update',
        message: 'Are you sure you want to update?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        this.rtemplateUsageReportsQueueService.tempCountSync({ id: temp.id });
      }
    });
  }

resentTemplate(temp) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'update',
        message: 'Are you sure you want to Resend?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        this.rtemplateUsageReportsQueueService.resend({ jobid: temp.id });
      }
    });
  }

}
