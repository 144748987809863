export class InstadmTicketModel {
    id: number;
    code: string;
    instadm_id: number;
    client_id: number;
    agent_id: number;
    priority: string;
    is_active: boolean;
    is_closed: boolean;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    instadm?: any;
    people?: any;
    people_id?: number;
    peopleName?: string;
    peopleAvatar?: string;
    clientSiteId?: number;
    pusherSync?: string;
    abandonAt: Date;
    abandonElapsed: number;
    last_message_on?: Date;

    mediaId?: string;
    unReadCount?: number;
    tableName: string;
    className: string;
    class?: string;
    statusName?: string;
    statusChanges?: Array<string>;
    peopleLabelId?: number;
    peopleLabelName?: string;
    peopleLabelColor?: string;
    peopleLabelColorText?: string;
    siteColor?: string;
    uName?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    siteName?: string;
    /**
     * Constructor
     *
     * @param instadm_ticket
     */
    constructor(instadm_ticket) {
            this.id = instadm_ticket.id || '';
            this.code = instadm_ticket.code || '';
            this.instadm_id = instadm_ticket.instadm_id || '';
            this.client_id = instadm_ticket.client_id || '';
            this.agent_id = instadm_ticket.agent_id || '';
            this.priority = instadm_ticket.priority || '';
            this.is_active = instadm_ticket.is_active || '';
            this.is_closed = instadm_ticket.is_closed || '';
            this.created_by = instadm_ticket.created_by || '';
            this.updated_by = instadm_ticket.updated_by || '';
            this.created_at = instadm_ticket.created_at || '';
            this.updated_at = instadm_ticket.updated_at || '';

            this.tableName 
            this.siteName = instadm_ticket.tableName || '';
    }
}
