import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sub-loader',
  templateUrl: './subLoader.component.html',
  styleUrls: ['./subLoader.component.scss']
})
export class SubLoaderComponent implements OnInit {

  @Input() enable: boolean = false;

  constructor() {}

  ngOnInit() { }

}
