import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, CustomvalidationService,
	AgentService, Agent, AgentModel,
	EventService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'agent-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	agent: Agent;
	action: string;
	agentForm: UntypedFormGroup;
	isAddAgent: boolean = true;
	totalItemCount: number = 0;
	eventData: any;

	constructor(public userService: UserService,
		public agentService: AgentService,
		private eventService: EventService,
		private customValidator: CustomvalidationService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.userService.user.subscribe(data => this.authUser = data);
		this.agentService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {
			if (data) {
				this.totalItemCount = data;
				// var  clientSettings = JSON.parse(localStorage.getItem('client_settings'));
				// var agentLimit = Number(clientSettings['agent_limits'])
				// if ( data['action'] == 'edit') {
				// 	this.isAddAgent = true;
				// }else if (this.totalItemCount && agentLimit && this.totalItemCount < agentLimit) {
				// 	this.isAddAgent = true;
				// }else if ( this.totalItemCount > agentLimit) {
				// 	this.isAddAgent = false;
				// }else {
				// 	this.isAddAgent = false;
				// }
			} else {
				this.isAddAgent = true;
			}
		});

		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});

		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => {
			if (data) {
				if (this.totalItemCount) {
					var clientSettings = JSON.parse(localStorage.getItem('client_settings'));
					var agentLimit = Number(clientSettings['agent_limits'])
					if (data && data.id) {
						this.isAddAgent = true;
					} else if (this.totalItemCount && agentLimit && this.totalItemCount < agentLimit) {
						this.isAddAgent = true;
					} else if (this.totalItemCount > agentLimit) {
						this.isAddAgent = false;
					} else {
						this.isAddAgent = false;
					}
				} else {
					this.isAddAgent = true;
				}
				this.agent = data;
				this.agentForm = this.createForm();
			}else{
				this.isAddAgent = true;
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Agent';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Agent';
				this.isAddAgent = true;
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.agent.name || null, [Validators.required, Validators.minLength(4), Validators.maxLength(25)]],
			// username: [this.agent.userInfo?this.agent.userInfo.username: null || null, [Validators.minLength(4), Validators.maxLength(25)]],
			designation: [this.agent.person_details ? this.agent.person_details.designation : null, [Validators.required, Validators.minLength(4), Validators.maxLength(40)]],
			email: [this.agent.userInfo ? this.agent.userInfo.email : '', [Validators.required, Validators.minLength(1), Validators.maxLength(60), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
			password: [null, [Validators.required, Validators.minLength(8), Validators.compose([this.customValidator.patternValidator()])]],
			mobile: [(this.agent.person_details) ? this.agent.person_details.mobile : null , [Validators.required, Validators.minLength(4), Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
		});
	}


	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'Login': case 'login':
					let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				case 'logout': case 'Logout':
					let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				default:
					break;
			}
		}
	}

}
