import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment-timezone';
import {
	User, UserService,
	LeadsHistoryService, LeadsHistory,
	ChatService, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-ticket-leads-history',
	templateUrl: './leadsHistorySidenav.component.html',
	styleUrls: ['./leadsHistorySidenav.component.scss']
})

export class LeadsHistorySideNavComponent implements OnInit, OnDestroy {
	@Output() onClose = new EventEmitter();
	user: User;
	loader: boolean = true;
	subLoader: boolean = true;
	leadsHistory: LeadsHistory;
	people: any;
	ticket: any;
	tickets: Array<any> = [];
	param: any;
	dataLength: number = 0;
	baseImage: string = this.commonService.logo_image;

	constructor(public userService: UserService,
		public chatService: ChatService,
		public leadsHistoryService: LeadsHistoryService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.leadsHistoryService.history.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data['rows'] && data['rows'].length > 0) {
				this.leadsHistory = data['rows'][0]['usersInfo'];
				this.people = data['rows']?data['rows'][0]['usersInfo']: null;
			}else{
				if (data && !data['rows'] || data && data['rows'] && !data['rows'][0] || data && data['rows'] && !data['rows'][0]['usersInfo']) {
					this.people =  null
				}
				
			}
			// else{
			// 	this.leadsHistory = data;
			// 	this.people = data['rows']?data['rows'][0]['usersInfo']: []
			// }
		});
		this.leadsHistoryService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.subLoader = true;
			if (data && data['rows'] && data['rows'].length > 0) {
				data['rows'].forEach(ticket => {
					ticket['comments'] = [];
					ticket['modal_has_flags'] = [];
					if (data && data['CommentsInfo']) {
						data['CommentsInfo'].forEach(comment => {
							if (ticket.id === comment.resource_id) {
								ticket['comments'].push(comment);
							}
						});
					}
					if (data && data['FlagMapInfo']) {
						data['FlagMapInfo'].forEach(flags => {
							if (ticket.id === flags.resource_id) {
								ticket['modal_has_flags'].push(flags);
							}
						});
					}
					if (ticket.created_at) {
						ticket.created_at = moment(ticket.created_at+'.000+0300').local();
					}
					if (ticket.updated_at) {
						ticket.updated_at = moment(ticket.updated_at+'.000+0300').local();
					}
				});
				this.tickets = data['rows'];
				this.subLoader = false;
			}else{
				this.subLoader = false;
				this.tickets = data;
			}
		});
		this.leadsHistoryService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.leadsHistoryService.resetParams();
		this.leadsHistoryService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 2000);
	}

	doSubLoader(){
		this.subLoader = true;
	}

	dataInit() {
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.leadsHistoryService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

}
