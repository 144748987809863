import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  CatalogService,
  CategoryService,
  User, UserService,
} from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-main-category',
  templateUrl: './main-category.component.html',
  styleUrls: ['./main-category.component.scss']
})
export class MainCategoryComponent {

  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  outputs: ['uploaded']
  user: User;
  params: any;
  constructor(public userService: UserService,
    public catalogService: CatalogService,
    public categoryService: CategoryService,
    private router: Router) {
    this.userService.user.subscribe(data => this.user = data);
    this.catalogService.params.subscribe(data => this.params = data);
  }

  ngOnInit(): void {
    this.params['is_connected'] = 1;
    this.catalogService.getAllItems();
  }
  toggleRightSidebar() {
    this.rightSideBar.toggle();
    this.categoryService.isAddItems.next(true);
    this.categoryService.itemSource.next(null);
  }

  isEditableData(data) {
    this.rightSideBar.toggle();
  }
}
