export class SettingModel {
    id: number;
    field: string;
    slug: string;
    value: string;
    field_type: string;
    category: string;
    is_editable: boolean;
    description: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    field_type_name: string;

    /**
     * Constructor
     *
     * @param setting
     */
    constructor(setting) {
            this.id = setting.id || '';
            this.field = setting.field || '';
            this.slug = setting.slug || '';
            this.value = setting.value || '';
            this.field_type = setting.field_type || '';
            this.category = setting.category || '';
            this.is_editable = setting.is_editable || '';
            this.description = setting.description || '';
            this.created_by = setting.created_by || '';
            this.updated_by = setting.updated_by || '';
            this.created_at = setting.created_at || '';
            this.updated_at = setting.updated_at || '';

            this.field_type_name = setting.field_type_name || '';
    }
}
