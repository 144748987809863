import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { WabaService } from 'src/app/@tji/_dbShare/waba/waba.service';

import { Eorders } from './eorders.interface';
import { EordersModel } from './eorders-model.model';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');
@Injectable({
  providedIn: 'root'
})
export class EordersService {
  url: string = 'eorders/list';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'search': '',
  };

  private librariesSource = new BehaviorSubject<Array<any>>([]);
  libraries = this.librariesSource.asObservable();

  private allItemsSource = new BehaviorSubject<Eorders[]>([]);
  allItems = this.allItemsSource.asObservable();

  public itemSource = new BehaviorSubject<Eorders>(new EordersModel({}));
  item = this.itemSource.asObservable();

  public itemIdSource = new BehaviorSubject<Eorders>(new EordersModel({}));
  itemId = this.itemIdSource.asObservable();

  private displayItemsSource = new BehaviorSubject<Eorders[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  public paymentGenerateItems = new BehaviorSubject<boolean>(false);
  paymentItems = this.paymentGenerateItems.asObservable();

  public isLoader = new BehaviorSubject<boolean>(true);
  loader = this.isLoader.asObservable();

  public isOrderLoader = new BehaviorSubject<boolean>(false);
  orderLoader = this.isOrderLoader.asObservable();

  public orderCountSource = new BehaviorSubject<any>([]);
  orderCount = this.orderCountSource.asObservable();

  public statusInfoData = new BehaviorSubject<boolean>(false);
  statusInfo = this.statusInfoData.asObservable();

  public itemPrintSource = new BehaviorSubject<any>([]);
  itemPrint = this.itemPrintSource.asObservable();

  public allActiveSource = new BehaviorSubject<Array<any>>([]);
  allActiveAgents = this.allActiveSource.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private wabaService: WabaService) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.defaultParams.search = '',
        this.resetParams(),
        this.getAllItems(),
        this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed EordersService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on EordersService');
  }

  concatlibrary(allItems: Eorders[]) {
    var oldLists: Array<any> = [];
    this.libraries.subscribe(data => {
      oldLists = data;
    });
    if (oldLists && oldLists.length > 0) {
      oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
    } else {
      oldLists = allItems;
    }
    this.changeLibraries(oldLists);
  }

  removelibrary(item: Eorders) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      oldLists = oldLists.filter(x => {
        return x._id !== item._id;
      });
    }
    this.changeLibraries(oldLists);
  }

  removelibraryById(id: number) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && id) {
      oldLists = oldLists.filter(x => {
        return x.id !== id;
      });
    }
    this.changeLibraries(oldLists);
  }

  replacelibrary(item: Eorders) {
    let oldLists = [];
    let isReplaced: boolean = false;
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      for (var i = 0; i < oldLists.length; ++i) {
        if (oldLists[i]._id === item._id) {
          oldLists.splice(i, 1, item);
          isReplaced = true;
          break;
        }
      }
    }
    if (!isReplaced) { oldLists.concat([item]); }
    this.changeLibraries(oldLists);
  }

  changeLibraries(libraries: Eorders[]) {
    this.librariesSource.next(libraries);
  }

  changeAllItems(allItems: Eorders[]) {
    this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }

  changeItem(item: Eorders) {
    this.itemSource.next(item);
  }

  changeItemId(item: Eorders) {
    this.itemIdSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: Eorders[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: Eorders) {
    let allItems = [];
    let isReplaced: boolean = false;
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id == item._id) { allItems.splice(i, 1, item); isReplaced = true; }
      }
    }
    if (!isReplaced) { allItems = allItems.concat([item]); }
    this.changeAllItems(allItems);
    this.changeItem(item);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'search': '',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          if (data.data && data.data.length > 0) {
            var orderData = data.data;
            var governorateInfo = data.additional.GovernorateInfo;
            var clientSiteInfo = data.additional.clientSiteInfo;
            var peopleInfo = data.additional.peopleInfo;
            var PaymentsInfo = data.additional.PaymentsInfo;
            var agentInfo = data.additional.agentsInfo;
            var brandInfo = data.additional.brandInfo;
            var regionInfo = data.additional.regions;
            orderData.forEach(element => {
              if (clientSiteInfo && clientSiteInfo.length > 0) {
                clientSiteInfo.forEach(clientSite => {
                  if (element.client_site_id == clientSite.id) {
                    element['account_name'] = clientSite.account_name;
                  }
                });
              }

              if (governorateInfo && governorateInfo.length > 0) {
                governorateInfo.forEach(clientSite => {
                  if (element.address && element.address.governarate_id == clientSite._id) {
                    element['address']['governorateName'] = clientSite.name_en;
                    element['governorateName'] = clientSite.name_en;
                  }
                });

                governorateInfo.forEach(clientSite => {
                  if (element.governorate_id == clientSite._id) {
                    element['governorateName'] = clientSite.name_en;
                  }
                });
              }

              if (regionInfo && regionInfo.length > 0) {
                regionInfo.forEach(clientSite => {
                  if (element.address && element.address.region_id && element.address.region_id == clientSite.id) {
                    element['address']['regionName'] = clientSite.name_en;
                    element['regionName'] = clientSite.name_en;

                  }
                });

                if (brandInfo && brandInfo.length > 0) {
                  brandInfo.forEach(brand => {
                    brand.clientSite.forEach(brandClient => {
                      if (brandClient.client_site_id == element.client_site_id && element.address) {
                        if (brand.countryInfo) {
                          element.address["country"] = brand?.countryInfo?.name_en;
                          element["tax"] = brand?.countryInfo?.tax;
                        }
                        element.address['brand'] = brand?.name;
                      }
                    });
                  });
                }
              }

              if (agentInfo && agentInfo.length > 0) {
                agentInfo.forEach(agent => {
                  if (element.agent_id == agent.id) {
                    element['agentName'] = agent.name;
                    element['agentLiveStatus'] = agent.liveStatus;
                    element['agentAvatar'] = agent.image_api;
                  }
                });
              }

              if (peopleInfo && peopleInfo.length > 0) {
                peopleInfo.forEach(people => {
                  if (element.people_id == people.id) {
                    element['name'] = people.name;
                    element['ref'] = people.unique_ref;
                    element['created_name'] = people.name;
                  }
                });
              }

              if (PaymentsInfo && PaymentsInfo.length > 0) {
                PaymentsInfo.forEach(Payments => {
                  if (element._id == Payments.typeId) {
                    element['PaymentsInfo'] = Payments;
                  }
                });
              }

            });
            this.changeAllItems(orderData);
            this.statusInfoData.next(data.additional.StatusInfo);
            this.changeTotalItem(data.pagnitation.totalResult);
            this.orderCountSource.next(data.additional.orderCount)
          } else {
            this.allItemsSource.next([]);
            this.changeTotalItem(null);
          }
          this.isLoader.next(false);
        } else {
          this.isLoader.next(false);
        }
      },
        error => { console.log('Error ::' + error); this.isLoader.next(false); }
      );
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        if (data.data.last_page <= data.data.current_page) {
          params.page = data.data.last_page;
          this.changeParams(params);
        }
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data.data);
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: Eorders) {
    let lists = [];
    let isChanged: boolean = false;
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result
      if (!lists) {
        lists = [];
      }
      if (lists && lists.length > 0) {
        var foundIndex = lists.findIndex(x => x?._id == item._id);
        if (foundIndex >= 0) {
          lists[foundIndex] = item;
          isChanged = true;
        } else {
          lists = lists.concat([item]);
          isChanged = true;
        };
      } else {
        lists[0] = item;
        isChanged = true;
      }
    });
    if (isChanged) {
      this.changeAllItems(lists);
      isChanged = false;
    }

  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id == id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  getItem(params: any = null) {
    this.routeParams = (params) ? params : this.routeParams;
    var itemId;
    this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
    var id = {
      "id": itemId
    }
    if (itemId > 0) {

      this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          this.changeAllItemsByItem(data.data);
          this.changeItem(data.data);
        },
          error => console.log('Error ::' + error)
        );
    }
    else {
      this.changeItem(new EordersModel({}));
    }
  }

  store(url, data: any) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.concatItem(res.data);
          this.changeItem(res.data);
          this.alert('Success', 'Created Successfully !!!');
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  update(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          var orderData = res.data;
          // orderData.forEach(element => {

          if (orderData.PeopleInfo && orderData.PeopleInfo.id) {
            orderData['name'] = orderData.PeopleInfo.name;
            orderData['ref'] = orderData.PeopleInfo.unique_ref;
            orderData['created_name'] = orderData.PeopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['address']['governorateName'] = governorate.name_en;
                  orderData['governorateName'] = governorate.name_en;
                }
              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }

          if (orderData.regions && orderData.regions.id) {
            orderData['address']['regionName'] = orderData.regions.name_en;
            orderData['regionName'] = orderData.regions.name_en;
          }

          if (orderData.brandInfo && orderData.brandInfo.length > 0) {
            orderData.brandInfo.forEach(brand => {
              brand.clientSite.forEach(brandClient => {
                if (brandClient.client_site_id == orderData.client_site_id) {
                  orderData['address']['country'] = brand.countryInfo.name_en;
                  orderData['address']['brand'] = brand.name;
                  orderData['tax'] = brand.countryInfo.tax;
                }
              });
            });
          }

          // });

          // orderData["status"] = data.status;
          // orderData["account_name"] =res.data.clientSiteInfo.account_name;
          this.changeAllItemsByItem(orderData);
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  addressRequest(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  addressupdate(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          var orderData = res.data;
          if (orderData.PeopleInfo && orderData.PeopleInfo.id) {
            orderData['name'] = orderData.PeopleInfo.name;
            orderData['ref'] = orderData.PeopleInfo.unique_ref;
            orderData['created_name'] = orderData.PeopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['address']['governorateName'] = governorate.name_en;
                  orderData['governorateName'] = governorate.name_en;
                }

              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }

          if (orderData.regions && orderData.regions.id) {
            orderData['address']['regionName'] = orderData.regions.name_en;
            orderData['regionName'] = orderData.regions.name_en;
          }

          if (orderData.brandInfo && orderData.brandInfo.length > 0) {
            orderData.brandInfo.forEach(brand => {
              brand.clientSite.forEach(brandClient => {
                if (brandClient.client_site_id == orderData.client_site_id) {
                  orderData['address']['country'] = brand.countryInfo.name_en;
                  orderData['address']['brand'] = brand.name;
                  orderData['tax'] = brand.countryInfo.tax;
                }
              });
            });
          }

          this.changeAllItemsByItem(orderData);
          this.changeItem(orderData);
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  paymentGenerate(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          // this.paymentGenerateItems.next(res.data)
          this.isOrderLoader.next(false);
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
          this.isOrderLoader.next(false);
          // this.paymentGenerateItems.next(null);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
          this.isOrderLoader.next(false);
        }
      );
  }

  summaryGenerate(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(url, id) {
    this.commonService.storeItem(url, id, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.spliceItem(id.Eorders_id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }


  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.page += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }
  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  // updateCatalog(catalog: any) {
  // 	let allItems = [];
  // 	var isChanged = false;
  // 	this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
  // 	if (allItems.length > 0) {
  // 		allItems.forEach(element => {
  // 			if (element.id == catalog.id) {
  // 				element = catalog;
  // 				isChanged = true;
  // 			}
  // 		});
  // 		if (!isChanged) {
  // 			allItems.push(catalog);
  // 			this.changeAllItems(allItems);
  // 		} else {
  // 			this.changeAllItems(allItems);
  // 		}
  // 	}
  // }


  /** Search Event */
  onSearch(input: string) {
    let newParams: any;
    this.params
      .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.limit = 200;
        newParams.current = 1;
        newParams.search = input;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  getActiveAgent(id: number): void {
    var data = {
      "client_site_id": id
    }
    this.commonService.storeItem('agent/agentsfororder', data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.allActiveSource.next(res.data);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  reassingnOrder(url, data: any): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

}
