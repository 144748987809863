import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
var moment = require('moment-timezone');
import {
	InstagramTicketService, InstagramService,
	InstadmTicketService, InstadmService,
	WhatsappService, WhatsappTicketService,
	WebchatService, WebchatTicketService,
	YoutubeService, YoutubeTicketService,
	MessengerService, MessengerTicketService,
	TweetService, TweetTicketService,
	TweetdmService, TweetdmTicketService,
	CommonService, EventService,
	ChatService, ClosedTicketService, User, UserService, ProductCartService, LeadsHistoryService,
	OpenTicketService,
	OpenTicketsService
} from 'src/app/@tji/_dbShare';

import { OrderByPipe } from 'ngx-pipes';
@UntilDestroy()
@Component({
	selector: 'agent-all-open-ticket',
	templateUrl: './allOpenTicket.component.html',
	styleUrls: ['./allOpenTicket.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [OrderByPipe]
})

export class AgentAllOpenTicketComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() hasNew = new EventEmitter();
	openTickets: Array<any> = [];
	openTicketsData: Array<any> = [];
	base_image: string = this.commonService.logo_image;
	authUser: User;
	ticket: any;
	showTickets: boolean = false;
	eventData: any;
	loader: boolean = true;
	subLoader: boolean = false;
	searchLoader: boolean = true;
	nextPage: boolean = false;

	constructor(public chatService: ChatService,
		public closedTicketService: ClosedTicketService,
		public commonService: CommonService,
		public userService: UserService,
		public eventService: EventService,
		private tweetService: TweetService,
		private tweetTicketService: TweetTicketService,
		private tweetdmService: TweetdmService,
		private tweetdmTicketService: TweetdmTicketService,
		private messengerService: MessengerService,
		private messengerTicketService: MessengerTicketService,
		private whatsappService: WhatsappService,
		private whatsappTicketService: WhatsappTicketService,
		private webchatService: WebchatService,
		private webchatTicketService: WebchatTicketService,
		private youtubeService: YoutubeService,
		private youtubeTicketService: YoutubeTicketService,
		private instagramService: InstagramService,
		private instagramTicketService: InstagramTicketService,
		private instadmService: InstadmService,
		private instadmTicketService: InstadmTicketService,
		private cdRef: ChangeDetectorRef,
		public productCartService: ProductCartService,
		public leadsHistoryService: LeadsHistoryService,
		public openTicketsService: OpenTicketsService,
		private orderByPipe: OrderByPipe,) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.openTicketsService.searchLoader.subscribe(data => {
			this.searchLoader = data;
			this.cdRef.markForCheck();
		});
		this.openTicketsService.libraries.subscribe(data => {
			// this.openTickets = data;
			this.openTickets = this.orderByPipe.transform(data, '-last_message_on');
			this.showTickets = (this.openTickets && this.openTickets.length > 0) ? true : false;
			if (this.openTickets.length < 1) {
				this.hasNew.emit(false);
			}
			// this.loader = false;
			this.cdRef.markForCheck();
		});
		this.openTicketsService.libraries.subscribe(data => this.openTicketsData = data);
		this.openTicketsService.nextPage.subscribe(data => this.nextPage = data);
	}

	ngOnInit() {
		this.getOpenTickets();
		this.checkAllOpenFirstTicket();
		this.doLoader();
	}

	ngAfterViewInit() {
		this.cdRef.detectChanges();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.openTicketsService.changeLibraries([]);
	}

	getOpenTickets() {
		this.openTicketsService.getRecentOpenedTickets();
		// this.chatService.getAllLiveItems();
		// if (this.userService.permissionMatch(['Manage Whatsapp'])) {
		// 	// this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
		// 	this.chatService.getAllTicketsEvent("Whatsapp");
		// }
		// if (this.userService.permissionMatch(['Manage Instadm'])) {
		// 	// this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
		// 	this.chatService.getAllTicketsEvent("Instadm");
		// }
		// if (this.userService.permissionMatch(['Manage Messenger'])) {
		// 	// this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
		// 	this.chatService.getAllTicketsEvent("Messenger");
		// }
		// if (this.userService.permissionMatch(['Manage Instagram'])) {
		// 	// this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
		// 	this.chatService.getAllTicketsEvent("Instagram");
		// }
	}

	doLoader() {
		this.loader = true;
		setTimeout(() => {
			this.loader = false;
			this.cdRef.markForCheck();
		}, 2000);

	}

	setSubLoader() {
		if (this.nextPage) {
			this.subLoader = true;
			setTimeout(() => {
				this.subLoader = false;
				this.cdRef.markForCheck();
			}, 2000);
		}
	}

	onSelectTicket(ticket) {
		this.whatsappService.changeLibraries([]);
		this.instagramService.changeLibraries([]);
		this.messengerService.changeLibraries([]);
		this.instadmService.changeLibraries([]);
		// ticket.unReadCount = null;
		this.chatService.changeTicket(ticket);
		this.productCartService.cartDataSource.next(null);
	}

	getEndTime(ticket) {
		let currentDate = new Date();
		let currentKuwaitDate = new Date(moment(currentDate).tz("Asia/Kuwait").format('YYYY-MM-DD HH:mm:ss'));
		let currentKuwaitDateSec = Math.abs(currentKuwaitDate.getTime());
		let abandonDate = new Date(ticket.abandonAt);
		let abandonDateSec = Math.abs(abandonDate.getTime());
		if (currentKuwaitDateSec && abandonDateSec && currentKuwaitDateSec < abandonDateSec) {
			var diff = (abandonDate.getTime() - currentKuwaitDate.getTime()) / 1000;
			return Math.abs(diff);
		}
		return 0;
	}

	checkAllOpenFirstTicket() {
		this.chatService.ticketLibraries.pipe(untilDestroyed(this)).subscribe(data => {
			let openTickets = data;
			// let openTickets = data.filter(x => {
			// 	if (this.authUser && this.authUser.person && this.authUser.person.is_supervisor && this.authUser.person.supervisoragentids && this.authUser.person.supervisoragentids.length > 0) {
			// 		return ((x.agent_id === this.authUser.person_id || this.authUser.person.supervisoragentids.includes(x.agent_id)) && !x.is_closed) ? true : false;
			// 	} else {
			// 		return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			// 	}
			// });
			if (openTickets && openTickets.length > 0) {
				for (var i = 0; i < openTickets.length; ++i) {
					let item = openTickets[i];
					if (item && !item.is_closed && item.unReadCount > 0) {
						this.hasNew.emit(true);
						break;
					} else {
						this.hasNew.emit(false);
					}
				}
			}
		});
	}

	canShowThisUser(): boolean {
		if (this.authUser && this.authUser.person_type === 'agent' && this.ticket && this.ticket.id) {
			return (this.authUser.person_id === this.ticket.agent_id) ? true : false;
		}
		return false;
	}

	isTicketClosed(): boolean {
		return (this.ticket && this.ticket.id && this.ticket.is_closed) ? true : false;
	}

	checkTicket(ticket): boolean {
		if (this.ticket && this.ticket.id && this.ticket.id === ticket.id && this.ticket.siteName === ticket.siteName) {
			return true;
		}
		return false;
	}

	getIcon(ticket: any): string {
		if (ticket && ticket.id && ticket.siteName) {
			return ticket.siteName.toLowerCase();
		}
		return 'support';
	}

	checkEventData() {
		if ((this.eventData && this.eventData.site && this.userService.isAuthUserIsAgent()) || (this.eventData && this.eventData.site && this.userService.isAuthUserIsSupervisor())) {
			let site: string = this.eventData.site.toLowerCase();
			switch (site) {
				case 'instagram':
				case 'Instagram':
					this.doInstagram();
					break;
				case 'instadm':
				case 'Instadm':
				case 'instaDm':
				case 'InstaDm':
					this.doInstadm();
					break;
				case 'messenger':
				case 'Messenger':
					this.doMessenger();
					break;
				case 'Whatsapp':
				case 'whatsapp':
					this.doWhatsapp();
					break;
				default:
					break;
			}
			// if (this.eventData && this.eventData.event == 'ticketclosed') {
			// 	this.concatItemData(this.eventData.data, this.eventData.site, "aticketclosed");
			// }
			// if (this.eventData && this.eventData.event == 'aticketclosed') {
			// 	this.concatItemData(this.eventData.data, this.eventData.site, "aticketclosed");
			// }
			switch (this.eventData.eventName) {
				case 'newmessage':
				case 'Newmessage':
				case 'aNewmessage':
					if (this.eventData.eventName == 'newmessage' || this.eventData.eventName == 'aNewmessage') {
						var first = "";
						if (this.openTickets && this.openTickets.length > 0) {
							this.openTickets.forEach(ticket => {
								if (ticket.id == this.eventData.data.ticketId) {
									ticket.updated_at = this.eventData.data.updated_at
									if (!ticket.unReadCount) {
										ticket.unReadCount = 0;
									}
									ticket.unReadCount = ticket.unReadCount + 1;
									first = ticket.id;
								}
								if (ticket.id == this.eventData.data.id) {
									ticket.updated_at = moment(this.eventData.data.updated_at + '.000+0300').local()
									if (!ticket.unReadCount) {
										ticket.unReadCount = 0;
									}
									ticket.unReadCount = ticket.unReadCount + 1;
									first = ticket.id;
								}
							});
						}
						var newData = this.openTickets;
						if (this.eventData.site != '"Instadm"') {
							newData.sort(function (x, y) { return x.id == first ? -1 : y.id == first ? 1 : 0; });
							this.chatService.ticketLibrariesSource.next(newData)
							this.cdRef.detectChanges();
						}
						if (this.ticket && this.eventData.data && this.eventData.data && this.ticket.id == this.eventData.data.id) {

							this.chatService.newMessageEvent(this.eventData.data, this.eventData.site);
						}

					}
					break;
				default:
					break;
			}
		}
		if (this.eventData && this.eventData.event && this.eventData.event == 'labelupdate' && this.openTickets && this.openTickets.length > 0) {
			var leadData = this.openTickets.filter(a => a.people_id == this.eventData?.data?.peopleInfo?.id)
			if (leadData && leadData.length > 0) {
				var params = {
					"id": leadData[0].id,
					"client_site_id": leadData[0].clientSiteId
				}
				this.openTicketsService.getItem(params)
			}
		}
	}


	doInstagram() {
		if ((this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instagram' && this.userService.isAuthUserIsAgent()) || (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instagram' && this.userService.isAuthUserIsSupervisor())) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'newmessage':
					let newMsg = this.eventData.data;
					if (newMsg && this.ticket && newMsg.ticketId === this.ticket.id) {
						this.instagramService.changeAllItemsByItem(newMsg);
					}
					break;
				case 'ticketassigned':
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					this.closedTicketService.removelibrary(this.eventData.data);
					break;
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketassigned");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketreassigned");
					break;

				case 'ticketUpdate':
					this.concatItemData(this.eventData.data, this.eventData.site, "ticketUpdate");
					break;

				case 'ticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'aticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					this.concatItemData(this.eventData.data, this.eventData.site, "removeTicketLibrary");
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketclosed':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketclosed");
					if (this.eventData.data && this.eventData.data.agentInfo) {
						this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
						if (this.eventData.data.agentInfo.person_details) {
							this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
						}
					} if (this.eventData.data.client_site_id) {
						this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
					}
					if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
						this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
						this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
						this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
							this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
							this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
							this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
							this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
						}
					}
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketabandon':
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketReply':
					this.chatService.replyEvent(this.eventData.data, this.eventData.notification.siteName);
					break;
				default:
					break;
			}
		}
	}

	doInstadm() {
		if ((this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instadm' && this.userService.isAuthUserIsAgent()) || (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instadm' && this.userService.isAuthUserIsSupervisor())) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'newmessage':
					let newMsg = this.eventData.data;
					if (newMsg && this.ticket && newMsg.ticketId === this.ticket.id) {
						this.instadmService.changeAllItemsByItem(newMsg);
					}
					break;
				case 'ticketassigned':
					this.instadmTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.instadmTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					this.closedTicketService.removelibrary(this.eventData.data);
					break;
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketassigned");
					break;
				case 'ticketUpdate':
					this.concatItemData(this.eventData.data, this.eventData.site, "ticketUpdate");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketreassigned");
					break;
				case 'ticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.instadmTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'aticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.instadmTicketService.spliceItem(this.eventData.data.id);
					this.concatItemData(this.eventData.data, this.eventData.site, "removeTicketLibrary");
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketclosed':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketclosed");
					if (this.eventData.data && this.eventData.data.agentInfo) {
						this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
						if (this.eventData.data.agentInfo.person_details) {
							this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
						}
					} if (this.eventData.data.client_site_id) {
						this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
					}
					if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
						this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
						this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
						this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
							this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
							this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
							this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
							this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
						}
					}
					this.instadmTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					this.instadmTicketService.changeAllItemsByItem(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketabandon':
					this.instadmTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketReply':
					this.chatService.replyEvent(this.eventData.data, this.eventData.notification.siteName);
					break;
				default:
					break;
			}
		}
	}

	doMessenger() {
		if ((this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'messenger' && this.userService.isAuthUserIsAgent()) || (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'messenger' && this.userService.isAuthUserIsSupervisor())) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'newmessage':
					let newMsg = this.eventData.data;
					if (newMsg && this.ticket && newMsg.ticketId === this.ticket.id) {
						this.messengerService.changeAllItemsByItem(newMsg);
					}
					break;
				case 'ticketassigned':
					this.messengerTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.messengerTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					this.closedTicketService.removelibrary(this.eventData.data);
					break;
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketassigned");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketreassigned");
					break;
				case 'ticketUpdate':
					this.concatItemData(this.eventData.data, this.eventData.site, "ticketUpdate");
					break;
				case 'ticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.messengerTicketService.spliceItem(this.eventData.data.id);
					this.concatItemData(this.eventData.data, this.eventData.site, "removeTicketLibrary");
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'aticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.messengerTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketclosed':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketclosed");
					if (this.eventData.data && this.eventData.data.agentInfo) {
						this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
						if (this.eventData.data.agentInfo.person_details) {
							this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
						}
					} if (this.eventData.data.client_site_id) {
						this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
					}
					if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
						this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
						this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
						this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
							this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
							this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
							this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
							this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
						}
					}
					this.messengerTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					this.messengerTicketService.changeAllItemsByItem(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketabandon':
					this.messengerTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketReply':
					this.chatService.replyEvent(this.eventData.data, this.eventData.notification.siteName);
					break;
				default:
					break;
			}
		}
	}

	doWhatsapp() {
		if (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'whatsapp') {
			let event: string = this.eventData.event;
			switch (event) {
				case 'newmessage':
					let newMsg = this.eventData.data;
					if (newMsg && this.ticket && newMsg.ticketId === this.ticket.id) {
						this.whatsappService.changeAllItemsByItem(newMsg);
					}
					break;
				case 'ticketassigned':
					this.whatsappTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.whatsappTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					this.closedTicketService.removelibrary(this.eventData.data);
					break;
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketassigned");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketreassigned");
					break;
				case 'ticketUpdate':
					this.concatItemData(this.eventData.data, this.eventData.site, "ticketUpdate");
					break;
				case 'ticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.whatsappTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'aticketremoved':
					this.openTicketsService.spliceItem(this.eventData.data.id);
					this.whatsappTicketService.spliceItem(this.eventData.data.id);
					this.concatItemData(this.eventData.data, this.eventData.site, "removeTicketLibrary");
					if (this.ticket && this.eventData.data.id == this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketclosed':
					this.concatItemData(this.eventData.data, this.eventData.site, "aticketclosed");
					if (this.eventData.data && this.eventData.data.agentInfo) {
						this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
						if (this.eventData.data.agentInfo.person_details) {
							this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
						}
					} if (this.eventData.data.client_site_id) {
						this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
					}
					if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
						this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
						this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
						this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
							this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
							this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
							this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
							this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
						}
					}
					this.whatsappTicketService.spliceItem(this.eventData.data.id);
					this.chatService.removeTicketLibrary(this.eventData.data);
					this.whatsappTicketService.changeAllItemsByItem(this.eventData.data);
					if (this.ticket && this.eventData.data.id === this.ticket.id) {
						this.chatService.changeTicket(null);
					}
					break;
				case 'ticketabandon':
					this.whatsappTicketService.changeAllItemsByItem(this.eventData.data);
					this.chatService.replaceTicketLibrary(this.eventData.data);
					break;
				case 'ticketReply':
					this.chatService.replyEvent(this.eventData.data, this.eventData.notification.siteName);
					break;
				default:
					break;
			}
		}
	}


	concatItemData(data, siteName, event) {
		var ticketList = data;
		var dataFormat;
		if (ticketList && ticketList.id) {
			var dataFormats = {
				id: ticketList.id,
				ticketId: ticketList.id,
				code: ticketList.code,
				client_id: ticketList.client_id,
				agent_id: ticketList.agent_id,
				priority: ticketList.priority,
				is_active: ticketList.is_active,
				is_closed: ticketList.is_closed ? true : false,
				comment: ticketList.comment,
				people_id: ticketList.people_id,
				created_by: ticketList.created_by,
				updated_by: ticketList.updated_by,
				created_at: moment(ticketList.created_at + '.000+0300').local(),
				updated_at: moment(ticketList.updated_at + '.000+0300').local(),
				

				// last_message_on: moment(ticketList.last_message_on * 1000).format("DD-MMM-yyy-hh:mm a"),
				deleted_at: ticketList.deleted_at,
				is_closeable: ticketList.is_closeable,
				statusChanges: null,
			}

			if (ticketList.last_message_on) {
				dataFormats['last_message_on'] = moment.unix(ticketList.last_message_on).format('YYYY-MM-DD HH:mm:ss');
			} else {
				dataFormats['last_message_on'] = moment(ticketList.updated_at + '.000+0300').local().format('YYYY-MM-DD HH:mm:ss');
				// dataFormats['updated_at'] = moment(ticketList.updated_at + '.000+0300').local();
			}

			if (ticketList.agentInfo) {
				dataFormats["agentName"] = ticketList.agentInfo.name;
				dataFormats["agentAvatar"] = ticketList?.image_api;
				if (ticketList.agentInfo.person_details) {
					dataFormats["agentAvatar"] = ticketList.agentInfo.person_details.image_api;
					dataFormats["createdByAvatar"] = ticketList.agentInfo.person_details.image_api;
					dataFormats["agentLiveStatus"] = ticketList.agentInfo.person_details.agentLiveStatus;
				}
			}

			if (ticketList.clientSite) {
				dataFormats["clientSiteTextColor"] = ticketList.clientSite?.textcolor;
				dataFormats["clientSiteName"] = ticketList.clientSite?.account_name;
				dataFormats["clientSiteId"] = ticketList.clientSite?.id;
				dataFormats["clientSiteColor"] = ticketList.clientSite?.color;
				if (ticketList && ticketList.clientSite && ticketList.clientSite.details) {
					dataFormats["siteName"] = ticketList.clientSite.details?.name;
					dataFormats["siteId"] = ticketList.clientSite.details?.id;
					dataFormats["siteIcon"] = ticketList.clientSite.details?.icon;
					dataFormats["siteColor"] = ticketList.clientSite.details?.color;
					dataFormats["clientSiteColor"] = ticketList.clientSite.details?.color;
				}
			}

			if (ticketList.clientSiteInfo) {
				dataFormats["clientSiteId"] =  ticketList.client_site_id;
				dataFormats["clientSiteTextColor"] =  ticketList?.clientSiteInfo?.textcolor;
				dataFormats["clientSiteName"] =  ticketList?.clientSiteInfo?.name;
				dataFormats["clientSiteColor"] =  ticketList?.clientSiteInfo?.color;
			}

			if (ticketList.siteinfo) {
				dataFormats["siteName"] = ticketList.siteinfo?.name;
				dataFormats["siteIcon"] = ticketList.siteinfo?.icon;
				dataFormats["siteColor"] = ticketList.siteinfo?.color;
			}

			if (ticketList.leadInfo && ticketList.leadInfo.id) {
				dataFormats["peopleName"] = ticketList.leadInfo?.name;
				dataFormats["peopleAvatar"] = ticketList.leadInfo?.peopleAvatar;
				dataFormats["recipientId"] = ticketList.leadInfo?.unique_ref;
				if (ticketList.leadInfo && ticketList.leadInfo.labelInfo) {
					dataFormats["peopleLabelId"] = ticketList.leadInfo.labelInfo?.id;
					dataFormats["peopleLabelName"] = ticketList.leadInfo.labelInfo?.name;
					dataFormats["peopleLabelColor"] = ticketList.leadInfo.labelInfo?.color;
					dataFormats["peopleLabelColorText"] = ticketList.leadInfo.labelInfo?.color_text;
				}
			}
			if (ticketList.leadInfo && ticketList.leadInfo.label_id) {
				dataFormats["peopleName"] = ticketList.leadInfo?.name;
				dataFormats["recipientId"] = ticketList.leadInfo?.unique_ref;
				dataFormats["peopleLabelName"] =  ticketList?.leadInfo?.labelname;
				dataFormats["peopleLabelColor"] =  ticketList?.leadInfo?.color;
				dataFormats["peopleLabelColorText"] =  ticketList?.leadInfo?.color_text;
			}

			

			var statusData = [];
			if (ticketList.ticketStatus && ticketList.ticketStatus.length > 0) {
				let lastStatusData = ticketList.ticketStatus.pop();
				dataFormats["statusName"] = lastStatusData.name;
				dataFormats["className"] = lastStatusData.model_type;
			}

			if (ticketList && ticketList.agents && ticketList.agents.length > 0) {
				var status = ticketList.agents.filter(agent => agent.id == ticketList.agent_id);
				dataFormats["statusName"] = status[0].event;
			}
			
			if (ticketList && ticketList.siteinfo && ticketList.siteinfo.name) {
				var siteName = ticketList.siteinfo.name;
				if (siteName) {
					switch (siteName) {
						case "Twitter": case "twitter":
						case "Tweet": case "tweet":
							dataFormats["ticketId"] = ticketList.tweet_id;
							dataFormats["tweet_id"] = ticketList.tweet_id;
							break;
						case "Twitterdm": case "twitterdm":
						case "Tweetdm": case "tweetdm":
							dataFormats["ticketId"] = ticketList.tweetdm_id;
							dataFormats["tweetdm_id"] = ticketList.tweetdm_id;
							break;
						case "Messenger": case "messenger":
							dataFormats["ticketId"] = ticketList.messenger_id;
							dataFormats["messenger_id"] = ticketList.messenger_id;
							break;
						case "Whatsapp": case "whatsapp":
							dataFormats["ticketId"] = ticketList.whatsapp_id;
							dataFormats["whatsapp_id"] = ticketList.whatsapp_id;
							break;
						case "Webchat": case "webchat":
							dataFormats["ticketId"] = ticketList.webchat_id;
							dataFormats["webchat_id"] = ticketList.webchat_id;
							break;
						case "Youtube": case "youtube":
							dataFormats["ticketId"] = ticketList.youtube_id;
							dataFormats["youtube_id"] = ticketList.youtube_id;
							break;
						case "Instagram": case "instagram":
							dataFormats["ticketId"] = ticketList.instagram_id;
							dataFormats["instagram_id"] = ticketList.instagram_id;
							break;
						case "Instadm": case "instadm":
							dataFormats["ticketId"] = ticketList.instadm_id;
							dataFormats["instadm_id"] = ticketList.instadm_id;
							break;
						default:
							break;
					}
				}
			}
		}

		dataFormat = dataFormats;
		if (siteName) {
			if (event == "aticketclosed") {
				this.openTicketsService.spliceItem(this.eventData.data.id);
				this.closedTicketService.changeItemClosed(dataFormat);
			}
			if (event == "ticketclosed") {
				this.openTicketsService.spliceItem(this.eventData.data.id);
				this.closedTicketService.changeItemClosed(dataFormat);
			}
			switch (siteName) {
				case "Messenger": case "messenger":
					dataFormat["messenger_id"] = ticketList.messenger_id;
					if (event == "aticketassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.messengerTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
					}
					if (event == "aticketreassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.messengerTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
						this.closedTicketService.removelibrary(dataFormat);
					}
					if (event == "removeTicketLibrary") {
						this.chatService.removeTicketLibrary(dataFormat);
					}
					if (event == "ticketUpdate") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
					}
					break;
				case "Whatsapp": case "whatsapp":
					dataFormat["whatsapp_id"] = ticketList.whatsapp_id;
					if (event == "aticketassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.whatsappTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
					}
					if (event == "aticketreassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.whatsappTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
						this.closedTicketService.removelibrary(dataFormat);
					}
					if (event == "removeTicketLibrary") {
						this.chatService.removeTicketLibrary(dataFormat);
					}
					if (event == "ticketUpdate") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
					}
					break;
				case "Instagram": case "instagram":
					dataFormat["instagram_id"] = ticketList.instagram_id;
					if (event == "aticketassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.instagramTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
					}
					if (event == "aticketreassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.instagramTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
						this.closedTicketService.removelibrary(dataFormat);
					}
					if (event == "removeTicketLibrary") {
						this.chatService.removeTicketLibrary(dataFormat);
					}
					if (event == "ticketUpdate") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
					}
					break;
				case "Instadm": case "instadm":
					dataFormat["instagram_id"] = ticketList.instagram_id;
					if (event == "aticketassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.instadmTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
					}
					if (event == "aticketreassigned") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
						this.instadmTicketService.changeAllItemsByItem(dataFormat);
						this.chatService.replaceTicketLibrary(dataFormat);
						this.closedTicketService.removelibrary(dataFormat);
					}
					if (event == "removeTicketLibrary") {
						this.chatService.removeTicketLibrary(dataFormat);
					}
					if (event == "ticketUpdate") {
						this.openTicketsService.changeAllItemsByItem(dataFormat);
					}
					break;
				default:
					break;
			}

		}

	}


	searchTicketData: string = "";
	searchTicketClear() {
		this.searchTicketData = '';
		this.openTicketsService.getRecentOpenedTickets();
		// this.openTickets = this.openTicketsData; //this.orderByPipe.transform(this.openTicketsData, '-last_message_on');
		// this.cdRef.markForCheck();
	}


	/* * * * *
	* search
	* * * * * */
	onSearchTicket() {
		this.showTickets = true;
		var filterData = [];
		var ticketSearchDatas: any = [];
		var filterInteractiveKeys = ['peopleName', 'peopleLabelName', 'siteName', 'recipientId', 'statusName', 'clientSiteName', 'agentName', 'last_message_on']
		ticketSearchDatas = this.openTicketsData;
		if (this.searchTicketData === '') {
			filterData = null;
			ticketSearchDatas = this.openTicketsData;
			this.openTickets = ticketSearchDatas;
		} else {
			if (ticketSearchDatas.length > 0) {
				for (let i = 0; i < ticketSearchDatas.length; i++) {
					if (filterInteractiveKeys.length > 0) {
						filterInteractiveKeys.forEach((key) => {
							if (typeof ticketSearchDatas[i][key] === 'string' && typeof this.searchTicketData === 'string') {
								if (ticketSearchDatas[i][key].toLowerCase().indexOf(this.searchTicketData.toLowerCase()) > -1) {
									const found = filterData.some(el => el.id === ticketSearchDatas[i].id);
									if (!found) {
										filterData.push(ticketSearchDatas[i]);
									}
								}
							}
						});
					} else {
						if (ticketSearchDatas[i].name.toLowerCase().indexOf(this.searchTicketData.toLowerCase()) > -1) {
							filterData.push(ticketSearchDatas[i]);
						}
					}
				}
			}
			this.openTickets = filterData;
			if (this.openTickets && this.openTickets.length < 1) {
				this.showTickets = false;
			}
		}
	}

}
