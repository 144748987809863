export class AgentActivityModel {
	abandon: Array<any>;
	closed: Array<any>;
	reassigned: Array<any>;

	/**
	 * Constructor
	 * @param agentActivity
	 */
	constructor(agentActivity) {
		this.abandon = agentActivity.abandon || [];
		this.closed = agentActivity.closed || [];
		this.reassigned = agentActivity.reassigned || [];
	}
}
