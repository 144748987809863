import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppAccountImportModule } from './appAccount-import.module';
import { AppAccountRouteModule } from './appAccount-route.module';
import { DashboardModule } from 'src/app/appAccount/dashboard/dashboard.module';
import { AgentModule } from 'src/app/appAccount/agent/agent.module';
import { ResellerModule } from 'src/app/appAccount/reseller/reseller.module';
import { ClientModule } from 'src/app/appAccount/client/client.module';
import { PeopleModule } from 'src/app/appAccount/people/people.module';
import { ConsumerModule } from 'src/app/appAccount/consumer/consumer.module';
import { SocialSiteModule } from 'src/app/appAccount/socialSite/socialSite.module';
import { SocialMediaManagementModule } from 'src/app/appAccount/socialMediaManagement/socialMediaManagement.module';
import { ItrsbotModule } from 'src/app/appAccount/itrsbot/itrsbot.module';
import { OpenConversationModule } from 'src/app/appAccount/openConversation/openConversation.module';
import { ClosedConversationModule } from 'src/app/appAccount/closedConversation/closedConversation.module';
import { FindConversationModule } from 'src/app/appAccount/findConversation/findConversation.module';
import { OpenTicketModule } from 'src/app/appAccount/openTicket/openTicket.module';
import { TweetTicketModule } from 'src/app/appAccount/tweetTicket/tweetTicket.module';
import { TweetdmTicketModule } from 'src/app/appAccount/tweetdmTicket/tweetdmTicket.module';
import { MessengerTicketModule } from 'src/app/appAccount/messengerTicket/messengerTicket.module';
import { InstagramTicketModule } from 'src/app/appAccount/instagramTicket/instagramTicket.module';
import { InstadmTicketModule } from 'src/app/appAccount/instadmTicket/instadmTicket.module';
import { WhatsappTicketModule } from 'src/app/appAccount/whatsappTicket/whatsappTicket.module';
import { WebchatTicketModule } from 'src/app/appAccount/webchatTicket/webchatTicket.module';
import { YoutubeTicketModule } from 'src/app/appAccount/youtubeTicket/youtubeTicket.module';
import { AbandonTicketModule } from 'src/app/appAccount/abandonTicket/abandonTicket.module';
import { RolesModule } from 'src/app/appAccount/roles/roles.module';
import { GalleryModule } from 'src/app/appAccount/gallery/gallery.module';
import { ManagePermissionModule } from 'src/app/appAccount/managePermission/managePermission.module';
import { LogModule } from 'src/app/appAccount/log/log.module';
import { FlagModule } from 'src/app/appAccount/flag/flag.module';
import { MenuGroupModule } from './menuGroup/menuGroup.module';
import { ProfileUpdateModule } from './profileUpdate/profileUpdate.module';
import { DiagnosticToolModule } from './diagnosticTool/diagnosticTool.module';
import { CommentModule } from 'src/app/appAccount/comment/comment.module';
import { LabelModule } from 'src/app/appAccount/label/label.module';

//import { MainComponent } from './main/main.component';
//import { ChatComponent } from './chat/chat.component';
//import { ChatTypeComponent } from './chat/chatType/chatType.component';
//import { PeopleFormComponent } from './people/form/form.component';
import { TemplateModule } from './template/template.module';
import { TeamModule } from './team/team.module';

// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
//import { environment } from 'src/environments/environment';
//import { CommonService, UserService } from 'src/app/@tji/_dbShare';
import { TemplateGroupModule } from './template-group/template-group.module';
import { ProductCatalogModule } from './product-catalog/product-catalog.module';
import { ProductSettingsModule } from './productSettings/product-settings.module';
import { AiCollectionModule } from './aiCollection/aiCollection.module';
import { FbTemplateModule } from './fbTemplate/fb-template.module';

import { CatalogModule } from './catalog/catalog.module';
import { BrandModule } from './brand/brand.module';
let LocalToken: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;
// let socketConfig = { url: environment.socketUrl,
// 	options: { transports: environment.socketTransports,
//     		query: { 'token' : 'almulla-media' }
//     	}
//     };

@NgModule({
    declarations: [],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        AppAccountImportModule,
        AppAccountRouteModule,
        DashboardModule,
        AgentModule,
        ResellerModule,
        ClientModule,
        PeopleModule,
        ConsumerModule,
        SocialSiteModule,
        SocialMediaManagementModule,
        ItrsbotModule,
        OpenConversationModule,
        ClosedConversationModule,
        FindConversationModule,
        OpenTicketModule,
        TweetTicketModule,
        TweetdmTicketModule,
        MessengerTicketModule,
        InstagramTicketModule,
        InstadmTicketModule,
        WhatsappTicketModule,
        WebchatTicketModule,
        YoutubeTicketModule,
        AbandonTicketModule,
        RolesModule,
        GalleryModule,
        LogModule,
        FlagModule,
        CommentModule,
        TemplateModule,
        TeamModule,
        LabelModule,
        ManagePermissionModule,
        MenuGroupModule,
        ProfileUpdateModule,
        DiagnosticToolModule,
        TemplateGroupModule,
        ProductCatalogModule,
        ProductSettingsModule,
        AiCollectionModule,
        CatalogModule,
        FbTemplateModule,
        BrandModule
        // SocketIoModule.forRoot(socketConfig),
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        DashboardModule,
        AgentModule,
        ResellerModule,
        ClientModule,
        PeopleModule,
        ConsumerModule,
        SocialSiteModule,
        SocialMediaManagementModule,
        ItrsbotModule,
        OpenConversationModule,
        ClosedConversationModule,
        FindConversationModule,
        OpenTicketModule,
        TweetTicketModule,
        TweetdmTicketModule,
        MessengerTicketModule,
        InstagramTicketModule,
        InstadmTicketModule,
        WhatsappTicketModule,
        WebchatTicketModule,
        YoutubeTicketModule,
        AbandonTicketModule,
        RolesModule,
        GalleryModule,
        LogModule,
        FlagModule,
        CommentModule,
        TemplateModule,
        TeamModule,
        LabelModule,
        ManagePermissionModule,
        MenuGroupModule,
        ProductCatalogModule,
        ProductSettingsModule,
        AiCollectionModule,
        CatalogModule,
        FbTemplateModule,
        BrandModule
        // SocketIoModule,
    ],
    providers: []
})

export class AppAccountModule { }


