export class FirebaseModel {
	body        : string;
	channel     : string;
	event       : string;
	icon        : string;
	faIcon      : string;
	is_triggered: boolean;
	text        : string;
	heading     : string;
	title       : string;
	userId      : number;
	site        : string;
	image?      : string;
	data?       : any;

	/**
	 * Constructor
	 *
	 * @param firebase
	 */
	constructor(firebase) {
		this.body        = firebase.body || null;
		this.channel     = firebase.channel || null;
		this.event       = firebase.event || null;
		this.icon        = firebase.icon || null;
		this.faIcon      = firebase.faIcon || null;
		this.is_triggered= firebase.is_triggered || null;
		this.text        = firebase.text || null;
		this.heading     = firebase.heading || null;
		this.title       = firebase.title || null;
		this.userId      = firebase.userId || null;
		this.site        = firebase.site || null;
		this.image       = firebase.image || null;
		this.data        = firebase.data || null;
	}
}
