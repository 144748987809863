import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import {
	User, UserService,
	TemplateMessageService, TemplateMessage, TemplateMessageModel,
	TemplateUsageService, TemplateUsage, DateModel, TemplateSuccessService, TemplateFailedService
} from 'src/app/@tji/_dbShare';

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

@UntilDestroy()
@Component({
	selector: 'template-history-list',
	templateUrl: './template-history.component.html',
	styleUrls: ['./template-history.component.scss'],
	providers: [DatePipe,
		// { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		// { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
	],
})

export class TemplateHistoryComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	template: TemplateMessage;
	templateUsageLists: TemplateUsage[] = [];
	selectedTemplateUsage: TemplateUsage = null;
	param: any;
	dataSource = new MatTableDataSource(this.templateUsageLists);
	displayedColumns = ['date', 'code', 'success', 'failed', 'rejected', 'total', 'status'];
	totalCount: number = 0;
	successCount: number = 0;
	failedCount: number = 0;
	rejectedCount: number = 0;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	campaignOne: UntypedFormGroup;
	fromDate: Date;
	toDate: Date;
	today: Date = new Date();
	minDate: Date = new Date(2021, 0, 1);
	maxDate: Date = new Date();
	subLoader: boolean = false;
	status: any;
	tempSuccessParam: any;
	templateFailedParam: any;
	constructor(public templateMessageService: TemplateMessageService,
		public templateUsageService: TemplateUsageService,
		public templateSuccessService: TemplateSuccessService, public templateFailedService: TemplateFailedService,
		private router: Router,
		public location: Location,
		private datepipe: DatePipe) {
		this.templateSuccessService.params.pipe(untilDestroyed(this)).subscribe(data => this.tempSuccessParam = data);
		this.templateFailedService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateFailedParam = data);
		this.templateUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.templateUsageService.totalCount.pipe(untilDestroyed(this)).subscribe(data => this.totalCount = data);
		this.templateUsageService.successCount.pipe(untilDestroyed(this)).subscribe(data => this.successCount = data);
		
		this.templateUsageService.failedCount.pipe(untilDestroyed(this)).subscribe(data => this.failedCount = data);
		this.templateUsageService.rejectedCount.pipe(untilDestroyed(this)).subscribe(data => this.rejectedCount = data);
		this.templateUsageService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.templateUsageLists) { this.subLoader = false; }
			this.templateUsageLists = data;
			this.dataSource = new MatTableDataSource(this.templateUsageLists);
			this.dataSource.sort = this.sort;
		});
		this.templateUsageService.item.pipe(untilDestroyed(this)).subscribe(data => this.selectedTemplateUsage = data);
		this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
		});
		this.dataSource.sort = this.sort;
		this.getDates();
	}

	ngOnInit() {
		this.doLoader();
		this.templateUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.templateUsageService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.templateMessageService.unSubscribe();
		this.templateUsageService.unSubscribe();
		this.templateUsageService.changeAllItems(null);
		this.templateUsageService.changeTotalCount(0);
		this.templateUsageService.changeSuccessCount(0);
		this.templateUsageService.changeFailedCount(0);
		this.templateFailedService.resetParams();
		this.templateSuccessService.resetParams();
		this.templateUsageService.resetParams();
		
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	getTitle(): string {
		let title: string = 'Template History';
		if (this.template && this.template.id) {
			title = this.template.code + ' | ' + this.template.name;
		}
		return title;
	}

	getDates() {
		let date = new Date();
		this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
		this.toDate = this.today;
		this.campaignOne = new UntypedFormGroup({
			start: new UntypedFormControl(this.fromDate),
			end: new UntypedFormControl(this.toDate)
		});
	}

	onChangeFromDate(event) {
		this.fromDate = event.target.value;
		let maxToDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth(), this.fromDate.getDay() + 31);
		maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
		this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
		this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;
	}

	onChangeToDate(event) {
		this.toDate = event.target.value;
		let maxToDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth(), this.fromDate.getDay() + 31);
		maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
		this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
		this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;
		setTimeout(() => {
			this.getTemplateUsages();
		}, 100)
	}

	getTemplateUsages() {
		this.param.from_date = this.datepipe.transform(this.fromDate, 'dd-MM-yyyy');
		this.param.to_date = this.datepipe.transform(this.toDate, 'dd-MM-yyyy');
		this.templateUsageService.getAllItems();
	}

	viewHistory(temp, status){
		this.templateUsageService.changeItem(null);
		this.templateSuccessService.changeAllItems(null);
		this.templateFailedService.changeAllItems(null);
		this.status = status;
		this.templateFailedService.isSubLoader.next(false);
		this.templateSuccessService.isSubLoader.next(false);
		this.templateFailedService.isLoader.next(false);
		this.templateSuccessService.isLoader.next(false);
		this.templateUsageService.changeItem(temp)
		if (status == 'sent') {
			this.tempSuccessParam.template_usage_id = temp.id;
			this.tempSuccessParam.limit = 50;
			delete this.tempSuccessParam.search;
			this.tempSuccessParam.current = 1;
			this.templateSuccessService.getAllItems();
		}
		if (status == 'failed') {
			this.templateFailedParam.template_usage_id = temp.id
			this.templateFailedParam.limit = 50;
			delete this.templateFailedParam.search;
			this.templateFailedParam.current = 1;
			this.templateFailedService.getAllItems();
		}
	}
}
