export class IntractiveMessageModel {
    id:number;
    code: string;
    client_id: number;
    client_site_id: number;
    name: string;
    status:string;
    json:string;
    required_values?:Array<any>;

    jsonData?: Array<any>;
    siteName?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    whatsappData?: any;

    /**
     * Constructor
     *
     * @param intractiveMessage
     */
    constructor(intractiveMessage) {
            this.id = intractiveMessage.id || '';
            this.code = intractiveMessage.code || '';
            this.client_id = intractiveMessage.client_id || '';
            this.client_site_id = intractiveMessage.client_site_id || '';
            this.name = intractiveMessage.name || '';
            this.status = intractiveMessage.status || '';
            this.json = intractiveMessage.json || '';
            this.required_values = intractiveMessage.required_values || '';
            this.whatsappData = intractiveMessage.whatsappData || '';
    }
}
