import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { FirebaseJobService } from 'src/app/@tji/_dbShare/alert/firebaseJob/firebaseJob.service';
import { RTemplateMessage } from './rTemplateMessage.interface';
import { RTemplateMessageModel } from './rTemplateMessage_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import * as moment from 'moment-timezone';
declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class RTemplateMessageService implements Resolve<any> {
	url: string = 'reseller/template/templateList';
	urlItrs: string = 'Intractive/list'
	urlTemplateItrs: string = 'template/update'
	routeParams: any;
	// templateStatus: boolean = false;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<RTemplateMessage[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<RTemplateMessage>(new RTemplateMessageModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<RTemplateMessage[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private completedCountsSource = new BehaviorSubject<number>(0);
	completedCounts = this.completedCountsSource.asObservable();

	private linkItrs = new BehaviorSubject<any>([]);
	currentItrs = this.linkItrs.asObservable()

	private itrsListData = new BehaviorSubject<any>([]);
	currentAllItrs = this.itrsListData.asObservable()

	private itrsDataScurce = new BehaviorSubject<any>([]);
	currentItrsDataScurce = this.itrsDataScurce.asObservable()

	public bulkTemSend = new BehaviorSubject<Array<any>>([]);
	temSend = this.bulkTemSend.asObservable();

	public templateViewsidebar = new BehaviorSubject<boolean>(false);
	templateView = this.templateViewsidebar.asObservable();

	public apiTemplateViewsidebar = new BehaviorSubject<boolean>(false);
	apiTemplateView = this.apiTemplateViewsidebar.asObservable();

	public getMediaIdDetails = new BehaviorSubject<Array<any>>([]);
	getMediaIdData = this.getMediaIdDetails.asObservable();

	public getInitDetails = new BehaviorSubject<Array<any>>([]);
	getInit = this.getInitDetails.asObservable();

	public isLoader = new BehaviorSubject<boolean>(false);
	loader = this.isLoader.asObservable();
	
	public templateStatusUpdate = new BehaviorSubject<boolean>(false);
	templateStatus = this.templateStatusUpdate.asObservable();

	// public isTemplateViewLoader = new BehaviorSubject<boolean>(false);
	// templateViewLoader = this.isTemplateViewLoader.asObservable();

	public allITicketSource = new BehaviorSubject<any>([]);
	allTicket = this.allITicketSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private firebaseJobService: FirebaseJobService,) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				// this.getAllInit(),
				// this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TemplateMessageService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TemplateMessageService');
	}
	concatlibrary(allItems: RTemplateMessage[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: RTemplateMessage) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: RTemplateMessage) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: RTemplateMessage[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: RTemplateMessage[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: any) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeCompletedCounts(total: number) {
		this.completedCountsSource.next(total);
	}

	changeDisplayItems(displayItems: RTemplateMessage[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changelinkItrs(itrs: any) {
		this.linkItrs.next(itrs)
	}

	changeItrsData(itrs: any) {
		this.itrsListData.next(itrs);
	}

	changeItrsDataScurce(itrs: any) {
		this.itrsDataScurce.next(itrs);
	}

	changeAllItemsByItem(item: any) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i]._id == item._id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var templateData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (templateData && templateData.length > 0) {
						templateData.forEach(tepm => {
							var templateView = {}
							var dataFormats = {
								_id: tepm._id,
								id: tepm.id,
								template_id: tepm.id,
								waba_id: tepm.waba_id,
								client_site_id: tepm.client_site_id,
								client_id: tepm.client_id,
								name: tepm.name,
								category: tepm.category,
								allow_category_change: tepm.allow_category_change,
								language: tepm.language,
								components: tepm.components,
								created_at: tepm.created_at,
								updated_at: tepm.updated_at,
								status: tepm.status.toLowerCase(),
								required_values: tepm.required_values,
								siteName: tepm.siteName,
								clientSiteName: tepm.clientSiteName,
								clientSiteColor: tepm.clientSiteColor,
								clientSiteTextColor: tepm.clientSiteTextColor,
								business_id: tepm.business_id,
								rejected_reason: tepm.rejected_reason ? tepm.rejected_reason : '',
								description: null
							}

							if (additionalData && additionalData.Csites && additionalData.Csites.length > 0) {
								additionalData.Csites.forEach(clientSiteInfo => {
									if (tepm.client_site_id == clientSiteInfo.id) {
										dataFormats["siteName"] = clientSiteInfo.siteName;
										dataFormats["clientSiteName"] = clientSiteInfo.account_name;
										dataFormats["clientSiteColor"] = clientSiteInfo.color;
										dataFormats["clientSiteTextColor"] = clientSiteInfo.textcolor;
									}
								});
							}

							if (additionalData && additionalData.clientInfo && additionalData.clientInfo.length > 0) {
								additionalData.clientInfo.forEach(clientInfo => {
									if (tepm.client_id == clientInfo.id) {
										dataFormats["clientName"] = clientInfo.name;
									}
								});
							}

							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
					
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllTicketItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("template/number", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var templateData = data.data;
					var templateInfo = data.additional.templateInfo;
					var dataFormat = [];
					if (templateData && templateData.length > 0) {
						templateData.forEach(tepm => {
							var dataFormats = {
								id: tepm.id,
								template_id: tepm.template_id,
								created_at: moment(tepm.created_at + '.000+0300').local(),
							}
							
							if (templateInfo && templateInfo.length > 0) {
								templateInfo.forEach(tempInfo => {
									if (tempInfo.id == tepm.template_id) {
										dataFormats["name"] = tempInfo.name;
										dataFormats["category"] = tempInfo.category;
										dataFormats["category"] = tempInfo.category;
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.allITicketSource.next(dataFormat);
					} else {
						this.allITicketSource.next(null);
					}
					
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllInit(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("RTemplate/init", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.getInitDetails.next(data.data)
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: RTemplateMessage) {
		this.replacelibrary(item);
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		this.removelibraryById(id);
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i]._id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params?.id : this.routeParams;

		if (this.routeParams && this.routeParams > 0) {
			// this.isTemplateViewLoader.next(true);
			this.commonService.storeItem('template/view', { id: this.routeParams }, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success && data.data.id) {

						var template = data.data;
						var templateView = {}
						
					} else {
						// this.isTemplateViewLoader.next(false);
					}

				},
					error => { console.log('Error ::' + error); }
				);
		}
		else {
			this.changeItem(new RTemplateMessageModel({}));
		}
	}


	reGenerateToken(url: any = null, params: any = null) {
		this.commonService.storeItem(url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success && data.data.id) {
					var template = data.data;

					var templateData = {
						_id: template._id,
						id: template.id,
						waba_id: template.waba_id,
						client_site_id: template.client_site_id,
						client_id: template.client_id,
						name: template.name,
						category: template.category,
						allow_category_change: template.allow_category_change,
						language: template.language,
						components: template.components,
						created_at: template.created_at,
						updated_at: template.updated_at,
						status: template.status.toLowerCase(),
						required_values: template.required_values,
						siteName: template.siteName,
						clientSiteName: template.clientSiteName,
						clientSiteColor: template.clientSiteColor,
						clientSiteTextColor: template.clientSiteTextColor,
						business_id: template.business_id,
						rejected_reason: template.rejected_reason ? template.rejected_reason : ''
					}

					if (template.clientSiteInfo) {
						templateData["siteName"] = template.clientSiteInfo.details.name;
						templateData["clientSiteName"] = template.clientSiteInfo.account_name;
						templateData["clientSiteColor"] = template.clientSiteInfo.color;
						templateData["clientSiteTextColor"] = template.clientSiteInfo.textcolor;
						if (template.clientSiteInfo.mongo && template.clientSiteInfo.mongo.wabaFullInfo) {
							templateData["waba_name"] = template.clientSiteInfo.mongo.wabaFullInfo.name ? template.clientSiteInfo.mongo.wabaFullInfo.name : '--'
						}
						if (template.clientSiteInfo.mongo && template.clientSiteInfo.mongo.business_info) {
							templateData["business_name"] = template.clientSiteInfo.mongo.business_info.name ? template.clientSiteInfo.mongo.business_info.name : '--'
						}

					}

					if (template && template.TokenInfo && template.TokenInfo.token) {
						var dataPost = {
							// template_id: template.id,
							token: template.TokenInfo.token,
							required: template.required_values,
							isToken : true,
						}

						templateData["postData"] = dataPost;
					}

					if (template.components && template.components.length > 0) {
						template.components.forEach(component => {
							if (component.type.toLowerCase() == 'carousel') {
								templateData['isCarousel'] = true;
							}
						});
					}

					this.changeAllItemsByItem(templateData);
					this.changeItem(templateData);
					this.alert('Success', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}

			},
				error => { console.log('Error ::' + error); }
			);
	}

	sync() {
		var temp = {}
		this.commonService.storeItem('RTemplate/syncTemplate', temp, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.resetParams();
					this.getAllItems();
					this.alert('Success', 'Sync Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	store(data: any) {
		this.isLoader.next(true);
		this.commonService.storeItem("RTemplate/add", data, true, "optionOne")
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alert('Success', 'Created Successfully !!!');
					this.isLoader.next(false);
					this.templateStatusUpdate.next(true);
				} else {
					this.isLoader.next(false);
					this.templateStatusUpdate.next(false);
					setTimeout(() => {
						this.alertService.webErrorShow(data);
					}, );
				}
			},
				error => {
					this.isLoader.next(false);
					this.templateStatusUpdate.next(false);
					console.log('Error ::' + error)
					this.alert('Danger', error);
				}
			);
	}

	// getMediaId(url, data) {
	// 	this.commonService.storeItem(url, data, true, 'optionOne')
	// 		.pipe(untilDestroyed(this, 'unSubscribe'))
	// 		.subscribe(data => {
	// 			if (data.success) {
	// 				this.getMediaIdDetails.next(data)
	// 			} else {
	// 				this.alertService.webErrorShow(data);
	// 			}
	// 		},
	// 			error => console.log('Error ::' + error)
	// 		);
	// }

	updateTemplate(data: any) {
		var template: any = {};
		this.currentItrsDataScurce.subscribe(data => template = data)
		this.commonService.storeItem("template/update", data, true, "optionOne")
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				template.templateId = data.data.id;
				if (template.templateId && template.itrsId) {
					this.createIntractiItem(template, 'optionOne');
				}
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					console.log('Error ::' + error)
					this.alert('Danger', error);
				}
			);
	}

	update(id: number, data: any) {
		var template: any = {};
		this.currentItrsDataScurce.subscribe(data => template = data)
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				template.templateId = data.data.id;
				if (template.templateId && template.itrsId) {
					this.createIntractiItem(template, 'optionOne');
				}
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					console.log('Error ::' + error)
					this.alert('Danger', error);
				}
			);
	}

	destroy(url, temp) {
		this.commonService.storeItem(url, temp, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(temp.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getByUrl(params: any = null, url = null) {
		params = this.paramsInit(params);
		let newUrl: string = (url) ? url : this.url;
		this.commonService.getAll(newUrl, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItems(data.data);
				// this.changeTotalItem(data.data.length);

			},
				error => console.log('Error ::' + error)
			);
	}

	postUrl(data: any, url = null) {
		url = (url) ? url : this.url;
		let showAlert: boolean = (data && data.showAlert) ? data.showAlert : false;
		this.commonService.storeItem(url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data && data.data) {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				}
				if (data.jobcode) {
					this.firebaseJobService.changeJobcode(data.jobcode);
				}
				if (showAlert) {
					this.alert('Info', 'Message Sent Successfully');
				}
				// this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	markAsRead(id, url = null) {
		let newUrl: string = (url) ? url : 'mark-read';
		this.commonService.getAll(newUrl + '/' + id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
			},
				error => console.log('Error ::' + error)
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getCompletedCounts(params: any = null) {
		let url = 'check-queue-status';
		this.commonService.getAll(url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeCompletedCounts(data.data.remaining_count);
			},
				error => console.log('Error ::' + error)
			);
	}

	getIntractiItem(data: any = null, urlOption: any = null) {
		this.commonService.getItrsData(this.urlItrs, data, urlOption)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				var intractive = data.data;
				var additionalData = data.additional;
				if (intractive && intractive.length > 0) {
					intractive.forEach(intractive => {
						additionalData.forEach(category => {
							if (intractive.intractive_category_id == category.id) {
								intractive.resource_id = parseInt(intractive.resource_id);
								intractive['intractive_category'] = category;
							}
						});
					});
					this.changeItrsData(intractive);
				} else {
					this.changeItrsData([]);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', error);
				}
			);
	}
	createIntractiItem(data: any = null, urlOption: any = null) {
		this.commonService.getItrsData(this.urlTemplateItrs, data, urlOption)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.alert('Info', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', error);
				}
			);
	}

	sendTemplate(data): void {
		this.commonService.storeItem('template/send', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					// this.concatItem(res.data);
					// this.changeItem(res.data);
					var data = [{
						jobcode: res.data.jobcode,
						tempSent: true,
					}]
					this.bulkTemSend.next(data);
					// this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	sendSingleTemplate(data): void {
		this.commonService.storeItem('template/send', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.alert('Success', 'Template Send Successfully !!!');
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

}
