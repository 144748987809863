import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	PeopleService, People, PeopleModel,
	ChatService, CommonService
} from 'src/app/@tji/_dbShare';
import { DynamicFormComponent } from 'src/app/library/dynamic-form/dynamic-form/dynamic-form.component';

@UntilDestroy()
@Component({
	selector: 'agent-ticket-person',
	templateUrl: './personSidenav.component.html',
	styleUrls: ['./personSidenav.component.scss']
})

export class PersonSideNavComponent implements OnInit, OnDestroy {
	@ViewChild(DynamicFormComponent) peopleFormData: DynamicFormComponent;
	@Output() onClose = new EventEmitter();
	user: User;
	ticket: any;
	people: People;
	baseImage: string = this.commonService.logo_image;
	selectedPeople: People;
	editPerson: boolean = false;

	constructor(public userService: UserService,
		public chatService: ChatService,
		public peopleService: PeopleService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => this.people = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		
	}

	ngOnInit() { 
	this.getPeople();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getPeople() {
		if (this.ticket && this.ticket.people_id) {
			this.peopleService.getItem({ 'id': this.ticket.people_id });
		}
	}

	onEditPerson() {
		if (this.editPerson) {
			this.savePeopleData();
		}
		this.editPerson = !this.editPerson;
	}

	savePeopleData() {
		delete this.people.created_at;
		delete this.people.updated_at;
		delete this.people?.detail?.created_at
		delete this.people?.detail?.updated_at

		Object.entries(this.peopleFormData.form.value).forEach(([key, value]) => {
			this.people.detail.forEach(people => {
				if (key == people.slug) {
					people.value = value	
				}
			});
		});
		this.peopleFormData.form.value["people_id"] = this.people.id;
		this.peopleService.updatePeple(this.people.id, this.peopleFormData.form.value);
	}
}
