import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive,
	IntractiveGroupService, IntractiveGroup, IntractiveMessageService
} from 'src/app/@tji/_dbShare';

import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-chooseintractiveform',
	templateUrl: './chooseIntractiveForm.component.html',
	styleUrls: ['./chooseIntractiveForm.component.scss']
})

export class ChooseIntractiveFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	itrsbot: Itrsbot;
	intractives: Intractive[] = [];
	intractiveGroup: IntractiveGroup;
	action: string;
	chooseIntractiveForm: UntypedFormGroup;


	selectInteractive = new UntypedFormControl();
	filteredStates: Observable<Intractive[]>;
	displayStates: Intractive[] = [];
	separatorKeysCodes: number[] = [ENTER, COMMA];
	nextIntractiveId: any = null;
	nextIntractiveIdButton : boolean = true;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveMessageService: IntractiveMessageService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<ChooseIntractiveFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.intractiveService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractives = data;
		});
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.getInit(_data);
			this.chooseIntractiveForm = this.createForm();
		});
		this.filteredStates = this.selectInteractive.valueChanges
			.pipe(
				startWith(''),
				map(state => state ? this._filterStates(state) : this.intractives.slice())
			);
		if (this.intractiveGroup && this.intractiveGroup.next_intractive_id && this.intractives) {
			this.intractives.forEach(element => {
				if (element.id == this.intractiveGroup.next_intractive_id) {
					this.nextIntractiveId = element.name;
				}
			});
		}
	}

	private _filterStates(value: string) {
		const filterValue = value.toLowerCase();
		return this.intractives.filter(state => {
			if (state.name.toLowerCase().indexOf(filterValue) === 0) {
				return state;
			}
		});
	}

	openPanel(trigger) {
		trigger.closePanel();
		trigger.openPanel()
	}

	isSelected: boolean = false;
	selectOption(data) {
		this.nextIntractiveIdButton = false;
		this.intractives.filter((state: Intractive) => {
			if (state.name === data.option.value) {
				this.isSelected = true;
			} else {
				this.isSelected = false;
			}
			if (this.isSelected) {
				this.nextIntractiveIdButton = false;
				this.chooseIntractiveForm.controls['next_intractive_id'].setValue(state.id);
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		let intractive = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Link Next Intractive';
				break;
			case 'edit':
				this.dialogTitle = 'Change Next Intractive';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			next_intractive_id: [this.intractiveGroup.next_intractive_id || null, [Validators.required]],
			is_active: [1],
		});
	}


}
