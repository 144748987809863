import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, ImageService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel,
	ConsumerService, Consumer, ConsumerModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'consumer-item-consumer',
	templateUrl: './consumer.component.html',
	styleUrls: ['./consumer.component.scss']
})

export class ConsumerComponent implements OnInit, OnDestroy {
	authUser: User;
	@Input() consumerGroup: ConsumerGroup = null;
	@Output() onSelectedGroup = new EventEmitter();
	consumer: Consumer;
	baseImage: string = 'assets/img/no-user.jpg';
	loader: boolean = false;
	
	constructor(public userService: UserService,
		public consumerService: ConsumerService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.consumerService.item.pipe(untilDestroyed(this)).subscribe(data => this.consumer = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	storeImage(event) { }

}
