import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { ClosedConversationRouteModule } from './closedConversation-route.module';

import { MainComponent } from './main/main.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { WebchatComponent } from './webchat/webchat.component';
import { MessengerComponent } from './messenger/messenger.component';
import { TweetComponent } from './twitter/twitter.component';
import { TweetdmComponent } from './twitterdm/twitterdm.component';
import { InstagramComponent } from './instagram/instagram.component';
import { InstadmComponent } from './instadm/instadm.component';
import { YoutubeComponent } from './youtube/youtube.component';

import { ConversationHistoryComponent } from './conversationHistory/conversationHistory.component';
import { ConversationInstagramHistoryComponent } from './conversationInstagramHistory/conversationInstagramHistory.component';
import { ConversationYoutubeHistoryComponent } from './conversationYoutubeHistory/conversationYoutubeHistory.component';

import { WhatsappListService, MessengerListService, 
		 InstagramListService, InstadmListService, 
		 TweetListService, TweetdmListService, 
		 YoutubeListService, WebchatListService
		} from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        WhatsappComponent,
        WebchatComponent,
        MessengerComponent,
        TweetComponent,
        TweetdmComponent,
        InstagramComponent,
        InstadmComponent,
        YoutubeComponent,
        ConversationHistoryComponent,
        ConversationInstagramHistoryComponent,
        ConversationYoutubeHistoryComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        ClosedConversationRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
    ],
    providers: [
        WhatsappListService,
        WebchatListService,
        MessengerListService,
        InstagramListService,
        InstadmListService,
        TweetListService,
        TweetdmListService,
        YoutubeListService
    ]
})

export class ClosedConversationModule { }
