import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { UserService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'template-group-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {

	constructor(public userService: UserService,
				private router: Router,
				public location: Location,
				@Inject(DOCUMENT) private document: Document) {
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

}
