export interface AutoTemplate {
    _id: number;
    name: string;
    catalog_id: number;
    client_id: number;
    clientSiteInfo?: Array<any>;
    products?: Array<any>;
    total_products: number;
    total_allowed: number;
    catalogName: string;
}
