export class ProductModel {
    _id: number;
    client_id: number;
    client_site_id: number;
    title: string;
    price: number;
    currency_code: string;
    group_id: any;
    image: string;
    content_id: any;
    created_at: any;
    __v: any;

    /**
     * Constructor
     *
     * @param product
     */
    constructor(product) {
        this._id = product._id || '';
        this.client_id = product.client_id || '';
        this.client_site_id = product.client_site_id || '';
        this.title = product.title || '';
        this.price = product.price || '';
        this.currency_code = product.currency_code || '';
        this.group_id = product.group_id || '';
        this.image = product.image || '';
        this.content_id = product.content_id || '';
        this.created_at = product.created_at || '';
        this.__v = product.__v || '';
        
    }
}
