import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
declare let $: any;
import { environment } from 'src/environments/environment';
import { PremiumDashboardService, PremiumDashboard, PremiumAlert, EventService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'app-payments-count',
    templateUrl: './payments-count.component.html',
    styleUrls: ['./payments-count.component.scss'],
    animations: tjiAnimation,
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class PaymentsCountComponent {

    paymentsCounts: Array<any> = [];
    dashboardParam: any;
    animState = false;
    channel: any;
    eventData: any;

    constructor(private router: Router,
        private eventService: EventService,
        public premiumDashboardService: PremiumDashboardService) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
        this.premiumDashboardService.paymentsCounts.pipe(untilDestroyed(this)).subscribe(data => this.paymentsCounts = data);
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit() { }

    animStateDone() { this.animState = !this.animState; }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'paymentCreate':
                    this.premiumDashboardService.changePaymentCount(data, 'paymentCreate');
                    break;
                case 'paymentUpdate':
                    this.premiumDashboardService.changePaymentCount(data, 'paymentUpdate');
                    break;
                default:
                    break;
            }
        }
    }
}
