import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';
import { ReassignFormComponent } from './../reassignForm/reassignForm.component';

import { ConversationHistoryComponent } from 'src/app/appAccount/openConversation//conversationHistory/conversationHistory.component';

import {
	EventService,
	User, UserService, CommonService,
	WebchatService, Webchat,
	WebchatTicketService, WebchatTicket,
	TicketActivityService, TicketActivity,
	ChatService, LeadsHistoryService, LeadsHistory,
	CommentService, Comment
} from 'src/app/@tji/_dbShare';
import { tick } from '@angular/core/testing';

@UntilDestroy()
@Component({
	selector: 'webchat-ticket-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	@Input() viewMode: string = "open";
	routeParam: string = null;
	loader: boolean = true;
	subLoader: boolean = false;
	baseImage: string = this.commonService.logo_image;
	authUser: User;
	ticketsAll: WebchatTicket[] = [];
	tickets: WebchatTicket[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.tickets);
	displayedColumns = ['date', 'avatar', 'content', 'webchat', 'agentavatar', 'agent', 'priority', 'action'];
	dataLength: number = 0;
	leadsHistoryParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	eventData: any;

	constructor(public userService: UserService,
		public eventService: EventService,
		public webchatService: WebchatService,
		public webchatTicketService: WebchatTicketService,
		public ticketActivityService: TicketActivityService,
		public leadsHistoryService: LeadsHistoryService,
		public commentService: CommentService,
		public commonService: CommonService,
		public chatService: ChatService,
		private router: Router,
		private route: ActivatedRoute,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);
		this.webchatTicketService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.ticketsAll) { this.subLoader = false; }
			this.ticketsAll = data;
			if (this.viewMode && this.viewMode === 'closed') {
				this.tickets = this.orderByPipe.transform(data, '-id').filter(x => {
					return (x.is_closed) ? true : false;
				});
				this.dataSource = new MatTableDataSource(this.tickets);
				this.dataSource.sort = this.sort;
			} else {
				this.tickets = this.orderByPipe.transform(data, '-id').filter(x => {
					return (!x.is_closed) ? true : false;
				});
				this.dataSource = new MatTableDataSource(this.tickets);
				this.dataSource.sort = this.sort;
			}
		});
	}

	ngOnInit() {
		this.getRouteParams();
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.webchatTicketService.unSubscribeFilter();
		this.webchatTicketService.changeAllItems([]);
	}

	getRouteParams() {
		this.routeParam = this.route.snapshot.paramMap.get('param');
	}

	doLoader() {
		this.loader = true;
		setTimeout(() => {
			this.loader = false;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.webchatTicketService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.webchatTicketService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Tag */
	reassignTicket(ticket = null): void {
		this.webchatTicketService.changeItem((ticket) ? ticket : null);
		let dialogRef = this.matDialog.open(ReassignFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: ticket,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				let updateData: any = formData.value;
				let newAgentId: any = updateData.agent_id;
				if (newAgentId && newAgentId === ticket.agent_id) {
					updateData.status_mode = null;
				}
				switch (actionType) {
					case 'update':
						this.webchatTicketService.update(id, updateData);
						break;
				}
			});
	}

	/** Re Open Ticket */
	reOpenTicket(ticket = null): void {
		this.webchatTicketService.changeItem((ticket) ? ticket : null);
		let dialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				title: 'Re-Open Ticket',
				message: 'Are you sure you want to Re-open this Ticket and Assigned to you?',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response || !response.data) { return; }
				const actionType: string = 'update';
				let updateData: any = {};
				updateData.agent_id = this.authUser.person_id;
				updateData.status_mode = 'Re-Assigned';
				const id: number = ticket.id;
				let newAgentId: any = updateData.agent_id;
				switch (actionType) {
					case 'update':
						this.webchatTicketService.update(id, updateData);
						break;
				}
			});
	}

	/** Close Tickets */
	closeTicket(ticket): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'close',
				title: 'Close Ticket',
				resource: ticket,
				clientSiteId: ticket.clientSiteId,
				message: 'Are you sure you want to Close this Ticket?',
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				result.formValue.is_closed = true;
				result.formValue.webchat_ticket_id = result.formValue.id;
				result.formValue.status_mode = 'Closed';
				var data = {
					"client_site_id": result.formValue.clientSiteId,
					"ticket_id": result.formValue.id,
					"comments": {
						"title": result.formValue.title,
						"description": result.formValue.description,
					},
					"set_flags": result.formValue.set_flags
				}
				this.webchatTicketService.close(ticket.id, data, 'tickets/close');
				this.webchatTicketService.removelibraryById(ticket.id);
			}
		});
	}

	/** Close Tickets */
	commentTicket(ticket): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: ticket,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.replayComment('comments/addcomment', result.formValue);
			}
		});
	}

	statusChange(ticket: WebchatTicket, status: string) {
		switch (status) {
			case "Re-Assigned":
				this.reassignTicket(ticket);
				break;
			case "Closed":
				this.closeTicket(ticket);
				break;
			case "Important":
				let updateData: any = { status_mode: 'Important' };
				this.webchatTicketService.update(ticket.id, updateData);
				break;
			default:
				break;
		}
	}

	getTicketActivities(ticket: WebchatTicket) {
		this.ticketActivityService.changeAllItems([]);
		this.webchatTicketService.changeItem(ticket);
		this.ticketActivityService.getTicketActivities(ticket);
	}

	checkEventData() {
		if (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'webchat' && this.userService.isAuthUserIsAgent()) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'ticketassigned':
					this.webchatTicketService.concatItem(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.webchatTicketService.concatItem(this.eventData.data);
					break;
				case 'ticketremoved':
					this.webchatTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'ticketclosed':
					this.webchatTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'ticketabandon':
					this.webchatTicketService.changeAllItemsByItem(this.eventData.data);
					break;
				default:
					break;
			}
		}
		if (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'webchat' && this.userService.isAuthUserIsClient()) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'ticketassigned':
				case 'ticketreassigned':
				case 'ticketremoved':
				case 'ticketclosed':
				case 'ticketabandon':
					if (this.eventData.data && this.eventData.data.agentInfo) {
						this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
						if (this.eventData.data.agentInfo.person_details) {
							this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
						}
					}
					if (this.eventData.data.client_site_id) {
						this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
					}
					if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
						this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
						this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
						this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
							this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
							this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
							this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
							this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
						}
					}
					this.webchatTicketService.changeAllItemsByItem(this.eventData.data);
					break;
				
				default:
					break;
			}
		}
	}

	getLeadsHistory(ticket: WebchatTicket) {
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = ticket.clientSiteId;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}

	showConversationHistory(ticket): void {
		let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
			width: "600px",
			data: {
				action: 'show',
				item: ticket,
				ticketClassName: (ticket) ? ticket.table : null,
				sourceFrom: 'ticket',
			}
		});
		dialogRef.afterClosed().subscribe(response => { });
	}

}
