export class WabaModel {
    _id: number;
    id: number;
    wabaFullInfo?: Array<any>;
    wAppFullInfo?: Array<any>;
    consumer_key: number;
    consumer_secret: number;
    whatsappNamespace: string;
    client_id: number;
    waba_id: number;
    business_id: number;
    business_info?: Array<any>;
    clientSiteInfo?: Array<any>;

    /**
     * Constructor
     *
     * @param waba
     */
    constructor(waba) {
        this._id = waba._id || '';
        this.id = waba.id || '';
        this.wabaFullInfo = waba.wabaFullInfo || {};
        this.wAppFullInfo = waba.wAppFullInfo || {};
        this.consumer_key = waba.consumer_key || '';
        this.consumer_secret = waba.consumer_secret || '';
        this.whatsappNamespace = waba.whatsappNamespace || '';
        this.client_id = waba.client_id || '';
        this.waba_id = waba.waba_id || '';
        this.business_id = waba.business_id || '';
        this.business_info = waba.business_info || {};
        this.clientSiteInfo = waba.clientSiteInfo || {};
    }
}

