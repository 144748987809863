import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel,
	ConsumerService, Consumer, ConsumerModel,
	TemplateMessageService, TemplateMessage
} from 'src/app/@tji/_dbShare';

import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER, TAB, SPACE } from '@angular/cdk/keycodes';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'template-templateform',
	templateUrl: './templateForm.component.html',
	styleUrls: ['./templateForm.component.scss']
})

export class TemplateFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Choose Template & Send Bulk';
	authUser: User;
	template: TemplateMessage;
	action: string;
	templateForm: UntypedFormGroup;
	data: Array<any> = [];

	contacts: Array<string> = [];
	selectable = true;
	removable = true;
	addOnBlur = true;
	readonly separatorKeysCodes = [TAB, COMMA] as const;

	constructor(public userService: UserService,
		public templateMessageService: TemplateMessageService,
		public matDialogRef: MatDialogRef<TemplateFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
			this.createForm();
		});
	}

	ngOnInit() {}

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Template & Send Bulk';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Template & Send Bulk';
				break;
			default:
				break;
		}
	}

	onChangeValue(value, place) {
		this.data[place] = value;
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			template_id: [this.template.id || null, [Validators.required]],
		});
	}

	sendTemplate() {
		// let templateData: any = {};
		// templateData.template_id = this.template.id;
		// templateData.data = [];
		let newData = Object.assign({}, this.data);
		// templateData.data.push(newData);
		// this.matDialogRef.close(templateData);
		var sendingData = [];
		if (this.contacts && this.contacts.length > 0) {
			this.contacts.forEach(constact => {
				var newVariable = Object.assign({'to': constact,}, this.data)
				sendingData.push(newVariable);
			});
		}
		var datas = {
			"template_id": this.template.id,
			"variables": sendingData
		}
		this.templateMessageService.sendSingleTemplate(datas);
		this.matDialogRef.close();

	}



	add(event: MatChipInputEvent): void {
		const value = (event.value).trim();
		// const arrayValue = value.splitMulti([",", " "]);
		// const arrayValue = splitMulti(value, [",", " "]);
		const arrayValue = value.split(/\r\n|\n|\r|,|\t|\s| /).filter(word => word.length > 0);
		// const arrayValue = value.split(/;|,|\n\r|\n/).filter(word => word.length > 0);

		// Add our contact
		if (arrayValue && arrayValue.length > 0) {
			for (let i = 0; i <= arrayValue.length; i++) {
				if (arrayValue[i] === null || arrayValue[i] === '' || arrayValue[i] === ' ' || arrayValue[i] === undefined) {
					continue;
				}
				else { this.contacts.push(arrayValue[i]); }
			}
		}

		this.contacts = this.contacts.filter((element): element is any => {
			return (element !== null || element !== 'null' || element !== undefined) ? true : false;
		});

		// Clear the input value
		event.chipInput!.clear();
	}

	remove(contact: string): void {
		const index = this.contacts.indexOf(contact);

		if (index >= 0) {
			this.contacts.splice(index, 1);
		}
	}

}
