export class MessengerTicketModel {
	id: number;
	code: string;
	messenger_id: number;
	client_id: number;
	agent_id: number;
	priority: string;
	is_active: boolean;
	is_closed: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	messenger?: any;
	people?: any;
	people_id?: number;
	peopleName?: string;
	peopleAvatar?: string;
	clientSiteId?: number;
	pusherSync?: string;
	recipientId?: string;
	abandonAt: Date;
	abandonElapsed: number;
	last_message_on?: Date;

	unReadCount?: number;
	tableName: string;
	className: string;
	class?: string;
	statusName?: string;
	statusChanges?: Array<string>;
	peopleLabelId?: number;
	peopleLabelName?: string;
	peopleLabelColor?: string;
	peopleLabelColorText?: string;
	siteColor?: string;
	uName?: string;
	clientSiteName?: string;
	clientSiteColor?: string;
	clientSiteTextColor?: string;
	siteName?: string;

	/**
	 * Constructor
	 *
	 * @param messenger_ticket
	 */
	constructor(messenger_ticket) {
		this.id = messenger_ticket.id || '';
		this.code = messenger_ticket.code || '';
		this.messenger_id = messenger_ticket.messenger_id || '';
		this.client_id = messenger_ticket.client_id || '';
		this.agent_id = messenger_ticket.agent_id || '';
		this.priority = messenger_ticket.priority || '';
		this.is_active = messenger_ticket.is_active || '';
		this.is_closed = messenger_ticket.is_closed || '';
		this.created_by = messenger_ticket.created_by || '';
		this.updated_by = messenger_ticket.updated_by || '';
		this.created_at = messenger_ticket.created_at || '';
		this.updated_at = messenger_ticket.updated_at || '';

		this.tableName = messenger_ticket.tableName || '';
		this.siteName = messenger_ticket.siteName || '';
	}
}
