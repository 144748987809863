import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  BranchService,
  User, UserService,
} from 'src/app/@tji/_dbShare';
import { Location } from '@angular/common';

@Component({
  selector: 'main-branch',
  templateUrl: './main-branch.component.html',
  styleUrls: ['./main-branch.component.scss']
})
export class MainBranchComponent {
  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  outputs: ['uploaded']
  user: User;

  constructor(public userService: UserService,
    private router: Router, public branchService: BranchService,
		public location: Location) {
    this.userService.user.subscribe(data => this.user = data);
  }

  ngOnInit(): void {
  }
  toggleRightSidebar() {
    this.rightSideBar.toggle();
    // this.branchService.itemSource.next(null);
    // this.branchService.isAddItems.next(true);
  }

  isEditableData(data) {
    this.rightSideBar.toggle();
  }

}
