export class MentionModel {
	id: number;
	client_id: number;
	client_site_id: number;
	text: string;
	type: string;
	is_active: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param mention
	 */
	constructor(mention) {
		this.id = mention.id || '';
		this.client_id = mention.client_id || '';
		this.client_site_id = mention.client_site_id || '';
		this.text = mention.text || '';
		this.type = mention.type || '';
		this.is_active = mention.is_active || '';
		this.created_by = mention.created_by || '';
		this.updated_by = mention.updated_by || '';
		this.created_at = mention.created_at || '';
		this.updated_at = mention.updated_at || '';

		this.tableName = mention.tableName || '';
	}
}
