import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import {
  User, UserService, ProductCatalog, ProductCatalogModel, ProductCatalogService
} from 'src/app/@tji/_dbShare';
@UntilDestroy({ checkProperties: true })


@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss'],
  providers: [OrderByPipe]
})
export class CatalogListComponent implements OnInit {
  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;
  productCatalogLists: ProductCatalog[] = [];
  param: any;
  usageParam: any;
  dataSource = new MatTableDataSource(this.productCatalogLists);
  displayedColumns = ['name', 'type', 'status', 'action'];

  @ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
  
  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;


  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    public productCatalogService: ProductCatalogService,
    public matDialog: MatDialog,
    private orderByPipe: OrderByPipe) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.productCatalogService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {this.subLoader = false; }
      this.productCatalogLists = data;
			this.productCatalogLists = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.productCatalogLists);
			this.dataSource.sort = this.sort;
    });
  }

  ngOnInit(): void {
    this.doLoader();
    this.dataInit();
  }
  /** On destroy */
  ngOnDestroy(): void {

  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }
	dataInit() {
		this.productCatalogService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.productCatalogService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}
    deleteProduct(id: number) {

  	// 	let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
		// 	disableClose: false,
		// 	width: '400',
		// 	data: {
		// 		type: 'delete',
		// 		message: 'Are you sure you want to delete?',
		// 	}
		// });
		// confirmDialogRef.afterClosed().subscribe(result => {
		// 	if (result && result.data) {
		// 		var data = {
		// 			id: socialSite.id
		// 		}
		// 		this.clientSiteService.deleteClientSite(data, 'client-site/delete');
		// 	}
		// });
    this.productCatalogService.destroyItems(id, 'ecatalog/delete');
  }
}

