import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { AssignFormComponent } from './../assignForm/assignForm.component';
import { ConversationHistoryComponent } from './../conversationHistory/conversationHistory.component';
import { ConversationInstagramHistoryComponent } from './../conversationInstagramHistory/conversationInstagramHistory.component';
import { ConversationYoutubeHistoryComponent } from './../conversationYoutubeHistory/conversationYoutubeHistory.component';

import {
	EventService,
	User, UserService, FeedService,
	OpenTicketService, OpenTicket, PusherService, AgentService, CommentService, ModalHasFlagService,
	TeamService
} from 'src/app/@tji/_dbShare';
import { TeamViewComponent } from '../team-view/team-view.component';

@UntilDestroy()
@Component({
	selector: 'queue-ticket-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	openTicketsAll: OpenTicket[] = [];
	openTickets: OpenTicket[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.openTickets);
	displayedColumns = ['media', 'date', 'avatar', 'user', 'mediaurl', 'contact', 'team', 'status', 'action'];
	dataLength: number = 0;
	channel: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	eventData: any;

	constructor(public userService: UserService,
		public eventService: EventService,
		public openTicketService: OpenTicketService,
		public feedService: FeedService,
		public pusherService: PusherService,
		private router: Router,
		public matDialog: MatDialog,
		public agentService: AgentService,
		public commentService: CommentService,
		public modalHasFlagService: ModalHasFlagService,
		public teamService: TeamService,
		private orderByPipe: OrderByPipe) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.openTicketService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.openTicketsAll) { this.subLoader = false; }
			if (!data) {
				this.openTicketsAll = null;
				this.openTickets = this.orderByPipe.transform(this.openTicketsAll, '-id');
				this.dataSource = new MatTableDataSource(this.openTickets);
				this.dataSource.sort = this.sort;
			} else {
				this.openTicketsAll = data.filter(x => { return x.is_ticketable; });
				this.openTickets = this.orderByPipe.transform(this.openTicketsAll, '-id');
				this.dataSource = new MatTableDataSource(this.openTickets);
				this.dataSource.sort = this.sort;
			}
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
		// this.subscribePusher();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.openTicketService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.openTicketService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.openTicketService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Tag */
	assignOpenTicket(openTicket = null): void {
		this.openTicketService.changeItem((openTicket) ? openTicket : null);
		let dialogRef = this.matDialog.open(AssignFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: openTicket,
				ticketClassName: (openTicket && openTicket.resource) ? openTicket.resource.className : null,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				this.agentService.allActiveSource.next([]);
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.openTicketService.assign(formData.value, openTicket.id, 'tickets/assign');
						break;
				}
			});
	}

	/** Add / Update Tag */
	showConversationHistory(openTicket): void {
		this.openTicketService.changeItem((openTicket) ? openTicket : null);
		this.feedService.changeItem((openTicket) ? openTicket : null);
		if (openTicket && openTicket.siteName === 'Instagram') {
			let dialogRef = this.matDialog.open(ConversationInstagramHistoryComponent, {
				width: "650px",
				data: {
					action: 'show',
					item: openTicket,
					siteName: (openTicket && openTicket.site) ? openTicket.site : null,
					siteId: (openTicket && openTicket.siteId) ? openTicket.siteId : null,
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		} else if (openTicket && openTicket.siteName === 'Instadm') {
			let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
				width: "650px",
				data: {
					action: 'show',
					item: openTicket,
					siteName: (openTicket && openTicket.site) ? openTicket.site : null,
					siteId: (openTicket && openTicket.siteId) ? openTicket.siteId : null,
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		} else if (openTicket && openTicket.siteName === 'Youtube') {
			let dialogRef = this.matDialog.open(ConversationYoutubeHistoryComponent, {
				width: "650px",
				data: {
					action: 'show',
					item: openTicket,
					siteName: (openTicket && openTicket.site) ? openTicket.site : null,
					siteId: (openTicket && openTicket.siteId) ? openTicket.siteId : null,
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		} else {
			let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
				width: "600px",
				data: {
					action: 'show',
					item: openTicket,
					siteName: (openTicket && openTicket.site) ? openTicket.site : null,
					siteId: (openTicket && openTicket.siteId) ? openTicket.siteId : null,
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		}
	}

	/** Close OpenTicket */
	closeOpenTicket(openTicket): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'close',
				message: 'Are you sure want to Close this Queue Ticket ?',
				resource: openTicket,
				clientSiteId: (openTicket) ? openTicket.client_site_id : null,
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var data = {
					"id": openTicket.id,
				}
				var user: any = JSON.parse(localStorage.getItem('tji_user'));
				let comments: any = {
					"client_site_id": result.formValue.clientSiteId,
					"comment_id": null,
					"ref_id": openTicket.resource_id,
					"user_id": (user && user.id) ? user.id : null,
					"description": result.formValue.description,
					"title": result.formValue.title
				}
				if (result.formValue.description) {
					this.commentService.closeTicketComment('comments/addcomment', comments);
				}
				// if (result.formValue.set_flags && result.formValue.set_flags.length > 0) {
				// 	result.formValue.set_flags.forEach(flag => {
				// 		let modalHasFlag: any = {
				// 			"ticket_id": openTicket.id,
				// 			"flag_id": flag,
				// 			"client_site_id": result.formValue.clientSiteId,
				// 		};
				// 		this.modalHasFlagService.store('flag/addflagMap', modalHasFlag);
				// 	});
				// }
				this.openTicketService.close(openTicket.id, data, 'feeds/close');
			}

		});
	}

	// subscribePusher() {
	// 	this.channel.bind('newmessage', (data) => {
	// 		this.openTicketService.getAllItems();
	// 	});
	// }

	goToUrl(openTicket) {
		let url = 'http://google.com';
		if (openTicket && openTicket.id) {
			switch (openTicket.siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					url = 'https://twitter.com/' + openTicket.resource.source + '/status/' + openTicket.resource.tweet_code;
					break;
			}
		}
		window.open(url, "_blank");
	}

	checkEventData() {
		if (this.eventData && this.userService.isAuthUserIsClient()) {
			let event: string = this.eventData.event;
			switch (event) {
				// case 'newticket':
				// 	this.openTicketService.changeAllItemsByItem(this.eventData.data);
				// 	break;
				case 'queueticket':
					this.openTicketService.concatItem(this.eventData.data);
					break;
				case 'aQueueticket':
					this.openTicketService.concatItem(this.eventData.data, this.eventData.eventName);
					break;
				case 'removefeed':
					this.openTicketService.spliceItem(this.eventData.data.id);
					break;
				default:
					break;
			}
		}
	}

	showTeam(teams: any = null, openTicket) {
		this.teamService.changeItem(null)
		this.teamService.getItem('team/viewmore', { id: teams })
		this.openTicketService.changeItem((openTicket) ? openTicket : null);
		let dialogRef = this.matDialog.open(TeamViewComponent, {
			width: "400px",
			data: {
				action: 'new',
				item: openTicket,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				this.teamService.changeItem(null)
				if (!response) { return; }
				
			});
	}

}
