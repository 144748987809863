export class FlagModel {
    _id: number;
    name: string;
    description: string;
    client_id: number;
    client_site_id: [];
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    ClientsiteInfo: [];
    tableName?: string;
    clientsite?: any;

    /**
     * Constructor
     *
     * @param flag
     */
    constructor(flag) {
            this._id = flag._id || '';
            this.name = flag.name || '';
            this.description = flag.description || '';
            this.client_id = flag.client_id || '';
            this.client_site_id = flag.client_site_id || [];
            this.ClientsiteInfo = flag.ClientsiteInfo || [];
            this.created_by = flag.created_by || '';
            this.updated_by = flag.updated_by || '';
            this.created_at = flag.created_at || '';
            this.updated_at = flag.updated_at || '';
            this.deleted_at = flag.deleted_at || '';

            this.tableName = flag.tableName || '';
    }
}
