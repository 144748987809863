import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-confirm-dialog-image-upload',
  templateUrl: './confirm-dialog-image-upload.component.html',
  styleUrls: ['./confirm-dialog-image-upload.component.scss']
})
export class ConfirmDialogImageUploadComponent {

  dialogTitle: string = 'Alert !!!';
  today = new Date(); 

  constructor(private formBuilder: UntypedFormBuilder, private orderByPipe: OrderByPipe, public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmDialogImageUploadComponent>,
  ) {


  }

  checked: boolean = false;
  isChecked = 'false';

  changeValue(value) {
      this.checked = !value;
      if (this.checked) {
        this.isChecked = 'true';
      }else{
        this.isChecked = 'false';
      }
  }

}
