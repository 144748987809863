import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {
	User, UserService,
	YoutubeService, Youtube,
	CommonService,
	FeedService, Feed
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'queue-ticket-youtube-history',
	templateUrl: './conversationYoutubeHistory.component.html',
	styleUrls: ['./conversationYoutubeHistory.component.scss']
})

export class ConversationYoutubeHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeYoutube: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Conversation Youtube History';
	authUser: User;
	feed: Feed;
	conversationYoutubeHistory: UntypedFormGroup;
	ticketClassName: string = null;

	youtubes: Youtube[] = [];
	youtubeParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;
	mediaImage: string;
	iframUrl: SafeResourceUrl = null;

	constructor(public userService: UserService,
		public feedService: FeedService,
		public youtubeService: YoutubeService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
		public matDialogRef: MatDialogRef<ConversationYoutubeHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.youtubeService.resetParams();
	}

	ngOnInit() {
		this.feedService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.feed = data;
		});
		this.youtubeService.params.pipe(untilDestroyed(this)).subscribe(data => this.youtubeParam = data);
		this.youtubeService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.youtubes = data;
			if(data && data.length > 0 && data[0].videoId) {
				this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data[0].videoEmbeddedUrl);
			}
		});
		this.doLoader();
		this.getConversations();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.youtubeService.changeAllItems([]);
		this.youtubeService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Conversation Youtube History';
				break;
			default:
				break;
		}
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	getConversations() {
		if (this._data && this._data.ticketClassName) {
			switch (this._data.ticketClassName) {
				case "Youtube\\Models\\Youtube": case "Youtube":
					this.youtubeParam.youtube_id = this.feed.resource_id;
					this.youtubeParam.channelId = this.feed.resource.channelId;
					this.youtubeParam.videoId = this.feed.resource.videoId;
					this.youtubeParam.is_itrs = null;
					this.youtubeParam.is_assigned = null;
					this.youtubeParam.all_history = 'true';
					this.youtubeParam.only = "id,text,youtube_id,reply_id,status,from,to,channelId,commentId,parentId,videoId,user_image,authorDisplayName,authorProfileImageUrl,created_by,updated_by,created_at,updated_at";
					this.youtubeService.getAllItems(this.youtubeParam);
					break;
				default:
					break;
			}
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.youtubeService.changeAllItems([]);
		this.youtubeService.unSubscribe();
	}
}
