export class ItrsbotModel {
	id: number;
	name: string;
	code: string;
	resource_id: number;
	resource_type: string;
	client_id: number;
	is_active: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	accountName?: string;
	siteName?: string;
	intractives?: Array<any>;
	resource?: any;
	clientSiteName?: string;
	clientSiteColor?: string;
	clientSiteTextColor?: string;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param itrsbot
	 */
	constructor(itrsbot) {
		this.id = itrsbot.id || '';
		this.name = itrsbot.name || '';
		this.code = itrsbot.code || '';
		this.resource_id = itrsbot.resource_id || '';
		this.resource_type = itrsbot.resource_type || '';
		this.client_id = itrsbot.client_id || '';
		this.is_active = itrsbot.is_active || '';
		this.created_by = itrsbot.created_by || '';
		this.updated_by = itrsbot.updated_by || '';
		this.created_at = itrsbot.created_at || '';
		this.updated_at = itrsbot.updated_at || '';

		this.tableName = itrsbot.tableName || '';
	}
}
