import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	TweetTicketService, TweetTicket,
	AgentService, Agent,
	BoardService, Board
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'tweet-ticket-reassign-form',
	templateUrl: './reassignForm.component.html',
	styleUrls: ['./reassignForm.component.scss']
})

export class ReassignFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	ticket: TweetTicket;
	reassignForm: UntypedFormGroup;

	agents: Agent[] = [];
	agentParam: any;

	action: string;
	priorities: any[] = ['Low', 'Normal', 'Important', 'Very Important'];

	constructor(public userService: UserService,
		public tweetTicketService: TweetTicketService,
		public agentService: AgentService,
		public matDialogRef: MatDialogRef<ReassignFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.tweetTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			this.reassignForm = this.createForm();
		});
		this.agentService.allActiveAgents.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void {
	this.agentService.unSubscribeFilter();
	 }

	getAgents() {
		this.agentService.getActiveAgent(this.ticket.clientSiteId);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Assign To';
				break;
			case 'edit':
				this.dialogTitle = 'Re-assign To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			agent_id: [this.ticket.agent_id || null, [Validators.required]],
			priority: [this.ticket.priority || 'Low', [Validators.required]],
			ticket_id: [this.ticket.id || null, [Validators.required]],
			client_site_id: [this.ticket.clientSiteId || null, [Validators.required]]
		});
	}

	changeBoard(boardId) { }

	checkIsAuthAgent(agentId): boolean {
		if(this.authUser && this.authUser.person_id && this.authUser.person_type 
			&& this.authUser.person_type === 'agent' && this.authUser.person_id === agentId) {
			return true;
		} else {
			return false;
		}
	}
}
