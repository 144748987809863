import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from 'src/app/app-share.module';

import { RTemplateGroupRoutingModule } from './rtemplate-group-route.module';

import { MainComponent } from './main/main.component';
import { TemplateUsageComponent } from './template-usage/template-usage.component';
import { TemplateViewComponent } from './template-view/template-view.component';
import { FaliedReasonComponent } from './falied-reason/falied-reason.component';
import { TemplateDeliveryReportComponent } from './template-delivery-report/template-delivery-report.component';
import { TemplateQueueComponent } from './template-queue/template-queue.component';
import { SidebarComponent } from './sidebar/sidebar.component';


@NgModule({
    declarations: [
        MainComponent,
        TemplateUsageComponent,
        TemplateViewComponent,
        FaliedReasonComponent,
        TemplateDeliveryReportComponent,
        TemplateQueueComponent,
        SidebarComponent
    ],
    imports: [
        CommonModule,
        AppShareModule,
        RTemplateGroupRoutingModule
    ]
})
export class RTemplateGroupModule {  

}
