import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'consumer-group-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'New Consumer Group';
	authUser: User;
	consumerGroup: ConsumerGroup;
	action: string;
	consumerGroupForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public consumerGroupService: ConsumerGroupService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		// this.getInit(_data);
		this.consumerGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.consumerGroup = data;
			if (this.consumerGroup && this.consumerGroup.id) {
				this.dialogTitle = 'Edit Consumer Group';
			}else {
				this.dialogTitle = 'New Consumer Group';
			}
			
			this.consumerGroupForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Consumer Group';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Consumer Group';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.consumerGroup.name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
			description: [this.consumerGroup.description || null, [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
		});
	}

}
