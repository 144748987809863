export class FeedModel {
	id: number;
	resource_id: number;
	resource_type: string;
	is_ticketable: boolean;
	is_closeable: boolean;
	client_id: number;
	client_site_id: number;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	last_message_on: Date;

	client_site: any;
	client: any;
	resource: any;
	peopleAvatar?: string;
	peopleName?: string;

	siteName?: string;
	siteIcon?: string;
	siteColor?: string;
	tableName: string;
	className: string;
	peopleLabelId?: number;
	peopleLabelName?: string;
	peopleLabelColor?: string;
	peopleLabelColorText?: string;
	clientSiteName?: string;
	clientSiteColor?: string;
	clientSiteTextColor?: string;
	clientSite: any;
	ChatData: any;
	leadInfo: any;

	/**
	 * Constructor
	 *
	 * @param feed
	 */
	constructor(feed) {
		this.id = feed.id || '';
		this.resource_id = feed.resource_id || '';
		this.resource_type = feed.resource_type || '';
		this.is_ticketable = feed.is_ticketable || '';
		this.is_closeable = feed.is_closeable || false;
		this.client_id = feed.client_id || '';
		this.client_site_id = feed.client_site_id || '';
		this.created_by = feed.created_by || '';
		this.updated_by = feed.updated_by || '';
		this.created_at = feed.created_at || '';
		this.updated_at = feed.updated_at || '';
		this.last_message_on = feed.last_message_on || '';

		this.tableName = feed.tableName || '';
		this.clientSite = feed.clientSite || '';
		this.ChatData = feed.ChatData || '';
		this.leadInfo = feed.LeadInfo || '';
	}
}
