import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	FindChatService,
	AgentService, Agent,
	BoardService, Board
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'find-conversation-assign-form',
	templateUrl: './assignForm.component.html',
	styleUrls: ['./assignForm.component.scss']
})

export class AssignFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	feed: any;
	assignForm: UntypedFormGroup;
	ticketClassName: string = null;

	agents: Agent[] = [];
	agentParam: any;

	action: string;
	priorities: any[] = ['Low', 'Normal', 'Important', 'Very Important'];

	constructor(public userService: UserService,
		public findChatService: FindChatService,
		public agentService: AgentService,
		public matDialogRef: MatDialogRef<AssignFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.findChatService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.feed = data;
			this.assignForm = this.createForm();
		});
		this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);

	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { 
		this.agentService.unSubscribeFilter();
	}

	getAgents() {
		this.agentParam.all = 1;
		this.agentService.getAllItems();
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Assign To';
				break;
			case 'edit':
				this.dialogTitle = 'Re-assign To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			id: [this.feed.id || null, [Validators.required]],
			client_id: [this.feed.client_id || null, [Validators.required]],
			clent_site_id: [this.feed.client_site_id || this.feed.clientSiteId || null, [Validators.required]],
			site_name: [this.feed.siteName || null, [Validators.required]],
			process: [this._data.process || null, [Validators.required]],
			agent_id: [null, [Validators.required]],
			priority: ['Low' || null, [Validators.required]],
		});
	}
}
