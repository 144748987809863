import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { ConversationHistoryComponent } from './../conversationHistory/conversationHistory.component';
import { ConversationInstagramHistoryComponent } from './../conversationInstagramHistory/conversationInstagramHistory.component';

import { EventService,
	User, UserService,
	InstagramService, Instagram, PusherService,
	InstagramListService, InstagramList
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'closed-conversation-instagram',
	templateUrl: './instagram.component.html',
	styleUrls: ['./instagram.component.scss'],
	providers: [OrderByPipe]
})

export class InstagramComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	messages: Instagram[] = [];
	listParam: any;
	param: any;
	dataSource = new MatTableDataSource(this.messages);
	displayedColumns = ['media', 'date', 'avatar', 'user', 'mediaurl', 'contact', 'status', 'action'];
	dataLength: number = 0;
	channel: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	eventData: any;

	constructor(public userService: UserService,
		public eventService: EventService,
		public instagramService: InstagramService,
		public instagramListService: InstagramListService,
		public pusherService: PusherService,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
		});
		this.instagramListService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.messages = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.messages);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
		// this.subscribePusher();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.instagramListService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.instagramListService.params.pipe(untilDestroyed(this)).subscribe(data => this.listParam = data);
		this.instagramListService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
	}


	/** Add / Update Tag */
	showConversationHistory(message): void {
		this.instagramService.changeItem((message) ? message : null);
		if (message && message.siteName === 'Instagram') {
			let dialogRef = this.matDialog.open(ConversationInstagramHistoryComponent, {
				width: "650px",
				data: {
					action: 'show',
					item: message,
					siteName: (message && message.siteName) ? message.siteName : null,
					siteId: (message && message.siteId) ? message.siteId : null,
					ticketClassName: (message && message.id) ? message.className : null,
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		} else {
			let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
				width: "600px",
				data: {
					action: 'show',
					item: message,
					ticketClassName: (message && message.id) ? message.className : null,
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		}
	}

	subscribePusher() {
		this.channel.bind('newmessage', (data) => {
			this.instagramService.getAllItems();
		});
	}

	onSearch(event) {
		this.param.is_itrs = null;
		this.instagramListService.onSearch(event);
	}

}
