export var data = [
  {
    "name": "Top Agent 1",
    "value": 80
  },
  {
    "name": "Top Agent 2",
    "value": 60
  },
  {
    "name": "Top Agent 3",
    "value": 54
  },
  {
    "name": "Top Agent 4",
    "value": 52
  },
  {
    "name": "Top Agent 5",
    "value": 35
  },
  {
    "name": "Top Agent 6",
    "value": 30
  }
];