import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import {
  User, UserService, Catalog, CatalogService, WabaService
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AssignCatalogWabaComponent } from '../assign-catalog-waba/assign-catalog-waba.component';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'catalog-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [OrderByPipe]
})
export class ListComponent implements OnInit {
  @Output() isEditable = new EventEmitter();
  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;
  catalogLists: Catalog[] = [];
  param: any;
  usageParam: any;
  dataSource = new MatTableDataSource(this.catalogLists);
  displayedColumns = ['name', 'business', 'number','product', 'action'];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    public catalogService: CatalogService,
    public matDialog: MatDialog,
    public wabaService: WabaService,
    private orderByPipe: OrderByPipe) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.catalogService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) { this.subLoader = false; }
      this.catalogLists = data;
      this.catalogLists = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.catalogLists);
      this.dataSource.sort = this.sort;
    });
  }


  ngOnInit(): void {
    this.doLoader();
    this.dataInit();
  }
  /** On destroy */
  ngOnDestroy(): void {

  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  dataInit() {
    this.catalogService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
    this.catalogService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  editCatalog(catalog: any) {
    this.isEditable.emit(true);
    this.catalogService.itemSource.next(catalog);
  }

  /** Delete Social Site */
  deleteCatalog(item: Catalog): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var id = {
          catalog_id: item.id.toString()
        }
        this.catalogService.destroy('fbCatalog/delete', id);
      }
    });
  }

  linkBussiness(item: Catalog) {
    this.wabaService.getAllItems();
    let confirmDialogRef = this.matDialog.open(AssignCatalogWabaComponent, {
      data: { type: 'info' }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result > 0) {
        var mark = {
           "catalog_id": item.id.toString(),
           "waba_id": result.toString()
        }
        this.catalogService.bussinessActivity("fbCatalog/linkCatalogWaba", mark, item);
      }
    });
  }

  removeBussiness(item: any) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'info',
        message: 'Are you sure you want to Unlink the Catalog Waba?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var mark = {
          "catalog_id": item.id.toString()
        }
        this.catalogService.bussinessActivity("fbCatalog/deleteCatalogWaba", mark, item);
      }
    });
  }

}
