import { Component } from '@angular/core';

@Component({
  selector: 'app-main-branch',
  templateUrl: './main-branch.component.html',
  styleUrls: ['./main-branch.component.scss']
})
export class MainBranchComponent {

}
