import { bounceAnimation,
         bounceOnEnterAnimation,
         flashAnimation,
         flashOnEnterAnimation,
         headShakeAnimation,
         headShakeOnEnterAnimation,
         heartBeatAnimation,
         heartBeatOnEnterAnimation,
         jelloAnimation,
         jelloOnEnterAnimation,
         pulseAnimation,
         pulseOnEnterAnimation,
         rubberBandAnimation,
         rubberBandOnEnterAnimation,
         shakeAnimation,
         shakeOnEnterAnimation,
         swingAnimation,
         swingOnEnterAnimation,
         tadaAnimation,
         tadaOnEnterAnimation,
         wobbleAnimation,
         wobbleOnEnterAnimation,

         bounceInAnimation,
         bounceInOnEnterAnimation,
         bounceInDownAnimation,
         bounceInDownOnEnterAnimation,
         bounceInLeftAnimation,
         bounceInLeftOnEnterAnimation,
         bounceInRightAnimation,
         bounceInRightOnEnterAnimation,
         bounceInUpAnimation,
         bounceInUpOnEnterAnimation,

         bounceOutAnimation,
         bounceOutOnLeaveAnimation,
         bounceOutDownAnimation,
         bounceOutDownOnLeaveAnimation,
         bounceOutLeftAnimation,
         bounceOutLeftOnLeaveAnimation,
         bounceOutRightAnimation,
         bounceOutRightOnLeaveAnimation,
         bounceOutUpAnimation,
         bounceOutUpOnLeaveAnimation,

         fadeInAnimation,
         fadeInOnEnterAnimation,
         fadeInDownAnimation,
         fadeInDownOnEnterAnimation,
         fadeInLeftAnimation,
         fadeInLeftOnEnterAnimation,
         fadeInRightAnimation,
         fadeInRightOnEnterAnimation,
         fadeInUpAnimation,
         fadeInUpOnEnterAnimation,
         fadeInDownBigAnimation,
         fadeInDownBigOnEnterAnimation,
         fadeInLeftBigAnimation,
         fadeInLeftBigOnEnterAnimation,
         fadeInRightBigAnimation,
         fadeInRightBigOnEnterAnimation,
         fadeInUpBigAnimation,
         fadeInUpBigOnEnterAnimation,

         fadeOutAnimation,
         fadeOutOnLeaveAnimation,
         fadeOutDownAnimation,
         fadeOutDownOnLeaveAnimation,
         fadeOutLeftAnimation,
         fadeOutLeftOnLeaveAnimation,
         fadeOutRightAnimation,
         fadeOutRightOnLeaveAnimation,
         fadeOutUpAnimation,
         fadeOutUpOnLeaveAnimation,
         fadeOutDownBigAnimation,
         fadeOutDownBigOnLeaveAnimation,
         fadeOutLeftBigAnimation,
         fadeOutLeftBigOnLeaveAnimation,
         fadeOutRightBigAnimation,
         fadeOutRightBigOnLeaveAnimation,
         fadeOutUpBigAnimation,
         fadeOutUpBigOnLeaveAnimation,

         flipAnimation,
         flipOnEnterAnimation,
         flipInXAnimation,
         flipInXOnEnterAnimation,
         flipInYAnimation,
         flipInYOnEnterAnimation,
         flipOutXAnimation,
         flipOutXOnLeaveAnimation,
         flipOutYAnimation,
         flipOutYOnLeaveAnimation,

         lightSpeedInAnimation,
         lightSpeedInOnEnterAnimation,
         lightSpeedOutAnimation,
         lightSpeedOutOnLeaveAnimation,

         slideInDownAnimation,
         slideInDownOnEnterAnimation,
         slideInLeftAnimation,
         slideInLeftOnEnterAnimation,
         slideInRightAnimation,
         slideInRightOnEnterAnimation,
         slideInUpAnimation,
         slideInUpOnEnterAnimation,

         slideOutDownAnimation,
         slideOutDownOnLeaveAnimation,
         slideOutLeftAnimation,
         slideOutLeftOnLeaveAnimation,
         slideOutRightAnimation,
         slideOutRightOnLeaveAnimation,
         slideOutUpAnimation,
         slideOutUpOnLeaveAnimation,

         zoomInAnimation,
         zoomInOnEnterAnimation,
         zoomInDownAnimation,
         zoomInDownOnEnterAnimation,
         zoomInLeftAnimation,
         zoomInLeftOnEnterAnimation,
         zoomInRightAnimation,
         zoomInRightOnEnterAnimation,
         zoomInUpAnimation,
         zoomInUpOnEnterAnimation,

         zoomOutAnimation,
         zoomOutOnLeaveAnimation,
         zoomOutDownAnimation,
         zoomOutDownOnLeaveAnimation,
         zoomOutLeftAnimation,
         zoomOutLeftOnLeaveAnimation,
         zoomOutRightAnimation,
         zoomOutRightOnLeaveAnimation,
         zoomOutUpAnimation,
         zoomOutUpOnLeaveAnimation,

} from 'angular-animations';


// const customAnimation = animation([
//     style({
//         opacity  : '{{opacity}}',
//         transform: 'scale({{scale}}) translate3d({{x}}, {{y}}, {{z}})'
//     }),
//     animate('{{duration}} {{delay}} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*'))
// ], {
//     params: {
//         duration: '200ms',
//         delay   : '0ms',
//         opacity : '0',
//         scale   : '1',
//         x       : '0',
//         y       : '0',
//         z       : '0'
//     }
// });

export const tjiAnimation = [
    
         bounceAnimation(),
         bounceOnEnterAnimation(),
         flashAnimation(),
         flashOnEnterAnimation(),
         headShakeAnimation(),
         headShakeOnEnterAnimation(),
         heartBeatAnimation(),
         heartBeatOnEnterAnimation(),
         jelloAnimation(),
         jelloOnEnterAnimation(),
         pulseAnimation(),
         pulseOnEnterAnimation(),
         rubberBandAnimation(),
         rubberBandOnEnterAnimation(),
         shakeAnimation(),
         shakeOnEnterAnimation(),
         swingAnimation(),
         swingOnEnterAnimation(),
         tadaAnimation(),
         tadaOnEnterAnimation(),
         wobbleAnimation(),
         wobbleOnEnterAnimation(),

         bounceInAnimation(),
         bounceInOnEnterAnimation(),
         bounceInDownAnimation(),
         bounceInDownOnEnterAnimation(),
         bounceInLeftAnimation(),
         bounceInLeftOnEnterAnimation(),
         bounceInRightAnimation(),
         bounceInRightOnEnterAnimation(),
         bounceInUpAnimation(),
         bounceInUpOnEnterAnimation(),

         bounceOutAnimation(),
         bounceOutOnLeaveAnimation(),
         bounceOutDownAnimation(),
         bounceOutDownOnLeaveAnimation(),
         bounceOutLeftAnimation(),
         bounceOutLeftOnLeaveAnimation(),
         bounceOutRightAnimation(),
         bounceOutRightOnLeaveAnimation(),
         bounceOutUpAnimation(),
         bounceOutUpOnLeaveAnimation(),

         fadeInAnimation(),
         fadeInOnEnterAnimation(),
         fadeInDownAnimation(),
         fadeInDownOnEnterAnimation(),
         fadeInLeftAnimation(),
         fadeInLeftOnEnterAnimation(),
         fadeInRightAnimation(),
         fadeInRightOnEnterAnimation(),
         fadeInUpAnimation(),
         fadeInUpOnEnterAnimation(),
         fadeInDownBigAnimation(),
         fadeInDownBigOnEnterAnimation(),
         fadeInLeftBigAnimation(),
         fadeInLeftBigOnEnterAnimation(),
         fadeInRightBigAnimation(),
         fadeInRightBigOnEnterAnimation(),
         fadeInUpBigAnimation(),
         fadeInUpBigOnEnterAnimation(),

         fadeOutAnimation(),
         fadeOutOnLeaveAnimation(),
         fadeOutDownAnimation(),
         fadeOutDownOnLeaveAnimation(),
         fadeOutLeftAnimation(),
         fadeOutLeftOnLeaveAnimation(),
         fadeOutRightAnimation(),
         fadeOutRightOnLeaveAnimation(),
         fadeOutUpAnimation(),
         fadeOutUpOnLeaveAnimation(),
         fadeOutDownBigAnimation(),
         fadeOutDownBigOnLeaveAnimation(),
         fadeOutLeftBigAnimation(),
         fadeOutLeftBigOnLeaveAnimation(),
         fadeOutRightBigAnimation(),
         fadeOutRightBigOnLeaveAnimation(),
         fadeOutUpBigAnimation(),
         fadeOutUpBigOnLeaveAnimation(),

         flipAnimation(),
         flipOnEnterAnimation(),
         flipInXAnimation(),
         flipInXOnEnterAnimation(),
         flipInYAnimation(),
         flipInYOnEnterAnimation(),
         flipOutXAnimation(),
         flipOutXOnLeaveAnimation(),
         flipOutYAnimation(),
         flipOutYOnLeaveAnimation(),

         lightSpeedInAnimation(),
         lightSpeedInOnEnterAnimation(),
         lightSpeedOutAnimation(),
         lightSpeedOutOnLeaveAnimation(),

         slideInDownAnimation(),
         slideInDownOnEnterAnimation(),
         slideInLeftAnimation(),
         slideInLeftOnEnterAnimation(),
         slideInRightAnimation(),
         slideInRightOnEnterAnimation(),
         slideInUpAnimation(),
         slideInUpOnEnterAnimation(),

         slideOutDownAnimation(),
         slideOutDownOnLeaveAnimation(),
         slideOutLeftAnimation(),
         slideOutLeftOnLeaveAnimation(),
         slideOutRightAnimation(),
         slideOutRightOnLeaveAnimation(),
         slideOutUpAnimation(),
         slideOutUpOnLeaveAnimation(),

         zoomInAnimation(),
         zoomInOnEnterAnimation(),
         zoomInDownAnimation(),
         zoomInDownOnEnterAnimation(),
         zoomInLeftAnimation(),
         zoomInLeftOnEnterAnimation(),
         zoomInRightAnimation(),
         zoomInRightOnEnterAnimation(),
         zoomInUpAnimation(),
         zoomInUpOnEnterAnimation(),

         zoomOutAnimation(),
         zoomOutOnLeaveAnimation(),
         zoomOutDownAnimation(),
         zoomOutDownOnLeaveAnimation(),
         zoomOutLeftAnimation(),
         zoomOutLeftOnLeaveAnimation(),
         zoomOutRightAnimation(),
         zoomOutRightOnLeaveAnimation(),
         zoomOutUpAnimation(),
         zoomOutUpOnLeaveAnimation(),
];
