import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


import {
	User, UserService, SubMenu, Menu, SubMenuService,
	Permission, PermissionService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'menuGroup-formSubMenu',
	templateUrl: './formSubMenu.component.html',
	styleUrls: ['./formSubMenu.component.scss']
})
export class FormSubMenuComponent implements OnInit, OnDestroy {

	selectedMenuId: string = '';
	dialogTitle: string = 'Create';
	authUser: User;
	subMenu: SubMenu;
	menu: Menu;
	menuData: any;
	action: string;
	menuForm: UntypedFormGroup;

	allPermissions: Permission[] = [];

	menuGroupTypes = [
		{ name: 'collapse', id: '1' },
		{ name: 'item', id: '2' }
	];

	constructor(public userService: UserService,
		public subMenuService: SubMenuService,
		private route: ActivatedRoute,
		public permissionService: PermissionService,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<FormSubMenuComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {

		this.getInit(_data);

		this.permissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.allPermissions = data;
		});
		this.subMenuService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.subMenu = data;
			this.menuForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.selectedMenuId = data.menu_id;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New ' + data.title;
				break;
			case 'edit':
				this.dialogTitle = 'Edit ' + data.title;
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			menu_id: [this.subMenu.menu_id || this.selectedMenuId],
			title: [this.subMenu.title || null, [Validators.required, Validators.minLength(3)]],
			type: [this.subMenu.type || null, [Validators.required]],
			translate: [this.subMenu.translate || null, [Validators.required]],
			icon: [this.subMenu.icon || null],
			route: [this.subMenu.route || null],
			order: [this.subMenu.order || null, [Validators.required]],
			permission: [null],
			is_hidden: [this.subMenu.is_hidden || 0, [Validators.required]],
			is_active: [this.subMenu.is_active || 1, [Validators.required]],
			is_open_new_tab: [this.subMenu.is_open_new_tab || 0, [Validators.required]],
		});
	}

}
