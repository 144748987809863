import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	InstagramService, Instagram,
	CommonService,
	FeedService, Feed,
	PeopleService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'people-conversation-instagram-history',
	templateUrl: './conversationInstagramHistory.component.html',
	styleUrls: ['./conversationInstagramHistory.component.scss']
})

export class ConversationInstagramHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeInstagram: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Conversation Instagram History';
	authUser: User;
	people: any;
	conversationInstagramHistory: UntypedFormGroup;
	ticketClassName: string = null;

	instagrams: Instagram[] = [];
	instagramParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;
	mediaImage: string;
	subLoader: boolean = false;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public instagramService: InstagramService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<ConversationInstagramHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.instagramService.resetParams();
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
	}

	ngOnInit() {
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.people = data;
		});
		this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => this.instagramParam = data);
		this.instagramService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.instagrams = data;
		});
		this.doLoader();
		this.getConversations();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.instagramService.changeAllItems([]);
		this.instagramService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Conversation Instagram History';
				break;
			default:
				break;
		}
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	getConversations() {
		if (this._data && this._data.id) {
			let sourceFrom = this._data.sourceFrom;
			if (sourceFrom == 'people') {
				switch (this._data.id) {
					case "Instagram\\Models\\Instagram": case "Instagram": case "Instagram\\Models\\InstagramTicket":
						this.instagramParam.client_site_id = this.people.client_site_id;
						this.instagramParam.people_id = this.people.id;
						this.instagramParam.sort = "updated_at|DESC";
						this.instagramParam.is_history = 1;
						this.instagramService.getAllItems(this.instagramParam);
						break;
					default:
						break;
				}
			} else {

				switch (this._data.id) {
					case "Instagram\\Models\\Instagram": case "Instagram": case "Instagram\\Models\\InstagramTicket": case "instagram_ticket":
						this.instagramParam.client_site_id = this.people.client_site_id;
						this.instagramParam.people_id = this.people.id;
						this.instagramParam.sort = "updated_at|DESC";
						this.instagramParam.is_history = 1;
						this.instagramService.getAllItems(this.instagramParam);
						break;
					default:
						break;
				}

			}
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.instagramService.changeAllItems([]);
		this.instagramService.unSubscribe();
	}

	/** Close Feed */
	closeAllFeed(feed): void {
		let closeDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'close',
				message: 'Are you sure want to Close all Open Conversation from this media ? So, that you may lose your un-assigned conversations.',
				resource: feed,
				clientSiteId: (feed) ? feed.client_site_id : null,
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let formData: any = null;
				switch (feed.siteName) {
					case "Instagram":
						formData = {
							'mediaId': (feed && feed.resource && feed.resource.mediaId) ? feed.resource.mediaId : null,
							'mediaSource': 'open-conversation',
							'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
							'title': 'Ticket Closed'
						};
						break;
					case "Youtube":
						formData = {
							'mediaId': (feed && feed.resource && feed.resource.videoId) ? feed.resource.videoId : null,
							'mediaSource': 'open-conversation',
							'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
							'title': 'Ticket Closed'
						};
						break;
					default:
						break;
				}
				// if (formData) {
				// 	this.feedService.close(feed.id, formData, 'feed-close-allbymedia');
				// 	setTimeout(() => {
				// 		this.feedService.getAllItems();
				// 	}, 100);
				// }
			}
		});
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}
}
