export class Contact {
    name: string;
    email: string;
    message: string;
    phone?: string;
    subject: string;

    /**
     * Constructor
     *
     * @param contact
     */
    constructor(contact) {
        this.name = contact.name || null;
        this.email = contact.email || null;
        this.subject = contact.subject || null;
        this.message = contact.message || null;
    }
}
