import { Component, ElementRef, OnDestroy, Input, CUSTOM_ELEMENTS_SCHEMA, NgModule, OnInit, QueryList, ContentChild, ViewChild, EventEmitter, Output, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, pipe } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { LabelChangeFormComponent } from 'src/app/library/forms/labelChangeForm/labelChangeForm.component';
import { IntractiveMessageViewComponent } from 'src/app/appAccount/intractiveMessage/intractiveMessage-view/intractiveMessage-view.component';
import * as moment from 'moment-timezone';

import {
	User, UserService, Media,
	WhatsappTicketService, WhatsappTicket,
	MessengerTicketService, MessengerTicket,
	InstagramTicketService, InstagramTicket,
	InstadmTicketService, InstadmTicket,
	TweetTicketService, TweetTicket,
	TweetdmTicketService, TweetdmTicket,
	YoutubeTicketService, YoutubeTicket,
	WebchatTicketService, WebchatTicket,
	LoaderService, PeopleModel, LeadsHistoryService,
	ChatService, CommonService, PeopleService, People, ModalHasLabelService, AlertService, ProductCartService, FlagService, ModalHasFlagService, MediaService, AicollectionService,
	TemplateMessageService,
	OpenTicketsService
} from 'src/app/@tji/_dbShare';
import { TemplateFormComponent } from 'src/app/library/forms/templateForm/templateForm.component';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

@UntilDestroy()
@Component({
	selector: 'agent-dashboard-ticket-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
	@Output() onViewGallery = new EventEmitter();
	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
	ticket: any = null;
	siteName: string;
	@Output() onLeftSideView = new EventEmitter();
	@Output() onRightSideView = new EventEmitter();
	@Output() onExitTicket = new EventEmitter();
	// @ViewChild('scrollMe') private myScrollContainer: ElementRef;

	loader: boolean = false;
	textLoader: boolean = false;
	user: User;
	tickets: Array<any> = [];
	allChatLists: Array<any> = [];
	chatLists: Array<any> = [];
	replyChat: any = null;
	param: any;
	leadsHistoryParam: any;
	base_image: string = this.commonService.logo_image;
	replyForm: UntypedFormGroup;
	textArea: string = null;
	isDirectMessage: boolean = false;
	showAutoMessage: boolean = false;
	showHistoryMessage: boolean = false;
	bufferLoader: boolean = false;
	attachFormat = 'document/*';

	totalPages: number;
	currentPage: number = 0;
	observer: any;
	peopleData: any;

	status: OnlineStatusType;
	OnlineStatusType = OnlineStatusType;
	isReconnect: boolean = false;

	constructor(public chatService: ChatService,
		public userService: UserService,
		public whatsappTicketService: WhatsappTicketService,
		public messengerTicketService: MessengerTicketService,
		public instagramTicketService: InstagramTicketService,
		public instadmTicketService: InstadmTicketService,
		public tweetTicketService: TweetTicketService,
		public tweetdmTicketService: TweetdmTicketService,
		public youtubeTicketService: YoutubeTicketService,
		public webchatTicketService: WebchatTicketService,
		public commonService: CommonService,
		public peopleService: PeopleService,
		public leadsHistoryService: LeadsHistoryService,
		public modalHasLabelService: ModalHasLabelService,
		private router: Router,
		private loaderService: LoaderService,
		public matDialog: MatDialog,
		public sanitizer: DomSanitizer,
		private cd: ChangeDetectorRef,
		private _formBuilder: UntypedFormBuilder,
		private alertService: AlertService,
		public productCartService: ProductCartService,
		public flagService: FlagService,
		public modalHasFlagService: ModalHasFlagService,
		public mediaService: MediaService,
		private onlineStatusService: OnlineStatusService,
		public templateMessageService: TemplateMessageService,
		public openTicketsService: OpenTicketsService,
		public aicollectionService: AicollectionService,) {
		this.userService.user.subscribe(data => { this.user = data });
		this.modalHasFlagService.params.pipe(untilDestroyed(this)).subscribe(data => this.ticketFlagParam = data);
		this.flagService.params.pipe(untilDestroyed(this)).subscribe(data => this.flagParam = data);
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);
		this.chatService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.chatService.textLoader.pipe(untilDestroyed(this)).subscribe(data => this.textLoader = data);
		this.chatService.autoMessage.pipe(untilDestroyed(this)).subscribe(data => this.showAutoMessage = data);
		this.chatService.setHistoryButton.pipe(untilDestroyed(this)).subscribe(data => this.showHistoryMessage = data);
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => this.peopleData = data);
		this.chatService.textQuickMsg.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data["isSend"]) {
				this.replyForm.controls.input.setValue(data["text"]);
				var quickMsg = {
					text: '',
					isSend: false,
				}
				this.chatService.changeQuickMsg(quickMsg);
			}
		});

		this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
			if (status == 1 && this.ticket && this.ticket.id) {
				this.loaders();
				this.isReconnect = true;
				this.chatService.changeTicket(this.ticket);
			}
			
		});

		this.changeTicket();
	}

	ngOnInit() {
		this.dataInit();
		this.doLoader();
		this.setCloseButton();
		this.processMediaEvent();
	}

	ngOnDestroy(): void { }

	ngAfterViewInit() {
		// this.scrollToBottom();
		this.cd.detectChanges();
	}

	loaders() {
		setTimeout(() => {
			this.isReconnect = false;
		}, 2000);
	}

	// disableScrollDown = false;
	// onScrollButtom() {
	// 	let element = this.myScrollContainer.nativeElement
	// 	let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
	// 	if (this.disableScrollDown && atBottom) {
	// 		this.disableScrollDown = false;
	// 	} else {
	// 		this.disableScrollDown = true;
	// 	}
	// }

	// scrollToBottom(): void {
	// 	if (this.disableScrollDown) {
	// 		return
	// 	}
	// 	try {
	// 		this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
	// 	} catch (err) { }
	// }

	dataInit() {
		this.chatService.siteName.pipe(untilDestroyed(this)).subscribe(data => this.siteName = data);
		this.getTickets();
		this.getSelectedTicket();
		this.getChatMessages();
		this.chatService.replyChat.pipe(untilDestroyed(this)).subscribe(data => {
			this.replyChat = data;
		});
		this.loaderService.adSendMsgLoader.subscribe(data => this.bufferLoader = data);
		this.replyForm = this.createForm();

		this.productCartService.summaryCartDetails.subscribe(data => {
			var productSummaryData = [];

			if (this.productCartService.cartSummaryData && data) {
				data.product.forEach((element, index, array) => {
					// if (index === array.length - 1) {
					// 	var totalProdAmounts = (element.item_price * element.quantity);
					// 	var totalProdAmountsDetails = totalProdAmounts.toString()
					// 	productSummaryData.push(element.product_retailer_id + '(', element.quantity + ') - ', element.currency + ' ', parseFloat(totalProdAmountsDetails).toFixed(3) + '\n\n');
					// } else {
					// 	productSummaryData.push(element.product_retailer_id + '(', element.quantity + ') - ', element.currency + ' ', parseFloat(element.item_price).toFixed(3) + '\n');
					// }
					if (element.quantity > 1) {
						var totalProdAmounts = (element.item_price * element.quantity);
						var totalProdAmountsDetails = totalProdAmounts.toString();
						productSummaryData.push(element.product_retailer_id + '(', element.quantity + ') - ', element.currency + ' ', parseFloat(totalProdAmountsDetails).toFixed(3) + '\n');
					} else if (element.quantity == 1) {
						productSummaryData.push(element.product_retailer_id + '(', element.quantity + ') - ', element.currency + ' ', parseFloat(element.item_price).toFixed(3) + '\n');
					} else {
						// productSummaryData.push(element.product_retailer_id + '(', element.quantity + ') - ', '0.000' + ' ', 0.000 + '\n');
					}
				});

				productSummaryData.unshift('*Summary*' + '\n\n');
				productSummaryData.push('\n' + 'Delivery Charges: ' + parseFloat(data.deliveryCharges).toFixed(3) + '\n');
				// var totalPrice = parseInt(data.totalPrice) + parseInt(data.deliveryCharges); 
				var totalPrice = data.totalPrice //parseInt(data.totalPrice.toFixed(3));
				productSummaryData.push('Total: ' + totalPrice.toFixed(3) + '\n\n');

				if (data && data.paymentMode) {
					if (data.paymentMode == 1) {
						productSummaryData.push('*Payment Method*' + '\n' + "Cash on Delivery (COD)" + '\n\n');
					}
					if (data.paymentMode == 2) {
						productSummaryData.push('*Payment Method*' + '\n' + "Online Payment" + '\n\n');
					}
				}

				if (data && data.payment && data.payment.paymentMethod) {
					if (data.payment.paymentMethod == 1) {
						productSummaryData.push('*Payment Method*' + '\n' + "Cash on Delivery (COD)" + '\n\n');
					}
					if (data.payment.paymentMethod == 2) {
						productSummaryData.push('*Payment Method*' + '\n' + "Online Payment" + '\n\n');
					}
				}

				if (data.payment && data.payment.paymentsInfo && data.payment.paymentsInfo.payment_link) {
					productSummaryData.push('*Payment Request Link*' + '\n' + data.payment.paymentsInfo.payment_link + '\n\n');
				}

				this.ticket.whatsapp = productSummaryData.join("");
				this.replyForm.controls.input.setValue(productSummaryData.join(""));
				this.reply();
				this.productCartService.cartSummaryData = false;
			}
		});

		this.aicollectionService.isSendPayment.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data['_id']) {
				var paymentSummaryData = [];
				paymentSummaryData.unshift('*Payment Details*' + '\n\n')
				paymentSummaryData.push(data['currency'] + ' : ' + data['amount'] + '\n')
				// paymentSummaryData.push('*Payment Link Expired Date*' + moment(data['amount'] * 1000).format("DD-MMM-yyy-hh:mm a") + '\n')
				paymentSummaryData.push('Payment Request Link ' + '\n' + data['payment_link'] + '\n\n')

				this.ticket.whatsapp = paymentSummaryData.join("");
				this.replyForm.controls.input.setValue(paymentSummaryData.join(""));
				this.reply();
				this.aicollectionService.isSendPaymentData.next([]);
			}

		});

		this.productCartService.cartDetails.subscribe(data => {
			if (data) {
				if (this.productCartService.cartClientPanel) {
					this.onRightSideView.emit('cartDetails')
				}
			}
		});
	}

	doLoader() {
		this.loader = true;
		setTimeout(() => {
			this.loader = false;
		}, 15000);
	}

	doDirectMessage(event: boolean) {
		this.isDirectMessage = event;
		this.replyForm.patchValue({
			is_direct_message: this.isDirectMessage
		});
	}

	// intersectionObserver() {
	// 	let options = {
	// 		root: null,
	// 		rootMargin: '0px',
	// 		threshold: 0.5,
	// 	};
	// 	this.observer = new IntersectionObserver((entries) => {
	// 		if (entries[0].isIntersecting) {
	// 			this.chatService.onScroll();
	// 		}
	// 	}, options);
	// }

	getTickets() {
		if (this.siteName && this.siteName != null && this.siteName != '') {
			this.openTicketsService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
			this.openTicketsService.item.pipe(untilDestroyed(this)).subscribe(data => {
				let ticketItem = data;
				if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
					ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
					ticketItem !== this.ticket) {
					this.chatService.changeTicketSource(ticketItem);
				}
			});
			switch (this.siteName) {
				case 'Whatsapp':
				case 'whatsapp':
					this.whatsappTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.whatsappTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Instagram':
				case 'instagram':
					this.instagramTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.instagramTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Instadm':
				case 'instadm':
					this.instadmTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.instadmTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Messenger':
				case 'messenger':
					this.messengerTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.messengerTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Twitter':
				case 'twitter':
					this.tweetTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.tweetTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Twitterdm':
				case 'twitterdm':
					this.tweetdmTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.tweetdmTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Webchat':
				case 'webchat':
					this.webchatTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.webchatTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
				case 'Youtube':
				case 'youtube':
					this.youtubeTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
					this.youtubeTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
						let ticketItem = data;
						if (ticketItem && ticketItem.id && this.ticket && this.ticket.id &&
							ticketItem.id === this.ticket.id && ticketItem.siteName === this.ticket.siteName &&
							ticketItem !== this.ticket) {
							this.chatService.changeTicketSource(ticketItem);
						}
					});
					break;
				default:
					break;
			}
		}
	}

	getSelectedTicket() {
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			this.isDirectMessage = (this.ticket && this.ticket.isDirectMessage) ? true : false;
			this.replyForm = this.createForm();
			this.cd.detectChanges();
		});
	}

	getLeadsHistory(ticket) {
		this.leadsHistoryService.resetParams();
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}

	getChatMessages() {
		this.chatService.chatMessages.pipe(untilDestroyed(this)).subscribe(data => {
			if (data != this.allChatLists) {
				this.loaderService.changeAdSendMsgLoader(false);
				this.chatService.changeTextLoader(false);
			}
			data = data.filter(function (e) { return e });
			if (data) {
				data.forEach(element => {
					if (element && element.media == 'contacts' && typeof element.text == 'string') {
						element.text = JSON.parse(element.text)
					}
					if (element && element.reply && element.reply.media == 'contacts' && typeof element.reply.text == 'string') {
						element.reply.text = JSON.parse(element.reply.text)
					}
				});

			}

			if (this.showHistoryMessage && this.showAutoMessage) {
				this.chatLists = data.filter((x, i) => {
					if (this.ticket && this.ticket.siteName && (this.ticket.siteName === 'Instagram' || this.ticket.siteName === 'instagram')) {
						return true;
					} else {
						return ((x?.people_id || i === 0) &&
							this.ticket && this.ticket.people_id &&
							x.people_id === this.ticket.people_id) ? true : false;
					}
				});
			}
			if (this.showHistoryMessage && !this.showAutoMessage) {
				this.chatLists = data.filter((y, i) => {
					return ((!y.is_itrs || i === 0) &&
						this.ticket && this.ticket.people_id &&
						y.people_id === this.ticket.people_id) ? true : false;
				});
			}
			if (!this.showHistoryMessage && this.showAutoMessage) {
				this.chatLists = data.filter((x, i) => {
					return ((x?.ticketId && this.ticket && x?.ticketId === this.ticket?.id) || i === 0) ? true : false;
				});
			}
			if (!this.showHistoryMessage && !this.showAutoMessage) {
				this.chatLists = data.filter((x, i) => {
					return ((x.ticketId && this.ticket && x.ticketId === this.ticket.id) || i === 0) ? true : false;
				}).filter((y, i) => {
					return (!y.is_itrs || i === 0) ? true : false;
				});
			}
			if (this.chatLists && this.chatLists.length < 1) {
				this.loader = false;
			} else { this.loader = false; }
			this.cd.detectChanges();
			this.allChatLists = this.chatLists;
		});
	}

	changeTicket() {
		this.chatService.onChangeTicket.pipe(untilDestroyed(this)).subscribe(data => {
			this.cd.detectChanges();
			if (data && (data === true || data === 'true')) {
				this.doLoader();
				this.chatService.onChangeTicket.emit(false);
				this.replyForm = this.createForm();
			}
		});
		// this.chatService.onNewMsg.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.cd.detectChanges();
		// });
	}

	getComments() {
		this.chatService.getComments(this.ticket);
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	setCloseButton() {
		this.chatService.setCloseButton.pipe(untilDestroyed(this)).subscribe(data => {
			this.closeTicket(this.ticket);
		});
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		let output: any;
		let clientSiteId = (this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : null;
		clientSiteId = (!clientSiteId && this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : clientSiteId;
		let parentId: any = this.getTicketParentId();
		if (this.siteName && this.siteName != null && this.siteName != '') {
			switch (this.siteName) {
				case 'Twitter':
				case 'twitter':
					output = this._formBuilder.group({
						input: [null, [Validators.required, Validators.minLength(1)]],
						tweet_status_id: [(this.ticket && this.ticket.tweetCode) ? this.ticket.tweetCode : null || null, [Validators.required]],
						tweet_ticket_id: [(this.ticket && this.ticket.id) ? this.ticket.id : null || null, [Validators.required]],
						client_id: [(this.ticket && this.ticket.client_id) ? this.ticket.client_id : null || null, [Validators.required]],
						client_site_id: [clientSiteId || null, [Validators.required]],
						is_direct_message: [this.isDirectMessage, [Validators.required]],
						recipient_id: [(this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null || null, [Validators.required]],
						parent_id: [parentId || null, [Validators.required]],
						ticket_id: [(this.ticket && this.ticket.id) ? this.ticket.id : null || null, [Validators.required]],
						type: ['text' || null, [Validators.required]],
						chatId: null,
					});
					break;
				case 'Twitterdm':
				case 'twitterdm':
					output = this._formBuilder.group({
						input: [null, [Validators.required, Validators.minLength(1)]],
						tweet_status_id: [(this.ticket && this.ticket.tweetCode) ? this.ticket.tweetCode : null || null, [Validators.required]],
						tweetdm_ticket_id: [(this.ticket && this.ticket.id) ? this.ticket.id : null || null, [Validators.required]],
						client_id: [(this.ticket && this.ticket.client_id) ? this.ticket.client_id : null || null, [Validators.required]],
						client_site_id: [clientSiteId || null, [Validators.required]],
						is_direct_message: [this.isDirectMessage, [Validators.required]],
						recipient_id: [(this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null || null, [Validators.required]],
						parent_id: [parentId || null, [Validators.required]],
						ticket_id: [(this.ticket && this.ticket.id) ? this.ticket.id : null || null, [Validators.required]],
						type: ['text' || null, [Validators.required]],

					});
					break;
				default:
					output = this._formBuilder.group({
						input: [null, [Validators.required, Validators.minLength(1)]],
						client_site_id: [clientSiteId || null, [Validators.required]],
						ticket_id: [(this.ticket && this.ticket.id) ? this.ticket.id : null || null, [Validators.required]],
						type: ['text' || null, [Validators.required]],
						chatId: null,
					});
					break;
			}
		}
		return output;
	}

	reply() {
		this.loaderService.changeAdSendMsgLoader(true);
		if (this.replyForm && !this.replyForm.invalid) {
			if (this.replyChat && this.replyChat.id) {
				let smsMessageId = this.replyChat.id;
				this.replyForm.controls['chatId'].setValue(smsMessageId);
			}
			this.replyForm.value.input = this.replyForm.value.input.trim();
			if (this.replyForm && this.replyForm.value && this.replyForm.value.input.length == 0) {
				this.alertService.webShow('Danger', 'Please enter the message');
				this.loaderService.changeAdSendMsgLoader(false);
				return;
			}
			this.chatService.setTextLoader();
			this.chatService.post(this.replyForm.value);
			this.replyForm.reset();
			this.replyForm = this.createForm();
			this.chatService.changeReplyChat(null);
			let clientSiteId = (this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : null;
			clientSiteId = (!clientSiteId && this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : clientSiteId;
			switch (this.siteName) {
				case 'Twitter':
				case 'twitter':
					this.replyForm.patchValue({
						tweet_status_id: this.ticket.tweetCode,
						tweet_ticket_id: this.ticket.id,
						is_direct_message: this.isDirectMessage,
						client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
						client_site_id: clientSiteId,
						recipient_id: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
						parent_id: this.getTicketParentId(),
						chatId: null,
					});
					break;
				case 'Twitterdm':
				case 'twitterdm':
					this.replyForm.patchValue({
						tweet_status_id: this.ticket.tweetCode,
						tweetdm_ticket_id: this.ticket.id,
						is_direct_message: this.isDirectMessage,
						client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
						client_site_id: clientSiteId,
						recipient_id: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
						parent_id: this.getTicketParentId(),
						chatId: null,
					});
					break;
				default:
					this.replyForm.patchValue({
						to: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
						client_site_id: clientSiteId,
						client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
						parent_id: this.getTicketParentId(),
						ticket_id: (this.ticket && this.ticket.id) ? this.ticket.id : null,
						chatId: null,
					});
					break;
			}
			this.chatService.setUnreadCount();
			this.ticket.unReadCount = null;
		}
	}

	checkChatMessage(chat: any, ticket: any): boolean {
		let peopleId = (ticket && ticket.people_id) ? ticket.people_id : null;
		let siteName = (ticket && ticket.siteName) ? ticket.siteName : null;
		if (siteName && (siteName === 'instagram' || siteName === 'Instagram')) {
			return (chat && chat.id && chat.siteName === siteName) ? true : false;
		} else {
			return (chat && chat.id && chat.siteName === siteName && chat.people_id === peopleId) ? true : false;
		}
	}

	getTicketParentId() {
		let parentId = null;
		if (this.siteName && this.siteName != null && this.siteName != '') {
			switch (this.siteName) {
				case 'Whatsapp':
				case 'whatsapp':
					parentId = (this.ticket && this.ticket.whatsapp_id) ? this.ticket.whatsapp_id : null;
					break;
				case 'Instadm':
				case 'instadm':
					parentId = (this.ticket && this.ticket.instadm_id) ? this.ticket.instadm_id : null;
					break;
				case 'Instagram':
				case 'instagram':
					parentId = (this.ticket && this.ticket.instagram_id) ? this.ticket.instagram_id : null;
					break;
				case 'Messenger':
				case 'messenger':
					parentId = (this.ticket && this.ticket.messenger_id) ? this.ticket.messenger_id : null;
					break;
				case 'Twitter':
				case 'twitter':
					parentId = (this.ticket && this.ticket.tweet_id) ? this.ticket.tweet_id : null;
					break;
				case 'Twitterdm':
				case 'twitterdm':
					parentId = (this.ticket && this.ticket.tweetdm_id) ? this.ticket.tweetdm_id : null;
					break;
				case 'Webchat':
				case 'webchat':
					parentId = (this.ticket && this.ticket.webchat) ? this.ticket.webchat : null;
					break;
				case 'Youtube':
				case 'youtube':
					parentId = (this.ticket && this.ticket.youtube_id) ? this.ticket.youtube_id : null;
					break;
				default:
					break;
			}
		}
		return parentId;

	}

	exitTicket() {
		this.tweetTicketService.isParams = false;
		this.tweetdmTicketService.isParams = false;
		this.messengerTicketService.isParams = false;
		this.whatsappTicketService.isParams = false;
		this.youtubeTicketService.isParams = false;
		this.instadmTicketService.isParams = false;
		this.webchatTicketService.isParams = false;
		this.chatService.changeTicket(null);
		this.onExitTicket.emit(true);
	}

	/** Close Tickets */
	closeTicket(ticket: WhatsappTicket): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'close',
				title: 'Close Ticket',
				resource: ticket,
				clientSiteId: ticket.clientSiteId,
				message: 'Are you sure you want to Close this Ticket?',
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let i = this.tickets.indexOf(this.ticket);
				this.ticket.is_closed = true;
				ticket.is_closed = true;
				result.formValue.is_closed = true;
				this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName, "close", result.formValue);
				if (i > -1) { this.tickets.splice(i, 1); }
				this.chatService.changeTicket(null);
				this.onExitTicket.emit(true);
			}
		});
	}

	validateTextField(e) {
		if (e.keyCode === 13 && !e.ctrlKey) {
			e.preventDefault();
			this.textArea = this.textArea + "\r\n";
		}
		if (e.keyCode === 13 && e.ctrlKey) {
			this.reply();
		}
	}

	processMediaEvent() {
		this.chatService.sendMediaEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data._id) {
				var baseUrl = data.url;
				// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
				// var decodeName = baseUrl.split("/").pop()
				// let encoded = encodeURIComponent(decodeName);
				data["url"] = baseUrl;
				this.sendMedia(data);
			}
		});
	}

	sendMedia(media: Media) {
		if (this.ticket && this.ticket.id && media && media._id) {
			this.replyForm.patchValue({
				input: media.url,
				type: media.type,
			});
			this.sendMediaById();
		}
	}

	sendMediaById() {
		this.chatService.setTextLoader();
		this.loaderService.changeAdSendMsgLoader(true);
		if (this.replyChat && this.replyChat.id) {
			let smsMessageId = this.replyChat.id;
			smsMessageId = smsMessageId;
			this.replyForm.controls['chatId'].setValue(smsMessageId);
		}

		if (this.replyForm.value && !this.replyForm.value.client_site_id) {
			this.replyForm.value.client_site_id = this.ticket.clientSiteId
		}
		if (this.replyForm.value && !this.replyForm.value.ticket_id) {
			this.replyForm.value.ticket_id = this.ticket.id;
		}

		this.chatService.post(this.replyForm.value);
		this.replyForm.reset();
		this.replyForm = this.createForm();
		this.chatService.changeReplyChat(null);
		switch (this.siteName) {
			case 'Twitter':
			case 'twitter':
				this.replyForm.patchValue({
					tweet_status_id: this.ticket.tweetCode,
					tweet_ticket_id: this.ticket.id,
					is_direct_message: this.isDirectMessage,
					client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
					client_site_id: this.ticket.clientSiteId,
					recipient_id: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
					parent_id: this.getTicketParentId(),
				});
				break;
			case 'Twitterdm':
			case 'twitterdm':
				this.replyForm.patchValue({
					tweet_status_id: this.ticket.tweetCode,
					tweetdm_ticket_id: this.ticket.id,
					is_direct_message: this.isDirectMessage,
					client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
					client_site_id: this.ticket.clientSiteId,
					recipient_id: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
					parent_id: this.getTicketParentId(),
				});
				break;
			default:
				this.replyForm.patchValue({
					to: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
					client_site_id: (this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : this.ticket.clientSiteId,
					client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
					parent_id: this.getTicketParentId(),
					chatId: null,
				});
				break;
		}
		this.loaderService.changeAdSendMsgLoader(false);
		this.chatService.setUnreadCount();
		this.ticket.unReadCount = null;
	}

	typeData: string = 'image';
	mediaForm = {};
	imageHandler(event) {
		let reader = new FileReader();
		let fileData = null;
		if (event.target.files && event.target.files.length) {
			this.chatService.changeTextLoader(true);
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				var types = file.type.split("/");
				this.typeData = null;
				var typeDatas = types[1];
				switch (typeDatas) {
					case "JPEG": case "JPG":
					case "PNG": case "GIF":
					case "TIFF": case "RAW":
					case "jpeg": case "jpg":
					case "png": case "gif":
					case "tiff": case "raw":
					case "WebP": case "webp": case "WEBP":
						this.typeData = 'image'
						break;
					case "DOC": case "DOCX":
					case "ODT": case "PDF":
					case "PPT": case "PPTX":
					case "TXT": case "XLS":
					case "XLSX": case "ZIP":
					case "CVS": case "vnd.ms-excel":
					case "doc": case "csv":
					case "docx": case "odt":
					case "pdf": case "pptx":
					case "text": case "xls":
					case "xlsx": case "zip": case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
					case "cvs": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
					case "vnd.openxmlformats-officedocument.wordprocessingml.document":
						this.typeData = 'document'
						break;
					case "MP3": case "WAV":
					case "AAC": case "FLAC":
					case "Ogg": case "Ogg Vorbis":
					case "PCM": case "MPEG":
					case "mp3": case "wav":
					case "aac": case "flac":
					case "ogg": case "mpeg":
					case "ogg vorbis": case "pcm":
					case "vnd.dlna.adts":
                    case "m4a": case "M4A":
                    case "x-m4a": case "x-M4A":
						this.typeData = 'voice'
						break;
					case "WEBM": case "MPG":
					case "MP2":
					case "MPE": case "MPV":
					case "OGG": case "MP4":
					case "AVI": case "WMV":
					case "MOV": case "QT":
					case "FLV": case "SWF":
					case "AVCHD":
					case "webm": case "mpg":
					case "mp2":
					case "mpe": case "mpv":
					case "ogg": case "mp4":
					case "avi": case "wmv":
					case "mov": case "qt":
					case "flv": case "swf":
					case "avchd": case "3gpp":
                            case "3GPP":
						this.typeData = 'video'
					default:
						break;
				}
				this.mediaForm = {
					asset: reader.result,
					asset_name: file.name.replace(/ /g, "_"),
					asset_type: this.typeData,
				};
				if (this.typeData != null) {
					this.addStore(this.mediaForm);
				} else {
					this.textLoader = false;
					this.chatService.changeTextLoader(false);
					this.replyForm.reset();
					this.replyForm = this.createForm();
					this.alertService.webShow('Danger', 'This Format Not Supported Contact admin');
				}
			};
		}
	}

	imageHandlerData(event) {
		if (event.target.files[0].type === "image/jpeg" ||
			event.target.files[0].type === "image/jpg" ||
			event.target.files[0].type === "image/png") {
			this.chatService.changeTextLoader(true);
			let reader = new FileReader();
			let fileData = null;
			var filesize = event.target.files[0].size;
			const fileSizeInKB = Math.round(filesize / 1024);
			if (fileSizeInKB <= 5120) {
				this.chatService.changeTextLoader(true);
				if (event.target.files && event.target.files.length) {
					const file = event.target.files[0];
					reader.readAsDataURL(file);

					reader.onload = (e: any) => {
						this.mediaForm = {
							asset: reader.result,
							asset_name: file.name.replace(/ /g, "_"),
							asset_type: 'image',
						};
						this.addStore(this.mediaForm);
					};
				}
			} else {
				this.replyForm.reset();
				this.replyForm = this.createForm();
				this.chatService.changeTextLoader(false);
				this.alertService.webShow('Danger', 'Please Select below 5MB');
			}
		} else {
			this.replyForm.reset();
			this.replyForm = this.createForm();
			this.chatService.changeTextLoader(false);
			this.alertService.webShow('Danger', 'Image Format Not Supported, Please Select JPEG, JPG and PNG');
		}
	}

	sendFile() {
		this.chatService.setTextLoader();
		this.loaderService.changeAdSendMsgLoader(true);
		if (this.replyChat && this.replyChat.id) {
			let smsMessageId = this.replyChat.id;
			this.replyForm.get('chatId').setValue(smsMessageId);
			// this.replyForm.patchValue({ chatId: smsMessageId });
		}
		this.chatService.post(this.replyForm.value);
		this.replyForm.reset();
		this.replyForm = this.createForm();
		this.chatService.changeReplyChat(null);
		if (this.replyForm.value && !this.replyForm.value.client_site_id) {
			this.replyForm.value.client_site_id = this.ticket.clientSiteId
		}
		if (this.replyForm.value && !this.replyForm.value.ticket_id) {
			this.replyForm.value.ticket_id = this.ticket.id;
		}
		switch (this.siteName) {
			case 'Twitter':
			case 'twitter':
				this.replyForm.patchValue({
					tweet_status_id: this.ticket.tweetCode,
					tweet_ticket_id: this.ticket.id,
					is_direct_message: this.isDirectMessage,
					client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
					client_site_id: this.ticket.clientSiteId,
					recipient_id: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
					parent_id: this.getTicketParentId(),
					chatId: null,
				});
				break;
			case 'Twitterdm':
			case 'twitterdm':
				this.replyForm.patchValue({
					tweet_status_id: this.ticket.tweetCode,
					tweetdm_ticket_id: this.ticket.id,
					is_direct_message: this.isDirectMessage,
					client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
					client_site_id: this.ticket.clientSiteId,
					recipient_id: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
					parent_id: this.getTicketParentId(),
					chatId: null,
				});
				break;
			default:
				this.replyForm.patchValue({
					to: (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : null,
					client_site_id: (this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : this.ticket.clientSiteId,
					client_id: (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null,
					parent_id: this.getTicketParentId(),
					chatId: null,
				});
				break;
		}
		this.chatService.setUnreadCount();
		this.ticket.unReadCount = null;
	}

	checkHeight(scroll) { }

	/** Change Lable to Lead */
	changeLabel(ticket: any): void {
		let people: People = new PeopleModel({});
		people.id = ticket.people_id;
		people.client_id = ticket.client_id;
		let labelDialogRef = this.matDialog.open(LabelChangeFormComponent, {
			disableClose: false,
			width: '600',
			data: people
		});
		labelDialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.modalHasLabelService.modelHasLabelUpdate(formData.value);
				setTimeout(() => {
					this.peopleService.getItem({ id: people.id });
					this.chatService.getTicket(ticket.id, this.siteName);
				}, 500);

			});
	}

	getTo() {
		let output: string = '';
		if (this.siteName) {
			switch (this.siteName) {
				case 'Whatsapp': case 'whatsapp':
					output = (this.ticket && this.ticket.whatsapp) ? this.ticket.whatsapp.from : '';
					break;
				case 'Youtube': case 'youtube':
					output = (this.ticket && this.ticket.youtube) ? this.ticket.youtube.from : '';
					break;
				case 'Webchat': case 'webchat':
					output = (this.ticket && this.ticket.webchat) ? this.ticket.webchat.from : '';
					break;
				case 'Messenger': case 'messenger':
					output = (this.ticket && this.ticket.messenger) ? this.ticket.messenger.from : '';
					break;
				case 'Instagram': case 'instagram':
					output = (this.ticket && this.ticket.instagram) ? this.ticket.instagram.from : '';
					break;
				case 'Instadm': case 'instadm':
					output = (this.ticket && this.ticket.instadm) ? this.ticket.instadm.from : '';
					break;
				case 'Twitter': case 'twitter':
				case 'Twitterdm': case 'twitterdm':
					output = (this.ticket && this.ticket.tweetCode) ? this.ticket.tweetCode : '';
					break;
				default:
					output = (this.ticket && this.ticket.recipientId) ? this.ticket.recipientId : '';
					break;
			}
		}
		return output;
	}

	canSendAttachment(ticket: any): boolean {
		let siteName = (ticket && ticket.siteName) ? ticket.siteName : this.siteName;
		if (siteName && (siteName === 'Whatsapp' || siteName === 'whatsapp' ||
			siteName === 'Webchat' || siteName === 'webchat' ||
			siteName === 'Twitter' || siteName === 'twitter' ||
			siteName === 'Twitterdm' || siteName === 'twitterdm' ||
			siteName === 'Messenger' || siteName === 'messenger' ||
			siteName === 'Instagram' || siteName === 'instagram' ||
			siteName === 'Instadm' || siteName === 'instadm')) {
			return true;
		}
		return false;
	}

	canSendDocument(ticket: any): boolean {
		let siteName = (ticket && ticket.siteName) ? ticket.siteName : this.siteName;
		if (siteName && (siteName === 'Whatsapp' || siteName === 'whatsapp' ||
			siteName === 'Webchat' || siteName === 'webchat' ||
			siteName === 'Twitter' || siteName === 'twitter' ||
			siteName === 'Twitterdm' || siteName === 'twitterdm' ||
			siteName === 'Messenger' || siteName === 'messenger')) {
			return true;
		}
		return false;
	}

	canSendVideo(ticket: any): boolean {
		let siteName = (ticket && ticket.siteName) ? ticket.siteName : this.siteName;
		if (siteName && (siteName === 'Whatsapp' || siteName === 'whatsapp' ||
			siteName === 'Webchat' || siteName === 'webchat' ||
			siteName === 'Twitter' || siteName === 'twitter' ||
			siteName === 'Twitterdm' || siteName === 'twitterdm' ||
			siteName === 'Messenger' || siteName === 'messenger')) {
			return true;
		}
		return false;
	}

	canSendImage(ticket: any): boolean {
		let siteName = (ticket && ticket.siteName) ? ticket.siteName : this.siteName;
		if (siteName && (siteName === 'Whatsapp' || siteName === 'whatsapp' ||
			siteName === 'Webchat' || siteName === 'webchat' ||
			siteName === 'Twitter' || siteName === 'twitter' ||
			siteName === 'Twitterdm' || siteName === 'twitterdm' ||
			siteName === 'Messenger' || siteName === 'messenger' ||
			siteName === 'Instagram' || siteName === 'instagram' ||
			siteName === 'Instadm' || siteName === 'instadm')) {
			return true;
		}
		return false;
	}
	getComentsData() {
		this.chatService.getComments(this.ticket);
	}
	getFlagsData(ticket) {
		this.getTicketFlags(ticket);
		this.getFlags(ticket);
	}
	ticketFlagParam: any;
	flagParam: any;
	getTicketFlags(ticket) {
		if (ticket && ticket.id) {
			this.ticketFlagParam.client_id = ticket.client_id;
			this.ticketFlagParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.clientSiteId;
			this.ticketFlagParam.resource_id = ticket.id;
			this.ticketFlagParam.ticket_id = ticket.id;
			this.ticketFlagParam.resource_type = ticket.className;
			if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
				this.ticketFlagParam.resource_id && this.ticketFlagParam.resource_type) {
				this.modalHasFlagService.getAllItems();
			}
		}
	}

	getFlags(ticket) {
		this.flagParam.client_id = ticket.client_id;
		this.flagParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.clientSiteId;
		this.flagService.getAllItems();
	}

	showGallery: boolean = false;
	changeGalleryView() {
		this.showGallery = !this.showGallery;
		if (this.showGallery) {
			this.mediaService.getAllItems();
		}
	}


	exportExcel(ticket) {

		this.peopleService.getItem({ id: ticket.people_id });
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure want to Export Message',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var title = 'Export Message';
				var chatDataList: any;
				var newChatDataList: any;
				setTimeout(() => {
					chatDataList = [...this.allChatLists];
					newChatDataList = chatDataList.sort((a, b) => a.created_at - b.created_at) // this.orderByPipe.transform(chatDataList, '-created_at');
					this.downloadFile(newChatDataList, title);
				}, 2000);
			}
		});
	}

	// downloadFile
	downloadFile(mesage, title = null) {
		var excelData = [];
		if (mesage && mesage.length > 0) {
			mesage.forEach((chat, index) => {
				if (chat && chat.id > 0) {
					var data = {
						"Date and Time": moment(chat.created_at).format('DD-MM-YYYY HH:mm:ss'), //chat.updated_at,// moment(chat.updated_at + '.000+0300').local().format("DD-MMM-yyy-hh:mm a"),
						"Message Type": chat['media'],
						"Status": chat['status']
					}
					if (chat['status'] == "sent") {
						if (chat['createdByName']) {
							data["Message From"] = chat['createdByName'] + "(" + chat['replayPersonType'] + ")";
						} else {
							data["Message From"] = "Auto Response";
						}
					}
					if (chat['status'] == "received") {
						data["Message From"] = this.peopleData.name ? this.peopleData.name : '--';
					}
					if (chat['media'] == 'text') {
						var chats = chat['text'].replace(/\n/g, '')
						data["Message"] = chats.replace(/🤵::/g, '')
					} else if (chat['media'] == 'location') {
						data["Message"] = chat['location_url'];
					} else if (chat['media'] == 'image' || chat['media'] == 'document'
						|| chat['media'] == 'video' || chat['media'] == 'audio' || chat['media'] == 'voice'
						|| chat['media'] == 'file' || chat['media'] == 'link') {
						data["Message"] = chat['asset_url']
					} else {
						data["Message"] = chat['media'] + ' ' + 'Received'
					}
					// else if (chat['media'] == 'button') {
					// 	if (chat['text']['components'] && chat['text']['components'].length > 0) {
					// 		chat['text']['components'].forEach(button => {
					// 			if (button.isActive) {
					// 				data["Message"] = button.value;
					// 			}
					// 		});
					// 	} else {
					// 		data["Message"] = chat['text']
					// 	}

					// } else if (chat['media'] == 'interactive') {
					// 	if (chat['interactiveData']['buttons'] && chat['interactiveData']['buttons'].length > 0) {
					// 		var intractiveChat = []
					// 		chat['interactiveData']['buttons'].forEach(interactiv => {
					// 				intractiveChat.push(interactiv.text)
					// 				data["Message"] = interactiv.text;
					// 		});
					// 	} else {
					// 		data["Message"] = chat['text']
					// 	}

					// }
					excelData.push(data);
				}
			});
			let arrHeader = ["Date and Time", "Message From", "Status", "Message Type", "Message"];
			let csvData = this.ConvertToCSV(excelData, arrHeader);
			let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
			// this.downLoadLoader = false;
			let dwldLink = document.createElement("a");
			let url = URL.createObjectURL(blob);
			let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
			if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
				dwldLink.setAttribute("target", "_blank");
			}
			dwldLink.setAttribute("href", url);
			var currentTime = new Date().toJSON();
			dwldLink.setAttribute("download", 'chat_reports_' + currentTime + '.csv');
			dwldLink.style.visibility = "hidden";
			document.body.appendChild(dwldLink);
			dwldLink.click();
			document.body.removeChild(dwldLink);

		} else {
			this.alertService.webShow('Danger', 'Chat Data is not Loading');
		}

	}
	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Date and Time", "Message From", "Status", "Message Type", "Message"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}

	unSubscribe() {
		// console.log('UnSubscribed MediaService');
	}

	isImageUpdated: boolean = false;

	addStore(data: any) {
		this.isImageUpdated = false;
		const timeoutId = setTimeout(() => {
			this.isImageUpdated = true;
			this.chatService.changeTextLoader(false);
			this.replyForm.reset();
			this.replyForm = this.createForm();
			this.alertService.webShow('Danger', 'Timeout Try again !!!');
			this.imageHandler(null);
			this.imageHandlerData(null);
		}, 30000);

		this.commonService.storeMedia('media/add', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success && !this.isImageUpdated) {
					clearTimeout(timeoutId)
					var baseUrl = res.data.url;
					// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
					// var filename = res.data.name;
					// let encoded = encodeURIComponent(filename);
					// var finalUrl = afterWith + encoded;
					this.replyForm.patchValue({
						input: baseUrl,
						type: data.asset_type
					});
					this.chatService.changeTextLoader(false);
					this.sendFile();
				} else {
					this.mediaService.getAllItems();
					this.chatService.changeTextLoader(false);
					this.replyForm.reset();
					this.replyForm = this.createForm();
					this.imageHandler(null);
					this.imageHandlerData(null);
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.chatService.changeTextLoader(false);
					this.replyForm.reset();
					this.replyForm = this.createForm();
					this.imageHandler(null);
					this.imageHandlerData(null);
				}
			);
	}
	/** Send Template Message to Lead */
	sendTemplateMessage(): void {
		this.peopleService.checkLeadReserve({"people_id":this.peopleData.id});
		this.peopleService.changeItem(this.peopleData);
		let templateDialogRef = this.matDialog.open(TemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: this.peopleData
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				this.templateMessageService.postUrl(result, 'send-bulk-template');
			}
		});
	}
}
