import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Eorders, EordersService, EventService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-order-status-update',
  templateUrl: './order-status-update.component.html',
  styleUrls: ['./order-status-update.component.scss']
})
export class OrderStatusUpdateComponent implements OnInit {

  dialogTitle: string = 'Change Order Status';
  Orders: Eorders;
  selectedOrderName: number;
  action: string;
  statusInfoData: any;

  constructor(public eordersService: EordersService, public eventService: EventService,
    public matDialogRef: MatDialogRef<OrderStatusUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.eordersService.item.pipe(untilDestroyed(this)).subscribe(data => this.Orders = data);
    this.eordersService.statusInfo.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['length'] > 0) {
        this.statusInfoData = data;
      }
    });
  }

  ngOnInit() {
  }

  /** On destroy */
  ngOnDestroy(): void { }

  onSelectOrderChnage(value) {
    if (value) {
      this.statusInfoData.forEach(status => {
        if (status.id == value) {
          this.selectedOrderName = status.status;
        }
      });
    }
  }
  
}
