export class IntractiveGroupModel {
	id: number;
	intractive_id: number;
	text: string;
	description: string;
	json: string;
	order: number;
	type_id: number;
	client_id: number;
	consumer_group_id: number;
	image_asset:Array<any>;
	imageAssets: Array<any>;
	videoAssets: Array<any>;
	next_intractive_id: number;
	intractive_message_id: number;
	is_active: boolean;
	is_closeable: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	typeName?: string;

	tableName: string;
	consumerGroupName?: string;
	consumerAction?: string;
	intractiveMessageName?: string;

	/**
	 * Constructor
	 *
	 * @param intractive_group
	 */
	constructor(intractive_group) {
		this.id = intractive_group.id || '';
		this.intractive_id = intractive_group.intractive_id || '';
		this.text = intractive_group.text || '';
		this.description = intractive_group.description || '';
		this.json = intractive_group.json || '';
		this.order = intractive_group.order || '';
		this.type_id = intractive_group.type_id || '';
		this.client_id = intractive_group.client_id || '';
		this.consumer_group_id = intractive_group.consumer_group_id || '';
		this.image_asset = intractive_group.image_asset || '';
		this.next_intractive_id = intractive_group.next_intractive_id || '';
		this.intractive_message_id = intractive_group.intractive_message_id || '';
		this.is_active = intractive_group.is_active || '';
		this.is_closeable = intractive_group.is_closeable || '';
		this.created_by = intractive_group.created_by || '';
		this.updated_by = intractive_group.updated_by || '';
		this.created_at = intractive_group.created_at || '';
		this.updated_at = intractive_group.updated_at || '';

		this.tableName = intractive_group.tableName || '';
	}
}
