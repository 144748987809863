import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	AgentService, Agent,
	PermissionService, Permission
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-item-permission',
	templateUrl: './permission.component.html',
	styleUrls: ['./permission.component.scss']
})

export class PermissionComponent implements OnInit, OnDestroy {
	authUser: User;
	agent: Agent;
	permissions: any;
	groupedPermissions: any[] = [];
	param: any;
	permissionsData: any;
	loader: boolean = true;
	subLoader: boolean = false;

	constructor(public userService: UserService,
		public agentService: AgentService,
		public permissionService: PermissionService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private route: ActivatedRoute) {
		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => this.agent = data);
		this.permissionService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.permissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.permissionsData) { this.subLoader = false; }
			this.permissionsData = data;
			if (this.permissionsData && this.permissionsData['modules']) {
				this.permissionsData['modules'].forEach(element => {
					element['permission'] = [];
				});
				this.permissionsData['modules'].forEach((module, index) => {
					this.permissionsData['allowed_permissions'].forEach(permission => {
						if (module.name == permission.module) {
							this.permissionsData['modules'][index]['permission'].push(permission);
						}
					});
				});
				this.permissions = this.permissionsData;
			}
		});
		this.permissionService.groupedItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.groupedPermissions = data;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.getPermissions();
		// console.log('agent data', this.agent);
		// this.agentService.getItem({ id: this.agent.id });
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.agentService.unSubscribeFilter();
		this.permissionService.unSubscribeFilter();
	}

	getPermissions() {
		// console.log("getPermissions", this.route.snapshot.params.id)
		var data = {
			id: this.route.snapshot.params.id,
		}
		this.permissionService.getItem(data);
	}

	// groupByModule() {
	// 	if (this.permissions && this.permissions.length > 0) {
	// 		Rx.Observable.from(this.permissions)
	// 			.groupBy(x => x.module)
	// 			.flatMap(group => group.toArray())
	// 			.map(g => {
	// 				return {
	// 					module: g[0].module,
	// 					gPermissions: g,
	// 				}
	// 			})
	// 			.toArray()
	// 			.subscribe(d => this.groupedPermissions = d);
	// 	}
	// }

	checkPermission(permissionId): boolean {
		if (this.agent && this.agent.permissionIdArray) {
			return (this.agent.permissionIdArray.includes(permissionId)) ? true : false;
		}
	}

	assignPermission(permission, event) {
		// var user_id;
		// if (this.agent && this.agent.userInfo && this.agent.userInfo.id) {
		// 	user_id = this.agent.userInfo.id;
		// }
		// let input = {
		// 	'status': (event) ? true : false,
		// 	'permission_id': permission.id,
		// 	'user_id': user_id,
		// };
		// this.agentService.assignPermission('permission/update', input);
		this.permissions.modules.forEach(modules => {
			if (modules.name == permission.module) {
				modules.permission.forEach(permissions => {
					if (permissions.id == permission.id) {
						permissions["has_permission"] = (event) ? true : false
					}
				});
			}
		});
	}

	checkDirectPermission(permission): boolean {
		if (this.agent && this.agent.directPermissionIdArray) {
			return (this.agent.directPermissionIdArray.includes(permission.id)) ? true : false;
		}
		return true;
	}

	assignPermissions(){

		var user_id;
		if (this.agent && this.agent.userInfo && this.agent.userInfo.id) {
			user_id = this.agent.userInfo.id;
		}

		var permissionList = [];
		this.permissions.modules.forEach(modules => {
				modules.permission.forEach(permissions => {
					if (permissions.has_permission) {
						permissionList.push(permissions.id)
					}
				});
		});

		let input = {
			'status': true,
			'permission_id': permissionList.toString(),
			'user_id': user_id,
		};

		this.agentService.assignPermission('permission/update', input);
	}
}
