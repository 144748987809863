import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringBetweenPipe } from 'src/app/pipes/string/stringBetween.pipe';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { RTemplateMessageService } from 'src/app/@tji/_dbShare';
import { RTemplateRouteModule } from './template-route.module';

@NgModule({
    declarations: [
        MainComponent,
		ListComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
		RTemplateRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
		ListComponent,
    ],
    providers: [
        StringBetweenPipe,
        RTemplateMessageService
    ]
})

export class RTemplateImportModule { }
