export class TweetTicketModel {
    id: number;
    code: string;
    tweet_id: number;
    client_id: number;
    agent_id: number;
    priority: string;
    is_active: boolean;
    is_closed: boolean;
    comment: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tweet?: any;
    people?: any;
    peopleName?: string;
    peopleAvatar?: string;
    people_id?: number;
    clientSiteId?: number;
    tweetCode?: string;
    recipientId?: string;
    isDirectMessage?: boolean;
    pusherSync?: string;
    abandonAt: Date;
    abandonElapsed: number;
    last_message_on?: Date;
    
    unReadCount?: number;
    tableName: string;
    className: string;
    statusName?: string;
    statusChanges?: Array<string>;
    peopleLabelId?: number;
    peopleLabelName?: string;
    peopleLabelColor?: string;
    peopleLabelColorText?: string;
    siteColor?: string;
    uName?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    siteName?: string;
    
    /**
     * Constructor
     *
     * @param tweet_ticket
     */
    constructor(tweet_ticket) {
            this.id = tweet_ticket.id || '';
            this.code = tweet_ticket.code || '';
            this.tweet_id = tweet_ticket.tweet_id || '';
            this.client_id = tweet_ticket.client_id || '';
            this.agent_id = tweet_ticket.agent_id || '';
            this.priority = tweet_ticket.priority || '';
            this.is_active = tweet_ticket.is_active || '';
            this.is_closed = tweet_ticket.is_closed || '';
            this.comment = tweet_ticket.comment || '';
            this.created_by = tweet_ticket.created_by || '';
            this.updated_by = tweet_ticket.updated_by || '';
            this.created_at = tweet_ticket.created_at || '';
            this.updated_at = tweet_ticket.updated_at || '';

            this.tableName = tweet_ticket.tableName || '';
            this.siteName = tweet_ticket.siteName || '';
    }
}
