import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MessengerService, MessengerTicketService } from 'src/app/@tji/_dbShare/messenger';
import { WhatsappService, WhatsappTicketService } from 'src/app/@tji/_dbShare/whatsapp';
import { WebchatService, WebchatTicketService } from 'src/app/@tji/_dbShare/webchat';
import { YoutubeService, YoutubeTicketService } from 'src/app/@tji/_dbShare/youtube';
import { TweetService, TweetTicketService } from 'src/app/@tji/_dbShare/twitter';
import { TweetdmService, TweetdmTicketService } from 'src/app/@tji/_dbShare/twitterdm';

declare var require: any;
var slugify = require('slugify')

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class UnSubscribeService {
	private _unsubscribeAll: Subject<any>;

	constructor(public messengerService: MessengerService,
		public messengerTicketService: MessengerTicketService,
		public whatsappService: WhatsappService,
		public whatsappTicketService: WhatsappTicketService,
		public webchatService: WebchatService,
		public webchatTicketService: WebchatTicketService,
		public youtubeService: YoutubeService,
		public youtubeTicketService: YoutubeTicketService,
		public tweetService: TweetService,
		public tweetTicketService: TweetTicketService,
		public tweetdmService: TweetdmService,
		public tweetdmTicketService: TweetdmTicketService) {
		this._unsubscribeAll = new Subject();
	}

	unSubscribeAll() {
		this.messengerTicketService.changeItem(null);
		this.messengerTicketService.changeAllItems([]);
		this.messengerTicketService.changeLibraries([]);
		this.messengerTicketService.unSubscribe();

		this.whatsappService.changeItem(null);
		this.whatsappService.changeAllItems([]);
		// this.whatsappService.changeLibraries([]);
		this.whatsappService.unSubscribe();
		
		this.whatsappTicketService.changeItem(null);
		this.whatsappTicketService.changeAllItems([]);
		this.whatsappTicketService.changeLibraries([]);
		this.whatsappTicketService.unSubscribe();

		this.webchatService.changeItem(null);
		this.webchatService.changeAllItems([]);
		// this.webchatService.changeLibraries([]);
		this.webchatService.unSubscribe();
		
		this.webchatTicketService.changeItem(null);
		this.webchatTicketService.changeAllItems([]);
		this.webchatTicketService.changeLibraries([]);
		this.webchatTicketService.unSubscribe();

		this.youtubeService.changeItem(null);
		this.youtubeService.changeAllItems([]);
		// this.youtubeService.changeLibraries([]);
		this.youtubeService.unSubscribe();
		
		this.youtubeTicketService.changeItem(null);
		this.youtubeTicketService.changeAllItems([]);
		this.youtubeTicketService.changeLibraries([]);
		this.youtubeTicketService.unSubscribe();

		this.tweetService.changeItem(null);
		this.tweetService.changeAllItems([]);
		// this.tweetService.changeLibraries([]);
		this.tweetService.unSubscribe();

		this.tweetTicketService.changeItem(null);
		this.tweetTicketService.changeAllItems([]);
		this.tweetTicketService.changeLibraries([]);
		this.tweetTicketService.unSubscribe();

		this.tweetdmService.changeItem(null);
		this.tweetdmService.changeAllItems([]);
		// this.tweetdmService.changeLibraries([]);
		this.tweetdmService.unSubscribe();

		this.tweetdmTicketService.changeItem(null);
		this.tweetdmTicketService.changeAllItems([]);
		this.tweetdmTicketService.changeLibraries([]);
		this.tweetdmTicketService.unSubscribe();
	}

}