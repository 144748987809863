export class ParamModel {
	id: number;
	client_id: number;
	key: string;
	type: string;
	description: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param param
	 */
	constructor(param) {
		this.id = param.id || '';
		this.client_id = param.client_id || '';
		this.key = param.key || '';
		this.type = param.type || '';
		this.description = param.description || '';
		this.created_by = param.created_by || '';
		this.updated_by = param.updated_by || '';
		this.created_at = param.created_at || '';
		this.updated_at = param.updated_at || '';

		this.tableName = param.tableName || '';
	}
}
