import { Component, OnInit, EventEmitter, ViewEncapsulation, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import {UserService, 
        ClientToggleService, Client, NotifyService,
        GlobalService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-sidebar-client',
  templateUrl: './sidebar-client.component.html',
  styleUrls: ['./sidebar-client.component.scss']
})
export class SidebarClientComponent implements OnInit {

  @Output() onClose = new EventEmitter();

  loader: boolean = true;
  clients: Client[] = [];
  filteredClients: Client[] = [];
  selectedClient: Client = null;
  clientParam: any;
  options = [];
  filteredOptions;
  searchInput: string = '';

  constructor(public clientToggleService: ClientToggleService,
              private fb: UntypedFormBuilder,
              public notifyService: NotifyService,
              public userService: UserService,
              private router: Router,
              public globalService: GlobalService) {
      // this.getClients();
      this.clientToggleService.params.subscribe(data => this.clientParam = data);
      this.clientToggleService.item.subscribe(data => this.selectedClient = data);
      this.clientToggleService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
        this.clients = data;
        this.filteredClients = this.clients.filter(x => {
          return x.name.toString().toLowerCase().indexOf(this.searchInput.toString().toLowerCase()) > -1
        });
      });
  }

  ngOnInit() {
    this.doLoader();
  }

  doLoader() {
      setTimeout(() => {
          this.loader = !this.loader;
      }, 15000);
  }

  getClients() {
    setTimeout(() => {
      if(this.userService.isAuthUserIsAdmin()) {
      }
      if(this.userService.isAuthUserIsReseller()) {
        this.clientToggleService.resetParams();
        this.clientToggleService.getAllToggleItems();
      }
        this.clientToggleService.resetParams();
        this.clientToggleService.getAllToggleItems();
    }, 200);
  }

  filterData(enteredData) {
    this.filteredOptions = this.options.filter(item => {
      return item.name.toString().toLowerCase().indexOf(enteredData.toString().toLowerCase()) > -1
    })
  }

  onSelectedClient(client) {
    this.clientToggleService.changeItem(client);
    this.onClose.emit(true);
    setTimeout(() => {
      // this.notifyService.getUnreadCounts();
      // this.reloadCurrentRoute();
    }, 200);
  }

  onSearch() {
    this.filteredClients = [];
    this.loader = true;
    this.clientParam.search = this.searchInput;
    if(this.searchInput !== null && this.searchInput !== '') {
      // this.clientToggleService.getAllItems();
    }
    setTimeout(() => {
      this.filteredClients = this.clients.filter(x => {
        return x.name.toString().toLowerCase().indexOf(this.searchInput.toString().toLowerCase()) > -1
      });
      this.loader = !this.loader;
    }, 2000);
  }

  reloadCurrentRoute() {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
  }

}
