import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ResellerService, Reseller,
	PermissionService, Permission
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-item-permission',
	templateUrl: './permission.component.html',
	styleUrls: ['./permission.component.scss']
})

export class PermissionComponent implements OnInit, OnDestroy {
	authUser: User;
	reseller: Reseller;
	permissions: Permission[] = [];
	groupedPermissions: any[] = [];
	param: any;

	constructor(public userService: UserService,
		public resellerService: ResellerService,
		public permissionService: PermissionService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		this.resellerService.item.pipe(untilDestroyed(this)).subscribe(data => this.reseller = data);
		this.permissionService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.permissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.permissions = data;
		});
		this.permissionService.groupedItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.groupedPermissions = data;
		});
	}

	ngOnInit() {
		this.getPermissions();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getPermissions() {
		this.param.all = 1;
		this.permissionService.getAllItems();
	}

	// groupByModule() {
	// 	if (this.permissions && this.permissions.length > 0) {
	// 		Rx.Observable.from(this.permissions)
	// 			.groupBy(x => x.module)
	// 			.flatMap(group => group.toArray())
	// 			.map(g => {
	// 				return {
	// 					module: g[0].module,
	// 					gPermissions: g,
	// 				}
	// 			})
	// 			.toArray()
	// 			.subscribe(d => this.groupedPermissions = d);
	// 	}
	// }

	checkPermission(permissionId): boolean {
		if (this.reseller && this.reseller.permissionIdArray) {
			return (this.reseller.permissionIdArray.includes(permissionId)) ? true : false;
		}
	}

	assignPermission(permission, event) {
		let input = {
			'state': (event) ? 'true' : 'false',
			'permission': permission.name,
			'user_id': this.reseller.userId,
			'alert': (event) ? 'Assigned Permission - ' + permission.name + '.' : 'Revoke Permission - ' + permission.name + '.',
			'alertType': (event) ? 'Success' : 'Warning',
		};
		this.resellerService.post('person/user-map-permission', input);
	}

}
