import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductService,ProductCatalogService } from 'src/app/@tji/_dbShare';
import { CatalogCreateComponent } from './catalog/catalog-create/catalog-create.component';
import { MainComponent } from './main/main.component';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: ProductService, site: ProductCatalogService },
	},
	{
		path: 'add',
		component: CatalogCreateComponent,
		resolve: { data: ProductService, site: ProductCatalogService },
	},
	{ path: '**', redirectTo: '/app/product-catalog' }
  ,
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
})
export class ProductCatalogRoutingModule { }
;
