export class ApiDataModel {
	id: number;
	key: string;
	value: string;
	is_default: boolean;

	/**
	 * Constructor
	 *
	 * @param apiData
	 */
	constructor(apiData) {
		this.id = apiData.id || 1;
		this.key = apiData.key || '';
		this.value = apiData.value || '';
		this.is_default = apiData.is_default || false;

	}
}
