import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import { User, UserService, CommonService,
	TemplateMessageService, TemplateMessage
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'template-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})

export class TemplateViewComponent implements OnInit, OnDestroy {
	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
	@Output() onClose = new EventEmitter();
	
	loader: boolean = true;
	authUser: User;
	url: string = this.templateMessageService.url;
	template: TemplateMessage;
	templateUpdate: any;
	
	base_image: string = this.commonService.logo_image;

	constructor(public userService: UserService,
		public templateMessageService: TemplateMessageService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
			if (this.template && this.template.postData) {
				delete this.template.postData.client_id;
			}
		});
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.templateMessageService.unSubscribe();
		this.templateMessageService.changeItem(null)
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getUrl() {
		return environment.domainSecuredOne + '/template/sent';
	}
	
	getHeaders() {
		return {"Content-Type": "application/json"};
	}

	getToken(template: TemplateMessage): string {
		// let tjiToken = localStorage.getItem('tji_token');
		// if(tjiToken) {
		// 	tjiToken = "Bearer " + tjiToken.replace('"', '').replace('"', '');
		// }
		// return tjiToken;
		return template.token;
	}


}
