export class WebclipModel {
	id            : number;
	user_id       : number;
	client_id     : number;
	client_site_id: number;
	for           : string;
	otp           : string;
	token         : string;
	expire_at     : Date;
	created_by    : number;
	updated_by    : number;
	created_at    : Date;
	updated_at    : Date;

	tableName?    : string;
	className?    : string;
	clientName?: string;
	client?: any;

	/**
	 * Constructor
	 *
	 * @param webclip
	 */
	constructor(webclip) {
		this.id             = webclip.id || null;
		this.user_id        = webclip.user_id || null;
		this.client_id      = webclip.client_id || null;
		this.client_site_id = webclip.client_site_id || null;
		this.for            = webclip.for || null;
		this.otp            = webclip.otp || null;
		this.token          = webclip.token || null;
		this.expire_at      = webclip.expire_at || null;
		this.created_by     = webclip.created_by || null;
		this.updated_by     = webclip.updated_by || null;
		this.created_at     = webclip.created_at || null;
		this.updated_at     = webclip.updated_at || null;
	}
}
