import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { BranchService, BrandService,CountryService } from 'src/app/@tji/_dbShare';
import { MainBranchComponent } from './main-branch/main-branch.component';
const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: BrandService, country: CountryService },
	},
	{
		path: ':brandId/branch',
		component: MainBranchComponent,
		resolve: { data: BranchService },
	},
	{ path: '**', redirectTo: '/app/my-brand' },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BrandRoutingModule { }
