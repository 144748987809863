import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OrderByPipe } from 'ngx-pipes';
declare let $: any;
import { environment } from 'src/environments/environment';
import { PremiumDashboardService, PremiumDashboard, PremiumAlert,
    PremiumLiveMessage, EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'premium-dashboard-live-messages',
    templateUrl: './liveMessages.component.html',
    styleUrls: ['./liveMessages.component.scss'],
    animations: tjiAnimation,
    providers: [
        OrderByPipe,
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class LiveMessagesComponent {
    
    duration: number = 5000;
    animOutState: boolean = true;
    animInState: boolean = true;
    dashboardParam: any;
    liveMessages: PremiumLiveMessage[] = [];
    base_image: string = 'assets/img/no-user.jpg';
    channel: any;
    eventData: any;

    constructor(private router: Router,
                private eventService: EventService,
                public premiumDashboardService: PremiumDashboardService,
                private orderByPipe: OrderByPipe) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
        this.premiumDashboardService.liveMessages.pipe(untilDestroyed(this)).subscribe(data => {
            this.liveMessages = this.orderByPipe.transform(data, 'created_at');
        });
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit(){
        this.checkDuration();
    }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'ticketReply':
                    this.premiumDashboardService.appendLiveMessage(data, 'ticketReply');
                break;
                case 'newmessage':
                    this.premiumDashboardService.appendLiveMessage(data, 'newmessage');
                break;
                case 'aNewmessage':
                    this.premiumDashboardService.appendLiveMessage(data, 'aNewmessage');
                break;
                default:
                    break;
            }
        }
    }

    checkDuration() {
        if(this.liveMessages.length > 20) { this.duration = 500; }
        else if(this.liveMessages.length > 10) { this.duration = 700; }
        else if(this.liveMessages.length > 5) { this.duration = 800; }
        else { this.duration = 1200; }
        setTimeout(() => {
            if(this.liveMessages.length > 4) {
                this.liveMessages.shift();
            }
            this.checkDuration();
        }, this.duration);
    }

}
