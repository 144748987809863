import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import 'rxjs';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AudioService } from 'src/app/@tji/_dbShare/alert/audio.service';
import { UnSubscribeService } from 'src/app/@tji/_dbShare/unSubscribe';
import { User } from './user.interface';
import { UserModel } from './user_model.model';

import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

@Injectable({
	providedIn: 'root'
})

export class UserService {
	item: any;
	private userSource = new BehaviorSubject<User>(new UserModel({}));
	user = this.userSource.asObservable();
	checkedUser: any;

	private checkMandiatoryRes = new BehaviorSubject<boolean>(false);
	checkMandiatoryResponse = this.checkMandiatoryRes.asObservable();

	public isSearchSource = new BehaviorSubject<boolean>(true);
	isSearch = this.isSearchSource.asObservable();

	onChanged: BehaviorSubject<any>;
	onSelected: BehaviorSubject<any>;
	private _unsubscribeAll: Subject<any>;

	storedUser: User = null;
	storedPermissions: Array<string> = [];
	storedRoles: Array<string> = [];

	authResellerId: number = null;
	playSound: string = 'disable';

	constructor(private httpClient: HttpClient,
		private cookieService: CookieService,
		private commonService: CommonService,
		private unSubscribeService: UnSubscribeService,
		private router: Router,
		private audioService: AudioService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
		this.onChanged = new BehaviorSubject([]);
	}

	tji_domain: string = this.commonService.backendUrlAiProd;
	// tji_domain: string = this.commonService.backendUrlAiDev;

	noAuthHeader = new HttpHeaders({
		'Content-Type': 'application/json',
		'No-Auth': 'True'
	});

	header = new HttpHeaders({ 'Content-Type': 'application/json' });

	changeUser(user: User) {
		this.userSource.next(user);
		if (user && user.enable_sound) {
			this.audioService.changePlaySound(user.enable_sound);
		} else {
			this.audioService.changePlaySound(false);
		}
		this.setUserNotify(user);
		this.commonService.changeIsAuthAgent((user && user.person_type && user.person_type === 'agent') ? true : false);
	}

	changeAuthUser(user: User) {
		localStorage.setItem('tji_user', JSON.stringify(user));
		localStorage.setItem('availability', user.availability);
		if (user.clientId) {
			localStorage.setItem('client_id', JSON.stringify(user.clientId));
		}
		if (user.resellerId) {
			localStorage.setItem('reseller_id', JSON.stringify(user.resellerId));
		}
		this.cookieService.set('tji_roles', JSON.stringify(user.roleNames));
		this.cookieService.set('tji_permissions', JSON.stringify(user.permissionNames));
		this.userSource.next(user);
		this.setUserNotify(user);
		this.commonService.changeIsAuthAgent((user && user.person_type && user.person_type === 'agent') ? true : false);
	}

	// Get Item by Selected ID
	register(postUrl: string, data: any) {
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + postUrl, body, { headers: this.noAuthHeader }).pipe(
			map((response: Response) => {
				this.item = response;
				return this.item.data;
			}));
	}

	// Get Item by Selected ID
	login(getUrl: string, data: any): Observable<any> {
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + getUrl, body, { headers: this.noAuthHeader }).pipe(
			map((response: Response) => {
				if (response['success'] && response['data'] && response['data'].token) {
					response['data']["roleNames"] = [];
					if (response['data'] && response['data'].person_type != 'agent') {
						var roleNames = response['data'].person_type[0].toUpperCase() + response['data'].person_type.slice(1);
						response['data']["roleNames"].push(roleNames);
					}
					this.item= response;

					// if (this.item.data && this.item.data.person_type == "reseller" || this.item.data && this.item.data.person_type == null) {
					// 	this.alertService.tosterShowBeforeLogin('Danger', 'Reseller user not allowed. Please contact admit !!!');
					// 	setTimeout(() => {
					// 		this.logout('logout');
					// 	}, 100);
					// } else {
						if (this.item.data && this.item.data.person && this.item.data.person.supervisoragentids && this.item.data.person.supervisoragentids.length > 0) {
							var supervisoragentids = this.item.data.person.supervisoragentids.map(i => Number(i));
							this.item.data.person.supervisoragentids = supervisoragentids;
						}

						this.onChanged.next(this.item);
						this.commonService.tji_token = this.item.data.token;
						localStorage.setItem('tji_token', JSON.stringify(this.item.data.token));
						localStorage.setItem('tji_user', JSON.stringify(this.item.data));
						localStorage.setItem('availability', this.item.data.availability);
						this.cookieService.set('tji_token', JSON.stringify(this.item.data.token));
						this.cookieService.set('tji_roles', JSON.stringify(this.item.data.roleNames));
						this.cookieService.set('availability', this.item.data.availability);
						this.getPermission();
					// }
				} else {
					return response;
				}

			}));
	}

	keepLiveAuthUser(url = null) {
		let newUrl: string = (url) ? url : 'profile/keepAlive';
		this.commonService.getData(newUrl, {}, 'optionOne')
			.pipe()
			.subscribe(data => {
				if (data.success && data.data && data.data.token) {
					localStorage.setItem('tji_token', JSON.stringify(data.data.token));
					this.cookieService.set('tji_token', JSON.stringify(data.data.token));
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	renewUser(url = null) {
		let newUrl: string = (url) ? url : 'user';
		this.commonService.hitUrl(newUrl)
			.pipe()
			.subscribe(data => {
				this.checkedUser = data;
			},
				error => console.log('Error ::' + error)
			);
	}

	// Get Item by Selected ID
	sendResetLink(url: string, data: any) {
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.noAuthHeader }).pipe(
			map((response: Response) => {
				return response;
			}));
	}

	// Get Item by Selected ID
	passwordReset(url: string, data: any) {
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.noAuthHeader }).pipe(
			map((response: Response) => {
				return response;
			}));
	}

	// Do Change Password
	changePassword(url: string, data: any) {
		let postUrl = (url) ? url : 'change-password';
		this.commonService.storeItem(postUrl, data, true, 'optionOne')
			.subscribe(data => {
				if (data.success) {
					this.alert('Success', 'Password Changed Successfully');
				} else {
					this.alertService.webErrorShow(data);
				}

			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Please Try after sometimes !!!');
				}
			);
	}

	logout(url: string, data: any = null) {
		const body = JSON.stringify(data);
		this.unSubscribeService.unSubscribeAll();
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			map((response: any) => {
				if (response && response.success) {
					this.cookieService.deleteAll();
					this.clearCookie();
					localStorage.clear();
					this.router.navigateByUrl('/');
					window.location.reload();
					this.storedUser = null;
					this.storedRoles = [];
					this.storedPermissions = [];
				}
				return response;
			}));
		// return this.httpClient.post(this.tji_domain + url, body, { headers: this.noAuthHeader }).pipe(
		//     map((response: Response) => {
		//         localStorage.clear();
		//         this.cookieService.deleteAll();
		//         this.clearCookie();
		//         localStorage.clear();
		//         this.router.navigateByUrl('/');
		//         window.location.reload();
		//         return response;
		//     }));
	}

	authUser() {
		var user: any = JSON.parse(localStorage.getItem('tji_user'));
		// var user: any = (this.cookieService.check('tji_user')) ? JSON.parse(this.cookieService.get('tji_user')) : null;
		this.changeUser(user);
		return user;
	}

	authCheck(): boolean {
		let authUser = this.authUser();
		return (authUser && authUser.id) ? true : false;
	}

	authUserId(): number {
		let authUser = this.authUser();
		return (authUser && authUser.id) ? authUser.id : null;
	}

	isAuthUserIsClient() {
		let authUser = this.authUser();
		return (authUser && authUser.person_type && authUser.person_type === 'client') ? true : false;
	}

	isAuthUserIsSupervisor() {
		let authUser = this.authUser();
		return (authUser && authUser.person_type && authUser.person_type === 'agent' && authUser.person && authUser.person.is_supervisor && authUser.person.is_supervisor == true) ? true : false;
	}

	isAuthUserIsAdmin() {
		let authUser = this.authUser();
		return (authUser && authUser.roleNames && authUser.roleNames[0] === 'Admin') ? true : false;
	}

	isAuthUserIsAgent() {
		let authUser = this.authUser();
		return (authUser && authUser.person_type && authUser.person_type === 'agent') ? true : false;
	}

	isAuthUserIsReseller() {
		let authUser = this.authUser();
		return (authUser && authUser.person_type && authUser.person_type === 'reseller') ? true : false;
	}

	canViewAdminDashboard() {
		return (this.roleMatch(['Super Admin', 'Admin']) || this.isAuthUserIsClient()) ? true : false;
	}

	canViewResellerDashboard() {
		return (this.roleMatch(['Reseller']) || this.isAuthUserIsReseller()) ? true : false;
	}

	setCookieAuthUser(authUser) {
		this.cookieService.set('tji_user', JSON.stringify(authUser));
	}

	setCookieToken(token) {
		this.cookieService.set('tji_token', JSON.stringify(token));
		this.commonService.tji_token = token;
	}

	setCookieRoles(roles) {
		this.cookieService.set('tji_roles', JSON.stringify(roles));
	}

	setCookiePermissions(permissions) {
		this.cookieService.set('tji_permissions', JSON.stringify(permissions));
	}

	getStoredUser() {
		var user = this.storedUser;
		if (user && user.id) { return user; }
		user = JSON.parse(localStorage.getItem('tji_user'));
		if (user && user.id) {
			this.storedUser = user;
			this.storedRoles = user.roleNames;
			this.storedPermissions = user.permissionNames;
			if (!this.cookieService.check('tji_user')) { this.cookieService.set('tji_user', JSON.stringify(user)); }
			if (!this.cookieService.check('tji_roles')) { this.cookieService.set('tji_roles', JSON.stringify(user.roleNames)); }
			if (!this.cookieService.check('tji_permissions')) { this.cookieService.set('tji_permissions', JSON.stringify(user.permissionNames)); }
			if (!this.cookieService.check('language')) {
				let lang = (user.default_lang) ? user.default_lang : 'en';
				this.cookieService.set('language', lang);
			}
			return user;
		}
		user = (this.cookieService.check('tji_user')) ? JSON.parse(this.cookieService.get('tji_user')) : null;
		if (user && user.id) {
			this.storedUser = user;
			this.storedRoles = user.roleNames;
			this.storedPermissions = user.permissionNames;
			this.commonService.tji_token = user.token;
			let lang1 = (user.default_lang) ? JSON.stringify(user.default_lang) : 'en';
			localStorage.setItem('tji_token', JSON.stringify(user.token));
			localStorage.setItem('tji_user', JSON.stringify(user));
			localStorage.setItem('availability', user.availability);
			localStorage.setItem('language', lang1);
			if (user.clientId) {
				localStorage.setItem('client_id', JSON.stringify(user.clientId));
			}
			if (user.resellerId) {
				localStorage.setItem('reseller_id', JSON.stringify(user.resellerId));
			}
			if (!this.cookieService.check('tji_roles')) { this.cookieService.set('tji_roles', JSON.stringify(user.roleNames)); }
			if (!this.cookieService.check('tji_permissions')) { this.cookieService.set('tji_permissions', JSON.stringify(user.permissionNames)); }
			if (!this.cookieService.check('language')) {
				let lang2 = (user.default_lang) ? user.default_lang : 'en';
				this.cookieService.set('language', lang2);
			}
			return user;
		} else {
			this.cookieService.deleteAll();
			this.clearCookie();
			localStorage.clear();
			this.storedUser = null;
			this.storedRoles = [];
			this.storedPermissions = [];
			this.router.navigateByUrl('/');
			window.location.reload();
			return null;
		}
		return null;
	}

	getStoredRoles() {
		var roleNames: Array<string> = this.storedRoles;
		if (roleNames && roleNames.length > 0) {
			return roleNames;
		} else {
			var user = this.getStoredUser();
			roleNames = (user && user.id) ? user.roleNames : this.storedRoles;
			return roleNames;
		}
		return [];
	}

	getStoredPermissions() {
		var permissionNames: Array<string> = this.storedPermissions;
		if (permissionNames && permissionNames.length > 0) {
			return permissionNames;
		} else {
			var user = this.getStoredUser();
			permissionNames = (user && user.id) ? user.permissionNames : this.storedPermissions;
			return permissionNames;
		}
		return [];
	}

	personTypeMatch(allowedPersonTypes): boolean {
		var isMatch = false;
		if (allowedPersonTypes && allowedPersonTypes.length === 0) { return true; }
		var userPersonType: string = this.storedUser.person_type;
		if (userPersonType) {
			for (var i = 0; i < allowedPersonTypes.length; i++) {
				if (userPersonType === allowedPersonTypes[i].toLowerCase()) {
					isMatch = true;
					break;
				}
			}
		} else {
			return true;
		}
		return isMatch;
	}

	roleMatch(allowedRoles): boolean {
		var isMatch = false;
		// var userRoles: string[] = JSON.parse(localStorage.getItem('tji_roles'));
		// var userRoles: string[] = (this.cookieService.check('tji_roles')) ? JSON.parse(this.cookieService.get('tji_roles')) : null;
		// if(!userRoles) {
		// 	var user = (this.cookieService.check('tji_user')) ? JSON.parse(this.cookieService.get('tji_user')) : null;
		// 	this.cookieService.set('tji_roles', JSON.stringify(user.roleNames));
		// 	var userRoles: string[] = (this.cookieService.check('tji_roles')) ? JSON.parse(this.cookieService.get('tji_roles')) : null;
		// }
		var userRoles: Array<any> = this.getStoredRoles();
		if (userRoles) {
			allowedRoles.forEach(element => {
				if (userRoles.indexOf(element.toLowerCase()) > -1 || userRoles.indexOf(element) > -1) {
					isMatch = true;
					return false;
				}
			});
		}
		return isMatch;
	}

	permissionMatch(allowedPermissions): boolean {
		var isMatch = false;
		// var user: string[] = JSON.parse(localStorage.getItem('tji_user'));
		// var userPermissions: string[] = JSON.parse(localStorage.getItem('tji_permissions'));
		// var userPermissions: string[] = (this.cookieService.check('tji_permissions')) ? JSON.parse(this.cookieService.get('tji_permissions')) : null;
		// if(!userPermissions) {
		// 	var user = (this.cookieService.check('tji_user')) ? JSON.parse(this.cookieService.get('tji_user')) : null;
		// 	this.cookieService.set('tji_permissions', JSON.stringify(user.permissionNames));
		// 	var userPermissions: string[] = (this.cookieService.check('tji_permissions')) ? JSON.parse(this.cookieService.get('tji_permissions')) : null;
		// }
		var userPermissions: Array<any> = this.getStoredPermissions();
		if (userPermissions && userPermissions.length > 0) {
			allowedPermissions.forEach(element => {
				if (userPermissions.indexOf(element) > -1 || userPermissions.indexOf(element.toLowerCase()) > -1) {
					isMatch = true;
					return false;
				}
			});
			return isMatch;
		}
	}

	isAuthPerson($personId, $personType): boolean {
		var isAuthPerson = false;
		if ($personId && $personType) {
			let authUser = this.authUser();
			if (authUser &&
				authUser.person_id && authUser.person_id === $personId &&
				authUser.person_type && authUser.person_type === $personType) {
				isAuthPerson = true;
			}
		}
		return isAuthPerson;
	}

	getAuthClientId() {
		let authUser = this.authUser();
		if (authUser && authUser.clientId) {
			return authUser.clientId;
		}
		if (this.isAuthUserIsClient()) {
			return authUser.person_id;
		}
		if (authUser && authUser.person_type && authUser.person_type === 'agent') {
			let clientId = authUser.clientId;
			if (!clientId || clientId === undefined || clientId === null) {
				clientId = authUser.person.client_id;
			}
			return clientId;
		}
		return null;
	}

	getAuthResellerId() {
		let authUser = this.authUser();
		if (authUser && authUser.resellerId) {
			return authUser.resellerId;
		}
		if (this.isAuthUserIsReseller()) {
			return authUser.person_id;
		}
		if (authUser && authUser.person_type && (authUser.person_type === 'agent' || authUser.person_type === 'client')) {
			return authUser.resellerId;
		}
		return null;
	}

	public setUserNotify(user: User) {
		if (user && user.enable_sound) {
			this.audioService.changePlaySound(user.enable_sound ? true : false);
		} else {
			this.audioService.changePlaySound(false);
		}

		if (user && user.is_web_notify) {
			this.alertService.changeWebNotify(user.is_web_notify ? true : false);
		} else {
			this.alertService.changeWebNotify(false);
		}

		if (user && user.is_desktop_notify) {
			this.alertService.changeDesktopNotify(user.is_desktop_notify ? true : false);
		} else {
			this.alertService.changeDesktopNotify(false);
		}
		return;
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.audioService.playAudio();
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	public clearAllMemory() {
		this.cookieService.deleteAll();
		this.clearCookie();
		localStorage.clear();
		this.router.navigateByUrl('/');
		window.location.reload();
	}

	public clearCookie() {
		if (this.cookieService.check("tji_token")) {
			this.cookieService.delete("tji_token");
			this.commonService.tji_token = null;
		}
		if (this.cookieService.check("tji_user")) {
			this.cookieService.delete("tji_user");
		}
		if (this.cookieService.check("tji_permissions")) {
			this.cookieService.delete("tji_permissions");
		}
		if (this.cookieService.check("tji_roles")) {
			this.cookieService.delete("tji_roles");
		}
		if (this.cookieService.check("language")) {
			this.cookieService.delete("language");
		}
	}

	public isDefaultShowAutoMsg(): boolean {
		let authUser = this.authUser();
		let status: boolean = false;
		status = (authUser && (authUser.clientSetting.default_show_auto_message === true || authUser.clientSetting.default_show_auto_message === 'true')) ? true : false;
		return status;
	}

	public isDefaultShowHistory(): boolean {
		let authUser = this.authUser();
		let status: boolean = false;
		status = (authUser && (authUser.clientSetting.default_show_history_message === true || authUser.clientSetting.default_show_history_message === 'true')) ? true : false;
		return status;
	}

	public isSetFlagMandatory(): boolean {
		let authUser = this.authUser();
		let status: boolean = false;
		status = (authUser && (authUser.clientSetting.set_flag_mandatory === true || authUser.clientSetting.set_flag_mandatory === 'true')) ? true : false;
		return status;
	}

	public isSetCommentMandatory(): boolean {
		let authUser = this.authUser();
		let status: boolean = false;
		status = (authUser && (authUser.clientSetting.set_comment_mandatory === true || authUser.clientSetting.set_comment_mandatory === 'true')) ? true : false;
		return status;
	}

	public getProfileCompletePercentage(): number {
		let authUser = this.authUser();
		let output = (authUser && authUser.id) ? 0 : 100;
		output = (authUser && authUser.profileCompletePercentage) ? authUser.profileCompletePercentage : output;
		return output;
	}

	public checkMandatoryInputs(): boolean {
		let authUser = this.authUser();
		let output = false;

		if (authUser && authUser.person.person_details && authUser.person.person_details) {

			for (var i = 0; i < authUser.person.person_details.length; i++) {
				var requiredInputs = authUser.person.person_details;
				switch (requiredInputs) {
					case 'address':
						output = (authUser.person.detail.address != '' && authUser.person.detail.address != null) ? true : false
						break;
					case 'mobile':
						output = (authUser.person.detail.mobile != '' && authUser.person.detail.mobile != null) ? true : false
						break;
					case 'company_name':
						output = (authUser.person.detail.company_name != '' && authUser.person.detail.company_name != null) ? true : false
						break;
					case 'image_api':
						if (authUser.person.detail.image_api != '' && authUser.person.detail.image_api != null) {
							output = true;
						} else {
							output = false;
						}
						break;
					case 'business_brand_name':
						if (authUser.person.detail.business_brand_name != '' && authUser.person.detail.business_brand_name != null) {
							output = true;
						} else {
							output = false;
						}
						break;
					case 'business_category':
						if (authUser.person.detail.business_category != '' && authUser.person.detail.business_category != null) {
							output = true;
						} else {
							output = false;
						}
						break;
					case 'country':
						if (authUser.person.detail.country != '' && authUser.person.detail.country != null) {
							output = true;
						} else {
							output = false;

						}
						break;
					case 'email_verified_at':
						if (authUser.email_verified_at != '' && authUser.email_verified_at != null) {
							output = true;
						} else {
							output = false;
						}
						break;
					case 'email':
						if (authUser.email != '' && authUser.email != null) {
							output = true;
						} else {
							output = false;
						}
						break;
					default:
						break;
				}

				if (output == true) {
					continue;
				} else {
					break;
				}
			}
		}

		this.checkMandiatoryRes.next(output);

		return output;
	}

	// Check Expired User
	logoutExpiredUser(url = null) {
		let newHeader = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		let newUrl: string = (url) ? url : 'logout-expired';
		return this.httpClient.get(this.tji_domain + newUrl, { headers: newHeader }).pipe(
			map((response: any) => {
				return response;
			}));
	}

	// logout Agent User
	logoutAgentUser(url = null) {
		let newHeader = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		let newUrl: string = (url) ? url : 'forcelogoutall';
		return this.httpClient.get(this.tji_domain + newUrl, { headers: newHeader }).pipe(
			map((response: any) => {
				return response;
			}));
	}

	unSubscribe() {
		// console.log('UnSubscribed YoutubeTicketService');
	}
	getPermission() {
		this.commonService.hitUrl('permission/me', 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data && data.data && data.data.permissionNames) {
						this.item.data["permissionNames"] = data.data.permissionNames;
						this.item.data.person.directPermissionArray = data.data.permissionNames;
						this.item.data.person.directPermissionIdArray = data.data.permissionIds;
						this.item.data.person.enabledSitesName = data.data.mysites;
						this.item.data.person.permissionArray = data.data.permissionNames;
						this.item.data.person.permissionIdArray = data.data.permissionIds;
						this.item.data.person.sitesName = data.data.mysites;
						this.cookieService.set('tji_permissions', JSON.stringify(data.data.permissionNames));
						this.storedPermissions = data.data.permissionNames;
						this.cookieService.set('tji_user', JSON.stringify(this.item.data));
						localStorage.setItem('tji_user', JSON.stringify(this.item.data));
						localStorage.setItem('availability', this.item.data.availability);
						this.cookieService.set('availability', JSON.stringify(this.item.data.availability));
						if (this.item.data.clientId) {
							localStorage.setItem('client_id', JSON.stringify(this.item.data.clientId));
						}
						if (this.item.data.resellerId) {
							localStorage.setItem('reseller_id', JSON.stringify(this.item.data.resellerId));
						}
						if (this.item.data.default_lang) {
							localStorage.setItem('language', this.item.data.default_lang);
							this.cookieService.set('language', this.item.data.default_lang);
						} else {
							localStorage.setItem('language', 'en');
							this.cookieService.set('language', 'en');
						}
						this.storedUser = this.item.data;
						this.storedRoles = this.item.data.roleNames;
						this.storedPermissions = this.item.data.permissionNames;
						this.changeUser(this.item.data);
						let user = this.item.data;
						if (user && user.person) { user.person.user = null };
						this.cookieService.set('tji_user', JSON.stringify(user));
						if (this.isAuthUserIsReseller()) {
							this.commonService.changeResellerId(this.item.data.resellerId);
						}
						if (data.data.ClientSettings) {
							localStorage.setItem('client_settings', JSON.stringify(data.data.ClientSettings));
						}
						return this.item.data;
					}
				}
			},
				error => { console.log('Error ::' + error) }
			);
	}
}

