import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { Router } from '@angular/router';
import {
	User, UserService,
	RTemplateUsageReportsService, RTemplateUsageReports,
	ClientService
} from 'src/app/@tji/_dbShare';

import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { TemplateDeliveryReportComponent } from '../template-delivery-report/template-delivery-report.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
	selector: 'template-usage-list',
	templateUrl: './template-usage.component.html',
	styleUrls: ['./template-usage.component.scss'],
	providers: [OrderByPipe]
})

export class TemplateUsageComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	downloadLoader: boolean = false;
	subLoader: boolean = false;
	authUser: User;
	templateUsages: RTemplateUsageReports[] = [];
	templateUsage: RTemplateUsageReports;
	param: any;
	dataSource = new MatTableDataSource(this.templateUsages);
	displayedColumns = ['date', 'jobcode', 'sentby', 'totalCount', 'success', 'failed','rejected', 'completed', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	@ViewChild('rightSideBar') rightSideBar: MatSidenav;

	constructor(public userService: UserService,
		public rtemplateUsageReportsService: RTemplateUsageReportsService,
		public clientService: ClientService,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe, public location: Location,
		public router: Router) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.rtemplateUsageReportsService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.rtemplateUsageReportsService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rtemplateUsageReportsService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsage = data);
		this.rtemplateUsageReportsService.loader.pipe(untilDestroyed(this)).subscribe(data => this.loader = data);
		this.rtemplateUsageReportsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.templateUsages) { this.subLoader = false; }
			this.templateUsages = data;
			this.dataSource = new MatTableDataSource(this.templateUsages);
			this.dataSource.sort = this.sort;
		});
		this.rtemplateUsageReportsService.downloadLoader.pipe(untilDestroyed(this)).subscribe(data => this.downloadLoader = data);
		this.clientService.getAllIData();
	}

	ngOnInit() {
		// this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.templateUsageReportsService.unSubscribe();
		// this.templateUsageReportsService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	getSuccessCount(data: RTemplateUsageReports) {
		this.templateUsage = data;
		// this.viewPanel = 'success';
		this.router.navigate(['reseller/template-usage/' + data.id + '/success']);
	}

	getFailedCount(data: RTemplateUsageReports) {
		this.templateUsage = data;
		// this.viewPanel = 'success';
		this.router.navigate(['reseller/template-usage/' + data.id + '/failed']);
	}

	viewTemplate(data: RTemplateUsageReports) {
		this.templateUsage = data;
		// this.viewPanel = 'success';
		// this.rtemplateUsageReportsService.changeItem(this.templateUsage);
		this.router.navigate(['reseller/template-usage/' + data.id + '/view']);
	}

	syncTempCount(temp) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'update',
				message: 'Are you sure you want to update?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.rtemplateUsageReportsService.tempCountSync({ id: temp.id });
			}
		});
	}

	deliveryReport(temp = null) {
		this.rtemplateUsageReportsService.tempDeliveryReport({ id: temp.id, limit: temp.total_count ? temp.total_count : '25', current: 1 });
		let templateDialogRef = this.matDialog.open(TemplateDeliveryReportComponent, {
			disableClose: false,
			width: '600',
			data: {
				action: 'new',
				title: 'Delivery Report',
				resource: temp,
			}
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) { }
		});
	}

	toggleRightSidebar() {
		this.rightSideBar.toggle();
	}
}
