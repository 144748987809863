import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolesRouteModule } from './roles-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';
import { AssignAgentComponent } from './assignAgent/assignAgent.component';
import { AssignClientComponent } from './assignClient/assignClient.component';

import { ProfileComponent } from './itemView/profile/profile.component';
import { EmpowerComponent } from './itemView/empower/empower.component';
import { PermissionComponent } from './itemView/permission/permission.component';
import { TagComponent } from './itemView/tag/tag.component';
import { ItrsComponent } from './itemView/itrs/itrs.component';

import { RoleService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        FormComponent,
        AssignAgentComponent,
        AssignClientComponent,
        ProfileComponent,
        EmpowerComponent,
        PermissionComponent,
        TagComponent,
        ItrsComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        RolesRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ItemComponent,
    ],
    providers: [
        RoleService
    ]
})

export class RolesModule { }
