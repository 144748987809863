import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Feed } from './feed.interface';
import { FeedModel } from './feed_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';
declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class FeedService implements Resolve<any> {
	url: string = 'feeds';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		"is_ticketable": 0
	};
	// defaultParams: any = {
	// 	'paginate': 25,
	// 	'page': 1,
	// 	'search': '',
	// 	'with': 'resource,clientSite.site',
	// 	'order': 'created_at|desc,updated_at|desc',
	// };

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Feed[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<Feed>(new FeedModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Feed[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed FeedService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on FeedService');
	}

	clearSubscribe() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	concatlibrary(allItems: Feed[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Feed) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Feed) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced && item && item.id) { oldLists.push(item); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: Feed[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Feed[]) {
		if (allItems) {
			allItems = allItems.filter(function (e) { return e });
			allItems = [...new Map(allItems.map((ticket) => [ticket.id, ticket])).values()];
			if (allItems.length === 0) {
				allItems = null;
			}
		}
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: Feed) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Feed[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Feed, eventName: any = null) {
		var items: any;
		items = item;
		var ticketData = item;
		if (eventName == 'aNewfeed') {
			var dataFormatS = {
				id: ticketData.id,
				resource_type: ticketData.resource_type,
				resource_id: ticketData.resource_id,
				client_id: ticketData.client_id,
				client_site_id: ticketData.client_site_id,
				is_ticketable: ticketData.is_ticketable,
				created_by: ticketData.created_by,
				updated_by: ticketData.updated_by,
				last_message_on: ticketData.last_message_on,
				is_closeable: ticketData.is_closeable,
				updated_at: ticketData.updated_at,
				site: ticketData.clientSite?.details.slug,
				siteName: ticketData.clientSite?.details.name,
				siteId: ticketData.clientSite?.details.id,
				siteIcon: ticketData.clientSite?.details.icon,
				clientSiteName: ticketData.clientSite?.account_name,
				clientSiteTextColor: ticketData.clientSite?.textcolor,
				clientSiteColor: ticketData.clientSite?.details.color,
				created_at: ticketData.created_at //moment(ticketData.created_at + '.000+0300').local(),
			}
			if (ticketData && ticketData.ChatData) {
				dataFormatS["peopleId"] = ticketData.ChatData.people_id;
				dataFormatS["resource"] = ticketData.ChatData;
				dataFormatS["resource"]["unReadCount"] = ticketData.ChatData.unReadCount ? ticketData.ChatData.unReadCount : 1;

			}
			if (ticketData && ticketData.leadInfo) {
				dataFormatS["peopleName"] = ticketData.leadInfo?.name;
				dataFormatS["peopleLabelId"] = ticketData.leadInfo?.id;
				dataFormatS["peopleLabelName"] = ticketData.leadInfo?.peopleLabelName;
				dataFormatS["peopleLabelColor"] = ticketData.leadInfo?.peopleLabelColor;
				dataFormatS["peopleLabelColorText"] = ticketData.leadInfo?.peopleLabelColorText;
			}
			items = dataFormatS;
		}
		let lists = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result
			if (!lists) {
				lists = [];
			}
			if (lists && lists.length > 0) {
				lists = lists.concat([items]);
				isChanged = true;
			} else {
				lists[0] = items;
				isChanged = true;
			}
		});
		if (isChanged) {
			this.changeAllItems(lists);
			isChanged = false;
		}
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	// resetParams() {
	// 	const defaultParams: any = {
	// 		'limit': 25,
	// 		'current': 1,
	// 		"is_ticketable": 0
	// 	};
	// 	this.changeParams(this.paramsInit(defaultParams));
	// }

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			"is_ticketable": 0
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	// getAllItems(params: any = null) {
	// 	params = this.paramsInit(params);
	// 	this.commonService.getAll(this.url, params)
	// 		.pipe(untilDestroyed(this, 'unSubscribe'))
	// 		.subscribe(data => {
	// 			if (params.all && params.all === 1) {
	// 				this.changeAllItems(data.data);
	// 				this.changeTotalItem(data.data.length);
	// 			} else {
	// 				this.changeAllItems(data.data.data);
	// 				this.changeTotalItem(data.data.total);
	// 			}
	// 		},
	// 			error => console.log('Error ::' + error)
	// 		);
	// }

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (ticketData && ticketData.length > 0) {

						ticketData.forEach(ticketList => {
							var dataFormatS = {
								id: ticketList.id,
								resource_type: ticketList.resource_type,
								resource_id: ticketList.resource_id,
								client_id: ticketList.client_id,
								client_site_id: ticketList.client_site_id,
								is_ticketable: ticketList.is_ticketable,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								last_message_on: ticketList.last_message_on,
								updated_at: ticketList.updated_at,
								site: ticketList.ClienSiteInfo?.details.slug,
								siteName: ticketList.ClienSiteInfo?.details.name,
								siteId: ticketList.ClienSiteInfo?.details.id,
								siteIcon: ticketList.ClienSiteInfo?.details.icon,
								clientSiteName: ticketList.ClienSiteInfo?.account_name,
								clientSiteTextColor: ticketList.ClienSiteInfo?.textcolor,
								clientSiteColor: ticketList.ClienSiteInfo?.details.color,
								is_closeable: ticketList.is_closeable,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
							}
							if (additionalData && additionalData.ChatData) {
								additionalData.ChatData.forEach(ChatData => {
									if (ticketList.resource_id == ChatData.id) {
										dataFormatS["peopleId"] = ChatData.people_id;
										dataFormatS["resource"] = ChatData;
										dataFormatS["resource"]["unReadCount"] = ChatData.unReadCount ? ChatData.unReadCount : 1;
										if (additionalData && additionalData.peopleData) {
											additionalData.peopleData.forEach(people => {
												if (ChatData.people_id == people.id) {
													dataFormatS["peopleName"] = people?.name;
													if (people && people.labelInfo) {
														dataFormatS["peopleLabelId"] = people?.labelInfo?.id;
														dataFormatS["peopleLabelName"] = people?.labelInfo?.name;
														dataFormatS["peopleLabelColor"] = people?.labelInfo?.color;
														dataFormatS["peopleLabelColorText"] = people?.labelInfo?.color_text;
													}
												}
											});
										}
									}
								});
							}
							dataFormat.push(dataFormatS);
						});
					}
					this.changeAllItems(dataFormat);
					this.changeTotalItem(data.pagnitation.totalResult);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.data.last_page <= data.data.current_page) {
					params.page = data.data.last_page;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: Feed) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => lists = data);
		if (lists && lists.length > 0) {
			lists = lists.concat([item]);
		} else {
			lists[0] = item;
		}
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
			allItems = data;
			if (allItems && allItems.length > 0) {
				for (var i = 0; i < allItems.length; ++i) {
					if (allItems[i].id == id) { allItems.splice(i, 1); }
					this.removelibraryById(id);
				}
			}
		});
		this.changeAllItems(allItems);
	}

	// spliceItem(id: number) {
	// 	let allItems = [];
	// 	this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
	// 		allItems = data;
	// 		allItems = allItems.filter(function (e) { return e });
	// 		allItems = [...new Map(allItems.map((ticket) => [ticket.id, ticket])).values()];
	// 		if (allItems && allItems.length > 0) {
	// 			allItems = allItems.filter(function (e) { return e });
	// 			allItems = [...new Map(allItems.map((ticket) => [ticket?.id, ticket])).values()];
	// 			var foundIndex = allItems.findIndex(x => x?.id == id);
	// 			if (foundIndex >= 0) {
	// 				delete allItems[foundIndex];
	// 			}
	// 		}
	// 	});
	// 	if (allItems && allItems.length > 0) {
	// 		allItems = allItems.filter(function (e) { return e });
	// 		allItems = [...new Map(allItems.map((ticket) => [ticket.id, ticket])).values()];
	// 	}
	// 	this.changeAllItems(allItems);
	// }

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new FeedModel({}));
		}
	}

	store(data: any, url: string = null) {
		let postUrl: string = (url) ? url : this.url;
		this.commonService.storeItem(postUrl, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				// this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					this.commonService.apiAlertResponse('Danger', error);
				}
			);
	}

	update(id: number, data: any, url: string = null) {
		let patchUrl: string = (url) ? url : this.url;
		this.commonService.updateItem(patchUrl, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				// this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					this.commonService.apiAlertResponse('Danger', error);
				}
			);
	}

	destroy(id: number, url: string = null) {
		let deleteUrl: string = (url) ? url : this.url;
		this.commonService.deleteItem(deleteUrl, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					this.commonService.apiAlertResponse('Danger', error);
				}
			);
	}

	assign(data: any, id: number = null, url: string = null) {
		url = (url) ? url : this.url;
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data && data.success) {
					this.spliceItem(id);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					this.alert('Danger', error || 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	close(id: number, data: any, url: string = null) {
		// url = (url) ? url : this.url;
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data && data.success) {
					this.spliceItem(id);
					this.alert('Info', 'Closed Successfully !!!');
				} else {
					if (data.errors.message == 'Invalid feed!') {
						this.allItemsSource.next([]);
						this.librariesSource.next([]);
						this.getAllItems();
					} else {
						this.alertService.webErrorShow(data);
					}
				}
			},
				error => {
					console.log('Error ::' + error);
					this.commonService.apiAlertResponse('Danger', error);
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();

			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

}

