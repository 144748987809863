import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ClientService, Client,
	RPermissionService, RPermission
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'client-item-permission',
	templateUrl: './permission.component.html',
	styleUrls: ['./permission.component.scss']
})

export class PermissionComponent implements OnInit, OnDestroy {
	authUser: User;
	client: Client;
	permissions: any;
	groupedPermissions: any[] = [];
	permissionsData: any;
	param: any;
	loader: boolean = true;
	subLoader: boolean = false;

	constructor(public userService: UserService,
		public clientService: ClientService,
		public rpermissionService: RPermissionService,
		private _formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		public matDialog: MatDialog) {
		this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => this.client = data);
		this.rpermissionService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rpermissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.permissionsData) { this.subLoader = false; }
			this.permissionsData = data;
			if (this.permissionsData && this.permissionsData['modules']) {
				this.permissionsData['modules'].forEach(element => {
					element['permission'] = [];
				});
				this.permissionsData['modules'].forEach((module, index) => {
					this.permissionsData['allowed_permissions'].forEach(permission => {
						if (module.name == permission.module) {
							this.permissionsData['modules'][index]['permission'].push(permission);
						}
					});
				});
				this.permissions = this.permissionsData;
			}
		});
		this.rpermissionService.groupedItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.groupedPermissions = data;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.getPermissions();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getPermissions() {
		// this.param.all = 1;
		// this.rpermissionService.getAllItems();
		var data = {
			id: this.route.snapshot.params.id,
		}
		this.rpermissionService.getItem(data);
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 1000);
	}

	// groupByModule() {
	// 	if (this.permissions && this.permissions.length > 0) {
	// 		Rx.Observable.from(this.permissions)
	// 			.groupBy(x => x.module)
	// 			.flatMap(group => group.toArray())
	// 			.map(g => {
	// 				return {
	// 					module: g[0].module,
	// 					gPermissions: g,
	// 				}
	// 			})
	// 			.toArray()
	// 			.subscribe(d => this.groupedPermissions = d);
	// 	}
	// }

	checkPermission(permissionId): boolean {
		if (this.client && this.client.permissionIdArray) {
			return (this.client.permissionIdArray.includes(permissionId)) ? true : false;
		}
	}

	assignPermission(permission, event) {
		// let input = {
		// 	'state': (event) ? 'true' : 'false',
		// 	'permission': permission.name,
		// 	'user_id': this.client.userId,
		// 	'alert': (event) ? 'Assigned Permission - ' + permission.name + '.' : 'Revoke Permission - ' + permission.name + '.',
		// 	'alertType': (event) ? 'Success' : 'Warning',
		// };
		// this.clientService.post('person/user-map-permission', input);

		this.permissions.modules.forEach(modules => {
			if (modules.name == permission.module) {
				modules.permission.forEach(permissions => {
					if (permissions.id == permission.id) {
						permissions["has_permission"] = (event) ? true : false
					}
				});
			}
		});
		
	}

	assignPermissions(){
		this.setSubLoader();
		var user_id;
		if (this.client && this.client.userInfo && this.client.userInfo.id) {
			user_id = this.client.userInfo.id;
		}

		var permissionList = [];
		this.permissions.modules.forEach(modules => {
				modules.permission.forEach(permissions => {
					if (permissions.has_permission) {
						permissionList.push(permissions.id)
					}
				});
		});

		let input = {
			'status': true,
			'permission_id': permissionList.toString(),
			'user_id': user_id,
		};

		this.rpermissionService.assignPermission('reseller/permission/update', input);
	}

}
