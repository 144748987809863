import { Component } from '@angular/core';
import { EordersService, EventService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-order-group-list',
  templateUrl: './order-group-list.component.html',
  styleUrls: ['./order-group-list.component.scss']
})
export class OrderGroupListComponent {
  eventData: any;
  param: any;
  totalOrder: number = 0;
  totalCreated: number = 0;
  totalConfirmed: number = 0;
  totalOnprocess: number = 0;
  totalDelivered: number = 0;
  totalClosed: number = 0;
  currentItem: string = null;
  isActive: string = 'confirmed';
  dataLength: number = 0;
  constructor(public eordersService: EordersService, public eventService: EventService,) {

    this.eordersService.resetParams();
    this.eordersService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
    });

    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });

    this.eordersService.orderCount.pipe(untilDestroyed(this)).subscribe(data => {
      var count = data;
      if (count && count.length > 0) {
        count.forEach(count => {
          if (count.status == 'Total') {
            this.totalOrder = count.count;
          }
          if (count.status == 'Created') {
            this.totalCreated = count.count;
          }
          if (count.status == 'Confirmed') {
            this.totalConfirmed = count.count;
          }
          if (count.status == 'Onprocess') {
            this.totalOnprocess = count.count;
          }
          if (count.status == 'Delivered') {
            this.totalDelivered = count.count;
          }
          if (count.status == 'Closed') {
            this.totalClosed = count.count;
          }
        });
      } else {
        this.totalOrder = 0;
        this.totalCreated = 0;
        this.totalConfirmed = 0;
        this.totalOnprocess = 0;
        this.totalDelivered = 0;
        this.totalClosed = 0;
      }
    });

  }

  ngOnInit(): void {
  }

  orderTab(status: any = null) {

    this.isActive = status;
    if (status == null) {
      setTimeout(() => {
        this.currentItem = null;
        this.eordersService.isLoader.next(true);
        this.eordersService.resetParams();
        this.eordersService.getAllItems();
        this.eordersService.changeAllItems([]);
      }, 100);
    }
    if (status) {
      setTimeout(() => {
        this.currentItem = status;
        this.eordersService.isLoader.next(true);
        this.param["status"] = status;
        delete this.param["search"];
        this.eordersService.getAllItems();
        this.eordersService.changeAllItems([]);
      }, 100);

    }
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;

      switch (event) {
        case 'orderCreate':
          this.totalCreated = this.totalCreated + 1;
          this.totalOrder = this.totalOrder + 1;
          this.dataLength = this.totalCreated;
          break;
        case 'orderUpdate':
          var currentStatus = null;
          var newStatus = null
          var orderData = this.eventData.data;
          if (orderData && orderData.OrderStatusInfo && orderData.OrderStatusInfo.length > 0) {
            if (orderData.OrderStatusInfo.length > 1) {
              currentStatus = orderData.OrderStatusInfo[0].status;
              newStatus = orderData.OrderStatusInfo[1].status;
            } else {
              console.log('order socket OrderStatusInfo data failed');
            }
            if (currentStatus && currentStatus.length > 0 && newStatus && newStatus.length > 0) {
              if (currentStatus == 'Created') {
                if (this.totalCreated > 1) {
                  this.totalCreated = this.totalCreated - 1;
                } else {
                  this.totalCreated = 0;
                }
              }
              if (currentStatus == 'Confirmed') {
                if (this.totalConfirmed > 1) {
                  this.totalConfirmed = this.totalConfirmed - 1;
                } else {
                  this.totalConfirmed = 0;
                }
              }
              if (currentStatus == 'Onprocess') {
                if (this.totalOnprocess > 1) {
                  this.totalOnprocess = this.totalOnprocess - 1;
                } else {
                  this.totalOnprocess = 0;
                }
              }
              if (currentStatus == 'Closed') {
                if (this.totalClosed > 1) {
                  this.totalClosed = this.totalClosed - 1;
                } else {
                  this.totalClosed = 0;
                }
              }
              if (currentStatus == 'Delivered') {
                if (this.totalDelivered > 1) {
                  this.totalDelivered = this.totalDelivered - 1;
                } else {
                  this.totalDelivered = 0;
                }
              }

              if (newStatus == 'Confirmed') {
                this.totalConfirmed = this.totalConfirmed + 1;
                this.dataLength = this.totalConfirmed;
              }
              if (newStatus == 'Onprocess') {
                this.totalOnprocess = this.totalOnprocess + 1;
                this.dataLength = this.totalOnprocess;
              }
              if (newStatus == 'Closed') {
                this.totalClosed = this.totalClosed + 1;
                this.dataLength = this.totalClosed;
              }
              if (newStatus == 'Delivered') {
                this.totalDelivered = this.totalDelivered + 1;
                this.dataLength = this.totalDelivered;
              }
            }

          }
          break;
        case 'orderemoved':
          var currentStatus = null;
          var orderData = this.eventData.data;
          currentStatus = orderData.status;
          if (currentStatus && currentStatus.length > 0) {
            if (currentStatus == 'Created') {
              if (this.totalCreated > 1) {
                this.totalCreated = this.totalCreated - 1;
                this.totalOrder = this.totalOrder - 1;
              } else {
                this.totalCreated = 0;
              }
            }
            if (currentStatus == 'Confirmed') {
              if (this.totalConfirmed > 1) {
                this.totalConfirmed = this.totalConfirmed - 1;
                this.totalOrder = this.totalOrder - 1;
              } else {
                this.totalConfirmed = 0;
              }
            }
            if (currentStatus == 'Onprocess') {
              if (this.totalOnprocess > 1) {
                this.totalOnprocess = this.totalOnprocess - 1;
                this.totalOrder = this.totalOrder - 1;
              } else {
                this.totalOnprocess = 0;
              }
            }
            if (currentStatus == 'Closed') {
              if (this.totalClosed > 1) {
                this.totalClosed = this.totalClosed - 1;
                this.totalOrder = this.totalOrder - 1;
              } else {
                this.totalClosed = 0;
              }
            }
            if (currentStatus == 'Delivered') {
              if (this.totalDelivered > 1) {
                this.totalDelivered = this.totalDelivered - 1;
                this.totalOrder = this.totalOrder - 1;
              } else {
                this.totalDelivered = 0;
              }
            }
          }
          break;
        case 'ordereassign':
          var newStatus = null
          var orderData = this.eventData.data;
          newStatus = orderData.status;
          if (newStatus && newStatus.length > 0) {
            if (newStatus == 'Confirmed') {
              this.totalConfirmed = this.totalConfirmed + 1;
              this.dataLength = this.totalConfirmed;
               this.totalOrder = this.totalOrder + 1;
            }
            if (newStatus == 'Onprocess') {
              this.totalOnprocess = this.totalOnprocess + 1;
              this.dataLength = this.totalOnprocess;
              this.totalOrder = this.totalOrder + 1;
            }
            if (newStatus == 'Closed') {
              this.totalClosed = this.totalClosed + 1;
              this.dataLength = this.totalClosed;
              this.totalOrder = this.totalOrder + 1;
            }
            if (newStatus == 'Delivered') {
              this.totalDelivered = this.totalDelivered + 1;
              this.dataLength = this.totalDelivered;
              this.totalOrder = this.totalOrder + 1;
            }
          }
          break;
        default:
          break;
      }
    }
  }

}
