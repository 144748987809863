import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PeopleService, People, UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})

export class PeopleComponent implements OnInit {

  peoples: People[] = [];
  peopleParam: any;
  
  constructor(public peopleService: PeopleService, private userService: UserService) {
	this.peopleService.params.subscribe(data => this.peopleParam = data);
	this.peopleService.allItems.subscribe(data => this.peoples = data);
  }

  ngOnInit() {
    if (!this.userService.roleMatch(['Super Admin', 'Reseller'])) {
      this.getPeoples();
    }
  }

	getPeoples() {
		this.peopleParam.order = 'created_at|desc,updated_at|desc',
		this.peopleService.getAllItems();
	}

}
