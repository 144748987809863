import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppAccountImportModule } from 'src/app/appAccount/appAccount-import.module';

import { ResellerRouteModule } from './reseller-route.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { RSocialSiteModule } from 'src/app/reseller/socialSite/rSocialSite.module';
import { AnalyticsModule } from 'src/app/reseller/analytics/analytics.module';

import { MainComponent } from './main/main.component';
import { RRolesModule } from './roles/rroles.module';
import { RClientModule } from './client/rclient.module';
import { RTemplateGroupModule } from './template-group/rtemplate-group.module';
import { ResellerClientTemplateReportModule } from './report/report.module';
import { RLogModule } from './log/log.module';
import { RTemplateImportModule } from './template/template.module';
import { RTeamModule } from './team/team.module'

@NgModule({
    declarations: [
        MainComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        DashboardModule,
        AppAccountImportModule,
        ResellerRouteModule,
        RSocialSiteModule,
        AnalyticsModule,
        RRolesModule,
        RClientModule,
        RTemplateGroupModule,
        RLogModule,
        RTemplateImportModule,
        RTeamModule
        
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
    ]
})

export class ResellerModule { }
