import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-three',
  templateUrl: './benefits-three.component.html',
  styleUrls: ['./benefits-three.component.scss']
})
export class BenefitsThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
