import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { ProductCatalog } from './product-catalog.interface';
import { ProductCatalogModel } from './product-catalog-model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { UserService, User } from 'src/app/@tji/_dbShare/user';

declare var require: any;
var slugify = require('slugify')
@Directive()
@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class ProductCatalogService implements Resolve<any> {
  url: string = 'ecatalog';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'client_site_id': '',
  };
  sortIdentity: any = {
    'name': 'name'
  };
  authUser: any; // = this.userService.user;

  // accountStatus: any;
  @Output() onChangeItem = new EventEmitter();
  @Output() onChangeAllItems = new EventEmitter();

  private allItemsSource = new BehaviorSubject<ProductCatalog[]>([]);
  allItems = this.allItemsSource.asObservable();

  private itemSource = new BehaviorSubject<ProductCatalog>(new ProductCatalogModel({}));
  item = this.itemSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private displayItemsSource = new BehaviorSubject<ProductCatalog[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  private allProductCatalogItemsSource = new BehaviorSubject<ProductCatalog[]>([]);
  allProductCatalogItems = this.allProductCatalogItemsSource.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    public userService: UserService,
    private snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
    this.userService.user.subscribe(data => {
      this.authUser = data
      this.defaultParams = {
        'limit': 25,
        'current': 1,
        'client_site_id': this.authUser.person_id,
      };
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.resetParams(),
        this.getAllItems(),
        this.getAllProductCatalogItems(),
        // this.getItem(),
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'client_site_id': this.authUser.person_id,
    };
    this.changeParams(this.paramsInit(defaultParams));
  }
  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }
  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  unSubscribe() {
    // console.log('UnSubscribed ProductService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on TemplateMessageService');
  }
  changeAllItems(allItems: ProductCatalog[]) {
    this.allItemsSource.next(allItems);
    this.onChangeAllItems.emit(allItems);
  }

  changeItem(item: ProductCatalog) {
    this.itemSource.next(item);
    this.onChangeItem.emit(item);
  }
  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeAllProductCatalogItems(allProductCatalogItems: ProductCatalog[]) {
    this.allProductCatalogItemsSource.next(allProductCatalogItems);
    this.onChangeAllItems.emit(allProductCatalogItems);
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  getSortName(input: string) {
    let sortName = 'name';
    sortName = (input) ? this.sortIdentity[input] : sortName;
    return sortName;
  }

  sortData(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = 1;
        newParams.order = this.getSortName(event.active) + '|' + event.direction;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
        if (data.success) {
          this.changeAllItems(data.data);
          this.changeTotalItem(data.pagnitation.totalResult);
        } else {
          this.alertService.webErrorShow(data);
        }
      },
        error => {
          console.log('Error ::' + error);
        }
      );
  }

  getAllProductCatalogItems(params: any = null) {
    params = {
      "limit":0,
      "current":1,
      "status":"true",
      "client_site_id":this.authUser.person_id,
  }
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
        if (data.success) {
          this.changeAllProductCatalogItems(data.data);
          // console.log('Success ::', data.data);
        }
      },
        error => {
          console.log('Error ::' + error);
        }
      );
  }

  destroyItems(id: number, url = null) {
    var data = {
      id: id
    }
    url = (url) ? url : this.url;
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
        if (data.success) {
          this.spliceItem(id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }
      },
        error => {
          console.log('Error ::' + error);
        }
      );
  }

}