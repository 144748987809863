export class ModalTagModel {
    id: number;
    tag_id: number;
    resource_id: number;
    resource_type: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    client_id: number;

    tableName: string;

    /**
     * Constructor
     *
     * @param modal_tag
     */
    constructor(modal_tag) {
            this.id = modal_tag.id || '';
            this.tag_id = modal_tag.tag_id || '';
            this.resource_id = modal_tag.resource_id || '';
            this.resource_type = modal_tag.resource_type || '';
            this.created_by = modal_tag.created_by || '';
            this.updated_by = modal_tag.updated_by || '';
            this.created_at = modal_tag.created_at || '';
            this.updated_at = modal_tag.updated_at || '';
            this.client_id = modal_tag.client_id || '';

            this.tableName = modal_tag.tableName || '';
    }
}
