import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ChatService,
	MessengerService,
	MessengerTicketService,
	ProductCartService,
	EordersService,
	LeadsHistoryService,
	AicollectionService,
	FbTemplateMessageService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'messenger-ticket-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})

export class ItemComponent implements OnInit, OnDestroy {
	user: User;
	ticket: any;
	leadsHistoryParam: any;
	aiCollectionParam: any;
	eordersParam: any;

	constructor(public userService: UserService,
		public chatService: ChatService,
		private router: Router,
		public location: Location,
		public messengerService: MessengerService,
		public messengerTicketService: MessengerTicketService,
		public matDialog: MatDialog,
		public productCartService: ProductCartService,
		public eordersService: EordersService,
		public leadsHistoryService: LeadsHistoryService,
		public aicollectionService: AicollectionService, public fbTemplateMessageService: FbTemplateMessageService) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			// if (!this.ticket && !this.ticket.id) {
			// 	this.checkTicket(this.ticket);
			// }
		});

		this.messengerTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if (data == true) {
				this.location.back();
			}	
		});

		this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.aiCollectionParam = data;
			if (this.ticket && this.ticket.people_id) {
				this.aiCollectionParam.people_id = this.ticket.people_id ? this.ticket.people_id : null;
			}
		});

		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);

		this.eordersService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.eordersParam = data;
		});

		this.productCartService.cartPanel.subscribe(data => {
			if (data) {
				if ((data && this.isInfo) || (data && this.isComment) || (data && this.isFlag)
					|| (data && this.isLeads) || (data && this.isHistories) || (data && this.isPayment)
					|| (data && this.isTicketTrack) || (data && this.isOrder) || (data && this.isTicketTemp)) {
					this.isInfo = false;
					this.isComment = false;
					this.isFlag = false;
					this.isLeads = false;
					this.isHistories = false;
					this.isPayment = false;
					this.isTicketTrack = false;
					this.isOrder = false;
					this.isCart = true;
					this.isTicketTemp = false;
				}else {
					this.isInfo = false;
					this.isComment = false;
					this.isFlag = false;
					this.isLeads = false;
					this.isHistories = false;
					this.isPayment = false;
					this.isTicketTrack = false;
					this.isOrder = false;
					this.isCart = true;
					this.isTicketTemp = false;
				}
			} else if ((data == false && this.isInfo) || (data == false && this.isComment) || (data == false && this.isFlag)
				|| (data == false && this.isLeads) || (data == false && this.isHistories) || (data == false && this.isPayment)
				|| (data == false && this.isTicketTrack) || (data == false && this.isOrder) || (data && this.isTicketTemp)) {
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = true;
				this.isTicketTemp = false;
			} else {
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { 
		this.chatService.changeTicket(null);
		
	}

	checkTicket(ticket) {
		// if (!(ticket && ticket.id)) {
		// 	// this.router.navigate(["/app/messenger-ticket"]);
		// }
		if (window.history.length > 1) {
			this.location.back()
		} else {
			this.router.navigate(["/app/messenger-ticket"]);
		}
	}
	previous() {
		this.messengerTicketService.changeAllItems([]);
		this.messengerTicketService.isParams = false;
		if (window.history.length > 1) {
			this.location.back()
		} else {
			this.router.navigate(["/app/messenger-ticket"]);
		}
	}


	isInfo: boolean = false;
	isComment: boolean = false;
	isFlag: boolean = false;
	isLeads: boolean = false;
	isHistories: boolean = false;
	isPayment: boolean = false;
	isTicketTrack: boolean = false;
	isOrder: boolean = false;
	isCart: boolean = false;
	isTicketTemp : boolean = false;

	ticketInfo(active) {
		switch (active) {
			case "info":
				this.isInfo = !this.isInfo;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				break;
			case "comments":
				this.isInfo = false;
				this.isComment = !this.isComment;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				if (this.isComment) {
					this.chatService.getComments(this.ticket);
				}
				break;
			case "flag":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = !this.isFlag;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				break;
			case "leads":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = !this.isLeads;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				break;
			case "histories":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = !this.isHistories;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				if (this.isHistories && this.ticket && this.ticket.id) {
					setTimeout(() => {
						this.getLeadsHistory(this.ticket);
					}, 300);
				}
				break;
			case "payment":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = !this.isPayment;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				if (this.isPayment) {
					if (this.ticket && this.ticket.people_id) {
						this.aiCollectionParam.people_id = this.ticket.people_id ? this.ticket.people_id : null;
					}
					this.aicollectionService.checkAuth();
					this.aicollectionService.getCurrentItems();
				}
				break;
			case "TrackTicket":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isTicketTrack = !this.isTicketTrack;
				if (this.isTicketTrack) {
					setTimeout(() => {
						this.leadsHistoryService.getTrackTicket(this.ticket);
					}, 300);
				}
				break;
			case "Order":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = !this.isOrder;
				this.isCart = false;
				this.isTicketTemp = false;
				if (this.isOrder) {
					this.eordersService.changeAllItems([]);
					this.eordersParam['people_id'] = this.ticket.people_id;
					this.eordersService.getAllItems();
					// this.leadsHistoryService.getTrackTicket(this.ticket);
				}
				break;
			case "TicketTemp":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isTicketTemp = !this.isTicketTemp;
				this.isCart = false;

				if (this.isTicketTemp) {
					this.fbTemplateMessageService.allITicketSource.next([]);
					var params = {
						"limit": 50,
						"current": 1
					}
					params['number'] = this.ticket.recipientId;
					this.fbTemplateMessageService.getAllTicketItems(params);
				}
				break;
			default:
				break;
		}
	}

	getLeadsHistory(ticket) {
		this.leadsHistoryService.resetParams();
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}

}
