import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-template-message-view',
  templateUrl: './templateMessageView.component.html',
  styleUrls: ['./templateMessageView.component.scss']
})
export class TemplateMessageViewComponent implements OnInit {

	logoImg = "./assets/images/tji/logo_white_bg.png";
  activeUrl: string = this.router.url;
  @Input() templateData : any = null;

  constructor(public userService: UserService,
              private router: Router) {
  }

  ngOnInit() { }

}
