import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MainComponent } from './main/main.component';

import { LogService } from 'src/app/@tji/_dbShare';
import { DiagnosticToolRouteModule } from './diagnosticTool-route.module';

@NgModule({
    declarations: [
        MainComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        DiagnosticToolRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
    ],
    providers: [
        LogService
    ]
})
export class DiagnosticToolModule { }
