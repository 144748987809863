import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { DatePipe } from '@angular/common';

import {
	User, UserService,
	DashboardService, Dashboard, DashboardList, PusherService
} from 'src/app/@tji/_dbShare';

import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
	parse: {
		dateInput: 'MM/YYYY',
	},
	display: {
		dateInput: 'MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};


@UntilDestroy()
@Component({
	selector: 'dashboard-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	providers: [
		DatePipe,
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},

		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
	encapsulation: ViewEncapsulation.None,
})

export class MainComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	user: User;
	channel: any;
	dashboard: Dashboard;
	dashboardCount: DashboardList[];
	dashboardParam: any;
	dates: any;
	loop: boolean = true;

	intractionTypes: any = [
		{ 'id': 1, 'title': 'Live', 'image': 'assets/img/scrm/live-icon-front.png', 'description': 'Live Streaming' },
		{ 'id': 2, 'title': 'Today', 'image': 'assets/img/scrm/today.png', 'description': this.getCurrentDate() },
		{ 'id': 3, 'title': 'This Week', 'image': 'assets/img/scrm/week.jpg', 'description': this.getcurrentWeek() + ' to ' + this.getCurrentDate() },
		{ 'id': 3, 'title': 'This Month', 'image': 'assets/img/scrm/month.jpg', 'description': this.getcurrentmonth() + ' to ' + this.getCurrentDate() },
		{ 'id': 3, 'title': 'This Year', 'image': 'assets/img/scrm/year.png', 'description': this.getcurrentYear() + ' to ' + this.getCurrentDate() },
	];

	intractiveButtonOption: any = {
		//icon: 'assets/img/scrm/live-icon-front.png',
		title: 'Live',
		defaultSelection: null,
		//color: 'cyan-500',
		menus: this.intractionTypes
	}


	constructor(public userService: UserService,
		public dashboardService: DashboardService,
		public pusherService: PusherService,
		private router: Router,
		public matDialog: MatDialog,
		private datePipe: DatePipe) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.dashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
		this.dashboardService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.dashboard = data;
		});
		this.userService.user.subscribe(data => this.user = data);
		this.loadDumyData();
		this.dashboardService.showDashBoard.pipe(untilDestroyed(this)).subscribe(data => { this.dashboardCount = data; });
	}

	ngOnInit() {
		this.doLoader();
		this.loop = true;
		// this.getLiveDashboard();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.dashboardService.resetParams();
		this.dashboardService.unSubscribe();
		this.loop = false;
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 8000);
	}

	getCurrentDate() {
		this.dates = new Date();
		let monthStartDate = this.dates.getFullYear();
		let currentDate = this.datePipe.transform(this.dates, 'dd-MM-yyyy');
		return currentDate;
	}

	getcurrentmonth() {
		let thisMonth = new Date().getMonth() + 1;
		var monthFirstDay = '1-' + thisMonth + '-' + new Date().getFullYear();
		return monthFirstDay;
	}

	getcurrentYear() {
		var monthFirstDay = '1-1-' + new Date().getFullYear();
		return monthFirstDay;
	}

	getcurrentWeek() {
		let thisMonth = new Date().getMonth() + 1;
		var diff = new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1);
		var weekFirstDay = diff + '-' + thisMonth + '-' + new Date().getFullYear();
		return weekFirstDay;
	}

	getLiveDashboard() {
		setTimeout(() => {
			this.dashboardService.getAllItems();
			if (this.loop) {
				this.getLiveDashboard();
			}
		}, 120000);
	}

	subscribePusher() {
		this.channel = this.pusherService.subscribeChannel('authuser');
		this.channel.bind('syncdashboard', (data) => {
			// this.dashboardService.getAllItems();
		});
		this.channel.bind('ticketassigned', (data) => {
			// this.dashboardService.getAllItems();
			this.pusherService.alert('warning', 'A New Ticket Assigned', 'receipt');
		});
		this.channel.bind('ticketreassigned', (data) => {
			// this.dashboardService.getAllItems();
			this.pusherService.alert('warning', 'A New Ticket Assigned', 'receipt');
		});
	}

	changeIntract(intract) {
		this.intractiveButtonOption = {
			icon: intract.image,
			title: intract.title,
			defaultSelection: null,
			color: 'cyan-500',
			menus: this.intractionTypes
		};
		this.loadDumyData(intract.title);
		this.dashboardService.dashBoardCounts(intract.title)
		switch (intract.title) {
			case "Live":
				this.dashboardParam.type = 'live';
				break;
			case "Today":
				this.dashboardParam.type = 'today';
				break;
			case "This Week":
				this.dashboardParam.type = 'thisWeek';
				break;
			case "This Month":
				this.dashboardParam.type = 'thisMonth';
				break;
			case "This Year":
				this.dashboardParam.type = 'thisYear';
				break;
			default:
				this.dashboardParam.type = 'live';
				break;
		}
		// this.dashboardService.getAllItems();
	}
	loadDumyData(title: any = null) {
		let titleData;
		if (title == null) {
			titleData = 'Live'
		} else {
			titleData = title
		}

		this.dashboardCount = [
			{
				"name": `${titleData} Open Tickets`,
				"value": -1,
				"icon": "fa fa-weixin fa-1x cyan-400-fg",
				"color": "cyan-400-fg"
			},
			{
				"name": `${titleData} Abandon Tickets`,
				"value": -1,
				"icon": "fa fa-clock-o fa-1x green-400-fg",
				"color": "green-400-fg"
			},
			{
				"name": `${titleData} Leads`,
				"value": -1,
				"icon": "fa fa-users fa-1x yellow-300-fg",
				"color": "yellow-300-fg"
			},
			{
				"name": `${titleData} Total Tickets`,
				"value": -1,
				"icon": "fa fa-ticket fa-1x red-300-fg",
				"color": "red-300-fg"
			},
			{
				"name": `${titleData} Active Agents`,
				"value": -1,
				"icon": "fa fa-black-tie fa-1x cyan-400-fg",
				"color": "cyan-400-fg"
			},
			{
				"name": `${titleData} Activated Accounts`,
				"value": -1,
				"icon": "fa fa-sitemap fa-1x indigo-300-fg",
				"color": "indigo-300-fg"
			}
		]
	}

	date = new FormControl(moment());

	setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		const ctrlValue = this.date.value!;
		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());
		this.date.setValue(ctrlValue);
		// console.log('====================',this.date)
		datepicker.close();
	}

}
