export class InstagramListModel {
    id: number;
    instagram_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    sms_message_sid: string;
    account_sid: string;
    text: string;
    from: string;
    to: Date;
    permalink: string;
    user_image: string;
    media_url: string;
    media: string;
    json: string;
    asset_url: string;
    status: string;
    is_active: boolean;
    is_assigned: boolean;
    is_read: boolean;
    is_closed: boolean;
    is_itrs: boolean;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    isSent?: boolean;
    pusherSync?: string;
    agentName?: string;
    createdByName?: string;
    createdByAvatar?: string;
    location_url?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    reply?: any;

    tableName: string;
    className: string;
    replayPersonType: any;

    /**
     * Constructor
     *
     * @param instagram
     */
    constructor(instagram) {
            this.id = instagram.id || '';
            this.instagram_id = instagram.instagram_id || '';
            this.reply_id = instagram.reply_id || '';
            this.client_id = instagram.client_id || '';
            this.client_site_id = instagram.client_site_id || '';
            this.sms_message_sid = instagram.sms_message_sid || '';
            this.account_sid = instagram.account_sid || '';
            this.text = instagram.text || '';
            this.from = instagram.from || '';
            this.to = instagram.to || '';
            this.user_image = instagram.user_image || '';
            this.media = instagram.media || '';
            this.json = instagram.json || '';
            this.status = instagram.status || '';
            this.is_active = instagram.is_active || '';
            this.is_assigned = instagram.is_assigned || '';
            this.is_read = instagram.is_read || '';
            this.is_closed = instagram.is_closed || '';
            this.is_itrs = instagram.is_itrs || '';
            this.created_by = instagram.created_by || '';
            this.updated_by = instagram.updated_by || '';
            this.created_at = instagram.created_at || '';
            this.updated_at = instagram.updated_at || '';

            this.tableName = instagram.tableName || '';
            this.replayPersonType = instagram.replayPersonType || '';
    }
}
