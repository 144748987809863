import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { AssignFormComponent } from './../assignForm/assignForm.component';

import {
	User, UserService,
	AgentService, Agent
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'abandon-agent-info',
	templateUrl: './agentInfo.component.html',
	styleUrls: ['./agentInfo.component.scss']
})

export class AgentInfoComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	agents: Agent[] = [];
	param: any;
	dataLength: number = 0;
	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		autoplayHoverPause: true,
		touchDrag: true,
		pullDrag: true,
		dots: true,
		autoplay: true,
		autoplayTimeout: 2500,
		autoplaySpeed: 800,
		navSpeed: 700,
		navText: [],
		responsive: {
			0: { items: 1 },
			360: { items: 1 },
			450: { items: 1 },
			740: { items: 1 },
			940: { items: 1 },
			1200: { items: 1 }
		},
		nav: false
	}

	constructor(public userService: UserService,
		public agentService: AgentService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
		this.agentService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
	}

	ngOnInit() {
		this.doLoader();
		this.agentService.unSubscribeFilter();
	}

	/** On destroy */
	ngOnDestroy(): void {
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getAgents() {
		this.param.all = 1;
		this.agentService.getAllItems();
	}

}
