import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'library-policy-model',
  templateUrl: './policy-model.component.html',
  styleUrls: ['./policy-model.component.scss']
})
export class PolicyModelComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
