export var data = [
	{
		"name": "Initiated On",
		"value": '12/May/2020'
	},
	{
		"name": "Deadline",
		"value": 35
	},
	{
		"name": "Agent",
		"value": "Agent 1"
	},
	{
		"name": "Message Received",
		"value": 80
	},
	{
		"name": "Message Replied",
		"value": 60
	},
	{
		"name": "MultiMedia Messages",
		"value": 54
	}
];