export var data = [
  {
    "name": "Total",
    "value": 50
  },
  {
    "name": "Closed",
    "value": 28
  },
  {
    "name": "Live",
    "value": 2
  }
];