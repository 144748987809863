import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	RoleService, Role,
	TagService, Tag, TagModel,
	BoardService, Board, BoardModel,
	ModalTagService, ModalTag, ModalTagModel,
	TagBoardService, TagBoard, TagBoardModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'role-item-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss']
})

export class TagComponent implements OnInit, OnDestroy {
	authUser: User;
	role: Role;

	tags: Tag[] = [];
	selectedTag: Tag;
	tagParam: any;
	tagSource = new MatTableDataSource(this.tags);
	tagColumns = ['name', 'action'];

	boards: Board[] = [];
	selectedBoard: Board;
	boardParam: any;

	modalTags: ModalTag[] = [];
	modalTagParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public roleService: RoleService,
		public tagBoardService: TagBoardService,
		public modalTagService: ModalTagService,
		public boardService: BoardService,
		public tagService: TagService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.roleService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.role = data;
			if (data && data.id) {
				this.getModalTags();
			}
		});

		this.tagService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.tags = data;
			this.tagSource = new MatTableDataSource(this.tags);
		});
		this.tagService.params.pipe(untilDestroyed(this)).subscribe(data => this.tagParam = data);

		this.boardService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.boards = data);
		this.boardService.params.pipe(untilDestroyed(this)).subscribe(data => this.boardParam = data);

		this.modalTagService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.modalTags = data);
		this.modalTagService.params.pipe(untilDestroyed(this)).subscribe(data => this.modalTagParam = data);
	}

	ngOnInit() {
		this.getTags();
		this.getBoards();
		this.getModalTags();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getTags() {
		this.tagParam.board_id = (this.selectedBoard && this.selectedBoard.id) ? this.selectedBoard.id : null;
		this.tagService.getAllItems();
	}

	getBoards() {
		this.boardService.getAllItems();
	}

	getModalTags() {
		if (this.role && this.role.id) {
			this.modalTagParam.resource_id = (this.role && this.role.id) ? this.role.id : null;
			this.modalTagParam.resource_type = (this.role && this.role.tableName) ? this.role.tableName : 'roles';
			this.modalTagService.getAllItems();
		}
	}

	onSelectBoard(board: Board) {
		this.selectedBoard = board;
		this.boardService.resetParams();
		this.getTags();
	}

	showAllTag() {
		this.selectedBoard = null;
		this.boardService.resetParams();
		this.getTags();
	}

	isTagMapped(tag: Tag): boolean {
		if (this.modalTags && this.modalTags.length > 0) {
			return (this.modalTags.find(x => x.tag_id === tag.id)) ? true : false;
		} else {
			return false
		}
	}

	syncModalTag(tag: Tag, status: boolean) {
		let data = {
			resource_id: this.role.id,
			resource_type: this.role.tableName,
			tag_id: tag.id,
		};
		if (status) {
			this.modalTagService.store(data);
		}
		if (!status) {
			this.modalTagService.removeByPost(data, 'modal-tag-destroy');
		}
	}

}