import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe, Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';

import {
	User, UserService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'profile-update-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	providers: [DatePipe]
})

export class MainComponent implements OnInit, OnDestroy {

	@ViewChild('rightSideBar') rightSideBar: MatSidenav;
	user: User;
	avatar = 'assets/img/no-user.jpg';

	constructor(public userService: UserService,
		public matDialog: MatDialog,
		public location: Location,
		private datePipe: DatePipe) {
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

}
