import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MainComponent } from './main/main.component';
import { ChatComponent } from './chat/chat.component';
import { ChatTypeComponent } from './chat/chatType/chatType.component';
import { PeopleFormComponent } from './people/form/form.component';

@NgModule({
    declarations: [
        MainComponent,
        ChatComponent,
        ChatTypeComponent,
        PeopleFormComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        MainComponent,
        PeopleFormComponent,
        ChatComponent
    ]
})

export class AppAccountImportModule { }
