import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ClientService, Client
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'client-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})

export class ItemComponent implements OnInit, OnDestroy {
	authUser: User;
	client: Client;

	constructor(public userService: UserService,
		public clientService: ClientService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		public location: Location) {
		this.clientService.item.subscribe(data => this.client = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

}
