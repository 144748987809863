import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { StoreAsCombinedFormComponent } from './../storeAsCombinedForm/storeAsCombinedForm.component';
import { StoreAsIntractiveFormComponent } from './../storeAsIntractiveForm/storeAsIntractiveForm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChooseIntractiveFormComponent } from './../chooseIntractiveForm/chooseIntractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { TicketableIntractiveFormComponent } from './../ticketableIntractiveForm/ticketableIntractiveForm.component';
import { ShortCodeFormComponent } from './../shortCodeForm/shortCodeForm.component';

import {
  User, UserService,
  ClientSiteService, ClientSite,
  IntractiveService, Intractive, IntractiveModel,
  IntractiveGroupService, IntractiveGroup, IntractiveGroupModel,
  IntractiveCommandService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'social-site-template-message',
  templateUrl: './templateMessage.component.html',
  styleUrls: ['./templateMessage.component.scss']
})

export class TemplateMessageComponent implements OnInit, OnDestroy {
  @Input() intractive: Intractive;
  authUser: User;
  clientSite: ClientSite;

  constructor(public userService: UserService,
    public clientSiteService: ClientSiteService,
    public intractiveService: IntractiveService,
    public intractiveGroupService: IntractiveGroupService,
    public intractiveCommandService: IntractiveCommandService,
    private router: Router,
    public matDialog: MatDialog) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);

  }

  ngOnInit() { }

  /** On destroy */
  ngOnDestroy(): void { }

  /** Add / Update Tag */
  newIntractiveGroup(intractiveGroup = null): void {
    this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(SimpleFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
        isChoiceMsg: this.isItrsTemplateMessage(this.intractive),
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
      });
  }

  /** Delete Intractive */
  deleteIntractiveGroup(intractiveGroup): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete this message?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var formData = {
					id : intractiveGroup.id,
				}
				this.intractiveGroupService.destroy('interactiveGroup/delete', formData, intractiveGroup);
      }
    });
  }

  /** Add / Update Intractive */
  newIntractive(intractive = null): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(IntractiveFormComponent, {
      width: "600px",
      data: {
        action: (intractive) ? 'edit' : 'new',
        item: intractive,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
        switch (actionType) {
          case 'new':
            this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
            break;
          case 'update':
            formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
            break;
        }
      });
  }

  /** Delete Intractive */
  deleteIntractive(intractive): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete this template?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var formData ={
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete',formData);
        this.intractiveService.removelibraryById(intractive.id);
      }
    });
  }

  isItrsTemplateMessage(intractive: Intractive): boolean {
    let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
    return (slug && slug === 'template-message') ? true : false;
  }

  getOptions(group: IntractiveGroup) {
    return (group && group.json) ? JSON.parse(group.json) : null;
  }

  /** Add / Update Change Intractive */
  linkIntractiveGroup(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(ChooseIntractiveFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
          case 'new':
            this.intractiveGroupService.store(formData.value);
            break;
          case 'update':
            this.intractiveGroupService.update(id, formData.value);
            break;
        }
      });
  }

  /** Add / Change IntractiveGroup Images */
  imageIntractiveGroup(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(ChangeIntractiveGroupImageComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
      });
  }

  /** Add / Update Change Intractive */
  markIntractiveGroup(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(TicketableIntractiveFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formDatas: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
					case 'new':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
					case 'update':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
				}
      });
  }

	/** setPrimaryIntractive Intractive */
	setPrimaryIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to set this Ticket Survey as Primary Survey ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.intractiveService.removelibraryById(intractive.id);
				var formData = {
					"id": intractive.id,
					"key": "is_begin",
					"value": 1,
					"itrsbot_id": intractive.itrsbot_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
			}
		});
	}

	/** removePrimaryIntractive Intractive */
	removePrimaryIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to remove this Ticket Survey as Primary Survey ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.intractiveService.removelibraryById(intractive.id);
				var formData = {
					"id": intractive.id,
					"key": "is_begin",
					"value": 0,
					"itrsbot_id": intractive.itrsbot_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
			}
		});
	}

  /** Add / Update Intractive */
  newShortCode(intractive): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(ShortCodeFormComponent, {
      width: "600px",
      data: {
        action: (intractive) ? 'edit' : 'new',
        item: intractive,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        this.intractiveCommandService.addShortCode('Intractive/addshortcode', formData.value);
        this.intractive.shortCode = formData.value['command'];
      });
  }

  getStoreAs(intractiveGroup): string {
    let json = '';
    if(intractiveGroup && intractiveGroup.json) {
      let jsonData = JSON.parse(intractiveGroup.json);
      if(jsonData && jsonData.store_as) {
            json = jsonData.store_as;
        }
    }
    return json;
  }

  /** Add / Update IntractiveGroup Json */
  updateIntractiveJson(intractive = null): void {
    this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
    let dialogRef = this.matDialog.open(StoreAsIntractiveFormComponent, {
      width: "600px",
      data: {
        action: 'edit',
        item: intractive
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        formData.value['intractive_id'] = id;
				this.intractiveService.updateStoreAs(formData.value);
        setTimeout(() => {
          this.intractiveService.getIntractiveItem({ id: intractive.id });
        }, 1500);
      });
  }

  /** Add / Update IntractiveGroup Json */
  updateIntractiveGroupJson(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    let dialogRef = this.matDialog.open(StoreAsCombinedFormComponent, {
      width: "600px",
      data: {
        action: 'edit',
        item: intractiveGroup
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        formData.value["intractive_group_id"] = id;
				this.intractiveGroupService.updateSaveAsGroup(formData.value);
      });
  }

  getStoreValue(intractiveGroup): string {
    let json = '';
    if(intractiveGroup && intractiveGroup.json) {
      let jsonData = JSON.parse(intractiveGroup.json);
      if(jsonData && jsonData.store_value) {
            json = jsonData.store_value;
        }
    }
    return json;
  }

  hasStoreAsValue(intractiveGroup): boolean {
    if(intractiveGroup && intractiveGroup.json) {
      let jsonData = JSON.parse(intractiveGroup.json);
      if(jsonData && jsonData.store_as) {
        return true;
        }
    }
    return false;
  }

  removeNextIntractiveGroup(intractiveGroup: IntractiveGroup) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to Remove this ITRS?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var data = {
          next_intractive_id: null,
			    is_active: 1,
        }
        this.intractiveGroupService.update(intractiveGroup.id, data);
      }
    });
  }
}
