import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService, CommonService } from 'src/app/@tji/_dbShare';
import { ConfirmPasswordValidator } from './password-validator';

@UntilDestroy()
@Component({
  selector: 'new-page-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  showForm: boolean = true;
  showResetForm: boolean = false;
  showPasswordReset: boolean = false;
  isRequiredOtp: boolean = false;
  apiError: String;
  logo: string = "./assets/img/scrm/logo.png";
  loader: boolean = false;
  isEnabledOtp: boolean = false;
  fieldTextType: boolean = false;
  passwordTextType: boolean = false;
  confirmTextType: boolean = false;
  isResetEnabledOtp: boolean = false;
  isResetRequiredOtp: boolean = false;
  isForgotPassword: boolean = false;
  isResetOtpDisabled: boolean = false;

  constructor(private _formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    public translate: TranslateService,
    private router: Router) {
    this.checkLogin();
    this.translate.addLangs(['en', 'ar']);
    this.setCurrentLang();
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(90)]],
      password: ['', [Validators.required, Validators.maxLength(30)]],
      otp: ['', [Validators.maxLength(6)]],
      resend_otp: ['false'],
      remember: ['']
    });

    this.resetForm = this._formBuilder.group({
      email: ['', {disabled: true},[Validators.required,Validators.email]],
      otp: ['', [Validators.maxLength(6)]],
      password: ['', [Validators.maxLength(30)]],
      confirmPassword: ['', [Validators.maxLength(30)]],
    },
      {
        validator: ConfirmPasswordValidator("password", "confirmPassword")
      });
    this.clearApiError();
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
  get reset_email() { return this.resetForm.get('reset_email'); }

  get passwords() { return this.resetForm.get('password'); }
  get confirmPassword() { return this.resetForm.get('confirmPassword'); }

  setCurrentLang() {
    let cookieLanguage: string = this.commonService.getLanguage();
    let language: string = (cookieLanguage) ? cookieLanguage : this.translate.currentLang;
    language = (language) ? language : this.translate.getBrowserLang();
    let lang: string = language.match(/en|ar/) ? language : 'en';
    if (!cookieLanguage || cookieLanguage === null) { this.commonService.setLanguage(lang); }
    if (lang) { this.translate.use(lang); }
    if (!this.translate.currentLang) {
      this.translate.use('en')
      this.translate.setDefaultLang('en');
      this.commonService.setLanguage('en');
    }

    if (lang && lang === 'ar') {
      document.querySelector('html')?.setAttribute('dir', 'rtl');
      return 'fa-ar';
    } else {
      document.querySelector('html')?.removeAttribute('dir');
      return 'fa-en';
    }
  }
  

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleConfirmTextType() {
    this.confirmTextType = !this.confirmTextType;
  }

  login(loginData) {
    // this.showForm = false;
    this.loader = true;
    this.userService.login('me/pre/login', loginData).subscribe(data => {
      if (data && data.success && data.data.sendtype) {
        if (data.data.sendtype == "sent") {
          this.apiError = data.data.message;
          this.isRequiredOtp = true;
          setTimeout(() => {
            this.isEnabledOtp = true;
          }, 6000);
        }
        if (data.data.sendtype == "resent") {
          this.apiError = data.data.message;
          this.isRequiredOtp = true;
          setTimeout(() => {
            this.isEnabledOtp = true;
          }, 6000);
        }
      } else if (data && !data.success && data.errors.message) {
        var error = data.errors.message
        if (error) {
          switch (error) {
            case 'otp-required':
              this.apiError = "Enter OTP : Your OTP has Sent your Registered Email.";
              this.isRequiredOtp = true;
              setTimeout(() => {
                this.isEnabledOtp = true;
              }, 6000);
              break;
            case 'otp-resent':
              this.apiError = "Your OTP has Sent your Registered Email Again, Check Your Email.";
              this.isRequiredOtp = true;
              setTimeout(() => {
                this.isEnabledOtp = true;
              }, 6000);
              break;
            case 'otp-wrong':
              this.apiError = "Enter Valid OTP or Click Resend OTP";
              this.isRequiredOtp = true;
              break;
            case 'Invalid otp':
              this.apiError = "Enter Valid OTP or Click Resend OTP";
              this.isRequiredOtp = true;
              break;
            default:
              this.isRequiredOtp = false;
              this.apiError = error;
              break;
          }
        } else { this.apiError = error; }

      }
      this.router.navigate(['/app/dashboard']);
      // this.logoutExpiredUser();
      setTimeout(() => { this.loader = false; }, 50);
    }, error => {
      if (error) {
        switch (error) {
          case 'otp-required':
            this.apiError = "Enter OTP : Your OTP has Sent your Registered Email.";
            this.isRequiredOtp = true;
            break;
          case 'otp-resent':
            this.apiError = "Your OTP has Sent your Registered Email Again, Check Your Email.";
            this.isRequiredOtp = true;
            break;
          case 'otp-wrong':
            this.apiError = "Enter Valid OTP or Click Resend OTP";
            this.isRequiredOtp = true;
            break;
          case 'resent':
            this.apiError = "Your OTP has Sent your Registered Email Again, Check Your Email.";
            this.isRequiredOtp = true;
            break;
          case 'Invalid otp':
            this.apiError = "Enter Valid OTP or Click Resend OTP";
            this.isRequiredOtp = true;
            break;
          default:
            this.isRequiredOtp = false;
            this.apiError = error;
            break;
        }
      } else { this.apiError = error; }
      this.loginForm.get('resend_otp').setValue(false);
      this.loginForm.get('otp').setValue('');
      this.loader = false;
      this.showForm = true;
    });
  }

  clearApiError() {
    if (this.apiError && this.apiError !== null) {
      setTimeout(() => {
        this.apiError = null;
      }, 1000);
    }
  }

  forgotPassword() {
    this.showPasswordReset = true;
    this.showForm = false;
    this.apiError = null;
  }

  isLogin() {
    this.showPasswordReset = false;
    this.showForm = true;
    this.isRequiredOtp = false;
    this.apiError = null;
  }

  resetPassword(resetData) {
    // this.showResetForm = true;
    this.userService.sendResetLink('me/pre/forgotpassword', resetData).subscribe(data => {
      if (data && data['success'] || data['data'].sendtype || data['data'].state) {
        if (data['data'].sendtype == "sent") {
          this.apiError = null;
          this.apiError = data['data'].message;
          
          // this.resetForm.controls['otp'].disable();
          // this.resetForm.controls['email'].disable()
          this.isResetRequiredOtp = true;
          setTimeout(() => {
            this.isResetEnabledOtp = true;
          }, 6000);
        }
        if (data['data'].sendtype == "resent") {
          this.apiError = null;
          this.apiError = data['data'].message;
          this.isResetRequiredOtp = true;
          setTimeout(() => {
            this.isResetEnabledOtp = true;
          }, 6000);
        }
        if (data['data'].sendtype == "valid otp") {
          this.apiError = null;
          this.isResetRequiredOtp = true;
          this.isResetEnabledOtp = false;
          this.isForgotPassword = true;
          this.isResetOtpDisabled = true;
          this.resetForm.controls["password"].setValidators(Validators.required);
          this.resetForm.controls["password"].updateValueAndValidity();
          this.resetForm.controls["confirmPassword"].setValidators(Validators.required);
          this.resetForm.controls["confirmPassword"].updateValueAndValidity();
        }
        if (data['data'].state == "done") {
          this.apiError = null;
          this.apiError = data['data'].message;
          this.isResetRequiredOtp = false;
          this.isResetEnabledOtp = false;
          this.isForgotPassword = false;
          this.isResetOtpDisabled = false;
          this.showPasswordReset = false;
          this.showForm = true;
        }
        
      } else if (data && !data['success'] && data['errors'].message) {
        var error = data['errors'].message
        if (error) {
          switch (error) {
            case 'otp-required':
              this.apiError = "Enter OTP : Your OTP has Sent your Registered Email.";
              this.isResetRequiredOtp = true;
              setTimeout(() => {
                this.isResetEnabledOtp = true;
              }, 6000);
              break;
            case 'otp-resent':
              this.apiError = "Your OTP has Sent your Registered Email Again, Check Your Email.";
              this.isResetRequiredOtp = true;
              setTimeout(() => {
                this.isResetEnabledOtp = true;
              }, 6000);
              break;
            case 'otp-wrong':
              this.apiError = "Enter Valid OTP or Click Resend OTP";
              this.isResetRequiredOtp = true;
              break;
            case 'Invalid otp':
              this.apiError = "Enter Valid OTP or Click Resend OTP";
              this.isResetRequiredOtp = true;
              break;
            default:
              this.isResetRequiredOtp = false;
              this.apiError = error;
              break;
          }
        } else { this.apiError = error; }

      }
      // this.router.navigate(['/login']);
    }, error => {
      this.showResetForm = false;
      this.apiError = error.error.message;
    });
  }

  checkLogin() {
    if (this.userService.authCheck()) {
      this.router.navigate(['/app/dashboard']);
    }
  }

  resendOtp() {
    this.loginForm.get('resend_otp').setValue(true);
    this.loginForm.value.otp = '';
    this.isEnabledOtp = false;
    this.login(this.loginForm.value);
  }

  resendResetOtp(resetData) {
    resetData.otp = '';
    this.isResetRequiredOtp = false;
    this.resetPassword(resetData);
  }

  logoutExpiredUser() {
    this.userService.logoutExpiredUser().subscribe(data => {
    }, error => {
      console.log(error);
    });
  }
  goToOctopus() {
    window.location.href = 'https://ai-octopus.com/';
  }
}
