export class TweetdmTicketModel {
    id: number;
    code: string;
    tweetdm_id: number;
    client_id: number;
    agent_id: number;
    priority: string;
    is_active: boolean;
    is_closed: boolean;
    comment: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tweetdm?: any;
    people?: any;
    peopleName?: string;
    peopleAvatar?: string;
    people_id?: number;
    clientSiteId?: number;
    tweetCode?: string;
    recipientId?: string;
    isDirectMessage?: boolean;
    pusherSync?: string;
    abandonAt: Date;
    abandonElapsed: number;
    last_message_on?: Date;
    
    unReadCount?: number;
    tableName: string;
    className: string;
    statusName?: string;
    statusChanges?: Array<string>;
    peopleLabelId?: number;
    peopleLabelName?: string;
    peopleLabelColor?: string;
    peopleLabelColorText?: string;
    siteColor?: string;
    uName?: string;
    siteName?: string;
    
    /**
     * Constructor
     *
     * @param tweetdm_ticket
     */
    constructor(tweetdm_ticket) {
            this.id = tweetdm_ticket.id || '';
            this.code = tweetdm_ticket.code || '';
            this.tweetdm_id = tweetdm_ticket.tweetdm_id || '';
            this.client_id = tweetdm_ticket.client_id || '';
            this.agent_id = tweetdm_ticket.agent_id || '';
            this.priority = tweetdm_ticket.priority || '';
            this.is_active = tweetdm_ticket.is_active || '';
            this.is_closed = tweetdm_ticket.is_closed || '';
            this.comment = tweetdm_ticket.comment || '';
            this.created_by = tweetdm_ticket.created_by || '';
            this.updated_by = tweetdm_ticket.updated_by || '';
            this.created_at = tweetdm_ticket.created_at || '';
            this.updated_at = tweetdm_ticket.updated_at || '';

            this.tableName = tweetdm_ticket.tableName || '';
            this.siteName = tweetdm_ticket.siteName || '';
    }
}
