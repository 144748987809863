import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER, TAB, SPACE } from '@angular/cdk/keycodes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, ImageService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel,
	ConsumerService, Consumer, ConsumerModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'consumer-item-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit, OnDestroy {
	authUser: User;
	@Input() consumerGroup: ConsumerGroup = null;
	@Input() selectedGroup: ConsumerGroup = null;
	@Input() contacts: Array<string> = [];

	selectable = true;
	removable = true;
	addOnBlur = true;
	readonly separatorKeysCodes = [TAB, COMMA] as const;

	constructor(public userService: UserService,
		public consumerService: ConsumerService,
		public consumerGroupService: ConsumerGroupService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.consumerGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.consumerGroup = data;
			this.selectedGroup = (this.selectedGroup && this.selectedGroup.id) ? this.selectedGroup : data;
			this.contacts = (this.selectedGroup && this.selectedGroup.consumer) ? this.selectedGroup.consumer.jsonArray : [];
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	checkTotalContacts(): boolean {
		if (this.selectedGroup && this.selectedGroup.id &&
			this.selectedGroup.consumer && this.selectedGroup.consumer.jsonArray &&
			this.selectedGroup.consumer.jsonArray.length > 0) {
			return true;
		}
		return false;
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value).trim();
		// const arrayValue = value.splitMulti([",", " "]);
		// const arrayValue = splitMulti(value, [",", " "]);
		const arrayValue = value.split(/\r\n|\n|\r|,|\t|\s| /).filter(word => word.length > 0);
		// const arrayValue = value.split(/;|,|\n\r|\n/).filter(word => word.length > 0);

		// Add our contact
		if (arrayValue && arrayValue.length > 0) {
			for (let i = 0; i <= arrayValue.length; i++) {
				if (arrayValue[i] === null || arrayValue[i] === '' || arrayValue[i] === ' ' || arrayValue[i] === undefined) {
					continue;
				}
				else { this.contacts.push(arrayValue[i]); }
			}
		}

		this.contacts = this.contacts.filter((element): element is any => {
			return (element !== null || element !== 'null' || element !== undefined) ? true : false;
		});

		// Clear the input value
		event.chipInput!.clear();
	}

	remove(contact: string): void {
		const index = this.contacts.indexOf(contact);

		if (index >= 0) {
			this.contacts.splice(index, 1);
		}
	}

	newConsumer() {
		let storeData: Consumer = new ConsumerModel({});
		storeData.consumer_group_id = (this.selectedGroup && this.selectedGroup.id) ? this.selectedGroup.id : this.consumerGroup.id;
		storeData.json = JSON.stringify(this.contacts);
		storeData.client_id =	Number(localStorage.getItem('client_id'));
		// if (this.consumerGroup && this.consumerGroup.id) {
		// 	// this.consumerService.update(this.consumerGroup.id, storeData);
		// 	this.consumerService.addContact(storeData);
		// } else {
		// 	this.consumerService.addContact(storeData);
		// }
		this.consumerService.addContact(storeData);

		setTimeout(() => { this.consumerGroupService.getItem({ 'id': storeData.consumer_group_id }); }, 1000);
		setTimeout(() => {
			this.consumerGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
				this.consumerGroup = data;
				this.selectedGroup = (this.selectedGroup && this.selectedGroup.id) ? this.selectedGroup : data;
				if (this.consumerGroup && this.consumerGroup.consumer && this.consumerGroup.consumer.jsonArray) {
					this.contacts = this.consumerGroup.consumer.jsonArray;
				} else {
					this.contacts = [];
				}
			});
		}, 2500);
	}

	updateConsumer() {
		let storeData: Consumer = new ConsumerModel({});
		storeData.consumer_group_id = (this.selectedGroup && this.selectedGroup.id) ? this.selectedGroup.id : this.consumerGroup.id;
		storeData.json = JSON.stringify(this.contacts);
		storeData.client_id =	Number(localStorage.getItem('client_id'));
		this.consumerService.addContact(storeData);
		setTimeout(() => { this.getAgainConsumerGroup(); }, 1000);
	}

	getAgainConsumerGroup() {
		this.consumerGroupService.getItem({ 'id': this.consumerGroup.id });
		setTimeout(() => {
			this.consumerGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
				this.consumerGroup = data;
				this.selectedGroup = (this.selectedGroup && this.selectedGroup.id) ? this.selectedGroup : data;
				if (this.consumerGroup && this.consumerGroup.consumer && this.consumerGroup.consumer.jsonArray) {
					this.contacts = this.consumerGroup.consumer.jsonArray;
				} else {
					this.contacts = [];
				}
			});
		}, 2000);
	}

exportExcel() {
		setTimeout(() => {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure want to Export All Contacts',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var title = 'All Contacts';
				setTimeout(() => {
					this.downloadFile(this.contacts, title);
				}, 2000);
			}
		});
		}, 1000);
		
	}

	// downloadFile
	downloadFile(contact, title = null) {
		var excelData = [];
		if (contact && contact.length > 0) {
			contact.forEach(number=> {
				if (number) {
					var data = {
						"Number": parseInt(number)
					}
					excelData.push(data);
				}
			});
		}

		let arrHeader = ["Number"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'consumer_contacts_reports_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}
	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Number"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}

}
