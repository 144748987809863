import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { TemplateUsageComponent } from './template-usage/template-usage.component';

import { RTemplateUsageReportsService, RTemplateUsageReportsQueueService, RClientService, ClientService } from 'src/app/@tji/_dbShare';
import { TemplateViewComponent } from './template-view/template-view.component';
import { TemplateQueueComponent } from './template-queue/template-queue.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: TemplateUsageComponent,
        resolve: { data: RTemplateUsageReportsService}
      },
      {
        path: 'list',
        component: TemplateUsageComponent,
        resolve: { data: RTemplateUsageReportsService }
      },
      {
        path: 'queue',
        component: TemplateQueueComponent,
        resolve: { data: RTemplateUsageReportsQueueService }
      },
      {
        path: ':templateUsageId/view',
        component: TemplateViewComponent,
        resolve: { data: RTemplateUsageReportsService }
      }
    ],
  },
  { path: '**', redirectTo: '/reseller/template-usage' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RTemplateGroupRoutingModule { }
