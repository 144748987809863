import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	PersonSettingService, PersonSetting,
	RClientService,
	RTemplateUsageReportsQueueService,
	RTemplateUsageReportsService,
	ClientService,
	RLogService,
	RAgentService,
} from 'src/app/@tji/_dbShare';

import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@UntilDestroy()
@Component({
	selector: 'filter-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [DatePipe,
		// { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		// { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
	]
})

export class SidebarComponent {
	@Output() onClose = new EventEmitter();
	isAuthReseller: boolean = (this.userService.isAuthUserIsReseller()) ? true : false;
	param: any;
	logParam: any;
	clientList: any;
	agentList: any;
	selectedClientSite: any;
	isActiveData: boolean = false;
	isDeletedData: boolean = false;

	// auto filter
	myControl = new FormControl<string | any>('');
	filteredOptions: Observable<any[]>;

	// date filter
	campaignOne: UntypedFormGroup;
	fromDate: Date;
	toDate: Date;
	today: Date = new Date();
	minDate: Date = new Date(2021, 0, 1);
	maxDate: Date = new Date();


	constructor(private _formBuilder: UntypedFormBuilder,
		public personSettingService: PersonSettingService,
		public userService: UserService,
		public rClientService: RClientService,
		public clientService: ClientService,
		public rlogService: RLogService,
		public ragentService: RAgentService,
		private router: Router,
		private datepipe: DatePipe,
		public matDialog: MatDialog) {
		this.rClientService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.ragentService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agentList = data);
		this.rlogService.params.pipe(untilDestroyed(this)).subscribe(data => this.logParam = data);
		this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientList = data
			this.filteredOptions = this.myControl.valueChanges.pipe(
				startWith(''),
				map(value => {
					const name = typeof value === 'string' ? value : value?.name;
					return name ? this._filter(name as string) : this.clientList.slice();
				}),
			);
		});
		this.getDates();
	}

	ngOnInit() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.clientList.slice();
			}),
		);
	}

	displayFn(user: any): string {
		return user && user.name ? user.name : '';
	}

	_filter(name: string): any[] {
		const filterValue = name.toLowerCase();

		return this.clientList.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	/** On destroy */
	ngOnDestroy(): void {
	}

	checkHidden(list): boolean {
		if (this.isAuthReseller) {
			return list.resellerAccess;
		} else {
			return list.clientAccess;
		}
	}

	selectClient(data: any) {
		if (data == null) {
			this.reset();
		} else {
			this.param["client_id"] = data.option.value.id;
			this.ragentService.getClientAgent({"client_id":data.option.value.id});
			this.logParam["client_id"] = data.option.value.id;
			this.rlogService.getAllItems();
		}
	}

	selectAgent(value) {
		if (value == null) {
			delete this.logParam["user_id"];
			this.rlogService.getAllItems();
		} else {
			this.logParam["user_id"] = value;
			this.rlogService.getAllItems();
		}
	}

	reset() {
		delete this.param.client_id;
		this.myControl.setValue('');
		delete this.logParam["from_date"];
		delete this.logParam["to_date"];
		delete this.logParam["user_id"];
		delete this.logParam["client_id"];
		this.getDates();
		this.rlogService.getAllItems();
		this.clientService.getAllIData();
	}

	getDates() {
		let date = new Date();
		this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
		this.toDate = this.today;
		this.campaignOne = new UntypedFormGroup({
			start: new UntypedFormControl(),
			end: new UntypedFormControl()
		});
	}

	onChangeFromDate(event) {
		this.fromDate = event.target.value;
		// let maxToDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth(), this.fromDate.getDay() + 31);
		let maxToDate = this.today;
		maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
		this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
		this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;
	}

	onChangeToDate(event) {
		this.toDate = event.target.value;
		// let maxToDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth(), this.fromDate.getDay() + 31);
		let maxToDate = this.today;
		maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
		this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
		this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;
		setTimeout(() => {
			this.logParam["from_date"] = moment(this.fromDate).format('DD-MM-YYYY');
			this.logParam["to_date"] =  moment(this.toDate).format('DD-MM-YYYY');
			this.rlogService.getAllItems();
		}, 100)
	}

}
