export class ImageModel {
	id: number;
	code: string;
	resource_id: number;
	resource_type: string;
	name: string;
	file_name: string;
	location: string;
	mime: string;
	size: number;
	is_active: boolean;
	is_primary: boolean;
	is_sysImage: boolean;
	description: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName?: string;
	url?: string;

	/**
	 * image
	 *
	 * @param image
	 */
	constructor(image) {
		this.id = image.id || '';
		this.code = image.code || '';
		this.resource_id = image.resource_id || '';
		this.resource_type = image.resource_type || '';
		this.name = image.name || '';
		this.file_name = image.file_name || '';
		this.location = image.location || '';
		this.mime = image.mime || '';
		this.size = image.size || '';
		this.is_active = image.is_active || '';
		this.is_primary = image.is_primary || '';
		this.is_sysImage = image.is_sysImage || '';
		this.description = image.description || '';
		this.created_by = image.created_by || '';
		this.updated_by = image.updated_by || '';
		this.created_at = image.created_at || '';
		this.updated_at = image.updated_at || '';
	}
}
