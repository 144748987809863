import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { AgentService, AicollectionService, ClientService, Payment, PaymentsService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
  providers: [OrderByPipe]
})
export class PaymentListComponent implements OnInit {

  paymentList: Payment[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  param: any;
  dataSource = new MatTableDataSource(this.paymentList);
  displayedColumns = ['date', 'name', 'payment', 'amount', 'payment link', 'status', 'details', 'action'];
  @ViewChild('rightSideBar') rightSideBar: MatSidenav;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  filterLoader: boolean = true;
  statusData: any;
  

  constructor(public paymentsService: PaymentsService,
    public aicollectionService : AicollectionService,
    private orderByPipe: OrderByPipe,
    public clientService: ClientService) {
    this.aicollectionService.getInit();
    this.clientService.getAllUsers();
    this.paymentsService.resetParams();
    this.paymentsService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
    });
    this.paymentsService.filterLoader.subscribe(data => this.filterLoader = data);
    this.paymentsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentList = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.paymentList);
      this.dataSource.sort = this.sort;
    });

    this.aicollectionService.isStatusPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['payment_status']) {
        if (data['transaction_response']) {
          data['transaction_response'] = JSON.parse(data['transaction_response']);
        }
        this.statusData = data;
      } else {
        delete this.statusData;
      }
    });

    this.paymentsService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.paymentsService.filterLoader.subscribe(data => this.filterLoader = data);
    this.aicollectionService.filterLoader.subscribe(data => this.filterLoader = data);

  }

  ngOnInit(): void {
    this.dataInit();
  }
  ngOnDestroy(): void {
    this.paymentsService.isLoader.next(false);
    this.paymentsService.resetParams();
    this.paymentsService.unSubscribe();
    this.paymentsService.unSubscribeFilter();
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }
  dataInit() {
    this.paymentsService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  toggleRightSidebar() {
		this.rightSideBar.toggle();
	}

  viewStatus(payment: any = null) {
    if (payment && payment._id) {
      var data = {
        "trackId": payment._id
      }
      this.aicollectionService.getStatusPayment(data);
    }
  }

  closeStatus() {
    this.aicollectionService.isStatusPaymentData.next(null);
  }
}
