import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	TeamService, Team,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'team-item-empower',
	templateUrl: './empower.component.html',
	styleUrls: ['./empower.component.scss']
})

export class EmpowerComponent implements OnInit, OnDestroy {
	authUser: User;
	team: Team;

	constructor(public userService: UserService,
		public teamService: TeamService,
		public matDialog: MatDialog) {
		this.teamService.item.pipe(untilDestroyed(this)).subscribe(data => this.team = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.teamService.unSubscribeFilter();
	}

}
