import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FbTemplateMessageService, TemplateMessageService } from 'src/app/@tji/_dbShare';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-itrsbot',
  templateUrl: './fbitrsbot.component.html',
  styleUrls: ['./fbitrsbot.component.scss']
})
export class FbItrsbotComponent implements OnInit {
  dialogTitle: string = 'Create';
  action: any;
  linkItrs: any;
  ItrsDataList: any;
  itrsId: any;
  itrsData: any;
  noItrsDataList: boolean = false;
  loader: boolean = true;
  subLoader: boolean = false;
  pageEvent: PageEvent;
	dataLength: number = 0;
  param: any;
  searchItrsData: string = '';
  itrsCategoryList: any = [];

  constructor(
    public fbTemplateMessageService: FbTemplateMessageService,
    public matDialogRef: MatDialogRef<FbItrsbotComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,

  ) {
    this.fbTemplateMessageService.currentItrs.subscribe(data => this.linkItrs = data);
    this.fbTemplateMessageService.totalItrsItem.subscribe(data => this.dataLength = data);
    this.fbTemplateMessageService.paramsItrs.subscribe(data => this.param = data);
    this.fbTemplateMessageService.itrsCategoryList.subscribe(data => this.itrsCategoryList = data);
    this.fbTemplateMessageService.currentAllItrs.subscribe(data => this.ItrsDataList = data);
    
    this.getInit(_data);
    // this.getInfo();
  }

  ngOnInit(): void {
    this.doLoader();
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  doLoader() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 5000);
  }

  getInit(data) {
    this.param['client_site_id'] = this.linkItrs?.client_site_id;
    this.fbTemplateMessageService.getIntractiItem(this.param, 'optionOne');
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Link To on ITRS';
        break;
      case 'edit':
        this.dialogTitle = 'Link To on ITRS';
        break;
      default:
        break;
    }
  }

  getInfo() {
    if (this.ItrsDataList.itractiveinfo.length > 1) {
      this.noItrsDataList = false;
    } else {
      this.noItrsDataList = true;
    }
  }

  selectItrs(itrsId: any, itrsbot_id: any) {
    this.itrsId = itrsId;
    this.linkItrs.requireValue
    // this.linkItrs.requireValue.parameters.forEach((button, index) => {
    //   if (index == this.linkItrs.idx) {
    //     button.payload = "temp_itrs_"+itrsId;
    //   }
    // });
    this.itrsData = {
      idx: this.linkItrs.idx,
      requireValue: this.linkItrs.requireValue,
      value: itrsId
    }
    this.matDialogRef.close(['new',this.itrsData]);
  }
  selectedItrs: any;
	searchItrsClear() {
		this.searchItrsData =  null;
    this.param['search'] = '';
    this.param.current = 1,
    delete this.param.search;
    this.fbTemplateMessageService.getIntractiItem(this.param, 'optionOne');
	}
  onSearchItrs() {
    this.param.current = 1,
    this.param['search'] = this.searchItrsData;
    this.fbTemplateMessageService.getIntractiItem(this.param, 'optionOne');
  }

  sortItrs(value){
  if (value == null) {
			this.param["intractive_category_id"] = 0;
			this.param.current = 1,
      delete this.param["intractive_category_id"];
			this.selectedItrs = null;
			this.fbTemplateMessageService.getIntractiItem(this.param, 'optionOne');
		} else {
      this.selectedItrs = value;
			this.param["intractive_category_id"] = value;
			this.param.current = 1;
      this.fbTemplateMessageService.getIntractiItem(this.param, 'optionOne');
		}
	}

	resetOpenParams() {
		this.param["intractive_category_id"] = 0;
    delete this.param["intractive_category_id"];
		this.param.current = 1,
		this.param.limit = 25,
		this.selectedItrs = null;
    this.fbTemplateMessageService.getIntractiItem(this.param, 'optionOne');
	}
}
