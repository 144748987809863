export class HistoryModel {
	id: number;
	model_type: string;
	model_id: number;
	client_id: number;
	event: string;
	description: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date;

	tableName?: string;
	model?: any;

	/**
	 * Constructor
	 *
	 * @param history
	 */
	constructor(history) {
		this.id = history.id || '';
		this.model_id = history.model_id || '';
		this.model_type = history.model_type || '';
		this.client_id = history.client_id || '';
		this.event = history.event || '';
		this.description = history.description || '';
		this.created_by = history.created_by || '';
		this.updated_by = history.updated_by || '';
		this.created_at = history.created_at || '';
		this.updated_at = history.updated_at || '';
		this.deleted_at = history.deleted_at || '';

		this.tableName = history.tableName || '';
	}
}
