import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ListComponent } from './../list/list.component';
import { MatSidenav } from '@angular/material/sidenav';

import {
	User, UserService,
	RTemplateMessageService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'fb-template-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {

	@ViewChild(ListComponent) listComponent;

	url: string;
	user: User;
	param: any;
	selectedTemplate: any = null;

	constructor(public userService: UserService,
		public rtemplateMessageService: RTemplateMessageService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.rtemplateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
	}

	ngOnInit() {
		this.rtemplateMessageService.changeItem(null);
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.rtemplateMessageService.unSubscribe();
		this.rtemplateMessageService.unSubscribeFilter();
	}

}
