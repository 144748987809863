import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Comment } from './comment.interface';
import { CommentModel } from './comment_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { ClientSiteService } from '../../core/client_site/client_site.service';
import { ClientSite } from '../../core/client_site/client_site.interface';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class CommentService implements Resolve<any> {
	url: string = 'comments';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
	};

	sortIdentity: any = {
		'name': 'name'
	};
	clientSites: ClientSite[] = [];
	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	public allItemsSource = new BehaviorSubject<Comment[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<Comment>(new CommentModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Comment[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar, public clientSiteService: ClientSiteService) {
		this._unsubscribeAll = new Subject();
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.length > 0) {
				this.clientSites = data;
			}
		});
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				// this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed CommentService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on CommentService');
	}

	concatlibrary(allItems: Comment[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Comment) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item._id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item._id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x._id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Comment) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item._id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i]._id === item._id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	incrementChildCountByCommentId(commentId: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && commentId) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i]._id === commentId) {
					oldLists[i].childrenCount = oldLists[i].childrenCount + 1;
					break;
				}
			}
		}
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: Comment[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Comment[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		// this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: Comment) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Comment[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Comment) {
		if (item.ReplyInfo && item.ReplyInfo.length > 0) {
			var replayInfo = [];
			item.ReplyInfo.forEach(replay => {
				if (!replay.deleted_at) {
					replayInfo.push(replay);
				}
			});
			item.ReplyInfo = replayInfo;
		}
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
			}
		}
		this.itemSource.next(item);
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 200,
			'current': 1
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data && data['additional'] && data['additional'].PeopleInfo.length > 0) {
						data['data'].forEach(comments => {
							data['additional'].PeopleInfo.forEach(info => {
								if (comments && comments.Resource_info && comments.Resource_info.people_id) {
									if (comments.Resource_info.people_id == info.id) {
										comments.Resource_info['people_info'] = info;
									}
								}
							});
							// if (comments.ReplyInfo && comments.ReplyInfo.length > 0) {
							// 	var replayInfo = [];
							// 	comments.ReplyInfo.forEach(replay => {
							// 		if (!replay.deleted_at) {
							// 			replayInfo.push(replay);
							// 		}
							// 	});
							// 	comments.ReplyInfo = replayInfo;
							// }
						});
						this.changeAllItems(data.data);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems(data.data);
						this.changeTotalItem(data.pagnitation.totalResult);
					}

				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}


	getTicketComments(params: any = null) {
		params = this.paramsInit(params);
		params['ticket_id'] = params.resource_id;
		params['limit'] = 200;
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data && data['additional'] && data['additional'].PeopleInfo.length > 0) {
						data['data'].forEach(comments => {
							data['additional'].PeopleInfo.forEach(info => {
								if (comments && comments.Resource_info && comments.Resource_info.people_id) {
									if (comments.Resource_info.people_id == info.id) {
										comments.Resource_info['people_info'] = info;
									}
								}
							});
							// if (comments.ReplyInfo && comments.ReplyInfo.length > 0) {
							// 	var replayInfo = [];
							// 	comments.ReplyInfo.forEach(replay => {
							// 		if (!replay.deleted_at) {
							// 			replayInfo.push(replay);
							// 		}
							// 	});
							// 	comments.ReplyInfo = replayInfo;
							// }
						});
						this.changeAllItems(data.data);
						// this.librariesSource.next(data.data);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems(data.data);
						// this.librariesSource.next(data.data);
						this.changeTotalItem(data.pagnitation.totalResult);
					}

				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}

				if (data.data.data && data.data.data['additional'] && data.data.data['additional'].PeopleInfo.length > 0) {
						data.data.data['data'].forEach(comments => {
							data.data['additional'].PeopleInfo.forEach(info => {
								if (comments && comments.Resource_info && comments.Resource_info.people_id) {
									if (comments.Resource_info.people_id == info.id) {
										comments.Resource_info['people_info'] = info;
									}
								}
							});
							// if (comments.ReplyInfo && comments.ReplyInfo.length > 0) {
							// 	var replayInfo = [];
							// 	comments.ReplyInfo.forEach(replay => {
							// 		if (!replay.deleted_at) {
							// 			replayInfo.push(replay);
							// 		}
							// 	});
							// 	comments.ReplyInfo = replayInfo;
							// }
						});
					}

				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: Comment) {
		let lists = [];
		if (this.clientSites && this.clientSites.length > 0) {
			this.clientSites.forEach(sites => {
				if (item.client_site_id == sites.id) {
					item["clientsite_info"] = sites;
				}
			});
		}
		if (item.ReplyInfo && item.ReplyInfo.length > 0) {
			var replayInfo = [];
			item.ReplyInfo.forEach(replay => {
				if (!replay.deleted_at) {
					replayInfo.push(replay);
				}
			});
			item.ReplyInfo = replayInfo;
		}
		// this.libraries.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
		// 	lists = result.concat([item]);
		// });
		// let lists = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result
			if (!lists) {
				lists = [];
			}
			if (lists && lists.length > 0) {
				var foundIndex = lists.findIndex(x => x?._id == item._id);
				if (foundIndex >= 0) {
					lists[foundIndex] = item;
					isChanged = true;
				} else {
					lists = lists.concat([item]);
					isChanged = true;
				};
			} else {
				lists[0] = item;
				isChanged = true;
			}
		});
		if (isChanged) {
			this.changeAllItems(lists);
			isChanged = false;
		}
		
		// this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i]._id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	spliceCommentItem(item) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			allItems.forEach((commend, index) => {
				if (commend._id == item._id && !item.deleted_at && item.ReplyInfo && item.ReplyInfo.length > 0) {
					var replayInfo = [];
					item.ReplyInfo.forEach(replay => {
						if (!replay.deleted_at) {
							replayInfo.push(replay);
						}
					});
					commend.ReplyInfo = replayInfo;
				}
				if (commend._id == item._id && item.deleted_at) {
					allItems.splice(index, 1)
				}
			});
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new CommentModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}
	getData(url, data: any) {
		this.commonService.getData(url, data, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data.ReplyInfo && data.data.ReplyInfo.length > 0) {
						var replayInfo = [];
						data.data.ReplyInfo.forEach(replay => {
							if (!replay.deleted_at) {
								replayInfo.push(replay);
							}
						});
						data.data.ReplyInfo = replayInfo;
					}

					this.itemSource.next(data.data);
					this.changeAllItemsByItem(data.data);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	storeData(url, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.concatItem(data.data);
					this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	replayComment(url, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var comment = {
						"commentId": data.data._id,
					}
					this.getData('comments/view', comment);
					this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	closeTicketComment(url, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (!data.success) {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	feedComment(url, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var comment = {
						"commentId": data.data._id,
					}
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(comment: any) {
		this.commonService.storeItem("comments/delete", comment, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(comment.commentId);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroyReplay(comment: any) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		this.commonService.storeItem("comments/delete", comment, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (allItems && allItems.length > 0) {
					var replayInfo = [];
					allItems.forEach(command => {
						if (command._id == comment.commentId && command.ReplyInfo && command.ReplyInfo.length > 0) {
							command.ReplyInfo.forEach(replay => {
								if (replay.id != comment.replyId) {
									replayInfo.push(replay);
								}
							});
							command.ReplyInfo = replayInfo;
						}
					});
				}
				this.changeAllItems(allItems);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.limit = 200;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

}