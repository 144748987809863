export class BoardModel {
    id: number;
    name: string;
    slug: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName: string;

    /**
     * Constructor
     *
     * @param Board
     */
    constructor(board) {
            this.id = board.id || '';
            this.name = board.name || '';
            this.slug = board.slug || '';
            this.created_by = board.created_by || '';
            this.updated_by = board.updated_by || '';
            this.created_at = board.created_at || '';
            this.updated_at = board.updated_at || '';

            this.tableName = board.tableName || '';
    }
}
