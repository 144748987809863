export class RLogModel {
	id: number;
	description: string;
	userType: string;
	userId: number;
	route: string;
	ipAddress: string;
	userAgent: string;
	locale: string;
	referer: string;
	methodType: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date;

    device?: string;
    userAgentDetails?: any;
	tableName?: string;
	avatar_url?: string;
	userDetails?: any;
	timePassed?: any;

	/**
	 * Constructor
	 *
	 * @param log
	 */
	constructor(log) {
		this.id = log.id || '';
		this.description = log.description || '';
		this.userType = log.userType || '';
		this.userId = log.userId || '';
		this.route = log.route || '';
		this.ipAddress = log.ipAddress || '';
		this.userAgent = log.userAgent || '';
		this.locale = log.locale || '';
		this.referer = log.referer || '';
		this.methodType = log.methodType || '';
		this.created_by = log.created_by || '';
		this.updated_by = log.updated_by || '';
		this.created_at = log.created_at || '';
		this.updated_at = log.updated_at || '';
		this.deleted_at = log.deleted_at || '';

		this.device = log.device || 'Windows';
		this.tableName = log.tableName || '';
	}
}
