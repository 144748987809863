import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	SiteService, Site,
	ClientSiteService, ClientSite, ClientSiteModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})

export class ItemComponent implements OnInit, OnDestroy {
	authUser: User;
	clientSite: ClientSite;

	intractionTypes: any = [
		{ 'id': 1, 'title': 'Auto Assign', 'image': 'assets/img/scrm/autoassign.jpg', 'description': 'This mode will Assign your Feeds Automaticall to the Agent by Reading the Contents.', 'permission': ['View Auto Assign', 'Add Auto Assign', 'Manage Auto Assign'] },
		{ 'id': 2, 'title': 'ITRS', 'image': 'assets/img/scrm/itrs.jpg', 'description': 'This mode will Intract with your Customer then Assign Ticket accordingly by Intractive Text Response System.', 'permission': ['View ITRS', 'Add ITRS', 'Manage ITRS'] },
		{ 'id': 3, 'title': 'Webhook', 'image': 'assets/img/scrm/webhook.jpg', 'description': 'This mode will Redirect the Customer messages by Webhook URL and Response accordingly by Restfull API.', 'permission': ['View Webhook', 'Add Webhook', 'Manage Webhook'] },
	];
	intractiveButtonOption: any = {
		title: 'Intractive Type',
		defaultSelection: null,
		menus: this.intractionTypes
	}

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public siteService: SiteService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		public location: Location) {
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => {
			if (data) {
				this.clientSite = data;
			this.intractiveButtonOption.defaultSelection = (this.clientSite) ? this.clientSite.intraction_type : null;
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getIcon() {
		return (this.clientSite && this.clientSite.details && this.clientSite.details.icon) ? this.clientSite.details.icon : "home";
	}

	changeIntract(intract) {
		if (this.clientSite && this.clientSite.intraction_type != intract.id) {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '400px',
				data: {
					title: 'Change Mode of Intraction',
					type: 'info',
					message: 'Are you sure want to Change this Intraction Mode to ' + intract.title + ' ? So, that you may be loose your Previous Settings.',
				}
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				// if (result && result.data) {
				// 	this.clientSiteService.update(this.clientSite.id, { 'intraction_type': intract.id });
				// }
				if (result && result.data) {
					var data = {
						"client_site_id": this.clientSite.id,
						'intraction_type': intract.id
					}

					this.clientSiteService.updateItrsSettings(data, 'client-site/settings');
				}
			});
		}
	}

}
