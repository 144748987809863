import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, Intractive, IntractiveService,
	IntractiveMessageService, IntractiveMessage, IntractiveMessageModel,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-intractive-message-form',
	templateUrl: './linkIntractiveMessageForm.component.html',
	styleUrls: ['./linkIntractiveMessageForm.component.scss']
})

export class LinkIntractiveMessageFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Change Intractive Message';
	intractive: Intractive;
	intractiveMessages: IntractiveMessage[] = [];
	filteredIntractiveMessages: IntractiveMessage[] = [];
	intractiveMessage: IntractiveMessage;
	action: string;
	linkIntractiveMessageForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public intractiveMessageService: IntractiveMessageService,
		public intractiveService: IntractiveService,
		public matDialogRef: MatDialogRef<LinkIntractiveMessageFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractive = data;
			this.linkIntractiveMessageForm = this.createForm();
		});
		this.intractiveMessageService.intrctiveListItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveMessages = data;
			if (this.intractive && this.intractive.id) {
				this.filteredIntractiveMessages = this.intractiveMessages
					.filter(x => { return x.client_id === this.intractive.client_id });
			}

		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Add Intractive Message';
				break;
			case 'edit':
				this.dialogTitle = 'Change Intractive Message';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			intractive_message_id: [this.intractive.intractive_message_id || null, [Validators.required]],
		});
	}

}
