import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ClientSite, ClientSiteService,
	OpenTicketService, Feed
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'queue-ticket-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;
	param: any;
	filterButtonOption: any = {
		title: 'Active Tickets',
		menus: [
			{ title: 'Active Tickets', value: 'false' },
			{ title: 'Closed Tickets', value: 'true' },
		]
	}
	clientSites: ClientSite[] = [];

	constructor(public userService: UserService,
		public openTicketService: OpenTicketService,
		public clientSiteService: ClientSiteService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.openTicketService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.clientSites = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	filterTicket(event) {
		this.param.deleted_at = event;
		this.param.page = 1;
		this.openTicketService.getAllItems();
	}

	filterTicketByAccount(clientSiteId = null) {
		this.param.client_site_id = clientSiteId;
		this.param.page = 1;
		this.openTicketService.getAllItems();
	}
}
