import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { ItemComponent } from './item/item.component';

import { ItrsbotService, ClientSiteService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: {
			data: ItrsbotService,
			clientSites: ClientSiteService,
		},
	},
	{
		path: ':id',
		component: ItemComponent,
		resolve: {
			data: ItrsbotService
		},
	},
	{ path: '**', redirectTo: '/app/itrsbot' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class ItrsbotRouteModule { }
