import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith } from 'rxjs/operators';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

import {
  UserService, AlertService, MediaService, CommonService,
  BrandService,
  CountryService
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@UntilDestroy()
@Component({
  selector: 'sidebar-brand',
  templateUrl: './sidebar-brand.component.html',
  styleUrls: ['./sidebar-brand.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SidebarBrandComponent {

  @Output() onClose = new EventEmitter();
  brandForm: UntypedFormGroup;
  brandTitle: string = 'New Brand';
  brandItem: any;
  params: any;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  isImageUpload: boolean = false;
  isEdite: boolean = false;
  tax: any;
  categoryList: any;
  countryList: any;
  myCountry = new FormControl<any>('');
  filteredOptions: Observable<any>;
  year = new FormControl(moment());
  today = new Date();
  selectedYear = this.today.getFullYear();
  selectedTax: string = '1';
  taxValue: any = 0;
  country: any;

  private _unsubscribeAll: Subject<any>;
  constructor(public brandService: BrandService, public countryService: CountryService,
    public matDialog: MatDialog,
    public userService: UserService,
    public mediaService: MediaService,
    private commonService: CommonService,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder) {
    this._unsubscribeAll = new Subject();
    this.countryService.allCategory.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.categoryList = data;
      }
    });
    this.countryService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.countryList = data;
        this.filterInit();
      }
    });
    this.brandService.params.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.params = data;
      }
    });
    this.brandService.item.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        console.log('=========================', data);
        this.brandItem = data;
        if (data && data._id) {
          this.brandTitle = 'Edit Brand';
          this.productPrice = this.brandItem?.price;
          this.imageUrl = this.brandItem?.logo;
          this.selectedYear = this.brandItem.established_at ? this.brandItem.established_at : '';

          if (this.brandItem.countryInfo && this.brandItem.countryInfo.taxinfo.type) {
            // this.country = parseInt(this.brandItem.countryInfo.id);
            var country = this.countryList.filter(c => c.id == this.brandItem.country);
            this.country = country[0];
            this.selectedTax = this.brandItem.countryInfo.taxinfo.type.toString();
            this.taxValue = this.brandItem.countryInfo.taxinfo.value.toString();
          } else {
            this.selectedTax = '1';
            this.taxValue = '0';
          }
          this.isEdite = true;
        } else {
          this.brandTitle = 'New Brand';
        }
      }
      this.brandForm = this.createForm();
    });

    this.brandService.addItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == true) {
        this.brandForm.reset();
        delete this.imageUrl;
        delete this.productPrice;
        this.brandService.isAddItems.next(false);
        this.resetImage();
        delete this.brandItem;
        this.brandService.itemSource.next(null);
        this.isEdite = false;
        delete this.country;
        this.taxValue = 0;
        this.brandTitle = 'New Brand';
      }
    });

  }


  ngOnInit(): void {
    delete this.imageUrl;

  }

  /** On destroy */
  ngOnDestroy(): void {
    this.closeWindow();
    this.brandService.itemSource.next(null);
    this.brandService.unSubscribe();
    delete this.brandItem;
    delete this.imageUrl;
    this.brandService.itemSource.next(null);
    this.isEdite = false;
    this.brandTitle = 'New Brand';
  }
  unSubscribe() {
    // console.log('UnSubscribed MediaService');
  }

  chosenYearHandler(normalizedYear: Moment, dp: any) {
    const ctrlValue = this.year.value;
    ctrlValue.year(normalizedYear.year());
    this.year.setValue(ctrlValue);
    dp.close();
    this.selectedYear = moment(ctrlValue).year() //this.year.value.getFullYear();
  }

  filterInit() {
    this.filteredOptions = this.myCountry.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.countryList.slice();
      }),
    );
  }
  displayFn(user: any): string {
    return user && user.name_en ? user.name_en : '';
  }

  private _filter(name_en: string): any[] {
    const filterValue = name_en.toLowerCase();

    return this.countryList.filter(option => option.name_en.toLowerCase().includes(filterValue));
  }

  createForm(): UntypedFormGroup {
    // var minOrder = null;
    // if (this.brandItem.orderSettings.min_order) {

    // }
    return this._formBuilder.group({
      name: [this.brandItem?.name || null, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]],
      email: [this.brandItem?.email || null, [Validators.required, Validators.minLength(1), Validators.maxLength(60), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      established_at: [this.brandItem?.established_at || null],
      country: [this.country?.id || null, [Validators.required]],
      website: [this.brandItem?.website || null, [Validators.pattern(this.urlRegex)]],
      about: [this.brandItem?.about || null, [Validators.minLength(1), Validators.maxLength(200)]],
      logo: [this.brandItem?.logo || null, [Validators.required]],
      category: [this.brandItem?.category || null],
      min_order: [this.brandItem?.orderSettings?.min_order || null],
      tax: [this.selectedTax ? this.selectedTax : '0'],
      taxValue: [this.taxValue ? this.taxValue : '0', [Validators.maxLength(10)]],
    });
  }


  // catalog_id
  // image_url
  autoGenerateGtn: boolean = false;
  productPrice: any;
  submit() {

    if (this.brandForm.invalid) {
      return
    }

    if (!this.brandForm.value.logo) {
      this.alertService.webShow('Danger', 'Select Product Image');
    }

    var data = {
      "name": this.brandForm.value.name ? this.brandForm.value.name : '',
      "email": this.brandForm.value.email ? this.brandForm.value.email : '',
      "logo": this.brandForm.value.logo ? this.brandForm.value.logo : '',
      "established_at": this.selectedYear ? this.selectedYear : '',
      "country": this.brandForm.value.country ? this.brandForm.value.country : '',
      "website": this.brandForm.value.website ? this.brandForm.value.website : '',
      "about": this.brandForm.value.about ? this.brandForm.value.about : '',
      "category": this.brandForm.value.category ? this.brandForm.value.category : '',
      "min_order": this.brandForm.value.min_order ? this.brandForm.value.min_order : '',
      "tax": {
        "type": this.selectedTax,
        "value": this.brandForm.value.taxValue ? this.brandForm.value.taxValue : '0'.toString(),
      }

    }

    if (this.brandItem && this.brandItem._id) {
      data['id'] = this.brandItem._id;
      this.brandService.update('brand/update', data);
      this.onClose.emit(false)
      delete this.brandItem;
      delete this.imageUrl;
      this.brandService.itemSource.next(null);
      this.isEdite = false;
      this.brandTitle = 'New Brand';
    } else {
      this.brandService.store('brand/add', data);
      this.onClose.emit(false)
      delete this.brandItem;
      delete this.imageUrl;
      this.brandService.itemSource.next(null);
      this.isEdite = false;
      this.brandTitle = 'New Brand';
    }
  }

  selectCountry(data: any) {
    if (this.brandItem && this.brandItem.clientSite && this.brandItem.clientSite.length > 0) {
      let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600',
        data: {
          type: 'alert',
          message: 'All delivery settings will be removed on connected client site.',
        }
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          this.brandForm.controls.country.setValue(data.option.value.id);
          // this.brandService.linkClientSite('brand/removeclientsite', data);
        } else {
          // this.dataReconstract();
          if (this.brandItem && this.brandItem.countryInfo && this.brandItem.countryInfo.taxinfo.type) {
            var country = this.countryList.filter(c => c.id == this.brandItem.country);
            this.country = country[0];
            this.brandForm.controls.country.setValue(country.id);
          }
        }
      });
    } else {

      if (data == null) {
        this.brandForm.controls.country.setValue('');
        delete this.country;
      } else {
        this.brandForm.controls.country.setValue(data.option.value.id);
      }
    }
  }


  resetForm() {
    this.brandForm.reset();
    // this.brandForm.get('business_id').enable();
  }

  imageHandlerData(event) {
    this.isImageUpload = true;
    if (event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/png") {
      let reader = new FileReader();
      let fileData = null;
      var filesize = event.target.files[0].size;
      const fileSizeInKB = Math.round(filesize / 1024);
      if (fileSizeInKB <= 8192) {
        if (event.target.files && event.target.files.length) {
          const file = event.target.files[0];
          reader.readAsDataURL(file);

          reader.onload = (e: any) => {

            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              if (img.width > 499 && img.height > 499) {

                var images = {
                  asset: reader.result,
                  asset_name: file.name.replace(/ /g, "_"),
                  asset_type: 'image'
                };
                this.addStore(images);
              } else {
                this.isImageUpload = false;
                this.alertService.webShow('Danger', 'Please Select minimum images size for widht 500px and height 500px');
              }
            };
          };
        }
      } else {
        this.isImageUpload = false;
        this.alertService.webShow('Danger', 'Please Select below 8MB');
      }
    } else {
      this.isImageUpload = false;
      this.alertService.webShow('Danger', 'Image Format Not Supported, Please Select JPEG, JPG and PNG');
    }
  }

  imageUrl: any = null;
  addStore(data: any) {
    this.commonService.storeItem('media/add', data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.isImageUpload = false;
          this.brandForm.controls.logo.setValue(res.data.url);
          this.imageUrl = res.data.url;
          this.alertService.webShow('Success', 'updated Successfully !!!');
        } else {
          this.isImageUpload = false;
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          this.isImageUpload = false;
          console.log('Error ::' + error);
          this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  productAmount: any;
  /** number only accept */
  numberOnly(event: any): boolean {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  }

  /** number only accept */
  orderIndex(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  resetImage() {
    this.brandForm.controls.logo.setValue('');
    delete this.imageUrl;
  }

  closeWindow() {
    this.resetImage();
    delete this.brandItem;
    delete this.imageUrl;
    this.brandService.itemSource.next(null);
    this.isEdite = false;
    delete this.country;
    this.taxValue = 0;
    this.brandTitle = 'New Brand';
  }

  taxChange(event: any) {
  }
}

