import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { TemplateUsageComponent } from './template-usage/template-usage.component';
import { TemplateSuccessComponent } from './template-success/template-success.component';
import { TemplateFailedComponent } from './template-failed/template-failed.component';

import { TemplateUsageReportsService, TemplateSuccessService, TemplateFailedService } from 'src/app/@tji/_dbShare';
import { TemplateViewComponent } from './template-view/template-view.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: TemplateUsageComponent,
        resolve: { data: TemplateUsageReportsService }
      },
      {
        path: 'list',
        component: TemplateUsageComponent,
        resolve: { data: TemplateUsageReportsService }
      },
      {
        path: ':templateUsageId/success',
        component: TemplateSuccessComponent,
        resolve: { data: TemplateSuccessService }
      },
      {
        path: 'success',
        component: TemplateSuccessComponent,
        resolve: { data: TemplateSuccessService }
      },
      {
        path: ':templateUsageId/failed',
        component: TemplateFailedComponent,
        resolve: { data: TemplateFailedService }
      },
      {
        path: 'failed',
        component: TemplateFailedComponent,
        resolve: { data: TemplateFailedService }
      },
      {
        path: ':templateUsageId/view',
        component: TemplateViewComponent,
        resolve: { data: TemplateUsageReportsService }
      }
    ],
  },
  { path: '**', redirectTo: '/app/template-usage/list' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateGroupRoutingModule { }
