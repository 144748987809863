import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ChatService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'tweetdm-ticket-item-left',
	templateUrl: './left.component.html',
	styleUrls: ['./left.component.scss']
})

export class LeftComponent implements OnInit, OnDestroy {
	user: User;

	constructor(public userService: UserService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

}