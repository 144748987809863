import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tjiAnimation } from 'src/app/@tji/animation';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, PeopleService,
	ModalHasFlagService, ModalHasFlag,
	ChatService,
	Flag,
	FlagService,
	ClosedTicketService,
	OpenTicketsService,
	SocketService,
	ClientSiteService,
	ClientSite
} from 'src/app/@tji/_dbShare';

import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

@UntilDestroy()
@Component({
	selector: 'ticket-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	animations: tjiAnimation
})

export class ListComponent implements OnInit, OnDestroy {
	user: User;
	ticket: any;
	peopleParam: any;

	ticketFlagParam: any = null;
	animStateTicket = false;
	animStateTwitter = false;
	animStateTwitterdm = false;
	animStateMessenger = false;
	animStateWhatsapp = false;
	animStateWebchat = false;
	animStateYoutube = false;
	animStateInstagram = false;
	animStateInstadm = false;

	hasNewTicket: boolean = false;
	tweetHasNewTicket: boolean = false;
	tweetdmHasNewTicket: boolean = false;
	messengerHasNewTicket: boolean = false;
	whatsappHasNewTicket: boolean = false;
	webchatHasNewTicket: boolean = false;
	youtubeHasNewTicket: boolean = false;
	instagramHasNewTicket: boolean = false;
	instadmHasNewTicket: boolean = false;
	viewScreen: any;
	expanded: string = 'openticket';
	flags: Flag[] = [];
	selectedFlag: any;
	selectedClientSites: any;
	closedTicketParams: any;
	openTicketParams: any;
	status: OnlineStatusType;
	OnlineStatusType = OnlineStatusType;
	isReconnect: boolean = false;
	clientSitesAll: ClientSite[] = [];

	constructor(public userService: UserService,
		public chatService: ChatService,
		public peopleService: PeopleService,
		public modalHasFlagService: ModalHasFlagService,
		public flagService: FlagService,
		public closedTicketService: ClosedTicketService,
		public openTicketsService: OpenTicketsService,
		private router: Router,
		public socketService: SocketService,
		private onlineStatusService: OnlineStatusService,
		public clientSiteService: ClientSiteService,
		public matDialog: MatDialog) {
		this.flagService.allFlag.pipe(untilDestroyed(this)).subscribe(data => this.flags = data);
		this.userService.user.subscribe(data => this.user = data);
		this.peopleService.params.pipe(untilDestroyed(this)).subscribe(data => this.peopleParam = data);
		this.closedTicketService.params.pipe(untilDestroyed(this)).subscribe(data => this.closedTicketParams = data);
		this.openTicketsService.params.pipe(untilDestroyed(this)).subscribe(data => this.openTicketParams = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.chatService.viewScreen.pipe(untilDestroyed(this)).subscribe(data => this.viewScreen = data);
		this.modalHasFlagService.params.pipe(untilDestroyed(this)).subscribe(data => this.ticketFlagParam = data);
		// this.socketService.isSocket.pipe(untilDestroyed(this)).subscribe(data => {
		// 	if (data) {
		// 		// this.socketService
		// 		this.openTicketsService.getRecentOpenedTickets();
		// 		this.closedTicketService.getRecentClosedTickets('n/tickets');
		// 	}
		// });
		this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
			if (status == 1) {
				this.loader();
				this.isReconnect = true;
				this.openTicketsService.resetParams();
				this.closedTicketService.resetParams();
				this.openTicketsService.getRecentOpenedTickets();
				this.closedTicketService.getRecentClosedTickets('n/tickets');
			}

		});
		// this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.clientSitesAll = data );
	}

	ngOnInit() {
		this.getTicketFlags();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	loader() {
		setTimeout(() => {
			this.isReconnect = false;
		}, 2000);
	}

	getTicketFlags() {
		if (this.ticket && this.ticket.id) {
			this.chatService.onChangeTicket.subscribe(data => {
				this.ticketFlagParam.client_id = (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null;
				this.ticketFlagParam.client_site_id = (this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : null;
				this.ticketFlagParam.resource_id = (this.ticket && this.ticket.id) ? this.ticket.id : null;
				if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
					this.ticketFlagParam.resource_id) {
					this.modalHasFlagService.getAllItems();
				}
			});
		}
	}

	animDoneTicket() {
		this.animStateTicket = !this.animStateTicket;
	}

	animDoneTwitter() {
		this.animStateTwitter = !this.animStateTwitter;
	}

	animDoneTwitterdm() {
		this.animStateTwitterdm = !this.animStateTwitterdm;
	}

	animDoneMessenger() {
		this.animStateMessenger = !this.animStateMessenger;
	}

	animDoneWhatsapp() {
		this.animStateWhatsapp = !this.animStateWhatsapp;
	}

	animDoneWebchat() {
		this.animStateWebchat = !this.animStateWebchat;
	}

	animDoneYoutube() {
		this.animStateYoutube = !this.animStateYoutube;
	}

	animDoneInstagram() {
		this.animStateInstagram = !this.animStateInstagram;
	}

	animDoneInstadm() {
		this.animStateInstadm = !this.animStateInstadm;
	}

	onSelectCustomerPanel(accountType = null) {
		if (accountType) {
			this.peopleParam.client_id = this.userService.getAuthClientId();
			this.peopleParam.accountType = accountType.toLowerCase();
			this.peopleService.getAllItems();
		}
	}
	selectFlag(value) {
		if (value == null) {
			this.closedTicketService.changeLibraries([]);
			this.closedTicketParams["flag_id"] = '';
			delete this.closedTicketParams["flag_id"];
			this.closedTicketParams.current = 1,
			this.closedTicketParams.limit = 25,
			this.selectedFlag = null;
			this.closedTicketParams.search = '';
			this.closedTicketService.isSearchLoader.next(true);
			this.getClosedTickets();
		} else {
			this.closedTicketService.changeLibraries([]);
			this.closedTicketParams["flag_id"] = value;
			this.closedTicketParams.current = 1,
			this.closedTicketParams.limit = 20,
			this.closedTicketService.isSearchLoader.next(true);
			this.getClosedTickets();
		}
	}

	resetParams() {
		this.closedTicketService.changeLibraries([]);
		this.closedTicketParams["flag_id"] = '';
		delete this.closedTicketParams["flag_id"];
		this.closedTicketParams.search = '';
		this.closedTicketParams.current = 1,
		this.closedTicketParams.limit = 25,
		this.selectedFlag = null;
		this.getClosedTickets();
	}

	getClosedTickets() {
		this.closedTicketService.getRecentClosedTickets('n/tickets');
	}

	searchData: string = '';
	onSearch() {
		this.closedTicketService.isSearchLoader.next(true);
		this.closedTicketService.changeLibraries([]);
		this.closedTicketService.onSearch(this.searchData);
	}

	searchClear() {
		this.closedTicketService.isSearchLoader.next(true);
		this.closedTicketService.changeLibraries([]);
		this.searchData = '';
		delete this.closedTicketParams["flag_id"];
		this.closedTicketParams.search = '';
		this.closedTicketService.resetParams();
		this.closedTicketService.getRecentClosedTickets('n/tickets');
	}

	selectClientSites(value) {
		if (value == null) {
			this.closedTicketService.changeLibraries([]);
			this.openTicketParams["client_site_id"] = '0';
			this.openTicketParams.current = 1,
			this.openTicketParams.limit = 25,
			this.selectedClientSites = null;
			this.getOpenTickets();
		} else {
			this.closedTicketService.changeLibraries([]);
			this.openTicketParams["client_site_id"] = value;
			this.openTicketParams.current = 1,
			this.openTicketParams.limit = 20,
			this.getOpenTickets();
		}
	}

	resetOpenParams() {
		this.openTicketsService.changeLibraries([]);
		this.openTicketParams["client_site_id"] = '0';
		this.openTicketParams.current = 1,
		this.openTicketParams.limit = 25,
		this.selectedClientSites = null;
		this.getOpenTickets();
	}

	getOpenTickets() {
		this.openTicketsService.getRecentOpenedTickets();
	}


	searchTicketData: string = '';
	onSearchTicket() {
		this.openTicketsService.isSearchLoader.next(true);
		this.openTicketsService.changeLibraries([]);
		this.openTicketsService.onSearch(this.searchTicketData);
	}

	searchTicketClear() {
		this.openTicketsService.isSearchLoader.next(true);
		this.openTicketsService.changeLibraries([]);
		this.searchTicketData = '';
		this.openTicketsService.resetParams();
		this.openTicketsService.getRecentOpenedTickets();
	}
}
