import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ClientSite, ClientSiteService,
	FindChatService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'find-conversation-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	url: string;
	user: User;
	param: any;
	selectedFind:any = null;

	filterButtonOption: any = {
		title: 'Active Tickets',
		menus: [
			{ title: 'Active Tickets', value: 'false' },
			{ title: 'Closed Tickets', value: 'true' },
		]
	}
	clientSites: ClientSite[] = [];

	constructor(public userService: UserService,
		public findChatService: FindChatService,
		public clientSiteService: ClientSiteService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.findChatService.item.pipe(untilDestroyed(this)).subscribe(data => this.selectedFind = data);
		this.findChatService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.findChatService.item.pipe(untilDestroyed(this)).subscribe(data => this.selectedFind = data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.clientSites = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	filterTicket(event) {
		this.param.deleted_at = event;
		this.param.page = 1;
		this.findChatService.getAllItems();
	}

	filterTicketByAccount(clientSite = null) {
		if(clientSite && clientSite.site && clientSite.site.name) {
			this.param.client_site_id = clientSite.id;
			this.param.page = 1;
			switch(clientSite.site.name) {
				case "Whatsapp":
				case "whatsapp":
					this.findChatService.url = this.url = 'whatsapp';
					this.param.null = "whatsapp_id|true";
					break;
				case "Webchat":
				case "webchat":
					this.findChatService.url = this.url = 'webchat';
					this.param.null = "webchat_id|true";
					break;
				case "Youtube":
				case "youtube":
					this.findChatService.url = this.url = 'youtube';
					this.param.null = "youtube_id|true";
					break;
				case "Messenger":
				case "messenger":
					this.findChatService.url = this.url = 'messenger';
					this.param.null = "messenger_id|true";
					break;
				case "Instagram":
				case "instagram":
					this.findChatService.url = this.url = 'instagram';
					this.param.null = "instagram_id|true";
					break;
				case "Instadm":
				case "instadm":
					this.findChatService.url = this.url = 'instadm';
					this.param.null = "instadm_id|true";
					break;
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.findChatService.url = this.url = 'tweet';
					this.param.null = "tweet_id|true";
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.findChatService.url = this.url = 'tweetdm';
					this.param.null = "tweetdm_id|true";
					break;
			}
			this.findChatService.getAllItems();
		}
	}

	onSearch(event) {
		if(event.keyCode === 13) {
			this.findChatService.url = this.url;
			this.findChatService.onSearch(event.target.value);
		}
	}
}
