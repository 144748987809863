import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormSubMenuComponent } from './../formSubMenu/formSubMenu.component';

import {
	User, UserService, MenuService, Menu, SubMenu,
	SubMenuService, MenuGroup, MenuGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'menugroup-submenu-list',
	templateUrl: './subMenuList.component.html',
	styleUrls: ['./subMenuList.component.scss']
})

export class SubMenuListComponent implements OnInit, OnDestroy {

	loader: boolean = true;
	authUser: User;
	dataSource;

	menu: Menu;
	subMenus: SubMenu[] = [];
	// subMenus: any;
	displayedColumns = ['title', 'type', 'translate', 'icon', 'route', 'order', 'hidden', 'active', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public subMenuService: SubMenuService,
		public menuService: MenuService,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.menuService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.menu = data;
			this.subMenus = (data.sub_menus && data.sub_menus.length > 0) ? data.sub_menus : [];
			this.dataSource = new MatTableDataSource(this.subMenus);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
		// this.dataSource = new MatTableDataSource(this.selectMenu?.sub_menus);
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.subMenuService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		// this.menuGroupService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		// this.menuService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Menu */
	newMenuGroup(menu = null): void {
		this.subMenuService.changeItem((menu) ? menu : new MenuGroupModel({}));
		let dialogRef = this.matDialog.open(FormSubMenuComponent, {
			width: "600px",
			data: {
				action: (menu) ? 'edit' : 'new',
				item: menu,
				title: 'Sub Menu',
				menu_id: this.route.snapshot.params.subId,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.subMenuService.store(formData.value);
						break;
					case 'update':
						this.subMenuService.update(id, formData.value);
						break;
					case 'delete':
						this.deleteMenuGroup(menu);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteMenuGroup(menu: MenuGroup): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.subMenuService.destroy(menu.id);
			}
		});
	}

}
