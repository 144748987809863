import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../form/form.component';

import {
	User, UserService,
	MenuGroupService, MenuGroup, MenuGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'menugroup-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, OnDestroy {

	loader: boolean = true;
	authUser: User;
	menuGroups: any = [];
	param: any;
	menuGroupResponse: any;
	dataSource = new MatTableDataSource(this.menuGroups);
	displayedColumns = ['title', 'type', 'translate', 'icon', 'route', 'order', 'hidden', 'active', 'action'];
	selectedMenuGroup: MenuGroup = null;
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public menuGroupService: MenuGroupService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.menuGroupService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.menuGroups = data;
			this.dataSource = new MatTableDataSource(this.menuGroups);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}


	/** On destroy */
	ngOnDestroy(): void {
		this.menuGroupService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.menuGroupService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		// this.menuService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Menu */
	newMenuGroup(menu = null): void {
		this.menuGroupService.changeItem((menu) ? menu : new MenuGroupModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (menu) ? 'edit' : 'new',
				item: menu,
				title: 'Menu Group',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.menuGroupService.store(formData.value);
						break;
					case 'update':
						this.menuGroupService.update(id, formData.value);
						break;
					case 'delete':
						this.deleteMenuGroup(menu);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteMenuGroup(menu: MenuGroup): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.menuGroupService.destroy(menu.id);
			}
		});
	}
}
