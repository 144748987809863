export class RPermissionModel {
    id: number;
    name: string;
    guard_name: string;
    created_at: Date;
    updated_at: Date;
    module: string;
    modules: any;

    /**
     * Constructor
     *
     * @param permission
     */
    constructor(permission) {
            this.id = permission.id || '';
            this.name = permission.name || '';
            this.guard_name = permission.guard_name || '';
            this.created_at = permission.created_at || '';
            this.updated_at = permission.updated_at || '';
            this.module = permission.module || '';
            this.modules = permission.modules || '';

    }
}