import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { ResellerClientTemplateReport } from './resellerClientTemplateReport.interface';
import { ResellerClientTemplateReportModel } from './resellerClientTemplateReport_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';
import { DatePipe } from '@angular/common'

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ResellerTemplateClientReportService implements Resolve<any> {
	url: string = 'reseller/template/clientTempReport';
	routeParams: any;
	defaultParams: any = {
		'from_date': '',
		'to_date': ''
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<ResellerClientTemplateReport[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<ResellerClientTemplateReport>(new ResellerClientTemplateReportModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<ResellerClientTemplateReport>(new ResellerClientTemplateReportModel({}));
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	public isLoader = new BehaviorSubject<boolean>(false);
	loader = this.isLoader.asObservable();
	
	public isLoaderDownload = new BehaviorSubject<boolean>(false);
	loaderDownload = this.isLoaderDownload.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		public datepipe: DatePipe,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ResellerTemplateReportService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on ResellerTemplateReportService');
	}

	changeAllItems(allItems: ResellerClientTemplateReport[]) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: ResellerClientTemplateReport) {
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: ResellerClientTemplateReport) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: ResellerClientTemplateReport) {
		// let allItems = [];
		// this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		// if (allItems && allItems.length > 0) {
		//     for (var i = 0; i < allItems.length; ++i) {
		//         if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
		//     }
		// }
		// this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'from_date': '',
			'to_date': ''
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		this.isLoader.next(true);
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.client_info
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"client_id": usage.client_id,
								"total_count": usage.totalCount,
								"success_count": usage.successCount,
								"failed_count": usage.failed_count,
								"rejectedCount": usage.rejectedCount
							}
							if (clientInfo && clientInfo.length > 0) {
								clientInfo.forEach(client => {
									if (client.id == usage.client_id) {
										dataFormats["clientName"] = client.name;
										dataFormats["clientAvatar"] = '';
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.isLoader.next(false);
						// this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.isLoader.next(false);
						// this.changeTotalItem(data.pagnitation.totalResult);
					}
				} else {
					this.changeAllItems([]);
					this.isLoader.next(false);
					// this.changeTotalItem(data.pagnitation.totalResult);
				}

			},
				error => {console.log('Error ::' + error), this.isLoader.next(false);}
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new ResellerClientTemplateReportModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(data: any) {
		let domain = this.commonService.backendUrl;
		let param = btoa(JSON.stringify(data));
		let url = domain + 'report/template-report-export/' + param;
		return url;
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}


	exportReport(params: any = null) {
		this.isLoaderDownload.next(true);
		this.commonService.storeItem("reseller/template/deliveryTemplateReport", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var success = data.data;
						var dataFormat = [];
						success.forEach(success => {
							var dataFormats = {
								"Phone Number": success.to ? success.to : '--',
								"Sent Status": '--',
								"Sent Date": '--',
								"Delivered Status": '--',
								"Delivered Date": '--',
								"Failed Status": '--',
								"Failed Date": '--',
								"Read Status": '--',
							}
							if (success.delivered) {
								dataFormats["Delivered Date"] = this.datepipe.transform(success.send_at * 1000, 'yyyy-MM-dd hh:mm')
								dataFormats["Delivered Status"] = success.delivered.status
							}
							if (success.read) {
								dataFormats["Read Status"] = success.read.status
							}
							if (success.sent) {
								dataFormats["Sent Date"] = this.datepipe.transform(success.send_at * 1000, 'yyyy-MM-dd hh:mm')
								dataFormats["Sent Status"] = success.sent.status
							}
							if (success.failed) {
								dataFormats["Failed Date"] = this.datepipe.transform(success.send_at * 1000, 'yyyy-MM-dd hh:mm')
								dataFormats["Failed Status"] = success.failed.status
							}
							// this.datepipe.transform(peopleData.created_at * 1000, 'yyyy-MM-dd hh:mm'),
							if (success.response && success.response.length > 0 && dataFormats["Failed Status"] == "failed") {
								var faliedData;
								var isFaliedData = true;
								try {
									faliedData = JSON.parse(success.response);
								} catch (e) {
									var error = [];
									error.push(success.response)
									dataFormats['Failed Reason'] = "unknown error";
									isFaliedData = false;
								}
								if (isFaliedData) {
									if (faliedData.error && !faliedData.error.error_data && faliedData.error.message) {
										dataFormats['Failed Reason'] = faliedData.error.message ? faliedData.error.message : '-';
									} else if (faliedData.error && faliedData.error.error_data && faliedData.error.error_data.details) {
										dataFormats['Failed Reason'] = faliedData.error.error_data.details ? faliedData.error.error_data.details : '-';
									} else if (faliedData.errors && faliedData.errors[0] && faliedData.errors[0].title) {
										dataFormats['Failed Reason'] = faliedData.errors[0].title ? faliedData.errors[0].title : '-';
									} else if (faliedData.message) {
										dataFormats['Failed Reason'] = faliedData.message ? faliedData.message : '-';
									} else {
										dataFormats['Failed Reason'] = "unknown error";
									}
								}
							}
							dataFormat.push(dataFormats);
						});
						this.downloadFile(dataFormat);
					}
				} else {
					this.isLoaderDownload.next(false);
					this.alertService.webErrorShow(data);
				}
			},
				error => { console.log('Error ::' + error); this.isLoaderDownload.next(false); }
			);
	}


	// downloadFile
	downloadFile(temp, title = null) {
		var excelData = [];
		excelData = temp;
		let arrHeader = ["Phone Number", "Sent Status", "Sent Date", "Delivered Status", "Delivered Date", "Failed Status", "Failed Date", "Read Status", "Failed Reason"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		this.isLoaderDownload.next(false);
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'template_failed_reports_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}
	
	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Phone Number", "Sent Status", "Sent Date", "Delivered Status", "Delivered Date", "Failed Status", "Failed Date", "Read Status", "Failed Reason"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}
}