export class QuickMessageModel {
	id: number;
	person_id: number;
	person_type: string;
	title: string;
	text: string;
	asset: string;
	type: string;
	image_asset: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	
	imageAssets?: Array<any>;
	videoAssets?: Array<any>;
	documentAssets?: Array<any>;
	allAssets?: Array<any>;
	
	imageCount: number;
	videoCount: number;
	documentCount: number;

	/**
	 * Constructor
	 *
	 * @param quickMessage
	 */
	constructor(quickMessage) {
		this.id = quickMessage.id || '';
		this.person_id = quickMessage.person_id || '';
		this.person_type = quickMessage.person_type || '';
		this.title = quickMessage.title || '';
		this.text = quickMessage.text || '';
		this.asset = quickMessage.asset || '';
		this.type = quickMessage.type || '1';
		this.image_asset = quickMessage.image_asset || '';
		this.created_by = quickMessage.created_by || '';
		this.updated_by = quickMessage.updated_by || '';
		this.created_at = quickMessage.created_at || '';
		this.updated_at = quickMessage.updated_at || '';
		this.imageCount = quickMessage.imageCount || '';
		this.videoCount = quickMessage.videoCount || '';
		this.documentCount = quickMessage.documentCount || '';
	}
}
