import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
var moment = require('moment-timezone');

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { AssignFormComponent } from './../assignForm/assignForm.component';

import {
	User, UserService,
	AbandonService, Abandon
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'abandon-ticket-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy, AfterViewInit {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	abandonsAll: Abandon[] = [];
	abandons: Abandon[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.abandons);
	displayedColumns = ['media', 'date', 'avatar', 'ticket', 'agent', 'time_elapsed', 'status', 'action'];
	dataLength: number = 0;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public abandonService: AbandonService,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.abandonService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.abandonsAll) { this.subLoader = false; }
			this.abandonsAll = data;
			this.abandons = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.abandons);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
		this.abandonService.unSubscribeFilter();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.abandonService.unSubscribeFilter();
	}

	ngAfterViewInit() {
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.abandonService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.abandonService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	getUnreadCount(abandon: Abandon): number {
		if (abandon && abandon.model && abandon.model.unReadCount && abandon.model.unReadCount > 0) {
			return abandon.model.unReadCount;
		}
		return 0
	}

	/** Add / Update Tag */
	assignAbandon(abandon = null): void {
		this.abandonService.changeItem((abandon) ? abandon : null);
		let dialogRef = this.matDialog.open(AssignFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: abandon,
				ticketClassName: (abandon && abandon.model) ? abandon.model.className : null
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						// this.abandonService.assign(formData.value, abandon.id, 'abandon-assign');
						break;
				}
			});
	}

	getDataDiff(startDate, endDate) {
		var diff = new Date(endDate).valueOf() - new Date(startDate).valueOf();
		var days = Math.floor(diff / (60 * 60 * 24 * 1000));
		var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
		var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
		var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
		if (days && days > 0) {
			var durations = (String)(days) + ' Days | ' + (String)(hours) + ' : ' + (String)(minutes) + ' : ' + (String)(seconds);
		} else {
			var durations = (String)(hours) + ' : ' + (String)(minutes) + ' : ' + (String)(seconds);
		}
		return { day: days, hour: hours, minute: minutes, second: seconds, duration: durations };
	}

	getTime(abandon): number {
		var today = new Date();
		let todayKuwait = new Date(moment(today).tz("Asia/Kuwait").format('YYYY-MM-DD HH:mm:ss'));
		var assignedDate = new Date(abandon.assigned_at);
		var diff = Math.floor(todayKuwait.valueOf() - assignedDate.valueOf()) / (1000 * 60);
		return Number(Math.abs(diff));
	}

}