import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Socket } from 'ngx-socket-io';

import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { EventService } from 'src/app/@tji/_dbShare/alert/event/event.service';
import { Socket as SocketCon } from './socket.interface';
import { SocketModel } from './socket_model.model';

declare function notifyMe(title, message): any;

@Injectable({
	providedIn: 'root',
})
export class SocketService {

	authUser: User = this.userService.authUser();
	authUserId: number;
	needReconnet: boolean = false;

	private librariesSource = new BehaviorSubject<SocketCon[]>([]);
	libraries = this.librariesSource.asObservable();

	private socketChannelSource = new BehaviorSubject<string>(null);
	socketChannel = this.socketChannelSource.asObservable();

	private itemSource = new BehaviorSubject<any>(null);
	item = this.itemSource.asObservable();

	// private socialAccountSource = new BehaviorSubject<any>(null);
	// socialAccount = this.socialAccountSource.asObservable();

	constructor(private socket: Socket,
		private userService: UserService,
		private commonService: CommonService,
		private eventService: EventService) {
		this.authUserId = (this.authUser && this.authUser.id) ? this.authUser.id : null;
		this.onConnect();
		this.onDisconnect();
	}

	changeLibraries(libraries: SocketCon[]) {
		this.librariesSource.next(libraries);
	}

	concatlibrary(allItems: SocketCon[]) {
		var oldLists: SocketCon[] = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.arrayMergeByEventName(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: SocketCon) {
		let oldLists: SocketCon[] = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.channelName && item.eventName) {
			oldLists = oldLists.filter(x => {
				return x.eventName !== item.eventName;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: SocketCon) {
		let oldLists: SocketCon[] = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.channelName && item.eventName) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].eventName === item.eventName) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists = oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeSocketChannel(channel: string) {
		this.socketChannelSource.next(channel);
	}

	changeItem(item: any) {
		if (environment.enableOctopusSocket) {
			this.itemSource.next(item);
			this.eventService.changeEventData(item);
		}
	}

	isEnabled() {
		return (environment.enableOctopusSocket) ? true : false;
	}

	// emit event
	subscribeAuthChannel(channelName: string = 'authme', eventName: string = "authme", uniqueData = null) {
		let socketData: SocketCon = new SocketModel({});
		if (!environment.enableOctopusSocket) { return null; }
		let tjiToken: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;
		if (!tjiToken) {
			socketData.channelName = channelName;
			socketData.eventName = eventName;
			socketData.uniqueData = uniqueData;
			setTimeout(() => {
				this.subscribeAuthChannel(channelName, eventName, uniqueData);
			}, 2000);
		}
		else {
			let data: any = {
				'uniqueData': uniqueData,
				'token': tjiToken
			};
			let subscribedChannel: any = this.socket.emit(eventName, tjiToken);
			if (channelName) { this.onChannelEvent(channelName); }
			let channel: string = channelName + '.' + this.authUserId;
			socketData.channelName = channel;
			socketData.eventName = eventName;
			socketData.uniqueData = tjiToken;
			this.replacelibrary(socketData);
			return channel;
		}
	}

	// emit event
	subscribeChannelRaw(channelName: string, eventName: string, uniqueData = null) {
		let socketData: SocketCon = new SocketModel({});
		if (!environment.enableOctopusSocket) { return null; }
		let tjiToken: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;
		if (!uniqueData && tjiToken) { uniqueData = tjiToken; }
		let data: any = {
			'uniqueData': uniqueData,
			'token': (tjiToken) ? tjiToken : null,
		};
		let subscribedChannel: any = this.socket.emit(eventName, uniqueData);
		this.changeSocketChannel(channelName);
		if (channelName) { this.onChannelEventRaw(channelName); }
		socketData.channelName = channelName;
		socketData.eventName = eventName;
		socketData.uniqueData = uniqueData;
		this.replacelibrary(socketData);
		return channelName;
	}

	// emit event
	subscribeChannel(channelName: string, eventName: string, uniqueData = null) {
		let socketData: SocketCon = new SocketModel({});
		if (!environment.enableOctopusSocket) { return null; }
		let tjiToken: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;
		let data: any = {
			'uniqueData': uniqueData,
			'token': tjiToken
		};
		let channel: string = channelName + '.' + this.authUserId;
		let subscribedChannel: any = this.socket.emit(channelName, (tjiToken) ? tjiToken : uniqueData);
		this.changeSocketChannel(channel);
		if (channelName) { this.onChannelEvent(channelName); }
		socketData.channelName = channelName;
		socketData.eventName = eventName;
		socketData.uniqueData = uniqueData;
		this.replacelibrary(socketData);
		return subscribedChannel;
	}

	// listen event
	onChannelEvent(channelName: string) {
		if (!environment.enableOctopusSocket) { return null; }
		let channel: string = channelName + '.' + this.authUserId;
		this.socket.fromEvent(channel).subscribe(data => {
			this.changeItem(data);
		});
		return;
	}

	onChannelEventRaw(channelName: string) {
		if (!environment.enableOctopusSocket) { return null; }
		let channel: string = channelName;
		this.socket.fromEvent(channel).subscribe(data => {
			this.changeItem(data);
		});
		return;
	}

	onConnect() {
		if (!environment.enableOctopusSocket) { return null; }
		let oldLists: SocketCon[] = [];
		this.libraries.subscribe(data => { oldLists = data; });
		this.socket.on('connect', function () {
			console.log('Socket Connected');
			if (oldLists && oldLists.length > 0) {
				oldLists.forEach(connection => {
					if (connection.uniqueData) {
						let channelName = connection.channelName;
						let eventName = connection.eventName;
						let tjiToken: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;
						let uniqueData = tjiToken;
						if (!uniqueData && tjiToken) { uniqueData = tjiToken; }
						let data: any = {
							'uniqueData': uniqueData,
							'token': (tjiToken) ? tjiToken : null,
						};
						this.emit(eventName, uniqueData);
					}

				});
				this.needReconnet = false;
			}
		});
		return;
	}

	onDisconnect() {
		if (!environment.enableOctopusSocket) { return null; }
		this.socket.on('disconnected', function () {
			console.log('disconnected Socket');
			this.needReconnet = true;
		});
		return;
	}
	onDisconnectSocket() {
		if (!environment.enableOctopusSocket) { return null; }
		this.socket.on('disconnected', function () {
			console.log('disconnected Socket');
			// this.needReconnet = true;
		});
		return;
	}
	showAlert(title: string, message: string, type: string = null, icon: string = null) {
		if (environment.enableOctopusSocket) {
			this.eventService.showAlert(title, message, type, icon);
		}
	}

	showToast(eventData: any) {
		if (environment.enableOctopusSocket) {
			this.eventService.showToast(eventData);
		}
	}

	showAlertByData(eventData: any) {
		if (environment.enableOctopusSocket) {
			this.eventService.showAlertByData(eventData);
		}
	}

	arrayMergeByEventName($oldArray, $updateArray) {
		if ($oldArray && $oldArray.length > 0) {
			for (var i = 0; i < $updateArray.length; ++i) {
				var item = $updateArray[i];
				for (var j = 0; j < $oldArray.length; ++j) {
					var oldItem = $oldArray[j];
					if (oldItem.eventName == item.eventName) {
						$oldArray[j] = item;
						break;
					}
				}
			}
			$oldArray = $oldArray.concat($updateArray.filter(x => $oldArray.every(y => (y.eventName !== x.eventName))));
		} else {
			$oldArray = $updateArray;
		}
		return $oldArray;
	}

}
