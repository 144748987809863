import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  User, UserService, AicollectionService, FieldConfig, ChatService, EventService, AlertService
} from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { DynamicFormComponent } from "../../../library/dynamic-form/dynamic-form/dynamic-form.component";
import { GeneratePaymentComponent } from '../generate-payment/generate-payment.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'current-payment',
  templateUrl: './current-payment.component.html',
  styleUrls: ['./current-payment.component.scss'],
  providers: [OrderByPipe]
})
export class CurrentPaymentComponent implements OnInit {
  @ViewChild(DynamicFormComponent) aiCollectionForm: DynamicFormComponent;
  dynamicFormData: FieldConfig[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  isFormLoaded: boolean = false;
  isFormListLoaded: boolean = false;
  ticket: any = null;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
  orderCharges: any;
  paymentList: any;
  statusData: any;
  paymentData: any;
  eventData: any;
  isPaymentLoaded: boolean = false;
  language: string = 'en';

  constructor(
    public aicollectionService: AicollectionService,
    public eventService: EventService,
    private cdRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    public chatService: ChatService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private orderByPipe: OrderByPipe,) {

    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });

    if (this.aicollectionService.isAuthUser == true) {
      this.isFormListLoaded = true;
      this.isFormLoaded = false;
      this.orderCharges = 0;
    }

    this.aicollectionService.isPaymentLoader.pipe(untilDestroyed(this)).subscribe(data => {
      this.isPaymentLoaded = data;
      if (!this.isPaymentLoaded) {
        delete this.orderCharges;
      }
    });

    this.aicollectionService.isAuthentication.pipe(untilDestroyed(this)).subscribe(data => {
      if (data['data'] && !data['data'].success) {
        if (data['data'] && !data['data']["result"]) {
          this.dynamicFormData = data['data']["requiredfileds"];
          this.paymentData = data['data'];
          if (this.dynamicFormData) {
            this.dynamicFormData.forEach(fileds => {
              if (fileds.type == 'text') {
                fileds.type = 'input'
              }
              if (fileds.validations) {
                fileds.validations.forEach(validation => {
                  if (validation.validator) {
                    validation.validator = Validators.required;
                  }
                });
              }
            });
            var submit = {
              type: 'button',
              label: 'Authorization',
            }
            this.dynamicFormData.push(submit);
            this.isFormLoaded = true;
            this.isFormListLoaded = false;
          }
        } else if (data['data'] && data['data']["result"] == true) {
          this.isFormListLoaded = true;
          this.isFormLoaded = false;
          delete this.orderCharges;
        }
      } else {
        this.orderCharges = 0;
      }
    });

    this.aicollectionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentList = this.orderByPipe.transform(data, '-_id');
    });

    this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
      this.ticket = data;
    });

    this.aicollectionService.isStatusPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['payment_status']) {
        if (data['transaction_response']) {
          data['transaction_response'] = JSON.parse(data['transaction_response']);
        }
        this.statusData = data;
      } else {
        delete this.statusData;
      }
    });

    this.aicollectionService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.aicollectionService.isPaymentGenerate.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == true) {
        this.orderCharges = 0;
      }
    });

  }

  ngOnInit(): void {
    var number = /^[0-9]*\.?[0-9]*$/;
    this.isSubmit = false;
    // this.doLoader();
  }
  /** On destroy */
  ngOnDestroy(): void {
    delete this.orderCharges;
    delete this.statusData;
    this.aicollectionService.isStatusPaymentData.next([]);
    this.aicollectionService.isAuthentication;
    this.aicollectionService.isAuthentication.next([]);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    delete this.orderCharges;
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 2000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  submit(value: any) {
    if (value) {
      this.aicollectionService.verifyAuth(value);
    }
  }

  isSubmit: boolean = false;

  numberOnly(event: any): boolean {
    this.isSubmit = false;
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      if (this.orderCharges) {
        var len = this.orderCharges.length;
        if (this.orderCharges[0] == 0 && this.orderCharges[1] == 0) {
          this.alertService.webShow('Danger', 'this value not allowed');
          delete this.orderCharges;
        }
        var index = this.orderCharges.indexOf('.');
        if (index > 0 && charCode == 46) {
          return false;
        }
        if (index > 0) {
          var CharAfterdot = (len + 1) - index;
          if (CharAfterdot > 4) {
            return false;
          }
        }
      }
    }
    this.isSubmit = false;
    return true;
  }

  onChange(event: any = null) {
    this.language = event.value;
  }

  generatePayment() {
    var user: any = JSON.parse(localStorage.getItem('tji_user'));
    var formData = {
      "people_id": this.ticket.people_id,
      "client_site_id": this.ticket.clientSiteId,
      "name": this.ticket.peopleName,
      "phoneNumber": parseInt(this.ticket.recipientId),
      "email": user.email ? user.email : '',
      "ticket_id": this.ticket.id,
      "amount": this.orderCharges, //parseInt(this.orderCharges),
      "language": this.language
    }

    if (this.orderCharges && this.orderCharges.length > 0 && this.orderCharges != 0) {
      this.aicollectionService.isPaymentLoader.next(true);
      this.aicollectionService.generatePayment(formData);
      this.isSubmit = false
    } else {
      this.isSubmit = true;
    }
  }
  sendPaymentRequest(payment: any = null) {
    if (payment && payment._id) {
      this.aicollectionService.isSendPaymentData.next(payment);
    }
  }

  /** create New Payment */
  createNewPayment() {
    this.aicollectionService.checkAuth();
    var clientSettings = JSON.parse(localStorage.getItem('client_settings'));
    let dialogRef = this.matDialog.open(GeneratePaymentComponent, {
      width: "600px",
      data: {
        action: 'new',
        item: 'payment',
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        if (this.ticket && this.ticket.id) {
          formData.value["people_id"] = this.ticket.people_id;
          formData.value["client_site_id"] = this.ticket.clientSiteId;
          formData.value["ticket_id"] = this.ticket.id;
        }
        switch (actionType) {
          case 'new':
            this.aicollectionService.isPaymentLoader.next(true);
            formData.value.phoneNumber = parseInt(formData.value.phoneNumber);
            this.aicollectionService.generatePayment(formData.value);
            break;
        }
      });
  }

  viewStatus(payment: any = null) {
    if (payment && payment._id) {
      var data = {
        "trackId": payment._id
      }
      this.aicollectionService.getStatusPayment(data);
    }
  }
  closeStatus() {
    this.aicollectionService.isStatusPaymentData.next(null);
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'paymentCreate':
          this.aicollectionService.concatItem(this.eventData.data);
          break;
        case 'paymentUpdate':
          this.aicollectionService.changeAllItemsByItem(this.eventData.data);
          break;
        default:
          break;
      }
    }
  }

}
