import { ImageItem, VideoItem, DocumentItem, Lists , ListItem, ButtonItem, Reply, TemplateButtonItem, section, sectionItem } from './tools.interface';

export class ToolModel {
    type:string;
    icon: string;
    value: string;
    category: string;
    index: number;
    color: string;
    intractiveId: any;

    /**
     * Constructor
     *
     * @param toolData
     */
    constructor(toolData) {
            this.type = toolData.type || 'text';
            this.icon = toolData.icon || '';
            this.value = toolData.value || '';
            this.value = toolData.value || '';
            this.category = toolData.category || '';
            this.index = toolData.index || 0;
            this.color = toolData.color || null;
            this.intractiveId = toolData.intractiveId || '';
    }
}
export class TextModel {
    type:string;
    text: string;

    /**
     * Constructor
     *
     * @param textData
     */
    constructor(textData) {
            this.type = textData.type || 'text';
            this.text = textData.text || '';
    }
}

export class ImagetModel {
    type:string;
    image: ImageItem;

    /**
     * Constructor
     *
     * @param imagetData
     */
    constructor(imagetData) {
            this.type = imagetData.type || 'image';
            this.image = imagetData.image || new ImageItemModel({});
    }
}

export class ImageItemModel {
    link:string;
    provider: any;

    /**
     * Constructor
     *
     * @param imageItemData
     */
    constructor(imageItemData) {
            this.link = imageItemData.link || '';
            this.provider = imageItemData.provider || '';
    }
}

export class VideoModel {
    type:string;
    video: VideoItem;

    /**
     * Constructor
     *
     * @param videoData
     */
    constructor(videoData) {
            this.type = videoData.type || 'video';
            this.video = videoData.video || new VideoItemModel({});
    }
}

export class VideoItemModel {
    link:string;
    provider: any;

    /**
     * Constructor
     *
     * @param videoItemData
     */
    constructor(videoItemData) {
            this.link = videoItemData.link || '';
            this.provider = videoItemData.provider || '';
    }
}

export class DocumentModel {
    type:string;
    document: DocumentItem;

    /**
     * Constructor
     *
     * @param documentData
     */
    constructor(documentData) {
            this.type = documentData.type || 'document';
            this.document = documentData.document || new DocumentItemModel({});
    }
}

export class DocumentItemModel {
    link:string;
    provider: any;
    filename: string;

    /**
     * Constructor
     *
     * @param documentItemData
     */
    constructor(documentItemData) {
            this.link = documentItemData.link || '';
            this.provider = documentItemData.provider || '';
            this.filename = documentItemData.filename || 'document';
    }
}

export class TemplateButtonModel {
    type:string;
    sub_type:string;
    index:number;
    parameters:Array<TemplateButtonItem>;
    intractiveId: any;
    value: string;

    /**
     * Constructor
     *
     * @param buttonData
     */
    constructor(templateButtonData) {
            this.type = templateButtonData.type || 'button';
            this.sub_type = templateButtonData.sub_type || 'quick_reply';
            this.index = templateButtonData.index || 0;
            this.intractiveId = templateButtonData.intractiveId || '';
            this.parameters = templateButtonData.parameters || [new TemplateButtonItemModel({})];
            this.value = templateButtonData.value || '';
    }
}

export class TemplateDynamicButtonModel {
    type:string;
    sub_type:string;
    index:number;
    parameters:Array<TemplateButtonItem>;
    intractiveId: any;
    value: string;

    /**
     * Constructor
     *
     * @param buttonData
     */
    constructor(templateButtonData) {
            this.type = templateButtonData.type || 'dynamic button';
            this.sub_type = templateButtonData.sub_type || 'quick_reply';
            this.index = templateButtonData.index || 0;
            this.intractiveId = templateButtonData.intractiveId || '';
            this.parameters = templateButtonData.parameters || [new TemplateButtonItemModel({})];
            this.value = templateButtonData.value || '';
    }
}

export class TemplateButtonItemModel {
    type:string;
    text:string;
    intractiveId: any;
    value: string;

    /**
     * Constructor
     *
     * @param buttonData
     */
    constructor(templateButtonData) {
            this.type = templateButtonData.type || 'text';
            this.text = templateButtonData.text || '';
            this.intractiveId = templateButtonData.intractiveId || '';
            this.value = templateButtonData.value || '';
    }
}

export class ButtonsModel {
    type:string;
    buttons:Array<ButtonItem>;

    /**
     * Constructor
     *
     * @param buttonsData
     */
    constructor(buttonsData) {
            this.type = buttonsData.type || 'buttons';
            this.buttons = buttonsData.buttons || [new ButtonItemModel({})];
    }
}

export class ButtonItemModel {
    type:string;
    reply:Reply;

    /**
     * Constructor
     *
     * @param buttonsData
     */
    constructor(buttonsData) {
            this.type = buttonsData.type || 'reply';
            this.reply = buttonsData.reply || new ReplyModel({});
    }
}

export class ReplyModel {
    id:string;
    title:string;

    /**
     * Constructor
     *
     * @param replyData
     */
    constructor(replyData) {
        this.id = replyData.id || '';
        this.title = replyData.title || '';
    }
}

export class SectionsModel {
    button:string;
    sections:Array<Lists>;

    /**
     * Constructor
     *
     * @param sectionsData
     */
    constructor(sectionsData) {
        this.button = sectionsData.button || '';
        this.sections = sectionsData.sections || [new ListsModel({})];
    }
}

export class ListsModel {
    title:string;
    rows:Array<ListItem>;

    /**
     * Constructor
     *
     * @param listsData
     */
    constructor(listsData) {
        this.title = listsData.title || '';
        this.rows = listsData.rows || [new ListItemModel({})];
    }
}

export class ListItemModel {
    id:string;
    title:string;
    description:string;

    /**
     * Constructor
     *
     * @param listItemData
     */
    constructor(listItemData) {
        this.id = listItemData.id || '';
        this.title = listItemData.title || '';
        this.description = listItemData.description || '';
    }
}

export class ProductSingleItemModel {
    catalog_id:string;
    product_retailer_id:string;

    /**
     * Constructor
     *
     * @param listItemData
     */
    constructor(listItemData) {
        this.catalog_id = listItemData.catalog_id || '';
        this.product_retailer_id = listItemData.id || '';
    }
}

export class ProductMultipleItemModel {
    catalog_id:string;
    sections:Array<section>;

    /**
     * Constructor
     *
     * @param listSectionData
     */
    constructor(listSectionData) {
        this.catalog_id = listSectionData.catalog_id || '';
        this.sections = listSectionData.sections || [new ListSectionModel({})];
    }
}

export class ListSectionModel {
    title:string;
    product_items:Array<any>;
    /**
     * Constructor
     *
     * @param listItemData
     */
    constructor(listItemData) {
        this.title = listItemData.title || '';
        this.product_items = listItemData.product_items || [];
        
    }
}


export class ListProductSectionModel {
    title:string;
    product_items:Array<any>;
    /**
     * Constructor
     *
     * @param listItemData
     */
    constructor(listItemData) {
        this.title = listItemData.title || '';
        this.product_items = listItemData.product_items || [];
        
    }
}

export class SectionListItem {
    product_retailer_id: string;
    /**
     * Constructor
     *
     * @param SectionListItem
     */
    constructor(SectionListItem) {
        this.product_retailer_id = SectionListItem.product_retailer_id || '';
    }
}

export class LocationModel {
    name:string;

    /**
     * Constructor
     *
     * @param location
     */
    constructor(location) {
            this.name = 'send_location';
    }
}