import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';
import {
  User, UserService,
  LeadsHistoryService, LeadsHistory,
  ChatService, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'track-ticket',
  templateUrl: './track-ticket.component.html',
  styleUrls: ['./track-ticket.component.scss']
})
export class TrackTicketComponent implements OnInit {
  ticketsStatusData: any;
  loader: boolean = true;
  user: User;
  @Output() onClose = new EventEmitter();

  constructor(
    public leadsHistoryService: LeadsHistoryService,
    public userService: UserService,
    public matDialog: MatDialog) {
    this.userService.user.subscribe(data => this.user = data);
    this.leadsHistoryService.ticketStatus.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.conversation && data.ticketEvent && data.ticketInfo) {
        this.ticketsStatusData = data;
      }
    });
  }

  ngOnInit(): void {
    this.doLoader();
    // setTimeout(() => {
    //   this.getTicketTrack();
    // }, 1000);
  }
  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }
  /** On destroy */
  ngOnDestroy(): void {
    this.leadsHistoryService.unSubscribe();
  }
  getTicketTrack() {
    // this.leadsHistoryService.ticketStatus.pipe(untilDestroyed(this)).subscribe(data => {
    //   if (data) {
    //     this.ticketsStatusData = data;
    //     console.log('------------------------',data)
    //   }
    // });
  }
}
