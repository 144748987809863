import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { ItemComponent } from './item/item.component';
import { ClosedListComponent } from './closedList/closedList.component';
import { ActivityComponent } from './activity/activity.component';
import { AbandonComponent } from './abandon/abandon.component';

import { InstagramTicketService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: InstagramTicketService },
	},
	{
		path: 'open',
		component: MainComponent,
		resolve: { data: InstagramTicketService },
	},
	{
		path: 'activity-view',
		component: ActivityComponent,
	},
	{
		path: 'chat-view/:id',
		component: ItemComponent,
		resolve: { data: InstagramTicketService },
	},
	{
		path: 'chat/:id',
		component: ItemComponent,
		resolve: { data: InstagramTicketService },
	},
	{
		path: 'abandon',
		component: AbandonComponent,
		resolve: { data: InstagramTicketService },
	},
	{
		path: ':param',
		component: ClosedListComponent,
		resolve: { data: InstagramTicketService },
	},
	{ path: '**', redirectTo: '/app/instagram-ticket' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class InstagramTicketRouteModule { }
