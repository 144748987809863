import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { CommentHistoryComponent } from './../commentHistory/commentHistory.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';

import {
	User, UserService,
	CommentService, Comment, CommentModel,
	ChildCommentService,
	EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'comment-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	commentsAll: Comment[] = [];
	comments: Comment[] = [];
	param: any;
	commentResponse: any;
	dataSource = new MatTableDataSource(this.comments);
	displayedColumns = ['code', 'avatar', 'name', 'people_avatar', 'people_name', 'description', 'status', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	eventData: any;

	constructor(public userService: UserService,
		public commentService: CommentService,
		public childCommentService: ChildCommentService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		public eventService: EventService,
		private orderByPipe: OrderByPipe) {
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.userService.user.subscribe(data => this.authUser = data);
		this.commentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.commentsAll) { this.subLoader = false; }
			// if (data && data['additional'] && data['additional'].PeopleInfo.length > 0) {
			// 	data['data'].forEach(comments => {
			// 		data['additional'].PeopleInfo.forEach(info => {
			// 			if (comments && comments.Resource_info && comments.Resource_info.people_id) {
			// 				if (comments.Resource_info.people_id == info.id) {
			// 					comments.Resource_info['people_info'] = info;
			// 				}
			// 			}
			// 		});
			// 	});
			// 	this.commentsAll = data['data'];
			// 	this.comments = this.orderByPipe.transform(data['data'], '-id');
			// 	this.dataSource = new MatTableDataSource(this.comments);
			// 	this.dataSource.sort = this.sort;
			// }else{
			this.commentsAll = data;
			this.comments = this.orderByPipe.transform(data, '-_id');
			this.dataSource = new MatTableDataSource(this.comments);
			this.dataSource.sort = this.sort;
			// }
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.commentService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.commentService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.commentService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Tag */
	showCommentHistory(comment): void {
		this.commentService.changeItem(comment);
		let dialogRef = this.matDialog.open(CommentHistoryComponent, {
			width: "600px",
			data: {
				action: 'show',
				item: comment,
				ticketClassName: (comment && comment.siteName) ? comment.siteName : null,
			}
		});
		dialogRef.afterClosed().subscribe(response => { });
	}

	/** Close Tickets */
	doReply(comment: Comment = null): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: comment.resourceInfo,
				comment: comment,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.replayComment('comments/addcomment', result.formValue);
			}
		});
	}

	/** Delete Social Site */
	deleteCommends(comment: Comment): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var id = {
					commentId: comment._id
				}
				this.commentService.destroy(id);
			}
		});
	}

	checkEventData() {
		if (this.eventData) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'addcomment':
					this.commentService.concatItem(this.eventData.data);
					break;
				case 'removecomment':
					this.commentService.spliceCommentItem(this.eventData.data);
					break;
				case 'replycomment':
					this.commentService.changeAllItemsByItem(this.eventData.data);
					break;
				default:
					break;
			}
		}
	}

}
