import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { User, UserService, UserProfileService, CommonService,
	TemplateMessageService, TemplateMessage
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'profile-form-view',
	templateUrl: './form-view.component.html',
	styleUrls: ['./form-view.component.scss']
})

export class FormViewComponent implements OnInit, OnDestroy {

	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
	@Output() closeProfileForm = new EventEmitter();

	loader: boolean = true;
	user: User;
	userForm: UntypedFormGroup;
	genderTypes = [
		{ name: 'male', id: 1 },
		{ name: 'female', id: 2 }
	];
	countryList = [
		{ name: 'Afghanistan', id: 1 }, { name: 'Albania', id: 2 }, { name: 'Algeria', id: 3 }, { name: 'Argentina', id: 4 }, { name: 'Armenia', id: 5 },
		{ name: 'Australia', id: 6 }, { name: 'Austria', id: 7 }, { name: 'Azerbaijan', id: 8 }, { name: 'Bahamas', id: 9 }, { name: 'Bangladesh', id: 10 },
		{ name: 'Barbados', id: 11 }, { name: 'Belgium', id: 12 }, { name: 'Belize', id: 13 }, { name: 'Bhutan', id: 14 }, { name: 'Brazil', id: 15 },
		{ name: 'Brunei', id: 16 }, { name: 'Burkina Faso', id: 17 }, { name: 'Cambodia', id: 18 }, { name: 'Cameroon', id: 19 }, { name: 'Canada', id: 20 },
		{ name: 'China', id: 21 }, { name: 'Colombia', id: 22 }, { name: 'Comoros', id: 23 }, { name: 'Costa Rica', id: 24 }, { name: 'Cuba', id: 25 },
		{ name: 'Denmark', id: 26 }, { name: 'Dominica', id: 27 }, { name: 'Egypt', id: 28 }, { name: 'Estonia', id: 29 }, { name: 'Ethiopia', id: 30 },
		{ name: 'Finland', id: 31 }, { name: 'France', id: 32 }, { name: 'Georgia', id: 33 }, { name: 'Germany', id: 34 }, { name: 'Greece', id: 35 },
		{ name: 'Guyana', id: 36 }, { name: 'Hungary', id: 37 }, { name: 'Iceland', id: 38 }, { name: 'India', id: 39 }, { name: 'Indonesia', id: 40 },
		{ name: 'Iran', id: 41 }, { name: 'Iraq', id: 42 }, { name: 'Ireland', id: 43 }, { name: 'Israel', id: 44 }, { name: 'Italy', id: 45 },
		{ name: 'Japan', id: 46 }, { name: 'Jordan', id: 47 }, { name: 'Kenya', id: 48 }, { name: 'Kiribati', id: 49 }, { name: 'Kuwait', id: 50 },
		{ name: 'Laos', id: 51 }, { name: 'Lebanon', id: 52 }, { name: 'Liberia', id: 53 }, { name: 'Madagascar', id: 54 }, { name: 'Malaysia', id: 55 },
		{ name: 'Maldives', id: 56 }, { name: 'Mexico', id: 57 }, { name: 'Micronesia', id: 58 }, { name: 'Monaco', id: 59 }, { name: 'Mongolia', id: 60 },
		{ name: 'Morocco', id: 61 }, { name: 'Myanmar', id: 62 }, { name: 'Namibia', id: 63 }, { name: 'Nepal', id: 64 }, { name: 'Netherlands', id: 65 },
		{ name: 'New Zealand', id: 66 }, { name: 'North Korea', id: 67 }, { name: 'Norway', id: 68 }, { name: 'Oman', id: 69 }, { name: 'Pakistan', id: 70 },
		{ name: 'Panama', id: 71 }, { name: 'Philippines', id: 72 }, { name: 'Poland', id: 73 }, { name: 'Portugal', id: 74 }, { name: 'Qatar', id: 75 },
		{ name: 'Romania', id: 76 }, { name: 'Russia', id: 77 }, { name: 'Saudi Arabia', id: 78 }, { name: 'Serbia', id: 79 }, { name: 'Singapore', id: 80 },
		{ name: 'Solomon Islands', id: 81 }, { name: 'South Africa', id: 82 }, { name: 'South Korea', id: 83 }, { name: 'Spain', id: 84 }, { name: 'Sri Lanka', id: 85 },
		{ name: 'Switzerland', id: 86 }, { name: 'Thailand', id: 87 }, { name: 'Tonga', id: 88 }, { name: 'Turkey', id: 89 }, { name: 'Uganda', id: 90 },
		{ name: 'United Kingdom', id: 91 }, { name: 'United States of America', id: 92 }, { name: 'Yemen', id: 93 }, { name: 'Zambia', id: 94 }, { name: 'Zimbabwe', id: 95 },
	];

	dateChange = false;

	base_image: string = this.commonService.logo_image;

	constructor(public userService: UserService,
		public userProfileService: UserProfileService,
		public commonService: CommonService,
		private router: Router,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => {
			this.user = data;
			this.dateChange = false;
			this.userForm = this.createForm();
		});
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {

	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.user?.person?.name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
			email: [this.user?.email|| null, [Validators.required, Validators.email, Validators.maxLength(30)]],
			mobile: [this.user?.person?.person_details?.mobile || null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(15)]],
			company_name: [this.user?.person?.person_details?.company_name || null, [Validators.minLength(4), Validators.maxLength(30)]],

			business_brand_name: [this.user?.person?.person_details?.business_brand_name || null, [Validators.minLength(4), Validators.maxLength(30)]],
			business_category: [this.user?.person?.person_details?.business_category || null, [Validators.minLength(4), Validators.maxLength(30)]],
			address: [this.user?.person?.person_details?.address || null, [Validators.minLength(4), Validators.maxLength(100)]],
			country: [this.user?.person?.person_details?.country || null, [Validators.required]],

			gender: [this.user?.person?.person_details?.gender || null],
			date_of_birth: [new Date(this.user?.person?.person_details?.date_of_birth) || null],
			blood_group: [this.user?.person?.person_details?.blood_group || null, [Validators.minLength(1), Validators.maxLength(10)]],

			person_id: [this.user?.person_id || null],
			person_type: [this.user?.person_type || null],
		});
	}


	userUpdate() {
		this.dateChange = false;
		console.log(this.user);
		console.log(this.userForm.value);
		if (this.userForm.value.date_of_birth != '' && this.userForm.value.date_of_birth != null) {
			var selecDate = this.userForm.value.date_of_birth.toLocaleString();
			var splitData = selecDate.split(",");
			var seperateDate = splitData[0].split("/");
			var originalDate = seperateDate[2] + '-' + seperateDate[0] + '-' + seperateDate[1];

			originalDate = originalDate.replace("undefined-", "");
			originalDate = originalDate.replace("-undefined", "");
			this.dateChange = true;
		}

		this.userForm.value.date_of_birth = (this.dateChange) ? originalDate : this.userForm.value.date_of_birth;
		this.userForm.value['id'] = this.user.id;
		this.userProfileService.updateProfile(this.userForm.value);
		this.dateChange = false;
	}

	dobChange() {
		this.dateChange = true;
	}

	checkGender(gender, user: User): boolean {
		this.userProfileService.changeItem(user);
		let genderId = (gender && gender.id) ? gender.id : null;
		let genderName = (gender && gender.name) ? gender.name : null;
		let userGenderValue = null;
		if (user && user.person && user.person.person_details && user.person.person_details.gender) {
			userGenderValue = user.person.person_details.gender;
			if (userGenderValue && (userGenderValue === genderId || userGenderValue === genderName)) {
				return true;
			}
		}
		return false;
	}

	checkCountry(country, user: User): boolean {

		this.userProfileService.changeItem(user);
		let countryId = (country && country.id) ? country.id : null;
		let countryName = (country && country.name) ? country.name : null;
		let userCountryValue = null;
		if (user && user.person && user.person.person_details && user.person.person_details.country) {
			userCountryValue = user.person.person_details.country;
			if (userCountryValue && (userCountryValue === countryName)) {
				return true;
			}
		}
		return false;

	}

}
