import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	LabelService, Label, LabelModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'edit-color-form',
	templateUrl: './editColorForm.component.html',
	styleUrls: ['./editColorForm.component.scss']
})

export class EditColorFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Change Color';
	color: string = '#dddddd';
	textcolor: string = '#333333';
	isDefault: boolean = false;
	action: string;
	item: any;
	editColorForm: UntypedFormGroup;

	constructor(public matDialogRef: MatDialogRef<EditColorFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.editColorForm = this.createForm();
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.item = data.item;
		this.dialogTitle = 'Change Color';
		this.color = data.item.color;
		this.textcolor = data.item.textcolor;
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			color: [this.item.color || this.item.clientSiteColor || this.color, [Validators.required]],
			textcolor: [this.item.textcolor || this.item.clientSiteTextColor || this.textcolor, [Validators.required]],
		});
	}

	onChangeColor($color) {
		this.editColorForm.value.color = $color;
	}

	onChangeTextColor($color) {
		this.editColorForm.value.textcolor = $color;
	}

}
