// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	hmr: true,

	// // ******************//
	// // For Local Server
	// // ******************//
	// domain: 'http://localhost:8000',
    // domainSecured: 'http://localhost:8000',
    // domainSecuredOne: 'https://oct.almulla-sms.com',
	// host: 'http://localhost:4200',
	// defaultServer: 'stage',

	// enableOctopusSocket: true,
	// socketUrl: 'https://socket.ai-octopus.com',
	// socketTransports: ['websocket', 'polling', 'flashsocket'],

	// enableFirebase: false,
	// firebase: {
	// 	apiKey: "AIzaSyA5vf1_M16_YNI-Ue8XxvAVu_URHnR5FLg",
	// 	authDomain: "octopus-da9cc.firebaseapp.com",
	// 	databaseURL: "https://octopus-da9cc-default-rtdb.firebaseio.com",
	// 	projectId: "octopus-da9cc",
	// 	storageBucket: "octopus-da9cc.appspot.com",
	// 	messagingSenderId: "63856949865",
	// 	appId: "1:63856949865:web:cd8eff21e2e305199680f8"
	// },
	// languages: [
	// 	{ 'name': 'English', 'code': 'en', 'icon': 'fa-en' },
	// 	{ 'name': 'Arabic', 'code': 'ar', 'icon': 'fa-ar' },
	// ],

	//******************//
	// For Live Server [NAPP] 
	//******************//
	domain: 'http://napp-ac.ai-octopus.com',
    domainSecured: 'https://napp-ac.ai-octopus.com',
    domainSecuredOne: 'https://napp.ai-octopus.com',
    domainDevelopment: 'https://oct.almulla-sms.com',
	host: 'https://ai-octopus.com',
	defaultServer: 'napp',

	enableOctopusSocket: true,
	socketUrl: 'https://socket.ai-octopus.com',
	socketTransports: ['websocket', 'polling', 'flashsocket'],

	enableFirebase: false,
	firebase: {
		apiKey: "AIzaSyA5vf1_M16_YNI-Ue8XxvAVu_URHnR5FLg",
		authDomain: "octopus-da9cc.firebaseapp.com",
		databaseURL: "https://octopus-da9cc-default-rtdb.firebaseio.com",
		projectId: "octopus-da9cc",
		storageBucket: "octopus-da9cc.appspot.com",
		messagingSenderId: "63856949865",
		appId: "1:63856949865:web:cd8eff21e2e305199680f8"
	},
	languages: [
		{ 'name': 'English', 'code': 'en', 'icon': 'fa-en' },
		{ 'name': 'عربي', 'code': 'ar', 'icon': 'fa-ar' },
	],

	// //******************//
	// // For Live Server [APP]
	// //******************//
	// domain: 'http://app.ai-octopus.com',
    // domainSecured: 'https://app.ai-octopus.com',
    // domainSecuredOne: 'https://oct.almulla-sms.com',
	// host: 'https://ai-octopus.com',
	// defaultServer: 'app',

	// enableOctopusSocket: true,
	// socketUrl: 'https://socket.ai-octopus.com',
	// socketTransports: ['websocket', 'polling', 'flashsocket'],

	// enableFirebase: false,
	// firebase : {
	//   apiKey: "AIzaSyA5vf1_M16_YNI-Ue8XxvAVu_URHnR5FLg",
	//   authDomain: "octopus-da9cc.firebaseapp.com",
	//   databaseURL: "https://octopus-da9cc-default-rtdb.firebaseio.com",
	//   projectId: "octopus-da9cc",
	//   storageBucket: "octopus-da9cc.appspot.com",
	//   messagingSenderId: "63856949865",
	//   appId: "1:63856949865:web:cd8eff21e2e305199680f8"
	// },
	// languages : [
	//   {'name': 'English', 'code': 'en', 'icon': 'fa-en'},
	//   {'name': 'Arabic', 'code': 'ar', 'icon': 'fa-ar'},
	// ],

	// // ******************//
	// // For Stage Server
	// // ******************//
	// domain: 'http://stagescrm.ai-octopus.com',
    // domainSecured: 'https://stagescrm.ai-octopus.com',
    // domainSecuredOne: 'https://oct.almulla-sms.com',
	// host: 'https://stage.ai-octopus.com',
	// defaultServer: 'stage',

	// enableOctopusSocket: true,
	// socketUrl: 'https://socket.ai-octopus.com',
	// socketTransports: ['websocket', 'polling', 'flashsocket'],

	// enableFirebase: false,
	// firebase : {
	//   apiKey: "AIzaSyBqMtWGPU2LvYxWeS864jVDjxiJNTIUAho",
	//   authDomain: "octopus-66ce3.firebaseapp.com",
	//   databaseURL: "https://octopus-66ce3-default-rtdb.firebaseio.com",
	//   projectId: "octopus-66ce3",
	//   storageBucket: "octopus-66ce3.appspot.com",
	//   messagingSenderId: "818648322413",
	//   appId: "1:818648322413:web:a9ae586c9c4a3c73b94a64"
	// },
	// languages : [
	//   {'name': 'English', 'code': 'en', 'icon': 'fa-en'},
	//   {'name': 'Arabic', 'code': 'ar', 'icon': 'fa-ar'},
	// ],


	/*
	 * For easier debugging in development mode, you can import the following file
	 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
	 *
	 * This import should be commented out in production mode because it will have a negative impact
	 * on performance if an error is thrown.
	 */
	// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

	// firebase : {
	//   apiKey: "AIzaSyBqMtWGPU2LvYxWeS864jVDjxiJNTIUAho",
	//   authDomain: "octopus-66ce3.firebaseapp.com",
	//   databaseURL: "https://octopus-66ce3-default-rtdb.asia-southeast1.firebasedatabase.app",
	//   projectId: "octopus-66ce3",
	//   storageBucket: "octopus-66ce3.appspot.com",
	//   messagingSenderId: "818648322413",
	//   appId: "1:818648322413:web:a9ae586c9c4a3c73b94a64",
	//   measurementId: "G-Y17G9YHPPT"
	// }

};
