import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import {
  User, UserService, Catalog, CatalogService, WabaService,
  Category,
  CategoryService,
  CatalogProductService
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AssignCatalogWabaComponent } from '../assign-catalog-waba/assign-catalog-waba.component';
import { UntypedFormGroup } from '@angular/forms';
import { ManageCategoryComponent } from '../manage-category/manage-category.component';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'catalog-category',
  templateUrl: './catalog-category.component.html',
  styleUrls: ['./catalog-category.component.scss'],
  providers: [OrderByPipe]
})
export class CatalogCategoryComponent {
  @Output() isEditable = new EventEmitter();
  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;
  categoryLists: Category[] = [];
  param: any;
  usageParam: any;
  productParam: any;
  dataSource = new MatTableDataSource(this.categoryLists);
  displayedColumns = ['name', 'catalog', 'product', 'action'];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    public categoryService: CategoryService,
    public catalogProductService: CatalogProductService,
    public matDialog: MatDialog,
    public wabaService: WabaService,
    private orderByPipe: OrderByPipe) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.catalogProductService.params.subscribe(data => this.productParam = data);
    this.categoryService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) { this.subLoader = false; }
      this.categoryLists = data;
      this.categoryLists = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.categoryLists);
      this.dataSource.sort = this.sort;
    });
  }


  ngOnInit(): void {
    this.doLoader();
    this.dataInit();
  }
  /** On destroy */
  ngOnDestroy(): void {

  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  dataInit() {
    this.categoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
    this.categoryService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  editCategory(catalog: any) {
    this.isEditable.emit(true);
    this.categoryService.itemSource.next(catalog);
  }

  /** Delete Social Site */
  deleteCatalog(item: Catalog): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var id = {
          id: item._id.toString()
        }
        this.categoryService.destroy('catCategory/delete', id);
      }
    });
  }


/** Add / Change IntractiveGroup Images */
	productIntractiveGroup(category = null): void {
    this.catalogProductService.resetParams();
		// this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
    this.productParam['catalog_id'] = category.catalog_id;
    this.productParam['type'] = 'all';
    this.catalogProductService.getAllCategoryProduct();
		let dialogRef = this.matDialog.open(ManageCategoryComponent, {
			width: "1000px",
			data: {
				action: (category) ? 'edit' : 'new',
				item: category,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				switch (actionType) {
					case 'update':
						var productData = {
							"id": category._id,
							"products": formDatas,
						}
						this.categoryService.categoryProductUpdate('catCategory/manage', productData);
						break;
				}
			});
	}

}
