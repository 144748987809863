export class ConsumerGroupModel {
    id: number;
    parent_id: number;
    client_id: number
    name: string;
    description: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName: string;
    className: string;
    createdByName?: string;
    updatedByName?: string;
    parent?: any;
    childrens?: Array<any>;
    consumer?: any;
    totalContacts?: number;
    parentName?: string;

    /**
     * Constructor
     *
     * @param consumerGroup
     */
    constructor(consumerGroup) {
            this.id = consumerGroup.id || '';
            this.parent_id = consumerGroup.parent_id || '';
            this.client_id = consumerGroup.client_id || '';
            this.name = consumerGroup.name || '';
            this.description = consumerGroup.description || '';
            this.created_by = consumerGroup.created_by || '';
            this.updated_by = consumerGroup.updated_by || '';
            this.created_at = consumerGroup.created_at || '';
            this.updated_at = consumerGroup.updated_at || '';

            this.tableName = consumerGroup.tableName || '';
    }
}
