import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { TemplateFormComponent } from 'src/app/library/forms/templateForm/templateForm.component';
import { LabelChangeFormComponent } from 'src/app/library/forms/labelChangeForm/labelChangeForm.component';
import * as moment from 'moment-timezone';
import {
	User, UserService,
	PeopleService, People, PeopleModel,
	TemplateMessageService, ModalHasLabelService, FbTemplateMessageService,
	EventService
} from 'src/app/@tji/_dbShare';
import { ConversationInstagramHistoryComponent } from '../conversationInstagramHistory/conversationInstagramHistory.component';
import { ConversationHistoryComponent } from '../conversationHistory/conversationHistory.component';
import { PeopleFormComponent } from '../form/form.component';

@UntilDestroy()
@Component({
	selector: 'people-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	peoplesAll: any;
	peoplesAllData: any;
	peoples: People[] = [];
	param: any;
	peopleResponse: any;
	dataSource = new MatTableDataSource(this.peoples);
	displayedColumns = ['date', 'avatar', 'name', 'leadName', 'account', 'contact', 'action'];

	templateParam: any;
	fbTemplateParam: any;
	eventData: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	filterLoader: boolean = true;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public templateMessageService: TemplateMessageService,
		public modalHasLabelService: ModalHasLabelService,
		public fbtemplateMessageService: FbTemplateMessageService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private eventService: EventService,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.peopleService.filterLoader.subscribe(data => this.filterLoader = data);
		this.peopleService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.peoplesAll) { this.subLoader = false; }

			// var filterdData = data.reduce((accumalator, current) => {
			// 		if (!accumalator.some((item) => item.unique_ref === current.unique_ref)) {
			// 		    accumalator.push(current);
			// 		} 
			// 		return accumalator;
			// 	}, []);
			this.peoplesAllData = data;
			// this.peoples = this.orderByPipe.transform(data, '-updated_at');
			this.peoples = data;
			this.dataSource = new MatTableDataSource(this.peoples);
			this.dataSource.sort = this.sort;
		});
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateParam = data);
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.fbTemplateParam = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.peopleService.unSubscribe();
		this.peopleService.unSubscribeFilter();
		this.templateMessageService.unSubscribe();
		this.templateMessageService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.peopleService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.peopleService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		// this.templateParam.limit = 200;
		// this.templateParam.current = 1;
		// this.fbTemplateParam.limit = 200;
		// this.fbTemplateParam.current = 1;
		// this.templateMessageService.getAllItems();
	}

	/** Delete Social Site */
	deletePeople(people: People): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var peopleData = {
					id: people.id,
				}
				this.peopleService.destroy("peoplen/delete", peopleData);
			}
		});
	}

	/** Send Template Message to Lead */
	sendTemplateMessage(people: People): void {
		this.peopleService.checkLeadReserve({ "people_id": people.id });
		this.peopleService.changeItem(people);
		let templateDialogRef = this.matDialog.open(TemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: people
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				this.templateMessageService.postUrl(result, 'send-bulk-template');
			}
		});
	}

	/** Change Lable to Lead */
	changeLabel(people: People): void {
		this.peopleService.changeItem(people);
		let labelDialogRef = this.matDialog.open(LabelChangeFormComponent, {
			disableClose: false,
			width: '600',
			data: people
		});
		labelDialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.peopleService.store(formData.value);
				// setTimeout(() => { this.peopleService.getItem({ id: people.id }); }, 300);
			});
	}

	statusChange(id, event) {
		this.peopleService.update(id, { 'is_active': event });
		// setTimeout(() => {
		// this.snackBar.openFromComponent(AlertComponent, {
		//     duration: 500000,
		//     panelClass: ['snackBarSuccess']
		// });
		// this.snackBar.open('Update Successfully', 'Close', {
		//     panelClass: ['snackBarSuccess']
		// });
		// }, 300);
	}

	/** Add / Update Tag */
	showConversationHistory(people): void {
		this.peopleService.changeItem((people) ? people : null);
		if (people && people.siteName === 'Instagram') {
			let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
				width: "650px",
				data: {
					action: 'show',
					item: people,
					siteName: (people && people.site) ? people.site : null,
					siteId: (people && people.siteId) ? people.siteId : null,
					sourceFrom: 'people',
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		} else if (people && people.siteName === 'Instadm' && people.siteId === '7') {
			let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
				width: "650px",
				data: {
					action: 'show',
					item: people,
					siteName: (people && people.site) ? people.site : null,
					siteId: (people && people.siteId) ? people.siteId : null,
					sourceFrom: 'people',
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		} else {
			let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
				width: "600px",
				data: {
					action: 'show',
					item: people,
					siteName: (people && people.site) ? people.site : null,
					siteId: (people && people.siteId) ? people.siteId : null,
					sourceFrom: 'people',
				}
			});
			dialogRef.afterClosed().subscribe(response => { });
		}
	}

	/** people Name update */
	updateLead(people = null): void {
		this.peopleService.changeItem((people) ? people : null);
		let dialogRef = this.matDialog.open(PeopleFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: people,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				let updateData: any = formData.value;
				switch (actionType) {
					case 'update':
						updateData["people_id"] = people.id;
						this.peopleService.updateName(people.id, updateData);
						break;
				}
			});
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'newfeed':
					if (eventData && data) {
						this.peoplesAllData.forEach((element, index) => {
							if (data.peopleId == element.id) {
								this.peoplesAllData.splice(index, 1);
							}
						});
						var people = {
							"id": data.peopleId,
							"code": "",
							"name": data.peopleName,
							"unique_ref": data?.resource?.account_sid,
							"client_id": data.client_id,
							"created_by": data.created_by,
							"updated_by": data.updated_by,
							"created_at": moment(moment(data.lead_updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), //moment(data.created_at).format('dddd MMMM D YYYY, h:mm:ss a'),
							"updated_at": moment(moment(data.lead_updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), // moment(data.updated_at).format('dddd MMMM D YYYY, h:mm:ss a'),
							"deleted_at": data.clientSite.deleted_at ? data.clientSite.deleted_at : null,
							"client_site_id": data.client_site_id,
							"whatsappNumber": data.resource.from,
							"labelName": data.peopleLabelName,
							"labelColor": data.peopleLabelColor,
							"labelColorText": data.peopleLabelColorText,
							"labelId": data.peopleLabelId,
							"icon": data.siteIcon,
							"siteName": data.siteName,
							"accountName": data.clientSiteName,
							"clientSiteName": data.clientSiteName,
							"clientSiteColor": data.clientSiteColor,
							"clientSiteTextColor": data.clientSiteTextColor,
							"siteId": '',
							"site": data.site,
							"is_active": true,
							"avatar_url": data.peopleAvatar,
							"detail": null
						}
						this.peoplesAllData.unshift(people)
						this.peopleService.changeAllItems(this.peoplesAllData);
					}
					break;
				case 'aNewfeed':
					if (eventData && data) {
						this.peoplesAllData.forEach((element, index) => {
							if (data.peopleId == element.id) {
								this.peoplesAllData.splice(index, 1);
							}
						});
						var peoples = {
							"id": data.peopleId,
							"code": "",
							"name": data.leadInfo.name,
							"unique_ref": data?.ChatData?.from,
							"client_id": data.client_id,
							"created_by": data.created_by,
							"updated_by": data.updated_by,
							"created_at": moment(moment(data.lead_updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), //moment(data.created_at).format('dddd MMMM D YYYY, h:mm:ss a'),
							"updated_at": moment(moment(data.lead_updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), // moment(data.updated_at).format('dddd MMMM D YYYY, h:mm:ss a'),
							"client_site_id": data.client_site_id,
							"whatsappNumber": data?.ChatData?.from,
							"labelName": data.leadInfo.peopleLabelName,
							"labelColor": data.leadInfo.peopleLabelColor,
							"labelColorText": data.leadInfo.peopleLabelColorText,
							"labelId": data.leadInfo.peopleLabelId,
							"icon": data.clientSite.details.icon,
							"siteName": data.clientSite.account_name,
							"accountName": data.clientSite.account_name,
							"clientSiteName": data.clientSite.details.name,
							"deleted_at": data.clientSite.deleted_at ? data.clientSite.deleted_at : null,
							// "clientSiteColor": data.clientSiteColor,
							// "clientSiteTextColor": data.clientSiteTextColor,
							"siteId": data.clientSite.details.id,
							"site": data.site,
							"is_active": true,
							"avatar_url": data.leadInfo.peopleAvatar,
							"detail": null
						}
						this.peoplesAllData.unshift(peoples)
						this.peopleService.changeAllItems(this.peoplesAllData);
					}
					break;
					case 'labelupdate':
					if (eventData && data) {
						this.peoplesAllData.forEach((element, index) => {
							if (data.peopleId == element.id) {
								element.labelName = data.name;
								element.labelId = data.id;
								element.labelColorText = data.color_text;
								element.labelColor = data.color;
							}
						});
						this.peopleService.changeAllItems(this.peoplesAllData);
					}
					break;
				default:
					break;
			}
		}
	}

}
