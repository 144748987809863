export class ClientDeliverySettingModel {
	_id: string;
	name: string;
	email: string;
	logo: string;
	established_at: string;
	country?: number;
	website: string;
	about: string;
	category: string;
	client_id: number;
	created_by: number;
	updated_by: number;
	created_at: number;
	updated_at: number;
	countryInfo: any;
	orderSettings: any;
	clientSite: any;
	clientSiteInfo: any;
	clientCount: number;
	clientSiteName: any;

	/**
	 * Constructor
	 *
	 * @param clientDeliverySetting
	 */
	constructor(clientDeliverySetting) {
		this._id = clientDeliverySetting._id || '';
		this.name = clientDeliverySetting.name || '';
		this.email = clientDeliverySetting.email || '';
		this.logo = clientDeliverySetting.logo || '';
		this.established_at = clientDeliverySetting.established_at || '';
		this.country = clientDeliverySetting.country || '';
		this.website = clientDeliverySetting.website || '';
		this.about = clientDeliverySetting.about || '';
		this.category = clientDeliverySetting.category || '';
		this.client_id = clientDeliverySetting.client_id || '';
		this.created_by = clientDeliverySetting.created_by || '';
		this.updated_by = clientDeliverySetting.updated_by || '';
		this.created_at = clientDeliverySetting.created_at || '';
		this.updated_at = clientDeliverySetting.updated_at || '';
		this.countryInfo = clientDeliverySetting.updated_at || '';
		this.orderSettings = clientDeliverySetting.orderSettings || '';
		this.clientSiteInfo = clientDeliverySetting.clientSiteInfo || '';
		this.clientCount = clientDeliverySetting.clientCount || 0;
		this.clientSiteName = clientDeliverySetting.clientSiteName || '';
	}
}
