import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  User, UserService,
  ConsumerService, ConsumerGroupService,
  ConsumerGroupModel
} from 'src/app/@tji/_dbShare';
@Component({
  selector: 'app-product-catalog-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  user: User;
  constructor(public userService: UserService,
    public consumerService: ConsumerService,
    public consumerGroupService: ConsumerGroupService,
    private router: Router) {
    this.userService.user.subscribe(data => this.user = data);
  }

  ngOnInit(): void {
  }
  newCatalog() {
    this.router.navigateByUrl('/app/product-catalog/add');
  }
}
