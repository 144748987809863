import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from './data';

import {
	User, UserService,
	AgentService, Agent
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-item-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']
})

export class ChartComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('chart', { static: true }) chart: ElementRef;
	authUser: User;
	agent: Agent;

	data: any = [];
	view: any[] = [];
	colorScheme = {
		domain: ['#1a75ff', '#00802b', '#ff66a3']
	};

	constructor(public userService: UserService,
		public agentService: AgentService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => this.agent = data);
		Object.assign(this, { data });
	}

	ngOnInit() { 
		this.view = [this.chart.nativeElement.offsetWidth - 50, this.chart.nativeElement.offsetHeight];
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.agentService.unSubscribeFilter();
	}

	ngAfterViewInit() { }

	onSelect(event) { }

}
