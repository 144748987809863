import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  User, UserService,
  OpenTicketService, OpenTicket,
  TeamService,
  BoardService, Board
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-team-view',
  templateUrl: './team-view.component.html',
  styleUrls: ['./team-view.component.scss']
})
export class TeamViewComponent {
  dialogTitle: string = 'View Team';
  authUser: User;
  openTicket: OpenTicket;
  team: any;
  action: any;
  baseImage: string = 'assets/img/no-user.jpg';
  loader: boolean = true;

  constructor(public userService: UserService,
    public openTicketService: OpenTicketService,
    public teamService: TeamService,
    public matDialogRef: MatDialogRef<TeamViewComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder) {
    this.getInit(_data);
    this.openTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.openTicket = data;
      console.log(this.openTicket)
    });
    this.teamService.item.pipe(untilDestroyed(this)).subscribe(data => this.team = data);
    this.doLoader();
  }

  getInit(data) {
    // Set the defaults
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'View Team';
        break;
      case 'edit':
        this.dialogTitle = 'View Team';
        break;
      default:
        break;
    }
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 3000);
  }

}
