import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from 'src/app/appAccount/socialSite/form/form.component';
import { MessengerFormComponent } from 'src/app/appAccount/socialSite/messengerForm/messengerForm.component';
import { WhatsappFormComponent } from 'src/app/appAccount/socialSite/whatsappForm/whatsappForm.component';
import { WhatsappProfileUpdateFormComponent } from 'src/app/appAccount/socialSite/whatsapp-profile-update-form/whatsapp-profile-update-form.component';
import { TwitterFormComponent } from 'src/app/appAccount/socialSite/twitterForm/twitterForm.component';
import { YoutubeFormComponent } from 'src/app/appAccount/socialSite/youtubeForm/youtubeForm.component';
import { InstagramFormComponent } from 'src/app/appAccount/socialSite/instagramForm/instagramForm.component';
import { InstadmFormComponent } from 'src/app/appAccount/socialSite/instadmForm/instadmForm.component';
import { WebchatFormComponent } from 'src/app/appAccount/socialSite/webchatForm/webchatForm.component';
import { LogViewComponent } from 'src/app/appAccount/socialSite/logView/logView.component';

import {
	User, UserService, CommonService,
	RClientSiteService, ClientSiteService, ClientSite, ClientSiteModel,
	SiteService, Site, SiteModel, PusherService
} from 'src/app/@tji/_dbShare';
import { exit } from 'process';

@UntilDestroy()
@Component({
	selector: 'reseller-analytics-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	isAdmin: any;
	clientSites: ClientSite[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.clientSites);

	displayedColumns = ['socialStatus', 'media', 'account', 'clientName', 'consumer', 'createdat', 'updatedat', 'login', 'status', 'profileImage', 'action'];
	now = +new Date();
	oneday = 60 * 60 * 24 * 1000;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	twitterRedirectUrl: string = null;

	channel: any;

	profileImageForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public commonService: CommonService,
		public rClientSiteService: RClientSiteService,
		public clientSiteService: ClientSiteService,
		public pusherService: PusherService,
		public siteService: SiteService,
		private router: Router,
		public matDialog: MatDialog,
		private _formBuilder: UntypedFormBuilder,
		private orderByPipe: OrderByPipe) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.userService.user.subscribe(data => this.authUser = data);
		this.clientSiteService.twitterRedirectUrl.pipe(untilDestroyed(this)).subscribe(data => this.twitterRedirectUrl = data);
		this.rClientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.clientSites);
			this.dataSource.sort = this.sort;
		});
		this.isAdmin = this.userService.isAuthUserIsAdmin();
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.rClientSiteService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rClientSiteService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	subscribePusher() {
		this.channel = this.pusherService.subscribeChannel('authuser');
		this.channel.bind('accountsessionexpired', (data) => {
			this.rClientSiteService.getAllItems();
		});
	}

	/** Add / Update ClientSite */
	newClientSite(clientSite = null): void {
		this.rClientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.site) ? clientSite.site : new SiteModel({}));
		let dialogRef: any = null;
		switch (clientSite.site.slug) {
			case "Twitter": case "twitter":
			case "Tweet": case "tweet":
			case "Twitterdm": case "twitterdm":
			case "Tweetdm": case "tweetdm":
				dialogRef = this.matDialog.open(TwitterFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "messenger":
				dialogRef = this.matDialog.open(MessengerFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "whatsapp":
				dialogRef = this.matDialog.open(WhatsappFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "instagram":
				dialogRef = this.matDialog.open(InstagramFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "instadm":
				dialogRef = this.matDialog.open(InstadmFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "webchat":
				dialogRef = this.matDialog.open(WebchatFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			case "facebook":
				break;
			case "youtube":
				dialogRef = this.matDialog.open(YoutubeFormComponent, {
					width: "600px",
					data: {
						action: 'edit',
						item: clientSite,
					}
				});
				break;
			default:
				break;
		}

		// let dialogRef = this.matDialog.open(FormComponent, {
		// 	width: "600px",
		// 	data: {
		// 		action: (clientSite) ? 'edit' : 'new',
		// 		item: clientSite,
		// 	}
		// });

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.rClientSiteService.store(formData.value);
						break;
					case 'update':
						this.rClientSiteService.update(id, formData.value);
						break;
					case 'delete':
						this.deleteSocialSite(clientSite);
						break;
				}
			});
	}


		/** Profile Update ClientSite */
		whatsappProfileUpdate(clientSite = null): void {
			this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
			this.rClientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
			this.siteService.changeItem((clientSite.site) ? clientSite.site : new SiteModel({}));
			let dialogRef: any = null;
			switch (clientSite.site.slug) {
				case "whatsapp":
					dialogRef = this.matDialog.open(WhatsappProfileUpdateFormComponent, {
						width: "600px",
						data: {
							action: 'edit',
							item: clientSite,
						}
					});
					break;
				default:
					break;
			}
	
			dialogRef.afterClosed()
				.subscribe(response => {
					if (!response) { return; }
					const actionType: string = response[0];
					const formData: UntypedFormGroup = response[1];
					const id: number = (response[2]) ? response[2] : null;
					switch (actionType) {
						case 'new':
							this.rClientSiteService.store(formData.value);
							break;
						case 'update':
							this.rClientSiteService.update(id, formData.value);
							break;
						case 'delete':
							this.deleteSocialSite(clientSite);
							break;
					}
				});
		}

	/** Delete Social Site */
	deleteSocialSite(socialSite): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.rClientSiteService.destroy(socialSite.id);
			}
		});
	}

	/** Delete Social Site */
	loginForm(clientSite): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.rClientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		let url = (clientSite && clientSite.loginUrl) ? clientSite.loginUrl : clientSite.site.loginUrl;
		window.open(url, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
	}

	/** Delete Social Site */
	loginTwitterForm(clientSite): void {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.rClientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.clientSiteService.getTwitterRedirectUrl(clientSite.loginUrl);
		setTimeout(() => {
			this.loader = !this.loader;
			if (this.twitterRedirectUrl) {
				window.open(this.twitterRedirectUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
			}
		}, 2000);

		// let url = (clientSite && clientSite.loginUrl) ? clientSite.loginUrl : clientSite.site.loginUrl;
		// window.open(url, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
	}

	calculateDiff(dateSent) {
		let currentDate = new Date();
		dateSent = new Date(dateSent);

		return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
	}

	getStatus(socialSite) {
		let statusName = 'open';
		if (!socialSite.is_authorized) {
			statusName = 'close';
		} else {
			if (socialSite.recentSentOn) {
				if (this.calculateDiff(socialSite.recentSentOn) == 0) {
					statusName = 'open';
				} else if (this.calculateDiff(socialSite.recentSentOn) > 5) {
					statusName = 'high';
				} else {
					statusName = 'low';
				}
			} else {
				statusName = 'open-nochat';
			}
		}
		return statusName;
	}

	onFileSelected(event, socialSite: ClientSite) {
		let reader = new FileReader();
		let fileData = null;
		this.profileImageForm = this._formBuilder.group({
			file_data: null,
			file_name: null,
			file_type: null,
			client_site_id: socialSite.id
		});
		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				this.profileImageForm.patchValue({
					file_data: reader.result,
					file_name: file.name,
					file_type: file.type,
					client_site_id: socialSite.id
				});
				this.sendFile();
			};
		}
	}

	/** Show Logs */
	showLogs(clientSite: ClientSite) {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.rClientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let dialogRef = this.matDialog.open(LogViewComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: clientSite,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			
		});
	}

	setForceReAuth(clientSite: ClientSite) {
		this.clientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.rClientSiteService.changeItem((clientSite) ? clientSite : new ClientSiteModel({}));
		this.siteService.changeItem((clientSite.details) ? clientSite.details : new SiteModel({}));
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to ask your client to Re-Authorized this Account?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let updateData: any = {'do_reauthendicate' : 1};
				this.rClientSiteService.update(clientSite.id, updateData);
			}
		});
	}

	sendFile() {
		this.rClientSiteService.post(this.profileImageForm.value, 'whatsapp-profile-image-update');
		this.profileImageForm.reset();
	}

	syncWhatsappProfile(socialSite) {
		this.clientSiteService.changeItem(socialSite);
		this.rClientSiteService.changeItem(socialSite);
		let data = {'client_site_id' : socialSite.id };
		this.rClientSiteService.getUrl('sync-whatsapp-profile', data);
	}

	/** Export Excel */
	downloadWebchatKey(socialSite: ClientSite) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to Download Key?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let url: string = this.clientSiteService.downloadWebchatKeyUrl({'client_site_id': socialSite.id, 'client_id': socialSite.client_id});
				window.open(url, '_blank');
			}
		});
	}

	/** Authorize WebChat */
	authorizeWebchatKey(socialSite: ClientSite) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to Authorize Webchat Key?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.rClientSiteService.getUrl('sync-webchat/' + socialSite.id);
			}
		});
	}

}
