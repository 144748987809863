import { IntractiveObject } from './intractiveMessageObject.interface';

export class IntractiveMessageObjectModel {
    recipient_type:string;
    to: string;
    type: string;
    interactive: IntractiveObject;
    // whatspp_data : string;

    /**
     * Constructor
     *
     * @param intractiveMessageObjectData
     */
    constructor(intractiveMessageObjectData) {
            this.recipient_type = intractiveMessageObjectData.recipient_type || "individual";
            this.to = intractiveMessageObjectData.to || "<<to>>";
            this.type = intractiveMessageObjectData.type || "interactive";
            this.interactive = intractiveMessageObjectData.interactive || new IntractiveObjectModel({});
            // this.whatspp_data = intractiveMessageObjectData.whatspp_data || "";
    }
}

export class IntractiveObjectModel {
    type: string;
    header: any;
    body: any;
    footer: any;
    action: any;

    /**
     * Constructor
     *
     * @param intractiveObjectData
     */
    constructor(intractiveObjectData) {
            this.type = intractiveObjectData.type || "button";
            // this.header = intractiveObjectData.header || new HeaderModel({});
            // this.body = intractiveObjectData.body || new BodyModel({});
            // this.footer = intractiveObjectData.footer || new FooterModel({});
            // this.action = intractiveObjectData.action || new ActionModel({});
    }
}

export class HeaderModel {
    type: string;
    text?: string;
    video?: any;
    image?: any;
    document?: any;

    /**
     * Constructor
     *
     * @param headerData
     */
    constructor(headerData) {
            this.type = headerData.type || "text";
            this.text = headerData.text || '';
    }
}

export class BodyModel {
    type: string;
    text: string;

    /**
     * Constructor
     *
     * @param bodyData
     */
    constructor(bodyData) {
            this.type = bodyData.type || "text";
            this.text = bodyData.text || '';
    }
}

export class FooterModel {
    type: string;
    text: string;

    /**
     * Constructor
     *
     * @param footerData
     */
    constructor(footerData) {
            this.type = footerData.type || "text";
            this.text = footerData.text || '';
    }
}

export class ActionModel {
    type: string;
    button?: string;
    buttons?: any;
    sections?: any;

    /**
     * Constructor
     *
     * @param footerData
     */
    constructor(footerData) {
            this.type = footerData.type || "";
    }
}
