export class FirebaseJobModel {
	jobcode: string;
	success_count: number;
	failed_count: number;
	pending_count: number;
	total_count: number;

	/**
	 * Constructor
	 *
	 * @param firebaseJob
	 */
	constructor(firebaseJob) {
		this.jobcode = firebaseJob.jobcode || null;
		this.success_count = firebaseJob.success_count || null;
		this.failed_count = firebaseJob.failed_count || null;
		this.pending_count = firebaseJob.pending_count || null;
		this.total_count = firebaseJob.total_count || null;
	}
}
