import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { UserService } from 'src/app/@tji/_dbShare/user/user.service';
import { NotifyService } from 'src/app/@tji/_dbShare/notify/notify.service';
import { Alert } from 'src/app/@tji/_dbShare/alert/alert/alert.interface';
import { AlertModel } from 'src/app/@tji/_dbShare/alert/alert/alert_model.model';
import { ToastService } from 'src/app/@tji/_dbShare/alert/toast/toast.service';
import { ToastData } from 'src/app/@tji/_dbShare/alert/toast/toastData.interface';
import { ToastModel } from 'src/app/@tji/_dbShare/alert/toast/toast_model.model';
import { FirebaseJob } from './firebaseJob.interface';
import { FirebaseJobModel } from './firebaseJob_model.model';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class FirebaseJobService implements Resolve<any> {

	authUser: any;
	firebaseJobRef: AngularFireObject<any>;
	firebaseJob: FirebaseJob[] = [];
	alertData: Alert;
	toastData: ToastData;
	@Output() onChangeItem: any = new EventEmitter();
	@Output() onChangeItems: any = new EventEmitter();

	private itemSource = new BehaviorSubject<FirebaseJob>(null);
	item = this.itemSource.asObservable();

	private itemsSource = new BehaviorSubject<FirebaseJob[]>([]);
	items = this.itemsSource.asObservable();

	private jobcodeSource = new BehaviorSubject<string>(null);
	jobcode = this.jobcodeSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private userService: UserService,
		private notifyService: NotifyService,
		private toastService: ToastService,
		private db: AngularFireDatabase,
		private snackBar: MatSnackBar) {
		this.firebaseJobRef = db.object('job_event');
		this.firebaseJobRef.snapshotChanges().subscribe(data => {
			this.firebaseJob = data.payload.val();
			this.changeAllItems(this.firebaseJob);
			this.getOutput(this.firebaseJob);
		});
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([
				// this.getItem()
			])
				.then(() => {
					resolve('/dashboard');
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed FeedService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on FeedService');
	}

	clearSubscribe() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	changeAllItems(items: FirebaseJob[]) {
		this.itemsSource.next(items);
		this.onChangeItems.emit(items);
	}

	changeItem(item: FirebaseJob) {
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeJobcode(jobcode: string) {
		this.jobcodeSource.next(jobcode);
	}

	getOutput(firebaseJob) {
		if(this.jobcode && this.firebaseJob && this.firebaseJob.length > 0) {
			let item = firebaseJob.findIndex(x => x.jobcode === this.jobcode);
			if(item && item.jobcode) {
				this.changeItem(item);
			}
		}
		return;
	}

	
}
