import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ChatService, CommentService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-ticket-comments',
	templateUrl: './commentSidenav.component.html',
	styleUrls: ['./commentSidenav.component.scss']
})

export class CommentSideNavComponent implements OnInit, OnDestroy {
	@Output() onClose = new EventEmitter();
	user: User;
	ticket: any;

	constructor(public userService: UserService,
		public chatService: ChatService,
		public commentService: CommentService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.commentService.unSubscribeFilter();
	}

}
