import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { tick } from '@angular/core/testing';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TemplateFormComponent } from 'src/app/library/forms/templateForm/templateForm.component';
import { InitiateMessageFormComponent } from 'src/app/appAccount/agentDashboard/initiateMessageForm/initiateMessageForm.component';
import { LabelChangeFormComponent } from 'src/app/library/forms/labelChangeForm/labelChangeForm.component';

import {
    PeopleService, People, PeopleModel, CommonService, TemplateMessageService,
	PeopleChatService, ChatService, UserService, User, WhatsappService, ModalHasLabelService, ProductCartService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-customer-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerListComponent implements OnInit, AfterViewInit {
	@Input() siteName: string;
	authUser: User;
	clientId: number = this.userService.getAuthClientId();
	peopleLibraries: People[] = [];
	peoples: People[] = [];
	selectedPeople: People;
	param: any;
	searchTerm: string;
	templateParam: any;
	viewScreen: any;
	base_image: string = this.commonService.logo_image;

	constructor(public chatService: ChatService,
		public peopleChatService: PeopleChatService,
		public peopleService: PeopleService,
		public whatsappService: WhatsappService,
		public modalHasLabelService: ModalHasLabelService,
		public commonService: CommonService,
		public userService: UserService,
		public templateMessageService: TemplateMessageService,
		public matDialog: MatDialog,
		private cdRef: ChangeDetectorRef,
		public productCartService: ProductCartService,) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.chatService.viewScreen.pipe(untilDestroyed(this)).subscribe(data => {
			this.viewScreen = data.toLowerCase();
			this.getPeoplesFromLibraries();
		});
	}

	ngOnInit() {
		this.subscribeData();
		this.getPeoples();
		// this.dataInit();
	}

	ngAfterViewInit() {
		this.cdRef.detectChanges();
	}

	subscribeData() {
		let siteName = (this.siteName) ? this.siteName : 'All Tickets';
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateParam = data);
		this.peopleService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.peopleService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.peopleLibraries = data;
			this.getPeoplesFromLibraries(siteName);
		});
	}

	getPeoplesFromLibraries(siteName = null) {
		if(this.peopleLibraries && this.peopleLibraries.length > 0) {
			if (!siteName) { siteName = (this.siteName) ? this.siteName : 'All Tickets'; }
			if (siteName && siteName !== '' && siteName !== null && siteName !== 'All Tickets' && siteName !== 'all tickets') {
				this.peoples = this.peopleLibraries.filter(x => {
					return (x.siteName && x.siteName.toLowerCase() === siteName.toLowerCase()) ? true : false;
				});
			} else {
				this.peoples = this.peopleLibraries.filter(x => { return true; });
			}
		}
	}

	getPeoples(siteName = null) {
		if (!siteName) { siteName = (this.siteName) ? this.siteName : 'All Tickets'; }
		this.param.client_id = this.clientId;
		this.param.accountType = (siteName && siteName !== '' && siteName !== null && siteName !== 'All Tickets' && siteName !== 'all tickets') ? siteName.toLowerCase() : 'all tickets';
		if (siteName && this.viewScreen && this.viewScreen.toLowerCase() === siteName.toLowerCase()) {
			this.peopleService.getAllItems();
		}
	}

	dataInit() {
		this.templateParam.limit = 200;
		this.templateParam.current = 1;
		// this.templateParam.stencilType = 'greating-template';
		this.templateMessageService.getAllItems();
	}

	onSelectPeople(people) {
		this.selectedPeople = people;
		// this.productCartService.cartDataSource.next(false);
	}
	
	/** Send Template Message to Lead */
	sendTemplateMessage(people: People): void {
		this.peopleService.checkLeadReserve({"people_id":people.id});
		this.selectedPeople = people;
		this.peopleService.changeItem(people);
		let templateDialogRef = this.matDialog.open(TemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: people
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				result.showAlert = true;
				this.templateMessageService.postUrl(result,'send-bulk-template');
			}
		});
	}

	/** Send Template Message to Lead */
	sendTemplateMessageToNewPeople(): void {
        this.selectedPeople = new PeopleModel({});
        this.peopleService.changeItem(this.selectedPeople);
		let templateDialogRef = this.matDialog.open(TemplateFormComponent, {
			disableClose: false,
            width: '600',
            data: this.selectedPeople
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				result.showAlert = true;
				this.templateMessageService.postUrl(result,'send-bulk-template');
			}
		});
	}

	/** Send New InitiateMessage */
	sendInitiateMessage(people: People): void {
		let dialogRef = this.matDialog.open(InitiateMessageFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: null,
				to: (people && people.unique_ref) ? people.unique_ref : null,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.whatsappService.post(formData.value, 'whatsapp-begin-message');
			});
	}

	/** Change Lable to Lead */
	changeLabel(people: People): void {
		this.peopleService.changeItem(people);
		let labelDialogRef = this.matDialog.open(LabelChangeFormComponent, {
			disableClose: false,
			width: '600',
			data: people
		});
		labelDialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.modalHasLabelService.store(formData.value);
				setTimeout(() => { this.peopleService.getItem({id: people.id}); }, 300);
			});
	}

}
