import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from 'src/app/@tji/_dbShare';
@Component({
  selector: 'app-input',
  template: `
    <div class="fieldContainer">
        <mat-form-field class="demo-full-width" [formGroup]="group">
          <input
            matInput
            [formControlName]="field.name"
            [placeholder]="field.label"
            [type]="field.type"
          />

          <ng-container
            *ngFor="let validation of field.validations"
            ngProjectAs="mat-error"
          >
            <mat-error
              *ngIf="group.get(field.name).hasError(validation.name)"
              >{{ validation.message }}</mat-error
            >
          </ng-container>
        </mat-form-field>
    </div>
  `,
  styles: ['.mat-form-field { width: 100% !important; }'],
})
export class InputComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}
