import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { TemplateReport } from './templateReport.interface';
import { TemplateReportModel } from './templateReport_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class TemplateReportService implements Resolve<any> {
	url: string = 'report/template-report';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'client_id': '',
		'client_site_id': '',
		'from_date': '',
		'to_date': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<TemplateReport[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<TemplateReport>(new TemplateReportModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private totalJobsSource = new BehaviorSubject<number>(0);
	totalJobs = this.totalJobsSource.asObservable();

	private totalCountSource = new BehaviorSubject<number>(0);
	totalCount = this.totalCountSource.asObservable();

	private successCountSource = new BehaviorSubject<number>(0);
	successCount = this.successCountSource.asObservable();

	private failedCountSource = new BehaviorSubject<number>(0);
	failedCount = this.failedCountSource.asObservable();

	private rejectedCountSource = new BehaviorSubject<number>(0);
	rejectedCount = this.rejectedCountSource.asObservable();

	private displayItemsSource = new BehaviorSubject<TemplateReport>(new TemplateReportModel({}));
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TemplateReportService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TemplateReportService');
	}

	changeAllItems(allItems: TemplateReport[]) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: TemplateReport) {
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeTotalJobs(count: number) {
		this.totalJobsSource.next(count);
	}

	changeTotalCount(count: number) {
		this.totalCountSource.next(count);
	}

	changeSuccessCount(count: number) {
		this.successCountSource.next(count);
	}

	changeFailedCount(count: number) {
		this.failedCountSource.next(count);
	}

	changeRejectedCount(count: number) {
		this.rejectedCountSource.next(count);
	}

	changeDisplayItems(displayItems: TemplateReport) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: TemplateReport) {
		// let allItems = [];
		// this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		// if (allItems && allItems.length > 0) {
		//     for (var i = 0; i < allItems.length; ++i) {
		//         if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
		//     }
		// }
		// this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'client_id': '',
			'client_site_id': '',
			'from_date': '',
			'to_date': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("template/usage", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.client_info
						var statistics = data.additional.statistics[0]
						var usersInfo = data.additional.users_info
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"id": usage.id,
								"jobcode": usage.jobcode,
								"template_id": usage.template_id,
								"client_id": usage.client_id,
								"client_site_id": usage.client_site_id,
								"is_sendlater": usage.is_sendlater,
								"total_count": usage.total_count,
								"success_count": usage.success_count,
								"failed_count": usage.failed_count,
								"rejected_count": usage.rejected_count,
								"is_completed": usage.is_completed,
								"total_retries": usage.total_retries,
								"created_by": usage.created_by,
								"updated_by": usage.updated_by,
								"deleted_at": usage.deleted_at,
								"progress_status": usage.progress_status,
								"stage": usage.stage,
								"clientName": clientInfo.name,
								"clientAvatar": clientInfo.details.image_api,
								"created_at": usage.created_at, //moment(usage.created_at + '.000+0300').local(),
								"updated_at": usage.created_at //moment(usage.updated_at + '.000+0300').local(),
							}
							if (usage.template_info) {
								dataFormats["templateCode"] = usage.template_info.code;
								dataFormats["templateName"] = usage.template_info.name;
							}
							if (usersInfo) {
								usersInfo.forEach(user => {
									if (usage.created_by == user.id) {
										dataFormats["senderName"] = user.username
										dataFormats["senderEmail"] = user.email
									} else if (usage.created_by == 1) {
										dataFormats["senderName"] = "API"
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
						// this.changeTotalItem(statistics.total);
						this.changeTotalJobs(statistics.totalJobCount);
						this.changeTotalCount(statistics.totalSend);
						this.changeSuccessCount(statistics.successCount);
						this.changeFailedCount(statistics.failedCount);
						this.changeRejectedCount(statistics.rejectedCount);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new TemplateReportModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				// this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(data: any) {
		this.commonService.storeItem("template/templateUsageReport", data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"date": usage.created_at,
								"success_count": usage.success_count,
								"total_count": usage.total_count,
								"failed_count": usage.failed_count,
								"rejected_count": usage.rejected_count,
								"progress_status": usage.progress_status,
								"code": usage.jobcode,
							}
							if (usage.template_info) {
								dataFormats["templateName"] = usage.template_info.name;
							}
							dataFormat.push(dataFormats);
						});
						this.downloadFile(dataFormat)
					}else{
						this.alert('Danger', "No records found");
					}
				}else{
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);

	}


	// downloadFile
	downloadFile(templateAll, title = null) {
		var excelData = [];
		if (templateAll && templateAll.length > 0) {
			templateAll.forEach(template=> {
				if (template) {
					var data = {
						"Date": template.date,
						"Code": template.code,
						"Template Name": template.templateName,
						"Success": template.success_count,
						"Failed": template.failed_count,
						"Rejected": template.rejected_count,
						"Total": template.total_count,
						"Status": template.progress_status
					}
					excelData.push(data);
				}
			});
		}

		let arrHeader = ["Date","Code","Template Name","Success","Failed","Rejected","Total","Status"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'all_template_reports_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Date","Code","Template Name","Success","Failed","Rejected","Total","Status"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}

	getSignleTemplateExport(data: any) {
		this.commonService.storeItem("template/singleTemplateUsageReport", data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.id) {
						var usage = data.data;
						var dataFormat = [];
							if (usage.failednumbers && usage.failednumbers.length > 0) {
								usage.failednumbers.forEach(element => {
									var failed = {};
									failed["to"] = element;
									failed["failed"] = 'Failed';
									failed["date"] = usage.created_at,
									dataFormat.push(failed);
								});
							}
							if (usage.successnumbers && usage.successnumbers.length > 0) {
								usage.successnumbers.forEach(element => {
									var success = {};
									success["to"] = element;
									success["success"] = 'Sent';
									success["date"] = usage.created_at,
									dataFormat.push(success);
								});
							}
							if (usage.rejectednumbers && usage.rejectednumbers.length > 0) {
								usage.rejectednumbers.forEach(element => {
									var rejected = {};
									rejected["to"] = element;
									rejected["rejected"] = 'Rejected';
									rejected["date"] = usage.created_at,
									dataFormat.push(rejected);
								});
							}
							// if (usage.template) {
							// 	dataFormats["templateName"] = usage.template.name;
							// }
							// if (usage.failednumbers && usage.failednumbers.length > 0) {
							// 	var failed = [];
							// 	usage.failednumbers.forEach(element => {
							// 		failed.push(element + ',')
							// 	});
							// 	dataFormats["failednumbers"] = failed.toString();
							// }
							// if (usage.successnumbers && usage.successnumbers.length > 0) {
							// 	var success = [];
							// 	usage.successnumbers.forEach(element => {
							// 		success.push(element + ',')
							// 	});
							// 	dataFormats["successnumbers"] = success.toString();
							// }
							
						this.downloadFiles(dataFormat)
					}else{
						this.alert('Danger', "No records found");
					}
				}else{
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);

	}


	// downloadFile
	downloadFiles(templateAll, title = null) {
		var excelData = [];
		if (templateAll && templateAll.length > 0) {
			templateAll.forEach(template=> {
				if (template) {
					var data = {
						"Date": template.date,
						"To": template.to,
						"Success Status": template.success,
						"Failed Status": template.failed,
						"Rejected Status": template.rejected,
					}
					excelData.push(data);
				}
			});
		}

		let arrHeader = ["Date","To","Success Status","Failed Status","Rejected Status"];
		let csvData = this.ConvertToCSVs(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'single_template_reports_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSVs(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Date","To","Success Status","Failed Status","Rejected Status"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			
			str += line + '\r\n';
		}
		return str;
	}


}