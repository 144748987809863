import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SocialSiteImportModule } from 'src/app/appAccount/socialSite/socialSite-import.module';
import { ItrsbotImportModule } from 'src/app/appAccount/itrsbot/itrsbot-import.module';
import { TemplateImportModule } from 'src/app/appAccount/template/template-import.module';
import { RSocialSiteRouteModule } from './rSocialSite-route.module';

import { RClientComponent } from './client/client.component';
import { ClientFormComponent } from './clientForm/clientForm.component';
import { SocialMediaComponent } from './socialMedia/socialMedia.component';
import { SocialMediaListComponent } from './socialMediaList/socialMediaList.component';
import { MainSocialsiteComponent } from './mainSocialsite/mainSocialsite.component';
import { MainItrsbotComponent } from './mainItrsbot/mainItrsbot.component';
import { MainTemplateComponent } from './mainTemplate/mainTemplate.component';
import { ListComponent } from './list/list.component';

import { RClientService, RClientSiteService, SiteService, IntractiveCommandService, ParamService } from 'src/app/@tji/_dbShare';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SwipeClientSiteComponent } from './swipe-client-site/swipe-client-site.component';

@NgModule({
    declarations: [
        RClientComponent,
        ClientFormComponent,
        SocialMediaComponent,
        SocialMediaListComponent,
        MainSocialsiteComponent,
        MainItrsbotComponent,
        MainTemplateComponent,
        ListComponent,
        SidebarComponent,
        SwipeClientSiteComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        SocialSiteImportModule,
        ItrsbotImportModule,
        TemplateImportModule,
        RSocialSiteRouteModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        SocialSiteImportModule,
        ItrsbotImportModule,
        TemplateImportModule,
        RClientComponent,
        ClientFormComponent,
        SocialMediaComponent,
        SocialMediaListComponent,
        MainSocialsiteComponent,
        MainItrsbotComponent,
        MainTemplateComponent,
    ],
    providers: [
        RClientService,
        RClientSiteService,
        SiteService,
        IntractiveCommandService,
        ParamService
    ]
})

export class RSocialSiteModule { }
