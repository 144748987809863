import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { PersonSettingFormComponent } from '../personSettingForm/personSettingForm.component';

import {
	User, UserService,
	ResellerService, Reseller,
	PersonSettingService, PersonSetting,
	SettingFieldService, SettingField
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-item-setting-field',
	templateUrl: './settingField.component.html',
	styleUrls: ['./settingField.component.scss']
})

export class SettingFieldComponent {
	@Output()onClose = new EventEmitter();
	settingFields: SettingField[] = [];
	settingFieldParam: any;
	reseller: Reseller;

	constructor(private _formBuilder: UntypedFormBuilder,
		public resellerService: ResellerService,
		public settingFieldService: SettingFieldService,
		public personSettingService: PersonSettingService,
		public matDialog: MatDialog) {
		this.settingFieldService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.settingFields = data.filter(x => {
				return (!x.is_client_editable && !x.is_agent_editable) ? true : false;
			});
		});
		this.settingFieldService.params.pipe(untilDestroyed(this)).subscribe(data => this.settingFieldParam = data);
		this.resellerService.item.pipe(untilDestroyed(this)).subscribe(data => this.reseller = data);
	}

	ngOnInit() {
		this.getSettingFields();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.settingFieldService.resetParams();
	}

	getSettingFields() {
		this.settingFieldParam.is_reseller_editable = '0';
		this.settingFieldParam.is_agent_editable = '0';
		this.settingFieldService.getAllItems();
	}

	selectSettingField(personSetting: PersonSetting = null, field: SettingField = null) {
		this.settingFieldService.changeItem(field);
		let dialogRef = this.matDialog.open(PersonSettingFormComponent, {
			width: "600px",
			data: {
				action: (personSetting) ? 'edit' : 'new',
				item: personSetting,
				field: field,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.personSettingService.store(formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.personSettingService.update(formData.value);
						break;
				}
			});
	}

}
