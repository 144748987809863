import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { FlagReport } from './flagReport.interface';
import { FlagReportModel } from './flagReport_model.model';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class FlagReportService implements Resolve<any> {
	url: string = 'flag/report';
	routeParams: any;
	defaultParams: any = {
		'client_id': '',
		'client_site_id': '',
		'from_date': '',
		'to_date': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<FlagReport[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<FlagReport>(new FlagReportModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<FlagReport>(new FlagReportModel({}));
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private allItemsReport = new BehaviorSubject<any>([]);
	allReport = this.allItemsReport.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed FlagReportService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on FlagReportService');
	}

	changeAllItems(allItems: FlagReport[]) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: FlagReport) {
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: FlagReport) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: FlagReport) {
		// let allItems = [];
		// this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		// if (allItems && allItems.length > 0) {
		//     for (var i = 0; i < allItems.length; ++i) {
		//         if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
		//     }
		// }
		// this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'client_id': '',
			'client_site_id': '',
			'from_date': '',
			'to_date': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.changeAllItems(data.data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new FlagReportModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				// this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		this.snackBar.openFromComponent(AlertComponent, {
			panelClass: ['alert' + capitalType],
			data: {
				message: message,
				type: type.toLowerCase(),
			}
		});
	}

	getExportUrl(data: any) {
		let domain = this.commonService.backendUrl;
		let param = btoa(JSON.stringify(data));
		let url = domain + 'report/flag-report-export/' + param;
		return url;
	}

	getAllReports(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('flag/reportprint', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var report = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (report && report.data.length > 0) { 
						report.data.forEach(flagList => {
							var dataFormats = {
								"_id": flagList._id,
								"created_at": moment(flagList.created_at * 1000).format("DD-MMM-yyy-hh:mm a"),
							}
							if (flagList.resourceInfo) {
								dataFormats['code'] = flagList.resourceInfo.code;
								additionalData.agentInfo.forEach(agent => {
									if (agent.id == flagList.resourceInfo.agent_id) {
										dataFormats['agentName'] = agent.name ? agent.name : '--';
									}
								});
							}
							additionalData.clientSitInfo.forEach(clientSitInfo => {
								if (clientSitInfo.id == flagList.client_site_id) {
									dataFormats['siteName'] = clientSitInfo.details.name ? clientSitInfo.details.name : '--';
								}
							});
							if (flagList.PeopleInfo) {
								dataFormats['account'] = flagList.PeopleInfo.unique_ref ? flagList.PeopleInfo.unique_ref : '--';;
								dataFormats['leads'] = flagList.PeopleInfo.name ? flagList.PeopleInfo.name : '--';
							}
							if (flagList.flagInfo) {
								dataFormats["flag_name"]=  flagList.flagInfo.name ? flagList.flagInfo.name: '--';
							}
							dataFormat.push(dataFormats);
						});
						this.allItemsReport.next(dataFormat);
					}else{
						this.allItemsReport.next([]);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

}