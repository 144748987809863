import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ChatService,
	WebchatTicketService, WebchatTicket
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'webchat-ticket-item-flag',
	templateUrl: './flagSidenav.component.html',
	styleUrls: ['./flagSidenav.component.scss']
})

export class FlagSideNavComponent implements OnInit, OnDestroy {
	user: User;
	ticket: WebchatTicket;

	constructor(public userService: UserService,
		public webchatTicketService: WebchatTicketService,
		private chatService: ChatService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		// this.webchatTicketService.item.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
	}

}
