import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	TweetService, Tweet,
	TweetdmService, Tweetdm,
	MessengerService, Messenger,
	WhatsappService, Whatsapp,
	WebchatService, Webchat,
	YoutubeService, Youtube,
	InstagramService, Instagram,
	InstadmService, Instadm,
	CommonService,
	FeedService, Feed,
	PeopleService,
	EventService
} from 'src/app/@tji/_dbShare';
import { I } from '@angular/cdk/keycodes';

@UntilDestroy()
@Component({
	selector: 'people-conversation-history',
	templateUrl: './conversationHistory.component.html',
	styleUrls: ['./conversationHistory.component.scss']
})

export class ConversationHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeTweet: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeTweetdm: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeMessenger: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeWhatsapp: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeWebchat: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeYoutube: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeInstagram: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeInstadm: ScrollToBottomDirective;

	loader: boolean = true;
	subLoader: boolean = false;
	dialogTitle: string = 'Conversation History';
	authUser: User;
	people: any;
	conversationHistory: UntypedFormGroup;
	ticketClassName: string = null;
	siteName: string = null;

	tweets: Tweet[] = [];
	tweetParam: any;
	tweetdms: Tweetdm[] = [];
	tweetdmParam: any;
	messengers: Messenger[] = [];
	messengerParam: any;
	whatsapps: Whatsapp[] = [];
	whatsappParam: any;
	webchats: Webchat[] = [];
	webchatParam: any;
	youtubes: Youtube[] = [];
	youtubeParam: any;
	instagrams: Instagram[] = [];
	instagramParam: any;
	instadms: Instadm[] = [];
	instadmParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;
	eventData: any;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public tweetService: TweetService,
		public tweetdmService: TweetdmService,
		public messengerService: MessengerService,
		public whatsappService: WhatsappService,
		public webchatService: WebchatService,
		public youtubeService: YoutubeService,
		public instagramService: InstagramService,
		public instadmService: InstadmService,
		public commonService: CommonService,
		public eventService: EventService,
		private cd: ChangeDetectorRef,
		public matDialogRef: MatDialogRef<ConversationHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.tweetService.resetParams();
		this.tweetdmService.resetParams();
		this.messengerService.resetParams();
		this.whatsappService.resetParams();
		this.webchatService.resetParams();
		this.youtubeService.resetParams();
		this.instagramService.resetParams();
		this.instadmService.resetParams();
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => {
		
			this.people = data;
		});
		this.tweetService.params.pipe(untilDestroyed(this)).subscribe(data => this.tweetParam = data);
		this.tweetService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.tweets) { this.subLoader = false; }
			this.tweets = data;
		});
		this.tweetdmService.params.pipe(untilDestroyed(this)).subscribe(data => this.tweetdmParam = data);
		this.tweetdmService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.tweetdms) { this.subLoader = false; }
			this.tweetdms = data;
		});
		this.messengerService.params.pipe(untilDestroyed(this)).subscribe(data => this.messengerParam = data);
		this.messengerService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.messengers) { this.subLoader = false; }
			this.messengers = data;
		});
		this.whatsappService.params.pipe(untilDestroyed(this)).subscribe(data => this.whatsappParam = data);
		this.whatsappService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.whatsapps) { this.subLoader = false; }
			var chatDataList: any;
			var newChatDataList: any;
			chatDataList = [...data];
			newChatDataList = chatDataList.sort((a, b) => a.created_at - b.created_at)
			this.whatsapps = newChatDataList;
			// this.whatsapps = data;
		});
		this.webchatService.params.pipe(untilDestroyed(this)).subscribe(data => this.webchatParam = data);
		this.webchatService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.webchats) { this.subLoader = false; }
			this.webchats = data;
		});
		this.youtubeService.params.pipe(untilDestroyed(this)).subscribe(data => this.youtubeParam = data);
		this.youtubeService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.youtubes) { this.subLoader = false; }
			this.youtubes = data;
		});
		this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => this.instagramParam = data);
		this.instagramService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.instagrams) { this.subLoader = false; }
			this.instagrams = data;
		});
		this.instadmService.params.pipe(untilDestroyed(this)).subscribe(data => this.instadmParam = data);
		this.instadmService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.instadms) { this.subLoader = false; }
			this.instadms = data;
		});
		this.doLoader();
		this.getConversations();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.tweetService.changeAllItems([]);
		this.tweetdmService.changeAllItems([]);
		this.messengerService.changeAllItems([]);
		this.whatsappService.changeAllItems([]);
		this.webchatService.changeAllItems([]);
		this.youtubeService.changeAllItems([]);
		this.instagramService.changeAllItems([]);
		this.instadmService.changeAllItems([]);
		this.tweetService.unSubscribe();
		this.tweetdmService.unSubscribe();
		this.messengerService.unSubscribe();
		this.whatsappService.unSubscribe();
		this.webchatService.unSubscribe();
		this.youtubeService.unSubscribe();
		this.instagramService.unSubscribe();
		this.instadmService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		this.siteName = data.siteName;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Conversation History';
				break;
			default:
				break;
		}
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	getConversations() {
		if (this._data && this._data.siteName) {

			let sourceFrom = this._data.sourceFrom;

			if (sourceFrom == 'people') {

				switch (this._data.siteName) {
					case "tweet": case "Tweet":
						this.tweetParam.client_site_id = this.people.client_site_id;
						this.tweetParam.people_id = this.people.id;
						this.tweetParam.sort = "created_at|DESC";
						this.tweetParam.is_history = 1;
						this.tweetService.getAllItems(this.tweetParam);
						break;
					case "tweetdm": case "Tweetdm":
						this.tweetdmParam.client_site_id = this.people.client_site_id;
						this.tweetdmParam.people_id = this.people.id;
						this.tweetdmParam.sort = "created_at|DESC";
						this.tweetdmParam.is_history = 1;
						this.tweetdmService.getAllItems(this.tweetdmParam);
						break;
					case "messenger": case "Messenger":
						this.messengerParam.client_site_id = this.people.client_site_id;
						this.messengerParam.people_id = this.people.id;
						this.messengerParam.sort = "created_at|DESC";
						this.messengerParam.is_history = 1;
						this.messengerService.getAllItems(this.messengerParam);
						break;
					case "whatsapp": case "Whatsapp":
						this.whatsappParam.client_site_id = this.people.client_site_id;
						this.whatsappParam.people_id = this.people.id;
						this.whatsappParam.sort = "created_at|DESC";
						this.whatsappParam.is_history = 1;
						this.whatsappService.getAllItems(this.whatsappParam);
						break;
					case "webchat": case "Webchat":
						this.webchatParam.client_site_id = this.people.client_site_id;
						this.webchatParam.people_id = this.people.id;
						this.webchatParam.sort = "created_at|DESC";
						this.webchatParam.is_history = 1;
						this.webchatService.getAllItems(this.webchatParam);
						break;
					case "youtube": case "Youtube":
						this.youtubeParam.client_site_id = this.people.client_site_id;
						this.youtubeParam.people_id = this.people.id;
						this.youtubeParam.sort = "created_at|DESC";
						this.youtubeParam.is_history = 1;
						this.youtubeService.getAllItems(this.youtubeParam);
						break;
					case "instagram": case "Instagram":
						this.instagramParam.client_site_id = this.people.client_site_id;
						this.instagramParam.people_id = this.people.id;
						this.instagramParam.sort = "created_at|DESC";
						this.instagramParam.is_history = 1;
						this.instagramService.getAllItems(this.instagramParam);
						break;
					case "instadm": case "Instadm":
						this.instadmParam.client_site_id = this.people.client_site_id;
						this.instadmParam.people_id = this.people.id;
						this.instadmParam.sort = "created_at|DESC";
						this.instadmParam.is_history = 1;
						this.instadmService.getAllItems(this.instadmParam);
						break;
					default:
						break;
				}


			} else {

				switch (this._data.siteName) {
					case "tweet": case "Tweet": case "tweet_ticket":
						this.tweetParam.client_site_id = this._data.item.clientSiteId;
						this.tweetParam.resource_id = this._data.item.tweet_id;
						this.tweetParam.sort = "created_at|DESC";
						this.tweetParam.is_history = 1;
						this.tweetService.getAllItems(this.tweetParam);
						break;
					case "tweetdm": case "Tweetdm": case "tweetdm_ticket":
						this.tweetdmParam.client_site_id = this._data.item.clientSiteId;
						this.tweetdmParam.resource_id = this._data.item.tweetdm_id;
						this.tweetdmParam.sort = "created_at|DESC";
						this.tweetdmParam.is_history = 1;
						this.tweetdmService.getAllItems(this.tweetdmParam);
						break;
					case "messenger": case "Messenger": case "messenger_ticket":
						this.messengerParam.client_site_id = this._data.item.clientSiteId;
						this.messengerParam.resource_id = this._data.item.messenger_id;
						this.messengerParam.sort = "created_at|DESC";
						this.messengerParam.is_history = 1;
						this.messengerService.getAllItems(this.messengerParam);
						break;
					case "Whatsapp": case "whatsapp": case "whatsapp_ticket":
						this.whatsappParam.client_site_id = this._data.item.clientSiteId;
						this.whatsappParam.resource_id = this._data.item.whatsapp_id;
						this.whatsappParam.sort = "created_at|DESC";
						this.whatsappParam.is_history = 1;
						this.whatsappService.getAllItems(this.whatsappParam);
						break;
					case "webchat": case "Webchat": case "webchat_ticket":
						this.webchatParam.client_site_id = this._data.item.clientSiteId;
						this.webchatParam.resource_id = this._data.item.webchat_id;
						this.webchatParam.sort = "created_at|DESC";
						this.webchatParam.is_history = 1;
						this.webchatService.getAllItems(this.webchatParam);
						break;
					case "youtube": case "Youtube":
						this.youtubeParam.client_site_id = this._data.item.clientSiteId;
						this.youtubeParam.resource_id = this._data.item.youtube_id;
						this.youtubeParam.sort = "created_at|DESC";
						this.youtubeParam.is_history = 1;
						this.youtubeService.getAllItems(this.youtubeParam);
						break;
					case "instagram": case "Instagram":
						this.instagramParam.client_site_id = this._data.item.clientSiteId;
						this.instagramParam.resource_id = this._data.item.instagram_id;
						this.instagramParam.sort = "created_at|DESC";
						this.instagramParam.is_history = 1;
						this.instagramService.getAllItems(this.instagramParam);
						break;
					case "instadm": case "Instadm":
						this.instadmParam.client_site_id = this._data.item.clientSiteId;
						this.instadmParam.resource_id = this._data.item.instadm_id;
						this.instadmParam.sort = "created_at|DESC";
						this.instadmParam.is_history = 1;
						this.instadmService.getAllItems(this.instadmParam);
						break;
					default:
						break;
				}


			}








		}
	}

	onClose() {
		this.loader = !this.loader;
		this.tweetService.changeAllItems([]);
		this.tweetdmService.changeAllItems([]);
		this.messengerService.changeAllItems([]);
		this.whatsappService.changeAllItems([]);
		this.webchatService.changeAllItems([]);
		this.youtubeService.changeAllItems([]);
		this.instagramService.changeAllItems([]);
		this.instadmService.changeAllItems([]);
		this.tweetService.unSubscribe();
		this.tweetdmService.unSubscribe();
		this.messengerService.unSubscribe();
		this.whatsappService.unSubscribe();
		this.webchatService.unSubscribe();
		this.youtubeService.unSubscribe();
		this.instagramService.unSubscribe();
		this.instadmService.unSubscribe();
	}

	isUnSupport(chat) {
		if (chat.media !== 'text' && chat.media !== 'video' && chat.media !== 'audio'
			&& chat.media !== 'document' && chat.media !== 'image' && chat.media !== 'button'
			&& chat.media !== 'location' && chat.media !== 'voice'
			&& chat.media !== 'interactive' && chat.media !== 'template') {
			return true;
		}
		return false;
	}

	
	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'Login': case 'login':
					let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
						this.onClose();
					}
					break;
				case 'logout': case 'Logout':
					let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
						this.onClose();
					}
					break;
				case 'newfeed':
					this.matDialogRef.close();
					this.onClose();
					break;
				case 'aNewfeed':
					this.matDialogRef.close();
					this.onClose();
					break;

				case 'removefeed':
					this.matDialogRef.close();
					this.onClose();
					break;
				default:
					break;
			}
		}
	}
}
