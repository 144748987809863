import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Brand } from './brand.interface';
import { BrandModel } from './brand_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class BrandService implements Resolve<any> {
	url: string = 'brand/list';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};
	isSelectAgent: boolean = false;

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Brand[]>([]);
	allItems = this.allItemsSource.asObservable();

	public itemSource = new BehaviorSubject<Brand>(new BrandModel({}));
	item = this.itemSource.asObservable();

	public itemIdSource = new BehaviorSubject<Brand>(new BrandModel({}));
	itemId = this.itemIdSource.asObservable();

	public isAddItems = new BehaviorSubject<boolean>(false);
	addItems = this.isAddItems.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Brand[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	public allIClientSiteSource = new BehaviorSubject<Brand[]>([]);
	allClientSite = this.allIClientSiteSource.asObservable();

	public getBrandSource = new BehaviorSubject<any>([]);
	getBrand = this.getBrandSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.defaultParams.search = '',
				this.resetParams(),
				this.getAllItems(),
				// this.getAllBrandClientSite(),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed AgentService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on AgentService');
	}

	concatlibrary(allItems: Brand[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Brand) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item._id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item._id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Brand) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item._id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item._id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: Brand[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Brand[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
	}

	changeItem(item: Brand) {
		this.itemSource.next(item);
	}

	changeItemId(item: Brand) {
		this.itemIdSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Brand[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Brand) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'search': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data && data.additional && data.additional.length > 0) {
						var additional = data.additional;

						data.data.forEach(element => {
							if (element.clientSite && element.clientSite.length > 0) {
								var clientSiteName = [];
								element.clientSite.forEach(clientSite => {
									additional.forEach(client => {
										if (clientSite.client_site_id == client.id) {
											clientSiteName.push(client.account_name)
										}
									});
								});
								if (clientSiteName.length > 0) {
									element['clientCount'] = element.clientSite.length;
									element['clientSiteName'] = clientSiteName.toString();
								}
							}

						});
					}
					this.changeAllItems(data.data);
					this.changeTotalItem(data.pagnitation.totalResult);
				}
			},
				error => { console.log('Error ::' + error); }
			);
	}

	getAllBrandClientSite(params: any = null) {
		// params = this.paramsInit(params);
		this.commonService.storeItem("brand/clientsiteList", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.allIClientSiteSource.next(data.data);
				}
			},
				error => { console.log('Error ::' + error); }
			);
	}

		getBrands(params: any = null) {
		// params = this.paramsInit(params);
		this.commonService.storeItem("brand/all", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.getBrandSource.next(data.data);
				}
			},
				error => { console.log('Error ::' + error); }
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: Brand) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeTotalItem(lists.length);
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
			}
		}
		this.changeTotalItem(allItems.length);
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.commonService.storeItem('brand/viewMore', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.additional && data.additional.length > 0) {
					var additional = data.additional;

					if (data.data && data.data.data && data.data.data.clientSite && data.data.data.clientSite.length > 0) {
						var clientSiteName = [];
						data.data.data.clientSite.forEach(clientSite => {
							additional.forEach(client => {
								if (clientSite.client_site_id == client.id) {
									clientSiteName.push(client.account_name)
								}
							});
						});
						if (clientSiteName.length > 0) {
							data.data.data['clientCount'] = data.data.data.clientSite.length;
							data.data.data['clientSiteName'] = clientSiteName.toString();
						}
					}
					this.changeItem(data.data.data);
					this.changeAllItemsByItem(data.data.data);
				} else {
					this.changeItem(data.data);
					this.changeAllItemsByItem(data.data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	store(url, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.concatItem(res.data);
					this.changeItem(res.data);
					this.alert('Success', "Created Successfully !!!");
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(url, data: any): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					if (res.additional && res.additional.length > 0) {
						var additional = res.additional;

						if (res.data && res.data.data && res.data.data.clientSite && res.data.data.clientSite.length > 0) {
							var clientSiteName = [];
							res.data.data.clientSite.forEach(clientSite => {
								additional.forEach(client => {
									if (clientSite.client_site_id == client.id) {
										clientSiteName.push(client.account_name)
									}
								});
							});
							if (clientSiteName.length > 0) {
								res.data.data['clientCount'] = res.data.data.clientSite.length;
								res.data.data['clientSiteName'] = clientSiteName.toString();
							}
						}
						this.changeItem(res.data.data);
						this.changeAllItemsByItem(res.data.data);
					} else {
						this.changeItem(res.data);
						this.changeAllItemsByItem(res.data);
					}

					this.alert('Success', "Updated Successfully !!!");

				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	linkClientSite(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					if (res.additional && res.additional.length > 0) {
						var additional = res.additional;

						if (res.data && res.data.data && res.data.data.clientSite && res.data.data.clientSite.length > 0) {
							var clientSiteName = [];
							res.data.data.clientSite.forEach(clientSite => {
								additional.forEach(client => {
									if (clientSite.client_site_id == client.id) {
										clientSiteName.push(client.account_name)
									}
								});
							});
							if (clientSiteName.length > 0) {
								res.data.data['clientCount'] = res.data.data.clientSite.length;
								res.data.data['clientSiteName'] = clientSiteName.toString();
							}
						}
						this.changeItem(res.data.data);
						this.changeAllItemsByItem(res.data.data);
					} else {
						this.changeItem(res.data);
						this.changeAllItemsByItem(res.data);
					}


					this.alert('Success', res.data.message);
				} else {
					this.getItem({ id: data.id });
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	statusUpdate(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.getAllItems();
					this.alert('Success', res.data.message);
				} else {
					this.alertService.webErrorShow(res);
					this.getAllItems();
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					this.getAllItems();
				}
			);
	}

	destroy(url, id) {
		this.commonService.storeItem(url, id, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(id.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	post(url: string, data: any) {
		let alert = (data && data.alert) ? data.alert : null;
		let alertType = (data && data.alertType) ? data.alertType : 'Success';
		this.commonService.storeItem(url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.getItem();
				this.alert(alertType, (alert) ? alert : 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

}
