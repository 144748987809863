import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';

import {
	User, UserService,
	CommentService, Comment,
	ChildCommentService, ChildComment, EventService
} from 'src/app/@tji/_dbShare';
import { E } from '@angular/cdk/keycodes';

@UntilDestroy()
@Component({
	selector: 'comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss'],
	providers: [OrderByPipe]
})

export class CommentsComponent implements OnInit, OnDestroy {
	@Input() ticket: any;
	@Input() clientSiteId: number;
	@Output() closeMe = new EventEmitter();

	user: User;
	allComments: Comment[] = [];
	comments: Comment[] = [];
	selectedComment: number;
	allChildComments: ChildComment[] = [];
	childComments: ChildComment[] = [];

	commentParam: any;
	childParam: any;
	loader: boolean = true;
	eventData: any;

	constructor(public userService: UserService,
		public commentService: CommentService,
		public childCommentService: ChildCommentService,
		private router: Router,
		public matDialog: MatDialog,
		public eventService: EventService,
		private orderByPipe: OrderByPipe) {
		this.comments = [];
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.userService.user.subscribe(data => this.user = data);
		this.commentService.params.pipe(untilDestroyed(this)).subscribe(data => this.commentParam = data);
		this.commentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.allComments = data;
			// this.comments = this.orderByPipe.transform(data, '-_id');
			this.commentsInit();
		});
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.commentService.allItemsSource.next([]);
	}

	commentsInit() {
		this.comments = [];
		if (this.ticket && this.ticket.id && this.clientSiteId && this.allComments && this.allComments.length > 0) {
			let allCommentsFiltered = this.allComments.filter(x => {
				return (x.resource_id == this.ticket.id &&
					x.client_site_id == this.clientSiteId) ? true : false;
			});
			this.comments = this.orderByPipe.transform(allCommentsFiltered, '_id');
		}
	}

	onSelectComment(comment: Comment) {
		if (this.selectedComment == comment._id) {
			delete this.selectedComment;
		} else {
			this.selectedComment = comment._id
		}
	}

	checkParentId(comment: Comment, childComments): boolean {
		if (childComments && childComments.length > 0) {
			return (childComments[0].comment_id === comment._id) ? true : false;
		}
		return false;
	}

	/** Close Tickets */
	doReply(comment: Comment = null): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: this.ticket,
				comment: comment,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.storeData('comments/addcomment', result.formValue);
			}
		});
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	transform(text: any): any {
		if (text.length > 0) {
			return text.replace(/\n/g, `<br>`);
		}
	}


	/** Delete Social Site */
	deleteCommends(comment: Comment): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var id = {
					commentId: comment._id
				}
				this.commentService.destroy(id);
			}
		});
	}

	deleteReplayCommends(replay: any): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var id = {
					commentId: replay.comment_id,
					replyId: replay.id
				}
				this.commentService.destroyReplay(id);
			}
		});
	}

	checkEventData() {
		if (this.eventData) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'addcomment':
					this.commentService.concatItem(this.eventData.data);
					break;
				case 'removecomment':
					this.commentService.spliceCommentItem(this.eventData.data);
					break;
				case 'replycomment':
					this.commentService.changeAllItemsByItem(this.eventData.data);
					break;
				default:
					break;
			}
		}
	}

}
