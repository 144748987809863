import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	WebhookService, Webhook, WebhookModel,
	ClientSiteService, ClientSite
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'social-site-webhook-urlform',
	templateUrl: './urlForm.component.html',
	styleUrls: ['./urlForm.component.scss']
})

export class UrlFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	clientSite: ClientSite;
	webhook: Webhook;
	action: string;
	urlForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public webhookService: WebhookService,
		public matDialogRef: MatDialogRef<UrlFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
		this.webhookService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.webhook = data;
			this.urlForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Add Site URL';
				break;
			case 'edit':
				this.dialogTitle = 'Update Site URL';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			site_url: [this.webhook.site_url || null, [Validators.required]],
			client_site_id: [this.webhook.client_site_id || this.clientSite.id || null, [Validators.required]],
			client_id: [this.webhook.client_id || this.clientSite.client_id || null, [Validators.required]],
		});
	}

}
