export class RMediaModel {
		id: number;
		name: string;
		is_active: number;
		count: number;
		total_Size: number;
	/**
	 * Constructor
	 *
	 * @param media
	 */
	constructor(media) {
		this.id = media.id || '';
		this.name = media.name || '';
		this.is_active = media.is_active || '';
		this.count = media.count || '';
		this.total_Size = media.total_Size || '';
	}
}
