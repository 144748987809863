import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-choosejumpintractiveform',
	templateUrl: './chooseJumpIntractiveForm.component.html',
	styleUrls: ['./chooseJumpIntractiveForm.component.scss']
})

export class ChooseJumpIntractiveFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	itrsbot: Itrsbot;
	intractives: Intractive[] = [];
	intractive: Intractive;
	action: string;
	chooseJumpIntractiveForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public matDialogRef: MatDialogRef<ChooseJumpIntractiveFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.intractiveService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.intractives = data);
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractive = data;
			this.getInit(_data);
			this.chooseJumpIntractiveForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		let intractive = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Link Skip to Jump Next Intractive';
				break;
			case 'edit':
				this.dialogTitle = 'Change Skip to Jump Next Intractive';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			jump_intractive_id: [this.intractive.id || null, [Validators.required]]
		});
	}


}
