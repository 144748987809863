export class DateModel {
	id: number;
	title: string;
	from_date: Date;
	to_date: Date;

	/**
	 * Constructor
	 * @param date
	 */
	constructor(date) {
		this.title = date.title || null;
		this.from_date = date.from_date || null;
		this.to_date = date.to_date || null;
	}
}
