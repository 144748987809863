export class PersonDetailModel {
	id: number;
	person_id: number;
	person_type: string;
	father_name: string;
	mother_name: string;
	company_name: string;
	mobile: string;
	address: string;
	home_phone: string;
	office_phone: string;
	emergency_mobile: string;
	gender: string;
	date_of_birh: Date;
	department: string;
	qualification: string;
	designation: string;
	authority_person: string;
	authority_email: string;
	authority_mobile: string;
	blood_group: string;
	marital_status: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	image_api: string;
	json: string;
	short_note: string;
	detail_about: string;
	
	twitter_id: string;
	twitter_name: string;
	twitter_username: string;
	twitter_bio: string;
	twitter_location: string;
	twitter_url: string;
	twitter_join_date: string;
	twitter_join_time: string;
	twitter_tweets: string;
	twitter_following: string;
	twitter_followers: string;
	twitter_likes: string;
	twitter_media: string;
	twitter_private: boolean;
	twitter_verified: boolean;
	twitter_profile_image_url: string;
	twitter_background_image: string;
	insta_follows_count: string;
	insta_followed_by_count: string;
	insta_media_count: string;
	insta_business_category_name: string;
	insta_email: string;
	profileCompletePercentage?: number;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param person_detail
	 */
	constructor(person_detail) {
		this.id = person_detail.id || '';
		this.person_id = person_detail.person_id || '';
		this.person_type = person_detail.person_type || '';
		this.father_name = person_detail.father_name || '';
		this.mother_name = person_detail.mother_name || '';
		this.company_name = person_detail.company_name || '';
		this.mobile = person_detail.mobile || '';
		this.address = person_detail.address || '';
		this.home_phone = person_detail.home_phone || '';
		this.office_phone = person_detail.office_phone || '';
		this.emergency_mobile = person_detail.emergency_mobile || '';
		this.gender = person_detail.gender || '';
		this.date_of_birh = person_detail.date_of_birh || '';
		this.department = person_detail.department || '';
		this.qualification = person_detail.qualification || '';
		this.designation = person_detail.designation || '';
		this.authority_person = person_detail.authority_person || '';
		this.authority_email = person_detail.authority_email || '';
		this.authority_mobile = person_detail.authority_mobile || '';
		this.blood_group = person_detail.blood_group || '';
		this.blood_group = person_detail.blood_group || '';
		this.created_by = person_detail.created_by || '';
		this.updated_by = person_detail.updated_by || '';
		this.created_at = person_detail.created_at || '';
		this.updated_at = person_detail.updated_at || '';
		
		this.image_api = person_detail.image_api || '';
		this.json = person_detail.json || '';
		this.json = person_detail.json || '';
		this.short_note = person_detail.short_note || '';
		this.detail_about = person_detail.detail_about || '';

		this.tableName = person_detail.tableName || '';
	}
}
