import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { RPersonSetting } from './rpersonSetting.interface';
import { RPersonSettingModel } from './rpersonSetting_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
var slugify = require('slugify')

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class RPersonSettingService implements Resolve<any> {
	url: string = 'person-setting';
	routeParams: any;
	defaultParams: any = {
		'all': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	private allItemsSource = new BehaviorSubject<RPersonSetting[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<RPersonSetting>(new RPersonSettingModel({}));
	item = this.itemSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed PersonSettingService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on PersonSettingService');
	}

	changeAllItems(allItems: RPersonSetting[]) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: RPersonSetting) {
		this.itemSource.next(item);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: RPersonSetting) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) {
					allItems.splice(i, 1, item);
				}
			}
		}
		this.changeAllItems(allItems);
	}

	checkAllItemsByItem(item: RPersonSetting): boolean {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) {
					return true;
				}
			}
		}
		return false;
	}



	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'all': 1,
			'search': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("reseller/setting/workspacesetting", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var dataFormat = [];
					data.data.forEach(settings => {
						var setting = {
							"title": settings.title,
							"slug": settings.slug,
							"field_type": settings.field_type,
							"category": settings.category,
							"is_editable": settings.is_editable ? true : false,
							"is_client_editable": settings.is_client_editable ? true : false,
							"is_agent_editable": settings.is_agent_editable ? true : false,
							"id": settings.MySettings.id,
							// "person_type": settings.title,
							"person_id": settings.MySettings.person_id,
							"setting_field_id": settings.MySettings.setting_field_id,
							"value": settings.MySettings.value,
							"created_at": settings.created_at,
							"updated_at": settings.updated_at,
							"deleted_at": settings.deleted_at,
							"jsonArray": JSON.parse(settings.json),
						}
						dataFormat.push(setting)
					});
					this.changeAllItems(dataFormat);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.items.data);
					if (data.items.last_page <= data.items.current_page) {
						params.page = data.items.last_page;
						this.changeParams(params);
					}
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: RPersonSetting) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.item);
					this.changeItem(data.item);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new RPersonSettingModel({}));
		}
	}

	store(params: any) {
		this.commonService.storeItem("reseller/setting/addWorkSpace", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {

				if (data.success) {
					var setting = {
						"title": data.data.title,
						"slug": data.data.slug,
						"field_type": data.data.field_type,
						"category": data.data.category,
						"is_editable": data.data.is_editable ? true : false,
						"is_client_editable": data.data.is_client_editable ? true : false,
						"is_agent_editable": data.data.is_agent_editable ? true : false,
						"id": data.data.MySettings.id,
						"person_type": '',
						"person_id": data.data.MySettings.person_id,
						"setting_field_id": data.data.MySettings.setting_field_id,
						"value": data.data.MySettings.value,
						"created_at": data.data.created_at,
						"updated_at": data.data.updated_at,
						"deleted_at": data.data.deleted_at,
						"jsonArray": JSON.parse(data.data.json),
					}
					if (this.checkAllItemsByItem(setting)) {
						this.changeAllItemsByItem(setting);
					} else {
						this.concatItem(setting);
					}

					this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(update: any) {
		this.commonService.storeItem('reseller/setting/updateWorkSpace', update, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {

					var setting = {
						"title": data.data.title,
						"slug": data.data.slug,
						"field_type": data.data.field_type,
						"category": data.data.category,
						"is_editable": data.data.is_editable ? true : false,
						"is_client_editable": data.data.is_client_editable ? true : false,
						"is_agent_editable": data.data.is_agent_editable ? true : false,
						"id": data.data.MySettings.id,
						"person_type": '',
						"person_id": data.data.MySettings.person_id,
						"setting_field_id": data.data.MySettings.setting_field_id,
						"value": data.data.MySettings.value,
						"created_at": data.data.created_at,
						"updated_at": data.data.updated_at,
						"deleted_at": data.data.deleted_at,
						"jsonArray": JSON.parse(data.data.json),
					}

					this.changeAllItemsByItem(setting);
					this.changeItem(setting);
					this.alert('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}

			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(persion: any) {
		this.commonService.storeItem("reseller/setting/deleteWorkspace", persion, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(persion.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}


	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

}