export class PermittedSiteModel {
	id: number;
	client_id: number;
	site_id: number;
	is_active: boolean;
	count: number;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param permited_site
	 */
	constructor(permited_site) {
		this.id = permited_site.id || '';
		this.client_id = permited_site.client_id || '';
		this.site_id = permited_site.site_id || '';
		this.is_active = permited_site.is_active || '';
		this.count = permited_site.count || '';
		this.created_by = permited_site.created_by || '';
		this.updated_by = permited_site.updated_by || '';
		this.created_at = permited_site.created_at || '';
		this.updated_at = permited_site.updated_at || '';

		this.tableName = permited_site.tableName || '';
	}
}
