import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
    User, UserService,
    PosterSchedule, PosterScheduleService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
    selector: 'smm-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
    user: User;
    posterSchedules: PosterSchedule[] = [];
    groupSchedules: Array<any> = [];
    posterCarouselOptions: OwlOptions = {
        loop: false,
        autoWidth: true,
        mouseDrag: true,
        autoplayHoverPause: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: false,
        navSpeed: 700,
        navText: [
            "<i class='fa fa-chevron-left leftIcon'></i>",
            "<i class='fa fa-chevron-right rightIcon'></i>"
        ],
        responsive: {
            0: { items: 1 },
            360: { items: 1 },
            450: { items: 2 },
            720: { items: 3 },
            940: { items: 4 },
            1200: { items: 5 }
        },
        nav: true
    }

    constructor(public userService: UserService,
        private posterScheduleService: PosterScheduleService,
        private dialog: MatDialog,
        private router: Router,
        public location: Location,
        public matDialog: MatDialog) {
        this.userService.user.subscribe(data => this.user = data);
        this.posterScheduleService.libraries.subscribe(data => {
            this.posterSchedules = data;
        });
    }

    ngOnInit() { }

    /** On destroy */
    ngOnDestroy(): void { }

    newPoster(): void {
        this.router.navigateByUrl('/app/smm/new-poster');
    }
}
