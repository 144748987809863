import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringBetweenPipe } from 'src/app/pipes/string/stringBetween.pipe';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { TemplateViewComponent } from './template-view/template-view.component';
import { TemplateMessageFormComponent } from './templateMessage-form/templateMessage-form.component';
import { SidebarStencilComponent } from './sidebarStencil/sidebarStencil.component';
import { BulkImportComponent } from './bulkImport/bulkImport.component';
import { TemplateHistoryComponent } from './template-history/template-history.component';
import { TemplateHistoryViewComponent } from './template-history-view/template-history-view.component';
import { TemplateFormComponent } from './templateForm/templateForm.component';

import { StencilService, TemplateMessageService, TemplateUsageService, ClientSiteService } from 'src/app/@tji/_dbShare';
import { ItrsbotComponent } from './itrsbot/itrsbot.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        TemplateViewComponent,
        TemplateMessageFormComponent,
        SidebarStencilComponent,
        TemplateHistoryComponent,
        TemplateHistoryViewComponent,
        BulkImportComponent,
        TemplateFormComponent,
        ItrsbotComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ListComponent,
        SidebarStencilComponent,
        TemplateViewComponent,
        ItrsbotComponent
    ],
    providers: [
        StencilService,
        TemplateMessageService,
        TemplateUsageService,
        ClientSiteService,
        StringBetweenPipe
    ]
})

export class TemplateImportModule { }
