export var data = [
	{
		"name": "Total Opened Tickets",
		"value": 80
	},
	{
		"name": "Total Closed Tickets",
		"value": 60
	},
	{
		"name": "Total Waiting Tickets",
		"value": 54
	},
	{
		"name": "On Messenger",
		"value": 52
	},
	{
		"name": "On Twitter",
		"value": 35
	},
	{
		"name": "On Whatsapp",
		"value": 30
	},
	{
		"name": "On Youtube",
		"value": 42
	}
];