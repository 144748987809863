import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { PeopleFormComponent } from 'src/app/appAccount/people/form/form.component';

import {
	User, UserService,
	ChatService,
	PeopleService, People, PeopleModel,
	CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'instadm-ticket-item-leftsidenav',
	templateUrl: './leftSidenav.component.html',
	styleUrls: ['./leftSidenav.component.scss']
})

export class LeftSideNavComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	user: User;
	ticket: any;
	people: People;
	baseImage: string = this.commonService.logo_image;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public commonService: CommonService,
		public chatService: ChatService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => this.people = data);
	}

	ngOnInit() {
		// this.getPeople();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.peopleService.changeItem(new PeopleModel({}));
	}

	/** Add / Update Agent */
	newPeople(people = null): void {
		this.peopleService.changeItem((people) ? people : new PeopleModel({}));
		let dialogRef = this.matDialog.open(PeopleFormComponent, {
			width: "600px",
			data: {
				action: (people) ? 'edit' : 'new',
				item: people,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.peopleService.store(formData.value);
						break;
					case 'update':
						this.peopleService.update(id, formData.value);
						break;
				}
			});
	}

	getPeople() {
		if (this.ticket && this.ticket.people_id) {
			this.peopleService.getItem({ 'id': this.ticket.people_id });
		}
	}
}
