export class TemplateMessageDataModel {
	id: number;
	key: string;
	value: string;
	is_default: boolean;

	/**
	 * Constructor
	 *
	 * @param templateMessageData
	 */
	constructor(templateMessageData) {
		this.id = templateMessageData.id || 1;
		this.key = templateMessageData.key || '';
		this.value = templateMessageData.value || '';
		this.is_default = templateMessageData.is_default || false;

	}
}
