export class ChildCommentModel {
    id: number;
    resource_id: number;
    resource_type: string
    comment_id: number;
    client_id: number;
    client_site_id: number;
    title: string;
    description: string;
    rating: number;
    person_id: number;
    person_type: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName: string;
    className: string;
    createdByName?: string;
    updatedByName?: string;
    createdByAvatar?: string;
    resource?: any;
    siteName?: string;
    ticketCode?: string;
    isClosed?: boolean;
    childrenCount?: number;

    /**
     * Constructor
     *
     * @param childComment
     */
    constructor(childComment) {
            this.id = childComment.id || '';
            this.resource_id = childComment.resource_id || '';
            this.resource_type = childComment.resource_type || '';
            this.comment_id = childComment.childComment_id || '';
            this.client_id = childComment.client_id || '';
            this.client_site_id = childComment.client_site_id || '';
            this.title = childComment.title || '';
            this.description = childComment.description || '';
            this.rating = childComment.rating || '';
            this.person_id = childComment.person_id || '';
            this.person_type = childComment.person_type || '';
            this.created_by = childComment.created_by || '';
            this.updated_by = childComment.updated_by || '';
            this.created_at = childComment.created_at || '';
            this.updated_at = childComment.updated_at || '';

            this.tableName = childComment.tableName || '';
    }
}
