import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Agent } from './agent.interface';
import { AgentModel } from './agent_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class AgentService implements Resolve<any> {
	url: string = 'agent/list';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};
	isSelectAgent: boolean = false;

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Agent[]>([]);
	allItems = this.allItemsSource.asObservable();

	public itemSource = new BehaviorSubject<Agent>(new AgentModel({}));
	item = this.itemSource.asObservable();

	public itemIdSource = new BehaviorSubject<Agent>(new AgentModel({}));
	itemId = this.itemIdSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Agent[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	public isAcitveLogoutAgent = new BehaviorSubject<boolean>(false);
	isAcitveLogout = this.isAcitveLogoutAgent.asObservable();

	public allActiveSource = new BehaviorSubject<Array<any>>([]);
	allActiveAgents = this.allActiveSource.asObservable();

	public allSearchSource = new BehaviorSubject<any>(null);
	allSearchAgents = this.allSearchSource.asObservable();

	public allSupervisorSource = new BehaviorSubject<Array<any>>([]);
	allSupervisorAgents = this.allSupervisorSource.asObservable();

	public isAcitveAgent = new BehaviorSubject<boolean>(false);
	isAcitve = this.isAcitveAgent.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.defaultParams.search = '',
				this.resetParams(),
				this.getAllItems(),
				this.getItem(),
				this.getSupervisorAgents()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed AgentService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on AgentService');
	}

	concatlibrary(allItems: Agent[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Agent) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Agent) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: Agent[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Agent[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
	}

	changeItem(item: Agent) {
		this.itemSource.next(item);
	}

	changeItemId(item: Agent) {
		this.itemIdSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Agent[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Agent) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'search': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.changeAllItems(data.data);
					this.changeTotalItem(data.pagnitation.totalResult);
				}
			},
				error => { console.log('Error ::' + error); }
			);
	}

	getAllAgent(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem("agent/activeAgents", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.changeAllItems(data.data);
				}
			},
				error => { console.log('Error ::' + error); }
			);
	}

	getSupervisorAgents() {
		var params = {
			'limit': 200,
			'current': 1,
		}; // = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.allSupervisorSource.next(data.data);
				}
			},
				error => { console.log('Error ::' + error); }
			);
	}
	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data);
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: Agent) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeTotalItem(lists.length);
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
			}
		}
		this.changeTotalItem(allItems.length);
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		var itemId;
		this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
		var id = {
			"id": itemId
		}
		if (itemId > 0) {

			this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new AgentModel({}));
		}
	}

	store(url, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.concatItem(res.data);
					this.changeItem(res.data);
					this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					agent.person_details.designation = data.designation;
					agent.userInfo.email = data.email;
					agent.person_details.mobile = data.mobile;
					agent.name = data.name;
					this.updateAgent(agent)
					this.alert('Success', res.data.message);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	linkSupervisorAgent(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					// let allItems = [];
					// agent.supervisor_id = data.supervisor_id;
					// agent.is_supervisor = 0;
					// if (data.agentName) {
					// 	agent["supervisorAgent"] = data.agentName;
					// } else {
					// 	delete agent["supervisorAgent"];
					// }
					// this.updateAgent(agent);
					this.getAllItems();
					this.alert('Success', res.data.message);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	removeSupervisorAccess(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					agent.supervisor_id = 0;
					agent.is_supervisor = 0;
					this.updateAgent(agent)
					this.alert('Success', res.data.message);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	markAsSupervisor(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					agent.is_supervisor = data.is_supervisor;
					this.updateAgent(agent)
					this.alert('Success', res.data.message);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	statusUpdate(url, data: any, agent = null): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					agent.is_active = data.is_active
					this.updateAgent(agent)
					this.alert('Success', res.data.message);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(url, id) {
		this.commonService.storeItem(url, id, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(id.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	post(url: string, data: any) {
		let alert = (data && data.alert) ? data.alert : null;
		let alertType = (data && data.alertType) ? data.alertType : 'Success';
		this.commonService.storeItem(url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.getItem();
				this.alert(alertType, (alert) ? alert : 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	forceLogout(url, data: any): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.alertService.webShow('info', 'Logout Successfully !!!');
						var agentList = data.userIds.length + " Agent Logout Successfully !!!"
						this.alertService.webShow('info', agentList);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	getUrl(url: string) {
		if (url) {
			this.commonService.hitUrl(url)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new AgentModel({}));
		}
	}

	assignPermission(url: string, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.alert('info', data.data.message);
				} else {
					this.alertService.webErrorShow(data);
				}
				// this.changeItem(data.data);
				// this.alert(alertType, (alert) ? alert : 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		// let newParams: any;
		// this.params
		// 	.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
		// 	.subscribe(data => {
		// 		newParams = data;
		// 		newParams.page = 1;
		// 		newParams.search = input;
		// 		this.changeParams(newParams);
		// 		this.getAllItems();
		// 	});
		this.allSearchSource.next(input);
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	checkAgentHidden(agent): boolean {
		if (!agent.is_active) { return true; }
		else {
			return (agent.liveStatus === 'offline') ? true : false;
		}
	}

	checkAgentDisable(agent): boolean {
		if (agent.allowed_total > 0 && agent.current_total >= agent.allowed_total) {
			return true;
		} else {
			return false;
		}
	}

	checkAgentHasPermission(agent, ticketClassName): boolean {
		var permission: string = null;
		switch (ticketClassName) {
			case "Messenger\\Models\\Messenger": case "messenger_ticket": case "messenger":
				permission = 'manage messenger';
				break;
			case "Whatsapp\\Models\\Whatsapp": case "whatsapp_ticket": case "whatsapp":
				permission = 'manage whatsapp';
				break;
			case "Webchat\\Models\\Webchat": case "webchat_ticket": case "webchat":
				permission = 'manage webchat';
				break;
			case "Twitter\\Models\\Tweet": case "tweet_ticket": case "tweet":
				permission = 'manage twitter';
				break;
			case "Twitterdm\\Models\\Tweetdm": case "tweetdm_ticket": case "tweetdm":
				permission = 'manage twitterdm';
				break;
			case "Youtube\\Models\\Youtube": case "youtube_ticket": case "youtube":
				permission = 'manage youtube';
				break;
			case "Instagram\\Models\\Instagram": case "instagram_ticket": case "instagram":
				permission = 'manage instagram';
				break;
			case "Instadm\\Models\\Instadm": case "instadm_ticket": case "instadm":
				permission = 'manage instadm';
				break;
			default:
				break;
		}
		if (agent && permission && agent.permissionArray && agent.permissionArray.length > 0 && agent.permissionArray.indexOf(permission) !== 1) {
			return true;
		} else {
			return false;
		}
	}

	updateAgent(agent: any) {
		let allItems = [];
		let allSupervisorAgents = [];
		var isChanged = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems.length > 0) {
			allItems.forEach(element => {
				if (element.id == agent.id) {
					element = agent;
					isChanged = true;
				}
			});
			if (!isChanged) {
				allItems.push(agent);
				this.changeAllItems(allItems);
			} else {
				this.changeAllItems(allItems);
			}
		}

		this.allSupervisorAgents.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allSupervisorAgents.length > 0) {
			allSupervisorAgents.forEach(element => {
				if (element.id == agent.id) {
					element = agent;
					isChanged = true;
				}
			});
			if (!isChanged) {
				allSupervisorAgents.push(agent);
				this.changeAllItems(allItems);
			} else {
				this.changeAllItems(allItems);
			}
			this.allSupervisorSource.next(allItems);
		}
	}

	// get active agent list

	getActiveAgent(id: number): void {
		var data = {
			"client_site_id": id
		}
		this.commonService.storeItem('agent/agentsforticket', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					this.allActiveSource.next(res.data);
				} else {
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}


	//Agents Count update

	changeIsagent(updateAgent: any, evenName: string) {
		var oldAgentsCount = [];
		var allAgentsList = [];
		this.allItems.subscribe(data => allAgentsList = data);

		if (allAgentsList && allAgentsList.length > 0) {
			allAgentsList.forEach(agent => {
				if (agent.id == updateAgent.userInfo.person_id) {
					agent.liveStatus = updateAgent.isOnline ? 'online' : 'offline'
					agent.availability = updateAgent.availability;
				}
			});
			this.changeAllItems(allAgentsList);
		}
	}

	changeIsavailability(updateAgent: any, evenName: string) {
		var oldAgentsCount = [];
		var allAgentsList = [];
		this.allItems.subscribe(data => allAgentsList = data);

		if (allAgentsList && allAgentsList.length > 0) {
			allAgentsList.forEach(agent => {
				if (agent.id == updateAgent.userInfo.person_id) {
					agent.availability = updateAgent.availability;
				}
			});
			this.changeAllItems(allAgentsList);
		}
	}

	// get active agent list

	ActiveAgentTick(url: any, data: any): void {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				if (res.success) {
					localStorage.setItem('availability', data.availability);
					this.isAcitveAgent.next(true);
					this.alert('Success', 'Updated successfully !!!');
				} else {
					this.isAcitveAgent.next(true);
					this.alertService.webErrorShow(res);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	// demo(url, data: any, agent = null): void {
	// 	this.commonService.storeItem(url, data, true, 'optionOne')
	// 		.pipe(untilDestroyed(this, 'unSubscribe'))
	// 		.subscribe(res => {
	// 			if (res.success) {
	// 				console.log('Success ::' + data.data);
	// 			} else {
	// 				this.alertService.webErrorShow(data);
	// 			}
	// 		},
	// 			error => {
	// 				console.log('Error ::' + error);
	// 				this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
	// 			}
	// 		);
	// }

}
