import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
import { AssignResellerComponent } from './../assignReseller/assignReseller.component';
import * as moment from 'moment-timezone';

import {
    User, UserService,
    ClientService, Client, ClientModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'client-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
    loader: boolean = true;
    subLoader: boolean = false;
    authUser: User;
    clientsAll: Client[] = [];
    clients: Client[] = [];
    param: any;
    clientResponse: any;
    dataSource = new MatTableDataSource(this.clients);
    displayedColumns = ['code', 'avatar', 'name', 'contact','dueDay','status', 'action'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    // MatPaginator Output
    pageEvent: PageEvent;
    dataLength: number = 0;
    downloadLoader: boolean = false;

    constructor(public userService: UserService,
        public clientService: ClientService,
        private snackBar: MatSnackBar,
        private router: Router,
        public matDialog: MatDialog,
        private orderByPipe: OrderByPipe) {
        this.userService.user.subscribe(data => this.authUser = data);
        this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
            if(data !== this.clientsAll) { this.subLoader = false; }
            this.clientsAll = data;
            this.clients = data; //this.orderByPipe.transform(data, '-id');
            this.dataSource = new MatTableDataSource(this.clients);
            this.dataSource.sort = this.sort;
        });
        this.clientService.downloadLoader.pipe(untilDestroyed(this)).subscribe(data => this.downloadLoader = data);
    }

    ngOnInit() {
        this.doLoader();
        this.dataInit();
    }

    /** On destroy */
    ngOnDestroy(): void {
        this.clientService.unSubscribeFilter();
    }

    doLoader() {
        setTimeout(() => {
            this.loader = !this.loader;
        }, 5000);
    }

    setSubLoader() {
        this.subLoader = true;
        setTimeout(() => {
            this.subLoader = false;
        }, 5000);
    }

    dataInit() {
        this.clientService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
        this.clientService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
    }

    /** Add / Update Client */
    newClient(client = null): void {
        this.clientService.changeItem((client) ? client : new ClientModel({}));
        let dialogRef = this.matDialog.open(FormComponent, {
            width: "600px",
            data: {
                action: (client) ? 'edit' : 'new',
                item: client,
            }
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) { return; }
                const actionType: string = response[0];
                const formData: UntypedFormGroup = response[1];
                formData.value.username = formData.value.email;
                if (formData.value.expire_at) {
                    var date = new Date(formData.value.expire_at).toLocaleDateString() //.toString("yyy-mm-dd");
                    formData.value.expire_at =  moment(date).format('YYYY-MM-DD')// date.toString();
                }
                const id: number = (response[2]) ? response[2] : null;
                switch (actionType) {
                    case 'new':
                        this.clientService.store("reseller/client/add",formData.value);
                        break;
                    case 'update':
                        formData.value.id = id;
                        this.clientService.update("reseller/client/update", formData.value);
                        break;
                    case 'delete':
                        this.deleteClient(client);
                        break;
                }
            });
    }

    /** Delete Social Site */
    deleteClient(client: Client): void {
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'delete',
                message: 'Are you sure you want to delete?',
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                this.clientService.destroy("reseller/client/delete", client.id);
            }
        });
    }

    statusChange(id, event, client) {
        // this.clientService.update(id, { 'is_active': event });

        var is_active;
		if (event == false) {
			is_active = 0;
		} else {
			is_active = 1;
		}

		this.clientService.statusUpdate("reseller/client/status",{ "id": id, 'is_active': is_active }, client);

        // setTimeout(() => {
        // this.snackBar.openFromComponent(AlertComponent, {
        //     duration: 500000,
        //     panelClass: ['snackBarSuccess']
        // });
        // this.snackBar.open('Update Successfully', 'Close', {
        //     panelClass: ['snackBarSuccess']
        // });
        // }, 300);
    }

    assignReseller(client: Client) {
        let dialogRef = this.matDialog.open(AssignResellerComponent, {
            width: "600px",
            data: {
                action: 'new',
                item: client,
            }
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) { return; }
                const formData: UntypedFormGroup = response[1];
                this.clientService.update(client.id, formData.value);
            });
    }

}
