import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { UserService, User, CommonService } from 'src/app/@tji/_dbShare';

@Component({
    selector: 'app-navbar-style-four',
    templateUrl: './navbar-style-four.component.html',
    styleUrls: ['./navbar-style-four.component.scss']
})
export class NavbarStyleFourComponent implements OnInit {

    authUser: User;
    isAuth: boolean = true;
    user: User;
    url: string = environment.host + "/app-chat/858755fe879200a54ba7e3caefcfca7ff4ca2980e07a069e145f2cd1d8c2d8d7";
    urlSafe: SafeResourceUrl;
    languages: Array<any> = environment.languages;

    constructor(public userService: UserService,
                public commonService: CommonService,
                public sanitizer: DomSanitizer,
                public translate: TranslateService) {
        this.isAuth = this.userService.authCheck();
        this.authUser = this.userService.authUser();
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        
        this.translate.addLangs(['en', 'ar']);
        this.setCurrentLang();
    }

    ngOnInit() {
       
    }

    ngOnDestroy() {
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    setCurrentLang() {
        let cookieLanguage: string = this.commonService.getLanguage();
        let language : string = (cookieLanguage) ? cookieLanguage : this.translate.currentLang;
        language = (language) ? language : this.translate.getBrowserLang();
        let lang: string = language.match(/en|ar/) ? language : 'en';
        if(!cookieLanguage || cookieLanguage === null) { this.commonService.setLanguage(lang); }
        if(lang) { this.translate.use(lang); }
        if(!this.translate.currentLang) {
          this.translate.use('en')
          this.translate.setDefaultLang('en');
          this.commonService.setLanguage('en');
        }
    }

    getCurrentLangIcon(): string {
        let cookieLanguage: string = this.commonService.getLanguage();
        let language : string = (cookieLanguage) ? cookieLanguage : this.translate.currentLang;
        if(language && language === 'ar') {
            document.querySelector('html')?.setAttribute('dir', 'rtl');
          return 'fa-ar';
        } else {
            document.querySelector('html')?.removeAttribute('dir');
          return 'fa-en';
        }
    }

    setCurrentLanguage(lang: string) {
        this.commonService.setLanguage(lang);
        this.translate.use(lang);
    }

    renewUser() {
        if(this.authUser && this.authUser.id) {
          var token = this.authUser.token;
          if(token) {
              this.userService.setCookieToken(token);
          }
          var roleNames = this.authUser.roleNames;
          if(roleNames && roleNames.length > 0) {
              this.userService.setCookieRoles(roleNames);
          }
          var permissionNames = this.authUser.permissionNames;
          if(permissionNames && permissionNames.length > 0) {
              this.userService.setCookiePermissions(permissionNames);
          }
        }
        this.user = JSON.parse(localStorage.getItem('tji_user'));
        this.userService.renewUser();
    }


}
