import { NgModule } from '@angular/core';
import { FbTemplateImportModule } from './fb-template-import.module';
import { FbTemplateRouteModule } from './fb-template-route.module';

@NgModule({
	declarations: [ ],
	imports: [
		FbTemplateImportModule,
		FbTemplateRouteModule,
	],
})

export class FbTemplateModule { }
