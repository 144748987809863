import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	PeopleService, People, PeopleModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'people-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class PeopleFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	people: People;
	action: string;
	peopleForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public matDialogRef: MatDialogRef<PeopleFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.people = data;
			this.peopleForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New People';
				break;
			case 'edit':
				this.dialogTitle = 'Edit People';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		var name = ''
		if (this.people && this.people.detail && this.people.detail.fullname) {
			name = this.people.detail.fullname?this.people.detail.fullname:'';
		}else{
			name = this.people.name?this.people.name:'';
		}
		
		return this._formBuilder.group({
			fullname: [ name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
			// designation: [this.people?.personalInfo?.designation || null, [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
			// email: [this.people?.personalInfo?.email || null],
			// mobile: [this.people?.personalInfo?.mobile || null, [Validators.required, Validators.minLength(4), Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
			// address: [this.people?.personalInfo?.address || null, [Validators.minLength(4), Validators.maxLength(120)]],
		});
	}

}
