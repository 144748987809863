import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import {
	User, UserService,
	TemplateFailedService, TemplateFailed, TemplateUsageService, TemplateUsage
} from 'src/app/@tji/_dbShare';
import { Location } from '@angular/common';
import { TemplateExportExcelComponent } from '../template-export-excel/template-export-excel.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FaliedReasonComponent } from '../falied-reason/falied-reason.component';
import { ExportTemplateComponent } from '../export-template/export-template.component';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'template-usage-failed',
	templateUrl: './template-failed.component.html',
	styleUrls: ['./template-failed.component.scss'],
	providers: [OrderByPipe]
})

export class TemplateFailedComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	templateFail: TemplateFailed[] = [];
	templateFailed: TemplateFailed;
	param: any;
	routeParams: any | null;

	dataSource = new MatTableDataSource(this.templateFail);
	displayedColumns = ['date', 'clientName', 'code', 'sentby', 'templateName', 'to', 'response_json'];

	templateUsage: TemplateUsage;
	templateUsageParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public templateFailedService: TemplateFailedService,
		public templateUsageService: TemplateUsageService,
		public matDialog: MatDialog,
		public location: Location,
		private route: ActivatedRoute,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.templateUsageService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateUsage = data);
		this.templateFailedService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.templateFailedService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.templateFailedService.item.pipe(untilDestroyed(this)).subscribe(data => this.templateFailed = data);
		this.templateFailedService.loader.pipe(untilDestroyed(this)).subscribe(data => this.loader = data);
		this.templateFailedService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.templateFail) { this.subLoader = false; }
			this.templateFail = data;
			this.dataSource = new MatTableDataSource(this.templateFail);
			this.dataSource.sort = this.sort;
		});
		this.routeParams = this.route.snapshot.paramMap.get('templateUsageId');
	}

	ngOnInit() {
		// this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.templateFailedService.unSubscribe();
		this.templateFailedService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	exportExcel() {
		this.templateFailedService.resetParams();
		if (this.routeParams) {

			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				data: {
					type: 'info',
					message: 'Are you sure want to Export ?'
				},
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result.data) {
					let dateFormat: any = {
						"limit": 500,
						"current": 1,

					}
					this.templateFailedService.exportReport(dateFormat);
				}
			});
		} else {
			let confirmDialogRef = this.matDialog.open(ExportTemplateComponent, {
				data: {
					type: 'info',
				},
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.value) {
					var dt = new Date(result.value._d);
					let dateFormat: any = {
						"month": dt.getMonth() + 1,
						"year": dt.getFullYear(),
					}
					this.templateFailedService.exportReport(dateFormat);
				}
			});

		}

	}

	/** Send Template Message to Lead */
	viewFaliedReason(template: any): void {
		let templateDialogRef = this.matDialog.open(FaliedReasonComponent, {
			disableClose: false,
			width: '600',
			data: template
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {

			}
		});
	}





}
