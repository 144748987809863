import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { OpenTicketRouteModule } from './openTicket-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';

import { AssignFormComponent } from './assignForm/assignForm.component';
import { ConversationHistoryComponent } from './conversationHistory/conversationHistory.component';
import { ConversationInstagramHistoryComponent } from './conversationInstagramHistory/conversationInstagramHistory.component';
import { ConversationYoutubeHistoryComponent } from './conversationYoutubeHistory/conversationYoutubeHistory.component';

import { OpenTicketService, ClientSiteService } from 'src/app/@tji/_dbShare';
import { TeamViewComponent } from './team-view/team-view.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        AssignFormComponent,
        ConversationHistoryComponent,
        ConversationInstagramHistoryComponent,
        ConversationYoutubeHistoryComponent,
        TeamViewComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        OpenTicketRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
    ],
    providers: [
        OpenTicketService,
        ClientSiteService
    ]
})

export class OpenTicketModule { }
