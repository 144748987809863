import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	TeamService, Team,
	IntractiveService, Intractive,
	ModalIntractiveGroupService, ModalIntractiveGroup
} from 'src/app/@tji/_dbShare';


@UntilDestroy()
@Component({
	selector: 'team-item-itrs',
	templateUrl: './itrs.component.html',
	styleUrls: ['./itrs.component.scss']
})

export class ItrsComponent implements OnInit, OnDestroy {
	authUser: User;
	team: Team;

	intractives: Intractive[] = [];
	intractiveParam: any;

	modalIntractiveGroups: ModalIntractiveGroup[] = [];
	modalIntractiveGroupParam: any;

	constructor(public userService: UserService,
		public teamService: TeamService,
		public intractiveService: IntractiveService,
		public modalIntractiveGroupService: ModalIntractiveGroupService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		this.teamService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.team = data;
			if (data && data.id) {
				this.getModalIntractiveGroups();
			}
		});
		this.intractiveService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.intractives = data);
		this.intractiveService.params.pipe(untilDestroyed(this)).subscribe(data => this.intractiveParam = data);
		this.modalIntractiveGroupService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.modalIntractiveGroups = data);
		this.modalIntractiveGroupService.params.pipe(untilDestroyed(this)).subscribe(data => this.modalIntractiveGroupParam = data);
	}

	ngOnInit() {
		this.getIntractives();
		this.getModalIntractiveGroups();
	}

	/** On destroy */
	ngOnDestroy(): void { 
		this.teamService.unSubscribeFilter();
		this.intractiveService.unSubscribeFilter();
		this.modalIntractiveGroupService.unSubscribeFilter();
	}

	getIntractives() {
		this.intractiveParam.all = 1;
		this.intractiveParam.category_filter = 'ticket-survey,survey,one-time-survey';
		this.intractiveParam.with = 'intractiveCategory,intractiveGroups';
		this.intractiveService.getAllItems();
	}

	getModalIntractiveGroups() {
		if (this.team && this.team.id) {
			this.modalIntractiveGroupParam.resource_id = (this.team && this.team.id) ? this.team.id : null;
			this.modalIntractiveGroupParam.resource_type = (this.team && this.team.tableName) ? this.team.tableName : 'team';
			this.modalIntractiveGroupService.getAllItems();
		}
	}

	isIntractiveGroupMapped(intractiveGroup): boolean {
		if (this.modalIntractiveGroups && this.modalIntractiveGroups.length > 0) {
			return (this.modalIntractiveGroups.find(x => x.intractive_group_id === intractiveGroup.id)) ? true : false;
		} else {
			return false;
		}
	}

	syncModalIntractiveGroup(intractiveGroup, status: boolean) {
		let data = {
			resource_id: this.team.id,
			resource_type: this.team.tableName,
			intractive_group_id: intractiveGroup.id,
		};
		if (status) {
			this.modalIntractiveGroupService.store(data);
		}
		if (!status) {
			this.modalIntractiveGroupService.removeByPost(data, 'modal-intractive-group-destroy');
		}
	}

}
