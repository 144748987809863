import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ClientSiteService, ClientSite,
	ClientSiteErrorService, ClientSiteError,
	CommonService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'socialsite-logview',
	templateUrl: './logView.component.html',
	styleUrls: ['./logView.component.scss']
})

export class LogViewComponent implements OnInit, OnDestroy, AfterViewInit {

	loader: boolean = true;
	dialogTitle: string = 'Recent Logs';
	authUser: User;

	errors: ClientSiteError[] = [];
	clientSiteErrors: ClientSiteError[] = [];
	param: any;
	clientSite: ClientSite;
	action: string;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public clientSiteErrorService: ClientSiteErrorService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<LogViewComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientSiteErrorService.resetParams();
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
		this.clientSiteErrorService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.clientSiteErrorService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.errors = data;
			this.clientSiteErrors = data.filter(x => {
				return (x.client_site_id && this.clientSite && x.client_site_id === this.clientSite.id) ? true : false;
			});
		});
	}

	ngOnInit() {
		this.doLoader();
		this.getClientSiteErrors();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.clientSiteErrorService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) { }

	getClientSiteErrors() {
		if (this.clientSite && this.clientSite.id) {
			this.param.client_id = this.clientSite.client_id;
			this.param.client_site_id = this.clientSite.id;
			this.clientSiteErrorService.getAllItems();
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.clientSiteErrorService.unSubscribe();
	}

	clearLog() {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to clear Logs?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.clientSiteErrorService.destroyAllByClientSiteId(this.clientSite.id);
			}
		});
	}

}
