import { Component, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Contact, ContactService } from 'src/app/@tji/_dbShare/';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElementRef } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    myRecaptcha: boolean
    contactForm: UntypedFormGroup;
    apiError: String;
    siteKey: string = "6Lcm11EcAAAAAI4gejXyjYns84k915GPSY8O6Rht";

    constructor(private http: HttpClient,
        private _formBuilder: UntypedFormBuilder,
        private contactService: ContactService,
        private router: Router,
        private myElement: ElementRef,
    ) { }

    ngOnInit(): void {
        this.createForm();
        this.clearApiError();
    }

    createForm() {
        this.contactForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.maxLength(30)]],
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern('^[a-zA-Z \-\']+')]],
            message: ['', Validators.required],
            subject: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(15),]],
            phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(15)]],
            recaptcha: ['', Validators.required],
        });
    }


    sendContactMail(contactData) {
        contactData['time'] = new Date().toLocaleString();
        let sendData: any = { 'param': btoa(JSON.stringify(contactData)) };
        this.contactService.sendContactMail('contact-submit', sendData).subscribe(data => {
            if(data && data && data.success && data.message) {
                if(data.success === 'ok') { 
                    this.contactForm.reset();
                    this.router.navigate(['/contact']);
                }
                if (data.error === 'error') { this.apiError = data.message; }
            }
        }, error => {
            this.apiError = error;
        });
    }

    clearApiError() {
        if (this.apiError && this.apiError !== null) {
            setTimeout(() => {
                this.apiError = null;
            }, 5000);
        }
    }

    onScriptLoad() {
        // console.log('Google reCAPTCHA loaded and is ready for use!')
    }

    onScriptError() {
        // console.log('Something went long when loading the Google reCAPTCHA')
    }

    scrollTo() {
        let el = this.myElement.nativeElement.ownerDocument.getElementById('contactFormView');
        el.scrollIntoView();
    }
}