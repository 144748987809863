import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommentsComponent } from './comments/comments.component';
import { FlagsComponent } from './flags/flags.component';
import { AgentReportComponent } from './agent-report/agent-report.component';
import { AgentAbandonchartComponent } from './agent-abandonchart/agent-abandonchart.component';
import { AgentClosedChartComponent } from './agent-closed-chart/agent-closed-chart.component';
import { AgentReAssignChartComponent } from './agent-re-assign-chart/agent-re-assign-chart.component';
import { MediaRightbarComponent } from './media-rightbar/media-rightbar.component';
import { WhatsappTemplateMessageViewComponent } from './whatsappTemplateMessageView/whatsappTemplateMessageView.component';
import { WhatsappIntreactiveMessageViewComponent } from './whatsappIntreactiveMessageView/whatsappIntreactiveMessageView.component';
import { EditColorFormComponent } from './editColorForm/editColorForm.component';
import { ChatItemComponent } from './chatItem/chatItem.component';
import { PusherEventComponent } from './pusherEvent/pusherEvent.component';

@NgModule({
    declarations: [
        CommentsComponent,
        FlagsComponent,
        AgentReportComponent,
        AgentAbandonchartComponent,
        AgentClosedChartComponent,
        AgentReAssignChartComponent,
        MediaRightbarComponent,
        WhatsappTemplateMessageViewComponent,
        WhatsappIntreactiveMessageViewComponent,
        EditColorFormComponent,
        ChatItemComponent,
        PusherEventComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommentsComponent,
        FlagsComponent,
        AgentReportComponent,
        MediaRightbarComponent,
        WhatsappTemplateMessageViewComponent,
        WhatsappIntreactiveMessageViewComponent,
        EditColorFormComponent,
        ChatItemComponent,
        PusherEventComponent,
    ],
    providers: []
})

export class UtilsModule { }
