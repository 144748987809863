import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { RClientComponent } from './client/client.component';
import { SocialMediaComponent } from './socialMedia/socialMedia.component';
import { SocialMediaListComponent } from './socialMediaList/socialMediaList.component';
import { MainSocialsiteComponent } from './mainSocialsite/mainSocialsite.component';
import { MainItrsbotComponent } from './mainItrsbot/mainItrsbot.component';
import { MainTemplateComponent } from './mainTemplate/mainTemplate.component';

import { RClientService, RClientSiteService, SiteService, RSiteService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: 'client',
		component: RClientComponent,
		resolve: { data: RClientService },
	},
	{
		path: ':clientId/list',
		component: MainSocialsiteComponent,
		// resolve: { data: RClientSiteService },
	},
	{
		path: ':clientId/itrsbot',
		component: MainItrsbotComponent,
		// resolve: { data: RClientSiteService },
	},
	{
		path: ':clientId/itrsbot/:clientSiteId',
		component: MainItrsbotComponent,
		// resolve: { data: RClientSiteService },
	},
	{
		path: ':clientId/template',
		component: MainTemplateComponent,
		// resolve: { data: RClientSiteService },
	},
	{
		path: ':clientId/template/:clientSiteId',
		component: MainTemplateComponent,
		// resolve: { data: RClientSiteService },
	},
	{
		path: 'media',
		component: SocialMediaComponent,
		resolve: { data: RSiteService },
	},
	{
		path: ':mediaId/socialsites',
		component: SocialMediaListComponent,
	},
	// {
	// 	path: ':id',
	// 	component: ItemComponent,
	// 	resolve: { data: RClientSiteService },
	// },
	{ path: '**', redirectTo: '/reseller/socialsite/client' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class RSocialSiteRouteModule { }
