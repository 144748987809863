import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsumerRouteModule } from './consumer-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';
import { FormLinkGroupComponent } from './formLinkGroup/formLinkGroup.component';

import { ConsumerComponent } from './itemView/consumer/consumer.component';
import { ContactComponent } from './itemView/contact/contact.component';
import { TemplateFormComponent } from './templateForm/templateForm.component';

import { ConsumerGroupService } from 'src/app/@tji/_dbShare';
import { UtilsModule } from "../utils/utils.module";

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        FormComponent,
        FormLinkGroupComponent,
        ConsumerComponent,
        ContactComponent,
        TemplateFormComponent,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ItemComponent,
    ],
    providers: [
        ConsumerGroupService
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        ConsumerRouteModule,
        UtilsModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})

export class ConsumerModule { }
