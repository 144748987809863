import { Menu, MenuModel } from '../menu';

export class MenuGroupModel {
	id: number;
	title: string;
	slug: string;
	type: string;
	translate: string;
	icon: string;
	url: string;
	route: string;
	external_url: string;
	classes: string;
	function: string;
	order: number;
	role: string;
	permission: string;
	json: string;
	is_hidden: boolean;
	is_active: boolean;
	is_exact_match: boolean;
	is_open_new_tab: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	exactMatch: boolean;

	menus?: Menu[];
	isPermitted?: boolean;
	children?: Menu[];
	roles?: Array<any>;
	permissions?: Array<any>;
	image?: string;

	/**
	 * Constructor
	 *
	 * @param menuGroup
	 */
	constructor(menuGroup) {
		this.id = menuGroup.id || '';
		this.title = menuGroup.title || '';
		this.slug = menuGroup.slug || '';
		this.type = menuGroup.type || '';
		this.translate = menuGroup.translate || '';
		this.icon = menuGroup.icon || '';
		this.url = menuGroup.url || '';
		this.route = menuGroup.route || '';
		this.external_url = menuGroup.external_url || '';
		this.classes = menuGroup.classes || '';
		this.function = menuGroup.function || '';
		this.order = menuGroup.order || '';
		this.role = menuGroup.role || '';
		this.permission = menuGroup.permission || '';
		this.json = menuGroup.json || '';
		this.is_hidden = menuGroup.is_hidden || '';
		this.is_active = menuGroup.is_active || '';
		this.is_exact_match = menuGroup.is_exact_match || '';
		this.is_open_new_tab = menuGroup.is_open_new_tab || '';
		this.created_by = menuGroup.created_by || '';
		this.updated_by = menuGroup.updated_by || '';
		this.created_at = menuGroup.created_at || '';
		this.updated_at = menuGroup.updated_at || '';

		this.exactMatch = menuGroup.exactMatch || false;
	}
}
