import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';

import { RClientSiteService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: RClientSiteService },
	},
	// {
	// 	path: ':id',
	// 	component: ItemComponent,
	// 	resolve: { data: RClientSiteService },
	// },
	{ path: '**', redirectTo: '/reseller/analytics' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class AnalyticsRouteModule { }
