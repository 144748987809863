import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	Itrsbot, ItrsbotService,
	Agent, AgentService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'itrsbot-assign-itrsbot',
	templateUrl: './assignItrsbot.component.html',
	styleUrls: ['./assignItrsbot.component.scss']
})

export class AssignItrsbotComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	action: string;
	assignItrsbotForm: UntypedFormGroup;

	agentParam: any;
	agents: Agent[] = [];
	itrsbot: Itrsbot;

	constructor(public userService: UserService,
		public agentService: AgentService,
		public matDialogRef: MatDialogRef<AssignItrsbotComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
		this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { 
	this.agentService.unSubscribeFilter();
	}

	getAgents() {
		this.agentParam.all = 1;
		this.agentService.getAllItems();
	}

	getInit(data) {
		// Set the defaults
		this.itrsbot = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Agent to Assign Itrsbot';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Agent to Change Itrsbot';
				break;
			default:
				break;
		}
		this.assignItrsbotForm = this.createForm();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			user_id: [null, [Validators.required]],
			itrsbot_id: [(this.itrsbot && this.itrsbot.id) ? this.itrsbot.id : null, [Validators.required]],
			client_id: [(this.itrsbot && this.itrsbot.client_id) ? this.itrsbot.client_id : null, [Validators.required]]
		});
	}

	changeAgent(agentId) {
		let selectedAgent: any = this.agents.filter(x => x.id === agentId)[0];
	}

}
