import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentPendingComponent } from './payment-pending/payment-pending.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
const routes = [
	{
		path: '',
		component: MainComponent
	},
	{
		path: 'success',
		component: PaymentSuccessComponent
	},
	{
		path: 'pending',
		component: PaymentPendingComponent
	},
	{
		path: 'failed',
		component: PaymentFailedComponent
	},
	{ path: '**', redirectTo: '/app/ai-collections' },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AiCollectionRoutingModule { }
