export class MediaModel {
    _id: number;
    user_id: number;
    client_id: number;
    name: string;
    file_name: string;
    mime: string;
    type: string;
    url: string;
    size: string;
    is_active: boolean;
    is_favorite: boolean;
    shared_by: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    sharedWith: any;
    tableName?: string;
    sharedByName?: string;
    sharedId: number;
    isSelected: boolean;

	/**
	 * media
	 *
	 * @param media
	 */
	constructor(media) {
		this._id = media._id || '';
		this.user_id = media.user_id || '';
		this.client_id = media.client_id || '';
		this.name = media.name || '';
		this.file_name = media.file_name || '';
		this.mime = media.mime || '';
		this.type = media.type || '';
		this.url = media.url || '';
        this.size = media.size || '';
		this.is_active = media.is_active || '';
        this.is_favorite = media.is_favorite || '';
        this.shared_by = media.shared_by || '';
		this.created_by = media.created_by || '';
		this.updated_by = media.updated_by || '';
		this.created_at = media.created_at || '';
		this.updated_at = media.updated_at || '';
        this.sharedWith = media.sharedWith || '';
        this.sharedId = media.sharedId || '';
        this.isSelected = media.isSelected || false;
	}
}
