export class PersonSettingModel {
	id: number;
	person_id: number;
	person_type: string;
	setting_field_id: number;
	value: string;
	created_at: Date;
	updated_at: Date;

	tableName?: string;
	is_editable?: boolean;
	is_client_editable?: boolean;
	is_agent_editable?: boolean;

	/**
	 * Constructor
	 *
	 * @param personSetting
	 */
	constructor(personSetting) {
		this.id = personSetting.id || '';
		this.person_id = personSetting.person_id || '';
		this.person_type = personSetting.person_type || '';
		this.setting_field_id = personSetting.setting_field_id || '';
		this.value = personSetting.value || '';
		this.created_at = personSetting.created_at || '';
		this.updated_at = personSetting.updated_at || '';

		this.is_editable = personSetting.is_editable || false;
		this.is_client_editable = personSetting.is_client_editable || false;
		this.is_agent_editable = personSetting.is_agent_editable || false;
	}
}
