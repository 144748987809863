import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel,
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-api-form',
	templateUrl: './apiForm.component.html',
	styleUrls: ['./apiForm.component.scss']
})

export class ApiFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Change API URL';
	intractiveGroup: IntractiveGroup;
	action: string;
	urlForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<ApiFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.urlForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Add Site URL';
				break;
			case 'edit':
				this.dialogTitle = 'Update API URL';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			text: [this.intractiveGroup.text || null, [Validators.required]],
		});
	}

}
