import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

import {
	User, UserService,
	ClientSite, ClientSiteService,
	FlagService, Flag, FlagModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'flag-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	@ViewChild('select') select: MatSelect;
	dialogTitle: string = 'Create';
	authUser: User;
	flag: Flag;
	action: string;
	flagForm: UntypedFormGroup;

	clientSiteParam: any;
	clientSites: ClientSite[] = [];
	client_id: number = null;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public flagService: FlagService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientSiteService.params.pipe(untilDestroyed(this)).subscribe(data => this.clientSiteParam = data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.clientSites = data);
		this.flagService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.flag = data;
			this.flagForm = this.createForm();
		});
	}

	ngOnInit() {
		this.getClientSites();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getClientSites() {
		this.clientSiteParam.all = 1;
		this.clientSiteParam.only = 'account_name,client_id,site_id,id';
		this.clientSiteService.getAllItems();
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Flag';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Flag';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
	var clientSiteId = []
	this.flag.client_site_id.forEach(element => {
		if (element) {
			clientSiteId.push(element.id);
		}
	});
		return this._formBuilder.group({
			name: [this.flag.name || null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
			client_site_id: [clientSiteId || null, [Validators.required]],
			description: [this.flag.description || null],
		});
	}

	changeClientSite(clientSiteId) {
		let selectedClientSite: any = this.clientSites.filter(x => x.id === clientSiteId)[0];
		this.client_id = selectedClientSite.client_id;
		this.flagForm.patchValue({ client_id: selectedClientSite.client_id });
	}
	allSelected = false;
	toggleAllSelection() {
		if (this.allSelected) {
			this.select.options.forEach((item: MatOption) => item.select());
		} else {
			this.select.options.forEach((item: MatOption) => item.deselect());
		}
	}
	optionClick() {
		let newStatus = true;
		this.select.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
			}
		});
		this.allSelected = newStatus;
	}

}
