import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel,
	ConsumerService, Consumer, ConsumerModel, ClientSiteService,
	FbTemplateMessageService, FbTemplateMessage, ClientSite, AlertService
} from 'src/app/@tji/_dbShare';

import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, TAB, SPACE } from '@angular/cdk/keycodes';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';


@UntilDestroy()
@Component({
  selector: 'app-falied-reason',
  templateUrl: './falied-reason.component.html',
  styleUrls: ['./falied-reason.component.scss']
})
export class FaliedReasonComponent implements OnInit, OnDestroy {
  dialogTitle: string = 'Response From Whatsapp';
  action: string;
  data: Array<any> = [];
  faliedReason: any  
  readonly separatorKeysCodes = [TAB, COMMA] as const;

  constructor(public userService: UserService,
    public fbTemplateMessageService: FbTemplateMessageService,
    public matDialogRef: MatDialogRef<FaliedReasonComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private alertService: AlertService,) {
    this.getInit(_data);
    this.faliedReason = _data;
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
	getInit(data) {
		// Set the defaults
		this.action = "new";
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Response From Whatsapp';
				break;
			case 'edit':
				this.dialogTitle = 'Response From Whatsapp';
				break;
			default:
				break;
		}
	}

}
