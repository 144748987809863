export class RTemplateSuccessModel {
    id:number;
    template_id: number;
    client_id: number;
    template_usage_id: number;
    client_site_id: number;
    client_name: string;
    template_name: string;
    to: number;
	json: string;
    created_by:number;
    updated_by:number;
    created_at:Date;
    updated_at:Date;

    template?:any;
    allUsers?:Array<any>;
    failedUsers?: Array<any>;
    templateCode?: string;
    jobcodee?: string;

    /**
     * Constructor
     *
     * @param templateSuccess
     */
    constructor(templateSuccess) {
            this.id = templateSuccess.id || '';
            this.template_id = templateSuccess.template_id || '';
            this.client_id = templateSuccess.client_id || '';
            this.template_usage_id = templateSuccess.template_usage_id || '';
            this.client_site_id = templateSuccess.client_site_id || '';
            this.client_name = templateSuccess.client_name || '';
            this.template_name = templateSuccess.template_name || '';
            this.to = templateSuccess.to || '';
            this.json = templateSuccess.json || '';
    }
}
