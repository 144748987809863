import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbandonTicketRouteModule } from './abandonTicket-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { AgentInfoComponent } from './agentInfo/agentInfo.component';
import { AssignFormComponent } from './assignForm/assignForm.component';

import { AbandonService, ClientSiteService, AgentService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        AgentInfoComponent,
        AssignFormComponent,
    ],
    imports: [
        AppShareModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AbandonTicketRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
    ],
    providers: [
        AbandonService,
        ClientSiteService,
        AgentService
    ]
})

export class AbandonTicketModule { }
