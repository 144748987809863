import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { InitiateMessageFormComponent } from 'src/app/appAccount/agentDashboard/initiateMessageForm/initiateMessageForm.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import {
	User, UserService,
	MessengerTicketService, MessengerTicket,
	TweetTicketService, TweetTicket,
	TweetdmTicketService, TweetdmTicket,
	WhatsappTicketService, WhatsappTicket, WhatsappService,
	WebchatTicketService, WebchatTicket, WebchatService,
	YoutubeTicketService, YoutubeTicket,
	InstagramTicketService, InstagramTicket,
	InstadmTicketService, InstadmTicket,
	ChatService, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'ticket-initiate-message',
	templateUrl: './initiateMessage.component.html',
	styleUrls: ['./initiateMessage.component.scss']
})

export class InitiateMessageComponent implements OnInit, OnDestroy {
	user: User;
	ticket: any = null;
	ticketClassName: string = null;
	isDirectMessage: boolean = false;
	initiateMessages: Array<any> = [];

	constructor(public chatService: ChatService,
		public userService: UserService,
		public messengerTicketService: MessengerTicketService,
		public tweetTicketService: TweetTicketService,
		public tweetdmTicketService: TweetdmTicketService,
		public whatsappService: WhatsappService,
		public whatsappTicketService: WhatsappTicketService,
		public webchatService: WebchatService,
		public webchatTicketService: WebchatTicketService,
		public youtubeTicketService: YoutubeTicketService,
		public instagramTicketService: InstagramTicketService,
		public instadmTicketService: InstadmTicketService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			this.ticketClassName = (data && data.className) ? data.className : null;
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Add / Update InitiateMessage */
	newInitiateMessage(initiateMessage = null): void {
		let dialogRef = this.matDialog.open(InitiateMessageFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: initiateMessage,
				to: null,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.whatsappService.post(formData.value, 'whatsapp-begin-message');
			});
	}

}
