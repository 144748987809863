import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	Team, GlobalService,
	FbTemplateMessageService,
	AutoTemplateService
} from 'src/app/@tji/_dbShare';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
export interface InputPattern { title: string; visible: boolean; required: boolean; }
@UntilDestroy()
@Component({
	selector: 'app-add-auto-template',
	templateUrl: './add-auto-template.component.html',
	styleUrls: ['./add-auto-template.component.scss']
})
export class AddAutoTemplateComponent {

	@ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
	dialogTitle: string = 'Create';
	authUser: User;
	action: string;
	assignTemplateForm: UntypedFormGroup;

	template: any;
	selectedTemplate: any;

	myControl = new FormControl;
	templateList: any;
	filteredOptions: Observable<User[]>;

	constructor(public userService: UserService,
		public fbTemplateMessageService: FbTemplateMessageService,
		public autoTemplateService: AutoTemplateService,
		private globalService: GlobalService,
		public matDialogRef: MatDialogRef<AddAutoTemplateComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.fbTemplateMessageService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.templateList = data;
			this.filter();
		});
		// this.autoTemplateService.item.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.template = data;
		// });
		this.getInit(_data);
	}

	ngOnInit() {
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.fbTemplateMessageService.unSubscribeFilter();
	}

	filter() {

		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.templateList.slice();
			}),
		);
	}

	displayFn(user: any = null): string {
		return user && user.name ? user.name : '';
	}

	private _filter(name: string): User[] {
		const filterValue = name.toLowerCase();

		return this.templateList.filter(option => option.name.toLowerCase().includes(filterValue));
	}


	getInit(data) {
		if (data.item && data.item.template_id) {
			var selectedTemplate = data.item.template_id;
			var selectedTemp = this.templateList.filter(option => option.id == selectedTemplate);
			var selectedTemplateData = this._filter(selectedTemp[0].name)
			// this.myControl.setValue(selectedTemp[0])
			this.myControl.setValue(selectedTemplateData[0])
			this.selectedTemplate = selectedTemp[0];
		}
		// Set the defaults
		this.template = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Template to Auto template';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Template to Change Auto template';
				break;
			default:
				break;
		}
	}
}