import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FindConversationRouteModule } from './findConversation-route.module';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ChatsComponent } from './chats/chats.component';

import { AssignFormComponent } from './assignForm/assignForm.component';

import { ClientSiteService, FindChatService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ChatsComponent,
        AssignFormComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        FindConversationRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
    ],
    providers: [
        FindChatService,
        ClientSiteService
    ]
})

export class FindConversationModule { }
