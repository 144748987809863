import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

const routes        : Routes = [
	// { 
	// 	path        : '',
	// 	loadChildren: () => import('./page/page.module').then(p => p.PageModule)
	// },
	{
		path        : '',
		loadChildren: () => import('./newPage/new-page.module').then(p => p.NewPageModule)
	},
	{
		path        : 'app',
		loadChildren: () => import('./appAccount/appAccount.module').then(a => a.AppAccountModule),
		canActivate : [AuthGuard]
	},
	{
		path        : 'reseller',
		loadChildren: () => import('./reseller/reseller.module').then(a => a.ResellerModule),
		canActivate : [AuthGuard]
	},
	{ path          : '**', redirectTo: '/' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
