import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, SecurityContext } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, CommonService, Eorders, EordersService, EventService } from 'src/app/@tji/_dbShare';
import { DomSanitizer } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'app-order-print-page',
  templateUrl: './order-print-page.component.html',
  styleUrls: ['./order-print-page.component.scss']
})
export class OrderPrintPageComponent implements OnInit {

  dialogTitle: string = 'Order View';
  orders: any;
  action: string;
  statusInfoData: any;
  clientProfile: any;

  constructor(public eordersService: EordersService, public eventService: EventService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private alertService: AlertService) {
    this.eordersService.itemPrint.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data._id) {
        data["subtotal"] = data.Totalamount - data.deliveryPrice
        this.orders = data;
      }
    });

  }
date = new Date();
currentYear = this.date.getFullYear();


  ngOnInit(): void {
  }

}
