import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/@tji/_dbShare';

@Injectable({
  providedIn: 'root'
})
export class ProfileValidateGuard implements CanActivate {

  constructor(private router: Router,
    private userService: UserService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return true;

    // if (this.userService.roleMatch(['Admin', 'Super Admin'])) {
    //   return true;
    // } else {
    //   let profileRequiredInputs: boolean = this.userService.checkMandatoryInputs();
    //   if (!profileRequiredInputs) {
    //     this.router.navigate(['/app/profile-update']);
    //     return false;
    //   } else {
    //     return true;
    //   }

    // }

  }
}
