import { Component, OnInit } from '@angular/core';
import { AicollectionService, EventService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'payment-list',
  templateUrl: './paymentList.component.html',
  styleUrls: ['./paymentList.component.scss'],
  providers: [OrderByPipe]
})
export class PaymentListComponent implements OnInit {
  paymentList: any;
  statusData: any;
  loader: boolean = true;
  eventData: any;

  constructor(public aicollectionService: AicollectionService,
    public eventService: EventService,
    private orderByPipe: OrderByPipe,) {

    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });

    this.aicollectionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentList = this.orderByPipe.transform(data, '-_id');
    });

    this.aicollectionService.isStatusPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['payment_status']) {
        if (data['transaction_response']) {
          data['transaction_response'] = JSON.parse(data['transaction_response']);
        }
        this.statusData = data;
      } else {
        delete this.statusData;
      }
    });

    this.aicollectionService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

  }

  ngOnInit(): void {
  }
  /** On destroy */
  ngOnDestroy(): void {
    delete this.statusData;
    this.aicollectionService.isStatusPaymentData.next([]);
    this.aicollectionService.isAuthentication;
    this.aicollectionService.isAuthentication.next([]);
  }
  viewStatus(payment: any = null) {
    if (payment && payment._id) {
      var data = {
        "trackId": payment._id
      }
      this.aicollectionService.getStatusPayment(data);
    }
  }
  closeStatus() {
    this.aicollectionService.isStatusPaymentData.next(null);
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'paymentCreate':
          this.aicollectionService.concatItem(this.eventData.data);
          break;
        case 'paymentUpdate':
          this.aicollectionService.changeAllItemsByItem(this.eventData.data);
          break;
        default:
          break;
      }
    }
  }

}
