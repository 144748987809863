import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
var moment = require('moment-timezone');
import {
	WhatsappTicketService, WhatsappTicket,
	WhatsappService, Whatsapp,
	MessengerTicketService, MessengerTicket,
	MessengerService, Messenger,
	InstagramTicketService, InstagramTicket,
	InstagramService, Instagram,
	InstadmTicketService, InstadmTicket,
	InstadmService, Instadm,
	TweetTicketService, TweetTicket,
	TweetService, Tweet,
	TweetdmTicketService, TweetdmTicket,
	TweetdmService, Tweetdm,
	YoutubeTicketService, YoutubeTicket,
	YoutubeService, Youtube,
	WebchatTicketService, WebchatTicket,
	WebchatService, Webchat,
	User, UserService,
	ChatService, EventService, CommonService, LeadsHistoryService, ProductCartService,
	OpenTicketService,
	OpenTicketsService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-dashboard-tickets',
	templateUrl: './tickets.component.html',
	styleUrls: ['./tickets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AgentDashboardTicketsComponent implements OnInit, AfterViewInit {
	@Input() siteName: string;
	@Output() hasNew = new EventEmitter();
	param: any;
	tickets: Array<any> = [];
	ticket: any;
	base_image: string = this.commonService.logo_image;
	authUser: User;
	showTickets = 0;
	eventData: any;

	constructor(public chatService: ChatService,
		public eventService: EventService,
		public commonService: CommonService,
		public userService: UserService,
		public tweetService: TweetService,
		public tweetTicketService: TweetTicketService,
		public tweetdmService: TweetdmService,
		public tweetdmTicketService: TweetdmTicketService,
		public messengerService: MessengerService,
		public messengerTicketService: MessengerTicketService,
		public whatsappService: WhatsappService,
		public whatsappTicketService: WhatsappTicketService,
		public webchatService: WebchatService,
		public webchatTicketService: WebchatTicketService,
		public youtubeService: YoutubeService,
		public youtubeTicketService: YoutubeTicketService,
		public instagramService: InstagramService,
		public instagramTicketService: InstagramTicketService,
		public instadmService: InstadmService,
		public instadmTicketService: InstadmTicketService,
		public leadsHistoryService: LeadsHistoryService,
		public productCartService: ProductCartService,
		public openTicketsService: OpenTicketsService,
		private cdRef: ChangeDetectorRef) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);
	}

	ngOnInit() {
		this.dataInit();
		this.getTickets();
		this.dataInit('checkFirstTicket');
	}

	ngAfterViewInit() {
		this.cdRef.detectChanges();
	}

	dataInit(process = null) {
		if (this.siteName) {
			switch (this.siteName) {
				case 'Whatsapp': case 'whatsapp':
					this.whatsappService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.whatsappTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						this.tickets = this.filterTicketsData(data);
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Messenger': case 'messenger':
					this.messengerService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.messengerTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Instagram': case 'instagram':
					this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.instagramTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Instadm': case 'instadm':
					this.instadmService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.instadmTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Webchat': case 'webchat':
					this.webchatService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.webchatTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Twitter': case 'twitter':
				case 'Tweet': case 'tweet':
					this.tweetService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.tweetTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Twitterdm': case 'twitterdm':
				case 'Tweetdm': case 'tweetdm':
					this.tweetdmService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.tweetdmTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				case 'Youtube': case 'youtube':
					this.youtubeService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
					this.youtubeTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
						if (process && process === 'checkFirstTicket') { this.doFirstTicketProcess(data); }
						else { this.tickets = this.filterTicketsData(data); }
					});
					break;
				default:
					break;
			}
		}
	}

	getIcon(ticket: any): string {
		if (ticket && ticket.id && ticket.siteName) {
			return ticket.siteName.toLowerCase();
		}
		return 'support';
	}

	filterTicketsData(data: Array<any>): Array<any> {
		let filteredData: Array<any> = [];
		filteredData = data.filter(x => {
			if (this.authUser && this.authUser.person && this.authUser.person.is_supervisor && this.authUser.person.supervisoragentids && this.authUser.person.supervisoragentids.length > 0) {
				return ((x.agent_id === this.authUser.person_id || this.authUser.person.supervisoragentids.includes(x.agent_id)) && !x.is_closed) ? 1 : 0;
			} else {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? 1 : 0;
			}
		});
		this.chatService.concatTicketLibraries(filteredData);
		this.showTickets = (filteredData && filteredData.length > 0) ? 1 : 0;
		if (filteredData && filteredData.length < 1) {
			this.hasNew.emit(false);
			this.cdRef.markForCheck();
		}
		if (filteredData && filteredData.length > 0) {
			for (var i = 0; i < filteredData.length; ++i) {
				let item = filteredData[i];
				if (item && !item.is_closed && item.unReadCount > 0) {
					this.hasNew.emit(true);
					break;
				} else {
					this.hasNew.emit(false);
				}
			}
		}
		return filteredData;
	}

	doFirstTicketProcess(data: Array<any>) {
		let filteredData: Array<any> = [];
		filteredData = data.filter(x => {
			if (this.authUser && this.authUser.person && this.authUser.person.is_supervisor && this.authUser.person.supervisoragentids && this.authUser.person.supervisoragentids.length > 0) {
				return ((x.agent_id === this.authUser.person_id || this.authUser.person.supervisoragentids.includes(x.agent_id)) && !x.is_closed) ? true : false;
			} else {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}
		});
		if (filteredData && filteredData.length > 0) {
			for (var i = 0; i < filteredData.length; ++i) {
				let item = filteredData[i];
				if (item && !item.is_closed && item.unReadCount > 0) {
					this.hasNew.emit(true);
					break;
				} else {
					this.hasNew.emit(false);
				}
			}
		}
	}

	getTickets() {
		if (this.siteName) {
			this.chatService.getAllTicketsEvent(this.siteName);
		}
	}

	onSelectTicket(ticket) {
		this.chatService.changeTicket(ticket);
		this.chatService.changeSetLeadsHistoryButton(null);
		this.getLeadsHistory(ticket);
		this.productCartService.cartDataSource.next(null);
	}

	getEndTime(ticket) {
		let currentDate = new Date();
		let currentKuwaitDate = new Date(moment(currentDate).tz("Asia/Kuwait").format('YYYY-MM-DD HH:mm:ss'));
		let currentKuwaitDateSec = Math.abs(currentKuwaitDate.getTime());
		let abandonDate = new Date(ticket.abandonAt);
		let abandonDateSec = Math.abs(abandonDate.getTime());
		if (currentKuwaitDateSec && abandonDateSec && currentKuwaitDateSec < abandonDateSec) {
			var diff = (abandonDate.getTime() - currentKuwaitDate.getTime()) / 1000;
			return Math.abs(diff);
		}
		return 0;
	}

	canShowThisUser(): boolean {
		if (this.authUser && this.authUser.person_type === 'agent' && this.ticket && this.ticket.id) {
			return (this.authUser.person_id === this.ticket.agent_id) ? true : false;
		}
		return false;
	}

	isTicketClosed(): boolean {
		return (this.ticket && this.ticket.id && this.ticket.is_closed) ? true : false;
	}

	checkTicket(ticket): boolean {
		if (this.ticket && this.ticket.id && this.ticket.id == ticket.id && this.ticket.siteName == ticket.siteName) {
			return true;
		}
		return false;
	}
	leadsHistoryParam: any;
	getLeadsHistory(ticket) {
		this.leadsHistoryService.resetParams();
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}
}
