import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@tji/_dbShare';
@Component({
  selector: 'app-download-app-style-two',
  templateUrl: './download-app-style-two.component.html',
  styleUrls: ['./download-app-style-two.component.scss']
})
export class DownloadAppStyleTwoComponent implements OnInit {
  imageURLPath = 'assets/img';
  constructor(public commonService: CommonService,) { }

  ngOnInit(): void {
    this.getCurrentLangIcon()
  }
  getCurrentLangIcon() {
    let cookieLanguage: string = this.commonService.getLanguage();
    if (cookieLanguage === 'ar') {
      this.imageURLPath = 'assets/img/ar';
    }
  }
}


