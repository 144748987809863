import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';

import { PermissionService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: PermissionService },
	},
	{ path: '**', redirectTo: '/app/manage-permission' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class ManagePermissionRouteModule { }
