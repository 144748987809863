import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { TemplateSuccess } from './templateSuccess.interface';
import { TemplateSuccessModel } from './templateSuccess_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';
declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class TemplateSuccessService implements Resolve<any> {
	url: string = 'template/success';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	public allItemsSource = new BehaviorSubject<TemplateSuccess[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<TemplateSuccess>(new TemplateSuccessModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<TemplateSuccess[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	public isLoader = new BehaviorSubject<boolean>(false);
	loader = this.isLoader.asObservable();

	public isSubLoader = new BehaviorSubject<boolean>(false);
	subLoader = this.isSubLoader.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(null, true),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TemplateSuccessService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TemplateSuccessService');
	}

	changeAllItems(allItems: TemplateSuccess[]) {
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}
	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}
	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		if (this.routeParams && this.routeParams.templateUsageId && this.routeParams.templateUsageId > 0) {
			newParams.template_usage_id = this.routeParams.templateUsageId;
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'search': '',
		};
		if (this.routeParams && this.routeParams.templateUsageId && this.routeParams.templateUsageId > 0) {
			defaultParams.template_usage_id = this.routeParams.templateUsageId;
		}
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null, isAll = false) {
		params = this.paramsInit(params);
		this.isLoader.next(true);
		if (this.routeParams && this.routeParams.templateId) {
			params.template_usage_id = this.routeParams.templateId;
		}
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.clientsiteName
						var usersInfo = data.additional.users_info
						var templateUsage = data.additional.tempUsageInfo
						var templateInfo = data.additional.template_info
						var success = data.data;
						var dataFormat = [];
						success.forEach(success => {
							var dataFormats = {
								"id": success.id,
								"template_usage_id": success.template_usage_id,
								"to": success.to,
							}
							templateUsage.forEach(usage => {
								if (usage.id == success.template_usage_id) {
									dataFormats['client_id'] = usage.client_id;
									dataFormats['client_site_id'] = usage.client_site_id;
									dataFormats['created_at'] = usage.created_at;
									dataFormats['failed_count'] = usage.failed_count;
									dataFormats['is_completed'] = usage.is_completed;
									dataFormats['is_sendlater'] = usage.is_sendlater;
									dataFormats['jobcode'] = usage.jobcode;
									dataFormats['progress_status'] = usage.progress_status;
									dataFormats['rejected_count'] = usage.rejected_count;
									dataFormats['success_count'] = usage.success_count;
									dataFormats['total_count'] = usage.total_count;
									dataFormats['total_retries'] = usage.total_retries;
									dataFormats['updated_at'] = usage.updated_at;
									dataFormats['template_id'] = usage.template_id;

									if (usersInfo && usersInfo.length > 0) {
										usersInfo.forEach(user => {
											if (usage.created_by == user.id) {
												dataFormats["senderName"] = user.username
												dataFormats["senderEmail"] = user.email
											} else if (success.created_by == 1) {
												dataFormats["senderName"] = "API"
											}
										});
									}

									if (templateInfo && templateInfo.length > 0) {
										templateInfo.forEach(temp => {
											if (temp.id == usage.template_id) {
												dataFormats['templateName'] = temp.name;
												dataFormats["templateCode"] = temp?.template_info?.code;
											}
										});
									}

									if (clientInfo && clientInfo.length > 0) {
										clientInfo.forEach(client => {
											if (client.id == usage.client_site_id) {
												dataFormats['clientName'] = client.account_name;
											}
										});
									}
								}

							});
							dataFormat.push(dataFormats);
						});
						this.isLoader.next(false);
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.isLoader.next(false);
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				} else {
					this.isLoader.next(false);
					this.changeAllItems([]);
					this.changeTotalItem(data.pagnitation.totalResult);
				}
			},
				error => { console.log('Error ::' + error); this.isLoader.next(false); }
			);
	}

	getSearchAllItems(params: any = null, isAll = false) {
		params = this.paramsInit(params);
		this.isSubLoader.next(true);
		if (this.routeParams && this.routeParams.templateId) {
			params.template_usage_id = this.routeParams.templateId;
		}
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.clientsiteName
						var usersInfo = data.additional.users_info
						var templateUsage = data.additional.tempUsageInfo
						var templateInfo = data.additional.template_info
						var success = data.data;
						var dataFormat = [];
						success.forEach(success => {
							var dataFormats = {
								"id": success.id,
								"template_usage_id": success.template_usage_id,
								"to": success.to,
							}
							templateUsage.forEach(usage => {
								if (usage.id == success.template_usage_id) {
									dataFormats['client_id'] = usage.client_id;
									dataFormats['client_site_id'] = usage.client_site_id;
									dataFormats['created_at'] = usage.created_at;
									dataFormats['failed_count'] = usage.failed_count;
									dataFormats['is_completed'] = usage.is_completed;
									dataFormats['is_sendlater'] = usage.is_sendlater;
									dataFormats['jobcode'] = usage.jobcode;
									dataFormats['progress_status'] = usage.progress_status;
									dataFormats['rejected_count'] = usage.rejected_count;
									dataFormats['success_count'] = usage.success_count;
									dataFormats['total_count'] = usage.total_count;
									dataFormats['total_retries'] = usage.total_retries;
									dataFormats['updated_at'] = usage.updated_at;
									dataFormats['template_id'] = usage.template_id;

									if (usersInfo && usersInfo.length > 0) {
										usersInfo.forEach(user => {
											if (usage.created_by == user.id) {
												dataFormats["senderName"] = user.username
												dataFormats["senderEmail"] = user.email
											} else if (success.created_by == 1) {
												dataFormats["senderName"] = "API"
											}
										});
									}

									if (templateInfo && templateInfo.length > 0) {
										templateInfo.forEach(temp => {
											if (temp.id == usage.template_id) {
												dataFormats['templateName'] = temp.name;
												dataFormats["templateCode"] = temp?.template_info?.code;
											}
										});
									}

									if (clientInfo && clientInfo.length > 0) {
										clientInfo.forEach(client => {
											if (client.id == usage.client_site_id) {
												dataFormats['clientName'] = client.account_name;
											}
										});
									}
								}

							});
							dataFormat.push(dataFormats);
						});
						this.isLoader.next(false);
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.isLoader.next(false);
						this.isSubLoader.next(false);
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				} else {
					this.isLoader.next(false);
					this.isSubLoader.next(false);
					this.changeAllItems([]);
					this.changeTotalItem(data.pagnitation.totalResult);
				}
			},
				error => { console.log('Error ::' + error); this.isSubLoader.next(false); }
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.isSubLoader.next(true);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				var dataFormat = [];
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.clientsiteName
						var usersInfo = data.additional.users_info
						var templateUsage = data.additional.tempUsageInfo
						var templateInfo = data.additional.template_info
						var success = data.data;
						var dataFormat = [];
						success.forEach(success => {
							var dataFormats = {
								"id": success.id,
								"template_usage_id": success.template_usage_id,
								"to": success.to,
							}
							templateUsage.forEach(usage => {
								if (usage.id == success.template_usage_id) {
									dataFormats['client_id'] = usage.client_id;
									dataFormats['client_site_id'] = usage.client_site_id;
									dataFormats['created_at'] = usage.created_at;
									dataFormats['failed_count'] = usage.failed_count;
									dataFormats['is_completed'] = usage.is_completed;
									dataFormats['is_sendlater'] = usage.is_sendlater;
									dataFormats['jobcode'] = usage.jobcode;
									dataFormats['progress_status'] = usage.progress_status;
									dataFormats['rejected_count'] = usage.rejected_count;
									dataFormats['success_count'] = usage.success_count;
									dataFormats['total_count'] = usage.total_count;
									dataFormats['total_retries'] = usage.total_retries;
									dataFormats['updated_at'] = usage.updated_at;
									dataFormats['template_id'] = usage.template_id;

									if (usersInfo && usersInfo.length > 0) {
										usersInfo.forEach(user => {
											if (usage.created_by == user.id) {
												dataFormats["senderName"] = user.username
												dataFormats["senderEmail"] = user.email
											} else if (success.created_by == 1) {
												dataFormats["senderName"] = "API"
											}
										});
									}

									if (templateInfo && templateInfo.length > 0) {
										templateInfo.forEach(temp => {
											if (temp.id == usage.template_id) {
												dataFormats['templateName'] = temp.name;
												dataFormats["templateCode"] = temp?.template_info?.code;
											}
										});
									}

									if (clientInfo && clientInfo.length > 0) {
										clientInfo.forEach(client => {
											if (client.id == usage.client_site_id) {
												dataFormats['clientName'] = client.account_name;
											}
										});
									}
								}

							});
							dataFormat.push(dataFormats);
						});
						this.isLoader.next(false);
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
					if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
						params.current = data.pagnitation.pageCount;
						this.changeParams(params);
					}

					this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
						if (dataFormat && dataFormat.length > 0) {
							lists = result.concat(dataFormat);
						} else {
							lists = result
						}
					});
					this.isSubLoader.next(false);
					this.changeAllItems(lists);
				} else {
					this.isSubLoader.next(false);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.isSubLoader.next(false);
				}
			);
	}

	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {

			},
				error => console.log('Error ::' + error)
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems(null, isAll);
			});
	}

	pageEvent(event, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems(null, isAll);
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems(null, isAll);
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(type: string, url: string = null) {
		let domain = this.commonService.backendUrl;
		let ch_url = '';

		if (type == 'excel') {
			ch_url = (url) ? domain + url : domain + 'template/success-template-exportexcel/';
		} else if (type == 'pdf') {
			ch_url = (url) ? domain + url : domain + 'template/success-template-exportpdf/';
		}

		return ch_url;
	}

	exportReport(params: any = null) {
		if (this.routeParams && this.routeParams.templateUsageId) {
			params.template_usage_id = this.routeParams.templateUsageId;
			params.limit = 500;
		}
		this.commonService.storeItem("template/successTemplateReport", params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.clientsiteName;
						var usersInfo = data.additional.users_info;
						var templateInfo = data.additional.template_info;
						var success = data.data;
						var dataFormat = [];
						success.forEach(success => {
							var dataFormats = {
								"to": success.to,
								"created_at": moment(success.created_at).format('MM-DD-YYYY HH:mm:ss'),
								// "templateName": success.template_info.name,
								// "clientName": clientInfo.name,
								// "templateCode": success.tempUsageInfo.jobcode,
							}
							if (templateInfo && templateInfo.length > 0) {
								templateInfo.forEach(temp => {
									if (temp.id == success.template_id) {
										dataFormats["templateName"] = temp.name;
									}
								});
							}
							if (templateInfo && templateInfo.length > 0) {
								templateInfo.forEach(temp => {
									if (temp.id == success.template_id) {
										dataFormats["templateName"] = temp.name;
									}
									if (clientInfo && clientInfo.length > 0 ) {
										clientInfo.forEach(client => {
											if (client.id == temp.client_site_id) {
												dataFormats["clientName"] = client.account_name;
											}
										});
									}
								});
							}
							if (usersInfo && usersInfo.length > 0) {
								usersInfo.forEach(user => {
									if (success.created_by == user.id) {
										dataFormats["senderName"] = user.username
										dataFormats["senderEmail"] = user.email
									} else if (success.created_by == 1) {
										dataFormats["senderName"] = "API"
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.downloadFile(dataFormat);
					}
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => { console.log('Error ::' + error); this.isLoader.next(false); }
			);
	}


	// downloadFile
	downloadFile(temp, title = null) {
		var excelData = [];
		if (temp && temp.length > 0) {
			temp.forEach(template => {
				if (template) {
					var data = {
						"Date": moment(template.created_at).format('MM-DD-YYYY HH:mm:ss'),
						"Template Name": template.templateName,
						"Account Name": template.clientName,
						"Sender Name": template.senderName,
						"Sender Email": template.senderEmail,
						"To": template.to,
					}
					excelData.push(data);
				}
			});
		}

		let arrHeader = ["Date", "Template Name", "Account Name", "Sender Name", "Sender Email", "To"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		// this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'template_success_reports_' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}
	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Date", "Template Name", "Account Name", "Sender Name", "Sender Email", "To"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}


}
