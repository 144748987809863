import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
declare let $: any;
import { environment } from 'src/environments/environment';
import {
	PremiumDashboardService, PremiumDashboard, PremiumAlert,
	SocketService, Webclip, Client, EventService, CountService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'premium-dashboard-root',
	templateUrl: './premiumDashboard.component.html',
	styleUrls: ['./premiumDashboard.component.scss'],
	providers: [
		Location, {
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		}
	]
})
export class PremiumDashboardComponent {
	webclip: Webclip;
	authUserId: number = null;
	authToken: string = null;
	location: any;
	routerSubscription: any;
	client: Client = null;
	dashboard: PremiumDashboard;
	dashboardParam: any;
	authUserClientId: number = null;
	channel: any;
	excecuted: boolean = false;
	eventData: any;
	countJsonData: Array<any> = [];
	elem: any;
	isFullScreenmode: boolean = false;

	constructor(private router: Router,
		public premiumDashboardService: PremiumDashboardService,
		public eventService: EventService,
		public countService: CountService,
		private socketService: SocketService, @Inject(DOCUMENT) private document: any) {
		this.elem = document.documentElement;
		this.premiumDashboardService.webclip.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.id && data.for === 'premium-dashboard') {
				this.webclip = data;
				this.authUserId = (data.user_id) ? data.user_id : null;
				this.authUserClientId = (data.client_id) ? data.client_id : null;
				this.authToken = (data.token) ? data.token : null;
				this.client = (data.client) ? data.client : null;
			}
		});
		this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
		this.premiumDashboardService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.dashboard = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.countService.jsonData.pipe(untilDestroyed(this)).subscribe(data => {
			this.countJsonData = (data) ? Object.entries(data).map(([type, value]) => ({ type, value })) : [];
		});
	}

	ngOnInit() {
		this.subscribeOctopusSocket();
		this.getCounts();
	}

	getCounts() {
		this.countService.getItem();
		// if (this.countJsonData && this.countJsonData.length < 1) {
		//     setTimeout(() => {
		//         this.getCounts();
		//     }, 3000);
		// }
	}

	subscribeOctopusSocket() {

		if (environment.enableOctopusSocket) {
			if (this.authUserId && this.authUserClientId && this.authToken) {
				let channel = 'premium-dashboard.' + this.authUserClientId;
				this.premiumDashboardService.changeChannel(channel);
				this.socketService.subscribeChannelRaw(channel, 'premium-dashboard', this.authUserClientId);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PremiumDashboardEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDAgentsCountEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDAgentWidgetEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDLeadBoardEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDLiveMessageEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDLiveTicketEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDPerformanceEvent'); }, 5);
				// setTimeout(() => { this.socketService.subscribeChannelRaw(channel, 'PDAccountCountEvent'); }, 5);
			} else {
				setTimeout(() => { this.subscribeOctopusSocket(); }, 500);
			}
		}
	}

	checkWebclip(): boolean {
		return (this.webclip && this.webclip.id && this.webclip.for === 'premium-dashboard') ? true : false;
	}

	getWebclip(key: string = null) {
		if (key && key.length === 8) {
			if (!this.excecuted) {
				this.premiumDashboardService.getWebclipByKey(key);
				this.excecuted = true;
			} else {
				setTimeout(() => { this.excecuted = false; }, 1000);
			}
		}
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.eventName) ? this.eventData.eventName : null;
		if (eventName) {
			let data = this.eventData.data;
			switch (eventName) {
				case 'CountTypeEvent':
				case 'CountTypeEvent':
					let countTypeData = (this.eventData && this.eventData.data) ? this.eventData.data : [];
					if (countTypeData) { this.countService.changeJsonDataByType(countTypeData); }
					break;
				case 'CountEvent':
				case 'countEvent':
					let countData = (this.eventData && this.eventData.data) ? this.eventData.data : [];
					if (countData) { this.countService.changeJsonData(countData); }
					break;
				default:
					break;
			}
		}
	}

	openFullscreen() {
		if (this.elem.requestFullscreen) {
			this.elem.requestFullscreen();
		} else if (this.elem.mozRequestFullScreen) {
			this.elem.mozRequestFullScreen();
		} else if (this.elem.webkitRequestFullscreen) {
			this.elem.webkitRequestFullscreen();
		} else if (this.elem.msRequestFullscreen) {
			this.elem.msRequestFullscreen();
		}
		this.isFullScreenmode = true;
	}

	/* Close fullscreen */
	closeFullscreen() {
		if (document.exitFullscreen) {
			this.document.exitFullscreen();
		} else if (this.document.mozCancelFullScreen) {
			this.document.mozCancelFullScreen();
		} else if (this.document.webkitExitFullscreen) {
			this.document.webkitExitFullscreen();
		} else if (this.document.msExitFullscreen) {
			this.document.msExitFullscreen();
		}
		this.isFullScreenmode = false;
	}

	@HostListener('window:keydown', ['$event'])
	onKeydownEvent(event: any): void {
		this.isFullScreenmode = false;
		if (document.fullscreenElement) {
			this.isFullScreenmode = true;
		}
	}

	@HostListener('window:keyup', ['$event'])
	onKeyupEvent(event: any): void {
	}
}
