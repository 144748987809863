import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-media-status',
  templateUrl: './socialMediaStatus.component.html',
  styleUrls: ['./socialMediaStatus.component.scss']
})
export class SocialMediaStatusComponent implements OnInit {

  @Input() icon: string = 'fa fa-square';
  @Input() status: string = 'open';


  constructor() { }

  ngOnInit() {

  }

  getStatus(): string {

    switch (this.status) {
      case 'open':
        return 'Active';
      case 'close':
        return 'In-Active';
      case 'high':
        return 'More Than 5 Days In-Active';
      case 'low':
        return 'More Than 1 Days In-Active';
      case 'open-nochat':
        return 'You Authorized But not Yet to communicate';
      default:
        return 'Active';
    }

  }

  getClass(): string {

    switch (this.status) {
      case 'open':
        return this.icon + ' green-500-fg';
      case 'close':
        return this.icon + ' red-500-fg';
      case 'high':
        return this.icon + ' orange-500-fg';
      case 'low':
        return this.icon + ' yellow-500-fg';
      case 'open-nochat':
        return this.icon + ' green-500-fg';
      default:
        return this.icon + ' green-500-fg';
    }

  }

}
