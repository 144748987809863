import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ItrsbotService, Itrsbot, ItrsbotModel,
	ClientSiteService, ClientSite
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'itrsbot-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	itrsbot: Itrsbot;
	clientSites: ClientSite[] = [];
	selectedClientSite: ClientSite;
	action: string;
	itrsbotForm: UntypedFormGroup;
	itrsbotList: any = [];

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public clientSiteService: ClientSiteService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {

		this.getInit(_data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data;
		});
		this.itrsbotService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.itrsbotList = data;
		});
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.itrsbot = data;
			this.itrsbotForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.itrsbotService.unSubscribe();
		this.itrsbotService.unSubscribeFilter();
		this.clientSiteService.unSubscribe();
		this.clientSiteService.unSubscribeFilter();
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Itrsbot';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Itrsbot';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			resource_id: [this.itrsbot?.resource_id || null, [Validators.required]],
			resource_type: ['clientsite', [Validators.required]],
			name: [this.itrsbot?.name || null, [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
			copyFromId: [null],
			is_coppy: [null],
		});
	}

	changeClientSite(clientSiteId) {
		let selectedClientSite: any = this.clientSites.filter(x => x.id === clientSiteId)[0];
	}

	checkSiteName(clientSite: ClientSite): boolean {
		if (clientSite && clientSite.siteName && (clientSite.siteName === 'Twitter' || clientSite.siteName === 'twitter' ||
			clientSite.siteName === 'Instagram' || clientSite.siteName === 'instagram')) {
			return true;
		}
		return false;
	}
	is_coppy: boolean = false;
	copyItrsBot(e) {
		this.is_coppy = e.checked;
	}
}
