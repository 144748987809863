import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from './data';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, ImageService,
	PeopleService, People, PeopleModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'people-item-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']
})

export class ChartComponent implements OnInit, OnDestroy {
	authUser: User;
	people: People;
	baseImage: string = 'assets/img/no-user.jpg';

	data: any = [];
	view: any[] = [, 300];
	colorScheme = {
		domain: ['#1a75ff', '#00802b', '#ff66a3', '#008080', '#cc6600', '#5200cc']
	};
	gradient: boolean = true;
	showLegend: boolean = true;
	showLabels: boolean = true;
	isDoughnut: boolean = false;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => this.people = data);
		Object.assign(this, { data });
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	onSelect(data): void {
	}

	onActivate(data): void {
	}

	onDeactivate(data): void {
	}

}
