import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, AgentService, Agent,
	AgentUsageService, AgentUsage,
	AgentActivityService, AgentActivity
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-item-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {
	authUser: User;
	agent: Agent;
	agentUsages: AgentUsage[] = [];
	agentUsageParam: any;

	agentActivities: AgentActivity = null;
	agentActivityParam: any;
	param: any;

	campaignOne: UntypedFormGroup;
	fromDate: Date;
	toDate: Date;
	today: Date = new Date();
	minDate: Date = new Date(2021, 0, 1);
	maxDate: Date = new Date();

	constructor(public userService: UserService,
		public agentService: AgentService,
		public agentUsageService: AgentUsageService,
		public agentActivityService: AgentActivityService,
		private _formBuilder: UntypedFormBuilder,
		private datepipe: DatePipe,
		public matDialog: MatDialog) {
		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.agent = data;
			if(this.agent && this.agent.id) { 
				// this.getAgentUsages();
				// this.getAgentActivities();
			}
		});
		this.agentUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentUsageParam = data);
		this.agentUsageService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agentUsages = data);
		this.agentActivityService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentActivityParam = data);
		this.agentActivityService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agentActivities = data);
		this.getDates();
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.agentUsageService.resetParams();
		this.agentService.unSubscribeFilter();
	}

	getDates() {
		let date = new Date();
		this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
		this.toDate = this.today;
		this.campaignOne = new UntypedFormGroup({
	      start: new UntypedFormControl(this.fromDate),
	      end: new UntypedFormControl(this.toDate)
	    });
	}

	getAgentUsages() {
		if(this.agent && this.agent.id) {
			this.agentUsageParam.client_id = this.userService.getAuthClientId();
			this.agentUsageParam.agent_id = this.agent.id;
			this.agentUsageParam.from_date = this.datepipe.transform(this.fromDate, 'dd-MM-yyyy');
			this.agentUsageParam.to_date = this.datepipe.transform(this.toDate, 'dd-MM-yyyy');
			this.agentUsageService.getAllItems();
		}
	}

	getAgentActivities() {
		if(this.agent && this.agent.id) {
			this.agentActivityParam.client_id = this.userService.getAuthClientId();
			this.agentActivityParam.agent_id = this.agent.id;
			this.agentActivityParam.from_date = this.datepipe.transform(this.fromDate, 'dd-MM-yyyy');
			this.agentActivityParam.to_date = this.datepipe.transform(this.toDate, 'dd-MM-yyyy');
			this.agentActivityService.getAllItems();
		}
	}

	// getAgentUsages() {
	// 	this.agentUsageParam.agent_id = this.agent.id;
	// 	this.agentUsageService.getAllItems();
	// }

	// changeFromDate(event) {
	// 	this.agentUsageParam.from_date = new Date(event.target.value).toLocaleString();
	// 	this.agentUsageService.getAllItems();
	// }

	// changeToDate(event) {
	// 	this.agentUsageParam.to_date = new Date(event.target.value).toLocaleString();
	// 	this.agentUsageService.getAllItems();
	// }

}
