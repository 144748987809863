import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import * as color from 'd3-color';
import { data } from './data';

import { User, UserService, DashboardService } from 'src/app/@tji/_dbShare';
import { exit } from 'process';

@UntilDestroy()
@Component({
	selector: 'dashboard-chart4',
	templateUrl: './chart4.component.html',
	styleUrls: ['./chart4.component.scss']
})

export class Chart4Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('chart4', { static: true }) chart4: ElementRef;
	authUser: User;

	data: any[];
	view: any[] = [];
	chart4Data: any = [];

	// options
	legend: boolean = false;
	showLabels: boolean = true;
	animations: boolean = true;
	xAxis: boolean = true;
	yAxis: boolean = true;
	showYAxisLabel: boolean = true;
	showXAxisLabel: boolean = true;
	showGridLines: boolean = false;
	autoScale: boolean = true;
	xAxisLabel: string = 'Day';
	yAxisLabel: string = 'Conversation';
	timeline: boolean = true;
	curve: any = shape.curveCatmullRom;

	colorScheme = {
		domain: ['#9C4F96', '#FF6355', '#FBA949', '#FAE442', '#8BD448', '#2AA8F2']
	};
	schemeType = "linear";
	cardColor: string = '#414aa257';

	constructor(public userService: UserService,
		private _formBuilder: UntypedFormBuilder,
		public dashboardService: DashboardService,
		public matDialog: MatDialog) {
		Object.assign(this, { data });

		this.dashboardService.chartFourData.subscribe(data => {
			if (data && data.chart4) {
				this.chart4Data = data.chart4;
			}
		});
	}

	ngOnInit() {
		this.view = [this.chart4.nativeElement.offsetWidth - 100, this.chart4.nativeElement.offsetHeight];
	}

	ngAfterViewInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.dashboardService.unSubscribe();
	}

	onSelect(data): void {
	}

	onActivate(data): void {
	}

	onDeactivate(data): void {
	}

}
