import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	EordersService, Agent,
	BoardService, Board
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-order-reassign-form',
  templateUrl: './order-reassign-form.component.html',
  styleUrls: ['./order-reassign-form.component.scss']
})
export class OrderReassignFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	ticket: any;
	reassignForm: UntypedFormGroup;

	agents: Agent[] = [];
	agentParam: any;

	action: string;
	priorities: any[] = ['Low', 'Normal', 'Important', 'Very Important'];

	constructor(public userService: UserService,
		public eordersService: EordersService,
		public matDialogRef: MatDialogRef<OrderReassignFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.eordersService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			this.reassignForm = this.createForm();
		});
		this.eordersService.allActiveAgents.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.eordersService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
	}
	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { 
	this.eordersService.unSubscribeFilter();
	}

	getAgents() {
		this.eordersService.getActiveAgent(this.ticket.client_site_id);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Assign To';
				break;
			case 'edit':
				this.dialogTitle = 'Re-assign To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			agent_id: [this.ticket.agent_id || null, [Validators.required]],
			id: [this.ticket._id, [Validators.required]],
		});
	}
}
