import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../../form/form.component';

import {
	User, UserService, ImageService,
	ClientService, Client, ClientModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'client-item-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
	authUser: User;
	client: Client;

	constructor(public userService: UserService,
		public clientService: ClientService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => this.client = data );
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	storeImage(event, clientId = null) {
		clientId = (clientId) ? clientId : this.client.id;
		let input = {
			'resource_type': 'client',
			'resource_id': clientId,
			'storeMode': 'replace'
		};
		this.imageService.storeImage(event, input);
		this.imageHandler(event);
	}

	imageHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				// this.client.imageId = 1;
				this.client.avatar_url = e.target.result;
				// need to run CD since file load runs outside of zone
				this.cd.markForCheck();
			};
		}
	}

	/** Add / Update Client */
	newClient(client = null): void {
		this.clientService.changeItem((client) ? client : new ClientModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (client) ? 'edit' : 'new',
				item: client,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.clientService.store("reseller/client/add", formData.value);
						break;
					case 'update':
						formData.value.id = id;
						this.clientService.update("reseller/client/update", formData.value);
						break;
				}
			});
	}

	syncClient(client: Client) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are You Sure?',
				info: 'By doing this, following data will be FORCE catched:',
				infoDetails: "( ClientInfo, Client Sites, Users Settings, Teams,Agents,Sites, Itrs, Permissions )"
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var data = {
					"id": client.id,
				}
				this.clientService.accountSettings("reseller/client/manualSync", data);
			}
		});



	}


	changeActiveReplay(e, id) {
		var data = {
			'active_reply': e.checked.toString(),
			"id": id
		}
		this.clientService.accountSettings("reseller/client/settings", data);
	}
	changeActiveTemplate(e, id) {
		var data = {
			'active_template': e.checked.toString(),
			"id": id
		}
		this.clientService.accountSettings("reseller/client/settings", data);
	}
}
