export class ClientModel {
	id: number;
	code: string;
	name: string;
	reseller_id: number;
	is_active?: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	detail?: any;
	details?: any;
	user?: any;
	reseller?: any;
	avatar_url?: string;
	totalCloseTickets?: number;
	totalOpenTickets?: number;
	roleArray?: Array<any>;
	roleIdArray?: Array<any>;
	permissionIdArray?: Array<any>;
	permissionArray?: Array<any>;
	directPermissionIdArray?: Array<any>;
	directPermissionArray?: Array<any>;
	userId?: number;
	userEmail?: string;

	tableName: string;
	sites?: Array<any>;
	userInfo?: any;
	OnlineAgentCount: any;
	TotalAgent: any;
	clientSiteCount: any;
	is_demo: any;
	expire_at: Date;
	dueDays: number;

	/**
	 * Constructor
	 *
	 * @param client
	 */
	constructor(client) {
		this.id = client.id || '';
		this.code = client.code || '';
		this.name = client.name || '';
		this.is_active = client.is_active || true;
		this.created_by = client.created_by || '';
		this.updated_by = client.updated_by || '';
		this.created_at = client.created_at || '';
		this.updated_at = client.updated_at || '';

		this.detail = client.detail || {};
		this.details = client.details || {};
		this.user = client.user || {};

		this.tableName = client.tableName || '';
		this.userInfo = client.userInfo || {};
		this.expire_at = client.expire_at || '';
		this.dueDays = client.dueDays || 0;
	}
}
