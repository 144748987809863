import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TruncatePipe } from 'src/app/pipes/string/truncate.pipe';
import { OrderByPipe } from 'ngx-pipes';
declare let $: any;
import { environment } from 'src/environments/environment';
import {
    PremiumDashboardService, PremiumDashboard, PremiumAlert,
    PremiumLeadBoard, EventService
} from 'src/app/@tji/_dbShare';
import * as moment from 'moment-timezone';
@UntilDestroy()
@Component({
    selector: 'premium-dashboard-leads-board',
    templateUrl: './leadsBoard.component.html',
    styleUrls: ['./leadsBoard.component.scss'],
    animations: tjiAnimation,
    providers: [
        OrderByPipe,
        TruncatePipe,
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class LeadsBoardComponent {

    duration: number = 5000;
    animOutState: boolean = true;
    animInState: boolean = true;
    dashboardParam: any;
    leadBoards: PremiumLeadBoard[] = [];
    base_image: string = 'assets/img/no-user.jpg';
    channel: any;
    eventData: any;

    constructor(private router: Router,
        public premiumDashboardService: PremiumDashboardService,
        private eventService: EventService,
        private orderByPipe: OrderByPipe) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
        this.premiumDashboardService.leadBoards.pipe(untilDestroyed(this)).subscribe(data => {
             this.leadBoards = data;
            // this.leadBoards = this.orderByPipe.transform(data, 'updated_at');
        });
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit() {
        this.checkDuration();
    }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName && eventName === 'newfeed' || eventName === 'aNewfeed') {
            let eventData = this.eventData;
            let data = this.eventData.data;
            if (eventData && data) {
                if (this.leadBoards && this.leadBoards.length > 0) {
                    this.leadBoards.forEach((x, index) => {
                        if (x.people_id == data.people_id) {
                            this.leadBoards.splice(index, 1);
                        }
                    });
                }
                var livePeople = {
                    "id": data.peopleId,
                    "accountName": data?.clientSiteName,
                    "siteName": data?.site,
                    "created_at": moment(moment(data.lead_updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), //data?.created_at,
                    "updated_at": moment(moment(data.lead_updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), //moment(data.updated_at).local(), //data.updated_at.toLocaleTimeString(), //moment(data.updated_at).local(), //moment(data?.updated_at).format('dddd MMMM D YYYY, h:mm:ss a'), //new Date(data?.updated_at), //  moment(data?.updated_at + '.000+0300').local(),
                    "peopleName": data?.peopleName,
                    "avatar_url": data.peopleAvatar?data.avatar_url: 'peopleAvatar',
                    "unique_ref": data?.resource?.account_sid,
                    "client_id": data.client_id,
                    "people_id": data.peopleId,
                    "type": "leadsboard"
                }
                if (data.leadInfo) {
                    livePeople["unique_ref"] = data?.leadInfo?.unique_ref;
                    livePeople["peopleName"] = data?.leadInfo?.name;
                }
                if (data.clientSite) {
                    livePeople["accountName"] = data?.clientSite?.account_name;
                    livePeople["siteName"] = data?.clientSite?.details?.name;
                }
                this.premiumDashboardService.appendLeadBoard(livePeople);
            }
        }
    }

    checkDuration() {
        if (this.leadBoards.length > 20) { this.duration = 500; }
        else if (this.leadBoards.length > 10) { this.duration = 700; }
        else if (this.leadBoards.length > 6) { this.duration = 800; }
        else { this.duration = 1200; }
        setTimeout(() => {
            if (this.leadBoards.length > 5) {
                this.leadBoards.shift();
            }
            this.checkDuration();
        }, this.duration);
    }

}
