import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
import { AssignItrsbotComponent } from './../assignItrsbot/assignItrsbot.component';

import {
	User, UserService, IntractiveService,
	Agent, AgentService,
	Team, TeamService,
	ItrsbotService, Itrsbot, ItrsbotModel,
	IntractiveMessageService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	itrsbotsAll: Itrsbot[] = [];
	itrsbots: Itrsbot[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.itrsbots);
	displayedColumns = ['code', 'name', 'accountfor', 'action'];
	intractiveParam: any;
	intractiveMessageParam: any;

	clientId: number = this.userService.getAuthClientId();
	agentParam: any;
	teamParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveMessageService: IntractiveMessageService,
		public agentService: AgentService,
		public teamService: TeamService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
		this.teamService.params.pipe(untilDestroyed(this)).subscribe(data => this.teamParam = data);
		this.intractiveService.params.pipe(untilDestroyed(this)).subscribe(data => this.intractiveParam = data);
		this.intractiveMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.intractiveMessageParam = data);
		this.itrsbotService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.itrsbotsAll) { this.subLoader = false; }
			this.itrsbotsAll = data;
			this.itrsbots = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.itrsbots);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
		this.getAgents();
		this.getTeams();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.itrsbotService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.itrsbotService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.itrsbotService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	getAgents() {
		this.agentParam.paginate = 200;
		this.agentParam.client_id = this.clientId;
		this.agentService.getAllItems();
	}

	getTeams() {
		this.teamParam.paginate = 200;
		this.teamParam.client_id = this.clientId;
		this.teamService.getAllItems();
	}

	/** Add / Update Itrsbot */
	newItrsbot(itrsbot = null): void {
		this.itrsbotService.changeItem((itrsbot) ? itrsbot : new ItrsbotModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (itrsbot) ? 'edit' : 'new',
				item: itrsbot,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				var formDatas = {
					'name': formData.value.name,
					'client_site_id': formData.value.resource_id,
				}
				if (formData.value.is_coppy) {
					formDatas["copyFromId"] = formData.value.copyFromId
				}
				
				switch (actionType) {
					case 'new':
						this.itrsbotService.itrsBotUpdate('Itrsbot/add',formDatas);
						break;
					case 'update':
						formDatas['id'] = id;
						this.itrsbotService.itrsBotDataUpdate('Itrsbot/update', formDatas, itrsbot);
						break;
					case 'delete':
						this.deleteItrsbot(itrsbot);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteItrsbot(itrsbot: Itrsbot): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id : itrsbot.id,
				}
				this.itrsbotService.destroy('Itrsbot/delete', formData);
			}
		});
	}

	assignItrsbot(itrsbot: Itrsbot) {
		let dialogRef = this.matDialog.open(AssignItrsbotComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: itrsbot,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const formData: UntypedFormGroup = response[1];
				// this.personTeamService.store(formData.value);
			});
	}

	/** markAsActive Itrsbot */
	markAsActive(itrsbot): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to set this Itrs Bot as Primary Bot ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.itrsbotService.removelibraryById(itrsbot.id);
				var formDatas = {
					"id":itrsbot.id,
    				"status":1,
					"client_site_id": itrsbot.resource_id,
				}
				this.itrsbotService.itrsBotUpdate('Itrsbot/status', formDatas);
				// this.itrsbotService.update(itrsbot.id, { 'is_active': 1, 'do': 'active' });
				// setTimeout(() => {
				// 	this.itrsbotService.getAllItems();
				// }, 500)
			}
		});
	}

	onSelectItrsBot(itrsbot: Itrsbot) {
		// this.itrsbotService.getItem(itrsbot.id);
		this.intractiveService.resetParams();
		this.intractiveParam.client_site_id = itrsbot.resource_id;
		this.intractiveParam.itrsbot_id = itrsbot.id;
		this.intractiveService.getAllItems();

		this.intractiveMessageParam.client_site_id = itrsbot.resource_id;
		this.intractiveMessageParam.client_id = itrsbot.client_id;
		this.intractiveMessageService.getAllItems();
		this.intractiveMessageService.getAllDropdownlist();
		if (itrsbot && itrsbot.is_active) {
			this.intractiveService.intractiveItrsStatus = true;
		} else {
			this.intractiveService.intractiveItrsStatus = false;
		}
		this.router.navigate(['/app/itrsbot/' + itrsbot.id]);
	}

}
