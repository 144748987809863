import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';

import {
	User, UserService,
	FlagService, Flag, FlagModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'flag-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	flags: Flag[] = [];
	param: any;
	flagResponse: any;
	dataSource = new MatTableDataSource(this.flags);
	displayedColumns = ['name','clientSiteName','description', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public flagService: FlagService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.flagService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data.length > 0) {
				data.forEach(element => {
					var clientSiteName = []
					if (element.ClientsiteInfo) {
						element.ClientsiteInfo.forEach(client => {
							if (client && client.account_name) {
								clientSiteName.push('<br/> ' + client.account_name + '-' + client.details.name);
							}
						});
						element['clientSiteName'] = clientSiteName;
					}
				});
			}
			this.flags = data;
			this.dataSource = new MatTableDataSource(this.flags);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.flagService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.flagService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.flagService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Flag */
	newFlag(flag = null): void {
		this.flagService.changeItem((flag) ? flag : new FlagModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (flag) ? 'edit' : 'new',
				item: flag,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						var clientSiteId = [];
						formData.value.client_site_id.forEach(element => {
							if (element) {
								clientSiteId.push({'id': element});
							}
						});
						formData.value.client_site_id = clientSiteId
						this.flagService.store('flag/add', formData.value);
						break;
					case 'update':
						var clientSiteId = [];
						formData.value.client_site_id.forEach(element => {
							if (element) {
								clientSiteId.push({'id': element});
							}
						});
						formData.value.client_site_id = clientSiteId
						var data = formData.value;
						data['id'] = id;
						this.flagService.update('flag/update', data);
						break;
					case 'delete':
						this.deleteFlag(flag);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteFlag(flag: Flag): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var data = {
					"id": flag._id
				}
				this.flagService.destroy('flag/delete', data);
			}
		});
	}

	statusChange(id, event) {
		var data;
		data['id'] = id;
		data['is_active'] = event;
		this.flagService.update('flag/update', data);
		// setTimeout(() => {
		// this.snackBar.openFromComponent(AlertComponent, {
		//     duration: 500000,
		//     panelClass: ['snackBarSuccess']
		// });
		// this.snackBar.open('Update Successfully', 'Close', {
		//     panelClass: ['snackBarSuccess']
		// });
		// }, 300);
	}

}
