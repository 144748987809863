import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
// import { FormComponent } from './../form/form.component';

import {
	User, UserService,
	LogService, Log, LogModel
} from 'src/app/@tji/_dbShare';
import * as moment from 'moment-timezone';
// import jstz from 'jstz';
@UntilDestroy()
@Component({
	selector: 'log-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	logs: Log[] = [];
	param: any;
	logResponse: any;
	dataSource = new MatTableDataSource(this.logs);
	displayedColumns = ['id', 'time', 'description', 'user', 'ipaddress'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	localTime = new Date().toISOString();
	subLoader: boolean = false;
	constructor(public userService: UserService,
		public logService: LogService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.logService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.logs) { this.subLoader = false; }
			if (data.length > 0) {
				data.forEach(time => {
					if (time.created_at) {
						time['created_at_time'] = moment(time.created_at * 1000).format("DD-MMM-yyy-hh:mm a");
						time['timePassed'] = moment(time.updated_at * 1000).format("DD-MMM-yyy-hh:mm a");
					}
				});
				this.logs = data;
				this.dataSource = new MatTableDataSource(this.logs);
				this.dataSource.sort = this.sort;
			}else {
				this.logs = [];
				this.dataSource = new MatTableDataSource(this.logs);
				this.dataSource.sort = this.sort;
			}
			// this.logs = data;
			// this.dataSource = new MatTableDataSource(this.logs);
			// this.dataSource.sort = this.sort;

			// var time = '2023-08-01 16:23:07'
			// // var testDateUtc = moment.utc(time);
			// // var localDate = moment(testDateUtc).local();

			// // console.log('time===========',localDate);

			// // var today= new Date().toISOString(); 
			// // var todaysDataTime = '';

			// //  todaysDataTime = formatDate(today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
			// // console.log('todaysDataTime===========',today);

			// // var times = moment(time).tz(today);
			// // console.log('final times===========',times);
			// // new date()
			// // var time = "2023-08-02 10:02:18.000" //moment.utc("2023-08-02 10:02:18.000+0300"); //2023-08-02 10:02:18.000 + 0300;
			// // // var time = "2023-08-02 10:02:18.000+0300";
			// // var localDate = moment(time).local();
			// // console.log('time===========',localDate);

			// var	userTimezone: string = 'Asia/Kuwait';
			

    		// // userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			
			// // // const timezone = jstz.determine();
			// // // timezone.name();

			// // userTimezone = moment.utc(time).tz(userTimezone).format("YYYY-MM-DD hh:mm:ss a")
			// var localDate = moment('2023-08-02 13::18.000+0300').local().format("YYYY-MM-DD hh:mm:ss a");
			// console.log('userTimezone===========',localDate);
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.logService.resetParams();
		this.logService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}
	
	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.logService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.logService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Delete Social Site */
	deleteLog(log: Log): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.logService.destroy(log.id);
			}
		});
	}

}
