export var data = [
  {
    "name": "Total Messages",
    "value": 50
  },
  {
    "name": "Satisfied",
    "value": 28
  },
  {
    "name": "Not Satisfied",
    "value": 2
  },
    {
    "name": "Committed",
    "value": 15
  },
    {
    "name": "Closed Tickets",
    "value": 17
  },
    {
    "name": "Active Tickets",
    "value": 3
  }
];