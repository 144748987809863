export class YoutubeListModel {
    id: number;
    youtube_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    parentId: string;
    commentId: string;
    etag: string;
    videoId: string;
    channelId: string;
    textDisplay: string;
    textOriginal: string;
    authorDisplayName: string;
    authorProfileImageUrl: string;
    authorChannelUrl: string;
    authorChannelId: string;
    viewerRating: string;
    likeCount: string;
    totalReplyCount: string;
    canRate: string;
    canReply: string;
    publishedAt: string;
    updatedAt: string;
    text: string;
    from: string;
    to: Date;
    user_image: string;
    json: string;
    status: string;
    asset_url: string;
    media: string;
    is_active: boolean;
    is_assigned: boolean;
    is_read: boolean;
    is_closed: boolean;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    isSent?: boolean;
    agentName?: string;
    createdByName?: string;
    createdByAvatar?: string;
    location_url?: string;
    videoEmbeddedUrl?: string;
    iframe?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    reply?: any;

    tableName: string;
    className: string;

    /**
     * Constructor
     *
     * @param youtube
     */
    constructor(youtube) {
            this.id = youtube.id || '';
            this.youtube_id = youtube.youtube_id || '';
            this.reply_id = youtube.reply_id || '';
            this.client_id = youtube.client_id || '';
            this.client_site_id = youtube.client_site_id || '';
            this.parentId = youtube.parentId || '';
            this.commentId = youtube.commentId || '';
            this.etag = youtube.etag || '';
            this.videoId = youtube.videoId || '';
            this.channelId = youtube.channelId || '';
            this.textDisplay = youtube.textDisplay || '';
            this.textOriginal = youtube.textOriginal || '';
            this.authorDisplayName = youtube.authorDisplayName || '';
            this.authorProfileImageUrl = youtube.authorProfileImageUrl || '';
            this.authorChannelUrl = youtube.authorChannelUrl || '';
            this.authorChannelId = youtube.authorChannelId || '';
            this.viewerRating = youtube.viewerRating || '';
            this.likeCount = youtube.likeCount || '';
            this.totalReplyCount = youtube.totalReplyCount || '';
            this.canRate = youtube.canRate || '';
            this.canReply = youtube.canReply || '';
            this.publishedAt = youtube.publishedAt || '';
            this.updatedAt = youtube.updatedAt || '';
            this.text = youtube.text || '';
            this.from = youtube.from || '';
            this.to = youtube.to || '';
            this.user_image = youtube.user_image || '';
            this.json = youtube.json || '';
            this.status = youtube.status || '';
            this.is_active = youtube.is_active || '';
            this.is_assigned = youtube.is_assigned || '';
            this.is_read = youtube.is_read || '';
            this.is_closed = youtube.is_closed || '';
            this.created_by = youtube.created_by || '';
            this.updated_by = youtube.updated_by || '';
            this.created_at = youtube.created_at || '';
            this.updated_at = youtube.updated_at || '';

            this.tableName = youtube.tableName || '';
    }
}
