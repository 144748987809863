import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelRouteModule } from './label-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { FormComponent } from './form/form.component';

import { LabelService } from 'src/app/@tji/_dbShare';
import { LableTemplateFormComponent } from './lable-template-form/lable-template-form.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        FormComponent,
        LableTemplateFormComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        LabelRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ListComponent,
        FormComponent,
    ],
    providers: [
        LabelService
    ]
})

export class LabelModule { }
