export class BusinessModel {
    _id: number;
    id: number;
    __v: number;
    business_id: number;
    client_id: number;
    consumer_key: number;
    consumer_secret: number;
    wAppFullInfo?: Array<any>;
    wabaFullInfo?: Array<any>;
    waba_id: any;
    whatsappNamespace: string;

    /**
     * Constructor
     *
     * @param business
     */
    constructor(business) {
        this._id = business._id || '';
        this.id = business.id || '';
        this.__v = business.__v || '';
        this.business_id = business.business_id || '';
        this.client_id = business.client_id || '';
        this.consumer_key = business.consumer_key || '';
        this.consumer_secret = business.consumer_secret || '';
        this.wAppFullInfo = business.wAppFullInfo || {};
        this.wabaFullInfo = business.wabaFullInfo || {};
        this.waba_id = business.waba_id || '';
        this.whatsappNamespace = business.whatsappNamespace || '';
    }
}
