import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ChatService,
	YoutubeTicketService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'youtube-ticket-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})

export class ItemComponent implements OnInit, OnDestroy {
	user: User;
	ticket: any;

	constructor(public userService: UserService,
		public chatService: ChatService,
		private router: Router,
		public location: Location,
		public youtubeTicketService: YoutubeTicketService,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
				// this.checkTicket(this.ticket);
		});
	}

	
	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.chatService.changeTicket(null);
	}

	checkTicket(ticket) {
		// if (window.history.length > 1) {
		// 	this.location.back()
		// } else {
		// 	this.router.navigate(["/app/youtube-ticket"]);
		// }
		if (!(ticket && ticket.id)) {
			this.router.navigate(["/app/youtube-ticket"]);
		}
	}
	previous() {
		this.youtubeTicketService.changeAllItems([]);
		this.youtubeTicketService.isParams = false;
		if (window.history.length > 1) {
			this.location.back()
		} else {
			this.router.navigate(["/app/youtube-ticket"]);
		}
	}
}
