export class DashboardModel {
	count_widgets: Array<any>;
	chart1: Array<any>;
	chart4:Array<any>

	/**
	 * Constructor
	 * @param dashboard
	 */
	constructor(dashboard) {
		this.count_widgets = dashboard.count_widgets || [];
		this.chart1 = dashboard.chart1 || [];
		this.chart4 = dashboard.chart4 || [];
	}
}

export class DashBoardCountModel {
	name: string;
	value: number;
	icon: string

	/**
	 * Constructor
	 * @param count
	 */
	constructor(count) {
		this.name = count.name || '';
		this.value = count.value || '';
		this.icon = count.icon || '';
	}
}
