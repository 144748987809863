import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { SubMenuListComponent } from './subMenuList/subMenuList.component';
import { MenuListComponent } from './menuList/menuList.component';
import { ListComponent } from './list/list.component';
import { PermissionService, MenuGroupService, MenuService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		// resolve: { data: MenuGroupService },
		children: [
			{
				path: 'menugroups',
				component: ListComponent,
				resolve: { data: PermissionService },
			},
			{
				path: 'menugroups/:id',
				component: MenuListComponent,
				resolve: { data: PermissionService },
			},
			{
				path: 'menugroups/:id/:subId',
				component: SubMenuListComponent,
				resolve: { data: PermissionService },
			},
			{ path: '**', redirectTo: '/app/menu/menugroups' }
		]
	},
	{ path: '**', redirectTo: '/app/menu/menugroups' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class MenuGroupRouteModule { }
