import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from './data';

import {
	User, UserService, CountService,
	DashboardService, Dashboard
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-dashboard-chart1',
	templateUrl: './chart1.component.html',
	styleUrls: ['./chart1.component.scss']
})

export class Chart1Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('chart1', { static: true }) chart1: ElementRef;

	data: any[];
	dashboard: Dashboard;
    countData: Array<any> = [];

	colorScheme = {
		domain: ['#4caf50', '#7759de', '#2196f3', '#f1376e', '#39DA8A', '#e5b516']
	};
	cardColor: string = '#e2edf3';

	constructor(public dashboardService: DashboardService,
        public countService: CountService,
		private cd: ChangeDetectorRef,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		Object.assign(this, { data });
		this.dashboardService.agentDashboard.pipe(untilDestroyed(this)).subscribe(data => {
			this.dashboard = data;
			this.convertForChat(data);
		});
        this.countService.jsonData.pipe(untilDestroyed(this)).subscribe(data => {
            this.countData = data;
		});
	}

	ngOnInit() { }

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	ngOnDestroy(): void { }

	onSelect(event) { }

	
	convertForChat(chart) {
		let newCart = [];
		
		if (chart && chart.length > 0) {
			
			chart.forEach(data => {
				var widget = {};
				widget['name'] = data.account_name;
				widget['clientId'] = data.client_id;
                widget['clientSiteId'] = data.client_site_id;
				widget['clientSiteName'] = data.account_name;
				widget['clientSiteColor'] = data.clientsiteBgcolor;
				widget['clientSiteTextColor'] = data.clientsitetextcolor;
				widget['activeTickets'] = data.ActiveticketCount;
				widget['closedTickets'] = data.ClosedticketCount;
				widget['siteName'] = data.site_name;
				newCart.push(widget);
			});

		}

		this.data = newCart;
	}

	getIcon(item: any): string {
		if(item && item.siteName) {
			return item.siteName.toLowerCase();
		}
		return 'support';
	}

	getCountFromCountData(item: any, value: string): number {
        let key = item.clientId + '_' + item.clientSiteId + '_' + item.siteName + '_' + value;
        if (this.countData && this.countData[key.toLowerCase()]) {
            return this.countData[key.toLowerCase()];
		}
        return 0;
	}

    getCount(item: any, slug: string): number {
        let clientId = (item && item.clientId) ? item.clientId : null;
        let clientSiteId = (item && item.clientSiteId) ? item.clientSiteId : null;
        let siteName = (item && item.siteName) ? item.siteName : null;
        let output: number = 0;
        if (clientId && clientSiteId && siteName && slug) {
            output = this.countService.getItemCount(slug, siteName, clientSiteId);
        }
        return output;
    }

}
