import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/@tji/_dbShare';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private router : Router,
              private userService: UserService){}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		if (localStorage.getItem('tji_token') != null) {
        
      let roles = next.data["roles"] as Array<string>;
      let dashboard = next.data["dashboard"] as string;
      
      if(dashboard) {
        if(dashboard === 'Super Admin Dashboard'){
          if(this.userService.roleMatch(['Admin', 'Super Admin'])) {
            return true;
          } else if(this.userService.isAuthUserIsReseller()) {
            this.router.navigate(['/reseller/dashboard']);
            return false;
          } else if(this.userService.isAuthUserIsClient()) {
            this.router.navigate(['/app/dashboard']);
            return false;
          } else { 
            this.router.navigate(['/app/agent-dashboard']);
            return false;
          }
        } else if(dashboard === 'Admin Dashboard'){
          if(this.userService.roleMatch(['Admin', 'Super Admin'])) {
            this.router.navigate(['/app/admin-dashboard']);
            return false;
          } else if(this.userService.isAuthUserIsReseller()) {
            this.router.navigate(['/reseller/dashboard']);
            return false;
          } else if(this.userService.isAuthUserIsClient()) {
            return true;
          } else { 
            this.router.navigate(['/app/agent-dashboard']);
            return false;
          }
        } else if(dashboard === 'Reseller Dashboard'){
          if(this.userService.roleMatch(['Admin', 'Super Admin'])) {
            this.router.navigate(['/app/admin-dashboard']);
            return false;
          } else if(this.userService.isAuthUserIsReseller()) {
            return true;
          } else if(this.userService.isAuthUserIsClient()) {
            this.router.navigate(['/app/dashboard']);
            return false;
          } else { 
            this.router.navigate(['/app/agent-dashboard']);
            return false;
          }
        } else if(dashboard === 'Agent Dashboard'){
          if(this.userService.roleMatch(['Admin', 'Super Admin'])) {
            this.router.navigate(['/app/admin-dashboard']);
            return false;
          } else if(this.userService.isAuthUserIsReseller()) {
            this.router.navigate(['/reseller/dashboard']);
            return false;
          } else if(this.userService.isAuthUserIsClient()) {
            this.router.navigate(['/app/dashboard']);
            return false;
          } else { 
            return true;
          }
        } else if(dashboard === 'Premium Dashboard'){
          if(this.userService.roleMatch(['Admin', 'Super Admin', 'Reseller', 'Agent'])) {
            this.router.navigate(['/premium/dashboard']);
            return false;
          } else { 
            return true;
          }
        } else {
          this.router.navigate(['/app/dashboard']);
          return false;
        }
      }

      if (roles) {
    		var match = this.userService.roleMatch(roles);
    		if (match) return true;
    		else {
          // this.router.navigate(['/page/forbidden']);
          localStorage.clear();
      		this.router.navigate(['/']);
      		return false;
    		}
  		}
      else return true;
    }

    localStorage.clear();
  	this.router.navigate(['/']);
  	return false;
	}
}
