export class ConsumerModel {
    id: number;
    consumer_group_id: number;
    client_id: number
    json: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    jsonArray: Array<any>;

    tableName: string;
    className: string;
    createdByName?: string;
    updatedByName?: string;
    consumerGroup?: any;

    /**
     * Constructor
     *
     * @param consumer
     */
    constructor(consumer) {
            this.id = consumer.id || '';
            this.consumer_group_id = consumer.consumer_group_id || '';
            this.client_id = consumer.client_id || '';
            this.json = consumer.json || '';
            this.created_by = consumer.created_by || '';
            this.updated_by = consumer.updated_by || '';
            this.created_at = consumer.created_at || '';
            this.updated_at = consumer.updated_at || '';

            this.tableName = consumer.tableName || '';
    }
}
