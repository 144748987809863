import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { IntractiveFormComponent } from './../itrsItems/intractiveForm/intractiveForm.component';

import {
	User, UserService,
	ClientSiteService, ClientSite,
	IntractiveCategoryService, IntractiveCategory,
	IntractiveService, Intractive, IntractiveModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-itrs',
	templateUrl: './itrs.component.html',
	styleUrls: ['./itrs.component.scss']
})
export class ItrsComponent implements OnInit, OnDestroy {
	authUser: User;
	clientSite: ClientSite;

	intractives: Intractive[] = [];
	intractiveParam: any;
	selectedIntractive: Intractive;

	intractiveCategories: IntractiveCategory[] = [];
	intractiveCategoryParam: any;
	selectedCategories: any = null;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public intractiveService: IntractiveService,
		public intractiveCategoryService: IntractiveCategoryService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
		this.intractiveService.params.pipe(untilDestroyed(this)).subscribe(data => this.intractiveParam = data);
		this.intractiveCategoryService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveCategories = data;
		});
	}

	ngOnInit() {
		this.intractiveCategoryService.getAllItems();
		this.getIntractive();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	dataInit() {
		setTimeout(() => {
			this.getIntractive();
		}, 1000);
	}

	getIntractive() {
		this.selectedCategories = (this.selectedCategories && this.selectedCategories.length > 0) ? this.selectedCategories : null;
		this.intractiveParam.intractive_category_id = (this.selectedCategories) ? this.selectedCategories.toString() : null;
		this.intractiveParam.client_site_id = (this.clientSite && this.clientSite.id) ? this.clientSite.id : null;
		this.intractiveParam.itrsbot_id = this.clientSite.activeItrsbotId;
		this.intractiveService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data.length > 0 && this.clientSite && this.clientSite.activeItrsbotId) {
				this.intractives = data.filter(x => {
					return (x.itrsbot_id && x.itrsbot_id === this.clientSite.activeItrsbotId) ? true : false;
				});
			} else { this.intractives = []; }
		});
		
		this.intractiveService.getAllItems();
	}

	/** Add / Update Intractive */
	newIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(IntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractive && intractive.id) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
				switch (actionType) {
					case 'new':
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
				}
			});
	}

}
