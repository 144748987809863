import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

import { Alert, AlertModel } from 'src/app/@tji/_dbShare';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    title = null;
    message = 'Success !!!';
    icon = 'done_all';
    faIcon = null;
    type = 'info';
    image = null;

    constructor(public snackBar: MatSnackBar,
        @Inject(MAT_SNACK_BAR_DATA) public data: Alert) {
        if (data && !data.icon && data.type) {
            data.type = data.type.toLowerCase();
            this.type = (data.type) ? data.type : this.type;
            this.title = (data.title) ? data.title : this.type;
            this.message = (data.message) ? data.message : this.message;
            this.image = (data.image) ? data.image : this.image;
            this.faIcon = (data.faIcon) ? data.faIcon : this.faIcon;
            this.image = (data.image) ? data.image : this.image;
            switch (data.type.toLowerCase()) {
                case "success":
                    data.icon = 'thumb_up';
                    break;
                case "info":
                    data.icon = 'notification_important';
                    break;
                case "warn":
                    data.icon = 'warning';
                    break;
                case "warning":
                    data.icon = 'warning';
                    break;
                case "danger":
                    data.icon = 'error';
                    break;
                case "newmessage":
                    data.icon = 'email';
                    break;
                case "ticketassigned":
                case "ticketreassigned":
                    data.icon = 'archive';
                    break;
                case "aticketassigned":
                case "aticketreassigned":
                    data.icon = 'archive';
                    break;
                case "ticketabandon":
                    data.icon = 'access_alarm';
                    break;
                case "ticketremoved":
                    data.icon = 'report_off';
                    break;
                case "aticketremoved":
                    data.icon = 'report_off';
                    break;
                case "ticketclosed":
                    data.icon = 'done_outline';
                    break;
                default:
                    data.icon = 'done_all';
                    break;
            }
        }
    }

    ngOnInit() {
    }

}
