export class NotifyModel {
	id: number;
	person_id: number;
	Person_type: string;
	from_id: number;
	from_type: string;
	type_id: number;
	title: string;
	description: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;
	data?: any;

	/**
	 * Constructor
	 *
	 * @param notify
	 */
	constructor(notify) {
		this.id = notify.id || '';
		this.person_id = notify.person_id || '';
		this.Person_type = notify.Person_type || '';
		this.from_id = notify.from_id || '';
		this.from_type = notify.from_type || '';
		this.type_id = notify.type_id || '';
		this.title = notify.title || '';
		this.description = notify.description || '';
		this.created_by = notify.created_by || '';
		this.updated_by = notify.updated_by || '';
		this.created_at = notify.created_at || '';
		this.updated_at = notify.updated_at || '';

		this.tableName = notify.tableName || '';
	}
}
