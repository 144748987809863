import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveService, Intractive, IntractiveModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-itrs-store-as-intractive-form',
	templateUrl: './storeAsIntractiveForm.component.html',
	styleUrls: ['./storeAsIntractiveForm.component.scss']
})

export class StoreAsIntractiveFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'User Response Stored As:';
	authUser: User;
	intractive: Intractive;
	action: string;
	storeAsIntractiveForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public intractiveService: IntractiveService,
		public matDialogRef: MatDialogRef<StoreAsIntractiveFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractive = data;
			this.getInit(_data);
			this.storeAsIntractiveForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		let intractive = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'User Response Stored As:';
				break;
			case 'edit':
				this.dialogTitle = 'User Response Stored As:';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			typeName : 'api',
			update_on : 'json',
			json_store_as : [null, [Validators.required, Validators.minLength(1)]],
		});
	}

}
