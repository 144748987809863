import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  CatalogService,
  User, UserService,
} from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  outputs: ['uploaded']
  user: User;
  constructor(public userService: UserService,
    public catalogService: CatalogService,
    private router: Router) {
    this.userService.user.subscribe(data => this.user = data);
  }

  ngOnInit(): void {
  }
  toggleRightSidebar() {
    this.rightSideBar.toggle();
    this.catalogService.itemSource.next(null);
    this.catalogService.isAddItems.next(true);
  }

  isEditableData(data) {
    this.rightSideBar.toggle();
  }
  sync(){
    this.catalogService.sync('fbCatalog/syncCatalog');
  }
}
