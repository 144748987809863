import { Component, ElementRef, OnDestroy, OnInit, AfterContentInit, ViewChild, ViewContainerRef, EventEmitter, Output, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StringBetweenPipe } from 'src/app/pipes/string/stringBetween.pipe';
import { CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem, CdkDragExit, CdkDragEnter } from '@angular/cdk/drag-drop';
import {
	User, UserService,
	ClientSiteService, ClientSite, StencilModel,
	FbTemplateMessageService, TemplateMessage, FbHeaderText, TemplateMessageObjectModel, Header, HeaderModel, Body, BodyModel,
	Footer, FooterModel, Action, ActionModel, Text, TextModel, FbTool, ToolModel,
	Imaget, ImagetModel, Video, VideoModel, Document, DocumentModel,
	Buttons, ButtonsModel, ButtonItem, ButtonItemModel, Reply, ReplyModel,
	TemplateButton, TemplateButtonModel, TemplateButtonItemModel,
	Sections, SectionsModel, Lists, ListsModel, ListItem, ListItemModel,
	TemplateComponent, TemplateComponentModel, AlertService, TemplateDynamicButtonModel, WabaService, FbToolModel, FbHeaderTextModel, FbHeaderImageModel, headerHandleModel, CommonService, FbBodyModel, BodyHandleModel, FbFooterModel
} from 'src/app/@tji/_dbShare';
// import { ItrsbotComponent } from 'src/app/appAccount/template/itrsbot/itrsbot.component';
@UntilDestroy()
@Component({
	selector: 'fb-template-message-form',
	templateUrl: './templateMessage-form.component.html',
	styleUrls: ['./templateMessage-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class FbTemplateMessageFormComponent implements OnInit, OnDestroy, AfterContentInit {
	mode: string = null;
	dialogTitle: string = 'Create';
	category: any;
	languages: any;
	name: string = null;
	wabaList: any = [];
	wabaId: any = null;
	languageCode: string = null;
	categoryId: string = null;
	categoryName: string = null;
	subCategoryId: string = null;
	fxFlexForCol: number = 48
	carouselCount: number = 1;
	isCarousel: boolean = false;
	headerParamsCount: number = 0;
	bodyParamsCount: number = 1;
	headerTextValue: string = null;
	slectedHeaderMedia: string = null;
	clientSiteId: number = null;
	isForm: boolean = false;
	subLoader = false;

	toolHeader = [
		{ 'id': 1, 'name': 'None', 'value': 'none' },
		{ 'id': 2, 'name': 'Text', 'value': 'text' },
		{ 'id': 3, 'name': 'Media', 'value': 'media' },
	];



	calltoActionButton = [
		{ "name": 'Visit website', 'type': 'web', 'isDisabled': false, 'description': '2 buttons maximum', 'limit': 0 },
		{ "name": 'Call Phone Number', 'type': 'call', 'isDisabled': false, 'description': '1 button maximum', 'limit': 0 },
		{ "name": 'Copy Offer Code', 'type': 'offerCode', 'isDisabled': false, 'description': '1 button maximum', 'limit': 0 }
	]

	toolCarouselHeader = [
		{ 'id': 1, 'name': 'Image', 'isActive': false, slectedHeaderMedia: 'null', value: '', url: '', unique: "IMAGE" },
		{ 'id': 2, 'name': 'Video', 'isActive': false, slectedHeaderMedia: 'null', value: '', url: '', unique: "VIDEO" },
	];

	calltoActionButtonList = [];
	quickButtonList = [];
	carouselList = [];

	selectedHeader = this.toolHeader[0].value;

	authUser: User;
	clientSites: ClientSite[] = [];
	templateMessageName: string = null;
	namespace: string = null;
	templateLabel: string = null;
	templateDescription: string = null;
	selectList = true;
	isLoader: boolean = false;
	stencilId: number = null;
	templateId: number = null;
	selectedLanguage: any = null;
	// languageCode: string = null;
	templateMessage: TemplateMessage;
	isServiceProvider360: boolean = false;




	templateNamePattern = "^[a-z0-9_-]{8,15}$";

	dropStatus = true;
	selectedType = '';
	imageURL = '';
	videoURL = '';
	listCount: number = 0;

	storeData: Array<string>;
	listArray: number[] = [];

	headerAcceptTypes = ["text", "image", "document", "video"];
	bodyAcceptTypes = ["text"];
	footerAcceptTypes = ["text"];
	actionAcceptTypes = ["button", "dynamic button"];

	tools: FbTool[] = [
		{ 'type': 'text', 'value': '', 'index': null, },
		{ 'type': 'image', 'value': '', 'index': null, },
		{ 'type': 'video', 'value': '', 'index': null, },
		{ 'type': 'document', 'value': '', 'index': null, },
		{ 'type': 'button', 'value': '', 'index': null, },
		{ 'type': 'dynamic button', 'value': '', 'index': null, },
	];


	headerList: FbTool[] = [];

	bodyList = new FbBodyModel({});
	carouselBodyList = new FbBodyModel({});

	footerList = new FbFooterModel({});

	actionList: FbTool[] = [];

	templateStatus: boolean = false;

	// responseObject = new TemplateMessageObjectModel({});
	responseObject: any = [];

	showPlaceholder: boolean[] = [];
	templateComponent: TemplateComponent = new TemplateComponentModel({});
	currecntDate = new Date();
	currecntTime = '';
	itrsDataList = { templateId: '', itrsId: '', action: 'button', button: [] };
	authenticationSelected: string = 'zero_tap';
	constructor(public userService: UserService,
		public location: Location,
		public clientSiteService: ClientSiteService,
		public wabaService: WabaService,
		public fbTemplateMessageService: FbTemplateMessageService,
		private stringBetweenPipe: StringBetweenPipe,
		private _formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
		public commonService: CommonService,
		private router: Router,
		private alertService: AlertService,) {
		this.mode = this.route.snapshot.paramMap.get('mode');
		this.templateId = Number(this.route.snapshot.paramMap.get('id'));

		this.fbTemplateMessageService.getInit.pipe(untilDestroyed(this)).subscribe(data => {
			// this.category = data["category"]?data["category"]: [];
			this.languages = data["language"] ? data["language"] : [];
			if (data["category"] && data["category"].length > 0) {
				var categoryData = [];
				data["category"].forEach(category => {
					Object.entries(category.category).forEach(entry => {
						if (category.subcategory) {
							var subCategoryData = [];
							Object.entries(category.subcategory).forEach(subEntry => {
								const [key, value] = subEntry;
								var object = {
									id: key,
									name: value
								}
								subCategoryData.push(object);
							});
						}

						const [key, value] = entry;
						var object = {
							id: key,
							name: value,
							subCategory: Object.assign({ 'categoryId': key }, { 'data': subCategoryData })
						}
						categoryData.push(object);
					});
				});
				this.category = categoryData;
			}
		});

		// this.wabaService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.wabaList = data;
		// });

		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			// this.wabaList = data;
			this.wabaList = [];
			if (data && data.length > 0) {
				data.forEach(client => {
					if ((client.service_provider && client.service_provider == "option-3") || (client.service_provider && client.service_provider == "option-7")) {
						this.wabaList.push(client);
					}
				});
			}
		});

		// this.fbTemplateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.templateMessage = data;
		// 	if (this.templateMessage && this.templateMessage.id) {
		// 		this.responsiveObjectInit();
		// 	}
		// });
		this.currecntTime = formatDate(this.currecntDate, 'hh:mm a', 'en-US', '+0530');

		// this.fbTemplateMessageService.loader.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.isLoader = data;
		// });

		this.fbTemplateMessageService.templateStatus.pipe(untilDestroyed(this)).subscribe(data => {
			this.templateStatus = data;
			if (this.templateStatus == true) {
				this.fbTemplateMessageService.templateStatusUpdate.next(false);
				this.isLoader = false;
				this.location.back();
			} else {
				this.isForm = false;
				this.isLoader = false;
				this.cd.detectChanges();
			}
		});

	}

	ngOnInit() {
		this.getItem();
		// this.clientSiteService.getAllItems();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.clientSiteService.unSubscribe();
		this.clientSiteService.unSubscribeFilter();
		// this.templateMessageService.unSubscribe();
		// this.templateMessageService.unSubscribeFilter();
	}

	/** On destroy */
	ngAfterContentInit(): void {
		// this.cd.detectChanges();
	}

	unSubscribe() {
		// console.log('UnSubscribed MediaService');
	}

	doRefresh() {
		// this.cd.detectChanges();
	}

	getPageTitle(): string {
		if (this.mode && this.mode === 'edit') {
			return 'Update Template Message';
		}
		return 'Create Template Message';
	}

	getItem() {
		this.clientSiteService.getAllItems();
		this.fbTemplateMessageService.getAllInit();
		this.wabaService.getAllItems();
		// if (this.mode && this.mode === 'edit' && this.templateId) {
		// 	this.fbTemplateMessageService.getItem({ 'id': this.templateId });
		// }
		// setTimeout(() => {
		// 	this.cd.detectChanges();
		// }, 500);
	}

	changeCategory(id: any) {
		if (id != null) {
			this.isCarousel = false;
			this.subCategoryId = null;
			this.fxFlexForCol = 48;
			this.category.forEach(category => {
				if (category.id == id) {
					this.categoryName = category.name;
				}
				if (category.id == id && category.subCategory && category.subCategory.data && category.subCategory.data.length > 0) {
					this.fxFlexForCol = 32;
				}
				if (category.id == '3') {
					this.authenticationSelected = 'zero_tap';
					var appCode = {
						"package_name": "",
						"signature_hash": ""
					}
					this.supportedAppList.push(appCode);
				}
			});
		} else {
			this.isCarousel = false;
			this.subCategoryId = null;
			this.fxFlexForCol = 48;
			this.categoryName = null;
			this.authenticationSelected = 'zero_tap';
		}

	}

	changeSubCategory(id: any) {
		this.carouselList = [];
		this.isCarousel = false;
		if (id == '101') {
			var calltoActionButton = [
				{ "name": 'Visit website', 'type': 'web', 'isDisabled': false, 'description': '2 buttons maximum', 'limit': 0 },
				{ "name": 'Call Phone Number', 'type': 'call', 'isDisabled': false, 'description': '1 button maximum', 'limit': 0 }
			];
			var carouselListData = {
				header: this.toolCarouselHeader,
				body: new FbBodyModel({}),
				button: calltoActionButton,
				bodyParamsCount: 1,
				quickButtonList: [],
				calltoActionButtonList: [],
				isDeleteCard: false,
				isAddCard: true,
			}
			this.carouselList.push(carouselListData);
			this.isCarousel = true;
			this.cd.detectChanges()
		}
	}

	selectWaba(id: any) {
		var keepwaba = true;
		this.clientSiteId = id;
		this.wabaList.forEach(waba => {
			if (keepwaba) {
				this.isServiceProvider360 = false;
				if (waba.id == id && waba.service_provider == "option-7") {
					this.clientSiteId = waba.id;
					this.isServiceProvider360 = true;
					keepwaba = false;
				}
			}
		});

		// var keepCardHeader = true;
		// 	var keepCardBody = true;
		// 	var keepCardButton = true;
		// 	if (cardData) {
		// 		var buttonList = [...cardData.quickButtonList, ...cardData.calltoActionButtonList];

		// 		cardData.header.forEach(header => {
		// 			if (keepCardHeader) {
		// 				if (!this.slectedHeaderMedia) {
		// 					this.alertService.webShow('Danger', 'select Header Type');
		// 					keepCardHeader = false;
		// 					return;
		// 				}
		// 				if (this.slectedHeaderMedia == header.unique && !header.value) {
		// 					this.alertService.webShow('Danger', 'Enter Ther Header Value');
		// 					keepCardHeader = false;
		// 					return;
		// 				}
		// 			}
		// 		});
	}

	deCrease() {
		if (this.carouselCount == 1) {
			this.alertService.webShow('Danger', 'Minimum allowed number of carousel items is 1');
		} else {
			this.carouselCount--;
		}
	}

	inCrease() {
		if (this.carouselCount == 10) {
			this.alertService.webShow('Danger', 'Maximum allowed number of carousel items is 10');
		} else {
			this.carouselCount++;
		}
	}

	binaryLength(str) {
		var escstr = encodeURIComponent(str);
		var binstr = escstr.replace(/%([0-9A-F]{2})/ig, function (match, hex) {
			var i = parseInt(hex, 16);
			return String.fromCharCode(i);
		});
		var binstrLength = binstr.length;
		return binstrLength;
	}

	addHeaderVerisable(value) {

		let headerParams = this.stringBetweenPipe.transform(value, '{{', '}}');
		this.headerParamsCount = headerParams.length;
		if (headerParams.length >= 0) {
			var data = value + '{{1}}'
			this.headerParamsCount++;

			if (this.responseObject && this.responseObject.length > 0) {
				this.responseObject.forEach(element => {
					if (element.type == 'HEADER' && element.format == 'TEXT') {
						element.text = data;
					}
					if (this.headerParamsCount == 1) {
						if (!element["example"]) {
							var variable = [];
							variable.push(new headerHandleModel({}));
							element["example"] = variable;
						}
					}
				});
			}

		} else {
			this.alertService.webShow('Danger', 'Maximum allowed one varible in header');
		}
	}

	selectMedia(type: string) {
		delete this.headerParamsData;
		this.subLoader = false;
		this.slectedHeaderMedia = type;
		if (this.responseObject && this.responseObject.length > 0) {
			this.responseObject.forEach(element => {
				if (element.type == 'HEADER') {
					element.format = type;
				}
			});
		}
	}

	onHeaderChange(data) {
		this.slectedHeaderMedia = null;
		this.subLoader = false;
		// if (data.includes("{{")) {
		// 	this.headerParamsCount++;
		// 	data += this.headerParamsCount + "}}";
		// 	console.log(data);
		// 	return
		// }
		let parameter = this.stringBetweenPipe.transform(data, '{{', '}}');
		if (!parameter) {
			this.headerParamsCount = 0;
		}
		if (parameter.length > 1) {
			this.alertService.webShow('Danger', 'only one parameter allowed');
		}
		if (parameter.length == 1) {
			this.headerParamsCount = 1
		}

		if (this.headerParamsCount != 0 && this.headerParamsCount != 1) {
			this.headerParamsCount = 0;
		}

		if (this.responseObject && this.responseObject.length > 0) {
			this.responseObject.forEach(element => {
				if (element.type == 'HEADER' && element.format == 'TEXT') {
					element.text = data;
				}
				if (this.headerParamsCount == 1) {
					if (!element["example"]) {
						var variable = [];
						variable.push(new headerHandleModel({}));
						element["example"] = variable;
					}
				} else {
					delete element["example"];
				}
			});
		}
		this.headerTextValue = data;
	}

	headerParamsData: string = '';

	onHeaderChangeParams(data: any) {
		if (this.responseObject && this.responseObject.length > 0) {
			this.responseObject.forEach(element => {
				if (element.type == 'HEADER' && element.format == 'TEXT') {
					element.example = {};
					var handel = [];
					handel.push(data);
					element.example["header_text"] = handel;
				}
				if (element.type == 'HEADER' && element.format != 'TEXT') {
					if (!this.clientSiteId) {
						this.selectedHeader = this.toolHeader[0].value;
						this.alertService.webShow('Danger', 'select a waba');
						delete this.headerParamsData;
						return;
					}
					if (!data.match('.(jpg|png)$', 'i') && element.format == 'IMAGE') {
						this.alertService.webShow('Danger', 'url does not match');
						delete this.headerParamsData;
						return;
					}
					if (!data.match('.(mp4)$', 'i') && element.format == 'VIDEO') {
						this.alertService.webShow('Danger', 'url does not match');
						delete this.headerParamsData;
						return;
					}
					if (!data.match('.(pdf)$', 'i') && element.format == 'DOCUMENT') {
						this.alertService.webShow('Danger', 'url does not match');
						delete this.headerParamsData;
						return;
					}
					var params = {
						"client_site_id": this.clientSiteId,
						"url": data
					}
					this.getHandle(params);
				}
			});
		}
	}


	onHeaderChange360Params(data: any) {
		if (this.responseObject && this.responseObject.length > 0) {
			this.responseObject.forEach(element => {
				if (element.type == 'HEADER' && element.format != 'TEXT') {
					if (!this.clientSiteId) {
						this.selectedHeader = this.toolHeader[0].value;
						this.alertService.webShow('Danger', 'select a waba');
						delete this.headerParamsData;
						return;
					}
					if (!data.match('.(jpg|png)$', 'i') && element.format == 'IMAGE') {
						this.alertService.webShow('Danger', 'url does not match');
						delete this.headerParamsData;
						return;
					}
					if (!data.match('.(mp4)$', 'i') && element.format == 'VIDEO') {
						this.alertService.webShow('Danger', 'url does not match');
						delete this.headerParamsData;
						return;
					}
					if (!data.match('.(pdf)$', 'i') && element.format == 'DOCUMENT') {
						this.alertService.webShow('Danger', 'url does not match');
						delete this.headerParamsData;
						return;
					}

					var baseUrl = data;
					if (this.responseObject && this.responseObject.length > 0) {
						this.responseObject.forEach(element => {
							if (element.type == 'HEADER' && element.format != 'TEXT') {
								delete element.example.header_handle;
								var handel = [];
								handel.push(baseUrl);
								element.example["header_handle"] = handel
							}
						});
					}
				}
			});
		}
	}



	onActionHeaderChange(selectedTool) {
		this.subLoader = false;
		delete this.slectedHeaderMedia;
		if (this.responseObject && this.responseObject.length > 0) {
			this.headerParamsCount = 0;
			this.responseObject.forEach((element, index) => {
				if (element.type == 'HEADER') {
					this.responseObject.splice(index, 1);
				}
			});
		}

		if (selectedTool != 'none') {
			let newData: FbTool = new FbToolModel(selectedTool);
			this.headerList = [newData];
			this.processHeaderList(selectedTool);
		}

		if (selectedTool == 'none') {
			this.responseObject.forEach((element, index) => {
				if (element.type == 'HEADER') {
					this.responseObject.splice(index, 1);
				}
			});
		}

	}



	processHeaderList(selectedHeaderType) {
		if (selectedHeaderType) {
			switch (selectedHeaderType) {
				case 'text':
					this.responseObject.push(new FbHeaderTextModel({}));
					break;
				case 'media':
					if (!this.clientSiteId) {
						this.alertService.webShow('Danger', 'select a waba');
					}
					this.responseObject.push(new FbHeaderImageModel({}));
					break;
				default:
					// delete this.responseObject.components
					break;
			}

		}
	}


	getHandle(data: any) {
		this.subLoader = true;
		this.commonService.storeItem('fbTemplate/getHandle', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				this.subLoader = false;
				if (res.success) {
					var baseUrl = res.data.h;
					if (this.responseObject && this.responseObject.length > 0) {
						this.responseObject.forEach(element => {
							if (element.type == 'HEADER' && element.format != 'TEXT') {
								delete element.example.header_handle;
								var handel = [];
								handel.push(baseUrl);
								element.example["header_handle"] = handel
							}
						});
					}
				} else {
					this.alertService.webErrorShow(res);
					delete this.headerParamsData;
				}
			},
				error => {
					this.subLoader = false;
					console.log('Error ::' + error);
				}
			);

	}


	addBodyVerisable(value) {
		let bodyParams = this.stringBetweenPipe.transform(value, '{{', '}}');
		this.bodyParamsCount = bodyParams.length;
		if (bodyParams) {
			var count = bodyParams.length;
			count++
			var data = value + '{{' + count + '}}';
			if (this.binaryLength(data) > 1024) {
				this.alertService.webShow('Danger', '1024 characters only allowed!');
				return
			}
			this.bodyParamsCount++;
			this.bodyList.text = data;
			this.bodyList.parameters = [];
			var parameter = [];
			for (var i = 1; i <= this.bodyParamsCount; i++) {
				parameter.push({ name: "Enter content for {{" + i + "}}", value: "" });
			}
			this.bodyList.parameters = parameter;
		}
	}

	onBodyChange(data) {
		let parameter = this.stringBetweenPipe.transform(data, '{{', '}}');
		this.bodyParamsCount = parameter.length;
		this.bodyList.text = data;
		this.bodyList.parameters = [];
		for (var i = 1; i <= this.bodyParamsCount; i++) {
			this.bodyList.parameters.push({ name: "Enter content for {{" + i + "}}", value: "" });
		}
	}

	onBodyChangeParams(data: any, i: any) {
		if (this.bodyList && this.bodyList.parameters && this.bodyList.parameters.length > 0) {
			this.bodyList.parameters.forEach((parameter, index) => {
				if (index == i) {
					parameter.value = data;
				}
			});
		}
	}

	onFooterChange(data) {
		this.footerList.text = data;
	}

	addQuickButton() {
		var button = {
			type: 'QUICK_REPLY',
			text: ''
		}
		this.quickButtonList.push(button)
	}

	onQuickButtonChange(value: any, idx: any) {
		if (this.quickButtonList && this.quickButtonList.length > 0) {
			this.quickButtonList.forEach((button, index) => {
				if (index == idx) {
					button.text = value;
				}
			});
		}
	}

	deleteQuickButton(idx: any) {
		if (this.quickButtonList && this.quickButtonList.length > 0) {
			this.quickButtonList.forEach((button, index) => {
				if (index == idx) {
					this.quickButtonList.splice(index, 1);
				}
			});
		}
	}

	addActionButton(type: any, i: any) {
		this.calltoActionButton.forEach(button => {
			if (button.type === type && button.type === 'web') {
				var web = {
					type: 'URL',
					text: '',
					url: '',
					parameter: {},
					defaultButton: 'static',
					btnType: 'web',
					toolButton: [
						{ 'id': 2, 'name': 'Static', 'value': 'static' },
						{ 'id': 3, 'name': 'Dynamic', 'value': 'dynaic' },
					]
				}
				button.limit = button.limit + 1;
				if (button.limit == 2) {
					button.isDisabled = true;
				}
				this.calltoActionButtonList.push(web)
			}
			if (button.type === type && button.type === 'call') {
				var call = {
					type: 'PHONE_NUMBER',
					countryCode: '',
					text: '',
					"phone_number": '',
					btnType: 'call',
				}
				button.limit = button.limit + 1;
				if (button.limit == 1) {
					button.isDisabled = true;
				}
				this.calltoActionButtonList.push(call)
			}
			if (button.type === type && button.type === 'offerCode') {
				var copyCode = {
					"type": "COPY_CODE",
					"text": "Copy offer code",
					'offerCode': '',
					btnType: 'offerCode'
				}
				button.limit = button.limit + 1;
				if (button.limit == 1) {
					button.isDisabled = true;
				}
				this.calltoActionButtonList.push(copyCode)
			}
		});
	}

	onButtonTypeChange(value: any, idx: number) {
		if (this.calltoActionButtonList) {
			this.calltoActionButtonList.forEach((element, index) => {
				if (index == idx) {
					element.defaultButton = value;
					if (value == "static") {
						element.parameter = {}
					}
					if (value == "dynaic") {
						var buttonPerameter = { name: "Enter full url for {{ 1 }}", value: "" }
						element.parameter = buttonPerameter;
					}
				}
			});
		}
	}

	onButtonChangeValue(name: string, value: any, idx: number) {
		this.calltoActionButtonList.forEach((buttonData, index) => {
			if (index == idx) {
				if (name == "params") {
					buttonData.parameter.value = value;
				}
				if (name == "webUrlButton") {
					buttonData.url = value;
				}
				if (name == "buttonValue") {
					buttonData.text = value;
				}
				if (name == "phone") {
					buttonData.phone_number = value;
				}
				if (name == "countryCode") {
					buttonData.countryCode = value;
				}
				if (name == "offerCode") {
					buttonData.offerCode = value
				}
			}
		});
	}

	deleteActionButton(idx: number) {
		this.calltoActionButtonList.forEach((buttonData, index) => {
			if (index == idx) {
				this.calltoActionButton.forEach(buttons => {
					if (buttonData.btnType == buttons.type) {
						buttons.limit = buttons.limit - 1;
						buttons.isDisabled = false;
					}
				});
				this.calltoActionButtonList.splice(index, 1)
			}
		});
	}


	// carousel implatemt start

	selectMediaType(type: string, index: number, id: number) {
		this.slectedHeaderMedia = type;
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.header.forEach(header => {
						if (cardIndex == 0) {
							if (header.id == id) {
								header.slectedHeaderMedia = type;
								header.isActive = false
							} else {
								header.slectedHeaderMedia = 'null'
								header.isActive = false
							}
							this.carouselList.forEach((carouselheader, idx) => {
								carouselheader.header.forEach(mediaType => {
									if (idx != 0) {
										if (mediaType.id == id) {
											mediaType.slectedHeaderMedia = type;
											mediaType.value = '';
											mediaType.url = '';
											mediaType.isActive = false
										} else {
											mediaType.slectedHeaderMedia = 'null'
											mediaType.value = '';
											mediaType.url = '';
											mediaType.isActive = true
										}
									}
								});
							});

						} else {
							if (header.id == id) {
								header.slectedHeaderMedia = type;
								header.isActive = false
							} else {
								header.slectedHeaderMedia = 'null'
								header.isActive = true
							}
						}
					});
				}

			});
		}
	}

	deleteCarouselheaderParamsData(index: number, id: number) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.header.forEach(header => {
						if (header.id == id) {
							header.value = '';
							header.url = '';
						}
					});
				}

			});
		}
	}

	onHeaderCarouselChangeParams(data: any, index: number, id, unique: string) {
		if (this.carouselList && this.carouselList.length > 0) {
			if (unique != 'TEXT') {
				if (!this.clientSiteId) {
					this.selectedHeader = this.toolHeader[0].value;
					this.alertService.webShow('Danger', 'select a waba');
					this.deleteCarouselheaderParamsData(index, id);
					return;
				}
				if (!data.match('.(jpg|png)$', 'i') && unique == 'IMAGE') {
					this.alertService.webShow('Danger', 'url does not match');
					this.deleteCarouselheaderParamsData(index, id);
					return;
				}
				if (!data.match('.(mp4)$', 'i') && unique == 'VIDEO') {
					this.alertService.webShow('Danger', 'url does not match');
					this.deleteCarouselheaderParamsData(index, id);
					return;
				}
				var params = {
					"client_site_id": this.clientSiteId,
					"url": data,
					"Datas": {
						index: index,
						id: id
					}
				}
				this.getHandleCarousel(params);
			}
		}
	}

	onHeaderCarouselChange360Params(data: any, index: number, id, unique: string) {
		if (this.carouselList && this.carouselList.length > 0) {
			if (unique != 'TEXT') {
				if (!this.clientSiteId) {
					this.selectedHeader = this.toolHeader[0].value;
					this.alertService.webShow('Danger', 'select a waba');
					this.deleteCarouselheaderParamsData(index, id);
					return;
				}
				if (!data.match('.(jpg|png)$', 'i') && unique == 'IMAGE') {
					this.alertService.webShow('Danger', 'url does not match');
					this.deleteCarouselheaderParamsData(index, id);
					return;
				}
				if (!data.match('.(mp4)$', 'i') && unique == 'VIDEO') {
					this.alertService.webShow('Danger', 'url does not match');
					this.deleteCarouselheaderParamsData(index, id);
					return;
				}

				this.carouselList.forEach((carousel, cardIndex) => {
					if (cardIndex == index) {
						carousel.header.forEach(header => {
							if (header.id == id) {
								header.value = data;
							} else {
								header.value = '';
							}
						});
					}
				});
			}
		}
	}

	getHandleCarousel(data: any) {
		this.subLoader = true;
		this.commonService.storeItem('fbTemplate/getHandle', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(res => {
				this.subLoader = false;
				if (res.success) {
					var baseUrl = res.data.h;
					if (this.carouselList && this.carouselList.length > 0) {
						this.carouselList.forEach((carousel, cardIndex) => {
							if (cardIndex == res.data.Datas.index) {
								carousel.header.forEach((header, headIndex) => {
									if (header.id == res.data.Datas.id) {
										header.value = baseUrl;
									}
								});

							}
						});
					}
				} else {
					this.alertService.webErrorShow(res);
					delete this.headerParamsData;
				}
			},
				error => {
					this.subLoader = false;
					console.log('Error ::' + error);
				}
			);

	}

	onCarouselBodyChange(data, index) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.body.text = data;
					let parameter = this.stringBetweenPipe.transform(data, '{{', '}}');
					carousel.bodyParamsCount = parameter.length;
					carousel.body.parameters = [];
					for (var i = 1; i <= carousel.bodyParamsCount; i++) {
						carousel.body.parameters.push({ name: "Enter content for {{" + i + "}}", value: "" });
					}
				}
			});
		}
	}

	addCarouselBodyVerisable(value, index) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					let bodyParams = this.stringBetweenPipe.transform(value, '{{', '}}');
					carousel.bodyParamsCount = bodyParams.length;
					if (bodyParams) {
						var count = bodyParams.length;
						count++
						var data = value + '{{' + count + '}}';
						if (this.binaryLength(data) > 160) {
							this.alertService.webShow('Danger', '160 characters only allowed!');
							return
						}
						carousel.bodyParamsCount++;
						carousel.body.text = data;
						carousel.body.parameters = [];
						var parameters = [];
						for (var i = 1; i <= carousel.bodyParamsCount; i++) {
							parameters.push({ name: "Enter content for {{" + i + "}}", value: "" });
						}
						carousel.body.parameters = parameters;
					}
				}
			});
		}
	}

	onCarouselBodyChangeParams(data: any, i: any, index: number) {

		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index && carousel.body) {
					carousel.body.parameters.forEach((parameters, index1) => {
						if (index1 == i) {
							parameters.value = data;
						}
					});
				}
			});
		}

	}

	addCarouselQuickButton(index: number) {
		var button = {
			type: 'QUICK_REPLY',
			text: ''
		}
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					var buttonList = [...carousel.quickButtonList, ...carousel.calltoActionButtonList];
					if (buttonList && buttonList.length >= 2) {
						this.alertService.webShow('Danger', 'Maximum Two Button only allowed');
						return;
					} else {
						carousel["quickButtonList"].push(button)
					}
				}
			});
		}
	}

	onCarouselQuickButtonChange(value: any, index: number, idx: any) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.quickButtonList.forEach((button, buttonIndex) => {
						if (buttonIndex == idx) {
							button.text = value;
						}
					});
				}
			});
		}
	}

	deleteCarouselQuickButton(index, idx: any) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.quickButtonList.forEach((button, deleteIndex) => {
						if (deleteIndex == idx) {
							carousel.quickButtonList.splice(deleteIndex, 1);
						}
					});
				}
			});
		}
	}



	addCarouselActionButton(type: any, index: number, i: any) {

		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					var buttonList = [...carousel.quickButtonList, ...carousel.calltoActionButtonList];
					if (buttonList && buttonList.length >= 2) {
						this.alertService.webShow('Danger', 'Maximum Two Button only allowed');
						return;
					} else {
						carousel.button.forEach((button, buttonIndex) => {
							if (button.type === type && button.type === 'web') {
								var web = {
									type: 'URL',
									text: '',
									url: '',
									parameter: {},
									defaultButton: 'static',
									btnType: 'web',
									toolButton: [
										{ 'id': 2, 'name': 'Static', 'value': 'static' },
										{ 'id': 3, 'name': 'Dynamic', 'value': 'dynaic' },
									]
								}
								button.limit = button.limit + 1;
								if (button.limit == 2) {
									button.isDisabled = true;
								}
								carousel.calltoActionButtonList.push(web)
							}
							if (button.type === type && button.type === 'call') {
								var call = {
									type: 'PHONE_NUMBER',
									text: '',
									"phone_number": '',
									btnType: 'call',
								}
								button.limit = button.limit + 1;
								if (button.limit == 1) {
									button.isDisabled = true;
								}
								carousel.calltoActionButtonList.push(call)
							}
						});
					}
				}
			});
		}
	}

	onCarouselButtonTypeChange(value: any, index: number, idx: number) {

		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.calltoActionButtonList.forEach((button, ButtonIndex) => {
						if (ButtonIndex == idx) {
							button.defaultButton = value;
							if (value == "static") {
								button.parameter = {}
							}
							if (value == "dynaic") {
								var buttonPerameter = { name: "Enter full url for {{ 1 }}", value: "" }
								button.parameter = buttonPerameter;
							}
						}
					});
				}
			});
		}
	}

	onCarouselChangeValue(name: string, value: any, index: number, idx: number) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.calltoActionButtonList.forEach((button, ButtonIndex) => {
						if (ButtonIndex == idx) {
							if (name == "params") {
								button.parameter.value = value;
							}
							if (name == "webUrlButton") {
								button.url = value;
							}
							if (name == "buttonValue") {
								button.text = value;
							}
							if (name == "phone") {
								button.phone_number = value;
							}
							if (name == "countryCode") {
								button.countryCode = value;
							}
						}
					});
				}
			});
		}
	}

	deleteCarouselActionButton(index: number, idx: number) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					carousel.calltoActionButtonList.forEach((buttonData, ButtonIndex) => {
						if (ButtonIndex == idx) {
							carousel.button.forEach(buttons => {
								if (buttonData.btnType == buttons.type) {
									buttons.limit = buttons.limit - 1;
									buttons.isDisabled = false;
								}
							});
							carousel.calltoActionButtonList.splice(ButtonIndex, 1)
						}
					});
				}
			});
		}
	}

	addCarouselCard(cardData: any) {

		if (this.carouselList && this.carouselList.length <= 10) {
			var keepCardHeader = true;
			var keepCardBody = true;
			var keepCardButton = true;
			if (cardData) {
				var buttonList = [...cardData.quickButtonList, ...cardData.calltoActionButtonList];

				cardData.header.forEach(header => {
					if (keepCardHeader) {
						if (!this.slectedHeaderMedia) {
							this.alertService.webShow('Danger', 'select Header Type');
							keepCardHeader = false;
							return;
						}
						if (this.slectedHeaderMedia == header.unique && !header.value) {
							this.alertService.webShow('Danger', 'Enter Ther Header Value');
							keepCardHeader = false;
							return;
						}
					}
				});

				if (cardData.body && !cardData.body.text) {
					this.alertService.webShow('Danger', 'Enter Ther Body Value');
					return;
				}
				if (cardData.body && cardData.body.parameters && cardData.body.parameters.length > 0) {
					cardData.body.parameters.forEach(parameters => {

						if (keepCardBody) {
							if (parameters.value.length < 1) {
								this.alertService.webShow('Danger', 'Enter Body sample Variable');
								keepCardBody = false;
								return;
							}
						}
					});
				}

				if (buttonList && buttonList.length > 0) {
					if (buttonList && buttonList.length > 2) {
						this.alertService.webShow('Danger', 'Maximum Two Button only allowed');
						return;
					}
					buttonList.forEach(Buttons => {
						if (keepCardButton) {
							if (Buttons.type == "QUICK_REPLY" && !Buttons.text) {
								this.alertService.webShow('Danger', 'Enter Button value');
								keepCardButton = false;
								return;
							}
							if (Buttons.type == "URL" && !Buttons.url) {
								this.alertService.webShow('Danger', 'Enter Button URL');
								keepCardButton = false;
								return;
							}
							if (Buttons.type == "URL" && Buttons.url && Buttons.defaultButton == "dynaic" && !Buttons.parameter.value) {
								this.alertService.webShow('Danger', 'Enter Button Parameter Full URL');
								keepCardButton = false;
								return;
							}
							if (Buttons.type == "PHONE_NUMBER" && !Buttons.text) {
								this.alertService.webShow('Danger', 'Enter Button Phone Number value');
								keepCardButton = false;
								return;
							}
						}
					});

				} else {
					this.alertService.webShow('Danger', 'Button is mandatory');
					return;
				}

			}
			if (!keepCardHeader || !keepCardButton || !keepCardBody) {
				return;
			}
			var toolCarouselHeader = [
				{ 'id': 1, 'name': 'Image', 'isActive': true, slectedHeaderMedia: 'null', value: '', url: '', unique: "IMAGE" },
				{ 'id': 2, 'name': 'Video', 'isActive': true, slectedHeaderMedia: 'null', value: '', url: '', unique: "VIDEO" },
			];

			toolCarouselHeader.forEach(element => {
				if (this.slectedHeaderMedia == element.unique) {
					element.url = '';
					element.value = '';
					element.isActive = false;
				}
			});

			if (this.carouselList && this.carouselList.length > 0) {
				this.carouselList.forEach((element, cardIndex) => {
					if (cardIndex == 0) {
						element.isDeleteCard = false;
						element.isAddCard = false;
					}
					if (cardIndex >= 1) {
						element.isDeleteCard = true;
						element.isAddCard = false;
					}
				});
			}

			var calltoActionButton = [
				{ "name": 'Visit website', 'type': 'web', 'isDisabled': false, 'description': '2 buttons maximum', 'limit': 0 },
				{ "name": 'Call Phone Number', 'type': 'call', 'isDisabled': false, 'description': '1 button maximum', 'limit': 0 }
			];

			var carouselListData = {
				header: toolCarouselHeader,
				body: new FbBodyModel({}),
				button: calltoActionButton,
				bodyParamsCount: 1,
				quickButtonList: [],
				calltoActionButtonList: [],
				isDeleteCard: true,
				isAddCard: true,

			}
			if (this.carouselList.length == 9) {
				carouselListData.isAddCard = false;
			}
			this.carouselList.push(carouselListData);
		} else {
			this.alertService.webShow('Danger', 'Ten Cards only Allowed');
		}
	}

	deleteCarouselCard(index: number) {
		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == index) {
					this.carouselList.splice(cardIndex, 1);
				}
			});

			var carouseIndex = this.carouselList.length - 1;
			this.carouselList.forEach((carousel, cardIndex) => {
				if (cardIndex == carouseIndex) {
					if (cardIndex == 0) {
						carousel.isDeleteCard = false;
						carousel.isAddCard = true;
					} else {
						carousel.isDeleteCard = true;
						carousel.isAddCard = true;
					}
				}
			});

		}
	}

	supportedAppList = [];
	appSetupError: boolean = false;
	isSecurity: boolean = true;
	isExpiryTime: boolean = false;
	customValidity: boolean = true;
	customValidityTime: boolean = false;
	validityPeriod: any = 10;
	addExpiryTime: number = 10;
	addAutofillBtn: string = 'Autofill';
	addCopycodeBtn: string = 'copycode';
	validityPeriodList = [
		{
			name: '1 minutes',
			value: 1
		},
		{
			name: '2 minutes',
			value: 2
		},
		{
			name: '3 minutes',
			value: 3
		},
		{
			name: '5 minutes',
			value: 5
		},
		{
			name: '10 minutes',
			value: 10
		}
	]

	selectAuthentication(event: any = null) {
		this.supportedAppList = [];
		this.authenticationSelected = event.value;
		this.isSecurity = true;
		this.isExpiryTime = false;
		this.addExpiryTime = 10;
		this.validityPeriod = 10;
		if (this.authenticationSelected != 'copy_code') {
			var appCode = {
				"package_name": "",
				"signature_hash": ""
			}
			this.supportedAppList.push(appCode);
		}

	}

	addAnotherApp() {
		if (this.supportedAppList && this.supportedAppList.length > 0 && this.supportedAppList.length < 5) {
			this.appSetupError = false;
			var keepGoingApp = true;
			this.supportedAppList.forEach(supportedApp => {
				if (keepGoingApp) {
					if (supportedApp.package_name) {
						var myArray = supportedApp.package_name.split(".");
						if (myArray.length != 3 && !myArray[1] && !myArray[2]) {
							this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
							keepGoingApp = false;
							if (this.supportedAppList.length > 1) {
								this.appSetupError = true;
							}
							return
						}

						if (myArray[2] && myArray[2].length === 0) {
							this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
							keepGoingApp = false;
							if (this.supportedAppList.length > 1) {
								this.appSetupError = true;
							}
							return
						}

					}
					if (!supportedApp.package_name) {
						this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
						keepGoingApp = false;
					}
					if (supportedApp.signature_hash && supportedApp.signature_hash.length != 11) {
						this.alertService.webShow('Danger', 'Enter Correct Signature Hash !!!.');
						keepGoingApp = false;
						return
					}
					if (!supportedApp.signature_hash) {
						this.alertService.webShow('Danger', 'Enter Correct Signature Hash !!!.');
						keepGoingApp = false;
					}

				}
			});
			if (keepGoingApp) {
				var appCode = {
					"package_name": "",
					"signature_hash": ""
				}
				this.supportedAppList.push(appCode);
			}


		} else {
			this.alertService.webShow('Danger', 'You can add up to 5 apps only !!!.');
		}
	}

	deleteAddAnotherApp(idx: any) {
		if (this.supportedAppList && this.supportedAppList.length > 0) {
			this.supportedAppList.forEach((button, index) => {
				if (index == idx) {
					this.supportedAppList.splice(index, 1);
				}
			});
		}
	}

	onAddAnotherAppPackage(value: any, idx: any) {
		if (this.supportedAppList && this.supportedAppList.length > 0) {
			this.supportedAppList.forEach((packageName, index) => {
				if (index == idx) {
					packageName.package_name = value;
				}
			});
		}
	}

	onAddAnotherAppSignature(value: any, idx: any) {
		if (this.supportedAppList && this.supportedAppList.length > 0) {
			this.supportedAppList.forEach((signature, index) => {
				if (index == idx) {
					signature.signature_hash = value;
				}
			});
		}
	}

	onSecurityChange(event: any = null) {
		this.isSecurity = event.checked;
	}

	onExpiryTimeChange(event: any = null) {
		this.isExpiryTime = event.checked;
	}

	toggleCustomValidity(event: any = null) {
		this.customValidity = event.checked;
		if (event.checked) {
			this.validityPeriod = 10;
		} else {
			this.validityPeriod = null;
		}
	}

	doValidityPeriod(event: any = null) {
		this.validityPeriod = event.value;
		if (this.isExpiryTime && this.addExpiryTime < this.validityPeriod) {
			this.customValidityTime = true;
			this.alertService.webShow('Danger', 'It`s recommended to set a validity period that is equal to or less than your code expiry time to ensure that the code is still usable when users get the message.');
		} else {
			this.customValidityTime = false;
		}
	}

	expiryTimeValue(event: number) {
		if (event > 90) {
			this.alertService.webShow('Danger', 'Enter a value between 1 and 90');
			this.addExpiryTime = 90;
		} else if (event < 0) {
			this.addExpiryTime = 0;
			this.alertService.webShow('Danger', 'Enter a value between 1 and 90');
		} else {
			this.addExpiryTime = event;
		}
		if (this.customValidity && this.addExpiryTime < this.validityPeriod) {
			this.customValidityTime = true;
			this.alertService.webShow('Danger', 'It`s recommended to set a validity period that is equal to or less than your code expiry time to ensure that the code is still usable when users get the message.');
		}else{
			this.customValidityTime = false;
		}
	}

	createTemplate() {

		if (!this.wabaId) {
			this.alertService.webShow('Danger', 'Select a waba');
			return;
		}
		if (!this.languageCode) {
			this.alertService.webShow('Danger', 'Select a Language');
			return;
		}
		if (!this.categoryName) {
			this.alertService.webShow('Danger', 'Select a Category');
			return;
		}
		if (!this.name) {
			this.alertService.webShow('Danger', 'Enter Template name');
			return;
		}
		var keepGoingBody = true;

		var template = {
			"client_site_id": this.wabaId, //parseInt(this.wabaId),
			"name": this.name.replace(/ /g, "_").toLowerCase(),
			"category": this.categoryName,
			"allow_category_change": "true",
			"language": this.languageCode,
			"components": [],
		}
		if (this.responseObject && this.responseObject.length > 0) {
			if (this.responseObject[0]) {
				if (this.responseObject[0].example && this.responseObject[0].example.header_handle && !this.responseObject[0].example.header_handle[0]) {
					delete this.headerParamsData;
					this.alertService.webShow('Danger', 'Enter Header sample Variable');
					return;
				}
				template.components.push(this.responseObject[0])
			}
			// this.responseObject.forEach(header => {
			// 	if (header.example && header.example.header_handle && !header.example.header_handle[0]) {
			// 		this.alertService.webShow('Danger', 'Enter Header sample Veriable');
			// 		return;
			// 	}
			// 	template.components.push(header)
			// });
		}

		if (this.bodyList) {
			var bodyData = {
				"type": "BODY",
				"text": this.bodyList.text,

			}
			if (this.bodyList.parameters && this.bodyList.parameters.length > 0) {
				var example = {
					"body_text": []
				}
				var data = []

				this.bodyList.parameters.forEach(parameters => {
					if (keepGoingBody) {
						if (parameters.value.length < 1) {
							this.alertService.webShow('Danger', 'Enter Body sample Variable');
							keepGoingBody = false;
							return
						} else {
							data.push(parameters.value)
						}
					}
				});
				example.body_text.push(data);
				bodyData["example"] = example;
			}
			if (this.bodyList.text && this.bodyList.text.length > 0) {
				template.components.push(bodyData);
			} else {
				this.alertService.webShow('Danger', 'Enter text in Body');
				return;
			}
		}

		if (this.footerList && this.footerList.text) {
			var footerData = {
				"type": "FOOTER",
				"text": this.footerList.text,

			}
			template.components.push(footerData);
		}

		var quickButton = {
			type: 'BUTTONS',
			buttons: []
		}

		if (this.quickButtonList && this.quickButtonList.length > 0) {

			this.quickButtonList.forEach(quickButtons => {
				if (!quickButtons.text) {
					this.alertService.webShow('Danger', 'Enter text in Button');
					return
				} else {
					quickButton.buttons.push(quickButtons);
				}
			});

		}

		if (this.calltoActionButtonList && this.calltoActionButtonList.length > 0) {
			// var isPersmeter = false;
			// this.calltoActionButtonList.forEach(element => {
			// 	if (element.parameter && element.parameter.name) {
			// 		isPersmeter = true;	
			// 	}
			// });
			this.calltoActionButtonList.forEach(element => {
				if (element.btnType == 'web') {
					var webButton = {
						type: element.type,
						text: element.text,
						url: element.url,
					}
					// if (isPersmeter) {
					// 	webButton["example"] = [];
					// 	webButton["example"].push(element.url);
					// }
					if (!element.text) {
						this.alertService.webShow('Danger', 'Enter text in Button');
						return
					}
					if (!element.url) {
						this.alertService.webShow('Danger', 'Enter url in Button');
						return
					}
					if (element.parameter.name) {
						if (!element.parameter.value) {
							this.alertService.webShow('Danger', 'Enter text in Button variable');
							return
						} else {
							webButton["example"] = [];
							webButton.url = element.url + "{{1}}";
							webButton["example"].push(element.parameter.value);
						}
					}
					quickButton.buttons.push(webButton);
				}
				if (element.btnType == 'call') {
					var callButton = {
						type: element.type,
						text: element.text,
						phone_number: element.countryCode + element.phone_number,
					}
					if (!element.text) {
						this.alertService.webShow('Danger', 'Enter text in Button');
						return
					}
					if (!element.phone_number) {
						this.alertService.webShow('Danger', 'Enter phone number in Button');
						return
					}
					if (!element.countryCode) {
						this.alertService.webShow('Danger', 'Enter Country Code in Button');
						return
					}
					quickButton.buttons.push(callButton);
				}
				if (element.btnType == 'offerCode') {
					var offer = {
						type: "COPY_CODE",
						text: "",
						example: []
					}

					if (this.languageCode == "en_GB" || this.languageCode == "en_US" || this.languageCode == "en") {
						offer["text"] = "Copy offer code";
					} else if (this.languageCode == "ar") {
						offer["text"] = "نسخ رمز العرض";
					} else {
						offer["text"] = "Copy offer code";
					}

					if (!element.offerCode) {
						this.alertService.webShow('Danger', 'Enter Offer Code in Button');
						return
					}
					offer.example.push(element.offerCode);
					quickButton.buttons.push(offer);

				}
			});
		}

		if (quickButton.buttons && quickButton.buttons.length > 0) {
			template.components.push(quickButton);
		}
		if (!keepGoingBody) {
			return;
		}
		this.fbTemplateMessageService.store(template);
		this.isForm = true;
		this.isLoader = true;
		// this.TemplateFormStatus();
	}

	createCarouselTemplate() {

		if (this.carouselList.length > 0) {
			var array = [...this.carouselList]
			var cardData = array[array.length - 1];
			this.carouselList.forEach(cardData => {
				var keepCardHeader = true;
				var keepCardBody = true;
				var keepCardButton = true;
				if (cardData) {
					var buttonList = [...cardData.quickButtonList, ...cardData.calltoActionButtonList];

					cardData.header.forEach(header => {
						if (keepCardHeader) {
							if (!this.slectedHeaderMedia) {
								this.alertService.webShow('Danger', 'select Header Type');
								keepCardHeader = false;
								return;
							}
							if (this.slectedHeaderMedia == header.unique && !header.value) {
								this.alertService.webShow('Danger', 'Enter Ther Header Value');
								keepCardHeader = false;
								return;
							}
						}
					});

					if (cardData.body && !cardData.body.text) {
						this.alertService.webShow('Danger', 'Enter Ther Body Value');
						return;
					}
					if (cardData.body && cardData.body.parameters && cardData.body.parameters.length > 0) {
						cardData.body.parameters.forEach(parameters => {

							if (keepCardBody) {
								if (parameters.value.length < 1) {
									this.alertService.webShow('Danger', 'Enter Body sample Variable');
									keepCardBody = false;
									return;
								}
							}
						});
					}

					if (buttonList && buttonList.length > 0) {
						if (buttonList && buttonList.length > 2) {
							this.alertService.webShow('Danger', 'Maximum Two Button only allowed');
						}
						buttonList.forEach(Buttons => {
							if (keepCardButton) {
								if (Buttons.type == "QUICK_REPLY" && !Buttons.text) {
									this.alertService.webShow('Danger', 'Enter Button value');
									keepCardButton = false;
									return;
								}
								if (Buttons.type == "URL" && !Buttons.url) {
									this.alertService.webShow('Danger', 'Enter Button URL');
									keepCardButton = false;
									return;
								}
								if (Buttons.type == "URL" && Buttons.url && Buttons.defaultButton == "dynaic" && !Buttons.parameter.value) {
									this.alertService.webShow('Danger', 'Enter Button Parameter Full URL');
									keepCardButton = false;
									return;
								}
								if (Buttons.type == "PHONE_NUMBER" && !Buttons.text) {
									this.alertService.webShow('Danger', 'Enter Button Phone Number value');
									keepCardButton = false;
									return;
								}
							}
						});

					} else {
						this.alertService.webShow('Danger', 'Button is mandatory');
						return;
					}
					if (!keepCardHeader || !keepCardButton || !keepCardBody) {
						return;
					}
				}
			});

		}



		if (!this.wabaId) {
			this.alertService.webShow('Danger', 'Select a waba');
			return;
		}
		if (!this.languageCode) {
			this.alertService.webShow('Danger', 'Select a Language');
			return;
		}
		if (!this.categoryName) {
			this.alertService.webShow('Danger', 'Select a Category');
			return;
		}
		if (!this.name) {
			this.alertService.webShow('Danger', 'Enter Template name');
			return;
		}
		var keepGoingBody = true;

		var template = {
			"client_site_id": this.wabaId, //parseInt(this.wabaId),
			"name": this.name.replace(/ /g, "_").toLowerCase(),
			"category": this.categoryName,
			"allow_category_change": "true",
			"language": this.languageCode,
			"components": [],
		}

		var cardsData = {
			type: "CAROUSEL",
			cards: []
		}

		if (this.bodyList) {
			var bodyData = {
				"type": "BODY",
				"text": this.bodyList.text,

			}
			if (this.bodyList.parameters && this.bodyList.parameters.length > 0) {
				var example = {
					"body_text": []
				}
				var data = []

				this.bodyList.parameters.forEach(parameters => {
					if (keepGoingBody) {
						if (parameters.value.length < 1) {
							this.alertService.webShow('Danger', 'Enter Body sample Variable');
							keepGoingBody = false;
							return
						} else {
							data.push(parameters.value)
						}
					}
				});
				example.body_text.push(data);
				bodyData["example"] = example;
			}
			if (this.bodyList.text && this.bodyList.text.length > 0) {
				template.components.push(bodyData);
			} else {
				this.alertService.webShow('Danger', 'Enter text in Body');
				return;
			}
		}

		if (this.carouselList && this.carouselList.length > 0) {
			this.carouselList.forEach((carousel, cardIndex) => {
				var carouselDatas = {
					components: [],
				};
				if (carousel.header) {
					carousel.header.forEach(header => {
						if (this.slectedHeaderMedia == header.slectedHeaderMedia) {
							var headers = {
								"type": "HEADER",
								"format": this.slectedHeaderMedia,
								"example": {
									"header_handle": [
										header.value
									]
								}
							}
							carouselDatas.components.push(headers);
						}
					});
				}
				if (carousel.body) {
					var bodyData = {
						"type": "BODY",
						"text": carousel.body.text,

					}
					if (carousel.body.parameters && carousel.body.parameters.length > 0) {
						var example = {
							"body_text": []
						}
						var data = []

						carousel.body.parameters.forEach(parameters => {
							data.push(parameters.value)
						});
						example.body_text.push(data);
						bodyData["example"] = example;
					}
					if (carousel.body.text && carousel.body.text.length > 0) {
						carouselDatas.components.push(bodyData);
					}
				}

				var quickButton = {
					type: 'BUTTONS',
					buttons: []
				}

				if (carousel.quickButtonList && carousel.quickButtonList.length > 0) {
					carousel.quickButtonList.forEach(quickButtons => {
						quickButton.buttons.push(quickButtons);
					});

				}

				if (carousel.calltoActionButtonList && carousel.calltoActionButtonList.length > 0) {
					carousel.calltoActionButtonList.forEach(element => {
						if (element.btnType == 'web') {
							var webButton = {
								type: element.type,
								text: element.text,
								url: element.url,
							}
							if (element.parameter.name) {
								webButton["example"] = [];
								webButton.url = element.url + "{{1}}";
								webButton["example"].push(element.parameter.value);
							}
							quickButton.buttons.push(webButton);
						}
						if (element.btnType == 'call') {
							var callButton = {
								type: element.type,
								text: element.text,
								phone_number: element.countryCode + element.phone_number,
							}
							quickButton.buttons.push(callButton);
						}
					});
				}

				if (quickButton.buttons && quickButton.buttons.length > 0) {
					carouselDatas.components.push(quickButton);
				}

				cardsData.cards.push(carouselDatas);
			});
		}


		template.components.push(cardsData);

		this.fbTemplateMessageService.store(template);
		this.isForm = true;
		this.isLoader = true;

	}

	createAuthTemplate() {
		if (!this.wabaId) {
			this.alertService.webShow('Danger', 'Select a waba');
			return;
		}
		if (!this.languageCode) {
			this.alertService.webShow('Danger', 'Select a Language');
			return;
		}
		if (!this.categoryName) {
			this.alertService.webShow('Danger', 'Select a Category');
			return;
		}
		if (!this.name) {
			this.alertService.webShow('Danger', 'Enter Template name');
			return;
		}

		var template = {
			"client_site_id": this.wabaId, //parseInt(this.wabaId),
			"name": this.name.replace(/ /g, "_").toLowerCase(),
			"category": this.categoryName,
			"allow_category_change": "true",
			"language": this.languageCode,
			"components": [],
		}
		var bodyData = {
			"type": "body",
			"add_security_recommendation": this.isSecurity,

		}
		if (this.customValidity) {
			var footer = {
				"type": "footer",
				"code_expiration_minutes": this.validityPeriod,

			}
			template.components.push(footer);
		}
		var coppyButton = {
			type: 'buttons',
			buttons: []
		}
		if (this.authenticationSelected == 'copy_code') {
			var button = {
				"type": "otp",
				"otp_type": "copy_code",
				"text": this.addCopycodeBtn
			}
			coppyButton.buttons.push(button);
		}
		if (this.authenticationSelected == 'zero_tap') {
			var buttonZero = {
				"type": "buttons",
				buttons: [
					{
						"type": "otp",
						"otp_type": "zero_tap",
						"text": this.addCopycodeBtn,
						"autofill_text": this.addAutofillBtn,
						"zero_tap_terms_accepted": true,
						"supported_apps": []
					}
				]
			}
			if (this.supportedAppList && this.supportedAppList.length > 0) {
				this.appSetupError = false;
				var keepGoingApp = true;
				this.supportedAppList.forEach(supportedApp => {
					if (keepGoingApp) {
						if (supportedApp.package_name) {
							var myArray = supportedApp.package_name.split(".");
							if (myArray.length != 3 && !myArray[1] && !myArray[2]) {
								this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
								keepGoingApp = false;
								if (this.supportedAppList.length > 1) {
									this.appSetupError = true;
								}
								return
							}

							if (myArray[2] && myArray[2].length === 0) {
								this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
								keepGoingApp = false;
								if (this.supportedAppList.length > 1) {
									this.appSetupError = true;
								}
								return
							}

						}
						if (!supportedApp.package_name) {
							this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
							keepGoingApp = false;
						}
						if (supportedApp.signature_hash && supportedApp.signature_hash.length != 11) {
							this.alertService.webShow('Danger', 'Enter Correct Signature Hash !!!.');
							keepGoingApp = false;
							return
						}
						if (!supportedApp.signature_hash) {
							this.alertService.webShow('Danger', 'Enter Correct Signature Hash !!!.');
							keepGoingApp = false;
						}

					}
					if (keepGoingApp) {
						var appCode = {
							"package_name": supportedApp.package_name,
							"signature_hash": supportedApp.signature_hash
						}
						buttonZero.buttons[0].supported_apps.push(appCode);
					}
				});

			} else {
				this.alertService.webShow('Danger', 'please enter at least one App setup !!!.');
			}
		}
		if (this.authenticationSelected == 'one_tap') {
			var buttonOne = {
				"type": "buttons",
				"buttons": [
					{
						"type": "otp",
						"otp_type": "one_tap",
						"text": this.addCopycodeBtn,
						"autofill_text": this.addAutofillBtn,
						"zero_tap_terms_accepted": true,
						"supported_apps": []
					}
				]
			}
			if (this.supportedAppList && this.supportedAppList.length > 0) {
				this.appSetupError = false;
				var keepGoingApp = true;
				this.supportedAppList.forEach(supportedApp => {
					if (keepGoingApp) {
						if (supportedApp.package_name) {
							var myArray = supportedApp.package_name.split(".");
							if (myArray.length != 3 && !myArray[1] && !myArray[2]) {
								this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
								keepGoingApp = false;
								if (this.supportedAppList.length > 1) {
									this.appSetupError = true;
								}
								return
							}

							if (myArray[2] && myArray[2].length === 0) {
								this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
								keepGoingApp = false;
								if (this.supportedAppList.length > 1) {
									this.appSetupError = true;
								}
								return
							}

						}
						if (!supportedApp.package_name) {
							this.alertService.webShow('Danger', 'Enter Correct Package Name !!!.');
							keepGoingApp = false;
						}
						if (supportedApp.signature_hash && supportedApp.signature_hash.length != 11) {
							this.alertService.webShow('Danger', 'Enter Correct Signature Hash !!!.');
							keepGoingApp = false;
							return
						}
						if (!supportedApp.signature_hash) {
							this.alertService.webShow('Danger', 'Enter Correct Signature Hash !!!.');
							keepGoingApp = false;
						}

					}
					if (keepGoingApp) {
						var appCode = {
							"package_name": supportedApp.package_name,
							"signature_hash": supportedApp.signature_hash
						}
						buttonOne.buttons[0].supported_apps.push(appCode);
					}
				});

			} else {
				this.alertService.webShow('Danger', 'please enter at least one App setup !!!.');
			}
		}
		if (this.isExpiryTime && this.addExpiryTime && this.customValidity) {
			if (this.customValidity && this.validityPeriod < this.addExpiryTime) {
				template['message_send_ttl_seconds'] = this.addExpiryTime;
			} 
			else {
				this.customValidityTime = true;
				this.alertService.webShow('Danger', 'It`s recommended to set a validity period that is equal to or less than your code expiry time to ensure that the code is still usable when users get the message.');
			}
		}
		if (!this.customValidity && !this.isExpiryTime && this.addExpiryTime) {
			template['message_send_ttl_seconds'] = this.addExpiryTime;
		}
		if (this.authenticationSelected == 'copy_code') {
			template.components.push(coppyButton);
		}
		if (this.authenticationSelected == 'zero_tap') {
			template.components.push(buttonZero);
		}
		if (this.authenticationSelected == 'one_tap') {
			template.components.push(buttonOne);
		}
		template.components.push(bodyData);
		this.fbTemplateMessageService.store(template);
		this.isForm = true;
		this.isLoader = true;
	}

	resetTemplate() {
		this.languageCode = null;
		this.name = "";
		this.wabaId = null;
		this.categoryName = null;
		this.calltoActionButtonList = [];
		this.quickButtonList = [];
		this.footerList = new FbFooterModel({});
		this.headerList = [];
		this.bodyList = new FbBodyModel({});
		this.carouselList = []
		var toolCarouselHeader = [
			{ 'id': 1, 'name': 'Image', 'isActive': false, slectedHeaderMedia: 'null', value: '', url: '', unique: "IMAGE" },
			{ 'id': 2, 'name': 'Video', 'isActive': false, slectedHeaderMedia: 'null', value: '', url: '', unique: "VIDEO" },
		];
		var carouselListData = {
			header: toolCarouselHeader,
			body: new FbBodyModel({}),
			button: this.calltoActionButton,
			bodyParamsCount: 1,
			quickButtonList: [],
			calltoActionButtonList: [],
			isDeleteCard: true,
			isAddCard: true,

		}
		this.carouselList.push(carouselListData);
	}

	TemplateFormStatus(status: any) {
		if (status == true) {
			this.isLoader = false;
			this.location.back();
		} else if (status == false) {
			this.isLoader = false;
			this.isForm = false;
		}
	}

}


