import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface DialogData {
    title: string;
    message: string;
    type: string;
    info: string;
    infoDetails: string;
    infoDetails1: string;
}

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close({ data: false });
    }

    onOkClick(): void {
        this.dialogRef.close({ data: true });
    }

    getTitle(type: string) {
        if(this.data && this.data.title) {
            return this.data.title;
        }
        switch (type) {
            case "success":
                return "Complete Action !!!";
                break;
            case "info":
                return "For Your Kind Information !!!";
                break;
            case "warn":
                return "Warning !!!";
                break;
            case "delete":
                return "Do You Want to Delete !!!";
                break;
            case "alert":
                return "Alert !!!";
                break;
            default:
                return "Complete Action !!!";
                break;
        }
    }

    getButtonText(type: string) {
        switch (type) {
            case "success":
                return "Complete";
                break;
            case "info":
                return "OK";
                break;
            case "warn":
                return "Confirm";
                break;
            case "delete":
                return "Delete";
                break;
            default:
                return "Ok";
                break;
        }
    }
}
