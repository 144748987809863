import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	RRoleService, RRole,
	RPermissionService, RPermission
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'role-item-permission',
	templateUrl: './permission.component.html',
	styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit, OnDestroy {
	authUser: User;
	role: RRole;
	permissions: any;
	groupedPermissions: any[] = [];;
	param: any;
	subLoader: boolean = false;
	loader: boolean = true;
	permissionsList: any;

	constructor(public userService: UserService,
		public rroleService: RRoleService,
		public rpermissionService: RPermissionService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private route: ActivatedRoute) {
		this.rroleService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.role = data;
			if (this.role && this.role.permission) {
				this.permissionsList = this.role.permission
			}
		});
		this.rpermissionService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rpermissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			// this.permissions = data;
			if (data !== this.permissions) { this.subLoader = false; }
			this.permissions = data;
			if (this.permissions && this.permissions['allowed_permissions']) {
				this.permissions['allowed_permissions'].forEach(permission => {
					permission.has_permission = false;
				});
				this.permissions['allowed_permissions'].forEach(permission => {
					this.permissions['input_default'].forEach(defaultPermission => {
						if (defaultPermission == permission.id) {
							permission.has_permission = true;
						}
					});
				});
			}
			this.rolesPermission();
		});
	}

	ngOnInit() {
		this.doLoader();
		this.getPermissions();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	/** On destroy */
	ngOnDestroy(): void { }


	rolesPermission() {

		if (this.permissionsList && this.permissionsList.length > 0) {
			this.permissions['allowed_permissions'].forEach(permission => {
				this.permissionsList.forEach(defaultPermission => {
					if (defaultPermission.permission_id == permission.id) {
						permission.has_permission = true;
					}
				});
			});
		}

		if (this.permissions && this.permissions['modules']) {
			this.permissions['modules'].forEach(element => {
				element['permission'] = [];
			});
			this.permissions['modules'].forEach((module, index) => {
				this.permissions['allowed_permissions'].forEach(permission => {
					if (module.name == permission.module) {
						this.permissions['modules'][index]['permission'].push(permission);
					}
				});
			});
			this.permissions = this.permissions;
		}
	}

	getPermissions() {
		var data = {
			id: this.route.snapshot.params.id,
		}
		this.rpermissionService.getRoleItem(data);
	}

	checkPermission(permissionId): boolean {
		if (this.role && this.role.permission && this.role.permission.length > 0) {
			return (this.role.permission.find(x => x.id === permissionId)) ? true : false;
		}
		return false;
	}

	assignPermission(permission, event) {
		this.permissions.modules.forEach(modules => {
			if (modules.name == permission.module) {
				modules.permission.forEach(permissions => {
					if (permissions.id == permission.id) {
						permissions["has_permission"] = (event) ? true : false
					}
				});
			}
		});
	}

	assignPermissions() {

		var permissionList = [];
		this.permissions.modules.forEach(modules => {
			modules.permission.forEach(permissions => {
				if (permissions.has_permission) {
					permissionList.push(permissions.id)
				}
			});
		});

		let input = {
			'permission_id': permissionList.toString(),
			'role_id': this.role.id,
			'state': 'true',
		};
		this.rroleService.updateRolesPermission('reseller/roles/updateRolesPermission', input);
	}
}
