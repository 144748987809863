import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { Router } from '@angular/router';
import {
  User, UserService,
  RTemplateUsageReportsService, RTemplateUsageReports
} from 'src/app/@tji/_dbShare';

import { Location } from '@angular/common';
import * as moment from 'moment-timezone';

@UntilDestroy()
@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit {

  loader: boolean = true;
  authUser: User;
  templateUsage: RTemplateUsageReports;
  templateUsageList: RTemplateUsageReports;
  param: any;
  tempNumberSearch: string = "";
  isCloudTemplate: boolean = false;



  constructor(public userService: UserService,
    public rtemplateUsageReportsService: RTemplateUsageReportsService,
    public matDialog: MatDialog,
    private orderByPipe: OrderByPipe, public location: Location,
    public router: Router) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.rtemplateUsageReportsService.itemTemplate.pipe(untilDestroyed(this)).subscribe(data => this.templateUsageList = data);
  }

  ngOnInit(): void {
    this.doLoader();
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 1000);
  }

  searchClear() {
    this.tempNumberSearch = "";
    this.templateUsage = this.templateUsageList;
  }

}
