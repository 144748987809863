import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { BusinessService, CatalogProductService, CatalogService, DeliverySettingsService, EordersService, PaymentsService, WabaService } from 'src/app/@tji/_dbShare';
import { MainProductComponent } from './main-product/main-product.component';
import { ProductsComponent } from './products/products.component';
import { DeliverySettingsComponent } from './delivery-settings/delivery-settings.component';
import { PaymentListComponent } from './payment-list/payment-list.component'
import { OrderListComponent } from './order-list/order-list.component';
import { OrderConfirmedComponent } from './order-confirmed/order-confirmed.component';
import { OrderGroupListComponent } from './order-group-list/order-group-list.component';
import { MainCategoryComponent } from './main-category/main-category.component';
import { CategoryService } from 'src/app/@tji/_dbShare/catalog/category';
const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: CatalogService, business: BusinessService, data1: WabaService },
	},
	{
		path: ':mode/:id',
		component: MainProductComponent,
		resolve: { data: CatalogProductService },
	},
	{
		path: 'product',
		component: ProductsComponent,
		resolve: { data: CatalogProductService },
	},
	{
		path: 'delivery-setting',
		component: DeliverySettingsComponent,
		resolve: { data: DeliverySettingsService },
	},
	{
		path: 'payments',
		component: PaymentListComponent,
		resolve: { data: PaymentsService },
	},
	{
		path: 'orders',
		component: OrderGroupListComponent,
		resolve: { data: EordersService },
	},
	{
		path: 'confirmed-orders',
		component: OrderConfirmedComponent,
	},
	{
		path: 'category',
		component: MainCategoryComponent,
		resolve: { category: CategoryService }
	},
	{ path: '**', redirectTo: '/app/catalog' },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CatalogRoutingModule { }
