import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';

import {
	User, UserService,
	CommentService, Comment,
	ChildCommentService, ChildComment,
	CommonService,
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

@UntilDestroy()
@Component({
	selector: 'open-comment-history',
	templateUrl: './commentHistory.component.html',
	styleUrls: ['./commentHistory.component.scss']
})

export class CommentHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeTweet: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Comment History';
	authUser: User;

	comment: Comment;
	commentParam: any;
	childComments: ChildComment[] = [];
	childCommentParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;

	constructor(public userService: UserService,
		public commentService: CommentService,
		public childCommentService: ChildCommentService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialogRef: MatDialogRef<CommentHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		this.getInit(_data);
	}

	ngOnInit() {
		this.commentService.params.pipe(untilDestroyed(this)).subscribe(data => this.commentParam = data);
		this.commentService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.comment = data;
		});
		this.userService.user.subscribe(data => this.authUser = data);
		// this.commentService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.comment = data;
		// });
		// this.childCommentService.params.pipe(untilDestroyed(this)).subscribe(data => this.childCommentParam = data);
		// this.childCommentService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.childComments = data;
		// });
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.commentService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.comment = data.item;
		this.getChildComments(data.item);
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Comment History';
				break;
			default:
				break;
		}
	}

	getChildComments(comment) {
		if (comment && comment.id) {
			this.childCommentService.getAllItems();
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.childCommentService.changeAllItems([]);
	}

	/** Close Tickets */
	doReply(comment: Comment = null): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: this.comment.resourceInfo,
				comment: comment,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.replayComment('comments/addcomment', result.formValue);
			}
		});
	}

	transform(text: any): any {
		return text.replace(/\n/g, `<br>`);
	}

	/** Delete Social Site */
	deleteCommends(comment: Comment): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var id = {
					commentId: comment._id
				}
				this.commentService.destroy(id);
			}
		});
	}

	deleteReplayCommends(replay: any): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var id = {
					commentId: replay.comment_id,
					replyId: replay.id
				}
				this.commentService.destroyReplay(id);
			}
		});
	}
}
