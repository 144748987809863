import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from 'src/app/@tji/_dbShare';

@Component({
	selector: 'verify-success',
	templateUrl: './verifySuccess.component.html',
	styleUrls: ['./verifySuccess.component.scss']
})
export class VerifySuccessComponent implements OnInit {

	constructor(private _formBuilder: UntypedFormBuilder,
		public userService: UserService,
		private router: Router) {
	}

	ngOnInit() {
	}

}
