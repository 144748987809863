import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ListComponent } from './../list/list.component';

import { User, UserService, ItrsbotService,
		 ClientSite, ClientSiteService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-itrsbot-main',
	templateUrl: './mainItrsbot.component.html',
	styleUrls: ['./mainItrsbot.component.scss']
})

export class MainItrsbotComponent implements OnInit, OnDestroy {
	user: User;
	@ViewChild(ListComponent) listComponent;
	params: any;
	clientSiteParams: any;
	clientId: number = Number(this.route.snapshot.paramMap.get('clientId'));
	clientSiteId: number = Number(this.route.snapshot.paramMap.get('clientSiteId'));

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		private router: Router,
		private route: ActivatedRoute,
		public location: Location,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.itrsbotService.params.pipe(untilDestroyed(this)).subscribe(data => this.params = data);
		// this.clientSiteService.params.pipe(untilDestroyed(this)).subscribe(data => this.clientSiteParams = data);
	}

	ngOnInit() {
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	dataInit() {
		this.params.client_id = this.clientId;
		if(this.clientSiteId) {
			this.params.client_site_id = this.clientSiteId;
			this.params.resource_id = this.clientSiteId;
			this.params.resource_type = 'clientsite';
		} else {
			this.params.client_site_id = null;
			this.params.resource_id = null;
			this.params.resource_type = null;
		}
		this.clientSiteParams.client_id = this.clientId;
		// this.itrsbotService.getAllItems();
		// this.clientSiteService.getAllItems();
	}
}
