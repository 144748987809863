import { map } from 'rxjs/operators';
import { Injectable, Pipe } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { finalize, share, shareReplay, tap, retryWhen, take } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { Alert } from '../alert';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { AudioService } from 'src/app/@tji/_dbShare/alert/audio.service';
import { LoaderService } from 'src/app/library/loader/loader.service';
import { environment } from '../../../../environments/environment';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})

export class CommonService {
	public logo_image: string = '../../../../assets/images/ai-octopus-logo.png' // environment.domainSecured + '/api/directory/image/1';
	public backendUrl: string = environment.domainSecured + '/api/';
	public backendUrlOptionOne: string = environment.domainSecuredOne + '/api/';
	public backendUrlDev: string = environment.domainDevelopment + '/api/';
	public baseUrlPrint: string = environment.domainSecuredOne + '/';

	public backendUrlAiProd: string = environment.domainSecuredOne + '/';
	public backendUrlAiDev: string = environment.domainDevelopment + '/';

	

	public logo: string = 'assets/img/scrm/scrm-logo_bg.png';
	public logoBlank: string = 'assets/img/scrm/scrm-logo.png';
	public logoAvatar: string = 'assets/img/scrm/logo_avatar.png';
	public logoAvatarGrey: string = 'assets/img/scrm/logo_avatar_grey.png';
	public noUser: string = 'assets/img/no-user.jpg';
	public audioSource: string = "../../../assets/audio/octopus-notification.mp3";

	items: any;
	item: any;
	onChanged: BehaviorSubject<any>;
	onSelected: BehaviorSubject<any>;

	constructor(private httpClient: HttpClient,
		private loaderService: LoaderService,
		private toastr: ToastrService,
		private alertService: AlertService,
		private cookieService: CookieService,
		private router: Router,
		private audioService: AudioService,
		private snackBar: MatSnackBar) {
		this.onChanged = new BehaviorSubject([]);
	}

	// tji_domain: string = this.backendUrl;
	tji_domain: string = '';
	client_id: number = Number(localStorage.getItem('client_id'));
	reseller_id: number = Number(localStorage.getItem('reseller_id'));
	isAgent: boolean = false;
	tji_token: string = (localStorage.getItem('tji_token')) ? localStorage.getItem('tji_token').replace('"', '').replace('"', '') : null;

	header = new HttpHeaders({
		'Content-Type': 'application/json'
	});
	premiumHeader = new HttpHeaders({
		'Content-Type' : 'application/json; charset=utf-8',
        'Accept'       : 'application/json',
	});


	private showLoader(): void {
		this.loaderService.show();
	}

	private hideLoader(): void {
		this.loaderService.hide();
	}

	changeIsAuthAgent(status: boolean) {
		this.isAgent = status;
	}

	changeClientId(clientId: number) {
		localStorage.setItem('client_id', JSON.stringify(clientId));
		this.client_id = Number(localStorage.getItem('client_id'));
	}

	changeResellerId(resellerId: number) {
		localStorage.setItem('reseller_id', JSON.stringify(resellerId));
		this.reseller_id = Number(localStorage.getItem('reseller_id'));
	}

	private getBackendUrl(e: any): void {
		// this.tji_domain = (e == 'optionOne' ? this.backendUrlOptionOne : this.backendUrl);
		if ((e && e == 'optionAiOne') || (e && e == 'optionAiOct')) {
			this.tji_domain = e == 'optionAiOne' ? this.backendUrlAiProd : this.backendUrlAiProd && e == 'optionAiOct' ? this.backendUrlAiDev : this.backendUrlAiDev;
		} else {
			this.tji_domain = e == 'optionOne' ? this.backendUrlOptionOne : this.backendUrl && e == 'optionOct' ? this.backendUrlDev : this.backendUrl;
		}
	}

	//

	// Get All Items
	getAll(url: string, input: any = null, withResellerId: boolean = true, urlOption: any = null): Observable<any> {
		this.showLoader();
		this.getBackendUrl(urlOption);
		if (this.client_id && this.client_id !== null && this.client_id > 0) {
			if (input === null) { input = {}; }
			input.client_id = (input && input.client_id) ? input.client_id : this.client_id;
		}
		if (!this.isAgent && withResellerId && this.reseller_id && this.reseller_id !== null && this.reseller_id > 0) { input.reseller_id = this.reseller_id; }
		return this.httpClient.get(this.tji_domain + url, { headers: this.header, params: input })
			.pipe(map((response: any) => {
				this.items = response;
				this.onChanged.next(this.items);
				return this.items;
			}),
				share(),
				finalize(() => {
					this.hideLoader();
				}),
				retry({ count: 2, delay: 1000 }),
				catchError(error => this.handleError(error))
			);
	}

	// Get Item by Selected ID
	getItem(url: string, id: number, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		return this.httpClient.get(this.tji_domain + url + '/' + id, { headers: this.header }).pipe(
			map((response: any) => {
				this.item = response;
				this.onChanged.next(this.item);
				return this.item;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			catchError(error => this.handleError(error))
		);
	}

	// Store New Item Data
	storeItem(url: string, data: any, withResellerId: boolean = true, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		// if (this.client_id && this.client_id !== null && this.client_id > 0) {
		// 	if (data === null) { data = {}; }
		// 	data.client_id = (data && data.client_id) ? data.client_id : this.client_id;
		// }
		if (data === null) { data = {}; }
		if (!this.isAgent && withResellerId && this.reseller_id && this.reseller_id !== null && this.reseller_id > 0) { data.reseller_id = this.reseller_id; }
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error)),
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	// backoff(maxTrigger, ms) {
	// 	return Pipe()
	// }

	// Update Item by Selected ID
	updateItem(url: string, id: number, data: any, withResellerId: boolean = true, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		if (this.client_id && this.client_id !== null && this.client_id > 0) {
			if (data === null) { data = {}; }
			data.client_id = (data && data.client_id) ? data.client_id : this.client_id;
		}
		if (!this.isAgent && withResellerId && this.reseller_id && this.reseller_id !== null && this.reseller_id > 0) { data.reseller_id = this.reseller_id; }
		const body = JSON.stringify(data);
		return this.httpClient.put(this.tji_domain + url + '/' + id, body, { headers: this.header }).pipe(
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			catchError(error => this.handleError(error))
		);
	}

	// Delete Item by Selected ID
	deleteItem(url: string, id: number, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		return this.httpClient.delete(this.tji_domain + url + '/' + id, { headers: this.header }).pipe(
			map((response: any) => {
				return response;
			}),
			share());
	}

	// Get Item by Selected ID
	hitUrl(url: string, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		return this.httpClient.get(this.tji_domain + url, { headers: this.header }).pipe(
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			catchError(error => this.handleError(error))
		);
	}

	// Get Url
	getUrl(url: string, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		let newHeader = new HttpHeaders({
			'Content-Type': 'application/xlsx',
			responseType: 'blob',
			Accept: 'application/xlsx',
			observe: 'response'
		});
		return this.httpClient.get(this.tji_domain + url, { headers: newHeader }).pipe(
			map((response: any) => {
				return response;
			}),
			share());
	}

	// Get Url
	getRawUrl(url: string, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		let newHeader = new HttpHeaders({
			'Content-Type': 'application/xlsx',
			skip: 'true',
		});
		return this.httpClient.get(url, { headers: newHeader }).pipe(
			map((response: any) => {
				return response;
			}),
			share());
	}

	// Get Url
	getRawUrlWithAuth(url: string, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		let newHeader = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(url, { headers: newHeader }).pipe(
			map((response: any) => {
				return response;
			}),
			share());
	}

	// Get Item by Selected name
	getItemByName(url: string, name: string, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		return this.httpClient.get(this.tji_domain + url + '/' + name, { headers: this.header }).pipe(
			map((response: any) => {
				this.item = response;
				this.onChanged.next(this.item);
				return this.item;
			}),
			share());
	}

	// Get Item by Selected Code
	getItemByCode(url: string, code: string, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		return this.httpClient.get(this.tji_domain + url + '/' + code, { headers: this.header }).pipe(
			map((response: any) => {
				this.item = response;
				this.onChanged.next(this.item);
				return this.item;
			}),
			share());
	}

	// Get Item by Selected Code
	getItemByParam(url: string, param: any = null, withResellerId: boolean = true, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		if (this.client_id && this.client_id !== null && this.client_id > 0) {
			if (param === null) { param = {}; }
			param.client_id = (param && param.client_id) ? param.client_id : Number(this.client_id);
		}
		if (!this.isAgent && withResellerId && this.reseller_id && this.reseller_id !== null && this.reseller_id > 0) { param.reseller_id = this.reseller_id; }
		return this.httpClient.get(this.tji_domain + url, { headers: this.header, params: param }).pipe(
			map((response: any) => {
				this.item = response;
				this.onChanged.next(this.item);
				return this.item;
			}),
			share());
	}

	// Store New Item Data
	storeMedia(url: string, data: any, withResellerId: boolean = true, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		if (this.client_id && this.client_id !== null && this.client_id > 0) {
			if (data === null) { data = {}; }
			data.client_id = (data && data.client_id) ? data.client_id : this.client_id;
		}
		if (data === null) { data = {}; }
		if (!this.isAgent && withResellerId && this.reseller_id && this.reseller_id !== null && this.reseller_id > 0) { data.reseller_id = this.reseller_id; }
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			// timeout(50000),
			map((response: any) => {
				return response;
			}),
			// retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error)),
			catchError(error => {
				// this.mediaService.getAllItems();
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	// Store New Item Data
	replayChat(url: string, data: any, withResellerId: boolean = true, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		if (this.client_id && this.client_id !== null && this.client_id > 0) {
			if (data === null) { data = {}; }
			data.client_id = (data && data.client_id) ? data.client_id : this.client_id;
		}
		if (data === null) { data = {}; }
		if (!this.isAgent && withResellerId && this.reseller_id && this.reseller_id !== null && this.reseller_id > 0) { data.reseller_id = this.reseller_id; }
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			timeout(30000),
			map((response: any) => {
				return response;
			}),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error)),
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	handleError(error: any) {
		if (error === 'Unauthenticated' || error === 'Unauthenticated.' || error === 'Token invalid or missing') {
			this.clearMemory();
		}
		// let errorMessage = '';
		// if (error.error instanceof ErrorEvent) {
		//     // client-side error
		//     errorMessage = `Error: ${error.error.message}`;
		// } else {
		//     // server-side error
		//     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		// }
		// console.log(errorMessage);
		// error = success
		return throwError(error);
	}

	apiAlertResponse(alertType = 'info', response: any) {
		let defaultMsg: string;
		switch (alertType) {
			case 'success':
			case 'Success':
				defaultMsg = "Process Completed Successfully !!!";
				break;
			case 'info':
			case 'Info':
				defaultMsg = "You May have a Information Message !!!";
				break;
			case 'danger':
			case 'Danger':
				defaultMsg = "Something Wrong. Try after Sometimes !!!";
				break;
			case 'warning':
			case 'Warning':
			case 'warn':
			case 'Warn':
				defaultMsg = "This Process may Alert You Something !!!";
				break;
			default:
				defaultMsg = "Something Wrong. Try after Sometimes !!!";
				break;
		}
		let responseMsg = (response && response.message) ? response.message : defaultMsg;
		this.alertService.webShow(alertType, responseMsg);
	}

	alert(data: Alert) {
		let type = (data.type) ? data.type : 'info';
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		this.audioService.playAudio();
		this.alertService.websocketShow(type, data);
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		type: type.toLowerCase(),
		// 		title: data.title,
		// 		message: data.message,
		// 		image: data.image,
		// 		icon: data.icon
		// 	}
		// });
	}

	playAudio() {
		this.audioService.playAudio();
	}

	playOrderAudio() {
		this.audioService.playOrderAudio();
	}

	showToastr() {
		this.toastr.success('<h1>Hello My Turn</h1>', 'Toastr fun!', {
			enableHtml: true,
		});
	}

	clearMemory() {

		this.cookieService.deleteAll();
		if (this.cookieService.check('tji_token')) {
			this.cookieService.delete('tji_token', '/');
		}
		if (this.cookieService.check('tji_user')) {
			this.cookieService.delete('tji_user', '/');
		}
		if (this.cookieService.check('tji_permissions')) {
			this.cookieService.delete('tji_permissions', '/');
		}
		if (this.cookieService.check('tji_roles')) {
			this.cookieService.delete('tji_roles', '/');
		}
		if (this.cookieService.check('language')) {
			this.cookieService.delete('language', 'en');
		}
		localStorage.clear();
		window.location.reload();
		this.router.navigate(["/"]);
		// this.client_id = Number(localStorage.getItem('client_id'));
		// this.reseller_id = Number(localStorage.getItem('reseller_id'));
		// if (location.origin == 'http://localhost:4200' || location.origin == 'https://ui.ai-octopus.com/' || location.origin == 'https://stage.ai-octopus.com/') {
		// 	window.location.reload();
		// 	this.router.navigate(["/"]);
		// }else{
		// 	window.location.href = 'https://ai-octopus.com/';
		// }
	}

	setLanguage(lang: string = 'en') {
		this.cookieService.set('language', lang);
		localStorage.setItem('language', lang);
	}

	getLanguage(): string {
		let lang: string = null;
		if (this.cookieService.check('language')) {
			lang = this.cookieService.get('language');
		}
		return lang;
	}

	// Get Item by Selected ID
	getItrsData(url: string, data, urlOption: any = null): Observable<any> {
		const body = JSON.stringify(data);
		this.getBackendUrl(urlOption);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error))
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}
	// create Item by tamplate based on the itrs data.
	createItrsData(url: string, data, urlOption: any = null): Observable<any> {
		const body = JSON.stringify(data);
		this.getBackendUrl(urlOption);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error))
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	deleteData(data, url: string, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		return this.httpClient.post(this.tji_domain + url, data).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error))\
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	// Store New Item Data
	getData(url: string, data: any, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error))
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	// Store New Item Data
	getPremium(url: string, data: any, urlOption: any = null): Observable<any> {
		this.getBackendUrl(urlOption);
		let body = {}
		// body = JSON.stringify(data);
		// let headers = new HttpHeaders();
		// this.header = this.header.append('Authorization', "Bearer " + data['token']);
		// headers = headers.append('x-Flatten', 'true');
		// headers = headers.append('Content-Type', 'application/json');

		
		// if (data['token']) {
		// 	// this.premiumHeader['Authorization'] = "Bearer " + data['token'];
		// 	this.premiumHeader.set('Authorization',  "Bearer " + data['token'])
		// } else {
		// 	body = {}
		// }

	// 	let headersToSend = new HttpHeaders();
    // headersToSend = headersToSend
    //   .set('Bearer', "Bearer " + data['token'])
    //   .set('Accept','application/json');

	const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'] })


		return this.httpClient.post(this.tji_domain + url, body, { headers: this.header }).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			// catchError(error => this.handleError(error))
			catchError(error => {
				this.handleError(error)
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}

	// Get Item by Selected Code
	getPremiumLogin(url: string, data: any = null, urlOption: any = null) {
		this.getBackendUrl(urlOption);
		const body = JSON.stringify(data);
		return this.httpClient.post(this.tji_domain + url, data, {headers:{skip:"true"}}).pipe(
			timeout(25000),
			map((response: any) => {
				return response;
			}),
			share(),
			retry({ count: 2, delay: 1000 }),
			catchError(error => {
				this.alertService.webShow('Info', "unstable network connectivity !!!")
				throw error;
			})
		);
	}
}

