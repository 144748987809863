export class IntractiveCategoryModel {
	id: number;
	name: string;
	slug: string;
	description: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;

	/**
	 * Constructor
	 * @param intractive_category
	 */
	constructor(intractive_category) {
		this.id = intractive_category.id || '';
		this.name = intractive_category.name || '';
		this.slug = intractive_category.slug || '';
		this.description = intractive_category.description || '';
		this.created_by = intractive_category.created_by || '';
		this.updated_by = intractive_category.updated_by || '';
		this.created_at = intractive_category.created_at || '';
		this.updated_at = intractive_category.updated_at || '';
	}
}
