import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let $: any;
import { environment } from 'src/environments/environment';
import { PremiumDashboardService, PremiumDashboard, PremiumAlert, EventService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'premium-dashboard-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AlertComponent {
    dashboard: PremiumDashboard;
    dashboardParam: any;
    base_image: string = 'assets/img/no-user.jpg';

    alerts: PremiumAlert[] = [];
    eventData: any;

    alertOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        autoplayHoverPause: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2500,
        autoplaySpeed: 800,
        navSpeed: 700,
        navText: [],
        responsive: {
            0: { items: 1 },
            360: { items: 1 },
            450: { items: 1 },
            740: { items: 3 },
            940: { items: 3 },
            1200: { items: 3 }
        },
        nav: false
    }

    constructor(private eventService: EventService,
                public premiumDashboardService: PremiumDashboardService) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.alerts.pipe(untilDestroyed(this)).subscribe(data => {
            this.alerts = data;
        });
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit(){ }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.eventName) ? this.eventData.eventName : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'OnlineUserEvent':
                    if (data && data.alert) {
                        let alertData = data.alert;
                        if (alertData && alertData.title) {
                            this.premiumDashboardService.addAlert(alertData);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
}
