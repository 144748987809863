import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ClientSiteService, ClientSite,
	IntractiveService, Intractive,
	IntractiveGroupService, IntractiveGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-itrs-chooseintractiveform',
	templateUrl: './chooseIntractiveForm.component.html',
	styleUrls: ['./chooseIntractiveForm.component.scss']
})

export class ChooseIntractiveFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	clientSite: ClientSite;
	intractives: Intractive[] = [];
	intractiveGroup: IntractiveGroup;
	action: string;
	chooseIntractiveForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<ChooseIntractiveFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
		this.intractiveService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.intractives = data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.getInit(_data);
			this.chooseIntractiveForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		let intractive = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Link Next Intractive';
				break;
			case 'edit':
				this.dialogTitle = 'Change Next Intractive';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			next_intractive_id: [this.intractiveGroup.next_intractive_id || null, [Validators.required]],
			is_active: [1],
		});
	}


}
