import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Product } from './product.interface';
import { ProductModel } from './product-model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

declare var require: any;
var slugify = require('slugify')
@Directive()
@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class ProductService implements Resolve<any> {
  url: string = 'eproduct';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
  };

  sortIdentity: any = {
    'name': 'name'
  };
  authUser: any; // = this.userService.user;
  searchIdentity: boolean = false;
  // accountStatus: any;
  @Output() onChangeItem = new EventEmitter();
  @Output() onChangeAllItems = new EventEmitter();

  private allItemsSource = new BehaviorSubject<Product[]>([]);
  allItems = this.allItemsSource.asObservable();

  private itemSource = new BehaviorSubject<Product>(new ProductModel({}));
  item = this.itemSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private displayItemsSource = new BehaviorSubject<Product[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  private allDataItemsSource = new BehaviorSubject<Product[]>([]);
  allIDatatems = this.allDataItemsSource.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    public userService: UserService,
    private snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
    this.userService.user.subscribe(data => {
      this.authUser = data
      this.defaultParams = {
        'limit': 25,
        'current': 1,
      };
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.resetParams(),
        this.getAllItems(),
        // this.getItem(),
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
    };
    this.changeParams(this.paramsInit(defaultParams));
  }
  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }
  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }
  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.current += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  unSubscribe() {
    // console.log('UnSubscribed ProductService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on TemplateMessageService');
  }
  changeAllItems(allItems: Product[]) {
    this.allItemsSource.next(allItems);
    this.onChangeAllItems.emit(allItems);
  }

  changeAllDataItems(allItems: Product[]) {
    this.allDataItemsSource.next(allItems);
    this.onChangeAllItems.emit(allItems);
  }
  changeItem(item: Product) {
    this.itemSource.next(item);
    this.onChangeItem.emit(item);
  }
  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = event.pageIndex + 1;
        newParams.paginate = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  getSortName(input: string) {
    let sortName = 'name';
    sortName = (input) ? this.sortIdentity[input] : sortName;
    return sortName;
  }

  sortData(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = 1;
        newParams.order = this.getSortName(event.active) + '|' + event.direction;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
        var newItems = [];
        if (data.success) {

          if (data.data && data.data.length > 0) {
            if (data.data[0] && data.data[0].retailer_id) {
              data.data.forEach(product => {
                var newData = {
                  "_id": product._id,
                  "client_id": product.client_id,
                  "client_site_id": product.client_site_id,
                  "title": product.name,
                  "price": product.price,
                  "currency_code": product.currency,
                  "group_id": product.catalog_id,
                  "content_id": product.retailer_id,
                  "created_at": product.created_at,
                  "image_url": product.image_url
                }
                newItems.push(newData);
                this.changeAllItems(newItems);
                this.changeTotalItem(data.pagnitation.totalResult);
              });
            } else {
              this.changeAllItems(data.data);
              this.changeTotalItem(data.pagnitation.totalResult);
            }
          } else {
            this.changeAllItems(data.data);
            this.changeTotalItem(data.pagnitation.totalResult);
          }

        }
      },
        error => {
          console.log('Error ::' + error);
        }
      );
  }

  getAllDataItems(id = null) {
    var params = {
      'limit': 0,
      'current': 0,
      'client_site_id': id
    }
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
        var newItems = [];
        if (data.success) {
          if (data.data && data.data.length > 0) {
            if (data.data[0] && data.data[0].retailer_id) {
              data.data.forEach(product => {
                var newData = {
                  "_id": product._id,
                  "client_id": product.client_id,
                  "client_site_id": product.client_site_id,
                  "title": product.name,
                  "price": product.price,
                  "currency_code": product.currency,
                  "group_id": product.catalog_id,
                  "content_id": product.retailer_id,
                  "created_at": product.created_at,
                  "image_url": product.image_url
                }
                data.additional.CatalogInfo.forEach(catalog => {
                  if (catalog.id == product.catalog_id) {
                    newData['catalogName'] = catalog.name;
                  }
                });
                newItems.push(newData);
                this.changeAllDataItems(newItems);
              });
            } else {
              this.changeAllDataItems(data.data);
            }
          } else {
            this.changeAllDataItems(data.data);
          }
        } else {
          this.changeAllDataItems(data.data);
        }
      },
        error => {
          console.log('Error ::' + error);
        }
      );
  }

  concatAllItems(params: any = null) {
    if (this.searchIdentity == false) {
      params = this.paramsInit(params);
      this.commonService.storeItem(this.url, params, true, 'optionOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          let lists = [];
          if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
            params.current = data.pagnitation.pageCount;
            this.changeParams(params);
          }
          this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
            lists = result.concat(data.data);
          });
          this.changeAllItems(lists);
        },
          error => console.log('Error ::' + error)
        );
    }
  }

  destroyItems(id: number, url = null) {
    var data = {
      id: id
    }
    url = (url) ? url : this.url;
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
        if (data.success) {
          this.spliceItem(id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }
      },
        error => {
          console.log('Error ::' + error);
        }
      );
  }


  public drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      var duplicates = []
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      duplicates = event.container.data.filter((item, index) => index !== event.container.data.indexOf(item));
      if (duplicates.length > 0) {
        this.alert('Danger', 'unique product Only allowed !!!');
      }
    }
  }
}
