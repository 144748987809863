import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { trigger, transition, useAnimation } from "@angular/animations";
import { rotateCubeToLeft } from "ngx-router-animations";

import { UserService, User } from 'src/app/@tji/_dbShare';

import { SwUpdate, SwPush } from '@angular/service-worker';
import { interval, filter, map } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	//  animations:  [
	// 	trigger('rotateCubeToLeft',  [ transition('home => about', useAnimation(rotateCubeToLeft))])
	// ]
})
export class AppComponent implements OnInit {

	title = 'Ai-Octopus';
	url: string;
	show: boolean = false;
	chatShow: boolean = false;
	channel: any;
	echo: any;
	isAuth: boolean = false;
	isInternet: boolean = false;
	private readonly publicKey = 'VvF2MywdSC755EZ9BGu1BnTx6HRGuPB1aJeymzRQmQ1qcGJNVSXnE48Y6MfPWD6UhVERC28Z5zGMttLP29QmuKG';

	constructor(private router: Router,
		public userService: UserService,
		private swPush: SwPush, private update: SwUpdate, private appRef: ApplicationRef,) {
		this.url = this.router.url;
		this.show = (this.router.url.includes('/app/')) ? true : false;
		this.chatShow = (this.router.url.includes('/app-chat/')) ? true : false;
		this.isAuth = this.userService.authCheck();
		setInterval(() => {
			this.checkConnection();
		}, 5000);
		this.updateClient();
		this.checkUpdate();
	}

	ngOnInit() {
		if (environment.production) {
			if (location.protocol === 'http:') {
				window.location.href = location.href.replace('http', 'https');
			}
		}

		// this.pushSubscription();

		this.swPush.messages.subscribe((message) => console.log(message));

		this.swPush.notificationClicks.subscribe(({ action, notification }) => {
			window.open(notification.data.url);
		});
	}

	checkurl() { }

	// 	getState(outlet)  {
	// 	return outlet.activatedRouteData.state;
	// }

	checkConnection() {
		// window.addEventListener('offline', function (event) {
		// 	alert("You are offline please turn on internet connection to run website");
		// 	// this.alterInfo();
		// });
		var ifConnected = window.navigator.onLine;
		if (!ifConnected) {
			this.isInternet = true;
		} else {
			this.isInternet = false;
		}
	}
	closeError() {
		this.isInternet = false;
	}
	updateClient() {
		if (!this.update.isEnabled) {
			// console.log('Not Enabled');
			return;
		}

		this.update.available.subscribe((event) => {
			// console.log(`current`, event.current, `available `, event.available);
			// if (confirm('update available for the app please conform')) {
			// 	this.update.activateUpdate().then(() => location.reload());
			// }
			this.update.activateUpdate().then(() => location.reload());
		});

		this.update.activated.subscribe((event) => {
			console.log(`current`, event.previous, `available `, event.current);
		});
	}

	// pushSubscription() {
	// 	if (!this.swPush.isEnabled) {
	// 		console.log('Notification is not enabled');
	// 		return;
	// 	}

	// 	this.swPush
	// 		.requestSubscription({
	// 			serverPublicKey: this.publicKey,
	// 		})
	// 		.then((sub) => {
	// 			// Make a post call to serve
	// 			console.log(JSON.stringify(sub));
	// 		})
	// 		.catch((err) => console.log(err));
	// }

	checkUpdate() {
		this.appRef.isStable.subscribe((isStable) => {
			if (isStable) {
				const timeInterval = interval(30000);
				timeInterval.subscribe(() => {
					this.update.checkForUpdate().then(() => console.log('checked'));
					// console.log('update checked');
				});
			}
		});
	}
}
