export class PersonTeamModel {
    id:number;
    person_id: number;
    person_type: string;
    team_id: number;
    client_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName?: string;
    name?: string;
    team?: any;

    /**
     * Constructor
     * @param personTeam
     */
    constructor(personTeam) {
        this.id = personTeam.id || '';
        this.person_id = personTeam.person_id || '';
        this.person_type = personTeam.person_type || '';
        this.team_id = personTeam.team_id || '';
        this.client_id = personTeam.client_id || '';
        this.created_by = personTeam.created_by || '';
        this.updated_by = personTeam.updated_by || '';
        this.created_at = personTeam.created_at || '';
        this.updated_at = personTeam.updated_at || '';
            
    }
}
