import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { UserService, UserProfileService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: UserProfileService },
	},
	{ path: '**', redirectTo: '/app/dashboard' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class ProfileUpdateRouteModule { }
