export class AbandonModel {
    id: number;
    client_id: number;
    agent_id: number;
    model_id: number;
    model_type: string;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    clientName?: string;
    agentName?: string;
    client?: any;
    agent?: any;
    tableName: string;
    model?: any;
    clientSiteId: any;

    /**
     * Constructor
     *
     * @param abandon
     */
    constructor(abandon) {
            this.id = abandon.id || '';
            this.model_id = abandon.model_id || '';
            this.model_type = abandon.model_type || '';
            this.client_id = abandon.client_id || '';
            this.agent_id = abandon.agent_id || '';
            this.created_by = abandon.created_by || '';
            this.updated_by = abandon.updated_by || '';
            this.created_at = abandon.created_at || '';
            this.updated_at = abandon.updated_at || '';

            this.tableName = abandon.tableName || '';
            this.clientSiteId  = abandon.clientSiteId || '';;
    }
}
