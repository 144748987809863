import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from './data';

import {
	User, UserService,
	DashboardService, Dashboard
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'dashboard-chart1',
	templateUrl: './chart1.component.html',
	styleUrls: ['./chart1.component.scss']
})

export class Chart1Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('chart1', { static: true }) chart1: ElementRef;
	authUser: User;

	data: any;
	view: any[] = [];
	dashboard: Dashboard;

	// options
	showXAxis: boolean = true;
	showYAxis: boolean = true;
	gradient: boolean = true;
	showLegend: boolean = true;
	showXAxisLabel: boolean = true;
	xAxisLabel: string = 'Site Accounts';
	showYAxisLabel: boolean = true;
	yAxisLabel: string = 'This Month Statistic';

	colorScheme = {
		//domain: ["aqua", "aquamarine", "darkcyan", "cadetblue", "greenyellow"]
		// domain: ["#264653","#2a9d8f","#e9c46a","#f4a261","#e76f51"]
		domain: ["#39da8a","#dc67ce","#4caf50","#7759de","#f1376e"]
	};

	constructor(public userService: UserService,
		public dashboardService: DashboardService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		// Object.assign(this, { data });
		this.dashboardService.chartFourData.pipe(untilDestroyed(this)).subscribe(data => {
			// this.dashboard = data;
			if (data && data.length > 0 && data[0].name) {
				this.data = data;
			}
		});
	}

	ngOnInit() {
		this.view = [this.chart1.nativeElement.offsetWidth - 60, this.chart1.nativeElement.offsetHeight];
	}

	ngAfterViewInit() { }



	/** On destroy */
	ngOnDestroy(): void { }

	onSelect(event) { }

}
