import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	cookieService: CookieService;
	constructor(private router: Router) { }

	intercept(req: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>> {

		const tjiToken:string|null = localStorage.getItem("tji_token") ? localStorage.getItem("tji_token") : "";
		// const tjiToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0MSIsImp0aSI6ImEwNWZmM2E4ZDY4ZGM2OTUwMjBhYjRhYjMzZDU5M2ZlODZkM2Y1OTc1YzY1NDM1MmI4YWQ5ZjdmNjE2ODMyZmVkNjc3MTZiMmIxZWVhNTc5IiwiaWF0IjoxNjkxNTc4MjkzLjc0ODc1MiwibmJmIjoxNjkxNTc4MjkzLjc0ODc1NywiZXhwIjoxNjkyNDQyMjkzLjc0NDkxNCwic3ViIjoiMTAzMCIsInNjb3BlcyI6W119.oPVde-Gw-wyA3HDEm3bkLR_Bg3o9EG5pxS4NkbsXNwhO_OTCCqHMnwywtyZDBrC5hYVsvcUhoPPIAaYVKXawnStgdR0hHJF8oM8wRO5Z-lo-Ex8VFNt2UnKr83PKYbhMqW5f2pZnCu90dvmki8TW1xsNLaP317-yQkcqPCgCRKNw6TS-fpuYphYz8lXSghbMRjEz8SY8P0u9-K5zYPzknLsyKULUPIiaqovYjOFt9U_G6YDPqo2aosMxIWjsO4_SIby8cBPm8H6kph897DJiExifencdvczaE5H1IzeEzP_vJLZ5FY8SkqpjNbGd5ci5pnVtQi8yFionAtfZrdM4eYCb84n4CybpQE90HBMgxq2HhkVSplsCHia5kuio_XUogwO2FTqIhTY2CXtGQ9s1l-_YvJzLC8Py12j47cDMFJbzW4fC9DLKEKWI2rv8YK89DL-_u5EaEjw_bNOWd3ZUqnVwrTK4fpwDNxQjPvMvVUB_MJk6J-S5Kg-hEgfMQZqOWnKeZs5c84rJDH8DfvxK0BtIPShpiIyeaEjUS3ECMf1H4v9mksPB_nBno-q0nxJmeL807VJL_6YKzxhRVL_BxhHdpQg4M6cjB5IppIiW19soQleBIBdrnuT3L8mtErX5JFJi376DspSBweS7fUwMrpHdPSLInM6EZOWHu7G1kq4';
		const clientId = localStorage.getItem("client_id");
		// const tjiToken = (this.cookieService.check("tji_token")) ? this.cookieService.get("tji_token") : null;
		const domainUrl: string = environment.domain;
		const domainUrlSecured: string = environment.domainSecured;
		const backendUrlOptionOne: string = environment.domainSecuredOne;
		const backendUrldemo: string = environment.domainDevelopment;
		const reqUrl = req.url;

		let cloned = req;

		if (req.headers.get("skip")) {
			return next.handle(cloned);
		}

		if ((reqUrl.indexOf(domainUrl) === 0 || reqUrl.indexOf(domainUrlSecured) === 0) || reqUrl.indexOf(backendUrlOptionOne) === 0 || reqUrl.indexOf(backendUrldemo) === 0) {
			cloned = cloned.clone(
				{ headers: cloned.headers.set("Authorization", "Bearer " + tjiToken.replace('"', '').replace('"', '')) }
			);
		}

		return next.handle(cloned);
	}

	private handleError(err: HttpErrorResponse): Observable<any> {
		if (err.status === 401) {
			localStorage.clear();
			this.cookieService.deleteAll();
			this.clearCookie();
			localStorage.clear();
			window.location.reload();
			this.router.navigateByUrl(`/`);
			return observableOf(err.message);
		}
		if (err.status === 403) {
			// console.log('UnKnown Access');
		}
		// handle your auth error or rethrow
		return observableThrowError(err);
	}

	public clearCookie() {
		if (this.cookieService.check('tji_token')) {
			this.cookieService.delete('tji_token', '/');
		}
		if (this.cookieService.check('tji_user')) {
			this.cookieService.delete('tji_user', '/');
		}
		if (this.cookieService.check('tji_permissions')) {
			this.cookieService.delete('tji_permissions', '/');
		}
		if (this.cookieService.check('tji_roles')) {
			this.cookieService.delete('tji_roles', '/');
		}
		if (this.cookieService.check('language')) {
			this.cookieService.delete('language', 'en');
		}
	}
}
