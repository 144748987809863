import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { TableViewComponent } from './tableView/tableView.component';
import { ImageComponent } from './itemView/image/image.component';
import { VideoComponent } from './itemView/video/video.component';
import { AudioComponent } from './itemView/audio/audio.component';
import { DocumentComponent } from './itemView/document/document.component';
import { PreviewComponent } from './preview/preview.component';
import { FormComponent } from './form/form.component';
import { ShareMediaFormComponent } from './shareMediaForm/shareMediaForm.component';
import { TemplateGalleryComponent } from './template-gallery/template-gallery.component';
import { ConfirmDialogImageUploadComponent } from './confirm-dialog-image-upload/confirm-dialog-image-upload.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        TableViewComponent,
        ImageComponent,
        VideoComponent,
        AudioComponent,
        DocumentComponent,
        PreviewComponent,
        FormComponent,
        ShareMediaFormComponent,
        TemplateGalleryComponent,
        ConfirmDialogImageUploadComponent
    ],
    imports: [
    AppShareModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
],
    exports: [
        MainComponent,
        ListComponent,
        PreviewComponent,
        TemplateGalleryComponent,
        ConfirmDialogImageUploadComponent
    ]
})

export class GalleryExportModule { }
