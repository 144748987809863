import { Component, OnInit } from '@angular/core';
import { AicollectionService, ChatService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-payment-chat-info',
  templateUrl: './payment-chat-info.component.html',
  styleUrls: ['./payment-chat-info.component.scss']
})
export class PaymentChatInfoComponent implements OnInit {
  paymentInfo: any;
  currentPayment: boolean = false;
  allPayment: boolean = false;
  paymentData: any;
  loader: boolean = true;
  param: any;
  ticket: any;

  constructor(public aicollectionService: AicollectionService, public chatService: ChatService,) {
    this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
      this.ticket = data;
    });
    this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
      this.param.people_id = this.ticket.people_id
    });
    this.aicollectionService.isAuthentication.pipe(untilDestroyed(this)).subscribe(data => {
      if (data['data'] && !data['data'].success) {
        if (data['data'] && !data['data']["result"] && !data['data']["requiredfileds"]) {
          this.loader = false;
          this.paymentInfo = true;
          this.paymentData = data['data']
        }
        if (data['data'] && !data['data']["result"] && data['data']["requiredfileds"] && data['data']["requiredfileds"].length > 0) {
          this.paymentInfo = false;
          this.currentPayment = true;
          this.loader = false;
        }
        if (data['data'] && data['data']["result"]) {
          this.paymentInfo = false;
          this.currentPayment = true;
          this.loader = false;
        }
      }
    });
  }

  ngOnInit(): void {
    // this.doLoader();
  }
  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 2000);
  }
  tabChange(e) {
    if (e == 'current') {
      this.aicollectionService.allItemsSource.next([]);
      this.param.people_id = this.ticket.people_id
      this.currentPayment = true;
      this.allPayment = false;
      this.aicollectionService.checkAuth();
      this.aicollectionService.getCurrentItems();
    } else if (e == 'all') {
      this.aicollectionService.allItemsSource.next([]);
      delete this.param.people_id;
      this.currentPayment = false;
      this.allPayment = true;
      this.aicollectionService.getCurrentItems();
    }
  }
}
