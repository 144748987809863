import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ListComponent } from './../list/list.component';

import { User, UserService, MenuGroupService, MenuGroup, Menu, SubMenu } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'menugroup-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;

	listName: string = 'menugroup';
	selectedMenuGroup: MenuGroup = null;
	selectedMenu: Menu = null;
	selectedSubMenu: SubMenu = null;

	@ViewChild(ListComponent) listComponent;

	constructor(public userService: UserService,
		public menuGroupService: MenuGroupService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	onchangeList(listName: any) {
		this.listName = listName;
	}

	changeListName(event) {
		this.listName = event;
	}

	changeSelectedMenuGroup(event) {
		this.selectedMenuGroup = event;
	}

	changeSelectedMenu(event) {
		this.selectedMenu = event;
	}

}
