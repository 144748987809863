import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
  User, UserService,
  ClientSiteService, ClientSite, ClientSiteModel,
  PersonSettingService, PersonSetting,
  SiteService, Site, AlertService, CommonService, TeamService, SettingsService, Team,
  ItrsbotService
} from 'src/app/@tji/_dbShare';
import { DomSanitizer } from '@angular/platform-browser';
import { WhatsappFormComponent } from '../whatsappForm/whatsappForm.component';

@UntilDestroy()

@Component({
  selector: 'whatsapp-settings',
  templateUrl: './whatsappSettings.component.html',
  styleUrls: ['./whatsappSettings.component.scss']
})
export class WhatsappSettingsComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  clientSite: ClientSite;
  fileSizeInvalid: boolean = false;
  InvalidFile: boolean = false;
  invalidMessage: any;
  profileImages: any;
  profileDataView: any;
  verticals: Array<string> = ['OTHER', 'AUTO'];
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  profileUpdateForm: UntypedFormGroup = new UntypedFormGroup({
    businessVertical: new UntypedFormControl(''),
    whatsappEmail: new UntypedFormControl(''),
    whatsappWebsites: new UntypedFormControl(''),
    whatsappAbout: new UntypedFormControl(''),
    whatsappDescription: new UntypedFormControl(''),
    whatsappAddress: new UntypedFormControl(''),
    timeZoon: new UntypedFormControl(''),
  });

  allLanguageData: any
  selectedLanguage: any;
  selectedModule: any;
  selectedTeam: any;
  greetingsData: any;
  is_aicommerce_automation: boolean = false;
  moduleListDate: any;
  teams: any;
  isOnlinePayment: boolean = false;
  updatePrice: any;
  accountData: any;

  timeZoonData: any = [
    {
      country: 'Asia/Kabul',
      timezoon: 'UTC +04:30',
    },
    {
      country: 'Europe/Tirane',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Algiers',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Pacific/Pago_Pago',
      timezoon: 'UTC -11:00',
    },
    {
      country: 'Europe/Andorra',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Luanda',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'America/Anguilla',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Antarctica/Casey',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Antarctica/Davis',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Antarctica/DumontDUrville',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Antarctica/Mawson',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Antarctica/McMurdo',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Antarctica/Palmer',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Antarctica/Rothera',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Antarctica/Syowa',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Antarctica/Troll',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Antarctica/Vostok',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'America/Antigua',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Argentina/Buenos_Aires',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Catamarca',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Cordoba',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Jujuy',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/La_Rioja',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Mendoza',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Rio_Gallegos',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Salta',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/San_Juan',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/San_Luis',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Tucuman',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Argentina/Ushuaia',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Asia/Yerevan',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'America/Aruba',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Antarctica/Macquarie',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Australia/Adelaide',
      timezoon: 'UTC +09:30',
    },
    {
      country: 'Australia/Brisbane',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Australia/Broken_Hill',
      timezoon: 'UTC +09:30',
    },
    {
      country: 'Australia/Darwin',
      timezoon: 'UTC +09:30',
    },
    {
      country: 'Australia/Eucla',
      timezoon: 'UTC +08:45',
    },
    {
      country: 'Australia/Hobart',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Australia/Lindeman',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Australia/Lord_Howe',
      timezoon: 'UTC +10:30',
    },
    {
      country: 'Australia/Melbourne',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Australia/Perth',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Australia/Sydney',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Europe/Vienna',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Baku',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'America/Nassau',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Asia/Bahrain',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Dhaka',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'America/Barbados',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Europe/Minsk',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Brussels',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Belize',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Africa/Porto-Novo',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Atlantic/Bermuda',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Asia/Thimphu',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'America/La_Paz',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Kralendijk',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Europe/Sarajevo',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Gaborone',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Araguaina',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Bahia',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Belem',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Boa_Vista',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Campo_Grande',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Cuiaba',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Eirunepe',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Fortaleza',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Maceio',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Manaus',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Noronha',
      timezoon: 'UTC -02:00',
    },
    {
      country: 'America/Porto_Velho',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Recife',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Rio_Branco',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Santarem',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Sao_Paulo',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Indian/Chagos',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'Asia/Brunei',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Europe/Sofia',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Ouagadougou',
      timezoon: 'UTC',
    },
    {
      country: 'Africa/Bujumbura',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Phnom_Penh',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Africa/Douala',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'America/Atikokan',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Blanc-Sablon',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Cambridge_Bay',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Creston',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Dawson',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Dawson_Creek',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Edmonton',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Fort_Nelson',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Glace_Bay',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Goose_Bay',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Halifax',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Inuvik',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Iqaluit',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Moncton',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Rankin_Inlet',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Regina',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Resolute',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/St_Johns',
      timezoon: 'UTC -02:30',
    },
    {
      country: 'America/Swift_Current',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Toronto',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Vancouver',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Whitehorse',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Winnipeg',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Atlantic/Cape_Verde',
      timezoon: 'UTC -01:00',
    },
    {
      country: 'America/Cayman',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Africa/Bangui',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Ndjamena',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'America/Punta_Arenas',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Santiago',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Pacific/Easter',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Asia/Shanghai',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Asia/Urumqi',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'Indian/Christmas',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Indian/Cocos',
      timezoon: 'UTC +06:30',
    },
    {
      country: 'America/Bogota',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Indian/Comoro',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Brazzaville',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Kinshasa',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Lubumbashi',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Pacific/Rarotonga',
      timezoon: 'UTC -10:00',
    },
    {
      country: 'America/Costa_Rica',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Europe/Zagreb',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Havana',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Curacao',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Asia/Famagusta',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Nicosia',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Prague',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Abidjan',
      timezoon: 'UTC',
    },
    {
      country: 'Europe/Copenhagen',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Djibouti',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'America/Dominica',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Santo_Domingo',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Guayaquil',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Pacific/Galapagos',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Africa/Cairo',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'America/El_Salvador',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Africa/Malabo',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Asmara',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Tallinn',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Addis_Ababa',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Atlantic/Stanley',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Atlantic/Faroe',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Pacific/Fiji',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Europe/Helsinki',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Paris',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Cayenne',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Pacific/Gambier',
      timezoon: 'UTC -09:00',
    },
    {
      country: 'Pacific/Marquesas',
      timezoon: 'UTC -09:30',
    },
    {
      country: 'Pacific/Tahiti',
      timezoon: 'UTC -10:00',
    },
    {
      country: 'Indian/Kerguelen',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Africa/Libreville',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Banjul',
      timezoon: 'UTC',
    },
    {
      country: 'Asia/Tbilisi',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Europe/Berlin',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Busingen',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Accra',
      timezoon: 'UTC',
    },
    {
      country: 'Europe/Gibraltar',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Athens',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'America/Danmarkshavn',
      timezoon: 'UTC',
    },
    {
      country: 'America/Nuuk',
      timezoon: 'UTC -02:00',
    },
    {
      country: 'America/Scoresbysund',
      timezoon: 'UTC',
    },
    {
      country: 'America/Thule',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'America/Grenada',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Guadeloupe',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Pacific/Guam',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'America/Guatemala',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Europe/Guernsey',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Conakry',
      timezoon: 'UTC',
    },
    {
      country: 'Africa/Bissau',
      timezoon: 'UTC',
    },
    {
      country: 'America/Guyana',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Port-au-Prince',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Europe/Vatican',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Tegucigalpa',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Asia/Hong_Kong',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Europe/Budapest',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Atlantic/Reykjavik',
      timezoon: 'UTC',
    },
    {
      country: 'Asia/Kolkata',
      timezoon: 'UTC +05:30',
    },
    {
      country: 'Asia/Jakarta',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Jayapura',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Makassar',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Asia/Pontianak',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Tehran',
      timezoon: 'UTC +03:30',
    },
    {
      country: 'Asia/Baghdad',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Dublin',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Europe/Isle_of_Man',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Asia/Jerusalem',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Rome',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Jamaica',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Asia/Tokyo',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Europe/Jersey',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Asia/Amman',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Almaty',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'Asia/Aqtau',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Asia/Aqtobe',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Asia/Atyrau',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Asia/Oral',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Asia/Qostanay',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'Asia/Qyzylorda',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Africa/Nairobi',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Pacific/Kanton',
      timezoon: 'UTC +13:00',
    },
    {
      country: 'Pacific/Kiritimati',
      timezoon: 'UTC +14:00',
    },
    {
      country: 'Pacific/Tarawa',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Asia/Pyongyang',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Seoul',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Kuwait',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Bishkek',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'Asia/Vientiane',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Europe/Riga',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Beirut',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Maseru',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Monrovia',
      timezoon: 'UTC',
    },
    {
      country: 'Africa/Tripoli',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Vaduz',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Vilnius',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Luxembourg',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Macau',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Europe/Skopje',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Indian/Antananarivo',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Blantyre',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Kuala_Lumpur',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Asia/Kuching',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Indian/Maldives',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Africa/Bamako',
      timezoon: 'UTC',
    },
    {
      country: 'Europe/Malta',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Pacific/Kwajalein',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Pacific/Majuro',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'America/Martinique',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Africa/Nouakchott',
      timezoon: 'UTC',
    },
    {
      country: 'Indian/Mauritius',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Indian/Mayotte',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'America/Bahia_Banderas',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Cancun',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Chihuahua',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Ciudad_Juarez',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Hermosillo',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Matamoros',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Mazatlan',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Merida',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Mexico_City',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Monterrey',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Ojinaga',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Tijuana',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'Pacific/Chuuk',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Pacific/Kosrae',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Pacific/Pohnpei',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Europe/Chisinau',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Monaco',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Choibalsan',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Asia/Hovd',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Ulaanbaatar',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Europe/Podgorica',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Montserrat',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Africa/Casablanca',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/El_Aaiun',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Maputo',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Yangon',
      timezoon: 'UTC +06:30',
    },
    {
      country: 'Africa/Windhoek',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Pacific/Nauru',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Asia/Kathmandu',
      timezoon: 'UTC +05:45',
    },
    {
      country: 'Europe/Amsterdam',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Pacific/Noumea',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Pacific/Auckland',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Pacific/Chatham',
      timezoon: 'UTC +12:45',
    },
    {
      country: 'America/Managua',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'Africa/Niamey',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Africa/Lagos',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Pacific/Niue',
      timezoon: 'UTC -11:00',
    },
    {
      country: 'Pacific/Norfolk',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Pacific/Saipan',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Europe/Oslo',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Muscat',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Asia/Karachi',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Pacific/Palau',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Gaza',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Hebron',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'America/Panama',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Pacific/Bougainville',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Pacific/Port_Moresby',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'America/Asuncion',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Lima',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'Asia/Manila',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Pacific/Pitcairn',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'Europe/Warsaw',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Atlantic/Azores',
      timezoon: 'UTC',
    },
    {
      country: 'Atlantic/Madeira',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Europe/Lisbon',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'America/Puerto_Rico',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Asia/Qatar',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Bucharest',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Anadyr',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Asia/Barnaul',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Chita',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Irkutsk',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Asia/Kamchatka',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Asia/Khandyga',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Krasnoyarsk',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Magadan',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Asia/Novokuznetsk',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Novosibirsk',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Omsk',
      timezoon: 'UTC +06:00',
    },
    {
      country: 'Asia/Sakhalin',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Asia/Srednekolymsk',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Asia/Tomsk',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Ust-Nera',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Asia/Vladivostok',
      timezoon: 'UTC +10:00',
    },
    {
      country: 'Asia/Yakutsk',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Asia/Yekaterinburg',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Europe/Astrakhan',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Europe/Kaliningrad',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Kirov',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Moscow',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Samara',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Europe/Saratov',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Europe/Ulyanovsk',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Europe/Volgograd',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Kigali',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Indian/Reunion',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'America/St_Barthelemy',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Atlantic/St_Helena',
      timezoon: 'UTC',
    },
    {
      country: 'America/St_Kitts',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/St_Lucia',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Marigot',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Miquelon',
      timezoon: 'UTC -02:00',
    },
    {
      country: 'America/St_Vincent',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Pacific/Apia',
      timezoon: 'UTC +13:00',
    },
    {
      country: 'Europe/San_Marino',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Sao_Tome',
      timezoon: 'UTC',
    },
    {
      country: 'Asia/Riyadh',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Dakar',
      timezoon: 'UTC',
    },
    {
      country: 'Europe/Belgrade',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Indian/Mahe',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Africa/Freetown',
      timezoon: 'UTC',
    },
    {
      country: 'Asia/Singapore',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'America/Lower_Princes',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Europe/Bratislava',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Ljubljana',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Pacific/Guadalcanal',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'Africa/Mogadishu',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Johannesburg',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Atlantic/South_Georgia',
      timezoon: 'UTC -02:00',
    },
    {
      country: 'Africa/Juba',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Ceuta',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Atlantic/Canary',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Europe/Madrid',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Colombo',
      timezoon: 'UTC +05:30',
    },
    {
      country: 'Africa/Khartoum',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'America/Paramaribo',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Arctic/Longyearbyen',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Mbabane',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Stockholm',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Zurich',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Asia/Damascus',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Taipei',
      timezoon: 'UTC +08:00',
    },
    {
      country: 'Asia/Dushanbe',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Africa/Dar_es_Salaam',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Bangkok',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'Asia/Dili',
      timezoon: 'UTC +09:00',
    },
    {
      country: 'Africa/Lome',
      timezoon: 'UTC',
    },
    {
      country: 'Pacific/Fakaofo',
      timezoon: 'UTC +13:00',
    },
    {
      country: 'Pacific/Tongatapu',
      timezoon: 'UTC +13:00',
    },
    {
      country: 'America/Port_of_Spain',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Africa/Tunis',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'Europe/Istanbul',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Ashgabat',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'America/Grand_Turk',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Pacific/Funafuti',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Africa/Kampala',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Kyiv',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Europe/Simferopol',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Asia/Dubai',
      timezoon: 'UTC +04:00',
    },
    {
      country: 'Europe/London',
      timezoon: 'UTC +01:00',
    },
    {
      country: 'America/Adak',
      timezoon: 'UTC -09:00',
    },
    {
      country: 'America/Anchorage',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'America/Boise',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Chicago',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Denver',
      timezoon: 'UTC -06:00',
    },
    {
      country: 'America/Detroit',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Indiana/Indianapolis',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Indiana/Knox',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Indiana/Marengo',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Indiana/Petersburg',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Indiana/Tell_City',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Indiana/Vevay',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Indiana/Vincennes',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Indiana/Winamac',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Juneau',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'America/Kentucky/Louisville',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Kentucky/Monticello',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Los_Angeles',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Menominee',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Metlakatla',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'America/New_York',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/Nome',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'America/North_Dakota/Beulah',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/North_Dakota/Center',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/North_Dakota/New_Salem',
      timezoon: 'UTC -05:00',
    },
    {
      country: 'America/Phoenix',
      timezoon: 'UTC -07:00',
    },
    {
      country: 'America/Sitka',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'America/Yakutat',
      timezoon: 'UTC -08:00',
    },
    {
      country: 'Pacific/Honolulu',
      timezoon: 'UTC -10:00',
    },
    {
      country: 'Pacific/Midway',
      timezoon: 'UTC -11:00',
    },
    {
      country: 'Pacific/Wake',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'America/Montevideo',
      timezoon: 'UTC -03:00',
    },
    {
      country: 'Asia/Samarkand',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Asia/Tashkent',
      timezoon: 'UTC +05:00',
    },
    {
      country: 'Pacific/Efate',
      timezoon: 'UTC +11:00',
    },
    {
      country: 'America/Caracas',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Asia/Ho_Chi_Minh',
      timezoon: 'UTC +07:00',
    },
    {
      country: 'America/Tortola',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'America/St_Thomas',
      timezoon: 'UTC -04:00',
    },
    {
      country: 'Pacific/Wallis',
      timezoon: 'UTC +12:00',
    },
    {
      country: 'Asia/Aden',
      timezoon: 'UTC +03:00',
    },
    {
      country: 'Africa/Lusaka',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Africa/Harare',
      timezoon: 'UTC +02:00',
    },
    {
      country: 'Europe/Mariehamn',
      timezoon: 'UTC +03:00',
    },
  ]

  filteredOptions: Observable<string[]>;

  intractionTypes: any = [
    { 'id': 1, 'title': 'Auto Assign', 'image': 'assets/img/scrm/autoassign.jpg', 'description': 'This mode will Assign your Feeds Automaticall to the Agent by Reading the Contents.', 'permission': ['View Auto Assign', 'Add Auto Assign', 'Manage Auto Assign'] },
    { 'id': 2, 'title': 'ITRS', 'image': 'assets/img/scrm/itrs.jpg', 'description': 'This mode will Intract with your Customer then Assign Ticket accordingly by Intractive Text Response System.', 'permission': ['View ITRS', 'Add ITRS', 'Manage ITRS'] },
    { 'id': 3, 'title': 'Webhook', 'image': 'assets/img/scrm/webhook.jpg', 'description': 'This mode will Redirect the Customer messages by Webhook URL and Response accordingly by Restfull API.', 'permission': ['View Webhook', 'Add Webhook', 'Manage Webhook'] },
  ];
  intractiveButtonOption: any = {
    title: 'Intractive Type',
    defaultSelection: null,
    defaultSelectionName: null,
    menus: this.intractionTypes
  }

  itrsbotParam: any;


  constructor(public userService: UserService,
    public clientSiteService: ClientSiteService,
    public settingsService: SettingsService,
    private alertService: AlertService,
    private commonService: CommonService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public itrsbotService: ItrsbotService,
    public matDialog: MatDialog,
    public teamService: TeamService,
    private _formBuilder: UntypedFormBuilder
  ) {

    this.itrsbotService.params.pipe(untilDestroyed(this)).subscribe(data => this.itrsbotParam = data);
    this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.clientSite = data;
      if (this.clientSite && this.clientSite.id) {
        this.accountData = this.clientSite?.account_name;
        this.intractiveButtonOption.defaultSelection = (this.clientSite) ? this.clientSite.intraction_type : null;
        var menuData = this.intractiveButtonOption.menus.filter(menu => menu.id == this.intractiveButtonOption.defaultSelection)
        this.intractiveButtonOption.defaultSelectionName = menuData[0]['title'];
      }
    });

    this.clientSiteService.allLanguage.pipe(untilDestroyed(this)).subscribe(data => this.allLanguageData = data);
    this.clientSiteService.allModule.pipe(untilDestroyed(this)).subscribe(data => this.moduleListDate = data);
    this.teamService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.teams = data
      if (this.teams && this.teams.length > 0) {
        var teamsData = {
          "id": null,
          "name": "Select Team",
          "person_type": "agent",
          "is_default": "0",
          "personTeamsCount": "0"

        }
        this.teams.unshift(teamsData);
      }

    });

    this.settingsService.clientSiteProfile.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.base64) {
        this.profileImages = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(data.base64));
      }
    });

    this.settingsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.profileDataView = data;
      if (this.profileDataView.success) {
        if (this.profileDataView.clientSiteInfo && this.profileDataView.clientSiteInfo.account_name) {
          this.accountData = this.profileDataView.clientSiteInfo.account_name;
        }

        this.verticals = this.profileDataView.allverticals;

        if (this.profileDataView.timeZoon == undefined) {
          this.profileDataView['timeZoon'] = 'Asia/Kuwait';
        }

        /** profile set value */
        var webAddress: any;
        if (this.profileDataView.whatsappWebsites && this.profileDataView.whatsappWebsites.length > 0) {
          webAddress = this.profileDataView.whatsappWebsites[0];
        }

        this.profileUpdateForm.setValue({
          businessVertical: this.profileDataView ? this.profileDataView.businessVertical : '',
          whatsappAddress: this.profileDataView ? this.profileDataView.whatsappAddress : '',
          whatsappEmail: this.profileDataView ? this.profileDataView.whatsappEmail : '',
          whatsappWebsites: webAddress ? webAddress : [],
          whatsappAbout: this.profileDataView ? this.profileDataView.whatsappAbout : '',
          whatsappDescription: this.profileDataView ? this.profileDataView.whatsappDescription : '',
          timeZoon: this.profileDataView ? this.profileDataView.timeZoon : 'Asia/Kuwait',
        })

        if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.default_language) {
          this.selectedLanguage = this.profileDataView.mongo.settings.default_language
        } else {
          this.selectedLanguage = "unknown";
        }

        if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_pay_module) {
          this.selectedModule = this.profileDataView.mongo.settings.aicommerce_pay_module
        } else {
          this.selectedModule = "unknown";
        }

        if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_assign_team) {
          this.selectedTeam = this.profileDataView.mongo.settings.aicommerce_assign_team
        } else {
          this.selectedTeam = "unknown";
        }

        if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_greetings) {
          this.greetingsData = this.profileDataView.mongo.settings.aicommerce_greetings
        } else {
          this.greetingsData = "";
        }

        if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_automation) {
          if (this.profileDataView["mongo"]["settings"]["aicommerce_automation"] == 1) {
            this.is_aicommerce_automation = true;
          } else {
            this.is_aicommerce_automation = false;
          }
        } else {
          this.is_aicommerce_automation = false;
        }

        if (this.profileDataView.AvailablePaymentMethods && this.profileDataView.AvailablePaymentMethods.length > 0) {
          this.profileDataView.AvailablePaymentMethods.forEach(paymentMethods => {
            paymentMethods['is_enabled'] = false;
            if (this.profileDataView.mongo && this.profileDataView.mongo.paymentMthods && this.profileDataView.mongo.paymentMthods.length > 0) {
              this.profileDataView.mongo.paymentMthods.forEach(payment => {
                if (payment == paymentMethods.id) {
                  paymentMethods['is_enabled'] = true;
                }
              });
            }
          });

          var paymentData = this.profileDataView.AvailablePaymentMethods.filter(e => e.id == 2);
          if (paymentData && paymentData.length > 0 && paymentData[0].is_enabled) {
            this.isOnlinePayment = true;
          } else {
            this.isOnlinePayment = false;
          }
        }

        if (this.profileDataView.AvailableGovernarates && this.profileDataView.AvailableGovernarates.length > 0) {
          this.profileDataView.AvailableGovernarates.forEach(element => {
            element.price = 0;
            if (this.profileDataView.mongo && this.profileDataView.mongo.deliveryInfo && this.profileDataView.mongo.deliveryInfo.length > 0) {
              this.profileDataView.mongo.deliveryInfo.forEach(deliverySetting => {
                if (element._id == deliverySetting.governorate_id) {
                  element.client_id = deliverySetting.client_id;
                  element.currency = deliverySetting.currency;
                  element.governorate_id = deliverySetting.governorate_id;
                  element.is_delivarable = deliverySetting.is_delivarable;
                  element.price = deliverySetting.price ? deliverySetting.price : 0;
                  element.updated_at = deliverySetting.updated_at;
                  element.deliveryId = deliverySetting._id;
                  element.team_id = deliverySetting.team_id;
                  element.isEditable = false;
                }
              });
            } else {
              element.is_delivarable = false;
            }

          });
        }


        this.cd.detectChanges();
      }

    });

  }

  ngOnInit(): void {

    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.profileUpdateForm = this._formBuilder.group({
      businessVertical: [null, Validators.required],
      whatsappEmail: [''],
      timeZoon: [''],
      whatsappWebsites: ['', [Validators.required, Validators.max(300), Validators.min(5), Validators.pattern(urlRegex)]],
      whatsappAbout: [''],
      whatsappDescription: [''],
      whatsappAddress: [''],
    })

    this.filteredOptions = this.timeZoon.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.itrsbotService.unSubscribe();
    this.itrsbotService.unSubscribeFilter();
    this.intractiveButtonOption.defaultSelection = null;
    this.intractiveButtonOption.defaultSelectionName = null;
  }

  ngAfterViewInit() {
    this.dataInit();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.timeZoonData.filter(option => option.country.toLowerCase().includes(filterValue));
  }

  get businessVertical() { return this.profileUpdateForm.get('businessVertical'); }
  get timeZoon() { return this.profileUpdateForm.get('timeZoon'); }
  get whatsappEmail() { return this.profileUpdateForm.get('whatsappEmail'); }
  get whatsappWebsites() { return this.profileUpdateForm.get('whatsappWebsites'); }
  get whatsappAbout() { return this.profileUpdateForm.get('whatsappAbout'); }
  get whatsappDescription() { return this.profileUpdateForm.get('whatsappDescription'); }
  get whatsappAddress() { return this.profileUpdateForm.get('whatsappAddress'); }

  dataInit() {
    this.itrsbotParam.client_site_id = this.clientSite.id.toString();;
    setTimeout(() => {
      this.itrsbotService.getAllItems();
    }, 500);
  }

  /** Start profile image add */

  fileChangeEvent(e: any) {
    if (e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/svg") {
      var filesize = e.target.files[0].size;
      const fileSizeInKB = Math.round(filesize / 1024);
      if (fileSizeInKB <= 5120) {
        this.InvalidFile = false;
        this.fileSizeInvalid = false;
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      } else {
        this.alertService.webShow('Danger', 'Please Select below 5MB');
      }
    } else {
      this.alertService.webShow('Danger', 'image format not supported please select a png, jpg, jpeg and svg file');
    }
  }

  handleReaderLoaded(e: any) {
    let reader = e.target;
    var data = {
      client_site_id: this.clientSite.id,
      base64: reader.result,
    }
    this.settingsService.updateProfile(data);
  }

  profileUpdate(data) {
    this.settingsService.updateProfile(data);
  }
  /** End profile image add */

  whatsappEmailDate(event) {
    if (event && event.target && event.target.value.length > 0) {
      this.profileUpdateForm.get('whatsappEmail').setValidators([Validators.required, Validators.maxLength(30), Validators.minLength(5), Validators.pattern(this.emailPattern)],);
      this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
    } else {
      this.profileUpdateForm.get('whatsappEmail').clearValidators();
      this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
    }
  }

  /** Update Profile Data */
  profileData() {
    // var data = this.profileUpdateForm.value;
    if (!this.profileUpdateForm.controls['whatsappEmail'].value) {
      this.profileUpdateForm.get('whatsappEmail').clearValidators();
      this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
    } else {
      this.profileUpdateForm.get('whatsappEmail').setValidators([Validators.required, Validators.maxLength(30), Validators.minLength(5), Validators.pattern(this.emailPattern)],);
      this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
    }
    if (this.profileUpdateForm.invalid) {
      return;
    }
    var data = {
      "whatsappEmail": this.profileUpdateForm.controls['whatsappEmail'].value,
      "whatsappAddress": this.profileUpdateForm.controls['whatsappAddress'].value,
      "whatsappDescription": this.profileUpdateForm.controls['whatsappDescription'].value,
      "whatsappAbout": this.profileUpdateForm.controls['whatsappAbout'].value,
      "businessVertical": this.profileUpdateForm.controls['businessVertical'].value,
      "whatsappWebsites": [],
      "client_site_id": this.clientSite.id
    }
    data.whatsappWebsites.push(this.profileUpdateForm.controls['whatsappWebsites'].value);
    this.settingsService.updateClientSite(data)
  }

  cartActive(e) {
    var data = {
      is_cart_enabled: e.checked ? 'yes' : 'no',
      "client_site_id": this.clientSite.id
    }
    this.settingsService.updateClientSite(data)
  }

  catlogActive(e) {
    var data = {
      is_catalog_visible: e.checked ? 'yes' : 'no',
      "client_site_id": this.clientSite.id
    }
    this.settingsService.updateClientSite(data)
  }

  paymentStatusUpdate(e, paymentMode = null) {
    var status: any = {
      "client_site_id": this.clientSite.id,
      "paymentMethods": [],
    };
    if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.paymentMthods && this.profileDataView.mongo.paymentMthods.length > 0) {
      const isInArray = this.profileDataView.mongo.paymentMthods.includes(paymentMode.id);
      if (isInArray) {
        var index = this.profileDataView.mongo.paymentMthods.indexOf(paymentMode.id);
        if (index !== -1) {
          this.profileDataView.mongo.paymentMthods.splice(index, 1);
        }
        status["paymentMethods"] = this.profileDataView.mongo.paymentMthods;
      } else {
        this.profileDataView.mongo.paymentMthods.push(paymentMode.id);
        status["paymentMethods"] = this.profileDataView.mongo.paymentMthods;
      }
    } else {
      status["paymentMethods"].push(paymentMode.id);
    }

    this.settingsService.updateEcommercePayemnt(status);
  }

  isAutoActive(e) {
    var data = {
      aicommerce_automation: e.checked ? 'yes' : 'no',
      "client_site_id": this.clientSite.id
    }
    this.settingsService.updateEcommerceSettings(data);
  }

  updateLanguage(value) {
    var data = {
      language: value,
      "client_site_id": this.clientSite.id
    }
    this.settingsService.updateEcommerceSettings(data);
  }

  // module update
  updateModule(value) {
    var data = {
      aicommerce_pay_module: value,
      "client_site_id": this.clientSite.id
    }
    this.settingsService.updateEcommerceSettings(data);
  }

  // deliverySettings
  priceUpdate(action, data = null) {
    if (action == 'edit') {
      this.profileDataView.AvailableGovernarates.forEach(price => {
        if (price._id == data._id) {
          price.isEditable = true;
        } else {
          price.isEditable = false;
        }
      });
    }
    if (action == 'remove') {
      this.profileDataView.AvailableGovernarates.forEach(price => {
        price.isEditable = false;
      });
    }
    if (action == 'update') {
      var setting = {
        "governorate_id": data._id,
        "is_delivarable": data.is_delivarable ? "yes" : "no",
        "price": this.updatePrice,
        "client_site_id": this.clientSite.id
      }
      this.settingsService.deliverySettings(setting);
    }
  }

  saveSetting(data, price) {
    this.updatePrice = price;
  }


  deliveryStatus(data, event) {
    if (event.checked) {
      var setting = {
        "governorate_id": data._id,
        "is_delivarable": "yes",
        "price": data.price ? data.price : 0,
        "client_site_id": this.clientSite.id
      }
      this.settingsService.deliverySettings(setting);
    } else {
      var setting = {
        "governorate_id": data._id,
        "is_delivarable": "no",
        "price": data.price ? data.price : 0,
        "client_site_id": this.clientSite.id
      }
      this.settingsService.deliverySettings(setting);
    }
  }

  deliveryTeams(data, event) {
    if (event) {
      var settings = {
        "governorate_id": data._id,
        "is_delivarable": data.is_delivarable ? 'yes' : 'no',
        "price": data.price ? data.price : 0,
        "client_site_id": this.clientSite.id,
        "team_id": event
      }
      this.settingsService.deliverySettings(settings);
    } else {
      var settings = {
        "governorate_id": data._id,
        "is_delivarable": data.is_delivarable ? 'yes' : 'no',
        "price": data.price ? data.price : 0,
        "client_site_id": this.clientSite.id,
        "team_id": null
      }
      this.settingsService.deliverySettings(settings);
    }
  }

  // update timezone information
  selectionLocation(e = null) {
    if (e) {
      var data = {
        "client_site_id": this.clientSite.id,
        "time": e.country,
        "zone": e.timezoon
      }
      this.settingsService.updateSettings(data)
    }
  }
  clearTimeZone() {
    this.profileUpdateForm.controls.timeZoon.reset();
  }

  // account name update
  socialSettingsUpdate() {
    this.timeZoon.value
    var data = {
      "client_site_id": this.clientSite.id,
      "account_name": this.accountData
    }
    this.settingsService.accountSettings(data)
  }

  // teams and greetings settings
  teamsSettingsUpdate() {
    this.timeZoon.value
    var data = {
      "client_site_id": this.clientSite.id,
    }
    if (this.selectedTeam && this.selectedTeam.length > 0) {
      data["aicommerce_assign_team"] = this.selectedTeam;
    } else {
      delete data["aicommerce_assign_team"];
    }
    if (this.greetingsData && this.greetingsData.length > 0) {
      data["aicommerce_greetings"] = this.greetingsData;
    } else {
      delete data["aicommerce_greetings"];
    }

    this.settingsService.updateEcommerceSettings(data);
  }

  changeConfig() {
    let dialogRef: any = null;
    switch (this.clientSite.details.slug) {
      case "whatsapp":
        this.clientSiteService.getAllModule();
        this.teamService.getAllItems();
        dialogRef = this.matDialog.open(WhatsappFormComponent, {
          width: "600px",
          data: {
            action: 'changeConfig',
            item: this.clientSite,
          },
          panelClass: ['animate__animated', 'animate__slideInRight']
        });
        break;
      default:
        break;
    }
    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response;
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
          case 'whatsapp':
            this.clientSiteService.getAllItems();
            break;

        }
      });
  }

  closeProfile() {
    if (!this.is_aicommerce_automation && this.selectedTeam == "unknown") {
      this.alertService.webShow('Danger', 'Team is mandatory');
    } else {
      this.onClose.emit(false);
    }
  }

  getIcon() {
    return (this.clientSite && this.clientSite.details && this.clientSite.details.icon) ? this.clientSite.details.icon : "home";
  }

  changeIntract(intract) {
    if (this.clientSite && this.clientSite.intraction_type != intract.id) {
      let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '400px',
        data: {
          title: 'Change Mode of Intraction',
          type: 'info',
          message: 'Are you sure want to Change this Intraction Mode to ' + intract.title + ' ? So, that you may be loose your Previous Settings.',
        }
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        // if (result && result.data) {
        // 	this.clientSiteService.update(this.clientSite.id, { 'intraction_type': intract.id });
        // }
        if (result && result.data) {
          var data = {
            "client_site_id": this.clientSite.id,
            'intraction_type': intract.id
          }

          this.clientSiteService.updateItrsSettings(data, 'client-site/settings');
          setTimeout(() => {
            this.clientSiteService.getAllItems();
            this.onClose.emit(false);
          }, 500);
        }
      });
    }
  }

  // onSelectIntractiveMode(intractive) {
  //   this.selectedIntractive = intractive;
  //   this.onSelectIntractive.emit(intractive);
  // }

}
