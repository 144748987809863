import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'whatsapp-intreactive-message-view',
	templateUrl: './whatsappIntreactiveMessageView.component.html',
	styleUrls: ['./whatsappIntreactiveMessageView.component.scss']
})

export class WhatsappIntreactiveMessageViewComponent implements OnInit, OnDestroy {

	logoImg = "./assets/images/tji/logo_white_bg.png";
	@Input() interactiveData: any = null;

	constructor() { }

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
	}

}
