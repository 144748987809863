import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { FbTemplateMessageFormComponent } from './templateMessage-form/templateMessage-form.component';
// import { TemplateHistoryComponent } from './template-history/template-history.component';

import { WabaService, FbTemplateMessageService, ClientSiteService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: FbTemplateMessageService},
	},
	// {
	// 	path: 'form',
	// 	component: FbTemplateMessageFormComponent,
	// 	resolve: { clientSite: ClientSiteService, stencil: StencilService },
	// },
	// {
	// 	path: 'history',
	// 	component: TemplateHistoryComponent,
	// 	resolve: { data: TemplateUsageService },
	// },
	// {
	// 	path: 'history/:templateId',
	// 	component: TemplateHistoryComponent,
	// 	resolve: { data: TemplateUsageService },
	// },
	{
		path: ':mode/:id',
		component: FbTemplateMessageFormComponent,
	},
	{
		path: ':mode',
		component: FbTemplateMessageFormComponent,
	},
	{ path: '**', redirectTo: '/app/fb-template' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class FbTemplateRouteModule { }
