import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../../form/form.component';

import {
	User, UserService, ImageService,
	RRoleService, RRole, RRoleModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'role-item-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
	authUser: User;
	role: RRole;

	constructor(public userService: UserService,
		public rroleService: RRoleService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.rroleService.item.pipe(untilDestroyed(this)).subscribe(data => this.role = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Add / Update role */
	newRole(role = null): void {
		this.rroleService.changeItem((role) ? role : new RRoleModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (role) ? 'edit' : 'new',
				item: role,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.rroleService.store("reseller/client/add", formData.value);
						break;
					case 'update':
						formData.value["id"] = id;
						this.rroleService.update('reseller/client/update', formData.value);
						break;
				}
			});
	}

	getPermissionLength(role) {
		if (role && role.permission) {
			return role.permission.length;
		}
		return 0;
	}

}
