import { Component, ElementRef, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { ShareMediaFormComponent } from './../shareMediaForm/shareMediaForm.component';
import { FormComponent } from './../form/form.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
    User, UserService,
    MediaService, Media, MediaModel,
    ChatService,
    AlertService,
} from 'src/app/@tji/_dbShare';
import { assert } from 'console';

@UntilDestroy()
@Component({
    selector: 'gallery-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {

    loader: boolean = true;
    subLoader: boolean = false;
    @Output() onSendMedia = new EventEmitter();
    @Input() tableView: boolean = false;
    @Input() flexWidth: string = "19";
    @Input() showSend: boolean = false;
    @Input() isChatMedia: boolean = false;
    authUser: User;

    galleries: Media[] = [];
    param: any;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    userType = JSON.parse(localStorage.getItem('tji_user'));

    // MatPaginator Output
    pageEvent: PageEvent;
    dataLength: number = 0;
    mediaForm: UntypedFormGroup;


    constructor(public userService: UserService,
        private _formBuilder: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        public mediaService: MediaService,
        public chatService: ChatService,
        private snackBar: MatSnackBar,
        private router: Router,
        public matDialog: MatDialog,
        private orderByPipe: OrderByPipe,
        private alertService: AlertService,) {
        this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
        this.mediaService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {
            this.dataLength = data;
        });
        this.mediaService.loader.pipe(untilDestroyed(this)).subscribe(data => {
            this.loader = data;
        });
        this.mediaService.params.pipe(untilDestroyed(this)).subscribe(data => {
            this.param = data;
        });
        this.mediaService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
            if (data && data.length > 0) {
                this.galleries = this.orderByPipe.transform(data, '-_id');
                this.cd.detectChanges();
            } else {
                this.galleries = data;
            }
        });
        this.mediaService.isCopyUrl.pipe(untilDestroyed(this)).subscribe(data => {
            if (data == null || data.length == 0 && this.galleries && this.galleries.length > 0) {
                this.galleries.forEach(element => {
                    element.isSelected = false;
                });
            }
        });
        this.mediaForm = this.createForm();
    }

    ngOnInit() {
        // this.doLoader();
        // this.cd.detectChanges();
        // this.loader = true;
    }

    /** On destroy */
    ngOnDestroy(): void {
        this.isChatMedia = false;
        this.mediaService.unSubscribeFilter();
        this.mediaService.unSubscribe();
        this.cd.detectChanges();
    }

    /** Create form */
    createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            file: [null],
            asset: [null],
            asset_name: [null],
            asset_type: [null],
        });
    }

    doLoader() {
        setTimeout(() => {
            this.loader = !this.loader;
        }, 5000);
    }

    doTabLoader() {
        setTimeout(() => {
            this.loader = !this.loader;
        }, 2000);
    }

    setSubLoader() {
        this.subLoader = true;
        setTimeout(() => {
            this.subLoader = false;
        }, 5000);
    }

    filterGallery(type: string): Media[] {
        let filteredData: Media[] = [];
        let ismymedia = (type && type === 'mymedia') ? true : false;
        let isfav = (type && type === 'favorite') ? true : false;
        let isShared = (type && type === 'shared') ? true : false;
        if (ismymedia) {
            filteredData = this.galleries;
            // filteredData = this.galleries.filter(x => {
            //     return (x.user_id === this.authUser.id) ? true : false;
            // });
        } else if (isfav) {
            filteredData = this.galleries;
            //  filteredData = this.galleries.filter(x => {
            //     return (x.is_favorite === true && x.user_id === this.authUser.id) ? true : false;
            // });
        } else if (isShared) {
            filteredData = this.galleries;
            // filteredData = this.galleries.filter(x => {
            //     return (x.user_id === this.authUser.id && x.shared_by !== null && x.shared_by > 0) ? true : false;
            // });
        } else {
            filteredData = this.galleries;
            // filteredData = this.galleries.filter(x => {
            //     return (x.user_id === this.authUser.id && x.type.toLowerCase() === type.toLowerCase()) ? true : false;
            // });
        }
        return filteredData;
    }

    currentTap: string = 'all';

    onTabChange(event) {
        this.loader = true;
        this.currentTap = event;
        this.copyUrl = '';
        this.mediaService.isCopyUrlSource.next(null);
        this.masterSelected = false;
        // this.mediaService.allItemsSource.next([]);
        this.loader = true;
        let shared: boolean = (event && event === 'shared') ? true : false;
        let fav: boolean = (event && event === 'favorite') ? true : false;
        event = (event && (event === 'image' || event === 'video' || event === 'voice' || event === 'document')) ? event : null;
        if (fav) {
            this.param.type = null;
            this.param.favorite = 1;
            this.param.null = null;
        } else if (shared) {
            this.param.type = null;
            this.param.favorite = null;
            this.param.shared = 1;
        } else {
            this.param.type = event
            this.param.favorite = null;
            this.param.shared = null;
        }
        this.mediaService.getAllItems();
    }

    markAsFavorite(event, media: Media) {
        let message = "Are you sure you want to set as Favorite?";
        if (media && media.is_favorite) { message = "Are you sure you want to Remove from Favorite?"; }
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'info',
                message: message,
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                let update: any = { 'is_favorite': '1', 'id': media._id.toString(), };
                if (media && media.is_favorite) { update = { 'is_favorite': '0', 'id': media._id.toString(), }; }
                this.mediaService.favoriteStore(update);
            }
        });
    }

    /** Add / Update Gallery */
    newGallery(media: any = null): void {
        this.mediaService.changeItem((media) ? media : new MediaModel({}));
        let dialogRef = this.matDialog.open(FormComponent, {
            width: "600px",
            data: {
                action: (media && media._id) ? 'edit' : 'new',
                item: media,
            }
        });

        // dialogRef.afterClosed()
        // 	.subscribe(response => {
        // 		if (!response) { return; }
        // 		const actionType: string = response[0];
        // 		const formData: FormGroup = response[1];
        // 		const id: number = (response[2]) ? response[2] : null;
        // 		switch (actionType) {
        // 			case 'new':
        // 				this.intractiveMessageService.store(formData.value);
        // 				break;
        // 			case 'update':
        // 				this.intractiveMessageService.update(id, formData.value);
        // 				break;
        // 			case 'delete':
        // 				this.deleteIntractiveMessage(intractiveMessage);
        // 				break;
        // 		}
        // 	});
    }

    /** Add / Update Tag */
    shareMedia(event, media: Media): void {
        this.mediaService.changeItem((media) ? media : null);
        let dialogRef = this.matDialog.open(ShareMediaFormComponent, {
            width: "600px",
            data: {
                action: 'edit',
                item: media
            }
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) { return; }
                const actionType: string = response[0];
                const formData: UntypedFormGroup = response[1];
                if (actionType && actionType === 'update') {
                    var data = {
                        "id": media._id,
                        "shared_with": formData.value.share_to.toString(),
                    }
                    this.mediaService.shareStore(data);
                }
            });
    }

    delete(event, media: Media) {
        let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '600',
            data: {
                type: 'delete',
                message: 'Are you sure you want to delete?',
            }
        });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                this.mediaService.destroy(media._id);
            }
        });
    }

    sendMedia(media: Media) {
        this.chatService.changeSendMediaEvent(media);
    }


    checkedList: any;
    masterSelected: boolean = false;
    copyUrl: any;
    collectData = [];
    shareSelected(item: any) {
        this.galleries.forEach(element => {
            if (element._id == item._id) {
                element.isSelected = !element.isSelected;
                if (element.isSelected) {
                    this.collectData.push(element);
                } else {
                    if (this.collectData && this.collectData.length > 0) {
                        this.collectData.forEach((assert, index, object) => {
                            if (assert._id == element._id) {
                                object.splice(index, 1);
                            }
                        });
                    }
                }
            }
        });
        this.getCheckedItemAsserts();
    }
    checkUncheckAll() {
        this.galleries.forEach(element => {
            element.isSelected = !element.isSelected;
        });
        this.getCheckedItemList();
    }

    getCheckedItemList() {
        this.checkedList = [];
        this.copyUrl = '';
        this.galleries.forEach(element => {
            if (element.isSelected) {
                this.checkedList.push(element.url);
                if (!this.copyUrl) {
                    this.copyUrl = element.url + '\n';
                } else {
                    this.copyUrl = this.copyUrl + element.url + '\n';
                }
            }
        });

        if (this.copyUrl && this.copyUrl.length > 0) {
            this.mediaService.isCopyUrlSource.next(this.copyUrl);
        } else {
            this.mediaService.isCopyUrlSource.next(null);
        }
    }

    getCheckedItemAsserts() {
        this.checkedList = [];
        this.copyUrl = '';
        if (this.collectData && this.collectData.length > 0) {
            this.collectData.forEach(element => {
                if (element.isSelected) {
                    this.checkedList.push(element.url);
                    if (!this.copyUrl) {
                        this.copyUrl = element.url + '\n';
                    } else {
                        this.copyUrl = this.copyUrl + element.url + '\n';
                    }
                }
            });
        }

        if (this.copyUrl && this.copyUrl.length > 0) {
            this.mediaService.isCopyUrlSource.next(this.copyUrl);
        } else {
            this.mediaService.isCopyUrlSource.next(null);
        }
    }


    typeData: string = 'image';
    imageHandler(event) {
        let reader = new FileReader();
        let fileData = null;

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

            reader.onload = (e: any) => {
                var types = file.type.split("/");
                var typeDatas = types[1];
                this.typeData = null;
                switch (typeDatas) {
                    case "JPEG": case "JPG":
                    case "PNG": case "GIF":
                    case "TIFF": case "RAW":
                    case "jpeg": case "jpg":
                    case "png": case "gif":
                    case "tiff": case "raw":
                    case "WebP": case "webp": case "WEBP":
                        this.typeData = 'image'
                        break;
                    case "DOC": case "DOCX":
                    case "ODT": case "PDF":
                    case "PPT": case "PPTX":
                    case "TXT": case "XLS":
                    case "XLSX": case "ZIP":
                    case "CVS": case "vnd.ms-excel":
                    case "doc": case "csv":
                    case "docx": case "odt":
                    case "pdf": case "pptx":
                    case "text": case "xls":
                    case "xlsx": case "zip": case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    case "cvs": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
                        this.typeData = 'document'
                        break;
                    case "MP3": case "WAV":
                    case "AAC": case "FLAC":
                    case "Ogg": case "Ogg Vorbis":
                    case "PCM": case "MPEG":
                    case "mp3": case "wav":
                    case "aac": case "flac":
                    case "ogg": case "mpeg":
                    case "ogg vorbis": case "pcm":
                    case "vnd.dlna.adts":
                    case "m4a": case "M4A":
                    case "x-m4a": case "x-M4A":
                        this.typeData = 'voice'
                        break;
                    case "WEBM": case "MPG":
                    case "MP2":
                    case "MPE": case "MPV":
                    case "OGG": case "MP4":
                    case "AVI": case "WMV":
                    case "MOV": case "QT":
                    case "FLV": case "SWF":
                    case "AVCHD":
                    case "webm": case "mpg":
                    case "mp2":
                    case "mpe": case "mpv":
                    case "ogg": case "mp4":
                    case "avi": case "wmv":
                    case "mov": case "qt":
                    case "flv": case "swf":
                    case "avchd": case "3gpp":
                    case "3GPP":
                        this.typeData = 'video'
                    default:
                        break;
                }

                this.mediaForm.patchValue({
                    asset: reader.result,
                    asset_name: file.name.replace(/ /g, "_"),
                    asset_type: this.typeData,
                });
                if (this.typeData != null) {
                    this.mediaService.addStore(this.mediaForm.value);
                    setTimeout(() => {
                        this.mediaForm.patchValue({
                            file: null,
                            asset: null,
                            asset_name: null,
                            asset_type: null
                        });
                    }, 300);
                } else {
                    this.mediaForm.patchValue({
                        file: null,
                        asset: null,
                        asset_name: null,
                        asset_type: null
                    });
                    this.alertService.webShow('Danger', 'This Format Not Supported Contact admin');
                }
            };
        }
    }

}
