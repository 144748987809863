import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, Role,
	Client, ClientService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'role-assign-client',
	templateUrl: './assignClient.component.html',
	styleUrls: ['./assignClient.component.scss']
})

export class AssignClientComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	action: string;
	assignClientForm: UntypedFormGroup;

	clientParam: any;
	clients: Client[] = [];
	role: Role;
	isChecked: boolean = true;

	constructor(public userService: UserService,
		public clientService: ClientService,
		public matDialogRef: MatDialogRef<AssignClientComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientService.params.pipe(untilDestroyed(this)).subscribe(data => this.clientParam = data);
		this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
				if (this.clients.length > 0 && data.length > 0) {
					if (this.clients && this.clients.length > 0) {
						for (var i = 0; i < data.length; ++i) {
							var item = data[i];
							for (var j = 0; j < this.clients.length; ++j) {
								var oldItem = this.clients[j];
								if (oldItem.id == item.id) {
									this.clients[j] = item;
									break;
								}
							}
						}
						this.clients = this.clients.concat(data.filter(x => this.clients.every(y => y.id !== x.id)));
					} else {
						this.clients = data;
					}
					this.reconstract();
					// return this.client;
				} else {
					this.clients = data
					this.reconstract();
				}
			});

		});
	}

	ngOnInit() {
		this.getClients();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getClients() {
		this.clientService.getAllIData();
	}

	getInit(data) {
		// Set the defaults
		this.role = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Client to Apply Role';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Client to Change Role';
				break;
			default:
				break;
		}
		this.assignClientForm = this.createForm();
	}


	reconstract() {
		if (this.clients) {
			this.clients.forEach(element => {
				element['checked'] = false;
			});
			if (this.role && this.role['users'] && this.role['users'].length > 0) {
				this.role['users'].forEach(selectAgent => {
					this.clients.forEach(agent => {
						if (agent.id == selectAgent.model_id) {
							agent['checked'] = true;
						}
					});
				});
			}
		}
	}

	changeClient(agentId) {

		let agentDetails: any = this.clients.filter(x => x.id == agentId)[0];
		if (agentDetails && agentDetails['checked']) {
			this.isChecked = false;
		} else {
			this.isChecked = true;
		}
		this.assignClientForm.controls.client_id.setValue(agentDetails.id);
		this.assignClientForm.controls.checked.setValue(this.isChecked);
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			client_id: [null, [Validators.required]],
			id: [(this.role && this.role.id) ? this.role.id : null, [Validators.required]],
			checked: [this.isChecked, [Validators.required]],
		});
	}

}
