import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.scss']
})
export class RightbarComponent implements OnInit {

  logoImg = './assets/images/tji/logo_black_bg.png';

  constructor(private _formBuilder: UntypedFormBuilder,
              public userService: UserService,
              private router: Router) {
	  	this.userService.authUser();
  }

  	ngOnInit() {
  	}

}
