import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    CommentService, Comment, CommentModel, UserService,
    FlagService, Flag,
    ModalHasFlagService, ModalHasFlag,
    User,
    EventService
} from 'src/app/@tji/_dbShare';

export interface DialogData {
    title: string;
    message: string;
    type: string;
    resource: any;
    clientSiteId: number;
}

@UntilDestroy()
@Component({
    selector: 'close-dialog',
    templateUrl: './close.component.html',
    styleUrls: ['./close.component.scss']
})
export class CloseDialogComponent implements OnInit {

    closeForm: UntypedFormGroup;
    resource: any;
    clientSiteId: number;

    flags: Flag[] = [];
    flagParam: any;

    ticketFlags: ModalHasFlag[] = [];
    ticketFlagParam: any;
    selectedFlags: any[] = [];

    setFlagMandatory: boolean = (this.userService.isSetFlagMandatory()) ? true : false;
    setCommentMandatory: boolean = (this.userService.isSetCommentMandatory()) ? true : false;
    eventData: any;
    authUser: User;

    constructor(public dialogRef: MatDialogRef<CloseDialogComponent>,
        public flagService: FlagService,
        private eventService: EventService,
        public userService: UserService,
        public modalHasFlagService: ModalHasFlagService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _formBuilder: UntypedFormBuilder) {
        this.modalHasFlagService.allItemsSource.next([]);
        this.flagService.allItemsSource.next([]);
        this.resource = data.resource;
        this.clientSiteId = data.clientSiteId;
        this.modalHasFlagService.params.subscribe(data => this.ticketFlagParam = data);
        this.modalHasFlagService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
            if (this.resource && this.resource.id && this.resource.table && this.clientSiteId) {
                if (data && data.length > 0) {
                    this.ticketFlags = data.filter(x => {
                        return (x.resource_type === this.resource.table && x.resource_id === this.resource.id &&
                            x.client_site_id === this.clientSiteId) ? true : false;
                    });
                }
            }
            this.ticketFlags = data
            if (this.ticketFlags && this.ticketFlags.length > 0) {
                this.selectedFlags = (this.ticketFlags && this.ticketFlags.length > 0) ? this.ticketFlags.map(({ flag_id }) => flag_id) : [];
            }
        });
        this.flagService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
            if (this.clientSiteId) {
                this.flags = [];
                if (data && data.length > 0) {
                    data.forEach(element => {
                        if (element.client_site_id && element.client_site_id.length > 0) {
                            element.client_site_id.forEach(id => {
                                if (id.id == this.clientSiteId) {
                                    this.flags.push(element) ? true : false;
                                }
                            });
                        }
                    });
                }
            }
        });
        this.flagService.params.subscribe(data => this.flagParam = data);
        this.userService.user.subscribe(data => this.authUser = data);
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });

    }

    ngOnInit() {
        this.getTicketFlags();
        this.getFlags();
        this.closeForm = this.createForm();
    }
    /** On destroy */
    ngOnDestroy(): void {
        this.flagService.unSubscribeFilter();
        // this.modalHasFlagService.changeAllItems([]);
        // this.flagService.changeAllItems([]);
        this.modalHasFlagService.allItemsSource.next([]);
        this.flagService.allItemsSource.next([]);
    }
    onNoClick(): void {
        this.dialogRef.close({ data: false });
    }

    onOkClick(): void {
        this.dialogRef.close({ data: true, formValue: this.closeForm.value });
    }

    getTicketFlags() {
        if (this.resource && this.resource.id) {
            this.ticketFlagParam.client_id = this.resource.client_id;
            this.ticketFlagParam.client_site_id = this.clientSiteId;
            this.ticketFlagParam.resource_id = this.resource.id;
            if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
                this.ticketFlagParam.resource_id) {
                this.modalHasFlagService.getAllItems();
            }
        }
    }

    getFlags() {
        this.flagParam.client_id = this.resource.client_id;
        this.flagParam.client_site_id = this.clientSiteId;
        this.flagService.getAllItems();
    }

    checkFlag(flag): boolean {
        if (this.ticketFlags && this.ticketFlags.length > 0) {
            let enabledFlag = this.ticketFlags.find(x => {
                return (Number(x.flag_id) === Number(flag.id) && x.resource_id === this.resource.id) ? true : false
            });
            return (enabledFlag && enabledFlag._id) ? true : false;
        } else {
            return false
        }
    }

    getTitle(type: string) {
        if (this.data && this.data.title) {
            return this.data.title;
        }
        switch (type) {
            case "close":
                return "Close Ticket";
                break;
            default:
                return "Close Ticket";
                break;
        }
    }

    getButtonText(type: string) {
        switch (type) {
            case "close":
                return "Go";
                break;
            default:
                return "Go";
                break;
        }
    }

    /** Create form */
    createForm(): UntypedFormGroup {
        this.selectedFlags = (this.ticketFlags && this.ticketFlags.length > 0) ? this.ticketFlags.map(({ flag_id }) => flag_id) : [];
        if (this.setFlagMandatory && this.setCommentMandatory) {
            return this._formBuilder.group({
                id: [(this.resource) ? this.resource.id : null, [Validators.required]],
                title: ["Ticket Closed.", [Validators.required, Validators.minLength(3)]],
                description: [null, [Validators.required, Validators.minLength(1)]],
                rating: [null],
                do_comment: ['true'],
                set_flags: [this.selectedFlags || null, [Validators.required, Validators.minLength(1)]],
                clientSiteId: [this.clientSiteId || null],
            });
        }

        if (this.setFlagMandatory && !this.setCommentMandatory) {
            return this._formBuilder.group({
                id: [(this.resource) ? this.resource.id : null, [Validators.required]],
                title: ["Ticket Closed.", [Validators.required, Validators.minLength(3)]],
                description: [null, [Validators.minLength(1)]],
                rating: [null],
                do_comment: ['true'],
                set_flags: [this.selectedFlags || null, [Validators.required, Validators.minLength(1)]],
                clientSiteId: [this.clientSiteId || null],
            });
        }

        if (!this.setFlagMandatory && this.setCommentMandatory) {
            return this._formBuilder.group({
                id: [(this.resource) ? this.resource.id : null, [Validators.required]],
                title: ["Ticket Closed.", [Validators.required, Validators.minLength(3)]],
                description: [null, [Validators.required, Validators.minLength(1)]],
                rating: [null],
                do_comment: ['true'],
                set_flags: [this.selectedFlags || null, [Validators.minLength(1)]],
                clientSiteId: [this.clientSiteId || null],
            });
        }

        if (!this.setFlagMandatory && !this.setCommentMandatory) {
            return this._formBuilder.group({
                id: [(this.resource) ? this.resource.id : null, [Validators.required]],
                title: ["Ticket Closed.", [Validators.required, Validators.minLength(3)]],
                description: [null, [Validators.minLength(1)]],
                rating: [null],
                do_comment: ['true'],
                set_flags: [this.selectedFlags || null],
                clientSiteId: [this.clientSiteId || null],
            });
        }
    }
    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'Login': case 'login':
                    let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
                    if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
                        this.onNoClick();
                    }
                    break;
                case 'logout': case 'Logout':
                    let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
                    if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
                        this.onNoClick();
                    }
                    break;
                default:
                    break;
            }
        }
    }

}
