
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-intractive-message-view',
  templateUrl: './intractive-message-view.component.html',
  styleUrls: ['./intractive-message-view.component.scss']
})
export class IntractiveMessageViewComponent implements OnInit {

  logoImg = "./assets/images/tji/logo_white_bg.png";
  activeUrl: string = this.router.url;
  @Input() IntractiveMessageData: any = null;
  loader: boolean = false;
  
  constructor(public userService: UserService,
    private router: Router) {
  }

  ngOnInit() { }

  toggleVideo() { }

}
