import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'library-terms-condition-model',
  templateUrl: './terms-condition-model.component.html',
  styleUrls: ['./terms-condition-model.component.scss']
})
export class TermsConditionModelComponent implements OnInit {

  constructor() {
  	
  }

  ngOnInit() {
  }

}
