import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
// import { AssignTeamComponent } from './../assignTeam/assignTeam.component';

import {
	User, UserService,
	LabelService, Label, LabelModel,
	AlertService
} from 'src/app/@tji/_dbShare';
import { LableTemplateFormComponent } from '../lable-template-form/lable-template-form.component';

@UntilDestroy()
@Component({
	selector: 'label-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	labelsAll: Label[] = [];
	labels: Label[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.labels);
	displayedColumns = [ 'name','priority', 'color','count', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	exportLoader: boolean = false;

	constructor(public userService: UserService,
		public labelService: LabelService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private alertService: AlertService,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.labelService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.labelsAll) { this.subLoader = false; }
			this.labelsAll = data;
			this.labels = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.labels);
			this.dataSource.sort = this.sort;
		});
		
		this.labelService.exportLoader.pipe(untilDestroyed(this)).subscribe(data => this.exportLoader = data);
		this.labelService.leadsCount.pipe(untilDestroyed(this)).subscribe(data => {
			if (data >= 1) {
				this.sendTemplateMessage(data);
			}
			if (data == 0){
				this.alertService.webShow('Danger', 'There is No Whatsapp Leads for this Label !!!');
			}
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.labelService.unSubscribeFilter();
		this.labelService.changeleadsCount(null);
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.labelService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.labelService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Label */
	newLabel(label = null): void {
		this.labelService.changeItem((label) ? label : new LabelModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (label) ? 'edit' : 'new',
				item: label,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				// formData.value.is_default = (formData && formData.value.is_default && (formData.value.is_default === 'true' || formData.value.is_default === true)) ? 1 : 0;
				switch (actionType) {
					case 'new':
						this.labelService.labelAdd('label/add',formData.value);
						break;
					case 'update':
						formData.value["id"] = id;
						this.labelService.labelUpdate('label/update',formData.value, label);
						break;
					case 'delete':
						this.deleteLabel(label);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteLabel(label: Label): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id : label.id
				}
				this.labelService.destroy(formData);
			}
		});
	}

	/** markAsActive Label */
	markAsDefault(label): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to set this Label as Default Label ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id: label.id,
					'is_default': 1
				}
				this.labelService.labelMakeDefualt('label/default', formData);
			}
		});
	}

	/** Send Template Message to Lead */
	sendLeabel(label: Label): void {
		this.labelService.changeleadsCount(null);
		this.labelService.changeItem(label);
		this.labelService.getLeadsCount({"lable_id": label.id});
	}

	sendTemplateMessage(label: Label): void {
		let templateDialogRef = this.matDialog.open(LableTemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: label
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				// this.templateMessageService.postUrl(result, 'send-bulk-template');
			}
			this.labelService.changeleadsCount(null);
		});
	}

	exportLeabelLeads(label: any = null) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure want to Export Label',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.labelService.exportAllItems({"lable_id":  label.id}, label.name)
			}
		});
	}
}
