export class LabelModel {
    id: number;
    name: string;
    description: string;
    color: string;
    color_text: string;
    is_default: boolean;
    client_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    tableName?: string;
	connectedPeopleCount: number;
    priorities: any;
    priority: any;
    priorityText: any;

    /**
     * Constructor
     *
     * @param label
     */
    constructor(label) {
            this.id = label.id || '';
            this.name = label.name || '';
            this.color = label.color || '';
            this.color_text = label.color_text || '';
            this.description = label.description || '';
            this.client_id = label.client_id || '';
            this.is_default = label.is_default || false;
            this.created_by = label.created_by || '';
            this.updated_by = label.updated_by || '';
            this.created_at = label.created_at || '';
            this.updated_at = label.updated_at || '';
            this.deleted_at = label.deleted_at || '';

            this.tableName = label.tableName || '';
            this.connectedPeopleCount = label.connectedPeopleCount || 0;
            this.priorities = label.priorities || '';
            this.priorityText = label.priorityText || '';
    }
}
