import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import 'rxjs';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { UnSubscribeService } from 'src/app/@tji/_dbShare/unSubscribe';
import { Contact } from './contact';
import { ContactModel } from './contact.model';


@Injectable({
    providedIn: 'root'
})

export class ContactService {
    item: any;
    private userSource = new BehaviorSubject<Contact>(new ContactModel({}));
    user = this.userSource.asObservable();
    checkedUser: any;

    onChanged: BehaviorSubject<any>;
    onSelected: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<any>;

    constructor(private httpClient: HttpClient,
        private cookieService: CookieService,
        private commonService: CommonService,
        private alertService: AlertService,
        private unSubscribeService: UnSubscribeService,
        private router: Router,
        private snackBar: MatSnackBar) {
        this._unsubscribeAll = new Subject();
        this.onChanged = new BehaviorSubject([]);
    }

    tji_domain: string = this.commonService.backendUrl;

    noAuthHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
    });

    header = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Get Item by Selected ID
    sendContactMail(url: string, data: any) {
        const body = JSON.stringify(data);
        return this.httpClient.post(this.tji_domain + url, body, { headers: this.noAuthHeader }).pipe(
            map((response: any) => {
                if (response && response && response.success && response.message) {
                    if (response.success === 'ok') { this.alert('info', response.message); }
                    if (response.error === 'error') { this.alert('danger', response.message); }
                }
                return response;
            }));
    }

    alert(type: string, message) {
        this.alertService.webShow(type, message);
        // let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
        // this.snackBar.openFromComponent(AlertComponent, {
        //     panelClass: ['alert' + capitalType],
        //     data: {
        //         message: message,
        //         type: type.toLowerCase(),
        //     }
        // });
    }

}

