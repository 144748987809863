export class StencilModel {
    id:number;
    name: string;
    slug: string;
    icon:string;
    color:string;

    /**
     * Constructor
     *
     * @param stencil
     */
    constructor(stencil) {
            this.id = stencil.id || '';
            this.name = stencil.name || '';            
            this.slug = stencil.slug || '';            
            this.icon = stencil.icon || '';            
            this.color = stencil.color || '';            
    }
}
