import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ChatService, MediaService, Media, ProductCartService, ClosedTicketService, UserService, LeadsHistoryService, AicollectionService, EordersService, TemplateMessageService, FbTemplateMessageService, FlagService, SocketService, ClientSiteService, AutoTemplateService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'dashboard-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	ticket: any = null;
	channel: any;
	chatParam: any;
	unreadTicket: boolean = false;
	showGallery: boolean = false;
	subLoader: boolean = false;
	isActive: string = 'info';
	leadsHistoryParam: any;
	aiCollectionParam: any;
	eordersParam: any;
	templateParam: any;
	autoTemplateParam: any;

	constructor(public chatService: ChatService,
		public mediaService: MediaService,
		public closedTicketService: ClosedTicketService,
		public leadsHistoryService: LeadsHistoryService,
		public userService: UserService,
		public templateMessageService: TemplateMessageService,
		public aicollectionService: AicollectionService,
		public productCartService: ProductCartService,
		public flagService: FlagService,
		public clientSiteService: ClientSiteService,
		public socketService: SocketService,
		public autoTemplateService: AutoTemplateService,
		public eordersService: EordersService, public fbTemplateMessageService: FbTemplateMessageService) {
		this.flagService.getAllFlag();
		this.clientSiteService.getAllItems();
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.templateParam = data);
		this.autoTemplateService.params.pipe(untilDestroyed(this)).subscribe(data => this.autoTemplateParam = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			if (this.ticket && this.ticket.id) {
				this.isInfo = true;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
			}
		});
		this.chatService.isGreeting.pipe(untilDestroyed(this)).subscribe(data => {
			if (data) {
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isTicketTemp = false;
				this.isGreeting = true;
			}
		});
		this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.aiCollectionParam = data;
			if (this.ticket && this.ticket.people_id) {
				this.aiCollectionParam.people_id = this.ticket.people_id ? this.ticket.people_id : null;
			}
		});
		this.chatService.params.pipe(untilDestroyed(this)).subscribe(data => this.chatParam = data);
		this.chatService.setUnreadTicket.pipe(untilDestroyed(this)).subscribe(data => this.unreadTicket = data);
		this.chatService.closeTicketEvent.pipe(untilDestroyed(this)).subscribe(data => this.subLoader = data);
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);
		// this.productCartService.cartClientPanel = false;
		this.getClosedTickets();

		this.eordersService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.eordersParam = data;
		});

		// this.productCartService.cartDetails.subscribe(data => {
		// 	if (data) {
		// 		if (this.productCartService.cartPanel) {
		// 			this.isInfo = false;
		// 			this.isComment = false;
		// 			this.isFlag = false;
		// 			this.isLeads = false;
		// 			this.isHistories = false;
		// 			this.isPayment = false;
		// 			this.isTicketTrack = false;
		// 			this.isOrder = false;
		// 			this.isCart = true;
		// 		} else {
		// 			this.isInfo = true;
		// 			this.isComment = false;
		// 			this.isFlag = false;
		// 			this.isLeads = false;
		// 			this.isHistories = false;
		// 			this.isPayment = false;
		// 			this.isTicketTrack = false;
		// 			this.isOrder = false;
		// 			this.isCart = false;
		// 		}
		// 	}
		// });

		this.productCartService.cartPanel.subscribe(data => {
			if (data) {
				if ((data && this.isInfo) || (data && this.isComment) || (data && this.isFlag)
					|| (data && this.isLeads) || (data && this.isHistories) || (data && this.isPayment)
					|| (data && this.isTicketTrack) || (data && this.isOrder) || (data && this.isTicketTemp)) {
					this.isInfo = false;
					this.isComment = false;
					this.isFlag = false;
					this.isLeads = false;
					this.isHistories = false;
					this.isPayment = false;
					this.isTicketTrack = false;
					this.isOrder = false;
					this.isCart = true;
					this.isTicketTemp = false;
				this.isGreeting = false;
				} else {
					this.isInfo = true;
					this.isComment = false;
					this.isFlag = false;
					this.isLeads = false;
					this.isHistories = false;
					this.isPayment = false;
					this.isTicketTrack = false;
					this.isOrder = false;
					this.isCart = false;
					this.isTicketTemp = false;
				this.isGreeting = false;
				}
			} else if ((data == false && this.isInfo) || (data == false && this.isComment) || (data == false && this.isFlag)
				|| (data == false && this.isLeads) || (data == false && this.isHistories) || (data == false && this.isPayment)
				|| (data == false && this.isTicketTrack) || (data == false && this.isOrder) || (data == false && this.isTicketTemp)) {
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = true;
				this.isTicketTemp = false;
				this.isGreeting = false;
			} else {
				this.isInfo = true;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
			}
		});
	}

	ngOnInit() {
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.chatService.resetParams();
		this.chatService.unSubscribe();
		this.productCartService.cartDataSource.next(null);
	}

	dataInit() {
		this.templateParam.limit = 200;
		this.templateParam.current = 1;
		this.templateMessageService.getAllItems();
	}

	changeGalleryView() {
		if (!this.showGallery) {
			this.mediaService.getAllItems();
		}
		this.showGallery = !this.showGallery;
	}

	getClosedTickets() {
		this.closedTicketService.getRecentClosedTickets('n/tickets');
	}

	isInfo: boolean = true;
	isComment: boolean = false;
	isFlag: boolean = false;
	isLeads: boolean = false;
	isHistories: boolean = false;
	isPayment: boolean = false;
	isTicketTrack: boolean = false;
	isOrder: boolean = false;
	isCart: boolean = false;
	isTicketTemp: boolean = false;
	isGreeting: boolean = false;

	ticketInfo(active) {
		switch (active) {
			case "info":
				this.isInfo = !this.isInfo;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				break;
			case "comments":
				this.isInfo = false;
				this.isComment = !this.isComment;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				if (this.isComment) {
					this.chatService.getComments(this.ticket);
				}
				break;
			case "flag":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = !this.isFlag;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				break;
			case "leads":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = !this.isLeads;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				break;
			case "histories":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = !this.isHistories;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				if (this.isHistories && this.ticket && this.ticket.id) {
					setTimeout(() => {
						this.getLeadsHistory(this.ticket);
					}, 300);
				}
				break;
			case "payment":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = !this.isPayment;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				if (this.isPayment) {
					if (this.ticket && this.ticket.people_id) {
						this.aiCollectionParam.people_id = this.ticket.people_id ? this.ticket.people_id : null;
					}
					this.aicollectionService.checkAuth();
					this.aicollectionService.getCurrentItems();
				}
				break;
			case "TrackTicket":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isTicketTrack = !this.isTicketTrack;
				this.isGreeting = false;
				if (this.isTicketTrack) {
					setTimeout(() => {
						this.leadsHistoryService.getTrackTicket(this.ticket);
					}, 300);

				}
				break;
			case "Order":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = !this.isOrder;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isGreeting = false;
				if (this.isOrder) {
					this.eordersService.changeAllItems([]);
					this.eordersParam['people_id'] = this.ticket.people_id;
					this.eordersService.getAllItems();
					// this.leadsHistoryService.getTrackTicket(this.ticket);
				}
				break;
			case "TicketTemp":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isTicketTrack = false;
				this.isOrder = false;
				this.isTicketTemp = !this.isTicketTemp;
				this.isCart = false;
				this.isGreeting = false;
				if (this.isTicketTemp) {
					this.fbTemplateMessageService.allITicketSource.next([]);
					var params = {
						"limit": 50,
						"current": 1
					}
					params['number'] = this.ticket.recipientId;
					this.fbTemplateMessageService.getAllTicketItems(params);
				}
				break;case "Greeting":
				this.isInfo = false;
				this.isComment = false;
				this.isFlag = false;
				this.isLeads = false;
				this.isHistories = false;
				this.isPayment = false;
				this.isOrder = false;
				this.isCart = false;
				this.isTicketTemp = false;
				this.isTicketTrack = false;
				this.isGreeting = !this.isGreeting;
				if (this.isGreeting) {
					this.autoTemplateService.changeAllItems([]);
					this.autoTemplateParam['type'] = '';
					this.autoTemplateParam['client_site_id'] = this.ticket.clientSiteId;
					
					setTimeout(() => {
						this.autoTemplateService.getAllItems();
					}, 300);

				}
				break;
			default:
				break;
		}
	}

	getLeadsHistory(ticket) {
		this.leadsHistoryService.resetParams();
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}	
}
