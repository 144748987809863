import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormGroup } from '@angular/forms';
import { ListComponent } from './../list/list.component';

import { User, UserService, 
		 ConsumerService, ConsumerGroupService, 
		 ConsumerGroupModel } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'consumer-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	user: User;
	@ViewChild(ListComponent) listComponent;
	
	constructor(public userService: UserService,
		public consumerService: ConsumerService,
		public consumerGroupService: ConsumerGroupService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Export Excel */
	exportExcel() {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure you want to Export all Leads',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let clientId = this.userService.getAuthClientId();
				// let url: string = this.consumerService.getExportUrl('excel');
				let url: string = "https://pin.ai-octopus.com/leads/export/";
				window.open(url + clientId, '_blank');
			}
		});
	}

		/** Export PDF */
		exportPdf() {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '600',
				data: {
					type: 'info',
					message: 'Are you sure you want to PDF all Leads',
				}
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.data) {
					let clientId = this.userService.getAuthClientId();
					// let url: string = this.consumerService.getExportUrl('pdf');
					// window.open(url + clientId, '_blank');
				}
			});
		}

}
