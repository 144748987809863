import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { PeopleService } from 'src/app/@tji/_dbShare/person/people/people.service';
import { Media } from 'src/app/@tji/_dbShare/directory/media';
import { FlagService, ModalHasFlagService } from 'src/app/@tji/_dbShare/remark';
import { LeadsHistoryService } from 'src/app/@tji/_dbShare/report';

import { WhatsappService, Whatsapp, WhatsappTicketService, WhatsappTicket } from 'src/app/@tji/_dbShare/whatsapp';
import { WebchatService, Webchat, WebchatTicketService, WebchatTicket } from 'src/app/@tji/_dbShare/webchat';
import { YoutubeService, Youtube, YoutubeTicketService, YoutubeTicket } from 'src/app/@tji/_dbShare/youtube';
import { InstagramService, Instagram, InstagramTicketService, InstagramTicket } from 'src/app/@tji/_dbShare/instagram';
import { InstadmService, Instadm, InstadmTicketService, InstadmTicket } from 'src/app/@tji/_dbShare/instadm';
import { MessengerService, Messenger, MessengerTicketService, MessengerTicket } from 'src/app/@tji/_dbShare/messenger';
import { TweetService, Tweet, TweetTicketService, TweetTicket } from 'src/app/@tji/_dbShare/twitter';
import { TweetdmService, Tweetdm, TweetdmTicketService, TweetdmTicket } from 'src/app/@tji/_dbShare/twitterdm';
import { ClosedTicketService } from 'src/app/@tji/_dbShare/chat/closedTicket.service';
import { CommentService } from 'src/app/@tji/_dbShare/remark/comment/comment.service';

declare var require: any;
var slugify = require('slugify')

@Injectable({
	providedIn: 'root'
})

@UntilDestroy()

export class ChatService {
	authUser: User;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'client_site_id': '',
		'resource_id': '',
		'sort': 'created_at|DESC',
		'is_history': (this.userService.isDefaultShowHistory()) ? 1 : 0,
	};
	commentParam: any;
	flagParam: any;
	ticketFlagParam: any;
	allHistory: boolean = (this.userService.isDefaultShowHistory()) ? true : false;
	// showAutoMessage: boolean = false;
	showAutoMessage: boolean = (this.userService.isDefaultShowAutoMsg()) ? true : false;

	public ticketLibrariesSource = new BehaviorSubject<Array<any>>([]);
	ticketLibraries = this.ticketLibrariesSource.asObservable();

	private concurrentTicketsSource = new BehaviorSubject<Array<any>>([]);
	concurrentTickets = this.concurrentTicketsSource.asObservable();

	private concurrentLimitSource = new BehaviorSubject<any>(null);
	concurrentLimit = this.concurrentLimitSource.asObservable();

	private siteNameSource = new BehaviorSubject<string>(null);
	siteName = this.siteNameSource.asObservable();

	private ticketSource = new BehaviorSubject<any>(null);
	ticket = this.ticketSource.asObservable();

	private chatMessagesSource = new BehaviorSubject<Array<any>>([]);
	chatMessages = this.chatMessagesSource.asObservable();

	private replyChatSource = new BehaviorSubject<any>(null);
	replyChat = this.replyChatSource.asObservable();

	private viewScreenSource = new BehaviorSubject<any>('All Tickets');
	viewScreen = this.viewScreenSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private setHistoryButtonSource = new BehaviorSubject<boolean>((this.userService.isDefaultShowHistory()) ? true : false);
	setHistoryButton = this.setHistoryButtonSource.asObservable();

	private autoMessageSource = new BehaviorSubject<boolean>((this.userService.isDefaultShowAutoMsg()) ? true : false);
	autoMessage = this.autoMessageSource.asObservable();

	private textLoaderSource = new BehaviorSubject<boolean>(false);
	textLoader = this.textLoaderSource.asObservable();

	private textQuickMsgSource = new BehaviorSubject<any>([]);
	textQuickMsg = this.textQuickMsgSource.asObservable();

	onChangeTicket: EventEmitter<boolean> = new EventEmitter();
	setFlagButton: EventEmitter<boolean> = new EventEmitter();
	setCommentButton: EventEmitter<boolean> = new EventEmitter();
	setCloseButton: EventEmitter<boolean> = new EventEmitter();
	setUnreadTicket: EventEmitter<boolean> = new EventEmitter();
	setUserUpdateButton: EventEmitter<boolean> = new EventEmitter();
	setLeadsHistoryButton: EventEmitter<boolean> = new EventEmitter();
	setTrackTickeButton: EventEmitter<boolean> = new EventEmitter();
	closeTicketEvent: EventEmitter<boolean> = new EventEmitter();
	sendMediaEvent: EventEmitter<Media> = new EventEmitter();
	setCartDetailsButton: EventEmitter<boolean> = new EventEmitter();
	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private tweetService: TweetService,
		private tweetTicketService: TweetTicketService,
		private tweetdmService: TweetdmService,
		private tweetdmTicketService: TweetdmTicketService,
		private messengerService: MessengerService,
		private messengerTicketService: MessengerTicketService,
		private whatsappService: WhatsappService,
		private whatsappTicketService: WhatsappTicketService,
		private webchatService: WebchatService,
		private webchatTicketService: WebchatTicketService,
		private youtubeService: YoutubeService,
		private youtubeTicketService: YoutubeTicketService,
		private instagramService: InstagramService,
		private instagramTicketService: InstagramTicketService,
		private instadmService: InstadmService,
		private instadmTicketService: InstadmTicketService,
		private commentService: CommentService,
		private flagService: FlagService,
		private modalHasFlagService: ModalHasFlagService,
		private closedTicketService: ClosedTicketService,
		private leadsHistoryService: LeadsHistoryService,
		private userService: UserService,
		private peopleService: PeopleService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
		this.subscribeAllTickets();
		this.commentService.params.subscribe(data => this.commentParam = data);
		this.modalHasFlagService.params.pipe(untilDestroyed(this)).subscribe(data => this.ticketFlagParam = data);
		this.flagService.params.pipe(untilDestroyed(this)).subscribe(data => this.flagParam = data);
		this.setHistoryButton.subscribe(data => this.allHistory = data);
		this.autoMessage.subscribe(data => this.showAutoMessage = data);
		this.subscribeTicketCloseEvents();
		this.subscribeCloseTickets();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([
				// this.getConcurrentLimit(),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed ChatService');
	}

	whatsapps: Whatsapp[] = [];
	whatsapps$: any;
	whatsappTickets: WhatsappTicket[] = [];
	whatsappTicket: WhatsappTicket;
	messengers: Messenger[] = [];
	messengers$: any;
	messengerTickets: MessengerTicket[] = [];
	messengerTicket: MessengerTicket;
	tweets: Tweet[] = [];
	tweets$: any;
	tweetTickets: TweetTicket[] = [];
	tweetTicket: TweetTicket;
	tweetdms: Tweetdm[] = [];
	tweetdms$: any;
	tweetdmTickets: TweetdmTicket[] = [];
	tweetdmTicket: TweetdmTicket;
	instagrams: Instagram[] = [];
	instagrams$: any;
	instagramTickets: InstagramTicket[] = [];
	instagramTicket: InstagramTicket;
	instadms: Instadm[] = [];
	instadms$: any;
	instadmTickets: InstadmTicket[] = [];
	instadmTicket: InstadmTicket;
	webchats: Webchat[] = [];
	webchats$: any;
	webchatTickets: WebchatTicket[] = [];
	webchatTicket: WebchatTicket;
	youtubes: Youtube[] = [];
	youtubes$: any;
	youtubeTickets: YoutubeTicket[] = [];
	youtubeTicket: YoutubeTicket;
	subscribeAllTickets() {
		this.whatsapps$ = this.whatsappService.libraries.pipe(untilDestroyed(this)).subscribe(data => { 
		this.whatsapps = data; });
		this.whatsappTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.whatsappTicket = data; });
		this.whatsappTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: WhatsappTicket[]) => this.whatsappTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.messengers$ = this.messengerService.libraries.pipe(untilDestroyed(this)).subscribe(data => { this.messengers = data; });
		this.messengerTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.messengerTicket = data; });
		this.messengerTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: MessengerTicket[]) => this.messengerTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.tweets$ = this.tweetService.libraries.pipe(untilDestroyed(this)).subscribe(data => { this.tweets = data; });
		this.tweetTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.tweetTicket = data; });
		this.tweetTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: TweetTicket[]) => this.tweetTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.tweetdms$ = this.tweetdmService.libraries.pipe(untilDestroyed(this)).subscribe(data => { this.tweetdms = data; });
		this.tweetdmTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.tweetdmTicket = data; });
		this.tweetdmTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: TweetdmTicket[]) => this.tweetdmTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.instagrams$ = this.instagramService.libraries.pipe(untilDestroyed(this)).subscribe(data => { this.instagrams = data; });
		this.instagramTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.instagramTicket = data; });
		this.instagramTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: InstagramTicket[]) => this.instagramTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.instadms$ = this.instadmService.libraries.pipe(untilDestroyed(this)).subscribe(data => { 
		this.instadms = data; });
		this.instadmTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.instadmTicket = data; });
		this.instadmTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: InstadmTicket[]) => this.instadmTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.webchats$ = this.webchatService.libraries.pipe(untilDestroyed(this)).subscribe(data => { this.webchats = data; });
		this.webchatTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.webchatTicket = data; });
		this.webchatTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: WebchatTicket[]) => this.webchatTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
		this.youtubes$ = this.youtubeService.libraries.pipe(untilDestroyed(this)).subscribe(data => { this.youtubes = data; });
		this.youtubeTicketService.item.pipe(untilDestroyed(this)).subscribe(data => { this.youtubeTicket = data; });
		this.youtubeTicketService.libraries
			.pipe(untilDestroyed(this))
			.subscribe((data: YoutubeTicket[]) => this.youtubeTickets = data.filter(x => {
				return (x.agent_id === this.authUser.person_id && !x.is_closed) ? true : false;
			}));
	}

	unSubscribeAllChats() {
		this.whatsapps$.unsubscribe();
		this.webchats$.unsubscribe();
		this.youtubes$.unsubscribe();
		this.messengers$.unsubscribe();
		this.tweets$.unsubscribe();
		this.tweetdms$.unsubscribe();
		this.instagrams$.unsubscribe();
		this.instadms$.unsubscribe();
		// this.changeChatMessages([]);
		// console.log('unSubscribed');
	}

	subscribeChats(siteName = null, doUnsubscribe = true) {
		if (!siteName) { this.siteName.subscribe(data => siteName = data); }
		// console.log ([siteName, doUnsubscribe]);
		if (siteName) {
			if (doUnsubscribe) { this.unSubscribeAllChats(); }
			switch(siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Messenger": case "messenger":
					this.messengerService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Webchat": case "webchat":
					this.webchatService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Youtube": case "youtube":
					this.youtubeService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Instagram": case "instagram":
					this.instagramService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				case "Instadm": case "instadm":
					this.instadmService.libraries.subscribe(data => { this.changeChatMessages(data); });
					break;
				default:
					break;
			}
		}
	}

	changeSiteName(siteName: string) {
		this.siteNameSource.next(siteName);
	}

	changeTextLoader(value: boolean) {
		this.textLoaderSource.next(value);
	}

	changeQuickMsg(value: any) {
		this.textQuickMsgSource.next(value);
	}

	setTextLoader() {
		this.textLoaderSource.next(true);
		setTimeout(() => {
			this.textLoaderSource.next(false);
		},5000);
	}

	changeTicketSource(ticket: any) {
		this.ticketSource.next(ticket);
	}

	changeSetFlagButton(event: boolean) {
		if(event) {
			this.modalHasFlagService.changeAllItems([]);
			let ticket = null;
			this.ticket.subscribe(data => ticket = data);
			this.getTicketFlags(ticket);
		}
		this.setFlagButton.emit(event);
	}

	changeSetLeadsHistoryButton(event: boolean) {
		if(event) {
			this.leadsHistoryService.changeHistory(null);
		}
		this.setLeadsHistoryButton.emit(event);
	}

	changeCloseTicketEvent(event: boolean) {
		this.closeTicketEvent.emit(event);
	}

	changeSendMediaEvent(event: Media) {
		this.sendMediaEvent.emit(event);
	}

	changeCloseTicketEventCheckDuration() {
		setTimeout(() => {
			this.closeTicketEvent.emit(false);
		}, 5000);
	}

	setAllButtonEvent(event: boolean) {
		this.setFlagButton.emit(event);
		this.setCommentButton.emit(event);
		this.setUserUpdateButton.emit(event);
		this.setLeadsHistoryButton.emit(event);
		this.setTrackTickeButton.emit(event);
		this.setCartDetailsButton.emit(event);
		if(!event) {
			this.leadsHistoryService.changeHistory(null);
		}
	}
	
    changeTicket(ticket: any) {
		this.unSubscribeAllChats();
		this.changeReplyChat(null);
		this.setHistoryButtonSource.next((this.userService.isDefaultShowHistory()) ? true : false);
		this.autoMessageSource.next((this.userService.isDefaultShowAutoMsg()) ? true : false);
        this.ticketSource.next(ticket);
		this.resetParams();
		if (ticket && ticket.siteName) {
			this.changeSiteName(ticket.siteName);
			this.getChatMessages();
			// this.commentParam.resource_type = ticket.className;
			this.commentParam.resource_id = ticket.id;
			// this.commentService.getAllItems();
			this.getTicketFlags(ticket);
			// this.getFlags(ticket);
			this.peopleService.getItem({'id' : ticket.people_id});
		} else {
			this.unSubscribeAllChats();
			// this.changeChatMessages([]);
			this.changeReplyChat(null);
			this.commentService.changeAllItems([]);
        }
        this.onChangeTicket.emit(true);
        this.setAllButtonEvent(false);
		return;
	}

	getComments(ticket) {
		this.commentParam.resource_type = ticket.className;
		this.commentParam.resource_id = ticket.id;
		this.commentService.getTicketComments();
	}

	getTicketFlags(ticket) {
		this.ticketFlagParam.client_id = ticket.client_id;
		this.ticketFlagParam.client_site_id = ticket.clientSiteId;
		this.ticketFlagParam.resource_id = ticket.id;
        if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
            this.ticketFlagParam.resource_id) {
            this.modalHasFlagService.getAllItems();
        }
	}

	getFlags(ticket) {
		this.flagParam.client_id = ticket.client_id;
		this.flagParam.client_site_id = ticket.clientSiteId;
		this.flagParam.limit = 200;
		this.flagParam.current = 1;
		this.flagService.getAllItems();
	}

	changeTicketLibraries(tickets: Array<any>) {
		this.ticketLibrariesSource.next(tickets);
	}

	concatTicketLibraries(tickets: Array<any>) {
		var oldTicketLibraries: Array<any> = [];
		this.ticketLibraries.subscribe(data => {
			oldTicketLibraries = data;
		});
		if (oldTicketLibraries && oldTicketLibraries.length > 0) {
			oldTicketLibraries = this.globalService.arrayMergeByIdAndTableName(oldTicketLibraries, tickets);
		} else {
			oldTicketLibraries = tickets;
		}
		this.changeTicketLibraries(oldTicketLibraries);
	}

	concatTicketLibrary(item: any) {
		let oldTicketLibraries = [];
		this.ticketLibraries.subscribe(result => {
			oldTicketLibraries = result.concat([item]);
		});
		this.changeTicketLibraries(oldTicketLibraries);
	}

	removeTicketLibrary(item: any) {
		if(item && item.id && item.siteName) {
            this.removeTicketLibraryById(item.id, item.siteName);
		} else {
			let oldTicketLibraries = [];
			this.ticketLibraries.subscribe(data => oldTicketLibraries = data);
			if (oldTicketLibraries && oldTicketLibraries.length > 0 && item && item.id && item.siteName) {
				oldTicketLibraries = oldTicketLibraries.filter(x => {
					return (x.id !== item.id && x.siteName !== item.siteName);
				});
			}
			this.changeTicketLibraries(oldTicketLibraries);
		}
	}

	removeTicketLibraryById(id: number, siteName: string) {
		if(id && siteName) {
            this.removeTicketLibraryByIdOnSocialSites(id, siteName);
			let oldTicketLibraries = [];
			this.ticketLibraries.subscribe(data => oldTicketLibraries = data);
			if (oldTicketLibraries && oldTicketLibraries.length > 0 && id) {
				oldTicketLibraries = oldTicketLibraries.filter(x => {
					return (x.id != id);
				});
			}
			this.changeTicketLibraries(oldTicketLibraries);
		}
	}

    removeTicketLibraryByIdOnSocialSites(id: number, siteName: string) {
		if(siteName) {
			switch (siteName) {
				case 'Whatsapp':
                case 'whatsapp':
                    this.whatsappTicketService.removelibraryById(id);
                    break;
				case 'Messenger':
                case 'messenger':
                    this.messengerTicketService.removelibraryById(id);
                    break;
				case 'Instagram':
                case 'instagram':
                    this.instagramTicketService.removelibraryById(id);
                    break;
				case 'Instadm':
                case 'instadm':
                    this.instadmTicketService.removelibraryById(id);
                    break;
				case 'Twitter':
                case 'twitter':
                    this.tweetTicketService.removelibraryById(id);
                    break;
				case 'Twitterdm':
                case 'twitterdm':
                    this.tweetdmTicketService.removelibraryById(id);
                    break;
				case 'Youtube':
                case 'youtube':
                    this.youtubeTicketService.removelibraryById(id);
                    break;
				case 'Webchat':
                case 'webchat':
                    this.webchatTicketService.removelibraryById(id);
                    break;
                default: break;
			}
		}
	}

	replaceTicketLibrary(item: any) {
		let oldTicketLibraries = [];
		let isReplaced: boolean = false;
		this.ticketLibraries.subscribe(data => oldTicketLibraries = data);
		if (oldTicketLibraries && oldTicketLibraries.length > 0 && item && item.id && item.siteName) {
			for (var i = 0; i < oldTicketLibraries.length; ++i) {
				if (oldTicketLibraries[i].id === item.id && oldTicketLibraries[i].siteName === item.siteName) {
					oldTicketLibraries.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldTicketLibraries.push(item); }
		this.changeTicketLibraries(oldTicketLibraries);
	}

	changeChatMessages(messages: Array<any>) {
		this.chatMessagesSource.next(messages);
	}

	changeReplyChat(messages: any) {
		this.replyChatSource.next(messages);
	}

	changeViewScreen(viewScreen: any) {
		this.viewScreenSource.next(viewScreen);
	}

	changeConcurrentLimit(limit: number) {
		this.concurrentLimitSource.next(limit);
	}

	changeSetHistoryButton(event: boolean) {
		let params = null;
		this.params.subscribe(data => params = data);
		if (!event) {
			params.current = 1;
		}
		this.setHistoryButtonSource.next(event);
		this.getChatMessages(false);
	}

	changeAutoMessage(event: boolean) {
		let params = null;
		this.params.subscribe(data => params = data);
		if (!event) {
			params.current = 1;
		}
		this.autoMessageSource.next(event);
		this.getChatMessages(false);
	}

	getConcurrentLimit() {
		let url: string = 'auth-user-concurrent-limit';
		this.commonService.getItemByParam(url)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeConcurrentLimit(data.data);
			},
				error => {
					console.log('Error ::' + error);
				}
			);
	}

	getChatMessages(doSubscribe: boolean = true) {
		let params = null;
		this.params.subscribe(data => params = data);
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		let ticket = null;
		this.ticket.subscribe(data => ticket = data);
		if (this.allHistory) {
			params.is_history = 1;
			params.current = 1;
		}else{
			params.is_history = 0;
		}
		
		params.is_itrs = (this.showAutoMessage) ? null : '0';
		var isItrsCondition = (this.showAutoMessage) ? null : false;
		if (isItrsCondition == false) {
			params.condition = "is_itrs|=|"+0;
		}else{
			delete params.condition;
		}
		params.client_site_id = (ticket.clientSiteId) ? ticket.clientSiteId : ticket.clientSiteId;
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.tweet_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.whatsappService.changeParams(params);
					this.tweetService.getAllItems();
					// this.tweetService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.tweetdm_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.tweetdmService.changeParams(params);
					this.tweetdmService.getAllItems();
					// this.tweetdmService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Messenger": case "messenger":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.messenger_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.messengerService.changeParams(params);
					this.messengerService.getAllItems();
					// this.messengerService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Whatsapp": case "whatsapp":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.whatsapp_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.whatsappService.changeParams(params);
					this.whatsappService.getAllItems();
					// this.whatsappService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Webchat": case "webchat":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.webchat_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.webchatService.changeParams(params);
					this.webchatService.getAllItems();
					// this.webchatService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Youtube": case "youtube":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.youtube_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.youtubeService.changeParams(params);
					this.youtubeService.getAllItems();
					// this.youtubeService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Instagram": case "instagram":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.instagram_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.instagramService.changeParams(params);
					this.instagramService.getAllItems();
					// this.instagramService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				case "Instadm": case "instadm":
					params.client_site_id = ticket.clientSiteId;
					params.resource_id = ticket.instadm_id;
					params.sort = "created_at|DESC";
					// params.is_history = 1;
					this.instadmService.changeParams(params);
					this.instadmService.getAllItems();
					// this.instadmService.allItems.subscribe(data => {
					// 	this.changeChatMessages(data);
					// });
					break;
				default:
					break;
			}
			if (doSubscribe) { this.subscribeChats(siteName); }
		}
		return;
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
		let siteName = null;
		let params = null;
		this.siteName.subscribe(data => siteName = data);
		this.params.subscribe(data => params = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.changeParams(params);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmService.changeParams(params);
					break;
				case "Messenger": case "messenger":
					this.messengerService.changeParams(params);
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.changeParams(params);
					break;
				case "Webchat": case "webchat":
					this.webchatService.changeParams(params);
					break;
				case "Youtube": case "youtube":
					this.youtubeService.changeParams(params);
					break;
				case "Instagram": case "instagram":
					this.instagramService.changeParams(params);
					break;
				case "Instadm": case "instadm":
					this.instadmService.changeParams(params);
					break;
				default:
					break;
			}
		}
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'client_site_id': '',
			'resource_id': '',
			'sort': 'created_at|DESC',
			'is_history': (this.userService.isDefaultShowHistory()) ? 1 : 0,
		};
		this.changeParams(defaultParams);
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.unSubscribe();
					break;
				case "Twitterdm": case "Twitterdm":
				case "Tweetdm": case "Tweetdm":
					this.tweetdmService.unSubscribe();
					break;
				case "Messenger": case "messenger":
					this.messengerService.unSubscribe();
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.unSubscribe();
					break;
				case "Webchat": case "webchat":
					this.webchatService.unSubscribe();
					break;
				case "Youtube": case "youtube":
					this.youtubeService.unSubscribe();
					break;
				case "Instagram": case "instagram":
					this.instagramService.unSubscribe();
					break;
				case "Instadm": case "instadm":
					this.instadmService.unSubscribe();
					break;
				default:
					break;
			}
		}
	}

	/** Scroll Event */
	onTicketScroll(siteName = null) {
		if(!siteName) {
			this.siteName.subscribe(data => siteName = data);
		}
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetTicketService.onScroll();
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmTicketService.onScroll();
					break;
				case "Messenger": case "messenger":
					this.messengerTicketService.onScroll();
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappTicketService.onScroll();
					break;
				case "Webchat": case "webchat":
					this.webchatTicketService.onScroll();
					break;
				case "Youtube": case "youtube":
					this.youtubeTicketService.onScroll();
					break;
				case "Instagram": case "instagram":
					this.instagramTicketService.onScroll();
					break;
				case "Instadm": case "instadm":
					this.instadmTicketService.onScroll();
					break;
				default:
					break;
			}
		}
	}

	/** Scroll Event */
	onScroll() {
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.onScroll();
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmService.onScroll();
					break;
				case "Messenger": case "messenger":
					this.messengerService.onScroll();
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.onScroll();
					break;
				case "Webchat": case "webchat":
					this.webchatService.onScroll();
					break;
				case "Youtube": case "youtube":
					this.youtubeService.onScroll();
					break;
				case "Instagram": case "instagram":
					this.instagramService.onScroll();
					break;
				case "Instadm": case "instadm":
					this.instadmService.onScroll();
					break;
				default:
					break;
			}
		}
	}

	post(data: any, type: string = null) {
		let url: string = null;
		let siteName = null;
		if (!data.chatId) {
			delete data.chatId;
		}
		this.siteName.subscribe(data => siteName = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					url = 'reply';
					url = (type) ? url + type : url;
					this.tweetService.post(data, url);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					url = 'reply';
					url = (type) ? url + type : url;
					this.tweetdmService.post(data, url);
					break;
				case "Messenger": case "messenger":
					url = 'reply';
					url = (type) ? url + type : url;
					this.messengerService.post(data, url);
					break;
				case "Whatsapp": case "whatsapp":
					url = 'reply';
					url = (type) ? url + type : url;
					this.whatsappService.post(data, url);
					break;
				case "Webchat": case "webchat":
					url = 'reply';
					url = (type) ? url + type : url;
					this.webchatService.post(data, url);
					break;
				case "Youtube": case "youtube":
					url = 'reply';
					url = (type) ? url + type : url;
					this.youtubeService.post(data, url);
					break;
				case "Instagram": case "instagram":
					url = 'reply';
					url = (type) ? url + type : url;
					this.instagramService.post(data, url);
					break;
				case "Instadm": case "instadm":
					url = 'reply';
					url = (type) ? url + type : url;
					this.instadmService.post(data, url);
					break;
				default:
					break;
			}
		}
	}

	getAllLiveItems() {
		this.getLiveMessages();
		if (this.userService.permissionMatch(['Manage Whatsapp'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
		}
		if (this.userService.permissionMatch(['Manage Instadm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
		}
		if (this.userService.permissionMatch(['Manage Messenger'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
		}
		if (this.userService.permissionMatch(['Manage Twitterdm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/tweetDmList');
		}
		if (this.userService.permissionMatch(['Manage Instagram'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
		}
		this.getAssignedTickets();
	}

	getAssignedTickets() {
		let viewScreen = null;
		this.viewScreen.subscribe(data => viewScreen = data);
		if (this.userService.permissionMatch(['manage twitter'])) {
			this.tweetTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage twitterdm'])) {
			this.tweetdmTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage whatsapp'])) {
			this.whatsappTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage webchat'])) {
			this.webchatTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage youtube'])) {
			this.youtubeTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage instagram'])) {
			this.instagramTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage instadm'])) {
			this.instadmTicketService.getAllLiveItems();
		}
		if (this.userService.permissionMatch(['manage messenger'])) {
			this.messengerTicketService.getAllLiveItems();
		}
	}

	getLiveMessages() {
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		let viewScreen = null;
		this.viewScreen.subscribe(data => viewScreen = data);
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					if (this.userService.permissionMatch(['manage twitter'])) {
						this.tweetService.getAllLiveItems();
					}
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					if (this.userService.permissionMatch(['manage twitterdm'])) {
						this.tweetdmService.getAllLiveItems();
					}
					break;
				case "Messenger": case "messenger":
					if (this.userService.permissionMatch(['manage messenger'])) {
						this.messengerService.getAllLiveItems();
					}
					break;
				case "Whatsapp": case "whatsapp":
					if (this.userService.permissionMatch(['manage whatsapp'])) {
						this.whatsappService.getAllLiveItems();
					}
					break;
				case "Webchat": case "webchat":
					if (this.userService.permissionMatch(['manage webchat'])) {
						this.webchatService.getAllLiveItems();
					}
					break;
				case "Youtube": case "youtube":
					if (this.userService.permissionMatch(['manage youtube'])) {
						this.youtubeService.getAllLiveItems();
					}
					break;
				case "Instagram": case "instagram":
					if (this.userService.permissionMatch(['manage instagram'])) {
						this.instagramService.getAllLiveItems();
					}
					break;
				case "Instadm": case "instadm":
					if (this.userService.permissionMatch(['manage instadm'])) {
						this.instadmService.getAllLiveItems();
					}
					break;
				default:
					break;
			}
		}
	}

	getClosedTickets() {
		this.getAssignedTickets();
		if (this.userService.permissionMatch(['Manage Whatsapp'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
		}
		if (this.userService.permissionMatch(['Manage Instadm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
		}
		if (this.userService.permissionMatch(['Manage Messenger'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
		}
		if (this.userService.permissionMatch(['Manage Twitterdm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/tweetDmList');
		}
		if (this.userService.permissionMatch(['Manage Instagram'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
		}
	}

	setUnreadCount(count = null) {
		let ticket: any = null;
		let siteName = null;
		this.ticket.subscribe(data => ticket = data);
		this.siteName.subscribe(data => siteName = data);
		if (ticket && ticket.id) {
			ticket.unReadCount = count;
			ticket.statusName = 'Taken';
			this.ticketChangeAllItemsByItemEvent(ticket, siteName);
		}
	}

	getAllTicketsEvent(siteName = null) {
		if (!siteName) {
			this.siteName.subscribe(data => siteName = data);
		}
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetTicketService.getAllItems();
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmTicketService.getAllItems();
					break;
				case "Messenger": case "messenger":
					this.messengerTicketService.getAllItems();
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappTicketService.getAllItems();
					break;
				// case "Webchat": case "webchat":
					// this.webchatTicketService.getAllItems();
				// 	break;
				// case "Youtube": case "youtube":
				// 	this.youtubeTicketService.getAllItems();
				// 	break;
				case "Instagram": case "instagram":
					this.instagramTicketService.getAllItems();
					break;
				case "Instadm": case "instadm":
					this.instadmTicketService.getAllItems();
					break;
				default:
					break;
			}
		}
	}

	ticketChangeProcess(ticket, siteName = null, event = "change", data = null) {
		if (!siteName) {
			this.siteName.subscribe(data => siteName = data);
		}
		if (ticket && ticket.id && siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					if (event === 'change') { this.tweetTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.tweetTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.tweetTicketService.update(ticket.id, data); }
					if (event === 'close') {
						data.tweet_ticket_id = ticket.id;
						this.tweetTicketService.close(ticket.id, data, 'tickets/close');
						// this.tweetTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					if (event === 'change') { this.tweetdmTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.tweetdmTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.tweetdmTicketService.update(ticket.id, data); }
					if (event === 'close') {
						data.tweetdm_ticket_id = ticket.id;
						this.tweetdmTicketService.close(ticket.id, data, 'tickets/close');
						this.tweetdmTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Messenger": case "messenger":
					if (event === 'change') { this.messengerTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.messengerTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.messengerTicketService.update("tickets/assign", data); }
					if (event === 'close') {
						data.messenger_ticket_id = ticket.id;
						this.messengerTicketService.close(ticket.id, data, 'tickets/close');
						this.messengerTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Whatsapp": case "whatsapp":
					if (event === 'change') { this.whatsappTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.whatsappTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.whatsappTicketService.update("tickets/assign", data); }
					if (event === 'close') {
						data.whatsapp_ticket_id = ticket.id;
						this.whatsappTicketService.close(ticket.id, data, 'tickets/close');
						// this.whatsappTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Webchat": case "webchat":
					if (event === 'change') { this.webchatTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.webchatTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.webchatTicketService.update(ticket.id, data); }
					if (event === 'close') {
						data.webchat_ticket_id = ticket.id;
						this.webchatTicketService.close(ticket.id, data, 'tickets/close');
						this.webchatTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Youtube": case "youtube":
					if (event === 'change') { this.youtubeTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.youtubeTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.youtubeTicketService.update(ticket.id, data); }
					if (event === 'close') {
						data.youtube_ticket_id = ticket.id;
						this.youtubeTicketService.close(ticket.id, data, 'tickets/close');
						this.youtubeTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Instagram": case "instagram":
					if (event === 'change') { this.instagramTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.instagramTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.instagramTicketService.update("tickets/assign", data); }
					if (event === 'close') {
						data.instagram_ticket_id = ticket.id;
						this.instagramTicketService.close(ticket.id, data, 'tickets/close');
						this.instagramTicketService.removelibraryById(ticket.id);
					}
					break;
				case "Instadm": case "instadm":
					if (event === 'change') { this.instadmTicketService.changeItem(ticket); }
					if (event === 'removeLibrary') { this.instadmTicketService.removelibraryById(ticket.id); }
					if (event === 'update') { this.instadmTicketService.update("tickets/assign", data); }
					if (event === 'close') {
						data.instadm_ticket_id = ticket.id;
						this.instadmTicketService.close(ticket.id, data, 'tickets/close');
						this.instadmTicketService.removelibraryById(ticket.id);
					}
				break;
				default:
					break;
			}
		}
		// if (ticket && ticket.id && event === 'close') {
		// 	this.changeTicket(null);
		// 	this.changeCloseTicketEventCheckDuration();
		// }
	}

	ticketChangeAllItemsByItemEvent(ticket, siteName = null) {
		if (!siteName) {
			this.siteName.subscribe(data => siteName = data);
		}
		if (ticket && ticket.id && siteName) {
			this.replaceTicketLibrary(ticket);
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetTicketService.changeAllItemsByItem(ticket);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmTicketService.changeAllItemsByItem(ticket);
					break;
				case "Messenger": case "messenger":
					this.messengerTicketService.changeAllItemsByItem(ticket);
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappTicketService.changeAllItemsByItem(ticket);
					break;
				case "Webchat": case "webchat":
					this.webchatTicketService.changeAllItemsByItem(ticket);
					break;
				case "Youtube": case "youtube":
					this.youtubeTicketService.changeAllItemsByItem(ticket);
					break;
				case "Instagram": case "instagram":
					this.instagramTicketService.changeAllItemsByItem(ticket);
					break;
				case "Instadm": case "instadm":
					this.instadmTicketService.changeAllItemsByItem(ticket);
					break;
				default:
					break;
			}
		}
	}

	ticketRemoveEvent(ticket, siteName = null, doChange = false) {
		let selectedTicket: any = null;
		this.ticket.subscribe(data => selectedTicket = data);
		if (!siteName) {
			this.siteName.subscribe(data => siteName = data);
		}
		if (ticket && ticket.id && siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetTicketService.spliceItem(ticket.id);
					if (doChange) { this.tweetTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmTicketService.spliceItem(ticket.id);
					if (doChange) { this.tweetdmTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Messenger": case "messenger":
					this.messengerTicketService.spliceItem(ticket.id);
					if (doChange) { this.messengerTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappTicketService.spliceItem(ticket.id);
					if (doChange) { this.whatsappTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Webchat": case "webchat":
					this.webchatTicketService.spliceItem(ticket.id);
					if (doChange) { this.webchatTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Youtube": case "youtube":
					this.youtubeTicketService.spliceItem(ticket.id);
					if (doChange) { this.youtubeTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Instagram": case "instagram":
					this.instagramTicketService.spliceItem(ticket.id);
					if (doChange) { this.instagramTicketService.changeAllItemsByItem(ticket); }
					break;
				case "Instadm": case "instadm":
					this.instadmTicketService.spliceItem(ticket.id);
					if (doChange) { this.instadmTicketService.changeAllItemsByItem(ticket); }
					break;
				default:
					break;
			}
			if (selectedTicket && ticket && selectedTicket.id === ticket.id) {
				this.changeTicket(null);
			}
		}
	}

	removeTicket(ticketId, ticketType) {
		if (ticketType) {
			this.removeTicketLibraryById(ticketId, ticketType);
			switch (ticketType) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetTicketService.removelibraryById(ticketId);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmTicketService.removelibraryById(ticketId);
					break;
				case "Messenger": case "messenger":
					this.messengerTicketService.removelibraryById(ticketId);
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappTicketService.removelibraryById(ticketId);
					break;
				case "Webchat": case "webchat":
					this.webchatTicketService.removelibraryById(ticketId);
					break;
				case "Youtube": case "youtube":
					this.youtubeTicketService.removelibraryById(ticketId);
					break;
				case "Instagram": case "instagram":
					this.instagramTicketService.removelibraryById(ticketId);
					break;
				case "Instadm": case "instadm":
					this.instadmTicketService.removelibraryById(ticketId);
					break;
				default:
					break;
			}
		}
		let siteName = null;
		this.siteName.subscribe(data => siteName = data);
		let ticket: any;
		this.ticket.subscribe(data => ticket = data);
		if (ticket && ticket.id === ticketId && siteName && siteName === ticketType) {
			this.changeTicket(null);
			this.changeSiteName(null);
		}
	}

	getTicket(ticketId, siteName) {
		if (ticketId && siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetTicketService.getItem({ 'id': ticketId });
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					this.tweetdmTicketService.getItem({ 'id': ticketId });
					break;
				case "Messenger": case "messenger":
					this.messengerTicketService.getItem({ 'id': ticketId });
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappTicketService.getItem({ 'id': ticketId });
					break;
				case "Webchat": case "webchat":
					this.webchatTicketService.getItem({ 'id': ticketId });
					break;
				case "Youtube": case "youtube":
					this.youtubeTicketService.getItem({ 'id': ticketId });
					break;
				case "Instagram": case "instagram":
					this.instagramTicketService.getItem({ 'id': ticketId });
					break;
				case "Instadm": case "instadm":
					this.instadmTicketService.getItem({ 'id': ticketId });
					break;
				default:
					break;
			}
		}
	}

	subscribeTicketCloseEvents() {
		this.tweetTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.tweetdmTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.messengerTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.whatsappTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.webchatTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.youtubeTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.youtubeTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.instagramTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
		this.instadmTicketService.onCloseEvent.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicketEvent.emit(false); }
		});
	}

	subscribeCloseTickets(){
		this.tweetTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.tweetTicketService.isCloseTicketSource.next(false); }
		});
		this.tweetdmTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.tweetdmTicketService.isCloseTicketSource.next(false); }
		});
		this.messengerTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.messengerTicketService.isCloseTicketSource.next(false); }
		});
		this.whatsappTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.whatsappTicketService.isCloseTicketSource.next(false); }
		});
		this.webchatTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.webchatTicketService.isCloseTicketSource.next(false); }
		});
		this.youtubeTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.youtubeTicketService.isCloseTicketSource.next(false); }
		});
		this.instagramTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.instagramTicketService.isCloseTicketSource.next(false); }
		});
		this.instadmTicketService.isCloseTicket.pipe(untilDestroyed(this)).subscribe(data => {
			if(data && data === true) { this.closeTicket(); this.instadmTicketService.isCloseTicketSource.next(false); }
		});
	}

	closeTicket(){
		this.changeCloseTicketEvent(true);
		this.changeTicket(null);
		this.changeCloseTicketEventCheckDuration();
	}

	newMessageEvent(data: any, siteName = null) {
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.replacelibrary(data);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweet": case "tweetdm":
					this.tweetdmService.replacelibrary(data);
					break;
				case "Messenger": case "messenger":
					this.messengerService.replacelibrary(data, siteName);
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.replacelibrary(data, siteName);
					break;
				case "Webchat": case "webchat":
					this.webchatService.replacelibrary(data);
					break;
				case "Youtube": case "youtube":
					this.youtubeService.replacelibrary(data);
					break;
				case "Instagram": case "instagram":
					this.instagramService.replacelibrary(data, siteName);
					break;
				case "Instadm": case "instadm":
					this.instadmService.replacelibrary(data, siteName);
					break;
				default:
					break;
			}
		}
	}

	replyEvent(data: any, siteName = null) {
		if (siteName) {
			switch (siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
					this.tweetService.replacelibrary(data);
					break;
				case "Twitterdm": case "twitterdm":
				case "Tweet": case "tweetdm":
					this.tweetdmService.replacelibrary(data);
					break;
				case "Messenger": case "messenger":
					this.messengerService.replaceReplyLibrary(data);
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.replaceReplyLibrary(data);
					break;
				case "Webchat": case "webchat":
					this.webchatService.replacelibrary(data);
					break;
				case "Youtube": case "youtube":
					this.youtubeService.replacelibrary(data);
					break;
				case "Instagram": case "instagram":
					this.instagramService.replaceReplyLibrary(data);
					break;
				case "Instadm": case "instadm":
					this.instadmService.replaceReplyLibrary(data);
					break;
				default:
					break;
			}
		}
	}

	unSubscribeAll() {
		this.whatsappTicketService.unSubscribeFilter();
		this.webchatTicketService.unSubscribeFilter();
		this.youtubeTicketService.unSubscribeFilter();
		this.instagramTicketService.unSubscribeFilter();
		this.instadmTicketService.unSubscribeFilter();
		this.tweetTicketService.unSubscribeFilter();
		this.tweetdmTicketService.unSubscribeFilter();
		this.messengerTicketService.unSubscribeFilter();
		this.commentService.unSubscribeFilter();
	}

	reDownload(siteName: any, download) {
		let url: string = 'reply/reDownloadn';
		if (siteName) {
			switch (siteName) {
				// case "Twitter": case "twitter":
				// case "Tweet": case "tweet":
				// 	this.tweetService.post(download, url);
				// 	break;
				// case "Twitterdm": case "twitterdm":
				// case "Tweetdm": case "tweetdm":
				// 	this.tweetdmService.post(download, url);
				// 	break;
				case "Messenger": case "messenger":
					this.messengerService.reDownloads(download, url);
					break;
				case "Whatsapp": case "whatsapp":
					this.whatsappService.reDownloads(download, url);
					break;
				// case "Webchat": case "webchat":
				// 	this.webchatService.post(download, url);
				// 	break;
				// case "Youtube": case "youtube":
				// 	this.youtubeService.post(download, url);
				// 	break;
				case "Instagram": case "instagram":
					this.instagramService.reDownloads(download, url);
					break;
				case "Instadm": case "instadm":
					this.instadmService.reDownloads(download, url);
					break;
				default:
					break;
			}
		}
	}
}
