export class SiteModel {
	id: number;
	name: string;
	slug: string;
	icon: string;
	color: string;
	is_active: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	loginUrl?: string;

	tableName: string;
	available_providers?: any;
	available_servers?: Array<any>;
	installedCount?: number;
	permittedCount?: number;

	/**
	 * Constructor
	 *
	 * @param site
	 */
	constructor(site) {
		this.id = site.id || '';
		this.name = site.name || '';
		this.slug = site.slug || '';
		this.is_active = site.is_active || '';
		this.created_by = site.created_by || '';
		this.updated_by = site.updated_by || '';
		this.created_at = site.created_at || '';
		this.updated_at = site.updated_at || '';

		this.tableName = site.tableName || '';
		this.installedCount = site.installedCount || 0;
		this.permittedCount = site.permittedCount || 0;
	}
}
