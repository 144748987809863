export class FlagReportModel {
	account_name: string;
	site_name: string;
	client_id: number;
	client_site_id: number;
	
	flagReports: Array<any>;

	/**
	 * Constructor
	 * @param flagReport
	 */
	constructor(flagReport) {
		this.account_name = flagReport.account_name || null;
		this.site_name = flagReport.site_name || null;
		this.client_id = flagReport.client_id || null;
		this.client_site_id = flagReport.client_site_id || null;

		this.flagReports = flagReport.flagReports || [];
	}
}
