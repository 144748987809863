import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { ConversationHistoryComponent } from './../conversationHistory/conversationHistory.component';

import { EventService,
	User, UserService,
	TweetdmService, Tweetdm, PusherService,
	TweetdmListService, TweetdmList
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'closed-conversation-twitterdm',
	templateUrl: './twitterdm.component.html',
	styleUrls: ['./twitterdm.component.scss'],
	providers: [OrderByPipe]
})

export class TweetdmComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	messages: Tweetdm[] = [];
	listParam: any;
	param: any;
	dataSource = new MatTableDataSource(this.messages);
	displayedColumns = ['media', 'date', 'avatar', 'user', 'mediaurl', 'contact', 'status', 'action'];
	dataLength: number = 0;
	channel: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	eventData: any;

	constructor(public userService: UserService,
		public eventService: EventService,
		public tweetdmService: TweetdmService,
		public tweetdmListService: TweetdmListService,
		public pusherService: PusherService,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
		});
		this.tweetdmListService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.messages = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.messages);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
		// this.subscribePusher();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.tweetdmListService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.tweetdmListService.params.pipe(untilDestroyed(this)).subscribe(data => this.listParam = data);
		this.tweetdmListService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.tweetdmService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
	}


	/** Add / Update Tag */
	showConversationHistory(message): void {
		this.tweetdmService.changeItem((message) ? message : null);
		let dialogRef = this.matDialog.open(ConversationHistoryComponent, {
			width: "600px",
			data: {
				action: 'show',
				item: message,
				siteName: (message && message.siteName) ? message.siteName : null,
				siteId: (message && message.siteId) ? message.siteId : null,
				ticketClassName: (message && message.id) ? message.className : null,
			}
		});
		dialogRef.afterClosed().subscribe(response => { });
	}

	subscribePusher() {
		this.channel.bind('newmessage', (data) => {
			this.tweetdmService.getAllItems();
		});
	}

	goToUrl(message) {
		let url = 'http://google.com';
		if (message && message.id) {
			switch (message.siteName) {
				case "Twitter": case "twitter":
				case "Tweet": case "tweet":
				case "Twitterdm": case "twitterdm":
				case "Tweetdm": case "tweetdm":
					url = 'https://twitter.com/' + message.resource.source + '/status/' + message.tweet_code;
					break;
			}
		}
		window.open(url, "_blank");
	}

	onSearch(event) {
		this.param.is_itrs = null;
		this.tweetdmListService.onSearch(event);
	}

}
