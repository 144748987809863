export class AgentUsageModel {
	account_name: string;
	site_id: number;
	site_name: string;
	clientSiteColor: string;
	clientSiteTextColor: string;
	client_id: number;
	client_site_id: number;
	totalTickets: number;
	totalMessages: number;
	sendMessages: number;
	receivedMessages: number;

	/**
	 * Constructor
	 * @param agentUsage
	 */
	constructor(agentUsage) {
		this.account_name = agentUsage.account_name || null;
		this.site_id = agentUsage.site_id || null;
		this.site_name = agentUsage.site_name || null;
		this.clientSiteColor = agentUsage.clientSiteColor || null;
		this.clientSiteTextColor = agentUsage.clientSiteTextColor || null;
		this.client_id = agentUsage.client_id || null;
		this.client_site_id = agentUsage.client_site_id || null;
		this.totalTickets = agentUsage.totalTickets || null;
		this.totalMessages = agentUsage.totalMessages || null;
		this.sendMessages = agentUsage.sendMessages || null;
		this.receivedMessages = agentUsage.receivedMessages || null;
	}
}
