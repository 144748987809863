import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { TagFormComponent } from './../tagForm/tagForm.component';
import { BoardFormComponent } from './../boardForm/boardForm.component';

import {
	User, UserService,
	ClientSiteService, ClientSite,
	TagService, Tag, TagModel,
	BoardService, Board, BoardModel,
	TagBoardService, TagBoard, TagBoardModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-auto-assign',
	templateUrl: './autoAssign.component.html',
	styleUrls: ['./autoAssign.component.scss']
})

export class AutoAssignComponent implements OnInit, OnDestroy {
	authUser: User;
	clientSite: ClientSite;

	tags: Tag[] = [];
	selectedTag: Tag;
	tagParam: any;
	tagSource = new MatTableDataSource(this.tags);
	tagColumns = ['name', 'action'];

	boards: Board[] = [];
	selectedBoard: Board;
	boardParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public tagBoardService: TagBoardService,
		public boardService: BoardService,
		public tagService: TagService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);

		this.tagService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.tags = data;
			this.tagSource = new MatTableDataSource(this.tags);
		});
		this.tagService.params.pipe(untilDestroyed(this)).subscribe(data => this.tagParam = data);
		this.boardService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.boards = data);
		this.boardService.params.pipe(untilDestroyed(this)).subscribe(data => this.boardParam = data);
	}

	ngOnInit() {
		this.getTags();
		this.getBoards();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getTags() {
		this.tagParam.client_site_id = this.clientSite.id;
		this.tagParam.client_id = this.clientSite.client_id;
		this.tagParam.board_id = (this.selectedBoard && this.selectedBoard.id) ? this.selectedBoard.id : null;
		// this.tagService.getAllItems();
	}

	getBoards() {
		this.boardParam.client_site_id = this.clientSite.id;
		this.boardParam.client_id = this.clientSite.client_id;
		// this.boardService.getAllItems();
	}

	onSelectBoard(board: Board) {
		this.selectedBoard = board;
		this.boardService.resetParams();
		// this.getTags();
	}

	showAllTag() {
		this.selectedBoard = null;
		this.boardService.resetParams();
		// this.getTags();
	}

	/** Add Tag Tag */
	newTagBoard(tag: Tag = null): void {
		let dialogRef = this.matDialog.open(TagFormComponent, {
			width: "600px",
			data: {
				action: (tag && tag.id) ? 'link' : 'new',
				item: null,
				tag: (tag && tag.id) ? tag : null,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.tagBoardService.store(formData.value);
						setTimeout(() => {
							this.getTags();
							this.getBoards();
						}, 500);
						break;
				}
			});
	}

	unLink(tag: Tag) {
		if (this.selectedBoard && this.selectedBoard.id) {
			let input: any = {
				tag_id: tag.id,
				board_id: this.selectedBoard.id,
				client_id: this.clientSite.client_id,
				client_site_id: this.clientSite.id,
			};
			this.tagBoardService.removeByPost(input, 'tag-board-destroy');
			setTimeout(() => {
				this.tagService.spliceItem(tag.id);
			}, 300)
		}
	}

	newBoard(board: Board = null) {
		this.boardService.changeItem(board);
		let dialogRef = this.matDialog.open(BoardFormComponent, {
			width: "600px",
			data: {
				action: (board && board.id) ? 'edit' : 'new',
				item: board,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.boardService.store(formData.value);
						break;
					case 'update':
						this.boardService.update(board.id, formData.value);
						break;
				}
			});
	}

}