export class TagBoardOneModel {
    id: number;
    tag_id: number;
    board_id: number;
    client_id: number;
    client_site_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tagName: string;
    boardName: string;
    tagsCount: number;
    
    tableName: string;

    /**
     * Constructor
     *
     * @param tag_board
     */
    constructor(tag_board) {
            this.id = tag_board.id || '';
            this.tag_id = tag_board.tag_id || '';
            this.board_id = tag_board.board_id || '';
            this.client_id = tag_board.client_id || '';
            this.client_site_id = tag_board.client_site_id || '';
            this.created_by = tag_board.created_by || '';
            this.updated_by = tag_board.updated_by || '';
            this.created_at = tag_board.created_at || '';
            this.updated_at = tag_board.updated_at || '';

            this.tableName = tag_board.tableName || '';
    }
}
