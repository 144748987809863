import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	LabelService, Label, LabelModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'label-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'New Label';
	authUser: User;
	label: Label;
	color: string = '#aaa';
	color_text: string = '#000';
	isDefault: boolean = false;
	action: string;
	labelForm: UntypedFormGroup;
	priorityList = JSON.parse(localStorage.getItem('tji_user'));

	constructor(public userService: UserService,
		public labelService: LabelService,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {

		this.getInit(_data);
		this.labelService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.label = data;
			this.labelForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Label';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Label';
				this.color = data.item.color;
				this.color_text = data.item.color_text;
				this.isDefault = data.item.is_default;
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.label.name || null, [Validators.required, Validators.minLength(3)]],
			color: [this.label.color || this.color, [Validators.required]],
			color_text: [this.label.color_text || this.color_text, [Validators.required]],
			is_default: [this.label.is_default || this.isDefault, [Validators.required]],
			priorities: [parseInt(this.label.priority) || null],
		});
	}

	getLableName(): string {
		if(this.labelForm && this.labelForm.value.name !== null) {
			return this.labelForm.value.name;
		} else {
			return 'Sample Text';
		}
	}

	onChangeColor($color) {
		this.labelForm.value.color = $color;
	}

	onChangeColorText($color) {
		this.labelForm.value.color_text = $color;
	}

	onChangeIsDefault($event: boolean) {
		this.labelForm.value.is_default = $event;
		this.isDefault = $event;
	}

	getDefault():boolean {
		return this.labelForm.value.is_default;
	}

	checkDefault(option):boolean {
		if(option && option.value === this.isDefault) {
			return true;
		} else {
			return false
		}
	}

}
