export class RSettingFieldModel {
	id: number;
	title: string;
	slug: string;
	field_type: string;
	category: string;
	is_editable: boolean;
	is_client_editable: boolean;
	is_agent_editable: boolean;
	json: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	jsonArray?: Array<any>;

	/**
	 * Constructor
	 *
	 * @param settingField
	 */
	constructor(settingField) {
		this.id = settingField.id || '';
		this.title = settingField.title || '';
		this.slug = settingField.slug || '';
		this.field_type = settingField.field_type || '';
		this.category = settingField.category || '';
		this.is_editable = settingField.is_editable || '';
		this.is_client_editable = settingField.is_client_editable || '';
		this.is_agent_editable = settingField.is_agent_editable || '';
		this.json = settingField.json || '';
		this.created_by = settingField.created_by || '';
		this.updated_by = settingField.updated_by || '';
		this.created_at = settingField.created_at || '';
		this.updated_at = settingField.updated_at || '';
		this.jsonArray = settingField.jsonArray || [];
	}
}
