import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  CatalogService, CatalogModel, Catalog, UserService, Business, BusinessService,
  AlertService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'sidebar-catalog',
  templateUrl: './sidebar-catalog.component.html',
  styleUrls: ['./sidebar-catalog.component.scss']
})
export class SidebarCatalogComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  catalogForm: UntypedFormGroup;
  catalogTitle: string = 'Create';
  catalog: Catalog;
  businessList: Business[] = [];

  constructor(public catalogService: CatalogService,
    public matDialog: MatDialog,
    public userService: UserService,
    public businessService: BusinessService,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder) {
    this.catalogService.item.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.catalog = data;
        this.catalogForm = this.createForm();
        if (data && data._id) {
          this.catalogTitle = 'Edit Catalog';
          this.catalogForm.get('business_id').disable();
        } else {
          this.catalogTitle = 'New Catalog';
        }
      }
    });

    this.catalogService.addItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == true) {
        this.resetForm()
      }
    });

    this.businessService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.businessList = data;
      }
    });

  }

  ngOnInit(): void {
    // this.catalogForm.reset();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.catalogService.itemSource.next(null);
  }

  createForm(): UntypedFormGroup {
    return this._formBuilder.group({
      business_id: [this.catalog.business_id || null, [Validators.required]],
      name: [this.catalog.name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
    });
  }

  submit() {
    if (this.catalogForm.invalid) {
      return
    }

    if (this.catalog._id) {
      var update = {
        "catalog_id": this.catalog.id.toString(),
        "name": this.catalogForm.value.name,
      }
      this.catalogService.update('fbCatalog/Update', update, this.catalog);
      this.onClose.emit();
    } else {
      var data = {
        "business_id": this.catalogForm.value.business_id.toString(),
        "name": this.catalogForm.value.name,
      }
      this.catalogService.store('fbCatalog/add', data);
      this.onClose.emit();
    }
    this.catalogService.changeItem(null)
  }

  resetForm() {
    this.catalogForm.reset();
    this.catalogForm.get('business_id').enable();
  }

  onClientSiteChange(value) {
    if (value) {
      this.businessList.forEach(element => {
        if (element.business_id == value && element['service_provider'] == 'option-7') {
          this.alertService.webShow('Danger', 'The catalog should be added while configuring the number on the account.');
          this.resetForm();
        }
      });
    }
  }
}
