import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Log } from './log.interface';
import { LogModel } from './log_model.model';
import { CsvModule } from '@ctrl/ngx-csv';
import { AlertService } from '../alert/alert/alert.service';
import moment from 'moment';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class LogService implements Resolve<any> {
	url: string = 'log/newloglist';
	logExportUrl: string = 'log/logexport';
	downloadExcel: any;
	routeParams: any;
	downLoadLoader: boolean = false;
	defaultParams: any = {
		"limit": 25,
		"current": 1,
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<Log[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<Log>(new LogModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Log[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private alertService: AlertService,
		private commonService: CommonService) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed LogService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on LogService');
	}

	changeAllItems(allItems: Log[]) {
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: Log) {
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Log[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Log) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	// paramsInit
	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	// resetParams
	resetParams() {
		const defaultParams: any = {
			"limit": 25,
			"current": 1,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	concatItem(item: Log) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
			}
		}
		this.changeAllItems(allItems);
	}

	// destroy
	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
			},
				error => console.log('Error ::' + error)
			);
	}

	// pageEvent
	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				if (this.defaultParams && this.defaultParams.user_id) {
					// newParams['user_id'] = this.defaultParams?this.defaultParams.user_id: '';
					this.changeParams(newParams);
					this.filterItem(this.defaultParams.user_id, newParams);
				} else {
					this.changeParams(newParams);
					this.getAllItems(newParams);
				}
			});
	}

	// getAllItems
	getAllItems(params: any = null) {
		if (params === null || (params && params.length === 0)) {
			params = this.defaultParams;
		}
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.changeAllItems(data.data);
					this.changeTotalItem(data.pagnitation.totalResult);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	// filter by agent
	filterItem(agent: any = null, params: any = null) {
		if (params === null || (params && params.length === 0)) {
			params = this.defaultParams;
		}
		params = this.paramsInit(params);
		if (agent) {
			params['user_id'] = agent;
		} else {
			delete params['user_id'];
			delete this.defaultParams.user_id;
		}

		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						this.changeAllItems(data.data);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems(data.data);
						this.changeTotalItem(0);
					}
				} else {
					var datas = []
					this.changeAllItems(datas);
					this.changeTotalItem(0);
					this.alertService.isWebNotify = true;
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	// filter by agent
	exportCsv(download, params: any = null) {
		this.downLoadLoader = true;
		if (params === null || (params && params.length === 0)) {
			params = this.defaultParams;
		}
		params = this.paramsInit(params);
		params["limit"] = 1000,
			this.commonService.storeItem(this.url, params, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success) {
						this.downloadExcel = data.data;
						this.downloadFile(this.downloadExcel);
					}
				},
					error => console.log('Error ::' + error)
				);
	}

	// getSortName
	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.activities.data);
					if (data.data.last_page <= data.data.activities.current_page) {
						params.page = data.data.activities.last_page;
						this.changeParams(params);
					}
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	// downloadFile
	downloadFile(data) {
		var excelData = [];
		data.forEach(element => {
			if (element._id) {
				data = {
					ID: element._id,
					Time: moment(element.created_at * 1000).format("DD-MMM-yyy-hh:mm a"),
					'User Name': element?.userInfo?.username,
					Description: element?.activity,
					"Ip Address": element?.deviceInfo?.ipAddress,
					"Device Name": element?.deviceInfo?.client?.name
				}
				excelData.push(data);
			}
		});
		let arrHeader = ["ID", "Time", "User Name", "Description","Ip Address","Device Name"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		this.downLoadLoader = false;
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'log-' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["ID", "Time", "User Name", "Description","Ip Address","Device Name"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			let newData = data.replace(/,/g, " ");
			return newData;
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}
}