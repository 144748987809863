import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { DeliverySettings, DeliverySettingsService, User, UserService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
  selector: 'delivery-settings',
  templateUrl: './delivery-settings.component.html',
  styleUrls: ['./delivery-settings.component.scss'],
  providers: [OrderByPipe]
})
export class DeliverySettingsComponent implements OnInit {
  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  deliverySettings: DeliverySettings[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  param: any;
  dataSource = new MatTableDataSource(this.deliverySettings);
  displayedColumns = ['name', 'amount', 'status'];
  authUser: User;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;

  constructor(public deliverySettingsService: DeliverySettingsService, public userService: UserService,
    private orderByPipe: OrderByPipe) {
    this.deliverySettingsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {

      // this.deliverySettings = this.orderByPipe.transform(data, '-id').filter(x => {
      //   return (x.governorate_id) ? true : false;
      // });
      this.deliverySettings = this.orderByPipe.transform(data, '-id');
      this.dataSource = new MatTableDataSource(this.deliverySettings);
      this.dataSource.sort = this.sort;
    });

    this.deliverySettingsService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.userService.user.subscribe(data => this.authUser = data);

  }

  ngOnInit(): void {
    this.dataInit();
  }
  ngOnDestroy(): void {
    this.deliverySettingsService.isLoader.next(true);
    this.deliverySettingsService.resetParams();
    this.deliverySettingsService.unSubscribe();
    this.deliverySettingsService.unSubscribeFilter();
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }
  dataInit() {
    this.deliverySettingsService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  toggleRightSidebar() {
    this.rightSideBar.toggle();
  }
  saveSetting(data, price) {
    var setting = {
      "governorate_id": data._id,
      "is_delivarable": data.is_delivarable,
      "price": price
    }
    this.deliverySettingsService.update('deliverySettings/add', setting)
  }


  deliveryStatus(data, event) {
    if (event.checked) {
      var setting = {
        "governorate_id": data._id,
        "is_delivarable": true,
        "price": data.price?data.price: '0',
      }
      this.deliverySettingsService.update('deliverySettings/add', setting);
    } else {
       var setting = {
        "governorate_id": data._id,
        "is_delivarable": false,
        "price": data.price?data.price: '0',
      }
      this.deliverySettingsService.update('deliverySettings/add', setting);
    }
  }

}