import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { FormComponent } from './../form/form.component';
import { AssignAgentComponent } from './../assignAgent/assignAgent.component';
import { AssignClientComponent } from './../assignClient/assignClient.component';

import {
	User, UserService,
	RRoleService, RRole, RRoleModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'role-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	roles: RRole[] = [];
	param: any;
	roleResponse: any;
	dataSource = new MatTableDataSource(this.roles);
	displayedColumns = ['avatar', 'name', 'applied', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public rroleService: RRoleService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.rroleService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.roles) { this.subLoader = false; }
			this.roles = data;
			this.dataSource = new MatTableDataSource(this.roles);
			this.dataSource.sort = this.sort;
		});
		if (this.userService.roleMatch(['Super Admin', 'Reseller'])) {
			this.displayedColumns = ['avatar', 'name', 'applied_clients', 'action'];
		}
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.rroleService.params.subscribe(data => this.param = data);
		this.rroleService.totalItem.subscribe(data => this.dataLength = data);
	}

	/** Add / Update Role */
	newRole(role = null): void {
		this.rroleService.changeItem((role) ? role : new RRoleModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (role) ? 'edit' : 'new',
				item: role,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.rroleService.store("reseller/roles/add", formData.value);
						break;
					case 'update':
						formData.value["id"] = id;
						this.rroleService.update('reseller/roles/update', formData.value);
						break;
					case 'delete':
						this.deleteRole(role);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteRole(role: RRole): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var deleteData = {
					id: role.id,
				}
				this.rroleService.destroy('reseller/roles/delete', deleteData);
			}
		});
	}

	roleMapAgent(role: RRole) {
		let dialogRef = this.matDialog.open(AssignAgentComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: role,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const formData: UntypedFormGroup = response[1];
				
				if (formData.value.checked) {
					this.rroleService.mapAgent('reseller/roles/addUser', formData.value);
				}else{
					this.rroleService.removeAgent('reseller/roles/removeUser', formData.value);
				}
				
			});
	}

	roleMapClient(role: RRole) {
		let dialogRef = this.matDialog.open(AssignClientComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: role,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const formData: UntypedFormGroup = response[1];
				if (formData.value.checked) {
					this.rroleService.mapAgent('reseller/roles/addUser', formData.value);
				}else{
					this.rroleService.removeAgent('reseller/roles/removeUser', formData.value);
				}
				// this.roleService.post('person/user-assign-role', formData.value);
			});
	}

}
