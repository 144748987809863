import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	Flag,
	FlagService,
	MessengerTicketService,
	User, UserService,
} from 'src/app/@tji/_dbShare';

import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'filter-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent {
	@Output() onClose = new EventEmitter();
	flags: Flag[] = [];
	param: any;
	selectedFlag: any;

	constructor(private _formBuilder: UntypedFormBuilder,
		private router: Router,
		public flagService: FlagService,
		public messengerTicketService: MessengerTicketService,
		public matDialog: MatDialog) {
		this.flagService.allFlag.pipe(untilDestroyed(this)).subscribe(data => this.flags = data);
		this.messengerTicketService.params.pipe(untilDestroyed(this)).subscribe(data => { this.param = data; });
	}

	ngOnInit() {

	}

	/** On destroy */
	ngOnDestroy(): void {
	}

	selectFlag(value: any) {
		this.messengerTicketService.isfilterLoader.next(true);
		this.messengerTicketService.changeAllItems([]);
		if (value == null) {
			this.param["flag_id"] = '';
			delete this.param["flag_id"];
			this.param.current = 1,
			this.param.limit = 25,
			this.selectedFlag = null;
			this.messengerTicketService.getAllItems();
		} else {
			this.selectedFlag = value;
			this.param["flag_id"] = value;
			this.param.current = 1,
				this.param.limit = 25,
				this.messengerTicketService.getAllItems();
		}

	}

	reset() {
		this.param["flag_id"] = '';
		delete this.param["flag_id"];
		this.param.current = 1,
		this.param.limit = 25,
		this.selectedFlag = null;
		this.messengerTicketService.getAllItems();
	}
}
