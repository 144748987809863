import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-profile-update-form',
  templateUrl: './whatsapp-profile-update-form.component.html',
  styleUrls: ['./whatsapp-profile-update-form.component.scss']
})
export class WhatsappProfileUpdateFormComponent implements OnInit {

  dialogTitle: string = 'Update';

  constructor() { }

  ngOnInit(): void {
  }

}
