import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { FormMenuComponent } from '../formmenu/formMenu.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import {
	User, UserService, MenuGroupService, MenuService, MenuModel, Menu, MenuGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'menugroup-menu-list',
	templateUrl: './menuList.component.html',
	styleUrls: ['./menuList.component.scss']
})

export class MenuListComponent implements OnInit, OnDestroy {

	loader: boolean = true;
	authUser: User;
	displayedColumns = ['title', 'type', 'translate', 'icon', 'route', 'order', 'hidden', 'active', 'action'];
	dataSource;
	menuGroup: MenuGroup;
	menus: Menu[] = [];
	param: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public menuService: MenuService,
		public menuGroupService: MenuGroupService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);

		this.menuGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.menuGroup = data;
			this.menus = data.menus;
			this.dataSource = new MatTableDataSource(this.menus);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.menuService.unSubscribe();
		// this.routeSub.unsubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		// this.menuService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		// this.menuService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.dataSource = data);
	}

	/** Add / Update Menu */
	newMenuGroup(menu = null): void {
		this.menuService.changeItem((menu) ? menu : new MenuModel({}));
		let dialogRef = this.matDialog.open(FormMenuComponent, {
			width: "600px",
			data: {
				action: (menu) ? 'edit' : 'new',
				item: menu,
				title: 'Menu',
				menu_group_id: this.route.snapshot.params.id,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.menuService.store(formData.value);
						break;
					case 'update':
						this.menuService.update(id, formData.value);
						break;
					case 'delete':
						this.deleteMenuGroup(menu);
						break;
				}
			});
	}

	/** Delete Social Site */
	deleteMenuGroup(menu: Menu): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.menuService.destroy(menu.id);
			}
		});
	}

}
