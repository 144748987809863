import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  User, UserService,
  ItrsbotService, Itrsbot,
  ConsumerGroupService, ConsumerGroup,
  IntractiveService, Intractive,
  IntractiveGroupService, IntractiveGroup
} from 'src/app/@tji/_dbShare';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER, TAB, SPACE } from '@angular/cdk/keycodes';

@UntilDestroy()
@Component({
  selector: 'app-ai-collection',
  templateUrl: './ai-collection.component.html',
  styleUrls: ['./ai-collection.component.scss']
})
export class AiCollectionComponent {
  dialogTitle: string = 'Create';
  authUser: User;
  itrsbot: Itrsbot;
  consumerGroups: ConsumerGroup[] = [];
  intractiveGroup: IntractiveGroup;
  action: string;
  chooseConsumerGroupForm: UntypedFormGroup;
  isValueType: boolean = true;
  paymentTag: any = [];
  selectable = true;
	removable = true;
	addOnBlur = true;
  readonly separatorKeysCodes = [TAB, COMMA] as const;

  types: Array<any> = [
    { title: 'Static Ai-collection', value: 'static' },
    { title: 'Dynamic Ai-collection', value: 'Dynamic' },
    { title: 'Subscription Ai-collection', value: 'subscription' },
  ];

  constructor(public userService: UserService,
    public itrsbotService: ItrsbotService,
    public intractiveService: IntractiveService,
    public intractiveGroupService: IntractiveGroupService,
    public matDialogRef: MatDialogRef<AiCollectionComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder) {
    this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
    this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.intractiveGroup = data;
      if (this.intractiveGroup && this.intractiveGroup.json && this.intractiveGroup.json['type'] && this.intractiveGroup.json['type'] == 'Dynamic') {
        this.isValueType = false;
      }
      this.getInit(_data);
      this.chooseConsumerGroupForm = this.createForm();
    });
  }


  ngOnInit() { }

  /** On destroy */
  ngOnDestroy(): void { }

  getInit(data) {
    // Set the defaults
    let intractive = data.item;
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Link With Ai-collection';
        break;
      case 'edit':
        this.dialogTitle = 'Change Ai-collcetion';
        break;
      default:
        break;
    }
  }

  /** Create form */
  createForm(): UntypedFormGroup {
    console.log('Create Form', this.intractiveGroup.json)
    var aiCollectionType: any;
    var aiCollectionValue: any;
    if (this.intractiveGroup && this.intractiveGroup.json && this.intractiveGroup.json['type']) {
      aiCollectionType = this.intractiveGroup.json['type'];
      aiCollectionValue = this.intractiveGroup.json['value'];
      if (this.intractiveGroup.json && this.intractiveGroup.json['tags'] && this.intractiveGroup.json['tags'].length > 0) {
        var tagsData = this.intractiveGroup.json['tags'].split(',');
        this.paymentTag = tagsData;
      }
    }else{
      aiCollectionType = null;
      aiCollectionValue = null;
      this.paymentTag = [];
    }
    return this._formBuilder.group({
      aiCollectionType: [aiCollectionType, [Validators.required, Validators.minLength(1)]],
      aiCollectionValue: [aiCollectionValue || null],
      update_on: 'json',
      is_active: [1],
    });
  }

  // aiCollectionType: [this.getJsonConsumerAction() || null, [Validators.required, Validators.minLength(1)]],
  //       aiCollectionValue: [this.intractiveGroup.consumer_group_id || null],

  getJsonConsumerAction(): string {
    let jsonConsumerAction = '';
    if (this.intractiveGroup && this.intractiveGroup.json) {
      let jsonData = JSON.parse(this.intractiveGroup.json);
      if (jsonData && jsonData.consumer_action) {
        jsonConsumerAction = jsonData.aiCollectionType;
      }
    }
    return jsonConsumerAction;
  }
  
  updateType(type: any) {
    if (this.chooseConsumerGroupForm.get('aiCollectionType').value == 'Dynamic') {
      this.chooseConsumerGroupForm.get('aiCollectionValue').clearValidators();
      this.chooseConsumerGroupForm.updateValueAndValidity();
      this.chooseConsumerGroupForm.controls.aiCollectionValue.setValue('');
      this.isValueType = false;
    } else {
      this.isValueType = true;
      this.chooseConsumerGroupForm.get('aiCollectionValue').setValidators([Validators.required]);
      this.chooseConsumerGroupForm.updateValueAndValidity();
    }
  }

  add(event: MatChipInputEvent): void {
		const value = (event.value).trim();
		// const arrayValue = value.splitMulti([",", " "]);
		// const arrayValue = splitMulti(value, [",", " "]);
		const arrayValue = value.split(/\r\n|\n|\r|,|\t|\s| /).filter(word => word.length > 0);
		// const arrayValue = value.split(/;|,|\n\r|\n/).filter(word => word.length > 0);

		// Add our contact
		if (arrayValue && arrayValue.length > 0) {
			for (let i = 0; i <= arrayValue.length; i++) {
				if (arrayValue[i] === null || arrayValue[i] === '' || arrayValue[i] === ' ' || arrayValue[i] === undefined) {
					continue;
				}
				else { this.paymentTag.push(arrayValue[i]); }
			}
		}

		this.paymentTag = this.paymentTag.filter((element): element is any => {
			return (element !== null || element !== 'null' || element !== undefined) ? true : false;
		});

		// Clear the input value
		event.chipInput!.clear();
	}

	remove(contact: string): void {
		const index = this.paymentTag.indexOf(contact);

		if (index >= 0) {
			this.paymentTag.splice(index, 1);
		}
	}
  
}
