import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ClientSiteService, ClientSiteModel,
	StencilService, Stencil, TemplateMessageService
} from 'src/app/@tji/_dbShare';
// import { FormComponent } from '../form/form.component';

@UntilDestroy()
@Component({
	selector: 'template-sidebar-sites',
	templateUrl: './sidebarStencil.component.html',
	styleUrls: ['./sidebarStencil.component.scss']
})

export class SidebarStencilComponent {
	@Output() onClose = new EventEmitter();
	stencils: Stencil[] = [];
	siteParam: any;

	twitterRedirectUrl: string = null;
	loader: boolean = true;

	constructor(private _formBuilder: UntypedFormBuilder,
		public stencilService: StencilService,
		public templateMessageService: TemplateMessageService,
		public clientSiteService: ClientSiteService,
		public userService: UserService,
		private router: Router,
		public matDialog: MatDialog) {
		this.stencilService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.stencils = data;
		});

		this.stencilService.params.pipe(untilDestroyed(this)).subscribe(data => this.siteParam = data);
	}

	ngOnInit() {
		this.stencilService.getAllItems();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.stencilService.resetParams();
	}


	addAccount(stencil: Stencil) {
		this.stencilService.changeItem(stencil);
		this.clientSiteService.changeItem(new ClientSiteModel({}));
		let dialogRef: any = null;
		switch (stencil.slug) {

			case "itrs-link-template":
				this.router.navigateByUrl('/app/template/form');
				// dialogRef = this.matDialog.open(FormComponent, {
				// 	width: "600px",
				// 	data: {
				// 		action: 'new',
				// 		item: stencil,
				// 		title: 'ITRS Template',
				// 	}
				// });
				break;

			case "greating-template":
				this.router.navigateByUrl('/app/template/form');
				// dialogRef = this.matDialog.open(FormComponent, {
				// 	width: "600px",
				// 	data: {
				// 		action: 'new',
				// 		item: stencil,
				// 		title: 'Greatings Template',
				// 	}
				// });
				break;

			case "api-template":
				this.router.navigateByUrl('/app/template/form');
				// dialogRef = this.matDialog.open(FormComponent, {
				// 	width: "600px",
				// 	data: {
				// 		action: 'new',
				// 		item: stencil,
				// 		title: 'API Template',
				// 	}
				// });
				break;

			default:
				break;
		}

		// dialogRef.afterClosed()
		// 	.subscribe(response => {
		// 		if (!response) { return; }
		// 		const actionType: string = response[0];
		// 		const formData: FormGroup = response[1];
		// 		const id: number = (response[2]) ? response[2] : null;
		// 		switch (actionType) {
		// 			case 'new':
		// 				this.templateMessageService.store(formData.value);
		// 				break;
		// 			case 'update':
		// 				this.templateMessageService.update(id, formData.value);
		// 				break;
		// 		}
		// 	});
	}

}
