import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { FormComponent } from './form/form.component';

import { PermissionService } from 'src/app/@tji/_dbShare';
import { ManagePermissionRouteModule } from './managePermission-route.module';
import { FormModuleComponent } from './formModule/formModule.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        FormComponent,
        FormModuleComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        ManagePermissionRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        FormModuleComponent
    ],
    providers: [
        PermissionService
    ]
})

export class ManagePermissionModule { }
