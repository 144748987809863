import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgentRouteModule } from './agent-route.module';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { DatePipe } from '@angular/common';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';
import { AssignSupervisorComponent } from './assignSupervisor/assignSupervisor.component';

import { ProfileComponent } from './itemView/profile/profile.component';
import { ChartComponent } from './itemView/chart/chart.component';
import { EmpowerComponent } from './itemView/empower/empower.component';
import { PermissionComponent } from './itemView/permission/permission.component';
import { TagComponent } from './itemView/tag/tag.component';
import { ItrsComponent } from './itemView/itrs/itrs.component';
import { SettingComponent } from './itemView/setting/setting.component';
import { SettingFieldComponent } from './itemView/settingField/settingField.component';
import { ReportComponent } from './itemView/report/report.component';
import { PersonSettingFormComponent } from './itemView/personSettingForm/personSettingForm.component';

import { AgentService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        FormComponent,
        AssignSupervisorComponent,
        ProfileComponent,
        ChartComponent,
        EmpowerComponent,
        PermissionComponent,
        TagComponent,
        ItrsComponent,
        SettingComponent,
        SettingFieldComponent,
        ReportComponent,
        PersonSettingFormComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        AgentRouteModule,
        UtilsModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
        ItemComponent,
    ],
    providers: [
        AgentService,
        DatePipe
    ]
})

export class AgentModule { }
