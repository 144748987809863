import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import {
	User, UserService, MenuGroup, MenuService, Menu,
	MenuGroupService, Permission, PermissionService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'menuGroup-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {

	dialogTitle: string = 'Create';
	authUser: User;
	menuGroup: MenuGroup;
	menu: Menu;
	menuData: any;
	action: string;
	menuForm: UntypedFormGroup;
	seletedPermission: any;
	checkSelect: boolean = true;
	menuGroupTypes = [
		{ name: 'collapse', id: '1' },
		{ name: 'item', id: '2' }
	];

	allPermissions: Permission[] = [];

	myFormGroup: UntypedFormGroup;
	iconCss = new UntypedFormControl();
	fallbackIcon = 'fas fa-user';

	constructor(public userService: UserService,
		public menuGroupService: MenuGroupService,
		public menuService: MenuService,
		public permissionService: PermissionService,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<FormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {

		this.getInit(_data);

		this.permissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.allPermissions = data;
		});
		this.menuGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.menuGroup = data;
			this.menuForm = this.createForm();
		});
	}

	ngOnInit() {
		this.myFormGroup = new UntypedFormGroup({ iconCss: this.iconCss });
	}

	onIconPickerSelect(icon: string): void {
		this.iconCss.setValue(icon);
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New ' + data.title;
				break;
			case 'edit':
				this.dialogTitle = 'Edit ' + data.title;
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		this.seletedPermission = this.menuGroup.permission;
		return this._formBuilder.group({
			title: [this.menuGroup.title || null, [Validators.required, Validators.minLength(3)]],
			type: [this.menuGroup.type || null, [Validators.required]],
			translate: [this.menuGroup.translate || null, [Validators.required]],
			icon: [this.menuGroup.icon || null],
			route: [this.menuGroup.route || null],
			order: [this.menuGroup.order || null, [Validators.required]],
			permission: [this.menuGroup.permission || null, [Validators.required]],
			is_hidden: [this.menuGroup.is_hidden || 0, [Validators.required]],
			is_active: [this.menuGroup.is_active || 1, [Validators.required]],
			is_open_new_tab: [this.menuGroup.is_open_new_tab || 0, [Validators.required]],
		});
	}

}
