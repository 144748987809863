export class RAgentModel {
	id: number;
	code: string;
	name: string;
	is_active: boolean;
	is_supervisor: boolean;
	supervisor_id?: number;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	client_id: number;
	username: string;

	detail?: any;
	user?: any;
	avatar_url?: string;
	totalClosedTickets?: number;
	totalOpenTickets?: number;
	permittedTicketCount?: number;
	liveStatus?: string;
	roleArray?: Array<any>;
	roleIdArray?: Array<any>;
	permissionIdArray?: Array<any>;
	permissionArray?: Array<any>;
	directPermissionIdArray?: Array<any>;
	directPermissionArray?: Array<any>;
	userId?: number;
	resellerId?: number;
	userEmail?: string;
    assignableStatus?: string;
    supervisorAgentIds?: Array<number>;
	person_details?: any;
	userInfo?: any;	
	tableName: string;
	CountInfo: any;

	/**
	 * Constructor
	 *
	 * @param agent
	 */
	constructor(agent) {
		this.id = agent.id || '';
		this.code = agent.code || '';
		this.name = agent.name || '';
        this.is_active = agent.is_active || true;
        this.is_supervisor = agent.is_supervisor || false;
        this.supervisor_id = agent.supervisor_id || '';
		this.created_by = agent.created_by || '';
		this.updated_by = agent.updated_by || '';
		this.created_at = agent.created_at || '';
		this.updated_at = agent.updated_at || '';
		this.client_id = agent.client_id || '';

		this.detail = agent.detail || {};
		this.user = agent.user || {};
		this.tableName = agent.tableName || '';
		this.userInfo = agent.userInfo || '';
		this.person_details = agent.person_details || '';
		this.CountInfo = agent.CountInfo || '';
		this.username = agent.username || '';
	}
}
