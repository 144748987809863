export class TweetdmListModel {
    id: number;
    tweetdm_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    twitter_code: string;
    in_reply_to_tweet_code: string;
    text: string;
    sender_id: string;
    recipient_id: string;
    user_name: string;
    user_screen_name: string;
    recipient_screen_name: string;
    tweet_at: Date;
    comment_count: number;
    user_image: string;
    media: string;
    asset_url: string;
    json: string;
    status: string;
    message_type: string;
    is_active: boolean;
    is_read: boolean;
    is_assigned: boolean;
    is_closed: boolean;
    is_itrs: boolean;
    intractive_id: number;
    intractive_group_id: number;
    people_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    statusType?: string;
    isSent?: boolean;
    pusherSync?: string;
    agentName?: string;
    createdByName?: string;
    reply?: any;

    tableName: string;
    className: string;
    class?: string;
    imageSrcUrl?: string;
    replayPersonType: any;

    /**
     * Constructor
     *
     * @param tweetdm
     */
    constructor(tweetdm) {
            this.id = tweetdm.id || '';
            this.tweetdm_id = tweetdm.tweetdm_id || '';
            this.reply_id = tweetdm.reply_id || '';
            this.client_id = tweetdm.client_id || '';
            this.client_site_id = tweetdm.client_site_id || '';
            this.twitter_code = tweetdm.twitter_code || '';
            this.in_reply_to_tweet_code = tweetdm.in_reply_to_tweet_code || '';
            this.text = tweetdm.text || '';
            this.sender_id = tweetdm.sender_id || '';
            this.recipient_id = tweetdm.recipient_id || '';
            this.user_name = tweetdm.user_name || '';
            this.user_screen_name = tweetdm.user_screen_name || '';
            this.recipient_screen_name = tweetdm.recipient_screen_name || '';
            this.tweet_at = tweetdm.tweet_at || '';
            this.comment_count = tweetdm.comment_count || '';
            this.user_image = tweetdm.user_image || '';
            this.media = tweetdm.media || '';
            this.asset_url = tweetdm.asset_url || '';
            this.json = tweetdm.json || '';
            this.status = tweetdm.status || '';
            this.message_type = tweetdm.message_type || '';
            this.is_active = tweetdm.is_active || '';
            this.is_read = tweetdm.is_read || '';
            this.is_assigned = tweetdm.is_assigned || '';
            this.is_closed = tweetdm.is_closed || '';
            this.is_itrs = tweetdm.is_itrs || '';
            this.intractive_id = tweetdm.intractive_id || '';
            this.intractive_group_id = tweetdm.intractive_group_id || '';
            this.people_id = tweetdm.people_id || '';
            this.created_by = tweetdm.created_by || '';
            this.updated_by = tweetdm.updated_by || '';
            this.created_at = tweetdm.created_at || '';
            this.updated_at = tweetdm.updated_at || '';

            this.tableName = tweetdm.tableName || '';
            this.replayPersonType = tweetdm.replay_person_type || '';
    }
}
