import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResellerRouteModule } from './reseller-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';

import { ProfileComponent } from './itemView/profile/profile.component';
import { EmpowerComponent } from './itemView/empower/empower.component';
import { PermissionComponent } from './itemView/permission/permission.component';
import { SettingComponent } from './itemView/setting/setting.component';
import { SettingFieldComponent } from './itemView/settingField/settingField.component';
import { PersonSettingFormComponent } from './itemView/personSettingForm/personSettingForm.component';

import { ResellerService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        FormComponent,
        ProfileComponent,
        EmpowerComponent,
        PermissionComponent,
        SettingComponent,
        SettingFieldComponent,
        PersonSettingFormComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        ResellerRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ItemComponent,
    ],
    providers: [
        ResellerService
    ]
})

export class ResellerModule { }
