export class EordersModel {
    _id: number;
    id: number;
    client_site_id: number;
    email: string;
    name: any;
    people_id: number;
    phoneNumber?: any;
    ticket_id?: number;
    productDetails?: Array<any>;
    deliveryPrice?: number;
    Totalamount?: number;
    currency?: number;
    status?: string;
    client_id?: number;
    created_name?: string;
    created_by?: any;
    person_type?: string;
    person_id?: number;
    created_at?: any;
    PaymentsInfo?: any;
    OrderId: number;
    regionName: string;
    governorateName: string;
    tax: any;
    address: any;

    /**
     * Constructor
     *
     * @param eorders
     */
    constructor(eorders) {
        this._id = eorders._id || '';
        this.id = eorders.id || '';
        this.client_site_id = eorders.client_site_id || '';
        this.email = eorders.email || '';
        this.name = eorders.name || '';
        this.people_id = eorders.people_id || '';
        this.productDetails = eorders.productDetails || {};
        this.ticket_id = eorders.ticket_id || '';
        this.deliveryPrice = eorders.deliveryPrice || '';
        this.Totalamount = eorders.Totalamount || '';
        this.currency = eorders.currency || '';
        this.status = eorders.status || '';
        this.client_id = eorders.client_id || '';
        this.created_name = eorders.created_name || '';
        this.created_by = eorders.created_by || '';
        this.person_type = eorders.person_type || '';
        this.person_id = eorders.person_id || '';
        this.created_at = eorders.created_at || '';
        this.PaymentsInfo = eorders.PaymentsInfo || '';
        this.OrderId = eorders.OrderId || '';
    }
}