import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
    MediaService, Media,
	AgentService, Agent
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'gallery-share-media-form',
    templateUrl: './shareMediaForm.component.html',
    styleUrls: ['./shareMediaForm.component.scss']
})

export class ShareMediaFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	media: Media;
    shareMediaForm: UntypedFormGroup;
	ticketClassName: string = null;

	agents: Agent[] = [];
	agentParam: any;

	action: string;

	constructor(public userService: UserService,
		public mediaService: MediaService,
		public agentService: AgentService,
        public matDialogRef: MatDialogRef<ShareMediaFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.mediaService.item.pipe(untilDestroyed(this)).subscribe(data => {
            this.media = data;
            this.shareMediaForm = this.createForm();
		});
		this.agentService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
		this.agents = data});
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.agentService.unSubscribeFilter();
	 }

	getAgents() {
		this.agentParam.limit = 500;
		this.agentService.getAllItems();
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'edit':
				this.dialogTitle = 'Share To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			client_id: [this.media.client_id || null, [Validators.required]],
			share_to: [null, [Validators.required]],
		});
	}

}
