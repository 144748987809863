import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'src/environments/environment';
import { filter, map, startWith, delay } from 'rxjs/operators';

import {
	User, UserService,
	SiteService, Site,
	ClientSiteService, ClientSite, ClientSiteModel, SocketService, EventService, AlertService, PusherService, TeamService, Team
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }
import {
	slideInLeftAnimation,
	slideInRightAnimation,
	slideOutLeftAnimation,
	slideOutRightAnimation,
} from 'angular-animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Socket } from 'ngx-socket-io';
import { DomSanitizer } from '@angular/platform-browser';


declare var window: any;
declare var FB: any;



@UntilDestroy()
@Component({
	selector: 'social-site-whatsapp-form',
	templateUrl: './whatsappForm.component.html',
	styleUrls: ['./whatsappForm.component.scss'],
	animations: [
		slideInLeftAnimation(),
		slideInRightAnimation(),
		slideOutLeftAnimation(),
		slideOutRightAnimation(),
	]
})

export class WhatsappFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	clientSite: ClientSite;
	sites: Site[] = [];
	selectedSite: Site;
	siteParam: any;
	action: string;
	siteId: any;
	clientSiteForm: UntypedFormGroup;
	availableProviders: Array<any> = [];
	server: string = environment.defaultServer || 'app';
	selectedProvider: any = null;
	socialAccountSource: any = null;
	socialAccountNumber: Array<any> = [];
	verticals: Array<string> = ['OTHER', 'AUTO'];
	isAuth: boolean = true;
	authUserId: number = null;
	channel: any;
	eventData: any;
	socialAccountSourceView: boolean = false;
	socialAccountVerifiedView: boolean = false;
	socialAccountNotVerified: boolean = false;
	newAccountMode: boolean = false;
	updateAccountMode: boolean = false;
	accountMode: boolean = true;
	viaMomento: boolean = false;
	viaMomentoWati: boolean = false;
	isIn = true;
	left = true;
	visibility = 'visible';
	loader: boolean = false;
	socialSiteResponseData: any;

	timeZoonData: any = [
		{
			country: 'Asia/Kabul',
			timezoon: 'UTC +04:30',
		},
		{
			country: 'Europe/Tirane',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Algiers',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Pacific/Pago_Pago',
			timezoon: 'UTC -11:00',
		},
		{
			country: 'Europe/Andorra',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Luanda',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'America/Anguilla',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Antarctica/Casey',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Antarctica/Davis',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Antarctica/DumontDUrville',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Antarctica/Mawson',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Antarctica/McMurdo',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Antarctica/Palmer',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Antarctica/Rothera',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Antarctica/Syowa',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Antarctica/Troll',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Antarctica/Vostok',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'America/Antigua',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Argentina/Buenos_Aires',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Catamarca',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Cordoba',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Jujuy',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/La_Rioja',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Mendoza',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Rio_Gallegos',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Salta',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/San_Juan',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/San_Luis',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Tucuman',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Argentina/Ushuaia',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Asia/Yerevan',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'America/Aruba',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Antarctica/Macquarie',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Australia/Adelaide',
			timezoon: 'UTC +09:30',
		},
		{
			country: 'Australia/Brisbane',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Australia/Broken_Hill',
			timezoon: 'UTC +09:30',
		},
		{
			country: 'Australia/Darwin',
			timezoon: 'UTC +09:30',
		},
		{
			country: 'Australia/Eucla',
			timezoon: 'UTC +08:45',
		},
		{
			country: 'Australia/Hobart',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Australia/Lindeman',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Australia/Lord_Howe',
			timezoon: 'UTC +10:30',
		},
		{
			country: 'Australia/Melbourne',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Australia/Perth',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Australia/Sydney',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Europe/Vienna',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Baku',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'America/Nassau',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Asia/Bahrain',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Dhaka',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'America/Barbados',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Europe/Minsk',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Brussels',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Belize',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Africa/Porto-Novo',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Atlantic/Bermuda',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Asia/Thimphu',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'America/La_Paz',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Kralendijk',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Europe/Sarajevo',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Gaborone',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Araguaina',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Bahia',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Belem',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Boa_Vista',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Campo_Grande',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Cuiaba',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Eirunepe',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Fortaleza',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Maceio',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Manaus',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Noronha',
			timezoon: 'UTC -02:00',
		},
		{
			country: 'America/Porto_Velho',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Recife',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Rio_Branco',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Santarem',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Sao_Paulo',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Indian/Chagos',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'Asia/Brunei',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Europe/Sofia',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Ouagadougou',
			timezoon: 'UTC',
		},
		{
			country: 'Africa/Bujumbura',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Phnom_Penh',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Africa/Douala',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'America/Atikokan',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Blanc-Sablon',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Cambridge_Bay',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Creston',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Dawson',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Dawson_Creek',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Edmonton',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Fort_Nelson',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Glace_Bay',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Goose_Bay',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Halifax',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Inuvik',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Iqaluit',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Moncton',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Rankin_Inlet',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Regina',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Resolute',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/St_Johns',
			timezoon: 'UTC -02:30',
		},
		{
			country: 'America/Swift_Current',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Toronto',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Vancouver',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Whitehorse',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Winnipeg',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Atlantic/Cape_Verde',
			timezoon: 'UTC -01:00',
		},
		{
			country: 'America/Cayman',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Africa/Bangui',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Ndjamena',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'America/Punta_Arenas',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Santiago',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Pacific/Easter',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Asia/Shanghai',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Asia/Urumqi',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'Indian/Christmas',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Indian/Cocos',
			timezoon: 'UTC +06:30',
		},
		{
			country: 'America/Bogota',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Indian/Comoro',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Brazzaville',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Kinshasa',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Lubumbashi',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Pacific/Rarotonga',
			timezoon: 'UTC -10:00',
		},
		{
			country: 'America/Costa_Rica',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Europe/Zagreb',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Havana',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Curacao',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Asia/Famagusta',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Nicosia',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Prague',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Abidjan',
			timezoon: 'UTC',
		},
		{
			country: 'Europe/Copenhagen',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Djibouti',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'America/Dominica',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Santo_Domingo',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Guayaquil',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Pacific/Galapagos',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Africa/Cairo',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'America/El_Salvador',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Africa/Malabo',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Asmara',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Tallinn',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Addis_Ababa',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Atlantic/Stanley',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Atlantic/Faroe',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Pacific/Fiji',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Europe/Helsinki',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Paris',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Cayenne',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Pacific/Gambier',
			timezoon: 'UTC -09:00',
		},
		{
			country: 'Pacific/Marquesas',
			timezoon: 'UTC -09:30',
		},
		{
			country: 'Pacific/Tahiti',
			timezoon: 'UTC -10:00',
		},
		{
			country: 'Indian/Kerguelen',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Africa/Libreville',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Banjul',
			timezoon: 'UTC',
		},
		{
			country: 'Asia/Tbilisi',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Europe/Berlin',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Busingen',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Accra',
			timezoon: 'UTC',
		},
		{
			country: 'Europe/Gibraltar',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Athens',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'America/Danmarkshavn',
			timezoon: 'UTC',
		},
		{
			country: 'America/Nuuk',
			timezoon: 'UTC -02:00',
		},
		{
			country: 'America/Scoresbysund',
			timezoon: 'UTC',
		},
		{
			country: 'America/Thule',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'America/Grenada',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Guadeloupe',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Pacific/Guam',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'America/Guatemala',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Europe/Guernsey',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Conakry',
			timezoon: 'UTC',
		},
		{
			country: 'Africa/Bissau',
			timezoon: 'UTC',
		},
		{
			country: 'America/Guyana',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Port-au-Prince',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Europe/Vatican',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Tegucigalpa',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Asia/Hong_Kong',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Europe/Budapest',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Atlantic/Reykjavik',
			timezoon: 'UTC',
		},
		{
			country: 'Asia/Kolkata',
			timezoon: 'UTC +05:30',
		},
		{
			country: 'Asia/Jakarta',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Jayapura',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Makassar',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Asia/Pontianak',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Tehran',
			timezoon: 'UTC +03:30',
		},
		{
			country: 'Asia/Baghdad',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Dublin',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Europe/Isle_of_Man',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Asia/Jerusalem',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Rome',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Jamaica',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Asia/Tokyo',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Europe/Jersey',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Asia/Amman',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Almaty',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'Asia/Aqtau',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Asia/Aqtobe',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Asia/Atyrau',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Asia/Oral',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Asia/Qostanay',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'Asia/Qyzylorda',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Africa/Nairobi',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Pacific/Kanton',
			timezoon: 'UTC +13:00',
		},
		{
			country: 'Pacific/Kiritimati',
			timezoon: 'UTC +14:00',
		},
		{
			country: 'Pacific/Tarawa',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Asia/Pyongyang',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Seoul',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Kuwait',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Bishkek',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'Asia/Vientiane',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Europe/Riga',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Beirut',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Maseru',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Monrovia',
			timezoon: 'UTC',
		},
		{
			country: 'Africa/Tripoli',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Vaduz',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Vilnius',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Luxembourg',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Macau',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Europe/Skopje',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Indian/Antananarivo',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Blantyre',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Kuala_Lumpur',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Asia/Kuching',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Indian/Maldives',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Africa/Bamako',
			timezoon: 'UTC',
		},
		{
			country: 'Europe/Malta',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Pacific/Kwajalein',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Pacific/Majuro',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'America/Martinique',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Africa/Nouakchott',
			timezoon: 'UTC',
		},
		{
			country: 'Indian/Mauritius',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Indian/Mayotte',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'America/Bahia_Banderas',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Cancun',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Chihuahua',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Ciudad_Juarez',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Hermosillo',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Matamoros',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Mazatlan',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Merida',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Mexico_City',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Monterrey',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Ojinaga',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Tijuana',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'Pacific/Chuuk',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Pacific/Kosrae',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Pacific/Pohnpei',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Europe/Chisinau',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Monaco',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Choibalsan',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Asia/Hovd',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Ulaanbaatar',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Europe/Podgorica',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Montserrat',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Africa/Casablanca',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/El_Aaiun',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Maputo',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Yangon',
			timezoon: 'UTC +06:30',
		},
		{
			country: 'Africa/Windhoek',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Pacific/Nauru',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Asia/Kathmandu',
			timezoon: 'UTC +05:45',
		},
		{
			country: 'Europe/Amsterdam',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Pacific/Noumea',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Pacific/Auckland',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Pacific/Chatham',
			timezoon: 'UTC +12:45',
		},
		{
			country: 'America/Managua',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'Africa/Niamey',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Africa/Lagos',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Pacific/Niue',
			timezoon: 'UTC -11:00',
		},
		{
			country: 'Pacific/Norfolk',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Pacific/Saipan',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Europe/Oslo',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Muscat',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Asia/Karachi',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Pacific/Palau',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Gaza',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Hebron',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'America/Panama',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Pacific/Bougainville',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Pacific/Port_Moresby',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'America/Asuncion',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Lima',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'Asia/Manila',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Pacific/Pitcairn',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'Europe/Warsaw',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Atlantic/Azores',
			timezoon: 'UTC',
		},
		{
			country: 'Atlantic/Madeira',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Europe/Lisbon',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'America/Puerto_Rico',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Asia/Qatar',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Bucharest',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Anadyr',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Asia/Barnaul',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Chita',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Irkutsk',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Asia/Kamchatka',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Asia/Khandyga',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Krasnoyarsk',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Magadan',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Asia/Novokuznetsk',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Novosibirsk',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Omsk',
			timezoon: 'UTC +06:00',
		},
		{
			country: 'Asia/Sakhalin',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Asia/Srednekolymsk',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Asia/Tomsk',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Ust-Nera',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Asia/Vladivostok',
			timezoon: 'UTC +10:00',
		},
		{
			country: 'Asia/Yakutsk',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Asia/Yekaterinburg',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Europe/Astrakhan',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Europe/Kaliningrad',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Kirov',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Moscow',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Samara',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Europe/Saratov',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Europe/Ulyanovsk',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Europe/Volgograd',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Kigali',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Indian/Reunion',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'America/St_Barthelemy',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Atlantic/St_Helena',
			timezoon: 'UTC',
		},
		{
			country: 'America/St_Kitts',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/St_Lucia',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Marigot',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Miquelon',
			timezoon: 'UTC -02:00',
		},
		{
			country: 'America/St_Vincent',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Pacific/Apia',
			timezoon: 'UTC +13:00',
		},
		{
			country: 'Europe/San_Marino',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Sao_Tome',
			timezoon: 'UTC',
		},
		{
			country: 'Asia/Riyadh',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Dakar',
			timezoon: 'UTC',
		},
		{
			country: 'Europe/Belgrade',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Indian/Mahe',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Africa/Freetown',
			timezoon: 'UTC',
		},
		{
			country: 'Asia/Singapore',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'America/Lower_Princes',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Europe/Bratislava',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Ljubljana',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Pacific/Guadalcanal',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'Africa/Mogadishu',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Johannesburg',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Atlantic/South_Georgia',
			timezoon: 'UTC -02:00',
		},
		{
			country: 'Africa/Juba',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Ceuta',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Atlantic/Canary',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Europe/Madrid',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Colombo',
			timezoon: 'UTC +05:30',
		},
		{
			country: 'Africa/Khartoum',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'America/Paramaribo',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Arctic/Longyearbyen',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Mbabane',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Stockholm',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Zurich',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Asia/Damascus',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Taipei',
			timezoon: 'UTC +08:00',
		},
		{
			country: 'Asia/Dushanbe',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Africa/Dar_es_Salaam',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Bangkok',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'Asia/Dili',
			timezoon: 'UTC +09:00',
		},
		{
			country: 'Africa/Lome',
			timezoon: 'UTC',
		},
		{
			country: 'Pacific/Fakaofo',
			timezoon: 'UTC +13:00',
		},
		{
			country: 'Pacific/Tongatapu',
			timezoon: 'UTC +13:00',
		},
		{
			country: 'America/Port_of_Spain',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Africa/Tunis',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'Europe/Istanbul',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Ashgabat',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'America/Grand_Turk',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Pacific/Funafuti',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Africa/Kampala',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Kyiv',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Europe/Simferopol',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Asia/Dubai',
			timezoon: 'UTC +04:00',
		},
		{
			country: 'Europe/London',
			timezoon: 'UTC +01:00',
		},
		{
			country: 'America/Adak',
			timezoon: 'UTC -09:00',
		},
		{
			country: 'America/Anchorage',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'America/Boise',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Chicago',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Denver',
			timezoon: 'UTC -06:00',
		},
		{
			country: 'America/Detroit',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Indiana/Indianapolis',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Indiana/Knox',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Indiana/Marengo',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Indiana/Petersburg',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Indiana/Tell_City',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Indiana/Vevay',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Indiana/Vincennes',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Indiana/Winamac',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Juneau',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'America/Kentucky/Louisville',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Kentucky/Monticello',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Los_Angeles',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Menominee',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Metlakatla',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'America/New_York',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/Nome',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'America/North_Dakota/Beulah',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/North_Dakota/Center',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/North_Dakota/New_Salem',
			timezoon: 'UTC -05:00',
		},
		{
			country: 'America/Phoenix',
			timezoon: 'UTC -07:00',
		},
		{
			country: 'America/Sitka',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'America/Yakutat',
			timezoon: 'UTC -08:00',
		},
		{
			country: 'Pacific/Honolulu',
			timezoon: 'UTC -10:00',
		},
		{
			country: 'Pacific/Midway',
			timezoon: 'UTC -11:00',
		},
		{
			country: 'Pacific/Wake',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'America/Montevideo',
			timezoon: 'UTC -03:00',
		},
		{
			country: 'Asia/Samarkand',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Asia/Tashkent',
			timezoon: 'UTC +05:00',
		},
		{
			country: 'Pacific/Efate',
			timezoon: 'UTC +11:00',
		},
		{
			country: 'America/Caracas',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Asia/Ho_Chi_Minh',
			timezoon: 'UTC +07:00',
		},
		{
			country: 'America/Tortola',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'America/St_Thomas',
			timezoon: 'UTC -04:00',
		},
		{
			country: 'Pacific/Wallis',
			timezoon: 'UTC +12:00',
		},
		{
			country: 'Asia/Aden',
			timezoon: 'UTC +03:00',
		},
		{
			country: 'Africa/Lusaka',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Africa/Harare',
			timezoon: 'UTC +02:00',
		},
		{
			country: 'Europe/Mariehamn',
			timezoon: 'UTC +03:00',
		},
	]

	filteredOptions: Observable<string[]>;

	profileUpdateForm: UntypedFormGroup = new UntypedFormGroup({
		timeZoon: new UntypedFormControl(''),
		businessVertical: new UntypedFormControl(''),
		whatsappEmail: new UntypedFormControl(''),
		whatsappWebsites: new UntypedFormControl(''),
		whatsappAbout: new UntypedFormControl(''),
		whatsappDescription: new UntypedFormControl(''),
		whatsappAddress: new UntypedFormControl(''),
	});
	profileDataView: any;
	accountsDetails: any;
	socialAccountActive: boolean = false;
	ReadOnlyData: boolean = false;
	allLanguageData: any
	selectedLanguage: any;
	selectedModule: any;
	selectedTeam: any;
	greetingsData: any;
	is_aicommerce_automation: boolean = false;
	moduleListDate: any;
	teams: Team[] = [];
	techProvider: any;


	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public siteService: SiteService,
		private socketService: SocketService,
		private eventService: EventService,
		private alertService: AlertService,
		private commonService: CommonService,
		private cd: ChangeDetectorRef,
		public pusherService: PusherService,
		private socket: Socket,
		private sanitizer: DomSanitizer,
		public teamService: TeamService,
		public matDialogRef: MatDialogRef<WhatsappFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		window.fbAsyncInit = function () {
			FB.init({
				appId: "192057255354059",
				autoLogAppEvents: true,
				xfbml: true,
				version: 'v18.0'
			});
		};

		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));

		this.getInit(_data);
		this.socialAccountSource = null;
		this.socialAccountSourceView = false;
		this.siteService.params.pipe(untilDestroyed(this)).subscribe(data => this.siteParam = data);
		this.siteService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.selectedSite = data;
			this.availableProviders = data.available_providers;
			// this.selectedProvider = data.available_providers.find(x => x.name === 'option-2');
		});
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSite = data;
			if (this.clientSite && this.clientSite.id) {
				this.selectedSite = this.clientSite.details;
				this.availableProviders = this.clientSite.details.available_providers;
				let service_provider_name = (this.clientSite && this.clientSite.service_provider) ? this.clientSite.service_provider : 'option-2';
				// this.selectedProvider = this.clientSite.details.available_providers.find(x => x.name === service_provider_name);
				this.profileDataView = JSON.parse(this.clientSite.json);
				this.accountsDetails = this.clientSite;
			}
		});

		this.clientSiteService.allLanguage.pipe(untilDestroyed(this)).subscribe(data => this.allLanguageData = data);
		this.clientSiteService.allModule.pipe(untilDestroyed(this)).subscribe(data => this.moduleListDate = data);
		this.teamService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.teams = data);

		this.userService.user.subscribe(data => {
			this.authUser = data;
			this.isAuth = (this.authUser && this.authUser.id > 0) ? true : false;
			this.authUserId = (data && data.id) ? data.id : null;
		});
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.clientSiteService.socialSiteUrlRedirectUrl.pipe(untilDestroyed(this)).subscribe(async data => {
			if (data && data.data) {
				this.whatsappUrl = data.data ? data.data.RedirectUrl : ''
				this.techProvider = data.data;
				if (data && data.data && data.data.bverticals) {
					this.verticals = data.data.bverticals;
				}
				if (this.techProvider && this.techProvider.appId && this.techProvider.config_id && this.techProvider.solutionid) {
					this.facebookLogin();
				}

			}
		});

	}
	/** close popup */
	close(data: any = null) {
		document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight")
		document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
		setTimeout(() => {
			if (data == 'new') {
				this.matDialogRef.close(['new', this.clientSiteForm]);
				this.newAccountMode = true;
				this.updateAccountMode = false;
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
			} else if (data == 'update') {
				this.matDialogRef.close(['update', this.clientSiteForm, this.clientSite?.id]);
			} else if (data == 'done') {
				this.matDialogRef.close('whatsapp');
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
				this.siteService.getAllItems();
				this.alertService.webShow('Success', 'configuration added Successfully !!!');
			} else if (data == 'updateProfile') {
				this.matDialogRef.close('updateProfile');
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
				this.profileData();
			} else if (data == 'error') {
				this.matDialogRef.close();
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.updateAccountMode = false;
				this.socialAccountNotVerified = false;
			} else {
				this.newAccountMode = true;
				this.updateAccountMode = false;
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
				this.matDialogRef.close();
				this.siteService.getAllItems();
			}
		}, 1000);
	}

	ngOnInit() {
		this.socialAccountSource = null;
		this.selectedService_provider = this.clientSite.service_provider;
		const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		/** profile control create */
		this.profileUpdateForm = this._formBuilder.group({
			businessVertical: [null, Validators.required],
			timeZoon: ['', Validators.required],
			whatsappEmail: [''],
			whatsappWebsites: ['', [Validators.required, Validators.max(30), Validators.min(5), Validators.pattern(urlRegex)]],
			whatsappAbout: [''],
			whatsappDescription: [''],
			whatsappAddress: [''],
		})
		// /** profile set value */
		// this.profileUpdateForm.setValue({
		// 	businessVertical: this.profileDataView ? this.profileDataView.businessVertical : '',
		// 	whatsappAddress: this.profileDataView ? this.profileDataView.whatsappAddress : '',
		// 	whatsappEmail: this.profileDataView ? this.profileDataView.whatsappEmail : '',
		// 	whatsappWebsites: this.profileDataView ? this.profileDataView.whatsappWebsites : '',
		// 	whatsappAbout: this.profileDataView ? this.profileDataView.whatsappAbout : '',
		// 	whatsappDescription: this.profileDataView ? this.profileDataView.whatsappDescription : '',
		// })
		this.filteredOptions = this.timeZoon.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '')),
		);

	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.timeZoonData.filter(option => option.country.toLowerCase().includes(filterValue));
	}

	/** profile validation set */
	get businessVertical() { return this.profileUpdateForm.get('businessVertical'); }
	get timeZoon() { return this.profileUpdateForm.get('timeZoon'); }
	get whatsappEmail() { return this.profileUpdateForm.get('whatsappEmail'); }
	get whatsappWebsites() { return this.profileUpdateForm.get('whatsappWebsites'); }
	get whatsappAbout() { return this.profileUpdateForm.get('whatsappAbout'); }
	get whatsappDescription() { return this.profileUpdateForm.get('whatsappDescription'); }
	get whatsappAddress() { return this.profileUpdateForm.get('whatsappAddress'); }

	reAuthentication: boolean = false;
	reAuthenticationNotification: boolean = false;
	reAuthenticationTitle = 'You are not authorized to access this application !!!';
	reAuthenticationData: any;
	/** On destroy */
	ngOnDestroy(): void {
		this.newAccountMode = true;
		this.updateAccountMode = false;
		this.accountMode = true;
		this.viaMomento = false;
		this.viaMomentoWati = false;
		this.socialAccountSourceView = false;
		this.socialAccountVerifiedView = false;
		this.socialAccountNotVerified = false;
		this.eventService.changeEventData(null);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.siteId = data.item.id
		var datas = {
			"site_id": 2,
			"option": "option-3"
		}
		switch (data.action) {
			case 'changeConfig':
				this.changeConfig();
				this.clientSiteService.facebookClientSiteUrl(null, datas)
				break;
			case 'new':
				this.dialogTitle = 'New Whatsapp Account';
				delete this.selectedService_provider;
				this.newAccountMode = true;
				this.updateAccountMode = false;
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
				this.reAuthentication = false;

				break;
			case 'edit':
				this.reAuthenticationData = data.item;
				this.clientSiteService.facebookClientSiteUrl(null, datas)
				// if (data.item.details.id && !data.item.is_authorized) {
				// 	this.dialogTitle = 'Re-Authorize Whatsapp Account';
				// 	this.updateAccountMode = true;
				// 	this.newAccountMode = false;
				// 	this.accountMode = true;
				// 	this.reAuthentication = true;
				// 	this.siteId = data.item.details.id
				// } else {
				this.accountData = data.item.account_name;
				this.dialogTitle = 'Edit Whatsapp Account';
				this.updateAccountMode = true;
				this.reAuthentication = false;
				this.newAccountMode = false;
				this.accountMode = true;
				this.siteId = data.item.details.id
				setTimeout(() => {
					this.getprofileImage();
					this.getProfileData();
				}, 100);
				// }
				break;
			case 'reAuthorize':
				datas["options"] = data.item.id;
				this.reAuthenticationData = data.item;
				// if (data.item.details.id && !data.item.is_authorized) {
				// 	this.dialogTitle = 'Re-Authorize Whatsapp Account';
				// 	this.updateAccountMode = true;
				// 	this.newAccountMode = false;
				// 	this.accountMode = true;
				// 	this.reAuthentication = true;
				// 	this.siteId = data.item.details.id
				// } else {
				this.dialogTitle = 'Re-Authorize Whatsapp Account';
				this.updateAccountMode = true;
				this.reAuthentication = false;
				this.newAccountMode = false;
				this.accountMode = true;
				this.siteId = data.item.details.id
				this.clientSiteService.facebookClientSiteUrl(null, datas)
				setTimeout(() => {
					this.getprofileImage();
					this.getProfileData();
				}, 100);
				// }
				break;
			default:
				break;
		}

	}
	emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
	/** Create form */
	createForm(providerName = 'option-2'): UntypedFormGroup {

		const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		const email = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
		// const emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
		switch (providerName) {
			case "default":
				return this._formBuilder.group({
					site_id: [this.clientSite.site_id || (this.selectedSite) ? this.selectedSite.id : null || null, [Validators.required]],
					service_provider: [this.clientSite.service_provider || (this.selectedProvider) ? this.selectedProvider.name : 'default', [Validators.required]],
					account_name: [this.clientSite.account_name || null, [Validators.required, Validators.minLength(3)]],
					whatsapp_number: [this.clientSite.whatsapp_number || null, [Validators.minLength(8), Validators.maxLength(15)]],
					token: [this.clientSite.token || null, [Validators.minLength(1)]],
					is_active: [this.clientSite.is_active || '1', Validators.required],
					is_authorized: [this.clientSite.is_authorized || '1', Validators.required],
					client_id: this.clientSite.client_id || null,
				});
				break;
			case "option-2":
				return this._formBuilder.group({
					whatsapp_number: [this.clientSite.whatsapp_number || null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(20)]],
					endpoint: [null, [Validators.minLength(1)]],
					username: [null, [Validators.minLength(1)]],
					password: [null, [Validators.minLength(1)]],
					namespace: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]],
				});
				break;
			case "option-3":
				return this._formBuilder.group({
					site_id: [this.clientSite.site_id || (this.selectedSite) ? this.selectedSite.id : null, [Validators.required]],
					service_provider: [this.clientSite.service_provider || (this.selectedProvider) ? this.selectedProvider.name : 'option-', [Validators.required]],
					account_name: [this.clientSite.account_name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
					whatsapp_number: [this.clientSite.whatsapp_number || null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(20)]],
					username: [this.clientSite.username || null, [Validators.minLength(1)]],
					password: [this.clientSite.password || null, [Validators.minLength(1)]],
					token: [this.clientSite.token || null, [Validators.required, Validators.minLength(1)]],
					is_active: [this.clientSite.is_active || '1', Validators.required],
					is_authorized: [this.clientSite.is_authorized || '1', Validators.required],
					whatsappDescription: [this.clientSite.whatsappDescription || null, [Validators.required, Validators.maxLength(300), Validators.minLength(5)]],
					whatsappAbout: [this.clientSite.whatsappAbout || null, [Validators.required, Validators.maxLength(300), Validators.minLength(5)]],
					whatsappAddress: [this.clientSite.whatsappAddress || null, [Validators.required, Validators.maxLength(300), Validators.minLength(5)]],
					whatsappWebsites: [this.clientSite.whatsappWebsites || null, [Validators.required, Validators.maxLength(30), Validators.pattern(urlRegex)]],
					whatsappEmail: [this.clientSite.whatsappEmail || null, [Validators.required, Validators.maxLength(30)]],

					server: [this.clientSite.server || this.server || 'app', Validators.required],
					// whatsappWebhookUrl: [this.clientSite.whatsappWebhookUrl || null, Validators.pattern(urlRegex)],
					client_id: this.clientSite.client_id || null,
				});
				break;
			case "option-4":
				return this._formBuilder.group({
					whatsapp_number: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(20)]],
					channelID: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
					namespace: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]],
				});
				break;
			case "profile":
				return this._formBuilder.group({
					businessVertical: [this.clientSite.businessVertical || '', Validators.required],
					timeZoon: [this.clientSite.time || '', Validators.required],
					whatsappEmail: [this.clientSite.whatsappEmail || '', [Validators.required, Validators.maxLength(30), Validators.minLength(5), Validators.pattern(this.emailPattern)]],
					whatsappWebsites: [this.clientSite.whatsappWebsites || '', [Validators.required, Validators.maxLength(30), Validators.minLength(5), Validators.pattern(urlRegex)]],
					whatsappAbout: [this.clientSite.whatsappAbout || '', [Validators.required, Validators.maxLength(300), Validators.minLength(5)]],
					whatsappDescription: [this.clientSite.whatsappDescription || '', [Validators.required, Validators.maxLength(300), Validators.minLength(5)]],
					whatsappAddress: [this.clientSite.whatsappAddress || '', [Validators.required, Validators.maxLength(300), Validators.minLength(5)]],
				});
				break;
			case "option-6":
				return this._formBuilder.group({
					site_id: [this.clientSite.site_id || (this.selectedSite) ? this.selectedSite.id : null, [Validators.required]],
					service_provider: [this.clientSite.service_provider || (this.selectedProvider) ? this.selectedProvider.name : 'option-6', [Validators.required]],
					account_name: [this.clientSite.account_name || null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
					whatsapp_number: [this.clientSite.whatsapp_number || null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(20)]],
					is_active: [this.clientSite.is_active || '1', Validators.required],
					is_authorized: [this.clientSite.is_authorized || '1', Validators.required],
					server: [this.clientSite.server || this.server || 'app', Validators.required],
					client_id: this.clientSite.client_id || null,
				});
				break;
			default:
				break;
		}
		// this.cd.detectChanges();
	}

	getSites() {
		this.siteParam.all = 1;
		this.siteService.getAllItems();
		this.siteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.sites = data;
			if (this.clientSite && this.clientSite.id) {
				this.changeSite(null, this.clientSite.details);
			}
		});
	}

	getAvailableProviders(availableProviders): Array<any> {
		let output: Array<any> = [];
		output = availableProviders.filter(x => {
			return x.is_active ? true : false;
		});
		return output;
	}

	changeSite(siteId, siteItem: Site = null, providerName: any = 'option-2') {
		providerName = (!providerName) ? 'default' : providerName;
		this.selectedSite = (siteItem && siteItem.id) ? siteItem : this.sites.find(x => x.id === siteId);
		this.availableProviders = this.selectedSite.available_providers;
		if (this.availableProviders && this.availableProviders.length === 1 && !providerName) {
			this.selectedProvider = this.availableProviders[0];
		}
		if (providerName) {
			this.selectedProvider = this.availableProviders.find(x => x.name === providerName);
			this.clientSiteForm = this.createForm(providerName);
		}

	}

	checkFillableForProfile(): boolean {
		let output: boolean = false;
		let fillColumns: Array<string> = ['businessVertical', 'timeZoon', 'whatsappEmail', 'whatsappWebsites', 'whatsappAbout', 'whatsappDescription', 'whatsappAddress'];
		for (let i = 0; i < fillColumns.length; i++) {
			if (this.selectedProvider && this.selectedProvider.requiredFillable && this.selectedProvider.requiredFillable.length > 0) {
				output = (this.selectedProvider.requiredFillable.includes(fillColumns[i])) ? true : false;
			}
			if (output) { break; }
		}
		return output;
	}

	checkFillable(fillColumn: string): boolean {
		if (this.selectedProvider && this.selectedProvider.requiredFillable && this.selectedProvider.requiredFillable.length > 0) {
			return (this.selectedProvider.requiredFillable.includes(fillColumn)) ? true : false;
		}
		return false;
	}
	/** get facebook popup url */
	whatsappUrl: any;
	whatsappLogin(data) {
		var datas = {
			"site_id": 2,
			"option": "option-3"
		}
		this.clientSiteService.facebookClientSiteUrl(null, datas)
		setTimeout(() => {
			this.doWhatsappLogin(data);
		}, 1000);

	}
	doWhatsappLogin(data: boolean = false) {
		this.reAuthenticationNotification = data;
		if (this.reAuthenticationData && this.reAuthenticationData.service_provider) {
			this.service_provider = this.reAuthenticationData.service_provider;
		}
		if (this.whatsappUrl) {
			window.open(this.whatsappUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
		}
	}


	/** select account mode */
	service_provider: any;
	accountModeChoose(item: any) {
		switch (item) {
			case 'cloud':
				var data = {
					"site_id": 2,
					"option": "option-3"
				}
				this.clientSiteService.facebookClientSiteUrl(null, data)
				setTimeout(() => {
					if (this.reAuthenticationData && this.reAuthenticationData.service_provider == 'option-3') {
						this.doWhatsappLogin(true);
						this.socialAccountActive = false;
					} else {
						this.doWhatsappLogin();
						this.service_provider = 'option-3'
						this.socialAccountActive = true;
					}
				}, 1000);
				break;
			case '360':
				var data = {
					"site_id": 2,
					"option": "option-7"
				}
				this.clientSiteService.socialSiteUrl.next(null)
				this.clientSiteService.facebookClientSiteUrl(null, data)
				// this.accountMode = false;
				// this.viaMomento = true;
				// setTimeout(() => {
				// 	window.open(this.whatsappUrl, '_blank', 'location=yes,height=650,width=750,scrollbars=yes,status=yes');
				// }, 1000);

				// setTimeout(() => {
				// 	this.rightIn();
				// }, 10);
				// this.clientSiteForm = this.createForm('option-7');
				// this.service_provider = 'option-7'
				// if (this.accountsDetails && this.accountsDetails.id) {
				// 	this.clientSiteForm.controls['whatsapp_number'].disable();
				// 	this.clientSiteForm.setValue({
				// 		whatsapp_number: this.accountsDetails.whatsapp_number ? this.accountsDetails.whatsapp_number : '',
				// 		channelID: '',
				// 		namespace: this.accountsDetails.whatsappNamespace ? this.accountsDetails.whatsappNamespace : '',
				// 	})
				// }
				break;
			case 'Wati':
				this.accountMode = false;
				this.viaMomentoWati = true;
				setTimeout(() => {
					this.rightIn();
				}, 10);
				this.clientSiteForm = this.createForm('option-2');
				this.service_provider = 'option-2'
				if (this.accountsDetails && this.accountsDetails.id) {
					this.clientSiteForm.controls['whatsapp_number'].disable();
					this.clientSiteForm.setValue({
						whatsapp_number: this.accountsDetails.whatsapp_number ? this.accountsDetails.whatsapp_number : '',
						endpoint: '',
						username: '',
						password: '',
						namespace: this.accountsDetails.whatsappNamespace ? this.accountsDetails.whatsappNamespace : '',
					})
				}
				break;
			default:
				break;
		}
	}
	/** popup animation added */
	backToAccount() {
		this.rightOut();
		setTimeout(() => {
			this.accountMode = true;
			this.viaMomentoWati = false;
			this.viaMomento = false;
		}, 300);
	}
	get right() {
		return !this.left;
	}
	get isOut() {
		return !this.isIn;
	}
	rightIn() {
		this.isIn = true;
		this.left = false;
		this.visibility = 'visible';
	}
	leftIn() {
		this.accountMode = false;
		this.viaMomento = true;
		this.isIn = true;
		this.left = true;
		this.visibility = 'visible';
	}
	rightOut() {
		this.isIn = false;
		this.left = false;
	}
	leftOut() {
		this.isIn = false;
		this.left = true;
	}
	afterOut() {
		if (this.isOut) {
			this.visibility = 'hidden';
		}
	}
	/** socket listner from facebook account add  */
	// checkEventData() {
	// 	let eventName;
	// 	eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
	// 	if (this.eventData && this.eventData.authorizeEvent) {
	// 		eventName = this.eventData.authorizeEvent
	// 	}


	// 	if (this.socialAccountActive && !this.reAuthenticationNotification && this.selectedService_provider != 'option-3') {
	// 		eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
	// 	} else if (this.reAuthenticationNotification && this.selectedService_provider == 'option-3') {
	// 		this.webHoks();
	// 		// this.alertService.webShow('Success', 'Re-authentication Successfully !!!');
	// 		// this.matDialogRef.close('360');
	// 		// this.reAuthenticationNotification = false;
	// 	}
	// 	// else if(this.selectedService_provider){
	// 	// 	this.alertService.webShow('Success', 'Re-authentication Successfully !!!');
	// 	// 	this.matDialogRef.close('360');
	// 	// }

	// 	// let eventName = data ? (this.eventData && this.eventData.event) : null;

	// 	if (eventName) {
	// 		let eventData = this.eventData;
	// 		let data = this.eventData.data;
	// 		switch (eventName) {
	// 			case 'addaccount':
	// 				if (!this.eventData.result) {
	// 					// this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
	// 					this.alertService.webShow('Danger', this.eventData.data.message);
	// 				} else {
	// 					this.alertService.webShow('Success', 'Facebook Login Successfully !!!');
	// 					this.socialAccountSource = this.eventData;
	// 					this.socialAccountSourceView = true;
	// 					this.accountMode = false;
	// 					this.rightIn();
	// 					this.socialAccountActive = false;
	// 				}
	// 				break;
	// 			default:
	// 				break;
	// 		}
	// 	}


	// }

	checkEventData() {
		if (this.eventData) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'addaccount':
					if (this.eventData && this.eventData.data && this.eventData.data.result == false) {
						// this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
						this.alertService.webShow('Danger', this.eventData.data.message);
						this.socialAccountSourceView = false;
						this.accountMode = true;
					} else {
						this.alertService.webShow('Success', 'Facebook Login Successfully !!!');
						this.socialAccountSource = this.eventData;
						this.socialAccountSourceView = true;
						this.accountMode = false;
						this.rightIn();
						this.socialAccountActive = false;
					}
					break;
				default:
					break;
			}
		}
	}
	/** select phone number */
	selectPhoneNumber(item) {
		this.socialAccountNumber = item;
	}
	/** account add and api call */
	webHoks() {
		this.loader = true;
		this.accountMode = false;

		if (this.socialAccountNumber && this.socialAccountNumber['display_phone_number']) {
			var number = this.socialAccountNumber['display_phone_number']
			var phoneNumber = number.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
			var display_phone_number = phoneNumber.split(' ').join('');
		}
		var socialAccountData;
		if (this.clientSiteForm && this.clientSiteForm.valid && this.clientSiteForm.value) {
			socialAccountData = this.clientSiteForm.value;
			if (this.accountsDetails && this.accountsDetails.id) {
				socialAccountData['whatsapp_number'] = this.accountsDetails.whatsapp_number ? this.accountsDetails.whatsapp_number : '';
			}
		}
		var socialAccountDataUpdate = [];
		if (this.service_provider == 'option-3') {
			socialAccountDataUpdate['account_name'] = this.socialAccountSource ? this.socialAccountSource.data.numberInfo[0].fullinfo.name : '';
			socialAccountDataUpdate['token'] = this.socialAccountSource ? this.socialAccountSource.data.access_key.access_token : '';
			socialAccountDataUpdate['whatsapp_number'] = display_phone_number;
			socialAccountDataUpdate['consumer_key'] = this.socialAccountSource ? this.socialAccountSource.data.numberInfo[0].fullinfo.id : '';
			socialAccountDataUpdate['consumer_secret'] = this.socialAccountNumber ? this.socialAccountNumber['id'] : '';
		}
		if (this.service_provider == 'option-4') {
			socialAccountDataUpdate['account_name'] = socialAccountData ? socialAccountData.whatsapp_number : '';
			socialAccountDataUpdate['whatsapp_number'] = socialAccountData ? socialAccountData.whatsapp_number : '';
			socialAccountDataUpdate['consumer_key'] = socialAccountData ? socialAccountData.whatsapp_number : '';
			socialAccountDataUpdate['namespace'] = socialAccountData ? socialAccountData.namespace : '';
			socialAccountDataUpdate['consumer_secret'] = socialAccountData ? socialAccountData.channelID : '';
		}
		if (this.service_provider == 'option-2') {
			socialAccountDataUpdate['account_name'] = socialAccountData ? socialAccountData.whatsapp_number : '';
			socialAccountDataUpdate['whatsapp_number'] = socialAccountData ? socialAccountData.whatsapp_number : '';
			socialAccountDataUpdate['consumer_key'] = socialAccountData ? socialAccountData.whatsapp_number : '';
			socialAccountDataUpdate['namespace'] = socialAccountData ? socialAccountData.namespace : '';
			socialAccountDataUpdate['consumer_secret'] = socialAccountData ? socialAccountData.channelID : '';
			socialAccountDataUpdate['username'] = socialAccountData ? socialAccountData.username : '';
			socialAccountDataUpdate['endpoint'] = socialAccountData ? socialAccountData.endpoint : '';
			socialAccountDataUpdate['password'] = socialAccountData ? socialAccountData.password : '';
		}
		var data = {
			"site_id": this.siteId,
			"account_name": socialAccountDataUpdate ? socialAccountDataUpdate['account_name'] : '',
			"token": socialAccountDataUpdate ? socialAccountDataUpdate['token'] : '',
			"token_secret": "",
			"apikey": "",
			"service_provider": this.service_provider,
			"page_id": '',
			"whatsapp_number": socialAccountDataUpdate ? socialAccountDataUpdate['whatsapp_number'] : '',
			"uname": socialAccountDataUpdate ? socialAccountDataUpdate['username'] : '',
			"password": socialAccountDataUpdate ? socialAccountDataUpdate['password'] : '',
			"endpoint": socialAccountDataUpdate ? socialAccountDataUpdate['endpoint'] : '',
			"expired_at": "",
			"consumer_key": socialAccountDataUpdate ? socialAccountDataUpdate['consumer_key'] : '',
			"consumer_secret": socialAccountDataUpdate ? socialAccountDataUpdate['consumer_secret'] : '',
			"namespace": '',
			"whatsappNamespace": socialAccountDataUpdate ? socialAccountDataUpdate['namespace'] : '',
			"json": {
				"whatsappEmail": this.profileDataView ? this.profileDataView.whatsappEmail : '',
				"whatsappAddress": this.profileDataView ? this.profileDataView.whatsappAddress : '',
				"whatsappDescription": this.profileDataView ? this.profileDataView.whatsappDescription : '',
				"whatsappAbout": this.profileDataView ? this.profileDataView.whatsappAbout : '',
				"businessVertical": this.profileDataView ? this.profileDataView.businessVertical : '',
				"whatsappWebsites": [this.profileDataView ? this.profileDataView.whatsappWebsites : ''],
				"timeZoon": this.profileDataView ? this.profileDataView.time : 'Asia/Kuwait',
				"avatar_url": ''
			}
		}
		if (this.accountsDetails && this.accountsDetails.id && !this.reAuthenticationNotification) {
			if (this.accountsDetails.whatsapp_number == socialAccountDataUpdate['whatsapp_number']) {
				data['id'] = this.accountsDetails ? this.accountsDetails.id : '';
				if (this.service_provider == 'option-4' || this.service_provider == 'option-2') {
					data['is_authorized'] = true;
				}
				let tempSub = this.commonService.storeItem('client-site/update', data, true, "optionOne").subscribe((res: any) => {
					if (res.success) {
						this.alertService.webShow('Info', 'Updated Successfully !!!');
						if (this.service_provider == 'option-3') {
							setTimeout(() => {
								this.loader = false;
								this.socialAccountVerifiedView = true;
								this.socialAccountSourceView = false;
							}, 100);
						} else if (this.service_provider == 'option-4' || this.service_provider == 'option-2') {
							this.matDialogRef.close('360');
							this.accountMode = true;
							this.viaMomento = false;
							this.viaMomentoWati = false;
							this.socialAccountSourceView = false;
							this.socialAccountVerifiedView = false;
						}
					} else {
						this.alertService.webErrorShow(res);
						this.loader = false;
						if (this.service_provider == 'option-3') {
							this.accountMode = true;
							this.newAccountMode = true;
						}
						if (this.service_provider == 'option-2') {
							this.viaMomentoWati = true;
							this.newAccountMode = true;
							this.accountMode = false;
						}
						if (this.service_provider == 'option-4') {
							this.viaMomento = true;
							this.newAccountMode = true;
							this.accountMode = false;
						}
					}
					tempSub.unsubscribe();
				}, error => {
					this.loader = false;
					this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
					console.error('There was an error!', error);
					tempSub.unsubscribe();
				})
			} else {
				this.loader = false;
				this.alertService.webShow('Danger', 'Your Hosting change field number not match, Please create new account !!!');
				this.accountMode = false;
				this.newAccountMode = false;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.updateAccountMode = true;
				this.socialAccountNotVerified = true;
			}
		} else if (this.accountsDetails && this.accountsDetails.id && this.reAuthenticationNotification) {
			if (this.accountsDetails.whatsapp_number) {
				if (this.eventData.data && this.eventData.data.access_key && this.eventData.data.access_key.access_token) {
					var access_token = this.eventData.data.access_key.access_token ? this.eventData.data.access_key.access_token : ''
					var datas = {
						"site_id": this.siteId,
						"account_name": this.accountsDetails ? this.accountsDetails['account_name'] : '',
						"token": access_token,
						"service_provider": this.accountsDetails ? this.accountsDetails['service_provider'] : '',
						"whatsapp_number": this.accountsDetails ? this.accountsDetails['whatsapp_number'] : '',
						"uname": this.accountsDetails ? this.accountsDetails['username'] : '',
						"password": this.accountsDetails ? this.accountsDetails['password'] : '',
						"endpoint": this.accountsDetails ? this.accountsDetails['endpoint'] : '',
						"consumer_key": this.accountsDetails ? this.accountsDetails['consumer_key'] : '',
						"consumer_secret": this.accountsDetails ? this.accountsDetails['consumer_secret'] : '',
						"whatsappNamespace": this.accountsDetails ? this.accountsDetails['namespace'] : '',
					}
					this.service_provider = 'option-3'
					datas['id'] = this.accountsDetails ? this.accountsDetails.id : '';
					if (this.service_provider == 'option-3' || this.service_provider == 'option-2') {
						datas['is_authorized'] = true;
						// datas['token'] = true;
					}
					this.accountMode = true;
					this.newAccountMode = false;
					this.reAuthentication = false;
					let tempSub = this.commonService.storeItem('client-site/update', datas, true, "optionOne").subscribe((res: any) => {
						if (res.success) {
							this.alertService.webShow('Info', res.data.message);
							if (this.service_provider == 'option-3') {
								this.loader = false;
								this.matDialogRef.close('done')
								this.newAccountMode = false;
								this.accountMode = false;
								this.viaMomento = false;
								this.viaMomentoWati = false;
								this.socialAccountSourceView = false;
								this.socialAccountVerifiedView = false;
								this.updateAccountMode = false;
								this.reAuthentication = false;

							} else if (this.service_provider == 'option-4' || this.service_provider == 'option-2') {
								this.matDialogRef.close('360');
								this.accountMode = true;
								this.viaMomento = false;
								this.viaMomentoWati = false;
								this.socialAccountSourceView = false;
								this.socialAccountVerifiedView = false;
								this.clientSiteService.getAllItems();
								this.siteService.getAllItems();
							}
						} else {
							this.loader = false;
							this.alertService.webErrorShow(res);
							if (this.service_provider == 'option-3') {
								this.accountMode = true;
								this.newAccountMode = false;
							}
							if (this.service_provider == 'option-2') {
								this.viaMomentoWati = true;
								this.newAccountMode = true;
								this.accountMode = false;
							}
							if (this.service_provider == 'option-4') {
								this.viaMomento = true;
								this.newAccountMode = true;
								this.accountMode = false;
							}
						}
						tempSub.unsubscribe();
					}, error => {
						this.loader = false;
						this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
						console.error('There was an error!', error);
						tempSub.unsubscribe();
					})
				} else {
					this.loader = false;
					this.alertService.webShow('Danger', this.eventData.data.error);
					this.accountMode = false;
					this.newAccountMode = false;
					this.viaMomento = false;
					this.viaMomentoWati = false;
					this.socialAccountSourceView = false;
					this.socialAccountVerifiedView = false;
					this.updateAccountMode = true;
					this.socialAccountNotVerified = true;
				}

			} else {
				this.alertService.webShow('Danger', 'Your Hosting change field number not match, Please create new account !!!');
				this.accountMode = false;
				this.newAccountMode = false;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.updateAccountMode = true;
				this.socialAccountNotVerified = true;
				this.loader = false;
			}
		} else {
			let tempSub = this.commonService.storeItem('client-site/add', data, true, "optionOne").subscribe((res: any) => {
				if (res.success) {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
					this.clientSiteService.getAllItems();
					this.siteService.getAllItems();
					if (this.service_provider == 'option-3') {
						setTimeout(() => {
							this.loader = false;
							this.socialAccountVerifiedView = true;
							this.socialAccountSourceView = false;
						}, 100);
					} else if (this.service_provider == 'option-4' || this.service_provider == 'option-2') {
						this.loader = false;
						this.matDialogRef.close('360');
						this.accountMode = true;
						this.viaMomento = false;
						this.viaMomentoWati = false;
						this.socialAccountSourceView = false;
						this.socialAccountVerifiedView = false;
					}
				} else {
					this.loader = false;
					this.alertService.webErrorShow(res);
					if (this.service_provider == 'option-3') {
						this.accountMode = false;
						this.newAccountMode = true;
					}
					if (this.service_provider == 'option-2') {
						this.viaMomentoWati = true;
						this.newAccountMode = true;
						this.accountMode = false;
					}
					if (this.service_provider == 'option-4') {
						this.viaMomento = true;
						this.newAccountMode = true;
						this.accountMode = false;
					}
				}
				tempSub.unsubscribe();
			}, (errors) => {
				this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
				console.log(errors);
				tempSub.unsubscribe();
				this.loader = !this.loader;
				this.matDialogRef.close();
			})
		}
	}

	/** Start profile image add */
	fileSize: any;
	fileSizeInvalid: boolean = false;
	InvalidFile: boolean = false;
	invalidMessage: any;
	fileChangeEvent(e: any) {
		if (e.target.files[0].type === "image/jpg" ||
			e.target.files[0].type === "image/png" ||
			e.target.files[0].type === "image/jpeg" ||
			e.target.files[0].type === "image/svg") {
			var filesize = e.target.files[0].size;
			const fileSizeInKB = Math.round(filesize / 1024);
			this.fileSize = fileSizeInKB;
			if (fileSizeInKB <= 5120) {
				this.InvalidFile = false;
				this.fileSizeInvalid = false;
				var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
				var reader = new FileReader();
				reader.onload = this.handleReaderLoaded.bind(this);
				reader.readAsDataURL(file);
			} else {
				this.alertService.webShow('Danger', 'Please Select below 5MB');
			}
		} else {
			this.alertService.webShow('Danger', 'image format not supported please select a png, jpg, jpeg and svg file');
		}
	}

	handleReaderLoaded(e: any) {
		let reader = e.target;
		var data = {
			client_site_id: this.clientSite.id,
			base64: reader.result,
		}
		this.postProfileUrl(data);
	}
	/** End profile image add */

	/** Update Profile image api call */

	postProfileUrl(data: any) {
		let tempSub = this.commonService.storeItem('client-site/profilepic', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.getprofileImage();
				this.alertService.webShow('Info', res.data.message);
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}

	profileImages: any;
	/** Get Profile image api call*/

	getprofileImage() {
		var data = {
			client_site_id: this.clientSite.id
		}
		let tempSub = this.commonService.storeItem('client-site/viewprofilepic', data, true, "optionOne",).subscribe((res: any) => {
			if (res.success) {
				this.profileImages = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(res.data.base64));
			}
			// else {
			// 	// this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			// }
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})

	}

	whatsappEmailDate(event) {
		if (event && event.target && event.target.value.length > 0) {
			this.profileUpdateForm.get('whatsappEmail').setValidators([Validators.required, Validators.maxLength(30), Validators.minLength(5), Validators.pattern(this.emailPattern)],);
			this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
		} else {
			this.profileUpdateForm.get('whatsappEmail').clearValidators();
			this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
		}
	}

	/** Update Profile Data */
	profileData() {
		// var data = this.profileUpdateForm.value;
		if (!this.profileUpdateForm.controls['whatsappEmail'].value) {
			this.profileUpdateForm.get('whatsappEmail').clearValidators();
			this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
		} else {
			this.profileUpdateForm.get('whatsappEmail').setValidators([Validators.required, Validators.maxLength(30), Validators.minLength(5), Validators.pattern(this.emailPattern)],);
			this.profileUpdateForm.get('whatsappEmail').updateValueAndValidity();
		}
		if (this.profileUpdateForm.invalid) {
			return;
		}
		var data = {
			"whatsappEmail": this.profileUpdateForm.controls['whatsappEmail'].value,
			"whatsappAddress": this.profileUpdateForm.controls['whatsappAddress'].value,
			"whatsappDescription": this.profileUpdateForm.controls['whatsappDescription'].value,
			"whatsappAbout": this.profileUpdateForm.controls['whatsappAbout'].value,
			"businessVertical": this.profileUpdateForm.controls['businessVertical'].value,
			"whatsappWebsites": [],
			"client_site_id": this.clientSite.id
		}
		data.whatsappWebsites.push(this.profileUpdateForm.controls['whatsappWebsites'].value);
		let tempSub = this.commonService.storeItem('client-site/profileData', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
				if (res.data && res.data.message) {
					this.matDialogRef.close('updateProfile');
					this.alertService.webShow('Info', res.data.message);
				} else {
					this.matDialogRef.close('updateProfile');
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				}
				this.matDialogRef.close('updateProfile');
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}

	isOnlinePayment: boolean = false;

	/**  Profile Data */
	getProfileData() {
		var data = {
			client_site_id: this.clientSite.id
		}
		let tempSub = this.commonService.storeItem('client-site/profileData', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.profileDataView = res.data;

				if (this.profileDataView.time == undefined) {
					this.profileDataView['time'] = 'Asia/Kuwait';
				}

				if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.default_language) {
					this.selectedLanguage = this.profileDataView.mongo.settings.default_language
				} else {
					this.selectedLanguage = "unknown";
				}

				if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_pay_module) {
					this.selectedModule = this.profileDataView.mongo.settings.aicommerce_pay_module
				} else {
					this.selectedModule = "unknown";
				}

				if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_assign_team) {
					this.selectedTeam = this.profileDataView.mongo.settings.aicommerce_assign_team
				} else {
					this.selectedTeam = "unknown";
				}

				if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_greetings) {
					this.greetingsData = this.profileDataView.mongo.settings.aicommerce_greetings
				} else {
					this.greetingsData = "";
				}

				if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.settings && this.profileDataView.mongo.settings.aicommerce_automation) {
					if (this.profileDataView["mongo"]["settings"]["aicommerce_automation"] == 1) {
						this.is_aicommerce_automation = true;
					} else {
						this.is_aicommerce_automation = false;
					}
				} else {
					this.is_aicommerce_automation = false;
				}

				if (this.profileDataView.AvailablePaymentMethods && this.profileDataView.AvailablePaymentMethods.length > 0) {
					this.profileDataView.AvailablePaymentMethods.forEach(paymentMethods => {
						paymentMethods['is_enabled'] = false;
						if (this.profileDataView.mongo && this.profileDataView.mongo.paymentMthods && this.profileDataView.mongo.paymentMthods.length > 0) {
							this.profileDataView.mongo.paymentMthods.forEach(payment => {
								if (payment == paymentMethods.id) {
									paymentMethods['is_enabled'] = true;
								}
							});
						}
					});

					var paymentData = this.profileDataView.AvailablePaymentMethods.filter(e => e.id == 2);
					if (paymentData && paymentData.length > 0 && paymentData[0].is_enabled) {
						this.isOnlinePayment = true;
					} else {
						this.isOnlinePayment = false;
					}
				}

				if (this.profileDataView.AvailableGovernarates && this.profileDataView.AvailableGovernarates.length > 0) {
					this.profileDataView.AvailableGovernarates.forEach(element => {
						element.price = 0;
						if (this.profileDataView.mongo && this.profileDataView.mongo.deliveryInfo && this.profileDataView.mongo.deliveryInfo.length > 0) {
							this.profileDataView.mongo.deliveryInfo.forEach(deliverySetting => {
								if (element._id == deliverySetting.governorate_id) {
									element.client_id = deliverySetting.client_id;
									element.currency = deliverySetting.currency;
									element.governorate_id = deliverySetting.governorate_id;
									element.is_delivarable = deliverySetting.is_delivarable;
									element.price = deliverySetting.price ? deliverySetting.price : 0;
									element.updated_at = deliverySetting.updated_at;
									element.deliveryId = deliverySetting._id;
									element.isEditable = false;
								}
							});
						} else {
							element.is_delivarable = false;
						}

					});
				}

				if (this.profileDataView) {
					this.verticals = this.profileDataView.allverticals;
					/** profile set value */
					var webAddress: any;
					var timeZoons: any = 'Asia/Kuwait';
					if (this.profileDataView.whatsappWebsites && this.profileDataView.whatsappWebsites.length > 0) {
						webAddress = this.profileDataView.whatsappWebsites[0];
					}
					if (this.profileDataView.time) {
						timeZoons = this.profileDataView.time;
					}
					this.profileUpdateForm.setValue({
						businessVertical: this.profileDataView ? this.profileDataView.businessVertical : '',
						whatsappAddress: this.profileDataView ? this.profileDataView.whatsappAddress : '',
						whatsappEmail: this.profileDataView ? this.profileDataView.whatsappEmail : '',
						whatsappWebsites: webAddress ? webAddress : [],
						whatsappAbout: this.profileDataView ? this.profileDataView.whatsappAbout : '',
						whatsappDescription: this.profileDataView ? this.profileDataView.whatsappDescription : '',
						timeZoon: timeZoons,
					})
					this.cd.detectChanges();
				}
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}
	/** Change COnfiguration */
	selectedService_provider: any;
	changeConfig() {
		this.newAccountMode = true;
		this.updateAccountMode = false;
		this.accountMode = true;
	}
	reAuthenticationCheck() {
		if (this.accountsDetails && this.accountsDetails.service_provider) {
			var item = this.accountsDetails.service_provider
		}
		switch (item) {
			case 'option-3':
				this.doWhatsappLogin();
				this.socialAccountActive = true;
				break;
			case 'option-4':
				this.accountMode = false;
				this.newAccountMode = true;
				this.viaMomento = true;
				this.updateAccountMode = false;
				setTimeout(() => {
					this.rightIn();
				}, 10);
				this.clientSiteForm = this.createForm('option-4');
				this.service_provider = 'option-4'
				if (this.accountsDetails && this.accountsDetails.id) {
					this.clientSiteForm.controls['whatsapp_number'].disable();
					this.clientSiteForm.setValue({
						whatsapp_number: this.accountsDetails.whatsapp_number ? this.accountsDetails.whatsapp_number : '',
						channelID: this.accountsDetails.consumer_secret ? this.accountsDetails.consumer_secret : '',
						namespace: this.accountsDetails.whatsappNamespace ? this.accountsDetails.whatsappNamespace : '',
					})
				}
				break;
			case 'option-2':
				this.accountMode = false;
				this.newAccountMode = true;
				this.viaMomentoWati = true;
				this.updateAccountMode = false;
				setTimeout(() => {
					this.rightIn();
				}, 10);
				this.clientSiteForm = this.createForm('option-2');
				this.service_provider = 'option-2'
				if (this.accountsDetails && this.accountsDetails.id) {
					this.clientSiteForm.controls['whatsapp_number'].disable();
					this.clientSiteForm.setValue({
						whatsapp_number: this.accountsDetails.whatsapp_number ? this.accountsDetails.whatsapp_number : '',
						endpoint: this.accountsDetails.endpoint ? this.accountsDetails.endpoint : '',
						username: this.accountsDetails.uname ? this.accountsDetails.uname : '',
						password: this.accountsDetails.password ? this.accountsDetails.password : '',
						namespace: this.accountsDetails.whatsappNamespace ? this.accountsDetails.whatsappNamespace : '',
					})
				}
				break;
			default:
				break;
		}
	}
	selectionLocation(e = null) {
		if (e) {
			var data = {
				"client_site_id": this.clientSite.id,
				"time": e.country,
				"zone": e.timezoon
			}
			var url = 'client-site/settings'
			this.clientSiteService.updateSettings(data, url)
		}
	}
	clearTimeZone() {
		this.profileUpdateForm.controls.timeZoon.reset();
	}
	accountData: string = '';
	socialSettingsUpdate() {
		this.timeZoon.value
		var data = {
			"client_site_id": this.clientSite.id,
			"account_name": this.accountData
		}

		let tempSub = this.commonService.storeItem('client-site/settings', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.clientSiteService.getAllItems();
				this.matDialogRef.close('updateSettings');
				this.alertService.webShow('Info', 'Updated Successfully !!!');
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}

	cartActive(e) {
		var data = {
			is_cart_enabled: e.checked ? 'yes' : 'no',
			"client_site_id": this.clientSite.id
		}
		this.ecommereUpdate(data);
	}

	catlogActive(e) {
		var data = {
			is_catalog_visible: e.checked ? 'yes' : 'no',
			"client_site_id": this.clientSite.id
		}
		this.ecommereUpdate(data);
	}

	isAutoActive(e) {
		var data = {
			aicommerce_automation: e.checked ? 'yes' : 'no',
			"client_site_id": this.clientSite.id
		}
		this.ecommereAutoUpdate(data);
	}

	updateLanguage(value) {
		var data = {
			language: value,
			"client_site_id": this.clientSite.id
		}
		this.ecommereAutoUpdate(data);
	}


	updateModule(value) {
		var data = {
			aicommerce_pay_module: value,
			"client_site_id": this.clientSite.id
		}
		this.ecommereAutoUpdate(data);
	}

	ecommereAutoUpdate(data) {
		let tempSub = this.commonService.storeItem('client-site/setVals', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				// if (this.profileDataView && this.profileDataView.settings && this.profileDataView.settings.default_language) {
				// 	this.selectedLanguage = this.profileDataView.settings.default_language
				// }
				if (data && data.aicommerce_automation) {
					this.is_aicommerce_automation = data.aicommerce_automation ? true : false;;
				} else {
					this.is_aicommerce_automation = false;
				}

				if (res.data && res.data && res.data.settings && res.data.settings.aicommerce_automation) {
					if (res.data["settings"]["aicommerce_automation"] == 1) {
						this.is_aicommerce_automation = true;
					} else {
						this.is_aicommerce_automation = false;
					}
				} else {
					this.is_aicommerce_automation = false;
				}

				if (res.data && res.data && res.data.settings && res.data.settings.default_language) {
					this.selectedLanguage = res.data.settings.default_language
				}

				if (res.data && res.data && res.data.settings && res.data.settings.aicommerce_pay_module) {
					this.selectedModule = res.data.settings.aicommerce_pay_module
				}


				if (res.data && res.data.message) {
					this.alertService.webShow('Info', res.data.message);
				} else {
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				}
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}



	ecommereUpdate(data) {
		let tempSub = this.commonService.storeItem('client-site/profileData', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.accountMode = true;
				this.viaMomento = false;
				this.viaMomentoWati = false;
				this.socialAccountSourceView = false;
				this.socialAccountVerifiedView = false;
				this.socialAccountNotVerified = false;
				if (res.data && res.data.message) {
					this.matDialogRef.close('updateProfile');
					this.alertService.webShow('Info', res.data.message);
				} else {
					this.matDialogRef.close('updateProfile');
					this.alertService.webShow('Info', 'Updated Successfully !!!');
				}
				this.matDialogRef.close('updateProfile');
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}



	paymentStatusUpdate(e, paymentMode = null) {
		var status: any = {
			"client_site_id": this.clientSite.id,
			"paymentMethods": [],
		};
		if (this.profileDataView && this.profileDataView.mongo && this.profileDataView.mongo.paymentMthods && this.profileDataView.mongo.paymentMthods.length > 0) {
			const isInArray = this.profileDataView.mongo.paymentMthods.includes(paymentMode.id);
			if (isInArray) {
				var index = this.profileDataView.mongo.paymentMthods.indexOf(paymentMode.id);
				if (index !== -1) {
					this.profileDataView.mongo.paymentMthods.splice(index, 1);
				}
				status["paymentMethods"] = this.profileDataView.mongo.paymentMthods;
			} else {
				this.profileDataView.mongo.paymentMthods.push(paymentMode.id);
				status["paymentMethods"] = this.profileDataView.mongo.paymentMthods;
			}
		} else {
			status["paymentMethods"].push(paymentMode.id);
		}

		this.updatePaymentStatus(status)
	}

	updatePaymentStatus(data) {
		let tempSub = this.commonService.storeItem('deliverySettings/updatePaymentsMethod', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.profileDataView.mongo = res.data;
				if (this.profileDataView.AvailablePaymentMethods && this.profileDataView.AvailablePaymentMethods.length > 0) {
					this.profileDataView.AvailablePaymentMethods.forEach(paymentMethods => {
						paymentMethods['is_enabled'] = false;
						if (this.profileDataView.mongo && this.profileDataView.mongo.paymentMthods && this.profileDataView.mongo.paymentMthods.length > 0) {
							this.profileDataView.mongo.paymentMthods.forEach(payment => {
								if (payment == paymentMethods.id) {
									paymentMethods['is_enabled'] = true;
								}
							});
						}
					});

					var paymentData = this.profileDataView.AvailablePaymentMethods.filter(e => e.id == 2);
					if (paymentData && paymentData.length > 0 && paymentData[0].is_enabled) {
						this.isOnlinePayment = true;
					} else {
						this.isOnlinePayment = false;
					}

				}
				this.alertService.webShow('Info', 'Updated Successfully !!!');
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}


	deliveryStatus(data, event) {
		if (event.checked) {
			var setting = {
				"governorate_id": data._id,
				"is_delivarable": "yes",
				"price": data.price ? data.price : 0,
				"client_site_id": this.clientSite.id
			}
			// this.deliverySettingsService.update('deliverySettings/add', setting);
			this.deliverySettings(setting, 'status');
		} else {
			var setting = {
				"governorate_id": data._id,
				"is_delivarable": "no",
				"price": data.price ? data.price : 0,
				"client_site_id": this.clientSite.id
			}
			// this.deliverySettingsService.update('deliverySettings/add', setting);
			this.deliverySettings(setting, 'status');
		}
	}



	updatePrice: any;
	priceUpdate(action, data = null) {
		if (action == 'edit') {
			this.profileDataView.AvailableGovernarates.forEach(price => {
				if (price._id == data._id) {
					price.isEditable = true;
				} else {
					price.isEditable = false;
				}
			});
		}
		if (action == 'remove') {
			this.profileDataView.AvailableGovernarates.forEach(price => {
				price.isEditable = false;
			});
		}
		if (action == 'update') {
			var setting = {
				"governorate_id": data._id,
				"is_delivarable": data.is_delivarable ? "yes" : "no",
				"price": this.updatePrice,
				"client_site_id": this.clientSite.id
			}
			this.deliverySettings(setting, "price");
		}
	}

	saveSetting(data, price) {
		this.updatePrice = price;
	}

	deliverySettings(data, status = null) {
		let tempSub = this.commonService.storeItem('deliverySettings/add', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				if (status == "price") {
					this.profileDataView.AvailableGovernarates.forEach(price => {
						if (data.governorate_id == price._id) {
							price.isEditable = false;
							price.price = data.price;
						}
					});
				}
				if (status == "status") {
					this.profileDataView.AvailableGovernarates.forEach(price => {
						if (data.governorate_id == price._id) {
							price.isEditable = false;
							if (data.is_delivarable == "yes") {
								price.is_delivarable = true;
							}
							if (data.is_delivarable == "no") {
								price.is_delivarable = false;
							}
						}
					});
				}
				this.alertService.webShow('Info', 'Updated Successfully !!!');
			} else {
				this.alertService.webErrorShow(res);
			}
			tempSub.unsubscribe();
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}


	teamsSettingsUpdate() {
		this.timeZoon.value
		var data = {
			"client_site_id": this.clientSite.id,
		}
		if (this.selectedTeam && this.selectedTeam.length > 0) {
			data["aicommerce_assign_team"] = this.selectedTeam;
		} else {
			delete data["aicommerce_assign_team"];
		}
		if (this.greetingsData && this.greetingsData.length > 0) {
			data["aicommerce_greetings"] = this.greetingsData;
		} else {
			delete data["aicommerce_greetings"];
		}

		let tempSub = this.commonService.storeItem('client-site/setVals', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				// this.clientSiteService.getAllItems();
				// this.matDialogRef.close('updateSettings');
				if (res.data && res.data && res.data.settings && res.data.settings.aicommerce_greetings) {
					this.greetingsData = res.data.settings.aicommerce_greetings
				}

				if (res.data && res.data && res.data.settings && res.data.settings.aicommerce_assign_team) {
					this.selectedTeam = res.data.settings.aicommerce_assign_team
				}

				this.alertService.webShow('Info', 'Updated Successfully !!!');
			} else {
				this.alertService.webErrorShow(res);
			}
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})
	}


	cloudOnpordClientData: any;
	cloudOnpordClientView: boolean = false;
	octopusOnpordClient(data) {
		this.loader = true;
		let tempSub = this.commonService.storeItem('client-site/facebookAuth', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				if (res.data && res.data.numberInfo) {
					this.accountMode = false;
					this.cloudOnpordClientView = true;
					this.cloudOnpordClientData = res;
					delete this.results
					this.isWhatsAppSignup = false;
					this.loader = false;
				} else {
					this.alertService.webShow('Danger', 'client numbers are not available. Try after Sometimes !!!');
				}

			} else {
				this.loader = false;
				this.alertService.webErrorShow(res);
			}
		}, error => {
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			this.loader = false;
			tempSub.unsubscribe();
		})

	}

	octopusWebHoks() {
		this.loader = true;

		if (this.socialAccountNumber && this.socialAccountNumber['display_phone_number']) {
			var number = this.socialAccountNumber['display_phone_number']
			var phoneNumber = number.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
			var display_phone_number = phoneNumber.split(' ').join('');
		}

		var data = {
			whatsapp_number: display_phone_number,
			"service_provider": "option-7",
			"site_id": "2",
		}

		if (this.cloudOnpordClientData && this.cloudOnpordClientData.data && this.cloudOnpordClientData.data.numberInfo && this.cloudOnpordClientData.data.numberInfo[0]) {
			data["namespace"] = this.cloudOnpordClientData.data.numberInfo[0].fullinfo.message_template_namespace;
			data["account_name"] = this.cloudOnpordClientData.data.numberInfo[0].fullinfo.name;
			data["waba_id"] = this.cloudOnpordClientData.data.numberInfo[0].fullinfo.id;
			data["consumer_key"] = this.cloudOnpordClientData.data.numberInfo[0].fullinfo.id;
			data['consumer_secret'] = this.socialAccountNumber ? this.socialAccountNumber['id'] : '';
		}

		if (this.cloudOnpordClientData && this.cloudOnpordClientData.data && this.cloudOnpordClientData.data.access_key) {
			data["token"] = this.cloudOnpordClientData.data.access_key.access_token;
		}

		
		console.log('data', data);

		let tempSub = this.commonService.storeItem('client-site/add', data, true, "optionOne").subscribe((res: any) => {
			if (res.success) {
				this.loader = false;
				this.clientSiteService.getAllItems();
				this.alertService.webShow('success', res.message);
				this.matDialogRef.close('done')
			} else {
				this.loader = false;
				this.alertService.webErrorShow(res);
			}
		}, error => {
			this.loader = false;
			this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
			console.error('There was an error!', error);
			this.invalidMessage = error.error.message;
			tempSub.unsubscribe();
		})

	}
	results: any;

	facebookLogin() {
		launchWhatsAppSignup()
		this.longLive();

	}

	isWhatsAppSignup: boolean = false;
	longLive() {
		this.results = facebookAccessCode;
		facebookAccessCode = '';
		if (this.results && this.results.code) {
			this.octopusOnpordClient(this.results)
			this.isWhatsAppSignup = true;
		}
		if (!this.isWhatsAppSignup) {
			setTimeout(() => {
				this.longLive();
			}, 5);
		}
	}
}


export interface Hero {
	id: number;
	name: string;
}

var facebookAccessCode: any;
function launchWhatsAppSignup() {
	let responseData = {}
	let isResponseData = false;

	window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '192057255354059', feature: 'whatsapp_embedded_signup' });
	// Launch Facebook login
	FB.login(function (response) {

		if (response.authResponse) {
			const code = response.authResponse.code;
			const accessToken = response.authResponse.code;
			if (response && response.authResponse && response.authResponse.code) {
				;
				var data = {
					code: response.authResponse.code,
				}
				facebookAccessCode = data;
				responseData = data;
				isResponseData = true;
			} else {
				responseData = {};
				isResponseData = false;
			}
		} else {
			responseData = {};
			isResponseData = false;
		}
	}, {
		config_id: "1561759511273381", //this.techProvider.config_id, // configuration ID goes here
		response_type: 'code',    // must be set to 'code' for System User access token
		override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
		extras: {
			setup: {
				solutionID: "2568333420016747", //this.techProvider.solutionid, // Prefilled data can go here
			}
		}
	});
}
