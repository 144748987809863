import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import {
  User, UserService, catalogProduct, ProductModel, CatalogProductService, AlertService, CommonService
} from 'src/app/@tji/_dbShare';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'category-product-list',
  templateUrl: './category-product-list.component.html',
  styleUrls: ['./category-product-list.component.scss']
})
export class CategoryProductListComponent {

  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;
  productLists: catalogProduct[] = [];
  productListsDefault: catalogProduct[] = [];
  param: any;
  usageParam: any;
  dataSource = new MatTableDataSource(this.productLists);
  displayedColumns = ['image', 'name', 'price', 'currency', 'action'];
  intractiveSearch: any = "";
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;

  addProductForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    sellingprice: new UntypedFormControl(''),
    currency_code: new UntypedFormControl(''),
    group_id: new UntypedFormControl(''),
    content_id: new UntypedFormControl('')
  });

  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    public catalogProductService: CatalogProductService,
    public matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private alertService: AlertService,
    private orderByPipe: OrderByPipe) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.catalogProductService.allIntractiveProductItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) { this.subLoader = false; }
      this.productLists = data ? data : [];
      this.dataLength = data ? data.length : 0;
    });
    // this.catalogProductService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {
    //     this.dataLength = data;
    // });
  }

  ngOnInit(): void {
    this.setSubLoader();
    this.dataInit();
    var number = /^[0-9]*\.?[0-9]*$/;
    this.addProductForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(200),],],
      price: ['', [Validators.required, Validators.pattern(number), Validators.minLength(1), Validators.maxLength(30)],],
      currency_code: ['', Validators.required],
      group_id: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60),],],
      sellingprice: ['', [Validators.pattern(number), Validators.minLength(1), Validators.maxLength(30)],],
      // sellingprice: ['', [Validators.required, Validators.pattern(number), Validators.minLength(1), Validators.maxLength(30)],],
      content_id: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100),],],
    })

  }
  /** On destroy */
  ngOnDestroy(): void {

  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }
  dataInit() {
    this.catalogProductService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
    // this.catalogProductService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }
  /* * * * *
  * search
  * * * * * */

  searchProductData() {
    this.setSubLoader();
    this.catalogProductService.onSearchProduct(this.intractiveSearch)
    // var filterData = [];
    // var productDataSearch: any = [];
    // var filterInteractiveKeys = ['name']
    // productDataSearch = this.productListsDefault;
    // if (this.intractiveSearch === '') {
    //   filterData = null;
    //   productDataSearch = this.productListsDefault;
    //   this.productLists = productDataSearch;
    //   // this.productService.searchIdentity = false;
    // } else {
    //   if (productDataSearch.length > 0) {
    //     // this.catalogProductService.searchIdentity = true;
    //     for (let i = 0; i < productDataSearch.length; i++) {
    //       if (filterInteractiveKeys.length > 0) {
    //         filterInteractiveKeys.forEach((key) => {
    //           if (typeof productDataSearch[i][key] === 'string' && typeof this.intractiveSearch === 'string') {
    //             if (productDataSearch[i][key].toLowerCase().indexOf(this.intractiveSearch.toLowerCase()) > -1) {
    //               const found = filterData.some(el => el._id === productDataSearch[i]._id);
    //               if (!found) {
    //                 filterData.push(productDataSearch[i]);
    //               }
    //             }
    //           }
    //         });
    //       } else {
    //         if (productDataSearch[i].name.toLowerCase().indexOf(this.intractiveSearch.toLowerCase()) > -1) {
    //           filterData.push(productDataSearch[i]);
    //         }
    //       }
    //     }
    //   }
    //   this.productLists = filterData;
    // }
  }


  searchClear() {
    this.intractiveSearch = "";
    // this.productLists = this.productListsDefault;
    this.setSubLoader();
    this.catalogProductService.onSearchProduct(this.intractiveSearch)
    // this.productService.searchIdentity = false;
  }
  // deleteProduct(id: number) {

  // 	// 	let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
  // 	// 	disableClose: false,
  // 	// 	width: '400',
  // 	// 	data: {
  // 	// 		type: 'delete',
  // 	// 		message: 'Are you sure you want to delete?',
  // 	// 	}
  // 	// });
  // 	// confirmDialogRef.afterClosed().subscribe(result => {
  // 	// 	if (result && result.data) {
  // 	// 		var data = {
  // 	// 			id: socialSite.id
  // 	// 		}
  // 	// 		this.clientSiteService.deleteClientSite(data, 'client-site/delete');
  // 	// 	}
  // 	// });
  //   this.catalogProductService.destroyItems(id, 'eproduct/delete');
  // }
  productPanel: boolean = false;
  submitted: boolean = false;
  fileSize: any;
  fileSizeInvalid: boolean = false;
  invalidMessage: any;
  invalidFile: boolean = false;
  addProduct() {
    this.productPanel = true;
    this.submitted = false;
    delete this.productId;
    this.addProductForm.reset();
    // delete this.imageUrl;
    this.addProductForm.controls.currency_code.setValue('');
  }
  cancelProduct() {
    this.productPanel = false;
  }
  get f(): { [key: string]: AbstractControl } {
    return this.addProductForm.controls;
  }

  // fileChangeEvent(e: any) {
  //   if (e.target.files[0].type === "image/jpeg" ||
  //     e.target.files[0].type === "image/jpg" ||
  //     e.target.files[0].type === "image/png") {
  //     var filesize = e.target.files[0].size;
  //     const fileSizeInKB = Math.round(filesize / 1024);
  //     this.fileSize = fileSizeInKB;
  //     if (fileSizeInKB <= 1024) {
  //       this.invalidFile = false;
  //       this.fileSizeInvalid = false;
  //       var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  //       var reader = new FileReader();
  //       reader.onload = this.handleReaderLoaded.bind(this);
  //       reader.readAsDataURL(file);
  //     } else {
  //       this.fileSizeInvalid = true;
  //       this.addProductForm.controls.image.reset();
  //     }
  //   } else {
  //     this.invalidFile = true;
  //     this.addProductForm.controls.image.reset();
  //   }
  // }
  // imageUrl: string;
  // handleReaderLoaded(e: any) {
  //   let reader = e.target;
  //   // var data = reader.result.substring(reader.result.indexOf(",") + 1)
  //   var data = reader.result;
  //   this.imageUrl = data;
  //   // this.addProductForm.controls.image.setValue(data);
  // }

  onSubmit(): void {
    this.submitted = true;
    if (this.addProductForm.invalid) {
      return;
    }
    this.addProductData();
    // this.register(JSON.stringify(this.addProductForm.value), "signup");
  }

  addProductData() {
    // var data = this.profileUpdateForm.value;
    var data = {
      "title": this.addProductForm.controls['title'].value,
      "price": this.addProductForm.controls['price'].value,
      "sellingprice": this.addProductForm.controls['sellingprice'].value,
      "currency_code": this.addProductForm.controls['currency_code'].value,
      "group_id": this.addProductForm.controls['group_id'].value,
      "content_id": this.addProductForm.controls['content_id'].value,
    }
    if (this.productId) {
      data['id'] = this.productId;
      let tempSub = this.commonService.storeItem('eproduct/update', data, true, "optionOne").subscribe((res: any) => {
        if (res.success) {
          if (res.data && res.data.message) {
            this.alertService.webShow('Info', res.data.message);
          } else {
            this.alertService.webShow('Info', 'Updated Successfully !!!');
          }
          this.productPanel = false;
          // this.productService.resetParams();
          // this.productService.getAllItems();
          this.submitted = false;
          this.addProductForm.reset();
          delete this.productId;
        } else {
          this.alertService.webErrorShow(res);
        }
        tempSub.unsubscribe();
      }, error => {
        this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        console.error('There was an error!', error);
        this.invalidMessage = error.error.message;
        tempSub.unsubscribe();
      })
    } else {
      let tempSub = this.commonService.storeItem('eproduct/add', data, true, "optionOne").subscribe((res: any) => {
        if (res.success) {
          if (res.data && res.data.message) {
            this.alertService.webShow('Info', res.data.message);
          } else {
            this.alertService.webShow('Info', 'Updated Successfully !!!');
          }
          this.productPanel = false;
          // this.productService.resetParams();
          // this.productService.getAllItems();
          this.submitted = false;
          this.addProductForm.reset();
        } else {
          this.alertService.webErrorShow(res);
        }
        tempSub.unsubscribe();
      }, error => {
        this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        console.error('There was an error!', error);
        this.invalidMessage = error.error.message;
        tempSub.unsubscribe();
      })
    }

  }
  // trashImage() {
  //   delete this.imageUrl;
  //   this.addProductForm.controls.image.reset();
  // }
  productId: any;
  updateProduct(data) {
    if (data._id) {
      this.productId = data._id;
      // this.imageUrl = data.image;
      this.addProductForm.controls.title.setValue(data ? data.title : '');
      this.addProductForm.controls.price.setValue(data ? data.price : '');
      this.addProductForm.controls.sellingprice.setValue(data ? data.sellingprice : '');
      this.addProductForm.controls.currency_code.setValue(data ? data.currency_code : '');
      this.addProductForm.controls.group_id.setValue(data ? data.group_id : '');
      // this.addProductForm.controls.image.setValue('test.png');
      this.addProductForm.controls.content_id.setValue(data ? data.content_id : '');
      this.productPanel = true;
    }
  }

  onDrop(event: any) {
    this.catalogProductService.drop(event);
  }

}
