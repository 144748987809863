import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive,
	IntractiveCategoryService, IntractiveCategory, IntractiveCategoryModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-intractiveform',
	templateUrl: './intractiveForm.component.html',
	styleUrls: ['./intractiveForm.component.scss']
})

export class IntractiveFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	itrsbot: Itrsbot;
	intractive: Intractive;
	intractiveCategories: IntractiveCategory[] = [];
	action: string;
	intractiveForm: UntypedFormGroup;
	languageList: any;
	isClone : boolean = false;
	intractiveName: string;
	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveCategoryService: IntractiveCategoryService,
		public matDialogRef: MatDialogRef<IntractiveFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.intractiveCategoryService.allILanguage.pipe(untilDestroyed(this)).subscribe(data => this.languageList = data);
		this.intractiveCategoryService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.intractiveCategories = data);
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractive = data;
			this.intractive["track_it"] = false;
			if (this.intractive && this.intractive.track_it) {
				this.intractive["track_it"] = true;
			}else {
				this.intractive["track_it"] = false;
			}
			if (this.intractive && !this.intractive.language) {
				this.intractive.language = null;
			}
			this.intractiveName = this.intractive.name;
			this.getInit(data);
			this.intractiveForm = this.createForm();
		});
		this.intractiveService.cloneItems.pipe(untilDestroyed(this)).subscribe(data => this.isClone = data);
	}

	ngOnInit() {
		this.intractiveCategoryService.getAllItems();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		if (this.isClone) {
			this.action = 'clone'
			this.intractiveName  = this.intractive.name+'_clone';
		}else{
			this.action = (data && data.id) ? 'edit' : 'new';
		}
		switch (this.action) {
			case 'new':
				this.dialogTitle = 'New Message';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Message';
				break;
			case 'clone':
				this.dialogTitle = 'Clone Template';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.intractiveName || null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
			intractive_category_id: [this.intractive.intractive_category_id || null, [Validators.required]],
			is_active: [this.intractive.is_active || true, [Validators.required]],
			resource_id: [this.intractive.resource_id || this.itrsbot.resource_id || null, [Validators.required]],
			resource_type: [this.intractive.resource_type || this.itrsbot.resource_type || null, [Validators.required]],
			itrsbot_id: [this.intractive.itrsbot_id || this.itrsbot.id || null, [Validators.required]],
			is_seperate_message: [this.intractive.is_seperate_message || null],
			language: [this.intractive.language || null],
			track_it: [this.intractive.track_it || false]
		});
	}

	onChangeCategory(categoryId) {
		let selectedCategory : IntractiveCategory = null;
		selectedCategory = (categoryId && this.intractiveCategories.length > 0) ? 
			this.intractiveCategories.filter(x=>{ return (x.id === categoryId) ? true : false; })[0] : selectedCategory;
		if(selectedCategory && selectedCategory.slug === 'live-seperate-message') {
			this.intractiveForm.patchValue({ 'is_seperate_message' : true });
		} else {
			this.intractiveForm.patchValue({ 'is_seperate_message' : null });
		}
	}
}
