export class TicketActivityModel {
	id                  : number;
	resource_id         : number;
	resource_type       : string;
	client_id           : number;
	client_site_id      : number;
	agent_id            : number;
	people_id           : number;
	event               : string;
	duration_taken      : number;
	created_by          : number;
	updated_by          : number;
	created_at          : Date;
	updated_at          : Date;

	client_site?        : any;
	client?             : any;
	resource?           : any;
	agentAvatar?        : string;
	agentName?          : string;
	clientName?         : string;
	peopleAvatar?       : string;
	peopleName?         : string;
	abandonDuration?    : number;
	ticketLifeTime?     : number;

	durationTakenString?: string;
	tableName?          : string;
	className?          : string;
	ticketCode?         : string;
	siteName            : string;
	siteIcon            : string;
	siteColor           : string;

	/**
	 * Constructor
	 *
	 * @param ticketActivity
	 */
	constructor(ticketActivity) {
		this.id            = ticketActivity.id || '';
		this.resource_id   = ticketActivity.resource_id || '';
		this.resource_type = ticketActivity.resource_type || '';
		this.client_id     = ticketActivity.client_id || '';
		this.client_site_id= ticketActivity.client_site_id || '';
		this.agent_id      = ticketActivity.agent_id || '';
		this.people_id     = ticketActivity.people_id || '';
		this.event         = ticketActivity.event || '';
		this.duration_taken= ticketActivity.duration_taken || '';
		this.created_by    = ticketActivity.created_by || '';
		this.updated_by    = ticketActivity.updated_by || '';
		this.created_at    = ticketActivity.created_at || '';
		this.updated_at    = ticketActivity.updated_at || '';

		this.tableName     = ticketActivity.tableName || '';
	}
}
