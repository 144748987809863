import { NgModule } from '@angular/core';
import { TemplateImportModule } from './template-import.module';
import { TemplateRouteModule } from './template-route.module';

@NgModule({
	declarations: [ ],
	imports: [
		TemplateImportModule,
		TemplateRouteModule,
	],
})

export class TemplateModule { }
