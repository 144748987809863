export class SocketModel {
    channelName: string;
    eventName  : string;
    uniqueData?: string;
    data?      : any;

    /**
     * Constructor
     *
     * @param socketData
     */
    constructor(socketData) {
        this.channelName = socketData.channelName || null;
        this.eventName   = socketData.eventName || null;
        this.uniqueData  = socketData.uniqueData || null;
        this.data        = socketData.data || null;
    }
}
