export var data = [
	{
		"name": "Satisifed",
		"value": 460
	},
	{
		"name": "Committed",
		"value": 157
	},
	{
		"name": "Not Satisifed",
		"value": 35
	},
	{
		"name": "Active",
		"value": 254
	},
	{
		"name": "Closed",
		"value": 745
	}
];