import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { AssignFormComponent } from './../assignForm/assignForm.component';

import { User, UserService, CommonService,
	FindChatService, ChatListsService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'find-conversation-chats',
	templateUrl: './chats.component.html',
	styleUrls: ['./chats.component.scss']
})

export class ChatsComponent implements OnInit, OnDestroy {
	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;

	loader: boolean = true;
	authUser: User;
	url: string = this.findChatService.url;
	selectedFind: any = null;
	chats: Array<any> = [];
	param: any;
	dataLength: number = 0;
	channel: any;
	base_image: string = this.commonService.logo_image;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public findChatService: FindChatService,
		public chatListsService: ChatListsService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.findChatService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.selectedFind = data;
		});
		this.chatListsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.chats = data;
		});

	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.findChatService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.url = this.findChatService.url;
		this.chatListsService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	/** Add / Update Tag */
	assignFeed(feed = null): void {
		let dialogRef = this.matDialog.open(AssignFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: this.selectedFind,
				ticketClassName: (this.selectedFind && this.selectedFind.id) ? this.selectedFind.className : null,
				process:'assign'
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						
						this.findChatService.assign(formData.value, this.selectedFind.id, 'find-conversation-process');
						break;
				}
			});
	}	



	reassignFeed(feed = null): void {
		let dialogRef = this.matDialog.open(AssignFormComponent, {
			width: "600px",
			data: {
				action: 'new',
				item: this.selectedFind,
				ticketClassName: (this.selectedFind && this.selectedFind.id) ? this.selectedFind.className : null,
				process:'reassign'
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.findChatService.assign(formData.value, this.selectedFind.id, 'find-conversation-process');
						break;
				}
			});
	}	


	/** Close Feed */
	closeFeed(feed = null): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'close',
				message: 'Are you sure want to Close this Feed ?',
				resource: feed,
				clientSiteId: (feed) ? feed.client_site_id : null,
				process:'close'
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.findChatService.close(feed.id, result.formValue, 'find-conversation-process');
			}
		});
	}

}
