import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


import {
	User, UserService,
	PermissionService, Permission, PermissionModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'managePermission-formModule',
	templateUrl: './formModule.component.html',
	styleUrls: ['./formModule.component.scss']
})
export class FormModuleComponent implements OnInit, OnDestroy {

	dialogTitle: string = 'Create';
	authUser: User;
	permission: Permission;
	allPermissions: Permission[] = [];
	action: string;
	moduleForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public permissionService: PermissionService,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<FormModuleComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.permissionService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.permission = data;
			this.moduleForm = this.createForm();
		});
		this.permissionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.allPermissions = data;
		});
	}

	ngOnInit() {

		this.moduleForm.valueChanges.subscribe(value => {

		});
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Module';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Module';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(permission: any = null): UntypedFormGroup {
		return this._formBuilder.group({
			name: [this.permission.name || null, [Validators.required, Validators.minLength(3)]],
			module: [this.permission.module || null, [Validators.required, Validators.minLength(3)]],
			permission: [null],
			guard_name: ['web'],
		});
	}

}
