import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'stringBetween',
})
export class StringBetweenPipe implements PipeTransform {
  transform(input: string, startDelimiter: string, endDelimiter: string): Array<string> {
    var contents = [];
    var startDelimiterLength = startDelimiter.length;
    var endDelimiterLength = endDelimiter.length;
    var startFrom = 0;
    var contentStart: any = 0;
    var contentEnd: any = 0;
    
    while(false !== (contentStart = this.strpos(input, startDelimiter, startFrom))) 
    {
        contentStart += startDelimiterLength;
        contentEnd = this.strpos(input, endDelimiter, contentStart);
        if(false === contentEnd){
            break;
        }
        contents.push( input.substr(contentStart, contentEnd - contentStart) );
        startFrom = contentEnd + endDelimiterLength;
    }
    return contents;
  }

  strpos(haystack, needle, offset): any {
      var i = (haystack+'').indexOf(needle, (offset || 0));
      return i === -1 ? false : i;
  }
}

@NgModule({
  declarations: [StringBetweenPipe],
  exports: [StringBetweenPipe],
})
export class NgStringBetweenPipeModule {}