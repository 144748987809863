import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, 
	CommentService, Comment,
	ChatService, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'ticket-gallery-view',
	templateUrl: './galleryView.component.html',
    styleUrls: ['./galleryView.component.scss']
})

export class GalleryViewComponent implements OnInit, OnDestroy {
	@Output() onViewGallery = new EventEmitter();
	@Input() isChatMedia: boolean = true;
    showSend: boolean = true;
	user: User;
	ticket: any = null;
	ticketClassName: string = null;
	baseImage: string = this.commonService.logo_image;

	constructor(public chatService: ChatService,
		public userService: UserService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.chatService.unSubscribeAll();
	}
}
