import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommentRouteModule } from './comment-route.module';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { CommentHistoryComponent } from './commentHistory/commentHistory.component';

import { CommentService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        CommentHistoryComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        CommentRouteModule,
        UtilsModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
    ],
    providers: [
        CommentService
    ]
})

export class CommentModule { }
