import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { PosterPreviewComponent } from './../posterPreview/posterPreview.component';
import {FormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {
	User, UserService, PosterService, PosterAsset,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
	selector: 'smm-new-poster',
	templateUrl: './newPoster.component.html',
	styleUrls: ['./newPoster.component.scss']
})

export class NewPosterComponent implements OnInit, OnDestroy {
    @ViewChild(PosterPreviewComponent) posterPreviewComponent: PosterPreviewComponent;
	teams = new UntypedFormControl();
    teamList: string[] = ['All', 'Supporting team', 'Sales team', 'Maintaing team', 'Testing team', 'HR Team']; 

    loader: boolean = true;
	user: User;
	ticket: any;
	options: UntypedFormGroup;
    hideRequiredControl = new UntypedFormControl(false);
    floatLabelControl = new UntypedFormControl('auto');

	constructor(public userService: UserService,
		public posterService: PosterService,
		private dialog: MatDialog,
		private router: Router,
		public location: Location,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.posterService.item.subscribe(data => {
			if(data && data.id) { this.loader = false; }
		})
	}

	@ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
	openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
		this.dialog.open(templateRef);
	}
	openDialogWithoutRef() {
		this.dialog.open(this.secondDialog);
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = false;
		}, 5000);
	}

    onEvent(allAssets: PosterAsset[]) {
        this.posterPreviewComponent.allAssets = allAssets;
        this.posterPreviewComponent.refreshCarousel();
    }

}
