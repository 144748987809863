import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  User, UserService,
  BranchService
} from 'src/app/@tji/_dbShare';
export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
  selector: 'app-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.scss']
})
export class MapMarkerComponent {
  dialogTitle: string = 'Select Branch Location';
  authUser: User;
  location: any = [];
  action: string;
  latitude: number = 0;
  longitude : number = 0;
  isMap: boolean = false;

  constructor(public userService: UserService,
    public branchService: BranchService,
    public matDialogRef: MatDialogRef<MapMarkerComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder) {
    this.getInit(_data);
    this.branchService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        // this.location.length
      } else {
      }
    });
    // this.branchService.getPosition().then(pos => {
    //   this.latitude = pos.lat;
    //   this.longitude = pos.lng;
    //   console.log(`Positon: ${pos.lng} ${pos.lat}`);
    // });
  }

  ngOnInit() { }

  /** On destroy */
  ngOnDestroy(): void { }

  getInit(data) {
    // Set the defaults
    this.longitude = data.item.longitude;
    this.latitude = data.item.latitude;
    this.isMap = true;
    this.center = { lat: this.longitude, lng: this.latitude };
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Select Branch Location';
        break;
      case 'edit':
        this.dialogTitle = 'Select Branch Location';
        break;
      default:
        break;
    }
  }


  center: any;
  zoom = 12;
  advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = { gmpDraggable: false };
  advancedMarkerPositions: google.maps.LatLngLiteral[] = [];

  addAdvancedMarker(event: google.maps.MapMouseEvent) {
    if (!this.advancedMarkerPositions || this.advancedMarkerPositions.length < 1) {
      this.advancedMarkerPositions.push(event.latLng.toJSON());
    }else{
      this.advancedMarkerPositions = [];
    }
    // ===============", this.advancedMarkerPositions);
  }

reset(){
  this.advancedMarkerPositions = [];
}


}

