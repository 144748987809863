import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-two',
  templateUrl: './benefits-two.component.html',
  styleUrls: ['./benefits-two.component.scss']
})
export class BenefitsTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
