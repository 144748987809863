import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamRouteModule } from './team-route.module';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { DatePipe } from '@angular/common';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';
import { AssignTeamComponent } from './assignTeam/assignTeam.component';

import { EmpowerComponent } from './itemView/empower/empower.component';
import { ProfileComponent } from './itemView/profile/profile.component';
import { TagComponent } from './itemView/tag/tag.component';
import { ItrsComponent } from './itemView/itrs/itrs.component';

import { TeamService } from 'src/app/@tji/_dbShare';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        AssignTeamComponent,
        FormComponent,
        EmpowerComponent,
        ProfileComponent,
        TagComponent,
        ItrsComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        TeamRouteModule,
        UtilsModule,
        MatSelectModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
        ItemComponent,
        AssignTeamComponent,
        MatSelectModule
    ],
    providers: [
        TeamService,
        DatePipe
    ]
})

export class TeamModule { }
