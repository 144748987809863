export class RTemplateFailedModel {
    id:number;
    template_id: number;
    client_id: number;
    template_usage_id: number;
    to: string;
    response_json: string;
    description:string;
    created_by:number;
    updated_by:number;
    created_at:Date;
    updated_at:Date;
    deleted_at: Date;
    templateCode?: string;
    jobcodee?: string;

/**
 * Constructor
 *
 * @param templatefaild
 */

 constructor(templatefaild) {
    this.id = templatefaild.id || '';
    this.template_id = templatefaild.template_id || '';
    this.template_usage_id = templatefaild.template_usage_id || '';
    this.client_id = templatefaild.client_id || '';
    this.to = templatefaild.to || '';
    this.response_json = templatefaild.response_json || '';
    this.description = templatefaild.description || '';
    this.created_by = templatefaild.created_by || '';
    this.updated_by = templatefaild.updated_by || '';
    this.created_at = templatefaild.created_at || '';
    this.updated_at = templatefaild.updated_at || '';
    this.deleted_at = templatefaild.deleted_at || '';
}
}
