import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import {
	User, UserService, CommonService,
	FbTemplateMessageService, FbTemplateMessage
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'fb-template-template-view',
	templateUrl: './template-view.component.html',
	styleUrls: ['./template-view.component.scss']
})

export class FbTemplateViewComponent implements OnInit, OnDestroy {
	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
	@Output() onClose = new EventEmitter();

	loader: boolean = true;
	authUser: User;
	url: string = this.fbTemplateMessageService.url;
	template: FbTemplateMessage;
	templateUpdate: any;
	cardLengthData: any = [];
	base_image: string = this.commonService.logo_image;

	constructor(public userService: UserService,
		public fbTemplateMessageService: FbTemplateMessageService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.fbTemplateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
			this.template["templateComponent"] = {};
			if (this.template && this.template.components && this.template.components.length > 0) {
				this.template.components.forEach(component => {
					if (component.type.toLowerCase() == 'header') {
						this.template["templateComponent"]["header"] = component;
						this.template["templateComponent"]["header"]["format"] = component.format.toLowerCase();
					}
					if (component.type.toLowerCase() == 'body') {
						this.template["templateComponent"]["body"] = component;
						this.template["templateComponent"]["body"]["type"] = component.type.toLowerCase();
					}
					if (component.type.toLowerCase() == 'footer') {
						this.template["templateComponent"]["footer"] = component;
						this.template["templateComponent"]["footer"]["type"] = component.type.toLowerCase();
					}
					if (component.type.toLowerCase() == 'buttons') {
						this.template["templateComponent"]["buttons"] = component;
						this.template["templateComponent"]["buttons"]["type"] = component.type.toLowerCase();
					}
					if (component.type.toLowerCase() == 'carousel') {
						this.template["templateComponent"]["carousel"] = component;
						this.template["templateComponent"]["carousel"]["type"] = component.type.toLowerCase();
						if (component.cards && component.cards.length > 0) {
							var cardId = 0;
							component.cards.forEach(cards => {
								cardId = cardId + 1;
								var data = {
									item: cardId
								}
								this.cardLengthData.push(data);
								if (cards.components && cards.components.length > 0) {
									cards.components.forEach(carousel => {
										carousel["type"] = carousel.type.toLowerCase();
										if (carousel.format) {
											carousel["format"] = carousel.format.toLowerCase();
										}
									});
								}
							});
						}
					}
				});
			}
			if (this.template && this.template['description'] && this.template['description']['template'] && this.template['description']['template']['components']) {
				if (this.template && this.template.components && this.template.components.length > 0) {
					this.template.components.forEach(component => {
						if (component.type.toLowerCase() == 'header') {
							this.template["templateComponent"]["header"] = component;
							this.template["templateComponent"]["header"]["format"] = component.format.toLowerCase();
							this.template['description']['template']['components'].forEach(replayTemp => {
								if (replayTemp.type == component.type.toLowerCase()) {
									if (replayTemp.parameters && replayTemp.parameters[0] && replayTemp.parameters[0].type && replayTemp.parameters[0].type.toLowerCase() == 'image') {
										this.template["templateComponent"]["header"]["text"] = replayTemp.parameters[0].image.link;
									}
									if (replayTemp.parameters && replayTemp.parameters[0] && replayTemp.parameters[0].type && replayTemp.parameters[0].type.toLowerCase() == 'video') {
										this.template["templateComponent"]["header"]["text"] = replayTemp.parameters[0].video.link;
									}
									if (replayTemp.parameters && replayTemp.parameters[0] && replayTemp.parameters[0].type && replayTemp.parameters[0].type.toLowerCase() == 'document') {
										this.template["templateComponent"]["header"]["text"] = replayTemp.parameters[0].document.link;
									}
									if (replayTemp.parameters && replayTemp.parameters[0] && replayTemp.parameters[0].type && replayTemp.parameters[0].type.toLowerCase() == 'text') {
										this.template["templateComponent"]["header"]["text"] = component.text.replace("{{1}}", "<span class='veriable-color'>"+replayTemp.parameters[0].text+ "</span>" );
									}
								}
							});
						}
						if (component.type.toLowerCase() == 'body') {
							this.template["templateComponent"]["body"] = component;
							this.template["templateComponent"]["body"]["type"] = component.type.toLowerCase();
							this.template['description']['template']['components'].forEach(replayTemp => {
								if (replayTemp.type == component.type.toLowerCase()) {
									if (replayTemp.parameters && replayTemp.parameters.length > 0) {
										replayTemp.parameters.forEach((bodyParameter, index) => {
											var count = index + 1
											this.template["templateComponent"]["body"]["text"] = component.text.replace('{{' + count + '}}', "<span class='veriable-color'>"+bodyParameter.text+ "</span>");
										});

									}
								}
							});
						}
						if (component.type.toLowerCase() == 'footer') {
							this.template["templateComponent"]["footer"] = component;
							this.template["templateComponent"]["footer"]["type"] = component.type.toLowerCase();
						}
						if (component.type.toLowerCase() == 'buttons') {
							this.template["templateComponent"]["buttons"] = component;
							this.template["templateComponent"]["buttons"]["type"] = component.type.toLowerCase();
						}
						if (component.type.toLowerCase() == 'carousel') {
							this.template["templateComponent"]["carousel"] = component;
							this.template["templateComponent"]["carousel"]["type"] = component.type.toLowerCase();
							if (component.cards && component.cards.length > 0) {
								var cardId = 0;
								component.cards.forEach(cards => {
									cardId = cardId + 1;
									var data = {
										item: cardId
									}
									this.cardLengthData.push(data);
									if (cards.components && cards.components.length > 0) {
										cards.components.forEach((carousel, carIndex) => {
											if (carousel.format) {
												carousel["format"] = carousel.format.toLowerCase();
											}
											// this.template['description']['template']['components']['cards'].forEach(replayCard => {
											// 	replayCard.components.forEach((replayComponent, repComIndex) => {
											// 		if (replayComponent.type.toLowerCase() == 'header' && carIndex == repComIndex && replayComponent.format.toLowerCase() == 'image') {
											// 			carousel["text"] = carousel.format.toLowerCase();
											// 		}
											// 	});

											// });

										});
									}
								});
							}
						}
					});
				}
			}
		});
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.fbTemplateMessageService.unSubscribe();
		this.fbTemplateMessageService.changeItem(null)
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getUrl() {
		return environment.domainSecuredOne + '/template/sent';
	}

	getHeaders() {
		return { "Content-Type": "application/json" };
	}

	// getToken(template: FbTemplateMessage): string {
	// 	// let tjiToken = localStorage.getItem('tji_token');
	// 	// if(tjiToken) {
	// 	// 	tjiToken = "Bearer " + tjiToken.replace('"', '').replace('"', '');
	// 	// }
	// 	// return tjiToken;
	// 	return template.token;
	// }


}
