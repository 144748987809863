import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import { User, UserService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'dashboard-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {
	authUser: User;
	isAuth: boolean = true;
	activeUrl: string = this.router.url;
	
	constructor(public userService: UserService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => {
			this.authUser = data;
			this.isAuth = (this.authUser && this.authUser.id > 0) ? true : false;
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	isDashboard(): boolean {
		return (window.location.href.includes('/app/dashboard') || window.location.href.includes('/app/agent-dashboard')) ? true : false;
	}

}
