import { Component, OnInit } from '@angular/core';  
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr'; 
import { ToastData, ToastService } from 'src/app/@tji/_dbShare';

@Component({  
    selector: 'library-toastr',  
    templateUrl: './toastr.component.html',  
    styleUrls: ['./toastr.component.scss']  
})  

export class ToastrComponent extends Toast {  
    
    data: ToastData;
    datas: ToastData[];

    constructor(protected toastrService: ToastrService,
                public toastPackage: ToastPackage,
                public toastService: ToastService) {
        super(toastrService, toastPackage);
        this.toastService.data.subscribe(data => {
            this.data = data;
            this.appendData(data, this.toastPackage.toastId);
        });
        this.toastService.datas.subscribe(data => this.datas = data );
    }  

    ngOnInit() { }  

    appendData(data: ToastData, id: number) {
        data.id = id;
        this.toastService.concatItem(data);
    }

    onHidden() {
        return this.toastPackage.toastId;
    }

    getClass(): string {
        let data: ToastData = null;
        if(this.datas && this.datas.length > 0) {
            data = this.datas.find(x => x.id === this.toastPackage.toastId);
        }
        if(data && data.type) {
            return 'toast-'+data.type;
        }
        return 'toast-default';
    }

    getIcon(): string {
        let data: ToastData = null;
        if(this.datas && this.datas.length > 0) {
            data = this.datas.find(x => x.id === this.toastPackage.toastId);
        }
        if(data && data.faIcon) {
            return data.faIcon;
        }

        if(data && data.site) {
            switch (data.site.toLowerCase()) {
                case 'whatsapp':
                    return 'fa fa-whatsapp';
                    break;
                case 'webchat':
                    return 'fa fa-webchat';
                    break;
                case 'instagram':
                    return 'fa fa-instagram';
                    break;
                case 'instadm':
                    return 'fa fa-instagram';
                    break;
                case 'twitter':
                    return 'fa fa-twitter';
                    break;
                case 'messenger':
                    return 'fa fa-comments-o';
                    break;
                case 'youtube':
                    return 'fa fa-youtube';
                    break;
                default:
                    return 'fa fa-bell-o';
                    break;
            }
        }
        return 'fa fa-bell-o';
    }

    isCategory(): boolean {
        let data: ToastData = null;
        if(this.datas && this.datas.length > 0) {
            data = this.datas.find(x => x.id === this.toastPackage.toastId);
        }
        return (data && data.category) ? true : false;
    }

    getCategoryIcon(): string {
        let data: ToastData = null;
        if(this.datas && this.datas.length > 0) {
            data = this.datas.find(x => x.id === this.toastPackage.toastId);
        }
        if(data && data.category) {
            switch (data.category.toLowerCase()) {
                case 'sent':
                    return 'fa fa-share';
                    break;
                case 'received':
                    return 'fa fa-reply';
                    break;
                case 'ticket':
                    return 'fa fa-ticket';
                    break;
                default:
                    return 'fa info-circle';
                    break;
            }
        }
        return 'fa info-circle';
    }

}