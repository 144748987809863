import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ImageService, IntractiveService,
	IntractiveGroupService, IntractiveGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-image_change_form',
	templateUrl: './changeIntractiveGroupImage.component.html',
	styleUrls: ['./changeIntractiveGroupImage.component.scss']
})

export class ChangeIntractiveGroupImageComponent implements OnInit, OnDestroy {
	subLoader: boolean = false;
	dialogTitle: string = 'Add / Change Image Asset';
	authUser: User;
	intractiveGroup: IntractiveGroup;
	action: string;
	imageForm: UntypedFormGroup;
	images: Array<any> = [];
	error: string = null;
	isEditable: boolean = false;

	constructor(public userService: UserService,
		public intractiveGroupService: IntractiveGroupService,
		public imageService: ImageService,
		public intractiveService: IntractiveService,
		public matDialogRef: MatDialogRef<ChangeIntractiveGroupImageComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder,
		private cd: ChangeDetectorRef) {
		// this.intractiveGroup = this._data.item;
		// if(this.intractiveGroup && this.intractiveGroup.imageAssets) {
		// 	this.images = this.intractiveGroup.imageAssets;
		// }
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.intractiveGroup) { this.subLoader = false; }
			this.intractiveGroup = data;
			if (this.intractiveGroup && this.intractiveGroup.image_asset) {
				this.images = this.intractiveGroup.image_asset;
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			next_intractive_id: [this.intractiveGroup.next_intractive_id || null, [Validators.required]],
			is_active: [1],
		});
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}
	typeData: string = 'image';
	storeImage(event, intractiveGroupId = null) {
		intractiveGroupId = (intractiveGroupId) ? intractiveGroupId : this.intractiveGroup.id;
		let resourceData = {
			'resource_type': 'intractive_group',
			'resource_id': intractiveGroupId,
			'storeMode': 'add-itrs-image'
		};
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			var types = file.type.split("/");
			var typeDatas = types[1];
			switch (typeDatas) {
				case "JPEG": case "JPG":
				case "PNG": case "GIF":
				case "TIFF": case "RAW":
				case "jpeg": case "jpg":
				case "png": case "gif":
				case "tiff": case "raw":
				case "WebP": case "webp": case "WEBP": 
					this.typeData = 'image'
					break;
				case "DOC": case "DOCX":
				case "ODT": case "PDF":
				case "PPT": case "PPTX":
				case "TXT": case "doc":
				case "csv":
				case "docx": case "odt":
				case "pdf": case "pptx":
				case "text":
					this.typeData = 'document'
					break;
				case "ogg": case "M4A":
				case "FLAC": case "MP3":
				case "MP4": case "WAV":
				case "WMA": case "MTM":
				case "FUR":
				case "OGG": case "m4a":
				case "flac": case "mp3":
				case "mp4": case "wav":
				case "wma": case "mtm":
				case "fur":
                    case "vnd.dlna.adts":
					this.typeData = 'voice'	
				default:
					break;
			}
			reader.readAsDataURL(file);
			reader.onload = (e: any) => {
				let input = {
					'asset': e.target.result,
					'asset_name': file.name,
					'asset_type': this.typeData,
					'is_name': false,
					'resource_id': resourceData.resource_id,
				};
				this.storeProcess(Object.assign(resourceData, input));
			}
		}
		// let input = {
		// 	'resource_type': 'intractive_group',
		// 	'resource_id': intractiveGroupId,
		// 	'storeMode': 'add-itrs-image',
		// 	'image': event.target.result,
		// 	'image_name': file.name,
		// 	'image_type': file.type,
		// 	'image_file_path': event.target.value
		// };
		// this.imageService.storeImage(event, input);
		// this.imageHandler(event);
	}

	storeProcess(input) {
		this.setSubLoader();
		this.intractiveGroupService.assetData('interactiveGroup/addAsset', input, this.intractiveGroup);
	}

	imageHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			if (file.size && file.size < 6000000) {
				this.error = null;
				reader.onload = (e: any) => {
					let newImage = [];
					newImage['url'] = e.target.result;
					newImage['type'] = 'image';
					this.images[this.images.length] = newImage;
					this.cd.markForCheck();
				};
			} else {
				this.error = "Maximum FileSize 5MB";
			}
		}
	}

	removeImage(intractiveGroupId, imageUrl) {
		intractiveGroupId = (intractiveGroupId) ? intractiveGroupId : this.intractiveGroup.id;
		let input = {
			'resource_id': intractiveGroupId,
			'url': imageUrl,
		};
		this.intractiveGroupService.assetDelete('interactiveGroup/removeAsset', input, this.intractiveGroup);
	}
	name: any;
	isChecked: any = false;
	editDataIndex: any;
	editName(image, index) {
		this.editDataIndex = index;
		this.isEditable = true;
		this.name = image.name;
	}
	close(image) {
		// this.name = image.name;
		// this.images.forEach(oldImage => {
		// 	if (oldImage.url == image.url) {
		// 		oldImage.name = image.name;
		// 	}
		// });
		delete this.editDataIndex;
		this.isEditable = false;
	}
	updateImage(intractiveGroupId, image) {
		let input = {
			'url': image.url,
			'asset_name': this.name,
			'is_name': image.is_name,
			'resource_id': intractiveGroupId,
		};
		this.setSubLoader();
		this.isEditable = false;
		this.intractiveGroupService.assetData('interactiveGroup/updateAsset', input, this.intractiveGroup);
		delete this.editDataIndex;
	}

	isNameChange($event: Event, image, intractiveGroupId) {
		let input = {
			'url': image.url,
			'asset_name': image.name,
			'is_name': image.is_name,
			'resource_id': intractiveGroupId,
		};
		this.setSubLoader();
		this.isEditable = false;
		this.intractiveGroupService.assetData('interactiveGroup/updateAsset', input, this.intractiveGroup);
		delete this.editDataIndex;
	}
}
