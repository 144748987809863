import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	TweetService, Tweet,
	TweetdmService, Tweetdm,
	MessengerService, Messenger,
	WhatsappService, Whatsapp,
	WebchatService, Webchat,
	YoutubeService, Youtube,
	InstagramService, Instagram,
	InstadmService, Instadm,
	CommonService,
	OpenTicketService, OpenTicket
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'queue-ticket-conversation-history',
	templateUrl: './conversationHistory.component.html',
	styleUrls: ['./conversationHistory.component.scss']
})

export class ConversationHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeTweet: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeTweetdm: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeMessenger: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeWhatsapp: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeWebchat: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeYoutube: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeInstagram: ScrollToBottomDirective;
	@ViewChild(ScrollToBottomDirective) scrollMeInstadm: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Conversation History';
	authUser: User;
	openTicket: OpenTicket;
	conversationHistory: UntypedFormGroup;
	ticketClassName: string = null;
	siteName: string = null;

	tweets: Tweet[] = [];
	tweetParam: any;
	tweetdms: Tweetdm[] = [];
	tweetdmParam: any;
	messengers: Messenger[] = [];
	messengerParam: any;
	whatsapps: Whatsapp[] = [];
	whatsappParam: any;
	webchats: Webchat[] = [];
	webchatParam: any;
	youtubes: Youtube[] = [];
	youtubeParam: any;
	instagrams: Instagram[] = [];
	instagramParam: any;
	instadms: Instadm[] = [];
	instadmParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;

	constructor(public userService: UserService,
		public openTicketService: OpenTicketService,
		public tweetService: TweetService,
		public tweetdmService: TweetdmService,
		public messengerService: MessengerService,
		public whatsappService: WhatsappService,
		public webchatService: WebchatService,
		public youtubeService: YoutubeService,
		public instagramService: InstagramService,
		public instadmService: InstadmService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialogRef: MatDialogRef<ConversationHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.tweetService.resetParams();
		this.tweetdmService.resetParams();
		this.messengerService.resetParams();
		this.whatsappService.resetParams();
		this.webchatService.resetParams();
		this.youtubeService.resetParams();
		this.instagramService.resetParams();
		this.instadmService.resetParams();
	}

	ngOnInit() {
		this.openTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.openTicket = data;
		});
		this.tweetService.params.pipe(untilDestroyed(this)).subscribe(data => this.tweetParam = data);
		this.tweetService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.tweets = data;
		});
		this.tweetdmService.params.pipe(untilDestroyed(this)).subscribe(data => this.tweetdmParam = data);
		this.tweetdmService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.tweetdms = data;
		});
		this.messengerService.params.pipe(untilDestroyed(this)).subscribe(data => this.messengerParam = data);
		this.messengerService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.messengers = data;
		});
		this.whatsappService.params.pipe(untilDestroyed(this)).subscribe(data => this.whatsappParam = data);
		this.whatsappService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.whatsapps = data;
		});
		this.webchatService.params.pipe(untilDestroyed(this)).subscribe(data => this.webchatParam = data);
		this.webchatService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.webchats = data;
		});
		this.youtubeService.params.pipe(untilDestroyed(this)).subscribe(data => this.youtubeParam = data);
		this.youtubeService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.youtubes = data;
		});
		this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => this.instagramParam = data);
		this.instagramService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.instagrams = data;
		});
		this.instadmService.params.pipe(untilDestroyed(this)).subscribe(data => this.instadmParam = data);
		this.instadmService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.instadms = data;
		});
		this.doLoader();
		this.getConversations();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.tweetService.changeAllItems([]);
		this.tweetdmService.changeAllItems([]);
		this.messengerService.changeAllItems([]);
		this.whatsappService.changeAllItems([]);
		this.webchatService.changeAllItems([]);
		this.youtubeService.changeAllItems([]);
		this.instagramService.changeAllItems([]);
		this.instadmService.changeAllItems([]);
		this.tweetService.unSubscribe();
		this.tweetdmService.unSubscribe();
		this.messengerService.unSubscribe();
		this.whatsappService.unSubscribe();
		this.webchatService.unSubscribe();
		this.youtubeService.unSubscribe();
		this.instagramService.unSubscribe();
		this.instadmService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		this.siteName = data.siteName;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Conversation History';
				break;
			default:
				break;
		}
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	getConversations() {
		if (this._data && this._data.siteName) {
			switch (this._data.siteName) {
				case "tweet": case "Tweet":
					this.tweetParam.client_site_id = this.openTicket.client_site_id;
					this.tweetParam.resource_id = this.openTicket.resource_id;
					// this.tweetParam.sort = "id|DESC";
					this.tweetParam.is_history = 1;
					this.tweetService.getAllItems(this.tweetParam);
					break;
				case "tweetdm": case "Tweetdm":
					this.tweetdmParam.client_site_id = this.openTicket.client_site_id;
					this.tweetdmParam.resource_id = this.openTicket.resource_id;
					// this.tweetdmParam.sort = "id|DESC";
					this.tweetdmParam.is_history = 1;
					this.tweetdmService.getAllItems(this.tweetdmParam);
					break;
				case "messenger": case "Messenger":
					this.messengerParam.client_site_id = this.openTicket.client_site_id;
					this.messengerParam.resource_id = this.openTicket.resource_id;
					// this.messengerParam.sort = "id|DESC";
					this.messengerParam.is_history = 1;
					this.messengerService.getAllItems(this.messengerParam);
					break;
				case "whatsapp": case "Whatsapp":
					this.whatsappParam.client_site_id = this.openTicket.client_site_id;
					this.whatsappParam.resource_id = this.openTicket.resource_id;
					// this.whatsappParam.sort = "id|DESC";
					this.whatsappParam.is_history = 1;
					this.whatsappService.getAllItems(this.whatsappParam);
					break;
				case "webchat": case "Webchat":
					this.webchatParam.client_site_id = this.openTicket.client_site_id;
					this.webchatParam.resource_id = this.openTicket.resource_id;
					// this.webchatParam.sort = "id|DESC";
					this.webchatParam.is_history = 1;
					this.webchatService.getAllItems(this.webchatParam);
					break;
				case "youtube": case "Youtube":
					this.youtubeParam.client_site_id = this.openTicket.client_site_id;
					this.youtubeParam.resource_id = this.openTicket.resource_id;
					// this.youtubeParam.sort = "id|DESC";
					this.youtubeParam.is_history = 1;
					this.youtubeService.getAllItems(this.youtubeParam);
					break;
				case "instagram": case "Instagram":
					this.instagramParam.client_site_id = this.openTicket.client_site_id;
					this.instagramParam.resource_id = this.openTicket.resource_id;
					// this.instagramParam.sort = "id|DESC";
					this.instagramParam.is_history = 1;
					this.instagramService.getAllItems(this.instagramParam);
					break;
				case "instadm": case "Instadm":
					this.instadmParam.client_site_id = this.openTicket.client_site_id;
					this.instadmParam.resource_id = this.openTicket.resource_id;
					// this.instadmParam.sort = "id|DESC";
					this.instadmParam.is_history = 1;
					this.instadmService.getAllItems(this.instadmParam);
					break;
				default:
					break;
			}
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.tweetService.changeAllItems([]);
		this.tweetdmService.changeAllItems([]);
		this.messengerService.changeAllItems([]);
		this.whatsappService.changeAllItems([]);
		this.webchatService.changeAllItems([]);
		this.youtubeService.changeAllItems([]);
		this.instagramService.changeAllItems([]);
		this.instadmService.changeAllItems([]);
		this.tweetService.unSubscribe();
		this.tweetdmService.unSubscribe();
		this.messengerService.unSubscribe();
		this.whatsappService.unSubscribe();
		this.webchatService.unSubscribe();
		this.youtubeService.unSubscribe();
		this.instagramService.unSubscribe();
		this.instadmService.unSubscribe();
	}
}
