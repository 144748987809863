import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from './data';

import { User, UserService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'dashboard-chart2',
	templateUrl: './chart2.component.html',
	styleUrls: ['./chart2.component.scss']
})

export class Chart2Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('chart2', { static: true }) chart2: ElementRef;
	authUser: User;

	data: any[];
	view: any[] = [];

	gradient: boolean = false;
	showLegend: boolean = true;
	showLabels: boolean = true;
	isDoughnut: boolean = false;
	legendPosition: string = 'top';

	colorScheme = {
		//domain: ["aqua", "aquamarine", "skyblue", "darkcyan", "cadetblue", "greenyellow", "yellow", "mediumspringgreen", "olive"]
		// domain: ["#ed6a5a","#f4f1bb","#9bc1bc","#5ca4a9","#e6ebe0"]
		domain: ["#4caf50","#7759de","#f1376e","#39da8a","#dc67ce"]
	};

	constructor(public userService: UserService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		Object.assign(this, { data });
	}

	ngOnInit() {
		this.view = [this.chart2.nativeElement.offsetWidth - 100, this.chart2.nativeElement.offsetHeight];
	}

	ngAfterViewInit() {
		
	}

	/** On destroy */
	ngOnDestroy(): void { }

	onSelect(event) {
	}

	onActivate(event) {
	}

	onDeactivate(event) {
	}

}
