export class UnreadCountModel {
    name: string;
    site?: string;
    count?: number;

    /**
     * Constructor
     *
     * @param unreadCount
     */
    constructor(unreadCount) {
            this.name = unreadCount.name || '';
            this.site = unreadCount.site || '';
            this.count = unreadCount.count || 0;
    }
}
