import { PosterAsset } from './poster.interface';
export class PosterModel {
	id: number;
	code: string;
	client_id: number;
	title: string;
	description: string;
	url: string;
	thumb_url: string;
	assets: string;
	is_active: boolean;
	is_approved: boolean;
	is_published: boolean;
	post_at: Date;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;
	client?: any;
	allAssets?: PosterAsset[];
	clientSiteIds?: Array<any>;

	/**
	 * Constructor
	 *
	 * @param poster
	 */
	constructor(poster) {
		this.id = poster.id || null;
		this.code = poster.code || null;
		this.client_id = poster.client_id || null;
		this.title = poster.title || null;
		this.description = poster.description || null;
		this.url = poster.url || null;
		this.thumb_url = poster.thumb_url || null;
		this.assets = poster.assets || null;
		this.is_active = poster.is_active || null;
		this.is_approved = poster.is_approved || null;
		this.is_published = poster.is_published || null;
		this.post_at = poster.post_at || null;
		this.created_by = poster.created_by || null;
		this.updated_by = poster.updated_by || null;
		this.created_at = poster.created_at || null;
		this.updated_at = poster.updated_at || null;

		this.tableName = poster.tableName || null;
		this.allAssets = poster.allAssets || [];
		this.clientSiteIds = poster.clientSiteIds || [];
	}
}

export class PosterAssetModel {
	index: number;
	file_data?: any;
	file_name?: string;
	file_type?: string;
	url?: string;
	type?: string;

	/**
	 * Constructor
	 *
	 * @param posterAsset
	 */
	constructor(posterAsset) {
		this.index = posterAsset.index || null;
		this.file_data = posterAsset.file_data || null;
		this.file_name = posterAsset.file_name || null;
		this.file_type = posterAsset.file_type || null;
		this.url = posterAsset.url || null;
		this.type = posterAsset.type || null;
	}
}
