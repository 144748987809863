import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'src/environments/environment';

import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { UserService } from 'src/app/@tji/_dbShare/user/user.service';
import { EventService } from 'src/app/@tji/_dbShare/alert/event/event.service';
import { Firebase } from './firebase.interface';
import { FirebaseModel } from './firebase_model.model';

declare function notifyMe(title, message): any;
declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class FirebaseService implements Resolve<any> {

	authUser              : any;
	firebaseRef           : AngularFireObject<any>;
	firebase              : Firebase;
	@Output() onChangeItem: any = new EventEmitter();

	private itemSource     = new BehaviorSubject<Firebase>(null);
	item                   = this.itemSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private userService : UserService,
				private eventService: EventService,
				private db          : AngularFireDatabase) {
		this.userService.user.subscribe(data => this.authUser = data );
		this.firebaseRef = db.object('push_event/' + this.authUser.id);
		this.firebaseRef.snapshotChanges().subscribe(data => {
			if(data.key && this.authUser && data.key == this.authUser.id) {
				this.firebase = data.payload.val();
				this.changeItem(this.firebase);
			} else {
				this.firebase = null;
				this.changeItem(this.firebase);
			}
		});
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([
				// this.getItem()
			])
				.then(() => {
					resolve('/dashboard');
				}, reject
			);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed FeedService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on FeedService');
	}

	clearSubscribe() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	changeItem(item: Firebase) {
		if (environment.enableFirebase) {
			this.itemSource.next(item);
			this.onChangeItem.emit(item);
			this.eventService.changeEventData(item);
		}
	}

	showAlert(title: string, message: string, type: string = null, icon: string = null) {
		if (environment.enableFirebase) {
			this.eventService.showAlert(title, message, type, icon);
		}
	}

	showToast() {
		if (environment.enableFirebase) {
			this.eventService.changeEventData(this.firebase);
			this.eventService.showToast(this.firebase);
		}
	}

	showAlertByData() {
		if (environment.enableFirebase) {
			this.eventService.changeEventData(this.firebase);
			this.eventService.showAlertByData(this.firebase);
		}
	}
	
}
