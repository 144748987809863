export interface TemplateMessageObject {
	type: string;
	to: string;
	template: TemplateObject;
	templateComponent: TemplateComponent;
	// whatspp_data: string;
}

export interface TemplateObject {
	namespace: string;
	name: string;
	language: LanguageObject;
	components: Array<ComponentObject>;
}

export interface ComponentObject {
	type: string;
	parameters: Array<any>;
}

export interface LanguageObject {
	code: string;
	policy: string;
}

export interface TemplateComponent {
	image:string;
    video:string;
    document:string;
    headerType:string;
    headerText:string;
    bodyType:string;
    bodyText:string;
    footerType:string;
    footerText:string;
	document_name: string;
    video_name: string;
    image_name: string;
}

// export interface Header {
// 	type: string;
// 	text?: string;
// 	video?: any;
// 	image?: any;
// 	document?: any;
// }

// export interface Body {
// 	type: string;
// 	text: string;
// }

// export interface Footer {
// 	type: string;
// 	text: string;
// }

// export interface Action {
// 	type: string;
// 	button?: string;
// 	buttons?: any;
// 	sections?: any;
// }