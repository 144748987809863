import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith } from 'rxjs/operators';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

import {
  UserService, AlertService, MediaService, CommonService,
  BranchService,
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MapMarkerComponent } from '../map-marker/map-marker.component';

@UntilDestroy()
@Component({
  selector: 'sidebar-branch',
  templateUrl: './sidebar-branch.component.html',
  styleUrls: ['./sidebar-branch.component.scss']
})
export class SidebarBranchComponent {

  @Output() onClose = new EventEmitter();
  branchForm: UntypedFormGroup;
  title: string = 'New Branch';
  branchItem: any;
  params: any;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  timeRegex = /^(\d)*(\.)?([0-9]{2})?$/;
  isEdite: boolean = false;
  brandId : any = '';
  typeList : any;
  ngxMatTimepicker: any;

  private _unsubscribeAll: Subject<any>;
  constructor(public branchService: BranchService,
    public matDialog: MatDialog,
    public userService: UserService,
    public mediaService: MediaService,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder) {
    this._unsubscribeAll = new Subject();
    this.getPosition();
    this.branchService.allTypes.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.typeList = data;
      }
    });
    this.branchService.params.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.params = data;
      }
    });
    this.branchService.brandId.pipe(untilDestroyed(this)).subscribe(data => {
        this.brandId = data;
    });
    this.branchService.item.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.branchItem = data;
        if (data && data._id) {
          this.title = 'Edit Branch';
          this.isEdite = true;
          if (this.branchItem && this.branchItem.latitude) {
            this.branchItem.location = this.branchItem.latitude + ' ' + this.branchItem.longitude;
            this.location.latitude = this.branchItem.latitude;
            this.location.longitude = this.branchItem.longitude;
          }
        } else {
          this.title = 'New Branch';
        }
      }
      this.branchForm = this.createForm();
    });

    this.branchService.addItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == true) {
        this.branchForm.reset();
        this.branchService.isAddItems.next(false);
        delete this.branchItem;
        this.branchService.itemSource.next(null);
        this.isEdite = false;
        this.title = 'New Branch';
      }
    });
  }


  ngOnInit(): void {

  }

  /** On destroy */
  ngOnDestroy(): void {
    this.closeWindow();
    this.branchService.itemSource.next(null);
    this.branchService.unSubscribe();
    delete this.branchItem;
    this.branchService.itemSource.next(null);
    this.isEdite = false;
    this.title = 'New Branch';
  }
  unSubscribe() {
    // console.log('UnSubscribed MediaService');
  }

  createForm(): UntypedFormGroup {
    return this._formBuilder.group({
      name: [this.branchItem?.name || null, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]],
      email: [this.branchItem?.email || null, [Validators.required, Validators.minLength(1), Validators.maxLength(60), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNumber: [this.branchItem?.phoneNumber || null, [Validators.required]],
      address: [this.branchItem?.address || null, [Validators.required]],
      availability: [this.branchItem?.availability || true],
      is_headquarters: [this.branchItem?.is_headquarters || false],
      pickup: [this.branchItem?.pickup || false],
      // type: [this.branchItem?.type || null, [Validators.required]],
      location: [this.branchItem?.location || null, [Validators.required]],
      work_time_start: [this.branchItem?.work_time_start || null, [Validators.required]],
      work_time_end: [this.branchItem?.work_time_end || null, [Validators.required]],
      delivery_within_after : [this.branchItem?.delivery_within_after || null, [Validators.pattern(this.timeRegex)]],
      delivery_within_until : [this.branchItem?.delivery_within_until || null,[Validators.pattern(this.timeRegex)]],
    });
  }


  submit() {

    if (this.branchForm.invalid) {
      return
    }

    var data = {
      name:this.branchForm.value.name ? this.branchForm.value.name : '',
      email: this.branchForm.value.email ? this.branchForm.value.email : '',
      phoneNumber: parseInt(this.branchForm.value.phoneNumber ? this.branchForm.value.phoneNumber : ''),
      address: this.branchForm.value.address ? this.branchForm.value.address : '',
      availability: this.branchForm.value.availability?1:0,
      is_headquarters: this.branchForm.value.is_headquarters?1:0,
      pickup: this.branchForm.value.pickup?1:0,
      // type: this.branchForm.value.type ? this.branchForm.value.type : '',
      work_time_start: this.branchForm.value.work_time_start ? this.branchForm.value.work_time_start : '',
      work_time_end: this.branchForm.value.work_time_end ? this.branchForm.value.work_time_end : '',
      delivery_within_after : this.branchForm.value.delivery_within_after ? this.branchForm.value.delivery_within_after : '',
      delivery_within_until : this.branchForm.value.delivery_within_until ? this.branchForm.value.delivery_within_until : '',
      latitude:this.location.latitude?this.location.latitude:'',
      longitude:this.location.longitude?this.location.longitude:'',
      brand_id:this.brandId
    }

    if (this.branchItem && this.branchItem._id) {
      data['id'] = this.branchItem._id;
      this.branchService.update('branch/update', data);
      this.onClose.emit(false)
      delete this.branchItem;
      this.branchService.itemSource.next(null);
      this.isEdite = false;
      this.title = 'New Branch';
    } else {
      this.branchService.store('branch/add', data);
      this.onClose.emit(false)
      delete this.branchItem;
      this.branchService.itemSource.next(null);
      this.isEdite = false;
      this.title = 'New Branch';
    }
  }

  /** number only accept */
  numberOnly(event: any): boolean {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  }

  /** number only accept */
  orderIndex(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  closeWindow() {
    delete this.branchItem;
    this.branchService.itemSource.next(null);
    this.isEdite = false;
    this.title = 'New Branch';
  }

  taxChange(event: any) {
  }
  resetForm() {
    this.branchForm.reset();
    // this.branchForm.get('business_id').enable();
  }

  	/** Add / Update Agent */
	mapLoad(agent = null): void {
			
			// this.agentService.changeItem((agent) ? agent : new AgentModel({}));
			let dialogRef = this.matDialog.open(MapMarkerComponent, {
				width: "600px",
				data: {
					action: (agent) ? 'edit' : 'new',
					item: this.location,
				}
			});

			dialogRef.afterClosed()
				.subscribe(response => {
					if (!response) { return; }
					const actionType: string = response[0];
					const responseData = response[1];
					switch (actionType) {
						case 'new':
              if ( responseData && responseData[0] && responseData[0].lat) {
                this.location.latitude = responseData[0].lng;
                this.location.longitude = responseData[0].lat;
                this.branchForm.controls.location.setValue(responseData[0].lng +" "+responseData[0].lat);
              }
							break;
					}
				});
		
	}

location = {
  latitude: 0,
  longitude: 0,
}
getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {
          this.location.latitude = resp.coords.longitude;
          this.location.longitude = resp.coords.latitude;
        // resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

}

