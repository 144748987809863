import { NgModule } from '@angular/core';
import { SocialSiteImportModule } from './socialSite-import.module';
import { SocialSiteRouteModule } from './socialSite-route.module';

@NgModule({
	imports: [
		SocialSiteImportModule,
		SocialSiteRouteModule,
	],
})

export class SocialSiteModule { }
