import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    User, UserService,
    MediaService, Media, MediaModel
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogImageUploadComponent } from '../confirm-dialog-image-upload/confirm-dialog-image-upload.component';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
    selector: 'gallery-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
    authUser: User;
    gallery: Media;
    galleryForm: UntypedFormGroup;
    loader: boolean = false;
    isMultiAttachment: boolean = false;

    constructor(public userService: UserService,
        public mediaService: MediaService,
        private _formBuilder: UntypedFormBuilder,
        public matDialog: MatDialog) {
        this.galleryForm = this.createForm();
        this.mediaService.uploadLoader.subscribe(data => this.loader = data);
        this.mediaService.isCopyUrl.subscribe(data => {
            if (data && data.length > 0) {
                this.copyUrl = data;
                this.isCopyUrl = true;
                this.isMultiAttachment = false;
            }
            if (!data) {
               this.resetProductImage(); 
            }
        });
    }

    ngOnInit() {
        // this.doLoader();
    }

    /** On destroy */
    ngOnDestroy(): void {
        this.mediaService.unSubscribe();
        this.mediaService.unSubscribeFilter();
    }

    doLoader() {
        this.loader = true;
        setTimeout(() => {
            this.loader = false;
        }, 5000);
    }

    /** Create form */
    createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            file: [null],
            asset: [null],
            asset_name: [null],
            asset_type: [null]
        });
    }
    typeData: string = 'image';
    totalFiles = 0;
    selectedFiles: FileList;
    multipleFilesData: any;
    imageHandler(event) {
        let reader = new FileReader();
        let fileData = null;
        var totalCount = 0;


        if (event.target.files && event.target.files.length > 1) {

            let confirmDialogRef = this.matDialog.open(ConfirmDialogImageUploadComponent, {
                disableClose: false,
                width: '400',
                data: {
                    type: 'info',
                    message: 'this attachment upload for product?',
                }
            });
            confirmDialogRef.afterClosed().subscribe(result => {
                if (result == "true") {
                    var selectedFiles = event.target.files;
                    this.totalProducts = event.target.files.length;
                    var selectedImages = [];
                    var mediaCount = 0;
                    // setTimeout(() => {
                    for (let i = 0; i < selectedFiles.length; i++) {
                        const file = selectedFiles[i];
                        if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                            let reader = new FileReader();
                            let fileData = null;
                            var filesize = file.size;
                            const fileSizeInKB = Math.round(filesize / 1024);
                            if (fileSizeInKB <= 8192) {
                                if (file && file.size) {
                                    mediaCount = mediaCount + 1;
                                    const files = file;
                                    var filesDatas = [];
                                    filesDatas.push(file);
                                    reader.readAsDataURL(files);

                                    reader.onload = (files: any) => {

                                        const img = new Image();
                                        img.src = files.target.result;
                                        img.onload = () => {
                                            if (img.width >= 499 && img.height >= 499) {
                                                reader.result
                                                var imageData = {
                                                    asset: reader.result,
                                                    asset_name: file.name.replace(/ /g, "_"),
                                                    asset_type: 'image',
                                                }
                                                // this.mediaService.isUploadLoader.next(true);
                                                // this.mediaService.addStore(imageData);
                                                selectedImages.push(imageData);

                                                if (selectedImages && selectedImages.length > 0 && selectedImages.length == mediaCount) {
                                                    // this.imageCroping(selectedImages);
                                                    this.isMultiAttachment = true;
                                                    this.multipleFilesData = selectedImages;
                                                    this.loopThroughPayloads(0, selectedImages.length);
                                                }
                                            }
                                            //  else {
                                            //     this.isImageUpload = false;
                                            //     this.alertService.webShow('Danger', 'Please Select minimum images size for widht 500px and height 500px');
                                            // }
                                        };

                                    };
                                }
                            }
                        }else{
                            if (this.totalProducts && !selectedImages) {
                                this.closed();
                            }
                        }
                    }
                    // }, 100);
                    if (!selectedImages){
                        this.closed();
                    }


                } else if(result == "false") {
                    this.multipleFileUpload(event);
                }
                else {
                    this.closed();
                }
            });
        }

        if (event.target.files && event.target.files.length == 1) {
            this.isMultiAttachment = false;
            const file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
                var types = file.type.split("/");
                var typeDatas = types[1];
                switch (typeDatas) {
                    case "JPEG": case "JPG":
                    case "PNG": case "GIF":
                    case "TIFF": case "RAW":
                    case "jpeg": case "jpg":
                    case "png": case "gif":
                    case "tiff": case "raw":
                    case "WebP": case "webp": case "WEBP":
                        this.typeData = 'image'
                        break;
                    case "DOC": case "DOCX":
                    case "ODT": case "PDF":
                    case "PPT": case "PPTX":
                    case "TXT": case "XLS":
                    case "XLSX": case "ZIP":
                    case "CVS": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    case "doc": case "csv":
                    case "docx": case "odt":
                    case "pdf": case "pptx":
                    case "text": case "xls":
                    case "xlsx": case "zip":
                    case "cvs": case "csv":
                        this.typeData = 'document'
                        break;
                    case "MP3": case "WAV":
                    case "AAC": case "FLAC":
                    case "Ogg": case "Ogg Vorbis":
                    case "PCM": case "MPEG":
                    case "mp3": case "wav":
                    case "aac": case "flac":
                    case "ogg": case "mpeg":
                    case "ogg vorbis": case "pcm":
                    case "vnd.dlna.adts":
                    case "m4a": case "M4A":
                    case "x-m4a": case "x-M4A":
                        this.typeData = 'voice'
                        break;
                    case "WEBM": case "MPG":
                    case "MP2":
                    case "MPE": case "MPV":
                    case "OGG": case "MP4":
                    case "AVI": case "WMV":
                    case "MOV": case "QT":
                    case "FLV": case "SWF":
                    case "AVCHD":
                    case "webm": case "mpg":
                    case "mp2":
                    case "mpe": case "mpv":
                    case "ogg": case "mp4":
                    case "avi": case "wmv":
                    case "mov": case "qt":
                    case "flv": case "swf":
                    case "avchd": case "3gpp":
                    case "3GPP":
                        this.typeData = 'video'
                    default:
                        break;
                }

                this.galleryForm.patchValue({
                    asset: reader.result,
                    asset_name: file.name.replace(/ /g, "_"),
                    asset_type: this.typeData,
                });
                this.mediaService.isUploadLoader.next(true);
                this.mediaService.addStore(this.galleryForm.value);
                setTimeout(() => {
                    this.galleryForm.patchValue({
                        file: null,
                        asset: null,
                        asset_name: null,
                        asset_type: null
                    });
                }, 300);
            };
        }
    }

    totalProducts: number = 0;
    countUpdate: number = 0;
    rejectedAttachment: number = 0;
    copyUrl: any;
    isCopyUrl: boolean = false;

    loopThroughPayloads(index: number, currentTotal: number = 0) {
        const payloads = this.getPayloads();
        if (index < payloads.length) {
            this.mediaService.addMedia(payloads[index]).toPromise()
                .then(response => {
                    if (!this.copyUrl) {
                        this.copyUrl = response.data.url + '\n';
                    } else {
                        this.copyUrl = this.copyUrl + response.data.url + '\n';
                    }

                    this.countUpdate = this.countUpdate + 1;
                    if (currentTotal == this.countUpdate) {
                        this.isCopyUrl = true;
                        this.isMultiAttachment = false;
                        this.rejectedAttachment = this.totalProducts - currentTotal;
                        this.mediaService.getAllItems();
                    }
                    this.loopThroughPayloads(index + 1, currentTotal); // Call the next payload
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }


    multipleFileUpload(event: any = null) {
        this.isMultiAttachment = true;
        var multipleFiles = [];
        var fileCount = event.target.files.length;
        var fileSize = 100 / fileCount;
        this.selectedFiles = event.target.files;
        this.totalProducts = event.target.files.length
        for (let i = 0; i < this.selectedFiles.length; i++) {
            setTimeout(() => {

                const file = this.selectedFiles[i];
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e: any) => {
                    var types = file.type.split("/");
                    var typeDatas = types[1];
                    switch (typeDatas) {
                        case "JPEG": case "JPG":
                        case "PNG": case "GIF":
                        case "TIFF": case "RAW":
                        case "jpeg": case "jpg":
                        case "png": case "gif":
                        case "tiff": case "raw":
                        case "WebP": case "webp": case "WEBP":
                            this.typeData = 'image'
                            break;
                        case "DOC": case "DOCX":
                        case "ODT": case "PDF":
                        case "PPT": case "PPTX":
                        case "TXT": case "XLS":
                        case "XLSX": case "ZIP":
                        case "CVS": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "doc": case "csv":
                        case "docx": case "odt":
                        case "pdf": case "pptx":
                        case "text": case "xls":
                        case "xlsx": case "zip":
                        case "cvs": case "csv":
                            this.typeData = 'document'
                            break;
                        case "MP3": case "WAV":
                        case "AAC": case "FLAC":
                        case "Ogg": case "Ogg Vorbis":
                        case "PCM": case "MPEG":
                        case "mp3": case "wav":
                        case "aac": case "flac":
                        case "ogg": case "mpeg":
                        case "ogg vorbis": case "pcm":
                        case "vnd.dlna.adts":
                        case "m4a": case "M4A":
                        case "x-m4a": case "x-M4A":
                            this.typeData = 'voice'
                            break;
                        case "WEBM": case "MPG":
                        case "MP2":
                        case "MPE": case "MPV":
                        case "OGG": case "MP4":
                        case "AVI": case "WMV":
                        case "MOV": case "QT":
                        case "FLV": case "SWF":
                        case "AVCHD":
                        case "webm": case "mpg":
                        case "mp2":
                        case "mpe": case "mpv":
                        case "ogg": case "mp4":
                        case "avi": case "wmv":
                        case "mov": case "qt":
                        case "flv": case "swf":
                        case "avchd": case "3gpp":
                        case "3GPP":
                            this.typeData = 'video'
                        default:
                            break;
                    }

                    this.galleryForm.patchValue({
                        asset: reader.result,
                        asset_name: file.name.replace(/ /g, "_"),
                        asset_type: this.typeData,
                    });
                    multipleFiles.push(this.galleryForm.value);
                    // this.mediaService.isUploadLoader.next(true);
                    // this.mediaService.addStore(this.galleryForm.value);
                    // if (fileSize) {
                    //     totalCount = totalCount + 1;
                    //     fileCount = fileCount - 1;
                    //     this.totalFiles = totalCount * fileSize
                    //     if (fileCount == 0) {
                    //         this.totalFiles = 0;
                    //     }
                    // }

                    // setTimeout(() => {
                    //     this.galleryForm.patchValue({
                    //         file: null,
                    //         asset: null,
                    //         asset_name: null,
                    //         asset_type: null
                    //     });
                    // }, 300);
                };
            }, 50);
        }

        setTimeout(() => {
            if (multipleFiles && multipleFiles.length > 0) {
                this.multipleFilesData = multipleFiles;
                this.loopThroughPayloads(0, multipleFiles.length);
            }
        }, this.totalProducts * 55);
    }





    getPayloads() {
        // Return an array of payloads
        if (this.multipleFilesData && this.multipleFilesData.length > 0) {
            return this.multipleFilesData;
        }
    }
    closed() {
        setTimeout(() => {
            this.isMultiAttachment = false;
            this.isCopyUrl = false;
            this.copyUrl = null;
            this.totalProducts = 0;
            this.countUpdate = 0;
            this.rejectedAttachment = 0;
            this.galleryForm.patchValue({
                file: null,
                asset: null,
                asset_name: null,
                asset_type: null
            });
            this.mediaService.isCopyUrlSource.next(null);
        }, 100);
    }

    resetProductImage() {
        setTimeout(() => {
            this.totalProducts = 0;
            this.countUpdate = 0;
            this.isCopyUrl = false;
            this.isMultiAttachment = false;
            this.copyUrl = null;
            this.rejectedAttachment = 0;
            this.galleryForm.patchValue({
                file: null,
                asset: null,
                asset_name: null,
                asset_type: null
            });
        }, 100);
    }
}
