export class ResellerModel {
	id: number;
	code: string;
	name: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	detail?: any;
	user?: any;
	avatar_url?: string;
	totalCloseTickets?: number;
	totalOpenTickets?: number;
	roleArray?: Array<any>;
	roleIdArray?: Array<any>;
	permissionIdArray?: Array<any>;
	permissionArray?: Array<any>;
	directPermissionIdArray?: Array<any>;
	directPermissionArray?: Array<any>;
	userId?: number;
	userEmail?: string;
	clientIds?: Array<any>;
	clientIdsString?: string;

	tableName: string;

	/**
	 * Constructor
	 *
	 * @param reseller
	 */
	constructor(reseller) {
		this.id = reseller.id || '';
		this.code = reseller.code || '';
		this.name = reseller.name || '';
		this.created_by = reseller.created_by || '';
		this.updated_by = reseller.updated_by || '';
		this.created_at = reseller.created_at || '';
		this.updated_at = reseller.updated_at || '';

		this.detail = reseller.detail || {};
		this.user = reseller.user || {};

		this.tableName = reseller.tableName || '';
	}
}
