import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Catalog, CatalogService, BusinessService, WabaService } from 'src/app/@tji/_dbShare';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-assign-catalog-waba',
  templateUrl: './assign-catalog-waba.component.html',
  styleUrls: ['./assign-catalog-waba.component.scss']
})
export class AssignCatalogWabaComponent implements OnInit {

  dialogTitle: string = 'Assign Catalog waba';
  catalog: any;
  bussinessList: any;
  selectedBussinessId: number;
  action: string;

  constructor(public catalogService: CatalogService,
    public wabaService: WabaService,
    public matDialogRef: MatDialogRef<AssignCatalogWabaComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.catalogService.item.pipe(untilDestroyed(this)).subscribe(data => this.catalog = data);
    this.wabaService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
    this.bussinessList = data;
    });
  }

  ngOnInit(): void {
  }
  onSelect(selectedBussinessId: number) {
    this.selectedBussinessId = selectedBussinessId;
  }
}
