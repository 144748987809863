import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PeopleRouteModule } from './people-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { LeadsViewComponent } from './leadsView/leads-view.component';

import { ProfileComponent } from './itemView/profile/profile.component';
import { ChartComponent } from './itemView/chart/chart.component';

import { PeopleService, LeadsHistoryService } from 'src/app/@tji/_dbShare';
import { LeadsexportexcelComponent } from './leadsexportexcel/leadsexportexcel.component';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OrderByPipe } from 'ngx-pipes';
import { ConversationHistoryComponent } from './conversationHistory/conversationHistory.component';
import { ConversationInstagramHistoryComponent } from './conversationInstagramHistory/conversationInstagramHistory.component';
import { ReserveLeadComponent } from './reserve-lead/reserve-lead.component'
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        LeadsViewComponent,
        ProfileComponent,
        ChartComponent,
        LeadsexportexcelComponent,
        ConversationHistoryComponent,
        ConversationInstagramHistoryComponent,
        ReserveLeadComponent,
        SidebarComponent
        
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        PeopleRouteModule,
        UtilsModule,
        MatDialogModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        UtilsModule,
        ItemComponent,
        LeadsViewComponent,
        LeadsexportexcelComponent,
        ConversationHistoryComponent,
        ConversationInstagramHistoryComponent,
        ReserveLeadComponent
    ],
    providers: [
        PeopleService,
        LeadsHistoryService,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        OrderByPipe
    ]
})

export class PeopleModule { }
