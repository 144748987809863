import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class LoaderService {

    private adSubLoaderSource = new BehaviorSubject<boolean>(false);
    adSubLoader = this.adSubLoaderSource.asObservable();

    private adSendMsgLoaderSource = new BehaviorSubject<boolean>(false);
    adSendMsgLoader = this.adSendMsgLoaderSource.asObservable();

    constructor() { }

    changeAdSubLoader(res: boolean) {
        this.adSubLoaderSource.next(res);
        if(res && res === true) {
            setTimeout(() => { this.adSubLoaderSource.next(false); }, 2000);
        }
    }

    changeAdSendMsgLoader(res: boolean) {
        this.adSendMsgLoaderSource.next(res);
        if (res && res === true) {
            setTimeout(() => { this.adSendMsgLoaderSource.next(false); }, 2000);
        }
    }
}
