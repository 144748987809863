export class TeamModel {
    id:number;
    name: string;
    person_type: string;
    client_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    is_default: boolean;

    tableName?: string;
    personTeamsCount?: number;
    person_teams?: Array<any>;
    agnet_info?: Array<any>;

    /**
     * Constructor
     * @param team
     */
    constructor(team) {
            this.id = team.id || '';
            this.name = team.name || '';
            this.person_type = team.person_type || '';
            this.client_id = team.client_id || '';
            this.created_by = team.created_by || '';
            this.updated_by = team.updated_by || '';
            this.created_at = team.created_at || '';
            this.updated_at = team.updated_at || '';
            this.is_default = team.is_default || false;
    }
}
