import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  CatalogProductService,
  CatalogService,
  User, UserService,
} from 'src/app/@tji/_dbShare';
import { BulkUploadProductComponent } from '../bulk-upload-product/bulk-upload-product.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

@UntilDestroy()
@Component({
  selector: 'app-main-product',
  templateUrl: './main-product.component.html',
  styleUrls: ['./main-product.component.scss']
})
export class MainProductComponent implements OnInit {

  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  outputs: ['uploaded']
  user: User;
  pageTitleName: string = '';
  catalogName: string = '';
  dataLength: any;
  params: any;
  isEditProduct: boolean = true;

  constructor(public userService: UserService,
    public catalogProductService: CatalogProductService,
    public location: Location,
    public catalogService: CatalogService,
    public matDialog: MatDialog,
    public patalogProductService: CatalogProductService,
    private router: Router) {
    this.userService.user.subscribe(data => this.user = data);
    this.patalogProductService.catalogName.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.catalogName = "Catalog Name : " + data;
      }
    });
    this.catalogProductService.params.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.params = data;
      }
    });
    this.catalogProductService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  ngOnInit(): void {
  }

  toggleRightSidebar() {
    this.rightSideBar.toggle();
    this.catalogProductService.itemSource.next(null);
    this.catalogProductService.isAddItems.next(true);
  }

  isEditableData(data) {
    this.rightSideBar.toggle();
  }
  sync() {
    this.catalogProductService.sync('fbProduct/syncProducts');
  }
  
  exportProduct() {
    var datas = {
      catalog_id: this.params.catalog_id.toString(),
      current: 1,
      limit: this.dataLength,
    }
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'success',
				message: 'Are you sure you want to Download?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.catalogProductService.getExportItems(datas);
			}
		});
  }

  importBulkProduct() {
    let templateDialogRef = this.matDialog.open(BulkUploadProductComponent, {
      disableClose: false,
      width: '400',
    });
    templateDialogRef.afterClosed().subscribe(result => {
      if (result && result.data && result.data.length > 0) {
        this.catalogProductService.store('fbProduct/manage', result.data);
      }
    });
  }

  deleteProduct() {
		this.catalogProductService.isSelectProductSource.next(true);
	}
  
}
