export class RTemplateUsageReportsQueueModel {
    id: number;
    jobcode: string;
    template_id: number;
    client_id: number;
    total_count: number;
    success_count: number;
    failed_count: number;
    is_completed: boolean;
    json: any;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    template?: any;
    templateCode?: string;
    allUsers?: Array<any>;
    failedUsers?: Array<any>;
    deleted_at: any;
    progress_status: any;
    stage: any;
    clientName: any;
    clientAvatar: any;

    templateName: any;
    senderName: any;
    senderEmail: any;
    clientsiteName: any;
    isMisMatch: boolean;
    total_retries: number;
    /**
     * Constructor
     *
     * @param templateUsageReports
     */
    constructor(templateUsageReports) {
        this.id = templateUsageReports.id || '';
        this.jobcode = templateUsageReports.jobcode || '';
        this.template_id = templateUsageReports.template_id || '';
        this.client_id = templateUsageReports.client_id || '';
        this.total_count = templateUsageReports.total_count || '';
        this.success_count = templateUsageReports.success_count || '';
        this.failed_count = templateUsageReports.failed_count || '';
        this.is_completed = templateUsageReports.is_completed || '';
        this.json = templateUsageReports.json || '';
        this.templateName = templateUsageReports.templateName || '';
        this.senderName = templateUsageReports.senderName || '';
        this.senderEmail = templateUsageReports.senderEmail || '';
        this.clientsiteName = templateUsageReports.clientsite || '';
        this.isMisMatch = templateUsageReports.isMisMatch || false;
        this.total_retries = templateUsageReports.totalRetries || 0;
    }
}
