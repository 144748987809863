export class CatalogModel {
    _id: number;
    id: number;
    business_id: number;
    name: string;
    catalog_id: number;
    client_id: number;
    clientSiteInfo?: Array<any>;
    mongo?: Array<any>;

    /**
     * Constructor
     *
     * @param catalog
     */
    constructor(catalog) {
        this._id = catalog._id || '';
        this.id = catalog.id || '';
        this.business_id = catalog.business_id || '';
        this.name = catalog.name || '';
        this.catalog_id = catalog.catalog_id || '';
        this.client_id = catalog.client_id || '';
        this.clientSiteInfo = catalog.clientSiteInfo || {};
        this.mongo = catalog.mongo || {};
    }
}