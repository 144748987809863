import { Menu, MenuModel } from '../menu';

export class SubMenuModel {
	id: number;
	menu_id: number;
	title: string;
	slug: string;
	type: string;
	translate: string;
	icon: string;
	url: string;
	route: string;
	external_url: string;
	classes: string;
	function: string;
	order: number;
	role: string;
	permission: string;
	json: string;
	is_hidden: boolean;
	is_active: boolean;
	is_exact_match: boolean;
	is_open_new_tab: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	exactMatch: boolean;

	menu?: Menu;
	roles?: Array<any>;
	permissions?: Array<any>;
	isPermitted?: boolean;
	image?: string;

	/**
	 * Constructor
	 *
	 * @param subMenu
	 */
	constructor(subMenu) {
		this.id = subMenu.id || '';
		this.menu_id = subMenu.menu_id || '';
		this.title = subMenu.title || '';
		this.slug = subMenu.slug || '';
		this.type = subMenu.type || '';
		this.translate = subMenu.translate || '';
		this.icon = subMenu.icon || '';
		this.url = subMenu.url || '';
		this.external_url = subMenu.external_url || '';
		this.classes = subMenu.classes || '';
		this.function = subMenu.function || '';
		this.order = subMenu.order || '';
		this.role = subMenu.role || '';
		this.permission = subMenu.permission || '';
		this.json = subMenu.json || '';
		this.is_hidden = subMenu.is_hidden || '';
		this.is_active = subMenu.is_active || '';
		this.is_exact_match = subMenu.is_exact_match || '';
		this.is_open_new_tab = subMenu.is_open_new_tab || '';
		this.created_by = subMenu.created_by || '';
		this.updated_by = subMenu.updated_by || '';
		this.created_at = subMenu.created_at || '';
		this.updated_at = subMenu.updated_at || '';

		this.exactMatch = subMenu.exactMatch || false;
	}
}
