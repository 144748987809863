import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	WebchatTicketService, WebchatTicket
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'webchat-ticket-closed-list',
	templateUrl: './closedList.component.html',
	styleUrls: ['./closedList.component.scss']
})

export class ClosedListComponent implements OnInit, OnDestroy {
	user: User;
	param: any;
	title = 'Webchat Closed Tickets';
	viewMode = 'closed';

	constructor(public userService: UserService,
		public webchatTicketService: WebchatTicketService,
		private router: Router,
		private route: ActivatedRoute,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.webchatTicketService.params.pipe(untilDestroyed(this)).subscribe(data => { this.param = data; });
	}
	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	filterTicket(event) {
		this.param.is_closed = (event && event === 'true') ? 1 : 0;
		this.param.page = 1;
		// this.webchatTicketService.getAllItems();
	}

	exportTicket(exportType) {

		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure want to Export',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let param = {
					'count': 500,
					'social_site': 'wechat',
					'client_id': this.user['clientId']
				};
				let url: string = this.webchatTicketService.getExportUrl(param, exportType);
				window.open(url, '_blank');
			}
		});
	}

}
