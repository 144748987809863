import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { ItemComponent } from './item/item.component';

import { ClientDeliverySettingService, ClientSiteService, TeamService } from 'src/app/@tji/_dbShare';
import { DeliveryClientSettingsComponent } from './delivery-settings/delivery-settings.component';

const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: ClientSiteService },
	},
	{
		path: 'delivery-settings/:id',
		component: DeliveryClientSettingsComponent,
		resolve: { data: ClientDeliverySettingService },
	},
	{
		path: ':id',
		component: ItemComponent,
		resolve: { data: ClientSiteService },
	},
	{ path: '**', redirectTo: '/app/social-site' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class SocialSiteRouteModule { }
