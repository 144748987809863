import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { TemplateFormComponent } from './../templateForm/templateForm.component';

import {
	User, UserService,
	TemplateMessageService, TemplateMessage, TemplateMessageModel,
	TemplateUsageService, TemplateUsage
} from 'src/app/@tji/_dbShare';
import { BulkImportComponent } from '../bulkImport/bulkImport.component';

@UntilDestroy()
@Component({
	selector: 'template-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	templateLists: TemplateMessage[] = [];
	param: any;
	usageParam: any;
	dataSource = new MatTableDataSource(this.templateLists);
	displayedColumns = ['code', 'name', 'language', 'account', 'action'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

	constructor(public userService: UserService,
		public templateMessageService: TemplateMessageService,
		public templateUsageService: TemplateUsageService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.templateUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.usageParam = data);
		this.templateMessageService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if(data !== this.templateLists) { this.subLoader = false; }
			this.templateLists = data;
			this.dataSource = new MatTableDataSource(this.templateLists);
			this.dataSource.sort = this.sort;
		});
		this.dataSource = new MatTableDataSource(this.templateLists);
		this.dataSource.sort = this.sort;
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.templateMessageService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.templateMessageService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Template Message */
	newTemplate(template = null): void {
		this.router.navigateByUrl('/app/template/form');
		// this.templateMessageService.changeItem((template) ? template : new TemplateMessageModel({}));
		// let dialogRef = this.matDialog.open(FormComponent, {
		// 	width: "600px",
		// 	data: {
		// 		action: (template) ? 'edit' : 'new',
		// 		item: template,
		// 	}
		// });

		// dialogRef.afterClosed()
		// 	.subscribe(response => {
		// 		if (!response) { return; }
		// 		const actionType: string = response[0];
		// 		const formData: FormGroup = response[1];
		// 		const id: number = (response[2]) ? response[2] : null;
		// 		switch (actionType) {
		// 			case 'new':
		// 				this.templateMessageService.store(formData.value);
		// 				break;
		// 			case 'update':
		// 				this.templateMessageService.update(id, formData.value);
		// 				break;
		// 			case 'delete':
		// 				this.deleteTemplate(template);
		// 				break;
		// 		}
		// 	});
	}

	/** Delete Social Site */
	deleteTemplate(template: TemplateMessage): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var temp = {
					id : template.id
				}
				this.templateMessageService.destroy("template/delete",temp);
			}
		});
	}

	selectedItem(template) {
		this.templateMessageService.changeItem(template);
		this.router.navigate(['/app/template/' + template.id]);
	}

	viewHistory(template) {
		// this.usageParam.template_id = template.id;
		// this.templateUsageService.getAllItems();
		this.templateMessageService.changeItem(template);
		this.router.navigate(['/app/template/history/'+ template.id]);
	}

	statusChange(id, event) {
        this.templateMessageService.update(id, { 'status': event });
    }

    /** Send Template Message to Lead */
	sendTemplateMessage(template: TemplateMessage): void {
		this.templateMessageService.changeItem(template);
		// this.consumerGroupService.changeItem(consumerGroup);
		let templateDialogRef = this.matDialog.open(TemplateFormComponent, {
			disableClose: false,
			width: '600',
			data: template
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				result['client_site_id'] = template.client_site_id;
				this.templateMessageService.postUrl(result,'send-bulk-template');
			}
		});
	}

	    /** Send Template Message to Lead */
	importExcel(template: TemplateMessage): void {
		this.templateMessageService.changeItem(template);
		// this.consumerGroupService.changeItem(consumerGroup);
		let templateDialogRef = this.matDialog.open(BulkImportComponent, {
			disableClose: false,
			width: '400',
			data: template
		});
		templateDialogRef.afterClosed().subscribe(result => {
			if (result && result.template_id && result.data) {
				result['client_site_id'] = template.client_site_id;
				// this.templateMessageService.postUrl(result,'send-bulk-template');
			}
		});
	}
	viewTemplate(template){
		this.templateMessageService.changeItem(template)
		this.templateMessageService.templateViewsidebar.next(true);
	}
}
