import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstagramTicketRouteModule } from './instagramTicket-route.module';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { AgentDashboardModule } from 'src/app/appAccount/agentDashboard/agentDashboard.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ClosedListComponent } from './closedList/closedList.component';
import { ActivityComponent } from './activity/activity.component';
import { ActivityListComponent } from './activity/list/list.component';
import { ItemComponent } from './item/item.component';
import { AbandonComponent } from './abandon/abandon.component';
import { CommentExportComponent } from './commentExport/commentExport.component';

import { TicketListComponent } from './itemView/ticketList/ticketList.component';
import { LeftComponent } from './itemView/left/left.component';
import { LeftSideNavComponent } from './itemView/leftSidenav/leftSidenav.component';
import { FeedbackSideNavComponent } from './itemView/feedbackSidenav/feedbackSidenav.component';
import { FlagSideNavComponent } from './itemView/flagSidenav/flagSidenav.component';

import { ReassignFormComponent } from './reassignForm/reassignForm.component';

import { InstagramTicketService, TicketActivityService } from 'src/app/@tji/_dbShare';
import { AiCollectionModule } from "../aiCollection/aiCollection.module";
import { CatalogModule } from "../catalog/catalog.module";
import { ProductCatalogModule } from "../product-catalog/product-catalog.module";
import { FbTemplateImportModule } from "../fbTemplate/fb-template-import.module";
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ActivityComponent,
        ActivityListComponent,
        ItemComponent,
        ClosedListComponent,
        AbandonComponent,
        CommentExportComponent,
        ReassignFormComponent,
        TicketListComponent,
        LeftComponent,
        LeftSideNavComponent,
        FeedbackSideNavComponent,
        FlagSideNavComponent,
        SidebarComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ItemComponent,
        ClosedListComponent,
        AbandonComponent,
        CommentExportComponent
    ],
    providers: [
        InstagramTicketService,
        TicketActivityService
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        InstagramTicketRouteModule,
        AgentDashboardModule,
        UtilsModule,
        AiCollectionModule,
        CatalogModule,
        ProductCatalogModule,
        FbTemplateImportModule
    ]
})

export class InstagramTicketModule { }
