import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import {
  User,
  UserService,
  AlertService,
  ImageService,
  IntractiveService,
  IntractiveGroupService,
  IntractiveGroup,
  CatalogProductService,
  catalogProduct,
  ListProductSectionModel,
} from "src/app/@tji/_dbShare";

@UntilDestroy()
@Component({
  selector: "change-intractive-catalog-product",
  templateUrl: "./changeIntractiveCatalogProduct.component.html",
  styleUrls: ["./changeIntractiveCatalogProduct.component.scss"],
})
export class ChangeIntractiveCatalogProductComponent implements OnInit {
  subLoader: boolean = false;
  dialogTitle: string = "Add / Change Product";
  authUser: User;
  intractiveGroup: IntractiveGroup;

  submitted: boolean = false;
  titleInputBox: boolean = false;
  bodyInputBox: boolean = true;
  headerInputBox: boolean = false;
  sectionError: boolean = false;
  selectedProductError: string = "Please select a Type of Product";
  catalogId: any;
  catalogName: any;
  typeList = [
    {
      code: 1,
      name: "Single Product",
    },
    {
      code: 2,
      name: "Multiple Products",
    },
    {
      code: 3,
      name: "Catalog",
    },
  ];
  selectedProductLists: catalogProduct[] = [];
  productAdd: any[] = [];
  loader: boolean = false;
  productCatalogForm: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(""),
    bodyText: new UntypedFormControl(""),
    headerText: new UntypedFormControl(""),
  });
  intractiveGroupProduct: IntractiveGroup;
  productList: catalogProduct[] = [];

  constructor(
    public userService: UserService,
    public intractiveGroupService: IntractiveGroupService,
    public imageService: ImageService,
    public intractiveService: IntractiveService,
    public catalogProductService: CatalogProductService,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<ChangeIntractiveCatalogProductComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.catalogProductService.allIntractiveProductItems
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data && data["additional"] && data["additional"]["CatalogInfo"]) {
          this.catalogId = data["additional"]["CatalogInfo"]["id"];
          this.catalogName = data["additional"]["CatalogInfo"]["name"];
          this.productList = data["data"] ? data["data"] : [];
          if (this.productList && this.productList.length > 0) {
            this.intractiveGroupService.item
              .pipe(untilDestroyed(this))
              .subscribe((data) => {
                if (data !== this.intractiveGroup) {
                  this.subLoader = false;
                }
                this.intractiveGroup = data;
                if (
                  data &&
                  data.intractive_id &&
                  data.intractive_id &&
                  data.json
                ) {
                  this.intractiveGroupProduct = JSON.parse(data.json);
                  if (this.intractiveGroupProduct && this.intractiveGroupProduct["action"] && this.intractiveGroupProduct["action"].sections) {
                    this.actionList = this.intractiveGroupProduct['action'].sections.length;
                  }

                  this.productFormStature(this.intractiveGroupProduct);
                }
              });
          }
        }
      });
  }

  ngOnInit(): void {
    this.productCatalogForm = this.formBuilder.group({
      type: ["", Validators.required],
      bodyText: ["", Validators.required],
      headerText: [""],
      title: [""],
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.productCatalogForm.controls;
  }

  // Transfer Items Between Lists
  onDrop(event: any, index: any) {
    if (
      (this.selectedProductLists.length < 1 && this.productCatalogForm.controls["type"].value == 1) || (this.selectedProductLists.length < 1 && this.productCatalogForm.controls["type"].value == 3)) {
      this.catalogProductService.drop(event);
    } else if (this.selectedProductLists.length == 1 && this.productCatalogForm.controls["type"].value == 1) {
      this.alertService.webShow("danger", "only one product allowed to drag");
    } else if (this.selectedProductLists.length == 1 && this.productCatalogForm.controls["type"].value == 3) {
      this.alertService.webShow("danger", "only one product allowed to drag");
    } else if (this.productCatalogForm.controls["type"].value == 2) {
      this.catalogProductService.drop(event);
      if (event.container.data) {
        if (this.productSections[index].product_items.length > 0) {
          this.productSections[index].product_items =
            event.container.data.filter(
              (product, index, array) =>
                index ===
                array.findIndex((findTest) => findTest._id === product._id)
            );
          this.productSections[index]["productStatus"] = false;
        } else {
          this.productSections[index].product_items = event.container.data.filter((product, index, array) => index === array.findIndex((findTest) => findTest._id === product._id));
          this.productSections[index]["titleStatus"] = false;
          this.productSections[index]["productStatus"] = false;
          this.productSections[index]["titleError"] = "Please Enter Title";
          this.productSections[index]["productError"] =
            "Please Select The Product";
        }
      }
      if (this.productSections[index].product_items["id"] === "" || this.productSections[index].product_items["id"] === undefined || this.productSections[index].product_items["id"] === null) {
        this.productSections[index].product_items["id"] = (index + 1).toString();
      }
    } else {
      this.alertService.webShow("danger", "Please select a product type");
    }
  }

  selectType(e) {
    this.reserProduct();
    if (this.productCatalogForm.controls["type"].value == 2) {
      this.titleInputBox = true;
      this.bodyInputBox = true;
      this.headerInputBox = true;
      this.actionList = 1;
      this.productCatalogForm.controls["headerText"].setValidators(Validators.required);
      this.productCatalogForm.controls["headerText"].updateValueAndValidity();
      this.productSections.push(new ListProductSectionModel({}));
    } else {
      this.productSections = [];
      this.titleInputBox = false;
      this.bodyInputBox = true;
      this.headerInputBox = false;
      this.productCatalogForm.get('headerText').clearValidators();
      this.productCatalogForm.get('headerText').updateValueAndValidity();
    }
  }

  reserProduct() {
    this.productList.forEach(element => {
      element['isDragDrop'] = false;
    });
  }

  productData: any;
  productSingleData: any[];
  productCatalogStatus: boolean = false;
  bodyText: any;
  headerText: any;

  onSubmit(): void {
    this.submitted = true;
    delete this.productData;
    var productCatalogStatusData = [];
    if (this.productCatalogForm.invalid) {
      return;
    } else {
      if (this.productCatalogForm.controls["type"].value == 1) {
        if (this.selectedProductLists.length > 0) {
          // this.productData = {
          //   "product_type": "product",
          //   "action": {
          //     "catalog_id": this.catalogId,
          //     'product_retailer_id': this.selectedProductLists[0]['retailer_id']
          //   }
          // }
          this.productData = {
            product_type: "product",
            data: {
              type: "product",
              body: {
                text: this.bodyText ? this.bodyText : "",
              },
              action: {
                catalog_id: this.catalogId,
                product_retailer_id:
                  this.selectedProductLists[0]["retailer_id"],
              },
            },
          };
          this.addProductData(this.productData, "Signle Product", "1");
        } else {
          this.alertService.webShow("danger", "Add product From list");
        }
      }
      if (this.productCatalogForm.controls["type"].value == 3) {
        if (this.selectedProductLists.length > 0) {
          this.productData = {
            product_type: "catalog_message",
            data: {
              type: "catalog_message",
              body: {
                text: this.bodyText ? this.bodyText : "",
              },
              action: {
                name: "catalog_message",
                parameters: {
                  thumbnail_product_retailer_id:
                    this.selectedProductLists[0]["retailer_id"],
                },
              },
            },
          };
          this.addProductData(this.productData, "Catalog", "1");
        } else {
          this.alertService.webShow("danger", "Add product From list");
        }
      }
      if (this.productCatalogForm.controls["type"].value == 2) {
        if (!this.productCatalogForm.controls["title"].value) {
          this.sectionError = true;
          return;
        } else {
          this.sectionError = false;
        }
        if (this.productSections.length > 0) {
          var productAdd = {
            sections: [],
          };
          var productCount = 0;
          this.productSections.forEach((element, index) => {
            this.productCatalogStatus = false;
            if (!element.title || element.title.length == 0) {
              element.titleStatus = true;
            } else {
              element.titleStatus = false;
            }
            if (element.title.length != 0) {
              if (element.title.length < 2) {
                element.titleError = "Please enter a Minimum 3 characters";
                element.titleStatus = true;
              } else if (element.title.length > 101) {
                element.titleError = "Allwoed Maximum 100 characters only";
                element.titleStatus = true;
              } else {
                element.titleStatus = false;
              }
            }
            if (element.product_items.length == 0) {
              element.productStatus = true;
            } else {
              element.productStatus = false;
            }
          });
          this.productSections.forEach((element, index) => {
            this.productCatalogStatus = false;
            if (!element.title || element.product_items.length == 0) {
              productCatalogStatusData.push(false);
              this.productCatalogStatus = true;
            } else {
              productCatalogStatusData.push(true);
            }
          });
          const allEqual = productCatalogStatusData.every((val) => val == true);
          this.productCatalogStatus = allEqual;
          if (this.productSections.length > 0 && this.productCatalogStatus) {
            var sectionData = {
              title: "",
              product_items: [],
            };
            this.productSections.forEach((product) => {
              if (product.title) {
                sectionData = {
                  title: "",
                  product_items: [],
                };
                sectionData.title = product.title;
              }
              product.product_items.forEach((element) => {
                sectionData["product_items"].push({
                  product_retailer_id: element.retailer_id,
                });
                productCount = productCount + 1;
              });
              productAdd["sections"].push(sectionData);
            });
            this.productData = {
              product_type: "product_list",
              data: {
                type: "product_list",
                "header": {
                  "type": "text",
                  "text": this.headerText ? this.headerText : "",
                },
                body: {
                  text: this.bodyText ? this.bodyText : "",
                },
                action: productAdd,
              },
            };
            this.productData["data"]["action"]["catalog_id"] = this.catalogId;
            if (this.productCatalogStatus) {
              this.addProductData(
                this.productData,
                "Multiple Product",
                productCount
              );
            }
          }
        }
      }
    }
  }

  onTitleValidation() {
    this.sectionError =
      this.productCatalogForm.controls["title"].value == "" ? true : false;
    return this.productCatalogForm.controls["title"].value != "";
  }

  addProductData(data, product, productCount) {
    this.matDialogRef.close(["update", data, product, productCount]);
    delete this.bodyText;
    delete this.headerText;
    // this.loader = !this.loader;
    // let tempSub = this.commonService.storeItem('ecatalog/add', data, true, "optionOne").subscribe((res: any) => {
    //   if (res.success) {
    //     if (res.data && res.data.message) {
    //       this.alertService.webShow('Info', res.data.message);
    //     } else {
    //       this.alertService.webShow('Info', 'Updated Successfully !!!');
    //     }
    //     this.productCatalogService.getAllItems();
    //     this.submitted = false;
    //     this.productCatalogForm.reset();
    //     this.titleInputBox = false;
    //     delete this.selectedProductLists;
    //     this.loader = !this.loader;
    //     this.location.back();
    //   } else {
    //     this.loader = !this.loader;
    //     this.alertService.webErrorShow(res);
    //   }
    //   tempSub.unsubscribe();
    // }, error => {
    //   this.loader = !this.loader;
    //   this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
    //   console.error('There was an error!', error);
    //   tempSub.unsubscribe();
    // })
  }

  productSections: any[] = [];
  actionList: number = 1;
  intractiveListData: boolean = false;

  onActionProductCountChange(data: number) {
    this.intractiveListData = false;
    if (this.productSections.length < data) {
      this.productSections.forEach((element) => {
        if (element.title || element.product_items.length > 0) {
          this.intractiveListData = true;
        }
      });
      if (this.intractiveListData) {
        this.productSections.push(new ListProductSectionModel({}));
      } else {
        this.productSections = [];
        if (data && data > 0 && data <= 10) {
          for (var i = 1; i <= data; i++) {
            this.productSections.push(new ListProductSectionModel({}));
          }
        } else {
          this.productSections = [new ListProductSectionModel({})];
        }
      }
    } else {
      this.productSections.pop();
    }
  }

  deleteCatalogList(i) {
    if (this.productSections.length > 1) {
      this.reserProduct();
      this.productSections.forEach((productSection, index) => {
        if (index == i) this.productSections.splice(index, 1);
      });

      this.productSections.forEach(productSection => {
        if (productSection.product_items && productSection.product_items.length > 0) {
          productSection.product_items.forEach(item => {
            this.productList.forEach(element => {
              if (item._id === element._id) {
                element.isDragDrop = true;
              }
            });
          });
        }


      });

      this.actionList--;
    }
  }

  onChangeProductTitile(event, i) {
    this.productSections[i].title = event;
  }

  removeProduct(data, list: any = null, idx: any = null) {
    this.productList.forEach(element => {
      if (element._id == list._id) {
        element.isDragDrop = false;
        // this.catalogProductService.getAllIntractiveProduct(element)
      }
    });
    if (data == "single") {
      this.selectedProductLists = [];
    }
    if (data == "muliple") {
      this.productSections.forEach((prod, index) => {
        if (index == idx) {
          prod.product_items.forEach((item, index) => {
            if (item._id == list._id) {
              this.productSections[idx]["product_items"].splice(index, 1);
            }
          });
        }
      });
    }
  }

  productType: any = "Select Product Type";
  sectionNumber: number;

  productFormStature(product) {
    // var productType = product.type ? product.product_type : '';
    if (product.body && product.body.text) {
      this.bodyText = product.body.text;
    } else {
      this.bodyText = "";
    }
    if (product.header && product.header.text) {
      this.headerText = product.header.text;
    } else {
      this.headerText = "";
    }
    switch (product.type) {
      case "product":
        this.productSections = [];
        this.titleInputBox = false;
        this.bodyInputBox = true;
        this.headerInputBox = false;
        this.productType = 1;
        this.productCatalogForm.patchValue({
          type: 1,
          bodyText: this.bodyText,
        });
        if (this.productList && this.productList.length > 0) {
          this.productList.forEach((prod) => {
            if (product.action.product_retailer_id == prod.retailer_id) {
              prod['isDragDrop'] = true;
              this.selectedProductLists.push(prod);
              // this.catalogProductService.changeAllItemsByCatalogProductItem(prod);
            }
          });
        }
        break;
      case "product_list":
        this.titleInputBox = true;
        this.bodyInputBox = true;
        this.headerInputBox = true;
        // this.actionList = 1;
        this.productType = 2;
        this.productCatalogForm.patchValue({
          type: 2,
          bodyText: this.bodyText,
          headerText: this.headerText,
        });
        var productSection = {
          product_items: [],
        };
        if (product.action && product.action.sections && product.action.sections.length > 0) {
          // this.onActionProductCountChange(product.action.sections.length);
          // this.actionList = product.action.sections.length;
          if (this.productList && this.productList.length > 0) {
            product.action.sections.forEach((section, i) => {
              section.product_items.forEach((productItems, i) => {
                this.productList.forEach((prod) => {
                  if (productItems.product_retailer_id == prod.retailer_id) {
                    prod['isDragDrop'] = true;
                    productSection["product_items"].push(prod);
                    // this.catalogProductService.changeAllItemsByCatalogProductItem(prod);
                  }
                });
                productSection["title"] = section.title;
              });
              this.productSections.push(productSection);
              productSection = {
                product_items: [],
              };
            });
          }
        } else {
          this.actionList = 1;
          this.onActionProductCountChange(1);
        }
        break;
      case "catalog_message":
        this.productSections = [];
        this.titleInputBox = false;
        this.bodyInputBox = true;
        this.headerInputBox = false;
        this.productType = 3;
        this.productCatalogForm.patchValue({
          type: 3,
          bodyText: this.bodyText,
        });
        if (this.productList && this.productList.length > 0) {
          this.productList.forEach((prod) => {
            if (
              product.action.parameters.thumbnail_product_retailer_id ==
              prod.retailer_id
            ) {
              prod['isDragDrop'] = true;
              this.selectedProductLists.push(prod);
              // this.catalogProductService.changeAllItemsByCatalogProductItem(prod);
            }
          });
        }
        break;
      default:
        break;
    }
  }


  decreaseValue() {
    if (this.actionList > 1) {
      this.actionList--
      this.onActionProductCountChange(this.actionList);
    }

  }

  increaseValue() {
    if (this.actionList < 11) {
      if (this.actionList == 10) {
        this.onActionProductCountChange(this.actionList);
      } else {
        this.actionList++;
        this.onActionProductCountChange(this.actionList);
      }
    } else {
      this.actionList--;
      this.onActionProductCountChange(this.actionList);
      this.alertService.webShow(
        "danger",
        "only allowed to 10 product section"
      );
    }
  }

}
