import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	TagBoardService, TagBoard,
	TagService, Tag,
	BoardService, Board,
	ClientSiteService, ClientSite, ClientSiteModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-tag-form',
	templateUrl: './tagForm.component.html',
	styleUrls: ['./tagForm.component.scss']
})

export class TagFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	tag: Tag;
	boards: Board[] = [];
	clientSite: ClientSite;

	tagForm: UntypedFormGroup;
	newBoard: boolean = false;

	siteParam: any;
	action: string;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public boardService: BoardService,
		public matDialogRef: MatDialogRef<TagFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
		this.boardService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.boards = data);
		this.tagForm = this.createForm();
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.tag = data.tag;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Tag';
				break;
			case 'link':
				this.dialogTitle = (this.tag && this.tag.name) ? this.tag.name + ' - Linked To' : 'Linked To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			tag: [(this.tag && this.tag.name) ? this.tag.name : null, [Validators.required, Validators.minLength(1)]],
			board: [null, [Validators.minLength(1)]],
			board_id: [null],
			client_site_id: [this.clientSite.id || null, [Validators.required]],
		});
	}

}
