import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { QuickMessageFormComponent } from 'src/app/appAccount/agentDashboard/quickMessageForm/quickMessageForm.component';
import { QuickMessageMediaFormComponent } from 'src/app/appAccount/agentDashboard/quickMessageMediaForm/quickMessageMediaForm.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import {
	User, UserService,
	MessengerTicketService, MessengerTicket,
	TweetTicketService, TweetTicket,
	TweetdmTicketService, TweetdmTicket,
	WhatsappTicketService, WhatsappTicket,
	WebchatTicketService, WebchatTicket,
	YoutubeTicketService, YoutubeTicket,
	InstagramTicketService, InstagramTicket,
	InstadmTicketService, InstadmTicket,
	QuickMessageService, QuickMessage, QuickMessageModel,
	ChatService, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'ticket-quick-message',
	templateUrl: './quickMessage.component.html',
	styleUrls: ['./quickMessage.component.scss']
})

export class QuickMessageComponent implements OnInit, OnDestroy {
	user: User;
	ticket: any = null;
	ticketClassName: string = null;
	isDirectMessage: boolean = false;
	textLoader: boolean = false;
	searchData: any;
	quickMessages: QuickMessage[] = [];
	quickMessagesData: QuickMessage[] = [];

	constructor(public chatService: ChatService,
		public userService: UserService,
		public messengerTicketService: MessengerTicketService,
		public tweetTicketService: TweetTicketService,
		public tweetdmTicketService: TweetdmTicketService,
		public whatsappTicketService: WhatsappTicketService,
		public webchatTicketService: WebchatTicketService,
		public youtubeTicketService: YoutubeTicketService,
		public instagramTicketService: InstagramTicketService,
		public instadmTicketService: InstadmTicketService,
		public quickMessageService: QuickMessageService,
		public commonService: CommonService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.chatService.textLoader.pipe(untilDestroyed(this)).subscribe(data => this.textLoader = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			if (data != this.ticket) { this.chatService.changeTextLoader(false); }
			this.ticket = data;
			this.ticketClassName = (data && data.className) ? data.className : null;
		});
		this.quickMessageService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.quickMessages = data);
		this.quickMessageService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.quickMessagesData = data);
	}

	ngOnInit() {
		this.getQuickMessages();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getQuickMessages() {
		this.quickMessageService.getAllItems();
	}

	/** Add / Update QuickMessage */
	newQuickMessage(quickMessage = null): void {
		this.quickMessageService.changeItem((quickMessage) ? quickMessage : new QuickMessageModel({}));
		let dialogRef = this.matDialog.open(QuickMessageFormComponent, {
			width: "600px",
			data: {
				action: (quickMessage) ? 'edit' : 'new',
				item: quickMessage,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.quickMessageService.store(formData.value);
						break;
					case 'update':
						formData.value["id"] = id;
						this.quickMessageService.update(formData.value);
						break;
					case 'delete':
						this.deleteQuickMessage(quickMessage);
						break;
				}
			});
	}

	/** Delete Quick Message */
	deleteQuickMessage(quickMessage: QuickMessage): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var quick = {
					id: quickMessage.id,
				}
				this.quickMessageService.destroy(quick);
			}
		});
	}

	replyToTicket(quickMessage) {
		if (quickMessage.type == '2' && quickMessage.text) {
				var quickMsg = {
					text: quickMessage.text,
					isSend: true,
				}
				this.chatService.changeQuickMsg(quickMsg);
		} else {
			let messageData;
			this.chatService.setTextLoader();
			if (this.ticket && this.ticket.id) {
				switch (this.ticket.siteName) {
					case "Twitter": case "twitter":
					case "Tweet": case "tweet":
						messageData = {
							'showAlert': true,
							'tweet_status_id': this.ticket.tweetCode,
							'tweet_ticket_id': this.ticket.id,
							'is_direct_message': this.isDirectMessage,
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'recipient_id': this.ticket.recipientId,
							'parent_id': this.ticket.tweet_id,
							'quick_message_id': quickMessage.id,
						};
						break;
					case "Twitterdm": case "twitterdm":
					case "Tweetdm": case "tweetdm":
						messageData = {
							'showAlert': true,
							'tweet_status_id': this.ticket.tweetCode,
							'tweetdm_ticket_id': this.ticket.id,
							'is_direct_message': this.isDirectMessage,
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'recipient_id': this.ticket.recipientId,
							'parent_id': this.ticket.tweetdm_id,
							'quick_message_id': quickMessage.id,
						};
						break;
					case "Messenger": case "messenger":
						messageData = {
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'quickMessageId': quickMessage.id,
							'type': "quickmessage",
							'ticket_id': this.ticket.id,
						};
						break;
					case "Whatsapp": case "whatsapp":
						messageData = {
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'quickMessageId': quickMessage.id,
							'type': "quickmessage",
							'ticket_id': this.ticket.id,
						};
						break;
					case "Webchat": case "webchat":
						messageData = {
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'quickMessageId': quickMessage.id,
							'type': "quickmessage",
							'ticket_id': this.ticket.id,
						};
						break;
					case "Youtube": case "youtube":
						messageData = {
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'quickMessageId': quickMessage.id,
							'type': "quickmessage",
							'ticket_id': this.ticket.id,
						};
						break;
					case "Instagram": case "instagram":
						messageData = {
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'quickMessageId': quickMessage.id,
							'type': "quickmessage",
							'ticket_id': this.ticket.id,
						};
						break;
					case "Instadm": case "instadm":
						messageData = {
							'client_id': this.ticket.client_id,
							'client_site_id': this.ticket.clientSiteId,
							'quickMessageId': quickMessage.id,
							'type': "quickmessage",
							'ticket_id': this.ticket.id,
						};
						break;
					default:
						break;
				}
				this.chatService.post(messageData);
				// if(quickMessage && quickMessage.allAssets && quickMessage.allAssets.length > 0) {
				// 	this.sendWithFile(quickMessage, messageData);
				// } else {
				// 	this.chatService.post(messageData, '-quickmessage');
				// }
				this.chatService.setUnreadCount();
			}
			return;
		}
	}

	sendWithFile(quickMessage, messageData) {
		let message: string = messageData.message;
		if (quickMessage && quickMessage.allAssets && quickMessage.allAssets.length > 0) {
			let allAssets: Array<any> = quickMessage.allAssets;
			for (let i = 0; i < allAssets.length; i++) {
				let asset: any = allAssets[i];
				messageData.message = '';
				if (asset && asset.url && asset.type) {
					messageData.file = (asset && asset.url) ? asset.url : null;
					messageData.file_url = (asset && asset.url) ? asset.url : null;
					messageData.file_data = (asset && asset.url) ? asset.url : null;
					messageData.file_name = (asset && asset.name) ? asset.name : 'document';
					messageData.type = (asset && asset.type) ? asset.type : 'document';
				}
				this.chatService.post(messageData);
				messageData.file = null;
				messageData.file_url = null;
				messageData.file_data = null;
				messageData.file_name = null;
				messageData.type = null;
			}
			messageData.message = message;
			this.chatService.post(messageData);
		}
		return;
	}

	/** Add / Update QuickMessage Images */
	quickMessageImage(quickMessage = null, type = 'image'): void {
		this.quickMessageService.changeItem((quickMessage) ? quickMessage : new QuickMessageModel({}));
		let dialogRef = this.matDialog.open(QuickMessageMediaFormComponent, {
			width: "600px",
			data: {
				action: (quickMessage) ? 'edit' : 'new',
				item: quickMessage,
				type: type,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.quickMessageService.store(formData.value);
						break;
					case 'update':
						formData.value["id"] = id;
						this.quickMessageService.update(formData.value);
						break;
					case 'delete':
						this.deleteQuickMessage(quickMessage);
						break;
				}
			});
	}

	searchClear() {
		delete this.searchData;
		this.quickMessages = this.quickMessagesData;
		// setTimeout(() => {
		// 	this.quickMessageService.onSearch('')
		// }, 100);
	}

	/* * * * *
	* search
	* * * * * */
	searchQuickData() {
		var filterData = [];
		var quickDataSearch: any = [];
		var filterQuickKeys = ['title']
		quickDataSearch = this.quickMessagesData;
		if (this.searchData === '') {
			filterData = null;
			quickDataSearch = this.quickMessagesData;
			this.quickMessages = quickDataSearch;
		} else {
			if (quickDataSearch.length > 0) {
				for (let i = 0; i < quickDataSearch.length; i++) {
					if (filterQuickKeys.length > 0) {
						filterQuickKeys.forEach((key) => {
							if (typeof quickDataSearch[i][key] === 'string' && typeof this.searchData === 'string') {
								if (quickDataSearch[i][key].toLowerCase().indexOf(this.searchData.toLowerCase()) > -1) {
									const found = filterData.some(el => el.id === quickDataSearch[i].id);
									if (!found) {
										filterData.push(quickDataSearch[i]);
									}
								}
							}
						});
					} else {
						if (quickDataSearch[i].name.toLowerCase().indexOf(this.searchData.toLowerCase()) > -1) {
							filterData.push(quickDataSearch[i]);
						}
					}
				}
			}
			this.quickMessages = filterData;
		}
	}

}
