import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../../form/form.component';

import {
	User, UserService, ImageService,
	TeamService, Team, TeamModel,
	PersonTeamService, PersonTeam
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'team-item-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
	authUser: User;
	team: Team;
	baseImage: string = 'assets/img/no-user.jpg';
	
	constructor(public userService: UserService,
		public teamService: TeamService,
		public personTeamService: PersonTeamService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.teamService.item.pipe(untilDestroyed(this)).subscribe(data => this.team = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.teamService.unSubscribeFilter();
	}

	/** Add / Update Team */
	newTeam(team = null): void {
		this.teamService.changeItem((team) ? team : new TeamModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (team) ? 'edit' : 'new',
				item: team,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.teamService.store(formData.value, 'team/add');
						break;
					case 'update':
						var data = {
							id: id,
							name: formData.value.name,
						}
						this.teamService.profileUpdate(data, 'team/update', team);
						break;
					case 'delete':
						this.deleteTeam(team);
						break;
				}
			});
	}

	/** Delete Team */
	deleteTeam(team: Team): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var data = {
					id: team.id,
				}
				this.teamService.destroy(data, 'team/delete');
			}
		});
	}

	/** Delete Team */
	removePersonTeam(agent: any, personTeam): void {
		let message: string = 'Are you sure want to remove '+ personTeam.name +' from '+ this.team.name +' ?';
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: message,
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				 var data = {
				 	'team_id': agent.id,
					'user_id': personTeam.userInfo.id
				 }
				this.personTeamService.destroyAgent('team/removeAgent',data);
			}
		});
	}

}
