import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

import {
  User, UserService, DateModel
} from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-template-export-excel',
  templateUrl: './template-export-excel.component.html',
  styleUrls: ['./template-export-excel.component.scss'],
  providers: [OrderByPipe]
})

export class TemplateExportExcelComponent implements OnInit {

  user: User;
  dialogTitle: string = 'Export Report';
  date = new UntypedFormControl(new Date());
  dates = new UntypedFormControl(new Date());

  constructor(private formBuilder: UntypedFormBuilder, public userService: UserService, private orderByPipe: OrderByPipe, public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<TemplateExportExcelComponent>,
  ) {
    this.userService.user.subscribe(data => this.user = data);
  }

  ngOnInit(): void {
  }

}
