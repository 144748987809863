import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-softer-integration',
  templateUrl: './home-softer-integration.component.html',
  styleUrls: ['./home-softer-integration.component.scss']
})
export class HomeSofterIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
