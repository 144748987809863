import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, ClientService, Client, AccountUsageReport,
	ClientUsageService, ClientUsage
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'client-item-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {
	authUser: User;
	client: Client;
	clientUsageParam: any;
	clientUsages: AccountUsageReport[] = [];
	param: any;

	constructor(public userService: UserService,
		public clientService: ClientService,
		public clientUsageService: ClientUsageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.client = data;
			if(this.client && this.client.id) { this.getClientUsages(); }
		});
		this.clientUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.clientUsageParam = data);
		this.clientUsageService.accountUsages.pipe(untilDestroyed(this)).subscribe(data => this.clientUsages = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.clientUsageService.resetParams();
	}

	getClientUsages() {
		this.clientUsageParam.client_id = this.client.id;
		this.clientUsageService.getAllItems();
		this.clientUsageService.getAllAccountsVsTickets();
		this.clientUsageService.getAllAgentVsTickets();
		this.clientUsageService.getAllAccountsVsTemplate();
	}

	changeFromDate(event) {
		this.clientUsageParam.from_date = new Date(event.target.value).toLocaleString();
		this.clientUsageService.getAllItems();
		this.clientUsageService.getAllAccountsVsTickets();
		this.clientUsageService.getAllAgentVsTickets();
		this.clientUsageService.getAllAccountsVsTemplate();
	}

	changeToDate(event) {
		this.clientUsageParam.to_date = new Date(event.target.value).toLocaleString();
		this.clientUsageService.getAllItems();
		this.clientUsageService.getAllAccountsVsTickets();
		this.clientUsageService.getAllAgentVsTickets();
		this.clientUsageService.getAllAccountsVsTemplate();
	}

}
