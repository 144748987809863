import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { QuickMessage } from './quickMessage.interface';
import { QuickMessageModel } from './quickMessage_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { joiner } from '@ctrl/ngx-csv';

var slugify = require('slugify')

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class QuickMessageService implements Resolve<any> {
	url: string = 'quick-message';
	routeParams: any;
	defaultParams: any = {
		'limit': 1000,
		'current': 1,
	};

	sortIdentity: any = {
		'name': 'name'
	};

	private allItemsSource = new BehaviorSubject<QuickMessage[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<QuickMessage>(new QuickMessageModel({}));
	item = this.itemSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed QuickMessageService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on QuickMessageService');
	}

	changeAllItems(allItems: QuickMessage[]) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: QuickMessage) {
		this.itemSource.next(item);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: QuickMessage) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 0,
			'current': 0,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('quickMessage', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var leadData = data.data;

					if (leadData && leadData.length > 0) {
						leadData.forEach(quick => {

							if (quick.image_asset) {
								var imageAssets = [];
								var videoAssets = [];
								var documentAssets = [];
								var asset = JSON.parse(quick.image_asset);
								quick['imageCount'] = asset.filter(image => image.type == 'image').length;
								quick['videoCount'] = asset.filter(image => image.type == 'video').length;
								quick['documentCount'] = asset.filter(image => image.type == 'document').length;
								asset.forEach(asset => {
									if (asset.type == 'image') {
										imageAssets.push(asset);
										quick['imageAssets'] = imageAssets;
									}
									if (asset.type == 'document') {
										documentAssets.push(asset);
										quick['documentAssets'] = documentAssets;
									}
									if (asset.type == 'video') {
										videoAssets.push(asset);
										quick['videoAssets'] = videoAssets;
									}
								});
							} else {
								quick["imageCount"] = 0;
								quick["videoCount"] = 0;
								quick["documentCount"] = 0;
							}

						});

						this.changeAllItems(leadData);
					} else {
						this.changeAllItems([]);
					}

				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.items.data);
					if (data.items.last_page <= data.items.current_page) {
						params.page = data.items.last_page;
						this.changeParams(params);
					}
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: QuickMessage) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.item);
					this.changeItem(data.item);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new QuickMessageModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem('quickMessage/add', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					data.data["imageCount"] = 0;
					data.data["videoCount"] = 0;
					data.data["documentCount"] = 0;
					this.concatItem(data.data);
					this.changeItem(data.data);
					this.alert('Success', 'Created Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(data: any) {
		this.commonService.storeItem('quickMessage/update', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data.image_asset) {
						var imageAssets = [];
						var videoAssets = [];
						var documentAssets = [];
						var asset = JSON.parse(data.data.image_asset);
						data.data['imageCount'] = asset.filter(image => image.type == 'image').length;
						data.data['videoCount'] = asset.filter(image => image.type == 'video').length;
						data.data['documentCount'] = asset.filter(image => image.type == 'document').length;
						asset.forEach(asset => {
							if (asset.type == 'image') {
								imageAssets.push(asset);
								data.data['imageAssets'] = imageAssets;
							}
							if (asset.type == 'document') {
								documentAssets.push(asset);
								data.data['documentAssets'] = documentAssets;
							}
							if (asset.type == 'video') {
								videoAssets.push(asset);
								data.data['videoAssets'] = videoAssets;
							}
						});
					} else {
						data.data["imageCount"] = 0;
						data.data["videoCount"] = 0;
						data.data["documentCount"] = 0;
					}
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
					this.alert('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(quick) {
		this.commonService.storeItem("quickMessage/delete", quick, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(quick.id);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	post(url, data: any) {
		this.commonService.storeMedia(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var leadData = data.data;

					if (leadData && leadData.id) {

						if (leadData.image_asset) {
							var imageAssets = [];
							var videoAssets = [];
							var documentAssets = [];
							var asset = JSON.parse(leadData.image_asset);
							leadData['imageCount'] = asset.filter(image => image.type == 'image').length;
							leadData['videoCount'] = asset.filter(image => image.type == 'video').length;
							leadData['documentCount'] = asset.filter(image => image.type == 'document').length;
							asset.forEach(asset => {
								if (asset.type == 'image') {
									imageAssets.push(asset);
									leadData['imageAssets'] = imageAssets;
								}
								if (asset.type == 'document') {
									documentAssets.push(asset);
									leadData['documentAssets'] = documentAssets;
								}
								if (asset.type == 'video') {
									videoAssets.push(asset);
									leadData['videoAssets'] = videoAssets;
								}
							});
							if (leadData['imageCount'] == 0) {
								leadData['imageAssets'] = [];
							}
							if (leadData['videoAssets'] == 0) {
								leadData['videoAssets'] = [];
							}	
							if (leadData['documentAssets'] == 0) {
								leadData['documentAssets'] = [];
							}
						} else {
							leadData["imageCount"] = 0;
							leadData["videoCount"] = 0;
							leadData["documentCount"] = 0;
						}

						this.changeAllItemsByItem(leadData);
						this.changeItem(leadData);
						this.alert('Info', 'Updated Successfully !!!');
					}
				} else {
					this.alertService.webErrorShow(data);
				}

				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
			},
				error => {
					console.log('Error ::' + error);
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

}