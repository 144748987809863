import { BrowserModule } from '@angular/platform-browser';
import { ComponentModule } from 'src/app/component/component.module';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from "@angular/common"; 
import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AppMatModule } from 'src/app/app-mat.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgPipesModule } from 'ngx-pipes';

import { TermsConditionModelComponent } from './model/terms-condition-model/terms-condition-model.component';
import { PolicyModelComponent } from './model/policy-model/policy-model.component';
import { SearchModelComponent } from './model/search-model/search-model.component';

import { ConfirmDialogComponent } from './dialog/confirm/confirm.component';
import { CloseDialogComponent } from './dialog/close/close.component';
import { CommentDialogComponent } from './dialog/comment/comment.component';
import { AlertComponent } from './dialog/alert/alert.component';
import { OtpDialogComponent } from './dialog/otp/otp.component';
import { MediaViewComponent } from './dialog/mediaView/mediaView.component';
// import { ToastrComponent } from './dialog/toastr/toastr.component';

import { TemplateFormComponent } from './forms/templateForm/templateForm.component';
import { LabelChangeFormComponent } from './forms/labelChangeForm/labelChangeForm.component';


import { InputComponent } from './dynamic-form/input/input.component';
import { ButtonComponent } from './dynamic-form/button/button.component';
import { SelectComponent } from './dynamic-form/select/select.component';
import { DateComponent } from './dynamic-form/date/date.component';
import { RadiobuttonComponent } from './dynamic-form/radiobutton/radiobutton.component';
import { CheckboxComponent } from './dynamic-form/checkbox/checkbox.component';
import { DynamicFieldDirective } from './dynamic-form/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form/dynamic-form/dynamic-form.component';
import { TextAreaComponent } from './dynamic-form/textarea/textarea.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatInputModule } from '@angular/material/input'; 
@NgModule({
    declarations: [
        TermsConditionModelComponent,
        PolicyModelComponent,
        SearchModelComponent,
        ConfirmDialogComponent,
        CloseDialogComponent,
        CommentDialogComponent,
        AlertComponent,
        OtpDialogComponent,
        MediaViewComponent,
        TemplateFormComponent,
        LabelChangeFormComponent,
        // ToastrComponent
        InputComponent,
        ButtonComponent,
        SelectComponent,
        DateComponent,
        RadiobuttonComponent,
        CheckboxComponent,
        DynamicFieldDirective,
        DynamicFormComponent,
        TextAreaComponent,
        ImageCarouselComponent,
    ],
    imports: [
        CommonModule,
        ComponentModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AppMatModule,
        FlexLayoutModule,
        TranslateModule,
        NgPipesModule,
        ClipboardModule,
        NgxPanZoomModule,
        NgxMatTimepickerModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        NgPipesModule,
        TermsConditionModelComponent,
        PolicyModelComponent,
        SearchModelComponent,
        ConfirmDialogComponent,
        CloseDialogComponent,
        CommentDialogComponent,
        AlertComponent,
        OtpDialogComponent,
        MediaViewComponent,
        TemplateFormComponent,
        LabelChangeFormComponent,
        // ToastrComponent
        DynamicFormComponent,
        NgxMatTimepickerModule,
        NgxPanZoomModule
    ],
    providers: [],
    schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class LibraryModule { }
