import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	YoutubeService, Youtube,
	CommonService,
	FeedService, Feed
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'open-conversation-youtube-history',
	templateUrl: './conversationYoutubeHistory.component.html',
	styleUrls: ['./conversationYoutubeHistory.component.scss']
})

export class ConversationYoutubeHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeYoutube: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Conversation Youtube History';
	authUser: User;
	feed: Feed;
	conversationYoutubeHistory: UntypedFormGroup;
	ticketClassName: string = null;

	youtubes: Youtube[] = [];
	youtubeParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;
	mediaImage: string;
	iframUrl: SafeResourceUrl = null;

	constructor(public userService: UserService,
		public feedService: FeedService,
		public youtubeService: YoutubeService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialog: MatDialog,
		private sanitizer: DomSanitizer,
		public matDialogRef: MatDialogRef<ConversationYoutubeHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.youtubeService.resetParams();
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
	}

	ngOnInit() {
		this.feedService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.feed = data;
		});
		this.youtubeService.params.pipe(untilDestroyed(this)).subscribe(data => this.youtubeParam = data);
		this.youtubeService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.youtubes = data;
			if (data && data.length > 0 && data[0].videoId) {
				this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data[0].videoEmbeddedUrl);
			}
		});
		this.doLoader();
		this.getConversations();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.youtubeService.changeAllItems([]);
		this.youtubeService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Conversation Youtube History';
				break;
			default:
				break;
		}
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	getConversations() {
		if (this._data && this._data.ticketClassName) {
			let sourceFrom = this._data.sourceFrom;

			if (sourceFrom == 'feed') {

				switch (this._data.ticketClassName) {
					case "Youtube\\Models\\Youtube": case "Youtube":
						this.youtubeParam.youtube_id = this.feed.resource_id;
						this.youtubeParam.channelId = this.feed.resource.channelId;
						this.youtubeParam.videoId = this.feed.resource.videoId;
						this.youtubeParam.is_itrs = null;
						this.youtubeParam.is_assigned = null;
						this.youtubeParam.all_history = 'true';
						this.youtubeParam.only = "id,text,youtube_id,reply_id,status,from,to,channelId,commentId,parentId,videoId,user_image,authorDisplayName,authorProfileImageUrl,created_by,updated_by,created_at,updated_at,people_id,json";
						this.youtubeService.getAllItems(this.youtubeParam);
						break;
					default:
						break;
				}

			} else {

				switch (this._data.ticketClassName) {
					case "Youtube\\Models\\Youtube": case "Youtube": case "youtube_ticket":
						this.youtubeParam.youtube_id = this._data.item.youtube_id;
						this.youtubeParam.channelId = this._data.item.channelId;
						this.youtubeParam.videoId = this._data.item.videoId;
						this.youtubeParam.is_itrs = null;
						this.youtubeParam.is_assigned = null;
						this.youtubeParam.all_history = 'true';
						this.youtubeParam.only = "id,text,youtube_id,reply_id,status,from,to,channelId,commentId,parentId,videoId,user_image,authorDisplayName,authorProfileImageUrl,created_by,updated_by,created_at,updated_at,people_id,json";
						this.youtubeService.getAllItems(this.youtubeParam);
						break;
					default:
						break;
				}
			}
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.youtubeService.changeAllItems([]);
		this.youtubeService.unSubscribe();
	}

	/** Close Feed */
	closeAllFeed(feed): void {
		let closeDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'close',
				message: 'Are you sure want to Close all Open Conversation from this media ? So, that you may lose your un-assigned conversations.',
				resource: feed,
				clientSiteId: (feed) ? feed.client_site_id : null,
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let formData: any = null;
				switch (feed.siteName) {
					case "Instagram":
						formData = {
							'mediaId': (feed && feed.resource && feed.resource.mediaId) ? feed.resource.mediaId : null,
							'mediaSource': 'open-conversation',
							'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
							'title': 'Ticket Closed'
						};
						break;
					case "Instadm":
						formData = {
							'mediaId': (feed && feed.resource && feed.resource.mediaId) ? feed.resource.mediaId : null,
							'mediaSource': 'open-conversation',
							'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
							'title': 'Ticket Closed'
						};
						break;
					case "Youtube":
						formData = {
							'mediaId': (feed && feed.resource && feed.resource.videoId) ? feed.resource.videoId : null,
							'mediaSource': 'open-conversation',
							'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
							'title': 'Ticket Closed'
						};
						break;
					default:
						break;
				}
				if (formData) {
					this.feedService.close(feed.id, formData, 'feed-close-allbymedia');
				}
			}
		});
	}
}
