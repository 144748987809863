import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-store-as-combined-form',
	templateUrl: './storeAsCombinedForm.component.html',
	styleUrls: ['./storeAsCombinedForm.component.scss']
})

export class StoreAsCombinedFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'User Response Stored As:';
	authUser: User;
	intractiveGroup: IntractiveGroup;
	action: string;
	storeAsCombinedForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<StoreAsCombinedFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.getInit(_data);
			this.storeAsCombinedForm = this.createForm();
			this.storeAsCombinedForm.get('json_store_as').disable();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		let intractiveGroup = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'User Response Stored As:';
				break;
			case 'edit':
				this.dialogTitle = 'User Response Stored As:';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		let json = (this.intractiveGroup && this.intractiveGroup.json) ? JSON.parse(this.intractiveGroup.json) : null;
		return this._formBuilder.group({
			typeName : 'api',
			json_store_as : [this.getJsonStoreAs() || null, [Validators.required, Validators.minLength(1)]],
			json_store_value : [this.getJsonStoreValue() || this.intractiveGroup.text || null, [Validators.required, Validators.minLength(1)]],
			key: "json_store_value"
		});
	}

	getJsonStoreAs(): string {
		let jsonStoreAs = '';
		if(this.intractiveGroup && this.intractiveGroup.json) {
			let jsonData = JSON.parse(this.intractiveGroup.json);
			if(jsonData && jsonData.store_as) {
		        jsonStoreAs = jsonData.store_as;
		    }
		}
		return jsonStoreAs;
	}

	getJsonStoreValue(): string {
		let jsonStoreValue = '';
		if(this.intractiveGroup && this.intractiveGroup.json) {
			let jsonData = JSON.parse(this.intractiveGroup.json);
			if(jsonData && jsonData.store_value) {
		        jsonStoreValue = jsonData.store_value;
		    } else {
		    	jsonStoreValue = this.intractiveGroup.text;
		    }
		}
		return jsonStoreValue;
	}

}
