import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	  loginForm: UntypedFormGroup;
    resetForm: UntypedFormGroup;
  	showForm: boolean = true;
    showResetForm: boolean = false;
  	showPasswordReset: boolean = false;
    apiError: String;
    logo: string = "./assets/img/scrm/logo.png";

	constructor(private _formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private router: Router) {
    this.checkLogin();
  }

	  ngOnInit() {
  		this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            remember: ['']
        });
  
      this.resetForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]]
        });
      this.clearApiError();
  	}

    get email() { return this.loginForm.get('email'); }
    get password() { return this.loginForm.get('password'); }
    get reset_email() { return this.resetForm.get('reset_email'); }

  	login(loginData) {
      // this.showForm = false;
        this.userService.login('login', loginData).subscribe(data => {
        	this.router.navigate(['/app/dashboard']);
        }, error => {
            this.showForm = true;
            this.apiError = error;
        });
    }

    clearApiError() {
      if(this.apiError && this.apiError !== null) {
        setTimeout(() => {
          this.apiError = null;
        }, 5000);
      }
    }

    resetPassword(resetData) {
      this.showResetForm = true;
        this.userService.sendResetLink('password/email', resetData).subscribe(data => {
          this.router.navigate(['/']);
        }, error => {
            this.showResetForm = false;
            this.apiError = error.error.message;
        });
    }

    checkLogin() {
      if(this.userService.authCheck()) {
        this.router.navigate(['/app/dashboard']);
      }
    }

}
