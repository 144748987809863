import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Setting } from './setting.interface';
import { SettingModel } from './setting_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';


var slugify = require('slugify')

@Injectable({
    providedIn: 'root',
})
@UntilDestroy()

export class DiagnosticService implements Resolve<any> {
    url: string = 'diagnostic';
    routeParams: any;
    defaultParams: any = {
        'all': 1,
        'search': '',
    };

    sortIdentity: any = {
        'name': 'name'
    };

    private allItemsSource = new BehaviorSubject<Setting[]>([]);
    allItems = this.allItemsSource.asObservable();

    private itemSource = new BehaviorSubject<Setting>(new SettingModel({}));
    item = this.itemSource.asObservable();

    private paramsSource = new BehaviorSubject<any>(this.defaultParams);
    params = this.paramsSource.asObservable();

    private _unsubscribeAll: Subject<any>;

    constructor(private globalService: GlobalService,
        private commonService: CommonService,
        private alertService: AlertService,
        private snackBar: MatSnackBar) {
        this._unsubscribeAll = new Subject();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.resetParams(),
                this.getAllItems(),
                this.getItem()
            ])
                .then(() => {
                    resolve(null);
                }, reject
                );
        });
    }

    unSubscribe() {
        // console.log('UnSubscribed SettingService');
    }

    unSubscribeFilter() {
        // console.log('UnSubscribed Filters on SettingService');
    }

    changeAllItems(allItems: Setting[]) {
        this.allItemsSource.next(allItems);
    }

    changeItem(item: Setting) {
        this.itemSource.next(item);
    }

    changeParams(parms: any) {
        this.paramsSource.next(parms);
    }

    changeAllItemsByItem(item: Setting) {
        let allItems = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
        if (allItems && allItems.length > 0) {
            for (var i = 0; i < allItems.length; ++i) {
                if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
            }
        }
        this.changeAllItems(allItems);
    }

    paramsInit(params: any) {
        let newParams: any;
        let key: any;
        if (params !== null) {
            newParams = params;
        } else {
            this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
        }

        for (key in newParams) {
            if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
                delete newParams[key];
            }
        }
        return newParams;
    }

    resetParams() {
        const defaultParams: any = {
            'all': 1,
            'search': '',
        };
        this.changeParams(this.paramsInit(defaultParams));
    }

    getAllItems(params: any = null) {
        params = this.paramsInit(params);
        this.commonService.getAll(this.url, params)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                if (params.all && params.all === 1) {
                    this.changeAllItems(data.items);
                } else {
                    this.changeAllItems(data.items.data);
                }
            },
                error => console.log('Error ::' + error)
            );
    }

    concatAllItems(params: any = null) {
        params = this.paramsInit(params);
        this.commonService.getAll(this.url, params)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                let lists = [];
                this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
                    lists = result.concat(data.items.data);
                    if (data.items.last_page <= data.items.current_page) {
                        params.page = data.items.last_page;
                        this.changeParams(params);
                    }
                });
                this.changeAllItems(lists);
            },
                error => console.log('Error ::' + error)
            );
    }

    concatItem(item: Setting) {
        let lists = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
            lists = result.concat([item]);
        });
        this.changeAllItems(lists);
    }

    spliceItem(id: number) {
        let allItems = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
        if (allItems && allItems.length > 0) {
            for (var i = 0; i < allItems.length; ++i) {
                if (allItems[i].id === id) { allItems.splice(i, 1); }
            }
        }
        this.changeAllItems(allItems);
    }

    getItem(params: any = null) {
        this.routeParams = (params) ? params : this.routeParams;
        if (this.routeParams && this.routeParams.id > 0) {
            this.commonService.getItem(this.url, this.routeParams.id)
                .pipe(untilDestroyed(this, 'unSubscribe'))
                .subscribe(data => {
                    this.changeAllItemsByItem(data.item);
                    this.changeItem(data.item);
                },
                    error => console.log('Error ::' + error)
                );
        }
        else {
            this.changeItem(new SettingModel({}));
        }
    }

    store(data: any) {
        this.commonService.storeItem(this.url, data)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.concatItem(data.item);
                this.changeItem(data.item);
                this.alert('Success', 'Created Successfully !!!');
            },
                error => {
                    console.log('Error ::' + error);
                    this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
                }
            );
    }

    update(id: number, data: any) {
        this.commonService.updateItem(this.url, id, data)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.changeAllItemsByItem(data.item);
                this.changeItem(data.item);
                this.alert('Info', 'Updated Successfully !!!');
            },
                error => {
                    console.log('Error ::' + error);
                    this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
                }
            );
    }

    destroy(id: number) {
        this.commonService.deleteItem(this.url, id)
            .pipe(untilDestroyed(this, 'unSubscribe'))
            .subscribe(data => {
                this.spliceItem(id);
                this.alert('Danger', 'Destroyed Successfully !!!');
            },
                error => {
                    console.log('Error ::' + error);
                    this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
                }
            );
    }

    getValue(category: string, field: string): any {
        let items: Setting[];
        let value = '';
        category = (slugify(category, { lower: true }));
        field = (slugify(field, { lower: true }));
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => {
            items = data;
        });
        for (var i = 0; i < items.length; ++i) {
            let setCategory = slugify(items[i].category, { lower: true });
            let setField = slugify(items[i].field, { lower: true });
            if (setCategory === category && setField === field) {
                return value = items[i].value;
            }
        }
    }

    /** Scroll Event */
    onScroll() {
        let newParams: any;
        this.params
            .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page += 1;
                this.changeParams(newParams);
                this.concatAllItems();
            });
    }

    /** Search Event */
    onSearch(input: string) {
        let newParams: any;
        this.params
            .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page = 1;
                newParams.search = input;
                this.changeParams(newParams);
                this.getAllItems();
            });
    }

    pageEvent(event) {
        let newParams: any;
        this.params
            .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page = event.pageIndex + 1;
                newParams.paginate = event.pageSize;
                this.changeParams(newParams);
                this.getAllItems();
            });
    }

    getSortName(input: string) {
        let sortName = 'name';
        sortName = (input) ? this.sortIdentity[input] : sortName;
        return sortName;
    }

    sortData(event) {
        let newParams: any;
        this.params
            .pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
            .subscribe(data => {
                newParams = data;
                newParams.page = 1;
                newParams.order = this.getSortName(event.active) + '|' + event.direction;
                this.changeParams(newParams);
                this.getAllItems();
            });
    }

    alert(type: string, message) {
        this.alertService.webShow(type, message);
        // let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
        // this.snackBar.openFromComponent(AlertComponent, {
        //     panelClass: ['alert' + capitalType],
        //     data: {
        //         message: message,
        //         type: type.toLowerCase(),
        //     }
        // });
    }

    clearRouteHitFun(param = null) {
        let newUrl: string = this.url + '/' + param;
        this.commonService.hitUrl(newUrl)
            .pipe()
            .subscribe(data => {
                this.alert('Success', 'Cleared Successfully !!!');
            },
                error => console.log('Error ::' + error)
            );
    }

}