import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import { User, UserService,
         LogService, Log, AgentService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'log-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

    user: User;
    params: any;

    constructor(public userService: UserService,
        public logService: LogService,
        private router: Router,
        public agentService: AgentService,
        public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.agentService.params.subscribe(data => this.params = data);
	}

	ngOnInit() {
        this.params.limit = 200;
        this.agentService.getAllItems();
	}

	/** On destroy */
    ngOnDestroy(): void {
        this.agentService.unSubscribe();
    }

}
