import { PersonDetail } from '../person_details/person_detail.interface';
import { PersonDetailModel } from '../person_details/person_detail_model.model';

export class ReservePeopleModel {
	id: number;
	name: string;
	unique_ref: string;
	client_id: number;
	client_site_id: number;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	tableName: string;
	siteName?: string;
	whatsappNumber?: string;
	avatar_url?: string;

	detail?: any;
	uuid?: string;
	labelId?: number;
	labelName?: string;
	labelColor?: string;
	labelColorText?: string;
	reserveAgentId?: number;
	reserveAgentName?: string;
	reserveAgentAvatarUrl?: string;
	reserveAgentLiveStatus?: string;
	clientSiteName?: string;
	clientSiteColor?: string;
	clientSiteTextColor?: string;
	requiredFiled?: any;
	personalInfo?: any;
	isDelete?: boolean;

	/**
	 * Constructor
	 *
	 * @param people
	 */
	constructor(people) {
		this.id = people.id || '';
		this.name = people.name || '';
		this.unique_ref = people.unique_ref || '';
		this.client_id = people.client_id || '';
		this.client_site_id = people.client_site_id || '';
		this.created_by = people.created_by || '';
		this.updated_by = people.updated_by || '';
		this.created_at = people.created_at || '';
		this.updated_at = people.updated_at || '';

		this.tableName = people.tableName || '';
		this.detail = people.detail || null;
		this.uuid = people.uuid || null;
		this.requiredFiled = people.requiredFiled || null;
		this.personalInfo = people.personalInfo || null;
		this.reserveAgentName= people.reserveAgentName || null;
		this.reserveAgentAvatarUrl= people.reserveAgentAvatarUrl || null;
		this.reserveAgentLiveStatus= people.reserveAgentLiveStatus || null;
		this.isDelete = people.isDelete || false;
	}
}
