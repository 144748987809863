import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../../form/form.component';

import {
	User, UserService, ImageService,
	ResellerService, Reseller, ResellerModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'reseller-item-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
	authUser: User;
	reseller: Reseller;

	constructor(public userService: UserService,
		public resellerService: ResellerService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.resellerService.item.pipe(untilDestroyed(this)).subscribe(data => this.reseller = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	storeImage(event, resellerId = null) {
		resellerId = (resellerId) ? resellerId : this.reseller.id;
		let input = {
			'resource_type': 'reseller',
			'resource_id': resellerId,
			'storeMode': 'replace'
		};
		this.imageService.storeImage(event, input);
		this.imageHandler(event);
	}

	imageHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				// this.reseller.imageId = 1;
				this.reseller.avatar_url = e.target.result;
				// need to run CD since file load runs outside of zone
				this.cd.markForCheck();
			};
		}
	}

	/** Add / Update Reseller */
	newReseller(reseller = null): void {
		this.resellerService.changeItem((reseller) ? reseller : new ResellerModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (reseller) ? 'edit' : 'new',
				item: reseller,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.resellerService.store(formData.value);
						break;
					case 'update':
						this.resellerService.update(id, formData.value);
						break;
				}
			});
	}

}
