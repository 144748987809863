import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	ConsumerGroupService, ConsumerGroup,
	IntractiveService, Intractive,
	IntractiveGroupService, IntractiveGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-chooseconsumergroupform',
	templateUrl: './chooseConsumerGroupForm.component.html',
	styleUrls: ['./chooseConsumerGroupForm.component.scss']
})

export class ChooseConsumerGroupFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	itrsbot: Itrsbot;
	consumerGroups: ConsumerGroup[] = [];
	intractiveGroup: IntractiveGroup;
	action: string;
	chooseConsumerGroupForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public consumerGroupService: ConsumerGroupService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public matDialogRef: MatDialogRef<ChooseConsumerGroupFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.consumerGroupService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.consumerGroups = data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.getInit(_data);
			this.chooseConsumerGroupForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		let intractive = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Link With Consumer Group';
				break;
			case 'edit':
				this.dialogTitle = 'Change Consumer Group';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			consumer_group_id: [this.intractiveGroup.consumer_group_id || null, [Validators.required]],
			json_consumer_action: [this.getJsonConsumerAction() || null, [Validators.required, Validators.minLength(1)]],
			update_on : 'json',
			is_active: [1],
		});
	}

	getJsonConsumerAction(): string {
		let jsonConsumerAction = '';
		if(this.intractiveGroup && this.intractiveGroup.json) {
			let jsonData = JSON.parse(this.intractiveGroup.json);
			if(jsonData && jsonData.consumer_action) {
		        jsonConsumerAction = jsonData.consumer_action;
		    }
		}
		return jsonConsumerAction;
	}


}
