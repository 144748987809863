import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { StoreAsFormComponent } from './../storeAsForm/storeAsForm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChooseIntractiveFormComponent } from './../chooseIntractiveForm/chooseIntractiveForm.component';
import { ChooseJumpIntractiveFormComponent } from './../chooseJumpIntractiveForm/chooseJumpIntractiveForm.component';
import { TicketableIntractiveFormComponent } from './../ticketableIntractiveForm/ticketableIntractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { ShortCodeFormComponent } from './../shortCodeForm/shortCodeForm.component';
import { LinkIntractiveMessageFormComponent } from './../linkIntractiveMessageForm/linkIntractiveMessageForm.component';

import {
	User, UserService,
	ClientSiteService, ClientSite,
	IntractiveService, Intractive, IntractiveModel,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel,
	IntractiveCommandService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-itrs-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, OnDestroy {

	@Input() intractive: Intractive;
	authUser: User;
	clientSite: ClientSite;
	primaryTicketImg: string = 'assets/img/no-user.jpg';

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public intractiveCommandService: IntractiveCommandService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	/** Add / Update Tag */
	newIntractiveGroup(intractiveGroup = null): void {
		this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(SimpleFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
				isChoiceMsg: this.isItrsCombined(this.intractive),
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
			});
	}

	/** Add / Update IntractiveGroup Json */
	updateIntractiveGroupJson(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(StoreAsFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: intractiveGroup
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				formData.value["intractive_group_id"] = id;
				this.intractiveGroupService.updateSaveAsGroup(formData.value);
			});
	}

	/** Update JumpIntractive  */
	updateJumpIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(ChooseJumpIntractiveFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: intractive
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				var formData = {
					"id": intractive.id,
					"key": "jump_intractive_id",
					"value": formDatas.value.jump_intractive_id
				}
				this.intractiveService.updateData('Intractive/options', formData);
			});
	}

	/** Delete Intractive */
	deleteIntractiveGroup(intractiveGroup): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete this message?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			var formData = {
				id: intractiveGroup.id,
			}
			this.intractiveGroupService.destroy('interactiveGroup/delete', formData, intractiveGroup);
		});
	}

	/** Add / Update Intractive */
	newIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(IntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
				switch (actionType) {
					case 'new':
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
				}
			});
	}

	/** Delete Intractive */
	deleteIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete this template?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete', formData);
				this.intractiveService.removelibraryById(intractive.id);
			}
		});
	}

	/** Add / Change IntractiveGroup Images */
	imageIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChangeIntractiveGroupImageComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
			});
	}

	isItrsCombined(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'feedback' || slug === 'survey' || slug === 'one-time-survey')) ? true : false;
	}

	getOptions(group: IntractiveGroup) {
		return (group && group.json) ? JSON.parse(group.json) : null;
	}

	/** Add / Update Change Intractive */
	linkIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChooseIntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.intractiveGroupService.store(formData.value);
						break;
					case 'update':
						this.intractiveGroupService.update(id, formData.value);
						break;
				}
			});
	}

	/** Add / Update Change Intractive */
	markIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(TicketableIntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
					case 'update':
						var formData = {
							id: id,
							key: 'is_closeable',
							value: formDatas.value.is_closeable,
						}
						this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
						break;
				}
			});
	}

	/** Add / Update Intractive */
	newShortCode(intractive): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(ShortCodeFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.intractiveCommandService.addShortCode('Intractive/addshortcode', formData.value);
				this.intractive.shortCode = formData.value['command'];
			});
	}

	getStoreAsValue(intractiveGroup): string {
		let json = '';
		if (intractiveGroup && intractiveGroup.json) {
			let jsonData = JSON.parse(intractiveGroup.json);
			if (jsonData && jsonData.store_as) {
				json = jsonData.store_as;
			}
		}
		return json;
	}

	hasStoreAsValue(intractiveGroup): boolean {
		if (intractiveGroup && intractiveGroup.json) {
			let jsonData = JSON.parse(intractiveGroup.json);
			if (jsonData && jsonData.store_as) {
				return true;
			}
		}
		return false;
	}

	/** Add / Update IntractiveGroup With Intractive Message Id */
	updateIntractiveGroupWithIntractiveMessageId(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(LinkIntractiveMessageFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup && intractiveGroup.intractive_message_id) ? 'edit' : 'new',
				item: intractiveGroup
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formDatas: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				const intractiveId: number = (intractiveGroup && intractiveGroup.intractive_id) ? intractiveGroup.intractive_id : this.intractive.id;
				const intractiveGroupId: number = (intractiveGroup && intractiveGroup.id) ? intractiveGroup.id : id;
				var formData = {
					id: id,
					key: 'intractive_message_id',
					value: formDatas.value.intractive_message_id,
				}
				this.intractiveGroupService.updateSubscribe('interactiveGroup/options', formData);
				// this.intractiveGroupService.update(intractiveGroupId, formData.value);
			});
	}
	
  removeNextIntractiveGroup(intractiveGroup: IntractiveGroup) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400',
      data: {
        type: 'delete',
        message: 'Are you sure you want to Remove this ITRS?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var data = {
          next_intractive_id: null,
			    is_active: 1,
        }
        this.intractiveGroupService.update(intractiveGroup.id, data);
      }
    });
  }
}
