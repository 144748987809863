import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';

import {
    User, UserService,
    MediaService, Media,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'gallery-table-view',
    templateUrl: './tableView.component.html',
    styleUrls: ['./tableView.component.scss'],
    providers: [OrderByPipe]
})

export class TableViewComponent implements OnInit, OnDestroy {
    authUser: User;
    loader: boolean = true;
	subLoader: boolean = false;
    
    @Input() galleries: Media[] = [];
    @Output() onDelete = new EventEmitter();
    @Output() onFavorite = new EventEmitter();
    @Output() onShareMedia = new EventEmitter();

    dataSource = new MatTableDataSource(this.galleries);
    displayedColumns = ['date', 'image', 'name', 'action'];
    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    param: any;

    // MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;

    constructor(public userService: UserService,
        public mediaService: MediaService,
        private cd: ChangeDetectorRef,
        private orderByPipe: OrderByPipe) {
        this.mediaService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
            this.galleries = this.orderByPipe.transform(data, '-_id');
            this.dataSource = new MatTableDataSource(this.galleries);
            this.dataSource.sort = this.sort;
        });
        this.mediaService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
        this.mediaService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {this.dataLength = data});
    }

    ngOnInit() { }

    /** On destroy */
    ngOnDestroy(): void { }

    showPreview(media: Media) {
        this.mediaService.changeItem(media);
    }

    setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}
}
