import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { FormComponent } from './../../form/form.component';

import {
	User, UserService, ImageService,
	AgentService, Agent, AgentModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-item-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
	authUser: User;
	agent: Agent;
	agentList: any[];
	superAgentList = [];
	constructor(public userService: UserService,
		public agentService: AgentService,
		public imageService: ImageService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.agent = data;
			if (this.agent && this.agent.id) {
				this.agentService.allSupervisorAgents.pipe(untilDestroyed(this)).subscribe(data => {
					this.agentList = data;
					this.getSupervice();
				});
			}
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.agentService.unSubscribeFilter();
	}

	storeImage(event, agentId = null) {
		agentId = (agentId) ? agentId : this.agent.id;
		let input = {
			'resource_type': 'agent',
			'resource_id': agentId,
			'storeMode': 'replace'
		};
		this.imageService.storeImage(event, input);
		this.imageHandler(event);
	}

	imageHandler(event) {
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				// this.agent.imageId = 1;
				this.agent.avatar_url = e.target.result;
				// need to run CD since file load runs outside of zone
				this.cd.markForCheck();
			};
		}
	}

	/** Add / Update Agent */
	newAgent(agent = null): void {
		this.agentService.changeItem((agent) ? agent : new AgentModel({}));
		let dialogRef = this.matDialog.open(FormComponent, {
			width: "600px",
			data: {
				action: (agent) ? 'edit' : 'new',
				item: agent,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.agentService.store('agent/add', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.agentService.update('agent/update', formData.value, agent);
						break;
				}
			});
	}
	getSupervice() {
		if (this.agent && this.agent["supervisoragentids"] && this.agent["supervisoragentids"].length > 0 && this.agentList && this.agentList.length > 0) {
			var superAgentList = [];
			this.agent["supervisoragentids"].forEach(id => {
				this.agentList.forEach(agent => {
					if (agent.person_details.person_id == id) {
						var data = {
							'name': agent.name?agent.name: '---',
							'liveStatus': agent.liveStatus?agent.liveStatus: '---',
							'availability': agent.availability?agent.availability: '---',
						}
						superAgentList.push(data);
					}
				});
			});
			this.superAgentList = superAgentList.filter((item, index) => superAgentList.indexOf(item) === index);
		}
	}
}
