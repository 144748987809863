export class BranchModel {
	_id: string;
	name: string;
	email: string;
	client_id: number;
	created_by: number;
	updated_by: number;
	created_at: number;
	updated_at: number;
	address: string;
	availability: number;
	brand_id: string;
	is_headquarters: number;
	latitude: number;
	longitude: number;
	phoneNumber: number;
	pickup: 0;
	brand: any;
	delivery_within_until: any;
	delivery_within_after: any;
	work_time_end: any;
	work_time_start: any;
	type: any;


	/**
	 * Constructor
	 *
	 * @param Branch
	 */
	constructor(Branch) {

		this._id = Branch._id;
		this.name = Branch.name;
		this.email = Branch.email;
		this.client_id = Branch.client_id;
		this.created_by = Branch.created_by;
		this.updated_by = Branch.updated_by;
		this.created_at = Branch.created_at;
		this.updated_at = Branch.updated_at;
		this.address = Branch.address;
		this.availability = Branch.availability;
		this.brand_id = Branch.brand_id;
		this.is_headquarters = Branch.is_headquarters;
		this.latitude = Branch.latitude;
		this.longitude = Branch.longitude;
		this.phoneNumber = Branch.phoneNumber;
		this.pickup = Branch.pickup;
		this.brand = Branch.brand;
	}
}
