import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { PageRouteModule } from './page-route.module';
import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { LibraryModule } from 'src/app/library/library.module';
import { CommonModule } from '@angular/common';
import { AppAccountModule } from 'src/app/appAccount/appAccount.module';

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomeFooterComponent } from './home-footer/home-footer.component';
import { PolicyComponent } from './policy/policy.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

@NgModule({
    declarations: [
        MainComponent,
        HomeComponent,
        HomeHeaderComponent,
        HomeFooterComponent,
        PolicyComponent,
        FaqComponent,
        LoginComponent,
        RegisterComponent
    ],
    imports: [
        AppShareModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PageRouteModule,
        LibraryModule,
        CommonModule,
        AppAccountModule,
    ],
    exports: [
        LibraryModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AppAccountModule,
        MainComponent,
        HomeComponent,
        HomeHeaderComponent,
        HomeFooterComponent,
        PolicyComponent,
        FaqComponent,
        LoginComponent,
        RegisterComponent
    ],
    providers: []
})
export class PageModule { }
