export class FeedbackModel {
    id: number;
    resource_id: number;
    resource_type: string
    feedback_id: number;
    client_id: number;
    client_site_id: number;
    title: string;
    description: string;
    rating: number;
    person_id: number;
    person_type: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName: string;

    /**
     * Constructor
     *
     * @param feedback
     */
    constructor(feedback) {
            this.id = feedback.id || '';
            this.resource_id = feedback.resource_id || '';
            this.resource_type = feedback.resource_type || '';
            this.feedback_id = feedback.feedback_id || '';
            this.client_id = feedback.client_id || '';
            this.client_site_id = feedback.client_site_id || '';
            this.title = feedback.title || '';
            this.description = feedback.description || '';
            this.rating = feedback.rating || '';
            this.person_id = feedback.person_id || '';
            this.person_type = feedback.person_type || '';
            this.created_by = feedback.created_by || '';
            this.updated_by = feedback.updated_by || '';
            this.created_at = feedback.created_at || '';
            this.updated_at = feedback.updated_at || '';

            this.tableName = feedback.tableName || '';
    }
}
