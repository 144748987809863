import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Catalog, CatalogService, BusinessService, WabaService, ClientSiteService, BrandService } from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'assignClientBrand',
  templateUrl: './assign-client.component.html',
  styleUrls: ['./assign-client.component.scss']
})
export class AssignClientComponent {

  dialogTitle: string = 'Assign Client Site';
  catalog: any;
  clientSitesAll: any = null;
  brandItem: any;
  action: string;
  loader: boolean = true;

  constructor(public clientSiteService: ClientSiteService, public brandService: BrandService, private cd: ChangeDetectorRef,
    public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<AssignClientComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    // this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
    //   this.clientSitesAll = data;
    //   if (this.clientSitesAll && this.clientSitesAll.length > 0) {
    //     this.dataReconstract();
    //   }
    // });

    this.brandService.allClientSite.subscribe(data => {
      this.clientSitesAll = data;
    });

    this.brandService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.brandItem = data;
      if (this.brandItem && this.brandItem._id) {
        this.dataReconstract();
      }
    });
  }

  ngOnInit(): void {
    this.doLoader();
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 2000);
  }

  dataReconstract() {
    if (this.brandItem && this.brandItem._id && this.clientSitesAll && this.clientSitesAll.length > 0) {
      this.clientSitesAll.forEach(element => {
        element['isLinked'] = false;
      });

      // this.cd.detectChanges();
      if (this.brandItem && this.brandItem.clientSite && this.brandItem.clientSite.length > 0) {
        this.brandItem.clientSite.forEach(brand => {
          this.clientSitesAll.forEach(element => {
            if (element.id == brand.client_site_id) {
              element['isLinked'] = true;
            }
          });
        });
      }
    }
  }

  linkClientSite(clientSites: any, event: any) {
    var data = {
      "id": this.brandItem._id.toString(),
      "client_site_id": clientSites.id,
    }
    if (event.checked) {

      this.brandService.linkClientSite('brand/addclientsite', data);
    } else {
      let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600',
        data: {
          type: 'alert',
          message: 'All delivery settings will be removed on connected client site.',
        }
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          this.brandService.linkClientSite('brand/removeclientsite', data);
        } else {
          this.dataReconstract();
        }
      });
    }
  }
}


