import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialMediaManagementRouteModule } from './socialMediaManagement-route.module';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { PosterComponent } from './poster/poster.component';
import { PosterItemComponent } from './posterItem/posterItem.component';
import { NewPosterComponent } from './newPoster/newPoster.component';
import { PosterFormComponent } from './posterForm/posterForm.component';
import { PosterPreviewComponent } from './posterPreview/posterPreview.component';
import { RecentPostComponent } from './recentPost/recentPost.component';
import { ScheduleViewComponent } from './scheduleView/scheduleView.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ReportComponent } from './report/report.component';

import { PosterService, PosterScheduleService, ClientSiteService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        PosterComponent,
        PosterItemComponent,
        NewPosterComponent,
        PosterFormComponent,
        PosterPreviewComponent,
        RecentPostComponent,
        ScheduleViewComponent,
        GalleryComponent,
        ReportComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        SocialMediaManagementRouteModule,
        UtilsModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        PosterComponent,
        PosterItemComponent,
        NewPosterComponent,
        PosterFormComponent,
        PosterPreviewComponent,
        RecentPostComponent,
        ScheduleViewComponent,
        GalleryComponent,
        ReportComponent,
    ],
    providers: [
        PosterService,
        PosterScheduleService,
        ClientSiteService
    ]
})

export class SocialMediaManagementModule { }
