export class LeadReserveModel {
    id:number;
    people_id: number;
    agent_id: number;
    client_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    tableName?: string;
    peopleName?: string;
    agentName?: string;

    /**
     * Constructor
     * @param leadReserve
     */
    constructor(leadReserve) {
        this.id = leadReserve.id || null;
        this.people_id = leadReserve.people_id || null;
        this.agent_id = leadReserve.agent_id || null;
        this.client_id = leadReserve.client_id || null;
        this.created_by = leadReserve.created_by || null;
        this.updated_by = leadReserve.updated_by || null;
        this.created_at = leadReserve.created_at || null;
        this.updated_at = leadReserve.updated_at || null;
            
    }
}
