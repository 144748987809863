export var data = [
  {
    "name": "Incoming Conversation",
    "series": [
      {
        "name": "Monday",
        "value": 50
      },
      {
        "name": "Tuesday",
        "value": 60
      },
      {
        "name": "Wednesday",
        "value": 100
      },
      {
        "name": "Thursday",
        "value": 128 
      },
      {
        "name": "Friday",
        "value": 85
      },
      {
        "name": "Saturday",
        "value": 85
      },
      {
        "name": "Sunday",
        "value": 85
      }
    ]
  },

  {
    "name": "Assigned",
    "series": [
      {
        "name": "Monday",
        "value": 15
      },
      {
        "name": "Tuesday",
        "value": 30
      },
      {
        "name": "Wednesday",
        "value": 80
      },
      {
        "name": "Thursday",
        "value": 140
      },
      {
        "name": "Friday",
        "value": 120
      },
      {
        "name": "Saturday",
        "value": 40
      },
      {
        "name": "Sunday",
        "value": 13
      }
    ]
  },

  {
    "name": "Closed",
    "series": [
      {
        "name": "Monday",
        "value": 44
      },
      {
        "name": "Tuesday",
        "value": 89
      },
      {
        "name": "Wednesday",
        "value": 130
      },
      {
        "name": "Thursday",
        "value": 80
      },
      {
        "name": "Friday",
        "value": 11
      },
      {
        "name": "Saturday",
        "value": 56
      },
      {
        "name": "Sunday",
        "value": 13
      }
    ]
  }
];
