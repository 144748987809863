import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardRouteModule } from './dashboard-route.module';

import { MainComponent } from './main/main.component';

import { ResellerDashboardService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        DashboardRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent
    ],
    providers: [
        ResellerDashboardService
    ]
})

export class DashboardModule { }
