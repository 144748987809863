import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	RPersonSettingService, RPersonSetting, RPersonSettingModel,
	RSettingFieldService, RSettingField,
	ClientService, Client
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'person-setting-form',
	templateUrl: './personSettingForm.component.html',
	styleUrls: ['./personSettingForm.component.scss']
})

export class PersonSettingFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	personSetting: RPersonSetting;
	settingField: RSettingField;
	client: Client;
	action: string;
	personSettingForm: UntypedFormGroup;
	weeks: Array<any> = [
        {'id': 0, 'value': 'Sunday'},
        {'id': 1, 'value': 'Monday'},
        {'id': 2, 'value': 'TuesDay'},
        {'id': 3, 'value': 'WednesDay'},
        {'id': 4, 'value': 'ThursDay'},
        {'id': 5, 'value': 'FriDay'},
        {'id': 6, 'value': 'SaturDay'},
    ];

	constructor(public userService: UserService,
		public rpersonSettingService: RPersonSettingService,
		public rsettingFieldService: RSettingFieldService,
		public clientService: ClientService,
		public matDialogRef: MatDialogRef<PersonSettingFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => this.client = data);
		this.rsettingFieldService.item.pipe(untilDestroyed(this)).subscribe(data => this.settingField = data);
		this.rpersonSettingService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.personSetting = data;
			this.personSettingForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = (data.field) ? 'Add ' + data.field.title : 'New Setting';
				break;
			case 'edit':
				this.dialogTitle = (data.field) ? 'Edit ' + data.field.title : 'Edit Setting';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			person_id: [this.client.id || null, [Validators.required]],
			setting_field_id: [this.settingField.id || null, [Validators.required]],
			value: [(this.personSetting) ? this.personSetting.value : null || null, [Validators.minLength(1)]],
		});
	}

}
