import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shot-clients',
  templateUrl: './shot-clients.component.html',
  styleUrls: ['./shot-clients.component.scss']
})
export class ShotClientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
