import { headerHandle, bodyHandle } from './fbTools.interface';

export class FbToolModel {
    type:string;
    value: string;
    index: number;

    /**
     * Constructor
     *
     * @param toolData
     */
    constructor(toolData) {
            this.type = toolData.type || 'text';
            this.value = toolData.value || '';
            this.index = toolData.index || 0;
    }
}

export class FbTextModel {
    type:string;
    text: string;

    /**
     * Constructor
     *
     * @param textData
     */
    constructor(textData) {
            this.type = textData.type || 'text';
            this.text = textData.text || '';
    }
}

export class FbHeaderTextModel {
    type:string;
    format: string;
	text: string;

    /**
     * Constructor
     *
     * @param textData
     */
    constructor(textData) {
            this.type = textData.type || 'HEADER';
            this.text = textData.text || '';
            this.format = textData.text || 'TEXT';
    }
}

export class FbHeaderImageModel {
    type:string;
    format: string;
	example: headerHandle;

    /**
     * Constructor
     *
     * @param image
     */
    constructor(image) {
            this.type = image.type || 'HEADER';
            this.format = image.text || '';
            this.example = image.example || new headerHandleModel([]);
    }
}

export class FbHeaderVideoModel {
    type:string;
    format: string;
	example: headerHandle;

    /**
     * Constructor
     *
     * @param video
     */
    constructor(video) {
            this.type = video.type || 'text';
            this.format = video.text || '';
            this.example = video.example || new headerHandleModel([]);
    }
}

export class FbHeaderDocumentModel {
    type:string;
    format: string;
	example: headerHandle;

    /**
     * Constructor
     *
     * @param document
     */
    constructor(document) {
            this.type = document.type || 'text';
            this.format = document.text || '';
            this.example = document.example || new headerHandleModel([]);
    }
}
export class headerHandleModel {
    header_handle:[];

    /**
     * Constructor
     *
     * @param headerHandl
     */
    constructor(headerHandl) {
            this.header_handle = headerHandl.header_handle || [];
    }
}

export class FbVeriableModel {
    type:string;
    text: string;
    index: number;

    /**
     * Constructor
     *
     * @param textData
     */
    constructor(textData) {
            this.type = textData.type || 'text';
            this.text = textData.text || '';
            this.index = textData.index || 0;
    }
}


export class FbBodyModel {
    type:string;
    text: string;
	example: bodyHandle;
    parameters: any;

    /**
     * Constructor
     *
     * @param body
     */
    constructor(body) {
            this.type = body.type || 'text';
            this.text = body.text || '';
            this.example = body.example || new BodyHandleModel([]);
            this.parameters = body.parameters || new BodyParametersModel({});
    }
}

export class BodyHandleModel {
    body_text:[];

    /**
     * Constructor
     *
     * @param bodyHandel
     */
    constructor(bodyHandel) {
            this.body_text = bodyHandel.body_text || [];
    }
}

export class BodyParametersModel {
    name: string;
    value: any;

    /**
     * Constructor
     *
     * @param bodyHandel
     */
    constructor(bodyHandel) {
            this.name = bodyHandel.name || ''
            this.value = bodyHandel.value || '';
    }
}

export class FbFooterModel {
    type:string;
    text: string;

    /**
     * Constructor
     *
     * @param body
     */
    constructor(body) {
            this.type = body.type || 'text';
            this.text = body.text || '';
    }
}



export class FbCarouselHeaderImageModel {
    type:string;
    format: string;
	example: headerHandle;

    /**
     * Constructor
     *
     * @param image
     */
    constructor(image) {
            this.type = image.type || 'HEADER';
            this.format = image.text || '';
            this.example = image.example || new headerHandleModel([]);
    }
}

export class FbCarouselHeaderVideoModel {
    type:string;
    format: string;
	example: headerHandle;

    /**
     * Constructor
     *
     * @param video
     */
    constructor(video) {
            this.type = video.type || 'text';
            this.format = video.text || '';
            this.example = video.example || new headerHandleModel([]);
    }
}

export class FbCarouselFooterModel {
    type:string;
    buttons: any;

    /**
     * Constructor
     *
     * @param body
     */
    constructor(body) {
            this.type = body.type || 'text';
            this.buttons = body.text || [];
    }
}

// export class FbImagetModel {
//     type:string;
//     image: FbImageItem;

//     /**
//      * Constructor
//      *
//      * @param imagetData
//      */
//     constructor(imagetData) {
//             this.type = imagetData.type || 'image';
//             this.image = imagetData.image || new FbImageItemModel({});
//     }
// }

// export class FbImageItemModel {
//     link:string;
//     provider: any;

//     /**
//      * Constructor
//      *
//      * @param imageItemData
//      */
//     constructor(imageItemData) {
//             this.link = imageItemData.link || '';
//             this.provider = imageItemData.provider || '';
//     }
// }
