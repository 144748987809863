export class ClientSettingsModel {
	whatsappEmail: string;
	whatsappAddress: string;
	whatsappDescription: string;
	whatsappAbout: string;
	businessVertical: string;
	whatsappWebsites: any;
	avatar_url: any;
	allverticals: any;
	commerceSettings: any;
	governarates: any;
	paymentsMethod: any;
	mongo: any;
	AvailableGovernarates: any;
	AvailablePaymentMethods: any;

	/**
	 * Constructor
	 *
	 * @param client_site
	 */
	constructor(client_site) {
		this.whatsappEmail = client_site.whatsappEmail || ""
		this.whatsappAddress = client_site.whatsappAddress || ""
		this.whatsappDescription = client_site.whatsappDescription || ""
		this.whatsappAbout = client_site.whatsappAbout || ""
		this.businessVertical = client_site.businessVertical || ""
		this.whatsappWebsites = client_site.whatsappWebsites || ""
		this.avatar_url = client_site.avatar_url || ""
		this.allverticals = client_site.allverticals || ""
		this.commerceSettings = client_site.commerceSettings || ""
		this.governarates = client_site.governarates || ""
		this.paymentsMethod = client_site.paymentsMethod || ""
		this.mongo = client_site.mongo || ""
		this.AvailableGovernarates = client_site.AvailableGovernarates || ""
		this.AvailablePaymentMethods = client_site.AvailablePaymentMethods || ""
		
	}
	
}
