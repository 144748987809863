import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let $: any;
import { environment } from 'src/environments/environment';
import {
    PremiumDashboardService, PremiumDashboard, PremiumAlert,
    PremiumLiveTicket, EventService
} from 'src/app/@tji/_dbShare';
import * as moment from 'moment-timezone';
@UntilDestroy()
@Component({
    selector: 'premium-dashboard-live-tickets',
    templateUrl: './liveTickets.component.html',
    styleUrls: ['./liveTickets.component.scss'],
    animations: tjiAnimation,
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class LiveTicketsComponent {

    duration: number = 5000;
    animOutState: boolean = true;
    animInState: boolean = true;
    dashboardParam: any;
    liveTickets: PremiumLiveTicket[] = [];
    base_image: string = 'assets/img/no-user.jpg';
    channel: any;
    eventData: any;
    newLiveTickets: Array<any> = [];

    constructor(private router: Router,
        public premiumDashboardService: PremiumDashboardService,
        private eventService: EventService) {
        this.premiumDashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
        this.premiumDashboardService.channel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
        this.premiumDashboardService.liveTickets.pipe(untilDestroyed(this)).subscribe(data => this.liveTickets = data);
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });
    }

    ngOnInit() {
        this.checkDuration();
    }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName && eventName === 'aticketassigned') {
            let eventData = this.eventData;
            let data = this.eventData.data;
            if (eventData && data && data.id && data.clientSite.details.name) {
                if (this.liveTickets && this.liveTickets.length > 0) {
                    this.liveTickets.forEach((x, index) => {
                        if (x.ticketId === data.id && x.siteName.toLowerCase() == data.clientSite.details.name.toLowerCase()) {
                            this.liveTickets.splice(index, 1);
                        }
                    });

                }
                var liveTicket = {
                    "client_id": data.client_id,
                    "accountName": data?.clientSite?.account_name,
                    "siteName": data?.clientSite?.details?.name,
                    "agentName": data?.agentInfo?.name,
                    "peopleName": data?.leadInfo?.name,
                    "unique_ref": data?.leadInfo?.unique_ref,
                    "avatar_url": data?.agentInfo?.person_details?.image_api,
                    "ticketId": data?.id,
                    "ticketCode": data?.code,
                    "created_at": moment(data?.created_at + '.000+0300').local(),
                    "type": "livetickets"
                }
                this.premiumDashboardService.appendLiveTicket(liveTicket);
            }
        }
        if (eventName && eventName === 'ticketclosed') {
            let eventData = this.eventData;
            let data = this.eventData.data;
            if (eventData && data && data.id && data.clientSite.details.name) {
                if (this.liveTickets && this.liveTickets.length > 0) {
                    this.liveTickets.forEach((x, index) => {
                        if (x.ticketId === data.id && x.siteName.toLowerCase() == data.clientSite.details.name.toLowerCase()) {
                            this.liveTickets.splice(index, 1);
                        }
                    });

                }
                this.premiumDashboardService.changeAllLiveTickets(this.liveTickets);
            }
        }
    }

    checkDuration() {
        if (this.liveTickets.length > 20) { this.duration = 500; }
        else if (this.liveTickets.length > 10) { this.duration = 700; }
        else if (this.liveTickets.length > 6) { this.duration = 800; }
        else { this.duration = 12000; }
        setTimeout(() => {
            if (this.liveTickets.length > 5) {
                this.liveTickets.shift();
            }
            this.checkDuration();
        }, this.duration);
    }

}
