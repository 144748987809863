import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import * as moment from 'moment-timezone';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ClosedTicketService {
	url: string = 'closed-ticket';
	defaultParams: any = {
		'sort': 'updated_at_unix|desc',
		'current': 1,
		'is_closed': 1,
		'limit': 25,
	};

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Array<any>>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<any>({});
	item = this.itemSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	public isSearchLoader = new BehaviorSubject<boolean>(false);
	searchLoader = this.isSearchLoader.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private snackBar: MatSnackBar,
		private alertService: AlertService,) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([
				this.getRecentClosedTickets('n/tickets')
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}


	unSubscribe() {
		// console.log('UnSubscribed catalogService');
	}
	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on catalogService');
	}

	concatlibrary(allItems: Array<any>) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: any) {
		let oldLibraries = [];
		this.libraries.subscribe(data => oldLibraries = data);
		if (oldLibraries && oldLibraries.length > 0 && item && item.id && item.siteName) {
			oldLibraries = oldLibraries.filter(x => {
				return (x.id === item.id && x.siteName === item.siteName) ? false : true;
			});
		}
		this.changeLibraries(oldLibraries);
	}

	splicelibrary(item: any) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (item && item.id && item.siteName) {
			let index = oldLists.findIndex(x => x.id === item.id && x.siteName === item.siteName);
			oldLists.splice(index, 1);
			this.changeLibraries(oldLists);
		}
	}

	replacelibrary(item: any) {
		let oldLibraries = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLibraries = data);
		if (oldLibraries && oldLibraries.length > 0 && item && item.id && item.siteName) {
			for (var i = 0; i < oldLibraries.length; ++i) {
				if (oldLibraries[i].id === item.id && oldLibraries[i].siteName === item.siteName) {
					oldLibraries.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLibraries.push(item); }
		this.changeLibraries(oldLibraries);
	}

	changeLibraries(libraries: Array<any>) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Array<any>) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
	}

	changeItem(item: any) {
		this.itemSource.next(item);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'sort': 'updated_at_unix|desc',
			'current': 1,
			'is_closed': 1,
			'limit': 25,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	
	changeAllItemsByItem(item: any) {
		let allItems = [];
		let isChanged: boolean = false;
		if (item && item.id && item.is_closed) {
			this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
			if (allItems && allItems.length > 0) {
				var foundIndex = allItems.findIndex(x => x?.id == item?.id);
				if (foundIndex >= 0) {
					isChanged = true;
					allItems.splice(foundIndex, 1, item);
				}
			}
			if (!allItems) {
				allItems = [];
			}
			this.changeAllItems(allItems);
			if (!isChanged) {
				this.concatItem(item);
			}
		}
	}

	changeItemClosed(item: any) {
		let allItems = [];
		let isChanged: boolean = false;
		if (item && item.id && item.is_closed) {
			this.libraries.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
			if (allItems && allItems.length > 0) {
				var foundIndex = allItems.findIndex(x => x?.id == item?.id);
				if (foundIndex >= 0) {
					isChanged = true;
					allItems.splice(foundIndex, 1, item);
				}
			}
			if (!allItems) {
				allItems = [];
			}
			this.changeAllItems(allItems);
			if (!isChanged) {
				allItems.unshift(item);
				this.changeLibraries(allItems)
			}
		}
	}

	concatItem(item: any, eventName: any = null) {
		var items: any;
		items = item;
		var ticketData = item;
		let lists = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result
			if (lists && lists.length > 0) {
				lists = lists.filter(function (e) { return e });
				lists = [...new Map(lists.map((ticket) => [ticket.id, ticket])).values()];
				var foundIndex = lists.findIndex(x => x?.id == items.id);
				if (foundIndex >= 0) {
					lists[foundIndex] = items;
					isChanged = true;
				} else {
					lists = lists.concat([items]);
					isChanged = true;
				}
			} else {
				lists = [];
				lists[0] = items;
				isChanged = true;
			}
		});
		if (isChanged) {
			this.changeAllItems(lists);
			isChanged = false;
		}
	}
	
	getRecentClosedTickets(url, params: any = null) {
		params = this.paramsInit(params);
		if (params.flag_id && params.flag_id.length < 5) {
			delete params.flag_id
		}
		this.commonService.storeItem(url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success && data.data && data.data.length > 0) {
					var ticketData = data.data;
					var dataFormat = [];
					if (ticketData && ticketData.length > 0) {
						ticketData.forEach(ticketList => {
							var dataFormats = {
								id: ticketList.id,
								code: ticketList.code,
								client_id: ticketList.client_id,
								agent_id: ticketList.agent_id,
								priority: ticketList.priority,
								is_active: ticketList.is_active,
								is_closed: ticketList.is_closed,
								people_id: ticketList.people_id,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								updated_at: moment(ticketList.updated_at + '.000+0300').local(),
								last_message_on: moment.unix(ticketList.last_message_on).format('YYYY-MM-DD HH:mm:ss'),
								deleted_at: ticketList.deleted_at,
								is_closeable: ticketList.is_closeable,
								agentName: ticketList.agentInfo?.name,
								agentAvatar: ticketList.agentInfo?.image_api,
								createdByAvatar: ticketList.agentInfo?.image_api,
								agentLiveStatus: ticketList.agentLiveStatus,
								statusChanges: null,
								clientSiteId: ticketList.client_site_id,
								from: ticketList?.leadInfo?.unique_ref,
								// to: ticketList?.leadInfo?.leadInfo,
								unReadCount: ticketList.unreadcount ? ticketList.unreadcount : '0',
								peopleName: ticketList?.leadInfo?.name,
								// peopleAvatar: ticketList?.leadInfo.peopleAvatar,
								recipientId: ticketList?.leadInfo?.unique_ref,
								// peopleLabelId : ticketList?.leadInfo?.id,
								peopleLabelName: ticketList?.leadInfo?.labelname,
								peopleLabelColor: ticketList?.leadInfo?.color,
								peopleLabelColorText: ticketList?.leadInfo?.color_text,

								clientSiteTextColor: ticketList?.clientSiteInfo?.textcolor,
								clientSiteName: ticketList?.clientSiteInfo?.name,
								clientSiteColor: ticketList?.clientSiteInfo?.color,

								siteName: ticketList?.siteinfo?.name,
								siteId: ticketList?.site,
								siteIcon: ticketList?.siteinfo?.icon,
								siteColor: ticketList?.siteinfo?.color,
								peopleAvatar: "https://scrmwapp.s3.amazonaws.com/0clients/645/media/nouser.jpg",
								// statusName : ticketList?.agents[0]?.event

							}
							if (ticketList && ticketList.agents && ticketList.agents.length > 0) {
								var status = ticketList.agents.filter(agent => agent.id == ticketList.agent_id);
								dataFormats["statusName"] = status[0].event;
							}

							if (ticketList && ticketList.siteinfo && ticketList.siteinfo.name) {
								var siteName = ticketList.siteinfo.name;
								if (siteName) {
									switch (siteName) {
										case "Twitter": case "twitter":
										case "Tweet": case "tweet":
											dataFormats["ticketId"] = ticketList.tweet_id;
											dataFormats["tweet_id"] = ticketList.tweet_id;
											break;
										case "Twitterdm": case "twitterdm":
										case "Tweetdm": case "tweetdm":
											dataFormats["ticketId"] = ticketList.tweetdm_id;
											dataFormats["tweetdm_id"] = ticketList.tweetdm_id;
											break;
										case "Messenger": case "messenger":
											dataFormats["ticketId"] = ticketList.messenger_id;
											dataFormats["messenger_id"] = ticketList.messenger_id;
											break;
										case "Whatsapp": case "whatsapp":
											dataFormats["ticketId"] = ticketList.whatsapp_id;
											dataFormats["whatsapp_id"] = ticketList.whatsapp_id;
											break;
										case "Webchat": case "webchat":
											dataFormats["ticketId"] = ticketList.webchat_id;
											dataFormats["webchat_id"] = ticketList.webchat_id;
											break;
										case "Youtube": case "youtube":
											dataFormats["ticketId"] = ticketList.youtube_id;
											dataFormats["youtube_id"] = ticketList.youtube_id;
											break;
										case "Instagram": case "instagram":
											dataFormats["ticketId"] = ticketList.instagram_id;
											dataFormats["instagram_id"] = ticketList.instagram_id;
											break;
										case "Instadm": case "instadm":
											dataFormats["ticketId"] = ticketList.instadm_id;
											dataFormats["instadm_id"] = ticketList.instadm_id;
											break;
										default:
											break;
									}
								}
							}
							dataFormat.push(dataFormats);
						});
					}
					this.changeAllItems(dataFormat);
					this.concatlibrary(dataFormat);
					this.isSearchLoader.next(false);
				} else {
					// this.alertService.webErrorShow(data);
					this.isSearchLoader.next(false);
				}
			},
				error => {
					this.isSearchLoader.next(false);
					console.log('Error ::' + error)
				}
			);
	}

	concatAllItems(url: any = null, params: any = null) {
		params = this.paramsInit(params);
		if (params.flag_id && params.flag_id.length < 5) {
			delete params.flag_id
		}
		this.commonService.storeItem(url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success && data.data && data.data.length > 0) {
					var ticketData = data.data;
					var dataFormat = [];
					if (ticketData && ticketData.length > 0) {
						ticketData.forEach(ticketList => {
							var dataFormats = {
								id: ticketList.id,
								code: ticketList.code,
								client_id: ticketList.client_id,
								agent_id: ticketList.agent_id,
								priority: ticketList.priority,
								is_active: ticketList.is_active,
								is_closed: ticketList.is_closed,
								people_id: ticketList.people_id,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								updated_at: moment(ticketList.updated_at + '.000+0300').local(),
								last_message_on: moment.unix(ticketList.last_message_on).format('YYYY-MM-DD HH:mm:ss'),
								deleted_at: ticketList.deleted_at,
								is_closeable: ticketList.is_closeable,
								agentName: ticketList.agentInfo?.name,
								agentAvatar: ticketList.agentInfo?.image_api,
								createdByAvatar: ticketList.agentInfo?.image_api,
								agentLiveStatus: ticketList.agentLiveStatus,
								statusChanges: null,
								clientSiteId: ticketList.client_site_id,
								from: ticketList?.leadInfo?.unique_ref,
								// to: ticketList?.leadInfo?.leadInfo,
								unReadCount: ticketList.unreadcount ? ticketList.unreadcount : '0',
								peopleName: ticketList?.leadInfo?.name,
								// peopleAvatar: ticketList?.leadInfo.peopleAvatar,
								recipientId: ticketList?.leadInfo?.unique_ref,
								// peopleLabelId : ticketList?.leadInfo?.id,
								peopleLabelName: ticketList?.leadInfo?.labelname,
								peopleLabelColor: ticketList?.leadInfo?.color,
								peopleLabelColorText: ticketList?.leadInfo?.color_text,

								clientSiteTextColor: ticketList?.clientSiteInfo?.textcolor,
								clientSiteName: ticketList?.clientSiteInfo?.name,
								clientSiteColor: ticketList?.clientSiteInfo?.color,

								siteName: ticketList?.siteinfo?.name,
								siteId: ticketList?.site,
								siteIcon: ticketList?.siteinfo?.icon,
								siteColor: ticketList?.siteinfo?.color,
								peopleAvatar: "https://scrmwapp.s3.amazonaws.com/0clients/645/media/nouser.jpg",
								// statusName : ticketList?.agents[0]?.event

							}

							if (ticketList && ticketList.agents && ticketList.agents.length > 0) {
								var status = ticketList.agents.filter(agent => agent.id == ticketList.agent_id);
								dataFormats["statusName"] = status[0].event;
							}

							if (ticketList && ticketList.siteinfo && ticketList.siteinfo.name) {
								var siteName = ticketList.siteinfo.name;
								if (siteName) {
									switch (siteName) {
										case "Twitter": case "twitter":
										case "Tweet": case "tweet":
											dataFormats["ticketId"] = ticketList.tweet_id;
											dataFormats["tweet_id"] = ticketList.tweet_id;
											break;
										case "Twitterdm": case "twitterdm":
										case "Tweetdm": case "tweetdm":
											dataFormats["ticketId"] = ticketList.tweetdm_id;
											dataFormats["tweetdm_id"] = ticketList.tweetdm_id;
											break;
										case "Messenger": case "messenger":
											dataFormats["ticketId"] = ticketList.messenger_id;
											dataFormats["messenger_id"] = ticketList.messenger_id;
											break;
										case "Whatsapp": case "whatsapp":
											dataFormats["ticketId"] = ticketList.whatsapp_id;
											dataFormats["whatsapp_id"] = ticketList.whatsapp_id;
											break;
										case "Webchat": case "webchat":
											dataFormats["ticketId"] = ticketList.webchat_id;
											dataFormats["webchat_id"] = ticketList.webchat_id;
											break;
										case "Youtube": case "youtube":
											dataFormats["ticketId"] = ticketList.youtube_id;
											dataFormats["youtube_id"] = ticketList.youtube_id;
											break;
										case "Instagram": case "instagram":
											dataFormats["ticketId"] = ticketList.instagram_id;
											dataFormats["instagram_id"] = ticketList.instagram_id;
											break;
										case "Instadm": case "instadm":
											dataFormats["ticketId"] = ticketList.instadm_id;
											dataFormats["instadm_id"] = ticketList.instadm_id;
											break;
										default:
											break;
									}
								}
							}
							dataFormat.push(dataFormats);
						});
					}

					let lists = [];
					if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
						params.page = data.pagnitation.last;
						this.changeParams(params);
					}
					this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
						lists = result.concat(dataFormat);
					});
					this.changeAllItems(lists);
				} else {
					// this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error)
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems("n/tickets");
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getRecentClosedTickets("n/tickets");
			});
	}
}
