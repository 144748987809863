import { NgModule } from '@angular/core';

import { NgStringBetweenPipeModule } from 'src/app/pipes/string/stringBetween.pipe';
import { NgTruncatePipeModule } from 'src/app/pipes/string/truncate.pipe';
import { SafePipe } from './string/safe.pipe';

@NgModule({
  declarations: [SafePipe],
  imports : [
    NgStringBetweenPipeModule,
    NgTruncatePipeModule,
  ],
  exports: [
    SafePipe
  ]
})
export class PipesModule { }
