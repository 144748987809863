import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { tjiAnimation } from 'src/app/@tji/animation';
declare let $: any;
import { environment } from 'src/environments/environment';
import { PremiumDashboardService, PremiumDashboard, PremiumAlert, EventService, DashboardService, PusherService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-order-count',
  templateUrl: './dashboard-order-count.component.html',
  styleUrls: ['./dashboard-order-count.component.scss'],
  animations: tjiAnimation,
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})

export class DashboardOrderCountComponent {

  orderCounts: Array<any> = [];
  dashboardParam: any;
  animState = false;
  channel: any;
  eventData: any;

  constructor(private router: Router,
    private eventService: EventService,
    public pusherService: PusherService,
    public dashboardService: DashboardService,) {
    this.dashboardService.params.pipe(untilDestroyed(this)).subscribe(data => this.dashboardParam = data);
    this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
    this.dashboardService.orderCounts.pipe(untilDestroyed(this)).subscribe(data => this.orderCounts = data);
    this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });
  }

  ngOnInit() { }

  animStateDone() { this.animState = !this.animState; }

  checkEventData() {
      let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
      if (eventName) {
          let eventData = this.eventData;
          let data = this.eventData.data;
          switch (eventName) {
                case 'orderUpdate':
                    this.dashboardService.changeOrderCount(data, "orderUpdate");
                    break;
                default:
                    break;
            }
      }
  }
}
