import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ConsumerGroupService, ConsumerGroup,
	ConsumerService, Consumer
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'consumer-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})

export class ItemComponent implements OnInit, OnDestroy {
	authUser: User;
	consumerGroup: ConsumerGroup;
	selectedGroup: ConsumerGroup;
	consumers: Consumer[] = [];
	consumerGroupId: number;
	contacts: Array<string> = [];

	constructor(public userService: UserService,
		public consumerGroupService: ConsumerGroupService,
		public consumerService: ConsumerService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		public location: Location) {
		this.consumerGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.consumerGroup = data;
			this.consumerGroupId = (this.consumerGroup && this.consumerGroup.id) ? this.consumerGroup.id : null;
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	onSelectedGroup(event) { 
		this.selectedGroup = event; 
		this.contacts = (event && event.consumer && event.consumer.jsonArray) ? event.consumer.jsonArray : []; 
	}
}
