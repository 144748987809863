import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MainComponent } from './main/main.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { ProfileUpdateRouteModule } from './profileUpdate-route.module';
import { FormViewComponent } from './form-view/form-view.component';
import { UserProfileService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ProfileViewComponent,
        FormViewComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        ProfileUpdateRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        UserProfileService
    ]
})

export class ProfileUpdateModule { }
