import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Client } from './client/client.interface';
import { ClientModel } from './client/client_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import 'rxjs';

@Injectable({
  providedIn: 'root'
})

@UntilDestroy()

export class ClientToggleServiceService implements Resolve<any> {

  url: string = 'person/client';
  routeParams: any;
  defaultParams: any = {
    'paginate': 25,
    'page': 1,
    'search': '',
    'with': 'detail',
  };

  sortIdentity: any = {
    'name': 'name'
  };

  private allItemsSource = new BehaviorSubject<Client[]>([]);
  allItems = this.allItemsSource.asObservable();

  private itemSource = new BehaviorSubject<Client>(new ClientModel({}));
  item = this.itemSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private displayItemsSource = new BehaviorSubject<Client[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  private _unsubscribeAll: Subject<any>;

  private selectedOption = new BehaviorSubject<ClientModel[]>([]);


  private selectedOptions = new BehaviorSubject<ClientModel[]>([]);

  option$ = this.selectedOption.asObservable();
  options$ = this.selectedOptions.asObservable();

  isOptionEmpty$: Observable<boolean>;

  isOptionsEmpty$: Observable<boolean>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    private snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.resetParams(),
        this.getAllItems(),
        this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed ClientService');
  }

  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on ClientService');
  }

  changeAllItems(allItems: Client[]) {
    this.allItemsSource.next(allItems);
  }

  changeItem(item: Client) {
    this.itemSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: Client[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: Client) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
      }
    }
    this.changeAllItems(allItems);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'paginate': 25,
      'page': 1,
      'search': '',
      'with': 'detail',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (params.all && params.all === 1) {
          this.changeAllItems(data.data);
          this.changeTotalItem(data.data.length);
        } else {
          this.changeAllItems(data.data.data);
          this.changeTotalItem(data.data.total);
        }
      },
        error => console.log('Error ::' + error)
      );
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data.data);
          if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
            params.page = data.pagnitation.last;
            this.changeParams(params);
          }
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: Client) {
    let lists = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result.concat([item]);
    });
    this.changeAllItems(lists);
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i].id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  getItem(params: any = null) {
    this.routeParams = (params) ? params : this.routeParams;
    if (this.routeParams && this.routeParams.id > 0) {
      this.commonService.getItem(this.url, this.routeParams.id)
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          this.changeAllItemsByItem(data.data);
          this.changeItem(data.data);
        },
          error => console.log('Error ::' + error)
        );
    }
    else {
      this.changeItem(new ClientModel({}));
    }
  }

  store(data: any) {
    this.commonService.storeItem(this.url, data)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        this.concatItem(data.data);
        this.changeItem(data.data);
        this.alert('Success', 'Created Successfully !!!');
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  update(id: number, data: any): void {
    this.commonService.updateItem(this.url, id, data)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        this.changeAllItemsByItem(data.data);
        this.changeItem(data.data);
        this.alert('Info', 'Updated Successfully !!!');
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(id: number) {
    this.commonService.deleteItem(this.url, id)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        this.spliceItem(id);
        this.alert('Danger', 'Destroyed Successfully !!!');
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  post(url: string, data: any) {
    let alert = (data && data.alert) ? data.alert : null;
    let alertType = (data && data.alertType) ? data.alertType : 'Success';
    this.commonService.storeItem(url, data)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        this.getItem();
        this.alert(alertType, (alert) ? alert : 'Created Successfully !!!');
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  /** Search Event */
  onSearch(input: string) {
    let newParams: any;
    this.params
      .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = 1;
        newParams.search = input;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = event.pageIndex + 1;
        newParams.paginate = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  getSortName(input: string) {
    let sortName = 'name';
    sortName = (input) ? this.sortIdentity[input] : sortName;
    return sortName;
  }

  sortData(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = 1;
        newParams.order = this.getSortName(event.active) + '|' + event.direction;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  alert(type: string, message) {
    this.alertService.webShow(type, message);
    // let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
    // this.snackBar.openFromComponent(AlertComponent, {
    //   panelClass: ['alert' + capitalType],
    //   data: {
    //     message: message,
    //     type: type.toLowerCase(),
    //   }
    // });
  }

  updateSelectedOption(option: ClientModel) {
    this.selectedOption.next([option]);
  }

  updateSelectedOptions(options: ClientModel[]) {
    this.selectedOptions.next(options);
  }

}
