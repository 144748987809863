import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgPipesModule } from 'ngx-pipes';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { CdTimerModule } from 'angular-cd-timer';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { LibraryModule } from 'src/app/library/library.module';
import { ComponentModule } from 'src/app/component/component.module';
import { AppMatModule } from './app-mat.module';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { LoaderComponent } from 'src/app/library/loader/loader.component';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { SpecialCharacterDirective } from 'src/app/library/directives/specialChracter.directive';

import { CountdownModule } from 'ngx-countdown';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ImageViewerModule } from "ngx-image-viewer";
import { TabsModule } from 'ngx-tabset';
import { CountUpModule } from 'ngx-countup';
import { AccordionModule } from "ngx-accordion";
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from "ngx-spinner";
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCaptchaModule } from 'ngx-captcha';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgOtpInputModule } from 'ng-otp-input';
import { ClipboardModule } from 'ngx-clipboard';
// import { SocketIoModule } from 'ngx-socket-io';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'ngx-avatar';

// import { IconPickerModule } from 'ngx-icon-picker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { WebchatModule } from 'webchat';

import { environment } from '../environments/environment';


@NgModule({
    declarations: [
        LoaderComponent,
        ScrollToBottomDirective,
        SpecialCharacterDirective
    ],
    imports: [
        CommonModule,
        LibraryModule,
        ComponentModule,
        CarouselModule,
        NgPipesModule,
        NgxChartsModule,
        AngularEditorModule,
        CdTimerModule,
        FlexLayoutModule,
        RouterModule,
        FormsModule,
        PipesModule,
        AppMatModule,
        InfiniteScrollModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        CountdownModule,
        NgxJsonViewerModule,
        NgxSmartModalModule.forRoot(),
        NgxScrollTopModule,
        ImageViewerModule.forRoot(),
        Ng2SearchPipeModule,
        NgOtpInputModule,
        ClipboardModule,
        TabsModule,
        CountUpModule,
        AccordionModule,
        CrystalLightboxModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        TranslateModule,
        AvatarModule,
        ColorPickerModule,
        // SocketIoModule,
        // IconPickerModule,
        NgxIntlTelInputModule,
        MatSelectModule,
        // WebchatModule.forRoot({})
    ],
    exports: [
        CommonModule,
        LibraryModule,
        ComponentModule,
        CarouselModule,
        NgPipesModule,
        NgxChartsModule,
        Ng2SearchPipeModule,
        NgOtpInputModule,
        ClipboardModule,
        Nl2BrPipeModule,
        AngularEditorModule,
        CdTimerModule,
        FlexLayoutModule,
        RouterModule,
        FormsModule,
        PipesModule,
        AppMatModule,
        InfiniteScrollModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        LoaderComponent,
        ScrollToBottomDirective,
        CountdownModule,
        NgxJsonViewerModule,
        NgxSmartModalModule,
        NgxScrollTopModule,
        ImageViewerModule,
        NgxSpinnerModule,
        TabsModule,
        CountUpModule,
        AccordionModule,
        CrystalLightboxModule,
        ColorPickerModule,
        NgxCaptchaModule,
        TranslateModule,
        AvatarModule,
        // SocketIoModule,
        // IconPickerModule,
        NgxIntlTelInputModule,
        MatSelectModule,
        // WebchatModule,
        SpecialCharacterDirective,
    ]
})
export class AppShareModule { }
