import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  User, UserService, ProductCatalog, ProductCatalogModel, ProductCatalogService, ProductService, Product, AlertService, productItemsModel, CommonService, ProductMultipleItemModel, ListSectionModel
} from 'src/app/@tji/_dbShare';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-catalog-create',
  templateUrl: './catalog-create.component.html',
  styleUrls: ['./catalog-create.component.scss']
})
export class CatalogCreateComponent implements OnInit {
  authUser: User;
  submitted: boolean = false;
  titleInputBox: boolean = false;
  sectionError: boolean = false;
  selectedProductError: string = 'Please select a Type of Product'
  typeList = [
    {
      code: 1,
      name: 'Single Product',
    },
    {
      code: 2,
      name: 'Multiple Product',
    }
  ]
  selectedProductLists: Product[] = [];
  productAdd: any[] = [];
  loader: boolean = false;
  productCatalogForm: UntypedFormGroup = new UntypedFormGroup({
    prodName: new UntypedFormControl(''),
    type: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    catalog_id: new UntypedFormControl(''),
    title: new UntypedFormControl(''),
  });

  constructor(
    public location: Location,
    public userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public productService: ProductService,
    private alertService: AlertService,
    private commonService: CommonService,
    private productCatalogService: ProductCatalogService,
  ) {
    this.userService.user.subscribe(data => this.authUser = data);
  }

  ngOnInit(): void {
    this.productCatalogForm = this.formBuilder.group({
      prodName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(120),],],
      type: ['', Validators.required],
      status: [true, Validators.required],
      catalog_id: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      title: [''],
    })
  }
  get f(): { [key: string]: AbstractControl } {
    return this.productCatalogForm.controls;
  }



  // Transfer Items Between Lists
  onDrop(event: any, index: any) {
    if (this.selectedProductLists.length < 1 && this.productCatalogForm.controls['type'].value == 1) {
      this.productService.drop(event);
    } else if (this.selectedProductLists.length == 1 && this.productCatalogForm.controls['type'].value == 1) {
      this.alertService.webShow('danger', "only one product allowed to drag");
    } else if (this.productCatalogForm.controls['type'].value == 2) {
      this.productService.drop(event);
      if (event.container.data) {
        if (this.productSections[index].product_items.length > 0) {
          this.productSections[index].product_items = event.container.data.filter((product, index, array) => index === array.findIndex((findTest) => findTest._id === product._id));
          this.productSections[index]['productStatus'] = false;
        } else {
          this.productSections[index].product_items = event.container.data.filter((product, index, array) => index === array.findIndex((findTest) => findTest._id === product._id));
          this.productSections[index]['titleStatus'] = false;
          this.productSections[index]['productStatus'] = false;
          this.productSections[index]['titleError'] = 'Please Enter Title';
          this.productSections[index]['productError'] = 'Please Select The Product';
        }
      }
      if (this.productSections[index].product_items['id'] === '' || this.productSections[index].product_items['id'] === undefined || this.productSections[index].product_items['id'] === null) {
        this.productSections[index].product_items['id'] = (index + 1).toString();
      }
    } else {
      this.alertService.webShow('danger', "Please select a product type");
    }
  }

  selectType(e) {
    if (this.productCatalogForm.controls['type'].value == 2) {
      this.titleInputBox = true;
      this.actionList = 1;
      this.productSections.push(new ListSectionModel({}));
    } else {
      this.productSections = [];
      this.titleInputBox = false;
    }
  }
  productData: any;
  productSingleData: any[];
  productCatalogStatus: boolean = false;

  onSubmit(): void {
    this.submitted = true;
    delete this.productData;
    var productCatalogStatusData = [];
    if (this.productCatalogForm.invalid) {
      return;
    } else {
      if (this.productCatalogForm.controls['type'].value == 1) {
        if (this.selectedProductLists.length > 0) {
          this.productData = {
            "type": parseInt(this.productCatalogForm.controls['type'].value),
            "name": this.productCatalogForm.controls['prodName'].value,
            "status": this.productCatalogForm.controls['status'].value.toString(),
            "client_site_id": this.authUser.person_id,
            "catalog_id": this.productCatalogForm.controls['catalog_id'].value,
            "product_content_id": {
              'product_retailer_id': this.selectedProductLists[0]['content_id']
            }
          }
          this.addProductData(this.productData);
        } else {
          this.alertService.webShow('danger', "Please select a product list");
        }
      }
      if (this.productCatalogForm.controls['type'].value == 2) {
        if (!this.productCatalogForm.controls['title'].value) {
          this.sectionError = true;
          return;
        } else {
          this.sectionError = false;
        }
        if (this.productSections.length > 0) {
          this.productSections.forEach((element, index) => {
            this.productCatalogStatus = false;
            if (!element.title || element.title.length == 0) {
              element.titleStatus = true;
            } else {
              element.titleStatus = false;
            }
            if (element.title.length != 0) {
              if (element.title.length < 2) {
                element.titleError = 'Please enter a Minimum 3 characters';
                element.titleStatus = true;
              } else if (element.title.length > 101) {
                element.titleError = 'Allwoed Maximum 100 characters only';
                element.titleStatus = true;
              } else {
                element.titleStatus = false;
              }
            }
            if (element.product_items.length == 0) {
              element.productStatus = true;
            } else {
              element.productStatus = false;
            }
          });
          this.productSections.forEach((element, index) => {
            this.productCatalogStatus = false;
            if (!element.title || element.product_items.length == 0) {
              productCatalogStatusData.push(false);
              this.productCatalogStatus = true;
            } else {
              productCatalogStatusData.push(true);
            }
          });
          const allEqual = productCatalogStatusData.every(val => val == true)
          this.productCatalogStatus = allEqual;
          if (this.productSections.length > 0 && this.productCatalogStatus) {
            var sectionData = {
              "title": '',
              "product_items": []
            }
            this.productSections.forEach(product => {
              if (product.title) {
                sectionData = {
                  "title": '',
                  "product_items": []
                }
                sectionData.title = product.title
              }
              product.product_items.forEach(element => {
                sectionData["product_items"].push({ 'product_retailer_id': element.content_id });
              });
              this.productAdd.push(sectionData);
            });
            this.productData = {
              "type": parseInt(this.productCatalogForm.controls['type'].value),
              "name": this.productCatalogForm.controls['prodName'].value,
              "status": this.productCatalogForm.controls['status'].value.toString(),
              "product_content_id": this.productAdd,
              "client_site_id": this.authUser.person_id,
              "catalog_id": this.productCatalogForm.controls['catalog_id'].value,
            }
            if (this.productCatalogStatus) {
              this.addProductData(this.productData);
            }
          }
        }

      }
    }
  }
  onTitleValidation() {
    this.sectionError = (this.productCatalogForm.controls['title'].value == "") ? true : false;
    return this.productCatalogForm.controls['title'].value != "";
  }

  addProductData(data) {
    this.loader = !this.loader;
    let tempSub = this.commonService.storeItem('ecatalog/add', data, true, "optionOne").subscribe((res: any) => {
      if (res.success) {
        if (res.data && res.data.message) {
          this.alertService.webShow('Info', res.data.message);
        } else {
          this.alertService.webShow('Info', 'Updated Successfully !!!');
        }
        this.productCatalogService.getAllItems();
        this.submitted = false;
        this.productCatalogForm.reset();
        this.titleInputBox = false;
        delete this.selectedProductLists;
        this.loader = !this.loader;
        this.location.back();
      } else {
        this.loader = !this.loader;
        this.alertService.webErrorShow(res);
      }
      tempSub.unsubscribe();
    }, error => {
      this.loader = !this.loader;
      this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
      console.error('There was an error!', error);
      tempSub.unsubscribe();
    })
  }

  productSections: any[] = [];
  actionList: 1;
  intractiveListData: boolean = false;
  onActionProductCountChange(data: number) {
    this.intractiveListData = false;
    if (this.productSections.length < data) {
      this.productSections.forEach(element => {
        if (element.title || element.product_items.length > 0) {
          this.intractiveListData = true;
        }
      });
      if (this.intractiveListData) {
        this.productSections.push(new ListSectionModel({}));
      } else {
        this.productSections = [];
        if (data && data > 0 && data <= 10) {
          for (var i = 1; i <= data; i++) {
            this.productSections.push(new ListSectionModel({}));
          }
        } else {
          this.productSections = [new ListSectionModel({})];
        }
      }
    }
    else {
      this.productSections.pop();
    }
  }
  deleteCatalogList(i) {
    if (this.productSections.length > 1) {
      this.productSections.forEach((element, index) => {
        if (index == i) this.productSections.splice(index, 1);
      });
      this.actionList--;
    }
  }
  onChangeProductTitile(event, i) {
    this.productSections[i].title = event;
  }
  removeProduct(data, list: any = null, idx: any = null) {
    if (data == 'single') {
      this.selectedProductLists = [];
    }
    if (data == 'muliple') {
      this.productSections.forEach((prod, index) => {
        if (index == idx) {
          prod.product_items.forEach((item, index) => {
            if (item._id == list._id) {
              this.productSections[idx]['product_items'].splice(index, 1);
            }
          });
        }
      });
    }
  }
}
