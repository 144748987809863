import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EventService, User, UserService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface DialogData {
    title: string;
    message: string;
    type: string;
    info: string;
    infoDetails: string;
    infoDetails1: string;
}

@UntilDestroy()
@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
    eventData: any;
    authUser: User;
    constructor(public userService: UserService,
        private eventService: EventService, public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.userService.user.subscribe(data => this.authUser = data);
        this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
            this.eventData = data;
            this.checkEventData();
        });

    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close({ data: false });
    }

    onOkClick(): void {
        this.dialogRef.close({ data: true });
    }

    getTitle(type: string) {
        if (this.data && this.data.title) {
            return this.data.title;
        }
        switch (type) {
            case "success":
                return "Complete Action !!!";
                break;
            case "info":
                return "For Your Kind Information !!!";
                break;
            case "warn":
                return "Warning !!!";
                break;
            case "delete":
                return "Do You Want to Delete !!!";
                break;
            case "alert":
                return "Alert !!!";
                break;
            default:
                return "Complete Action !!!";
                break;
        }
    }

    getButtonText(type: string) {
        switch (type) {
            case "success":
                return "Complete";
                break;
            case "info":
                return "OK";
                break;
            case "warn":
                return "Confirm";
                break;
            case "delete":
                return "Delete";
                break;
            default:
                return "Ok";
                break;
        }
    }

    checkEventData() {
        let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
        if (eventName) {
            let eventData = this.eventData;
            let data = this.eventData.data;
            switch (eventName) {
                case 'Login': case 'login':
                    let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
                    if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
                        this.onNoClick();
                    }
                    break;
                case 'logout': case 'Logout':
                    let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
                    if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
                        this.onNoClick();
                    }
                    break;
                default:
                    break;
            }
        }
    }

}
