import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserService, User } from 'src/app/@tji/_dbShare/user';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { PushNotificationOptions, PushNotificationService } from 'ngx-push-notifications';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
var presenceChannel: PusherTypes.PresenceChannel;
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Alert, AlertModel } from 'src/app/@tji/_dbShare/alert/alert';
declare function notifyMe(title, message): any;

@Injectable({
	providedIn: 'root',
})

export class PusherService {

	// appKey: string = 'SCRMSOCKET';
	// cluster: string = 'mt1';
	// pusher: any = new Pusher(this.appKey, {
	// 	cluster: this.cluster,
	//    wsHost: '127.0.0.1',
	//    wsPort: 6001,
	//    wssPort: 6001,
	//    disableStats: true,
	//    forceTLS: true,
	//    enabledTransports: ['ws'],
	// });

	appKey: string = '048881802ea8ef44b68d';
	cluster: string = 'ap2';
	pusher: any = new Pusher(this.appKey, {
		cluster: this.cluster,
		// wssPort: environment.wssPort,
		forceTLS: false,
		// useTLS: false,
		disableStats: true,
		enabledTransports: ['ws'],
		disabledTransports: ['wss'],
	});

	authUser: User = this.userService.authUser();
	authUserId: number;
	alertData: Alert;

	private pusherChannelSource = new BehaviorSubject<string>(null);
	pusherChannel = this.pusherChannelSource.asObservable();

	// Our constructor calls our wsService connect method
	constructor(public userService: UserService,
		public pushNotificationService: PushNotificationService,
		private commonService: CommonService,
		private snackBar: MatSnackBar) {
		// this.authUserId = (this.authUser && this.authUser.id) ? this.authUser.id : null;
	}

	changePusherChannel(channel: string) {
		// this.pusherChannelSource.next(channel);
	}

	subscribeChannel(channelName: string) {
		// let channel: string = (this.authUserId) ? this.authUserId + '_' + channelName : channelName;
		// let subscribedChannel: any = this.pusher.subscribe(channel);
		// this.changePusherChannel(subscribedChannel);
		// return subscribedChannel;
		return;
	}

	unSubscribeChannel(channelName: string) {
		// let channel: string = (this.authUserId) ? this.authUserId + '_' + channelName : channelName;
		// let unSubscribedChannel: any = this.pusher.unsubscribe(channel);
		// unSubscribedChannel.unbind();
		// this.changePusherChannel(null);
		return;
	}

	showAlert(title: string, message: string, type: string = null, icon: string = null) {
		this.alertData = new AlertModel({});
		this.alertData.type = (type) ? type : 'info';
		this.alertData.title = title;
		this.alertData.message = message;
		this.alertData.faIcon = 'fa ' + (icon) ? icon : 'fa-exclamation-circle';
		this.alertData.icon = 'fa ' + (icon) ? icon : 'fa-exclamation-circle';
		this.commonService.alert(this.alertData);
	}

	alert(type: string, message: string, icon: string = null, duration: number = 5000) {
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		icon: icon,
		// 		type: type.toLowerCase(),
		// 	},
		// 	duration: duration
		// });
		// return;
	}

	alertPusher(type: string, message: string, icon: string = null) {
		// const isGranted = this.pushNotificationService.isPermissionGranted;
		// const title = type;
		// const options = new PushNotificationOptions();
		// options.body = message;
		// if (icon) {
		// 	options.icon = icon;
		// }
		// if (isGranted) {
		// 	this.pushNotificationService.create(title, options).subscribe((notif) => {
		// 		if (notif.event.type === 'show') {
		// 			setTimeout(() => {
		// 				notif.notification.close();
		// 			}, 5000);
		// 		}
		// 		if (notif.event.type === 'click') {
		// 			notif.notification.close();
		// 		}
		// 		if (notif.event.type === 'close') {
		// 		}
		// 	},
		// 		(err) => {
		// 			console.log(err);
		// 		});
		// } else {
		// 	this.alert(type, message, icon);
		// }
	}

}