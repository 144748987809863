import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, Log, UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})

export class LogComponent implements OnInit {

  logs: Log[] = [];
  logParam: any;
  
  constructor(public logService: LogService, public userService: UserService) {
	this.logService.params.subscribe(data => this.logParam = data);
	this.logService.allItems.subscribe(data => this.logs = data);
  }

  ngOnInit() {
    if (!this.userService.roleMatch(['Super Admin', 'Reseller'])) {
      this.getLogs();
    }
	
  }

	getLogs() {
		this.logParam.order = 'created_at|desc,updated_at|desc',
		this.logService.getAllItems();
	}

}
