export class TemplateModel {
    id:number;
    code: string;
    template_code: string;
    name: string;
    label: string;
    description: string;
	category: string;
    stencil_id:number;
	language: string;
	header_type: string;
	text_header: string;
	media_header: string;
    file_path:string;
	comment: string;
	button_type: string;
	button_text: string;
	buttonText: Array<any>;
    footer:string;
    status:string;
    json:string;
    token:string;
    requiredValues?:Array<any>;
    requiredAllValues?:Array<any>;

    templateUsages?:Array<any>;
    stencil?:any;
    apiJson?:string;
    templateData?: any;
    siteName?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;

    /**
     * Constructor
     *
     * @param template
     */
    constructor(template) {
            this.id = template.id || '';
            this.code = template.code || '';
            this.template_code = template.template_code || '';
            this.name = template.name || '';
            this.label = template.label || '';
            this.description = template.description || '';
            this.category = template.category || '';
            this.stencil_id = template.stencil_id || '';
            this.language = template.language || '';
            this.header_type = template.header_type || '';
            this.text_header = template.text_header || '';
            this.media_header = template.media_header || '';
            this.file_path = template.file_path || '';
            this.footer = template.footer || '';
            this.comment = template.comment || '';
            this.button_type = template.button_type || '';
            this.button_text = template.button_text || '';
            this.buttonText = template.buttonText || [];
            this.status = template.status || '';
            this.json = template.json || '';
            this.token = template.token || '';
            
    }
}
