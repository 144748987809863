import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductCatalogRoutingModule } from './product-catalog-routing.module';
import { MainComponent } from './main/main.component';
import { ListComponent } from './product/list/product-list.component';
import { AppShareModule } from "../../app-share.module";
import { CatalogListComponent } from './catalog/catalog-list/catalog-list.component';
import { CatalogCreateComponent } from './catalog/catalog-create/catalog-create.component';
import { CartComponent } from './cart/cart/cart.component';


@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        CatalogListComponent,
        CatalogCreateComponent,
        CartComponent,
    ],
    imports: [
        CommonModule,
        ProductCatalogRoutingModule,
        AppShareModule,
    ],
    exports: [
        CartComponent,
	],
})
export class ProductCatalogModule { }
