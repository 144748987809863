export class CountryModel {
    _id: string;
    id: number;
    name_en: string;
    name_ar: string;
    iso3: string;
    iso2: string;
    phonecode: number;
    currency: string;
    currency_name: string;
    currency_symbol: string;
    timezones: any;
    latitude: string;
    longitude: string;
    emoji: string;
    emojiU: string;

    /**
     * Constructor
     *
     * @param country
     */
    constructor(country) {
        this._id = country._id || '';
        this.id = country.id || ''
        this.name_en = country.name_en || ''
        this.name_ar = country.name_ar || ''
        this.iso3 = country.iso3 || ''
        this.iso2 = country.iso2 || ''
        this.phonecode = country.phonecode || ''
        this.currency = country.currency || ''
        this.currency_name = country.currency_name || ''
        this.currency_symbol = country.currency_symbol || ''
        this.timezones = country.timezones || ''
        this.latitude = country.latitude || ''
        this.longitude = country.longitude || ''
        this.emoji = country.emoji || ''
        this.emojiU = country.emojiU || ''
    }
}