import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, CommonService,
	ChatService,
	WhatsappTicketService, WhatsappTicket,
	WhatsappService, Whatsapp,
	CommentService, Comment,
	PusherService, PeopleService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'whatsapp-ticket-item-ticketlist',
	templateUrl: './ticketList.component.html',
	styleUrls: ['./ticketList.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TicketListComponent implements OnInit, OnDestroy {
	@Output() onSelectTicket = new EventEmitter();
	baseImage: string = this.commonService.logo_image;
	channel: any;
	user: User;
	tickets: WhatsappTicket[] = [];
	selectedTicket: any;
	select: boolean = false;

	constructor(public userService: UserService,
		public chatService: ChatService,
		public whatsappService: WhatsappService,
		public whatsappTicketService: WhatsappTicketService,
		public pusherService: PusherService,
		public commentService: CommentService,
		public commonService: CommonService,
		public peopleService: PeopleService,
		private router: Router,
		public matDialog: MatDialog) {
		this.pusherService.pusherChannel.pipe(untilDestroyed(this)).subscribe(data => this.channel = data);
		this.whatsappTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.tickets = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.selectedTicket = data;
		});
		this.userService.user.subscribe(data => this.user = data);
	}

	ngOnInit() {
		// this.subscribePusher();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	subscribePusher() {
		this.channel = this.pusherService.subscribeChannel('authuser');
		this.channel.bind('newmessage', (data) => {
			this.chatService.getLiveMessages();
		});
		this.channel.bind('ticketassigned', (data) => {
			this.chatService.getAllLiveItems();
		});
		this.channel.bind('ticketreassigned', (data) => {
			this.chatService.getAllLiveItems();
		});
		this.channel.bind('ticketremoved', (data) => {
			this.chatService.removeTicket(data.data.ticket_id, data.data.site);
		});
		this.channel.bind('aticketassigned', (data) => {
			this.chatService.getAllLiveItems();
		});
		this.channel.bind('aticketreassigned', (data) => {
			this.chatService.getAllLiveItems();
		});
		this.channel.bind('aticketremoved', (data) => {
			this.chatService.removeTicket(data.data.ticket_id, data.data.site);
		});
	}

	onSelect(ticket: any) {
		this.chatService.changeTicket(ticket);
		this.onSelectTicket.emit(true);
		if (ticket && ticket.people_id) {
			this.peopleService.changeItem(null);
			this.peopleService.getItem({ 'id': ticket.people_id });
		}
	}

	getLiveRecords() {
		this.chatService.getLiveMessages();
	}

}
