import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	FeedService, Feed,
	AgentService, Agent,
	BoardService, Board
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-assign-supervisor',
	templateUrl: './assignSupervisor.component.html',
	styleUrls: ['./assignSupervisor.component.scss']
})

export class AssignSupervisorComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Choose and Assign Supervisor';
	agents: Agent[] = [];
	agent: Agent;
	selectedAgentId: number;
	selectedAgent: any;
	agentParam: any;
	action: string;

	constructor(public agentService: AgentService,
		public matDialogRef: MatDialogRef<AssignSupervisorComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any) {
		this.agentService.getSupervisorAgents();
		this.agentService.allSupervisorAgents.pipe(untilDestroyed(this)).subscribe(data => this.agents = data.filter(x => { return x.is_supervisor; }));
		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => this.agent = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getAgents() {
		// this.agentParam.all = 1;
		// this.agentService.getSupervisorAgents();
	}

	onSelect(selectedAgentId: number) {
		this.selectedAgentId = selectedAgentId;
	}
	onSelectAgent(agent){
		this.selectedAgentId = agent.id;
		this.selectedAgent = {
			"agentName": agent.name,
			"selectedAgentId": agent.id
		}
	}
}
