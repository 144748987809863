import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Clipboard } from '@angular/cdk/clipboard';
import { Webclip, WebclipService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
    selector: 'otp-dialog',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss']
})
export class OtpDialogComponent implements OnInit {

    premiumDashboardWebclip: Webclip;
    subLoader: boolean = false;

    constructor(public dialogRef: MatDialogRef<OtpDialogComponent>,
        public webclipService: WebclipService) {
        this.webclipService.item.pipe(untilDestroyed(this)).subscribe(data => {
            if (data && data.id && data.for === 'premium-dashboard') {
                this.premiumDashboardWebclip = data;
                this.subLoader = false;
            } else {
                this.premiumDashboardWebclip = null;
                this.subLoader = true;
            }
        });
    }

    ngOnInit() { }

    onOkClick(): void {
        this.dialogRef.close();
    }

}
