import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from "../../app-share.module";

import { BrandRoutingModule } from './brand-routing.module';
import { MainComponent } from './main/main.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { SidebarBrandComponent } from './sidebar-brand/sidebar-brand.component';
import { AssignClientComponent } from './assign-client/assign-client.component';
import { BranchListComponent } from './branch-list/branch-list.component';
import { MainBranchComponent } from './main-branch/main-branch.component';

@NgModule({
  declarations: [
    MainComponent,
    BrandListComponent,
    SidebarBrandComponent,
    AssignClientComponent,
    BranchListComponent,
    MainBranchComponent,
  ],
  imports: [
    AppShareModule,
    CommonModule,
    BrandRoutingModule,
  ],
  exports: [
    SidebarBrandComponent
  ]
})
export class BrandModule { }
