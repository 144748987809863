import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ClientFormComponent } from 'src/app/reseller/socialSite/clientForm/clientForm.component';

import {
	User, UserService, CommonService,
	Client, ClientModel, RClientService, ClientToggleService,
	RClientSiteService, ClientSiteService, ClientSite, ClientSiteModel,
	SiteService, Site, SiteModel, PusherService,
	ClientService
} from 'src/app/@tji/_dbShare';
import { exit } from 'process';
import { MatSidenav } from '@angular/material/sidenav';
import { SwipeClientSiteComponent } from '../swipe-client-site/swipe-client-site.component';
@UntilDestroy()
@Component({
	selector: 'reseller-socialsite-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.scss'],
	providers: [OrderByPipe]
})

export class RClientComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	isAdmin: any;
	clients: Client[] = [];
	param: any;

	dataLength: number = 0;
	dataSource = new MatTableDataSource(this.clients);

	displayedColumns = ['media', 'account', 'createdat','deletedat', 'status', 'action'];
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;

	@ViewChild('rightSideBar') rightSideBar: MatSidenav;

	constructor(public userService: UserService,
		public commonService: CommonService,
		public rClientService: RClientService,
		public clientService: ClientService,
		public clientToggleService: ClientToggleService,
		public pusherService: PusherService,
		private router: Router,
		public matDialog: MatDialog,
		private _formBuilder: UntypedFormBuilder,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.rClientService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rClientService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.clients) { this.subLoader = false; }
			this.clients = data;
			this.clients = this.orderByPipe.transform(data, '-id');
			this.dataSource = new MatTableDataSource(this.clients);
			this.dataSource.sort = this.sort;
		});
		this.isAdmin = this.userService.isAuthUserIsAdmin();
		this.clientService.getAllIData();
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.rClientService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	toggleRightSidebar() {
		this.rightSideBar.toggle();
	}

	selectClient(client = null) {
		this.rClientService.changeItem((client));
		// this.clientService.changeItem((client));
		this.clientToggleService.changeItem(client);
	}

	viewMore(client = null) {
		this.rClientService.changeItem((client));
		// this.clientService.changeItem((client));
		this.clientToggleService.changeItem(client);
		this.router.navigateByUrl('/app/client/'+client.id);
	}

	onSelectClientAccounts(client = null) {
		this.rClientService.changeItem((client));
		// this.clientService.changeItem((client));
		this.clientToggleService.changeItem(client);
		this.router.navigateByUrl('/reseller/socialsite/'+client.id+'/list');
	}

	onSelectClientItrsBot(client = null) {
		this.rClientService.changeItem((client));
		// this.clientService.changeItem((client));
		this.clientToggleService.changeItem(client);
		this.router.navigateByUrl('/reseller/socialsite/'+client.id+'/itrsbot');
	}

	onSelectClientTemplate(client = null) {
		this.rClientService.changeItem((client));
		// this.clientService.changeItem((client));
		this.clientToggleService.changeItem(client);
		this.router.navigateByUrl('/reseller/socialsite/'+client.id+'/template');
	}

	/** Add / Update ClientSite */
	newClient(client = null): void {
		this.rClientService.changeItem((client) ? client : new ClientModel({}));
		// this.clientService.changeItem((client) ? client : new ClientModel({}));
		let dialogRef: any = null;
		dialogRef = this.matDialog.open(ClientFormComponent, {
			width: "600px",
			data: {
				action: (client && client.id) ? 'edit' : 'new',
				item: client,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.rClientService.store('',formData.value);
						break;
					case 'update':
						this.rClientService.update(id, formData.value);
						break;
					case 'delete':
						this.deleteClient(client);
						break;
				}
			});
	}


	/** Update Client */
	changeClientActive(client: Client, status: boolean): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: (status) ? 'info' : 'info',
				message: (status) ? 'Are you sure you want to Activate this Client?' : 'Are you sure you want to Disable this Client?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.rClientService.update(client.id, {'is_active': (status) ? 1 : 0});
			}
		});
	}

	/** Delete Client */
	deleteClient(client): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.rClientService.destroy(client.id);
			}
		});
	}

	/** swipe account */
	swipeAccount(site = null): void {
		this.rClientService.changeItem((site) ? site : '');
		let dialogRef = this.matDialog.open(SwipeClientSiteComponent, {
			width: "600px",
			data: {
				action: (site) ? 'edit' : 'new',
				item: site,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				var data = {
					"client_site_id":site.id,
					"client_id": response
				}
				if (response) {
					console.log(data);
					this.rClientService.store('reseller/clientsite/swipeClientSite',data);
				}
			});
	}
}
