import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	logoImg = "./assets/images/tji/logo_white_bg.png";
  activeUrl: string = this.router.url;
  year = 2021;
  
  constructor(public userService: UserService,
              private router: Router) {
    this.year = new Date().getFullYear();
  }

  ngOnInit() {
  }

  isDashboard(): boolean {
    return (window.location.href.includes('/app/dashboard') || window.location.href.includes('/app/agent-dashboard')) ? true : false;
  }

}
