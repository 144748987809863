import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import * as XLSX from 'xlsx';

import {
	User, UserService,
	TemplateMessageService, TemplateMessage, TemplateMessageModel, FirebaseJobService, FirebaseJob
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'bulk-import',
	templateUrl: './bulkImport.component.html',
	styleUrls: ['./bulkImport.component.scss']
})

export class BulkImportComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Bulk Import';
	error: string = '';
	excelJsonData: any = [];
	selectedExcelData: any = [];
	convertTemplateJson = [];
	worksheetDataKey: any;
	isUploadFile: boolean = true;
	isAssingDate: boolean = false;
	selectWorkSheet: string = '';
	fileData: any;
	template: TemplateMessage;
	isSend: boolean = true;
	isRepeat: boolean = false;
	convertTemplateJsonLength: any;
	TemplateJsonLength: any;
	constructor(public userService: UserService,
		public templateMessageService: TemplateMessageService,
		public firebaseJobService: FirebaseJobService,
		private snackBar: MatSnackBar,
		private router: Router,
		private _formBuilder: UntypedFormBuilder,
		private cd: ChangeDetectorRef,
		private alertService: AlertService,
		public matDialogRef: MatDialogRef<BulkImportComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		public location: Location) {
		this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
		});
		this.templateMessageService.temSend.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data[0] && data[0].jobcode) {
				this.matDialogRef.close();
				if (this.isRepeat) {
					var msg = "Job created with Job Code " + data[0].jobcode + " " + this.convertTemplateJsonLength + " out of " + this.TemplateJsonLength
					this.alertService.webShow('Success', msg);
				} else {
					var msgs = "Job created with Job Code " + data[0].jobcode + " " + this.TemplateJsonLength + " out of " + this.TemplateJsonLength
					this.alertService.webShow('Success', msgs);
				}

			}
		});

	}

	ngOnInit() {
		// this.cd.detectChanges();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.templateMessageService.bulkTemSend.next([]);
		this.templateMessageService.unSubscribe();
		this.firebaseJobService.changeJobcode(null);
	}

	// doLoader() {
	// 	setTimeout(() => {
	// 		this.loader = !this.loader;
	// 	}, 5000);
	// }

	// timer() {
	// 	let data = 'bulk';
	// 	setTimeout(() => {
	// 		if (this.remainingCounts != 0) {
	// 			this.templateMessageService.getCompletedCounts({ 'queue_name': data });
	// 			this.completeCounts = this.totalCounts - this.remainingCounts;
	// 			this.rangeValue = (100 / this.totalCounts) * this.completeCounts;
	// 			// this.timer();
	// 		} else {
	// 			this.rangeValue = 100;
	// 			this.completeCounts = this.totalCounts;
	// 		}
	// 	}, 3000);
	// }

	readExcel(event) {
		this.fileData = event;
		var types = event.target.files[0].name.split(/\.(?=[^\.]+$)/);
		var typeDatas = types[1];
		if (typeDatas == 'xlsx' || typeDatas == 'xls' || typeDatas == 'csv') {
			let workBook = null;
			let jsonData = null;
			const reader = new FileReader();
			const file = event.target.files[0];
			reader.onload = (event) => {
				const data = reader.result;
				workBook = XLSX.read(data, { type: 'binary' });
				jsonData = workBook.SheetNames.reduce((initial, name) => {
					const sheet = workBook.Sheets[name];
					initial[name] = XLSX.utils.sheet_to_json(sheet);
					initial['keys'] = sheet
					var excel = {
						keys: name,
						data: XLSX.utils.sheet_to_json(sheet, {defval:""}) //XLSX.utils.sheet_to_json(workBook.Sheets[sheet], {defval:""}) //XLSX.utils.sheet_to_json(sheet)
					}
					this.excelJsonData.push(excel);
					return initial;
				}, {});
				const dataString = jsonData //JSON.stringify(jsonData);
				if (jsonData) {
					this.isUploadFile = false;
					this.isAssingDate = true;

				}
			}
			reader.readAsBinaryString(file);
		} else {
			this.error = "upload file formate is worng. only accept xlsx, xls and csv"
		}
	}

	isWorksheetDataKey = 'false';
	selectWorkSheetData(e, work) {
		this.worksheetDataKey = null;
		this.excelJsonData.forEach(workbook => {
			if (workbook.keys == e.keys) {
				if (!workbook.data[0]) {
					this.isWorksheetDataKey = 'false';
					return;
				}
				this.selectedExcelData = workbook.data;

				var keys = Object.keys(workbook.data[0]);
				if (keys && keys.length > 0) {
					this.worksheetDataKey = [];
					keys.forEach(element => {
						var iss = {
							key: element,
							isdIsabled: false
						}
						this.worksheetDataKey.push(iss);
					});
				} else {
					this.isWorksheetDataKey = 'false';
				}

			}
		});
		if (this.worksheetDataKey && this.worksheetDataKey.length > 0) {
			this.isWorksheetDataKey = 'true';
		}
	}

	selectData(requireValue, selectedItem) {
		this.worksheetDataKey.forEach(element => {
			if (element.key == selectedItem) {
				this.worksheetDataKey.forEach(keys => {
					if (keys.requireValue == requireValue) {
						delete keys.requireValue;
						keys['isdIsabled'] = false;
						delete element.requireValue;
						element['isdIsabled'] = false;
					}
				});
				element['requireValue'] = requireValue
				element['isdIsabled'] = true;
			}
			if (selectedItem == '') {
				if (element.requireValue == requireValue) {
					delete element['requireValue'];
					element['isdIsabled'] = false;
				}
			}
		});
		this.isSend = false;
	}

	sendTemplate() {
		var convertTemplateJson = [];
		this.selectedExcelData.forEach((excel) => {
			var finalData = {};
			this.worksheetDataKey.forEach(element => {
				Object.keys(excel).forEach(excelKey => {
					if (excelKey.toLowerCase() == element['key'].toLowerCase() && element['requireValue']) {
						finalData[element['requireValue']] = excel[element['key']];
					}
				});
			});
			convertTemplateJson.push(finalData);
		});

		this.TemplateJsonLength = convertTemplateJson.length;
		if (this.isRepeat) {
			convertTemplateJson = convertTemplateJson.filter(function (e) { return e });
			convertTemplateJson = [...new Map(convertTemplateJson.map((json) => [json.to, json])).values()];
			this.convertTemplateJsonLength = convertTemplateJson.length;
		}
		var datas = {
			"template_id": this.template.id,
			"variables": convertTemplateJson
		}
		if (convertTemplateJson && convertTemplateJson.length > 0) {
			this.templateMessageService.sendTemplate(datas);
		}else {
			this.alertService.webShow('Success', 'Please select a correct data');
		}
	}
}
