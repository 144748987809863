import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	InstagramService, Instagram,
	CommonService,
	FeedService, Feed
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'open-conversation-instagram-history',
	templateUrl: './conversationInstagramHistory.component.html',
	styleUrls: ['./conversationInstagramHistory.component.scss']
})

export class ConversationInstagramHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeInstagram: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Conversation Instagram History';
	authUser: User;
	feed: Feed;
	conversationInstagramHistory: UntypedFormGroup;
	ticketClassName: string = null;

	instagram: Instagram;
	instagrams: Instagram[] = [];
	instagramParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;
	mediaImage: string;

	constructor(public userService: UserService,
		public feedService: FeedService,
		public instagramService: InstagramService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<ConversationInstagramHistoryComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.instagramService.resetParams();
	}

	ngOnInit() {
		this.feedService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.feed = data;
		});
		this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => this.instagramParam = data);
		this.instagramService.item.pipe(untilDestroyed(this)).subscribe(data => this.instagram = data);
		this.instagramService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.instagrams = data;
		});
		this.doLoader();
		this.getConversations();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.instagramService.changeAllItems([]);
		this.instagramService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.ticketClassName = data.ticketClassName;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Conversation Instagram History';
				break;
			default:
				break;
		}
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}

	getConversations() {
		let item = this._data.item;
		if (this._data && this._data.ticketClassName) {
			switch (this._data.ticketClassName) {
				case "Instagram\\Models\\Instagram": case "Instagram":
					this.instagramParam.instagram_id = item.id;
					// this.instagramParam.mediaId = this.feed.resource.mediaId;
					this.instagramParam.is_itrs = null;
					this.instagramParam.is_assigned = null;
					this.instagramParam.all_history = 'true';
					this.instagramParam.only = "id,text,instagram_id,reply_id,client_id,status,from,to,mediaId,commentId,media_url,user_image,uName,created_by,updated_by,created_at,updated_at,people_id,json";
					this.instagramService.getAllItems(this.instagramParam);
					break;
				default:
					break;
			}
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.instagramService.changeAllItems([]);
		this.instagramService.unSubscribe();
	}

	/** Close Feed */
	closeAllFeed(feed): void {
		// let closeDialogRef = this.matDialog.open(ConfirmDialogComponent, {
		// 	disableClose: false,
		// 	width: '400',
		// 	data: {
		// 		type: 'close',
		// 		message: 'Are you sure want to Close all Open Conversation from this media ? So, that you may lose your un-assigned conversations.',
		// 		resource: feed,
		// 		clientSiteId: (feed) ? feed.client_site_id : null,
		// 	}
		// });
		// closeDialogRef.afterClosed().subscribe(result => {
		// 	if (result && result.data) {
		// 		let formData: any = null;
		// 		switch (feed.siteName) {
		// 			case "Instagram":
		// 				formData = {
		// 					'mediaId': (feed && feed.resource && feed.resource.mediaId) ? feed.resource.mediaId : null,
		// 					'mediaSource': 'open-conversation',
		// 					'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
		// 					'title' : 'Ticket Closed'
		// 				};
		// 				break;
		// 			case "Youtube":
		// 				formData = {
		// 					'mediaId': (feed && feed.resource && feed.resource.videoId) ? feed.resource.videoId : null,
		// 					'mediaSource': 'open-conversation',
		// 					'client_site_id': (feed && feed.client_site_id) ? feed.client_site_id : null,
		// 					'title' : 'Ticket Closed'
		// 				};
		// 				break;
		// 			default:
		// 				break;
		// 		}
		// 		if(formData) {
		// 			this.feedService.close(feed.id, formData, 'feed-close-allbymedia');
		// 			setTimeout(() => {
		// 				this.feedService.getAllItems();
		// 			}, 100);
		// 		}
		// 	}
		// });
	}
}
