import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	MediaService, Media,
    ChatService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'gallery-item-document',
	templateUrl: './document.component.html',
	styleUrls: ['./document.component.scss']
})

export class DocumentComponent implements OnInit, OnDestroy {
	authUser: User;
    @Input() media: Media = null;
    @Input() showCaption: boolean = true;
    @Input() showSend: boolean = false;
    @Output() onSendMedia = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() onFavorite = new EventEmitter();
    @Output() onShareMedia = new EventEmitter();
	@Output() onSelected = new EventEmitter();
    @Input() isChatMedia: boolean = false;
    documentAssets: any;

	constructor(public userService: UserService,
        public mediaService: MediaService,
		private cd: ChangeDetectorRef, private chatService: ChatService) {
        
		this.chatService.assetsDetails.pipe(untilDestroyed(this)).subscribe(data => {
			this.documentAssets = null;
			if (data && data.length > 0) {
				data.forEach(assets => {
					if (assets.type == 'document' && assets.allowedFormats && assets.allowedFormats.length > 0) {
						this.documentAssets = assets;
						var access = [];
						assets.allowedFormats.forEach(element => {
							access.push('.' + element)
						});
						this.documentAssets['accept'] = access;
					}
				});
			}
		});
	}
	ngOnInit() {
        this.cd.detectChanges();
    }

	/** On destroy */
    ngOnDestroy(): void {
        this.isChatMedia = false;
        this.cd.detectChanges();
    }


    showPreview(media: Media) {
        this.mediaService.changeItem(media);
    }

	assetsMatch(accept) {
		var isMatch = false;
		var acceptsAssets: Array<any> = this.documentAssets['accept'];
		if (acceptsAssets && acceptsAssets.length > 0) {
			accept.forEach(element => {
				if (acceptsAssets.indexOf(element) > -1 || acceptsAssets.indexOf(element.toLowerCase()) > -1) {
					isMatch = true;
					return false;
				}
			});
			return isMatch;
		}
	}
}
