import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import {
  User, UserService,
  AgentService,
  BranchService,
  Branch
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { SelectionModel } from '@angular/cdk/collections';
import { AssignClientComponent } from '../assign-client/assign-client.component';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss']
})
export class BranchListComponent {
  @Output() isEditable = new EventEmitter();
  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;
  branchList: Branch[] = [];
  param: any;
  usageParam: any;
  dataSource = new MatTableDataSource(this.branchList);
  displayedColumns = ['name', 'brand', 'pickup','work','delivery', 'address', 'status', 'action'];
  @Input() isEditProduct: boolean = false;
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    public branchService: BranchService,
    public agentService: AgentService,
    public matDialog: MatDialog,
    private orderByPipe: OrderByPipe) {
    this.userService.user.subscribe(data => this.authUser = data);
    
    this.branchService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) { this.subLoader = false; }
      this.branchList = data;
      this.branchList = this.orderByPipe.transform(data, '-_id');
      // this.branchList = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.branchList);
      this.dataSource.sort = this.sort;
    });

  }


  ngOnInit(): void {
    this.doLoader();
    this.dataInit();
  }
  /** On destroy */
  ngOnDestroy(): void {
    this.branchService.unSubscribeFilter();
    this.branchService.unSubscribe();
    this.branchService.changeAllItems([]);
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  dataInit() {
    this.branchService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
    this.branchService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  editProduct(catalog: any) {
    this.isEditable.emit(true);
    this.branchService.itemSource.next(catalog);
  }

  /** Delete Social Site */
  deleteProduct(item: Branch): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var id = {
          id: item._id.toString()
        }
        this.branchService.destroy('branch/delete', id);
      }
    });
  }

  removeBussiness(item: any) {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'info',
        message: 'Are you sure you want to Unlink the Client Site?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var mark = {
          "id": item._id.toString(),
          "client_site_id": item.clientSiteInfo.id
        }
        this.branchService.statusUpdate("brand/removeclientsite", mark);
      }
    });
  }

  statusChange(id: any, event: any) {
    var is_active;
    if (event == false) {
      is_active = 0;
    } else {
      is_active = 1;
    }
    var update = {
      "availability": is_active,
      "id": id
    }
    this.branchService.statusUpdate('branch/availability', update);
  }

pickupChange(id: any, event: any) {
    var is_active;
    if (event == false) {
      is_active = 0;
    } else {
      is_active = 1;
    }
    var update = {
      "pickup": is_active,
      "id": id
    }
    this.branchService.statusUpdate('branch/pickup', update);
  }

	inActiveAsDefault(branch: any): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to remove this Head Quarters as Default Branch ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.branchService.statusUpdate( 'branch/headquarters', { 'is_headquarters': 0, 'id': branch._id });
			}
		});
	}

activeAsDefault(branch: any): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'info',
				message: 'Are you sure you want to set this Head Quarters as Default Branch ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.branchService.statusUpdate( 'branch/headquarters', { 'is_headquarters': 1, 'id': branch._id });
			}
		});
	}

  // selection = new SelectionModel<any>(true, []);

  // /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //     // this.agentService.isSelectAgent = true;
  //     const numSelected = this.selection.selected.length;
  //     const numRows = this.dataSource.data.length;
  //     if (numSelected > 0) {
  //         this.agentService.isSelectAgent = true;
  //     } else {
  //         this.agentService.isSelectAgent = false;
  //     }
  //     return numSelected === numRows;
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //     if (this.isAllSelected()) {
  //         this.selection.clear();
  //         return;
  //     }
  //     this.agentService.isSelectAgent = true;
  //     this.selection.select(...this.dataSource.data);
  // }

  // /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: any): string {
  //     if (!row) {
  //         return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //     }
  //     // this.agentService.isSelectAgent = true;
  //     return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  // }

  // deleteProductAll() {
  //     var selectedAgent = { product_id: [] };
  //     if (this.selection && this.selection['_selected'] && this.selection['_selected'].length > 0) {
  //         this.selection['_selected'].forEach(element => {
  //             if (this.authUser && this.authUser.person_id != element.id) {
  //                 selectedAgent.product_id.push(element.id);
  //             }
  //         });
  //         // console.log('selectedAgent', selectedAgent);
  //         this.branchService.destroyAll('fbProduct/delete', selectedAgent);

  //         setTimeout(() => {
  //             this.selection.clear();
  //             this.branchService.isSelectProductSource.next(false);
  //         }, 1000);
  //     } else {
  //         // this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
  //     }
  // }
}