import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { FormComponent } from './form/form.component';
import { FormMenuComponent } from './formmenu/formMenu.component';

import { PermissionService } from 'src/app/@tji/_dbShare';
import { MenuGroupRouteModule } from './menuGroup-route.module';
import { MenuListComponent } from './menuList/menuList.component';
import { SubMenuListComponent } from './subMenuList/subMenuList.component';
import { FormSubMenuComponent } from './formSubMenu/formSubMenu.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        FormComponent,
        FormMenuComponent,
        MenuListComponent,
        SubMenuListComponent,
        FormSubMenuComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        MenuGroupRouteModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        FormMenuComponent,
        FormSubMenuComponent
    ],
    providers: [
        PermissionService
    ]
})

export class MenuGroupModule { }
