import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsRouteModule } from './analytics-route.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';

import { ClientSiteService, RClientSiteService, SiteService, IntractiveCommandService, ParamService } from 'src/app/@tji/_dbShare';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        AnalyticsRouteModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
    ],
    providers: [
        RClientSiteService,
        ClientSiteService,
        SiteService,
        IntractiveCommandService,
        ParamService
    ]
})

export class AnalyticsModule { }
