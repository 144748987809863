import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';

import {
	User, UserService, CommonService,
	InstagramTicketService, InstagramTicket,
	TicketActivityService, TicketActivity
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'instagram-ticket-activity-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ActivityListComponent implements OnInit, OnDestroy {
	routeParam: string = null;
	loader: boolean = true;
	baseImage: string = this.commonService.logo_image;
	authUser: User;
	ticket: InstagramTicket;
	ticketActivities: TicketActivity[] = [];
	param: any;
	dataSource = new MatTableDataSource(this.ticketActivities);
	displayedColumns = ['date', 'avatar', 'event', 'lifeTime'];
	dataLength: number = 0;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public instagramTicketService: InstagramTicketService,
		public ticketActivityService: TicketActivityService,
		public commonService: CommonService,
		private router: Router,
		private route: ActivatedRoute,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.instagramTicketService.item.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.ticketActivityService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data['ticketEvent'] && data['ticketEvent'].length > 0) {
				this.ticketActivities = data['ticketEvent'];
				this.dataLength = data['ticketEvent'].length;
				this.dataSource = new MatTableDataSource(this.ticketActivities);
			}
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.ticketActivityService.unSubscribeFilter();
	}

	getRouteParams() {
		this.routeParam = this.route.snapshot.paramMap.get('param');
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.ticketActivityService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.ticketActivityService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

}
