import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	AgentService, Agent,
	IntractiveGroupService, IntractiveGroup,
	ModalIntractiveGroupService, ModalIntractiveGroup
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-mapagentform',
	templateUrl: './mapAgentForm.component.html',
	styleUrls: ['./mapAgentForm.component.scss']
})

export class MapAgentFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Map Agent';
	authUser: User;
	agents: Agent[] = [];
	modalIntractiveGroups: ModalIntractiveGroup[] = [];
	intractiveGroup: IntractiveGroup;
	action: string;
	mapAgentForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public agentService: AgentService,
		public intractiveGroupService: IntractiveGroupService,
		public modalIntractiveGroupService: ModalIntractiveGroupService,
		public matDialogRef: MatDialogRef<MapAgentFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.agentService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.modalIntractiveGroupService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.modalIntractiveGroups = data);
		this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveGroup = data;
			this.mapAgentForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { 
	this.agentService.unSubscribeFilter();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			resource_ids: [null, [Validators.required]],
			resource_type: ['agent', [Validators.required]],
			intractive_group_id: [this.intractiveGroup.id || null, [Validators.required]],
			client_id: [this.intractiveGroup.client_id || this.userService.getAuthClientId() || null, [Validators.required]],
		});
	}

	checkAgentSelected(agent: Agent): boolean {
		if(this.modalIntractiveGroups && this.modalIntractiveGroups.length > 0) {
			return (this.modalIntractiveGroups.filter(x => {
				return (x && x.resource_id === agent.id && x.resource_type === 'agent' && 
					x.intractive_group_id === this.intractiveGroup.id && x.client_id === this.intractiveGroup.client_id) ? true : false;
			}).length > 0) ? true : false;
		}
		return false;
	}


}
