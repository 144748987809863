import { Component, ElementRef, ChangeDetectorRef, OnDestroy, OnInit, AfterViewInit, ViewChild, AfterViewChecked, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { environment } from 'src/environments/environment';
import { tjiAnimation } from 'src/app/@tji/animation';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	ChatUserService, ChatUser,
	TeamChatService, TeamChat, TeamChatModel,
	SocketService, EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'appaccount-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	providers: [OrderByPipe],
	animations: tjiAnimation
})

export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('scrollMe') private myScrollContainer: ElementRef;

	authUser: User;
	isAuth: boolean = true;
	authUserId: number = null;
	channel: any;
	newchannel: any;
	isOpened: boolean = false;
	activeUrl: string = this.router.url;
	users: ChatUser[] = [];
	onlineUsers: ChatUser[] = [];
	activeUser: ChatUser = null;
	chats: TeamChat[] = [];
	activeChats: TeamChat[] = [];
	message: string = null;
	eventData: any;

	doUserAnimation: boolean = false;
	doAnimation: boolean = false;
	todoAnimation: boolean = false;

	teamChatParam: any;
	animState: boolean = false;

	alerts: Array<any> = [];
	showAlert: string = "Team Chat";
	alertAnimation: boolean = false;

	media: any = null;

	constructor(public userService: UserService,
		private chatUserService: ChatUserService,
		private teamChatService: TeamChatService,
		private socketService: SocketService,
		private eventService: EventService,
		private orderByPipe: OrderByPipe,
		private router: Router,
		public matDialog: MatDialog,
		private cd: ChangeDetectorRef) {
		this.userService.user.subscribe(data => {
			this.authUser = data;
			this.isAuth = (this.authUser && this.authUser.id > 0) ? true : false;
			this.authUserId = (data && data.id) ? data.id : null;
		});
		this.chatUserService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.users = this.orderByPipe.transform(data, '-isOnline').filter(x => {
				return (this.authUserId && x.id !== this.authUserId) ? true : false;
			});
			this.onlineUsers = data.filter(x => {
				return (this.authUserId && x.isOnline && x.id !== this.authUserId) ? true : false;
			});
			this.getHasChatCount();
		});
		this.teamChatService.params.pipe(untilDestroyed(this)).subscribe(data => this.teamChatParam = data);
		this.teamChatService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.chats = data;
			this.filterChats(data);
		});
		this.teamChatService.alerts.pipe(untilDestroyed(this)).subscribe(data => {
			this.alerts = data;
		});
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
		  this.eventData = data;
		  this.checkEventData();
		});
		document.body.classList.add("myBody");
	}

	ngOnInit() {
		this.subscribeOctopusSocket();
		this.dataInit();
		this.getHasChatCount();
		this.shiftMessage();
		this.cd.detectChanges();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	ngAfterViewChecked() {
		this.scrollToBottom();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.chatUserService.unSubscribe();
		this.chatUserService.unSubscribeFilter();
	}

	subscribeOctopusSocket() {
		if(environment.enableOctopusSocket) {
			let channel = 'chat.'+this.authUserId;
			this.channel = this.socketService.subscribeChannelRaw(channel, 'ChatEvent');
		}
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.eventName) ? this.eventData.eventName : null;
		if (eventName) {
			let data = this.eventData.data;
			switch (eventName) {
			case 'ChatEvent':
			case 'chatEvent':
				let chatData = this.eventData;
				let userData = (chatData && chatData.createdBy && chatData.createdBy.id) ? chatData.createdBy : null;
				this.teamChatService.addAlert(chatData.createdByName + ' has send New Message');
				this.teamChatService.concatlibrary([chatData]);
				if(userData && userData.id) {
					this.chatUserService.changeAllItemsByItem(userData);
					if(this.activeUser && this.activeUser.id) {
						setTimeout(() => {
							this.scrollToBottom();
							this.cd.detectChanges();
						}, 100);
					}
				}
				break;
			default:
				break;
			}
		}
	}

	dataInit() {
		// this.getUsers();
		// this.getChats();
	}

	getUsers() {
		this.chatUserService.getAllItems();
	}

	getChats() {
		this.teamChatParam.userId = this.authUser.id;
		this.teamChatParam.markAsRead = true;
		this.teamChatService.getAllItems();
	}

	getAlertMessage(): string {
		if(this.alerts && this.alerts.length > 0) {
			this.showAlert = this.alerts[0];
		} else {
			this.showAlert = "Team Chat";
		}
		return this.showAlert;
	}

	shiftMessage() {
		setTimeout(() => {
			this.alerts.shift();
			this.shiftMessage();
		}, 5000);
	}

	selectUser(user: ChatUser) {
		this.activeUser = user;
		user.chatUnreadCount = 0;
		this.chatUserService.changeItem(user);
		this.teamChatParam.userId = user.id;
		this.teamChatParam.paginate = 25;
		this.teamChatParam.page = 1;
		this.teamChatService.getAllItems();
		this.teamChatService.clearAlerts();
		this.filterChats(this.chats);
		setTimeout(() => {
			this.scrollToBottom();
			this.cd.detectChanges();
		}, 300);
	}

	filterChats(data: TeamChat[]) {
		if (this.activeUser && this.activeUser.id && this.authUserId) {
			let activeUserId = this.activeUser.id;
			this.activeChats = this.chats.filter(function (chat) {
				return (chat.fromId == activeUserId || chat.toId == activeUserId);
			});
		} else {
			this.activeChats = [];
		}
	}

	sendMessage() {
		if (this.message && this.message !== '' && this.message !== null) {
			let data: TeamChat = new TeamChatModel({});
			data.user_id = this.activeUser.id;
			data.text = this.message;
			data.message = this.message;
			this.message = null;
			this.teamChatService.store(data);
			setTimeout(() => {
				this.scrollToBottom();
				this.cd.detectChanges();
			}, 300);
		}
	}

	closeChat() {
		this.activeUser = null;
		setTimeout(() => {
			this.scrollToTop();
			this.cd.detectChanges();
		}, 100);
	}

	scrollToTop(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = 0;
        } catch(err) { }                 
    }

	scrollToBottom(): void {
		if(this.activeUser && this.activeUser.id) {
	        try {
	            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
	        } catch(err) { }                 
	    }
    }

    getHasChatCount(): boolean {
    	let unReadCount = 0;
    	this.users.map((item, key) => {
    		var chatUnreadCount = (item.chatUnreadCount) ? item.chatUnreadCount : 0;
    		unReadCount = unReadCount + chatUnreadCount;
    	})
    	this.todoAnimation = (unReadCount && unReadCount > 0) ? true : false;
	    return;
    }

    onTotalChatCountAnimateDone() {
    	this.doAnimation = !this.doAnimation;
    }

    doUserAnimate(user: ChatUser): boolean {
    	if(user && user.id && user.chatUnreadCount && user.chatUnreadCount > 0) {
    		return true;
    	} else {
    		return false;
    	}
    }

    onUserAnimateDone() {
    	this.doUserAnimation = !this.doUserAnimation;
    }

    onAlertAnimationDone() {
    	setTimeout(() => {
			this.alertAnimation = !this.alertAnimation;
		}, 500);
    }

    onFileSelected(event) {
		let reader = new FileReader();
		let mediaData = null;

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				let data: TeamChat = new TeamChatModel({});
				data.file_data = reader.result;
				data.file_name = file.name;
				data.file_type = file.type;
				data.user_id = this.activeUser.id;
				data.text = 'media';
				data.message = 'media';
				this.message = null;
				this.teamChatService.store(data);
				setTimeout(() => {
					this.scrollToBottom();
					this.cd.detectChanges();
					data.file_data = null;
					data.file_type = null;
					data.file_name = null;
				}, 300);
			};
		}
	}

	
}
