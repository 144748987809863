import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

import {
	User, UserService,
	Poster, PosterService, PosterModel, 
	PosterAsset, PosterAssetModel,
	ClientSite, ClientSiteService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
	selector: 'smm-poster-form',
	templateUrl: './posterForm.component.html',
	styleUrls: ['./posterForm.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})

export class PosterFormComponent implements OnInit, OnDestroy {
	subLoader: boolean = false;
	user: User;
	poster: Poster;
	posterForm: UntypedFormGroup;
	allAssets: PosterAsset[] = [];
	clientSites: ClientSite[] = [];
	@Output() onAllAssets = new EventEmitter();

	constructor(public userService: UserService,
		public posterService: PosterService,
		public clientSiteService: ClientSiteService,
		private dialog: MatDialog,
		private router: Router,
		public location: Location,
		public matDialog: MatDialog,
		private formBuilder: UntypedFormBuilder,
		private dateAdapter: DateAdapter<Date>) {
		this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
		this.userService.user.subscribe(data => this.user = data);
		this.clientSiteService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data.filter(x => { return (x.details && x.details.type === 'smm') ? true : false ; });
		});
		this.posterService.item.subscribe(data => {
			this.poster = data;
			this.posterForm = this.createForm();
		});
	}

	ngOnInit() {
		this.checkForm();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.posterService.unSubscribe();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this.formBuilder.group({
            client_id: [this.poster.client_id || this.userService.getAuthClientId() || null, [Validators.required]],
			title: [this.poster.title || null, [Validators.required]],
			description: [this.poster.description || null],
			url: [this.poster.url || null],
			post_at: [this.poster.post_at || null, [Validators.required]],
			allAssets: [this.poster.allAssets || this.allAssets || null],
			clientSiteIds: [this.poster.clientSiteIds || []],
		});
	}

	checkForm() {
		this.posterForm.get("title").valueChanges.subscribe(selectedValue => {
			this.poster.title = selectedValue;
	    });
		this.posterForm.get("description").valueChanges.subscribe(selectedValue => {
			this.poster.description = selectedValue;
	    });
		this.posterForm.get("url").valueChanges.subscribe(selectedValue => {
			this.poster.url = selectedValue;
	    });
		this.posterForm.get("post_at").valueChanges.subscribe(selectedValue => {
			this.poster.post_at = selectedValue;
	    });
		this.posterForm.get("allAssets").valueChanges.subscribe(selectedValue => {
			this.poster.allAssets = selectedValue;
			this.onAllAssets.emit(selectedValue);
	    });
	}

	readFile(event){
		if (event.target.files && event.target.files.length) {
			for (let i=0; i < event.target.files.length; i++) {
				let reader = new FileReader();
				let asset: PosterAsset = new PosterAssetModel({});
				const file = event.target.files[i];
				reader.readAsDataURL(file);
				reader.onload = (e: any) => {
					asset.index = this.allAssets.length;
					asset.file_data = reader.result;
					asset.file_name = file.name;
					asset.file_type = file.type;
					this.allAssets.push(asset);
				};
			}
		}
		this.posterForm.get('allAssets').setValue(this.allAssets);
	}

	removeAsset(asset) {
		var lists = this.allAssets.filter(x => { return (x.index != asset.index) ? true : false; });
		this.allAssets = lists;
		this.posterForm.get('allAssets').setValue(lists);
	}

	clearForm() {
		let clientId = (this.poster.client_id)  ? this.poster.client_id : this.userService.getAuthClientId();
		this.posterForm.reset();
		this.posterForm.get('client_id').setValue(clientId);
        this.poster.client_id = clientId;
	}

	setClientId() {
		let clientId = (this.poster.client_id)  ? this.poster.client_id : this.userService.getAuthClientId();
		this.posterForm.get('client_id').setValue(clientId);
        this.poster.client_id = clientId;
	}

	checkAndNavigate() {
		setTimeout(() => { 
			if(this.poster && this.poster.id && this.poster.id > 0) {
				this.subLoader = false;
                this.router.navigateByUrl('app/smm/poster');
			}
		}, 10000);
	}
	addPoster() {
        if (this.checkValidatePoster()) {
			this.subLoader = true;
			this.posterForm.get("clientSiteIds").setValue(this.poster.clientSiteIds);
			this.posterService.store(this.posterForm.value);
			this.checkAndNavigate();
		}
	}

    checkValidatePoster(): boolean {
    	let output : boolean = false;
        let clientSiteIds: Array<any> = this.poster.clientSiteIds;
        output = (clientSiteIds && clientSiteIds.length > 0) ? true : false;
        return output;
    }

    checkSelectedClientSite(clientSite: ClientSite): boolean {
		let output: boolean = false;
        let clientSiteIds: Array<any> = this.poster.clientSiteIds;
		if(clientSiteIds && clientSiteIds.length > 0 ) {
            for (let i = 0; i < clientSiteIds.length; i++) {
            	if(clientSiteIds[i] === clientSite.id) {
                    output = true;
                    break;
            	}
            }
		}
        return output;
	}

	onSelectClientSite(clientSite: ClientSite) {
        this.setClientId();
		let doMerge: boolean = true;
		let clientSiteIds: Array<any> = this.poster.clientSiteIds;
		let filteredItems = this.poster.clientSiteIds.filter(x => {
			return (x && x === clientSite.id) ? true : false;
		});
		if(this.poster && this.poster.clientSiteIds && this.poster.clientSiteIds.length > 0 &&
			filteredItems && filteredItems.length > 0) {
			doMerge = false;
		}
		if(doMerge) {
			clientSiteIds.push(clientSite.id);
			this.poster.clientSiteIds = clientSiteIds;
		} else {
            let index = clientSiteIds.indexOf(clientSite.id);
            clientSiteIds.splice(index, 1);
            this.poster.clientSiteIds = clientSiteIds;
		}
	}

	onRemoveClientSite(clientSite: ClientSite) {
		let clientSiteIds: Array<any> = this.poster.clientSiteIds;
		if (clientSiteIds && clientSiteIds.length > 0) {
			for (var i = 0; i < clientSiteIds.length; ++i) {
				if (clientSiteIds[i] === clientSite.id) { clientSiteIds.splice(i, 1); }
			}
		}
		this.poster.clientSiteIds = clientSiteIds;
	}

	checkIsSelected(clientSite: ClientSite): boolean {
		let isSelected: boolean = false;
		let filteredItems = this.poster.clientSiteIds.filter(x => {
			return (x && x === clientSite.id) ? true : false;
		});
		if(this.poster && this.poster.clientSiteIds && this.poster.clientSiteIds.length > 0 && 
			filteredItems && filteredItems.length > 0) {
			isSelected = true;
		}
		return isSelected;
	}
}
