import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
// import { TemplateFormComponent } from './../templateForm/templateForm.component';

import {
	User, UserService,
	RTemplateMessageService, RTemplateMessage, RTemplateMessageModel,
	 EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'fb-template-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	subLoader: boolean = false;
	authUser: User;
	templateLists: RTemplateMessage[] = [];
	param: any;
	usageParam: any;
	dataSource = new MatTableDataSource(this.templateLists);
	displayedColumns = ['name','language', 'category','clientName', 'account', 'status'];

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// MatPaginator Output
	pageEvent: PageEvent;
	dataLength: number = 0;
	eventData: any;

	constructor(public userService: UserService,
		public rtemplateMessageService: RTemplateMessageService,
		public eventService: EventService,
		private snackBar: MatSnackBar,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		// this.templateUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.usageParam = data);
		this.rtemplateMessageService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.templateLists) { this.subLoader = false; }
			this.templateLists = data;
			this.templateLists = this.orderByPipe.transform(data, '-_id');
			this.dataSource = new MatTableDataSource(this.templateLists);
			this.dataSource.sort = this.sort;
		});
		this.dataSource = new MatTableDataSource(this.templateLists);
		this.dataSource.sort = this.sort;
		this.rtemplateMessageService.templateStatusUpdate.next(false);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.rtemplateMessageService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.rtemplateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.rtemplateMessageService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Template Message */
	newTemplate(template = null): void {
		this.router.navigateByUrl('/app/template/form');
		// this.rtemplateMessageService.changeItem((template) ? template : new TemplateMessageModel({}));
		// let dialogRef = this.matDialog.open(FormComponent, {
		// 	width: "600px",
		// 	data: {
		// 		action: (template) ? 'edit' : 'new',
		// 		item: template,
		// 	}
		// });

		// dialogRef.afterClosed()
		// 	.subscribe(response => {
		// 		if (!response) { return; }
		// 		const actionType: string = response[0];
		// 		const formData: FormGroup = response[1];
		// 		const id: number = (response[2]) ? response[2] : null;
		// 		switch (actionType) {
		// 			case 'new':
		// 				this.rtemplateMessageService.store(formData.value);
		// 				break;
		// 			case 'update':
		// 				this.rtemplateMessageService.update(id, formData.value);
		// 				break;
		// 			case 'delete':
		// 				this.deleteTemplate(template);
		// 				break;
		// 		}
		// 	});
	}

	/** Delete Social Site */
	deleteTemplate(template: RTemplateMessage): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var temp = {
					id: template._id
				}
				this.rtemplateMessageService.destroy("fbTemplate/delete", temp);
			}
		});
	}

	selectedItem(template) {
		this.rtemplateMessageService.changeItem(template);
		this.router.navigate(['/app/template/' + template.id]);
	}

	viewHistory(template) {
		// this.usageParam.template_id = template.id;
		// this.templateUsageService.getAllItems();
		this.rtemplateMessageService.changeItem(template);
		this.router.navigate(['/app/template/history/' + template.id]);
	}

	statusChange(id, event) {
		this.rtemplateMessageService.update(id, { 'status': event });
	}

	// /** Send Template Message to Lead */
	// sendTemplateMessage(template: FbTemplateMessage): void {
	// 	this.rtemplateMessageService.changeItem(template);
	// 	// this.consumerGroupService.changeItem(consumerGroup);
	// 	let templateDialogRef = this.matDialog.open(FbTemplateFormComponent, {
	// 		disableClose: false,
	// 		width: '600',
	// 		data: template
	// 	});
	// 	templateDialogRef.afterClosed().subscribe(result => {
	// 		if (result && result.template_id && result.data) {
	// 			// result['client_site_id'] = template.client_site_id;
	// 			// this.rtemplateMessageService.postUrl(result,'send-bulk-template');
	// 		}
	// 	});
	// }

	/** Send Template Message to Lead */

	viewTemplate(template) {
		this.rtemplateMessageService.changeItem(template)
		this.rtemplateMessageService.templateViewsidebar.next(true);
	}

	viewApiTemplate(template) {
		this.rtemplateMessageService.changeItem(template)
		this.rtemplateMessageService.apiTemplateViewsidebar.next(true);
	}


	checkEventData() {
		if (this.eventData) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'templatecreate':
					this.templateUpdate(this.eventData.data, "templatecreate")
					break;
				case 'templatedelete':
					this.rtemplateMessageService.spliceItem(this.eventData.data._id);
					break;
				case 'templateupdate':
					this.templateUpdate(this.eventData.data, "templateupdate")
					break;
				case 'templatevent':
					this.templateUpdate(this.eventData.data, "templatevent")
					break;
				default:
					break;
			}
		}
	}

	templateUpdate(templateData: any, eventData: any) {

		if (templateData.clientSiteInfo) {
			if (templateData.clientSiteInfo.id) {
				templateData["siteName"] = templateData.clientSiteInfo?.details?.name;
				templateData["clientSiteName"] = templateData.clientSiteInfo?.account_name;
				templateData["clientSiteColor"] = templateData.clientSiteInfo?.color;
				templateData["clientSiteTextColor"] = templateData.clientSiteInfo?.textcolor;
				if (templateData.clientSiteInfo.mongo && templateData.clientSiteInfo.mongo.wabaFullInfo) {
					templateData["waba_name"] = templateData.clientSiteInfo.mongo.wabaFullInfo.name ? templateData.clientSiteInfo.mongo.wabaFullInfo.name : '--'
				}
				if (templateData.clientSiteInfo.mongo && templateData.clientSiteInfo.mongo.business_info) {
					templateData["business_name"] = templateData.clientSiteInfo.mongo.business_info.name ? templateData.clientSiteInfo.mongo.business_info.name : '--'
				}
			}
		}
		if (templateData.components && templateData.components.length > 0) {
			templateData.components.forEach(component => {
				if (component.type.toLowerCase() == 'carousel') {
					templateData['isCarousel'] = true;
				}
			});
		}

		if (eventData == 'templatecreate') {
			this.rtemplateMessageService.concatItem(templateData);
		}
		if (eventData == 'templateupdate') {
			this.rtemplateMessageService.changeAllItemsByItem(this.eventData.data);
		}
		if (eventData == 'templatevent') {
			this.rtemplateMessageService.changeAllItemsByItem(this.eventData.data);
		}

	}

}
