import { PremiumAlert, CountWidget } from './premiumDashboard.interface';

export class PremiumDashboardModel {
	client: any;
	count_widgets: Array<any>;
	agents_count: Array<any>;
	agents_tickets_count: Array<any>;
	lead_boards: Array<any>;
	live_tickets: Array<any>;
	live_messages: Array<any>;
	performances: Array<any>;

	/**
	 * Constructor
	 * @param premiumDashboard
	 */
	constructor(premiumDashboard) {
		this.client = premiumDashboard.client || null;
		this.count_widgets = premiumDashboard.count_widgets || [];
		this.agents_count = premiumDashboard.agents_count || [];
		this.agents_tickets_count = premiumDashboard.agents_tickets_count || [];
		this.lead_boards = premiumDashboard.lead_boards || [];
		this.live_tickets = premiumDashboard.live_tickets || [];
		this.live_messages = premiumDashboard.live_messages || [];
		this.performances = premiumDashboard.performances || [];
	}
}

export class CountWidgetModel {
	name: string;
	client_site_id: number;
	site_name: string;
	counts?: Array<any>;

	/**
	 * Constructor
	 * @param countWidget
	 */
	constructor(countWidget) {
		this.name = countWidget.name || null;
		this.client_site_id = countWidget.client_site_id || null;
		this.site_name = countWidget.site_name || null;
		this.counts = countWidget.counts || [];
	}
}

export class PremiumAlertModel {
	image: string;
	title: string;
	description: string;
	status?: string;
	created_at: Date;

	/**
	 * Constructor
	 * @param premiumAlert
	 */
	constructor(premiumAlert) {
		this.image = premiumAlert.image || null;
		this.title = premiumAlert.title || null;
		this.description = premiumAlert.description || null;
		this.status = premiumAlert.status || null;
		this.created_at = premiumAlert.created_at || null;
	}
}

export class PremiumLiveTicketModel {
	client_id: number;
	accountName: string;
	siteName: string;
	agentName: string;
	peopleName: string;
	unique_ref: string;
	avatar_url?: string;
	ticketId: number;
	ticketCode: string;
	description: string;
	created_at: Date;

	/**
	 * Constructor
	 * @param premiumLiveTicket
	 */
	constructor(premiumLiveTicket) {
		this.client_id = premiumLiveTicket.client_id || null;
		this.accountName = premiumLiveTicket.accountName || null;
		this.siteName = premiumLiveTicket.siteName || null;
		this.agentName = premiumLiveTicket.agentName || null;
		this.peopleName = premiumLiveTicket.peopleName || null;
		this.unique_ref = premiumLiveTicket.unique_ref || null;
		this.avatar_url = premiumLiveTicket.avatar_url || null;
		this.ticketId = premiumLiveTicket.ticketId || null;
		this.ticketCode = premiumLiveTicket.ticketCode || null;
		this.description = premiumLiveTicket.description || null;
		this.created_at = premiumLiveTicket.created_at || null;
	}
}

export class PremiumLiveMessageModel {
	client_id: number;
	accountName: string;
	siteName: string;
	agentName: string;
	peopleName: string;
	clientName: string;
	status: string;
	unique_ref: string;
	avatar_url?: string;
	ticketId?: number;
	ticketCode?: string;
	description: string;
	created_at: Date;

	/**
	 * Constructor
	 * @param premiumLiveMessage
	 */
	constructor(premiumLiveMessage) {
		this.client_id = premiumLiveMessage.client_id || null;
		this.accountName = premiumLiveMessage.accountName || null;
		this.siteName = premiumLiveMessage.siteName || null;
		this.agentName = premiumLiveMessage.agentName || null;
		this.peopleName = premiumLiveMessage.peopleName || null;
		this.clientName = premiumLiveMessage.clientName || null;
		this.status = premiumLiveMessage.status || null;
		this.unique_ref = premiumLiveMessage.unique_ref || null;
		this.avatar_url = premiumLiveMessage.avatar_url || null;
		this.ticketId = premiumLiveMessage.ticketId || null;
		this.ticketCode = premiumLiveMessage.ticketCode || null;
		this.description = premiumLiveMessage.description || null;
		this.created_at = premiumLiveMessage.created_at || null;
	}
}

export class PremiumPerformanceModel {
	client_id: number;
	accountName: string;
	siteName: string;
	createdByName: string;
	agentName: string;
	peopleName: string;
	ticketId: number;
	ticketCode: string;
	action: string;
	title: string;
	description: string;
	created_at: Date;

	/**
	 * Constructor
	 * @param premiumPerformance
	 */
	constructor(premiumPerformance) {
		this.client_id = premiumPerformance.client_id || null;
		this.accountName = premiumPerformance.accountName || null;
		this.siteName = premiumPerformance.siteName || null;
		this.createdByName = premiumPerformance.createdByName || null;
		this.agentName = premiumPerformance.agentName || null;
		this.ticketId = premiumPerformance.ticketId || null;
		this.ticketCode = premiumPerformance.ticketCode || null;
		this.peopleName = premiumPerformance.peopleName || null;
		this.action = premiumPerformance.action || null;
		this.title = premiumPerformance.title || null;
		this.description = premiumPerformance.description || null;
		this.created_at = premiumPerformance.created_at || null;
	}
}

export class PremiumLeadBoardModel {
	id: number;
	client_id: number;
	accountName: string;
	siteName: string;
	people_id: number;
	peopleName: string;
	unique_ref: string;
	avatar_url?: string;
	message: string;
	created_at: Date;
	updated_at: Date;

	/**
	 * Constructor
	 * @param premiumLeadBoard
	 */
	constructor(premiumLeadBoard) {
		this.id = premiumLeadBoard.id || null;
		this.client_id = premiumLeadBoard.client_id || null;
		this.accountName = premiumLeadBoard.accountName || null;
		this.siteName = premiumLeadBoard.siteName || null;
		this.people_id = premiumLeadBoard.people_id || null;
		this.peopleName = premiumLeadBoard.peopleName || null;
		this.unique_ref = premiumLeadBoard.unique_ref || null;
		this.avatar_url = premiumLeadBoard.avatar_url || null;
		this.message = premiumLeadBoard.message || null;
		this.created_at = premiumLeadBoard.created_at || null;
		this.updated_at = premiumLeadBoard.updated_at || null;
	}
}

export class ClientAgentModel {
	id: number;
	code: string;
	name: string;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	client_id: number;

	detail?: any;
	user?: any;
	avatar_url?: string;
	totalCloseTickets?: number;
	totalOpenTickets?: number;
	permittedTicketCount?: number;
	liveStatus?: string;
	roleArray?: Array<any>;
	roleIdArray?: Array<any>;
	permissionIdArray?: Array<any>;
	permissionArray?: Array<any>;
	directPermissionIdArray?: Array<any>;
	directPermissionArray?: Array<any>;
	userId?: number;
	resellerId?: number;
	userEmail?: string;

	tableName: string;
	premiumDashboardData?: any;

	/**
	 * Constructor
	 *
	 * @param clientAgent
	 */
	constructor(clientAgent) {
		this.id = clientAgent.id || '';
		this.code = clientAgent.code || '';
		this.name = clientAgent.name || '';
		this.created_by = clientAgent.created_by || '';
		this.updated_by = clientAgent.updated_by || '';
		this.created_at = clientAgent.created_at || '';
		this.updated_at = clientAgent.updated_at || '';
		this.client_id = clientAgent.client_id || '';

		this.detail = clientAgent.detail || {};
		this.user = clientAgent.user || {};
		this.tableName = clientAgent.tableName || '';
		this.premiumDashboardData = clientAgent.premiumDashboardData || null;
	}
}


export class PremiumBestSellingModel {
	count: number;
	_id: number;
	name: string;
	currency: string;
	price: string;
	image_url: string;
	description: string;
	created_at: Date;

	/**
	 * Constructor
	 * @param PremiumBestSelling
	 */
	constructor(PremiumBestSelling) {
		this.count = PremiumBestSelling.count || null;
		this._id = PremiumBestSelling._id || null;
		this.name = PremiumBestSelling.name || null;
		this.currency = PremiumBestSelling.currency || null;
		this.price = PremiumBestSelling.price || null;
		this.image_url = PremiumBestSelling.image_url || null;
		this.description = PremiumBestSelling.description || null;
		this.created_at = PremiumBestSelling.created_at || null;
	}
}
