import { Component, ElementRef, OnDestroy, Input, CUSTOM_ELEMENTS_SCHEMA, NgModule, OnInit, ViewChild, EventEmitter, Output, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, pipe } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import { IntractiveMessageViewComponent } from 'src/app/appAccount/intractiveMessage/intractiveMessage-view/intractiveMessage-view.component';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { LabelChangeFormComponent } from 'src/app/library/forms/labelChangeForm/labelChangeForm.component';

import {
	User, UserService, LoaderService, PeopleModel,
	ChatService, CommonService, PeopleService, People, ModalHasLabelService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-dashboard-ticket-chatboard',
	templateUrl: './chatBoard.component.html',
	styleUrls: ['./chatBoard.component.scss'],
})

export class ChatBoardComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('rightSideBar') public rightSideBar;
	@ViewChild('leftSideBar') public leftSideBar;
	@Output() onExitTicket = new EventEmitter();

	ticket: any = null;
	siteName: string;

	user: User;
	rightSideView: string = 'comment';
	leftSideView: string = 'person';

	constructor(public chatService: ChatService,
		public userService: UserService,
		public commonService: CommonService,
		public peopleService: PeopleService,
		public modalHasLabelService: ModalHasLabelService,
		private router: Router,
		private loaderService: LoaderService,
		public matDialog: MatDialog,
		public sanitizer: DomSanitizer,
		private cd: ChangeDetectorRef,
		private _formBuilder: UntypedFormBuilder) {
	}

	ngOnInit() {
		this.dataInit();
		this.setFlagButton();
		this.setCommentButton();
		this.setUserUpdateButton();
		this.setLeadsHistoryButton();
		this.setTrackTicketButton();
		// this.setCartDetailsButton();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	dataInit() {
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data
		});
		this.chatService.siteName.pipe(untilDestroyed(this)).subscribe(data => this.siteName = data);
	}

	leftSideViewEvent(event) {
		this.leftSideView = event;
		this.leftSideBar.toggle()
	}

	rightSideViewEvent(event) {
		this.rightSideView = event;
		this.rightSideBar.toggle()
	}

	setFlagButton() {
		this.chatService.setFlagButton.pipe(untilDestroyed(this)).subscribe(data => {
			this.rightSideView = 'flag';
			if (data) { this.rightSideBar.open(); }
			else { this.rightSideBar.close(); }
		});
	}

	setCommentButton() {
		this.chatService.setCommentButton.pipe(untilDestroyed(this)).subscribe(data => {
			this.rightSideView = 'comment';
			if (data) { this.rightSideBar.open(); }
			else { this.rightSideBar.close(); }
		});
	}

	setUserUpdateButton() {
		this.chatService.setUserUpdateButton.pipe(untilDestroyed(this)).subscribe(data => {
			this.leftSideView = 'person';
			if (data) { this.leftSideBar.open(); }
			else { this.leftSideBar.close(); }
		});
	}

	setLeadsHistoryButton() {
		this.chatService.setLeadsHistoryButton.pipe(untilDestroyed(this)).subscribe(data => {
			this.leftSideView = 'leadsHistory';
			if (data) { this.leftSideBar.open(); }
			else { this.leftSideBar.close(); }
		});
	}

	// setCartDetailsButton() {
	// 	this.chatService.setCartDetailsButton.pipe(untilDestroyed(this)).subscribe(data => {
	// 		this.rightSideView = 'cartDetails';
	// 		if (data) { this.rightSideBar.open(); }
	// 		// else { this.rightSideBar.close(); }
	// 	});
	// }
	setTrackTicketButton() {
		this.chatService.setTrackTickeButton.pipe(untilDestroyed(this)).subscribe(data => {
			this.rightSideView = 'trackTicket';
			if (data) { this.rightSideBar.open(); }
			// else { this.rightSideBar.close(); }
		});
	}
}
