import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService,
	AgentService, Agent,
	PersonSettingService, PersonSetting
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'agent-item-setting',
	templateUrl: './setting.component.html',
	styleUrls: ['./setting.component.scss']
})

export class SettingComponent {
	agent: Agent;
	settings: PersonSetting[] = [];
	selectedSetting: PersonSetting = null;
	personSettingParam: any = null;
	weeks: Array<any> = [
        {'id': 'sunday', 'value': 'Sunday'},
        {'id': 'monday', 'value': 'Monday'},
        {'id': 'tuesday', 'value': 'Tuesday'},
        {'id': 'wednesday', 'value': 'Wednesday'},
        {'id': 'thursday', 'value': 'Thursday'},
        {'id': 'friday', 'value': 'Friday'},
        {'id': 'saturday', 'value': 'Saturday'},
    ];

	constructor(private _formBuilder: UntypedFormBuilder,
		public agentService: AgentService,
		public personSettingService: PersonSettingService,
		public matDialog: MatDialog) {
		this.personSettingService.params.pipe(untilDestroyed(this)).subscribe(data => this.personSettingParam = data);
		this.personSettingService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.settings = data.filter(x => {
				return (!x.is_client_editable && x.is_agent_editable) ? true : false;
			});
		});
	}

	ngOnInit() {
		this.agentService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.agent = data;
			this.getPersonSettings();
		});
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getPersonSettings() {
		this.personSettingParam.person_id = (this.agent) ? this.agent.id : null;
		this.personSettingParam.person_type = (this.agent) ? this.agent.tableName : null;
		if (this.agent && this.agent.id) {
			this.personSettingService.getAllItems();
		}
	}

	editMode(event, personSetting: PersonSetting) {
		if(event.ctrlKey) {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '600',
				data: {
					type: 'delete',
					message: 'Are you sure you want to delete?',
				}
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.data) {
					this.personSettingService.destroy(personSetting.id);
				}
			});
		} else {
			this.selectedSetting = personSetting;
		}
	}

	saveSetting(id: number, value: string) {
		// if(this.userService.permissionMatch(['Manage Setting'])) {
		let data = {
			'value': value,
			'id': id
			// 'person_id': (this.agent) ? this.agent.id : null,
			// 'person_type': (this.agent) ? this.agent.tableName : null,
		}
		this.personSettingService.update(data);
		// }
	}

	strToArr(strValue: string): Array<any> {
		return strValue.split(',');
	}
}
