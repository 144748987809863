export class WebchatModel {
    id: number;
    webchat_id: number;
    reply_id: number;
    client_id: number;
    client_site_id: number;
    uuid: string;
    text: string;
    message?: string;
    from: string;
    to: string;
    user_image: string;
    media: string;
    asset_url: string;
    json: string;
    status: string;
    is_active: boolean;
    is_assigned: boolean;
    is_read: boolean;
    is_closed: boolean;
    is_itrs: boolean;
    p_intractive_id: number;
    intractive_id: number;
    intractive_group_id: number;
    people_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;

    ticketId?: number;
    peopleName?: string;
    peopleAvatar?: string;
    isSent?: boolean;
    pusherSync?: string;
    agentName?: string;
    createdByName?: string;
    createdByAvatar?: string;
    location_url?: string;
    clientSiteName?: string;
    clientSiteColor?: string;
    clientSiteTextColor?: string;
    reply?: any;

    tableName: string;
    className: string;
    templateData?: any;

    /**
     * Constructor
     *
     * @param webchat
     */
    constructor(webchat) {
            this.id = webchat.id || '';
            this.webchat_id = webchat.webchat_id || '';
            this.reply_id = webchat.reply_id || '';
            this.client_id = webchat.client_id || '';
            this.client_site_id = webchat.client_site_id || '';
            this.uuid = webchat.uuid || '';
            this.text = webchat.text || '';
            this.from = webchat.from || '';
            this.to = webchat.to || '';
            this.user_image = webchat.user_image || '';
            this.media = webchat.media || '';
            this.asset_url = webchat.asset_url || '';
            this.json = webchat.json || '';
            this.status = webchat.status || '';
            this.is_active = webchat.is_active || '';
            this.is_assigned = webchat.is_assigned || '';
            this.is_read = webchat.is_read || '';
            this.is_closed = webchat.is_closed || '';
            this.is_itrs = webchat.is_itrs || '';
            this.p_intractive_id = webchat.p_intractive_id || '';
            this.intractive_id = webchat.intractive_id || '';
            this.intractive_group_id = webchat.intractive_group_id || '';
            this.people_id = webchat.people_id || '';
            this.created_by = webchat.created_by || '';
            this.updated_by = webchat.updated_by || '';
            this.created_at = webchat.created_at || '';
            this.updated_at = webchat.updated_at || '';

            this.tableName = webchat.tableName || '';
    }
}
