import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	SiteService, Site,
	ClientSiteService, ClientSite, ClientSiteModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'social-site-messenger-form',
	templateUrl: './messengerForm.component.html',
	styleUrls: ['./messengerForm.component.scss']
})

export class MessengerFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	clientSite: ClientSite;
	sites: Site[] = [];
	selectedSite: Site;
	siteParam: any;
	action: string;
	clientSiteForm: UntypedFormGroup;
	availableProviders: Array<any> = [];
	selectedProvider: any = null;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public siteService: SiteService,
		public matDialogRef: MatDialogRef<MessengerFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.siteService.params.pipe(untilDestroyed(this)).subscribe(data => this.siteParam = data);
		this.siteService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.selectedSite = data;
			this.selectedProvider = data.available_providers[0];
		});
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSite = data;
			if (this.clientSite && this.clientSite.id) {
				this.selectedSite = this.clientSite.details;
				this.availableProviders = this.clientSite.details.available_providers;
				let service_provider_name = (this.clientSite && this.clientSite.service_provider) ? this.clientSite.service_provider : 'default';
				this.selectedProvider = this.clientSite.details.available_providers.find(x => x.name === service_provider_name);
			}
			this.clientSiteForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Messenger Account';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Messenger Account';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			site_id: [this.clientSite.site_id || (this.selectedSite) ? this.selectedSite.id : null || null, [Validators.required]],
			service_provider: [this.clientSite.service_provider || (this.selectedProvider) ? this.selectedProvider.name : null || 'default', [Validators.required]],
			account_name: [this.clientSite.account_name || null, [Validators.required, Validators.minLength(3)]],
			page_id: [this.clientSite.page_id || null, [Validators.minLength(1)]],
			token: [this.clientSite.token || '123ABC456DEF789G', [Validators.minLength(1)]],
			is_active: [this.clientSite.is_active || '1', Validators.required]
		});
	}

	getSites() {
		this.siteParam.all = 1;
		this.siteService.getAllItems();
		this.siteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.sites = data;
			if (this.clientSite && this.clientSite.id) {
				this.changeSite(null, this.clientSite.details);
			}
		});
	}

	changeSite(siteId, siteItem: Site = null, providerName: any = null) {
		providerName = (!providerName) ? 'default' : providerName;
		this.selectedSite = (siteItem && siteItem.id) ? siteItem : this.sites.find(x => x.id === siteId);
		this.availableProviders = this.selectedSite.available_providers;
		if (this.availableProviders && this.availableProviders.length === 1 && !providerName) {
			this.selectedProvider = this.availableProviders[0];
		}
		if (providerName) {
			this.selectedProvider = this.availableProviders.find(x => x.name === providerName);
		}

	}

	checkFillable(fillColumn: string): boolean {
		if (this.selectedProvider && this.selectedProvider.requiredFillable && this.selectedProvider.requiredFillable.length > 0) {
			return (this.selectedProvider.requiredFillable.includes(fillColumn)) ? true : false;
		}
		return false;
	}

}
