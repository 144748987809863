import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import {
  User, UserService,
  TeamService, Team, TeamModel,
  PersonTeamService, PersonTeam, PersonTeamModel, CommonService, AlertService
} from 'src/app/@tji/_dbShare';
@Component({
  selector: 'app-product-settings',
  templateUrl: './product-settings.component.html',
  styleUrls: ['./product-settings.component.scss'],
  providers: [OrderByPipe]
})
export class ProductSettingsComponent implements OnInit {
  settings: [];
  action: string;
  dialogTitle: string = 'Product Settings';
  teams: Team[] = [];
  clientSite: any;
  addProductSettings: UntypedFormGroup = new UntypedFormGroup({
    teams: new UntypedFormControl(''),
    greetings: new UntypedFormControl(''),
  });
  prodSettingData: any;
  prodSettingId: any = null;
  submitted: boolean = false;
  constructor(public teamService: TeamService,
    private orderByPipe: OrderByPipe,
    private commonService: CommonService,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<ProductSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private formBuilder: UntypedFormBuilder) {
    this.teamService.getAllItems();
    delete this.clientSite;
    this.getInit(_data);
  }

  ngOnInit(): void {
    this.dataInit();
    this.addProductSettings = this.formBuilder.group({
      teams: ['', Validators.required],
      greetings: [''],
    })
  }
  get prodSettingsValidation(): { [key: string]: AbstractControl } {
    return this.addProductSettings.controls;
  }
  getInit(data) {
    // Set the defaults
    this.clientSite = data.item;
    this.action = data.action;
    this.getProdSettings();
  }
  dataInit() {
    this.teamService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data)
        this.teams = this.orderByPipe.transform(data, '-is_default');
      if (this.teams && this.teams[0] && this.teams[0].id) {
        this.addProductSettings.controls.teams.setValue(this.teams ? this.teams[0].id : '');
      }
    });
  }
  getProdSettings() {
    var data = {
      "client_site_id": this.clientSite ? this.clientSite.id : '',
      "site_id": this.clientSite ? this.clientSite.site_id : '',
      "prod_setting_id": 'prodSettingsId_'.concat(this.clientSite ? this.clientSite.id : ''.toString())// "'prodSettingsId_' + `this.clientSite ? this.clientSite.id : ''`",
    }
    let tempSub = this.commonService.storeItem('setting', data, true, "optionOne").subscribe((res: any) => {
      if (res.success) {
        if (res.data.data.length > 0) {
          this.prodSettingData = res.data.data;
          this.addProductSettings.controls.greetings.setValue(this.prodSettingData ? this.prodSettingData[0].greetings : '');
          this.addProductSettings.controls.teams.setValue(this.prodSettingData ? this.prodSettingData[0].teams_id : '');
          this.prodSettingId = this.prodSettingData ? this.prodSettingData[0]._id : null;
        }
      }
      tempSub.unsubscribe();
    }, error => {
      this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
      console.error('There was an error!', error);
      tempSub.unsubscribe();
    })
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.addProductSettings.invalid) {
      return;
    }
    this.addProdSettings();
  }
  addProdSettings() {
    var data = {
      "client_site_id": this.clientSite ? this.clientSite.id : '',
      "site_id": this.clientSite ? this.clientSite.site_id : '',
      "teams_id": this.addProductSettings.controls['teams'].value,
      "greetings": this.addProductSettings.controls['greetings'].value,
      "prod_setting_id": 'prodSettingsId_'.concat(this.clientSite ? this.clientSite.id : ''.toString())// "'prodSettingsId_' + `this.clientSite ? this.clientSite.id : ''`",
    }
    if (this.prodSettingId) {
      data['id'] = this.prodSettingId;
      let tempSub = this.commonService.storeItem('setting/update', data, true, "optionOne").subscribe((res: any) => {
        if (res.success) {
          if (res.data && res.data.message) {
            this.alertService.webShow('Info', res.data.message);
          } else {
            this.alertService.webShow('Info', 'Updated Successfully !!!');
          }
          this.matDialogRef.close();
          this.submitted = false;
          this.addProductSettings.reset();
        } else {
          this.alertService.webErrorShow(res);
        }
        tempSub.unsubscribe();
      }, error => {
        this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        console.error('There was an error!', error);
        tempSub.unsubscribe();
      })
    } else {
      let tempSub = this.commonService.storeItem('setting/add', data, true, "optionOne").subscribe((res: any) => {
        if (res.success) {
          if (res.data && res.data.message) {
            this.alertService.webShow('Info', res.data.message);
          } else {
            this.alertService.webShow('Info', 'Updated Successfully !!!');
          }
          this.matDialogRef.close();
          this.submitted = false;
          this.addProductSettings.reset();
        } else {
          this.alertService.webErrorShow(res);
        }
        tempSub.unsubscribe();
      }, error => {
        this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        console.error('There was an error!', error);
        tempSub.unsubscribe();
      })
    }
  }
}
