import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { Clipboard } from '@angular/cdk/clipboard';

import {
	User, UserService, CommonService,
	FbTemplateMessageService, FbTemplateMessage
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'fb-api-template-template-view',
	templateUrl: './api-template-view.component.html',
	styleUrls: ['./api-template-view.component.scss']
})

export class FbApiTemplateViewComponent implements OnInit, OnDestroy {
	@ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
	@Output() onClose = new EventEmitter();

	loader: boolean = true;
	authUser: User;
	url: string = this.fbTemplateMessageService.url;
	template: FbTemplateMessage;
	templateUpdate: any;
	cardLengthData: any=[];
	base_image: string = this.commonService.logo_image;

	constructor(public userService: UserService,
		public fbTemplateMessageService: FbTemplateMessageService,
		public commonService: CommonService,
		private clipboard: Clipboard,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.fbTemplateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.template = data;
		});
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.fbTemplateMessageService.unSubscribe();
		this.fbTemplateMessageService.changeItem(null)
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getUrl() {
		return environment.domainSecuredOne + '/template/sent';
	}

	getHeaders() {
		return { "Content-Type": "application/json" };
	}

	// getToken(template: FbTemplateMessage): string {
	// 	// let tjiToken = localStorage.getItem('tji_token');
	// 	// if(tjiToken) {
	// 	// 	tjiToken = "Bearer " + tjiToken.replace('"', '').replace('"', '');
	// 	// }
	// 	// return tjiToken;
	// 	return template.token;
	// }
	tokenRegGenerate(){
		var params = {
			"template_id":this.template.id,
   			"action":"regenerate"
		}
		this.fbTemplateMessageService.reGenerateToken('template/getToken',params)
	}

	tokenGenerate(){
		var params = {
			"template_id":this.template.id,
   			"action":"generate"
		}
		this.fbTemplateMessageService.reGenerateToken('template/getToken',params)
	}

	tokenDelete(){
		var params = {
			"template_id":this.template.id,
   			"action":"delete"
		}
		this.fbTemplateMessageService.reGenerateToken('template/getToken',params)
	}
	public copy(data : any) {
        // replace this object with your data
        const object = {abc:'abc',xy:{x:'1',y:'2'}}

        // Note the parameters
        this.clipboard.copy(JSON.stringify(data, null, 2));
    }
}
