import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';
import { PosterComponent } from './poster/poster.component';
import { NewPosterComponent } from './newPoster/newPoster.component';
import { RecentPostComponent } from './recentPost/recentPost.component';
import { ScheduleViewComponent } from './scheduleView/scheduleView.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ReportComponent } from './report/report.component';

import { PosterService, PosterScheduleService, ClientSiteService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
        component: MainComponent,
        resolve: { data: PosterScheduleService },
	},
	{
		path: 'poster',
		component: PosterComponent,
		resolve: { data: PosterScheduleService },
	},
	{
		path: 'new-poster',
        component: NewPosterComponent,
        resolve: { clientSite: ClientSiteService },
	},
	{
		path: 'edit-poster/:id',
		component: NewPosterComponent,
		resolve: { data: PosterService },
	},
	{
		path: 'recent-post',
		component: RecentPostComponent,
	},
	{
		path: 'schedule-view',
		component: ScheduleViewComponent,
	},
	{
		path: 'gallery',
		component: GalleryComponent,
	},
	{
		path: 'report',
		component: ReportComponent,
	},
	{ path: '**', redirectTo: '/app/smm/poster' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})
export class SocialMediaManagementRouteModule { }
