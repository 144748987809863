import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { TemplateUsageReports } from './templateUsageReports.interface';

import { TemplateUsageReportsModel } from './templateUsageReports_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class TemplateUsageReportsService implements Resolve<any> {
	url: string = 'template/usage';
	routeParams: any;
	defaultParams: any = {
		'limit': 100,
		'current': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private allItemsSource = new BehaviorSubject<TemplateUsageReports[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<TemplateUsageReports>(new TemplateUsageReportsModel({}));
	item = this.itemSource.asObservable();

	private itemTemplateSource = new BehaviorSubject<TemplateUsageReports>(new TemplateUsageReportsModel({}));
	itemTemplate = this.itemTemplateSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private totalCountSource = new BehaviorSubject<number>(0);
	totalCount = this.totalCountSource.asObservable();

	private successCountSource = new BehaviorSubject<number>(0);
	successCount = this.successCountSource.asObservable();

	private failedCountSource = new BehaviorSubject<number>(0);
	failedCount = this.failedCountSource.asObservable();

	private displayItemsSource = new BehaviorSubject<TemplateUsageReports[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private completedCountsSource = new BehaviorSubject<number>(0);
	completedCounts = this.completedCountsSource.asObservable();

	public isLoader = new BehaviorSubject<boolean>(false);
	loader = this.isLoader.asObservable();

	public isDownloadLoader = new BehaviorSubject<boolean>(false);
	downloadLoader = this.isDownloadLoader.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				// this.getAllItems(),
				this.getAllListItems(),
				// this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed TemplateUsageReportService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on TemplateUsageReportService');
	}

	changeAllItems(allItems: TemplateUsageReports[]) {
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: any) {
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeItemTemplate(item: any) {
		this.itemTemplateSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeTotalCount(totalCount: number) {
		this.totalCountSource.next(totalCount);
	}

	changeSuccessCount(successCount: number) {
		this.successCountSource.next(successCount);
	}

	changeFailedCount(failedCount: number) {
		this.failedCountSource.next(failedCount);
	}

	changeCompletedCounts(total: number) {
		this.completedCountsSource.next(total);
	}

	changeDisplayItems(displayItems: TemplateUsageReports[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: TemplateUsageReports) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 100,
			'current': 1,
			'search': '',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		if (this.routeParams && this.routeParams.templateId) {
			params.template_id = this.routeParams.templateId;
		}
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (params.all && params.all === 1) {
					this.changeTotalCount(data.totalCount);
					this.changeSuccessCount(data.successCount);
					this.changeFailedCount(data.failedCount);
					this.changeAllItems(data.data);
					this.changeTotalItem(data.total);
				} else {
					this.changeTotalCount(data.data.totalCount);
					this.changeSuccessCount(data.data.successCount);
					this.changeFailedCount(data.data.failedCount);
					this.changeAllItems(data.data);
					this.changeTotalItem(data.total);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllListItems(params: any = null) {
		this.isLoader.next(true);
		params = this.paramsInit(params);
		// if (this.routeParams && this.routeParams.templateId) {
		// 	params.template_id = this.routeParams.templateId;
		// }
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.client_info
						var usersInfo = data.additional.users_info
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"id": usage.id,
								"jobcode": usage.jobcode,
								"template_id": usage.template_id,
								"client_id": usage.client_id,
								"client_site_id": usage.client_site_id,
								"is_sendlater": usage.is_sendlater,
								"total_count": usage.total_count,
								"success_count": usage.success_count,
								"rejected_count": usage.rejected_count,
								"failed_count": usage.failed_count,
								"is_completed": usage.is_completed,
								"total_retries": usage.total_retries,
								"created_by": usage.created_by,
								"updated_by": usage.updated_by,
								"deleted_at": usage.deleted_at,
								"progress_status": usage.progress_status,
								"stage": usage.stage,
								"clientsiteName": usage.clientsiteName,
								"clientName": clientInfo.name,
								"clientAvatar": clientInfo.details.image_api,
								"created_at": usage.created_at, //moment(usage.created_at + '.000+0300').local(),
								"updated_at": usage.updated_at, //moment(usage.updated_at + '.000+0300').local(),
							}
							if (usage.template_info) {
								dataFormats["templateCode"] = usage.template_info.code;
								dataFormats["templateName"] = usage.template_info.name;
							}
							if (usage.json) {
								var jsonNew = [];
								var jsonData = JSON.parse(usage.json);
								if (jsonData && !jsonData[0] && !jsonData[0]) {
									jsonNew.push(jsonData)
									dataFormats["json"] = jsonNew
								} else {
									dataFormats["json"] = jsonData
								}
							} else {
								dataFormats["json"] = null;
							}
							if (usersInfo) {
								usersInfo.forEach(user => {
									if (usage.created_by == user.id) {
										dataFormats["senderName"] = user.username
										dataFormats["senderEmail"] = user.email
									} else if (usage.created_by == 1) {
										dataFormats["senderName"] = "API"
									}
								});
							}
							dataFormat.push(dataFormats);
						});
						this.isLoader.next(false);
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.isLoader.next(false);
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				} else {
					this.isLoader.next(false);
					this.changeAllItems([]);
					this.changeTotalItem(data.pagnitation.totalResult);
				}
			},
				error => { console.log('Error ::' + error); this.isLoader.next(false); }
			);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.templateUsageId > 0) {
			var paramData = {
				template_usage_id: this.routeParams.templateUsageId,
			}
			this.commonService.storeItem(this.url, paramData, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					var usage = data.data;
					var usersInfo = data.additional.users_info
					var clientInfo = data.additional.client_info
					if (data.success) {
						var dataFormats = {
							"id": usage.id,
							"jobcode": usage.jobcode,
							"template_id": usage.template_id,
							"client_id": usage.client_id,
							"total_count": usage.total_count,
							"success_count": usage.success_count,
							"rejected_count": usage.rejected_count,
							"failed_count": usage.failed_count,
							"is_completed": usage.is_completed,
							"created_by": usage.created_by,
							"updated_by": usage.updated_by,
							"deleted_at": usage.deleted_at,
							"progress_status": usage.progress_status,
							"stage": usage.stage,
							"clientName": clientInfo.name,
							"clientsiteName": usage.clientsiteName,
							"clientAvatar": clientInfo.details.image_api,
							"created_at": usage.created_at, //moment(usage.created_at + '.000+0300').local(),
							"updated_at": usage.updated_at, //moment(usage.updated_at + '.000+0300').local(),
							"template": usage.template_info?usage.template_info:'',
							json: JSON.parse(usage.json),
						}
						if (usage.template_info) {
							dataFormats["templateCode"] = usage.template_info.code;
							dataFormats["templateName"] = usage.template_info.name;
						}
						if (usersInfo) {
							usersInfo.forEach(user => {
								if (usage.created_by == user.id) {
									dataFormats["senderName"] = user.username
									dataFormats["senderEmail"] = user.email
								} else if (usage.created_by == 1) {
									dataFormats["senderName"] = "API"
								}
							});
						}
						this.changeItem(dataFormats);
					} else {
						this.changeItem(null);
					}

				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new TemplateUsageReportsModel({}));
		}
	}

	getItemData(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.templateUsageId > 0) {
			var paramData = {
				id: this.routeParams.templateUsageId,
			}
			this.commonService.storeItem("template/viewUsage", paramData, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					var usage = data.data;
					if (data.success) {
						var dataFormats = {}
						if (usage.json) {
								var jsonNew = [];
								var jsonData = JSON.parse(usage.json);
								if (jsonData && !jsonData[0] && !jsonData[0]) {
									jsonNew.push(jsonData)
									dataFormats["json"] = jsonNew
								} else {
									dataFormats["json"] = jsonData
								}
							} else {
								dataFormats["json"] = null;
							}
						this.changeItemTemplate(dataFormats);
					} else {
						this.changeItemTemplate(null);
					}

				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new TemplateUsageReportsModel({}));
		}
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
			});
	}

	/** Search Event */
	onSearch(input: string, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllListItems();
			});
	}

	pageEvent(event, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllListItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event, isAll = false) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllListItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(type: string, url: string = null) {
		let domain = this.commonService.backendUrl;
		let ch_url = '';

		if (type == 'excel') {
			ch_url = (url) ? domain + url : domain + 'template/people-export/';
		} else if (type == 'pdf') {
			ch_url = (url) ? domain + url : domain + 'person/people-exportpdf/';
		}

		return ch_url;
	}

	exportReport(params: any = null) {
		this.isDownloadLoader.next(true);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data && data.data.length > 0) {
						var clientInfo = data.additional.client_info
						var usersInfo = data.additional.users_info
						var usage = data.data;
						var dataFormat = [];
						usage.forEach(usage => {
							var dataFormats = {
								"Template Code": usage?.template_info?.code,
								"Job Code": usage.jobcode,
								"Account Name": usage.clientsiteName,
								"Total Count": usage.total_count,
								"Sent": usage.success_count,
								"Failed": usage.failed_count,
								"Rejected": usage.rejected_count,
								"Completed": usage.is_completed ? 'YES' : 'NO',
								"Date": moment(usage.created_at).format('MM-DD-YYYY HH:mm:ss')
							}
							if (usersInfo) {
								usersInfo.forEach(user => {
									if (usage.created_by == user.id) {
										dataFormats["Sent By"] = user.username
										dataFormats["Sender Email ID"] = user.email
									} else if (usage.created_by == 1) {
										dataFormats["Sent By"] = "API"
										dataFormats["Sender Email ID"] = "API"
									}
								});
							}
							dataFormat.push(dataFormats);
						});

						let arrHeader = ["Date", "Template Code","Job Code", "Account Name", "Sent By", "Sender Email ID", "Total Count", "Sent", "Failed", "Rejected", "Completed"];
						let csvData = this.ConvertToCSV(dataFormat, arrHeader);
						let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
						// this.downLoadLoader = false;
						let dwldLink = document.createElement("a");
						let url = URL.createObjectURL(blob);
						let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
						if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
							dwldLink.setAttribute("target", "_blank");
						}
						dwldLink.setAttribute("href", url);
						var currentTime = new Date().toJSON();
						dwldLink.setAttribute("download", 'Template_usage_reports_' + currentTime + '.csv');
						dwldLink.style.visibility = "hidden";
						document.body.appendChild(dwldLink);
						dwldLink.click();
						document.body.removeChild(dwldLink);


						this.isDownloadLoader.next(false);
					} else {
						this.isDownloadLoader.next(false);
						this.alert('Danger', 'Data is not available for selected date !!!');
					}
				} else {
					this.isDownloadLoader.next(false);
					this.alert('Danger', 'Data is not available for selected date !!!');
				}
			},
				error => { console.log('Error ::' + error); this.isDownloadLoader.next(false); }
			);
	}


	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Date", "Template Code","Job Code", "Account Name", "Sent By", "Sender Email ID", "Total Count", "Sent", "Failed", "Rejected", "Completed"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			if (data == '\"') {
				data = ','
			}
			let newData = data.replace(/,/g, " ");
			// newData.replace(|\|, "colour");
			return newData.toString();
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}

}
