export class MessengerModel {
	id: number;
	messenger_id: number;
	reply_id: number;
	client_id: number;
	client_site_id: number;
	message_mid: string;
	text: string;
	from: string;
	to: Date;
	user_image: string;
	media: string;
	json: string;
    asset_url: string;
	status: string;
	is_active: boolean;
	is_assigned: boolean;
	is_read: boolean;
	is_closed: boolean;
	is_itrs: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;

	ticketId?: number;
	peopleName?: string;
	peopleAvatar?: string;
	isSent?: boolean;
	pusherSync?: string;
	agentName?: string;
	createdByName?: string;
    createdByAvatar?: string;
	location_url?: string;
	clientSiteName?: string;
	clientSiteColor?: string;
	clientSiteTextColor?: string;
	reply?: any;

	tableName: string;
	className: string;
	people_id?:number;
	ChatData?:any;
	clientSite?:any;
	ReplyData?:any;
	replayPersonType: string;

	/**
	 * Constructor
	 *
	 * @param messenger
	 */
	constructor(messenger) {
		this.id = messenger.id || '';
		this.messenger_id = messenger.messenger_id || '';
		this.reply_id = messenger.reply_id || '';
		this.client_id = messenger.client_id || '';
		this.client_site_id = messenger.client_site_id || '';
		this.message_mid = messenger.message_mid || '';
		this.text = messenger.text || '';
		this.from = messenger.from || '';
		this.to = messenger.to || '';
		this.user_image = messenger.user_image || '';
		this.media = messenger.media || '';
		this.asset_url = messenger.asset_url || '';
		this.json = messenger.json || '';
		this.status = messenger.status || '';
		this.is_active = messenger.is_active || '';
		this.is_assigned = messenger.is_assigned || '';
		this.is_read = messenger.is_read || '';
		this.is_closed = messenger.is_closed || '';
		this.is_itrs = messenger.is_itrs || '';
		this.created_by = messenger.created_by || '';
		this.updated_by = messenger.updated_by || '';
		this.created_at = messenger.created_at || '';
		this.updated_at = messenger.updated_at || '';

		this.tableName = messenger.tableName || '';
		this.people_id = messenger.tableName || '';
		this.ChatData = messenger.ChatData || '';
		this.clientSite = messenger.clientSite || '';
		this.ReplyData = messenger.ReplyData || '';
		this.replayPersonType = messenger.replayPersonType || '';
	}
}
