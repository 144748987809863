import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService,
	ConsumerGroupService, ConsumerGroup, ConsumerGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'consumer-group-link-form',
	templateUrl: './formLinkGroup.component.html',
	styleUrls: ['./formLinkGroup.component.scss']
})

export class FormLinkGroupComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	parentGroups: ConsumerGroup[] = [];
	consumerGroup: ConsumerGroup;
	action: string;
	consumerGroupLinkForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public consumerGroupService: ConsumerGroupService,
		public matDialogRef: MatDialogRef<FormLinkGroupComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.consumerGroupService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.parentGroups = data.filter((x) => { return (x.parent_id === null || x.parent_id === '') ? true : false; });
		});
		this.consumerGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.consumerGroup = data;
			this.consumerGroupLinkForm = this.createForm();
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Consumer Group';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Consumer Group';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			parent_id: [this.consumerGroup.parent_id || null, [Validators.required]],
		});
	}

}
