import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-funfacts-area',
  templateUrl: './home-funfacts-area.component.html',
  styleUrls: ['./home-funfacts-area.component.scss']
})
export class HomeFunfactsAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
