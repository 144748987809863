import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    isWebNotify = false;
    isDesktopNotify = false;

    private isDesktopNotifySoundSource = new BehaviorSubject<boolean>(false);
    desktopSound = this.isDesktopNotifySoundSource.asObservable();

    public isWebNotifySoundSource = new BehaviorSubject<boolean>(false);
    webSound = this.isWebNotifySoundSource.asObservable();

    constructor(private snackBar: MatSnackBar,
        private toastr: ToastrService) { }

    changeDesktopNotify(status: boolean) {
        this.isDesktopNotifySoundSource.next(status);
        this.isDesktopNotify = status;
    }

    changeWebNotify(status: boolean) {
        this.isWebNotifySoundSource.next(status);
        this.isWebNotify = status;
    }

    webShow(type: string, message) {
        if (this.isWebNotify && this.isWebNotify === true) {
            let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
            this.snackBar.openFromComponent(AlertComponent, {
                panelClass: ['alert' + capitalType],
                data: {
                    message: message,
                    type: type.toLowerCase(),
                }
            });
        }
    }

    tosterShow(message, title) {
        if (this.isWebNotify && this.isWebNotify === true) {
            this.toastr.show(message, title);
        }
    }

    tosterPrepremiumDashboardShow(message, title) {
        this.toastr.show(message, title);
    }

    websocketShow(type: string, data) {
        if (this.isWebNotify && this.isWebNotify === true) {
            let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
            this.snackBar.openFromComponent(AlertComponent, {
                panelClass: ['alert' + capitalType],
                data: {
                    type: type.toLowerCase(),
                    title: data.title,
                    message: data.message,
                    image: data.image,
                    icon: data.icon
                }
            });
        }
    }


    socketShow(type: string, message) {
        if (this.isWebNotify && this.isWebNotify === true) {
            let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
            this.snackBar.openFromComponent(AlertComponent, {
                panelClass: ['alert' + capitalType],
                data: {
                    message: message,
                    type: type.toLowerCase(),
                }
            });
        }
    }

    pusherShow(type: string, message) {
        if (this.isWebNotify && this.isWebNotify === true) {
            let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
            this.snackBar.openFromComponent(AlertComponent, {
                panelClass: ['alert' + capitalType],
                data: {
                    message: message,
                    type: type.toLowerCase(),
                }
            });
        }
    }

    webErrorShow(res) {

        if (res?.errors?.catchErr?.error?.message && res?.errors?.catchErr?.error?.message.length > 0) {
            var type = 'Danger';
            var message = res.errors.catchErr.error.message;
            if (this.isWebNotify && this.isWebNotify === true) {
                let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                this.snackBar.openFromComponent(AlertComponent, {
                    panelClass: ['alert' + capitalType],
                    data: {
                        message: message,
                        type: type.toLowerCase(),
                    }
                });
            }
        } else if (res?.errors?.catchErr?.message && res?.errors?.catchErr?.message.length > 0) {
            // this.alertService.webShow('Danger', res.errors.message);
            var type = 'Danger';
            var message = res.errors.catchErr.message;
            if (this.isWebNotify && this.isWebNotify === true) {
                let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                this.snackBar.openFromComponent(AlertComponent, {
                    panelClass: ['alert' + capitalType],
                    data: {
                        message: message,
                        type: type.toLowerCase(),
                    }
                });
            }
        }

        else if (res.errors && res.errors.error && res.errors.error.message && !Array.isArray(res.errors.error)) {
            var type = 'Danger';
            if (this.isWebNotify && this.isWebNotify === true) {
                let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                this.snackBar.openFromComponent(AlertComponent, {
                    panelClass: ['alert' + capitalType],
                    data: {
                        message: res.errors.error.message,
                        type: type.toLowerCase(),
                    }
                });
            }
        }

        else if (res.errors && res.errors.length > 0) {
            res.errors.forEach(error => {
                var type = 'Danger';
                if (this.isWebNotify && this.isWebNotify === true) {
                    let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                    this.snackBar.openFromComponent(AlertComponent, {
                        panelClass: ['alert' + capitalType],
                        data: {
                            message: error.message,
                            type: type.toLowerCase(),
                        }
                    });
                }
            });
        } else if (res.errors && res.errors.error && res.errors.error.length > 0) {
            res.errors.error.forEach(error => {
                var type = 'Danger';
                if (this.isWebNotify && this.isWebNotify === true) {
                    let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                    this.snackBar.openFromComponent(AlertComponent, {
                        panelClass: ['alert' + capitalType],
                        data: {
                            message: error.message,
                            type: type.toLowerCase(),
                        }
                    });
                }
            });
        } else if (res.errors && res.errors.message && res.errors.message.length > 0 && Array.isArray(res.errors.message)) {
            res.errors.message.forEach(error => {
                var type = 'Danger';
                if (this.isWebNotify && this.isWebNotify === true) {
                    let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                    this.snackBar.openFromComponent(AlertComponent, {
                        panelClass: ['alert' + capitalType],
                        data: {
                            message: error.message,
                            type: type.toLowerCase(),
                        }
                    });
                }
            });
        } else if (res.errors && res.errors.message) {
            // this.alertService.webShow('Danger', res.errors.message);
            var type = 'Danger';
            var message = res.errors.message;
            if (this.isWebNotify && this.isWebNotify === true) {
                let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                this.snackBar.openFromComponent(AlertComponent, {
                    panelClass: ['alert' + capitalType],
                    data: {
                        message: message,
                        type: type.toLowerCase(),
                    }
                });
            }
        } else if (res.errors && res.errors.length > 0) {
            res.errors.forEach(error => {
                var type = 'Danger';
                if (this.isWebNotify && this.isWebNotify === true) {
                    let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                    this.snackBar.openFromComponent(AlertComponent, {
                        panelClass: ['alert' + capitalType],
                        data: {
                            message: error.message,
                            type: type.toLowerCase(),
                        }
                    });
                }
            });
        } else if (res.message && res.message.length > 0) {
            var type = 'Danger';
            if (this.isWebNotify && this.isWebNotify === true) {
                let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
                this.snackBar.openFromComponent(AlertComponent, {
                    panelClass: ['alert' + capitalType],
                    data: {
                        message: res.message,
                        type: type.toLowerCase(),
                    }
                });
            }
        } else {
            this.socketShow('Danger', 'Something Wrong. Try after Sometimes !!!')
        }
    }

    tosterShowBeforeLogin(type, message) {
        let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
        this.snackBar.openFromComponent(AlertComponent, {
            panelClass: ['alert' + capitalType],
            data: {
                message: message,
                type: type.toLowerCase(),
            }
        });
    }
}
