import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive, IntractiveModel,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-intractive-category',
	templateUrl: './intractiveCategory.component.html',
	styleUrls: ['./intractiveCategory.component.scss']
})

export class IntractiveCategoryComponent implements OnInit, OnDestroy {
	intractive: Intractive;
	authUser: User;
	itrsbot: Itrsbot;
	loader: boolean = true;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => this.intractive = data);
	}

	ngOnInit() {
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void { 
		this.itrsbotService.unSubscribe();
		this.itrsbotService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 2000);
	}

	/** Add / Update Tag */
	newIntractiveGroup(intractiveGroup = null): void {
		this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(SimpleFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
				isChoiceMsg: this.isItrsCombined(this.intractive),
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
			});
	}

	/** Add / Update Intractive */
	newIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(IntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
				switch (actionType) {
					case 'new':
						formData.value.resource_id = parseInt(formData.value.resource_id);
						this.intractiveService.intractiveStore('Intractive/add', formData.value);
						break;
					case 'update':
						formData.value.resource_id = parseInt(formData.value.resource_id);
						this.intractiveService.removelibraryById(id);
						this.intractiveService.removelibraryById(id);
						formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
				}
			});
	}


	/** Delete Intractive */
	deleteIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete this template?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData ={
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete',formData);
				this.intractiveService.removelibraryById(intractive.id);
			}
		});
	}

	/** Add / Change IntractiveGroup Images */
	imageIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChangeIntractiveGroupImageComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
			});
	}

	isItrsSingle(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'welcome-message' || slug === 'message' || slug === 'thankyou' || slug === 'live-message' || slug === 'ai-collection')) ? true : false;
	}

	isItrsCombined(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'ticket-survey' || slug === 'live-seperate-message' || slug === 'parent-catelog')) ? true : false;
	}

	isItrsSurvey(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'feedback' || slug === 'survey' || slug === 'one-time-survey')) ? true : false;
	}

	isItrsApi(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'get-api' || slug === 'post-api')) ? true : false;
	}

	isItrsContinuousIntegraion(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'continuous-integraion')) ? true : false;
	}

	isTemplateMessage(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'template-message' || slug === 'customer-input')) ? true : false;
	}
	
	isProduct(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'products' )) ? true : false;
	}

	checkBegin(intractive: Intractive): boolean {
		return (intractive && intractive.intractive_category &&
			intractive.intractive_category && intractive.intractive_category.slug === 'ticket-survey' &&
			intractive.is_begin) ? true : false;
	}
}
