import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import { WabaService } from 'src/app/@tji/_dbShare/waba/waba.service';
import { DatePipe } from '@angular/common'
import { Payment } from './payments.interface';
import { PaymentModel } from './payments-model.model';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');
@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  url: string = 'eorders/paymentsList';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'search': '',
  };

  private librariesSource = new BehaviorSubject<Array<any>>([]);
  libraries = this.librariesSource.asObservable();

  private allItemsSource = new BehaviorSubject<Payment[]>([]);
  allItems = this.allItemsSource.asObservable();

  public itemSource = new BehaviorSubject<Payment>(new PaymentModel({}));
  item = this.itemSource.asObservable();

  public itemIdSource = new BehaviorSubject<Payment>(new PaymentModel({}));
  itemId = this.itemIdSource.asObservable();

  private displayItemsSource = new BehaviorSubject<Payment[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  public isAddItems = new BehaviorSubject<boolean>(false);
  addItems = this.isAddItems.asObservable();

  public isLoader = new BehaviorSubject<boolean>(true);
  loader = this.isLoader.asObservable();

  public isfilterLoader = new BehaviorSubject<boolean>(false);
  filterLoader = this.isfilterLoader.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    public datepipe: DatePipe,
    private snackBar: MatSnackBar,
    private wabaService: WabaService) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.defaultParams.search = '',
        this.resetParams(),
        this.getAllItems(),
        this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed PaymentService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on PaymentService');
  }

  concatlibrary(allItems: Payment[]) {
    var oldLists: Array<any> = [];
    this.libraries.subscribe(data => {
      oldLists = data;
    });
    if (oldLists && oldLists.length > 0) {
      oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
    } else {
      oldLists = allItems;
    }
    this.changeLibraries(oldLists);
  }

  removelibrary(item: Payment) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      oldLists = oldLists.filter(x => {
        return x._id !== item._id;
      });
    }
    this.changeLibraries(oldLists);
  }

  removelibraryById(id: number) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && id) {
      oldLists = oldLists.filter(x => {
        return x.id !== id;
      });
    }
    this.changeLibraries(oldLists);
  }

  replacelibrary(item: Payment) {
    let oldLists = [];
    let isReplaced: boolean = false;
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      for (var i = 0; i < oldLists.length; ++i) {
        if (oldLists[i]._id === item._id) {
          oldLists.splice(i, 1, item);
          isReplaced = true;
          break;
        }
      }
    }
    if (!isReplaced) { oldLists.concat([item]); }
    this.changeLibraries(oldLists);
  }

  changeLibraries(libraries: Payment[]) {
    this.librariesSource.next(libraries);
  }

  changeAllItems(allItems: Payment[]) {
    this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }

  changeItem(item: Payment) {
    this.itemSource.next(item);
  }

  changeItemId(item: Payment) {
    this.itemIdSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: Payment[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: Payment) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
      }
    }
    this.changeAllItems(allItems);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'search': '',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.isLoader.next(false);
          this.isfilterLoader.next(false);
          this.changeAllItems(data.data);
          this.changeTotalItem(data.pagnitation.totalResult);
        } else {
          this.isLoader.next(false);
          this.isfilterLoader.next(false);
        }
      },
        error => { console.log('Error ::' + error); this.isfilterLoader.next(false); this.isLoader.next(false); }
      );
  }

  getFilterAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.isfilterLoader.next(false);
          this.changeAllItems(data.data);
          this.changeTotalItem(data.pagnitation.totalResult);
        } else {
          this.isfilterLoader.next(false);
        }
      },
        error => { console.log('Error ::' + error); this.isfilterLoader.next(false); }
      );
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        if (data.pagnitation && data.pagnitation.last && data.pagnitation.last <= data.pagnitation.current) {
					params.page = data.pagnitation.last;
					this.changeParams(params);
				}
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data);
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: Payment) {
    let lists = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result.concat([item]);
    });
    this.changeAllItems(lists);
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i].id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  getItem(params: any = null) {
    this.routeParams = (params) ? params : this.routeParams;
    var itemId;
    this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
    var id = {
      "id": itemId
    }
    if (itemId > 0) {

      this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          this.changeAllItemsByItem(data.data);
          this.changeItem(data.data);
        },
          error => console.log('Error ::' + error)
        );
    }
    else {
      this.changeItem(new PaymentModel({}));
    }
  }

  store(url, data: any) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.concatItem(res.data);
          this.changeItem(res.data);
          this.alert('Success', 'Created Successfully !!!');
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  sync(url, data: any = null) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.alert('Success', 'Payment Sync Successfully !!!');
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  update(url, data: any, Payment = null): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          Payment.name = data.name;
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(url, id) {
    this.commonService.storeItem(url, id, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.spliceItem(id.Payment_id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }


  bussinessActivity(url, data: any, catalog = null): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          // catalog['clientSiteInfo']['mongo']['waba_id'] = data.waba_id;
          // catalog['waba_id'] = data.waba_id;
          // this.concatItem(catalog);
          // this.changeItem(catalog);
          // this.changeAllItems(catalog);
          this.getAllItems();
          this.wabaService.getAllItems();
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }
  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  /** Search Event */
  onSearch(input: string) {
    let newParams: any;
    this.params
      .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.page = 1;
        newParams.search = input;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }


  export(Dates: any = null) {

    this.commonService.storeItem("module/aicollection/paymentReport", Dates, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          if (data.data == 0) {
            this.isfilterLoader.next(false);
            this.alert('Danger', 'Datas are not available for selected dates !!!');
          }
          if (data.data && data.data.length > 0) {
            var excelprint = data.data;
            let excelData = [];
            if (excelprint && excelprint.length > 0) {
              data.data.forEach(elementKey => {
                var dataIn = {
                  "Date and Time": this.datepipe.transform(elementKey.created_at * 1000, 'yyyy-MM-dd hh:mm'), // moment(moment(elementKey.updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), 
                  "Name": elementKey?.direct_name,
                  "Payment ID": elementKey?.PaymentId,
                  "Amount":elementKey?.currency +' '+ elementKey?.amount,
                  "Payment Link": elementKey?.payment_link,
                  "Payment Status": elementKey?.payment_status,
                  "Contact Number": elementKey?.direct_phone,
                  "Contact Email": elementKey?.direct_email,
                  "Tags": elementKey?.tags
                }
                excelData.push(dataIn);
              });
              this.downloadFile(excelData);
            }else{
              this.isfilterLoader.next(false);
              this.alert('Danger', 'Datas are not available for selected dates !!!');
            }

          }
        }else{
          this.isfilterLoader.next(false);
          this.alert('Danger', 'Datas are not available for selected dates !!!');
        }

      });

    error => {
      this.isfilterLoader.next(false);
      console.log('Error ::' + error);
    }

  }

  // download files

  downloadFile(excelData) {

    let arrHeader = ["Date and Time","Name","Payment ID","Amount","Payment Link","Payment Status","Contact Number","Contact Email","Tags"];
    let csvData = this.ConvertToCSV(excelData, arrHeader);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    this.isfilterLoader.next(false);
    dwldLink.setAttribute("href", url);
    var currentTime = new Date().toJSON();
    dwldLink.setAttribute("download", 'payment-' + currentTime + '.csv');
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    let newHeaders = ["Date and Time","Name","Payment ID","Amount","Payment Link","Payment Status","Contact Number","Contact Email","Tags"];

    for (let index in newHeaders) {
      row += newHeaders[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + this.strRep(array[i][head]);
      }
      str += line + '\r\n';
    }
    return str;
  }

  strRep(data) {
    if (typeof data == "string") {
      let newData = data.replace(/,/g, " ");
      return newData;
    }
    else if (typeof data == "undefined") {
      return "-";
    }
    else if (typeof data == "number") {
      return data.toString();
    }
    else {
      return data;
    }
  }



}
