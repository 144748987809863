import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringBetweenPipe } from 'src/app/pipes/string/stringBetween.pipe';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { FbTemplateViewComponent } from './template-view/template-view.component'
import { FbTemplateMessageFormComponent } from './templateMessage-form/templateMessage-form.component';
import { FbTemplateFormComponent } from './templateForm/templateForm.component';


import { ClientSiteService, FbTemplateMessageService } from 'src/app/@tji/_dbShare';
import { FbApiTemplateViewComponent } from './api-template-view/api-template-view.component';
import { FbBulkImportComponent } from './bulkImport/bulkImport.component';
import { FbItrsbotComponent } from './fbItrsbot/fbitrsbot.component';
import { TicketTemplateComponent } from './ticket-template/ticket-template.component';
import { TemplateSendLaterListComponent } from './template-send-later-list/template-send-later-list.component';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { TicketGreetingTemplateComponent } from './ticket-greeting-template/ticket-greeting-template.component';
import { AutoTemplateListComponent } from './auto-template-list/auto-template-list.component';
import { AddAutoTemplateComponent } from './add-auto-template/add-auto-template.component';

@NgModule({
    declarations: [
        MainComponent,
		ListComponent,
        FbTemplateViewComponent,
        FbTemplateMessageFormComponent,
        FbTemplateFormComponent,
        FbApiTemplateViewComponent,
        FbBulkImportComponent,
        FbItrsbotComponent,
        TicketTemplateComponent,
        TemplateSendLaterListComponent,
        RescheduleComponent,
        TicketGreetingTemplateComponent,
        AutoTemplateListComponent,
        AddAutoTemplateComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
		ListComponent,
        FbTemplateViewComponent,
        FbApiTemplateViewComponent,
        FbBulkImportComponent,
        FbItrsbotComponent,
        TicketTemplateComponent,
        TemplateSendLaterListComponent,
        RescheduleComponent,
        TicketGreetingTemplateComponent,
        AutoTemplateListComponent,
        AddAutoTemplateComponent
    ],
    providers: [
        ClientSiteService,
        StringBetweenPipe,
        FbTemplateMessageService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})

export class FbTemplateImportModule { }
