// import {
//     HttpEvent,
//     HttpHandler,
//     HttpRequest,
//     HttpErrorResponse,
//     HttpInterceptor
// } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError, retry } from 'rxjs/operators';

// export class ErrorIntercept implements HttpInterceptor {
//     intercept(
//         request: HttpRequest<any>,
//         next: HttpHandler
//     ): Observable<HttpEvent<any>> {
//         return next.handle(request)
//             .pipe(
//                 retry(1),
//                 catchError((error: HttpErrorResponse) => {
//                     let errorMessage = '';
//                     if (error.error instanceof ErrorEvent) {
//                         // client-side error
//                         errorMessage = `Error: ${error.error.message}`;
//                     } else {
//                         // server-side error
//                         errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
//                     }
//                     return throwError(errorMessage);
//                 })
//             )
//     }
// }

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, delay, retryWhen, concatMap  } from 'rxjs/operators';

import { UserService } from 'src/app/@tji/_dbShare';

export const retryCount = 2;
export const retryWaitMilliSeconds = 100;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) { }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     return next.handle(request).pipe(
    //     retryWhen(error => 
    //     error.pipe(
    //       concatMap((error, count) => {
    //         if (count <= retryCount || error.status == 0 || error.status == 503 || error.status == 'CORS error' || error.status == 500 || error.status == 502 || error.status == 507) {
    //           return of(error);
    //         }
    //         return throwError(error);
    //       }),
    //       delay(retryWaitMilliSeconds)
    //     )
    //   ),
    //     catchError(err => {
    //         if ([401, 403].includes(err.status) && this.userService.authUser()) {
    //             // auto logout if 401 or 403 response returned from api
    //             this.userService.clearAllMemory();
    //         }

    //         const error = (err && err.error && err.error.error) || (err && err.error && err.error.message) || null;
    //         return throwError(error);
    //     }
    //     ));
    // }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.userService.authUser()) {
                // auto logout if 401 or 403 response returned from api
                this.userService.clearAllMemory();
            }

            const error = (err && err.error && err.error.error) || (err && err.error && err.error.message) || (err && err.error && err.error.errors && err.error.errors.message) || null;
            return throwError(error);
        }));
    }

}