import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { DomSanitizer } from '@angular/platform-browser';

import {
	User, UserService,
	InstagramService, Instagram,
	InstagramTicketService, InstagramTicket,
	CommonService,
	FeedService, Feed
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'instagram-comment-export',
	templateUrl: './commentExport.component.html',
	styleUrls: ['./commentExport.component.scss']
})

export class CommentExportComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(ScrollToBottomDirective) scrollMeInstagram: ScrollToBottomDirective;

	loader: boolean = true;
	dialogTitle: string = 'Instagram Media Comments';
	authUser: User;

	instagrams: Instagram[] = [];
	instagramTicket: InstagramTicket;
	instagramParam: any;

	base_image: string = this.commonService.logo_image;
	action: string;
	mediaImage: string;

	mediaUrl: string = null;

	constructor(public userService: UserService,
		public instagramService: InstagramService,
		public instagramTicketService: InstagramTicketService,
		public commonService: CommonService,
		private cd: ChangeDetectorRef,
		public matDialog: MatDialog,
		public sanitizer: DomSanitizer,
		public matDialogRef: MatDialogRef<CommentExportComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
	}

	ngOnInit() {
		this.instagramService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.instagramParam = data;
			if(data && data.length > 0) {
				let first: Instagram = data[0];
				this.mediaUrl = first.permalink;
			}
		});
		this.instagramTicketService.item.pipe(untilDestroyed(this)).subscribe(data => this.instagramTicket = data);
		this.instagramService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.instagrams = data );
		this.doLoader();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.instagramService.changeAllItems([]);
		this.instagramService.unSubscribe();
		this.instagramTicketService.changeItem(null);
		this.instagramTicketService.unSubscribe();
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'show':
				this.dialogTitle = 'Instagram Media Comments';
				break;
			default:
				break;
		}
	}

	onClose() {
		this.loader = !this.loader;
		this.instagramService.changeAllItems([]);
		this.instagramService.unSubscribe();
		this.instagramTicketService.unSubscribe();
	}

	getIfrmeView(instagram: Instagram = null) {
		if(instagram && instagram.permalink) {
			this.mediaUrl = instagram.permalink + "embed/";
			return this.sanitizer.bypassSecurityTrustResourceUrl(instagram.permalink + "embed/"); 
		}
		if(this.mediaUrl) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(this.mediaUrl); 
		}
		return null;
	}

	getPosition(chat): string {
		return (chat && chat.status && chat.status === 'received') ? 'left' : 'right';
	}
	
}
