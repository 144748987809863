import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	MediaService, Media,
	ChatService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'gallery-item-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss']
})

export class ImageComponent implements OnInit, OnDestroy {
	authUser: User;
	@Input() media: Media = null;
	@Input() showCaption: boolean = true;
	@Input() showSend: boolean = false;
	@Output() onSendMedia = new EventEmitter();
	@Output() onDelete = new EventEmitter();
	@Output() onFavorite = new EventEmitter();
	@Output() onShareMedia = new EventEmitter();
	@Output() onSelected = new EventEmitter();
	@Input() isChatMedia: boolean = false;
	imageAssets: any;

	constructor(public userService: UserService,
		public mediaService: MediaService,
		private cd: ChangeDetectorRef, private chatService: ChatService) {

		this.chatService.assetsDetails.pipe(untilDestroyed(this)).subscribe(data => {
			this.imageAssets = null;
			if (data && data.length > 0) {
				data.forEach(assets => {
					if (assets.type == 'image' && assets.allowedFormats && assets.allowedFormats.length > 0) {
						this.imageAssets = assets;
						var access = [];
						assets.allowedFormats.forEach(element => {
							access.push('.' + element)
						});
						this.imageAssets['accept'] = access;
					}
				});
			}
		});
	}

	ngOnInit() {
		this.cd.detectChanges();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.isChatMedia = false;
		this.cd.detectChanges();
		this.mediaService.resetParams();
	}

	showPreview(media: Media) {
		this.mediaService.changeItem(media);
	}


	assetsMatch(accept) {
		var isMatch = false;
		var acceptsAssets: Array<any> = this.imageAssets['accept'];
		if (acceptsAssets && acceptsAssets.length > 0) {
			accept.forEach(element => {
				if (acceptsAssets.indexOf(element) > -1 || acceptsAssets.indexOf(element.toLowerCase()) > -1) {
					isMatch = true;
					return false;
				}
			});
			return isMatch;
		}
	}

}
