export class ResellerDashboardModel {
	count_widgets: Array<any>;

	/**
	 * Constructor
	 * @param resellerDashboard
	 */
	constructor(resellerDashboard) {
		this.count_widgets = resellerDashboard.count_widgets || [];
	}
}