import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import { User, UserService, MediaService, Media } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'gallery-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit, OnDestroy {
    @Input() tableView: boolean = false;
    @Output() onTableView = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() onFavorite = new EventEmitter();
    @Output() onShareMedia = new EventEmitter();

	authUser: User;
	media: Media;
    showCaption: boolean = false;

    imgIcon = 'assets/images/icons/gallery-icon.png';
    docIcon = 'assets/images/icons/doc-icon.png';

	constructor(public userService: UserService,
		public mediaService: MediaService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog,
		public location: Location) {
        this.mediaService.item.pipe(untilDestroyed(this)).subscribe(data => {
            this.media = data;
        });
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { 
		this.mediaService.unSubscribe();
		this.mediaService.unSubscribeFilter();	
	}
	deleteMedia(){
		delete this.media;
	}
	
}
