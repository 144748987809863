import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService,
         MenuGroupService, MenuGroup } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuGroups: MenuGroup[] = [];
  menuGroupParam: any;
  selectedMenu: any = null;
  selectedSubMenu: any = null;
  logoImg = './assets/images/tji/logo_black_bg.png';

  constructor(private _formBuilder: UntypedFormBuilder,
              public userService: UserService,
              public menuGroupService: MenuGroupService,
              private router: Router) {
	  	this.userService.authUser();
      this.menuGroupService.allItems.subscribe(data => this.menuGroups = data );
      this.menuGroupService.params.subscribe(data => this.menuGroupParam = data);
  }

  	ngOnInit() {
      // this.getMenuGroups();
      this.menuGroupService.getAllItems();
  	}

    getMenuGroups() {
      this.menuGroupParam.all = 1;
      this.menuGroupParam.with = 'menus,menus.subMenus';
      this.menuGroupParam.order = 'order|asc';
    	this.menuGroupService.getAllItems();
    }

    checkRoles(item): boolean {
      if(item.role) {
        let roles: Array<any> = item.roles;
        if(roles.length > 0) {
          return this.userService.personTypeMatch(roles);
        }
      }
      return true;
    }
}
