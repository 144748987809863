import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMatModule } from 'src/app/app-mat.module';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from "@ngx-translate/core";

import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { RightbarComponent } from './rightbar/rightbar.component';
import { SidebarClientComponent } from './sidebar-client/sidebar-client.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { SubLoaderComponent } from './subLoader/subLoader.component';
import { TextLoaderComponent } from './textLoader/textLoader.component';
import { BreadcrumpComponent } from './breadcrump/breadcrump.component';
import { NoRecordComponent } from './norecord/norecord.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PeopleComponent } from './people/people.component';
import { SocialMediaStatusComponent } from './socialMediaStatus/socialMediaStatus.component';
import { TemplateMessageViewComponent } from './templateMessageView/templateMessageView.component';
import { LogComponent } from './log/log.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { IntractiveMessageViewComponent } from './intractive-message-view/intractive-message-view.component';
import { NotificationComponent } from './notification/notification.component';


@NgModule({
    declarations: [
        NavbarComponent,
        HeaderComponent,
        ChangePasswordComponent,
        RightbarComponent,
        SidebarClientComponent,
        FooterComponent,
        LoaderComponent,
        SubLoaderComponent,
        TextLoaderComponent,
        BreadcrumpComponent,
        NoRecordComponent,
        NavigationComponent,
        PeopleComponent,
        SocialMediaStatusComponent,
        LogComponent,
        TemplateMessageViewComponent,
        IntractiveMessageViewComponent,
        NotificationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AppMatModule,
        NgPipesModule,
        PipesModule,
        InfiniteScrollModule,
        ReactiveFormsModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatDialogModule,
        TranslateModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        NavbarComponent,
        HeaderComponent,
        ChangePasswordComponent,
        RightbarComponent,
        SidebarClientComponent,
        FooterComponent,
        LoaderComponent,
        SubLoaderComponent,
        TextLoaderComponent,
        BreadcrumpComponent,
        NoRecordComponent,
        NavigationComponent,
        PeopleComponent,
        SocialMediaStatusComponent,
        LogComponent,
        TemplateMessageViewComponent,
        IntractiveMessageViewComponent
    ]
})
export class ComponentModule { }
