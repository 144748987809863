import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { UrlFormComponent } from './../webhookItems/urlForm/urlForm.component';

import {
	User, UserService,
	WebhookService, Webhook, WebhookModel,
	ClientSiteService, ClientSite
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'social-site-webhook',
	templateUrl: './webhook.component.html',
	styleUrls: ['./webhook.component.scss']
})

export class WebhookComponent implements OnInit, OnDestroy {
	authUser: User;
	clientSite: ClientSite;

	constructor(public userService: UserService,
		public clientSiteService: ClientSiteService,
		public webhookService: WebhookService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.clientSiteService.item.pipe(untilDestroyed(this)).subscribe(data => this.clientSite = data);
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getSiteUrl() {
		return (this.clientSite && this.clientSite.webhook) ? this.clientSite.webhook.site_url : '';
	}

	/** Add / Update Webhook */
	newWebhook(webhook = null): void {
		this.webhookService.changeItem((webhook) ? webhook : new WebhookModel({}));
		let dialogRef = this.matDialog.open(UrlFormComponent, {
			width: "600px",
			data: {
				action: (webhook) ? 'edit' : 'new',
				item: webhook,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.webhookService.store(formData.value);
						setTimeout(() => { this.clientSiteService.getItem({ id: this.clientSite.id }); }, 1500);
						break;
					case 'update':
						this.webhookService.update(id, formData.value);
						setTimeout(() => { this.clientSiteService.getItem({ id: this.clientSite.id }); }, 1500);
						break;
				}
			});
	}

	generateApi() {
		this.clientSiteService.changeItem(this.clientSite);
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				title: 'Generate Api',
				type: 'info',
				message: 'Are you sure want to Generate Apikey ?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.clientSiteService.postUrl({ 'client_site_id': this.clientSite.id }, 'client-site/api-generate');
			}
		});
	}

}
