import { NgModule } from '@angular/core';
import { ItrsbotImportModule } from './itrsbot-import.module';
import { ItrsbotRouteModule } from './itrsbot-route.module';

@NgModule({
	declarations: [],
	imports: [
		ItrsbotRouteModule,
		ItrsbotImportModule,
	]
})

export class ItrsbotModule { }
