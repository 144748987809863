export class ClientSiteErrorModel {
	id            : number;
	client_id     : number;
	client_site_id: number;
	error         : string;
	error_code    : string;
	json          : string;
	created_by    : number;
	updated_by    : number;
	created_at    : Date;
	updated_at    : Date;

	/**
	 * Constructor
	 *
	 * @param clientSiteError
	 */
	constructor(clientSiteError) {
		this.id            = clientSiteError.id || '';
		this.client_id     = clientSiteError.client_id || '';
		this.client_site_id= clientSiteError.client_site_id || '';
		this.error         = clientSiteError.error || '';
		this.error_code    = clientSiteError.error_code || '';
		this.json          = clientSiteError.json || '';
		this.created_by    = clientSiteError.created_by || '';
		this.updated_by    = clientSiteError.updated_by || '';
		this.created_at    = clientSiteError.created_at || '';
		this.updated_at    = clientSiteError.updated_at || '';
	}
}
