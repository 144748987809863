export class PosterScheduleModel {
	id: number;
	poster_id: number;
	client_id: number;
	client_site_id: number;
	post_at: Date;
	is_approved: boolean;
	is_published: boolean;
	created_by: number;
	updated_by: number;
	created_at: Date;
	updated_at: Date;
	
	status?: string;

	tableName: string;
	poster?: any;
	client_site?: any;

	/**
	 * Constructor
	 *
	 * @param posterSchedule
	 */
	constructor(posterSchedule) {
		this.id = posterSchedule.id || '';
		this.poster_id = posterSchedule.poster_id || '';
		this.client_id = posterSchedule.client_id || '';
		this.client_site_id = posterSchedule.client_site_id || '';
		this.post_at = posterSchedule.post_at || '';
		this.is_approved = posterSchedule.is_approved || '';
		this.is_published = posterSchedule.is_published || '';
		this.created_by = posterSchedule.created_by || '';
		this.updated_by = posterSchedule.updated_by || '';
		this.created_at = posterSchedule.created_at || '';
		this.updated_at = posterSchedule.updated_at || '';
		
		this.status = posterSchedule.status || null;

		this.tableName = posterSchedule.tableName || '';
		this.poster = posterSchedule.poster || null;
		this.client_site = posterSchedule.client_site || null;
	}
}
