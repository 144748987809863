import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { Messenger } from './messenger.interface';
import { MessengerModel } from './messenger_model.model';
import { LoaderService } from 'src/app/@tji/_dbShare/general/loader.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class MessengerService implements Resolve<any> {
	url: string = 'messenger';
	routeParams: any;
	defaultParams: any = {
		"limit": 25,
		"current": 1,
		"sort": "created_at|DESC",
		"is_history": 1
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Messenger[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<Messenger>(new MessengerModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<Messenger[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private loaderService: LoaderService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed MessengerService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on MessengerService');
	}

	concatlibrary(allItems: Messenger[]) {
		allItems = allItems.filter(function (e) { return e });
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: Messenger) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: Messenger, eventName: string = null) {

		var items: any;
		items = item;
		if (item != null && item != undefined) {
			if (eventName == 'Messenger' && item && items.ChatData) {
				var ticketData = item;
				var dataFormat = [];
				var jsonData = JSON.parse(ticketData.ChatData.json);
				var dataFormats = {
					id: ticketData.ChatData.id,
					reply_id: ticketData.ChatData.reply_id,
					status: ticketData.ChatData.status,
					sms_message_sid: ticketData.ChatData.message_mid,
					from: ticketData.ChatData.from,
					to: ticketData.ChatData.to,
					media: ticketData.ChatData.media,
					asset_url: ticketData.ChatData.asset_url ? ticketData.ChatData.asset_url : null,
					created_by: ticketData.ChatData.created_by,
					updated_by: ticketData.ChatData.updated_by,
					created_at: moment(ticketData.ChatData.created_at + '.000+0300').local(), //ticketData.ChatData.created_at, // 
					updated_at: ticketData.ChatData.updated_at,
					people_id: ticketData.people_id,
					parent_id: jsonData.message.mid ? ticketData.ChatData.instadm_id : 'null',
					replyMessageId: ticketData.ChatData.reply_id,
					createdByName: ticketData.ChatData?.CreatedByInfo?.MoreInfo?.name,
					is_itrs: ticketData.ChatData.is_itrs ? true : false,
					location_url: ticketData.ChatData.locationurl,
					text: ticketData.ChatData.text,
					messenger_id: ticketData.messenger_id,
					is_active: ticketData.is_active,
					is_closed: ticketData.is_closed,
					siteName: ticketData.clientSite.details.name,
					ticketId: ticketData.id,
					isAssetData: false,
				}

				if (ticketData["TicketInfo"] && ticketData["TicketInfo"].id && ticketData.people_id) {
					if (ticketData["TicketInfo"].people_id == ticketData.people_id) {
						dataFormats['ticketId'] = ticketData["TicketInfo"].id;
					}
				}

				if (ticketData.ChatData.asset_url && ticketData.ChatData.asset_url.length > 0) {
					var baseUrl = ticketData.ChatData.asset_url;
					// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
					// var decodeName = baseUrl.split("/").pop()
					// let encoded = decodeURIComponent(decodeName);
					dataFormats["asset_url"] = baseUrl;
				}

				if (ticketData.media == 'audio' || ticketData.media == 'voice') {
					dataFormats['asset_url'] = ticketData.asset_url ? ticketData.asset_url : null;
				}

				if (dataFormats && dataFormats.reply_id) {
					dataFormats['text'] = dataFormats.text;
					var jsonData = JSON.parse(ticketData.ReplyData.json);

					if (jsonData && jsonData.interactive) {
						var interactiveData = {
							"to": "",
							"type": "interactive",
							"headerType": null,
							"headerText": null,
							"image": null,
							"video": null,
							"document": null,
							"bodyType": null,
							"footerType": null,
							"footerText": null,
							"buttons": [],
							"lists": []
						};
						var intreactiveStr = [];
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
							jsonData.interactive.action.buttons.forEach(interactive => {
								var data = {
									text: interactive.reply.title,
									index: interactive.reply.id
								}
								intreactiveStr.push(data);
								if (interactive.reply.id == dataFormats['text']) {
									dataFormats['text'] = interactive.reply.title;
								}
							});
							interactiveData.buttons = intreactiveStr
						}
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
							jsonData.interactive.action.lists.forEach(interactive => {
								var data = {
									text: interactive.reply.title,
									index: interactive.reply.id
								}
								intreactiveStr.push(data);
							});
							interactiveData.lists = intreactiveStr
						}
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
							jsonData.interactive.action.sections[0].rows.forEach(interactive => {
								var data = {
									text: interactive.title,
									index: interactive.id
								}
								intreactiveStr.push(data);
								if (interactive.id == dataFormats['text']) {
									dataFormats['text'] = interactive.title;
								}
							});
							interactiveData.lists = intreactiveStr
						}
						interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
						dataFormats['reply']['interactiveData'] = interactiveData;
					} else if (ticketData.ReplyData.media == 'text' || ticketData.ChatData.media == 'text') {
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					} else if (ticketData.ReplyData.media == 'video' || ticketData.ChatData.media == 'video') {
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					} else if (ticketData.ReplyData.media == 'image' || ticketData.ChatData.media == 'image') {
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					} else if (ticketData.ReplyData.media == 'audio' || ticketData.ChatData.media == 'audio') {
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					} else if (ticketData.ReplyData.media == 'document' || ticketData.ChatData.media == 'document') {
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					} else if (ticketData.ReplyData.media == 'voice' || ticketData.ChatData.media == 'voice') {
						dataFormats['reply'] = ticketData.ReplyData;
						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					}

					if (ticketData.ReplyData.media == 'location') {
						dataFormats['reply'] = ticketData.reply;
						dataFormats['reply']['location_url'] = ticketData.reply.locationurl;

						if (ticketData.clientSite && ticketData.clientSite.details) {
							dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
						}
					}

					if (jsonData && jsonData.order) {
						dataFormats['reply']['orderData'] = jsonData.order;
					}
				} else if (dataFormats && !dataFormats.reply_id) {
					if (ticketData.ChatData.media == 'contacts') {
						dataFormats['text'] = JSON.parse(ticketData.ChatData.text);
					} else {
						dataFormats['text'] = ticketData.ChatData.text;
					}

					if (jsonData && jsonData.interactive) {
						var interactiveData = {
							"to": "",
							"type": "interactive",
							"headerType": null,
							"headerText": null,
							"image": null,
							"video": null,
							"document": null,
							"bodyType": null,
							"footerType": null,
							"footerText": null,
							"buttons": [],
							"lists": []
						};
						var intreactiveStr = [];
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
							jsonData.interactive.action.buttons.forEach(interactive => {
								var data = {
									text: interactive.reply.title,
									index: interactive.reply.id
								}
								intreactiveStr.push(data);
							});
							interactiveData.buttons = intreactiveStr
						}
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
							jsonData.interactive.action.lists.forEach(interactive => {
								var data = {
									text: interactive.reply.title,
									index: interactive.reply.id
								}
								intreactiveStr.push(data);
							});
							interactiveData.lists = intreactiveStr
						}
						if (jsonData && jsonData.order) {
							dataFormats['orderData'] = jsonData.order;
						}
						interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
						dataFormats['interactiveData'] = interactiveData;
					}
				}

				// if (additionalData.TicketInfo && additionalData.TicketInfo.length > 0 && ticketList && ticketList.people_id) {
				// 	additionalData.TicketInfo.forEach(ticketId => {
				// 		if (ticketId.people_id == ticketList.people_id) {
				// 			dataFormats['ticketId'] = ticketId.id;
				// 		}
				// 	});
				// }
				if (ticketData && ticketData.messenger_id) {
					dataFormats['instadm_id'] = ticketData.messenger_id;
				} else {
					dataFormats['instadm_id'] = ticketData.id;
				}
				// if (ticketData.clientSite && ticketData.clientSite.details) {
				// 	dataFormats['siteName'] = ticketData.clientSite.details?.name;
				// 	dataFormats['clientSiteTextColor'] = ticketData.clientSite.details?.color;
				// 	dataFormats['clientSiteColor'] = ticketData.clientSite.details?.color;
				// }
				// if (additionalData && additionalData.peopleData.length > 0) {
				// 	dataFormats['peopleName'] = additionalData.peopleData[0]?.name;
				// 	dataFormats['peopleAvatar'] = additionalData.peopleData[0]?.peopleAvatar;
				// }

				items = dataFormats;
			}



			let oldLists = [];
			let isReplaced: boolean = false;
			this.libraries.subscribe(data => oldLists = data);
			if (oldLists && oldLists.length > 0 && items && items.id) {
				for (var i = 0; i < oldLists.length; ++i) {
					if (oldLists[i].id === items.id) {
						oldLists.splice(i, 1, items);
						isReplaced = true;
						break;
					}
				}
			}
			if (!isReplaced) { oldLists.push(items); }
			this.changeLibraries(oldLists);
		}
	}

	replaceReplyLibrary(item: any) {
		if (item != null && item != undefined) {
			let oldLists = [];
			let isReplaced: boolean = false;
			var ticketData = item;
			if (ticketData && ticketData.id && ticketData.id) {
				var jsonData = JSON.parse(ticketData.json);
				var dataFormats = {
					id: ticketData.id,
					ticketId: ticketData.messenger_id,
					reply_id: ticketData.reply_id,
					status: ticketData.status,
					sms_message_sid: ticketData.sms_message_sid,
					from: ticketData.from,
					to: ticketData.to,
					media: ticketData.media,
					asset_url: ticketData.asset_url ? ticketData.asset_url : null,
					created_by: ticketData.created_by,
					updated_by: ticketData.updated_by,
					created_at: moment(ticketData.created_at + '.000+0300').local(),
					updated_at: ticketData.updated_at,
					people_id: ticketData.people_id,
					parent_id: jsonData.parent_id ? ticketData.id : 'null',
					replyMessageId: ticketData.reply_id,
					createdByName: ticketData?.CreatedByInfo?.MoreInfo?.name,
					replayPersonType: ticketData?.CreatedByInfo?.person_type,
					is_itrs: ticketData.is_itrs ? true : false,
					location_url: ticketData.locationurl,
					client_id: ticketData.client_id,
					client_site_id: ticketData.client_site_id,
					account_sid: ticketData.account_sid,
					isAssetData: false,
				}

				if (ticketData.UsersInfo && ticketData.UsersInfo.id && ticketData.UsersInfo.avatar_url) {
					dataFormats["user_image"] = ticketData.UsersInfo.avatar_url
				}

				if (ticketData["TicketInfo"] && ticketData["TicketInfo"].id && ticketData.people_id) {
					if (ticketData["TicketInfo"].people_id == ticketData.people_id) {
						dataFormats['ticketId'] = ticketData["TicketInfo"].id;
					}
				}

				if (ticketData.UsersInfo && ticketData.UsersInfo.id) {
					dataFormats["replayPersonType"] = ticketData.UsersInfo.person_type;
					dataFormats["createdByName"] = ticketData.UsersInfo.username;
				}

				if (ticketData.asset_url && ticketData.asset_url.length > 0) {
					var baseUrl = ticketData.asset_url;
					// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
					// var decodeName = baseUrl.split("/").pop()
					// let encoded = decodeURIComponent(decodeName);
					dataFormats["asset_url"] = baseUrl;
				}

				if (ticketData.media == 'audio' || ticketData.media == 'voice') {
					dataFormats['asset_url'] = ticketData.asset_url ? ticketData.asset_url : null;
				}

				if (ticketData && ticketData.reply_id) {
					dataFormats['text'] = ticketData.text;
					if (ticketData.reply && ticketData.reply.id && ticketData.reply.id == ticketData.reply_id) {
						var jsonData = JSON.parse(ticketData.reply.json);

						if (jsonData && jsonData.interactive) {
							var interactiveData = {
								"to": "",
								"type": "interactive",
								"headerType": null,
								"headerText": null,
								"image": null,
								"video": null,
								"document": null,
								"bodyType": null,
								"footerType": null,
								"footerText": null,
								"buttons": [],
								"lists": []
							};
							var intreactiveStr = [];
							if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
								jsonData.interactive.action.buttons.forEach(interactive => {
									var data = {
										text: interactive.reply.title,
										index: interactive.reply.id
									}
									intreactiveStr.push(data);
									if (interactive.reply.id == dataFormats['text']) {
										dataFormats['text'] = interactive.reply.title;
									}
								});
								interactiveData.buttons = intreactiveStr
							}
							if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
								jsonData.interactive.action.lists.forEach(interactive => {
									var data = {
										text: interactive.reply.title,
										index: interactive.reply.id
									}
									intreactiveStr.push(data);
								});
								interactiveData.lists = intreactiveStr
							}
							if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
								jsonData.interactive.action.sections[0].rows.forEach(interactive => {
									var data = {
										text: interactive.title,
										index: interactive.id
									}
									intreactiveStr.push(data);
									if (interactive.id == dataFormats['text']) {
										dataFormats['text'] = interactive.title;
									}
								});
								interactiveData.lists = intreactiveStr
							}
							interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
							dataFormats['reply']['interactiveData'] = interactiveData;
						} else if (ticketData.reply.media == 'text' || ticketData.media == 'text') {
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
						} else if (ticketData.reply.media == 'video' || ticketData.media == 'video') {
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
						} else if (ticketData.reply.media == 'image' || ticketData.media == 'image') {
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
						} else if (ticketData.reply.media == 'audio' || ticketData.media == 'audio') {
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
						} else if (ticketData.reply.media == 'document' || ticketData.media == 'document') {
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
						} else if (ticketData.reply.media == 'voice' || ticketData.media == 'voice') {
							dataFormats['reply'] = ticketData.reply;
							if (ticketData.clientSiteInfo) {
								dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
							}
						}

						if (ticketData.reply.media == 'location') {
							dataFormats['reply'] = ticketData.reply;
							dataFormats['reply']['location_url'] = ticketData.reply.locationurl;

							if (ticketData.clientSite && ticketData.clientSite.details) {
								dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
							}
						}

						if (jsonData && jsonData.order) {
							dataFormats['reply']['orderData'] = jsonData.order;
						}
					}
				} else if (ticketData && !ticketData.reply_id) {
					if (ticketData.media == 'contacts') {
						dataFormats['text'] = JSON.parse(ticketData.text);
					} else if (ticketData.media == 'document') {
						dataFormats['text'] = null;
					} else if (ticketData.media == 'video') {
						dataFormats['text'] = null;
					} else {
						dataFormats['text'] = ticketData.text;
					}

					if (jsonData && jsonData.interactive) {
						var interactiveData = {
							"to": "",
							"type": "interactive",
							"headerType": null,
							"headerText": null,
							"image": null,
							"video": null,
							"document": null,
							"bodyType": null,
							"footerType": null,
							"footerText": null,
							"buttons": [],
							"lists": []
						};
						var intreactiveStr = [];
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
							jsonData.interactive.action.buttons.forEach(interactive => {
								var data = {
									text: interactive.reply.title,
									index: interactive.reply.id
								}
								intreactiveStr.push(data);
							});
							interactiveData.buttons = intreactiveStr
						}
						if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
							jsonData.interactive.action.lists.forEach(interactive => {
								var data = {
									text: interactive.reply.title,
									index: interactive.reply.id
								}
								intreactiveStr.push(data);
							});
							interactiveData.lists = intreactiveStr
						}
						if (jsonData && jsonData.order) {
							dataFormats['orderData'] = jsonData.order;
						}
						interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
						dataFormats['interactiveData'] = interactiveData;
					}

					if (jsonData && jsonData.template && jsonData.template.json) {
						var templateData: any;
						var type = typeof jsonData.template.json
						if (type == "string") {
							templateData = JSON.parse(jsonData.template.json)
						} else {
							templateData = jsonData.template.json;
						}

						var chatDatas = {};
						var buttonList = [];
						if (jsonData.template.selected) {
							chatDatas['selected'] = jsonData.template.selected
							templateData.templateComponent['templateName'] = templateData.template.name;
							chatDatas['templateComponent'] = templateData.templateComponent;
							if (templateData.templateComponent.buttons && templateData.templateComponent.buttons.length > 0) {
								templateData.templateComponent.buttons.forEach(temp => {

									if (temp.value == jsonData.template.selected) {
										temp['isActive'] = true;
									}
									if (temp.type == 'button') {
										buttonList.push(temp)
									}
								});
								chatDatas['components'] = buttonList;
								dataFormats['text'] = chatDatas;
							} else {
								dataFormats['text'] = ticketData.text;
							}
						}
						if (!jsonData.template.selected) {
							var isCarousel = false;
							chatDatas['selected'] = jsonData.human.button_text;
							if (!templateData.templateComponent) {
								templateData["templateComponent"] = jsonData.template.json;
							}
							jsonData.template.json.forEach(carousel => {
								if (carousel.type == 'CAROUSEL') {
									isCarousel = true;
								}
							});
							if (isCarousel) {
								templateData.templateComponent['templateName'] = jsonData.template.name + ' - carousel Template';
								templateData.templateComponent['isCarousel'] = true;
							} else {
								templateData.templateComponent['templateName'] = jsonData.template.name;
							}
							chatDatas['templateComponent'] = templateData;
							const buttonData = templateData.filter((template) => template.type == 'BUTTONS');
							if (buttonData && buttonData.length > 0 && !isCarousel) {
								buttonData[0].buttons.forEach(temp => {
									var dataButton = {
										value: temp.text,
										type: temp.type,
									}
									if (temp.text == jsonData.human.button_text) {
										dataButton['isActive'] = true;
									}
									buttonList.push(dataButton)
								});
								chatDatas['components'] = buttonList;
								dataFormats['text'] = chatDatas;
							}
							if (isCarousel) {
								var components = {
									// value: '',
									// type: 'button',
								}
								buttonList.push(components);
								chatDatas['components'] = buttonList;
								chatDatas['text'] = ticketData.text;
								dataFormats['text'] = chatDatas;
							}
						}

					} else {
						dataFormats['text'] = ticketData.text;
					}
				}
				if (ticketData && ticketData.whatsapp_id) {
					dataFormats['whatsapp_id'] = ticketData.whatsapp_id;
				} else {
					dataFormats['whatsapp_id'] = ticketData.id;
				}
				if (ticketData.clientSiteInfo) {
					dataFormats['siteName'] = ticketData.clientSiteInfo.details?.name;
					dataFormats['clientSiteTextColor'] = ticketData.clientSiteInfo?.color;
					dataFormats['clientSiteColor'] = ticketData.clientSiteInfo.details?.color;
				}
				if (ticketData.peopleData.id) {
					dataFormats['peopleName'] = ticketData.peopleData.name;
					dataFormats['peopleAvatar'] = ticketData?.peopleData?.peopleAvatar;
				}
				var statusData = [];
				if (ticketData && ticketData.ticketStatus) {
					ticketData.ticketStatus.forEach(ticketStatus => {
						if (ticketStatus.model_id == ticketData.id) {
							statusData.push(ticketStatus);
						}
					});
					if (statusData && statusData.length > 0) {
						let lastStatusData = statusData.pop();
						dataFormats["statusName"] = lastStatusData.name;
						dataFormats["className"] = lastStatusData.model_type;
					}
				}
			}
			this.libraries.subscribe(data => oldLists = data);
			if (oldLists && oldLists.length > 0 && dataFormats && dataFormats.id) {
				for (var i = 0; i < oldLists.length; ++i) {
					if (oldLists[i]?.id === dataFormats.id) {
						oldLists.splice(i, 1, dataFormats);
						isReplaced = true;
						break;
					}
				}
			}
			if (!isReplaced) { oldLists.push(dataFormats); }
			this.changeLibraries(oldLists);
		}
	}

	changeLibraries(libraries: Messenger[]) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Messenger[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
	}

	changeItem(item: any) {
		this.replacelibrary(item);
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: Messenger[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: Messenger) {
		let allItems = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) {
					allItems.splice(i, 1, item);
					isChanged = true;
				}
			}
		}
		this.changeAllItems(allItems);
		if (!isChanged) { this.concatItem(item); }
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			"limit": 25,
			"current": 1,
			"sort": "created_at|DESC",
			"is_history": 1
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		// params.is_history = 0
		this.commonService.storeItem('tickets/chatView', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (ticketData && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var jsonData = JSON.parse(ticketList.json);
							var dataFormats = {
								id: ticketList.id,
								reply_id: ticketList.reply_id,
								status: ticketList.status,
								sms_message_sid: ticketList.sms_message_sid,
								from: ticketList.from,
								to: ticketList.to,
								media: ticketList.media,
								asset_url: ticketList.asset_url ? ticketList.asset_url : null,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								updated_at: ticketList.updated_at,
								people_id: ticketList.people_id,
								parent_id: jsonData.parent_id ? ticketList.id : 'null',
								replyMessageId: ticketList.reply_id,
								createdByName: ticketList?.CreatedByInfo?.MoreInfo?.name,
								replayPersonType: ticketList?.CreatedByInfo?.person_type,
								is_itrs: ticketList.is_itrs ? true : false,
								location_url: ticketList.locationurl,
								isAssetData: false,
							}

							if (additionalData.TicketInfo && additionalData.TicketInfo.length > 0 && ticketList && ticketList.people_id) {
								additionalData.TicketInfo.forEach(ticketId => {
									if (ticketId.people_id == ticketList.people_id) {
										dataFormats['ticketId'] = ticketId.id;
									}
								});
							}

							if (ticketList.asset_url && ticketList.asset_url.length > 0) {
								var baseUrl = ticketList.asset_url;
								// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
								// var decodeName = baseUrl.split("/").pop()
								// let encoded = decodeURIComponent(decodeName);
								dataFormats["asset_url"] = baseUrl;
							}
							if (ticketList && ticketList.reply_id) {
								dataFormats['text'] = ticketList.text;
								additionalData.ReplyInfo.forEach(replyInfo => {
									if (replyInfo.id === ticketList.reply_id) {
										var jsonData = JSON.parse(replyInfo.json);

										if (jsonData && jsonData.interactive) {
											var interactiveData = {
												"to": "",
												"type": "interactive",
												"headerType": null,
												"headerText": null,
												"image": null,
												"video": null,
												"document": null,
												"bodyType": null,
												"footerType": null,
												"footerText": null,
												"buttons": [],
												"lists": []
											};
											var intreactiveStr = [];
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
												jsonData.interactive.action.buttons.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
													if (interactive.reply.id == dataFormats['text']) {
														dataFormats['text'] = interactive.reply.title;
													}
												});
												interactiveData.buttons = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
												jsonData.interactive.action.lists.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
												});
												interactiveData.lists = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
												jsonData.interactive.action.sections[0].rows.forEach(interactive => {
													var data = {
														text: interactive.title,
														index: interactive.id
													}
													intreactiveStr.push(data);
													if (interactive.id == dataFormats['text']) {
														dataFormats['text'] = interactive.title;
													}
												});
												interactiveData.lists = intreactiveStr
											}
											interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
											dataFormats['reply']['interactiveData'] = interactiveData;
										} else if (replyInfo.media == 'text' || ticketList.media == 'text') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'video' || ticketList.media == 'video') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'image' || ticketList.media == 'image') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'audio' || ticketList.media == 'audio') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'document' || ticketList.media == 'document') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'voice' || ticketList.media == 'voice') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (replyInfo.media == 'location') {
											dataFormats['reply'] = replyInfo;
											dataFormats['reply']['location_url'] = replyInfo.locationurl;

											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (jsonData && jsonData.order) {
											dataFormats['reply']['orderData'] = jsonData.order;
										}


									}
								});
							} else if (ticketList && !ticketList.reply_id) {
								if (ticketList.media == 'contacts') {
									dataFormats['text'] = JSON.parse(ticketList.text);
								} else {
									dataFormats['text'] = ticketList.text;
								}

								if (jsonData && jsonData.interactive) {
									var interactiveData = {
										"to": "",
										"type": "interactive",
										"headerType": null,
										"headerText": null,
										"image": null,
										"video": null,
										"document": null,
										"bodyType": null,
										"footerType": null,
										"footerText": null,
										"buttons": [],
										"lists": []
									};
									var intreactiveStr = [];
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
										jsonData.interactive.action.buttons.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.buttons = intreactiveStr
									}
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
										jsonData.interactive.action.lists.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.lists = intreactiveStr
									}
									if (jsonData && jsonData.order) {
										dataFormats['orderData'] = jsonData.order;
									}
									interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
									dataFormats['interactiveData'] = interactiveData;
								}

								if (jsonData && jsonData.template && jsonData.template.json) {
									var templateData: any;
									var type = typeof jsonData.template.json
									if (type == "string") {
										templateData = JSON.parse(jsonData.template.json)
									} else {
										templateData = jsonData.template.json;
									}

									var chatDatas = {};
									var buttonList = [];
									if (jsonData.template.selected) {
										chatDatas['selected'] = jsonData.template.selected
										templateData.templateComponent['templateName'] = templateData.template.name;
										chatDatas['templateComponent'] = templateData.templateComponent;
										if (templateData.templateComponent.buttons && templateData.templateComponent.buttons.length > 0) {
											templateData.templateComponent.buttons.forEach(temp => {

												if (temp.value == jsonData.template.selected) {
													temp['isActive'] = true;
												}
												if (temp.type == 'button') {
													buttonList.push(temp)
												}
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										} else {
											dataFormats['text'] = ticketList.text;
										}
									}
									if (!jsonData.template.selected) {
										var isCarousel = false;
										chatDatas['selected'] = jsonData.human.button_text;
										if (!templateData.templateComponent) {
											templateData["templateComponent"] = jsonData.template.json;
										}
										jsonData.template.json.forEach(carousel => {
											if (carousel.type == 'CAROUSEL') {
												isCarousel = true;
											}
										});
										if (isCarousel) {
											templateData.templateComponent['templateName'] = jsonData.template.name + ' - carousel Template';
											templateData.templateComponent['isCarousel'] = true;
										} else {
											templateData.templateComponent['templateName'] = jsonData.template.name;
										}
										chatDatas['templateComponent'] = templateData;
										const buttonData = templateData.filter((template) => template.type == 'BUTTONS');
										if (buttonData && buttonData.length > 0 && !isCarousel) {
											buttonData[0].buttons.forEach(temp => {
												var dataButton = {
													value: temp.text,
													type: temp.type,
												}
												if (temp.text == jsonData.human.button_text) {
													dataButton['isActive'] = true;
												}
												buttonList.push(dataButton)
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										}
										if (isCarousel) {
											var components = {
												// value: '',
												// type: 'button',
											}
											buttonList.push(components);
											chatDatas['components'] = buttonList;
											chatDatas['text'] = ticketList.text;
											dataFormats['text'] = chatDatas;
										}
									}

								} else {
									dataFormats['text'] = ticketList.text;
								}

							}

							if (ticketList && ticketList.messenger_id) {
								dataFormats['messenger_id'] = ticketList.messenger_id;
							} else {
								dataFormats['messenger_id'] = ticketList.id;
							}
							if (additionalData && additionalData.clientSiteInfo) {
								dataFormats['siteName'] = additionalData.clientSiteInfo.details?.name;
								dataFormats['clientSiteTextColor'] = additionalData.clientSiteInfo?.color;
								dataFormats['clientSiteColor'] = additionalData.clientSiteInfo.details?.color;
							}
							if (additionalData && additionalData.peopleData.length > 0) {
								dataFormats['peopleName'] = additionalData.peopleData[0]?.name;
								dataFormats['peopleAvatar'] = additionalData.peopleData[0]?.peopleAvatar;
							}
							dataFormat.push(dataFormats);
						});
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					} else {
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	getAllLiveItems(params: any = null) {
		params = this.paramsInit(params);
		params.page = 1;
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result.concat(data.data.data.filter(x => result.every(y => y.id !== x.id)));
				});
				this.changeAllItems(lists);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('tickets/chatView', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
						params.current = data.pagnitation.pageCount;
						this.changeParams(params);
					}
					if (ticketData && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var jsonData = JSON.parse(ticketList.json);
							var dataFormats = {
								id: ticketList.id,
								reply_id: ticketList.reply_id,
								status: ticketList.status,
								sms_message_sid: ticketList.sms_message_sid,
								from: ticketList.from,
								to: ticketList.to,
								media: ticketList.media,
								asset_url: ticketList.asset_url ? ticketList.asset_url : null,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								updated_at: ticketList.updated_at,
								people_id: ticketList.people_id,
								parent_id: jsonData.parent_id ? ticketList.id : 'null',
								replyMessageId: ticketList.reply_id,
								createdByName: ticketList?.CreatedByInfo?.MoreInfo?.name,
								replayPersonType: ticketList?.CreatedByInfo?.person_type,
								is_itrs: ticketList.is_itrs ? true : false,
								location_url: ticketList.locationurl,
								isAssetData: false,
							}

							if (additionalData.TicketInfo && additionalData.TicketInfo.length > 0 && ticketList && ticketList.people_id) {
								additionalData.TicketInfo.forEach(ticketId => {
									if (ticketId.people_id == ticketList.people_id) {
										dataFormats['ticketId'] = ticketId.id;
									}
								});
							}

							if (ticketList.asset_url && ticketList.asset_url.length > 0) {
								var baseUrl = ticketList.asset_url;
								// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
								// var decodeName = baseUrl.split("/").pop()
								// let encoded = decodeURIComponent(decodeName);
								dataFormats["asset_url"] = baseUrl;
							}
							if (ticketList.media == 'audio' || ticketList.media == 'voice') {
								dataFormats['asset_url'] = ticketList.asset_url ? ticketList.asset_url : null;
							}

							if (ticketList && ticketList.reply_id) {
								dataFormats['text'] = ticketList.text;
								additionalData.ReplyInfo.forEach(replyInfo => {
									if (replyInfo.id === ticketList.reply_id) {
										var jsonData = JSON.parse(replyInfo.json);

										if (jsonData && jsonData.interactive) {
											var interactiveData = {
												"to": "",
												"type": "interactive",
												"headerType": null,
												"headerText": null,
												"image": null,
												"video": null,
												"document": null,
												"bodyType": null,
												"footerType": null,
												"footerText": null,
												"buttons": [],
												"lists": []
											};
											var intreactiveStr = [];
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
												jsonData.interactive.action.buttons.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
													if (interactive.reply.id == dataFormats['text']) {
														dataFormats['text'] = interactive.reply.title;
													}
												});
												interactiveData.buttons = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
												jsonData.interactive.action.lists.forEach(interactive => {
													var data = {
														text: interactive.reply.title,
														index: interactive.reply.id
													}
													intreactiveStr.push(data);
												});
												interactiveData.lists = intreactiveStr
											}
											if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
												jsonData.interactive.action.sections[0].rows.forEach(interactive => {
													var data = {
														text: interactive.title,
														index: interactive.id
													}
													intreactiveStr.push(data);
													if (interactive.id == dataFormats['text']) {
														dataFormats['text'] = interactive.title;
													}
												});
												interactiveData.lists = intreactiveStr
											}
											interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
											dataFormats['reply']['interactiveData'] = interactiveData;
										} else if (replyInfo.media == 'text' || ticketList.media == 'text') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'video' || ticketList.media == 'video') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'image' || ticketList.media == 'image') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'audio' || ticketList.media == 'audio') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'document' || ticketList.media == 'document') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										} else if (replyInfo.media == 'voice' || ticketList.media == 'voice') {
											dataFormats['reply'] = replyInfo;
											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (replyInfo.media == 'location') {
											dataFormats['reply'] = replyInfo;
											dataFormats['reply']['location_url'] = replyInfo.locationurl;

											if (additionalData && additionalData.clientSiteInfo) {
												dataFormats['reply']['siteName'] = additionalData.clientSiteInfo.details?.name;
											}
										}

										if (jsonData && jsonData.order) {
											dataFormats['reply']['orderData'] = jsonData.order;
										}


									}
								});
							} else if (ticketList && !ticketList.reply_id) {
								if (ticketList.media == 'contacts') {
									dataFormats['text'] = JSON.parse(ticketList.text);
								} else {
									dataFormats['text'] = ticketList.text;
								}

								if (jsonData && jsonData.interactive) {
									var interactiveData = {
										"to": "",
										"type": "interactive",
										"headerType": null,
										"headerText": null,
										"image": null,
										"video": null,
										"document": null,
										"bodyType": null,
										"footerType": null,
										"footerText": null,
										"buttons": [],
										"lists": []
									};
									var intreactiveStr = [];
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
										jsonData.interactive.action.buttons.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.buttons = intreactiveStr
									}
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
										jsonData.interactive.action.lists.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.lists = intreactiveStr
									}
									if (jsonData && jsonData.order) {
										dataFormats['orderData'] = jsonData.order;
									}
									interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
									dataFormats['interactiveData'] = interactiveData;
								}

								if (jsonData && jsonData.template && jsonData.template.json) {
									var templateData: any;
									var type = typeof jsonData.template.json
									if (type == "string") {
										templateData = JSON.parse(jsonData.template.json)
									} else {
										templateData = jsonData.template.json;
									}

									var chatDatas = {};
									var buttonList = [];
									if (jsonData.template.selected) {
										chatDatas['selected'] = jsonData.template.selected
										templateData.templateComponent['templateName'] = templateData.template.name;
										chatDatas['templateComponent'] = templateData.templateComponent;
										if (templateData.templateComponent.buttons && templateData.templateComponent.buttons.length > 0) {
											templateData.templateComponent.buttons.forEach(temp => {

												if (temp.value == jsonData.template.selected) {
													temp['isActive'] = true;
												}
												if (temp.type == 'button') {
													buttonList.push(temp)
												}
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										} else {
											dataFormats['text'] = ticketList.text;
										}
									}
									if (!jsonData.template.selected) {
										var isCarousel = false;
										chatDatas['selected'] = jsonData.human.button_text;
										if (!templateData.templateComponent) {
											templateData["templateComponent"] = jsonData.template.json;
										}
										jsonData.template.json.forEach(carousel => {
											if (carousel.type == 'CAROUSEL') {
												isCarousel = true;
											}
										});
										if (isCarousel) {
											templateData.templateComponent['templateName'] = jsonData.template.name + ' - carousel Template';
											templateData.templateComponent['isCarousel'] = true;
										} else {
											templateData.templateComponent['templateName'] = jsonData.template.name;
										}
										chatDatas['templateComponent'] = templateData;
										const buttonData = templateData.filter((template) => template.type == 'BUTTONS');
										if (buttonData && buttonData.length > 0 && !isCarousel) {
											buttonData[0].buttons.forEach(temp => {
												var dataButton = {
													value: temp.text,
													type: temp.type,
												}
												if (temp.text == jsonData.human.button_text) {
													dataButton['isActive'] = true;
												}
												buttonList.push(dataButton)
											});
											chatDatas['components'] = buttonList;
											dataFormats['text'] = chatDatas;
										}
										if (isCarousel) {
											var components = {
												// value: '',
												// type: 'button',
											}
											buttonList.push(components);
											chatDatas['components'] = buttonList;
											chatDatas['text'] = ticketList.text;
											dataFormats['text'] = chatDatas;
										}
									}

								} else {
									dataFormats['text'] = ticketList.text;
								}

							}

							if (ticketList && ticketList.messenger_id) {
								dataFormats['messenger_id'] = ticketList.messenger_id;
							} else {
								dataFormats['messenger_id'] = ticketList.id;
							}
							if (additionalData && additionalData.clientSiteInfo) {
								dataFormats['siteName'] = additionalData.clientSiteInfo.details?.name;
								dataFormats['clientSiteTextColor'] = additionalData.clientSiteInfo?.color;
								dataFormats['clientSiteColor'] = additionalData.clientSiteInfo.details?.color;
							}
							if (additionalData && additionalData.peopleData.length > 0) {
								dataFormats['peopleName'] = additionalData.peopleData[0]?.name;
								dataFormats['peopleAvatar'] = additionalData.peopleData[0]?.peopleAvatar;
							}
							dataFormat.push(dataFormats);
						});
						if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
							params.current = data.pagnitation.pageCount;
							this.changeParams(params);
						}
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: any) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			this.commonService.getItem(this.url, this.routeParams.id)
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					this.changeAllItemsByItem(data.data);
					this.changeItem(data.data);
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new MessengerModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				// this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	post(data: any, url: string = null) {
		let postUrl = (url) ? url : this.url;
		let showAlert: boolean = (data && data.showAlert) ? data.showAlert : false;
		this.commonService.replayChat(postUrl, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.loaderService.changeAdSendMsgLoader(false);
				if (data.success) {
					var ticketData = data.data;
					if (ticketData && ticketData.length > 0) {
						ticketData.forEach(ticketData => {
							var jsonData = JSON.parse(ticketData.json);
							var dataFormats = {
								id: ticketData.id,
								ticketId: ticketData.messenger_id,
								reply_id: ticketData.reply_id,
								status: ticketData.status,
								sms_message_sid: ticketData.sms_message_sid,
								from: ticketData.from,
								to: ticketData.to,
								media: ticketData.media,
								asset_url: ticketData.asset_url ? ticketData.asset_url : null,
								created_by: ticketData.created_by,
								updated_by: ticketData.updated_by,
								created_at: moment(ticketData.created_at + '.000+0300').local(),
								updated_at: ticketData.updated_at,
								people_id: ticketData.people_id,
								parent_id: jsonData.parent_id ? ticketData.id : 'null',
								replyMessageId: ticketData.reply_id,
								createdByName: ticketData?.CreatedByInfo?.MoreInfo?.name,
								replayPersonType: ticketData?.CreatedByInfo?.person_type,
								is_itrs: ticketData.is_itrs ? true : false,
								location_url: ticketData.locationurl,
								client_id: ticketData.client_id,
								client_site_id: ticketData.client_site_id,
								account_sid: ticketData.account_sid,
								isAssetData: false,
							}

							if (ticketData["TicketInfo"] && ticketData["TicketInfo"].id && ticketData.people_id) {
								if (ticketData["TicketInfo"].people_id == ticketData.people_id) {
									dataFormats['ticketId'] = ticketData["TicketInfo"].id;
								}
							}

							if (ticketData.UsersInfo && ticketData.UsersInfo.id && ticketData.UsersInfo.avatar_url) {
								dataFormats["user_image"] = ticketData.UsersInfo.avatar_url
							}

							if (ticketData.UsersInfo && ticketData.UsersInfo.id) {
								dataFormats["replayPersonType"] = ticketData.UsersInfo.person_type;
								dataFormats["createdByName"] = ticketData.UsersInfo.username;
							}

							if (ticketData.asset_url && ticketData.asset_url.length > 0) {
								var baseUrl = ticketData.asset_url;
								// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
								// var decodeName = baseUrl.split("/").pop()
								// let encoded = decodeURIComponent(decodeName);
								dataFormats["asset_url"] = baseUrl;
							}

							if (ticketData.media == 'audio' || ticketData.media == 'voice') {
								dataFormats['asset_url'] = ticketData.asset_url ? ticketData.asset_url : null;
							}

							if (ticketData && ticketData.reply_id) {
								dataFormats['text'] = ticketData.text;
								if (ticketData.reply && ticketData.reply.id && ticketData.reply.id == ticketData.reply_id) {
									var jsonData = JSON.parse(ticketData.reply.json);

									if (jsonData && jsonData.interactive) {
										var interactiveData = {
											"to": "",
											"type": "interactive",
											"headerType": null,
											"headerText": null,
											"image": null,
											"video": null,
											"document": null,
											"bodyType": null,
											"footerType": null,
											"footerText": null,
											"buttons": [],
											"lists": []
										};
										var intreactiveStr = [];
										if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
											jsonData.interactive.action.buttons.forEach(interactive => {
												var data = {
													text: interactive.reply.title,
													index: interactive.reply.id
												}
												intreactiveStr.push(data);
												if (interactive.reply.id == dataFormats['text']) {
													dataFormats['text'] = interactive.reply.title;
												}
											});
											interactiveData.buttons = intreactiveStr
										}
										if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
											jsonData.interactive.action.lists.forEach(interactive => {
												var data = {
													text: interactive.reply.title,
													index: interactive.reply.id
												}
												intreactiveStr.push(data);
											});
											interactiveData.lists = intreactiveStr
										}
										if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
											jsonData.interactive.action.sections[0].rows.forEach(interactive => {
												var data = {
													text: interactive.title,
													index: interactive.id
												}
												intreactiveStr.push(data);
												if (interactive.id == dataFormats['text']) {
													dataFormats['text'] = interactive.title;
												}
											});
											interactiveData.lists = intreactiveStr
										}
										interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
										dataFormats['reply']['interactiveData'] = interactiveData;
									} else if (ticketData.reply.media == 'text' || ticketData.media == 'text') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'video' || ticketData.media == 'video') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'image' || ticketData.media == 'image') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'audio' || ticketData.media == 'audio') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'document' || ticketData.media == 'document') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'voice' || ticketData.media == 'voice') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									}

									if (ticketData.reply.media == 'location') {
										dataFormats['reply'] = ticketData.reply;
										dataFormats['reply']['location_url'] = ticketData.reply.locationurl;

										if (ticketData.clientSite && ticketData.clientSite.details) {
											dataFormats['reply']['siteName'] = ticketData.clientSite.details?.name;
										}
									}

									if (jsonData && jsonData.order) {
										dataFormats['reply']['orderData'] = jsonData.order;
									}
								}
							} else if (ticketData && !ticketData.reply_id) {
								if (ticketData.media == 'contacts') {
									dataFormats['text'] = JSON.parse(ticketData.text);
								} else if (ticketData.media == 'document') {
									dataFormats['text'] = null;
								} else if (ticketData.media == 'video') {
									dataFormats['text'] = null;
								} else {
									dataFormats['text'] = ticketData.text;
								}

								if (jsonData && jsonData.interactive) {
									var interactiveData = {
										"to": "",
										"type": "interactive",
										"headerType": null,
										"headerText": null,
										"image": null,
										"video": null,
										"document": null,
										"bodyType": null,
										"footerType": null,
										"footerText": null,
										"buttons": [],
										"lists": []
									};
									var intreactiveStr = [];
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
										jsonData.interactive.action.buttons.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.buttons = intreactiveStr
									}
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
										jsonData.interactive.action.lists.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.lists = intreactiveStr
									}
									if (jsonData && jsonData.order) {
										dataFormats['orderData'] = jsonData.order;
									}
									interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
									dataFormats['interactiveData'] = interactiveData;
								}
							}
							if (ticketData && ticketData.whatsapp_id) {
								dataFormats['whatsapp_id'] = ticketData.whatsapp_id;
							} else {
								dataFormats['whatsapp_id'] = ticketData.id;
							}
							if (ticketData.clientSiteInfo) {
								dataFormats['siteName'] = ticketData.clientSiteInfo.details?.name;
								dataFormats['clientSiteTextColor'] = ticketData.clientSiteInfo?.color;
								dataFormats['clientSiteColor'] = ticketData.clientSiteInfo.details?.color;
							}
							if (ticketData.peopleData.id) {
								dataFormats['peopleName'] = ticketData.peopleData.name;
								dataFormats['peopleAvatar'] = ticketData?.peopleData?.peopleAvatar;
							}
							var statusData = [];
							if (ticketData && ticketData.ticketStatus) {
								ticketData.ticketStatus.forEach(ticketStatus => {
									if (ticketStatus.model_id == ticketData.id) {
										statusData.push(ticketStatus);
									}
								});
								if (statusData && statusData.length > 0) {
									let lastStatusData = statusData.pop();
									dataFormats["statusName"] = lastStatusData.name;
									dataFormats["className"] = lastStatusData.model_type;
								}
							}

							this.concatItem(dataFormats);
							this.changeItem(dataFormats);
							if (showAlert) { this.alert('Success', 'Message Sent Successfully'); }
						});
					} else {
						if (ticketData && ticketData.id && ticketData.id) {
							var jsonData = JSON.parse(ticketData.json);
							var dataFormats = {
								id: ticketData.id,
								ticketId: ticketData.messenger_id,
								reply_id: ticketData.reply_id,
								status: ticketData.status,
								sms_message_sid: ticketData.sms_message_sid,
								from: ticketData.from,
								to: ticketData.to,
								media: ticketData.media,
								asset_url: ticketData.asset_url ? ticketData.asset_url : null,
								created_by: ticketData.created_by,
								updated_by: ticketData.updated_by,
								created_at: moment(ticketData.created_at + '.000+0300').local(),
								updated_at: ticketData.updated_at,
								people_id: ticketData.people_id,
								parent_id: jsonData.parent_id ? ticketData.id : 'null',
								replyMessageId: ticketData.reply_id,
								createdByName: ticketData?.CreatedByInfo?.MoreInfo?.name,
								replayPersonType: ticketData?.CreatedByInfo?.person_type,
								is_itrs: ticketData.is_itrs ? true : false,
								location_url: ticketData.locationurl,
								client_id: ticketData.client_id,
								client_site_id: ticketData.client_site_id,
								account_sid: ticketData.account_sid,
								isAssetData: false,
							}

							if (ticketData["TicketInfo"] && ticketData["TicketInfo"].id && ticketData.people_id) {
								if (ticketData["TicketInfo"].people_id == ticketData.people_id) {
									dataFormats['ticketId'] = ticketData["TicketInfo"].id;
								}
							}

							if (ticketData.UsersInfo && ticketData.UsersInfo.id && ticketData.UsersInfo.avatar_url) {
								dataFormats["user_image"] = ticketData.UsersInfo.avatar_url
							}

							if (ticketData.UsersInfo && ticketData.UsersInfo.id) {
								dataFormats["replayPersonType"] = ticketData.UsersInfo.person_type;
								dataFormats["createdByName"] = ticketData.UsersInfo.username;
							}

							if (ticketData.asset_url && ticketData.asset_url.length > 0) {
								var baseUrl = ticketData.asset_url;
								// var afterWith = baseUrl.substr(0, baseUrl.lastIndexOf("/") + 1);
								// var decodeName = baseUrl.split("/").pop()
								// let encoded = decodeURIComponent(decodeName);
								dataFormats["asset_url"] = baseUrl;
							}

							if (ticketData && ticketData.reply_id) {
								dataFormats['text'] = ticketData.text;
								if (ticketData.reply && ticketData.reply.id && ticketData.reply.id == ticketData.reply_id) {
									var jsonData = JSON.parse(ticketData.reply.json);

									if (jsonData && jsonData.interactive) {
										var interactiveData = {
											"to": "",
											"type": "interactive",
											"headerType": null,
											"headerText": null,
											"image": null,
											"video": null,
											"document": null,
											"bodyType": null,
											"footerType": null,
											"footerText": null,
											"buttons": [],
											"lists": []
										};
										var intreactiveStr = [];
										if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
											jsonData.interactive.action.buttons.forEach(interactive => {
												var data = {
													text: interactive.reply.title,
													index: interactive.reply.id
												}
												intreactiveStr.push(data);
												if (interactive.reply.id == dataFormats['text']) {
													dataFormats['text'] = interactive.reply.title;
												}
											});
											interactiveData.buttons = intreactiveStr
										}
										if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
											jsonData.interactive.action.lists.forEach(interactive => {
												var data = {
													text: interactive.reply.title,
													index: interactive.reply.id
												}
												intreactiveStr.push(data);
											});
											interactiveData.lists = intreactiveStr
										}
										if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.sections && jsonData.interactive.action.sections && jsonData.interactive.action.sections[0].rows.length > 0) {
											jsonData.interactive.action.sections[0].rows.forEach(interactive => {
												var data = {
													text: interactive.title,
													index: interactive.id
												}
												intreactiveStr.push(data);
												if (interactive.id == dataFormats['text']) {
													dataFormats['text'] = interactive.title;
												}
											});
											interactiveData.lists = intreactiveStr
										}
										interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
										dataFormats['reply']['interactiveData'] = interactiveData;
									} else if (ticketData.reply.media == 'text' || ticketData.media == 'text') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'video' || ticketData.media == 'video') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'image' || ticketData.media == 'image') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'audio' || ticketData.media == 'audio') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'document' || ticketData.media == 'document') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									} else if (ticketData.reply.media == 'voice' || ticketData.media == 'voice') {
										dataFormats['reply'] = ticketData.reply;
										if (ticketData.clientSiteInfo) {
											dataFormats['reply']['siteName'] = ticketData.clientSiteInfo.details?.name;
										}
									}
									if (jsonData && jsonData.order) {
										dataFormats['reply']['orderData'] = jsonData.order;
									}
								}
							} else if (ticketData && !ticketData.reply_id) {
								if (ticketData.media == 'contacts') {
									dataFormats['text'] = JSON.parse(ticketData.text);
								} else if (ticketData.media == 'document') {
									dataFormats['text'] = null;
								} else if (ticketData.media == 'video') {
									dataFormats['text'] = null;
								} else {
									dataFormats['text'] = ticketData.text;
								}

								if (jsonData && jsonData.interactive) {
									var interactiveData = {
										"to": "",
										"type": "interactive",
										"headerType": null,
										"headerText": null,
										"image": null,
										"video": null,
										"document": null,
										"bodyType": null,
										"footerType": null,
										"footerText": null,
										"buttons": [],
										"lists": []
									};
									var intreactiveStr = [];
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.buttons && jsonData.interactive.action.buttons.length > 0) {
										jsonData.interactive.action.buttons.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.buttons = intreactiveStr
									}
									if (jsonData && jsonData.interactive && jsonData.interactive.action && jsonData.interactive.action.lists && jsonData.interactive.action.lists.length > 0) {
										jsonData.interactive.action.lists.forEach(interactive => {
											var data = {
												text: interactive.reply.title,
												index: interactive.reply.id
											}
											intreactiveStr.push(data);
										});
										interactiveData.lists = intreactiveStr
									}
									if (jsonData && jsonData.order) {
										dataFormats['orderData'] = jsonData.order;
									}
									interactiveData['bodyText'] = jsonData.interactive.body ? jsonData.interactive.body.text : null;
									dataFormats['interactiveData'] = interactiveData;
								}
							}
							if (ticketData && ticketData.whatsapp_id) {
								dataFormats['whatsapp_id'] = ticketData.whatsapp_id;
							} else {
								dataFormats['whatsapp_id'] = ticketData.id;
							}
							if (ticketData.clientSiteInfo) {
								dataFormats['siteName'] = ticketData.clientSiteInfo.details?.name;
								dataFormats['clientSiteTextColor'] = ticketData.clientSiteInfo?.color;
								dataFormats['clientSiteColor'] = ticketData.clientSiteInfo.details?.color;
							}
							if (ticketData.peopleData.id) {
								dataFormats['peopleName'] = ticketData.peopleData.name;
								dataFormats['peopleAvatar'] = ticketData?.peopleData?.peopleAvatar;
							}
							var statusData = [];
							if (ticketData && ticketData.ticketStatus) {
								ticketData.ticketStatus.forEach(ticketStatus => {
									if (ticketStatus.model_id == ticketData.id) {
										statusData.push(ticketStatus);
									}
								});
								if (statusData && statusData.length > 0) {
									let lastStatusData = statusData.pop();
									dataFormats["statusName"] = lastStatusData.name;
									dataFormats["className"] = lastStatusData.model_type;
								}
							}

							this.concatItem(dataFormats);
							this.changeItem(dataFormats);
							if (showAlert) { this.alert('Success', 'Message Sent Successfully'); }
						}
					}
				} else {
					this.alertService.webErrorShow(data);
					this.loaderService.changeAdSendMsgLoader(false);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.loaderService.changeAdSendMsgLoader(false);
					// this.alert('Danger', 'Server Busy. Try after Sometimes !!!');
				}
			);
	}

	update(id: number, data: any) {
		this.commonService.updateItem(this.url, id, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.changeAllItemsByItem(data.data);
				this.changeItem(data.data);
				// this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
				this.spliceItem(id);
				this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	reDownloads(downloadData: any, url: string = null) {
		this.commonService.storeItem(url, downloadData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var allData = [];
					this.allItems.subscribe(assert => allData = assert);
					if (allData && allData.length > 0) {
						allData.forEach(assets => {
							if (assets.id == data.data.chatId) {
								assets.asset_url = data.data.asset_url;
								assets.isAssetData = false;
							}
						});
						this.allItemsSource.next(allData);
						this.onChangeAllItems.emit(allData);
					}
				} else {
					this.loaderService.changeAdSendMsgLoader(false);
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.loaderService.changeAdSendMsgLoader(false);
					this.alert('Danger', 'Server Busy. Try after Sometimes !!!');
				}
			);
	}

}
