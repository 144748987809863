import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { People } from './people.interface';
import { PeopleModel } from './people_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';
import { ClientSiteService, ClientSite } from 'src/app/@tji/_dbShare';
import { OrderByPipe } from 'ngx-pipes';
import { DatePipe } from '@angular/common'

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class PeopleService implements Resolve<any> {
	url: string = 'peoplen';
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'search': '',
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<People[]>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<People[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<People>(new PeopleModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<People[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private checkLeadReserveSource = new BehaviorSubject<boolean>(false);
	checkLead = this.checkLeadReserveSource.asObservable();

	public isfilterLoader = new BehaviorSubject<boolean>(false);
	filterLoader = this.isfilterLoader.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar,
		public datepipe: DatePipe,
		private orderByPipe: OrderByPipe,) {
		this._unsubscribeAll = new Subject();
		// this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.clientSites = this.orderByPipe.transform(data, '-id');
		// });
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed PeopleService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on PeopleService');
	}

	concatlibrary(allItems: People[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: People) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: People) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: People[]) {
		this.librariesSource.next(libraries);
	}

	changeCheckLeadReserve(lead: boolean) {
		this.checkLeadReserveSource.next(lead);
	}

	changeAllItems(allItems: People[]) {
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: any) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: People[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: any) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) { allItems.splice(i, 1, item); }
			}
		}
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'search': '',
			'with': 'detail',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var peopleData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (peopleData && peopleData.length > 0) {
						peopleData.forEach(people => {
							var dataFormats = {
								"id": people.id,
								"code": people.code,
								"name": people.name,
								"unique_ref": people.unique_ref,
								"client_id": people.client_id,
								"created_by": people.created_by,
								"updated_by": people.updated_by,
								"created_at": moment(moment(people.updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), //moment(locals).format('YYYY-MM-DD HH:mm:ss'), //moment(people.created_at).toDate(), //this.datepipe.transform(people.created_at * 1000, 'yyyy-MM-dd hh:mm'),//  format(new Date(), "YYYY-MM-DD HH:mm:ss"),// moment(people.created_at + '.000+0300').local(),
								"updated_at": moment(moment(people.updated_at).toDate()).format('YYYY-MM-DD HH:mm:ss'), //moment(update).format('YYYY-MM-DD HH:mm:ss'), //this.datepipe.transform(people.updated_at * 1000, 'yyyy-MM-dd hh:mm'), // moment(people.updated_at + '.000+0300').local(),
								"client_site_id": people.client_site_id,
								"whatsappNumber": people.unique_ref ? people.unique_ref : null,
								"avatar_url" : people.peopleAvatar?people.peopleAvatar:'assets/img/no-user.jpg'
							}

							if (people.labeldata) {
								dataFormats["labelName"] = people.labeldata.name;
								dataFormats["labelColor"] = people.labeldata.color;
								dataFormats["labelColorText"] = people.labeldata.color_text;
								dataFormats["labelId"] = people.labeldata.label_id;
							}

							if (people.clientSiteInfo) {
								dataFormats["icon"] = people.clientSiteInfo.details.icon;
								dataFormats["siteName"] = people.clientSiteInfo.details.name;
								dataFormats["accountName"] = people.clientSiteInfo.account_name;
								dataFormats["clientSiteName"] = people.clientSiteInfo.account_name;
								dataFormats["clientSiteColor"] = people.clientSiteInfo.color;
								dataFormats["clientSiteTextColor"] = people.clientSiteInfo.textcolor;
								dataFormats["siteId"] = people.clientSiteInfo.site_id;
								dataFormats["site"] = people.clientSiteInfo.details.slug;
								dataFormats["is_active"] = people.clientSiteInfo.is_active ? true : false;
								dataFormats["deleted_at"] = people.clientSiteInfo.deleted_at?people.clientSiteInfo.deleted_at:null;
							}

							if (people.ReserveInfo) {
								dataFormats["reserveAgentId"] = people.ReserveInfo.agent_id;
							}

							if (people.ReserveInfo && people.ReserveInfo.AgentINfo) {
								dataFormats["reserveAgentName"] = people.ReserveInfo.AgentINfo.name;
							}

							if (people.ReserveInfo && people.ReserveInfo.AgentINfo && people.ReserveInfo.AgentINfo.person_details) {
								dataFormats["agent_avatar_url"] = people.ReserveInfo.AgentINfo.person_details.image_api;
							} else {
								dataFormats["agent_avatar_url"] = 'assets/img/no-user.jpg';
							}

							if (people.DetailsInfo) {
								var requiredInput = ["address", "mobile", "company_name", "image_api", "business_brand_name", "business_category", "country", "email_verified_at", "email"];
								dataFormats['detail'] = people.DetailsInfo;
								dataFormats['detail']['requiredInputs'] = requiredInput;
							} else {
								dataFormats["detail"] = null;
							}

							dataFormat.push(dataFormats);
						});
						this.isfilterLoader.next(false);
						this.changeAllItems(dataFormat);
						this.changeTotalItem(data.pagnitation.totalResult);

					} else {
						this.isfilterLoader.next(false);
						this.changeAllItems([]);
						this.changeTotalItem(data.pagnitation.totalResult);
					}
				}
			},
				error =>{ 
					this.isfilterLoader.next(false);
					console.log('Error ::' + error); 
				}
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var peopleData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (peopleData && peopleData.length > 0) {
						peopleData.forEach(people => {
							var dataFormats = {
								"id": people.id,
								"code": people.code,
								"name": people.name,
								"unique_ref": people.unique_ref,
								"client_id": people.client_id,
								"created_by": people.created_by,
								"updated_by": people.updated_by,
								"created_at": this.datepipe.transform(people.created_at * 1000, 'yyyy-MM-dd hh:mm'),//  format(new Date(), "YYYY-MM-DD HH:mm:ss"),// moment(people.created_at + '.000+0300').local(),
								"updated_at": this.datepipe.transform(people.updated_at * 1000, 'yyyy-MM-dd hh:mm'), // moment(people.updated_at + '.000+0300').local(),
								"client_site_id": people.client_site_id,
								"whatsappNumber": people.unique_ref ? people.unique_ref : null,
								"avatar_url" : people.peopleAvatar?people.peopleAvatar:'assets/img/no-user.jpg',
							}

							if (people.labeldata) {
								dataFormats["labelName"] = people.labeldata.name;
								dataFormats["labelColor"] = people.labeldata.color;
								dataFormats["labelColorText"] = people.labeldata.color_text;
								dataFormats["labelId"] = people.labeldata.label_id;
							}

							if (people.clientSiteInfo) {
								dataFormats["icon"] = people.clientSiteInfo.details.icon;
								dataFormats["siteName"] = people.clientSiteInfo.details.name;
								dataFormats["accountName"] = people.clientSiteInfo.account_name;
								dataFormats["clientSiteName"] = people.clientSiteInfo.account_name;
								dataFormats["clientSiteColor"] = people.clientSiteInfo.color;
								dataFormats["clientSiteTextColor"] = people.clientSiteInfo.textcolor;
								dataFormats["is_active"] = people.clientSiteInfo.is_active ? true : false;
								dataFormats["deleted_at"] = people.clientSiteInfo.deleted_at?people.clientSiteInfo.deleted_at:null;
							}

							if (people.ReserveInfo) {
								dataFormats["reserveAgentId"] = people.ReserveInfo.agent_id;
							}

							if (people.ReserveInfo && people.ReserveInfo.AgentINfo) {
								dataFormats["reserveAgentName"] = people.ReserveInfo.AgentINfo.name;
							}

							if (people.ReserveInfo && people.ReserveInfo.AgentINfo && people.ReserveInfo.AgentINfo.person_details) {
								dataFormats["avatar_url"] = people.ReserveInfo.AgentINfo.person_details.image_api;
							} else {
								dataFormats["avatar_url"] = 'assets/img/no-user.jpg';
							}

							if (people.DetailsInfo) {
								var requiredInput = ["address", "mobile", "company_name", "image_api", "business_brand_name", "business_category", "country", "email_verified_at", "email"];
								dataFormats['detail'] = people.DetailsInfo;
								dataFormats['detail']['requiredInputs'] = requiredInput;
							} else {
								dataFormats["detail"] = null;
							}

							dataFormat.push(dataFormats);
						});


						let lists = [];
						if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
							params.current = data.pagnitation.pageCount;
							this.changeParams(params);
						}

						this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
							lists = result.concat(dataFormat);
						});

						this.changeAllItems(lists);

					}
				}

			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: People) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		this.routeParams = (params) ? params : this.routeParams;
		if (this.routeParams && this.routeParams.id > 0) {
			var peopleData = {
				people_id: params.id,
			}
			this.commonService.storeItem("peoplen/view", peopleData, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success) {
						if (data.data && data.data.id > 0) {
							var peopleData = data.data;
							var requiredFiled = [];
							var dataFormats = {
								"id": peopleData.id,
								"code": peopleData.code,
								"name": peopleData.name,
								"unique_ref": peopleData.unique_ref,
								"client_id": peopleData.client_id,
								"created_by": peopleData.created_by,
								"updated_by": peopleData.updated_by,
								"created_at": this.datepipe.transform(peopleData.created_at * 1000, 'yyyy-MM-dd hh:mm'),//moment(peopleData.created_at + '.000+0300').local(),
								"updated_at": this.datepipe.transform(peopleData.created_at * 1000, 'yyyy-MM-dd hh:mm'), //moment(peopleData.updated_at + '.000+0300').local(),
								"isDeleted": false,
								"client_site_id": peopleData.client_site_id,
								"whatsappNumber": peopleData.unique_ref ? peopleData.unique_ref : null,
								"tableName": "people",
								"avatar_url" : peopleData.peopleAvatar?peopleData.peopleAvatar:'assets/img/no-user.jpg',
							}
							if (peopleData.labeldata) {
								dataFormats["labelName"] = peopleData.labeldata.name;
								dataFormats["labelColor"] = peopleData.labeldata.color;
								dataFormats["labelColorText"] = peopleData.labeldata.color_text;
								dataFormats["labelId"] = peopleData.labeldata.label_id;
							}

							if (peopleData.clientSiteInfo) {
								dataFormats["icon"] = peopleData.clientSiteInfo.details.icon;
								dataFormats["siteName"] = peopleData.clientSiteInfo.details.name;
								dataFormats["accountName"] = peopleData.clientSiteInfo.account_name;
								dataFormats["clientSiteName"] = peopleData.clientSiteInfo.account_name;
								dataFormats["clientSiteColor"] = peopleData.clientSiteInfo.color;
								dataFormats["clientSiteTextColor"] = peopleData.clientSiteInfo.textcolor;
								dataFormats["is_active"] = peopleData.clientSiteInfo.is_active ? true : false;
								dataFormats["deleted_at"] = peopleData.clientSiteInfo.deleted_at?peopleData.clientSiteInfo.deleted_at:null;
							}

							if (peopleData.ReserveInfo) {
								dataFormats["reserveAgentId"] = peopleData.ReserveInfo.agent_id;
							}

							if (peopleData.ReserveInfo && peopleData.ReserveInfo.AgentINfo) {
								dataFormats["reserveAgentName"] = peopleData.ReserveInfo.AgentINfo.name;
							}

							if (peopleData.ReserveInfo && peopleData.ReserveInfo.AgentINfo && peopleData.ReserveInfo.AgentINfo.person_details) {
								dataFormats["avatar_url"] = peopleData.ReserveInfo.AgentINfo.person_details.image_api;
							}

							if (peopleData.peopleFields && peopleData.peopleFields.length > 0) {
								peopleData.peopleFields.forEach(peopleFields => {

									var filedFormat = {
										"inputType": "inputType",
										"type": peopleFields.type,
										"name": peopleFields.slug, //peopleFields.displayname_en.replace(/ +/g, ""),
										"label": peopleFields.displayname_en,
										"value": "",
										"validations": [],
										"slug": peopleFields.slug,
									}
									if (peopleFields.type.toLowerCase() == 'text' || peopleFields.type == 'email' || peopleFields.type == 'password') {
										filedFormat.type = 'input'
									}
									if (peopleFields.type == 'radio') {
										filedFormat.type = 'radiobutton'
										delete filedFormat["inputType"]
										filedFormat["options"] = [];
										peopleFields.options.forEach(element => {
											filedFormat["options"].push(element.name)
										});
									}
									if (peopleFields.validation && peopleFields.validation.length > 0) {
										// peopleFields.validation.forEach(validation => {
										filedFormat["validations"].push({
											"name": "required",
											"validator": "Validators.required",
											"message": "Token Required"
										})
										// });

									} else {
										delete filedFormat["validations"]
									}
									requiredFiled.push(filedFormat)

								});
								// var submit = {
								// 	type: 'button',
								// 	label: 'Authorization',
								// }
								// requiredFiled.push(submit);

							}

							if (peopleData.DetailsInfo) {

								dataFormats["personalInfo"] = peopleData.DetailsInfo;
								var requiredInput = ["address", "mobile", "company_name", "image_api", "business_brand_name", "business_category", "country", "authority_email", "email_verified_at", "email"];
								dataFormats['detail'] = peopleData.DetailsInfo;
								Object.entries(peopleData.DetailsInfo).forEach(([key, value]) => {
									requiredFiled.forEach(filed => {
										if (key == filed.slug) {
											filed["value"] = value;
											if (filed.type == 'date' && filed["value"] && !isNaN(filed["value"])) {
												if (this.datepipe.transform(new Date(filed["value"]), 'yyyy-MM-dd')) {
													filed["value"] = this.datepipe.transform(new Date(filed["value"]), 'yyyy-MM-dd');
												}else{
													filed["value"] = new Date(filed["value"]);
												}
											}
										}
									});
								});

								dataFormats["detail"] = requiredFiled;

							} else {
								dataFormats["detail"] = requiredFiled;
								peopleData["personalInfo"] = null;
							}
							this.changeAllItemsByItem(dataFormats);
							this.changeItem(dataFormats);
						}
					} else {
						this.alertService.webErrorShow(data);
					}
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new PeopleModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem('label/modelhaslabel', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.getAllItems();
					this.alert('Success', 'updated Successfully !!!');
				}else{
					this.alertService.webErrorShow(data);
				}
				
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	update(id: number, people: any) {
		var peopleData = {
			"people_id": id,
			"fullname": people.name,
			"email": people.email,
			"mobile": people.mobile,
			"designation": people.designation,
			"address": people.address
		}
		this.commonService.storeItem('peoplen/addPeopleDetails', peopleData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					let peopleItems;
					this.item.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => peopleItems = data);
					if (peopleItems && peopleItems.id == peopleData.people_id) {
						// peopleItems["name"] = peopleData.fullname;
						if (peopleItems["personalInfo"]) {
							peopleItems["personalInfo"]["fullname"] = peopleData.fullname;
							peopleItems["personalInfo"]["mobile"] = peopleData.mobile;
							peopleItems["personalInfo"]["designation"] = peopleData.designation;
							peopleItems["personalInfo"]["address"] = peopleData.address;
							peopleItems["personalInfo"]["authority_email"] = peopleData.email;
						} else {
							var peopleUpdate = {
								"fullname": peopleData.fullname,
								"mobile": peopleData.mobile,
								"designation": peopleData.designation,
								"address": peopleData.address,
								"email": peopleData.email,
							}
							peopleItems["personalInfo"] = peopleUpdate
						}

					}
					this.changeAllItemsByItem(peopleItems);
					this.changeItem(peopleItems);

					this.alert('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getItem({ "id": id });
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}


	updateName(id: number, peopleData: any) {
		this.commonService.storeItem('peoplen/addPeopleDetails', peopleData, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					let allItems: Array<any> = [];
					
					this.allItems.subscribe(data => {
						allItems = data;
						if (allItems && allItems.length > 0) {
							allItems.forEach(element => {
								if (element.id == id) {
									element["detail"]["fullname"] = peopleData.fullname;
								}
							});
						}
					});

					this.changeAllItemsByItem(allItems);

					this.alert('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	updatePeple(id: number, people: any) {
		this.commonService.storeItem('peoplen/addPeopleDetails', people, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					// this.getItem({"id": id});
					this.alert('Info', 'Updated Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
				this.getItem({ "id": id });
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	destroy(url, people) {
		this.commonService.storeItem(url, people, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.spliceItem(people.id);
					this.alert('Danger', 'Destroyed Successfully !!!');
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	checkLeadReserve(data: any) {
		this.commonService.storeItem('leadreserven/checkLeadReserve', data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data == false) {
						this.changeCheckLeadReserve(false);
					}else{
						this.changeCheckLeadReserve(true);
					}
				}else{
					this.alertService.webErrorShow(data);
				}
				
			},
				error => {
					console.log('Error ::' + error);
					this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
				}
			);
	}

	getExportUrl(type: string, url: string = null) {
		let domain = this.commonService.backendUrl;
		let ch_url = '';

		if (type == 'excel') {
			ch_url = (url) ? domain + url : domain + 'person/people-export/';
		} else if (type == 'pdf') {
			ch_url = (url) ? domain + url : domain + 'person/people-exportpdf/';
		}

		return ch_url;
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}


	export(Dates, client_site_id = null) {

		this.commonService.storeItem(this.url + '/export', Dates, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					if (data.data == 0) {
						this.alert('Danger', 'Datas are not available for selected dates !!!');
					}
					if (data.data && data.data.length > 0) {
						var clientSites = data?.additional?.clientSiteInfo
						var excelprint = data.data;
						let excelData = [];
						if (excelprint && excelprint.length > 0) {
							data.data.forEach(elementKey => {
								var dataIn = {
									"Name": elementKey.name,
									"Date and Time": this.datepipe.transform(elementKey.created_at * 1000, 'yyyy-MM-dd hh:mm'),//moment(elementKey.created_at).format('DD-MM-YYYY'), // moment(elementKey.created_at + '.000+0300').local(),
									"Contact No": elementKey.unique_ref
								}
								if (clientSites && clientSites.length > 0) {
									clientSites.forEach(clientSitesKey => {
										if (client_site_id && clientSitesKey.id == client_site_id) {
											dataIn["Account For"] = clientSitesKey.account_name;
											dataIn["Site Name"] = clientSitesKey.details.name;
										} else {
											if (clientSitesKey.id == elementKey.client_site_id) {
												dataIn["Account For"] = clientSitesKey.account_name;
												dataIn["Site Name"] = clientSitesKey.details.name;
											}
										}
									}); ``
								}


								excelData.push(dataIn);

							});
							this.downloadFile(excelData);
						}

					}
				}

			});

		error => {
			console.log('Error ::' + error);
		}

	}

	// download files

	downloadFile(excelData) {

		let arrHeader = ["Date and Time", "Name", "Site Name", "Account For", "Contact No"];
		let csvData = this.ConvertToCSV(excelData, arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		var currentTime = new Date().toJSON();
		dwldLink.setAttribute("download", 'lead-' + currentTime + '.csv');
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}


	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = 'S.No,';

		let newHeaders = ["Date and Time", "Name", "Site Name", "Account For", "Contact No"];

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			for (let index in headerList) {
				let head = headerList[index];

				line += ',' + this.strRep(array[i][head]);
			}
			str += line + '\r\n';
		}
		return str;
	}

	strRep(data) {
		if (typeof data == "string") {
			let newData = data.replace(/,/g, " ");
			return newData;
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}


}