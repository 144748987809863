import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	WhatsappTicketService, WhatsappTicket,
	AgentService, Agent,
	BoardService, Board,
	EventService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'whatsapp-ticket-reassign-form',
	templateUrl: './reassignForm.component.html',
	styleUrls: ['./reassignForm.component.scss']
})

export class ReassignFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	ticket: WhatsappTicket;
	reassignForm: UntypedFormGroup;

	agents: Agent[] = [];
	agentParam: any;

	action: string;
	priorities: any[] = ['Low', 'Normal', 'Important', 'Very Important'];
	eventData: any;

	constructor(public userService: UserService,private eventService: EventService,
		public whatsappTicketService: WhatsappTicketService,
		public agentService: AgentService,
		public matDialogRef: MatDialogRef<ReassignFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.whatsappTicketService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			this.reassignForm = this.createForm();
		});
		this.agentService.allActiveAgents.pipe(untilDestroyed(this)).subscribe(data => this.agents = data);
		this.agentService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentParam = data);
		this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
	}

	ngOnInit() {
		this.getAgents();
	}

	/** On destroy */
	ngOnDestroy(): void { 
	this.agentService.unSubscribeFilter();
	}

	getAgents() {
		// this.agentParam.all = 1;
		// console.log(this.ticket);
		this.agentService.getActiveAgent(this.ticket.clientSiteId);
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Assign To';
				break;
			case 'edit':
				this.dialogTitle = 'Re-assign To';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			agent_id: [this.ticket.agent_id || null, [Validators.required]],
			priority: [this.ticket.priority || 'Low', [Validators.required]],
			ticket_id: [this.ticket.id, [Validators.required]],
			client_site_id: [this.ticket.clientSiteId, [Validators.required]],
		});
	}

	changeBoard(boardId) { }

	checkIsAuthAgent(agentId): boolean {
		if(this.authUser && this.authUser.person_id && this.authUser.person_type 
			&& this.authUser.person_type === 'agent' && this.authUser.person_id === agentId) {
			return true;
		} else {
			return false;
		}
	}
	checkEventData() {
		let eventName = (this.eventData && this.eventData.event) ? this.eventData.event : null;
		if (eventName) {
			let eventData = this.eventData;
			let data = this.eventData.data;
			switch (eventName) {
				case 'Login': case 'login':
					let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				case 'logout': case 'Logout':
					let auths = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (auths.isOnline && auths.userInfo && auths.userInfo.id == this.authUser.id) {
						this.matDialogRef.close();
					}
					break;
				default:
					break;
			}
		}
	}

}
