import { Component, OnInit, OnDestroy  } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserService } from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit, OnDestroy {

  authUser: User;
  isAuth: boolean = true;
  activeSection: string = 'home';
  user: User;
  url: string = "https://stage.ai-octopus.com/app-chat";
  urlSafe: SafeResourceUrl;

  constructor(public userService: UserService,
              private router: Router,
              public sanitizer: DomSanitizer) {
      this.isAuth = this.userService.authCheck();
      this.authUser = this.userService.authUser();
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  renewUser() {
    if(this.authUser && this.authUser.id) {
      var token = this.authUser.token;
      if(token) {
        this.userService.setCookieToken(token);
      }
      var roleNames = this.authUser.roleNames;
      if(roleNames && roleNames.length > 0) {
        this.userService.setCookieRoles(roleNames);
      }
      var permissionNames = this.authUser.permissionNames;
      if(permissionNames && permissionNames.length > 0) {
        this.userService.setCookiePermissions(permissionNames);
      }
    }
    this.user = JSON.parse(localStorage.getItem('tji_user'));
    this.userService.renewUser();
  }
}
