import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';
import * as moment from 'moment-timezone';
import {
	User, UserService, CommonService,
	LeadsHistoryService, LeadsHistory,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'person-ticket-leads-view',
	templateUrl: './leads-view.component.html',
	styleUrls: ['./leads-view.component.scss']
})

export class LeadsViewComponent implements OnInit, OnDestroy {
	routeParam: string = null;
	loader: boolean = true;
	baseImage: string = this.commonService.logo_image;
	authUser: User;
	leadsHistory: LeadsHistory;
	tickets: Array<any> = [];
	param: any;
	dataLength: number = 0;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	constructor(public userService: UserService,
		public leadsHistoryService: LeadsHistoryService,
		public commonService: CommonService,
		public location: Location,
		private router: Router,
		private route: ActivatedRoute,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.leadsHistoryService.history.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data['rows'] && data['rows'].length > 0) {
				this.leadsHistory = data['rows'][0]['usersInfo'];
			}
		});
		this.leadsHistoryService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data['rows'] && data['rows'].length > 0) {
				data['rows'].forEach(ticket => {
					ticket['comments'] = [];
					ticket['modal_has_flags'] = [];
					if (data && data['CommentsInfo']) {
						data['CommentsInfo'].forEach(comment => {
							if (ticket.id === comment.resource_id) {
								ticket['comments'].push(comment);
							}
						});
					}
					if (data && data['FlagMapInfo']) {
						data['FlagMapInfo'].forEach(flags => {
							if (ticket.id === flags.resource_id) {
								ticket['modal_has_flags'].push(flags);
							}
						});
					}
					ticket.created_at =  moment(ticket.created_at+'.000+0300').local();
					ticket.updated_at =  moment(ticket.updated_at+'.000+0300').local();
				});
				this.tickets = data['rows'];
			}
		});
		this.leadsHistoryService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.leadsHistoryService.unSubscribeFilter();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.leadsHistoryService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	getTitle(people) {
		if (people) {
			if (this.leadsHistory && this.leadsHistory.people && this.leadsHistory.people.id) {
				return people.code + ' | ' + people.name + ' [' + people.unique_ref + ']';
			} else { return 'Leads History'; }
		} else { return 'Leads History'; }

	}

}
