export class UserModel {
    id: number;
    username: string;
    mobile: number;
    email: string;
    password: string;
    person_id: number;
    person_type: string;
    is_sysAdmin: boolean;
    enable_sound: boolean;
    is_web_notify: boolean;
    is_desktop_notify: boolean;
    token?: string;
    email_verified_at?: Date;
    isOnline?: boolean;
    default_lang?: string;

    tableName?: string;
    roleNames: Array<any>;
    permissionNames: Array<any>;

    roles: Array<any>;
    permissions: Array<any>;
    person?: any;
    avatar?: string;
    isDefaultShowAutoMsg?: boolean;
    isDefaultShowHistory?: boolean;
    clientId?: number;
    resellerId?: number;
    displayName?: string;
    avatar_url?: string;
    profileCompletePercentage?: number;
    availability: any;

    /**
     * user
     *
     * @param user
     */
    constructor(user) {
        this.id = user.id || '';
        this.username = user.username || '';
        this.email = user.email || '';
        this.mobile = user.mobile || '';
        this.password = user.password || '';
        this.person_id = user.person_id || '';
        this.person_type = user.person_type || '';
        this.is_sysAdmin = user.is_sysAdmin || false;
        this.enable_sound = user.enable_sound || true;
        this.isDefaultShowAutoMsg = user.isDefaultShowAutoMsg || false;
        this.isDefaultShowHistory = user.isDefaultShowHistory || false;
        this.email_verified_at = user.email_verified_at || null;
        this.default_lang = user.default_lang || 'en';

        this.roles = user.roles || [];
        this.permissions = user.permissions || [];
        this.roleNames = user.roleNames || [];
        this.permissionNames = user.permissionNames || [];
        this.person = user.person || {};
        this.avatar = user.avatar || '';
        this.profileCompletePercentage = user.profileCompletePercentage || 0;
        this.availability = user.availability || 0;
    }
}
