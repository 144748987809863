import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'initiate-message-form',
	templateUrl: './initiateMessageForm.component.html',
	styleUrls: ['./initiateMessageForm.component.scss']
})

export class InitiateMessageFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	to: any = null;
	initiateMessage: any = null;
	action: string;
	clientId: number = null;
	initiateMessageForm: UntypedFormGroup;

	constructor(public userService: UserService,
		public matDialogRef: MatDialogRef<InitiateMessageFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientId = this.userService.getAuthClientId();
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		this.to = (data && data.to) ? data.to : null;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'New Message';
				break;
			case 'edit':
				this.dialogTitle = 'Edit Message';
				break;
			default:
				break;
		}
		this.initiateMessageForm = this.createForm();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			client_id: [this.userService.getAuthClientId() || this.clientId],
			to: [this.to || null, [Validators.required, Validators.minLength(1)]],
			message: [null, [Validators.required, Validators.minLength(1)]],
		});
	}

}
