import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';

import {
	AgentService,
	AicollectionService,
	AlertService,
	ClientService,
	Flag,
	FlagService,
	LabelService,
	PaymentsService,
	PeopleService,
	ReservePeopleService,
	User, UserService,
	WhatsappTicketService,
} from 'src/app/@tji/_dbShare';

import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment-timezone';

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

@UntilDestroy()
@Component({
	selector: 'filter-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [DatePipe]
})

export class SidebarComponent {
	@Output() onClose = new EventEmitter();
	@Input() action: string;
	userType = JSON.parse(localStorage.getItem('tji_user'));
	status: any;
	userList: any;
	param: any;
	selectedStatus: any;
	selectedAgent: any;
	search: string = "";


	myControl = new FormControl<any>('');
	filteredOptions: Observable<string[]>;

	campaignOne: UntypedFormGroup;
	fromDate: Date;
	toDate: Date;
	today: Date = new Date();
	minDate: Date = new Date(2021, 0, 1);
	maxDate: Date = new Date();
	range = new FormGroup({
		start: new FormControl<Date | null>(null),
		end: new FormControl<Date | null>(null),
	});

	constructor(private _formBuilder: UntypedFormBuilder,
		private router: Router,
		public aicollectionService: AicollectionService,
		public paymentsService: PaymentsService,
		public clientService: ClientService,
		private alertService: AlertService,
		public userService: UserService,
		public matDialog: MatDialog) {
		this.aicollectionService.statusInit.pipe(untilDestroyed(this)).subscribe(data => {
			this.status = data;
		});
		this.paymentsService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.clientService.allUserItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.userList = data;
			if (data && data.length > 0) {
				this.getLabelItems();
			}
		});
		// this.getDates();
	}

	ngOnInit() {
	}

	/** On destroy */
	ngOnDestroy(): void {
	}

	getLabelItems() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.username;
				return name ? this._filter(name as string) : this.userList.slice();
			}),
		);

	}

	displayFn(user: any = null): string {
		return user && user.username ? user.username : '';
	}

	private _filter(name: string): User[] {
		const filterValue = name.toLowerCase();

		return this.userList.filter(option => option.username.toLowerCase().includes(filterValue));
	}

	onChangeToDate(event) {
		this.paymentsService.isfilterLoader.next(true);
		this.paymentsService.changeAllItems([]);
		this.param["to"] = moment(this.range.value['end']).format('YYYY-MM-DD');
		this.param["from"] = moment(this.range.value['start']).format('YYYY-MM-DD');
		this.param.current = 1,
		this.param.limit = 25,
		this.paymentsService.getFilterAllItems();
	}

	selectStatus(value: any) {
		this.paymentsService.isfilterLoader.next(true);
		this.paymentsService.changeAllItems([]);
		if (value == null) {
			this.param["type"] = '';
			this.param.current = 1,
			this.param.limit = 25,
			this.selectedStatus = null;
			this.selectedAgent = null;
			this.search = '';
			this.paymentsService.getFilterAllItems();
		} else {
			this.selectedStatus = value;
			this.param["type"] = value;
			this.param.current = 1,
			this.param.limit = 25,
			this.paymentsService.getFilterAllItems();
		}
	}

	selectedAgents(value: any) {
		this.paymentsService.isfilterLoader.next(true);
		this.paymentsService.changeAllItems([]);
		if (value == null) {
			this.param["user_id"] = '';
			this.param.current = 1,
			this.param.limit = 25,
			this.selectedAgent = null;
			this.paymentsService.getFilterAllItems();
		} else {
			this.selectedAgent = value;
			this.param["user_id"] = value;
			this.param.current = 1,
			this.param.limit = 25,
			this.paymentsService.getFilterAllItems();
		}
	}

	searchData() {
		this.paymentsService.isfilterLoader.next(true);
		this.param["search"] = this.search
		this.paymentsService.getFilterAllItems();
	}

	searchClear() {
		this.paymentsService.isfilterLoader.next(true);
		this.search = "";
		this.param["search"] = '';
		this.paymentsService.getFilterAllItems();
	}
	paymentExport() {
		var payload = {
			ordertype: "order",
		}
		if (!this.param["from"]) {
			this.alertService.webShow('Danger', 'Please Select Date !!!');
			return;
		}
		if (!this.param["to"]) {
			this.alertService.webShow('Danger', 'Please Select Date !!!');
			return;
		}
		payload["to"] = moment(this.param["to"]).format('YYYY-MM-DD');
		payload["from"] = moment(this.param["from"]).format('YYYY-MM-DD');
		if (this.param["type"]) { payload["type"] = this.param["type"]; }
		if (this.param["user_id"]) { payload["user_id"] = this.param["user_id"]; }
		if (this.param["search"]) { payload["search"] = this.param["search"]; }
		this.paymentsService.isfilterLoader.next(true);
		this.paymentsService.export(payload);
	}

	reset() {
		this.userService.isSearchSource.next(false);
		this.paymentsService.isfilterLoader.next(true);
		this.param["from"] = '';
		this.param["to"] = '';
		this.param["type"] = '';
		this.param["user_id"] = '';
		this.param["search"] = '';
		this.param.current = 1,
		this.param.limit = 25,
		this.selectedStatus = null;
		this.selectedAgent = null;
		this.search = '';
		this.range = new FormGroup({
			start: new FormControl<Date | null>(null),
			end: new FormControl<Date | null>(null),
		});
		this.myControl.reset()
		this.paymentsService.getFilterAllItems();
		this.userService.isSearchSource.next(true);
	}
}
