import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { AssignFormComponent } from './../assignForm/assignForm.component';

import { User, UserService,
	FindChatService, ChatListsService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'find-conversation-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	loader: boolean = true;
	authUser: User;
	url: string = this.findChatService.url;
	feeds: Array<any> = [];
	selectedFind: any = null
	param: any;
	chatListparam: any;
	dataLength: number = 0;
	channel: any;

	constructor(public userService: UserService,
		public findChatService: FindChatService,
		public chatListsService: ChatListsService,
		private router: Router,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.findChatService.item.pipe(untilDestroyed(this)).subscribe(data => this.selectedFind = data);
		this.findChatService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.feeds = this.orderByPipe.transform(data, '-id');
		});
	}

	ngOnInit() {
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.findChatService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	dataInit() {
		this.findChatService.params.pipe(untilDestroyed(this)).subscribe(data => this.param = data);
		this.findChatService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
		this.chatListsService.params.pipe(untilDestroyed(this)).subscribe(data => this.chatListparam = data);
	}

	getChatLists(selectedFind: any) {
		this.findChatService.changeItem(selectedFind);
		if(selectedFind && selectedFind.id) {
			this.chatListsService.resetParams();
			this.chatListparam.client_site_id = selectedFind.clientSiteId;
			this.chatListparam.client_id = selectedFind.client_id;
			this.chatListsService.url = selectedFind.siteName.toLowerCase();
			switch (selectedFind.siteName) {
				case 'Whatsapp':
				case 'whatsapp':
					this.chatListparam.whatsapp_id = selectedFind.id;
					break;
				case 'Webchat':
				case 'webchat':
					this.chatListparam.webchat_id = selectedFind.id;
					break;
				case 'Youtube':
				case 'youtube':
					this.chatListparam.youtube_id = selectedFind.id;
					break;
				case 'Instagram':
				case 'instagram':
					this.chatListparam.instagram_id = selectedFind.id;
					break;
				case 'Instadm':
				case 'instadm':
					this.chatListparam.instadm_id = selectedFind.id;
					break;
				case 'Messenger':
				case 'messenger':
					this.chatListparam.messenger_id = selectedFind.id;
					break;
				case 'Twitter':
				case 'twitter':
					this.chatListparam.tweet_id = selectedFind.id;
					break;
				case 'Twitterdm':
				case 'twitterdm':
					this.chatListparam.tweetdm_id = selectedFind.id;
					break;
			}
			this.chatListsService.getAllItems();
		}
	}

}
