import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/app/appAccount/utils/utils.module';
import { DatePipe } from '@angular/common';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';
import { AssignItrsbotComponent } from './assignItrsbot/assignItrsbot.component';

import { ItrsComponent } from './itrs/itrs.component';
import { IntractiveFormComponent } from './itrsItems/intractiveForm/intractiveForm.component';
import { ChooseIntractiveFormComponent } from './itrsItems/chooseIntractiveForm/chooseIntractiveForm.component';
import { ChooseJumpIntractiveFormComponent } from './itrsItems/chooseJumpIntractiveForm/chooseJumpIntractiveForm.component';
import { ChooseConsumerGroupFormComponent } from './itrsItems/chooseConsumerGroupForm/chooseConsumerGroupForm.component';
import { ChangeIntractiveGroupImageComponent } from './itrsItems/changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { ChangeIntractiveGroupVideoComponent } from './itrsItems/changeIntractiveGroupVideo/changeIntractiveGroupVideo.component';
import { TicketableIntractiveFormComponent } from './itrsItems/ticketableIntractiveForm/ticketableIntractiveForm.component';
import { IntractiveComponent } from './itrsItems/intractive/intractive.component';
import { IntractiveCategoryComponent } from './itrsItems/intractiveCategory/intractiveCategory.component';
import { SingleComponent } from './itrsItems/single/single.component';
import { CombinedComponent } from './itrsItems/combined/combined.component';
import { SurveyComponent } from './itrsItems/survey/survey.component';
import { StoreAsFormComponent } from './itrsItems/storeAsForm/storeAsForm.component';
import { StoreAsCombinedFormComponent } from './itrsItems/storeAsCombinedForm/storeAsCombinedForm.component';
import { StoreAsIntractiveFormComponent } from './itrsItems/storeAsIntractiveForm/storeAsIntractiveForm.component';
import { SimpleFormComponent } from './itrsItems/simpleForm/simpleForm.component';
import { ShortCodeFormComponent } from './itrsItems/shortCodeForm/shortCodeForm.component';
import { LinkTemplateFormComponent } from './itrsItems/linkTemplateForm/linkTemplateForm.component';
import { ItrsApiComponent } from './itrsItems/itrs-api/itrs-api.component';
import { ApiFormComponent } from './itrsItems/apiForm/apiForm.component';
import { TemplateMessageComponent } from './itrsItems/templateMessage/templateMessage.component';
import { TemplateMessageFormComponent } from './itrsItems/templateMessageForm/templateMessageForm.component';
import { LinkIntractiveMessageFormComponent } from './itrsItems/linkIntractiveMessageForm/linkIntractiveMessageForm.component';
import { MapTeamFormComponent } from './itrsItems/mapTeamForm/mapTeamForm.component';
import { MapAgentFormComponent } from './itrsItems/mapAgentForm/mapAgentForm.component';

import { ItrsbotService, ClientSiteService } from 'src/app/@tji/_dbShare';
import { ProductFormComponent } from './itrsItems/product-form/product-form.component';
import { ChangeIntractiveCatalogProductComponent } from './itrsItems/changeIntractiveCatalogProduct/changeIntractiveCatalogProduct.component';
import { IntractiveProductListComponent } from './itrsItems/intractive-product-list/intractive-product-list.component';
import { AiCollectionComponent } from './itrsItems/ai-collection/ai-collection.component';
import { ItrsContinuousIntegraionComponent } from './itrsItems/itrsContinuousIntegraion/itrsContinuousIntegraion.component';
@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        AssignItrsbotComponent,
        FormComponent,
        ItrsComponent,
        IntractiveFormComponent,
        ChooseIntractiveFormComponent,
        ChooseJumpIntractiveFormComponent,
        ChooseConsumerGroupFormComponent,
        ChangeIntractiveGroupImageComponent,
        ChangeIntractiveGroupVideoComponent,
        TicketableIntractiveFormComponent,
        IntractiveComponent,
        IntractiveCategoryComponent,
        SingleComponent,
        CombinedComponent,
        SurveyComponent,
        StoreAsFormComponent,
        StoreAsCombinedFormComponent,
        StoreAsIntractiveFormComponent,
        SimpleFormComponent,
        ShortCodeFormComponent,
        LinkTemplateFormComponent,
        ItrsApiComponent,
        ApiFormComponent,
        TemplateMessageComponent,
        TemplateMessageFormComponent,
        LinkIntractiveMessageFormComponent,
        MapTeamFormComponent,
        MapAgentFormComponent,
        ProductFormComponent,
        ChangeIntractiveCatalogProductComponent,
        IntractiveProductListComponent,
        AiCollectionComponent,
        ItrsContinuousIntegraionComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MainComponent,
        ListComponent,
        ItemComponent,
        AssignItrsbotComponent,
        FormComponent,
        ItrsComponent,
        IntractiveFormComponent,
        ChooseIntractiveFormComponent,
        ChooseJumpIntractiveFormComponent,
        ChooseConsumerGroupFormComponent,
        ChangeIntractiveGroupImageComponent,
        ChangeIntractiveGroupVideoComponent,
        TicketableIntractiveFormComponent,
        IntractiveComponent,
        IntractiveCategoryComponent,
        SingleComponent,
        CombinedComponent,
        SurveyComponent,
        StoreAsFormComponent,
        StoreAsCombinedFormComponent,
        StoreAsIntractiveFormComponent,
        SimpleFormComponent,
        ShortCodeFormComponent,
        LinkTemplateFormComponent,
        ItrsApiComponent,
        ApiFormComponent,
        TemplateMessageComponent,
        TemplateMessageFormComponent,
        LinkIntractiveMessageFormComponent,
        MapTeamFormComponent,
        MapAgentFormComponent,
        ProductFormComponent,
        ChangeIntractiveCatalogProductComponent,
        IntractiveProductListComponent,
        AiCollectionComponent,
        ItrsContinuousIntegraionComponent
    ],
    providers: [
        ItrsbotService,
        ClientSiteService,
        DatePipe
    ]
})

export class ItrsbotImportModule { }
