import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	IntractiveService, Intractive,
	TemplateMessageService, TemplateMessage,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-linkTemplateForm',
	templateUrl: './linkTemplateForm.component.html',
	styleUrls: ['./linkTemplateForm.component.scss']
})

export class LinkTemplateFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Add / Update Short Code';
	authUser: User;
	intractive: Intractive;
	action: string;
	linkTemplateForm: UntypedFormGroup;
	templateParam: any;
	templates: TemplateMessage[] = [];

	constructor(public userService: UserService,
		public intractiveService: IntractiveService,
		public templateMessageService: TemplateMessageService,
		public matDialogRef: MatDialogRef<LinkTemplateFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.templateMessageService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.templateParam = data;
		});
		this.templateMessageService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.templates = data;
		});
		this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractive = data;
			this.getInit(data);
			this.linkTemplateForm = this.createForm();
		});
	}

	ngOnInit() {
		this.templateParam.status = 'true';
		this.templateParam.limit = 100;
		this.templateParam.current = 1;
		// this.templateParam.stencilType = 'itrs-link-template';
		this.templateMessageService.getAllItems();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.templateMessageService.unSubscribe();
		this.templateMessageService.unSubscribeFilter();
	}

	getInit(data) {
		// Set the defaults
		this.action = (data && data.id) ? 'edit' : 'new';
		switch (this.action) {
			case 'new':
				this.dialogTitle = 'Add / Update Short Code';
				break;
			case 'edit':
				this.dialogTitle = 'Add / Update Short Code';
				break;
			default:
				break;
		}
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			template_id: [this.intractive.template_id || null, [Validators.required, Validators.minLength(1)]],
			client_id: [this.intractive.client_id || null, [Validators.required]],
			client_site_id: [this.intractive.resource_id || null, [Validators.required]]
		});
	}
}
