import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { MainComponent } from './main/main.component';

import { ClientToggleServiceService } from 'src/app/@tji/_dbShare';

const routes = [
	{
		path: '',
		component: MainComponent,
		// resolve: { data: ClientToggleServiceService },
	},
	// { path: '**', redirectTo: '/reseller/dashboard/all' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	]
})

export class DashboardRouteModule { }
