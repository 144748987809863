import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as $ from "jquery";

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, ClientService, Client,
	AgentActivityService, AgentActivity, 
	AgentUsageService, AgentUsage, Agent, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
	selector: 'utils-agent-usage-report',
	templateUrl: './agent-report.component.html',
	styleUrls: ['./agent-report.component.scss']
})

export class AgentReportComponent implements OnInit, OnDestroy {
	@ViewChild('report', { static: true }) report: ElementRef;
	@Input() selectedAgent: Agent = null;
	
	authUser: User;
	clientId: any;
	client: Client;
	agentUsageParam: any;
	agentUsages: any;
	agentActivityParam: any;
	agentActivities: AgentActivity = null;
	closeChartData: Array<any> = [];
	abandonChartData: Array<any> = [];
	reassignChartData: Array<any> = [];
	param: any;
	data: any;

	private allItemsSource = new BehaviorSubject<AgentActivity[]>([]);
	allchartItems = this.allItemsSource.asObservable();

	private _unsubscribeAll:Subject<any>;

	constructor(public userService: UserService,
		private router: Router,
		public clientService: ClientService,
		public commonService:CommonService,
		public agentUsageService: AgentUsageService,
		public agentActivityService: AgentActivityService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		// this.clientService.item.pipe(untilDestroyed(this)).subscribe(data => {
		// 	this.client = data;
		// 	if(this.client && this.client.id) {
		// 		this.getAgentUsages(); 
		// 		this.getAgentActivities(); 
		// 	}
		// });
		this.agentUsageService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentUsageParam = data);
		this.agentUsageService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.agentUsages = data );
		this.agentActivityService.params.pipe(untilDestroyed(this)).subscribe(data => this.agentActivityParam = data);
		this.agentActivityService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.agentActivities = data
			if(data && data.closed) { this.closeChartData = data.closed; }
			if(data && data.abandon) { this.abandonChartData = data.abandon; }
			if(data && data.reassigned) { this.reassignChartData = data.reassigned; }
		});
		this.clientId = this.userService.getAuthClientId();
	  }
	
	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void {
		this.agentUsageService.resetParams();
	}

	// getAgentUsages() {
	// 	this.agentUsageParam.client_id = this.client.id;
	// 	this.agentUsageService.getAllItems();
	// }

	// getAgentActivities() {
	// 	this.agentActivityParam.client_id = this.client.id;
	// 	this.agentActivityService.getAllItems();
	// }

	public openPDF(idsec):void {

		let DATA = document.getElementById(idsec);

		$('#hidden-section').show();
		
		html2canvas(DATA,  {
			scrollX: 0,
			scrollY: 0
		  }).then(canvas => {
			
			const FILEURI = canvas.toDataURL('image/png')
			let PDF = new jsPDF();
			let position = 3;
		
			var imgWidth = PDF.internal.pageSize.getWidth();
			var pageHeight = PDF.internal.pageSize.getHeight();
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;

			PDF.addImage(FILEURI, 'PNG', 0, position, imgWidth, imgHeight)

			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
			position = heightLeft - imgHeight;
			PDF.addPage();
			PDF.addImage(FILEURI, 'PNG', 1, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;
			}

			PDF.save(idsec+'.pdf');

			position = 3;

			let currentUrl = this.router.url;

			this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
				this.router.navigate([currentUrl]);
			});

			$('#hidden-section').hide();

		});     
	}
	
	exportExcel(agentUsage, ticketType) {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				message: 'Are you sure want to Export ' + ticketType + ' Tickets on ' + agentUsage.site_name + '?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				let param = {
					'client_id': agentUsage.client_id,
					'client_site_id': agentUsage.client_site_id,
					'agent_id': (this.selectedAgent) ? this.selectedAgent.id : '',
					'ticket_type': ticketType,
					'from_date': this.agentUsageParam.from_date,
					'to_date': this.agentUsageParam.to_date,
				};
				let url: string = this.agentUsageService.getExportUrl(param);
				window.open(url, '_self');
			}
		});
	}

}
