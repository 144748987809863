export class CatalogProductModel {
    _id: number;
    client_id: number;
    catalog_id: number;
    business_id: number;
    name: string;
    currency: string;
    price: number;
    description: string;
    image_url: string;
    url: string;
    gtin: number;
    created_at: number;
    deleted_at: any;
    id: number;
    auto_generate_gtn: any;
    catalogName: string;
    retailer_id: any;
    isDragDrop: boolean;
    availability: string;
    is_active: any;
    ordering_index: any;

    /**
     * Constructor
     *
     * @param product
     */
    constructor(product) {
        this._id = product._id || '';
        this.client_id = product.client_id || '';
        this.catalog_id = product.catalog_id || '';
        this.business_id = product.business_id || '';
        this.name = product.name || '';
        this.currency = product.currency || '';
        this.price = product.price || '';
        this.description = product.description || '';
        this.image_url = product.image_url || '';
        this.url = product.url || '';
        this.gtin = product.gtin || '';
        this.created_at = product.created_at || '';
        this.deleted_at = product.deleted_at || '';
        this.id = product.id || '';
        this.auto_generate_gtn = product.auto_generate_gtn || '';
        this.catalogName = product.catalog_name || '';
        this.retailer_id = product.retailer_id || '';
        this.isDragDrop = product.is_drag_drop || false;
        this.availability = product.availability || '';
        this.is_active = product.is_active || false;
        this.ordering_index = product.ordering_index || 0;
    }
}