import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { SimpleFormComponent } from './../simpleForm/simpleForm.component';
import { IntractiveFormComponent } from './../intractiveForm/intractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './../changeIntractiveGroupImage/changeIntractiveGroupImage.component';

import {
	User, UserService,
	ItrsbotService, Itrsbot,
	IntractiveService, Intractive, IntractiveModel,
	IntractiveGroupService, IntractiveGroup, IntractiveGroupModel, IntractiveCategoryService, IntractiveCategory,
	IntractiveMessageService,
	AlertService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'itrsbot-itrs-intractive',
	templateUrl: './intractive.component.html',
	styleUrls: ['./intractive.component.scss']
})

export class IntractiveComponent implements OnInit, OnDestroy {
	authUser: User;
	itrsbot: Itrsbot;
	intractivesData: Intractive[] = [];
	intractive: Intractive;
	intractiveParam: any;
	selectedIntractive: Intractive;
	intractiveSearch: any = "";
	intractiveId = null;
	selectedCategories: any = null;
	intractiveCategories: IntractiveCategory[] = [];
	isActive: any = null;

	constructor(public userService: UserService,
		public itrsbotService: ItrsbotService,
		public intractiveService: IntractiveService,
		public intractiveGroupService: IntractiveGroupService,
		public intractiveMessageService: IntractiveMessageService,
		private alertService: AlertService,
		private router: Router,
		public intractiveCategoryService: IntractiveCategoryService,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.itrsbotService.item.pipe(untilDestroyed(this)).subscribe(data => this.itrsbot = data);
		this.intractiveService.params.pipe(untilDestroyed(this)).subscribe(data => this.intractiveParam = data);
		this.intractiveService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.intractivesData = data );
		this.intractiveCategoryService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.intractiveCategories = data;
		});
	}

	ngOnInit() {
		this.intractiveParam.client_site_id = this.intractiveParam.resource_id;
		this.intractiveService.getAllItems();
		this.intractiveMessageService.getAllDropdownlist();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.intractiveService.resetParams();
		this.intractiveService.changeAllItems(null);
		this.intractiveService.unSubscribe();
		this.intractiveService.unSubscribeFilter();
		delete this.intractiveParam.itrsbot_id;
	}

	selectIntractive(intractive, index) {
		this.isActive = index;
		this.intractiveService.changeItem(intractive);
		let intractParam = this.intractiveParam;
		intractParam.id = intractive.id;
		this.intractiveService.getIntractiveItem(intractParam);
	}
	/** Add / Update Tag */
	newIntractiveGroup(intractiveGroup = null): void {
		this.intractiveService.changeItem((this.intractive) ? this.intractive : null);
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(SimpleFormComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
				isChoiceMsg: this.isItrsCombined(this.intractive),
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				switch (actionType) {
					case 'new':
						this.intractiveGroupService.itrsGroup('interactiveGroup/addMoreMessage', formData.value);
						break;
					case 'update':
						formData.value['id'] = id;
						this.intractiveGroupService.itrsGroup('interactiveGroup/updateMoreMessage', formData.value);
						break;
				}
			});
	}

	/** Add / Update Intractive */
	newIntractive(intractive = null): void {
		this.intractiveService.changeItem((intractive) ? intractive : new IntractiveModel({}));
		let dialogRef = this.matDialog.open(IntractiveFormComponent, {
			width: "600px",
			data: {
				action: (intractive) ? 'edit' : 'new',
				item: intractive,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) {
					delete this.intractiveId;
					this.intractiveService.cloneItemsSource.next(false);
					return;
				}
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				if (formData.value.track_it) {
					formData.value.track_it = 1;
				} else {
					formData.value.track_it = 0;
				}
				switch (actionType) {
					case 'new':
						formData.value.resource_id = parseInt(formData.value.resource_id);
						this.intractiveService.intractiveStore('Intractive/add', formData.value);
						break;
					case 'update':
						formData.value.resource_id = parseInt(formData.value.resource_id);
						this.intractiveService.removelibraryById(id);
						this.intractiveService.removelibraryById(id);
						formData.value['id'] = id;
						this.intractiveService.newIntractiveUpdate('Intractive/update', formData.value, intractive);
						break;
					case 'clone':
						var clone = formData.value;
						clone["copyFromIntractiveId"] = parseInt(this.intractiveId);
						clone["resource_id"] = parseInt(formData.value.resource_id);
						this.intractiveService.intractiveClone('Intractive/add', clone);
						this.intractiveService.cloneItemsSource.next(false);
						delete this.intractiveId;
						break;
					default:
						delete this.intractiveId;
						this.intractiveService.cloneItemsSource.next(false);
						break;
				}
			});
	}

	/** Delete Intractive */
	deleteIntractive(intractive): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '400',
			data: {
				type: 'delete',
				message: 'Are you sure you want to delete this template?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				var formData = {
					id: intractive.id
				}
				this.intractiveService.destroy('Intractive/delete', formData);
				this.intractiveService.removelibraryById(intractive.id);
			}
		});
	}

	/** Add / Change IntractiveGroup Images */
	imageIntractiveGroup(intractiveGroup = null): void {
		this.intractiveGroupService.changeItem((intractiveGroup) ? intractiveGroup : new IntractiveGroupModel({}));
		let dialogRef = this.matDialog.open(ChangeIntractiveGroupImageComponent, {
			width: "600px",
			data: {
				action: (intractiveGroup) ? 'edit' : 'new',
				item: intractiveGroup,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
			});
	}

	isItrsCombined(intractive: Intractive): boolean {
		let slug = (intractive && intractive.id && intractive.intractive_category.slug) ? intractive.intractive_category.slug : null;
		return (slug && (slug === 'feedback' || slug === 'survey' || slug === 'one-time-survey')) ? true : false;
	}
	/* * * * *
	* search
	* * * * * */
	searchInteractiveData() {
		this.intractiveService.onSearch(this.intractiveSearch);
	}

	searchClear() {
		this.intractiveSearch = "";
		this.intractiveParam.search = '';
		this.intractiveService.getAllItems();
	}

	intractiveFilter(intractive: string) {
		if (intractive == 'reset') {
			delete this.intractiveParam.is_begin;
			this.intractiveService.getAllItems();
		} else if (intractive == 'main') {
			// this.intractivesData = this.intractives.filter(data => data.is_begin == true);
			this.intractiveParam.is_begin = 1
			this.intractiveService.getAllItems();
		}
		// else if (intractive == 'agent') {
		// 	var agent = [];
		// 	this.intractives.forEach(data => {
		// 		if (data['mapAgentIds'].length > 0 || data['mapTeamIds'].length > 0) {
		// 			agent.push(data);
		// 		}
		// 	});
		// 	this.intractivesData = agent;
		// } else if (intractive == 'itrs') {
		// 	var agent = [];
		// 	this.intractives.forEach(data => {
		// 		if (data.intractive_message_id) {
		// 			agent.push(data);
		// 		}
		// 	});
		// 	this.intractivesData = agent;
		// }
	}

	// @HostListener('window:scroll', ['$event']) 
	// scrollHandler(event) {
	//   console.debug("Scroll Event", event  , window.pageYOffset);
	// //   this.divCurtain.nativeElement.style.top = window.pageYOffset.toString().concat('px');
	// }
	cloneIntractive(item = null, intractive = null) {
		if (item == 'clone') {
			this.intractiveService.cloneItemsSource.next(true);
			this.intractiveId = intractive.id;
			this.newIntractive();
		}
	}
	getIntractive() {
		this.selectedCategories = (this.selectedCategories && this.selectedCategories.length > 0) ? this.selectedCategories : null;
		this.intractiveParam.intractive_category_id = (this.selectedCategories) ? this.selectedCategories.toString() : null;
		this.getIntractiveData();
		this.intractiveService.getAllItems();
	}
	getIntractiveData() {
		this.intractiveParam.client_site_id = (this.itrsbot && this.itrsbot.resource_id) ? this.itrsbot.resource_id : null;
		this.intractiveParam.itrsbot_id = this.itrsbot.id;
		this.intractiveService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.length > 0 && this.itrsbot && this.itrsbot.id) {
				this.intractivesData = data.filter(x => {
					return (x.itrsbot_id && x.itrsbot_id === this.itrsbot.id) ? true : false;
				});
			} else { this.intractivesData = []; }
		});
	}

	copyCode(){
		this.alertService.webShow('Success', 'Payload copied successfully');
	}

}
