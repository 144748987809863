import { Component, OnInit, OnDestroy  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { User, UserService } from 'src/app/@tji/_dbShare';

import { PolicyModelComponent } from 'src/app/library/model/policy-model/policy-model.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  authUser: User;
  isAuth: boolean = true;
  activeSection: string = 'home';
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplaySpeed: 800,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 2 },
      400: { items: 3 },
      740: { items: 5 },
      940: { items: 6 }
    },
    nav: false
  }

  clients: Array<any> = [
    { id: 1, title: 'client1', img: 'assets/webtheme/images/clients/client1.png' },
    { id: 2, title: 'client2', img: 'assets/webtheme/images/clients/client2.png' },
    { id: 3, title: 'client3', img: 'assets/webtheme/images/clients/client3.png' },
    { id: 4, title: 'client4', img: 'assets/webtheme/images/clients/client4.png' },
    { id: 5, title: 'client5', img: 'assets/webtheme/images/clients/client5.png' },
    { id: 6, title: 'client6', img: 'assets/webtheme/images/clients/client6.png' },
    { id: 7, title: 'client7', img: 'assets/webtheme/images/clients/client7.png' },
    { id: 8, title: 'client8', img: 'assets/webtheme/images/clients/client8.png' },
    { id: 9, title: 'client9', img: 'assets/webtheme/images/clients/client9.png' },
    { id: 10, title: 'client10', img: 'assets/webtheme/images/clients/client10.png' },
    { id: 11, title: 'client11', img: 'assets/webtheme/images/clients/client11.png' },
    { id: 12, title: 'client12', img: 'assets/webtheme/images/clients/client12.png' },
    { id: 13, title: 'client13', img: 'assets/webtheme/images/clients/client13.png' },
    { id: 14, title: 'client14', img: 'assets/webtheme/images/clients/client14.png' },
  ];
  features: Array<any> = [
    { title: 'Omni Channel Integration', description: 'Quickly integrate and implement new digital service channels.', 'icon': 'pe-7s-diamond'},
    { title: 'Auto routing by Key words', description: 'Divert callers to self-service or live digital agents through our virtual ITRs. No more tedious ITRs sessions.', 'icon': 'pe-7s-display2' },
    { title: 'Automated Messeging', description: 'Send automatic messages to customers who have waited for a long time or abandoned the call.', 'icon': 'pe-7s-piggy' },
    { title: 'Multimedia Supported', description: 'Enable your customers to send you photocopied documents from their smartphone through all digital channels instead of scanning them or sending them through mail or fax.', 'icon': 'pe-7s-science' },
    { title: 'Data Collection', description: 'Enable virtual identification - in some cases (e.g. provision of loans) you need to see the customers to verify their identity. Now you can do it from social media.', 'icon': 'pe-7s-news-paper' },
    { title: 'Personalized Insight', description: 'Identify customer care and support issues in your social media channels and route them to the right agent with the right skills in the right support channel.', 'icon': 'pe-7s-plane' },
    { title: 'RoboChat', description: 'Optimize your support by using our Bot and Intentions modules, understanding the issue and its urgency and transferring it the most appropriate channel.', 'icon': 'pe-7s-arc' },
    { title: 'Analysis', description: 'The backend team will be provided with full analysis of cycle in the customer relation management, Agents response Analysis, customer satisfaction survey etc.', 'icon': 'pe-7s-tools' },
    { title: 'Marketing Insight', description: 'Convert customers to achieve your business goals with fast onboarding and lead generation features.', 'icon': 'pe-7s-timer' }
  ];
  benefits: Array<any> = [
    { title: 'Increase sales', description: 'Satisfied customer means good sales and more customers, if more customers mean increase in sales.', 'icon': 'pe-7s-diamond' },
    { title: 'Loyalty Program', description: 'Can conduct any type of loyalty program in any social media, the loyalty program can be integrated our system.', 'icon': 'pe-7s-display2' },
    { title: 'Survey', description: 'Similar to loyalty program, any type of survey can be conducted though our system and integrate with our system.', 'icon': 'pe-7s-piggy' },
    { title: 'Low overheads', description: 'No need for a separate call centre agent, we can integrate to your databases and replies come through our AI.', 'icon': 'pe-7s-science' },
    { title: 'Easy Ticketing System', description: 'Each query can be treated as a ticket and the ticket can be forwarded to different departments in your business line.', 'icon': 'pe-7s-news-paper' },
    { title: 'Fast & accurate Response', description: 'Very fast and accurate response in any social media through or system, the system will also monitor all the agent behaviour and will pass on to other agent if no quick response.', 'icon': 'pe-7s-plane' }
  ];

  loop: boolean = true;

  constructor(public userService: UserService,
              private router: Router) {
      this.isAuth = this.userService.authCheck();
  }

  ngOnInit() {
     this.checkAuth();
  }

  ngOnDestroy() {
    this.loop = false;
  }

  checkAuth() {
    if(this.isAuth) {
      this.userService.renewUser();
      setTimeout(() => {
        if(this.loop) {
          this.checkAuth();
        }
      }, 20000);
    } else {
      this.loop = false;
    }
  }

}
