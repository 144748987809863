import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { BrandService,CountryService } from 'src/app/@tji/_dbShare';
const routes = [
	{
		path: '',
		component: MainComponent,
		resolve: { data: BrandService, country: CountryService },
	},
	{ path: '**', redirectTo: '/app/my-brand' },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BrandRoutingModule { }
