import { TemplateObject, ComponentObject, LanguageObject, TemplateComponent } from './templateMessageObject.interface';

export class TemplateMessageObjectModel {
    type: string;
    to: string;
    template: TemplateObject;
    templateComponent: TemplateComponent;
    // whatspp_data : string;
    /**
     * Constructor
     *
     * @param templateMessageObjectData
     */
    constructor(templateMessageObjectData) {
            this.to = templateMessageObjectData.to || "<<to>>";
            this.type = templateMessageObjectData.type || "template";
            this.template = templateMessageObjectData.template || new TemplateObjectModel({});
            this.templateComponent = templateMessageObjectData.templateComponent || new TemplateComponentModel({});
            // this.whatspp_data = templateMessageObjectData.whatspp_data || "";
    }
}

export class TemplateObjectModel {
    namespace: string;
    name: string;
    language: LanguageObject;
    components: Array<ComponentObject>;

    /**
     * Constructor
     *
     * @param templateObjectData
     */
    constructor(templateObjectData) {
            this.namespace = templateObjectData.namespace || null;
            this.name = templateObjectData.name || null;
            this.language = templateObjectData.language || new LanguageObjectModel({});
            this.components = templateObjectData.components || [];
    }
}

export class ComponentObjectModel {
    type: string;
    parameters: Array<any>;

    /**
     * Constructor
     * @param languageObjectData
     */
    constructor(componentObjectData) {
        this.type = componentObjectData.type || null;
        this.parameters = componentObjectData.parameters || null;
    }
}

export class LanguageObjectModel {
    code: string;
    policy: string;

    /**
     * Constructor
     * @param languageObjectData
     */
    constructor(languageObjectData) {
        this.code = languageObjectData.code || 'en_US';
        this.policy = languageObjectData.policy || 'deterministic';
    }
}

export class TemplateComponentModel {
    image:string;
    video:string;
    document:string;
    headerType:string;
    headerText:string;
    bodyType:string;
    bodyText:string;
    footerType:string;
    footerText:string;
    document_name: string;
    video_name: string;
    image_name: string;

    /**
     * Constructor
     *
     * @param templateComponentData
     */
    constructor(templateComponentData) {
        this.image = templateComponentData.image || null;
        this.video = templateComponentData.video || null;
        this.document = templateComponentData.document || null;
        this.headerType = templateComponentData.headerType || 'text';
        this.headerText = templateComponentData.headerText || null;
        this.bodyType = templateComponentData.bodyType || 'text';
        this.bodyText = templateComponentData.bodyText || null;
        this.footerType = templateComponentData.footerType || 'text';
        this.footerText = templateComponentData.footerText || null;
        this.document_name = templateComponentData.document_name || null;
        this.video_name = templateComponentData.video_name || null;
        this.image_name = templateComponentData.image_name || null;
    }
}

// export class HeaderModel {
//     type: string;
//     text?: string;
//     video?: any;
//     image?: any;
//     document?: any;

//     /**
//      * Constructor
//      *
//      * @param headerData
//      */
//     constructor(headerData) {
//             this.type = headerData.type || "text";
//             this.text = headerData.text || '';
//     }
// }

// export class BodyModel {
//     type: string;
//     text: string;

//     /**
//      * Constructor
//      *
//      * @param bodyData
//      */
//     constructor(bodyData) {
//             this.type = bodyData.type || "text";
//             this.text = bodyData.text || '';
//     }
// }

// export class FooterModel {
//     type: string;
//     text: string;

//     /**
//      * Constructor
//      *
//      * @param footerData
//      */
//     constructor(footerData) {
//             this.type = footerData.type || "text";
//             this.text = footerData.text || '';
//     }
// }

// export class ActionModel {
//     type: string;
//     button?: string;
//     buttons?: any;
//     sections?: any;

//     /**
//      * Constructor
//      *
//      * @param footerData
//      */
//     constructor(footerData) {
//             this.type = footerData.type || "";
//     }
// }
