export class TemplateReportModel {
	id: number;
	jobcode: string;
	template_id: number;
	client_id: number;
	is_completed: boolean;
	created_at: Date;
	updated_at: Date;
	created_by: number;
	updated_by: number;

	templateName?: string;
	allUsers?: Array<any>;
	failedUsers?: Array<any>;
	total_count?: number;
	failed_count?: number;
	success_count?: number;
	rejected_count?: number;
	
	template?: any;

	/**
	 * Constructor
	 * @param templateReport
	 */
	constructor(templateReport) {
		this.id = templateReport.id || null;
		this.jobcode = templateReport.jobcode || null;
		this.template_id = templateReport.template_id || null;
		this.client_id = templateReport.client_id || null;
		this.is_completed = templateReport.is_completed || null;
		this.created_at = templateReport.created_at || null;
		this.updated_at = templateReport.updated_at || null;
		this.created_by = templateReport.created_by || null;
		this.updated_by = templateReport.updated_by || null;

		this.templateName = templateReport.templateName || null;
	}
}
