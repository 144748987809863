export class ModalHasFlagModel {
    _id: number;
    resource_id: number;
    resource_type: string;
    flag_id: number;
    client_id: number;
    client_site_id: number;
    created_by: number;
    updated_by: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    ticket_id: number;
    tableName?: string;

    /**
     * Constructor
     *
     * @param modalHasFlag
     */
    constructor(modalHasFlag) {
            this._id = modalHasFlag._id || '';
            this.resource_id = modalHasFlag.resource_id || '';
            this.resource_type = modalHasFlag.resource_type || '';
            this.flag_id = modalHasFlag.flag_id || '';
            this.client_id = modalHasFlag.client_id || '';
            this.client_site_id = modalHasFlag.client_site_id || '';
            this.created_by = modalHasFlag.created_by || '';
            this.updated_by = modalHasFlag.updated_by || '';
            this.created_at = modalHasFlag.created_at || '';
            this.updated_at = modalHasFlag.updated_at || '';
            this.deleted_at = modalHasFlag.deleted_at || '';
            this.ticket_id = modalHasFlag.ticket_id || '';
            this.tableName = modalHasFlag.tableName || '';
    }
}
