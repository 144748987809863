import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from './data';

import { User, UserService } from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'dashboard-chart3',
	templateUrl: './chart3.component.html',
	styleUrls: ['./chart3.component.scss']
})

export class Chart3Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('chart3', { static: true }) chart3: ElementRef;
	authUser: User;

	data: any[];
	view: any[] = [];

	colorScheme = {
		domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
	};
	cardColor: string = '#414aa257';

	constructor(public userService: UserService,
		private _formBuilder: UntypedFormBuilder,
		public matDialog: MatDialog) {
		Object.assign(this, { data });
	}

	ngOnInit() {
		this.view = [this.chart3.nativeElement.offsetWidth - 100, this.chart3.nativeElement.offsetHeight];
	}

	ngAfterViewInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	onSelect(event) {
	}

}
