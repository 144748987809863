import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';

import {
	User, UserService,
	FlagService, Flag,
	ModalHasFlagService, ModalHasFlag
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'flags',
	templateUrl: './flags.component.html',
	styleUrls: ['./flags.component.scss']
})

export class FlagsComponent implements OnInit, OnDestroy {
	@Input() ticket: any;
	@Input() clientSiteId: number;
	@Output() closeMe = new EventEmitter();

	loader: boolean = true;
	user: User;
	flags: Flag[] = [];
	flagParam: any;

	ticketFlags: ModalHasFlag[] = [];
	ticketFlagParam: any;

	constructor(public userService: UserService,
		public flagService: FlagService,
		public modalHasFlagService: ModalHasFlagService,
		private router: Router,
		public matDialog: MatDialog) {
		this.userService.user.subscribe(data => this.user = data);
		this.modalHasFlagService.params.pipe(untilDestroyed(this)).subscribe(data => this.ticketFlagParam = data);
		this.modalHasFlagService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (this.ticket && this.ticket.id && this.ticket.siteName && this.clientSiteId) {
				this.ticketFlags = [];
				if (data.length > 0) {
					data.forEach(element => {
						if (element.client_site_id === this.clientSiteId) {
							this.ticketFlags.push(element);
						}
					});
					this.setModaHashFlags();
				}
			}
		});
		this.flagService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			if (this.clientSiteId && data.length > 0) {
				this.flags = [];
				data.forEach(element => {
					if (element.client_site_id && element.client_site_id.length > 0) {
						element.client_site_id.forEach(id => {
							if (id.id == this.clientSiteId) {
								element["isActive"] = false;
								this.flags.push(element);
							}
						});
					}
				});
				if (this.ticketFlags.length > 0 && this.flags.length > 0) {
					this.ticketFlags.forEach(modaHashFlags => {
						this.flags.forEach(flag => {
							if (modaHashFlags.flag_id == flag._id) {
								flag["isActive"] = true;
							}
						});
					});
				}
				// this.setModaHashFlags();
			}
		});

		this.flagService.params.pipe(untilDestroyed(this)).subscribe(data => this.flagParam = data);
	}

	ngOnInit() {
		this.doLoader();
		this.getTicketFlags();
		this.getFlags();
	}

	/** On destroy */
	ngOnDestroy(): void {
		// this.modalHasFlagService.unSubscribe();
	}

	doLoader() {
		setTimeout(() => {
			this.loader = !this.loader;
		}, 5000);
	}

	getTicketFlags() {
		if (this.ticket && this.ticket.id) {
			this.ticketFlagParam.client_id = this.ticket.client_id;
			this.ticketFlagParam.client_site_id = this.clientSiteId;
			this.ticketFlagParam.resource_id = this.ticket.id;
			// this.ticketFlagParam.ticket_id = this.ticket.id;
			// this.ticketFlagParam.resource_type = this.ticket.className;
			if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
				this.ticketFlagParam.resource_id) {
				this.modalHasFlagService.getAllItems();
			}
		}
	}

	getFlags() {
		this.flagParam.client_id = this.ticket.client_id;
		this.flagParam.client_site_id = this.clientSiteId;
		this.flagService.getAllItems();
	}

	// checkFlag(flag): boolean {
	// 	if (this.ticketFlags && this.ticketFlags.length > 0) {
	// 		let enabledFlag = this.ticketFlags.find(x => x.flag_id == flag._id);
	// 		return (enabledFlag && enabledFlag._id) ? true : false;
	// 	} else {
	// 		return false
	// 	}
	// }

	syncFlag(flag: Flag, event) {
		if (event.checked) {
			let data: any = {
				"ticket_id": this.ticket.id,
				"flag_id": flag._id,
				"client_site_id": this.clientSiteId,
			};
			this.modalHasFlagService.store('flag/addflagMap', data);
		} else {
			let enabledFlag = this.ticketFlags.find(x => x.flag_id == flag._id);
			if (enabledFlag && enabledFlag._id) {
				var data = {
					id: enabledFlag._id
				}
				this.modalHasFlagService.destroy('flag/flagMapdelete', data);
			}
		}
	}

	setModaHashFlags() {
		if (this.ticketFlags.length > 0 && this.flags.length > 0) {
			this.ticketFlags.forEach(modaHashFlags => {
				this.flags.forEach(flag => {
					if (modaHashFlags.flag_id === flag._id) {
						flag["isActive"] = true;
					}
				});
			});
		}
	}

	/** Close Tickets */
	// doReply(flag: Flag = null): void {
	//     let flagDialogRef = this.matDialog.open(FlagDialogComponent, {
	//         disableClose: false,
	//         width: '400px',
	//         data: {
	//             type: 'new',
	//             title: 'Add New Flag',
	//             resource: this.ticket,
	//             flag: flag,
	//             message: 'Enter Your flag here...',
	//         }
	//     });
	//     flagDialogRef.afterClosed().subscribe(result => {
	//         if (result && result.data) {
	//             this.flagService.store(result.formValue);
	//         }
	//     });
	// }
}