import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService, Role,
	Client, ClientService
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'role-assign-client',
	templateUrl: './assignClient.component.html',
	styleUrls: ['./assignClient.component.scss']
})

export class AssignClientComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Create';
	authUser: User;
	action: string;
	assignClientForm: UntypedFormGroup;

	clientParam: any;
	clients: Client[] = [];
	role: Role;

	constructor(public userService: UserService,
		public clientService: ClientService,
		public matDialogRef: MatDialogRef<AssignClientComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.getInit(_data);
		this.clientService.params.pipe(untilDestroyed(this)).subscribe(data => this.clientParam = data);
		this.clientService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.clients = data);
	}

	ngOnInit() {
		this.getClients();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getClients() {
		this.clientParam.all = 1;
		this.clientParam.only = 'id,name,code';
		this.clientService.getAllItems();
	}

	getInit(data) {
		// Set the defaults
		this.role = data.item;
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Choose Client to Apply Role';
				break;
			case 'edit':
				this.dialogTitle = 'Choose Client to Change Role';
				break;
			default:
				break;
		}
		this.assignClientForm = this.createForm();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			user_id: [null, [Validators.required]],
			role_name: [(this.role && this.role.name) ? this.role.name : null, [Validators.required]]
		});
	}

	changeClient(clientId) {
		let selectedClient: any = this.clients.filter(x => x.id === clientId)[0];
	}

}
